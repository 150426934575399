import React, { useState, useRef, useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams, useHistory } from "react-router-dom";
import SignupPageBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Input from "../Input";
import Button from "@material-ui/core/Button";
import "../../../assets/scss/signuppage-styles/TenantSignUp.scss";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import SignUpPageFooter from "../signUpPages/SignUpPageFooter";
import { getTenantDetails } from "../../../redux/actions/getTenantDetailsActions";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import resetPasswordIcon from "../../../assets/vizr_images/Reset_Password-delete.svg";
import {
  ACTIVATE_ACCOUNT_RESET_CLICK,
  GET_FEATURES,
  GET_LICENSES,
  VALIDATE_SHORTNAME,
  PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE,
  LOGO_FORMAT_ERROR_MESSAGE,
  LOGO_SIZE_ERROR_MESSAGE,
  TENANT_LOGO_SIZE,
  RISK,
  SERVER_ERROR_MESSAGE,
} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { SendLink } from "../../common/buttons";
import SnackBar2 from "../../../components/shared/SnackBar2";
import { filter, forEach, isEmpty } from "lodash";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { MDR, DDV } from "../../../redux/constants/constants";
import Toggler from "../../shared/Toggler";
import { ViewTenantConcentratorDetails } from "../MdrConcentrator/viewConcentrator/ViewMdrConcentratorDetails";

import styled from "styled-components";
import MuiGrid from "@material-ui/core/Grid";

import CreateTenantDBConfig, { DB_CONFIG_ACTION } from "./CreateTenantDBConfig";
import { DB_MANUAL } from "./CreateTenantDBConfig";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  signUpGrid_container: {
    border: "1px solid lavender !important",
    marginTop: "2.5rem",
    marginLeft: "12.5rem",
    height: "100%",
    width: "84% !important",
    borderRadius: "0.2rem",
    paddingLeft: "2rem",
    paddingTop: "2rem",
    position: "relative",
  },
  label: {
    height: "20px",
    width: "8rem",
    color: "#292929",
    fontSize: "0.9rem",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
  },
  label2: {
    height: "20px",
    width: "8rem",
    color: "#292929",
    fontSize: "0.9rem",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
  },
  input: {
    width: "100%",
    marginTop: "20px",
    boxSizing: "border-box",
    height: "32px",
    border: "1px solid #DFE1E9",
    borderRadius: "4px",
    backgroundColor: " #FFFFFF",
  },
  domain: {
    width: "268px",
    minWidth: "50%",
    maxWidth: "100%",
    marginTop: "20px",
    boxSizing: "border-box",
    height: "32px",
    border: "1px solid #DFE1E9",
    borderRadius: "4px",
    backgroundColor: " #FFFFFF",
    position: "relative",
  },
  domain2: {
    width: "200px",
    marginTop: "20px",
    boxSizing: "border-box",
    height: "32px",
    border: "1px solid #DFE1E9",
    borderRadius: "4px",
    backgroundColor: " #FFFFFF",
    position: "relative",
  },
  plus: {
    height: "32px !important",
    minWidth: "36px !important",
    borderRadius: "4px !important",
    backgroundColor: "#2947FD !important",
    boxShadow: "0 5px 8px -3px rgba(41,71,253,0.5) !important",
    marginTop: "5rem",
    marginLeft: "1rem",
    fontSize: "1.7rem",
    
  },
  plus2: {
    height: "32px !important",
    minWidth: "42px !important",
    borderRadius: "4px !important",
    backgroundColor: "#2947FD !important",
    boxShadow: "0 5px 8px -3px rgba(41,71,253,0.5) !important",
    padding: "10px !important",
    position: "absolute",
    left: "19.5rem",
    top: "20rem",
  },
  tenenantsignup_select_container: {
    marginTop: "1.8rem",
    postion: "relative",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    // width: "120%",
    // maxWidth: "220%",
    // position: "relative",
    // maxWidth: "100%",
  },
  adminSignUp: {
    width: "100%",
    marginTop: "40px",
  },
  selectionLabel: {
    height: "20px",
    width: "8rem",
    color: "#292929",
    fontSize: "0.9rem",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
    marginBottom: "12px",
    marginTop: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const Grids = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));
const initialState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  adminName: "",
  adminEmail: "",
  tenantName: "",
  domain: [],
  licenseTypes: [],
  platformSubdomainName: "",
  logo: "",  //for tenant logos's
  monitorVision: "Silver(500)",
  controlShield: "Silver(500)",
  windows_domain: "",
  shortName: "",
  featuresData: [],
  licenceTypeData: [],
  selectedFeatures: [],
  loaded: false,
  provisionDbType: "NEW_RDS",
  databaseConfiguration: {},
  host: "",
  port: "",
  username: "",
  password: "",
  instanceId: "",
  schema: "",
  name: "",
};

//defining the Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "firstName":
      return {
        ...state,
        firstName: action.payload,
      };
    case "lastName":
      return {
        ...state,
        lastName: action.payload,
      };
    
    case "adminName":
      return {
        ...state,
        adminName: action.payload,
      };
    case "adminEmail":
      return {
        ...state,
        adminEmail: action.payload,
      };
    case "tenantName":
      return {
        ...state,
        tenantName: action.payload,
      };
    case "domain":
      return {
        ...state,
        domain: [...state.domain, action.payload],
      };
    case "domainInit":
      return {
        ...state,
        domain: action.payload,
      };
    case "licenseTypes":
      return {
        ...state,
        licenseTypes: [...state.licenseTypes, action.payload],
      };
    case "licenseTypesInit":
      return {
        ...state,
        licenseTypes: action.payload,
      };
    case "platformSubdomainName":
      return {
        ...state,
        platformSubdomainName: action.payload,
      };
    case "logo":
      return {
        ...state,
        logo: action.payload,
      }
    case "shortName":
      return {
        ...state,
        shortName: action.payload,
      };
    case "windows_domain":
      return {
        ...state,
        windows_domain: action.payload,
      };
    case "featuresData":
      return {
        ...state,
        featuresData: action.payload,
      };
    case "licenceTypeData":
      return {
        ...state,
        licenceTypeData: action.payload,
      };
    case "selectedFeatures":
      return {
        ...state,
        selectedFeatures: action.payload,
      };
    case "loaded":
      return {
        ...state,
        loaded: action.payload,
      };
    case DB_CONFIG_ACTION:
      if (action.payload.provisionDbType === DB_MANUAL) {
        return {
          ...state,
          provisionDbType: action.payload.provisionDbType,
          databaseConfiguration: {
            ...state.databaseConfiguration,
            ...action.payload.databaseConfiguration,
          },
        };
      } else {
        // reset db config object if any other db type other than manual is selected
        return {
          ...state,
          provisionDbType: action.payload.provisionDbType,
          databaseConfiguration: {},
        };
      }
    default:
      return state;
  }
};

const TenenantSignUp = (props) => {
  const inputFile = useRef(null);
  const classes = useStyles();

  const queryParam = useParams();
  const [tenantSignUpObj, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const [inputNumber, setInputNumber] = useState([1]);
  // const [id, setId] = useState([0]);
  const [file, setFile] = useState(null);
  const [fileUpload, setfileUpload] = useState("");
  const [signUpactive, makeSignUpAcitive] = useState(false);
  const [privacyPolicy, setprivacyPolicy] = useState(false);
  const [license, setLicense] = useState(false);
  const [isCreate, setIsCreate] = useState(
    window.location.href.indexOf("createTenant") > -1
  );
  const [tenantId, setTenantId] = useState("");
  const [domain, setDomain] = useState("");
  const [domainsArr, setDomainsArr] = useState([]);
  const [shortNameRef, setShortname] = useState("");
  const [mdr, setMdr] = useState("");
  const [errorObj, setErrorObj] = useState({
    tenantName: "",
    Domain: "",
    email: "",
    firstName: "",
    lastName: "",
    shortName: "",
    host_error: "",
    username_error: "",
    password_error: "",
  });
  const [resetPasswordMail, setresetPasswordMail] = useState("");
  const [passwordToggler, setpasswordToggler] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disableDBConfig, setDisableDBConfig] = useState(false);

  const resetPasswordHandler = () => {
    setpasswordToggler((prevState) => !prevState);
  };
  const [togglers, setTogglers] = useState([
    { name: "Data Vision", state: "active" },
    { name: "Threat Insights", state: "inactive" },
  ]);
  const [logoErrorMessage, setLogoErrorMesage] = useState("");
  let name;
  let conditionFeature = "";
  let routFrom = "";
  let platformSubdomainNameFrom = "";
  if (props.location.state) {
    name = props.location.state.name;
    conditionFeature = props.location.state.features.length ? "exist" : "";
    routFrom = props.location.state.routFrom || "";
    platformSubdomainNameFrom =
      props.location.state.platformSubdomainName || "";
  }

  const resetPassword = () => {
    sendActivationEmail();
  };

  const UpdateDomainArray = (value, index) => {
    let arr = domainsArr;
    arr[index] = value;
    setDomainsArr([...arr]);
  };

  const DomainplusButtonClickHandler = () => {
    setInputNumber((inputNumber) => [...inputNumber, 1]);
    dispatch({ type: "domain", payload: domain });
  };

  const DomainMinusButtonClickHandler = (index) => {
    let inputNumberArr = inputNumber;
    let arr = domainsArr;
    arr.splice(index, 1);
    inputNumberArr.pop();
    setInputNumber([...inputNumberArr]);
    setDomainsArr([...arr]);
  };

  useEffect(() => {
    if (props.location.state) {
      name = props.location.state.name;
      conditionFeature = props.location.state.features.length ? "exist" : "";
      if (props.location.state.routFrom) {
        setTogglers([
          { name: "Data Vision", state: "inactive" },
          { name: "Threat Insights", state: "active" },
        ]);
      }
    }
    async function getFeaturesAndLicence() {
      let response;
      let responseData;
      await Promise.all([
        getService({
          method: "GET",
          url: `${GET_FEATURES}`,
          headers: getAxiosHeaders(true),
        }),
        getService({
          method: "GET",
          url: `${GET_LICENSES}`,
          headers: getAxiosHeaders(true),
        }),
      ]).then((data) => ((response = data[0]), (responseData = data[1])));
      if (response.status === 200 && responseData.status === 200) {
        dispatch({
          type: "featuresData",
          payload: response.data.content,
        });
        dispatch({
          type: "licenceTypeData",
          payload: responseData.data.content,
        });
        dispatch({
          type: "selectedFeatures",
          payload: [
            { licenseTypeId: "None", featureId: response.data.content[0].id },
            { licenseTypeId: "None", featureId: response.data.content[1].id },
            { licenseTypeId: "None", featureId: response.data.content[2].id },
          ],
        });
        dispatch({
          type: "loaded",
          payload: true,
        });
      }
    }
    getFeaturesAndLicence();
    setLoader(true);
  }, []);

  useEffect(() => {
    if (
      queryParam.id !== "createTenant" &&
      queryParam.id !== undefined &&
      tenantSignUpObj.loaded
    ) {
      setDisableDBConfig(true);
      getDetails();
    } else {
      setLoader(false);
    }
  }, [tenantSignUpObj.loaded]);

  const setDefaultValues = (feature) => {
    let index;
    forEach(tenantSignUpObj.selectedFeatures, (item, id) => {
      if (item.featureId === feature.id) {
        index = id;
        return;
      }
    });
    let data = tenantSignUpObj.selectedFeatures;
    if (index !== undefined) {
      if (feature.name === MDR) {
        setMdr(feature.licenseType.id);
      }
      data[index].licenseTypeId = feature.licenseType.id;
      dispatch({
        type: "selectedFeatures",
        payload: data,
      });
    }
  };

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleBackdrop = () => {
    setOpenBackdrop(false);
  };

  async function getDetails() {
    const res = await getTenantDetails(queryParam.id);
    let data = tenantSignUpObj.selectedFeatures;
    if (res && res.id) {
      setTenantId(res.id);
      setLoader(false);
      res.features.map((featureData) => setDefaultValues(featureData));
      if (res.databaseConfiguration === null) {
        dispatch({ type: "tenantName", payload: res.name });
        dispatch({ type: "firstName", payload: res.administrator.firstName });
        dispatch({ type: "lastName", payload: res.administrator.lastName });
        dispatch({ type: "adminEmail", payload: res.administrator.email });
      } else {
        dispatch({ type: "tenantName", payload: res.name });
        dispatch({ type: "firstName", payload: res.administrator.firstName });
        dispatch({ type: "lastName", payload: res.administrator.lastName });
        dispatch({ type: "adminEmail", payload: res.administrator.email });
      }
      dispatch({
        type: DB_CONFIG_ACTION,
        payload: res,
      });
      if (res.windowsDomain) {
        dispatch({ type: "windows_domain", payload: res.windowsDomain });
      }
      if (res.shortName) {
        setShortname(res.shortName);
        dispatch({ type: "shortName", payload: res.shortName });
      }
      dispatch({
        type: "platformSubdomainName",
        payload: res.platformSubdomainName,
      });
      if(!isEmpty(res.logo)) {
        // Your base64 string
        const base64String = res.logo;
        //creates a data URI for the image using the 'base64String'
        const imgSrc = `data:image/png;base64,${base64String}`
        setFile(imgSrc);
        dispatch({
          type: "logo",
          payload: res.logo,
        });
      }
      setresetPasswordMail(res.administrator.email);

      if (
        res.platformSubdomainName !== "" &&
        res.domains.length &&
        res.domains[0].name
      ) {
        if (tenantSignUpObj.domain.indexOf(res.domains[0].name) === -1) {
          dispatch({ type: "domain", payload: res.domains[0].name });
          let arr = [...res.domains];
          setDomainsArr([...arr]);
        } else {
          dispatch({ type: "domain", payload: res.platformSubdomainName });
          let arr = [res.platformSubdomainName];
          setDomainsArr([...arr]);
        }
      }
     
    }
  }

  const [errorObject, setErrorMessage] = useState({
    errorMessage: "",
    successMessage: "",
  });

  //used for error handling and closing the backdrop
  const tenantCreation = useSelector(
    (state) => state.tenantCreate.tenantCreation
  );

  useEffect(() => {
    setOpenBackdrop(false);
    if (tenantCreation.errorMessage) {
      let obj = {
        errorMessage: tenantCreation.errorMessage,
        successMessage: "",
      };
      setErrorMessage(obj);
      reduxDispatch({ type: PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE });
    }
  }, [tenantCreation]);

  const sendActivationEmail = async () => {
    setErrorMessage({
      errorMessage: "",
      successMessage: "",
    });
    const res = await getService({
      method: "POST",
      url: ACTIVATE_ACCOUNT_RESET_CLICK(tenantId),
      headers: getAxiosHeaders(true),
      data: {
        email: resetPasswordMail,
      },
    });
    if (res && res.status === 200) {
      let obj = {
        successMessage: "Password link has been sent successfully.",
        errorMessage: "",
      };
      setErrorMessage({
        ...obj,
      });
    } else {
      let obj = {
        successMessage: "",
        errorMessage: res.response?.data?.errorMessage || SERVER_ERROR_MESSAGE,
      };
      setErrorMessage({
        ...obj,
      });
    }
  };

  const onButtonClick = (event) => {
    event.preventDefault();
    inputFile.current.click();
    handleSnackbarClose();
  };
  const imageHandler = (event) => {
    const requiredFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (event.target.files[0]) {
      if (event.target.files[0].size / 1000 > TENANT_LOGO_SIZE) {
        setLogoErrorMesage(LOGO_SIZE_ERROR_MESSAGE)
        setopenError(true);
      } else if (!requiredFileTypes.includes(event.target.files[0].type)) {
        setLogoErrorMesage(LOGO_FORMAT_ERROR_MESSAGE)
        setopenError(true);
      } else {
        setFile(URL.createObjectURL(event.target.files[0]));
        setfileUpload(event.target.files[0]);
      }
    }
  };

  const [commonDomain, setCommonDomain] = useState("");
  const setDomainName = (name) => {
    if (name) {
      const arr = name.split(".");
      const domain1 = arr[arr.length - 2];
      const domain2 = arr[arr.length - 1];
      if (domain1 && domain2) {
        setCommonDomain(domain1 + "." + domain2);
      } else if (domain1 && !domain2) {
        setCommonDomain(domain1);
      } else if (!domain1 && domain2) {
        setCommonDomain("." + domain2);
      }
    } else {
      setCommonDomain("");
    }
  };

  const [openError, setopenError] = useState(false);
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLogoErrorMesage("");
    setopenError(false);
  };

  const handleFeatures = (event, feature) => {
    let index;
    forEach(tenantSignUpObj.selectedFeatures, (item, id) => {
      if (item.featureId === feature.id.id) {
        index = id;
        return;
      }
    });
    if (feature.id.name === MDR) {
      if (event.target.value !== "None") {
        setMdr(event.target.value);
      } else {
        setMdr("");
        setErrorObj({
          ...errorObj,
          shortName: "",
        });
      }
    }
    let data = tenantSignUpObj.selectedFeatures;
    data[index].licenseTypeId = event.target.value;
    dispatch({
      type: "selectedFeatures",
      payload: data,
    });
  };

  const getFeatureName = (name) => {
    switch (name) {
      case MDR:
        return "Threat Insights";
      case DDV:
        return "Data Vision";
      case RISK:
        return RISK
      default:
        return "";
    }
  };

  const renderFeatures = (features, index) => {
    return (
      <>
        <h3 className={classes.selectionLabel}>
          {getFeatureName(features.name)}
        </h3>
        <select
          className="tenenantsignup_select"
          value={
            (tenantSignUpObj.selectedFeatures[index] &&
              tenantSignUpObj.selectedFeatures[index].licenseTypeId) ||
            "None"
          }
          onChange={(event) => handleFeatures(event, { id: features })}
        >
         
          {tenantSignUpObj.licenceTypeData.map((licenceData) => {
            if (licenceData.name === "None") {
              return <option value="None">{licenceData.name}</option>;
            } else {
              return <option value={licenceData.id}>{licenceData.name}</option>;
            }
          })}
        </select>
      </>
    );
  };

  const validateShortName = (name) => {
    async function getFeaturesAndLicence() {
      if (name !== "" && shortNameRef !== name) {
        const res = await getService({
          method: "GET",
          url: VALIDATE_SHORTNAME(name),
          headers: getAxiosHeaders(true),
          data: {
            email: resetPasswordMail,
          },
        });
        if (res && res.status === 200) {
          if (res.data.isShortNameExist) {
            setErrorObj({
              ...errorObj,
              shortName: "shortName already exists.",
            });
          } else {
            setErrorObj({
              ...errorObj,
              shortName: "",
            });
          }
        }
      }
    }
    getFeaturesAndLicence();
  };

  const noFeaturesSelected = (relation) => {
    const message =
      relation === "create"
        ? "please select any feature to create tenant"
        : "please select any feature to update tenant";
    let obj = {
      successMessage: "",
      errorMessage: message,
    };
    setErrorMessage({
      ...obj,
    });
  };

  const handleToggle = () => {};

  return (
    <div>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {logoErrorMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        onClick={handleBackdrop}
      >
        <img src={Page_Loader} alt="_Loader" className="loader" />
      </Backdrop>
      <SnackBar2
        failure={errorObject.errorMessage ? true : false}
        success={errorObject.successMessage ? true : false}
        errorObject={errorObject}
        successMessage={errorObject.successMessage}
        errorMessage={errorObject.errorMessage}
      ></SnackBar2>
      <div
        className={privacyPolicy || license ? "signupPageBlur" : "signUpPage"}
      >
        {loader && (
          <div className="global-loader-holder">
            <img src={Page_Loader} alt="_Loader" className="loader" />
          </div>
        )}
        <SignupPageBackGround
          headerName="Tenant Management"
          header={tenantId ? tenantSignUpObj.tenantName : "Tenant Onboarding"}
          breadcrumbValue={
            tenantId !== ""
              ? [
                  {
                    value: "Tenant Management",
                    link: "/platform/tenantManagement",
                  },
                  { value: "Edit", link: "" },
                ]
              : isCreate
              ? [
                  {
                    value: "Tenant Management",
                    link: "/platform/tenantManagement",
                  },
                  { value: "Create Tenant", link: "" },
                ]
              : ""
          }
        ></SignupPageBackGround>
        <form>
          <div className="togglerandSearch_Conatiner">
            {isCreate === false && conditionFeature !== "" && (
              <Toggler
                togglers={togglers}
                setTogglers={setTogglers}
                handleToggle={handleToggle}
              ></Toggler>
            )}
          </div>
          {togglers[0].state === "active" && (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.signUpGrid_container}
            >
              <Grid item lg={3} md={4} sm={4}>
                <h1 className="gridHeaders">Tenant Details</h1>
                <Input
                  type="text"
                  required={true}
                  labelName="Tenant Name"
                 
                  labelClassName={classes.label}
                  value={tenantSignUpObj.tenantName}
                  containerName="signUpconatainer"
                  classname={classes.input}
                  inputProps={{ maxLength: 3, minLength: 3 }}
                  eventHandler={(value) => {
                    setErrorObj({
                      ...errorObj,
                      tenantName: "",
                    });
                    dispatch({ type: "tenantName", payload: value });
                  }}
                ></Input>
                {errorObj.tenantName ? (
                  <div style={{ color: "red", marginTop: "20px" }}>
                    {errorObj.tenantName}
                  </div>
                ) : (
                  ""
                )}
                

                {inputNumber.map((val, index) => (
                  <div className={classes.flex}>
                    <Input
                      type="text"
                      labelName="Domain"
                      required={true}
                      labelClassName={classes.label}
                      containerName={classes.adminSignUp}
                      classname={classes.domain}
                      value={
                        domainsArr[index] && domainsArr[index].name
                          ? domainsArr[index].name
                          : domainsArr[index]
                      }
                      eventHandler={(value) => {
                        dispatch({ type: "domain", payload: value });
                        setErrorObj({
                          ...errorObj,
                          Domain: "",
                        });
                        setDomain(value);
                        UpdateDomainArray(value, index);
                      }}
                    ></Input>

                    {inputNumber.length - 1 === index ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.plus}
                        onClick={DomainplusButtonClickHandler}
                      >
                        +
                      </Button>
                    ) : null}
                    {index !== 0 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.plus}
                        onClick={() => DomainMinusButtonClickHandler(index)}
                      >
                        -
                      </Button>
                    ) : null}
                  </div>
                ))}
                {errorObj.Domain ? (
                  <div
                    style={{
                      color: "red",
                      marginTop: "15px",
                      display: "flex",
                    }}
                  >
                    {errorObj.Domain}
                  </div>
                ) : (
                  ""
                )}
                {mdr !== "" && (
                  <Input
                    type="text"
                    labelName="Short Name"
                    required={true}
                    labelClassName={classes.label}
                    containerName="signUpconatainer"
                    value={tenantSignUpObj.shortName}
                    classname={classes.input}
                   
                    eventHandler={(value) => {
                      validateShortName(value);
                      dispatch({ type: "shortName", payload: value });
                     
                    }}
                  ></Input>
                )}
                {errorObj.shortName ? (
                  <div style={{ color: "red", marginTop: "20px" }}>
                    {errorObj.shortName}
                  </div>
                ) : (
                  ""
                )}
                <Input
                  type="text"
                  labelName="Windows Domain"
                  labelClassName={classes.label}
                  containerName="signUpconatainer"
                  value={tenantSignUpObj.windows_domain}
                  classname={classes.input}
                 
                  eventHandler={(value) => {
                    dispatch({ type: "windows_domain", payload: value });
                   
                  }}
                ></Input>
                <CreateTenantDBConfig
                  disableDBConfig={disableDBConfig}
                  currentConfig={tenantSignUpObj}
                  dispatcher={(payload) =>
                    dispatch({ type: DB_CONFIG_ACTION, payload })
                  }
                  setErrorObj={setErrorObj}
                  errorObj={errorObj}
                />
              </Grid>

              <Grid item lg={3} md={4} sm={4} style={{ marginLeft: "6rem" }}>
                <h1 className="gridHeaders" style={{ width: "15rem" }}>
                  Select Features & Licenses
                </h1>
                <div className={classes.tenenantsignup_select_container}>
                  {tenantSignUpObj.featuresData.map((feature, index) =>
                    renderFeatures(feature, index)
                  )}
                </div>
                {tenantSignUpObj.platformSubdomainName !== "" && (
                  <div className="editUser_resetPasswordFlex">
                    <h3 style={{ marginRight: "4rem" }}>Reset Password</h3>
                    <img
                      src={resetPasswordIcon}
                      alt="ResetPassword"
                      onClick={resetPasswordHandler}
                    ></img>
                  </div>
                )}
                <Grid
                  item
                  xs={12}
                  xl={12}
                  className={passwordToggler ? `` : "hide"}
                  style={{ marginTop: "20px" }}
                >
                  <div>Tenant Admin Email:</div>
                  <input
                    type="text"
                    value={resetPasswordMail}
                    onChange={(event) => {
                     
                      setresetPasswordMail(event.target.value);
                    }}
                    className="randomStyle"
                  ></input>
                  <br />
                  <br />
                  <SendLink
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => resetPassword()}
                    className="min-margin"
                  >
                    Send Link
                  </SendLink>
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={4} style={{ marginLeft: "6rem" }}>
                <h1 className="gridHeaders" style={{ width: "12rem" }}>
                  Tenant Details
                </h1>
                <Input
                  type="text"
                  labelName="First Name"
                  labelClassName={classes.label}
                 
                  containerName="signUpconatainer"
                  value={tenantSignUpObj.firstName}
                  classname={classes.input}
                  eventHandler={(value) =>
                    dispatch({ type: "firstName", payload: value })
                  }
                ></Input>
                <Input
                  type="text"
                  labelName="Last Name"
                  labelClassName={classes.label}
                  
                  value={tenantSignUpObj.lastName}
                  containerName="signUpconatainer"
                  classname={classes.input}
                  eventHandler={(value) =>
                    dispatch({ type: "lastName", payload: value })
                  }
                ></Input>
                <Input
                  type="text"
                  labelName="Email"
                  required={true}
                  labelClassName={classes.label}
                 
                  value={tenantSignUpObj.adminEmail}
                  containerName="signUpconatainer"
                  classname={classes.input}
                  eventHandler={(value) => {
                    dispatch({ type: "adminEmail", payload: value });
                    setErrorObj({
                      ...errorObj,
                      email: "",
                    });
                  }}
                ></Input>
                {errorObj.email ? (
                  <div
                    style={{
                      color: "red",
                      marginTop: "15px",
                      display: "flex",
                    }}
                  >
                    {errorObj.email}
                  </div>
                ) : (
                  ""
                )}
                <p style={{ marginTop: "60px" }}>Tenant Logo</p>
                <div className="imageUploadContainer">
                  <input
                    type="file"
                    id="img"
                    name="img"
                    accept="image/*"
                    onChange={imageHandler}
                    ref={inputFile}
                    className="img"
                  />
                  <button
                    type="file"
                    className="imgButton"
                    onClick={onButtonClick}
                  >
                    Browse
                  </button>
                  {file ? (
                    <img
                      className="signUp_uploadedImage"
                      src={file}
                      alt="Preview"
                      onClick={onButtonClick}
                    />
                  ) : null}
                </div>
              </Grid>

              <SignUpPageFooter
                handleBackdrop={handleBackdrop}
                classname="tenantSignUpFooter"
                popClassName="tenantPopup"
               
                platformAdminTenantOnBoard={isCreate}
                signUpObj={{
                  ...tenantSignUpObj,
                  features: filter(tenantSignUpObj.selectedFeatures, (item) => {
                    if (item.licenseTypeId !== "None") {
                      return item;
                    }
                  }),
                }}
                signUpactive={signUpactive}
                makeSignUpAcitive={makeSignUpAcitive}
                privacyPolicy={privacyPolicy}
                setprivacyPolicy={setprivacyPolicy}
                license={license}
                setLicense={setLicense}
                updateTenantId={tenantId}
                fileUpload={fileUpload}
                commonDomain={commonDomain}
                domainsArr={domainsArr}
                createTenant
                setErrorObj={setErrorObj}
                errorObj={errorObj}
                resetPasswordMail={resetPasswordMail}
                passwordToggler={passwordToggler}
                noFeaturesSelected={noFeaturesSelected}
                mdr={mdr}
                setOpenBackdrop={setOpenBackdrop}
              ></SignUpPageFooter>
            </Grid>
          )}
        </form>
      </div>
      {togglers[0].state === "active" && (
        <div
          className={
            privacyPolicy || license
              ? "signupPageBlur-visible"
              : "signupPageBlur-hidden"
          }
        ></div>
      )}
      {togglers[1].state === "active" && (
        <ViewTenantConcentratorDetails
          id={queryParam.id}
          name={name}
          routFrom={routFrom}
          platformSubdomainNameFrom={platformSubdomainNameFrom}
        ></ViewTenantConcentratorDetails>
      )}
    </div>
  );
};

export default TenenantSignUp;