import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { QueryRenderer } from "@cubejs-client/react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import { renderSingleValue } from "./utils";
import { isUndefined } from "lodash";

const useStyles = makeStyles({
  root: {
    minWidth: "24rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  chartWrapperHidden: {
    display: "none",
  },
});

export const StyledCardHeader = styled(({ ...other }) => (
  <CardHeader {...other} />
))`
  & .MuiCardHeader-action {
    /* display: hidden !important; */
    visibility: hidden;
  }
  & .MuiCardHeader-content {
    flex: 1 1 auto;
    width: 100%;
  }
  & .MuiTypography-h5 {
    font-size: 1rem;
    font-weight: bold
  }
  & .MuiCardHeader-action {
    /* display: hidden !important; */
    visibility: hidden;
  }
`;

const Chart = ({
  cubejsApi,
  title,
  query,
  countQuery,
  render,
  openEditModal,
  redirectTo,
  userRole
}) => {
  const classes = useStyles();
  let history = useHistory();
  const showIcon =
    localStorage.getItem("user_role").includes("Administrator")  ? true : false;
  let arg = {};
  if (showIcon) {
    arg.action = (
      <>
        <IconButton onClick={openEditModal} aria-label="settings">
          <EditIcon />
        </IconButton>
      </>
    );
  }
  const cardClassname = () => {
    if (userRole) {
      return 'user-role-widget';
    } else if (redirectTo) {
      return 'widget-styles';
    } else {
      return null;
    }
  };

  const renderTitle = () => {
    if (!isUndefined(countQuery)) {
      return (
        <div className="barChartRiskContainer">
        <div>
          <div className="totalRiskHeading">
            <div>
              <h3>{title}:</h3>
            </div>
            <div>
              <span>
                <QueryRenderer
                  query={countQuery}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) {
                      return <div className="loader" />;
                    }
                    return renderSingleValue(resultSet, countQuery.measures[0]);
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      );
    } else {
      return (
        <>
          <StyledCardHeader {...arg} title={title} />
        </>
      );
    }
  };

  return (
    <Card 
    className={cardClassname()}
    onClick={() =>{ redirectTo && history.push(redirectTo)}}>
      {userRole ? null : renderTitle()}
      <CardContent>
        <Typography variant="body2" component="p">
          <QueryRenderer
            query={query}
            cubejsApi={cubejsApi}
            render={({ resultSet, error }) => {
              if (error) {
                if (error.message === "TokenExpiredError: jwt expired") {
                  if (window.location.host.indexOf("localhost") !== -1) {
                    window.location.replace(
                      `http://localhost:3000/?tenant=${localStorage.getItem(
                        "tenant"
                      )}`
                    );
                  } else {
                    window.location.replace(`http://${window.location.host}/`);
                  }
                  localStorage.clear();
                  sessionStorage.clear();
                }
              }
              if (!resultSet) {
                return (
                  <div className="loader" style={{ height: "300px" }}>
                    Loading chart......
                  </div>
                );
              }

              return render(resultSet);
            }}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default React.memo(Chart);
