import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {
  MDRIpConfigLoc,
  MDRAccountIdConfigLoc,
} from "../../../redux/constants/constants";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ImageTooltip from "../../common/HelpIconWithTooltip";
import DeleteIcon from "../../../assets/vizr_images/ic_rubbish_bin.png";
import {
  DeleteButtonForToolBar,
  ColoredActionButton,
  NewPolicyButtonForPolicyCreateEdit,
} from "../../common/buttons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const history = useHistory();
  const {
    totalElements,
    loc,
    alertsTitle,
    helpDescription,
    rows,
    selectedList,
    deleteHandler,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const dispatch = useDispatch();

  const handleClick = (event, newPlacement) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    //disabling tool bar buttons if no data for table present
    if (rows && rows.length > 0 && selectedList && selectedList.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [rows, selectedList]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const deleteSubmit = () => {
    deleteHandler();
    handleClose();
  };
  const renderHeaderTitle = () => {
    return (
      <Grid style={{ display: "flex" }} item xs={6}>
        <Typography
          className="title"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {alertsTitle}
        </Typography>
        <p style={{ margin: "11px 5px 0px 0px", minWidth: "45px" }}>Total:</p>
        <div className="count">{totalElements}</div>

        <ImageTooltip description={helpDescription} />
      </Grid>
    );
  };

  const renderButtons = () => {
    return (
      <Grid item xs={6} style={{ float: "right", textAlign: "right" }}>
        <DeleteButtonForToolBar
          variant="contained"
          id="delete"
          size="small"
          color="primary"
          disabled={disableButton}
          onClick={() => handleClickOpen()}
          className="min-margin table-buttons"
        >
          <img
            style={{ width: "24px", height: "22px" }}
            src={DeleteIcon}
            alt="delete"
          />
        </DeleteButtonForToolBar>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            color: "rgb(255, 255, 255)",
            backgroundColor: "rgb(128, 20, 189)",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            padding: "4px 30px",
            boxShadow: "0 5px 8px -3px rgba(1, 18, 121, 0.5)",
            cursor: "pointer",
            borderRadius: "6px",
            lineHeight: "1.5",
          }}
          onClick={() => {
            if (loc === MDRIpConfigLoc) {
              dispatch({
                type: "MDR_SETTINGS_UPDATE_LOCATION",
                payload: loc,
              });

              history.push({
                pathname: "/mdr/Settings/ipConfiguration",
                // state: data,
              });
            } else if (loc === MDRAccountIdConfigLoc) {
              dispatch({
                type: "MDR_SETTINGS_UPDATE_LOCATION",
                payload: loc,
              });
              history.push({
                pathname: "/mdr/Settings/accountIdConfiguration",
                // state: data,
              });
            }
          }}
          startIcon={<AddCircleOutlineIcon />}
          className="min-margin table-buttons"
        >
          {loc === MDRIpConfigLoc && <span> Add IP/Subnet</span>}
          {loc === MDRAccountIdConfigLoc && <span> Add Account ID</span>}
        </Button>

        {/* {renderPopover()} */}
      </Grid>
    );
  };

  const renderGrid = () => {
    return (
      <Grid container spacing={3}>
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          {(loc === MDRIpConfigLoc || loc === "MDRAccountIdConfigLoc") && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deleting these configurations means they will be unavailable for
                you and your team.
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <ColoredActionButton
              style={{
                width: "124px",
                height: "32px",
                boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
              }}
              onClick={handleClose}
            >
              Cancel
            </ColoredActionButton>
            <NewPolicyButtonForPolicyCreateEdit
              style={{
                marginBottom: "0px",
                cursor: "pointer",
                width: "124px",
                height: "32px",
              }}
              onClick={() => deleteSubmit()}

              //autoFocus
            >
              Confirm
            </NewPolicyButtonForPolicyCreateEdit>
          </DialogActions>
        </Dialog>
        {renderHeaderTitle()}
        {renderButtons()}
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          {renderGrid()}
        </Grid>
      </Toolbar>
    </Paper>
  );
};

EnhancedTableToolbar.propTypes = {
  totalElements: PropTypes.number.isRequired,
};
