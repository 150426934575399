import React, { useState, useEffect, useContext } from "react";
import { getCubejsApi, redirectToLogin } from "../cubejs-util";
import { useSelector, useDispatch } from "react-redux";
import EnhancedTable from "../../../shared/alertsTable";
import { MDRapiCall } from "../utils";

import {
  MDRIncidentTable,
  MDRAuthenticationTable,
  endPointsHeadCells,
  headCellsForDistinctUsers,
  headCellsForDistinctIPs,
  MDRpatchMonitoringHeadCells,
} from "../../headCells";
import {
  AutheticationTableQuery,
  AutheticationTableQuery_UniqueUsers,
  AutheticationTableQuery_UniqueIPs,
  tableQuery as IncidentTableQueryData,
  MDREndpointsQuery,
  MDRPatchMonitoringQuery,
} from "../../../common/Dashboard/dashboard-data";
import { CustomUseEffectHook } from "../../../views/CustomUseEffectHook";
import {
  FailedLoginsAllUsers,
  FailedLoginsIPbyDistinctUsers,
  FailedLoginsIPbyDistinctIP,
} from "../../../../redux/constants/constants";
import { cloneDeep } from "lodash";

import Loader from "../../../../assets/vizr_images/page_loader.gif";
import { addingDateRangetoTableQueries } from "../MDRHelpers";
import { MDRContext } from "../../../views/DashboardMDR";
const TableDataUpdateComponent = ({ globalFiltersIntialList }) => {
  const dispatch = useDispatch();
  let cubejsApi = getCubejsApi();

  const mdrContext = useContext(MDRContext);
  const incidentTableData = useSelector(
    (state) => state.IncidenetTableFilterReducer.incidentTableData
  );
  console.log("incidentTableData", incidentTableData);
  const incidentTableRowCount = useSelector(
    (state) => state.IncidenetTableFilterReducer.incidentTableRowCount
  );
  const MDRDropdownValue = useSelector(
    (state) => state.IncidenetReducer.MDRDropdownValue
  );

  const mdrauthenticationTableDropDownvalue = useSelector(
    (state) =>
      state.MDRAuthenticationReducer.mdrauthenticationTableDropDownvalue
  );

  const autheticationTableData = useSelector(
    (state) => state.MDRAuthenticationReducer.autheticationTableData
  );
  const authenticationTableTotalRows = useSelector(
    (state) => state.MDRAuthenticationReducer.authenticationTableTotalRows
  );

  const autheticationLoc = useSelector(
    (state) => state.MDRAuthenticationReducer.autheticationLoc
  );

  const endPointsTableData = useSelector(
    (state) => state.MDREndPointsFilterReducer.endPointsTableData
  );
  const totalRowsEndpoints = useSelector(
    (state) => state.MDREndPointsFilterReducer.totalRowsEndpoints
  );
  const patchMonitoringTableData = useSelector(
    (state) => state.MDRPatchMonitoringReducer.patchMonitoringTableData
  );
  const totalRowsPatchMonitoring = useSelector(
    (state) => state.MDRPatchMonitoringReducer.totalRowsPatchMonitoring
  );

  const [tableLocation, setTableLocation] = React.useState("");

  const [headCells, setHeadCells] = React.useState([...MDRIncidentTable]);

  const [tableQuerys, setTableQuery] = React.useState([]);

  // const [dataLoaded, setDataLoaded] = useState(false);

  const initializeView = () => {
    switch (MDRDropdownValue) {
      case "Incidents":
        setHeadCells([...MDRIncidentTable]);
        setTableLocation("MDRDashboard_IncidentTable");
        IncidentTableQueryData[0].query[0].filters = [];
        IncidentTableQueryData[0].query[1].filters = [];
        setTableQuery([...IncidentTableQueryData]);

        break;
      case "Authentication":
        setHeadCells([...MDRAuthenticationTable]);
        setTableLocation("MDRDashboard_AuthenticationTable_AllUsers");
        setTableQuery([...AutheticationTableQuery]);
        break;
      case "Endpoints":
        setHeadCells([...endPointsHeadCells]);
        setTableLocation("MDRDashboard_EndPointsTable");
        setTableQuery([...MDREndpointsQuery]);
        break;
      case "Patch Monitoring":
        setHeadCells([...MDRpatchMonitoringHeadCells]);
        setTableLocation("MDRDashboard_PatchMonitoring");
        setTableQuery([...MDRPatchMonitoringQuery]);
        break;
      default:
    }
  }

  CustomUseEffectHook(() => {
    initializeView();
  }, [MDRDropdownValue]);

  useEffect(()=>{
    initializeView();
  },[]);

  //Used to execute when dropdown value changes in AuthenticationTable

  CustomUseEffectHook(() => {
    if (mdrauthenticationTableDropDownvalue === FailedLoginsAllUsers) {
      setHeadCells([...MDRAuthenticationTable]);
      dispatch({
        type: "CHANGE_MDRAUTHENTICATION_TABLE_QUERY",
        payload: cloneDeep(AutheticationTableQuery),
      });
      setTableQuery([...AutheticationTableQuery]);
    } else if (
      mdrauthenticationTableDropDownvalue === FailedLoginsIPbyDistinctUsers
    ) {
      setHeadCells([...headCellsForDistinctUsers]);
      dispatch({
        type: "CHANGE_MDRAUTHENTICATION_TABLE_QUERY",
        payload: cloneDeep(AutheticationTableQuery_UniqueUsers),
      });
      setTableQuery([...AutheticationTableQuery_UniqueUsers]);
    } else if (
      mdrauthenticationTableDropDownvalue === FailedLoginsIPbyDistinctIP
    ) {
      setHeadCells([...headCellsForDistinctIPs]);
      dispatch({
        type: "CHANGE_MDRAUTHENTICATION_TABLE_QUERY",
        payload: cloneDeep(AutheticationTableQuery_UniqueIPs),
      });
      setTableQuery([...AutheticationTableQuery_UniqueIPs]);
    }
  }, [mdrauthenticationTableDropDownvalue]);

  CustomUseEffectHook(() => {
    if (MDRDropdownValue === "Authentication") {
      setTableLocation(autheticationLoc);
    }
  }, [autheticationLoc]);

  const dateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );

  const IncidenttableQuery = useSelector(
    (state) => state.IncidenetTableFilterReducer.tableQuery
  );
  CustomUseEffectHook(() => {
    mdrContext.setDataLoaded(false);
    setTableQuery([...IncidenttableQuery]);
  }, [IncidenttableQuery]);

  const authenticationTableQuery = useSelector(
    (state) => state.MDRAuthenticationReducer.authenticationTableQuery
  );

  CustomUseEffectHook(() => {
    mdrContext.setDataLoaded(false);
    setTableQuery([...authenticationTableQuery]);
  }, [authenticationTableQuery]);

  const endPointsTableQuery = useSelector(
    (state) => state.MDREndPointsFilterReducer.endPointsTableQuery
  );

  CustomUseEffectHook(() => {
    mdrContext.setDataLoaded(false);
    setTableQuery([...endPointsTableQuery]);
  }, [endPointsTableQuery]);

  const patchMonitoringTableQuery = useSelector(
    (state) => state.MDRPatchMonitoringReducer.patchMonitoringTableQuery
  );

  CustomUseEffectHook(() => {
    mdrContext.setDataLoaded(false);
    setTableQuery([...patchMonitoringTableQuery]);
  }, [patchMonitoringTableQuery]);

  CustomUseEffectHook(() => {
    if (tableQuerys.length) {
      MDRapiCall(
        mdrContext.setDataLoaded,
        dateTimeRange,
        tableQuerys,
        dispatch
      );
    }
  }, [tableQuerys, tableQuerys[0]]);

  CustomUseEffectHook(() => {
    let dateRangeChanged = true;
    if (tableQuerys.length) {
      MDRapiCall(
        mdrContext.setDataLoaded,
        dateTimeRange,
        tableQuerys,
        dispatch,
        dateRangeChanged
      );
    }
  }, [dateTimeRange]);

  useEffect(() => {
    console.log("loadingload");
    let asyncFunc = async () => {
      mdrContext.setDataLoaded(false);
      if (MDRDropdownValue === "Patch Monitoring") {
        let tableQuerys = patchMonitoringTableQuery;
        let loc = tableQuerys[0].loc;
        let filters = tableQuerys[0].query[0].filters;
        let tableNameArr = tableQuerys[0].query[0].dimensions
          ? tableQuerys[0].query[0].dimensions[0]
          : "";
        let tableName = tableNameArr && tableNameArr.split(".")[0];
        let singleFilterValuesObj = [];
        let gFilters = JSON.parse(
          sessionStorage.getItem("globalFiltersIntialList")
        );
        // let gFilters = globalFiltersIntialList;
        gFilters &&
          gFilters.length &&
          gFilters.forEach((el, index) => {
            // idArr.push(el["id"]);
            singleFilterValuesObj.push({
              id: el["id"] ? el["id"] : "",
              value: el["columnValue"],
              type: el["type"],
              name: el["columnName"],
            });
          });
        if (!filters || filters.length === 0) {
          let timeFil = addingDateRangetoTableQueries(
            tableQuerys,
            dateTimeRange
          );
          let filterArr = [...timeFil];
          singleFilterValuesObj.forEach((obj) => {
            if (obj["type"] === "include") {
              let bool = false;

              filterArr.forEach((el, index) => {
                if (el["member"] === `${tableName}.${obj["name"]}`) {
                  filterArr[index]["values"].push(`${obj["value"]}`);
                  bool = true;
                }
              });
              if (!bool) {
                filterArr.push({
                  member: `${tableName}.${obj["name"]}`,
                  operator: "equals",
                  values: [`${obj["value"]}`],
                });
              }
            }
            if (obj["type"] === "exclude") {
              let bool = false;

              filterArr.forEach((el, index) => {
                if (
                  el["member"] === `${tableName}.${tableName}.${obj["name"]}`
                ) {
                  filterArr[index]["values"].push(`${obj["value"]}`);
                  bool = true;
                }
              });
              if (!bool) {
                filterArr.push({
                  member: `${tableName}.${obj["name"]}`,
                  operator: "notEquals",
                  values: [`${obj["value"]}`],
                });
              }
            }
          });
          tableQuerys[0].query[0].filters = filterArr;
          tableQuerys[0].query[1].filters = filterArr;
        } else {
          // let curFilters = [...filters];
          let timeFil = addingDateRangetoTableQueries(
            tableQuerys,
            dateTimeRange
          );
          let filterArr = [];

          singleFilterValuesObj.forEach((obj) => {
            if (obj["type"] === "include") {
              let bool = false;

              filterArr.forEach((el, index) => {
                console.log(el["values"], "testing");
                if (el["member"] === `${tableName}.${obj["name"]}`) {
                  if (!el["values"].includes(`${obj["value"]}`)) {
                    filterArr[index]["values"].push(`${obj["value"]}`);
                  }
                  // filterArr[index]["values"].push(`${obj["value"]}`);
                  bool = true;
                }
              });
              if (!bool) {
                filterArr.push({
                  member: `${tableName}.${obj["name"]}`,
                  operator: "equals",
                  values: [`${obj["value"]}`],
                });
              }
            }
            if (obj["type"] === "exclude") {
              let bool = false;

              filterArr.forEach((el, index) => {
                console.log(el["values"], "testing");
                if (el["member"] === `${tableName}.${obj["name"]}`) {
                  if (!el["values"].includes(`${obj["value"]}`)) {
                    filterArr[index]["values"].push(`${obj["value"]}`);
                  }
                  // filterArr[index]["values"].push(`${obj["value"]}`);
                  bool = true;
                }
              });
              if (!bool) {
                filterArr.push({
                  member: `${tableName}.${obj["name"]}`,
                  operator: "notEquals",
                  values: [`${obj["value"]}`],
                });
              }
            }
          });

          tableQuerys[0].query[0].filters = [...filterArr, ...timeFil];
          tableQuerys[0].query[1].filters = [...filterArr, ...timeFil];
        }
        let response;
        let response2;
        try {
          const resultSet = await cubejsApi.load(tableQuerys[0].query[1]);
          response = resultSet.rawData();
        } catch (error) {
          redirectToLogin(error);
        }
        try {
          const resultSet2 = await cubejsApi.load(tableQuerys[0].query[0]);
          response2 = resultSet2.rawData();
        } catch (error) {
          redirectToLogin(error);
        }
        mdrContext.setDataLoaded(true);
        dispatch({
          type: "CHANGE_PATCHMONITORING_TABLE_DATA",
          payload: {
            data: response2,
            loc: loc,
            totalTableDataCount:
              response[0][`${tableQuerys[0].queryCall}.count`],
            dateRangeChanged: true,
          },
        });
      }
    };
    asyncFunc();
  }, [globalFiltersIntialList, MDRDropdownValue]);

  return (
    <div className="main-dashboard-container">
      {MDRDropdownValue !== "Risk View" && !mdrContext.dataLoaded && (
        <div
          className="loader"
          style={{
            position: "absolute",
            left: "50%",
            paddingTop: "5rem",
            zIndex: "10",
          }}
        >
          <img src={Loader} alt="loader"></img>
        </div>
      )}
      {MDRDropdownValue === "Incidents" ? (
        <EnhancedTable
          tableData={incidentTableData.length > 0 ? incidentTableData : []}
          headCells={headCells}
          check={true}
          loc={tableLocation}
          project="MDR"
          totalElements={incidentTableRowCount ? incidentTableRowCount : 0}
        />
      ) : MDRDropdownValue === "Authentication" ? (
        <EnhancedTable
          tableData={
            autheticationTableData && autheticationTableData.length > 0
              ? autheticationTableData
              : []
          }
          headCells={headCells}
          check={true}
          loc={tableLocation}
          project="MDR"
          totalElements={
            authenticationTableTotalRows ? authenticationTableTotalRows : 0
          }
        />
      ) : MDRDropdownValue === "Endpoints" ? (
        <EnhancedTable
          tableData={
            endPointsTableData && endPointsTableData.length > 0
              ? endPointsTableData
              : []
          }
          headCells={headCells}
          check={true}
          loc={tableLocation}
          project="MDR"
          totalElements={totalRowsEndpoints ? totalRowsEndpoints : 0}
        />
      ) : MDRDropdownValue === "Patch Monitoring" ? (
        <EnhancedTable
          tableData={
            patchMonitoringTableData && patchMonitoringTableData.length > 0
              ? patchMonitoringTableData
              : []
          }
          headCells={headCells}
          check={true}
          loc={tableLocation}
          project="MDR"
          totalElements={
            totalRowsPatchMonitoring ? totalRowsPatchMonitoring : 0
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default TableDataUpdateComponent;
