import React, { useState, useEffect } from "react";
import {
  GET_TEST_CONNECTION_URL,
  VERIFY_AZURE_LOG_SOURCE,
  GET_LOG_SOURCE_TYPE_DETAILS,
  CLEAR_LOG_SOURCE_DATA,
  VERIFY_LOG_SOURCES,
  VERIFY_AWS_LOG_SOURCE,
  SAVE_LOG_SOURCE_DETAILS,
  AZURE_EVENT_HUB,
  AMAZON_RDS_SQLSERVER,
  REGIONS,
  REGION_ERROR_MESSAGE,
  AMAZON_RDS_POSTGRESQL,
} from "../../../redux/constants/constants";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import Snackbar from "@material-ui/core/Snackbar";
import {Chip} from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { applicationNameValidation, isAWSFileSystem } from "../../common/utilities";
import {azureFormData} from "./azureFieldsData.js";
import {awsFormData} from "./awsFieldsData.js";
import {sqlFormData} from "./sqlFieldsData.js";
import DynamicFormDataCreator from "./DynamicFormdataCreator";

export const CreateLogSource = (props) => {
  const sourceLoader = useSelector((state) => state.CreateSourceReducer.loader);
  const sourcesData = useSelector((state) => state.CreateSourceReducer.logSourceData);
  const isLogSourceVerified = useSelector((state) => state.CreateSourceReducer.logSourceVerified);
  const isLogSourceSaved = useSelector((state) => state.CreateSourceReducer.isLogSourceSaved);
  const sourceErrorMessage = useSelector((state) => state.CreateSourceReducer.errorMessage);
  const [engine, setDesiredEngine] = useState("");
  const [types, setTypes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [applications, setApplications] = useState([]);
  const [applicationName, setApplicationName] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [allRegions, setAllRegions] = useState([]);
  const [region, setRegion] = useState("");
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [toggleCloseEvent, setToggleCloseEvent] = useState(null);
  const [applicationError, setApplicationError] = useState(null);
  const [regionError, setRegionError] = useState(null);
  const dispatch = useDispatch();


  useEffect(() => {
    setLoader(sourceLoader);
  },[sourceLoader])

  useEffect(() => {
    if (sourcesData) {
      setTypes(sourcesData.types);
      setDesiredEngine(sourcesData.engine);
      setApplications(sourcesData.applications);
      setApplicationName(sourcesData.applicationName);
      setAllRegions(sourcesData.allRegions);
      setRegion(sourcesData.allRegions[0]);
    }
  }, [sourcesData]);

  useEffect(() => {
    return () => {
      dispatch({type: CLEAR_LOG_SOURCE_DATA})
      setLoader(false);
    };
  },[]);

  useEffect(() => {
    if (isLogSourceSaved) {
      if (props.loc !== "dataSource") {
        props.getServiceData();
        props.toggleDrawer(false, toggleCloseEvent);
      } else {
        props.setLoadDataSourceData(true);
        props.setLogSourceOpen(false);
      }
    } else {
      if (!isEmpty(sourceErrorMessage)) {
        setSnackBarMessage(sourceErrorMessage);
        setOpen(true);
      }
    }
  }, [isLogSourceSaved, sourceErrorMessage]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const handleVerificationAPI = () => {
    if (engine === AZURE_EVENT_HUB) {
      return VERIFY_AZURE_LOG_SOURCE;
    } else if (isAWSFileSystem(engine)) {
      return VERIFY_AWS_LOG_SOURCE;
    }
    else {
      return GET_TEST_CONNECTION_URL;
    }
  };

  const handleVerification = (formData) => {
    let request;
    if (engine === AZURE_EVENT_HUB) {
      request = {
        eventHubConnectionString: formData.eventHubConnection,
        storageAccountConnectionString: formData.storageAccountConnection,
        storageContainerName: formData.storageContainerName,
      };
    } else if (isAWSFileSystem(engine)) {
      request = {
        accessId: formData.accessKey,
        accessSecret: formData.secretKey,
        databaseName: formData.databaseName,
        region: region,
      };
    } else {
      request = {
        username: formData.username,
        password: formData.password,
        desiredPort: parseInt(formData.port),
        databaseName: formData.databaseName,
        engine: engine,
        connectionProperties: formData.connectionUrl,
        desiredHost: formData.address,
        discoveredHost: formData.address,
        instance: formData.instance,
      };
    }
  
    dispatch({
      type: VERIFY_LOG_SOURCES,
      payload: { url: handleVerificationAPI(), request: request },
    });
  };
  

  useEffect(() => {
    dispatch({type: GET_LOG_SOURCE_TYPE_DETAILS});
  },[])

  const handleSave = (event, data) => {
    let request;
  
    if (engine === AZURE_EVENT_HUB) {
      request = {
        sourceType: { code: engine },
        name: data.Name,
        region: data.region,
        connectionProperties: data.storageAccountConnection,
        accessSecret: data.eventHubConnection,
        dataLocation: data.storageContainerName,
      };
    } else if (isAWSFileSystem(engine)) {
      request = {
        sourceType: { code: engine },
        name: data.Name,
        applicationName: applicationName,
        accessId: data.accessKey,
        accessSecret: data.secretKey,
        databaseName: data.databaseName,
        dataLocation: data.dataLocation,
        region: region,
      };
    } else {
      request = {
        sourceType: { code: engine },
        name: data.Name,
        applicationName: applicationName,
        databaseName: data.databaseName,
        host: data.address,
        port: parseInt(data.port),
        accessId: data.username,
        accessSecret: data.password,
        connectionProperties: data.connectionUrl,
        dataLocation: data.networkPath,
        instance: data.instance,
        archiveLocation: data.archiveLocation,
      };
    } 

    dispatch({
      type: SAVE_LOG_SOURCE_DETAILS,
      payload: request,
    });
    setToggleCloseEvent(event);
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const handleEngineChange = (newEngine) => {
    setDesiredEngine(newEngine)
  }

  const handleToggleDrawerClose = (event) => {
    props.toggleDrawer(false, event)
  }

  const handleTypeChange = (value, field) => {
      handleEngineChange(value);
  }
  
  const handleAutocompleteInput = (value, field) => {
    if(isAWSFileSystem(engine) && field.name === REGIONS) {
      setRegion(value);
    } else {
      setApplicationName(value);
    }
  }

  const renderTypesValue = (field) => {
    if(isAWSFileSystem(engine) && field.name === REGIONS) {
      return region;
    } else {
      return engine;
    }
  }
  const handleAutocompleteInputText = (value, field) => {
    if(field.name === "applicationName") {
      setApplicationName(value)
    }
  }

  const handleAutocompleteValue = (field) => {
    if(isAWSFileSystem(engine) && field.name === REGIONS) {
      return region;
    } else {
      return applicationName;
    }
  }

  const applicationErrorCheck = (field) => {
    if (field) {
      const errorMessage = applicationNameValidation(applicationName);
      setApplicationError(errorMessage);
      return !isEmpty(errorMessage);
    } else {
      setApplicationError(null);
      return false;
    }
  };
  
  const regionErrorCheck = (field) => {
    if (field && isEmpty(region)) {
      setRegionError(REGION_ERROR_MESSAGE);
      return true;
    } else {
      setRegionError(null);
      return false;
    }
  };
  

  const renderFieldsBasedOnSource = () => {
    if (engine === AZURE_EVENT_HUB) {
      return azureFormData;
    } else if (isAWSFileSystem(engine)) {
      return awsFormData;
    } else {
      return sqlFormData;
    }
  };

  const getTypeData = (field) => {
    if (isAWSFileSystem(engine) && field.name === REGIONS && allRegions) {
      return allRegions;
    } else {
      return types;
    }
  };

  const handleAutoCompleteError = (field) => {
    if(isAWSFileSystem(engine) && field.name === REGIONS && allRegions) {
      return regionError;
    } else {
      return applicationError;
    }
  }

  const handleOptions = (field) => {
    if (isAWSFileSystem(engine) && field.name === REGIONS && allRegions) {
      return allRegions;
    } else {
      return applications;
    }
  }

  const handleAutocompleteSolo = (field) => {
    if (isAWSFileSystem(engine) && field.name === REGIONS && allRegions) {
      return false;
    } else {
      return true;
    }
  }

  const handleAutoCompleteErrorChecks = (field) => {
    if (isAWSFileSystem(engine) && field.name === REGIONS && allRegions) {
      return setRegionError(null);
    } else {
      return setApplicationError(null);
    }
  }
  const handleAutocompleteTags = (value, getTagProps, field) => {
    if (isAWSFileSystem(engine) && field.name === REGIONS && allRegions) {
      return (
        <div className="chipContainer">
          {value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              label={option}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          style={{
            position: "absolute",
            marginLeft: "40%",
            top: "40%",
            zIndex: 1,
          }}
        ></img>
      )}
      <DynamicFormDataCreator
          formData={renderFieldsBasedOnSource()}
          engine={engine}
          applicationError={applicationError}
          setApplicationError={setApplicationError}
          setRegionError={setRegionError}
          handleVerification={handleVerification}
          applicationErrorCheck={applicationErrorCheck}
          regionErrorCheck={regionErrorCheck}
          getTypeData={getTypeData}
          region={region}
          handleEngineChange={handleEngineChange}
          applicationName={applicationName}
          isLogSourceVerified={isLogSourceVerified}
          handleToggleDrawerClose={handleToggleDrawerClose}
          handleTypeChange={handleTypeChange}
          renderTypesValue={renderTypesValue}
          handleAutocompleteValue={handleAutocompleteValue}
          handleOptions={handleOptions}
          handleAutoCompleteError={handleAutoCompleteError}
          handleAutocompleteInput={handleAutocompleteInput}
          handleAutocompleteInputText={handleAutocompleteInputText}
          handleAutocompleteTags={handleAutocompleteTags}
          handleAutocompleteSolo={handleAutocompleteSolo}
          handleSave={handleSave}
          applicationNameValidation={applicationNameValidation}
          handleAutoCompleteErrorChecks={handleAutoCompleteErrorChecks}
        />
      <div>
        <Snackbar
          //key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          onExited={handleExited}
          //message={messageInfo ? messageInfo.message : undefined}
        >
          <Alert onClose={handleClose} severity={"error"}>
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};