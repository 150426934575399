import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toggler from "../../shared/Toggler";
import DatabaseUsers from "./DataSourceUsers";
import FlaggedDataSourceUsers from "./FlaggedDataSourceUsers";
import AccessDetails from "./DirectDataAccess/DirectDataAccess";
import ServiceDataAccesses from "./ServiceDataAccess/ServiceDataAccesses";
import {
  ApiCall_DataSourceNames,
  ApiCall_DataSourceTypes,
  ApiCall_AccountStatus,
  ApiCall_DatabaseRoles,
  ApiCall_AccountStatus_On_Role,
  APPLICATIONS_URL,
} from "../../../redux/constants/constants";
import "./userAccessRights.scss";
import moment from "moment";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { useHistory } from "react-router-dom";
import { isNull } from "lodash";

const UserAccessRights = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [togglers, setTogglers] = useState([
    { name: "Data Source Users", state: "active" },
    { name: "Direct Data Accesses", state: "inactive" },
  ]);
  const directDataAccess = useSelector(
    (state) => state.normalDirectDataSourceReducer.directDataAccess
  );
  const currentUrl = window.location.search;
  const urlParam = new URLSearchParams(currentUrl);
  const status = urlParam.get("status");
  useEffect(() => {
    if (status && !isNull(status)) {
      dispatch({
        type: "SET_QUERY_PARAM",
        payload: { status: status, directDataAccess: true }
      })
    }
  },[])

  const renderTogglerWithServiceData = () => {
    if (directDataAccess) {
      setTogglers([
        { name: "Data Source Users", state: "inactive" },
        { name: "Direct Data Accesses", state: "active" },
        { name: "Service Data Accesses", state: "inactive" },
      ])
    } else {
      setTogglers([
        { name: "Data Source Users", state: "active" },
        { name: "Direct Data Accesses", state: "inactive" },
        { name: "Service Data Accesses", state: "inactive" },
      ]);
    }
  }
  const renderToggler = () => {
    if (directDataAccess) {
      setTogglers([
        { name: "Data Source Users", state: "inactive" },
        { name: "Direct Data Accesses", state: "active" },
      ])
    } else {
      setTogglers([
        { name: "Data Source Users", state: "active" },
        { name: "Direct Data Accesses", state: "inactive" },
      ]);
    }
  }
  const [roleName, setRoleName] = useState([]);
  const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
  const features =
    featuresData &&
    featuresData.features &&
    featuresData.features.filter((data) => data.name === "Data Vision");
  useEffect(() => {
    if (
      features &&
      features.length &&
      features[0].licenseType.name === "Platinum"
    ) {
      renderTogglerWithServiceData();
    } else {
      renderToggler();
    }

    window.scrollTo(0, 0);
    return () => {
      dispatch({ type: "RESET_BATCHEDIT_CLICK" });
      dispatch({ type: "CLOSECALENDER", payload: "" });
    };
  }, [directDataAccess]);

  const [dbusersDate, setDbUsersDate] = useState({
    pastDate: "",
    futureDate: "",
  });
  //useEffect to load the graph
  useEffect(() => {
    const past3MonthsDate = moment()
      .subtract(3, "months")
      .startOf("month")
      .format("YYYY-MM-DD");

    const next3MonthsDate = moment()
      .add(3, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    setDbUsersDate({ pastDate: past3MonthsDate, futureDate: next3MonthsDate });
    FilterAPICall();
    getRolesData();
    getRoleStatusBasedOnRole();
    if (props && props.location && props.location.state) {
      setTogglers([
        { name: "Data Source Users", state: "inactive" },
        { name: "Direct Data Accesses", state: "active" },
      ]);
      props.location.state = null;
    }
    return () => {
      dispatch({
        type: "DATA_SOURCE_CLEAR_STATE_DATA",
      });
      dispatch({
        type: "DIRECT_DATA_SOURCE_CLEAR_STATE_DATA",
      });
    };
  }, []);

  const getDataService = (url) => {
    return getService({
      method: "GET",
      url: `${url}`,
      headers: getAxiosHeaders(true),
    });
  };

  const getRolesData = async () => {
    const data = await getDataService(ApiCall_AccountStatus);
    if (data && data.status === 200) {
      dispatch({
        type: "DATA_SOURCE_ROLES_DATA",
        payload: data.data.content,
      });
    }
  };

  const getRoleStatusBasedOnRole = async () => {
    const data = await getDataService(ApiCall_AccountStatus_On_Role);
    if (data && data.status === 200) {
      // Extract the roleDBStateTransitionActions array from the data
      const roleDBStateTransition = data.data.roleDBStateTransitionActions
      // Extract and flatten the 'actions' array from each roleDBStateTransitionActions item
      const rolesBased = roleDBStateTransition.map(item => item.actions).flat(1);
      dispatch({
        type: "DATA_SOURCE_DATA_BASED_ON_ROLE",
        payload: rolesBased,
      });
      // Extract the 'roleName' property from each roleDBStateTransitionActions item
      const roleNames = roleDBStateTransition.map(item => item.roleName);
      setRoleName(roleNames);
    }
  };

  const FilterAPICall = async () => {
    await Promise.all([
      getDataService(ApiCall_DataSourceNames),
      getDataService(ApiCall_DataSourceTypes),
      getDataService(ApiCall_DatabaseRoles),
      getDataService(ApiCall_AccountStatus),
      getDataService(APPLICATIONS_URL),
    ]).then((data) => {
      if (data[0] !== "Internal Error")
        data.map((apiData, index) => settingApiData(apiData.data, index));
    });
  };

  const settingApiData = (apiData, index) => {
    switch (index) {
      case 0:
        return dispatch({
          type: "SET_API_DATA_SOURCE_NAME",
          payload: apiData || "",
        });
      case 1:
        return dispatch({
          type: "SET_API_DATA_SOURCE_TYPE",
          payload: apiData.content,
        });
      case 2:
        return dispatch({
          type: "SET_API_DATA_BASE_ROLES",
          payload: apiData.content,
        });
      case 3:
        return dispatch({
          type: "SET_API_DATA_BASE_STATUS_INFO",
          payload: apiData.content,
        });
      case 4:
        return dispatch({
          type: "SET_APPLICATIONS",
          payload: apiData.content,
        });
      default:
        return "";
    }
  };

  const handleToggle = () => {
    dispatch({
      type: "DATA_SOURCE_CLEAR_FILTER_DATA",
    });
    dispatch({
      type: "DIRECT_DATA_SOURCE_CLEAR_FILTER_DATA",
    });
    dispatch({
      type: "SERVICE_DATA_ACCESS_DATA_SOURCE_CLEAR_FILTER_DATA",
    });
    dispatch({ type: "RESET_BATCHEDIT_CLICK" });
    dispatch({ type: "CLOSECALENDER", payload: "" });
  };

  return (
    <div className="useraccessRights_main_container">
      <div className="description">
        <div style={{ display: "grid" }}>
          <h2 style={{ margin: "0", fontSize: "32px" }}>Access Rights</h2>
          <p style={{ margin: "0" }}>
            Details of end-users and their data access details
          </p>
        </div>
      </div>
      <div className="togglerandSearch_Conatiner">
        <Toggler
          togglers={togglers}
          setTogglers={setTogglers}
          handleToggle={handleToggle}
          show={roleName.includes('Administrator') || roleName.includes('DataManager')}
          history={history}
        ></Toggler>
      </div>

      <div className="useraccessRights_content">
        {togglers[0].state === "active" && (
          <>
            <FlaggedDataSourceUsers
              dbusersDate={dbusersDate}
              roleName={roleName}
              show={roleName.includes('Administrator') || roleName.includes('DataManager')}
            ></FlaggedDataSourceUsers>
            <DatabaseUsers
              dbusersDate={dbusersDate}
              roleName={roleName}
            ></DatabaseUsers>
          </>
        )}
        {togglers[1].state === "active" && <AccessDetails></AccessDetails>}
        {togglers[2]
          ? togglers[2].state === "active" && (
              <ServiceDataAccesses></ServiceDataAccesses>
            )
          : ""}
      </div>
    </div>
  );
};

export default UserAccessRights;
