import {
  GET_ALERT_POLICIES,
  GET_ALERT_POLICIES_URL
} from "../constants/constants";
//import axios from 'axios';
import { getAxiosHeaders, getService } from "../../library/RestAPI";

export async function getAllAlertPolicies(page, rowsPerPage, sortBy,sortOrder) {
  let paramData = {};
  if (sortBy && sortBy !== "") {
    paramData.sortBy = sortBy;
  }
  if(sortOrder && sortOrder !== ""){
    paramData.direction = sortOrder;
  }

  const options = {
    method: "GET",
    url: `${GET_ALERT_POLICIES_URL}?size=${rowsPerPage}&page=${page}`,
    headers: getAxiosHeaders(true),
    params: paramData,
    data: {}
  };

  const response = await getService(options);
  if (response && response.status === 200) {
    return response.data;
  } else {
    return "Error Fetching data from API";
  }

  // axios.get(`${GET_ALERT_POLICIES_URL}?size=20`).then(res => {

  //     if (res && res.data && res.status === 200) {
  //         console.log(res.data)
  //         return res.data.content
  //     }
  // })
}
