import { SAVE_CONNECTION_URL } from "../constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";

export async function saveConnection(data, applicationId, dataSourceId) {
  const options = {
    method: "PUT",
    url: `${SAVE_CONNECTION_URL}/${applicationId}/dataSources/${dataSourceId}`,
    headers: getAxiosHeaders(true),
    data: data,
  };
  //Remove /services/${servicesId} from above

  const response = await getService(options);
  if (response && response.status === 200) {
    console.log("success");
    return response.data;
  } else if (response && response.response.status === 400)  {
    let errorMessage = '';
    if (response && response.response.data.errorMessage) {
      errorMessage = response.response.data.errorMessage
    } else if (response && response.response.data.fieldErrors) {
      response.response.data.fieldErrors.map((error) => {
        errorMessage = errorMessage + error.errorMessage + "\n"
      }
      )
    } 
    return errorMessage;
  }
}
