const initialState = {
  qrCode: "",
  resetPassword: false,
};

export const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QR_CODE":
      return {
        ...state,
        qrCode: action.payload,
        resetPassword: true,
      };
    case "CLEAR_REDUCER":
      return initialState;
    default:
      return state;
  }
};
