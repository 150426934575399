import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import RiskPilotVulnerability from "./RiskPilotVulnerability";
import RiskLikelihoodAndImpact from "./RiskLikelihoodAndImpact";
import {
  handleAddVulnerability,
  handleThreatTextChange,
} from "../RiskPilot/riskPilotUtilities";
import { renderInputFields } from "./riskPilotHelperComp";

const RiskPilotThreatComp = ({
  handleThreatAccordionChange,
  renderThreatHeading,
  renderThreatDeleteButton,
  vulnerabilityExpanded,
  handleVulnerabilityAccordionChange,
  mitigationExpanded,
  handleMitigationAccordionChange,
  renderHeading,
  threatExpanded,
}) => {
  const dispatch = useDispatch();
  const threats = useSelector((state) => state.threatReducer.threats);
  const orderedThreats = useSelector((state) => state.threatReducer.orderedThreats);
  const vulnerabilities = useSelector(
    (state) => state.vulnerabilityReducer.vulnerabilities
  );

  const renderThreat = (threatId, threat) => (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {renderHeading(`Threat`)}
      </div>
      <div className="riskRegisterFormInput">
        {renderInputFields(
          threat.threat,
          (newText) => handleThreatTextChange(threatId, newText, dispatch),
          "Threat"
        )}
      </div>
    </>
  );

  const handleAddVulnClick = (threatId) => {
    handleAddVulnerability(dispatch, threatId, vulnerabilities);
  };

  return (
    <>
      {orderedThreats &&
        orderedThreats.map((tId) => {
          const threatData = threats && threats[tId];
          const threatId = threatData.id
          return (
            <div className="region" key={threatId}>
              <div className="threatContainer">
                <Accordion
                  expanded={threatExpanded[threatId]}
                  onChange={() => handleThreatAccordionChange(threatId)}
                >
                  <AccordionSummary>
                    <Typography className="riskThreatHeaderTypography">
                      <div className="riskThreatHeaderContainer">
                        {renderThreatHeading(threatId, threatData.threat)}
                        {renderThreatDeleteButton(threatId)}
                      </div>
                    </Typography>
                  </AccordionSummary>

                  {/* Accordion details for each threat */}
                  <AccordionDetails>
                    <div className="riskMultiContainer">
                      <div className="riskThreatDataContainer">
                        <div style={{ padding: "8px 16px 0px" }}>
                          {renderThreat(threatId, threatData)}
                        </div>
                        <RiskLikelihoodAndImpact
                          threat={threatData}
                          threatId={threatId}
                        />
                      </div>
                      <div className="riskThreatContainer">
                        <Typography variant="subtitle1">
                          <div className="riskAddThreatContainer">
                            <img
                              src="/riskPilotIcons/VulnerbilityIcon.svg"
                              alt="icon"
                            />
                            {renderHeading(`Vulnerability`, "threat")}
                          </div>
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            minWidth: "30px",
                            padding: "0px",
                            background: "#8015BD",
                            color: "white",
                            borderRadius: "5px",
                          }}
                          onClick={() => handleAddVulnClick(threatId)}
                        >
                          +
                        </Button>
                      </div>
                      {threatData.vulnerabilities.map((vulnerabilityId) => (
                        <RiskPilotVulnerability
                          key={vulnerabilityId}
                          threatId={threatId}
                          threatName={threatData.threat}
                          vulnerability={vulnerabilities[vulnerabilityId]}
                          vulnerabilityExpanded={vulnerabilityExpanded}
                          handleVulnerabilityAccordionChange={
                            handleVulnerabilityAccordionChange
                          }
                          mitigationExpanded={mitigationExpanded}
                          handleMitigationAccordionChange={
                            handleMitigationAccordionChange
                          }
                        />
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default RiskPilotThreatComp;
