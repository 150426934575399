import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { ColorButtonForClearFilter, ApplyButton } from "../common/buttons";
import { CloseButton } from "../common/buttons";
import { APPLY_INCIDENTTABLE_FILTER_ACTION } from "../../redux/actions/MDRActions/IncidentDashboardAction";
import { APPLY_ENDPOINTS_FILTER_ACTION } from "../../redux/actions/MDRActions/MDREndPointsAction";
import { APPLY_AUTHENTICATION_FILTER_ACTION } from "../../redux/actions/MDRActions/AuthenticationDashboardAction";
import { useSelector } from "react-redux";
import {
  CACHE_INCIDENT_TABLE_DATA,
  APPLY_CACHED_INCIDENT_TABLE_DATA,
} from "../../redux/constants/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { clearURLParam } from "../common/utilities";


const ClearFilter = (props) => {
  const {
    toggle,
    clearFilterButtonClick,
    loc,
    selecedValues,
    setSelectedValues,
    setFilterSeledtedValues,
    handleApplyFilter,
    handleCloseButton,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  //incident Table Filter
  let MDRIncidentTablePriorityFilter = useSelector(
    (state) => state.IncidenetTableFilterReducer.Priority
  );
  let MDRIncidentTableAssigneeFilter = useSelector(
    (state) => state.IncidenetTableFilterReducer.Assignee
  );
  let MDRIncidentTableEffectiveStatusFilter = useSelector(
    (state) => state.IncidenetTableFilterReducer.EffectiveStatus
  );
  let MDRIncidentTableStateDetailsFilter = useSelector(
    (state) => state.IncidenetTableFilterReducer.StateDetails
  );
  let MDRIncidentTableIncidentIdFilter = useSelector(
    (state) => state.IncidenetTableFilterReducer.IncidentId
  );
  let MDRIncidentTableCityFilter = useSelector(
    (state) => state.IncidenetTableFilterReducer.City
  );
  //Authentication Table Filter
  let mdrAuthenticationSourceFilter = useSelector(
    (state) => state.MDRAuthenticationReducer.mdrAuthenticationSourceFilter
  );

  //EndPoints Filters
  let EnvironmentFilters = useSelector(
    (state) => state.MDREndPointsFilterReducer.Environment
  );

  let OsDescriptionFilters = useSelector(
    (state) => state.MDREndPointsFilterReducer.OsDescription
  );

  // patchMonitoring Filters

  let MDRPatchMonitoringClassificationFilter = useSelector(
    (state) => state.MDRPatchMonitoringReducer.Classification
  );

  let MDRPatchMonitoringSeverityFilter = useSelector(
    (state) => state.MDRPatchMonitoringReducer.Severity
  );
  let MDRPatchMonitoringSubscriptionNameFilter = useSelector(
    (state) => state.MDRPatchMonitoringReducer.SubscriptionName
  );
  let MDRPatchMonitoringUpdateStateFilter = useSelector(
    (state) => state.MDRPatchMonitoringReducer.UpdateState
  );
  
  useEffect(() => {
    if (loc === "MDRDashboard_IncidentTable") {
      dispatch({ type: CACHE_INCIDENT_TABLE_DATA });    
    }
  },[]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "5px",
      }}
    >
      <p className="clear-filter-head">
        {loc === "policy" ? "Sort" : "Sort & Filter"}
      </p>
      {loc === "MDRDashboard_IncidentTable" ||
      loc === "MDRDashboard_EndPointsTable" ||
      loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
      loc === "MDRDashboard_PatchMonitoring" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ColorButtonForClearFilter
            onClick={(event) => {
              toggle("bottom", event);
              if (loc === "MDRDashboard_IncidentTable") {
                dispatch({ type: "RESET_INCIDENT_TABLE_DATA" });
                dispatch(
                  APPLY_INCIDENTTABLE_FILTER_ACTION([
                    {
                      values: MDRIncidentTablePriorityFilter,
                      query: {
                        member: "IncidentsTable.priority",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: MDRIncidentTableEffectiveStatusFilter,
                      query: {
                        member: "IncidentsTable.status",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: MDRIncidentTableStateDetailsFilter,
                      query: {
                        member: "IncidentsTable.stateDetails",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: MDRIncidentTableIncidentIdFilter,
                      query: {
                        member: "IncidentsTable.incidentId",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: MDRIncidentTableCityFilter,
                      query: {
                        member: "IncidentsTable.city",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: MDRIncidentTableAssigneeFilter,
                      query: {
                        member: "IncidentsTable.assignee",
                        operator: "equals",
                        values: [],
                      },
                    },
                  ])
                );
              } else if (loc === "MDRDashboard_EndPointsTable") {
                dispatch(
                  APPLY_ENDPOINTS_FILTER_ACTION([
                    {
                      values: EnvironmentFilters,
                      query: {
                        member: "MDREndPoints.environment",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: OsDescriptionFilters,
                      query: {
                        member: "MDREndPoints.osDescription",
                        operator: "equals",
                        values: [],
                      },
                    },
                  ])
                );
              } else if (loc === "MDRDashboard_AuthenticationTable_AllUsers") {
                dispatch(
                  APPLY_AUTHENTICATION_FILTER_ACTION([
                    {
                      values: mdrAuthenticationSourceFilter,
                      query: {
                        member: "MdrAuthentication.authErrorSource",
                        operator: "equals",
                        values: [],
                      },
                    },
                  ])
                );
              } else if (loc === "MDRDashboard_PatchMonitoring") {
                dispatch({
                  type: "APPLY_PATCHMONITORING_FILTER",
                  payload: [
                    {
                      values: MDRPatchMonitoringClassificationFilter,
                      query: {
                        member: "MDRPatchManagement.classification",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: MDRPatchMonitoringSeverityFilter,
                      query: {
                        member: "MDRPatchManagement.severity",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: MDRPatchMonitoringUpdateStateFilter,
                      query: {
                        member: "MDRPatchManagement.updateState",
                        operator: "equals",
                        values: [],
                      },
                    },
                    {
                      values: MDRPatchMonitoringSubscriptionNameFilter,
                      query: {
                        member: "MDRPatchManagement.subscriptionName",
                        operator: "equals",
                        values: [],
                      },
                    },
                  ],
                });
              }
            }}
            variant="contained"
            color="primary"
            size="small"
            style={{ backgroundColor: "#650176", color: "white" }}
          >
            Apply
          </ColorButtonForClearFilter>
          <ColorButtonForClearFilter
            onClick={(event) => {
              toggle("bottom", event);
              if (loc === "MDRDashboard_IncidentTable") {
                dispatch({ type: "INCIDENT_TABLE_FILTER_CLEARALL" });
              } else if (loc === "MDRDashboard_AuthenticationTable_AllUsers") {
                dispatch({ type: "MDR_AUTHENTICATION_FILTERS_CLEARALL" });
              } else if (loc === "MDRDashboard_EndPointsTable") {
                dispatch({ type: "ENDPOINTS_TABLE_FILTER_CLEARALL" });
              } else if (loc === "MDRDashboard_PatchMonitoring") {
                dispatch({ type: "PATCHMONITORING_FILTER_CLEARALL" });
              }
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              // if (loc === "MDRDashboard_IncidentTable") {
              //   dispatch({ type: APPLY_CACHED_INCIDENT_TABLE_DATA });
              // }
              toggle("bottom", event);
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "Mdr_PatchMonitoring" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ColorButtonForClearFilter
            onClick={(event) => {
              toggle(event, "bottom", "saveandApply");
            }}
            variant="contained"
            color="primary"
            size="small"
            style={{ backgroundColor: "#650176", color: "white" }}
          >
            Save and Apply
          </ColorButtonForClearFilter>
          {/* <ColorButtonForClearFilter
            onClick={(event) => {
              toggle("bottom", event);
            }}
            variant="contained"
            color="primary"
            size="small"
            style={{ backgroundColor: "#650176", color: "white" }}
          >
            Apply
          </ColorButtonForClearFilter> */}
          <ColorButtonForClearFilter
            onClick={(event) => {
              toggle(event, "bottom", "clearAll");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              toggle(event, "bottom");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "Access_details" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ApplyButton
            onClick={(event) => {
              dispatch({ type: "SET_DIRECT_DATA_SOURCE_APPLY_DATA" });
              clearURLParam(history, true, dispatch);
              dispatch({ type: "GET_NORMAL_DIRECT_DATA_SOURCE_INFO_DATA" });
              toggle(event, "bottom");
            }}
          >
            Apply
          </ApplyButton>
          <ColorButtonForClearFilter
            onClick={() => {
              clearFilterButtonClick();
              dispatch({ type: "GET_NORMAL_DIRECT_DATA_SOURCE_INFO_DATA" });
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              dispatch({ type: "DIRECT_DATA_SOURCE_CLEAR_ON_CLOSE" });
              toggle(event, "bottom");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "oneDrive_filter" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ApplyButton
            onClick={(event) => {handleApplyFilter()}}
          >
            Apply
          </ApplyButton>
          <ColorButtonForClearFilter
            onClick={(event) => {
              clearFilterButtonClick();
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              toggle(event, "bottom-end");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "database_Users" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ApplyButton
            onClick={(event) => {
              dispatch({ type: "SET_DATA_SOURCE_APPLY_DATA" });
              dispatch({ type: "GET_NORMAL_DATA_SOURCE_INFO_DATA" });
              toggle(event, "bottom");
            }}
          >
            Apply
          </ApplyButton>
          <ColorButtonForClearFilter
            onClick={(event) => {
              clearFilterButtonClick();
              dispatch({ type: "GET_NORMAL_DATA_SOURCE_INFO_DATA" });
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              dispatch({ type: "DATA_SOURCE_CLEAR_ON_CLOSE" });
              toggle(event, "bottom");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "Flagged_database_Users" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ApplyButton
            onClick={(event) => {
              dispatch({ type: "SET_DATA_FLAGGED_SOURCE_APPLY_DATA" });
              dispatch({ type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA" });
              toggle(event, "bottom");
            }}
          >
            Apply
          </ApplyButton>
          <ColorButtonForClearFilter
            onClick={(event) => {
              clearFilterButtonClick();
              dispatch({ type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA" });
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              dispatch({ type: "DATA_FLAGGED_SOURCE_CLEAR_ON_CLOSE" });
              toggle(event, "bottom");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "alerts" || loc === "resolved-alerts" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ApplyButton
            onClick={(event) => {
              handleApplyFilter();
              // toggle(event, "bottom");
            }}
          >
            Apply
          </ApplyButton>
          <ColorButtonForClearFilter
            onClick={(event) => {
              clearFilterButtonClick();
              // dispatch({ type: "GET_NORMAL_DATA_SOURCE_INFO_DATA" });
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => handleCloseButton()}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "database-logs" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ApplyButton
            onClick={(event) => {
              dispatch({ type: "SET_APPLY_DATA" });
              // Full reset of page size, total count etc
              dispatch({ type: "FULL_RESET_DB_LOGS" });
              dispatch({ type: "GET_DATABASE_LOGS_INFO_DATA" });
              toggle(event, "bottom");
            }}
          >
            Apply
          </ApplyButton>
          <ColorButtonForClearFilter
            onClick={(event) => {
              toggle(event, "bottom");
              clearFilterButtonClick();
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              dispatch({ type: "CLEAR_ON_CLOSE" });
              toggle(event, "bottom");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "Service_Data_Accesses" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ApplyButton
            onClick={(event) => {
              dispatch({ type: "SET_SERVICE_DATA_ACCESS_APPLY_DATA" });
              // Full reset of page size, total count etc
              dispatch({ type: "FULL_RESET_SERVICE_LOGS" });
              dispatch({ type: "GET_SERVICE_DATA_ACCESSES_INFO_DATA" });
              toggle(event, "bottom");
            }}
          >
            Apply
          </ApplyButton>
          <ColorButtonForClearFilter
            onClick={(event) => {
              toggle(event, "bottom");
              clearFilterButtonClick();
              dispatch({ type: "GET_SERVICE_DATA_ACCESSES_INFO_DATA" });
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              dispatch({ type: "DIRECT_DATA_SOURCE_CLEAR_ON_CLOSE_SERVICE_ACCESS_DATA" });
              toggle(event, "bottom")
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : loc === "service-database-logs" ? (
        <div style={{ padding: "10px 20px 0px 0px" }}>
          <ApplyButton
            onClick={(event) => {
              dispatch({ type: "SET_APPLY_DATA_SERVICE_ACCESS_LOGS" });
              // Full reset of page size, total count etc
              dispatch({ type: "FULL_RESET_SERVICE_LOGS" });
              dispatch({ type: "GET_SERVICE_ACCESS_LOGS_INFO_DATA" });
              toggle(event, "bottom");
            }}
          >
            Apply
          </ApplyButton>
          <ColorButtonForClearFilter
            onClick={(event) => {
              toggle(event, "bottom");
              clearFilterButtonClick();
              dispatch({ type: "GET_SERVICE_ACCESS_LOGS_INFO_DATA" });

            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear All
          </ColorButtonForClearFilter>
          <CloseButton
            onClick={(event) => {
              dispatch({ type: "SERVICE_ACCESS_LOGS_CLEAR_ON_CLOSE" });
              toggle(event, "bottom");
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            <CloseOutlinedIcon />
          </CloseButton>
        </div>
      ) : (
          <div style={{ padding: "10px 20px 0px 0px" }}>
            <ColorButtonForClearFilter
              onClick={() => clearFilterButtonClick()}
              variant="contained"
              color="primary"
              size="small"
            >
              Clear All
            </ColorButtonForClearFilter>
            <CloseButton
              onClick={toggle("bottom")}
              variant="contained"
              color="primary"
              size="small"
            >
              <CloseOutlinedIcon />
            </CloseButton>
          </div>
        )}
    </div>
  );
};

export default ClearFilter;
