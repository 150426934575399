import React from "react";
import moment from "moment";


export const formattedDate = (date) => {
  // Get the year component of the date
  const year = date.getFullYear();
  // Get the month component of the date and ensure it has two digits
  const month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);
  // Get the day component of the date and ensure it has two digits
  const day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate();
  // Combine the year, month, and day components with hyphens to create the formatted date
  return `${month}-${day}-${year}`;
};


export const formatDateAndTime = (date, requiredFormat) => {
  return moment.utc(new Date(parseInt(date))).format(requiredFormat);
}