import {
  awsCloud,
  azureCloud,
  normalDataSources,
  onPremiseDataSource,
} from "../dataSource-data";
import MSSQLServerIcon from "../../../assets/icons/MSSQLServerIcon.svg";
import AWSServerIcon from "../../../assets/icons/AWSServerIcon.svg";
import AzureServerIcon from "../../../assets/icons/AzureServerIcon.svg";
import AzurePostgresSqlIcon from "../../../assets/icons/azurePostgresqlLogo.png";
import PostgresSqlIcon from "../../../assets/icons/PostgresIconName.png";
import { POSTGRESQL } from "../../../redux/constants/constants";
import { isNull } from "lodash";

export const renderDataSourceData = (engine, iconMap) => {
  // Check if iconMap has data
  if (!iconMap || Object.keys(iconMap).length === 0) {
    return normalDataSources; // Fallback to normalDataSources
  }
  const selectedIcon = Object.entries(iconMap).find(([key, value]) => value.selected);

  // Check if a selected icon was found
  if (!selectedIcon) {
    return normalDataSources; // Fallback to normalDataSources
  }

  const selectedName = selectedIcon[0]; // Get the name from the selected key-value pair
  const cloudMapping = {
    "On-Premise": onPremiseDataSource,
    "AWS": awsCloud,
    "Azure": azureCloud,
  };

  return cloudMapping[selectedName] || normalDataSources; // Return data source or fallback
};

export const generateIconMap = (engine) => {
  const iconMapTemplate = {
    MSSQLServer: {
      selected: true,
      name: "On-Premise",
      type: "radio",
    },
    AWSServer: {
      selected: false,
      name: "AWS",
      type: "radio",
    },
    AzureServer: {
      selected: false,
      name: "Azure",
      type: "radio",
    },
  };

  const icons = engine === POSTGRESQL ? {
    MSSQLServer: PostgresSqlIcon,
    AWSServer: AWSServerIcon,
    AzureServer: AzurePostgresSqlIcon,
  } : {
    MSSQLServer: MSSQLServerIcon,
    AWSServer: AWSServerIcon,
    AzureServer: AzureServerIcon,
  };

  return Object.keys(iconMapTemplate).reduce((map, key) => {
    map[key] = {
      ...iconMapTemplate[key],
      icon: icons[key],
    };
    return map;
  }, {});
};

export const filterGroups = (group, allGroups, classes) => {
  let updatedGroups = {
    allGroups: [],
    noOptionText: "",
  };
  let isDefaultSelected;
  if (group) {
    // Check if "Default" group is selected
    isDefaultSelected = group.some(
      (selectedGroup) => selectedGroup.name === "Default"
    );
    if (isDefaultSelected) {
      // Filter out all other groups if "Default" is selected
      updatedGroups = {
        allGroups: allGroups.filter((group) => group.name === "Default"),
        noOptionText:
          "When the 'Default' option is selected, other groups cannot be selected.",
        noOptionClass: classes.noOptions
      };
    } else if (!isNull(group) && group.length > 0) {
      // Filter out "Default" group if any other group is selected
      updatedGroups = {
        allGroups: allGroups.filter((group) => group.name !== "Default"),
        noOptionText: "No Options",
        noOptionClass: ""
      };
    } else {
      // No group is selected, return all groups
      updatedGroups = {
        allGroups: allGroups,
        noOptionText: "No Options",
        noOptionClass: ""
      };
    }
  }
  return updatedGroups;
};