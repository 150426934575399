import { isEmpty } from "lodash";
import { addThreat, deleteThreat, updateThreat } from "./actions/threatActions";
import {
  CRITICAL_SEVERITY,
  HIGH_SEVERITY,
  LOW_SEVERITY,
  MEDIUM_SEVERITY,
  RISK_PILOT_MITIGATION_STATUS,
  RISK_PILOT_IMPACT_LEVELS,
  RISK_PILOT_LEVELS,
} from "./riskPilotConstant";
import {
  addVulnerability,
  deleteVulnerability,
  updateVulnerability,
} from "./actions/vulnerabilityActions";
import {
  addMitigation,
  deleteMitigation,
  updateMitigation,
} from "./actions/mitigationAction";
import { v4 as uuidv4 } from 'uuid';

export const assignIDs = (data) => {
  let threatId = 1;
  if (!isEmpty(data) && Object.keys(data).length > 0) {
    const formattedData = data.map((threat) => ({
      id: threatId++,
      threat: threat.threat,
      vulnerabilities: threat.vulnerability.map((vulnerability, index) => ({
        id: index + 1,
        vulnerability: vulnerability.vulnerability,
        mitigation: vulnerability.mitigation.map((mitigation, i) => ({
          id: i + 1,
          mitigation: mitigation.mitigation,
          mitigationPriority: mitigation.mitigationPriority.toUpperCase(),
          mitigationStatus: mitigation.mitigationStatus.toUpperCase(),
        })),
      })),
    }));
    return formattedData;
  } else {
    return [];
  }
};

export const getRiskSeverity = {
  HIGH: {
    LOW: LOW_SEVERITY,
    MEDIUM: MEDIUM_SEVERITY,
    HIGH: HIGH_SEVERITY,
    CRITICAL: CRITICAL_SEVERITY
  },
  MEDIUM: {
    LOW: LOW_SEVERITY,
    MEDIUM: MEDIUM_SEVERITY,
    HIGH: MEDIUM_SEVERITY,
    CRITICAL: HIGH_SEVERITY
  },
  LOW: {
    LOW: LOW_SEVERITY,
    MEDIUM: LOW_SEVERITY,
    HIGH: MEDIUM_SEVERITY,
    CRITICAL: MEDIUM_SEVERITY
  }
}

export const handleAddThreat = (dispatch, currentThreats) => {
  const newThreatId = uuidv4();
  const maxOrderExecuted = Math.max(...Object.values(currentThreats).map(threat => threat.orderExecuted), 0);
  const newThreat = {
    id: newThreatId,
    threat: "",
    likelihood: RISK_PILOT_LEVELS[0],
    impact: RISK_PILOT_IMPACT_LEVELS[0],
    riskLevel: getRiskSeverity[RISK_PILOT_LEVELS[0]][RISK_PILOT_IMPACT_LEVELS[0]],
    vulnerabilities: [],
    orderExecuted: maxOrderExecuted + 1
  };
  dispatch(addThreat(newThreat));
};


export const handleThreatTextChange = (threatId, newText, dispatch) => {
  const updates = {
    threat: newText,
  };
  dispatch(updateThreat(threatId, updates));
};

export const handleThreatChange = (threatId, threat, field, value, dispatch) => {
  const updateRiskSeverity =
    field === "likelihood"
      ? getRiskSeverity[value][threat.impact]
      : getRiskSeverity[threat.likelihood][value]

  const updates = {
    [field]: value,
    riskLevel: updateRiskSeverity,
  };

  dispatch(updateThreat(threatId, updates));
};

export const handleDeleteThreat = (threatId, dispatch) => {
  // Dispatch action to delete the threat with the specified ID
  dispatch(deleteThreat(threatId));
};

export const handleAddVulnerability = (dispatch, threatId, vulnerabilities) => {
  const newVulnerabilityId = uuidv4();
  const maxOrderExecuted = Math.max(...Object.values(vulnerabilities).map(vuln => vuln.orderExecuted), 0);
  const newVulnerability = {
    id: newVulnerabilityId,
    vulnerability: "",
    mitigations: [],
    threatId,
    orderExecuted: maxOrderExecuted + 1
  };
  dispatch(addVulnerability(newVulnerability));
};

export const handleDeleteVulnerability = (
  dispatch,
  vulnerabilityId,
  threatId
) => {
  dispatch(deleteVulnerability({ vulnerabilityId, threatId }));
};

export const handleVulnerabilityTextChange = (
  threatId,
  vulnerabilityId,
  newText,
  dispatch
) => {
  const updates = {
    vulnerability: newText,
  };
  dispatch(updateVulnerability(vulnerabilityId, threatId, updates));
};

export const handleAddMitigation = (
  dispatch,
  threatId,
  vulnerabilityId,
  mitigations,
  riskOwners
) => {
  const newMitigationId = uuidv4();
  const maxOrderExecuted = Math.max(...Object.values(mitigations).map(m => m.orderExecuted), 0);
  const newMitigation = {
    id: newMitigationId,
    mitigationText: "",
    mitigationPriority: RISK_PILOT_LEVELS[0],
    mitigationStatus: RISK_PILOT_MITIGATION_STATUS[0].code,
    mitigationOwner: riskOwners[0].code,
    vulnerabilityId,
    threatId,
    orderExecuted: maxOrderExecuted + 1
  };

  dispatch(addMitigation(newMitigation));
};

export const handleMitigationTextChange = (
  dispatch,
  threatId,
  vulnerabilityId,
  mitigationId,
  newText
) => {
  const updates = {
    mitigationText: newText,
  };
  dispatch(updateMitigation(vulnerabilityId, threatId, mitigationId, updates));
};

export const handleMitigationPriorityChange = (
  dispatch,
  threatId,
  vulnerabilityId,
  mitigationId,
  newSeverity
) => {
  const updates = {
    mitigationPriority: newSeverity,
  };
  dispatch(updateMitigation(vulnerabilityId, threatId, mitigationId, updates));
};

export const handleMitigationStatusChange = (
  dispatch,
  threatId,
  vulnerabilityId,
  mitigationId,
  newStatus
) => {
  const updates = {
    mitigationStatus: newStatus,
  };
  dispatch(updateMitigation(vulnerabilityId, threatId, mitigationId, updates));
};

export const handleMitigationOwnerChange = (
  dispatch,
  threatId,
  vulnerabilityId,
  mitigationId,
  newOwner
) => {
  const updates = {
    mitigationOwner: newOwner,
  };
  dispatch(updateMitigation(vulnerabilityId, threatId, mitigationId, updates));
};

export const handleDeleteMitigation = (
  dispatch,
  mitigationId,
  vulnerabilityId,
  threatId
) => {
  dispatch(deleteMitigation({mitigationId, vulnerabilityId, threatId}));
};

export const renderMitigationOwner = (value, riskOwners) => {
  const owner = riskOwners.find((risk) => risk.code === value);
  return owner ? owner.code : null; // Return null or some default value if not found
};

export const RiskPilotHeadCells = (
  renderRiskRegisterID,
  renderRiskRegisterName,
  renderRiskSeverity,
  renderThreats,
  renderVulnerabilities,
  renderMitigations,
  renderRegisterAction
) => {

  return [
    {
      title: "Risk ID",
      style: { width: "10%", cursor: "default" },
      rowBodyCellStyle: { width: "10%", cursor: "default" },
      render: (rowData) => {
        return <>{renderRiskRegisterID(rowData.riskId)}</>;
      },
    },
    {
      title: "Risk",
      style: { width: "15%" },
      rowBodyCellStyle: { width: "15%", cursor: "default" },
      render: (rowData) => {
        return <>{renderRiskRegisterName(rowData.riskType)}</>;
      },
    },
    {
      title: "Risk Severity",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", cursor: "default" },
      render: (rowData) => {
        return <>{renderRiskSeverity(rowData.severity)}</>;
      },
    },
    {
      title: "Threats",
      style: { width: "10%", textAlign: "center"},
      rowBodyCellStyle: { width: "10%", cursor: "default", textAlign: "center" },
      render: (rowData) => {
        return <>{renderThreats(rowData.threatCount)}</>;
      },
    },
    {
      title: "Vulnerabilities",
      style: { width: "13%", textAlign: "center"},
      rowBodyCellStyle: { width: "13%", cursor: "default", textAlign: "center" },
      render: (rowData) => {
        return <>{renderVulnerabilities(rowData.vulnerabilityCount)}</>
      }
    },
    {
      title: "No Of Mitigations",
      style: { width: "10%", textAlign: "center"},
      rowBodyCellStyle: { width: "10%",  cursor: "default", textAlign: "center" },
      render: (rowData) => {
        return <>{renderMitigations(rowData.mitigationCount, rowData.toDoCount, rowData.openCount, rowData.inProgressCount,rowData.closedCount )}</>;
      },
    },
    {
      title: "Action",
      style: { width: "10%", textAlign: "right"},
      rowBodyCellStyle: { width: "10%", textAlign: "right", cursor: "pointer"},
      render: (rowData) => {
        return <>{renderRegisterAction(rowData.riskId)}</>;
      },
    },
  ];
};

export const transformTableData = (data, type, fields) => {
  return data.map((item) => {
    const transformedItem = {};
    fields.forEach(field => {
      const value = item[`${type}.${field.key}`];
      transformedItem[field.name] = field.type === 'int' ? parseInt(value, 10) : value;
    });
    return transformedItem;
  });
};
export const transformStatusData = (data, type) => {
  const aggregatedData = data.reduce((acc, item) => {
    acc.toDo += parseInt(item[`${type}.entitiesWithToDoCount`]) || 0;
    acc.open += parseInt(item[`${type}.entitiesWithOpenCount`]) || 0;
    acc.inProgress += parseInt(item[`${type}.entitiesWithInProgressCount`]) || 0;
    acc.closed += parseInt(item[`${type}.entitiesWithClosedCount`]) || 0;
    return acc;
  }, {
    toDo: 0,
    open: 0,
    inProgress: 0,
    closed: 0
  });

  return aggregatedData;
};

const getMitigationData = (obj, threatId, mitigationData, riskOwners) => {
  const mitigationIds = [];
  
  obj.mitigation.forEach((m) => {
    mitigationData[m.id] = {
      id: m.id,
      mitigationText: m.mitigation,
      mitigationPriority: m.mitigationPriority,
      mitigationStatus: m.mitigationStatus,
      mitigationOwner: renderMitigationOwner(m.riskOwner, riskOwners),
      vulnerabilityId: obj.id,
      threatId,
      orderExecuted: m.orderExecuted,
    };
    mitigationIds.push(m.id);
  });

  return {
    id: obj.id,
    vulnerability: obj.vulnerability,
    threatId,
    orderExecuted: obj.orderExecuted,
    mitigations: mitigationIds,
  };
};

const getVulnerabilityData = (obj, vulnerabilityData, mitigationData, riskOwners) => {
  const vulnerabilityIds = [];

  obj.vulnerability.forEach((v) => {
    vulnerabilityIds.push(v.id);
    vulnerabilityData[v.id] = getMitigationData(v, obj.id, mitigationData, riskOwners);
  });

  return {
    id: obj.id,
    threat: obj.threat,
    likelihood: obj.likelihood,
    impact: obj.impact,
    riskLevel: obj.riskLevel,
    orderExecuted: obj.orderExecuted,
    vulnerabilities: vulnerabilityIds,
  };
};

export const createObjectWithOrder = (data, riskOwners) => {
  const vulnerabilityData = {};
  const threatIds = [];
  const threatData = {};
  const mitigationData = {};

  data.forEach((obj) => {
    threatIds.push(obj.id);
    threatData[obj.id] = getVulnerabilityData(obj, vulnerabilityData, mitigationData, riskOwners);
  });

  return {
    threatIds,
    threatData,
    vulnerabilityData,
    mitigationData,
  };
};


