import { GET_POLICY_DATAFLOW, GET_POLICY_DATAFLOW_URL } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function getAlertPolicyDataFlowGroups(id) {
    const options = {
        method: "GET",
        url: `${GET_POLICY_DATAFLOW_URL}/${id}/dataFlowGroups`,
        headers: getAxiosHeaders(true),
        data: {}
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
    
    // axios.get(`${GET_ALERT_POLICIES_URL}?size=20`).then(res => {
        
    //     if (res && res.data && res.status === 200) {
    //         console.log(res.data)
    //         return res.data.content
    //     }
    // })
};

