import axios from "axios";
import { isFunction } from "lodash";
//const serviceUrl = 'Production url';
export async function getService(getOptions, handleServerError) {
  try {
    let res = await axios(getOptions);
    return res;
  } catch (e) {
    if (isFunction(handleServerError)) {
      handleServerError(e.response.data);
    }
    console.log("Possibly local timed out, please check the error -> ", e);
    let errorRespose;
    if (e.response && e.response.status === 401) {
      // Save the URL pathname from the current location
      const currentPathname = window.location.pathname;

      if (currentPathname) {
        // Get the URL query string
        const queryString = window.location.search;
        // Create a URLSearchParams object from the query string
        const params = new URLSearchParams(queryString);

        // Extract the 'id' parameter
        const id = params.get("id");

        // Store pathname in local storage
        localStorage.setItem("storedPathname", currentPathname);

        // Store 'id' in local storage if available
        if (id) {
          localStorage.setItem("storedId", id);
        }
      } else {
        // Clear both local storage and session storage
        localStorage.clear();
        sessionStorage.clear();
      }

      // Redirect to the login page
      if (window.location.host.indexOf("localhost") !== -1) {
        window.location.replace(
          `http://localhost:3000/?tenant=${localStorage.getItem("tenant")}`
        );
      } else {
        window.location.replace(`http://${window.location.host}/`);
      }
    }
    if (typeof e.response === "undefined") {
      errorRespose = "Internal Error";
    } else {
      errorRespose = e;
    }
    return errorRespose;
  }
}

export const getAxiosHeaders = (addBearerToken, contentType) => {
  let headers = {};

  if (addBearerToken) {
    headers["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`;
  }
  headers["Content-Type"] = contentType || "application/json";
  headers["Tenant"] = localStorage.getItem("tenant");

  return headers;
};
