import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { GET_UNMASKED_INSTRUCTIONS } from "../../../redux/constants/constants";
// import Input from "../../views/Input";

export default function PopUp({
  currentInstructions,
  curAgentId,
  setcurrentInstructions,
  tenant,
  currentPlatform,
  platforms,
  setinsUnmasked,
  loc
}) {
  const [open, setOpen] = React.useState(false);
  const [authenticationCode, setAuthenticationCode] = useState("");
  const [error, setInputFeildError] = useState("");

  const MFAHandler = async () => {
    if (authenticationCode) {
      const res = await getService({
        method: "GET",
        url: GET_UNMASKED_INSTRUCTIONS(tenant, curAgentId, authenticationCode),
        headers: getAxiosHeaders(true),
      });
      console.log(res);
      if (res.status === 200) {
        console.log("Bring Unmasked Instructions");
        const platforms = res.data
          ? res.data.platformInstructions
            ? res.data.platformInstructions
            : []
          : [];
        let currentIns;
        platforms.forEach((el) => {
          if (el.platformType === currentPlatform) {
            currentIns = el.instructions ? el.instructions : "";
          } else {
            setcurrentInstructions([]);
          }
        });
        // const insArr = currentIns
        //   ? currentIns.split("<h3>Attach an agent in a standalone mode</h3>")
        //   : [];
        const insArr = currentIns ? currentIns.split() : [];

        setcurrentInstructions([...insArr]);
        setOpen(false);
        setinsUnmasked(true);
      } else {
        setInputFeildError("Please enter Valid Verfication Code");
      }
    } else {
      setInputFeildError("Please Enter Valid Code");
    }
  };

  const popUpChangeHandler = (event) => {
    setInputFeildError("");
    console.log(event.target.value);
    setAuthenticationCode(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={
        loc === "dataSource"
          ? "Devops_popUp_Container dataSource"
          : "Devops_popUp_Container"
      }
    >
      {loc !== "googleDrive" ? (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Unmask
        </Button>
      ) : null}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Please Enter MFA code to Unmask the Instructions
        </DialogTitle>

        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>*/}
          <TextField
            error={error ? true : ""}
            id="outlined-basic"
            label="Verification Code"
            variant="outlined"
            helperText={error}
            style={{ marginLeft: "25%" }}
            onChange={popUpChangeHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={MFAHandler} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
