import { DeleteButtonForToolBar } from "../../common/buttons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import InputBase from "@material-ui/core/InputBase";
import { Button } from "@material-ui/core";
import { reviewDataSource } from "../../../redux/constants/constants";
export const ViewServiceNodeConstants = (
  handleEditItem,
  handleEvent,
  handleSave,
  handleCancel,
  classes
) => {
  return [
    {
      title: "Alias Name",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%" },
      render: (rowData) => {
        return (
          <span>
            {rowData.edit !== true && rowData.dummyAliasName}
            {rowData.edit && (
              <InputBase
                value={rowData.dummyAliasName}
                name="dummyAliasName"
                placeholder="Alias Name"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInputClass,
                }}
                onChange={(event) => {
                  handleEvent(event, rowData);
                }}
                inputProps={{ "aria-label": "search" }}
              />
            )}
          </span>
        );
      },
    },
    {
      title: "Type",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.typeCode}</span>;
      },
    },
    {
      title: "Application Name",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%" },
      render: (rowData) => {
        return (
          <span>
            {rowData.applicationName}
          </span>
        );
      },
    },
    {
      title: "Action",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%" },
      render: (rowData) => {
        return (
          <>
            {rowData.edit !== true && (
              <span>
                <DeleteButtonForToolBar
                  variant="contained"
                  id="delete"
                  size="small"
                  color="primary"
                  onClick={() => handleEditItem(rowData)}
                  className="min-margin"
                >
                  <EditOutlinedIcon />
                </DeleteButtonForToolBar>
              </span>
            )}
            {rowData.edit && (
              <span>
                <Button
                  variant="contained"
                  style={{ textTransform: "none" , backgroundColor:"#2947fd" , color:"white"  , marginRight:"3px"}}
                  onClick={() => handleSave(rowData)}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  style={{ textTransform: "none" , backgroundColor:"white" , color:"#2947fd" , marginTop:"2px"}}
                  onClick={() => handleCancel(rowData)}
                >
                  Cancel
                </Button>
              </span>
            )}
          </>
        );
      },
      requiredPermission: reviewDataSource,
    },
  ];
};
