import React from "react";
import { Popper, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataMapTooltip from "./tooltip/Datamaptooltip.js";
import UserTooltip from "./tooltip/UserTooltip.js";

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ToolTip(props) {
  const classes = useStyles();
  const hoverNode = props.selectedHoverNode;

  const toolTipSwitch = () => {
    if (hoverNode.type === "USER") {
      return <UserTooltip hoverNode={hoverNode} />
    } else {
      if (props.loader) {
        return <div>Loading...</div>;
      } else if (props.data) {
        return <DataMapTooltip hoverNode={hoverNode} toolTipData={hoverNode.uiConfiguration} parentprops={props} />
      }
    }
  }

  return (
    <div>
      <Popper
        id="mouse-over-popover"
        className={classes.popper}
        classes={{
          paper: classes.paper,
        }}
        open={true}
        anchorEl={props.position ? props.position.anchorElement : null}
        placement="right-start"
        style={{
          position: "absolute",
          top: props.position ? props.position.y : 0,
          left: props.position ? props.position.x : 0,
        }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [10, 0],
            },
          },
        ]}
      >
        <Paper className={classes.paper} elevation={8}>
          <div>{toolTipSwitch()}</div>
        </Paper>
      </Popper>
    </div>
  );
}