import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import { SIGNUPPAGE_Reset } from "../../../redux/actions/signUppageAction";
import page_loader from "../../../assets/vizr_images/page_loader.gif";
import signUpSuccessful from "../../../assets/vizr_images/signUpSuccessful.svg";
import { cssVariables } from "../../../cssVariables";
import { useTranslation } from "react-i18next";
import "./SignUpsuccessful.scss";

const useStyles = makeStyles({
  VerifyEmail_Container: {},
  header: {
    height: "5rem",
    width: "100%",
    background: "linear-gradient(180deg, rgba(241,242,246,0) 0%, #DFE1E9 100%)",
  },
  headerLink: {
    color: (prop) => prop.colors.$lightRoyalBlue,
    textDecoration: "none",
    position: "absolute",
    textAlign: "center",
    left: "13.5rem",
    top: "7.5rem",
    zIndex: "10",
  },
  centralContainer: {
    height: "811px",
    width: "80%",
    borderRadius: "4px",
    backgrounColor: (prop) => prop.colors.$white,
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.3)",
    marginLeft: "14rem",
    marginTop: "3%",
    paddingTop: "5%",
    paddingLeft: "10% !important",
    color: (prop) => prop.colors.$black,
    fontSize: "1.6rem",
    letterSpacing: "0",
    lineheight: "24px",
    textAlign: "center",
  },
  loader: {
    width: "5rem",
    height: "5rem",
    marginTop: "4%",
  },
});

const SignUpSuccesful = ({ message, state }) => {
  let history = useHistory();
  const { t, i18n } = useTranslation();

  const classes = useStyles(cssVariables);
  const clickHandler = () => {
    console.log("Click handler");
    history.push("/signup");
    dispatch(SIGNUPPAGE_Reset());
  };
  const dispatch = useDispatch();
  return (
    <div className="VerifyEmail_Container">
      <SignUpBackGround></SignUpBackGround>
      <a href="/signup" onClick={clickHandler} className={classes.headerLink}>
        <span style={{ marginRight: "10px" }}>&larr;</span>
        {t("Back to Sign up")}
      </a>

      <div className="centralContainer">
        <p>
          {message
            ? message
            : "Thanks for signing up. You will receive an email containing the activation link.Click on the activation link to activate your account and initiate the account provisioning process"}
        </p>
        {state && !state.emailVerificationCompleted ? (
          <div>
            <img
              src={page_loader}
              alt="loader"
              className={classes.loader}
            ></img>
          </div>
        ) : (
          <div>
            <img
              src={signUpSuccessful}
              alt="signUpSuccessful"
              className={classes.loader}
            ></img>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpSuccesful;
