import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBar2 = ({
  success,
  failure,
  errorObject,
  successMessage,
  errorMessage,
  handler,
  setroleDeletedSuccess,
  setusersDeletedSuccess,
  setProfileUpdateState,
  profileUpdateState,
  state,
  setState,
}) => {
  const [openSuccess, setopenSuccess] = useState(false);
  const [openError, setopenError] = useState(false);

  useEffect(() => {
    // console.log(success, successMessage);

    // console.log(failure, errorMessage, state, successMessage, openError);
    if (success && successMessage) {
      setopenSuccess(true);
      if (setroleDeletedSuccess) {
        setroleDeletedSuccess(false);
      }
      if (setusersDeletedSuccess) {
        setusersDeletedSuccess(false);
      }
    } else if (failure && errorMessage) {
      setopenError(true);
    }
  }, [
    success,
    failure,
    setroleDeletedSuccess,
    setusersDeletedSuccess,
    successMessage,
    errorMessage,
    profileUpdateState,
    setProfileUpdateState,
    errorObject,
  ]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSuccess(false);
    setopenError(false);
    if (state) {
      setState({
        ...state,
        downloadingErrormessage: "",
      });
    }
  };

  return (
    <div>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBar2;
