import { HISTROY_DATA_SOURCE } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function getStatusHistory(actorId, dataSourceId,pageSize,currentPage) {
    const options = {
        method: "GET",
        url: HISTROY_DATA_SOURCE(actorId, dataSourceId) + `?size=${pageSize}&page=${currentPage}`,
        headers: getAxiosHeaders(true),
        data: {}
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
    
    // axios.get(`${GET_ALERT_POLICIES_URL}?size=20`).then(res => {
        
    //     if (res && res.data && res.status === 200) {
    //         console.log(res.data)
    //         return res.data.content
    //     }
    // })
};

