import { put, takeEvery } from "redux-saga/effects";
import {
  // SETPASSWORD__SUBMIT,
  SETPASSWORD__SUCCESS,
  SETPASSWORD__FAIL,
} from "../../redux/actions/setPasswordAction";

import { getService, getAxiosHeaders } from "../../library/RestAPI";
import { SERVER_ERROR_MESSAGE, SET_PASSWORD } from "../constants/constants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* setPassword(action) {
  const headers = getAxiosHeaders(false);
  headers.Tenant = action.payload.tenant;

  // delete headers.Tenant;
  try {
    const apiCall = yield getService({
      method: "POST",
      url: SET_PASSWORD(action.payload.tenant ? action.payload.tenant : ""),
      data: action.payload,
      headers: headers,
    });

    if (apiCall.status === 200) {
      if (apiCall.data.qrCode.imageUri) {
        localStorage.setItem("qr_code", apiCall.data.qrCode.imageUri);
      }
      yield put(SETPASSWORD__SUCCESS());
    } else {
      const status = apiCall.response?.status;
      if (status === 500) {
        yield put(
          SETPASSWORD__FAIL(
            "Activation code is not a valid one or Activation code has expired. Please contact admin"
          )
        );
      } else if (status === 400) {
        const errorMessage = apiCall.response?.data?.errorMessage || SERVER_ERROR_MESSAGE;
        yield put(SETPASSWORD__FAIL(errorMessage));
      } else {
        const errorMessage = apiCall.response?.data?.fieldErrors?.[0]?.errorMessage || "";
        yield put(SETPASSWORD__FAIL(errorMessage));
      }
    }
    
  } catch (error) {
    console.log(error);
  }
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* setPasswordSaga() {
  yield takeEvery("SETPASSWORD_SUBMIT", setPassword);
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/

export default setPasswordSaga;
