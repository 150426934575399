import React from "react";
import LoginbackGround from "../LoginContainer/LoginbackGround";
import ResetPasswordContainer from "../ResetPassword/ResetPasswordContainer";

const ResetBackground = ({ email, code, tenant, featureName }) => {
  return (
    <div>
      <LoginbackGround
        log={
          <ResetPasswordContainer
            email={email}
            code={code}
            tenant={tenant}
            featureName={featureName}
          ></ResetPasswordContainer>
        }
      ></LoginbackGround>
    </div>
  );
};

export default ResetBackground;
