import moment from "moment";
import { DeleteButtonForToolBar } from "../../common/buttons";
import ShowMoreData from "../../common/ShowMoreData/ShowMoreData";
import { checkIfEmpty, lessThanOnePercent, renderDataSource } from "../../common/utilities"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import BlackLockCross from "../../../assets/icons/BlackLockCross.svg";
import BlackLockQuestionMark from "../../../assets/icons/BlackLockQuestionMark.svg";
import GreenClosedLockTick from "../../../assets/icons/GreenClosedLockTick.svg";
import RedLockCross from "../../../assets/icons/RedLockCross.svg";
import { uniqBy } from "lodash";
import { differenceInDays } from "date-fns";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LevelComponent from "../../shared/levelComponent";
import { renderDateTime } from "../../views/Util/ViewCommon";
import { statusActive, statusInActive } from "../../common/utilities";
import { UPDATE_ENTERPRISE_ACTORS } from "../../../redux/constants/constants";
const useSelectStyles = makeStyles((theme) => ({
  selected: {},
  rootMenuItem: {
    "&$selected": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));

export const returnData = (code) => {
  switch (code) {
    case "ACCESS_VERIFIED":
      return {
        value: "Access verified",
        code,
      };
    case "ACCESS_TO_BE_VERIFIED":
      return {
        value: "Access to be verified",
        code,
      };
    case "ACCESS_EXPIRED":
      return {
        value: "Access expired",
        code,
      };
    case "ACCESS_REJECTED":
      return {
        value: "Access rejected",
        code,
      };
    case "ACCESS_REQUESTED":
      return {
        value: "Access requested",
        code,
      };
    case "ACCESS_GRANTED":
      return {
        value: "Access granted",
        code,
      };
    case "ACCESS_REVOKED":
      return {
        value: "Access revoked",
        code,
      };
    case "ACCESS_WILL_EXPIRE":
      return {
        value: "Access will expire",
        code,
      };
    case "ACCESS_MARKED_FOR_DELETION":
      return {
        value: "Access marked for deletion",
        code,
      };
    case "ACCESS_REMOVED":
      return {
        value: "Access removed",
        code,
      };
    case "ACCESS_APPROVED":
      return {
        value: "Access approved",
        code,
      };
    default:
      return {
        value: code,
        code,
      };
  }
};

const renderOption = (el, icon) => {
  return (
    <span
      className={el.className}
      style={{ display: "flex", marginTop: "3px" }}
    >
      {icon} <span style={{ marginLeft: "3px" }}>{el.value}</span>
    </span>
  );
};
export const returnOption = (el) => {
  let icon;
  if (el.code === "ACCESS_EXPIRED") {
    icon = <img src={BlackLockCross} height={"20px"} />;
  } else if (el.code === "ACCESS_VERIFIED") {
    icon = <img height={"20px"} src={GreenClosedLockTick} />;
  } else if (el.code === "ACCESS_REQUESTED") {
    icon = <img height={"20px"} src={BlackLockQuestionMark} />;
  } else if (el.code === "ACCESS_TO_BE_VERIFIED") {
    icon = <img height={"20px"} src={BlackLockQuestionMark} />;
  } else if (el.code === "ACCESS_GRANTED") {
    icon = <img height={"20px"} src={GreenClosedLockTick} />;
  } else if (el.code === "ACCESS_REJECTED") {
    icon = <img height={"20px"} src={RedLockCross} />;
  } else if (el.code === "ACCESS_REVOKED") {
    icon = <img height={"20px"} src={RedLockCross} />;
  } else if (el.code === "ACCESS_REMOVED") {
    icon = <img height={"20px"} src={RedLockCross} />;
  } else if (el.code === "ACCESS_MARKED_FOR_DELETION") {
    icon = <img height={"20px"} src={BlackLockCross} />;
  } else if (el.code === "ACCESS_MARKED_FOR_DELETION") {
    icon = <img height={"20px"} src={BlackLockCross} />;
  } else if (el.code === "ACCESS_WILL_EXPIRE") {
    icon = <img height={"20px"} src={BlackLockCross} />;
  } else if (el.code === "ACCESS_APPROVED") {
    icon = <img height={"20px"} src={GreenClosedLockTick} />;
  }
  return renderOption(el, icon);
};

const returnDataIcon = (rowData, flag) => {
  let icon =
    flag === "flaggedDataSource" &&
    rowData.accountStatus !== "ACCESS_EXPIRED" &&
    differenceInDays(new Date(rowData.expiryDate), new Date()) >= 0 &&
    differenceInDays(new Date(rowData.expiryDate), new Date()) <= 5 ? (
      <ErrorOutlineIcon color="#EDE04D" style={{ color: "#FF8421" }} />
    ) : (
      ""
    );
  return (
    <span>
      {moment(new Date(rowData.expiryDate)).format("MM-DD-YYYY")}{" "}
      <span
        title={"Will Expire Soon"}
        style={{ padding: "5px 0px", verticalAlign: "middle" }}
      >
        {icon}
      </span>
    </span>
  );
};
const showExpiryDate = (rowData, roleName, setTarget) => {
  let disabled = true;
  if (roleName.includes("Administrator") || roleName.includes("DataOwner")) {
    disabled = false;
  } else if (
    roleName.includes("DataManager") &&
    rowData.accountStatus === "ACCESS_REQUESTED"
  ) {
    disabled = false;
  }
  return (
    <DeleteButtonForToolBar
      variant="contained"
      id="delete"
      size="small"
      color="primary"
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        setTarget(event.currentTarget, rowData.databaseAccessRequestID);
      }}
      className="min-margin"
    >
      <EditOutlinedIcon />
    </DeleteButtonForToolBar>
  );
};


const statusUpdate = (deleted) => {
  if(deleted) {
    return statusInActive();
  } else {
    return statusActive();
  }
}

export const DataSourceUserConstants = (
  setTarget,
  accountDataOnRole,
  handleOnChangeRole,
  flag,
  roleName
) => {
  const menuClasses = useSelectStyles();
  const result = accountDataOnRole.reduce((acc, it) => {
    if (!acc[it.fromState.code]) acc[it.fromState.code] = [];
    acc[it.fromState.code].push(it.fromState);
    acc[it.fromState.code].push(it.toState);
    return acc;
  }, {});
  return [
    {
      title: "Data Source User",
      style: { width: "18%" },
      rowBodyCellStyle: {
        width: "18%",
        wordBreak: "break-all",
        backgroundColor: flag === "flaggedDataSource" ? "#FBEBF2" : "none",
      },
      render: (rowData) => {
        return (
          <div className="access-rights">
          {rowData.username}
          <span>{statusUpdate(rowData.delete)}</span>
          </div>
        );
      },
    },
    {
      title: "User Roles",
      style: { width: "15%" },
      rowBodyCellStyle: {
        width: "15%",
        wordBreak: "break-all",
        backgroundColor: flag === "flaggedDataSource" ? "#FBEBF2" : "none",
      },
      render: (rowData) => {
        return (
          <div style={{ width: "175px", wordBreak: "initial" }}>
            {rowData.accessRoleNames && rowData.accessRoleNames.map((each) => each.name).join(", ")}
          </div>
        );
      },
    },
    {
      title: "Data Source Name",
      style: { width: "20%" },
      rowBodyCellStyle: {
        width: "20%",
        wordBreak: "break-all",
        backgroundColor: flag === "flaggedDataSource" ? "#FBEBF2" : "none",
      },
      render: (rowData) => {
        const dataSourceName = rowData.aliasName ? rowData.aliasName : rowData.dataSourceName;
        const dataSourceType = rowData.dataSourceTypeCode ? rowData.dataSourceTypeCode : ""
        return (
          <span
            style={{
              wordBreak: "break-all",
            }}
          >
            {renderDataSource(dataSourceType, dataSourceName)}
          </span>
        );
      },
    },
    {
      title: "Approval Status",
      style: { width: "20%" },
      rowBodyCellStyle: {
        backgroundColor: flag === "flaggedDataSource" ? "#FBEBF2" : "none",
      },
      render: (rowData) => {
        return result[rowData.accountStatus] !== undefined ? (
          <span className="select">
            {" "}
            <Select
              className="InputSelectField select"
              value={rowData.accountStatus}
              onChange={(event) => {
                event.stopPropagation();
                handleOnChangeRole(event.target.value, rowData);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {uniqBy(result[rowData.accountStatus], "code").map((el, i) => (
                <MenuItem
                  value={el.code}
                  className="select"
                  classes={{
                    selected: menuClasses.selected,
                    root: menuClasses.rootMenuItem,
                  }}
                >
                  {returnOption(el)}
                </MenuItem>
              ))}
            </Select>
          </span>
        ) : (
          <>
            <span style={{ display: "flex" }}>
              {returnOption(returnData(rowData.accountStatus))}
            </span>
          </>
        );
      },
    },
    {
      title: "Expiry Date",
      style: { width: "15%" },
      rowBodyCellStyle: {
        width: "15%",
        wordBreak: "break-all",
        backgroundColor: flag === "flaggedDataSource" ? "#FBEBF2" : "none",
      },
      render: (rowData) => {
        return (
          <span>
            {rowData.expiryDate ? returnDataIcon(rowData, flag) : "-"}
          </span>
        );
      },
    },
    {
      title: "",
      style: { width: "5%" },
      rowBodyCellStyle: {
        width: "5%",
        wordBreak: "break-all",
        backgroundColor: flag === "flaggedDataSource" ? "#FBEBF2" : "none",
      },
      render: (rowData) => {
        return showExpiryDate(rowData, roleName, setTarget);
      },
      requiredPermission: UPDATE_ENTERPRISE_ACTORS,
    }];
};

export const DirectDataSourceConstants = () => {
  return [
    {
      title: "Data Source User",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return rowData.username;
      },
    },
    {
      title: "User Roles",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return (
          rowData.roles &&
          rowData.roles.map((el, index, arr) => (
            <div
              style={{
                width: "150px",
                wordBreak: "break-all",
              }}
            >{`${el}${index !== arr.length - 1 && el ? "," : ""}`}</div>
          ))
        );
      },
    },
    {
      title: "Data Source Name",
      headerClassName : "access-rights-table-head",
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        const dataSourceType = rowData.dataSourceType ? rowData.dataSourceType : "";
        return (renderDataSource(dataSourceType, rowData.dataSourceName))
      },
    },
    {
      title: "Has Sensitive Data?",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span> {rowData.sensitiveDataAccessed}</span>;
      },
    },
    {
      title: "Sensitive Data Accessed",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        const rowPercentage = parseFloat(rowData.sensitiveDataAccessPercentage);
        return (
          <div title={rowPercentage.toFixed(2)}>
            <CircularProgressbar
              value={lessThanOnePercent(rowPercentage, 1)}
              text={`${lessThanOnePercent(rowPercentage, '<1')}%`}
              styles={buildStyles({
                pathColor: `${
                  rowData.sensitiveDataAccessPercentage <= 30
                    ? "green"
                    : rowData.sensitiveDataAccessPercentage <= 60
                    ? "yellow"
                    : "red"
                }`,
              })}
            />
          </div>
        );
      },
    },
    {
      title: "No of Anomalies",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return rowData.totalAnomalies;
      },
    },
    {
      title: "Highest Alert Severity",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (row) => {
        return (
          <div className={`accessDetailsRow ${row.highestAlert}`}>
            {row.highestAlert ? row.highestAlert : "-"}
          </div>
        );
      },
    },
    {
      title: "First  Access Time",
      style: { width: "8%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (row) => {
        return renderDateTime(row.firstAccessedOn);
      },
    },
    {
      title: "Last Access Time",
      style: { width: "8%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (row) => {
        return renderDateTime(row.lastAccessedOn);
      },
    },
  ];
};

export const DataSourceStatus = (popUpData) => {
  return [
    {
      title: "Data Source Username",
      headerClassName : "access-rights-table-head",
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: () => {
        return popUpData.username;
      },
    },
    {
      title: "Data Source Name",
      headerClassName : "access-rights-table-head",
      rowBodyCellStyle: { width: "20%", wordBreak: "break-all" },
      render: () => {
        return renderDataSource(popUpData.dataSourceTypeCode,popUpData.aliasName);
      },
    },
    {
      title: "From Account Status",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span> {returnData(rowData.fromStatus).value}</span>;
      },
    },
    {
      title: "To Account Status",
      headerClassName : "account-status-table-head",
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span> {returnData(rowData.toStatus).value}</span>;
      },
    },
    {
      title: "Time of Change",
      style: { width: "15%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return renderDateTime(rowData.statusChangedOn);
      },
    },
    {
      title: "Change Performed By",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%", wordBreak: "break-all" },
      render: (rowData) => {
        return rowData.statusChangedBy !== " "
          ? rowData.statusChangedBy
          : rowData.username;
      },
    },
  ];
};

export const ServiceDataAccessesConstants = () => {
  return [
    {
      title: "Service User",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return rowData.username;
      },
    },
    {
      title: "Application Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return rowData.applicationName;
      },
    },
    {
      title: "Service Name",
      style: { width: "12%" },
      rowBodyCellStyle: { width: "12%", wordBreak: "break-all" },
      render: (rowData) => {
        const size = 10;
        return checkIfEmpty(
          rowData.serviceName,
          <ShowMoreData size={size} data={rowData.serviceName} 
          partialRender={(data) => {
            return (
              <span>
                {data[0]}, 
              </span>
            );
           }}
           fullRender={(data) => {
            return data.map((element) => {
             return (<span>
              {element}
              <br />
            </span>
            );
          })
        }}/>
        );
      },
    },


    // Commented for Future Release


    {
      title: "Data Source Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        const size = 15;
        return checkIfEmpty(
          rowData.dataSourceNames, 
          <ShowMoreData size={size} data={rowData.dataSourceNames} 
           partialRender={(data) => {
            return (
              <span>
                {data[0]}, 
              </span>
            );
           }}
           fullRender={(data) => {
            return data.map((element) => {
              return (
                <span>
                  {element}
                  <br />
                </span>
              );
            })
          }}/>
        );
      },
    },
    {
      title: "User Roles",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        const size = 10;
        return checkIfEmpty(
          rowData.dataSourceRoles,
          <ShowMoreData size={size} data={rowData.dataSourceRoles} 
          partialRender={(data) => {
            return (
              <span>
                {data[0]}, 
              </span>
            );
           }}
           fullRender={(data) => {
            return data.map((element) => {
             return (<span>
              {element}
              <br />
            </span>
            );
          })
        }}/>
        );
      },
    },
    {
      title: "Data Source Username",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        const size = 10;
        return checkIfEmpty(
          rowData.usernames,
          <ShowMoreData size={size} data={rowData.usernames} 
          partialRender={(data) => {
            return (
              <span>
                {data[0]}, 
              </span>
            );
           }}
           fullRender={(data) => {
            return data.map((element) => {
             return (<span>
              {element}
              <br />
            </span>);
          })
        }}/>
        );
      },
    },
    {
      title: "Has Sensitive Data?",
      style: { width: "6%" },
      rowBodyCellStyle: { width: "6%", wordBreak: "break-all" },
      render: (rowData) => {
        return rowData.hasSensitiveData? (
          <span> True</span>
        ) : (
          <span> False</span>
        );
      },
    },

    {
      title: "Sensitive Data Accessed",
      style: { width: "5%" },
      rowBodyCellStyle: { width: "5%", wordBreak: "break-all" },
      render: (rowData) => {
        const rowPercentage = parseFloat(rowData.sensitiveCountPercentage);
        return (
          <div>
            <CircularProgressbar
              value={lessThanOnePercent(rowPercentage, 1)}
              text={`${lessThanOnePercent(rowPercentage, '<1')}%`}
              styles={buildStyles({
                pathColor: `${
                  rowData.sensitiveCountPercentage <= 30
                    ? "green"
                    : rowData.sensitiveCountPercentage <= 60
                    ? "yellow"
                    : "red"
                }`,
              })}
            />
          </div>
        );
      },
    },
    {
      title: "No.of Anomalies",
      style: { width: "4%" },
      rowBodyCellStyle: { width: "4%", wordBreak: "break-all" },
      render: (row) => {
        return (
          <div className={`accessDetailsRow ${row.highestAlert}`}>
            {/* {row.highestAlert ? row.highestAlert : "-"} */}
            {row.numOfAnomalies}
          </div>
        );
      },
    },
    {
      title: "Highest Alert Severity",
      style: { width: "8%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (row) => {
        return (
          <div className={`accessDetailsRow ${row.highestAlert}`}>
            <LevelComponent
              level={
                row.highSeveritySensitivityCategory
                  ? row.highSeveritySensitivityCategory
                  : "-"
              }
            />
          </div>
        );
      },
    },
    {
      title: "First  Access Time",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "7%" , whiteSpace:"nowrap"},
      render: (row) => {
        return renderDateTime(row.firstAccessTime);
      },
    },
    {
      title: "Last Access Time",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "7%" , whiteSpace:"nowrap" },
      render: (row) => {
        return renderDateTime(row.lastAccessTime);
      },
    },
  ];
};
