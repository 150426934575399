import { Button, Dialog, DialogContent } from "@material-ui/core";
import "./RiskGovernance.scss";
import React from "react";
import { useDispatch } from "react-redux";
import { CLEAR_MITIGATION, CLEAR_THREATS, CLEAR_VULNERABILITIES } from "./riskPilotConstant";

const RiskPilotPopUp = ({ riskPilotPopUp, setRiskPilotPopUp, setIsEditMode, ShowRiskPilotForm }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setRiskPilotPopUp(false); // Close the popup
  };

  const handleClearRiskForm = () => {
    dispatch({type: CLEAR_THREATS});
    dispatch({type: CLEAR_VULNERABILITIES});
    dispatch({type: CLEAR_MITIGATION});
    setRiskPilotPopUp(false);
    setIsEditMode(false);
    ShowRiskPilotForm(true);
  }

  return (
    <Dialog
      open={riskPilotPopUp} // Controls the open state of the dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="riskPopUpDialogContainer"
    >
      <DialogContent>
        <div className="riskPopTextContainer">
          <div className="riskPopUpText">
            <span>Create New Risk</span>
          </div>
          <div className="riskPopUpMessage">
            There are unsaved changes. Do you wish to continue?
          </div>
        </div>
        <div className="riskPopUpButtonContainer">
          <div className="riskCancelButton">
            <Button
              type="submit"
              variant="contained"
              onClick={handleClose} // Handle close action
            >
              Cancel
            </Button>
          </div>
          <div className="riskContinueButton">
            <Button type="submit" variant="contained" onClick={() => handleClearRiskForm()}>
              Continue
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RiskPilotPopUp;