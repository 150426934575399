import { UPDATE_DROPBOX_CONNECTION_STATUS, RESET_DROPBOX_CONNECTION_STATUS } from '../constants/constants';

const initialState = {
  dropBoxConnected: false,
};

export const dropBoxSuccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DROPBOX_CONNECTION_STATUS: {
      return {
        ...state,
        dropBoxConnected: true
      }
    }
    case RESET_DROPBOX_CONNECTION_STATUS: {
      return {
        ...state,
        dropBoxConnected: false
      }
    }
    default:
      return state;
  }
};