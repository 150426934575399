import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "../../assets/vizr_images/ic_help.svg";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontFamily: "Nunito-Regular",
    fontSize: 14,
    padding: 15,
    maxWidth:"550px"
  },
  arrow: {
    fontSize: 16,
    width: 17,
    "&::before": {
      border: "1px solid #fff",
      backgroundColor: "white",
      boxSizing: "border-box",
      boxShadow: " 0 0 3px #0004",
    },
  },
}))(Tooltip);

export default function HelpIconWithTooltip(props) {
  const { description, loc } = props;
  return (
    <>
      <LightTooltip title={description} arrow>
      <img className={loc === "riskPilot" ? "help-icon riskPilot" : "help-icon"} src={HelpIcon} alt="help" />
      </LightTooltip>
    </>
  );
}
