import React from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import InfoIcon from "../../../assets/vizr_images/infoIcon.svg";


const UTCTooltipStyles = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fff',
      color: 'rgba(41, 71, 253, 1)',
      border: '1px solid rgba(41, 71, 253, 1)',
      fontSize: '12px',
      lineHeight: '16px',
      borderRadius: '5px',
      padding: '6px 8px',
      wordBreak: 'break-all',
    },
    arrow: {
      color: '#fff',
    },
  }))(Tooltip);


export const UTCTooltip = (location) => {
    const icon = location && location === "tableHeader" ? InfoIcon : "/infoIconBlue.svg";
    return (
      <UTCTooltipStyles title="UTC Time Format" arrow>
        <img src={icon} alt="UTC Time Format Info Icon" />
      </UTCTooltipStyles>
    );
  };