// export const SIGNUPPAGE_USERNAME = "SIGNUPPAGE_USERNAME";
export const SIGNUPPAGE_FIRSTNAME = "SIGNUPPAGE_FIRSTNAME";
export const SIGNUPPAGE_LASTNAME = "SIGNUPPAGE_LASTNAME";
export const SIGNUPPAGE_PHONE = "SIGNUPPAGE_PHONE";
export const SIGNUPPAGE_ADMINNAME = "SIGNUPPAGE_ADMINNAME";
export const SIGNUPPAGE_ADMINEMAIL = "SIGNUPPAGE_ADMINEMAIL";
export const SIGNUPPAGE_TENENTNAME = "SIGNUPPAGE_TENENTNAME";
export const SIGNUPPAGE_DOMAIN = "SIGNUPPAGE_DOMAIN";
export const SIGNUPPAGE_MONITOR = "SIGNUPPAGE_MONITOR";
export const SIGNUPPAGE_CONTROL = "SIGNUPPAGE_CONTROL";
export const SIGNUPPAGE_SUCCESSFUL = "SIGNUPPAGE_SUCCESSFUL";
export const SIGNUPPAGE_FAILED = "SIGNUPPAGE_FAILED";
export const SIGNUPPAGE_RESET = "SIGNUPPAGE_RESET";
export const SIGNUPPAGE_SELFSUBMIT = "SIGNUPPAGE_SELFSUBMIT";
