import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { QueryRenderer } from "@cubejs-client/react";
import { Chart } from "react-google-charts";

import ChartWrapper from "./Chart";
import { dateFormatter } from "./utils";
import { reviewDataSource, viewAlert, viewDatabaseLogs, viewDataSource, VIEW_ENTERPRISE_ACTORS } from "../../../redux/constants/constants";
import { pathWithPermission } from "../utilities";

const returnRedirectTo = (name) => {
  let data = {
    "Users with Alerts": {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },
    "Sensitive Data Users": {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },
    "Sensitive Data Sources": {
      pathname: "/tenant/reviewDataSources",
      permission: [viewDataSource],
    },
   "Active Alerts":{
      pathname: "/tenant/alerts",
      permission: [viewAlert],
    },
    "Sensitive Data Access Traces":{
      pathname: "/tenant/databaseLogs",
      permission: [viewDatabaseLogs]
    },
    "Traces":{
      pathname: "/tenant/databaseLogs",
      permission: [viewDatabaseLogs]
    },
    "Nodes":{
      pathname: "/tenant/reviewDataSources",
      permission: [reviewDataSource],
    },
    "Data Sources":{
      pathname: "/tenant/reviewDataSources",
      permission: [reviewDataSource],
    },
  }
  return pathWithPermission(data[name])
};

function ComboChart(
  cubejsApi,
  name,
  renderSingleValue,
  renderNoData,
  queries,
  project,
  history
) {
  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      loader={<div>Loading Chart</div>}
      query={queries[1]}
      render={(resultSet) => {
        let data = resultSet.loadResponse.results[0].data;
        let projectedData = [["Day", "Count"]];
        if (project === "MDR") {
          console.log("queries[1]", queries[1]);
          let granularity = `${queries[1].timeDimensions[0].dimension}.${queries[1].timeDimensions[0].granularity}`;
          console.log("granularity", granularity);
          data.forEach((d) => {
            let formattedDate = dateFormatter(d[granularity]);
            projectedData.push([
              formattedDate,
              parseInt(
                d[queries[1].measures] && d[queries[1].measures].length
                  ? d[queries[1].measures[0]]
                  : d[queries[1].dimensions[0]]
              ),
            ]);
          });
        } else {
          let granularity = `${queries[1].timeDimensions[0].dimension}.${queries[1].timeDimensions[0].granularity}`;
          data.forEach((d) => {
            let formattedDate = dateFormatter(d[granularity]);
            projectedData.push([
              formattedDate,
              parseInt(d[queries[1].measures[0]]),
            ]);
          });
        }

        let options = {
          vAxis: {
            minValue: 0,
            baselineColor: "#fff",
            gridlineColor: "#fff",
            textPosition: "none",
          },
          haxis: {
            title: "",
            baselineColor: "#fff",
            gridlineColor: "#fff",
            textPosition: "none",
          },

          // For the legend to fit, we make the chart area smaller
          chartArea: {
            width: "100%",
            height: "100%",
          },
          //Used for coloring the chart Area
          // colors: ["green"],
          // lineWidth: 25,

          //used for styling the Title of chart
        };
        const colorFinder = (data1, data2) => {
          if (data1[1] > data2[1]) {
            return "green";
          } else if (data2[1] > data1[1]) {
            return "red";
          } else {
            return "green";
          }
        };
        if (
          project === "MDR" &&
          name.indexOf("Events") < 0 &&
          name.indexOf("Servers")
        ) {
          projectedData[1] && projectedData[projectedData.length - 1]
            ? (options.colors = [
                colorFinder(
                  projectedData[1],
                  projectedData[projectedData.length - 1]
                ),
              ])
            : (options.colors = []);
        } else if (project === "MDR") {
          options.colors = ["black"];
        }
        const calculatePercentage = (point1, point2) => {
          let pointInt1 = Number(point1);
          let pointInt2 = Number(point2);
          if (pointInt2) {
            if (pointInt1 > 0) {
              let percentage = ((pointInt1 - pointInt2) / pointInt1) * 100;
              return Math.floor(percentage);
            } else {
              let percentage = ((1 - pointInt2) / 1) * 100;
              return Math.floor(percentage);
            }
          } else {
            return 0;
          }
        };
        const percentageDisplayHtml = (project) => {
          if (
            project === "MDR" &&
            name.indexOf("Events") < 0 &&
            name.indexOf("Servers") < 0
          ) {
            return (
              <Typography variant="body2">
                {calculatePercentage(
                  projectedData[1][1],
                  projectedData[projectedData.length - 1][1]
                ) >= 0 ? (
                  <div
                    style={{
                      height: "24px",
                      width: "140px",
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "18px",
                      lineHeight: "20px",
                    }}
                  >
                    {`${calculatePercentage(
                      projectedData[1][1],
                      projectedData[projectedData.length - 1][1]
                    )}%`}
                  </div>
                ) : (
                  <div
                    style={{
                      height: "24px",
                      width: "140px",
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "18px",
                      lineHeight: "20px",
                    }}
                  >
                    {`${Math.abs(
                      calculatePercentage(
                        projectedData[1][1],
                        projectedData[projectedData.length - 1][1]
                      )
                    )}%`}
                  </div>
                )}
              </Typography>
            );
          }
          //Return empty HTML to align the graphs which are not percentage to display
          else {
            return (
              <Typography variant="body2">
                <div
                  style={{
                    height: "24px",
                    width: "140px",
                    fontWeight: "bold",
                    fontSize: "18px",
                    visibility: "hidden",
                    // display: "none",
                  }}
                >
                  {" "}
                  %
                </div>
              </Typography>
            );
          }
        };

        return (
          <Grid container spacing={0}>
            {data && data.length > 0 && project === "MDR" ? (
              <React.Fragment>
                <Grid item container xs={12} direction={"row"}>
                  <Grid item container xs={6} direction={"column"} spacing={4}>
                    <Grid item xs={4}>
                      <Typography variant="body2">
                        <QueryRenderer
                          query={queries[0]}
                          cubejsApi={cubejsApi}
                          render={({ resultSet }) => {
                            if (!resultSet) {
                              return <div className="loader" />;
                            }

                            //3 rd Argument being isMDR project if true yes else no
                            return renderSingleValue(
                              resultSet,
                              queries[0].measures[0],
                              true
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {percentageDisplayHtml(project)}
                    </Grid>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "-1rem" }}>
                    <Chart
                      width={"100%"}
                      height={"100px"}
                      chartType="AreaChart"
                      loader={<div>Loading Chart</div>}
                      data={projectedData}
                      options={options}
                      rootProps={{ "data-testid": "1" }}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : data && data.length > 0 ? (
              <React.Fragment>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    <QueryRenderer
                      query={queries[0]}
                      cubejsApi={cubejsApi}
                      render={({ resultSet }) => {
                        if (!resultSet) {
                          return <div className="loader" />;
                        }
                        return renderSingleValue(
                          resultSet,
                          queries[0].measures[0]
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Chart
                    width={"100%"}
                    height={"100px"}
                    chartType="AreaChart"
                    loader={<div>Loading Chart</div>}
                    data={projectedData}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                          if (
                            name === "Users with Alerts" ||
                            name === "Sensitive Data Users" 
                          ) {
                            var handler = function (e) {
                              history.push({
                                ...returnRedirectTo(name)
                              });
                            };
                          }
                          if (name === "Active Alerts") {
                            var handler = function (e) {
                              history.push({
                                ...returnRedirectTo(name)
                              });
                            };
                          }
                          if (
                            name === "Sensitive Data Access Traces" ||
                            name === "Traces"
                          ) {
                            var handler = function (e) {
                              history.push({
                                ...returnRedirectTo(name)
                              });
                            };
                          }
                          if (name === "Nodes" || name === "Data Sources" ||
                          name === "Sensitive Data Sources") {
                            var handler = function (e) {
                              history.push({
                                ...returnRedirectTo(name)
                              });
                            };
                          }
                          google.visualization.events.addListener(
                            chartWrapper.getChart(),
                            "select",
                            handler
                          );
                        },
                      },
                    ]}
                    options={{
                      // interpolateNulls: true,
                      // isStacked: false,
                      vAxis: {
                        minValue: 0,
                        // gridlines: { color: 'transparent' },
                        baselineColor: "#fff",
                        gridlineColor: "#fff",
                        textPosition: "none",
                      },
                      haxis: {
                        title: "",
                        baselineColor: "#fff",
                        gridlineColor: "#fff",
                        textPosition: "none",
                      },
                      // For the legend to fit, we make the chart area smaller
                      chartArea: { width: "90%", height: "100%" },
                      // lineWidth: 25,
                    }}
                    // For tests
                    rootProps={{ "data-testid": "1" }}
                  />
                </Grid>
              </React.Fragment>
            ) : (
              renderNoData()
            )}
          </Grid>
        );
      }}
      redirectTo={returnRedirectTo(name)}
    />
  );
}

export default ComboChart;
