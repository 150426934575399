import { call, put, takeEvery } from "redux-saga/effects";
import { EDIT_SENSITIVITY_REQUEST } from "../constants/constants";
import { EDIT_SENSITIVITY_URL } from '../constants/constants';
import {getAxiosHeaders, getService} from "../../library/RestAPI";

function* updateSensitivityInfo(action) {
    let options;
    try {
        options = {
            method: "PUT",
            url: `${EDIT_SENSITIVITY_URL}`,
            headers: getAxiosHeaders(true),
            data: action.payload.data
        };
      try {
        const res = yield getService(options);
        if(res && res.status === 204) {
          yield put({ type: "EDIT_SENSITIVITY_SUCCEEDED"});
        } else {
          yield put({
            type: "EDIT_SENSITIVITY_FAILED",
            payload: "Edit unsuccessful",
          });
        }
      } catch (error) {
        console.log(error);
        // myStorage.setItem("access_token", "error");
        yield put({
          type: "EDIT_SENSITIVITY_FAILED",
          payload: "Error Fetching Data from API",
        });
      }
    } catch (e) {
      yield put({ type: "EDIT_SENSITIVITY_FAILED" });
    }
  }
  
  function* updateSensitivityInfoSaga() {
    yield takeEvery(EDIT_SENSITIVITY_REQUEST, updateSensitivityInfo);
  }
  
  export default updateSensitivityInfoSaga;