import React from "react";
import "./RiskGovernance.scss";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import DeleteRRIcon from "../../../assets/icons/DeleteRR.svg";
import arrowDropUpCircle from "../../../assets/icons/arrowDropUpCircle.svg";
import arrowDropDownCircle from "../../../assets/icons/arrowDropDownCircle.svg";
import { renderHeading, renderTextArea } from "./riskPilotHelperComp";
import {
  handleAddMitigation,
  handleDeleteVulnerability,
  handleVulnerabilityTextChange,
} from "./riskPilotUtilities";
import { useSelector, useDispatch } from "react-redux";
import RiskPilotMitigation from "./RiskPilotMitigation";

const RiskPilotVulnerability = ({
  threatId,
  threatName,
  vulnerability,
  vulnerabilityExpanded,
  handleVulnerabilityAccordionChange,
  mitigationExpanded,
  handleMitigationAccordionChange,
  loc,
}) => {
  const dispatch = useDispatch();
  const mitigations = useSelector(
    (state) => state.mitigationReducer.mitigations
  );
  const riskOwners = useSelector((state) => state.riskPilotReducer.riskOwners);

  const renderVulnerability = (threatId, vulnerability) => (
    <>
      <div
        className="riskRegisterTextArea"
        style={{ marginLeft: "3.5%", marginBottom: "15px", width: "52.5%" }}
      >
        {renderTextArea(
          vulnerability.vulnerability,
          (newValue) =>
            handleVulnerabilityTextChange(
              threatId,
              vulnerability.id,
              newValue,
              dispatch
            ),
          "Vulnerability"
        )}
      </div>
    </>
  );

  return (
    <div className="vulnerabilityContainer">
      <Accordion
        expanded={vulnerabilityExpanded[vulnerability.id]}
        onChange={() => handleVulnerabilityAccordionChange(vulnerability.id)}
      >
        <AccordionSummary>
          <Typography className="riskThreatHeaderTypography">
            <div className="riskThreatHeaderContainer">
              <div className="riskGeneralStyle">
                {vulnerabilityExpanded[vulnerability.id] ? (
                  <img src={arrowDropUpCircle} alt="arrow up" />
                ) : (
                  <img src={arrowDropDownCircle} alt="arrow down" />
                )}
                <div className="riskMultiTitleContainer">
                  <img
                    className="multiThreatIconCustomSizes threatVulnIcon"
                    src="/riskPilotIcons/ThreatIcon.svg"
                  />
                  <span className="secondTitle">{`Threat: ${threatName}`}</span>
                  <span className="riskSummaryInfo">{">"}</span>
                  <img
                    className="multiThreatIconCustomSizes threatVulnIcon"
                    src="/riskPilotIcons/VulnerbilityIcon.svg"
                  />
                  <div className="riskRegisterHeading threat">{`Vulnerability: ${vulnerability.vulnerability}`}</div>
                </div>
              </div>
              <div>
                <Button
                  color="secondary"
                  onClick={() =>
                    handleDeleteVulnerability(
                      dispatch,
                      vulnerability.id,
                      threatId
                    )
                  }
                >
                  <img src={DeleteRRIcon} alt="delete icon" />
                </Button>
              </div>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="vulnerabilityChildContainer">
            <div>
              {renderVulnerability(threatId, vulnerability)}
              <div className="riskThreatContainer" style={{ marginLeft: "1%" }}>
                <Typography variant="subtitle1">
                  <div className="riskAddThreatContainer">
                    <img
                      className="addMitigationIcon"
                      src="/riskPilotIcons/MitigationIcon.svg"
                      alt="icon"
                    />
                    {renderHeading(`Mitigation`, "threat")}
                  </div>
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    minWidth: "30px",
                    padding: "0px",
                    background: "#8015BD",
                    color: "white",
                    borderRadius: "5px",
                  }}
                  onClick={() =>
                    handleAddMitigation(
                      dispatch,
                      threatId,
                      vulnerability.id,
                      mitigations,
                      riskOwners
                    )
                  }
                >
                  +
                </Button>
              </div>
            </div>
            <div>
              {vulnerability.mitigations.map((mitigationId) => (
                <RiskPilotMitigation
                  key={mitigationId}
                  vulnerabilityId={vulnerability.id}
                  vulnerabilityName={vulnerability.vulnerability}
                  threatId={threatId}
                  threatName={threatName}
                  mitigation={mitigations[mitigationId]}
                  mitigationExpanded={mitigationExpanded}
                  handleMitigationAccordionChange={
                    handleMitigationAccordionChange
                  }
                  riskOwners={riskOwners}
                />
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RiskPilotVulnerability;
