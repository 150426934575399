import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import "../../assets/scss/admin-styles/custom/loginpagestyle.scss";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(6),
    },
  },
}))(InputBase);

const Input = ({
  type,
  labelName,
  placeholder,
  classname,
  value,
  eventHandler,
  containerName,
  disabled,
  loc,
  minimum,
  maximum
}) => {
  // const [text, setText] = useState("");
  // const handleChange = (event) => {
  //   console.log(event.target.value);
  //   setText(event.target.value);
  // };
  return (
    <div className={containerName ? containerName : "inputContainer"}>
      <h3 className={classname === "signinpage" ? "margin" : null}>
        {labelName}
      </h3>
      <BootstrapInput
        placeholder={placeholder}
        type={type}
        className={classname !== "signinpage" ? classname : "email-input"}
        value={value}
        onChange={
          eventHandler ? (event) => eventHandler(event.target.value) : null
        }
        disabled={disabled ? disabled : false}
        inputProps={{
          min: loc === "generateReport" ? minimum : null,
          max: loc === "generateReport" ? maximum : null
        }}
      />
    </div>
  );
};

export default Input;
