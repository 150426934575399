import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSelector, useDispatch } from "react-redux";
import { Input, Grid } from "@material-ui/core";
import { DateRange } from "react-date-range";
import "./customCalender.scss";
import {
  addDays,
  startOfDay,
  add,
  startOfWeek,
  endOfWeek,
  addMonths,
  endOfMonth,
  startOfMonth,
  subMinutes,
  subHours,
  endOfDay,
} from "date-fns";
import moment from "moment";
import MaskedInput from "react-text-mask";
import { dateFormat } from "../../../redux/constants/constants";
import TimepickerInput from "./TimepickerInput";
import {
  DATABASE_LOGS_TO_TIME,
  DATABASE_LOGS_FROM_TIME,
} from "../../../redux/actions/DatabaseLogsAction";

const defineds = {
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),
  endOfNext3Month: endOfMonth(addMonths(new Date(), 3)),
  endOfNext6Month: endOfMonth(addMonths(new Date(), 6)),
  after1week: add(new Date(), { weeks: 1 }),
  after1Month: add(new Date(), { months: 1 }),
  after3Months: add(new Date(), { months: 3 }),
  after6Months: add(new Date(), { months: 6 }),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  past0Minutes: subMinutes(new Date(), 0),
  past15Minutes: subMinutes(new Date(), 15),
  past5Minutes: subMinutes(new Date(), 5),
  past30Minutes: subMinutes(new Date(), 30),
  past0Hour: subHours(new Date(), 0),
  past1Hour: subHours(new Date(), 1),
  past12Hours: subHours(new Date(), 12),
  past24Hours: subHours(new Date(), 24),
  startOfToday: startOfDay(new Date()),
  past7Days: startOfDay(addDays(new Date(), -7)),
};

const dateInput = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-1]/,
        /[0-9]/,
        "/",
        /[0-3]/,
        /[0-9]/,
        "/",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholder={dateFormat}
    />
  );
};

export const DatabaseDateRangePicker = (props) => {
  const { loc, timeStamps } = props;
  const [timeRange, setTimeRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [fromDateValue, setFromDateValue] = useState(new Date());
  const [toDateValue, setToDateValue] = useState(new Date());
  const [fromTimeValue, setFromTimeValue] = useState(
    moment().subtract(1, "hours").format("HH:mm:ss A")
  );
  const [toTimeValue, setToTimeValue] = useState(moment().format("HH:mm:ss A"));

  const fromTimeRange = useSelector(
    (state) => state.databaseLogsReducer.fromTimeValue
  );
  const toTimeRange = useSelector(
    (state) => state.databaseLogsReducer.toTimeValue
  );
  const fromTimeRange_serviceDataAccess = useSelector(
    (state) => state.serviceAccessLogsReducer.fromTimeValue
  );
  const toTimeRange_serviceDataAccess = useSelector(
    (state) => state.serviceAccessLogsReducer.toTimeValue
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (loc === "service-database-logs") {
      setTimeRange([
        {
          startDate: new Date(
            moment(fromTimeRange_serviceDataAccess).format("MM-DD-YYYY")
          ),
          endDate: new Date(
            moment(fromTimeRange_serviceDataAccess).format("MM-DD-YYYY")
          ),
          key: "selection",
        },
      ]);
      setFromDateValue(new Date(fromTimeRange_serviceDataAccess));
      setToDateValue(new Date(toTimeRange_serviceDataAccess));
      setFromTimeValue(
        moment(fromTimeRange_serviceDataAccess).format("HH:mm:ss")
      );
      setToTimeValue(moment(toTimeRange_serviceDataAccess).format("HH:mm:ss"));
    } else {
      setTimeRange([
        {
          startDate: new Date(moment(fromTimeRange).format("MM-DD-YYYY")),
          endDate: new Date(moment(toTimeRange).format("MM-DD-YYYY")),
          key: "selection",
        },
      ]);
      setFromDateValue(new Date(fromTimeRange));
      setToDateValue(new Date(toTimeRange));
      setFromTimeValue(moment(fromTimeRange).format("HH:mm:ss"));
      setToTimeValue(moment(toTimeRange).format("HH:mm:ss"));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //To dispatch the from date and time
  const fromTimeDispatch = (startDate) => {
    const fromTimeVal = `${("0" + startDate.getHours()).slice(-2)}:${(
      "0" + startDate.getMinutes()
    ).slice(-2)}:${("0" + startDate.getSeconds()).slice(-2)}`;
    setFromTimeValue(fromTimeVal);
    if (loc === "service-database-logs") {
      let fromTime = startDate.getTime();
      dispatch({
        type: "SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE_ON_MOUNT",
        payload: fromTime,
      });
      dispatch({
        type: "SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE",
        payload: fromTime,
      });
    } else if (loc === "database-logs") {
      let fromTime = startDate.getTime();
      dispatch(DATABASE_LOGS_FROM_TIME(fromTime));
    }
  };

  //To dispatch the to date and time 
  const toTimeDispatch = (toDate) => {
    const toTimeVal = `${("0" + toDate.getHours()).slice(-2)}:${(
      "0" + toDate.getMinutes()
    ).slice(-2)}:${("0" + toDate.getSeconds()).slice(-2)}`;
    setToTimeValue(toTimeVal);
    if (loc === "service-database-logs") {
      let toTime = toDate.getTime();
      dispatch({
        type: "SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE_ON_MOUNT",
        payload: toTime,
      });
      dispatch({
        type: "SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE",
        payload: toTime,
      });
    } else if (loc === "database-logs") {
      let toTime = toDate.getTime();
      dispatch(DATABASE_LOGS_TO_TIME(toTime));
    }
  };

  //To set a range for minimum Date for user to enter 
  //Date picker default: 100 years before the current date
  const minDate = new Date().setFullYear(new Date().getFullYear() - 100);

  //To set a range for maximum Date for user to enter
  //Date picker default: 20 years after the current date
  const maxDate = new Date().setFullYear(new Date().getFullYear() + 20);

  //To check whether the given input is a valid date or not
  const dateIsValid = (date) => {
    //if the user gives the date out of range it is not valid
    if (date > maxDate || date < minDate) {
      return false;
    } else {
      return date instanceof Date && !isNaN(date);
    }
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setFromDateValue(selection.startDate);
    setToDateValue(endOfDay(new Date(selection.endDate)));
    setTimeRange([selection]);
    let startDate = new Date(selection.startDate);
    let endDate = endOfDay(new Date(selection.endDate));
    fromTimeDispatch(startDate);
    toTimeDispatch(endDate);
  };

  //if the user wants to change the "from date" from input field 
  const fromDateChange = (fromDate) => {
    let startDate = new Date(fromDate);
    let endDate = endOfDay(toDateValue);
    if (dateIsValid(startDate)) {
      //To check whether the startDate is less than the endDate or not
      if (startDate > endDate) {
        setFromDateValue(startDate);
        setToDateValue(startDate);
        setTimeRange([{
          startDate: startDate,
          endDate: startDate,
          key: "selection",
        }]);
      } else {
        setFromDateValue(startDate);
        setToDateValue(endDate);
        setTimeRange([{
          startDate: startDate,
          endDate: endDate,
          key: "selection"
        }]);
      }
      fromTimeDispatch(startDate);
      toTimeDispatch(endDate);
    }
  };
 
  //if the user wants to change the "to date" from input field 
  const toDateChange = (toDate) => {
    let startDate = fromDateValue;
    let endDate = endOfDay(new Date(toDate));
    if (dateIsValid(endDate)) {
      //To check whether the endDate is greater than the startDate or not 
      if (endDate < startDate) {
        setFromDateValue(endDate);
        setToDateValue(endDate);
        setTimeRange([{
          startDate: endDate,
          endDate: endDate,
          key: "selection"
        }]);
      } else {
        setFromDateValue(startDate);
        setToDateValue(endDate);
        setTimeRange([{
          startDate: startDate,
          endDate: endDate,
          key: "selection"
        }]);
      }
      fromTimeDispatch(startDate);
      toTimeDispatch(endDate);
    }
  };

  const fromTimeChange = (name) => (event) => {
    if (event.target.value) {
      setFromTimeValue(event.target.value);
      const tarValSpl = event.target.value.split(":");
      if (
        tarValSpl.length === 3 &&
        !isNaN(tarValSpl[0]) &&
        !isNaN(tarValSpl[1]) &&
        !isNaN(tarValSpl[2])
      ) {
        fromDateValue.setHours(tarValSpl[0]);
        fromDateValue.setMinutes(tarValSpl[1]);
        fromDateValue.setSeconds(tarValSpl[2]);
        setFromDateValue(fromDateValue);
        let fromTime = fromDateValue.getTime();
        if (loc === "service-database-logs") {
          dispatch({
            type: "SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE_ON_MOUNT",
            payload: fromTime,
          });
          dispatch({
            type: "SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE",
            payload: fromTime,
          });
        } else {
          dispatch(DATABASE_LOGS_FROM_TIME(fromTime));
        }
        // handleDateRangeDiff(toDateValue, fromDateValue);
      }
    }
  }; // QuickRanges = (time) =>{

  // }
  const toTimeChange = (name) => (event) => {
    if (event.target.value) {
      setToTimeValue(event.target.value);
      const tarValSpl = event.target.value.split(":");
      if (
        tarValSpl.length === 3 &&
        !isNaN(tarValSpl[0]) &&
        !isNaN(tarValSpl[1]) &&
        !isNaN(tarValSpl[2])
      ) {
        toDateValue.setHours(tarValSpl[0]);
        toDateValue.setMinutes(tarValSpl[1]);
        toDateValue.setSeconds(tarValSpl[2]);
        setToDateValue(toDateValue);
        let toTime = toDateValue.getTime();
        if (loc === "service-database-logs") {
          dispatch({
            type: "SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE_ON_MOUNT",
            payload: toTime,
          });
          dispatch({
            type: "SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE",
            payload: toTime,
          });
        } else if (loc === "database-logs") {
          dispatch(DATABASE_LOGS_TO_TIME(toTime));
        }
      }
    }
  };
  const returnDateRanges = () => {
    return (
      <>
        <div
          className="week"
          onClick={() =>
            handleOnChange({
              selection: {
                startDate: defineds.past5Minutes,
                key: "selection",
                endDate: defineds.past0Minutes,
              },
            })
          }
        >
          Past 5 Mins
        </div>
        <div
          className="week"
          onClick={() =>
            handleOnChange({
              selection: {
                endDate: defineds.past0Minutes,
                key: "selection",
                startDate: defineds.past30Minutes,
              },
            })
          }
        >
          Past 30 Mins
        </div>
        <div
          className="week"
          onClick={() =>
            handleOnChange({
              selection: {
                endDate: defineds.past0Hour,
                key: "selection",
                startDate: defineds.past1Hour,
              },
            })
          }
        >
          Past 1 Hour
        </div>
        <div
          className="month"
          onClick={() =>
            handleOnChange({
              selection: {
                endDate: defineds.past0Hour,
                key: "selection",
                startDate: defineds.past12Hours,
              },
            })
          }
        >
          Past 12 Hours
        </div>

        <div
          className="month"
          onClick={() =>
            handleOnChange({
              selection: {
                endDate: defineds.past0Hour,
                key: "selection",
                startDate: defineds.past24Hours,
              },
            })
          }
        >
          Past 24 Hours
        </div>
        <div
          className="month"
          onClick={() =>
            handleOnChange({
              selection: {
                endDate: defineds.startOfToday,
                key: "selection",
                startDate: defineds.past7Days,
              },
            })
          }
        >
          Past 7 Days
        </div>
      </>
    );
  };

  const getFromAndToInputFields = () => {
    return (
      <Grid container spacing={2} className="filter-time-container">
        <Grid item xs={6}>
          <div>
            <span className="range-text">From</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Input
              value={moment(fromDateValue).format(dateFormat)}
              inputComponent={dateInput}
              onBlur={(event) => fromDateChange(event.target.value)}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className="range-text">To</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Input
              value={moment(toDateValue).format(dateFormat)}
              inputComponent={dateInput}
              onBlur={(event) => toDateChange(event.target.value)}
            ></Input>
          </div>
        </Grid>
      </Grid>
    );
  };
  const getTimeFields = () => {
    return (
      <Grid container spacing={2} className="filter-time-container">
        <Grid item xs={6}>
          <div>
            <span className="range-text">From</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Input
              value={fromTimeValue}
              inputComponent={TimepickerInput}
              onChange={fromTimeChange("textmask")}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className="range-text">To</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Input
              value={toTimeValue}
              inputComponent={TimepickerInput}
              onChange={toTimeChange("textmask")}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="database-logs-access-picker">
      <div className="header-text">Select a Date Range</div>
      <DateRange
        style={{ width: "275px" }}
        moveRangeOnFirstSelection={false}
        onChange={handleOnChange}
        ranges={timeRange}
        showDateDisplay={false}
      />
      <div style={{ width: "275px" }}>{getFromAndToInputFields()}</div>

      {loc === "database-logs" || loc === "service-database-logs" ? (
        <>
          <div className="quick-range-heading">Time Range</div>
          <div style={{ width: "275px" }}>{getTimeFields()}</div>
        </>
      ) : (
        ""
      )}

      <div className="quick-range-heading">Quick Ranges</div>
      {returnDateRanges()}
    </div>
  );
};
