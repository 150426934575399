import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import SortIcon from "../../assets/vizr_images/ic_sort-1.svg";
import Popper from "@material-ui/core/Popper";
import ClearFilter from "../shared/clearFilter";
import Fade from "@material-ui/core/Fade";
// import SortComponent from "../shared/sortComponent";
import DashboardMDRGlobalFilter from "./DashboardMDRGlobalFilter";
import DashboardMDRGlobalFilterViewPanel from "./DashboardMDRGlobalFilterViewPanel";
import { getCubejsApi } from "../common/Dashboard/cubejs-util";
import { getService, getAxiosHeaders } from "../../library/RestAPI";
import {
  SAVE_MDR_GLOBAL_FILTERS,
  GET_MDR_GLOBAL_FILTERS,
} from "../../redux/constants/constants";
import { cloneDeep } from "lodash";
import {
  addGlobalFiltersForDashboard,
  masterArr,
  MDRMultipleFilters_Merge_Helper,
  addingDateRangetoTableQueries,
} from "../../components/common/Dashboard/MDRHelpers";
import { redirectToLogin } from "../common/Dashboard/cubejs-util";
import { MDRContext } from "./DashboardMDR";

const DashboardMDRGlobalFilters = ({
  globalFiltersIntialList,
  setGlobalFilters,
}) => {
  const dispatch = useDispatch();
  let cubejsApi = getCubejsApi();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  const handleClick = (event, newPlacement) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const clearFilterButtonClick = () => {
    if (true) {
      //   dispatch({ type: "DATA_FLAGGED_SOURCE_CLEAR_FILTER_DATA" });
      setOpen(false);
    } else {
      //   dispatch({ type: "DATA_SOURCE_CLEAR_FILTER_DATA" });
      setOpen(false);
    }
  };
  const [singleFilterValuesObj, setSingleFilterValuesObj] = useState([]);
  const [singleFilterValues, setSingleFilterValues] = useState([]);
  // const [globalFilterIds, setglobalFilterIds] = useState([]);

  console.log("testetst", singleFilterValuesObj, singleFilterValues);

  const hadleGlobalFiltersDelete = (data) => {
    //! For Mainting the checkboxes
    const copyOfData = [...singleFilterValues];
    const temp = copyOfData.filter((each) => each.id !== data.id);
    setSingleFilterValues([...temp]);

    //! For Maintaining the single filterObj
    let copyOfDataObj = [...singleFilterValuesObj];
    const temp2 = copyOfDataObj.filter((each) => each.id !== data.id);
    setSingleFilterValuesObj([...temp2]);
  };

  let idTobeRemoved;
  const checkCondition = (id, type, value, name) => {
    let bool;

    for (let i = 0; i < singleFilterValues.length; i++) {
      console.log(singleFilterValues[i], "testestest");
      if (
        singleFilterValues[i].value === value &&
        singleFilterValues[i].name === name
      ) {
        idTobeRemoved = i;
        bool = true;
        break;
      }
    }
    return bool;
  };
  const MDRDropdownValue = useSelector(
    (state) => state.IncidenetReducer.MDRDropdownValue
  );
  const initilizeGlobalFilter = () => {
    if (globalFiltersIntialList.length) {
      let singleArr = [];
      let singleArr2 = [];
      // let idArr = [];
      globalFiltersIntialList.forEach((el, index) => {
        // idArr.push(el["id"]);
        singleArr.push({
          id: el["id"],
          value: el["columnValue"],
          type: el["type"],
          name: el["columnName"],
        });
        singleArr2.push({
          id: el["id"],
          value: el["columnValue"],
          type: el["type"],
          name: el["columnName"],
        });
      });
      setSingleFilterValues([...singleArr]);
      setSingleFilterValuesObj([...singleArr2]);
      // setglobalFilterIds([...idArr]);
    } else {
      setSingleFilterValues([]);
      setSingleFilterValuesObj([]);
    }
  };

  useEffect(() => {
    initilizeGlobalFilter();
  }, [globalFiltersIntialList, MDRDropdownValue, open]);

  const globalFiltersClickHandler = (event, id, value, type, name) => {
    console.log("testestetst", name);
    //! For Mainting the checkboxes
    const copyOfData = [...singleFilterValues];
    if (!checkCondition(id, type, value, name)) {
      copyOfData.push({ id: id, type: type, name: name, value: value });
      setSingleFilterValues([...copyOfData]);

      //! For Maintaining the single filterObj
      setSingleFilterValuesObj([...copyOfData]);
    } else {
      let temp = [];
      if (idTobeRemoved >= 0) {
        copyOfData.forEach((el, index) => {
          console.log(el, idTobeRemoved, "t6estestest");
          if (index !== idTobeRemoved) {
            temp.push(el);
          } else if (index === idTobeRemoved && el["type"] !== type) {
            temp.push({ id: el.id, type: type, name: name, value: value });
          }
        });
      }

      //todo: second logic
      // copyOfData.forEach((each) => {
      //   console.log(each, "testestest");
      //   if (each.value === value && each.name === name) {
      //     temp.push({ id: each.id, type: type, name: name, value: value });
      //   } else {
      //     temp.push(each);
      //   }
      // });
      console.log(temp, "temp");
      setSingleFilterValues([...temp]);

      //! For Maintaining the single filterObj
      setSingleFilterValuesObj([...temp]);
    }
  };
  const [totalFilters, setTotalFilters] = useState([]);
  const [filterValues, setfilterValues] = useState({});

  useEffect(() => {
    const getAllFilters = async () => {
      setTotalFilters(["Subscription Name", "Resource"]);
      let filterValuesObj = { "Subscription Name": [], Resource: [] };
      setfilterValues({ ...filterValuesObj });
    };
    getAllFilters();
  }, []);

  console.log(filterValues, "filterValues");
  useEffect(() => {
    if (totalFilters.length) {
      let arr = totalFilters.map((el, index) => {
        console.log("testing", masterArr[el]);
        return cubejsApi.load({
          dimensions: [`MDRPatchManagement.${masterArr[el]}`],
        });
      });
      Promise.all([...arr]).then((data) => {
        // const response = data.rawData();
        console.log(data);
        data.forEach((el, index) => {
          let valArr = el.rawData();
          console.log(valArr, "test");
          let valArrremoveNull = valArr.filter((el) =>
            el[`MDRPatchManagement.${masterArr[totalFilters[index]]}`]
              ? true
              : false
          );
          setfilterValues((prevState) => {
            return {
              ...prevState,
              [totalFilters[index]]: valArrremoveNull,
            };
          });
        });
      });
    }
  }, [totalFilters]);

  const latestdashboardInstance = useSelector(
    (state) => state.IncidenetReducer.incidentdashboardInstance
  );

  const patchMonitoringTableQuery = useSelector(
    (state) => state.MDRPatchMonitoringReducer.patchMonitoringTableQuery
  );
  let MDRPatchMonitoringClassificationFilter = useSelector(
    (state) => state.MDRPatchMonitoringReducer.Classification
  );

  let MDRPatchMonitoringSeverityFilter = useSelector(
    (state) => state.MDRPatchMonitoringReducer.Severity
  );
  let MDRPatchMonitoringSubscriptionNameFilter = useSelector(
    (state) => state.MDRPatchMonitoringReducer.SubscriptionName
  );
  let MDRPatchMonitoringUpdateStateFilter = useSelector(
    (state) => state.MDRPatchMonitoringReducer.UpdateState
  );

  const dateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );
  const mdrContext = useContext(MDRContext);

  let asyncFunc = async (singleFilterValuesObj) => {
    let tableQuerys = patchMonitoringTableQuery;
    mdrContext.setDataLoaded(false);
    console.log("tableQuerys", tableQuerys);
    let loc = tableQuerys[0].loc;
    let filters = tableQuerys[0].query[0].filters;
    let tableNameArr = tableQuerys[0].query[0].dimensions
      ? tableQuerys[0].query[0].dimensions[0]
      : "";
    let tableName = tableNameArr && tableNameArr.split(".")[0];
    if (!filters || filters.length === 0) {
      let filterArr = [];
      singleFilterValuesObj.forEach((obj) => {
        if (obj["type"] === "include") {
          let bool = false;
          filterArr.forEach((el, index) => {
            if (el["member"] === `${tableName}.${obj["name"]}`) {
              filterArr[index]["values"].push(`${obj["value"]}`);
              bool = true;
            }
          });
          if (!bool) {
            filterArr.push({
              member: `${tableName}.${obj["name"]}`,
              operator: "equals",
              values: [`${obj["value"]}`],
            });
          }
        }
        if (obj["type"] === "exclude") {
          let bool = false;

          filterArr.forEach((el, index) => {
            if (el["member"] === `${tableName}.${tableName}.${obj["name"]}`) {
              filterArr[index]["values"].push(`${obj["value"]}`);
              bool = true;
            }
          });
          if (!bool) {
            filterArr.push({
              member: `${tableName}.${obj["name"]}`,
              operator: "notEquals",
              values: [`${obj["value"]}`],
            });
          }
        }
      });
      console.log("tstestestt", filterArr);
      tableQuerys[0].query[0].filters = filterArr;
      tableQuerys[0].query[1].filters = filterArr;
    } else {
      let timeFil = addingDateRangetoTableQueries(tableQuerys, dateTimeRange);
      let filterArr = [];
      singleFilterValuesObj.forEach((obj) => {
        if (obj["type"] === "include") {
          let bool = false;
          filterArr.forEach((el, index) => {
            if (el["member"] === `${tableName}.${obj["name"]}`) {
              if (!el["values"].includes(`${obj["value"]}`)) {
                filterArr[index]["values"].push(`${obj["value"]}`);
              }
              // filterArr[index]["values"].push(`${obj["value"]}`);
              bool = true;
            }
          });
          if (!bool) {
            filterArr.push({
              member: `${tableName}.${obj["name"]}`,
              operator: "equals",
              values: [`${obj["value"]}`],
            });
          }
        } else if (obj["type"] === "exclude") {
          let bool = false;

          filterArr.forEach((el, index) => {
            console.log(el["values"], "testing");
            if (el["member"] === `${tableName}.${obj["name"]}`) {
              if (!el["values"].includes(`${obj["value"]}`)) {
                filterArr[index]["values"].push(`${obj["value"]}`);
              }
              bool = true;
            }
          });
          if (!bool) {
            filterArr.push({
              member: `${tableName}.${obj["name"]}`,
              operator: "notEquals",
              values: [`${obj["value"]}`],
            });
          }
        }
      });
      const fil = MDRMultipleFilters_Merge_Helper([
        {
          values: MDRPatchMonitoringClassificationFilter,
          query: {
            member: "MDRPatchManagement.classification",
            operator: "equals",
            values: [],
          },
        },
        {
          values: MDRPatchMonitoringSeverityFilter,
          query: {
            member: "MDRPatchManagement.severity",
            operator: "equals",
            values: [],
          },
        },
        {
          values: MDRPatchMonitoringUpdateStateFilter,
          query: {
            member: "MDRPatchManagement.updateState",
            operator: "equals",
            values: [],
          },
        },
        {
          values: MDRPatchMonitoringSubscriptionNameFilter,
          query: {
            member: "MDRPatchManagement.subscriptionName",
            operator: "equals",
            values: [],
          },
        },
      ]);
      console.log(fil, filterArr, timeFil, "testesttest");
      tableQuerys[0].query[0].filters = [...filterArr, ...fil, ...timeFil];
      tableQuerys[0].query[1].filters = [...filterArr, ...fil, ...timeFil];
    }
    let response;
    let response2;
    try {
      const resultSet = await cubejsApi.load(tableQuerys[0].query[1]);
      response = resultSet.rawData();
    } catch (error) {
      redirectToLogin(error);
    }
    try {
      const resultSet2 = await cubejsApi.load(tableQuerys[0].query[0]);
      response2 = resultSet2.rawData();
    } catch (error) {
      redirectToLogin(error);
    }
    dispatch({
      type: "CHANGE_PATCHMONITORING_TABLE_DATA",
      payload: {
        data: response2,
        loc: loc,
        totalTableDataCount: response[0][`${tableQuerys[0].queryCall}.count`],
        dateRangeChanged: true,
      },
    });
    mdrContext.setDataLoaded(true);
  };
  const patchMonitoringSaveorApplyFilters = async (buttonType) => {
    //! if user clicks on saveand apply on global filter
    if (buttonType === "saveandApply") {
      // ! converyting in to format the backend API accepts
      let postArr = singleFilterValuesObj.map((el) => {
        return {
          columnName: el["name"],
          columnValue: el["value"],
          type: el["type"],
        };
      });
      setGlobalFilters([...postArr]);
      //! posting the global filters to backend API
      const res = await getService({
        method: "POST",
        url: `${SAVE_MDR_GLOBAL_FILTERS}`,
        headers: getAxiosHeaders(true),
        data: postArr.length ? postArr : [],
      });

      console.log(res);

      const response = await getService({
        method: "GET",
        url: `${GET_MDR_GLOBAL_FILTERS}`,
        headers: getAxiosHeaders(true),
      });

      if (response.status === 200 && response.data.content) {
        setGlobalFilters(
          response.data && response.data.content ? response.data.content : []
        );
        sessionStorage.setItem(
          "globalFiltersIntialList",
          JSON.stringify(
            response.data && response.data.content ? response.data.content : []
          )
        );
      }
      //! Logic for updating the dashboard widgets with global filters
      let patchmonitorUpdatedData = cloneDeep(
        addGlobalFiltersForDashboard(
          [...latestdashboardInstance],
          singleFilterValuesObj,
          masterArr
        )
      );
      console.log(patchmonitorUpdatedData, "patchmonitorUpdatedData");
      dispatch({
        type: "CHANGE_MDR_DASHBOARD",
        payload: [...patchmonitorUpdatedData],
      });

      //add globalFilterstoSessionstorage
      let filterArr = [];
      singleFilterValuesObj.forEach((obj) => {
        if (obj["type"] === "include") {
          let bool = false;

          filterArr.forEach((el, index) => {
            if (el["member"] === `MDRPatchManagement.${obj["name"]}`) {
              filterArr[index]["values"].push(`${obj["value"]}`);
              bool = true;
            }
          });
          if (!bool) {
            filterArr.push({
              member: `MDRPatchManagement.${obj["name"]}`,
              operator: "equals",
              values: [`${obj["value"]}`],
            });
          }
        } else if (obj["type"] === "exclude") {
          let bool = false;

          filterArr.forEach((el, index) => {
            if (el["member"] === `MDRPatchManagement.${obj["name"]}`) {
              filterArr[index]["values"].push(`${obj["value"]}`);
              bool = true;
            }
          });
          if (!bool) {
            filterArr.push({
              member: `MDRPatchManagement.${obj["name"]}`,
              operator: "notEquals",
              values: [`${obj["value"]}`],
            });
          }
        }
      });

      sessionStorage.setItem("globalFiltersObj", JSON.stringify(filterArr));

      // ! Logic to add global filters for table
      asyncFunc(singleFilterValuesObj);
    } else if (buttonType === "clearAll") {
      setSingleFilterValues([]);

      //!Clearing Global filters
      sessionStorage.setItem("globalFiltersObj", "[]");
      sessionStorage.setItem("globalFiltersIntialList", "[]");
      setGlobalFilters([]);
      //! For Maintaining the single filterObj
      setSingleFilterValuesObj([]);

      const response = await getService({
        method: "POST",
        url: `${SAVE_MDR_GLOBAL_FILTERS}`,
        headers: getAxiosHeaders(true),
        data: [],
      });
      console.log(response);
      let patchmonitorUpdatedData = cloneDeep(
        addGlobalFiltersForDashboard(
          [...latestdashboardInstance],
          [],
          masterArr
        )
      );

      dispatch({
        type: "CHANGE_MDR_DASHBOARD",
        payload: [...patchmonitorUpdatedData],
      });
      // ! Logic to add global filters for table
      asyncFunc([]);
    }
  };
  const renderPopover = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{
          boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
          marginTop: "10rem",
          marginRight: "1rem",
          zIndex: "10",
        }}
      >
        {({ TransitionProps }) => (
          <>
            <div className="clear-filter-container">
              <ClearFilter
                className="clear-filter"
                clearFilterButtonClick={clearFilterButtonClick}
                toggle={(event, type, buttonType) => {
                  handleClick(event, type);
                  if (buttonType) {
                    patchMonitoringSaveorApplyFilters(buttonType);
                  }
                }}
                loc={"Mdr_PatchMonitoring"}
              />
            </div>
            <div className="popper-root" style={{ height: "742px" }}>
              <Fade {...TransitionProps} timeout={350}>
                <div style={{ width: "420px", height: "705px" }}>
                  <DashboardMDRGlobalFilter
                    globalFiltersClickHandler={globalFiltersClickHandler}
                    totalFilters={totalFilters}
                    filterValues={filterValues}
                    singleFilterValues={singleFilterValues}
                  />
                </div>
              </Fade>
              <div
                style={{
                  minheight: "350px",

                  width: "400px",
                  display: "flex",
                  flexDirection: "column",
                  // marginTop: "2rem",
                  paddingLeft: "1.5rem",
                  overflow: "hidden auto",
                }}
              >
                <DashboardMDRGlobalFilterViewPanel
                  singleFilterValuesObj={singleFilterValuesObj}
                  hadleGlobalFiltersDelete={hadleGlobalFiltersDelete}
                />
              </div>
            </div>
          </>
        )}
      </Popper>
    );
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{
          color: "#081981",
          backgroundColor: "transparent",
          fontSize: "14px",
          fontWeight: "bold",
          marginLeft: "60%",
          textTransform: "none",
        }}
        onClick={(event) => handleClick(event, "bottom")}
        startIcon={<img src={SortIcon} alt="sort-filter" />}
        className="min-margin table-buttons"
      >
        Global Filter
      </Button>
      {renderPopover()}
    </div>
  );
};

export default React.memo(DashboardMDRGlobalFilters);
