import React, { useState, useEffect } from "react";
import { ColoredActionButton } from "../common/buttons";
import { Button } from "@material-ui/core";
import ErrorIcon from '../../assets/icons/ErrorIcon.svg';
import ConnectedIcon from "../../assets/icons/ConnectedIcon.svg"
import NotConnected from "../../assets/icons/NotConnected.svg"

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Operation from "antd/lib/transfer/operation";
import { Graph2d } from "vis";
import { useLocation } from "react-router-dom";

const OPERATIONS = {
  DropboxConnect: 'DropboxConnect',
}

export const DropBoxConnection = (props) => {
  const cancelErrorInfo = "Something went wrong, Please try again";
  const currentRoute = useLocation();
  const connectToolTip = 'Access to users, roles, sites, files data through the Dropbox Connect API'
  const { onHandleConnect, dataSourceData } = props;
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [error, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [errorOcurred, setErrorOcurred] = useState(false);
  const dispatch = useDispatch();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    const urlParam = new URLSearchParams(currentRoute.search);
    const error_description = urlParam.get('error_description');
    if (!isEmpty(error_description)) {
      setErrorOcurred(true);
    }
  }, [currentRoute])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const getButtonText = (secretKey) => {
    if ((dataSourceData && dataSourceData[secretKey])) {
      return "Connect";
    } else if (dataSourceData.id) {
      return "Connect"
    } else {
      return "Save & Connect"
    }
  }

  const graphButton = () => {
    return getButtonText("secret1");
  }

  const getConnectedStatus = () => {
    return (
      <div className="one-drive-status-icon not-error">
        <img src={ConnectedIcon} />
        <span className="icon-status connected one-drive-status-text">Connected</span>
      </div>
    )
  };

  const getNotConnectedStatus = () => {
    return (
      <div className="one-drive-status-icon not-error">
        <img src={NotConnected} />
        <span className="icon-status not-connected one-drive-status-text">Not connected</span>
      </div>
    )
  };

  const getErrorStatus = (errorInfo) => {
    return (
      <div className="one-drive-status-icon error-occurred">
        <img src={ErrorIcon} />
        <span className="icon-status error one-drive-status-text">{errorInfo}</span>
      </div>
    )
  };

  const getSnackBarStatus = (secretKey) => {
    //If we receive any error from backend 
    if (dataSourceData && isNull(dataSourceData[secretKey]) && !isNull(dataSourceData.errorInfo)) {
      return getErrorStatus(dataSourceData.errorInfo);
    }
    //If we receive any error from Microsoft 365 
    if (dataSourceData && isNull(dataSourceData[secretKey]) && isNull(dataSourceData.errorInfo) && errorOcurred) {
      return getErrorStatus(cancelErrorInfo);
    }
    //To see if the Api's are connected or not
    if (dataSourceData && dataSourceData[secretKey]) {
      return getConnectedStatus();
    } else if (dataSourceData && isNull(dataSourceData[secretKey])) {
      return getNotConnectedStatus();
    }
  }

  return (
    <>
      <div className="one-drive-buttons">
        <div className="connection-group  graph-api">
          <div className="one-drive-status-group">
            <div className="one-drive-status-icon dropbox" title={connectToolTip}>Access to Users and Files</div>
            <div className="dropBoxButtons">
              <div className="one-drive-button-style">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={(event) => onHandleConnect(event, OPERATIONS.DropboxConnect, dispatch)}
                >
                  {
                    graphButton()
                  }
                </Button>
              </div>
              <div className="one-drive-button-style">
                <ColoredActionButton
                  style={{
                    height: "37px",
                    fontSize: "0.87rem",
                    boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
                  }}
                  onClick={(event) => props.toggleDrawer(false, event)}
                >
                  Cancel
                </ColoredActionButton>
              </div>
            </div>
            {getSnackBarStatus("secret1")}
          </div>
        </div>
      </div>
      <div className="button-group">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          onExited={handleExited}
        >
          <Alert onClose={handleClose} severity={"error"}>
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}