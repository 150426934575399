import React from "react";
import { useDispatch } from "react-redux";
import unChecked from "../../../../assets/vizr_images/Input_cell.svg";
import checked from "../../../../assets/vizr_images/Active_Input.svg";
import "./IncidentTableStyles.scss";

const UseIncidentFilterHook = ({
  query,
  action,
  filterType,
  filterData,
  className,
}) => {
  const dispatch = useDispatch();

  const selectClickHandler = (index) => {
    let data = [...filterData];
    data[index].isSelected = data[index].isSelected ? false : true;
    dispatch(
      action({
        filterType: filterType,
        data: data,
        addIsSelected: false,
        dimensions: query.dimensions[0],
      })
    );
  };

  return (
    <>
      {filterData &&
        filterData.length > 0 &&
        filterData.map((el, index) => {
          return (
            el.data && (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
                className={className}
              >
                <img
                  src={el.isSelected ? checked : unChecked}
                  alt="select"
                  onClick={() => selectClickHandler(index)}
                  className="filterStyle"
                ></img>
                <div
                  className={className && el.data ? el.data.toLowerCase() : ""}
                >
                  {el.data}
                </div>
              </div>
            )
          );
        })}
    </>
  );
};

export default UseIncidentFilterHook;
