import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCubejsApi } from "../../../common/Dashboard/cubejs-util";
import EnhancedGridTable from "../../../shared/Table/Table";
import { DirectDataSourceConstants } from "../AccessRightsutilities";
import page_loader from "../../../../assets/vizr_images/page_loader.gif";
import { usersWithAlertsBarChart } from "../../../common/Dashboard/custom-charts/usersWithAlerts";
import { EnhancedDirectDataSourceHeader } from "./EnhancedDirectDataSourceHeader";
import AlertDrawer from "../../../shared/alertDrawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { clearURLParam } from "../../../common/utilities";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AccessDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let cubejsApi = getCubejsApi();

  const allNormalDirectDataSourceUsers = useSelector(
    (state) =>
      state.normalDirectDataSourceReducer.allNormalDirectDataSourceUsers
  );
  const currentPage = useSelector(
    (state) => state.normalDirectDataSourceReducer.currentPage
  );
  const recordsPerPage = useSelector(
    (state) => state.normalDirectDataSourceReducer.recordsPerPage
  );
  const totalCount = useSelector(
    (state) => state.normalDirectDataSourceReducer.totalCount
  );
  const loader = useSelector(
    (state) => state.normalDirectDataSourceReducer.loader
  );
  const accessDetailsDrawer = useSelector(
    (state) => state.normalDirectDataSourceReducer.accessDetailsDrawer
  );
  const errorMessage = useSelector(
    (state) => state.normalDirectDataSourceReducer.errorMessage
  );
  const usersWithAlertsQueryParam = useSelector(
    (state) => state.normalDirectDataSourceReducer.usersWithAlertsQueryParam
  );
  const [open, setOpen] = useState(false);


  useEffect(() => {
    dispatch({
      type: "SET_NORMAL_DIRECT_DATA_SOURCE_LOADER_INFO",
      payload: true,
    });
    if (
      localStorage.getItem("data")
    ) {
      const data = JSON.parse(localStorage.getItem("data"))
      dispatch({
        type: "SET_FILTER_DIRECT_DATA_SOURCE_NAME",
        payload: data["ActorDatasource.dataSourceId"] || data["ActorDataSourceBySensitiveData.dataSourceId"],
      });
      dispatch({
        type: "MAINTAIN_DIRECT_DATA_SOURCE_SINGLE_DATA",
        payload: {
          name: data["ActorDatasource.aliasName"],
          value: data["ActorDatasource.dataSourceId"] || data["ActorDataSourceBySensitiveData.dataSourceId"],
          dispatchName: "SET_FILTER_DIRECT_DATA_SOURCE_NAME",
        },
      });   
      dispatch({ type: "SET_DIRECT_DATA_SOURCE_APPLY_DATA" });
      dispatch({ type: "GET_NORMAL_DIRECT_DATA_SOURCE_INFO_DATA", payload: usersWithAlertsQueryParam});
      localStorage.removeItem("data")
    } else {
      dispatch({ type: "GET_NORMAL_DIRECT_DATA_SOURCE_INFO_DATA", payload: usersWithAlertsQueryParam});
    }
    return () => {
      clearURLParam(history, false, dispatch);
    }
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setOpen(true);
    }
  }, [errorMessage]);

  const renderChartData = () => {
    return (
      <div style={{ marginLeft: "13rem", maxWidth: "84%" }}>
        {usersWithAlertsBarChart(
          cubejsApi,
          "Users with Anomalies over Time",
          "Users Accessing Data"
        )}
      </div>
    );
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch({
      type: "SET_NORMAL_DIRECT_DATA_SOURCE_RECORDS_PAGE",
      payload: value,
    });
    dispatch({
      type: "SET_NORMAL_DIRECT_DATA_SOURCE_CURRENT_PAGE",
      payload: 0,
    });
    dispatch({ type: "GET_NORMAL_DIRECT_DATA_SOURCE_INFO_DATA", payload: usersWithAlertsQueryParam });
  };

  const handleChangePage = (page) => {
    dispatch({
      type: "SET_NORMAL_DIRECT_DATA_SOURCE_CURRENT_PAGE",
      payload: page,
    });
    dispatch({ type: "GET_NORMAL_DIRECT_DATA_SOURCE_INFO_DATA", payload: usersWithAlertsQueryParam });
  };

  const tableRowsClickHandler = (row) => {
    dispatch({ type: "OPEN_ACCESS_DRAWER", payload: row });
  };

  const renderTableToolbar = () => {
    return (
      <EnhancedDirectDataSourceHeader
        totalElements={totalCount}
        rows={allNormalDirectDataSourceUsers}
        loc={"Access_details"}
        title={"Data Source Users"}
        helpDescription={
          "Data source users along with their direct data access information. click on the entry to view access details and anomalies"
        }
      />
    );
  };

  const renderTable = () => {
    return (
      <EnhancedGridTable
        cols={DirectDataSourceConstants()}
        data={allNormalDirectDataSourceUsers}
        totalCount={totalCount}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onTableRowClick={(data) => tableRowsClickHandler(data)}
        permission={"VIEW_ACCESS_ANOMALIES"}
      />
    );
  };

  const renderLoader = () => {
    const height =
      allNormalDirectDataSourceUsers.length > 0 ? "580px" : "100px";
    return (
      loader && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            zIndex: 5,
            top: "0px",
            display: "flex",
            justifyContent: "space-around",
            height: height,
            alignItems: "center",
          }}
        >
          <img src={page_loader} alt="loading"></img>
        </div>
      )
    );
  };

  const renderSidePopup = () => {
    return (
      <AlertDrawer
        accessDetailsDrawer={accessDetailsDrawer}
        loc={"Access_details"}
      ></AlertDrawer>
    );
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={"error"}>
          {errorMessage}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      {renderChartData()}
      <div className="filter-table-container">
        <div className="table-container" style={{ position: "relative" }}>
          {renderTableToolbar()}
          {renderTable()}
          {renderLoader()}
          {renderSidePopup()}
          {renderSnackBar()}
        </div>
      </div>
    </>
  );
};

export default AccessDetails;
