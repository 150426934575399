import { GET_PLATFORM_USER_PREFERENCES_URL, GET_USER_PREFERENCES_URL } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function getUserPreferences(tenant) {
    let preferenceUrl = GET_USER_PREFERENCES_URL;
    if (tenant === 'druvstar' || tenant === "datavision") {
        preferenceUrl = GET_PLATFORM_USER_PREFERENCES_URL;
    }
    const options = {
        method: "GET",
        url: preferenceUrl,
        headers: getAxiosHeaders(true),
    };

    const response = await getService(options);
    if (response.status === 200) {
        return JSON.parse(response.data.preferences)
    }
};
