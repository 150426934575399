import { CONNECTION_SUCCESSFUL, CONNECTION_UNSUCCESSFUL, GET_TEST_CONNECTION_URL } from "../constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";

export async function getTestConnection(
  data,
  applicationId,
  servicesId,
  dataSourceId
) {
  const options = {
    method: "POST",
    url: `${GET_TEST_CONNECTION_URL}`,
    headers: getAxiosHeaders(true),
    data: data,
  };

  const response = await getService(options);
  if (response && response.status === 204) {
    return CONNECTION_SUCCESSFUL;
  } else if (response && response.response.status === 400) {
    let errorMessage = '';
    if (response && response.response.data.errorMessage) {
      errorMessage = response.response.data.errorMessage
    } else if (response && response.response.data.fieldErrors) {
      response.response.data.fieldErrors.map((error) => {
        errorMessage = errorMessage + error.errorMessage + "\n"
      }
      )
    } 
    return errorMessage;
  } else {
    let errorMessage = '';
    if (response && response.response.data) {
      errorMessage = response.response.data.errorMessage;
    } else if (response && response.response.data) {
      errorMessage = response.response.data.fieldErrors[0].errorMessage;
    } else {
      errorMessage = CONNECTION_UNSUCCESSFUL;
    }
    return errorMessage;
  }
}
