import { call, put, takeEvery } from "redux-saga/effects";
import { TENANTSIGNUPPAGE_SUBMIT } from "../constants/TenantSignUpTypes";
import { ApiCall } from "../../../src/components/views/Util/ApiCall";
import {
  TenantSignUpPage_Successful,

  // TenantSIGNUPPAGE_Reset,
  // TENANTSIGNUPPAGE_Submit,
} from "../../../src/redux/actions/TenantSignUpAction";

import { TENANT_SIGN_UP_URL } from "../constants/constants";

function* APICALL2(action) {
  const apiCall2 = yield call(() =>
    ApiCall({
      method: "POST",
      url: TENANT_SIGN_UP_URL,
      // url: "http://4c9239663575.ngrok.io/platform/tenants",
      data: action.payload,
      headers: {
        "Content-Type": "application/json",
      },
    })
  );
  // console.log(apiCall2);
  let out;
  if (apiCall2.status !== 201) {
    out = JSON.parse(apiCall2);
    console.log(out.message);
  }
  // console.log(JSON.parse(apiCall2));

  if (apiCall2 && apiCall2.status === 201) {
    // console.log(apiCall2.status);
    alert("Tenant SignUp Successful");
    yield put(TenantSignUpPage_Successful());
    console.log("tenantSignUpSuccessful");
  } else if (out.message === "Request failed with status code 500") {
    alert("User Already Exists");
  } else if (out.message === "Request failed with status code 400") {
    alert("Please fill the Fields");
  } else {
    alert("Please fill all the fields");
  }
}

export function* TenanantsignUpUser(action) {
  yield console.log("Hello from TenantSignUpSaga");
  yield APICALL2(action);
}

export function* TenantSignUpSaga() {
  yield takeEvery(TENANTSIGNUPPAGE_SUBMIT, TenanantsignUpUser);
}