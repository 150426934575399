import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Chart } from "react-google-charts";
import { getCubejsApi } from "../../common/Dashboard/cubejs-util";
import SnackBar2 from "../../shared/SnackBar2";
import ChartWrapper from "../../common/Dashboard/Chart";
import { accessDetailsBarChartrenderFunction } from "./commonRenderFunctionsForGraphs";
import EnhancedGridTable from "../../shared/Table/Table";
import { DataSourceUserConstants } from "./AccessRightsutilities";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import AppDateTimeRange from "../../common/Navbar/AppDateTimeRange";
import { updateTimeStamps } from "../../../redux/constants/constants";
import { EnhancedDataSourceHeader } from "./EnhancedDataSourceHeader";
import page_loader from "../../../assets/vizr_images/page_loader.gif";
import {
  UPDATE_ROLE_API
} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import AlertDrawer from "../../shared/alertDrawer";
import { useHistory} from "react-router-dom";

const FlaggedDataSourceUsers = ({ dbusersDate ,show ,roleName }) => {
  const [snackBarState, setSnackBarState] = useState({
    success: false,
    failure: false,
    successMessage: "",
    failureMessage: "",
  });
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openCalender, setOpenCalender] = useState(false);
  const [openBatchEditCalender, setOpenBatchEditCalender] = useState(false);
  const [accessRequestID, setAccessRequestID] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [dataSourceUsers, setAllNormalDataSourceUsers] = useState([]);
  const [stateOpen, setStateOpen] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const dispatch = useDispatch();
  let cubejsApi = getCubejsApi();
  let history = useHistory();

  const allNormalDataSourceUsers = useSelector(
    (state) => state.flaggedDataSourceReducer.allNormalDataSourceUsers
  );
  const currentPage = useSelector(
    (state) => state.flaggedDataSourceReducer.currentPage
  );
  const recordsPerPage = useSelector(
    (state) => state.flaggedDataSourceReducer.recordsPerPage
  );
  const totalCount = useSelector(
    (state) => state.flaggedDataSourceReducer.totalCount
  );
  const roles = useSelector(
    (state) => state.normalDataSourceReducer.rolesData
  );
  const loader = useSelector((state) => state.flaggedDataSourceReducer.loader);
  const accountDataOnRole = useSelector((state) => state.normalDataSourceReducer.accountDataOnRole);

  useEffect(() => {
    dispatch({ type: "SET_FLAGGED_DATA_SOURCE_LOADER_INFO", payload: true });
    dispatch({ type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA" });
    dispatch({ type: "UPDATE_LOCATION", payload: "database_Users" });
    return () => {
      dispatch({ type: "UPDATE_LOCATION", payload: "" });
      // Clear search value
      dispatch({
        type: "CLEAR_SEARCH_VALUE",
      });
    };
  }, []);

  useEffect(() => {
    setAllNormalDataSourceUsers(allNormalDataSourceUsers);
    setSelectedList([]);
    setSnackBarState((prevState) => {
      return {
        ...prevState,
        success: false,
      };
    });
  }, [allNormalDataSourceUsers]);

  const render = (resultSet) => {
    const projectedData = accessDetailsBarChartrenderFunction(resultSet);
    if (projectedData) {
      return (
        <div className="accessrights-databaseusers-graph-container">
          <Chart
            width={"100%"}
            height={"430px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={projectedData}
            options={{
              colors: ["green", "#FF8421", "#FF003E","black","#FFDF60","#48A04B","#706F7E","#498BD7" ,"#087B81"],
            }}
          />
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%", height: "300px" }}>No Data Available</div>
      );
    }
  };

  const renderChartData = () => {
    if (dbusersDate.pastDate !== "" && dbusersDate.futureDate !== "") {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return (
        <div style={{ marginLeft: "13rem", maxWidth: "84%" }}>
          <ChartWrapper
            cubejsApi={cubejsApi}
            title={"Status of Data Source Users"}
            query={{
              dimensions: [
                "DataSourceUsers.userName",
                "DataSourceUsers.requestStatusCode",
                "DataSourceUsers.aliasName",
              ],
              timeDimensions: [
                {
                  dimension: "DataSourceUsers.expirationTimestamp",
                  granularity: "day",
                  dateRange: [dbusersDate.pastDate, dbusersDate.futureDate],
                },
              ],
              order: { "DataSourceUsers.expirationTimestamp": "asc" },
              timezone: tz,
            }}
            render={render}
          />
        </div>
      );
    }
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch({ type: "SET_FLAGGED_DATA_SOURCE_RECORDS_PAGE", payload: value });
    dispatch({ type: "SET_FLAGGED_DATA_SOURCE_CURRENT_PAGE", payload: 0 });
    dispatch({
      type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA",
    });
  };

  const handleChangePage = (page) => {
    dispatch({ type: "SET_FLAGGED_DATA_SOURCE_CURRENT_PAGE", payload: page });
    dispatch({
      type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA",
    });
  };

  const renderSnackBar = () => {
    return (
      <SnackBar2
        success={
          snackBarState.success ||
          (snackBarState.successMessage && snackBarState.success)
        }
        failure={snackBarState.failure}
        successMessage={snackBarState.successMessage}
        errorMessage={snackBarState.failureMessage}
      ></SnackBar2>
    );
  };

  const renderCalender = () => {
    return (
      <Popper
        id={"simple-popper"}
        open={true}
        anchorEl={anchorEl2}
        style={{ marginTop: "21rem", zIndex: "1" }}
      >
        <Paper elevation={5}>
          <AppDateTimeRange
            loc="useraccessRights_content"
            setAnchorElHandler={setAnchorEl2}
            closeCalender={closeCalender}
            onTimeChange={onTimeChange}
          ></AppDateTimeRange>
        </Paper>
      </Popper>
    );
  };

  const setTarget = (target, id) => {
    setAnchorEl2(target);
    setOpenCalender(true);
    setAccessRequestID(id);
  };

  const closeCalender = () => {
    setOpenCalender(false);
    setOpenBatchEditCalender(false);
  };

  const handleOnChangeRole = async ( value , data) => {
    dispatch({ type: "SET_FLAGGED_DATA_SOURCE_LOADER_INFO", payload: true });
    const options = {
      method: "PUT",
      url: UPDATE_ROLE_API(value ,data.databaseAccessRequestID),
      headers: getAxiosHeaders(true),
      data: {}
    };
    const response = await getService(options);
    if (response && response.status === 200) {
      dispatch({ type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA" });
      dispatch({ type: "GET_NORMAL_DATA_SOURCE_INFO_DATA" });
    }else{
      dispatch({ type: "SET_FLAGGED_DATA_SOURCE_LOADER_INFO", payload: false });
    }
  };

  const epocTimeGenerator = (curExpiry, expiryDate) => {
    var theDate = new Date(curExpiry);
    var myNewDate = new Date(theDate);
    myNewDate.setDate(myNewDate.getDate() + expiryDate);
    return myNewDate.getTime();
  };

  const onTimeChange = (calenderSideMenu, expiryDate) => {
    let arr = [];
    if (openCalender) {
      if (calenderSideMenu) {
        let curExpiry;
        allNormalDataSourceUsers.forEach((el) => {
          if (el.databaseAccessRequestID === accessRequestID) {
            curExpiry = el.expiryDate;
          }
        });
        const updatedEpocTime = epocTimeGenerator(curExpiry, expiryDate);

        arr = [
          {
            databaseAccessRequestId: accessRequestID,
            expirationTimeInMillis: updatedEpocTime,
          },
        ];
      } else {
        arr = [
          {
            databaseAccessRequestId: accessRequestID,
            expirationTimeInMillis: expiryDate,
          },
        ];
      }
      let data = {
        updateExpirationTimeRequests: arr,
      };
      DataBaseUsersAPIUpdateCall(data);
    } else {
      if (selectedList.length > 0) {
        if (calenderSideMenu) {
          // let curExpiry;
          let arr = [];
          allNormalDataSourceUsers.forEach((el) => {
            if (selectedList.includes(el.databaseAccessRequestID)) {
              arr.push({
                databaseAccessRequestId: el.databaseAccessRequestID,
                expirationTimeInMillis: epocTimeGenerator(
                  el.expiryDate,
                  expiryDate
                ),
              });
            }
          });
          let data = {
            updateExpirationTimeRequests: arr,
          };
          DataBaseUsersAPIUpdateCall(data);
        } else {
          let arr = selectedList
            .filter((accessRequestID) => {
              if (accessRequestID && expiryDate) {
                return accessRequestID;
              }
            })
            .map((accessRequestID) => {
              return {
                databaseAccessRequestId: accessRequestID,
                expirationTimeInMillis: expiryDate,
              };
            });

          let data = {
            updateExpirationTimeRequests: arr,
          };
          DataBaseUsersAPIUpdateCall(data);
        }
      }
    }
  };

  const DataBaseUsersAPIUpdateCall = async (data) => {
    const res = await getService({
      method: "PUT",
      url: updateTimeStamps,
      headers: getAxiosHeaders(true),
      data: data,
    });
    if (res.status === 204) {
      dispatch({ type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA" });
      const obj = {
        success: true,
        successMessage: "Expiry date updated Successfully",
      };
      setSnackBarState({
        ...obj,
      });
    } else {
      setSnackBarState({
        failure: true,
        failureMessage: "Expiry Date updated failed. Please try later",
      });
    }
  };


  const handleBatchEdit = (currentTarget) => {
    setAnchorEl2(currentTarget);
    setOpenBatchEditCalender(true);
  };

  const toggleDrawer = (open, event, row) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setPopUpData(row)
    setStateOpen(open);
  };

  const getColumnsData = () => {
    let rolesData = JSON.parse(JSON.stringify(roles));
    rolesData = rolesData.filter((data) => {
      if (
        data.code !== "ACCESS_WILL_EXPIRE" &&
        data.code !== "ACCESS_EXPIRED"
      ) {
        return data;
      }
    });
    let data = DataSourceUserConstants(setTarget,accountDataOnRole,handleOnChangeRole ,"flaggedDataSource",roleName);
    return data;
  };

  const renderTableToolbar = () => {
    return (
      <EnhancedDataSourceHeader
        totalElements={totalCount}
        rows={allNormalDataSourceUsers}
        loc={"Flagged_database_Users"}
        title={"Flagged Data Source Users"}
        helpDescription={
          "Data source users of interest – account access to be verified, expired , verified , revoked , will expire , requested , marked for deletion"
        }
        setBatchEdit={handleBatchEdit}
        selectedRows={selectedList}
        location={"flaggedDataSource"}
        show={show }
        roleName={roleName}
        history = {history} />
    );
  };

  const tableRowsClickHandler = (event, row) => {
    toggleDrawer(true, event, row);
  };

  const renderTable = () => {
    return (
      <EnhancedGridTable
        cols={getColumnsData()}
        data={allNormalDataSourceUsers}
        totalCount={totalCount}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onTableRowClick={(event,data) => tableRowsClickHandler(data,event)}
      />
    );
  };

  const renderLoader = () => {
    const height = allNormalDataSourceUsers.length > 0 ? "580px" : "60px";
    return (
      loader && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            zIndex: 5,
            top: "0px",
            display: "flex",
            justifyContent: "space-around",
            height: height,
            alignItems: "center",
          }}
        >
          <img src={page_loader} alt="loading"></img>
        </div>
      )
    );
  };

  const renderSidePopup = () => {
    return (
      stateOpen && (
        <AlertDrawer
          toggleDrawer={toggleDrawer}
          stateOpen={stateOpen}
          loc="data-source-status"
          popUpData={popUpData}
        />
      )
    );
  };

  return (
    <>
      {renderChartData()}
      <div className="filter-table-container">
        <div className="table-container" style={{ position: "relative" }}>
          {renderTableToolbar()}
          {renderTable()}
          {renderLoader()}
        </div>
      </div>
      {openCalender && renderCalender()}
      {openBatchEditCalender && renderCalender()}
      
      {renderSnackBar()}
      {renderSidePopup()}
    </>
  );
};

export default FlaggedDataSourceUsers;
