import {
  UPDATE_PATCHMONITORING_CLASSIFICATION_FILTERDATA,
  UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA,
  UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA,
  UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA,
} from "../../constants/constants";

export const UPDATE_PATCHMONITORING_CLASSFICATION_FILTERDATA_ACTION = (
  payload
) => {
  return {
    type: UPDATE_PATCHMONITORING_CLASSIFICATION_FILTERDATA,
    payload: payload,
  };
};

export const UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA_ACTION = (payload) => {
  return {
    type: UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA,
    payload: payload,
  };
};
export const UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA_ACTION = (
  payload
) => {
  return {
    type: UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA,
    payload: payload,
  };
};

export const UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA_ACTION = (
  payload
) => {
  return {
    type: UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA,
    payload: payload,
  };
};
