import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ResolvedFalsePositiveAlerts from "../shared/totalAlerts/ResolvedFalsePositiveAlerts";
import UnresolvedAlerts from "../shared/totalAlerts/UnresolvedAlerts"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const AlertPolicyById = props => {
  const dispatch = useDispatch();
  const { policyId } = props;
  const alertStatusUpdated = useSelector(
    state => state.alertStatusChanged.alertUpdated
  );
  const alertStatusUpdatedSuccessMessage = useSelector(
    state => state.alertStatusChanged.successMessage
  );
  const alertStatusUpdatedErrorMessage = useSelector(
    state => state.alertStatusChanged.unsuccessful
  );
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (alertStatusUpdatedSuccessMessage !== "") {
      setSnackBarMessage(alertStatusUpdatedSuccessMessage);
    }
  }, [alertStatusUpdatedSuccessMessage]);

  useEffect(() => {
    if (alertStatusUpdatedErrorMessage !== "") {
      setSnackBarMessage(alertStatusUpdatedErrorMessage);
      setOpen(true);
    }
  }, [alertStatusUpdatedErrorMessage]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "CLOSE_SNACKBAR" });
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  useEffect(() => {
    if (alertStatusUpdated) {
      setOpen(true);
    }
  }, [alertStatusUpdated]);

  return (
    <React.Fragment>
      <UnresolvedAlerts policyId={policyId}/>
      <ResolvedFalsePositiveAlerts policyId={policyId} />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        onExited={handleExited}
      >
        <Alert
          onClose={handleClose}
          severity={alertStatusUpdatedErrorMessage ? "error" : "success"}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
