import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';

function CanAllow(props) {
  const { children, needs, component } = props;
  //permissions from redux
  const permissions = useSelector((state) => state.applicationData.permissions);
  //permissions array need to check
  const permissionsCheckArray = needs.permission;
  const featureCheck = needs.hasFeature;

  const checkRequirements = () => {
    if (isUndefined(featureCheck)) {
      return (
        permissionsCheckArray &&
        permissionsCheckArray.every((permission) =>
          permissions.includes(permission)
        )
      );
    } else {
      return (
        permissionsCheckArray &&
        featureCheck &&
        permissionsCheckArray.every((permission) =>
          permissions.includes(permission)
        )
      );
    }
  };
  //check permission match
  const hasPermission = checkRequirements();

  if (hasPermission) {
    return <>{children}</>;
  } else if (component) {
    return <>{component}</>;
  } else {
    return null;
  }
}

export default CanAllow;
