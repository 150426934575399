import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import { isEmpty, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_CLOUD_DRIVE_GROUPS,
  SET_CLOUD_SOURCE_FIELDS_DATA,
} from "../../../redux/constants/constants";
import { renderDataSourceIcons } from "./DatasourceIcons";
import { OneDriveConnection } from "../OneDriveConnection";
import { FormControlLabel, TextField, makeStyles } from "@material-ui/core";
import { renderGroupNames } from "./uitilitiesComp";
import { filterGroups } from "./dataSourceUtilities";
import { ONEDRIVE_SERVER, SHAREPOINT_SERVER } from "./dataSourceConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  noOptions: {
    color: 'red',
  },
  autocompleteRoot: {
    "& ::-webkit-scrollbar": {
      width: "8px",
    },
    "& ::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "6px",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#D0D0D0",
      borderRadius: "6px",
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#C0C0C0",
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
  },
  fixedTag: {
    backgroundColor: "#e0e0e0",
  },
}));

function OneDriveDataSourceForm({
  isEditMode,
  engine,
  error,
  removeErrorByKey,
  handleNumberInput,
  dataSourceData,
  handleConnect,
  toggleDrawer
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cloudSourceData = useSelector(
    (state) => state.CreateSourceReducer.cloudSourceData
  );
  const {
    domainName,
    group,
    allGroups,
    limitUsers,
    cloudSourceUsers,
    noOfUsersError,
    oneDriveSelected,
    sharePointSelected,
  } = cloudSourceData;

  useEffect(() => {
    if (isEditMode && dataSourceData) {
      dispatch({
        type: SET_CLOUD_SOURCE_FIELDS_DATA,
        payload: {
          ...cloudSourceData,
          group: !isNull(dataSourceData.groups) ? dataSourceData.groups : [],
          oneDriveSelected: !isNull(dataSourceData.oneDrive) ? dataSourceData.oneDrive : false,
          sharePointSelected: !isNull(dataSourceData.sharePoint) ? dataSourceData.sharePoint : false
        },
      });
    }
  }, [isEditMode, dataSourceData]);

  useEffect(() => {
    if (
      dataSourceData &&
      !isEmpty(dataSourceData.secret1) &&
      !isEmpty(dataSourceData.secret3)
    ) {
      dispatch({ type: GET_ALL_CLOUD_DRIVE_GROUPS, payload: dataSourceData.id });
    }
  }, [dataSourceData]);

  const handleUserToggleChange = () => {
    dispatch({
      type: SET_CLOUD_SOURCE_FIELDS_DATA,
      payload: {
        ...cloudSourceData,
        limitUsers: !limitUsers,
        noOfUsersError: "",
        cloudSourceUsers: limitUsers ? "" : 50,
      },
    });
  };

  const updateCloudSourceData = (key, selected) => {
    dispatch({
      type: SET_CLOUD_SOURCE_FIELDS_DATA,
      payload: {
        ...cloudSourceData,
        [key]: selected,
      },
    });
  };
  
  const processSelectionChanges = (key, dataSourceKey, selected) => {
    if (isEditMode) {
      if (!dataSourceData[dataSourceKey]) {
        updateCloudSourceData(key, selected);
      }
    } else {
      updateCloudSourceData(key, selected);
    }
  };
  
  const handleSelectionChange = (altText) => {
    if (altText === "SharePointServer") {
      processSelectionChanges("sharePointSelected", "sharePoint", !sharePointSelected);
    } else if (altText === "OneDriveServer") {
      processSelectionChanges("oneDriveSelected", "oneDrive", !oneDriveSelected);
    }
  };
  

  const renderSelectiveUsersToggle = () => {
    return (
      <>
      <div className="selectiveUsers">
        <span className="selectiveUserText">
          Monitor Only a Subset of Users
        </span>
        <FormControlLabel
          control={
            <Switch
              checked={limitUsers}
                onChange={() => handleUserToggleChange()}
              color="primary"
            />
          }
        />
      </div>
      </>
    );
  };

  const renderSelectiveUserField = () => {
    if (isEditMode && (isNull(cloudSourceUsers) || cloudSourceUsers === 0)) {
      return null;
    } else {
      return (
        <>
        <div className="selectiveUserContainer">
          <div
            className={
              !limitUsers && !isEditMode ? "selectiveUserDisabled" : ""
            }
          >
            Number of Users
          </div>
          <TextField
            required
            disabled={!limitUsers}
            value={cloudSourceUsers}
            className={!limitUsers ? "selectiveUserFiled" : ""}
            onChange={(event) =>
              handleNumberInput(event.target.value, "users")
            }
            id="outlined-required"
            variant="outlined"
            fullWidth={true}
            placeholder="No of Users"
            type={"number"}
            autoComplete="off"
            InputProps={{ inputProps: { min: 0 } }}
          />
          {!isEmpty(noOfUsersError) ? (
            <div style={{ color: "red" }}>{noOfUsersError}</div>
          ) : null}
        </div>
        </>
      );
    }
  };

  const handleGroupNameChange = (value) => {
    const updatedGroups = [
      ...dataSourceData.groups,
      ...value.filter((option) => dataSourceData.groups.indexOf(option) === -1)
    ]
    dispatch({
      type: SET_CLOUD_SOURCE_FIELDS_DATA,
      payload: {
        ...cloudSourceData,
        group: updatedGroups,
      },
    });
  };

  const renderSelectiveUsers = () => {
    if (
      dataSourceData &&
      !isEmpty(dataSourceData.secret1) &&
      !isEmpty(dataSourceData.secret3) &&
      isEditMode
    ) {
      const fixedOptions =
        dataSourceData.groups && dataSourceData.groups.length > 0
          ? dataSourceData.groups
          : [];
      const updatedGroups = filterGroups(group, allGroups, classes);
      return (
        <>
        <div style={{ padding: "10px 0px" }}>
          <div>Group Name</div>
          {renderGroupNames(
             fixedOptions,
             group,
             updatedGroups,
             handleGroupNameChange,
             classes
          )}
          </div>
          <div className="noOfUsersContainer">
            {renderSelectiveUsersToggle()}
            {renderSelectiveUserField()}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {renderDataSourceIcons(
        isEditMode,
        {
          SharePointServer: { ...SHAREPOINT_SERVER, selected: sharePointSelected },
          OneDriveServer: { ...ONEDRIVE_SERVER, selected: oneDriveSelected }
        },
        handleSelectionChange,
        engine,
        dataSourceData
      )}
      <div style={{ padding: "10px 0px" }}>
        <div>Domain Name{<span style={{ color: "red" }}>*</span>}</div>
        <TextField
          required
          value={domainName}
          onChange={(event) => {
            dispatch({
              type: SET_CLOUD_SOURCE_FIELDS_DATA,
              payload: { ...cloudSourceData, domainName: event.target.value },
            });
            removeErrorByKey("domainName");
          }}
          id="outlined-required"
          variant="outlined"
          fullWidth={true}
          placeholder="Domain Name"
          disabled={isEditMode}
        />
        {error && error.domainName ? (
          <div style={{ color: "red" }}>{error.domainName}</div>
        ) : null}
      </div>
      <OneDriveConnection
        onHandleConnect={handleConnect}
        dataSourceData={dataSourceData}
        toggleDrawer={toggleDrawer}
      />
      {renderSelectiveUsers()}
    </div>
  );
}

export default OneDriveDataSourceForm;
