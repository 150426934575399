export const dataFlowStaData = [
  {
    id:"flow1",
    flowName: "Self Serviced 3",
    flowUrl : "Post/Network",
    subServicesTraceComponent : {
      type: "subService",
      from: {
        name:"service",
        id: "service",
        flowDirection: "reverse"
      },
      to: {
        name:"Update Customer",
        id: "updateCustomer"
      },
      dataSentivity : {
        type: "soft",
        id:"doft"
      },
      transcations : {
        count: 54,
        time: "5s",
        flowCount: 4
      } 
    },
    flowMap: {
        name: "Data flow X",
        flowGroups: [
            {
                from: 1,
                to:2,
                color : {
                    color: "#BBB",
                  },
                  arrows : {
                    to: {
                      scaleFactor : 1},
                      dashes: true, width : 3}
            },
            {
                from: 3,
                to:4,
                color : {
                    color: "#BBB",
                  },
                  arrows : {
                    to: {
                      scaleFactor : 1},
                      dashes: true, width : 3}
            }
        ]
      
    }
  },
  {
    id:"flow1",
    flowName: "Self Serviced 3",
    flowUrl : "Post/Network",
    subServicesTraceComponent : {
      type: "subService",
      from: {
        name:"service",
        id: "service",
        flowDirection: "reverse"
      },
      to: {
        name:"Update Customer",
        id: "updateCustomer"
      },
      dataSentivity : {
        type: "soft",
        id:"doft"
      },
      transcations : {
        count: 54,
        time: "5s",
        flowCount: 4
      } 
    },
    flowMap: {
        name: "Data flow 12",
        flowGroups: [
            {
                from: 1,
                to:2,
                color : {
                    color: "#BBB",
                  },
                  arrows : {
                    to: {
                      scaleFactor : 1},
                      dashes: true, width : 3}
            },
            {
                from: 3,
                to:4,
                color : {
                    color: "#BBB",
                  },
                  arrows : {
                    to: {
                      scaleFactor : 1},
                      dashes: true, width : 3}
            }
        ]
      
    }
  }
]

export const flowGroupStaData = [
  {
    id: "flowg1",
    name: "Self service Customer 1",
    transcationAggregationRules: "AdminService, *Customer(Name,Email,Address) 1",
    flowAggregation: "2",
    noOfTransactions: 400,
    dataSentivity: "MEDIUM"
  },
  {
    id: "flowg2",
    name: "Self service Customer 2",
    transcationAggregationRules: "AdminService, *Customer(Name,Email,Address) 2",
    flowAggregation: "3",
    noOfTransactions: 500,
    dataSentivity: "HIGH"
  },
  {
    id: "flowg3",
    name: "Self service Customer 3",
    transcationAggregationRules: "AdminService, *Customer(Name,Email,Address) 1",
    flowAggregation: "4",
    noOfTransactions: 300,
    dataSentivity: "MEDIUM"
  },
  {
    id: "flowg5",
    name: "Self service Customer 4",
    transcationAggregationRules: "AdminService, *Customer(Name,Email,Address) 1",
    flowAggregation: "5",
    noOfTransactions: 400,
    dataSentivity: "MEDIUM"
  },
  {
    id: "flowg5",
    name: "Self service Customer 5",
    transcationAggregationRules: "AdminService, *Customer(Name,Email,Address) 1",
    flowAggregation: "6",
    noOfTransactions: 600,
    dataSentivity: "MEDIUM"
  }
]


export const transactionsStaData = [
  {
    id:1,
    transcationName: "JSPServelet#doGet",
    user:"john.smitjh@gmail.com",
    dateTime: "Apr 20, 2020 at 15:00",
    transcationTime: "0.1s",
    transcationCount: 0,
    dataSentivity:"MEDIUM",
    transcationInfo: [
      {
        id:1,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "0ms",
        endTime: "50ms",
      },
      {
        id:2,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "50ms",
        endTime: "100ms",
      },
      {
        id:3,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "100ms",
        endTime: "250ms",
      },
      {
        id:4,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "250ms",
        endTime: "400ms",
      },
      {
        id:5,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "400ms",
        endTime: "600ms",
      }
    ]
  },
  {
    id:2,
    transcationName: "JSPServelet#doPost",
    user:"john.ken@gmail.com",
    dateTime: "Apr 21, 2020 at 15:00",
    transcationTime: "0.2s",
    transcationCount: 5,
    dataSentivity:"HIGH",
    transcationInfo: [
      {
        id:1,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "0ms",
        endTime: "50ms",
      },
      {
        id:2,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "50ms",
        endTime: "100ms",
      },
      {
        id:3,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "100ms",
        endTime: "250ms",
      },
      {
        id:4,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "250ms",
        endTime: "400ms",
      },
      {
        id:5,
        name: "Select from SALESMANAGER.USER",
        value: "343μs",
        startTime: "400ms",
        endTime: "600ms",
      }
    ]
  }
]

  export const nodes = [ {
    id:28,
    x:-760,
    y:-660,
    shape: "circularImage",
    image: 'fluffycloudsilhouette',
    label: "Database X",
    size: 44,
    shapeProperties: { useImageSize: true },
    imagePadding: { left: 2, top: 40, right: 8, bottom: 20 },
    widthMin: 14,
    widthMax: 14,
    borderWidth:0,
    color: {  
      border: "#b8b7d0",
      background: "#f8f9ff",
      highlight: { border: "orange", background: "#CCC" },
      hover: { border: "orange", background: "grey" },
    },
    font : {
      size : 16,
      align : "center",
      vadjust : 42
    },
    edgeProps : [
        {from: 12, to: 8, 
                color : {
                  color: "#BBB",
                },
                arrows : {
                  to: {
                    scaleFactor : 1}},
                dashes: true, width : 3}
    ]
    },    
    ]

