import {
  APPLICATIONS,
  SELECTED_APPLICATION,
  PERMISSIONS,
} from "../constants/constants";

const initialState = {
  applications: [],
  selectedApplication: "",
  unsuccessful: "",
  permissions: localStorage.getItem("PERMISSIONS") ? JSON.parse(localStorage.getItem("PERMISSIONS")) : [],
};

export const getApplicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATIONS: {
      return {
        ...state,
        applications: action.payload,
      };
    }
    case PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case SELECTED_APPLICATION: {
      return {
        ...state,
        selectedApplication: action.payload,
      };
    }
    case "REMOVE_SELECTED_APPLICATION": {
      return {
        selectedApplication: "",
      };
    }
    default:
      return state;
  }
};
