import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import MapPopover from "./MapPopover";
import geoUrl from "./worldmap.json";

const MDRMapChart = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [priorityState, setPriorityState] = useState({
    high: 0,
    medium: 0,
    low: 0,
  });

  const handleClick = (event, priority) => {
    setPriorityState({
      high: priority["HIGH"],
      medium: priority["MEDIUM"],
      low: priority["LOW"],
    });
    setAnchorEl(event.currentTarget);
  };
  let markersObj = {};
  data.forEach((el) => {
    if (markersObj[`${el["IncidentMaps.long"]}${el["IncidentMaps.lat"]}`]) {
      let count = markersObj[
        `${el["IncidentMaps.long"]}${el["IncidentMaps.lat"]}`
      ].priority[el["IncidentMaps.priority"].toUpperCase()]
        ? markersObj[`${el["IncidentMaps.long"]}${el["IncidentMaps.lat"]}`]
            .priority[el["IncidentMaps.priority"].toUpperCase()]
        : 0;

      markersObj[
        `${el["IncidentMaps.long"]}${el["IncidentMaps.lat"]}`
      ].priority[el["IncidentMaps.priority"].toUpperCase()] = 1 + count;
    } else {
      markersObj[`${el["IncidentMaps.long"]}${el["IncidentMaps.lat"]}`] = {
        markerOffset: 15,
        name: el["IncidentMaps.city"],
        coordinates: [el["IncidentMaps.long"], el["IncidentMaps.lat"]],
        priority: {
          [el["IncidentMaps.priority"].toUpperCase()]: 1,
        },
      };
    }
  });

  let markersArr = [];
  markersArr = Object.keys(markersObj).map((key) => {
    return markersObj[key];
  });

  const colorFinder = (priority) => {
    let color = "";

    const maxNumber = Object.values(priority).reduce(
      (t, value) => (t > value ? t : value),
      0
    );

    let updatedObject = { LOW: "", MEDIUM: "", HIGH: "" };
    updatedObject.HIGH = priority["HIGH"];
    updatedObject.MEDIUM = priority["MEDIUM"];
    updatedObject.LOW = priority["LOW"];

    Object.keys(updatedObject).forEach((key) => {
      if (priority[key] === maxNumber && key === "LOW") {
        color = "#91DFFF";
      } else if (priority[key] === maxNumber && key === "MEDIUM") {
        color = "#FF8421";
      } else if (priority[key] === maxNumber && key === "HIGH") {
        color = "#FF2251";
      }
    });

    return color;
  };

  return (
    <ComposableMap
      projection="geoEqualEarth"
      style={{ maxHeight: "35rem", width: "100%", marginTop: "-8rem" }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo, key) => (
            <Geography
              style={{
                default: { outline: "none", border: "1px solid #081981" },
                hover: { outline: "none", border: "1px solid #081981" },
                pressed: { outline: "none", border: "1px solid #081981" },
              }}
              key={geo.rsmKey}
              geography={geo}
              fill="#081981"
            />
          ))
        }
      </Geographies>
      {markersArr.map(({ name, coordinates, markerOffset, priority }) => (
        <Marker
          key={name}
          coordinates={coordinates}
          onClick={(e) => {
            handleClick(e, priority);
          }}
        >
          <g
            fill="none"
            // stroke="#FF5533"
            stroke={colorFinder(priority)}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
            onClick={(e) => handleClick(e, priority)}
          >
            <circle
              r={10}
              fill={colorFinder(priority)}
              stroke="#fff"
              strokeWidth={2}
            />
          </g>
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#FFF" }}
          ></text>
        </Marker>
      ))}
      <MapPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        priorityState={priorityState}
      ></MapPopover>
    </ComposableMap>
  );
};

export default MDRMapChart;
