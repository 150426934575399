import React, { useState, useEffect } from "react";
import "../../../../assets/scss/TenantMangement.scss";

import EnhancedGridTable from "../../../shared/Table/Table";
import { EnhancedTableToolbar } from "../Header";
import Page_Loader from "../../../../assets/vizr_images/page_loader.gif";
import { useHistory } from "react-router-dom";
import { getService, getAxiosHeaders } from "../../../../library/RestAPI";
import {
  CONCENTRATOR_URL,
  GET_ENDPOINT_URL,
  Endpoints,
} from "../../../../redux/constants/constants";

import { DeleteButtonForToolBar } from "../../../common/buttons";
import DeleteIcon from "@material-ui/icons/Delete";


const ViewMdrEndpointConcentratorDetailsConstants = (
  _,
  id,
  deleteEndpointApi
) => {
  return [
    {
      title: "Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: "Host",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.host}</span>;
      },
    },
    {
      title: "Port",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.port}</span>;
      },
    },
    {
      title: "Username",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.userName}</span>;
      },
    },
    {
      title: "",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return (
          <span>
            {" "}
            {
              <DeleteButtonForToolBar
                variant="contained"
                id="delete"
                size="small"
                color="primary"
                onClick={() => {
                  deleteEndpointApi(rowData.id);
                }}
                className="min-margin"
              >
                <DeleteIcon />
              </DeleteButtonForToolBar>
            }
          </span>
        );
      },
    },
  ];
};
export const ViewEndpoint = (props) => {
  const { id, name, platformSubdomainNameFrom } = props;
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [loader, setLoader] = useState(false);
  const [EndpointDetails, setEndpointDetails] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setLoader(true);
    getEndpointData();
  }, [currentPage, recordsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getEndpointData() {
    const data = getAxiosHeaders(true);
    data.Tenant = name;
    data["platform-subdomain-name"] = platformSubdomainNameFrom;

    const res = await getService({
      method: "GET",
      url: `${GET_ENDPOINT_URL}?page=${currentPage}&size=${recordsPerPage}`,
      headers: data,
    });
    if (res && res.status === 200) {
      setEndpointDetails(res.data.content);
      setTotalCount(res.data.totalElements);
      setLoader(false);
    } else {
      setLoader(true);
    }
  }

  async function deleteEndpointsApi(id) {
    const data = getAxiosHeaders(true);
    data.Tenant = name;
    data["platform-subdomain-name"] = platformSubdomainNameFrom;

    const res = await getService({
      method: "DELETE",
      url: `${CONCENTRATOR_URL}/${id}`,
      headers: data,
    });
    if (res && res.status === 200) {
      if (currentPage > 0 && EndpointDetails.length === 1) {
        setCurrentPage(currentPage - 1);
      } else {
        getEndpointData();
      }
    } else {
      if (res.response && res.response.status === 401) {
        setLoader(false);
      }
    }
  }

  const deleteEndpointApi = (id) => {
    setLoader(true);
    deleteEndpointsApi(id);
  };

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="main">
      {loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )}
      <div className="filter-table-container">
        <div className="table-container">
          <EnhancedTableToolbar
            totalElements={totalCount}
            loc={Endpoints}
            alertsTitle={"Endpoints Concentrator Config"}
            helpDescription={"Endpoints Concentrator Config"}
            id={id}
            name={name}
            platformSubdomainNameFrom={platformSubdomainNameFrom}
          />
          <EnhancedGridTable
            cols={ViewMdrEndpointConcentratorDetailsConstants(
              history,
              id,
              deleteEndpointApi
            )}
            data={EndpointDetails}
            totalCount={totalCount}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
          />
        </div>
      </div>
    </div>
  );
};
