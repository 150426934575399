import {
  dataSource,
  dataSourceType,
  databaseAccessRole,
  status,
} from "../../../redux/constants/constants";
import _ from "lodash";

export const expiryDate = "expiryDate";

// export const createCustomGRidObject = (resContent) => {
//   let cusArrObj = [];
//   resContent &&
//     resContent.length > 0 &&
//     resContent.forEach((el) => {
//       let obj = {
//         username: el.databaseUsername,
//         dataSourceName: el.sourceName ? el.sourceName : "",
//         accessRoleNames: el.roles ? el.roles : "",
//         accountStatus: el.accountStatus ? el.accountStatus : "",
//         expiryDate: el.expirationTimestamp ? el.expirationTimestamp : "",
//         databaseAccessRequestID: el.databaseAccessRequestId
//           ? el.databaseAccessRequestId
//           : "",
//         aliasName: el.aliasName ? el.aliasName : "",
//         deleted: el.deleted,
//       };
//       cusArrObj.push(obj);
//     });

//   return cusArrObj;
// };
export const createCustomGRidObject = (resContent) => {
  let cusArrObj = [];
  resContent &&
    resContent.length > 0 &&
    resContent.forEach((el) => {
      let obj = {
        username: el.username,
        emailId: el.emailId ? el.emailId : "",
        dataSourceName: el.dataSource ? el.dataSource.name : "",
        accessRoleNames: el.dataSource ? el.dataSource.roles : "",
        deleted: el.deleted,
        accountStatus: el.dataSource
          ? el.dataSource.databaseAccessRequest.accessRequestStatus
          : "",
        expiryDate: el.dataSource
          ? el.dataSource.databaseAccessRequest.expirationTimestamp
          : "",
        databaseAccessRequestID: el.dataSource
          ? el.dataSource.databaseAccessRequest.id
          : "",
        aliasName: el.dataSource ? el.dataSource.aliasName : "",
        deleted: el.deleted,
        dataSourceId:el.dataSource.id,
        id:el.id,
        emailId: el.emailId ? el.emailId : "",
        dataSourceTypeCode: el.dataSource.dataSourceType ? el.dataSource.dataSourceType.code : ""
      };
      cusArrObj.push(obj);
    });

  return cusArrObj;
};

export const loopFunction = (filterBySelectedValues, filterBy) => {
  let arr = [];
  filterBySelectedValues.length &&
    filterBySelectedValues.forEach((el) => {
      if (el.isSelected && el) {
        if (el.id && filterBy === dataSource) {
          arr.push(el.id);
        } else if (el.id && filterBy === dataSourceType) {
          arr.push(el.code);
        } else if (el.id && filterBy === databaseAccessRole) {
          arr.push(el.id);
        } else if (el.id && filterBy === status) {
          arr.push(el.code);
        } else if (el.code) {
          arr.push(el.code);
        }
      }
    });
  return arr;
};

export const createCustomObject_AccessDetails = (data) => {
  let customArr = [];
  if (data.length > 0) {
    data.forEach((el) => {
      let obj = {
        username: el.username ? el.username : "",
        roles: el.roles ? el.roles : "",
        emailId: el.emailId ? el.emailId : "",
        dataSourceName: el.dataSourceName ? el.dataSourceName : "",
        sensitiveDataAccessPercentage: el.sensitiveDataAccessPercentage
          ? el.sensitiveDataAccessPercentage
          : "0",
        firstAccessedOn: el.firstAccessedOn ? el.firstAccessedOn : 0,
        lastAccessedOn: el.lastAccessedOn ? el.lastAccessedOn : 0,
        sensitiveDataAccessed: el.sensitiveDataAccessed ? "True" : "False",
        tenantId: el.tenantId ? el.tenantId : "",
        actorId: el.actorId ? el.actorId : "",
        dataSourceId: el.dataSourceId ? el.dataSourceId : "",
        highestAlert: el.highestAlert ? el.highestAlert : "",
        totalAnomalies: el.totalAnomalies ? el.totalAnomalies : "",
        emailId: el.emailId ? el.emailId : "",
        dataSourceType: el.type.code ? el.type.code : ""
      };
      customArr.push(obj);
    });
  }

  return customArr;
};

export const createCustomObject_AccessDetails_afterGettingSensitivePercentage =
  (SenstivepercentageArr, tableData) => {
    let newTableData = [...tableData];

    SenstivepercentageArr &&
      SenstivepercentageArr.length &&
      newTableData.forEach((obj, index) => {
        SenstivepercentageArr.forEach((SenstivepercentageArrobj) => {
          if (
            SenstivepercentageArrobj.dataSourceId === obj.dataSourceId &&
            SenstivepercentageArrobj.actorId === obj.actorId &&
            SenstivepercentageArrobj.tenantId === obj.tenantId
          ) {
            obj.sensitiveDataAccessPercentage =
              SenstivepercentageArrobj.percentage;
          }
        });
      });

    return newTableData;
  };

export const createCustomObject_ServiceDataAcceses = (data) =>{
  let arr = data;
  let enterprisearray=[]
  arr.forEach(element => {
    enterprisearray.push({"enterpriseActorId":element.id, 
    "dataSourceId":element.dataSource ? element.dataSource.id : null,
    applicationId:element.applicationId ? element.applicationId:null
  })
  });
 return enterprisearray
}
export const createCustomObject_ServiceDataAcceses_SensitivityPercentage = (sensitiviyPercentageApiData, tableData) =>{
  let newTableData = [...tableData];
  sensitiviyPercentageApiData.forEach((SenstivepercentageArrobj) => {
    const tableObj = _.find(newTableData, {id: SenstivepercentageArrobj.enterpriseActorId, applicationId : SenstivepercentageArrobj.applicationId});
    tableObj.sensitiveCountPercentage =
    SenstivepercentageArrobj.sensitiveCountPercentage;
    tableObj.sensitiveCount = SenstivepercentageArrobj.sensitiveCount;
    tableObj.highSeveritySensitivityCategory = SenstivepercentageArrobj.highSeveritySensitivityCategory;
    tableObj.dataSourceNames = SenstivepercentageArrobj.userAndDataSources.dataSourceNames;
    tableObj.usernames = SenstivepercentageArrobj.userAndDataSources.usernames;
    tableObj.dataSourceRoles = SenstivepercentageArrobj.userAndDataSources.dataSourceRoles;
    tableObj.hasSensitiveData = SenstivepercentageArrobj.hasSensitiveData;
  })
  return newTableData;
}