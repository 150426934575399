import React from "react";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import { privacy } from "../../../assets/ddv_privacy";
import { sanitizeDOMString } from "../../common/utilities";

const Popup = ({ cancelClickHanlder, Name, popClassName }) => {
  // console.log(privacy)
  return (
    <>
      {Name === "License" ? (
        <div
          className={popClassName ? popClassName : "signUp_License"}
          id="cross"
        >
          <p className="signUp_cross" onClick={cancelClickHanlder}>
            X
          </p>
          <p className="signUp_LicenseHeading">{Name}</p>
          <div className="signUp_LicensePointsContainer">
            <div>
              We collect the following information when you use our websites or
              contact us:
            </div>
            <p className="signUp_LicensePoints">
              1. When you contact us by mail, phone or through the contact form
              or chat on our websites: we collect your name, email address,
              location, general analytics data and any contact and other
              information you provide to us
            </p>
            <p className="signUp_LicensePoints">
              2. When you contact us about a job opening: we collect any
              relevant information you provide to us.
            </p>
            <p className="signUp_LicensePoints">
              3.When you browse the website or contact us with commercial
              inquiries: we collect general analytics data and any contact and
              other information you provide to us.
            </p>
          </div>
        </div>
      ) : (
        <div
          className={popClassName ? popClassName : "signUp_License"}
          id="cross"
          style={{height: "450px", overflow:"scroll"}}
        >
          <p className="signUp_cross" onClick={cancelClickHanlder}>
            X
          </p>
          <p className="signUp_LicenseHeading">{Name}</p>
          
          <div dangerouslySetInnerHTML={{ __html: sanitizeDOMString(privacy) }}></div>
        </div>
      )}
    </>
  );
};

export default Popup;
