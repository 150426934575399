import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react'
import { CloseButton, ColoredActionButton, NewPolicyButtonForPolicyCreateEdit } from '../common/buttons';
import CloseIcon from "../../assets/vizr_images/ic_remove.svg";

function ConfirmationDialog(props) {
  const { children, dialogOpen, dialogTitle, handleDialogCancel, handleDialogConfirm, disable } = props;
  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirmationDialog"
      >

        <DialogTitle id="alert-dialog-title" className="dialogHeader">
          <span>{dialogTitle}</span>
          <CloseButton
            onClick={() => handleDialogCancel()}
            aria-label="close"
            className='closeIconButton'
          >
            <img src={CloseIcon} alt={"close"} />
          </CloseButton>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <ColoredActionButton
            className="dialogCancel"
            onClick={() => handleDialogCancel()}
          >
            Cancel
          </ColoredActionButton>
          <ColoredActionButton
            className="dialogConfirm"
            onClick={() => handleDialogConfirm()}
            disabled={disable}
          >
            Confirm
          </ColoredActionButton>
        </DialogActions>
      </Dialog></div>
  )
}

export default ConfirmationDialog