import React from 'react'

export default function LevelComponent(props) {
    const { level, status, risk } = props;
    return (
        <>
            {status ? (
                    <div id={status.toLowerCase()} className="level bg-color status">
                        {status.toUpperCase()}
                    </div>
                ) : risk ? (
                    <div id={level && level.toLowerCase()} className="level bg-color risk">
                        {risk}
                    </div>
                ) : (
                    <div id={level && level.toLowerCase()} className="level bg-color">
                        {level && level.toUpperCase()}
                    </div>
                )}

        </>
    )
}
