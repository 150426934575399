import React, { useState, useEffect } from "react";
// import LoginbackGround from "../LoginContainer/LoginbackGround";
// import Sidebar from "../../../components/common/Sidebar/Sidebar";
import SignupPageBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
// import Navbar from "../signUpPages/Navbar";
import { makeStyles } from "@material-ui/core/styles";
// import signUpSuccessful from "../../../assets/vizr_images/signUpSuccessful.svg";
import "./serPasswordStyles.scss";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {resetFirstPasswordContent,resetPasswordContent} from '../../../redux/constants/constants'
const useStyles = makeStyles({
  container: {
    height: "811px",
    width: "80%",
    minWidth: "25%",
    marginTop: "2rem",
    marginLeft: "14rem",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.3)",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignContent: "center",
    alignItems: "center",
  },
  textContent: {
    height: "100%",
    width: "75%",
    color: "#706F7E",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "27px",
    marginTop: "5rem",
    paddingLeft: "14%",
  },

  imageContainer: {
    marginTop: "4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  login: {
    marginTop: "2rem",
    fontSize: "20px",
  },
});

const SetPasswordSuccessful = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { backgroundColor, setBackgroundColor } = useState("blue");
  const [expand, setExpand] = useState(true);
  const [loginLink, setLoginLink] = useState(true);
  const qrCode = useSelector((state) => state.resetPasswordReducer.qrCode);
  const resetPassword = useSelector(
    (state) => state.resetPasswordReducer.resetPassword
  );

  useEffect(() => {
    setLoginLink();
    return () => {
      dispatch({ type: "CLEAR_REDUCER" }); // removing data  component-will-mount
    };
  }, []);

  const handleShrink = () => {
    setExpand(!expand);
  };

  const getHeaderContent = () =>{
    return resetPassword ? resetPasswordContent : resetFirstPasswordContent
  }

  return (
    <div>
      {/*<LoginbackGround></LoginbackGround>*/}
      {/*  <Navbar></Navbar>
      <Sidebar
        routes={[]}
        bgColor={backgroundColor}
        expand={expand}
        handleShrink={handleShrink}
        // isSignuppage="true"
      ></Sidebar>*/}

      <SignupPageBackGround></SignupPageBackGround>

      <div className="SetPasswordContainer">
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid>
            <h1 className={classes.textContent}>
            {getHeaderContent()}
            </h1>
          </Grid>
          {localStorage.getItem("qr_code") ? (
            <Grid className={classes.imageContainer}>
              <img
                src={localStorage.getItem("qr_code")}
                className={classes.img}
                alt="QR code"
              />
              <a href="/" className={classes.login}>
                Login
              </a>
            </Grid>
          ) : (
            <Grid className={classes.imageContainer}>
              <img src={qrCode} className={classes.img} alt="QR code" />
              <a href="/" className={classes.login}>
                Login
              </a>
            </Grid>
          )}
          {/* <div className={classes.imageContainer}>
          <img src={signUpSuccessful} className={classes.img} alt="QR code" />
          <a href="/?tenant=" className={classes.login}>
            Login
          </a>
        </div> */}
        </Grid>
      </div>
    </div>
  );
};

export default SetPasswordSuccessful;
