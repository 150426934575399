
export const onPremiseDataSource = {
  dataSourceName: {
    label: "Datasource Name",
    placeholder: "Datasource Name",
    isRequired: true,
    type: "text",
  },
  address: {
    label: "Host/IP Address",
    placeholder: "IP Address",
    isRequired: true,
    type: "text",
  },
  instance: {
    label: "Instance",
    placeholder: "Instance",
    isRequired: false,
    type: "text"
  },
  port: {
    label: "Port",
    placeholder: "Port",
    isRequired: true,
    type: "text",
  },
  desiredPort: {
    label: "Desired Port",
    placeholder: "Enter desired Port",
    isRequired: true,
    type: "text",
  },
  desiredHost: {
    label: "Desired Host",
    placeholder: "Enter Desired Host",
    isRequired: false,
    type: "text",
  },
  schema: {
    label: "Schema",
    placeholder: "Enter Schema",
    isRequired: false,
    type: "text",
  },
  DBName: {
    label: "Database Name",
    placeholder: "Enter DataBase Name",
    isRequired: true,
    type: "text",
  },
  username: {
    label: "Username",
    placeholder: "Enter Username",
    isRequired: true,
    type: "text",
  },
  password: {
    label: "Password",
    placeholder: "Enter Password",
    isRequired: true,
    type: "password",
  },
  connectionProperties: {
    label: "Connection Properties",
    placeholder: "Enter Connection Properties",
    isRequired: false,
    type: "text"
  },
};

export const awsCloud = {
  dataSourceName: {
    label: "Datasource Name",
    placeholder: "Datasource Name",
    isRequired: true,
    type: "text",
  },
  address: {
    label: "Host/IP Address",
    placeholder: "IP Address",
    isRequired: true,
    type: "text",
  },
  port: {
    label: "Port",
    placeholder: "Port",
    isRequired: true,
    type: "text",
  },
  desiredPort: {
    label: "Desired Port",
    placeholder: "Enter desired Port",
    isRequired: true,
    type: "text",
  },
  desiredHost: {
    label: "Desired Host",
    placeholder: "Enter Desired Host",
    isRequired: false,
    type: "text",
  },
  schema: {
    label: "Schema",
    placeholder: "Enter Schema",
    isRequired: false,
    type: "text",
  },
  DBName: {
    label: "Database Name",
    placeholder: "Enter DataBase Name",
    isRequired: true,
    type: "text",
  }
};

export const azureCloud = {
  dataSourceName: {
    label: "Database Name",
    placeholder: "Database Name",
    isRequired: true,
  },
  address: {
    label: "Host/IP Address",
    placeholder: "IP Address",
    isRequired: true,
    type: "text",
  },
  port: {
    label: "Port",
    placeholder: "Port",
    isRequired: true,
    type: "text",
  },
  desiredPort: {
    label: "Desired Port",
    placeholder: "Enter desired Port",
    isRequired: true,
    type: "number",
  },
  desiredHost: {
    label: "Desired Host",
    placeholder: "Enter Desired Host",
    isRequired: false,
    type: "text",
  },
  schema: {
    label: "Schema",
    placeholder: "Enter Schema",
    isRequired: false,
    type: "text",
  },
  DBName: {
    label: "Database Name",
    placeholder: "Enter DataBase Name",
    isRequired: true,
    type: "text",
  },
  username: {
    label: "Username",
    placeholder: "Enter Username",
    isRequired: true,
    type: "text",
  },
  password: {
    label: "Password",
    placeholder: "Enter Password",
    isRequired: true,
    type: "password",
  },
  connectionProperties: {
    label: "Connection Properties",
    placeholder: "Enter Connection Properties",
    isRequired: false,
    type: "text"
  }
};

export const normalDataSources = {
  dataSourceName: {
    label: "Datasource Name",
    placeholder: "Datasource Name",
    isRequired: true,
  },
  address: {
    label: "Host/IP Address",
    placeholder: "IP Address",
    isRequired: false,
  },
  port: {
    label: "Port",
    placeholder: "Port",
    isRequired: false,
  },
  desiredPort: {
    label: "Desired Port",
    placeholder: "Enter desired Port",
    isRequired: true,
    type: "text",
  },
  desiredHost: {
    label: "Desired Host",
    placeholder: "Enter Desired Host",
    isRequired: false,
    type: "text",
  },
  schema: {
    label: "Schema",
    placeholder: "Enter Schema",
    isRequired: false,
    type: "text",
  },
  DBName: {
    label: "Database Name",
    placeholder: "Enter DataBase Name",
    isRequired: true,
    type: "text",
  },
  username: {
    label: "Username",
    placeholder: "Enter Username",
    isRequired: true,
    type: "text",
  },
  password: {
    label: "Password",
    placeholder: "Enter Password",
    isRequired: true,
    type: "password",
  },
  instance: {
    label: "Instance",
    placeholder: "Instance",
    isRequired: false,
    type: "text"
  },
  connectionProperties: {
    label: "Connection Properties",
    placeholder: "Enter Connection Properties",
    isRequired: false,
    type: "text"
  }
};
