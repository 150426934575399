import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCubejsApi } from "../../common/Dashboard/cubejs-util";
import { chartTypes } from "../../common/Dashboard/chartTypeToComponent";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { getAlertPolicyDataFlowGroups } from "../../../redux/actions/getPolicyDataFlowGroupsAction";
import { changeAlertPolicySeverity } from "../../../redux/actions/changeAlertPolicySeverityAction";
import { DataMapRoute } from "../../common/DatamapImage";
import { getAllAlertPolicies } from "../../../redux/actions/getAlertsPoliciesAction";
import { tableConstants } from "./alertPolicyUtilities";
import { EnhancedTableToolbar } from "./EnhancedAlertPoliciesTableHeader";
import EnhancedGridTable from "../../shared/Table/Table";
import { PopoverComponent } from "./Popover";
import Checkbox from "@material-ui/core/Checkbox";
import { isBoolean, filter } from "lodash";
import DataFlowGroups from "./PolicyFlowGroup";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { updateAlertPolicy } from "../../../redux/constants/constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertsPolicies() {
  const dispatch = useDispatch();
  const history = useHistory();
  let cubejsApi = getCubejsApi();

  const isDeleted = useSelector((state) => state.policyDeleted.deleted);
  const policyCreated = useSelector((state) => state.policyCreated.created);
  const policyUpdated = useSelector((state) => state.policyUpdated.updated);
  const policyStatusUpdated = useSelector(
    (state) => state.policyStatusUpdated.policyStatusUpdated
  );
  const unsuccessfulCreateEdit = useSelector(
    (state) => state.policyCreated.unsuccessful
  );

  const policyStatusChangeMessage = useSelector(
    (state) => state.policyStatusUpdated.successMessage
  );
  const policyUpdatedSuccessMessage = useSelector(
    (state) => state.policyUpdated.successMessage
  );
  const policyCreatedSuccessMessage = useSelector(
    (state) => state.policyCreated.successMessage
  );
  const policyDeletedSuccessMessage = useSelector(
    (state) => state.policyDeleted.successMessage
  );
  const [snackPack, setSnackPack] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [openDataFlowGroupDialog, setOpenDataFlowGroupDialog] = useState(false);
  const [alertPolicyDataFlowGroups, setAlertPolicyDataFlowGroups] = useState(
    []
  );
  const [
    selectedAlertPolicyToShowFlowGroups,
    setSelectedAlertPolicyToShowFlowGroups,
  ] = useState("");
  const [policies, setPolicies] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [severityPopUpOpen, setSeverityPopUpOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowForSeverity, setSelectedRowForSeverityChange] =
    useState("");
  const [placement, setPlacement] = useState();
  const [sortBy, setSortBy] = useState("");
  const [loader, setLoader] = useState(true);
  const [selectedList, setSelectedList] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const ALERT_POLICY_STATUS = "DELETED";

  useEffect(() => {
    if (policyStatusChangeMessage !== "") {
      setLoader(true);
      getAllPolicies(recordsPerPage, currentPage);
      setSnackBarMessage(policyStatusChangeMessage);
    } else if (policyUpdatedSuccessMessage !== "") {
      setSnackBarMessage(policyUpdatedSuccessMessage);
    } else if (policyCreatedSuccessMessage !== "") {
      setSnackBarMessage(policyCreatedSuccessMessage);
    } else if (policyDeletedSuccessMessage !== "") {
      setLoader(true);
      getAllPolicies(recordsPerPage, currentPage);
      setSnackBarMessage(policyDeletedSuccessMessage);
    } else if (unsuccessfulCreateEdit !== "") {
      setSnackBarMessage(unsuccessfulCreateEdit);
    }
  }, [
    policyStatusChangeMessage,
    policyUpdatedSuccessMessage,
    policyCreatedSuccessMessage,
    policyDeletedSuccessMessage,
    unsuccessfulCreateEdit,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isDeleted ||
      policyCreated ||
      policyUpdated ||
      policyStatusUpdated ||
      unsuccessfulCreateEdit
    ) {
      setOpen(true);
    }
  }, [isDeleted, policyCreated, policyUpdated, policyStatusUpdated]);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  useEffect(() => {
    setLoader(true);
    setOrder("asc");
    setOrderBy("");
    setCurrentPage(0);
    getAllPolicies(recordsPerPage, 0);
  }, [sortBy, sortOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  function alertPolicyDataFlowGroupOpen(id, name, bool) {
    async function getAlertPolicyDataFlowGroupsAction(id) {
      const response = await getAlertPolicyDataFlowGroups(id);
      if (response) {
        setAlertPolicyDataFlowGroups(response.content);
      }
    }
    if (bool) {
      setOpenDataFlowGroupDialog(bool);
      setSelectedAlertPolicyToShowFlowGroups(name);
      getAlertPolicyDataFlowGroupsAction(id);
    } else {
      setOpenDataFlowGroupDialog(bool);
    }
  }

  async function getAllPolicies(recordsPerPage, currentPage) {
    const response = await getAllAlertPolicies(
      currentPage,
      recordsPerPage,
      sortBy,
      sortOrder
    );
    if (response && response.content) {
      const data = response.content;
      setPolicies(data);
      setTotalCount(response.totalElements);
      setSelectedList([]);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  //change severity from grid
  const changeAlertPolicySeveritySave = (severity) => {
    async function changeSeverity(severity) {
      const response = await changeAlertPolicySeverity(
        selectedRowForSeverity,
        severity
      );
      if (response) {
        dispatch({ type: "UPDATE_POLICY_STATUS_CHANGE_SUCCEEDED" });
        setSeverityPopUpOpen(false);
      }
    }
    if (severity) {
      changeSeverity(severity);
    }
  };

  const getSelectAllStatus = () => {
    if (selectedList.length === 0) {
      return "UNCHECKED";
    }
    if (selectedList.length === getPoliciesData()) {
      return "CHECKED";
    }
    return "INTERMEDIATE";
  };

  const getColumnsData = () => {
    let data = tableConstants(
      dispatch,
      alertPolicyDataFlowGroupOpen,
      history,
      handleOpenSeverityPopUp
    );
      data = [
        {
          title: () => {
            return (
              <Checkbox
                value={getSelectAllStatus()}
                onChange={(event) => onSelectAllClick(event)}
                inputProps={{ "aria-label": "decorative checkbox" }}
                checkedIcon={
                  selectedList.length === getPoliciesData() ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                indeterminate={
                  selectedList.length > 0 &&
                  selectedList.length < getPoliciesData()
                }
              />
            );
          },
          render: (rowData) => {
            return (
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                onChange={() => handleCheckItem(rowData)}
                disabled={
                  rowData &&
                  rowData.status &&
                  rowData.status.code === ALERT_POLICY_STATUS
                }
                checkedIcon={
                  rowData.checked ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={true}
              />
            );
          },
          padding: "checkbox",
          requiredPermission: updateAlertPolicy,
        },
      ...data,
      ];
    return data;
  };

  const getPoliciesData = () => {
    return filter(policies, (item) => item.status.code !== ALERT_POLICY_STATUS)
      .length;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "CLOSE_SNACKBAR" });
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const selectDataFlowGroupHandle = (data) => {
    data["type"] = "dataFlowGroups";
    dispatch({ type: "TRACK_TRAVERSAL", payload: "Data Flows" });
    history.push({
      pathname: "/tenant/dataFlows",
      state: data,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRecordsPerPage(value);
    setCurrentPage(0);
    setLoader(true);
    getAllPolicies(value, 0);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
    setLoader(true);
    getAllPolicies(recordsPerPage, page);
  };

  const handleCloseSeverityPopup = () => {
    setAnchorEl(null);
    setSeverityPopUpOpen(false);
  };

  const handleOpenSeverityPopUp = (newPlacement, row) => (event) => {
    setSelectedRowForSeverityChange(row.id);
    setAnchorEl(event.currentTarget);
    setSeverityPopUpOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const onSelectAllClick = (event) => {
    const list = [...policies];
    list.forEach((item) => {
      if (item.status.code !== ALERT_POLICY_STATUS) {
        if (event.target.checked) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      }
    });
    setSelectedList(filter(list, (item) => item.checked).map((i) => i.id));
    setPolicies(list);
  };

  const handleCheckItem = (item) => {
    const id = item.id;
    const list = [...policies];
    list.forEach((item) => {
      if (item.id === id) {
        item.checked = isBoolean(item.checked) ? !item.checked : true;
      }
    });
    setSelectedList(filter(list, (item) => item.checked).map((i) => i.id));
    setPolicies(list);
  };

  const handleSortBy = (value) => {
    setSortBy(value);
    if (value !== "") {
      setSortOrder("ASC");
    } else {
      setSortOrder("");
    }
  };

  const handleSortOrder = (value) => {
    setSortOrder(value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderPolicyGroups = () => {
    return (
      <DataFlowGroups
        openDataFlowGroupDialog={openDataFlowGroupDialog}
        selectDataFlowGroupHandle={selectDataFlowGroupHandle}
        setOpenDataFlowGroupDialog={setOpenDataFlowGroupDialog}
        handleClose={handleClose}
        alertPolicyDataFlowGroups={alertPolicyDataFlowGroups}
        selectedAlertPolicyToShowFlowGroups={
          selectedAlertPolicyToShowFlowGroups
        }
      />
    );
  };

  const renderPolicyTable = () => {
    return (
      <div className="filter-table-container">
        <div className="table-container">
          <EnhancedTableToolbar
            totalElements={totalCount}
            rows={policies}
            history={history}
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleSortOrder={handleSortOrder}
            handleSortBy={handleSortBy}
            selectedRows={selectedList}
            helpDescription={
              "Policies to monitor data access violations. Click on the policy name to view details or edit"
            }
          />
          <EnhancedGridTable
            cols={getColumnsData()}
            data={policies}
            totalCount={totalCount}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            order={order}
            orderBy={orderBy}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
            onRequestSort={handleRequestSort}
          />
          <PopoverComponent
            changeAlertPolicySeveritySave={changeAlertPolicySeveritySave}
            severityPopUpOpen={severityPopUpOpen}
            anchorEl={anchorEl}
            handleCloseSeverityPopup={handleCloseSeverityPopup}
          />
        </div>
      </div>
    );
  };

  const renderChartComponent = () => {
    return (
      <div className="chart-container alert-policies">
        {chartTypes.alertsOverTimeBarChart(cubejsApi, "Alerts Over Time")}
      </div>
    );
  };

  const renderPolicyHeader = () => {
    return (
      <div className="description">
        <div style={{ display: "grid" }}>
          <h2 style={{ margin: "0", fontSize: "32px" }}>Policies</h2>
          <p style={{ margin: "0" }}>
            Policies to monitor data access violations.
          </p>
        </div>
        <DataMapRoute />
      </div>
    );
  };

  const renderNotificationPopup = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        onExited={handleExited}
      >
        <Alert
          style={{
            width: "421px",
            height: "63px",
            background: "#2CE68F",
          }}
          onClose={handleClose}
          severity={"success"}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    );
  };

  const renderLoader = () => {
    return (
      loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )
    );
  };

  return (
    <React.Fragment>
      {renderPolicyGroups()}
      <div className="main">
        {renderPolicyHeader()}
        {renderChartComponent()}
        {renderPolicyTable()}
        {renderNotificationPopup()}
        {renderLoader()}
      </div>
    </React.Fragment>
  );
}
