import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {
  ACCESS_DETAILS_FILTERVALUES,
  DATABASE_LOGS_FILTERVALUES,
  dataSource,
  dataSourceType,
  databaseAccessRole,
  status,
  expiryDate,
  SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE,
  SERVICE_DATA_LOGS_SORT_CHANGE,
  INSPECT_CONTENT_SORT_BY_VALUE,
  INSPECT_CONTENT_ORDER_BY_VALUE
} from "../../redux/constants/constants";
import Asc from "../../assets/icons/Asc.svg";
import Dsc from "../../assets/icons/Dsc.svg";
import {
  changeFilterByvalue,
  filterBy_SelectedValues,
} from "../../redux/actions/userAccessrightsAction";
import "./sortComponent.scss";
import {
  CHANGE_FILTERBYVALUE_ACCESSDETAILS_ACTION,
} from "../../redux/actions/AccessDetailsAction";
import {
  CHANGE_FILTERBYVALUE_DATABASELOGS_ACTION,
  DATABASE_CHANGE_SORT,
  Database_changeOrderByValue,
  DATABASE_FILTER_LOGS,
} from "../../redux/actions/DatabaseLogsAction";
const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  formControlContainerSort: {
    width: "290px",
    background: "#DFE1E9",
    margin: "5px",
    borderRadius: "3px",
    paddingTop: "12px",
    paddingLeft: "10px",
  },
  formControlContainerFilter: {
    width: "250px",
    margin: "5px",
    borderRadius: "3px",
    paddingTop: "12px",
    paddingLeft: "10px",
    borderLeft: "2px  grey",
    borderRight: "2px  grey",
  },
  inspectContentSortFilters: {
    width: "180px",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#2947FD",
    border: "1px solid white",
    "input:hover ~ &": {
      backgroundColor: "#2947FD",
    },
  },
  databaseUsers: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  margin: {
    marginTop: "1rem",
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const { sortBy, loc } = props;
  const classes = useStyles();
  return (
    <Radio
      className={
        loc === "database_Users" ? classes.root : classes.databaseUsers
      }
      disableRipple
      color="default"
      checked={sortBy === props.value}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function FSortComponent(props) {
  const {
    handleSortingFilter,
    sortBy,
    loc,
    type,
    cloudFileSystem,
    selectedDatabaseFilter,
    alertIdCheck,
    sortOrder,
    handleSortOrder,
  } = props;
  const dispatch = useDispatch();
  const filterBy = useSelector(
    (state) => state.userAccessRightsReducer.filterValue
  );
  const sortByValue = useSelector(
    (state) => state.normalDataSourceReducer.sortByValue
  );
  const filterByAccessDetails = useSelector(
    (state) => state.accessDetails.filterValue
  );
  const sortByValue_AccessDetails = useSelector(
    (state) => state.normalDirectDataSourceReducer.sortByValue
  );
  const orderByValueAccessDetails = useSelector(
    (state) => state.normalDirectDataSourceReducer.orderByValue
  );
  const orderByValue = useSelector(
    (state) => state.normalDataSourceReducer.orderByValue
  );
  const databaseLogsFilterValue = useSelector(
    (state) => state.databaseLogsReducer.filterValue
  );
  const databaseLogs_orderByValue = useSelector(
    (state) => state.databaseLogsReducer.orderByValue
  );
  const databaseLogs_sortByValue = useSelector(
    (state) => state.databaseLogsReducer.sortingValue
  );
  const sortbyServiceDataLogs = useSelector(
    (state) => state.serviceAccessLogsReducer.sortingValue
  )
  const orderByValueServiceDataLogs = useSelector(
    (state) => state.serviceAccessLogsReducer.orderByValue
  )
  const orderByFlaggedValue = useSelector(
    (state) => state.flaggedDataSourceReducer.orderByValue
  );
  const sortByFlaggedValue = useSelector(
    (state) => state.flaggedDataSourceReducer.sortByValue
  );
  const sortbyServiceDataAccesses = useSelector(
    (state) => state.serviceDataAccessReducer.sortByValue
  );
  const orderByValueServiceDataAccess = useSelector(
    (state) => state.serviceDataAccessReducer.orderByValue
  );
  const sortByValueInspectContent = useSelector(
    (state) => state.InspectContentReducer.sortByValue
  );
  const orderByValueInspectContent = useSelector(
    (state) => state.InspectContentReducer.orderByValue
  );

  const renderOrderBy = () => {
    return (
      <>
        <div className="database-horizontal-line"></div>
        <RadioGroup
          onChange={(event) => handleSortOrder(event.target.value)}
          // defaultValue="username"
          aria-label="sortBy"
          name="customized-radios"
        >
          <div className="">
            <FormControlLabel
              className={classes.margin}
              value="ASC"
              control={<StyledRadio sortBy={sortOrder} />}
              label="Ascending"
            />
            <img
              src={Asc}
              className="asc"
              style={{
                marginTop: "18px",
                marginLeft: "2px",
                cursor: "pointer",
                float: "right",
                marginRight: "20px",
              }}
              onClick={() => {
                handleSortOrder("ASC");
              }}
            ></img>
          </div>
          <div>
            <FormControlLabel
              className={classes.margin}
              value="DESC"
              control={<StyledRadio sortBy={sortOrder} />}
              label="Descending"
            />
            <img
              src={Dsc}
              className="dsc"
              style={{
                marginTop: "18px",
                cursor: "pointer",
                float: "right",
                marginRight: "20px",
              }}
              onClick={() => {
                handleSortOrder("DESC");
              }}
            ></img>
          </div>
        </RadioGroup>
      </>
    );
  };
  const classes = useStyles();
  return (
    <FormControl
      component="fieldset"
      className={
        type === "filter"
          ? classes.formControlContainerFilter
          : type === "inspect_filters" 
          ? `${classes.formControlContainerSort} ${classes.inspectContentSortFilters}`
          : classes.formControlContainerSort
      }
    >
      <FormLabel style={{ fontSize: "12px" }}>
        {type === "filter" ? "Filter By" : "Sort By"}
      </FormLabel>

      {loc === "oneDrive_filter" && type !== "filter" ? (
        <>
        <RadioGroup
          onChange={(event) => {
            dispatch({type:INSPECT_CONTENT_SORT_BY_VALUE,payload:event.target.value});
            dispatch({type:INSPECT_CONTENT_ORDER_BY_VALUE,payload:"asc"});
          }}
          defaultValue="files"
          aria-label="sortBy"
          name="customized-radios"
        >
          <FormControlLabel
            className={classes.margin}
            value="Contents.columnName"
            control={<StyledRadio sortBy={sortByValueInspectContent} loc={loc} />}
            label={cloudFileSystem? "Files" : "Fields"}
          />
        </RadioGroup>
        <div className="database-horizontal-line"></div>
        <RadioGroup
          onChange={(event) => {
            dispatch({type:INSPECT_CONTENT_ORDER_BY_VALUE,payload:event.target.value});
            dispatch({type:INSPECT_CONTENT_SORT_BY_VALUE,payload: 'Contents.columnName'});
          }}
          // defaultValue="files"
          aria-label="sortBy"
          name="customized-radios"
        >
          <div className="">
            <FormControlLabel
              className={classes.margin}
              value="asc"
              control={<StyledRadio sortBy={orderByValueInspectContent} loc={loc} />}
              label="Ascending"
            />
            <img
              src={Asc}
              className="asc"
              style={{
                marginTop: "18px",
                marginLeft: "2px",
                cursor: "pointer",
                float: "right",
                marginRight: "20px",
              }}
              onClick={() => {
                dispatch({type:INSPECT_CONTENT_ORDER_BY_VALUE,payload:"asc"});
                dispatch({type:INSPECT_CONTENT_SORT_BY_VALUE,payload: 'Contents.columnName'});
              }}
            ></img>
          </div>
          <div>
            <FormControlLabel
              className={classes.margin}
              value="desc"
              control={<StyledRadio sortBy={orderByValueInspectContent} loc={loc} />}
              label="Descending"
            />
            <img
              src={Dsc}
              className="dsc"
              style={{
                marginTop: "18px",
                cursor: "pointer",
                float: "right",
                marginRight: "20px",
              }}
              onClick={() => {
                dispatch({type:INSPECT_CONTENT_ORDER_BY_VALUE,payload:"desc"});
                dispatch({type:INSPECT_CONTENT_SORT_BY_VALUE,payload: 'Contents.columnName'});
              }}
            ></img>
          </div>
        </RadioGroup>
      </>
      ) : loc === "database_Users" && type !== "filter" ? (
        <>
          <RadioGroup
            onChange={(event) => {
              dispatch({type:"DATA_SOURCE_SORT_BY_VALUE",payload:event.target.value});
              dispatch({type:"DATA_SOURCE_ORDER_BY_VALUE",payload:"ASC"})
            }}
            // defaultValue="username"
            aria-label="sortBy"
            name="customized-radios"
          >
            <FormControlLabel
              className={classes.margin}
              value="username"
              control={<StyledRadio sortBy={sortByValue} loc={loc} />}
              label="Username"
            />
            <FormControlLabel
              className={classes.margin}
              value="expirationTimestamp"
              control={<StyledRadio sortBy={sortByValue} loc={loc} />}
              label="Expiry Date"
            />
          </RadioGroup>
          <br />
          <br />
          <br />
          <div className="database-horizontal-line"></div>
          <RadioGroup
            onChange={(event) =>
              dispatch({type:"DATA_SOURCE_ORDER_BY_VALUE",payload:event.target.value})
            }
            // defaultValue="username"
            aria-label="sortBy"
            name="customized-radios"
          >
            <div className="">
              <FormControlLabel
                className={classes.margin}
                value="ASC"
                control={<StyledRadio sortBy={orderByValue} loc={loc} />}
                label="Ascending"
              />
              <img
                src={Asc}
                className="asc"
                style={{
                  marginTop: "18px",
                  marginLeft: "2px",
                  cursor: "pointer",
                  float: "right",
                  marginRight: "20px",
                }}
                onClick={() => {
                  dispatch({type:"DATA_SOURCE_ORDER_BY_VALUE",payload:"ASC"})
                }}
              ></img>
            </div>
            <div>
              <FormControlLabel
                className={classes.margin}
                value="DESC"
                control={<StyledRadio sortBy={orderByValue} loc={loc} />}
                label="Descending"
              />
              <img
                src={Dsc}
                className="dsc"
                style={{
                  marginTop: "18px",
                  cursor: "pointer",
                  float: "right",
                  marginRight: "20px",
                }}
                onClick={() => {
                  dispatch({type:"DATA_SOURCE_ORDER_BY_VALUE",payload:"DESC"})
                }}
              ></img>
            </div>
          </RadioGroup>
        </>
      ) :loc === "Flagged_database_Users" && type !== "filter" ? (
        <>
          <RadioGroup
            onChange={(event) => {
              dispatch({type:"DATA_FLAGGED_SOURCE_SORT_BY_VALUE",payload:event.target.value});
              dispatch({type:"DATA_FLAGGED_SOURCE_ORDER_BY_VALUE",payload:"ASC"})
            }}
            // defaultValue="username"
            aria-label="sortBy"
            name="customized-radios"
          >
            <FormControlLabel
              className={classes.margin}
              value="username"
              control={<StyledRadio sortBy={sortByFlaggedValue} loc={loc} />}
              label="Username"
            />
            <FormControlLabel
              className={classes.margin}
              value="expirationTimestamp"
              control={<StyledRadio sortBy={sortByFlaggedValue} loc={loc} />}
              label="Expiry Date"
            />
          </RadioGroup>
          <br />
          <br />
          <br />
          <div className="database-horizontal-line"></div>
          <RadioGroup
            onChange={(event) =>
              dispatch({type:"DATA_FLAGGED_SOURCE_ORDER_BY_VALUE",payload:event.target.value})
            }
            // defaultValue="username"
            aria-label="sortBy"
            name="customized-radios"
          >
            <div className="">
              <FormControlLabel
                className={classes.margin}
                value="ASC"
                control={<StyledRadio sortBy={orderByFlaggedValue} loc={loc} />}
                label="Ascending"
              />
              <img
                src={Asc}
                className="asc"
                style={{
                  marginTop: "18px",
                  marginLeft: "2px",
                  cursor: "pointer",
                  float: "right",
                  marginRight: "20px",
                }}
                onClick={() => {
                  dispatch({type:"DATA_FLAGGED_SOURCE_ORDER_BY_VALUE",payload:"ASC"})
                }}
              ></img>
            </div>
            <div>
              <FormControlLabel
                className={classes.margin}
                value="DESC"
                control={<StyledRadio sortBy={orderByFlaggedValue} loc={loc} />}
                label="Descending"
              />
              <img
                src={Dsc}
                className="dsc"
                style={{
                  marginTop: "18px",
                  cursor: "pointer",
                  float: "right",
                  marginRight: "20px",
                }}
                onClick={() => {
                  dispatch({type:"DATA_FLAGGED_SOURCE_ORDER_BY_VALUE",payload:"DESC"})
                }}
              ></img>
            </div>
          </RadioGroup>
        </>
      ): loc === "database_Users" && type === "filter" ? (
        <RadioGroup
          onChange={(event) => {
            dispatch(changeFilterByvalue(event.target.value));
            dispatch(filterBy_SelectedValues([]));
            dispatch({
              type: "UPDATE_EXPIRY_DATES",
              payload: {
                expiryFromDate: "",
                expiryToDate: "",
              },
            });
          }}
          // defaultValue="dataSourceNames"
          aria-label="sortBy"
          name="customized-radios"
        >
          <FormControlLabel
            className={classes.margin}
            value={dataSource}
            control={<StyledRadio sortBy={filterBy} />}
            label="Data Source Name"
          />
          <FormControlLabel
            className={classes.margin}
            value={dataSourceType}
            //disabled
            control={<StyledRadio sortBy={filterBy} />}
            label="Data Source Type"
          />
          <FormControlLabel
            className={classes.margin}
            //disabled
            value={databaseAccessRole}
            control={<StyledRadio sortBy={filterBy} />}
            label="Data Access Role"
          />
          <FormControlLabel
            className={classes.margin}
            //disabled
            value={status}
            control={<StyledRadio sortBy={filterBy} />}
            label="Account Status"
          />
          <FormControlLabel
            className={classes.margin}
            //disabled
            value={expiryDate}
            control={<StyledRadio sortBy={filterBy} />}
            label="Expiry Date"
          />
        </RadioGroup>
      ) : loc === "Access_details" && type !== "filter" ? (
        <>
        <RadioGroup
          onChange={(event) => {
            dispatch({type:"DIRECT_DATA_SOURCE_SORT_BY_VALUE",payload:event.target.value});
            dispatch({type:"DIRECT_DATA_SOURCE_ORDER_BY_VALUE",payload:"ASC"})
          }}
          defaultValue="username"
          aria-label="sortBy"
          name="customized-radios"
        >
          <FormControlLabel
            className={classes.margin}
            value="username"
            control={
              <StyledRadio sortBy={sortByValue_AccessDetails} loc={loc} />
            }
            label="Username"
          />
          <FormControlLabel
            className={classes.margin}
            value="dataSourceName"
            control={
              <StyledRadio sortBy={sortByValue_AccessDetails} loc={loc} />
            }
            label="Data Source Name"
          />
          <FormControlLabel
            className={classes.margin}
            value="firstAccessedOn"
            control={
              <StyledRadio sortBy={sortByValue_AccessDetails} loc={loc} />
            }
            label="First Accessed Time"
          />
          <FormControlLabel
            className={classes.margin}
            value="lastAccessedOn"
            control={
              <StyledRadio sortBy={sortByValue_AccessDetails} loc={loc} />
            }
            label="Last Accessed Time"
          />
        </RadioGroup>
        <br />
        <div className="database-horizontal-line"></div>
        <RadioGroup
          onChange={(event) => {
            dispatch({type:"DIRECT_DATA_SOURCE_ORDER_BY_VALUE",payload:event.target.value})
          }}
          // defaultValue="username"
          aria-label="sortBy"
          name="customized-radios"
        >
          <div className="">
            <FormControlLabel
              className={classes.margin}
              value="ASC"
              control={
                <StyledRadio sortBy={orderByValueAccessDetails} loc={loc} />
              }
              label="Ascending"
            />
            <img
              src={Asc}
              className="asc"
              style={{
                marginTop: "18px",
                marginLeft: "2px",
                cursor: "pointer",
                float: "right",
                marginRight: "20px",
              }}
              onClick={() => {
                dispatch({type:"DIRECT_DATA_SOURCE_ORDER_BY_VALUE",payload:"ASC"})
              }}
            ></img>
          </div>
          <div>
            <FormControlLabel
              className={classes.margin}
              value="DESC"
              control={
                <StyledRadio sortBy={orderByValueAccessDetails} loc={loc} />
              }
              label="Descending"
            />
            <img
              src={Dsc}
              className="dsc"
              style={{
                marginTop: "18px",
                cursor: "pointer",
                float: "right",
                marginRight: "20px",
              }}
              onClick={() => {
                dispatch({type:"DIRECT_DATA_SOURCE_ORDER_BY_VALUE",payload:"DESC"})
              }}
            ></img>
          </div>
        </RadioGroup>
        </>
      ) : loc === "Access_details" && type === "filter" ? (
        <RadioGroup
          onChange={(event) => {
            dispatch(
              CHANGE_FILTERBYVALUE_ACCESSDETAILS_ACTION(event.target.value)
            );
          }}
          defaultValue={ACCESS_DETAILS_FILTERVALUES.DATASOURCE_NAME}
          aria-label="sortBy"
          name="customized-radios"
        >
          <FormControlLabel
            className={classes.margin}
            value={ACCESS_DETAILS_FILTERVALUES.DATASOURCE_NAME}
            control={<StyledRadio sortBy={filterByAccessDetails} loc={loc} />}
            label="Data Source Names"
          />
          <FormControlLabel
            className={classes.margin}
            //disabled
            value={ACCESS_DETAILS_FILTERVALUES.DATASOURCE_TYPE}
            control={<StyledRadio sortBy={filterByAccessDetails} loc={loc} />}
            label="Data Source Type"
          />
          <FormControlLabel
            className={classes.margin}
            //disabled
            value={ACCESS_DETAILS_FILTERVALUES.DATABASE_ACCESSROLE}
            control={<StyledRadio sortBy={filterByAccessDetails} loc={loc} />}
            label="Database Access Roles"
          />
        </RadioGroup>
      ) : loc === "alerts" || loc === "summary" || loc === "resolved-alerts" ? (
        <>
          <RadioGroup
            onChange={(event) => handleSortingFilter(event)}
            defaultValue="severity"
            aria-label="sortBy"
            name="customized-radios"
          >
            <FormControlLabel
              value="severity"
              control={<StyledRadio sortBy={sortBy} />}
              label="Policy Severity Level"
            />
            <FormControlLabel
              value="generatedOn"
              control={<StyledRadio sortBy={sortBy} />}
              label="Alert Time"
            />
            <FormControlLabel
              value="name"
              control={<StyledRadio sortBy={sortBy} />}
              label="Policy Name"
            />
          </RadioGroup>

          {renderOrderBy()}
        </>
      ) : loc === "policy" ? (
        <>
          <RadioGroup
            onChange={(event) => handleSortingFilter(event)}
            defaultValue="policy"
            aria-label="sortBy"
            name="customized-radios"
          >
            <FormControlLabel
              value="policy"
              control={<StyledRadio sortBy={sortBy} />}
              label="Policy"
            />
            <FormControlLabel
              value="alertsCount"
              control={<StyledRadio sortBy={sortBy} />}
              label="Alerts"
            />
            <FormControlLabel
              value="dataFlowGroupsCount"
              control={<StyledRadio sortBy={sortBy} />}
              label="Data Flow Groups"
            />
            <FormControlLabel
              value="severity"
              control={<StyledRadio sortBy={sortBy} />}
              label="Severity"
            />
          </RadioGroup>
          {renderOrderBy()}
        </>
      ) : loc === "database-logs" && type !== "filter" ? (
        <div>
          <RadioGroup
            onChange={(event) => {
              dispatch(DATABASE_CHANGE_SORT(event.target.value));
              dispatch(Database_changeOrderByValue("ASC"));
            }}
            // defaultValue="username"
            aria-label="sortBy"
            name="customized-radios"
          >
            <FormControlLabel
              className={classes.margin}
              value="username"
              control={
                <StyledRadio sortBy={databaseLogs_sortByValue} loc={loc} />
              }
              label="Username"
            />
          </RadioGroup>
          <br />
          <br />
          <br />
          <div className="database-horizontal-line"></div>
          <RadioGroup
            onChange={(event) => {
              dispatch(Database_changeOrderByValue(event.target.value));
            }}
            // defaultValue="username"
            aria-label="sortBy"
            name="customized-radios"
          >
            <div className="">
              <FormControlLabel
                className={classes.margin}
                value="ASC"
                control={
                  <StyledRadio sortBy={databaseLogs_orderByValue} loc={loc} />
                }
                label="Ascending"
              />
              <img
                src={Asc}
                className="asc"
                style={{
                  marginTop: "18px",
                  marginLeft: "2px",
                  cursor: "pointer",
                  float: "right",
                  marginRight: "20px",
                }}
                onClick={() => {
                  dispatch(Database_changeOrderByValue("ASC"));
                }}
              ></img>
            </div>
            <div>
              <FormControlLabel
                className={classes.margin}
                value="DESC"
                control={
                  <StyledRadio sortBy={databaseLogs_orderByValue} loc={loc} />
                }
                label="Descending"
              />
              <img
                src={Dsc}
                className="dsc"
                style={{
                  marginTop: "18px",
                  cursor: "pointer",
                  float: "right",
                  marginRight: "20px",
                }}
                onClick={() => {
                  dispatch(Database_changeOrderByValue("DESC"));
                }}
              ></img>
            </div>
          </RadioGroup>
        </div>
      ) : loc === "database-logs" && type === "filter" ? (
        <RadioGroup
          onChange={(event) => {
            // selectedDatabaseFilter
            dispatch(
              CHANGE_FILTERBYVALUE_DATABASELOGS_ACTION(event.target.value)
            );
            dispatch(DATABASE_FILTER_LOGS([]));
          }}
          defaultValue={DATABASE_LOGS_FILTERVALUES.DATASOURCE_NAME}
          aria-label="sortBy"
          name="customized-radios"
        >
          <FormControlLabel
            className={classes.margin}
            value={DATABASE_LOGS_FILTERVALUES.DATASOURCE_NAME}
            control={<StyledRadio sortBy={databaseLogsFilterValue} loc={loc} />}
            label="Data Source Names"
          />
          <FormControlLabel
            className={classes.margin}
            //disabled
            value={DATABASE_LOGS_FILTERVALUES.DATASOURCE_TYPE}
            control={<StyledRadio sortBy={databaseLogsFilterValue} loc={loc} />}
            label="Data Source Type"
          />
          {alertIdCheck ? (
            <FormControlLabel
              className={classes.margin}
              value={DATABASE_LOGS_FILTERVALUES.ALERT_ID}
              control={
                <StyledRadio sortBy={databaseLogsFilterValue} loc={loc} />
              }
              label="Alert ID"
            />
          ) : null}

          <FormControlLabel
            className={classes.margin}
            //disabled
            value={DATABASE_LOGS_FILTERVALUES.DATABASE_ACCESSROLE}
            control={<StyledRadio sortBy={databaseLogsFilterValue} loc={loc} />}
            label="Database Access Roles"
          />
          <FormControlLabel
            className={classes.margin}
            value={DATABASE_LOGS_FILTERVALUES.SENSITIVITY_LEVEL}
            control={<StyledRadio sortBy={databaseLogsFilterValue} loc={loc} />}
            label="Sensitivity Level"
          />
          <FormControlLabel
            className={classes.margin}
            value={DATABASE_LOGS_FILTERVALUES.TIME_RANGE}
            control={<StyledRadio sortBy={databaseLogsFilterValue} loc={loc} />}
            label="Time Range"
          />
          <FormControlLabel
            className={classes.margin}
            value={DATABASE_LOGS_FILTERVALUES.POLICY_NAME}
            control={<StyledRadio sortBy={databaseLogsFilterValue} loc={loc} />}
            label="Policy Name"
          />
        </RadioGroup>
      ) : loc === "Service_Data_Accesses" && type !== "filter" ? (
          <>
          <RadioGroup
            onChange={(event) => {
              dispatch({type:"DIRECT_DATA_SOURCE_SORT_BY_VALUE_SERVICE_DATA_ACCESS", payload:event.target.value});
              dispatch({type:"DIRECT_DATA_SOURCE_ORDER_BY_VALUE_SERVICE_DATA_ACCESS", payload:"ASC"})
            }}
            defaultValue="username"
            aria-label="sortBy"
            name="customized-radios"
          >
            <FormControlLabel
              className={classes.margin}
              value="username"
              control={
                <StyledRadio sortBy={sortbyServiceDataAccesses} loc={loc} />
              }
              label="Service User"
            />
          </RadioGroup>
          <br />
          <div className="database-horizontal-line"></div>
          <RadioGroup
            onChange={(event) => {
              dispatch({type:"DIRECT_DATA_SOURCE_ORDER_BY_VALUE_SERVICE_DATA_ACCESS",payload:event.target.value})
            }}
            // defaultValue="username"
            aria-label="sortBy"
            name="customized-radios"
          >
            <div className="">
              <FormControlLabel
                className={classes.margin}
                value="ASC"
                control={
                  <StyledRadio sortBy={orderByValueServiceDataAccess} loc={loc} />
                }
                label="Ascending"
              />
              <img
                src={Asc}
                className="asc"
                style={{
                  marginTop: "18px",
                  marginLeft: "2px",
                  cursor: "pointer",
                  float: "right",
                  marginRight: "20px",
                }}
                onClick={() => {
                  dispatch({type:"DIRECT_DATA_SOURCE_ORDER_BY_VALUE_SERVICE_DATA_ACCESS",payload:"ASC"})
                }}
              ></img>
            </div>
            <div>
              <FormControlLabel
                className={classes.margin}
                value="DESC"
                control={
                  <StyledRadio sortBy={orderByValueServiceDataAccess} loc={loc} />
                }
                label="Descending"
              />
              <img
                src={Dsc}
                className="dsc"
                style={{
                  marginTop: "18px",
                  cursor: "pointer",
                  float: "right",
                  marginRight: "20px",
                }}
                onClick={() => {
                  dispatch({type:"DIRECT_DATA_SOURCE_ORDER_BY_VALUE_SERVICE_DATA_ACCESS",payload:"DESC"})
                }}
              ></img>
            </div>
          </RadioGroup>
          </>
      ) :loc === "service-database-logs" && type !== "filter" ? (
        <div>
        <RadioGroup
            onChange={(event) => {
              dispatch({type: SERVICE_DATA_LOGS_SORT_CHANGE, payload: event.target.value});
              dispatch({type: SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE, payload: "ASC"})
            }}
          aria-label="sortBy"
          name="customized-radios"
        >
          <FormControlLabel
            className={classes.margin}
            value="username"
            control={
              <StyledRadio sortBy={sortbyServiceDataLogs} loc={loc} />
            }
            label="Service User"
          />
        </RadioGroup>
        <br />
        <br />
        <br />
        <div className="database-horizontal-line"></div>
        <RadioGroup
          onChange={(event) => {
            dispatch({type: SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE, payload: event.target.value});
          }}
          aria-label="sortBy"
          name="customized-radios"
        >
          <div className="">
            <FormControlLabel
              className={classes.margin}
              value="ASC"
              control={
                <StyledRadio sortBy={orderByValueServiceDataLogs} loc={loc} />
              }
              label="Ascending"
            />
            <img
              src={Asc}
              className="asc"
              style={{
                marginTop: "18px",
                marginLeft: "2px",
                cursor: "pointer",
                float: "right",
                marginRight: "20px",
              }}
              onClick={() => {
                dispatch({type: SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE, payload: "ASC"});
              }}
            ></img>
          </div>
          <div>
            <FormControlLabel
              className={classes.margin}
              value="DESC"
              control={
                <StyledRadio sortBy={orderByValueServiceDataLogs} loc={loc} />
              }
              label="Descending"
            />
            <img
              src={Dsc}
              className="dsc"
              style={{
                marginTop: "18px",
                cursor: "pointer",
                float: "right",
                marginRight: "20px",
              }}
              onClick={() => {
                dispatch({type: SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE, payload: "DESC"});
              }}
            ></img>
          </div>
        </RadioGroup>
      </div>
      ):      
      (
        ""
      )}
    </FormControl>
  );
}
