import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../../assets/scss/BackOfficeStyles/Common.scss";
import DeleteIcon from "../../../assets/vizr_images/ic_rubbish_bin.png";
import { DeleteButtonForToolBar } from "../../common/buttons";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  autocompleteRoot: {
    maxWidth: 300, // specify the desired width
  },
});

const InputField = ({
  number,
  inputonChangeHandler,
  selectOnchangeHandler,
  input,
  roleId,
  error,
  state,
  select,
  key,
  handleClickDelete,
  checkBoxChangeHandler,
}) => {
  const classes = useStyles();
  const getSelectedRoles = (state, roleId, number) => {
    // Check if the necessary properties exist and if the roleIds array has at least one element
    if (state[number - 1] && state[number - 1].roleIds && state[number - 1].roleIds.length > 0) {
      // If the conditions are met, map over the roleIds array and find the corresponding role objects
      return state[number - 1].roleIds.map((id) =>
        roleId.find((role) => role.id === id)
      );
    } else {
      return [];
    }
  };
  return (
    <Grid
      container
      direction="row"
      className="InputField_flex"
      key={key}
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Grid item xs={4} style={{ display: "flex", alignItems: "flex-end" }}>
        <div className="inputFieldNumber_container">
          <div className="inputFieldNumber">{number}</div>
        </div>
        <div className="input_Container">
          <h3 className="onboarding_text">Email</h3>
          <input
            value={state[number - 1] ? state[number - 1].email : ""}
            type="text"
            className="InputTextFeildCreateUsers"
            onChange={(event) => inputonChangeHandler(event, number - 1)}
          ></input>
          {error && error.email ? (
            <div style={{ color: "red", position: "absolute" }}>{error.email}</div>
          ) : null}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <h3 className="onboarding_text">Roles</h3>
          <Autocomplete
            options={roleId || []}
            getOptionLabel={(option) => option ? option.name : ""}
            value={getSelectedRoles(state, roleId, number)}
            multiple={true}
            onChange={(event, value) => selectOnchangeHandler(event, value, number - 1)}
            renderInput={(params) => (
              <TextField {...params} classes={{ root: classes.autocompleteRoot }} size="small" variant="outlined" />
            )}
          />
          {error && error.roles ? (
            <div style={{ color: "red", position: "absolute" }}>{error.roles}</div>
          ) : null}
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className="">
          <h3 className="onboarding_text">Enable MFA</h3>
          <Checkbox
            checked={state[number - 1].isChecked}
            color="primary"
            onChange={(event) => checkBoxChangeHandler(event, number - 1)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </Grid>

      {state.length !== 1 ? (
        <Grid item xs={2} style={{ display: "flex", alignItems: "flex-end" }}>
          <DeleteButtonForToolBar
            variant="contained"
            id="delete"
            size="small"
            color="primary"
            onClick={(e) => {
              handleClickDelete(e, number - 1);
            }}
            className="min-margin table-buttons"
          >
            <img
              style={{ width: "24px", height: "22px" }}
              src={DeleteIcon}
              alt="delete"
            />
          </DeleteButtonForToolBar>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default InputField;
