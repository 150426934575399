import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../assets/vizr_images/ic_remove.svg";
import { Button } from "@material-ui/core";
import {
  CLEAR_REPORT_HISTORY_DATA,
  CLOSE_REPORT_HISTORY,
  GET_REPORT_HISTORY,
} from "../../../redux/constants/constants";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { expiryDaysCalculation, getExpiryDetails } from '../../common/utilities';
import { formatDateAndTime } from './utilities';
import { UTCTooltip } from "./UTCTooltip";
import { isEmpty, isNull } from "lodash";

function ReportHistory() {
  const reportHistoryData = useSelector(
    (state) => state.GenerateReportReducer.reportHistoryData
  );
  const sharedHistoryData = useSelector(
    (state) => state.GenerateReportReducer.sharedHistoryData
  );
  const reportHistoryLoader = useSelector(
    (state) => state.GenerateReportReducer.reportHistoryLoader
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_REPORT_HISTORY, payload: reportHistoryData.reportId });
    return () => {
      dispatch({ type: CLEAR_REPORT_HISTORY_DATA });
    };
  }, []);

  // Function to format user initials from the given full user name
  const formatUserNameInitials = (userName) => {
    // Split the user name into words and map each word to its first character
    return userName.split(" ").map((el) => el[0]);
  };

  // Function to render a single field with its title and content
  const renderFieldDetails = (title, content) => {
    return (
      <div className="reportHistoryHeaderDetails">
        <div className="reportHistoryFieldTitle">{title}:</div>
        <div>{content}</div>
      </div>
    );
  };

  // Function to render the entire report details section
  const renderReportDetails = () => {
    // Destructure properties from the reportHistoryData object
    const {
      reportName,
      creationTimestamp,
      createdBy,
      sectionType,
      startDate,
      endDate,
      status,
      numberOfRows,
    } = reportHistoryData;

    const reportPeriodCheck = (startDate, endDate) => {
      if (!isEmpty(startDate) && !isEmpty(endDate)) {
        return `${formatDateAndTime(startDate, "MM-DD-YYYY")} to ${formatDateAndTime(endDate, "MM-DD-YYYY")}`
      } else {
        return "-"
      }
    };

    return (
      <div>
        {/* Header section with report title and close button */}
        <div className="reportHistoryHeader">
          <div className="reportHistoryTitle">{reportName || null}</div>
          <Button
            className="close-icon"
            onClick={() => dispatch({ type: CLOSE_REPORT_HISTORY })}
            aria-label="close"
          >
            <img src={CloseIcon} alt={"close"} />
          </Button>
        </div>

        {/* Individual field sections */}
        {renderFieldDetails(
          "Generated On",
          // Format creation timestamp using formatDateAndTime function
          creationTimestamp ? (
            <div className="header-icon history-time">
              <span>{formatDateAndTime(creationTimestamp, "MM-DD-YYYY, HH:mm")}</span>
              {UTCTooltip()}
            </div>
          ) : ( "-" )
        )}

        {renderFieldDetails(
          "Generated By",
          // Render user initials and full name if createdBy exists
          !isEmpty(createdBy) ? (
            <div className="generatedBy">
              <span className="userName">
                {/* Call formatUserNameInitials to get user initials */}
                {formatUserNameInitials(createdBy)}
              </span>
              <div>{createdBy}</div>
            </div>
          ) : (
            "-"
          )
        )}

        {renderFieldDetails("Content Generated", sectionType || "-")}

        {renderFieldDetails(
          "Report Period",
          // Format and display report date range
          <div className="header-icon history-time">
            <span>{reportPeriodCheck(startDate, endDate)}</span>
            {UTCTooltip()}
          </div>
        )}
        {renderFieldDetails("Status", status || "-")}

        {renderFieldDetails("No of Rows", numberOfRows || "-")}
      </div>
    );
  };

  const renderExpirationDetails = () => {
    const remainingDays = expiryDaysCalculation(reportHistoryData.expirationTime);
    const expiryDetails = getExpiryDetails(remainingDays);
    return (
      <div>
        <hr className="separator" />
        <div className={`expiryDetails ${expiryDetails.colorClassName}`}>
          <img src={expiryDetails.icon} alt="expiry" />
          <span>
            This Report Expires in {remainingDays} days at{" "}
            {formatDateAndTime(reportHistoryData.expirationTime, "MM-DD-YYYY")}
          </span>
        </div>
        <hr className="separator" />
      </div>
    );
  };

  const renderDateAndTime = (data) => {
    if (data) {
      const reportSharedTime = formatDateAndTime(
        data,
        "MM-DD-YYYY HH:mm"
      ).split(" ");
      return (
        <>
          <div>{reportSharedTime[0]}</div>
          <div>{reportSharedTime[1]}</div>
        </>
      );
    } else return null;
  };
  const renderSharedHistoryDetails = () => {
    return (
      <>
        <div className="sharedHistoryTitle">Sharing History</div>
        <div className="sharedHistoryTableHead">
          <div className="sharedHistoryEmail">Shared with</div>
          <div className="sharedHistoryTime header-icon">
            Date and Time {UTCTooltip()}
          </div>
        </div>
        <div className="sharedHistoryTable">
          {sharedHistoryData.length > 0 ? (
            sharedHistoryData.map((data, index) => {
              return (
                <>
                  <div className="sharedHistoryDetails">
                    <div className="sharedHistoryEmail sharedEmail">
                      {data.email ? (
                        <span className="userName">
                          {data.email.split(" ").map((el) => {
                            return el[0];
                          })}
                        </span>
                      ) : null}
                      {data.email ? data.email : null}
                    </div>
                    <div className="sharedHistoryTime">
                      {renderDateAndTime(data.dateAndTime)}
                    </div>
                  </div>
                  {index !== sharedHistoryData.length - 1 && (
                    <hr className="separator" />
                  )}
                </>
              );
            })
          ) : (
            <div className="noSharedData">No Data to Show</div>
          )}
        </div>
      </>
    );
  };
  const renderReportHistory = () => {
    return (
      <div className="reportHistoryDrawer">
        {renderReportDetails()}
        {renderExpirationDetails()}
        {renderSharedHistoryDetails()}
      </div>
    );
  };
  return (
    <>
      {reportHistoryLoader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source reports"
        ></img>
      )}
      {renderReportHistory()}
    </>
  );
}

export default ReportHistory;
