import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  FormControlLabel,
  TextField,
  makeStyles,
  Chip,
  Button
} from "@material-ui/core";


import { ColoredActionButton, ConnectButton, SaveButton } from "../../common/buttons";
import { isCloudFileSystem } from "../../common/utilities";
import { OTHER } from "../../../redux/constants/constants";

export const renderGroupNames = (
  fixedOptions,
  group,
  updatedGroups,
  handleGroupNameChange,
  classes
) => {
    // Filter out options that are not in scope and those already selected
    const selectableGroups = updatedGroups.allGroups.filter(
      (g) => g.inScope !== true && !group.some(selectedGroup => selectedGroup.id === g.id)
    );

  return (
    <Autocomplete
      multiple
      id="fixed-tags-demo"
      value={group}
      onChange={(event, newValue) => {
        handleGroupNameChange(newValue);
      }}
      options={
        selectableGroups
          .map((group) => ({
            ...group,
            firstLetter: group.name[0].toUpperCase(),
          }))
          .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) || []
      }
      getOptionLabel={(option) => option.name}
      noOptionsText={updatedGroups.noOptionText}
      groupBy={(option) => option.firstLetter}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            disabled={fixedOptions.indexOf(option) !== -1}
          />
        ))
      }
      limitTags={4}
      style={{ width: "100%" }}
      classes={{
        noOptions: updatedGroups.noOptionClass,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select Groups"
          classes={{ root: classes.autocompleteRoot }}
          size="small"
        />
      )}
    />
  );
};


const renderDataSourceConnectButton = (isEditMode, engine, connection, loader) => {
  if (isEditMode && engine !== OTHER) {
    return (
      <>
        <div style={{ display: "flex" }}>
          <ConnectButton
            variant="contained"
            color="primary"
            style={{ width: "124px", height: "32px" }}
            size="small"
            label="Save connection"
            onClick={(event) => connection(event, "test")}
            disabled={loader}
            className="min-margin"
          >
            Connect
          </ConnectButton>
        </div>
      </>
    );
  }
  return null;
};


export const dataSourceButtons = (props, isEditMode, engine, connection, handleDisableSaveButton, handleSaveActions, loader ) => {
  return (
    <div className="dataSourceButtonsContainer">
      <ColoredActionButton
        style={{
          width: "124px",
          height: "32px",
          boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
        }}
        onClick={(event) => props.toggleDrawer(false, event)}
      >
        Cancel
      </ColoredActionButton>
      {isEditMode && !isCloudFileSystem(engine) ? renderDataSourceConnectButton(isEditMode, engine, connection, loader) : null}
      <Button
        type="submit"
        variant="contained"
        disabled={handleDisableSaveButton()}
        color="primary"
        style={{ marginLeft: "10px", textTransform: "none", width: "124px", height: "32px" }}
        onClick={(event) => handleSaveActions(event)}
      >
        Save
      </Button>
    </div>
  );
}; 
