import React, { useState, useEffect } from "react";
import SignUpBackGround from "../../BackOfficeGeneralContainer/SignupPageBackGround";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { getService, getAxiosHeaders } from "../../../../library/RestAPI";
import {
  ApiCall_DataSourceNames,
  ApiCall_DatabaseRoles,
  Create_Data_Source_Users,
  Get_Data_Source_Roles,
} from "../../../../redux/constants/constants";
import "./createDataSourceUser.scss";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TodayIcon from "@material-ui/icons/Today";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import DateTimeRage from "./DateTimeRange";
import {
  NewPolicyButtonForPolicyCreateEdit,
  ColoredActionButton,
} from "../../../common/buttons";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Page_Loader from "../../../../assets/vizr_images/page_loader.gif";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { addDays } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export const CreateDataSourceUser = () => {
  const [dataSourceNames, setDataSourceNames] = useState([]);
  const [dataSourceUsers, setDataSourceUsers] = useState([]);
  const [dataSourceName, setDataSourceName] = useState("");
  const [description, setDescription] = useState("");
  const [dataSourceUsername, setDataSourceUsername] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openCalender, setOpenCalender] = useState(false);
  const [expiryDate, setExpiryDate] = useState(
    new Date(addDays(new Date(), 90)).getTime()
  );
  const [openBatchEditCalender, setOpenBatchEditCalender] = useState(false);
  const fixedOptions = [];
  const [value, setValue] = React.useState([...fixedOptions]);
  const [loader, setLoader] = useState(true);
  const [error, setErrors] = useState({});
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const getDataService = (url) => {
    return getService({
      method: "GET",
      url: `${url}`,
      headers: getAxiosHeaders(true),
    });
  };

  useEffect(() => {
    getAPICall();
  }, []);

  const epocTimeGenerator = (curExpiry, expiryDate) => {
    var theDate = new Date(curExpiry);
    var myNewDate = new Date(theDate);
    myNewDate.setDate(myNewDate.getDate() + expiryDate);
    return myNewDate.getTime();
  };

  const onTimeChange = (calenderSideMenu, expiryDateCalendar) => {
    if (calenderSideMenu) {
      setExpiryDate(epocTimeGenerator(expiryDate, expiryDateCalendar));
    } else {
      setExpiryDate(expiryDateCalendar);
    }
  };

  const getAPICall = async () => {
    await Promise.all([getDataService(ApiCall_DataSourceNames)]).then(
      (data) => {
        if (data && data[0].status === 200) {
          setDataSourceNames(data[0].data);
          setDataSourceName(data[0].data[0].id);
          getRoles(data[0].data[0].id);
          setLoader(false);
        } else {
          setLoader(false);
        }
      }
    );
  };

  const getRoles = async (id) => {
    const response = await getDataService(Get_Data_Source_Roles(id));
    if (response && response.status === 200) {
      setDataSourceUsers(response.data.content);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleValidation = () => {
    const errors = {};
    if (value.length === 0) {
      errors.databaseRole = "please select roles or add roles";
    }
    if (isEmpty(dataSourceUsername) || isEmpty(dataSourceUsername.trim())) {
      errors.dataSourceUsername =
        "Data Source Username cannot be blank or empty";
    }
    return errors;
  };

  const handleSave = async () => {
    const errors = handleValidation();
    if (Object.keys(errors).length > 0) {
      setErrors({ ...errors });
    } else {
      setErrors({});
      let request = {
        username: dataSourceUsername,
        dataSource: {
          id: dataSourceName,
          name: dataSourceUsername,
          // aliasName: "konakartDB",
          databaseAccessRequest: { expirationTimestamp: expiryDate },
          roles: value,
        },
      };

      const res = await getService({
        method: "POST",
        url: Create_Data_Source_Users,
        headers: getAxiosHeaders(true),
        data: request,
      });
      if (res && res.status === 200) {
        history.push("/tenant/useraccessRights");
      } else {
        setSnackBarMessage("datasource creation is failed");
        setOpen(true);
      }
    }
  };

  const renderHeader = () => {
    return (
      <SignUpBackGround
        headerName=""
        header=""
        breadcrumbValue={[
          {
            value: "User Access Rights",
            link: "/tenant/useraccessRights",
          },
          { value: "Data Source Users", link: "/tenant/useraccessRights" },
          { value: "Create Data Source User", link: "" },
        ]}
        // type="marginRequired"
      ></SignUpBackGround>
    );
  };

  const renderForm = () => {
    return (
      <div className={classes.root}>
        <Grid container spacing={3} style={{ paddingTop: "15px" }}>
          <Grid item xs={12} sm={4}>
            <div>Data Source Name</div>
            <FormControl variant="outlined" fullWidth={true}>
              <Select
                value={dataSourceName}
                onChange={(event) => {
                  setLoader(true);
                  setDataSourceName(event.target.value);
                  getRoles(event.target.value);
                }}
              >
                {dataSourceNames.map((data) => {
                  return <MenuItem value={data.id}>{data.aliasName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              Data Source User Role {<span style={{ color: "red" }}>*</span>}
            </div>
            <FormControl variant="outlined" fullWidth={true}>
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                limitTags={2}
                value={value}
                onChange={(event, newValue) => {
                  setErrors({});
                  setValue([
                    ...fixedOptions,
                    ...newValue.filter(
                      (option) => fixedOptions.indexOf(option) === -1
                    ),
                  ]);
                }}
                options={dataSourceUsers}
                getOptionLabel={(option) => option.name}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      disabled={fixedOptions.indexOf(option) !== -1}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Data Source User Role"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        setValue([
                          ...value,
                          { id: "", name: event.target.value },
                        ]);
                      }
                    }}
                  />
                )}
              />
              {error && error.databaseRole ? (
                <div style={{ color: "red" }}>{error.databaseRole}</div>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              Data Source Username{<span style={{ color: "red" }}>*</span>}
            </div>
            <TextField
              required
              value={dataSourceUsername}
              onChange={(event) => setDataSourceUsername(event.target.value)}
              id="outlined-required"
              variant="outlined"
              fullWidth={true}
              placeholder="Data Source Username"
            />
            {error && error.dataSourceUsername ? (
              <div style={{ color: "red" }}>{error.dataSourceUsername}</div>
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ paddingTop: "23px" }}>
          <Grid item xs={12} sm={4}>
            <div>Description</div>
            <TextField
              required
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              id="outlined-required"
              variant="outlined"
              fullWidth={true}
              multiline={true}
              rows={3}
              maxRows={3}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>Expiry Date</div>
            <TextField
              required
              id="outlined-read-only-input"
              variant="outlined"
              fullWidth={true}
              value={moment(new Date(expiryDate)).format("MM-DD-YYYY")}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <TodayIcon
                    style={{ cursor: "pointer" }}
                    onClick={(event) => {
                      setTarget(event.currentTarget);
                    }}
                  />
                ),
              }}
            />
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ColoredActionButton
            style={{
              width: "124px",
              height: "46px",
              boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
              marginTop: "5rem",
              marginRight: "10px",
              // float: "right", marginTop: "5rem", marginRight: "26px"
            }}
            onClick={(event) => history.push("/tenant/useraccessRights")}
          >
            Cancel
          </ColoredActionButton>
          <NewPolicyButtonForPolicyCreateEdit
            variant="contained"
            color="primary"
            size="medium"
            className="min-margin"
            style={{ marginTop: "5rem" }}
            onClick={handleSave}
          >
            Save
          </NewPolicyButtonForPolicyCreateEdit>
        </div>
      </div>
    );
  };

  const setTarget = (target) => {
    setAnchorEl2(target);
    setOpenCalender(true);
  };

  const closeCalender = () => {
    setOpenCalender(false);
    setOpenBatchEditCalender(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const renderCalender = () => {
    return (
      <Popper
        id={"simple-popper"}
        open={true}
        anchorEl={anchorEl2}
        style={{ marginTop: "21rem", zIndex: "1" }}
      >
        <Paper elevation={5}>
          <DateTimeRage
            setAnchorElHandler={setAnchorEl2}
            closeCalender={closeCalender}
            onTimeChange={onTimeChange}
            expiryDate={expiryDate}
          ></DateTimeRage>
        </Paper>
      </Popper>
    );
  };

  const renderFormHeading = () => {
    return (
      <div className="create-data-source-container">
        <div className="create-data-source-heading">
          Enter below details to onboard new users
        </div>
        {renderForm()}
      </div>
    );
  };
  return (
    <>
      {loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )}
      {renderHeader()}
      {renderFormHeading()}
      {openCalender && renderCalender()}
      <Snackbar
        //key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        onExited={handleExited}
        //message={messageInfo ? messageInfo.message : undefined}
      >
        <Alert onClose={handleClose} severity={"error"}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
