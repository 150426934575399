import { 
  SET_USER_EMAILS, 
  SET_REPORT_LOADER, 
  REPORT_DATA, 
  CLEAR_REPORT_DATA, 
  OPEN_REPORT_HISTORY, 
  CLOSE_REPORT_HISTORY, 
  CLEAR_REPORT_HISTORY_DATA, 
  REPORT_HISTORY_DATA, 
  ACTIVITY_REPORT_VIEW, 
  REPORT_DETAILS, 
  ACTIVITY_REPORT_DATA, 
  SET_SNACKBAR_MESSAGE,
  SET_TOTAL_REPORTS_COUNT,
  CLEAR_SNACK_BAR_MESSAGE,
  HTML_PAGE,
  VIEW_REPORT_ERROR_MESSAGE,
  CLEAR_HTML_PAGE,
  SET_REPORT_HISTORY_LOADER,
  SET_SHARE_REPORT_MESSAGE,
  CLEAR_SHARE_REPORT_MESSAGE,
  SET_SHARE_REPORT_LOADER
} from "../constants/constants";

const initialState = {
  userEmails: [],
  loader: false,
  reportData: [],
  htmlData: "",
  viewReportHistory: false,
  reportHistoryData: {},
  sharedHistoryData: [],
  activityReportView: false,
  reportDetails: [],
  totalReportsCount: 0,
  activityReportData: [],
  snackBarMessage: {},
  reportHistoryLoader: false,
  shareReportMessage: {},
  shareReportloader: false,
  viewReportErrorMessage: "",
}

export const GenerateReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_EMAILS: {
      return {
        ...state,
        userEmails: action.payload
      }
    }
    case SET_REPORT_LOADER: {
      return {
        ...state,
        loader: action.payload
      }
    }
    case SET_REPORT_HISTORY_LOADER: {
      return {
        ...state,
        reportHistoryLoader: action.payload
      }
    }
    case REPORT_DATA: {
      return {
        ...state,
        reportData: action.payload
      }
    }
    case CLEAR_REPORT_DATA: {
      return {
        ...state,
        reportData: []
      }
    }
    case REPORT_HISTORY_DATA: {
      return {
        ...state,
        sharedHistoryData: action.payload
      }
    }
    case ACTIVITY_REPORT_VIEW: {
      return {
        ...state,
        activityReportView: action.payload
      }
    }
    case REPORT_DETAILS: {
      return {
        ...state,
        reportDetails: action.payload
      }
    }
    case ACTIVITY_REPORT_DATA: {
      return {
        ...state,
        activityReportData: action.payload
      }
    }
    case VIEW_REPORT_ERROR_MESSAGE: {
      return {
        ...state,
        viewReportErrorMessage: action.payload
      }
    }
    case CLEAR_REPORT_HISTORY_DATA: {
      return {
        ...state,
        sharedHistoryData: []
      }
    }
    case HTML_PAGE: {
      return {
        ...state,
        htmlData: action.payload
      }
    }
    case OPEN_REPORT_HISTORY: {
      return {
        ...state,
        viewReportHistory: true,
        reportHistoryData: action.payload,
      };
    }
    case SET_SNACKBAR_MESSAGE: {
      return {
        ...state,
        snackBarMessage: action.payload,
      }
    }
    case SET_TOTAL_REPORTS_COUNT: {
      return {
        ...state,
        totalReportsCount: action.payload,
      }
    }
    case CLEAR_SNACK_BAR_MESSAGE: {
      return {
        ...state,
        snackBarMessage: {}
      }
    }
    case CLOSE_REPORT_HISTORY: {
      return {
        ...state,
        viewReportHistory: false,
        reportHistoryData: {},
      };
    }
    case CLEAR_HTML_PAGE: {
      return {
        ...state,
        htmlData: ""
      }
    }
    case SET_SHARE_REPORT_MESSAGE: {
      return {
        ...state,
        shareReportMessage: action.payload,
      }
    }
    case CLEAR_SHARE_REPORT_MESSAGE: {
      return {
        ...state,
        shareReportMessage: {}
      }
    }
    case SET_SHARE_REPORT_LOADER: {
      return {
        ...state,
        shareReportloader: action.payload
      }
    }
    default:
      return state;
  }
}