import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartWrapper from "../../../common/Dashboard/Chart";
import { viewDataMap } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../../common/utilities";
import { renderNoData } from "../../../common/Dashboard/utils";
import { mitigationOwnerLookup } from "../riskPilotConstant";

// Register necessary Chart.js components
Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  ChartDataLabels
);

const MitigationOwnerWidget = (cubejsApi, name, query) => {

  const renderChart = (resultSet) => {
    const rawData = resultSet.loadResponse.results[0].data;

    // Handle no data scenarios
    if (!rawData || rawData.length === 0) {
      return renderNoData("30%");
    }

    // Transform the raw data into labels and data points for the chart
    const labels = rawData.map(
      (item) =>
        mitigationOwnerLookup[item["Mitigation.riskOwner"]] ||
        item["Mitigation.riskOwner"]
    );
    const dataPoints = rawData.map((item) =>
      parseInt(item["Mitigation.mitigationCount"], 10)
    );

    // Calculate the max value for x-axis
    const maxDataPoint = Math.max(...dataPoints);
    const maxXAxisValue = maxDataPoint + 1;

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Mitigations",
          data: dataPoints,
          backgroundColor: "#800080",
          borderRadius: 5,
          barPercentage: 0.5,
          datalabels: {
            anchor: "end",
            align: "end",
            color: "#000",
            formatter: (value) => `${value.toLocaleString()}`,
          },
        },
      ],
    };

    const options = {
      indexAxis: "y", // Horizontal bar chart
      plugins: {
        legend: {
          display: false, // Hide legend
        },
        datalabels: {
          display: true, // Show data labels
          color: "white",
          anchor: "end",
          align: "end",
        },
      },
      scales: {
        x: {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return value.toLocaleString(); // Format as number with commas
            },
          },
          max: maxXAxisValue, // Set max value for x-axis
        },
        y: {
          ticks: {
            beginAtZero: true,
          },
          grid: {
            display: false, // Hide x-axis grid lines
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    };

    return (
      <div>
        <hr className="threatLevelChartLine" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{height: "300px", width: "95%" }}>
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="threatPieChart" style={{ width: "95%", marginTop: "2%" }}>
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={name}
        query={query}
        render={renderChart}
      />
    </div>
  );
};

export default MitigationOwnerWidget;
