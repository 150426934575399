import React from "react";
import { Chart } from "react-google-charts";
import ChartWrapper from "../../../common/Dashboard/Chart";
import { renderNoData } from "../../../common/Dashboard/utils";


function daysSinceCreation(createdTime) {
  const currentDate = new Date();
  const parsedDate = new Date(createdTime); // Parse the ISO 8601 format string
  const diffTime = Math.abs(currentDate - parsedDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
}

const RiskAgingWidget = (cubejsApi, name, query) => {
  const renderChart = (resultSet) => {
    let intervals = [];
    const rawData = resultSet.rawData();

    if (!rawData || rawData.length === 0) {
      return renderNoData("33%");
    }
    const oldestDays = daysSinceCreation(rawData[0]["RiskAging.oldestRisk"]);

    if (oldestDays <= 30) {
      intervals = [10, 20, 30];
    } else {
      const numIntervals = Math.ceil(oldestDays / 30);
      intervals = Array.from({ length: numIntervals }, (_, index) => (index + 1) * 30);
      intervals[intervals.length - 1] = oldestDays; // Set the last interval to oldestDays
    }

    // Initialize data array and risk count map
    let data = [["Days", "Risk Count"]];
    const riskCountByInterval = Object.fromEntries(intervals.map((interval) => [interval, 0]));

    // Process rawData to count risks by interval
    rawData.forEach((entry) => {
      const days = daysSinceCreation(entry["RiskAging.createdTime"]);
      const interval = intervals.find((interval) => days <= interval);
      if (interval) {
        riskCountByInterval[interval]++;
      }
    });

    // Populate data array with interval and risk count
    intervals.forEach((interval) => {
      data.push([interval, riskCountByInterval[interval]]);
    });

    // Ensure the data points are sorted by "Days"
    data = data.sort((a, b) => a[0] - b[0]);

    const options = {
      legend: { position: "none" },
      areaOpacity: 0.2,
      colors: ["#800080"],
      hAxis: {
        title: "Days",
        format: "# days",
      },
      vAxis: {
        title: "Risk Count",
        minValue: 0,
      },
    };

    return (
      <div>
        <hr className="threatLevelChartLine" />
        <div className="barChartParentRiskContainer">
          <div style={{ height: "250px", width: "95%" }}>
            <Chart
              chartType="AreaChart"
              width="100%"
              height="250px"
              data={data}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="threatPieChart">
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={name}
        query={query}
        render={renderChart}
      />
    </div>
  );
};

export default RiskAgingWidget;
