import { GET_TENANTS, GET_DELETEDTENANTS } from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

export async function getAllTenants() {
  let headers = {};
  headers["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`;
  headers["Content-Type"] = "application/json";
  headers["Tenant"] = "druvstar";
  const options = {
    method: "GET",
    url: `${GET_TENANTS}`,
    headers: headers,
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}
export async function getAllDeletedTenants() {
  let headers = {};
  headers["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`;
  headers["Content-Type"] = "application/json";
  headers["Tenant"] = "druvstar";
  const options = {
    method: "GET",
    url: `${GET_DELETEDTENANTS}`,
    headers: headers,
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}
