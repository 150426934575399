import React, { useEffect, useState } from "react";
import { EnhancedTableToolbar } from "./EnhancedAlertsTableHeader";
import EnhancedGridTable from "../Table/Table";
import { relatedTableConstants } from "./alertsUtilities";
import { getRelatedAlerts } from "../../../redux/actions/getRelatedAlertsAction";
import page_loader from "../../../assets/vizr_images/page_loader.gif";

export default function RelatedTables(props) {
  const { alertDetailsId } = props;
  const [alertDetails, setAlertDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (
      alertDetailsId !== {} &&
      alertDetailsId !== null &&
      alertDetailsId !== undefined
    ) {
      getRelatedAlertsById();
      setLoader(true);
    }
    return () => {
      setAlertDetails([]);
    };
  }, [alertDetailsId]);

  useEffect(() => {
    getRelatedAlertsById();
    setLoader(true);
  }, [currentPage, recordsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const getRelatedAlertsById = () => {
    async function fetchRelatedAlert(id) {
      const response = await getRelatedAlerts(id, recordsPerPage, currentPage);
      if (response) {
        setAlertDetails(response.content);
        setTotalCount(response.totalElements);
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
    fetchRelatedAlert(alertDetailsId);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {loader && (
        <img
          src={page_loader}
          alt="loading"
          style={{
            position: "absolute",
            marginLeft: "172px",
            top: "120px",
            zIndex: 5,
          }}
        ></img>
      )}
      <EnhancedTableToolbar
        totalElements={""}
        loc={"resolved-alerts"}
        alertsTitle={"Related Alerts"}
        selectedRows={[]}
        rows={alertDetails}
        hideAction={true}
      />
      <EnhancedGridTable
        cols={relatedTableConstants()}
        data={alertDetails}
        totalCount={totalCount}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
      />
    </div>
  );
}
