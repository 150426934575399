import React from "react";
import { Chart } from "react-google-charts";
import _ from "lodash";
import { QueryRenderer } from "@cubejs-client/react";
import ChartWrapper from "../Chart";
import {
  renderNoData,
  renderSingleValue,
} from "../utils";
import { pathWithPermission, percentageCalculation } from "../../utilities";
import { viewDataMap } from "../../../../redux/constants/constants";

const SensitiveGroups = (cubejsApi, title, query) => {
  const renderLegend = (legendData) => {
    let tableBorder = {
      boxShadow: "none",
    };
    return (
      <>
        <div className="user-roles-legendData" style={tableBorder}>
          {legendData.map((data) => {
            let box = {
              width: "10px",
              height: "10px",
              backgroundColor: data.color,
            };
            return (
              <div className="user-roles-legend">
                <div style={box}></div>
                <div title={data.text} className="content user-role-group-name">
                  {data.text ? data.text : "-"}
                </div>
                <div><span>{`${data.count}%`}</span></div>
              </div>
            );
          })}
        </div>
      </>
    )
  };
  const renderChart = (projectedData) => {
    let options = {
      // Material design options
      pieHole: 0.5,
      legend: "none",
      colors: []
    };
    options.chartArea = { left: "0%", width: "100%" };
    options.pieSliceText = "none";
    let pieData = [["X", "Y"]];
    projectedData.forEach((e) => {
      let pDataItem = {
        color: e.color,
        text: e.text,
        count: e.count
      }
      pieData.push([pDataItem.text, pDataItem.count]);
      options.colors.push(pDataItem.color);
    })
    return (
      <>
        <React.Fragment>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <div onClick={(e) => e.stopPropagation()}>
              <Chart
                width={"150px"}
                height={"100%"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={pieData}
                options={options}
              />
            </div>
            {renderLegend(projectedData)}
          </div>
        </React.Fragment>
      </>
    )
  };
  const pieChart = (query, cubejsApi, legends) => {
    return (
      <>
        <h4>Top 5 groups with the sensitive users</h4>
        <ChartWrapper
          cubejsApi={cubejsApi}
          query={query}
          render={(resultSet) => {
            let colors = ["#081981","#1A2A8C","#1831C2","#2B46E8","#4B62EC"]
            let countData = resultSet.loadResponse.results[0].data[0]["SensitiveGroups.sensitiveFileCount"]
            const floorValues = percentageCalculation(legends, countData).sort((a, b) => b.count - a.count);
            floorValues.map((value, index) => {
              value.color = colors[index];
            });
            return (
              <>
                {renderChart(floorValues)}
              </>
            );
          }}
          userRole={true}
          redirectTo={returnRedirectTo(title)}
        />
      </>
    )
  };
  const renderTotalCount = (query, cubejsApi) => {
    return (
      <div className="widget-header2">
        <span className="span-total-count">
          <QueryRenderer
            query={query}
            cubejsApi={cubejsApi}
            render={({ resultSet }) => {
              if (!resultSet) {
                return <div className="loader" />;
              }
              return renderSingleValue(resultSet, query.measures[0]);
            }}
          />
        </span>
      </div>
    );
  };
const returnRedirectTo = (name) => {
  let data = {
    "Sensitive Data Groups":{
      pathname: "/tenant/dataMap",
      permission: [viewDataMap],
    },
  }
  return pathWithPermission(data[name])
};
  let chartQuery = query;
  let countQuery = query[0];
  let isTotalCount = false;
  if (query && query.length > 1) {
    isTotalCount = true;
    chartQuery = query[1];
  }
  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={title}
      query={chartQuery}
      render={(resultSet) => {
        let data = resultSet.loadResponse.results[0].data;
        if (!data || data.length === 0) {
          return renderNoData("30%");
        }
        let legends = [];
        data.forEach((d, index) => {
          let pDataItem = {
            text: d[chartQuery.dimensions[0]] || "-",
            count: parseInt(d[chartQuery.measures[0]]),
          };
          legends.push(pDataItem);
        });

        return (
          <>
            {isTotalCount && renderTotalCount(query[0], cubejsApi)}
            {pieChart(countQuery, cubejsApi, legends)}
            
          </>
        );
      }}
      redirectTo={returnRedirectTo(title)}
    />
  );
}
export default SensitiveGroups;

