import { TRACK_TRAVERSAL,CLEAR_TRACK_TRAVERSAL } from "../constants/constants";

const initialState = {
  track: [],
};

export const trackTraversalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRACK_TRAVERSAL:
      return {
        ...state,
        track: [...state.track, action.payload],
      };
    case CLEAR_TRACK_TRAVERSAL:
      return {
        ...state,
        track: [],
      };
    default:
      return state;
  }
}
