import { CREATE_ALERT_POLICY_SUCCEEDED,CLOSE_SNACKBAR,CREATE_ALERT_POLICY_FAILED, CLEAR_ERROR } from '../constants/constants';

const initialState = {
    created : false,
    successMessage: '',
    unsuccessful : ''
  };

  export const createAlertPolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ALERT_POLICY_SUCCEEDED: {
            console.log("CREATE_ALERT_POLICY_FAILED");
            return {
              ...state,
              successMessage: 'Policy Created',
              unsuccessful: '',
              created: true,
            };
          }
          case CREATE_ALERT_POLICY_FAILED: {
            console.log("CREATE_ALERT_POLICY_FAILED");
            return {
              ...state,
              created: false,
              unsuccessful: action.payload,
            };
          }
          case CLOSE_SNACKBAR: {
            console.log("CLOSE_SNACKBAR");
            return {
              ...state,
              successMessage: '',
              created: false,
            };
          }
          case CLEAR_ERROR: {
            console.log("CLEAR_ERROR");
            return {
              ...state,
              unsuccessful: ''
            };
          }
          default:
            return state;
    }
  };
