import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSelector, useDispatch } from "react-redux";
import { Input, Grid } from "@material-ui/core";
import { DateRange } from "react-date-range";
import "./customCalender.scss";
import {
  addDays,
  startOfDay,
  add,
  startOfWeek,
  endOfWeek,
  addMonths,
  endOfMonth,
  startOfMonth,
  subMinutes,
  subHours,
  endOfDay,
} from "date-fns";
import moment from "moment";
import MaskedInput from "react-text-mask";
import { dateFormat } from "../../../redux/constants/constants";

const defineds = {
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),
  endOfNext3Month: endOfMonth(addMonths(new Date(), 3)),
  endOfNext6Month: endOfMonth(addMonths(new Date(), 6)),
  after1week: add(new Date(), { weeks: 1 }),
  after1Month: add(new Date(), { months: 1 }),
  after3Months: add(new Date(), { months: 3 }),
  after6Months: add(new Date(), { months: 6 }),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  past0Minutes: subMinutes(new Date(), 0),
  past15Minutes: subMinutes(new Date(), 15),
  past5Minutes: subMinutes(new Date(), 5),
  past30Minutes: subMinutes(new Date(), 30),
  past0Hour: subHours(new Date(), 0),
  past1Hour: subHours(new Date(), 1),
  past12Hours: subHours(new Date(), 12),
  past24Hours: subHours(new Date(), 24),
  startOfToday: startOfDay(new Date()),
  past7Days: startOfDay(addDays(new Date(), -7)),
};

const dateInput = (props) => {
  const { inputRef, ...other } = props;
  const checkMonth = (value) => {
    if (value[0] === "1") {
      return /[0-2]/;
    } else {
      return /[0-9]/;
    }
  };
  const checkDate = (value) => {
    if (value[3] === "3") {
      return /[0-1]/;
    } else {
      return /[0-9]/;
    }
  };
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-1]/,
        checkMonth(other.value),
        "/",
        /[0-3]/,
        checkDate(other.value),
        "/",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholder={dateFormat}
    />
  );
};

export const DateRangePicker = (props) => {
  const { fromTimeRange, toTimeRange, handleOnDateChange } = props;
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [fromDateValue, setFromDateValue] = useState(new Date());
  const [toDateValue, setToDateValue] = useState(new Date());
  const [fromTimeValue, setFromTimeValue] = useState(
    moment().subtract(1, "hours").format("HH:mm:ss A")
  );
  const [toTimeValue, setToTimeValue] = useState(moment().format("HH:mm:ss A"));

  const dispatch = useDispatch();
  console.log(fromTimeRange, toTimeRange);
  useEffect(() => {
    if (fromTimeRange && toTimeRange) {
      setState([
        {
          startDate: new Date(moment(fromTimeRange).format("MM-DD-YYYY")),
          endDate: new Date(moment(toTimeRange).format("MM-DD-YYYY")),
          key: "selection",
        },
      ]);
      setFromDateValue(new Date(fromTimeRange));
      setToDateValue(new Date(toTimeRange));
      setFromTimeValue(moment(fromTimeRange).format("HH:mm:ss"));
      setToTimeValue(moment(toTimeRange).format("HH:mm:ss"));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setFromDateValue(selection.startDate);
    setToDateValue(endOfDay(new Date(selection.endDate)));
    handleOnDateChange(
      selection.startDate,
      endOfDay(new Date(selection.endDate))
    );
    setState([selection]);
    let startDate = new Date(selection.startDate);
    let endDate = endOfDay(new Date(selection.endDate));
    const fromTimeVal = `${("0" + startDate.getHours()).slice(-2)}:${(
      "0" + startDate.getMinutes()
    ).slice(-2)}:${("0" + startDate.getSeconds()).slice(-2)}`;
    const toTimeVal = `${("0" + endDate.getHours()).slice(-2)}:${(
      "0" + endDate.getMinutes()
    ).slice(-2)}:${("0" + endDate.getSeconds()).slice(-2)}`;
    setFromTimeValue(fromTimeVal);
    setToTimeValue(toTimeVal);
  };
  const getFromAndToInputFields = () => {
    return (
      <Grid container spacing={2} className="filter-time-container">
        <Grid item xs={6}>
          <div>
            <span className="range-text">From</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Input
              value={moment(fromDateValue).format(dateFormat)}
              inputComponent={dateInput}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className="range-text">To</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Input
              value={moment(toDateValue).format(dateFormat)}
              inputComponent={dateInput}
            ></Input>
          </div>
        </Grid>
      </Grid>
    );
  };

  //   const fromTimeChange = (name) => (event) => {
  //     if (event.target.value) {
  //       setFromTimeValue(event.target.value);
  //       const tarValSpl = event.target.value.split(":");
  //       if (
  //         tarValSpl.length === 3 &&
  //         !isNaN(tarValSpl[0]) &&
  //         !isNaN(tarValSpl[1]) &&
  //         !isNaN(tarValSpl[2])
  //       ) {
  //         fromDateValue.setHours(tarValSpl[0]);
  //         fromDateValue.setMinutes(tarValSpl[1]);
  //         fromDateValue.setSeconds(tarValSpl[2]);
  //         setFromDateValue(fromDateValue);
  //         let fromTime = fromDateValue.getTime();
  //         dispatch(DATABASE_LOGS_FROM_TIME(fromTime));
  //         // handleDateRangeDiff(toDateValue, fromDateValue);
  //       }
  //     }
  //   }; // QuickRanges = (time) =>{

  //   // }
  //   const toTimeChange = (name) => (event) => {
  //     if (event.target.value) {
  //       setToTimeValue(event.target.value);
  //       const tarValSpl = event.target.value.split(":");
  //       if (
  //         tarValSpl.length === 3 &&
  //         !isNaN(tarValSpl[0]) &&
  //         !isNaN(tarValSpl[1]) &&
  //         !isNaN(tarValSpl[2])
  //       ) {
  //         toDateValue.setHours(tarValSpl[0]);
  //         toDateValue.setMinutes(tarValSpl[1]);
  //         toDateValue.setSeconds(tarValSpl[2]);
  //         setToDateValue(toDateValue);
  //         let toTime = toDateValue.getTime();
  //         dispatch(DATABASE_LOGS_TO_TIME(toTime));
  //       }
  //     }
  //   };
  //   const returnDateRanges = () => {
  //     return (
  //       <>
  //         <div
  //           className="week"
  //           onClick={() =>
  //             handleOnChange({
  //               selection: {
  //                 startDate: defineds.past5Minutes,
  //                 key: "selection",
  //                 endDate: defineds.past0Minutes,
  //               },
  //             })
  //           }
  //         >
  //           Past 5 Mins
  //         </div>
  //         <div
  //           className="week"
  //           onClick={() =>
  //             handleOnChange({
  //               selection: {
  //                 endDate: defineds.past0Minutes,
  //                 key: "selection",
  //                 startDate: defineds.past30Minutes,
  //               },
  //             })
  //           }
  //         >
  //           Past 30 Mins
  //         </div>
  //         <div
  //           className="week"
  //           onClick={() =>
  //             handleOnChange({
  //               selection: {
  //                 endDate: defineds.past0Hour,
  //                 key: "selection",
  //                 startDate: defineds.past1Hour,
  //               },
  //             })
  //           }
  //         >
  //           Past 1 Hour
  //         </div>
  //         <div
  //           className="month"
  //           onClick={() =>
  //             handleOnChange({
  //               selection: {
  //                 endDate: defineds.past0Hour,
  //                 key: "selection",
  //                 startDate: defineds.past12Hours,
  //               },
  //             })
  //           }
  //         >
  //           Past 12 Hours
  //         </div>

  //         <div
  //           className="month"
  //           onClick={() =>
  //             handleOnChange({
  //               selection: {
  //                 endDate: defineds.past0Hour,
  //                 key: "selection",
  //                 startDate: defineds.past24Hours,
  //               },
  //             })
  //           }
  //         >
  //           Past 24 Hours
  //         </div>
  //         <div
  //           className="month"
  //           onClick={() =>
  //             handleOnChange({
  //               selection: {
  //                 endDate: defineds.startOfToday,
  //                 key: "selection",
  //                 startDate: defineds.past7Days,
  //               },
  //             })
  //           }
  //         >
  //           Past 7 Days
  //         </div>
  //       </>
  //     );
  //   };

  //   const getFromAndToInputFields = () => {
  //     return (
  //       <Grid container spacing={2}>
  //         <Grid item xs={12}>
  //           <div className="time-range-container">
  //             <Grid container spacing={2}>
  //               <Grid item xs={6}>
  //                 <div className="time-range-picker">
  //                   <Grid container spacing={1}>
  //                     <Grid item xs={4}>
  //                       <span className="range-text">From</span>
  //                     </Grid>
  //                     <Grid item xs={8}>
  //                       <div className="timepicker-div">
  //                         <Input
  //                           value={moment(fromDateValue).format(dateFormat)}
  //                           inputComponent={dateInput}
  //                         />
  //                       </div>
  //                     </Grid>
  //                   </Grid>
  //                 </div>
  //               </Grid>
  //               <Grid item xs={6}>
  //                 <div className="time-range-picker">
  //                   <Grid container spacing={1}>
  //                     <Grid item xs={4}>
  //                       <span className="range-text">To</span>
  //                     </Grid>
  //                     <Grid item xs={8}>
  //                       <div className="timepicker-div">
  //                         <Input
  //                           value={moment(toDateValue).format(dateFormat)}
  //                           inputComponent={dateInput}
  //                         ></Input>
  //                       </div>
  //                     </Grid>
  //                   </Grid>
  //                 </div>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         </Grid>
  //       </Grid>
  //     );
  //   };

  return (
    <div className="alerts-logs-access-picker">
      {/* <div className="header-text">Select a Date Range</div> */}
      <DateRange
        style={{ width: "275px" }}
        moveRangeOnFirstSelection={false}
        onChange={handleOnChange}
        ranges={state}
        showDateDisplay={false}
      />
      <div style={{ width: "270px" }}>{getFromAndToInputFields()}</div>

      {
        <>
          {/* <div className="quick-range-heading">Time Range</div> */}
          {/* <div style={{ width: "275px" }}>{getTimeFields()}</div> */}
        </>
      }

      {/* <div className="quick-range-heading">Quick Ranges</div> */}
      {/* {returnDateRanges()} */}
    </div>
  );
};
