import React from "react";
import "./RiskGovernance.scss";
import { RenderFormFields, renderHeading } from "./riskPilotHelperComp";
import {
  handleThreatChange,
} from "./riskPilotUtilities";
import { useDispatch } from "react-redux";
import { RISK_LEVEL_LOOKUP, RISK_PILOT_IMPACT_LEVELS, RISK_PILOT_LEVELS } from "./riskPilotConstant";

const RiskLikelihoodAndImpact = ({
  threat,
  threatId,
}) => {
  const dispatch = useDispatch();

  const renderLikelyHood = () => {
    return (
      <div>
        {renderHeading("LikeliHood")}
        <div className="riskSelect impact">
          <RenderFormFields
            options={RISK_PILOT_LEVELS}
            value={threat.likelihood}
            setValue={(value) => handleThreatChange(threatId, threat, "likelihood", value, dispatch)}
            severityValue
          />
        </div>
      </div>
    )
  }
  const renderImpact = () => {
    return (
      <div>
        {renderHeading("Impact")}
        <div className="riskSelect impact">
          <RenderFormFields
            options={RISK_PILOT_IMPACT_LEVELS}
            value={threat.impact}
            setValue={(value) => handleThreatChange(threatId, threat, "impact", value, dispatch)}
            severityValue
          />
        </div>
      </div>
    )
  }

  const renderSeverity = () => {
    return (
      <div className={`riskSeverityContainer ${threat.riskLevel}`}>
        <div className={`riskSeverityValue ${threat.riskLevel}`}>
          {threat.riskLevel ? RISK_LEVEL_LOOKUP[threat.riskLevel] : ""}
        </div>
      </div>
    );
  };

  return (
    <div className="riskAndThreatContainer">
      {renderLikelyHood()}
      {renderImpact()}
      {renderSeverity()}
    </div>
  );
};

export default RiskLikelihoodAndImpact;
