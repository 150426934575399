import React from "react";
import { RadialBarChart, RadialBar, ResponsiveContainer } from "recharts";

const MitigationRadialChart = ({ data }) => {
  const colors = ["#FF2251", "#FFBF00", "#F68D2B", "#344BFD"];
  // Calculate total count of mitigation counts
  const totalCount = data.reduce(
    (acc, item) => acc + parseInt(item["Mitigation.mitigationCount"], 10),
    0
  );

  // Prepare customData based on the passed 'data' prop and using totalCount as max value
  let customData = data.map((item, index) => {
    const count = parseInt(item["Mitigation.mitigationCount"], 10);
    const percentage = (count / totalCount) * 100;
    return {
      name: item["Mitigation.status"], // Assuming 'Mitigation.status' is the status field
      value: parseFloat(percentage.toFixed(2)), // Use percentage directly since totalCount acts as max value
      fill: `url(#gradient${index})`,
    };
  });

  // Add an invisible segment for the total count as the first object
  const invisibleSegment = {
    name: "Total",
    value: 100, // This should be 100% to fill the entire chart
    fill: `#fff`, // Reference gradient based on index
  };
  customData = [invisibleSegment, ...customData]; // Prepend invisibleSegment to customData array

  // Calculate the start and end angles for each segment based on their percentage
  const gapAngle = 50; // Adjust this value to increase or decrease the gap between bars
  let cumulativeAngle = 0;
  customData = customData.map((item, index) => {
    const startAngle = cumulativeAngle * 3.6; // 1% of 360 degrees
    const endAngle = (cumulativeAngle + item.value) * 3.6;
    cumulativeAngle += item.value;
    return {
      ...item,
      startAngle: startAngle + index * gapAngle, // Add gap for each segment
      endAngle: endAngle + index * gapAngle,
    };
  });

  return (
    <ResponsiveContainer width={200} height={180}>
      <RadialBarChart
        cx="50%"
        cy="50%"
        innerRadius="30%"
        outerRadius="110%" // Adjust outerRadius to fit the chart properly
        barSize={8} // Increase barSize for thicker bars
        data={customData}
        startAngle={90}
        endAngle={-270}
      >
        <defs>
          {colors.map((color, index) => (
            <linearGradient
              key={`gradient-${index}`}
              id={`gradient${index}`}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop
                offset="0%"
                style={{ stopColor: color, stopOpacity: 0.3 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: color, stopOpacity: 1 }}
              />
            </linearGradient>
          ))}
        </defs>
        <RadialBar
          minAngle={15}
          background
          clockWise
          dataKey="value"
          cornerRadius={5} // Set cornerRadius for rounded corners
          data={customData} // Pass customData here
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
        >
          Status
        </text>
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default MitigationRadialChart;
