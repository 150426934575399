const containsSpecialCharacters = (str) => {
  const pattern = /[^a-zA-Z0-9:\\]/;
  return pattern.test(str);
};

export const sqlFormData = [
  {
    name: "Name",
    label: "Name",
    type: "text",
    placeholder: "Name",
    required: true,
    errorMessage: "Name cannot be blank or empty",
  },
  {
    name: "applicationName",
    label: "Application Name",
    type: "autocomplete",
    placeholder: "Application Name",
    required: true,
    errorMessage: "Application Name cannot be blank or empty",
  },
  {
    name: "sourceType",
    label: "Source Type",
    type: "select",
    required: true,
    errorMessage: "Source Type must be selected",
  },
  {
    label: "Database Name",
    placeholder: "Database Name",
    name: "databaseName",
    type: "text",
    required: true,
    errorMessage: "Database Name cannot be blank or empty",
  },
  {
    label: "Instance Name",
    placeholder: "Instance Name",
    name: "instance",
    type: "text",
    required: true,
    errorMessage: "Instance Name cannot be blank or empty",
  },
  {
    label: "Host/IP Address",
    placeholder: "IP Address",
    name: "address",
    type: "text",
    required: true,
    errorMessage: "Host/IP Address cannot be blank or empty",
  },
  {
    label: "Port",
    placeholder: "Port",
    name: "port",
    type: "text",
    required: true,
    errorMessage: "Port cannot be blank or empty",
  },
  {
    label: "Username",
    placeholder: "Username",
    name: "username",
    type: "text",
    required: true,
    errorMessage: "Username cannot be blank or empty",
  },
  {
    label: "Password",
    placeholder: "Password",
    name: "password",
    type: "password",
    required: true,
    errorMessage: "Password cannot be blank or empty",
  },
  {
    label: "Connection Properties",
    placeholder: "Enter Connection Properties",
    name: "connectionUrl",
    type: "text",
  },
  {
    label: "Data Location",
    placeholder: "Data Location",
    name: "networkPath",
    type: "text",
    required: true,
    errorMessage: "Data Location cannot be blank or empty",
    customErrorMessage: "Location cannot contain special characters except ':' and '\\'",
    customFn: containsSpecialCharacters
  },
  {
    label: "Archival Location",
    placeholder: "Archival Location",
    name: "archiveLocation",
    type: "text",
    required: true,
    errorMessage: "Archival Location cannot be blank or empty",
    customErrorMessage: "Location cannot contain special characters except ':' and '\\'",
    customFn: containsSpecialCharacters
  },
];
