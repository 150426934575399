import React, { useState, useContext } from "react";
// import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../views/Input";
import Button from "@material-ui/core/Button";
import { SETPASSWORD__SUBMIT } from "../../../redux/actions/setPasswordAction";

import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { NETWORK_ERROR_MESSAGE, PASSWORD_CRITERIA, RESET_PASSWORD } from "../../../redux/constants/constants";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";

import { UserContext } from "../../../App";
import { errorMessageComp } from "../../common/utilities";

const useStyles = makeStyles({
  resetPassword_signinSignupContainer: {
    height: "22px",
    width: "119px",
    position: "absolute",
    top: "2rem",
    left: "22rem",
  },
  resetPassword_signinSignup: {
    textDecoration: "none !important",
    color: "#2947fd !important",
  },
  resetPassword_input: {
    height: "349px",
    width: "320px",
  },
  verifyPasswordCondition: {
    height: "140px",
    width: "310px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 6px 8px 0 rgba(0,0,0,0.2)",
    position: "absolute",
    zIndex: "10",
  },
  passwordCheckTrue: {
    height: "14px",
    width: "14px",
    transform: "rotate(-360deg)",
    backgroundColor: "#2CE68F",
    borderRadius: "20px",
  },
  passwordCheckFalse: {
    height: "14px",
    width: "14px",
    transform: "rotate(-360deg)",
    backgroundColor: "#FF2251",
    borderRadius: "20px",
  },
  passWordCheckIcon: {
    position: "absolute",
    color: "white",
    left: "2px",
    top: "-2px",
  },
  setPasswordButton: {
    textTransform: "none !important",
    width: "95% !important",
    backgroundColor: "#2947FD !important",
    fontWeight: "bold !important",
    marginTop: "1rem !important",
  },
  setPasswordHeading: {},
});

const ResetPasswordRight = ({ email, code, tenant, featureName }) => {
  const callback = useContext(UserContext); // This calls the page with user MFA after Successfully setting / resetting password. 
  let headingName;
  if (featureName === "setPassword") {
    headingName = "Set your password to activate the account";
  } else if (featureName === "resetPassword") {
    headingName = "Please reset your password";
  }
  const classname = "setPassword";
  const classes = useStyles();
  const dispatch = useDispatch();
  const setPasswordSuccess = useSelector(
    (state) => state.setPasswordReducer.setPasswordSuccess
  );
  const setPasswordSubmit = useSelector(
    (state) => state.setPasswordReducer.setPasswordSubmitted
  );
  const setPasswordErrorMessage = useSelector(
    (state) => state.setPasswordReducer.setPasswordErrorMessage
  );

  const [password, SetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMismatchError, setpasswordMismatchError] = useState("");
  const [resetPasswordStatus, setresetPasswordStatus] = useState(false);
  const [resetPasswordError, setresetPasswordError] = useState("");
  const [loader, setLoader] = useState(false);
  const passWordMatches = (pass, conPass) => {
    if (pass === conPass) {
      return true;
    } else {
      return false;
    }
  };
  const setErrorMessage = (res) => {
    if (res && res.response && res.response.data) {
      const errorMessageData = res.response.data;
  
      if (errorMessageData.fieldErrors && errorMessageData.fieldErrors.length > 0) {
        return errorMessageData.fieldErrors[0].errorMessage;
      } else {
        return errorMessageData.errorMessage;
      }
    }
  };

  const setPasswordHandler = (callback) => {
    if (password && passWordMatches(password, confirmPassword)) {
      // console.log("setPasswordHandler");
      if (featureName === "setPassword") {
        dispatch(
          SETPASSWORD__SUBMIT({
            email: email,
            code: code,
            tenant,
            password: password,
          })
        );
      } else if (featureName === "resetPassword") {
        setLoader(true);
        const apiCall = async () => {
          const headers = getAxiosHeaders(false);
          headers.Tenant = tenant;
          const res = await getService({
            method: "POST",
            url: RESET_PASSWORD(tenant),
            data: {
              email: email,
              token: code,
              newPassword: password,
            },
            headers: headers,
          });
          if (res.status === 200) {
            dispatch({
              type: "SET_QR_CODE",
              payload: res.data.qrCode.imageUri,
            });
            setresetPasswordStatus(true);
            callback(true);
            setLoader(false);
          } else if (res.response && res.response.status === 400) {
            const errMessage = setErrorMessage(res);
            setresetPasswordError(errorMessageComp(errMessage, "resetPasswordScreen"));
            setLoader(false);
            callback(false);
          } else if (res.code === "ERR_NETWORK") {
            setresetPasswordError(NETWORK_ERROR_MESSAGE);
            setLoader(false);
            callback(false);
          } else {
            setresetPasswordError(errorMessageComp(PASSWORD_CRITERIA, "resetPasswordScreen"));
            setLoader(false);
            callback(false);
          }
        };
        apiCall();
      }
    } else if (!password || !confirmPassword) {
      setpasswordMismatchError("Please enter the password");
    } else {
      setpasswordMismatchError("Password not matching");
    }
  };
  const confirmPasswordHandler = (value) => {
    setpasswordMismatchError("");
    setresetPasswordError("");
    setConfirmPassword(value);
  };
  return (
    <>
    {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source resetPasswordLoader"
        ></img>
      )}
      <Grid
      item
      xs={7}
      sm={7}
      md={7}
      lg={7}
      style={{
        backgroundColor: "white",
        width: "100%",
        minWidth: "25%",
        paddingLeft: "5%",
        fontFamily: "Nunito-Regular !important",
      }}
    >
      <div>
        {!featureName === "setPassword" ? (
          <div className={classes.resetPassword_signinSignupContainer}>
            <a href="/" className={classes.resetPassword_signinSignup}>
              Sign in
            </a>
            <span style={{ color: "#2947fd", margin: "2px" }}>&#73;</span>
            <a href="/signup" className={classes.resetPassword_signinSignup}>
              Sign Up
            </a>
          </div>
        ) : null}
        <div
          className={classes.resetPassword_input}
          style={{ marginLeft: "2rem" }}
        >
          <h2 className="setPasswordHeading">{headingName}</h2>
          <h3 style={{ marginBottom: "1.5rem" }}>{email ? email : ""}</h3>
          <Input
            type="password"
            labelName="New Password"
            classname={classname}
            containerName="setUserPassword"
            labelClassName="setPasswordLabeLHeading"
            value={password}
            eventHandler={(value) => {
              setpasswordMismatchError("");
              setresetPasswordError("");
              SetPassword(value);
              dispatch({ type: "RESET_SETPASSWORD_ERROR_MESSAGE" });
            }}
          ></Input>
          {/*<div className={classes.verifyPasswordCondition}>
            <div> Password must meet the following:</div>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.passwordCheckTrue}>
                  <div className={classes.passWordCheckIcon}>&#10003;</div>
                </div>
                <div>Atleast Oneletter</div>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.passwordCheckTrue}>
                  <div className={classes.passWordCheckIcon}>&#10003;</div>
                </div>
                <div>Atleast Oneletter</div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.passwordCheckTrue}>
                  <div className={classes.passWordCheckIcon}>&#10003;</div>
                </div>
                <div>Atleast Oneletter</div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.passwordCheckTrue}>
                  <div className={classes.passWordCheckIcon}>&#10003;</div>
                </div>
                <div>Atleast Oneletter</div>
              </div>
            </div>
          </div> */}
          <Input
            type="password"
            labelName="Re-enter Password"
            classname={classname}
            containerName="setUserPassword"
            labelClassName="setPasswordLabeLHeading"
            value={confirmPassword}
            eventHandler={(value) => {
              confirmPasswordHandler(value);
              dispatch({ type: "RESET_SETPASSWORD_ERROR_MESSAGE" });
            }}
          ></Input>
          {passwordMismatchError ? (
            <div
              style={{
                color: "red",
                marginTop: "-1rem",
              }}
            >
              {passwordMismatchError}
            </div>
          ) : null}

          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.setPasswordButton}
            onClick={() => {
              setPasswordHandler(callback);
            }}
          >
            {featureName === "setPassword" ? "Set Password" : "Reset Password"}
          </Button>
        </div>
        {setPasswordSubmit ? (
          setPasswordSuccess ? (
            <h4>Set Password Successfull</h4>
          ) : (
            <h4>
              {errorMessageComp(setPasswordErrorMessage, "resetPasswordScreen")}
            </h4>
          )
        ) : null}
        {resetPasswordStatus ? (
          <h4>Reset Password Successfull</h4>
        ) : resetPasswordError ? (
          <h4 style={{ color: "red", marginLeft: "20px", maxWidth: "30rem" }}>
            {resetPasswordError}
          </h4>
        ) : (
          ""
        )}
      </div>
    </Grid>
    </>
  );
};

export default ResetPasswordRight;
