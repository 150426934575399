import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import _ from "lodash";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import { QueryRenderer } from "@cubejs-client/react";
import { Link } from "react-router-dom";

import ChartWrapper from "./Chart";
import ComboChart from "./OrdersCombo";
import { usersWithAlertsBarChart } from "./custom-charts/usersWithAlerts";
import { sensitiveDataUsersLineChart } from "./custom-charts/sensitiveDataUsers";
import {
  alertsOverTimeBarChart,
  healthAgentAlertsOverTime,
} from "./custom-charts/alertsOverTime";
import {
  complianceAlertsByLevelChart,
  usersWithAlertsByLevelChart,
} from "./custom-charts/alertsByLevel";
import { totalUsersCombo } from "./custom-charts/totalUsersCombo";
import {
  numberFormatter,
  dateFormatter,
  renderNoData,
  renderSingleValue,
  dateFormatterMDR,
} from "./utils";
// import DashboardEdit from './Edit';
import Toggler from "./CommonComponents/Toggler";
import SquareToggler from "./CommonComponents/SquareToggler";
import MDRPieCharts from "./custom-charts/MDRPieCharts";
import IncidentByState from "./custom-charts/IncidentByState";
import MDRSortedBarCharts from "./custom-charts/MDRSortedBarCharts";
import MDRMapChart from "./custom-charts/MDRMapChart";
import { fileSummary } from "./custom-charts/totalAssets";
import { userRoles } from "./custom-charts/userRoles.js";
import { DropBox } from "./custom-charts/DropBox";
import SensitiveGroups from "./custom-charts/SensitiveGroups";
import { dataAssetsDonut } from "./custom-charts/dataAssetsDonut.js";
import { heatMapLegends } from "./custom-charts/heatMapLegends";
import { totalGroups } from "./custom-charts/totalGroups";
import { threatRiskLevelWidget } from "../../views/RiskPilot/Dashboards/threatRiskLevelWidget.js";
import TotalRiskWidget from "../../views/RiskPilot/Dashboards/TotalRiskWidget.js";
import RiskCategoryWidget from "../../views/RiskPilot/Dashboards/RiskCategoryWidget.js";
import MitigationOwnerWidget from "../../views/RiskPilot/Dashboards/MitigationOwnerWidget.js";
import RiskAgingWidget from "../../views/RiskPilot/Dashboards/RiskAgingWidget.js";
import MitigationPriorityWidget from "../../views/RiskPilot/Dashboards/MitigationPriorityWidget.js";
import MitigationStatusWidget from "../../views/RiskPilot/Dashboards/MitigationStatusWidget.js";
import { viewAlert, viewDatabaseLogs, viewDataFlow, viewDataFlowGroup, VIEW_ENTERPRISE_ACTORS } from "../../../redux/constants/constants";
import { pathWithPermission } from "../utilities";

const openEditModal = () => {
  const handleOpen = () => {};

  const handleClose = () => {};

  const body = (
    <div>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p>
    </div>
  );

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

const generateColors = (count) => {
  let colors = [
    "#081981",
    "#2CE68F",
    "#FF5478",
    "#087B81",
    "#FFDF60",
    "#498BD7",
    "#FF87A1",
    "#48A04B",
    "#91DFFF",
    "#66C569",
    "#FF2251",
    "#3B5C81",
    "#6D1528",
    "#FFC344",
  ];
  for (let counter = 0; counter < count; counter++) {
    colors.push(generateColor());
  }
  return colors;
};

const generateColor = () => {
  return "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
};

let randomColors = generateColors(20);

const returnRedirectTo = (name) => {
  let data = {
    "Alerts by Severity": {
      pathname: "/tenant/alerts",
      permission: [viewAlert]
    },
    "Alerts by Policy": {
      pathname: "/tenant/alerts",
      permission: [viewAlert]
    },
    "Users by Data Source": {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },
    "Users with Alerts by Policy": {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },
    "Sensitive Data Users by Data Source" : {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },"Sensitive Data Users by Data Source" : {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },
    "Users with Alerts by Severity":{
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },
    "Traces by data flows":{
      pathname: "/tenant/dataFlows",
      permission: [viewDataFlow]
    },
    "Traces by flow groups":{
      pathname: "/tenant/dataFlowGroups",
      permission: [viewDataFlowGroup]
    },
    "Traces over Time":{
      pathname: "/tenant/databaseLogs",
      permission: [viewDatabaseLogs]
    },
    "Traces over time":{
      pathname: "/tenant/databaseLogs",
      permission: [viewDatabaseLogs]
    },
    "Total Alerts":{
      pathname: "/tenant/alerts",
      permission: [viewAlert]
    }
  }
  return pathWithPermission(data[name])
};

const renderTotalCount = (query, cubejsApi) => {
  return (
    <div className="widget-header2">
      <span className="span-total-count">
        <QueryRenderer
          query={query}
          cubejsApi={cubejsApi}
          render={({ resultSet }) => {
            if (!resultSet) {
              return <div className="loader" />;
            }
            return renderSingleValue(resultSet, query.measures[0]);
          }}
        />
      </span>
    </div>
  );
};
let chartTypes = {
  number: (cubejsApi, title, queries , history) => {
    return ComboChart(
      cubejsApi,
      title,
      renderSingleValue,
      renderNoData,
      queries,
      undefined,
      history
    );
  },

  MDRCustomChart: (cubejsApi, title, query, dateTimeRange) => {
    return (
      <IncidentByState
        cubejsApi={cubejsApi}
        title={title}
        renderSingleValue={renderSingleValue}
        renderNoData={renderNoData}
        query={query}
        project="MDR"
      ></IncidentByState>
    );
  },
  MDRNumber: (cubejsApi, title, queries) => {
    return ComboChart(
      cubejsApi,
      title,
      renderSingleValue,
      renderNoData,
      queries,
      "MDR"
    );
  },
  area: (cubejsApi, title, query, color , history) => {
    const render = (resultSet) => {
      let data = resultSet.loadResponse.results[0].data;
      if (!data || data.length === 0) {
        return renderNoData("10%");
      }
      let projectedData = [["X", "Y"]];
      data.forEach((d) => {
        let formattedMonth = dateFormatter(
          d[
            `${query.timeDimensions[0].dimension}.${query.timeDimensions[0].granularity}`
          ]
        );
        projectedData.push([formattedMonth, parseInt(d[query.measures[0]])]);
      });
      return (
        <Chart
          // width={'100%'}
          // height={'300px'}
          chartType="AreaChart"
          loader={<div>Loading Chart</div>}
          data={projectedData}
          chartEvents={[
            {
              eventName: "ready",
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                if (history) {
                  let handler = function (e) {
                      if (("Traces over Time" === title || "Traces over time" === title) && history) {
                        history.push({
                          ...returnRedirectTo(title)
                        });
                      }
                    }
                  google.visualization.events.addListener(
                    chartWrapper.getChart(),
                    "select",
                    handler
                  );
                }
              },
            },
          ]}
          options={{
            vAxis: { minValue: 0 },
            chartArea: { width: "90%", height: "80%" },
          }}
        />
      );
    };
    return (
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={title}
        query={query}
        render={render}
        redirectTo={returnRedirectTo(title)}
      />
    );
  },
  bar: (cubejsApi, title, query, colors) => {
    let options = {
      // Material design options
      legend: { position: "bottom" },
    };
    let categories = Object.getOwnPropertyNames(colors);
    if (colors) {
      let colorsForBars = Object.getOwnPropertyNames(colors).map(
        (color) => colors[color]
      );
      options.colors = colorsForBars;
    }
    const render = (resultSet) => {
      let data = resultSet.loadResponse.results[0].data;
      if (!data || data.length === 0) {
        return renderNoData("10%");
      }
      let projectedData;
      if (query.dimensions && query.dimensions.length > 0) {
        let dimensions = _.uniq(data.map((d) => d[query.dimensions[0]]));
        let timeFinder = query.timeDimensions[0].granularity
          ? `${query.timeDimensions[0].dimension}.${query.timeDimensions[0].granularity}`
          : query.timeDimensions[0].dimension;
        let dates = _.uniq(data.map((d) => d[timeFinder]));
        projectedData = [["X"]];
        categories.forEach((category) => projectedData[0].push(category));
        dates.forEach((d) => {
          let formattedMonth = dateFormatter(d);
          let barChartData = { date: formattedMonth };
          data
            .filter((entry) => entry[timeFinder])
            .map((e) => {
              return (barChartData[e[query.dimensions[0]]] =
                e[query.measures[0]]);
            });
          categories.forEach((c) => {
            // if(!barChartData[c])
            if (!barChartData[c]) {
              barChartData[c] = 0;
            }
          });
          let itemdata = [
            barChartData.date,
            parseInt(barChartData[categories[0]]) || 0,
          ];
          if (
            barChartData[categories[1]] !== null &&
            barChartData[categories[1]] !== undefined
          ) {
            itemdata.push(parseInt(barChartData[dimensions[1]]) || 0);
          }
          if (
            barChartData[categories[2]] !== null &&
            barChartData[categories[2]] !== undefined
          ) {
            itemdata.push(parseInt(barChartData[dimensions[2]]) || 0);
          }

          projectedData.push(itemdata);
        });
      } else {
        projectedData = [["X", "Y"]];
        data.forEach((d) => {
          projectedData.push([
            d[
              `${query.timeDimensions[0].dimension}.${query.timeDimensions[0].granularity}`
            ],
            parseInt(d[query.measures[0]]),
          ]);
        });
      }

      return (
        <Chart
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          data={projectedData}
          options={options}
          // For tests
          rootProps={{ "data-testid": "2" }}
        />
      );
    };
    return (
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={title}
        query={query}
        render={render}
        edit={openEditModal}
      />
    );
  },
  line: (cubejsApi, title, query, color, projection, dateFormat) => {
    const render = (resultSet) => {
      let data = resultSet.loadResponse.results[0].data;
      if (!data || data.length === 0) {
        return renderNoData("10%");
      }
      let projectedData = [["X", "Y"]];
      if (projectedData) {
        projectedData = [[projection.x, projection.y]];
      }
      let xAxisTicks = [];
      data.forEach((d) => {
        let formattedMonth = new Date(
          d[
            `${query.timeDimensions[0].dimension}.${query.timeDimensions[0].granularity}`
          ]
        );

        xAxisTicks.push(formattedMonth);
        projectedData.push([formattedMonth, parseInt(d[query.measures[0]])]);
      });
      let ticks = [];
      if (xAxisTicks.length > 10) {
        let interval = Math.ceil(xAxisTicks.length / 10);
        // let ticks = [];
        for (let i = 0; i < 10; i++) {
          if (i === 0) {
            ticks.push(xAxisTicks[0]);
          } else {
            ticks.push(xAxisTicks[i * interval]);
          }
          // ticks.push(xAxisTicks[]);
        }
      }
      else
      {
        ticks=xAxisTicks;
      }
      return (
        <Chart
          // width={'100%'}
          // height={'300px'}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={projectedData}
          // options={{
          //   hAxis: { ticks },
          // }}
          options={{
            opacity: 1,
            hAxis: { ticks: ticks, format: dateFormat },
          }}
          // For tests
          rootProps={{ "data-testid": "2" }}
        />
      );
    };
    return (
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={title}
        query={query}
        render={render}
      />
    );
  },
  MDRdonut: (cubejsApi, title, query, colors, type) => {
    return (
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={title}
        query={query}
        render={(resultSet) => {
          let data = resultSet.loadResponse.results[0].data;
          let labelArr = [];
          labelArr[0] = resultSet.loadResponse.pivotQuery.dimensions[0];
          labelArr[1] = resultSet.loadResponse.pivotQuery.measures[0];
          return (
            <React.Fragment>
              {/* <CanvasJSChart options={options2} /> */}
              <MDRPieCharts
                data={data}
                type={type}
                labelArr={labelArr}
                // resultSet={resultSet}
              ></MDRPieCharts>
            </React.Fragment>
          );
        }}
      />
    );
  },
  MDRSortedBarChartsFun: (cubejsApi, title, query, type, colors) => {
    const togglerNamesArr = [
      "Agents: Last Seen",
      "Agents: Top 10 Devices by Score",
    ];
    return (
      <>
        {type === "MDREndpoints" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Toggler togglerNamesArr={togglerNamesArr} type={type}></Toggler>
            <SquareToggler type={type}></SquareToggler>
          </div>
        ) : (
          ""
        )}

        <ChartWrapper
          cubejsApi={cubejsApi}
          title={title}
          query={query}
          render={(resultSet) => {
            let data = resultSet.loadResponse.results[0].data;

            let options = {
              title: "",
              chartArea: { width: "75%" },
              bar: {
                groupWidth: "95%",
              },
              hAxis: {
                title: "Total Population",
                minValue: 0,
              },
              vAxis: {
                title: "City",
              },
            };

            return data.length > 0 ? (
              <MDRSortedBarCharts data={data} query={query} type={type} />
            ) : (
              <div>No Data Available</div>
            );
          }}
        />
      </>
    );
  },
  MDRBarGraph: (cubejsApi, title, query, colors, type) => {
    console.log(type, "typetypeTest");
    let options = {};
    let togglerNamesArr = [];
    let tabValue = query.timeDimensions[0].granularity || "day";

    if (type === "MDRIncidents") {
      options = { colors: ["#F43860", "#FF5478", "#FFC344", "#91DFFF"] };
      togglerNamesArr = [
        "Incidents: By Priority",
        "Incidents: True Positive/Open",
      ];
      if (tabValue === "month") {
        options.hAxis = {
          title: "Month",
          titleTextStyle: { italic: false },
        };
        options.vAxis = {
          title: "Incidents By Priority",
          titleTextStyle: { italic: false },
        };
      } else if (tabValue === "day") {
        options.hAxis = {
          title: "Date",
          titleTextStyle: { italic: false },
        };
        options.vAxis = {
          title: "Incidents By Priority",
          titleTextStyle: { italic: false },
        };
      } else if (tabValue === "week") {
        options.hAxis = {
          title: "Week",
          titleTextStyle: { italic: false },
        };
        options.vAxis = {
          title: "Incidents By Priority",
          titleTextStyle: { italic: false },
        };
      }
    } else if (type === "MDREndpoints") {
      options = { colors: ["#498BD7"] };
      if (tabValue === "month") {
        options.hAxis = {
          title: "Month",
          titleTextStyle: { italic: false },
        };
        options.vAxis = {
          title: "Agents Last Seen",
          titleTextStyle: { italic: false },
        };
      } else if (tabValue === "day") {
        options.hAxis = {
          title: "Date",
          titleTextStyle: { italic: false },
        };
        options.vAxis = {
          title: "Agents Last Seen",
          titleTextStyle: { italic: false },
        };
      } else if (tabValue === "week") {
        options.hAxis = {
          title: "Week",
          titleTextStyle: { italic: false },
        };
        options.vAxis = {
          title: "Agents Last Seen",
          titleTextStyle: { italic: false },
        };
      }
      togglerNamesArr = [
        "Agents: Last Seen",
        "Agents: Top 10 Devices by Score",
      ];
    } else if (type === "MDRpatchesInstalledvsNeeded") {
      options = { colors: ["#064A8E", "#ED7D31"] };
      options.hAxis = {
        title: "Date",
        titleTextStyle: { italic: false },
      };
      options.vAxis = {
        title: "Patches",
        titleTextStyle: { italic: false },
      };
    }

    const calculatePatchMonitoringBarGraphCount = (el, projectedData, i) => {
      if (el["MDRPatchManagement.updateState"].toLowerCase() === "installed") {
        projectedData[i][1] =
          Number(projectedData[i][1]) + Number(el["MDRPatchManagement.count"]);
      } else if (
        el["MDRPatchManagement.updateState"].toLowerCase() === "needed"
      ) {
        projectedData[i][2] =
          Number(projectedData[i][2]) + Number(el["MDRPatchManagement.count"]);
      }
    };
    const calculateEndPointsCount = (el, projectedData, i) => {
      projectedData[i][1] =
        Number(projectedData[i][1]) + Number(el["MDREndPoints.count"]);
    };
    const findLowHighMedium = (el, projectedData, i) => {
      if (el["Incidents.priority"] === "Critical") {
        projectedData[i][1] =
          Number(projectedData[i][1]) + Number(el["Incidents.count"]);
      } else if (el["Incidents.priority"] === "High") {
        projectedData[i][2] =
          Number(projectedData[i][2]) + Number(el["Incidents.count"]);
      } else if (el["Incidents.priority"] === "Medium") {
        projectedData[i][3] =
          Number(projectedData[i][3]) + Number(el["Incidents.count"]);
      } else if (el["Incidents.priority"] === "Low") {
        projectedData[i][4] =
          Number(projectedData[i][4]) + Number(el["Incidents.count"]);
      }
    };

    return (
      <>
        <div>
          {type !== "MDRpatchesInstalledvsNeeded" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Toggler togglerNamesArr={togglerNamesArr} type={type}></Toggler>
              <SquareToggler type={type}></SquareToggler>
            </div>
          )}

          <ChartWrapper
            cubejsApi={cubejsApi}
            title={title}
            query={query}
            render={(resultSet) => {
              let data = resultSet.loadResponse.results[0].data;
              let projectedData = [];
              if (type === "MDRIncidents") {
                let i = 1;
                if (data.length !== 0) {
                  projectedData.push(["", "Critical", "High", "Medium", "Low"]);
                }

                data.forEach((el, index, arr) => {
                  let result = el["Incidents.creation_date"]
                    ? moment(el["Incidents.creation_date"]).format("YYYY-MM-DD")
                    : moment(el["Incidents.incidentTime"]).format("YYYY-MM-DD");
                  if (index === 0) {
                    projectedData.push([result, 0, 0, 0, 0]);
                    findLowHighMedium(el, projectedData, i);
                  } else if (index !== 0) {
                    let date1 = dateFormatterMDR(
                      el["Incidents.creation_date"],
                      el["Incidents.incidentTime"]
                    );

                    let date2 = dateFormatterMDR(
                      arr[index - 1]["Incidents.creation_date"],
                      arr[index - 1]["Incidents.incidentTime"]
                    );

                    let boolean = date1 === date2;
                    if (boolean) {
                      findLowHighMedium(el, projectedData, i);
                    } else {
                      i = i + 1;
                      projectedData.push([result, 0, 0, 0, 0]);
                      findLowHighMedium(el, projectedData, i);
                    }
                  }
                });
              } else if (type === "MDREndpoints") {
                let i = 1;
                if (data.length !== 0) {
                  projectedData.push(["", "Count"]);
                }
                data.forEach((el, index, arr) => {
                  let result = moment(el["MDREndPoints.creation_time"]).format(
                    "YYYY-MM-DD"
                  );
                  if (index === 0) {
                    projectedData.push([result, 0]);
                    calculateEndPointsCount(el, projectedData, i);
                  } else if (index !== 0) {
                    let date1 = dateFormatterMDR(
                      el["MDREndPoints.creation_time"]
                    );

                    let date2 = dateFormatterMDR(
                      arr[index - 1]["MDREndPoints.creation_time"]
                    );
                    let boolean = date1 === date2;
                    if (boolean) {
                      calculateEndPointsCount(el, projectedData, i);
                    } else {
                      i = i + 1;
                      projectedData.push([result, 0]);
                      calculateEndPointsCount(el, projectedData, i);
                    }
                  }
                });
              } else if (type === "MDRpatchesInstalledvsNeeded") {
                let i = 1;
                if (data.length !== 0) {
                  projectedData.push(["", "Installed", "Needed"]);
                }
                data.forEach((el, index, arr) => {
                  let result = moment(
                    el["MDRPatchManagement.creation_time"]
                  ).format("YYYY-MM-DD");
                  if (index === 0) {
                    projectedData.push([result, 0, 0]);
                    calculatePatchMonitoringBarGraphCount(el, projectedData, i);
                  } else if (index !== 0) {
                    let date1 = dateFormatterMDR(
                      el["MDRPatchManagement.creation_time"]
                    );

                    let date2 = dateFormatterMDR(
                      arr[index - 1]["MDRPatchManagement.creation_time"]
                    );
                    let boolean = date1 === date2;
                    if (boolean) {
                      calculatePatchMonitoringBarGraphCount(
                        el,
                        projectedData,
                        i
                      );
                    } else {
                      i = i + 1;
                      projectedData.push([result, 0, 0]);
                      calculatePatchMonitoringBarGraphCount(
                        el,
                        projectedData,
                        i
                      );
                    }
                  }
                });
              }

              return (
                <React.Fragment>
                  {projectedData && projectedData.length > 0 ? (
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            writingMode: "vertical-rl",
                            transform: "rotate(180deg)",
                            display: "flex",
                            padding: "2px",
                            marginRight: "5px",
                            justifyContent: "center",
                          }}
                        >
                          {options.vAxis.title}
                        </div>
                        <Chart
                          width={"100%"}
                          height={"24rem"}
                          chartType="Bar"
                          loader={<div>No Data Available</div>}
                          data={projectedData}
                          options={options}
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {options.hAxis.title}
                      </div>
                    </>
                  ) : (
                    <div width={"100%"} height={"24rem"}>
                      No Data Available
                    </div>
                  )}
                </React.Fragment>
              );
            }}
          />
        </div>
      </>
    );
  },
  MDRAreaGraph: (cubejsApi, title, query, colors, type) => {
    let options = {};
    let togglerNamesArr = [];
    if (type === "MDRIncidents") {
      togglerNamesArr = [
        "Incidents: By Priority",
        "Incidents: True Positive/Open",
      ];
    } else if (type === "MDREndpoints") {
      togglerNamesArr = [
        "Agents: Last Seen",
        "Agents: Top 10 Devices by Score",
      ];
    }
    if (colors) {
      let colorsForDonut = Object.getOwnPropertyNames(colors).map(
        (color) => colors[color]
      );
      options.colors = colorsForDonut;
    }
    let incidentGroupingValue = query.timeDimensions[0].granularity;

    const getOptions = () => {
      if (type === "MDRIncidents") {
        if (incidentGroupingValue === "day") {
          options.vAxis = {
            title: "Incidents by True /Open",
            titleTextStyle: { italic: false },
          };
          options.hAxis = {
            title: "Date",
            titleTextStyle: { italic: false },
          };
        } else if (incidentGroupingValue === "week") {
          options.vAxis = {
            title: "Incidents by True /Open",
            titleTextStyle: { italic: false },
          };
          options.hAxis = {
            title: "Week",
            titleTextStyle: { italic: false },
          };
        } else if (incidentGroupingValue === "month") {
          options.vAxis = {
            title: "Incidents by True /Open",
            titleTextStyle: { italic: false },
          };
          options.hAxis = {
            title: "Month",
            titleTextStyle: { italic: false },
          };
        }
      }
      return options;
    };
    return (
      <>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Toggler togglerNamesArr={togglerNamesArr} type={type}></Toggler>
            <SquareToggler type={type}></SquareToggler>
          </div>

          <ChartWrapper
            cubejsApi={cubejsApi}
            title={title}
            query={query}
            render={(resultSet) => {
              let data = resultSet.loadResponse.results[0].data;
              let projectedData = [];
              if (data && data.length > 0) {
                if (data.length > 0) {
                  projectedData = [["Year", "True", "Open"]];
                }

                let i = 1;
                data.forEach((el, index, arr) => {
                  let count = Number(el["Incidents.count"]);
                  let result = el["Incidents.incidentTime"]
                    ? moment(el["Incidents.incidentTime"]).format("YYYY-MM-DD")
                    : moment(el["Incidents.creation_date"]).format(
                        "YYYY-MM-DD"
                      );

                  if (index === 0) {
                    projectedData.push([result, 0, 0]);
                    if (
                      el["Incidents.status"] &&
                      el["Incidents.status"].toLowerCase() === "true"
                    ) {
                      projectedData[i][1] = projectedData[i][1]
                        ? projectedData[i][1] + count
                        : count;
                    } else if (
                      el["Incidents.status"] &&
                      el["Incidents.status"].toLowerCase() === "open"
                    ) {
                      projectedData[i][2] = projectedData[i][2]
                        ? projectedData[i][2] + count
                        : count;
                    }
                  } else if (index !== 0) {
                    let date1 = dateFormatterMDR(
                      el["Incidents.incidentTime"],
                      el["Incidents.creation_date"]
                    );

                    let date2 = dateFormatterMDR(
                      arr[index - 1]["Incidents.incidentTime"],
                      arr[index - 1]["Incidents.creation_date"]
                    );
                    let Boolean = date1 === date2;

                    if (Boolean) {
                      if (
                        el["Incidents.status"] &&
                        el["Incidents.status"].toLowerCase() === "true"
                      ) {
                        projectedData[i][1] = projectedData[i][1]
                          ? projectedData[i][1] + count
                          : count;
                      } else if (
                        el["Incidents.status"] &&
                        el["Incidents.status"].toLowerCase() === "open"
                      ) {
                        projectedData[i][2] = projectedData[i][2]
                          ? projectedData[i][2] + count
                          : count;
                      }
                    } else {
                      i = i + 1;
                      projectedData.push([result, 0, 0]);
                      if (
                        el["Incidents.status"] &&
                        el["Incidents.status"].toLowerCase() === "true"
                      ) {
                        projectedData[i][1] = projectedData[i][1]
                          ? projectedData[i][1] + count
                          : count;
                      } else if (
                        el["Incidents.status"] &&
                        el["Incidents.status"].toLowerCase() === "open"
                      ) {
                        projectedData[i][2] = projectedData[i][2]
                          ? projectedData[i][2] + count
                          : count;
                      }
                    }
                  }
                });
              }

              return (
                <React.Fragment>
                  {projectedData.length > 0 ? (
                    <Chart
                      width={"100%"}
                      height={"24rem"}
                      chartType="AreaChart"
                      loader={<div>Loading Chart</div>}
                      data={projectedData}
                      options={{
                        vAxis: {
                          title: "No. of Results",
                          titleTextStyle: { italic: false },
                        },
                        series: [{ color: "#5B90FF" }, { color: "#FF8421" }],
                        curveType: "function",
                        ...getOptions(),
                        // legend: { position: "none" },

                        // lineWidth: 25
                      }}
                      // For tests
                      rootProps={{ "data-testid": "1" }}
                    />
                  ) : (
                    <div style={{ width: "100%", height: "24rem" }}>
                      No Data Available
                    </div>
                  )}
                </React.Fragment>
              );
            }}
          />
        </div>
      </>
    );
  },
  MDRMapGraph: (cubejsApi, title, query, colors) => {
    return (
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={title}
        query={query}
        render={(resultSet) => {
          let data = resultSet.loadResponse.results[0].data;

          return <MDRMapChart data={data}></MDRMapChart>;
        }}
      />
    );
  },
  custom: (cubejsApi, name) => {
    return ComboChart(cubejsApi, name, renderSingleValue);
  },
  placeholder: (name, marginTop) => {
    const StyledCardHeader = styled(({ ...other }) => (
      <CardHeader {...other} />
    ))`
      & .MuiCardHeader-content {
        flex: 1 1 auto;
        width: 100%;
      }
      & .MuiTypography-h5 {
        font-size: 1rem;
      }
    `;

    return (
      <Card>
        <StyledCardHeader
          action={
            <IconButton aria-label="settings">
              <MenuIcon />
            </IconButton>
          }
          title={name}
        />
        {/* style={{ marginLeft: '20px', marginTop }} */}
        <CardContent style={{ marginLeft: "20px", marginTop }}>
          <Typography variant="body2">Work in progress</Typography>
        </CardContent>
      </Card>
    );
  },
  donut: (cubejsApi, title, query, colors, history) => {
    let tableStyle = { border: 0 };
    let options = {
      // Material design options
      pieHole: 0.5,
      legend: "none",
    };
    if (colors) {
      let colorsForDonut = Object.getOwnPropertyNames(colors).map(
        (color) => colors[color]
      );
      options.colors = colorsForDonut;
    }
    options.legend = { position: "labeled" };
    options.chartArea = { left: "0%", width: "100%" };
    options.pieSliceText = "none";
    let separatorStyle = {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "1px solid #ccc",
      height: "1px",
    };

    let tableBorder = {
      boxShadow: "none",
    };
    let tableData = [];
    let chartQuery = query;
    let isTotalCount = false;
    if (query && query.length > 1) {
      isTotalCount = true;
      chartQuery = query[1];
    }
    return (
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={title}
        query={chartQuery}
        render={(resultSet) => {
          let data = resultSet.loadResponse.results[0].data;
          tableData = resultSet.loadResponse.results[0].data;
          if (!data || data.length === 0) {
            return renderNoData("30%");
          }
          let projectedData = [["X", "Y"]];
          let legends = [];
          if (!options.colors) {
            options.colors = randomColors;
          }
          data.forEach((d, index) => {
            let pDataItem = {
              text: d[chartQuery.dimensions[0]] || "-",
              count: parseInt(d[chartQuery.measures[0]]),
            };
            if (colors) {
              pDataItem.color = colors[d[chartQuery.dimensions[0]]];
            } else {
              pDataItem.color = randomColors[index];
            }
            projectedData.push([pDataItem.text, pDataItem.count]);

            legends.push(pDataItem);
            options.colors.push(pDataItem.color);
          });

          return (
            <React.Fragment>
              {isTotalCount && renderTotalCount(query[0],cubejsApi)}
              <div onClick={(e)=>e.stopPropagation()}>
              <Chart
                width={"100%"}
                height={"100%"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={projectedData}
                options={options}
                chartEvents={[
                  {
                    eventName: "ready",
                    callback: ({ chartWrapper, google, props }) => {
                      const chart = chartWrapper.getChart();
                      if (title === "Alerts by Severity") {
                        let handler = function (e) {
                          let selectedItem = chart.getSelection()[0];

                          if (selectedItem) {
                            if (history) {
                              history.push({
                                ...returnRedirectTo(title),
                                state: {
                                  severity:
                                    projectedData[selectedItem.row + 1][0],
                                },
                              });
                            }
                          }
                        };
                        google.visualization.events.addListener(
                          chartWrapper.getChart(),
                          "select",
                          handler
                        );
                      } else if (title === "Alerts by Policy") {
                        let handler = function (e) {
                          let selectedItem = chart.getSelection()[0];

                          if (selectedItem) {
                            if (history) {
                              history.push({
                                ...returnRedirectTo(title),
                                state: {
                                  policyId: tableData[selectedItem.row],
                                },
                              });
                            }
                          }
                        };
                        google.visualization.events.addListener(
                          chartWrapper.getChart(),
                          "select",
                          handler
                        );
                      }else if (title === "Users by Data Source" || title === "Sensitive Data Users by Data Source"  ) {
                        let handler = function (e) {
                          let selectedItem = chart.getSelection()[0];
                          if (selectedItem) {
                            if (history) {
                              localStorage.setItem("data" ,JSON.stringify(tableData[selectedItem.row]))
                              history.push({
                                ...returnRedirectTo(title)
                              });
                            }
                          }
                        };
                        google.visualization.events.addListener(
                          chartWrapper.getChart(),
                          "select",
                          handler
                        );
                      }else if(title === "Users with Alerts by Policy"){
                        let handler = function (e) {
                          let selectedItem = chart.getSelection()[0];
                          if (selectedItem) {
                            if (history) {
                              history.push({
                                ...returnRedirectTo(title),
                              });
                            }
                          }
                        };
                        google.visualization.events.addListener(
                          chartWrapper.getChart(),
                          "select",
                          handler
                        );
                      }else if(title === "Traces by data flows" ){
                        let handler = function (e) {
                          let selectedItem = chart.getSelection()[0];
                          if (selectedItem) {
                            if (history) {
                              history.push({
                                ...returnRedirectTo(title),
                              });
                            }
                          }
                        };
                        google.visualization.events.addListener(
                          chartWrapper.getChart(),
                          "select",
                          handler
                        );
                      }else if( title === "Traces by flow groups"){
                        let handler = function (e) {
                          let selectedItem = chart.getSelection()[0];
                          if (selectedItem) {
                            if (history) {
                              history.push({
                                ...returnRedirectTo(title),
                              });
                            }
                          }
                        };
                        google.visualization.events.addListener(
                          chartWrapper.getChart(),
                          "select",
                          handler
                        );
                      }
                    },
                  },
                ]}
              />
              </div>
              <hr style={separatorStyle} />
              <TableContainer className="donut-legends" style={tableBorder}>
                <Table style={tableStyle}>
                  <TableBody>
                    {legends.map((row) => {
                      let box = {
                        width: "10px",
                        height: "10px",
                        backgroundColor: row.color,
                      };
                      let content = {
                        maxWidth: "120px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      };
                      return (
                        <TableRow key={row.name}>
                          <TableCell>
                            <div style={box}></div>
                          </TableCell>
                          <TableCell title={row.text} className="content">
                            {row.text ? row.text : "-"}
                          </TableCell>
                          <TableCell align="right">{row.count}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          );
        }}
        redirectTo={returnRedirectTo(title)}
      />
    );
  },
  custom: (cubejsApi, name) => {
    return ComboChart(cubejsApi, name, renderSingleValue);
  },
  placeholder: (name, marginTop) => {
    const StyledCardHeader = styled(({ ...other }) => (
      <CardHeader {...other} />
    ))`
      & .MuiCardHeader-content {
        flex: 1 1 auto;
        width: 100%;
      }
      & .MuiTypography-h5 {
        font-size: 1rem;
      }
    `;

    return (
      <Card>
        <StyledCardHeader
          action={
            <IconButton aria-label="settings">
              <MenuIcon />
            </IconButton>
          }
          title={name}
        />
        {/* style={{ marginLeft: '20px', marginTop }} */}
        <CardContent style={{ marginLeft: "20px", marginTop }}>
          <Typography variant="body2">Work in progress</Typography>
        </CardContent>
      </Card>
    );
  },
  customChart: (cubejsApi, chartName, name) => {
    let transactions = {
      data: [
        ["Day", "Count"],
        ["01-Jan-2021", 100],
        ["02-Jan-2021", 117],
        ["03-Jan-2021", 66],
        ["04-Jan-2021", 30],
      ],
      count: 316,
      color: "yellow",
    };

    let transactionsAccessingSensitiveData = {
      data: [
        ["Day", "Count"],
        ["01-Jan-2021", 20],
        ["02-Jan-2021", 17],
        ["03-Jan-2021", 26],
        ["04-Jan-2021", 5],
      ],
      count: 68,
      color: "green",
    };

    let dataSourcesWithSensitiveData = {
      data: [
        ["Day", "Count"],
        ["01-Jan-2021", 10],
        ["02-Jan-2021", 15],
        ["03-Jan-2021", 5],
        ["04-Jan-2021", 23],
      ],
      count: 53,
      color: "pink",
    };

    let transactionsByDataFlows = {
      data: [
        ["Data Flow", "Count"],
        ["Data flow 1", 20],
        ["Data flow 2", 30],
        ["Data flow 3", 5],
        ["Data flow 4", 10],
        ["Data flow 5", 1],
      ],
    };

    let transactionsByDataFlowGroups = {
      data: [
        ["Data Flow", "Count"],
        ["Flow Group 1", 20],
        ["Flow Group 2", 30],
        ["Flow Group 3", 5],
        ["Flow Group 4", 10],
        ["Flow Group 5", 1],
      ],
    };

    let renderAreaChart = (chartData) => {
      const render = () => {
        let data = chartData.data;

        return (
          <Chart
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
              vAxis: { minValue: 0 },
              chartArea: { width: "90%", height: "80%" },
              colors: [chartData.color],
            }}
          />
        );
      };
      return render();
    };
    let renderMultiLineChart = () => {
      const render = () => {
        return (
          <Chart
            chartType="Line"
            loader={<div>Loading Chart</div>}
            data={[
              ["", "Total Users", "Sensitive Data Users"],
              ["jan", 0, 0],
              ["feb", 10, 5],
              ["mar", 23, 15],
              ["apr", 17, 9],
              ["may", 18, 10],
              ["jun", 9, 5],
              ["jul", 11, 3],
              ["aug", 27, 19],
              ["aug", 27, 19],
              ["sep", 27, 19],
              ["oct", 10, 12],
              ["nov", 4, 8],
              ["dec", 10, 9],
            ]}
            options={{
              chart: {
                title: "Sensitive Data Users Time ",
                subtitle: "",
              },
              colors: ["#91DFFF", "#FF8421"],
            }}
          />
        );
      };
      return render();
    };
    let renderNumber = (chartData) => {
      return (
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <Typography variant="body2">
              {renderSingleValue(chartData.count)}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Chart
              // width={'100%'}
              height={"120px"}
              chartType="AreaChart"
              data={chartData.data}
              options={{
                vAxis: {
                  minValue: 0,
                  // gridlines: { color: 'transparent' },
                  baselineColor: "#fff",
                  gridlineColor: "#fff",
                  textPosition: "none",
                },
                haxis: {
                  title: "",
                  baselineColor: "#fff",
                  gridlineColor: "#fff",
                  textPosition: "none",
                },
                // For the legend to fit, we make the chart area smaller
                chartArea: { width: "90%", height: "100%" },
                colors: [chartData.color],
                // lineWidth: 25,
              }}
              rootProps={{ "data-testid": "1" }}
            />
          </Grid>
        </Grid>
      );
    };
    let renderDonutChart = (chartData) => {
      let tableStyle = { border: 0 };
      let options = {
        // Material design options
        pieHole: 0.5,
        legend: "none",
      };
      let data = chartData.data;
      let projectedData = [];
      let legends = [];
      if (!options.colors) {
        options.colors = randomColors;
      }
      data.forEach((d, index) => {
        if (index === 0) {
          projectedData.push(d);
          return;
        }
        let pDataItem = {
          text: d[0],
          count: d[1],
        };

        pDataItem.color = randomColors[index - 1];

        projectedData.push([pDataItem.text, pDataItem.count]);

        legends.push(pDataItem);
      });

      return (
        <React.Fragment>
          <Chart
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={projectedData}
            options={options}
          />
          <hr />
          <TableContainer component={Paper}>
            <Table style={tableStyle}>
              <TableBody>
                {legends.map((row) => {
                  let box = {
                    width: "10px",
                    height: "10px",
                    backgroundColor: row.color,
                  };
                  return (
                    <TableRow key={row.name}>
                      <TableCell>
                        <div style={box}></div>
                      </TableCell>
                      <TableCell>{row.text}</TableCell>
                      <TableCell align="right">{row.count}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      );
    };

    let chartData, chartMethod;

    if (chartName === "Custom - Transactions") {
      chartData = transactions;
      chartMethod = renderNumber;
    } else if (chartName === "Custom - Transactions Sensitive Data") {
      chartData = transactionsAccessingSensitiveData;
      chartMethod = renderNumber;
    } else if (chartName === "Custom - Data Sources") {
      chartData = dataSourcesWithSensitiveData;
      chartMethod = renderNumber;
    } else if (chartName === "Custom - Transactions over time") {
      chartData = transactions;
      chartMethod = renderAreaChart;
    } else if (chartName === "Custom - Transactions by data flows") {
      chartData = transactionsByDataFlows;
      chartMethod = renderDonutChart;
    } else if (chartName === "Custom - Transactions by flow groups") {
      chartData = transactionsByDataFlowGroups;
      chartMethod = renderDonutChart;
    } else if (chartName === "Custom - Sensitive Data Users over Time") {
      // chartData = transactionsByDataFlowGroups;
      chartMethod = renderMultiLineChart;
    }

    const StyledCardHeader = styled(({ ...other }) => (
      <CardHeader {...other} />
    ))`
      & .MuiCardHeader-content {
        flex: 1 1 auto;
        width: 100%;
      }
      & .MuiTypography-h5 {
        font-size: 1rem;
      }
    `;

    return (
      <Card>
        <StyledCardHeader
          action={
            <IconButton aria-label="settings">
              <MenuIcon />
            </IconButton>
          }
          title={name}
        />
        <CardContent>
          <Typography variant="body2">{chartMethod(chartData)}</Typography>
        </CardContent>
      </Card>
    );
  },
  renderUsersWithAlertsBarChart: usersWithAlertsBarChart,
  sensitiveDataUsersLineChart: sensitiveDataUsersLineChart,
  alertsOverTimeBarChart: alertsOverTimeBarChart,
  alertsByLevel: complianceAlertsByLevelChart,
  usersWithAlertsByLevelChart: usersWithAlertsByLevelChart,
  totalUsersCombo: totalUsersCombo,
  healthAgentAlertsOverTime: healthAgentAlertsOverTime,
  fileSummary: fileSummary,
  userRoles: userRoles,
  dropBox: DropBox,
  sensitiveGroups: SensitiveGroups,
  dataAssetsDonut:dataAssetsDonut,
  heatMapLegends: heatMapLegends,
  totalGroups: totalGroups,
  threatRiskLevelWidget: threatRiskLevelWidget,
  mitigationOwnerWidget: MitigationOwnerWidget,
  riskAgingWidget: RiskAgingWidget,
  mitigationPriorityWidget: MitigationPriorityWidget,
  mitigationStatusWidget: MitigationStatusWidget,
  totalRiskWidget: TotalRiskWidget,
  riskCategoryWidget: RiskCategoryWidget,
};

export function getChart(
  dashboardWidgets,
  index,
  cubejsApi,
  dateTimeRange,
  dispatch,
  tableData,
  history
) {
  let dashboardWidget = dashboardWidgets[index];
  if (dashboardWidget.vizState.chartType === "area") {
    return chartTypes.area(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.color,
      history
    );
  }
  if (dashboardWidget.vizState.chartType.indexOf("number") >= 0) {
    let numChart = chartTypes.number(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.queries,
      history
    );
    return numChart;
  }
  if (dashboardWidget.vizState.chartType.indexOf("MDRNumber") >= 0) {
    let numChart = chartTypes.MDRNumber(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.queries
    );
    return numChart;
  }
  if (dashboardWidget.vizState.chartType === "bar") {
    return chartTypes.bar(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.colors
    );
  }
  if (dashboardWidget.vizState.chartType === "donut") {
    return chartTypes.donut(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.colors,
      history
    );
  }
  if (dashboardWidget.vizState.chartType === "MDRdonut") {
    return chartTypes.MDRdonut(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.colors,
      dashboardWidget.type
    );
  }
  if (dashboardWidget.vizState.chartType === "MDRAreaGraph") {
    return chartTypes.MDRAreaGraph(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.colors,
      dashboardWidget.type,
      useSelector
    );
  }

  if (dashboardWidget.vizState.chartType === "MDRBarGraph") {
    return chartTypes.MDRBarGraph(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.colors,
      dashboardWidget.type
    );
  }
  if (dashboardWidget.vizState.chartType === "MDRSortedBarCharts") {
    return chartTypes.MDRSortedBarChartsFun(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.type
    );
  }

  if (dashboardWidget.vizState.chartType === "MDRTable") {
    return chartTypes.MDRTable(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.type,
      dashboardWidget.loc,
      dispatch,
      tableData
    );
  }

  if (dashboardWidget.vizState.chartType === "MDRCustomChart") {
    return chartTypes.MDRCustomChart(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.colors,
      dateTimeRange
    );
  }
  if (dashboardWidget.vizState.chartType === "MDRMapGraph") {
    return chartTypes.MDRMapGraph(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.colors
    );
  }
  if (dashboardWidget.vizState.chartType === "line") {
    return chartTypes.line(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      dashboardWidget.vizState.color
    );
  }
  if (dashboardWidget.vizState.chartType === "placeholder") {
    return chartTypes.placeholder(
      dashboardWidget.name,
      dashboardWidget.vizState.margin
    );
  }
  if (dashboardWidget.vizState.chartType.indexOf("Custom") >= 0) {
    // cubejsApi, chartName, name
    return chartTypes.customChart(
      cubejsApi,
      dashboardWidget.vizState.chartType,
      dashboardWidget.name
    );
  }
  if (dashboardWidget.vizState.chartType === "usersWithAlerts") {
    return chartTypes.renderUsersWithAlertsBarChart(
      cubejsApi,
      dashboardWidget.name,
      undefined,
      history
    );
  }
  if (dashboardWidget.vizState.chartType === "sensitiveDataUsers") {
    return chartTypes.sensitiveDataUsersLineChart(
      cubejsApi,
      dashboardWidget.name,
      history
    );
  }
  if (dashboardWidget.vizState.chartType === "alertsOverTimeBarChart") {
    return chartTypes.alertsOverTimeBarChart(cubejsApi, dashboardWidget.name,undefined,history);
  }
  if (dashboardWidget.vizState.chartType === "complianceAlertsByLevelChart") {
    return chartTypes.alertsByLevel(cubejsApi, dashboardWidget.name, history);
  }
  if (dashboardWidget.vizState.chartType === "usersWithAlertsByLevelChart") {
    return chartTypes.usersWithAlertsByLevelChart(
      cubejsApi,
      dashboardWidget.name ,
      history
    );
  }
  if (dashboardWidget.vizState.chartType === "totalUsersCombo") {
    return chartTypes.totalUsersCombo(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.queries,
      history
    );
  }
  if (dashboardWidget.vizState.chartType === "healthAgentAlertsOverTime") {
    return chartTypes.healthAgentAlertsOverTime(
      cubejsApi,
      dashboardWidget.name
    );
  }
  if (dashboardWidget.vizState.chartType === "totalAssets") {
    return chartTypes.fileSummary(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query
    );
  }
  if (dashboardWidget.vizState.chartType === "heatMapLegend") {
    return chartTypes.heatMapLegends(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
    );
  }
  if (dashboardWidget.vizState.chartType === "totalGroups") {
    return chartTypes.totalGroups(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
      );
  }
  if (dashboardWidget.vizState.chartType === "userRoles") {
    return chartTypes.userRoles(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.queries,
    );
  }
  //need to implement dropbox changes once the data is available
  if (dashboardWidget.vizState.chartType === "drop box") {
    return chartTypes.dropBox(
      cubejsApi,
      dashboardWidget.vizState.data
    )
  }
  if (dashboardWidget.vizState.chartType === "SensitiveGroups") {
    return chartTypes.sensitiveGroups(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
    )
  }
  if (dashboardWidget.vizState.chartType === "dataAssetsDonut") {
    return chartTypes.dataAssetsDonut(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query
    )
  }
  if (dashboardWidget.vizState.chartType === "riskCategoryWidget") {
    return chartTypes.riskCategoryWidget(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
    );
  }
  if (dashboardWidget.vizState.chartType === "threatRiskLevelWidget") {
    return chartTypes.threatRiskLevelWidget(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
    );
  }
  if (dashboardWidget.vizState.chartType === "mitigationOwnerWidget") {
    return chartTypes.mitigationOwnerWidget(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
    );
  }
  if (dashboardWidget.vizState.chartType === "mitigationPriorityWidget") {
    return chartTypes.mitigationPriorityWidget(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
    );
  }
  if (dashboardWidget.vizState.chartType === "mitigationStatusWidget") {
    return chartTypes.mitigationStatusWidget(
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query,
    );
  }
  if (dashboardWidget.vizState.chartType === "totalRiskWidget") {
    return chartTypes.totalRiskWidget (
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query
    );
  }
  if (dashboardWidget.vizState.chartType === "riskAgingWidget") {
    return chartTypes.riskAgingWidget (
      cubejsApi,
      dashboardWidget.name,
      dashboardWidget.vizState.query
    );
  }
}

export { chartTypes };
