import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EnhancedGridTable from "../Table/Table";
import {fetchAllUnResolvedAlerts, getDataSourceNamesForAlerts, processAlerts, mergeDataSourceIntoAlerts} from "../../../redux/actions/getUnresolvedAlertsAction";
import { getTime } from "../../common/utilities";
import { EnhancedTableToolbar } from "./EnhancedAlertsTableHeader";
import { tableConstants } from "./alertsUtilities";
import AlertDrawer from "../alertDrawer";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import Checkbox from "@material-ui/core/Checkbox";
import { isBoolean, filter } from "lodash";
import { CustomUseEffectHook } from "../../views/CustomUseEffectHook";
import {
  endOfDay,
} from "date-fns";
import { updateAlert } from "../../../redux/constants/constants";

export default function UnresolvedAlerts(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const { policyId } = props;
  const [unresolvedAlerts, setUnresolvedAlerts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [selectedAlertId, setSelectedAlertId] = useState("");
  const [stateOpen, setStateOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [severity, setSeverityFilter] = useState([]);
  const [resolution, setResolutionFilter] = useState([]);
  const [policy, setPolicyFilter] = useState([]);
  const [sortBy, setSortBy] = useState("generatedOn");
  const [selectedList, setSelectedList] = useState("");
  const [filterTouched, setFilterTouched] = useState(false);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [filterDataInfo, setDataFilterInfo] = useState([]);
  const [toTimeRange , setToTimeRange]= useState("");
  const [fromTimeRange , setFromTimeRange]= useState("");

 
  const alertStatus = useSelector(
    (state) => state.alertStatusChanged.alertStatus
  );
  const alertStatusUpdated = useSelector(
    (state) => state.alertStatusChanged.alertUpdated
  );
  const permissions = useSelector((state) => state.applicationData.permissions);

  CustomUseEffectHook(() => {
    setLoader(true);
    setOrder("asc");
    setOrderBy("");
    getAllUnresolvedAlerts(recordsPerPage, currentPage);
  }, [
    severity,
    resolution,
    policy,
    sortBy,
    sortOrder,
    currentPage,
    recordsPerPage,
    toTimeRange,
    fromTimeRange
  ]);

  //filter function
  useEffect(() => {
    setLoader(true);
    if (props.propsData && props.propsData.location && props.propsData.location.state) {
      if(props.propsData.location.state.severity){
        if(props.propsData.location.state.time){
          setFromTimeRange(new Date(props.propsData.location.state.time))
          setToTimeRange(endOfDay(new Date(props.propsData.location.state.time)))
        }
        setSeverityFilter([props.propsData.location.state.severity]);
        const severity = props.propsData.location.state.severity;
        setDataFilterInfo([
          {
            data: { code: severity, value: severity },
            name: severity,
            type: "severity",
            value: severity,
          },
        ]);
      }
      else{
        setPolicyFilter([props.propsData.location.state.policyId["Alerts.alert_policy_id"]]);
        const id = props.propsData.location.state.policyId["Alerts.alert_policy_id"];
        const policyname = props.propsData.location.state.policyId["Alerts.policyname"];
        setDataFilterInfo([
          {
            data: { name: policyname, id: id },
            name: policyname,
            type: "policy",
            value: id,
          },
        ]);
      }
     
    } else {
      getAllUnresolvedAlerts(recordsPerPage, currentPage);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getAllUnresolvedAlerts(recordsPerPage, currentPage) {

    const response = await fetchAllUnResolvedAlerts(
      recordsPerPage,
      currentPage,
      sortBy,
      severity,
      resolution,
      policy,
      policyId,
      sortOrder,
      fromTimeRange,
      toTimeRange,

    );

    let alerts;

    // call next api

    if (response) {
      if (response.content && response.content.length > 0) {
        // we get the list of alerts and separate service alerts for getting its data source and direct data alerts to update state
        const { serviceAlertsRequest, alertsWithLoader } = processAlerts(response.content);
        // get data source name from async function but dont block UI
        const dataSourceResponse = getDataSourceNamesForAlerts(serviceAlertsRequest);
        dataSourceResponse.then((alertsWithDS) => {
          setUnresolvedAlerts(mergeDataSourceIntoAlerts(alertsWithLoader, alertsWithDS));
          // after receiving response from service alert merge and update state
        });
        // update state with alerts with loading at initial load
        alerts = alertsWithLoader; //response.content;//mergeDataSourceIntoAlerts(response.content, dataSourceResponse);
      }

      setUnresolvedAlerts(alerts);
      setTotalCount(response.totalElements);
      setSelectedList([]);
      setLoader(false);
    } else {
      setLoader(false);

    }
  }

  useEffect(() => {
    if (alertStatusUpdated) {
      if (
        currentPage > 0 &&
        (selectedList.length === unresolvedAlerts.length ||
          unresolvedAlerts.length === 1) &&
        (alertStatus === "MARK_UNRESOLVED" || alertStatus === "MARK_RESOLVED")
      ) {
        setCurrentPage(currentPage - 1);
      } else {
        getAllUnresolvedAlerts(recordsPerPage, currentPage);
      }
    }
  }, [alertStatusUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleDrawer = (open, event, alertId) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSelectedAlertId(alertId);
    setStateOpen(open);
  };

  const getColumnsData = () => {
    let data = tableConstants(toggleDrawer, getTime, dispatch, history);
    data = [
      {
        title: () => {
          return (
            <Checkbox
              onChange={(event) => onSelectAllClick(event)}
              inputProps={{ "aria-label": "decorative checkbox" }}
              checkedIcon={
                selectedList.length === unresolvedAlerts.length ? (
                  <span className="checked-icon" />
                ) : (
                  <span className="checkbox-icon" />
                )
              }
              indeterminate={
                selectedList.length > 0 &&
                selectedList.length < unresolvedAlerts.length
              }
            />
          );
        },
        render: (rowData) => {
          return (
            <Checkbox
              className="checkbox-root"
              disableRipple
              color="default"
              onChange={() => handleCheckItem(rowData)}
              checkedIcon={
                rowData.checked ? (
                  <span className="checked-icon" />
                ) : (
                  <span className="checkbox-icon" />
                )
              }
              icon={<span className="checkbox-icon" />}
              inputProps={{
                "aria-label": "decorative checkbox",
              }}
              checked={true}
            />
          );
        },
        padding: "checkbox",
        requiredPermission: updateAlert,
      },
      ...data,
    ];
    if (policyId) {
      return filter(data, (_, index) => index !== 3);
    } else {
      return data;
    }
  };

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
    setLoader(true);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
    setLoader(true);
  };
 

  const clearFilterData = () => {
    setLoader(true);
    setFilterTouched(false);
    setCurrentPage(0);
    setResolutionFilter([]);
    setPolicyFilter([]);
    setSortBy("generatedOn");
    setSortOrder("DESC");
    setSeverityFilter([]);
    setDataFilterInfo([]);
    setToTimeRange("");
    setFromTimeRange("");
    // if (props.propsData && props.propsData.location && props.propsData.location.state) {
    //   if(props.propsData.location.state.severity){
    //     setSeverityFilter([props.propsData.location.state.severity]);
    //     const severity = props.propsData.location.state.severity;
    //     setDataFilterInfo([
    //       {
    //         data: { code: severity, value: severity },
    //         name: severity,
    //         type: "severity",
    //         value: severity,
    //       },
    //     ]);
    //   }else{
    //     setPolicyFilter([props.propsData.location.state.policyId["Alerts.alert_policy_id"]]);
    //     const id = props.propsData.location.state.policyId["Alerts.alert_policy_id"];
    //     const policyname = props.propsData.location.state.policyId["Alerts.policyname"];
    //     setDataFilterInfo([
    //       {
    //         data: { name: policyname, id: id },
    //         name: policyname,
    //         type: "policy",
    //         value: id,
    //       },
    //     ]);
    //   }
    // } else {
    //   setSeverityFilter([]);
    //   setDataFilterInfo([]);
    // }
  };

  const handleSorting = (event) => {
    setLoader(true);
    setCurrentPage(0);
    setSortOrder("ASC");
    setSortBy(event.target.value);
  };

  const handleSortOrder = (value) => {
    setCurrentPage(0);
    setSortOrder(value);
  };

  const onSelectAllClick = (event) => {
    const list = [...unresolvedAlerts];
    list.forEach((item) => {
      if (event.target.checked) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    setSelectedList(filter(list, (item) => item.checked).map((i) => i.id));
    setUnresolvedAlerts(list);
  };

  const handleCheckItem = (item) => {
    const id = item.id;
    const list = [...unresolvedAlerts];
    list.forEach((item) => {
      if (item.id === id) {
        item.checked = isBoolean(item.checked) ? !item.checked : true;
      }
    });
    setSelectedList(filter(list, (item) => item.checked).map((i) => i.id));
    setUnresolvedAlerts(list);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  
  // const handleApplyFilter = (severity , resolution,policy,order,orderBy,filterDataInfo) =>{
  //   setSeverityFilter(severity);
  //   setResolutionFilter(resolution);
  //   setPolicyFilter(policy);
  //   setSortBy(order);
  //   setSortOrder(orderBy);
  //   setCurrentPage(0);
  //   setFilterTouched(true);
  //   setDataFilterInfo(filterDataInfo)
  // }

  const handleApplyFilter = (
    severity,
    resolution,
    policy,
    order,
    orderBy,
    filterDataInfo,
    fromTimeRange ,
    toTimeRange
  ) => {
    setSeverityFilter(severity);
    setResolutionFilter(resolution);
    setPolicyFilter(policy);
    setSortBy(order);
    setSortOrder(orderBy);
    setCurrentPage(0);
    setFilterTouched(true);
    setDataFilterInfo(filterDataInfo);
    setToTimeRange(toTimeRange)
    setFromTimeRange(fromTimeRange)
  };

  const getTooltipText = () => {
    if (policyId) {
      return "Active alerts to be investigated and resolved .Click on the Time to view details and monitors.Click on action to view the data access that triggered the alert";
    } else {
      return "Active alerts to be investigated and resolved .Click on the Time to view details and monitors. Click on the Policy Name to view details of the policy.Click on action to view the data access that triggered the alert";
    }
  };
  return (
    <React.Fragment>
      {loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )}
      <EnhancedTableToolbar
        totalElements={totalCount}
        rows={unresolvedAlerts}
        severityFilter={severity}
        resolutionFilter={resolution}
        policyFilter={policy}
        sortByAlert={sortBy}
        toTimeRangeAlert={toTimeRange}
        fromTimeRangeAlert={fromTimeRange}
        loc={"alerts"}
        alertsTitle={"Active Alerts"}
        sortOrderAlert={sortOrder}
        handleSortOrder={handleSortOrder}
        clearFilterData={clearFilterData}
        handleSorting={handleSorting}
        policyId={policyId}
        selectedRows={selectedList}
        dataLoaded={filterTouched}
        applyFilter={handleApplyFilter}
        filterDataInfo={filterDataInfo}
        helpDescription={getTooltipText()}
        show={true}
      />
      <EnhancedGridTable
        cols={getColumnsData()}
        data={unresolvedAlerts || [] }
        totalCount={totalCount}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        order={order}
        orderBy={orderBy}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onRequestSort={handleRequestSort}
      />
      {selectedAlertId !== "" && (
        <AlertDrawer
          alertDetailsId={selectedAlertId}
          toggleDrawer={toggleDrawer}
          stateOpen={stateOpen}
        />
      )}
    </React.Fragment>
  );
}
