import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { FormatDataforPieChart } from "../utils";
import "./MDRPieCharts.scss";

am4core.useTheme(am4themes_animated);

const MDRPieCharts = ({ data, type, labelArr }) => {
  let randomColors = true;
  if (type === "incidentDonutChart") {
    randomColors = false;
  }

  const getVariablesAsperType = () => {
    if (type === "incidentDonutChart") {
      return {
        innerRadius: 100,
        maxWidth: 90,
        fontSize: 10,
        radius: 90,
      };
    } else if (type === "AuthenticationFailedSourcePieChart") {
      return {
        innerRadius: 100,
        maxWidth: 90,
        fontSize: 10,
        radius: 90,
      };
    } else if (type === "AuthenticationFailedIpPieChart") {
      return {
        innerRadius: 100,
        maxWidth: 90,
        fontSize: 10,
        radius: 90,
      };
    } else if (
      type === "AgentsbyEnvironment" ||
      type === "AgentsSecurityToolState" ||
      type === "AgentByOSVersion"
    ) {
      return {
        innerRadius: 50,
        maxWidth: 70,
        fontSize: 10,
        radius: 60,
      };
    } else if (type === "patchMonitoringBySubsCriptionName") {
      return {
        innerRadius: 130,
        maxWidth: 100,
        fontSize: 10,
        radius: 100,
      };
    }
    return {
      innerRadius: 100,
      maxWidth: 90,
      fontSize: 10,
      radius: 80,
    };
  };

  let formattedDataObj = FormatDataforPieChart(
    data,
    type,
    randomColors,
    labelArr
  );

  useLayoutEffect(() => {
    let chart = am4core.create(`${type}`, am4charts.PieChart);
    chart.data = formattedDataObj.data;

    // Add label inside Pie chart
    let label = chart.seriesContainer.createChild(am4core.Label);
    label.text =
      formattedDataObj.data.length > 0
        ? `${formattedDataObj.name} ${formattedDataObj.count}`
        : "No Data Available";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = formattedDataObj.data.length > 0 ? 20 : 14;
    label.wrap = true;
    label.maxWidth = 180;
    // label.textAlign = "center";

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "count";
    pieSeries.dataFields.category = "field";
    pieSeries.slices.template.propertyFields.fill = "color";

    //Used for showing the number instead of % in the pie chart
    pieSeries.labels.template.text = "{category}: {value.value}";

    // pieSeries.alignLabels = true;

    // pieSeries.labels.template.fill = am4core.color("#2947FD");
    // //Legend line height
    // chart.legend = new am4charts.Legend();
    // chart.legend.maxHeight = 100;
    // pieSeries.ticks.template.maxHeight = 900;

    let objVariables = getVariablesAsperType();

    chart.innerRadius = objVariables.innerRadius;

    //To make overflowed labels visible properly

    pieSeries.labels.template.maxWidth = objVariables.maxWidth;
    pieSeries.labels.template.wrap = true;
    // pieSeries.labels.template.truncate = true;
    pieSeries.labels.template.fontSize = objVariables.fontSize;
    chart.radius = am4core.percent(objVariables.radius);

    return () => {
      chart.dispose();
    };
  }, []);
  return <div id={type} />;
};

export default MDRPieCharts;
