import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCubejsApi } from "../../../common/Dashboard/cubejs-util";
import EnhancedGridTable from "../../../shared/Table/Table";
import { ServiceDataAccessesConstants } from "../AccessRightsutilities";
import page_loader from "../../../../assets/vizr_images/page_loader.gif";
import { serviceUsersWithAlertsBarChart } from "../../../common/Dashboard/custom-charts/serviceUsersWithAlerts";
import { EnhancedServiceDataAccessHeader } from "./EnhancedServiceDataAccessHeader";
import AlertDrawer from "../../../shared/alertDrawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ServiceDataAccesses = () => {
  const dispatch = useDispatch();
  let cubejsApi = getCubejsApi();

  const allServiceDataAccesses = useSelector(
    (state) => state.serviceDataAccessReducer.allServiceDataAccesses
  );
  const currentPage = useSelector(
    (state) => state.serviceDataAccessReducer.currentPage
  );
  const recordsPerPage = useSelector(
    (state) => state.serviceDataAccessReducer.recordsPerPage
  );
  const totalCount = useSelector(
    (state) => state.serviceDataAccessReducer.totalCount
  );
  const loader = useSelector((state) => state.serviceDataAccessReducer.loader);
  const accessDetailsDrawer = useSelector(
    (state) => state.normalDirectDataSourceReducer.accessDetailsDrawer
  );
  const serviceDataaccessDrawer = useSelector(
    (state) => state.serviceDataAccessReducer.serviceAccessDetailsDrawer
  );
  const errorMessage = useSelector(
    (state) => state.serviceDataAccessReducer.errorMessage
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch({
      type: "SET_SERVICE_DATA_ACCESSES_LOADER_INFO",
      payload: true,
    });
    dispatch({ type: "GET_SERVICE_DATA_ACCESSES_INFO_DATA" });
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setOpen(true);
    }
  }, [errorMessage]);

  const renderChartData = () => {
    return (
      <div style={{ marginLeft: "13rem", maxWidth: "84%" }}>
        {serviceUsersWithAlertsBarChart(
          cubejsApi,
          "Service Users with Anomalies over Time",
          "Service Users Accessing Data"
        )}
      </div>
    );
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch({
      type: "SET_SERVICE_DATA_ACCESS_RECORDS_PAGE",
      payload: value,
    });
    dispatch({
      type: "SET_SERVICE_DATA_ACCESS_CURRENT_PAGE",
      payload: 0,
    });
    dispatch({
      type: "SET_SERVICE_DATA_ACCESSES_LOADER_INFO",
      payload: true,
    });
    dispatch({ type: "GET_SERVICE_DATA_ACCESSES_INFO_DATA" });
  };

  const handleChangePage = (page) => {
    dispatch({
      type: "SET_SERVICE_DATA_ACCESS_CURRENT_PAGE",
      payload: page,
    });
    dispatch({
      type: "SET_SERVICE_DATA_ACCESSES_LOADER_INFO",
      payload: true,
    });
    dispatch({ type: "GET_SERVICE_DATA_ACCESSES_INFO_DATA" });
  };

  const tableRowsClickHandler = (row) => {
    dispatch({
      type: "SERVICE_DATA_ACCESSES_OPEN_ACCESS_DRAWER",
      payload: row,
    });
  };

  const renderTableToolbar = () => {
    return (
      <EnhancedServiceDataAccessHeader
        totalElements={totalCount}
        rows={allServiceDataAccesses}
        loc={"Service_Data_Accesses"}
        title={"Service Data Users"}
        helpDescription={
          "Data source users along with their direct data access information. click on the entry to view access details and anomalies"
        }
      />
    );
  };

  const renderTable = () => {
    return (
      <EnhancedGridTable
        cols={ServiceDataAccessesConstants()}
        data={allServiceDataAccesses}
        totalCount={totalCount}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onTableRowClick={(data) => tableRowsClickHandler(data)}
        permission={"VIEW_ACCESS_ANOMALIES"}
      />
    );
  };

  const renderLoader = () => {
    const height = allServiceDataAccesses.length > 0 ? "580px" : "100px";
    return (
      loader && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            zIndex: 5,
            top: "0px",
            display: "flex",
            justifyContent: "space-around",
            height: height,
            alignItems: "center",
          }}
        >
          <img src={page_loader} alt="loading"></img>
        </div>
      )
    );
  };

  const renderSidePopup = () => {
    return (
      <AlertDrawer
        accessDetailsDrawer={serviceDataaccessDrawer}
        loc={"Service_Data_Accesses"}
      ></AlertDrawer>
    );
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={"error"}>
          {errorMessage}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      {renderChartData()}
      <div className="filter-table-container">
        <div className="table-container" style={{ position: "relative" }}>
          {renderTableToolbar()}
          {renderTable()}
          {renderLoader()}
          {renderSidePopup()}
          {renderSnackBar()}
        </div>
      </div>
    </>
  );
};

export default ServiceDataAccesses;
