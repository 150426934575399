import React from 'react'
import TablePagination from '@material-ui/core/TablePagination';

export const Pagination=(props) =>{
	const {
		totalCount,
		currentPage,
		recordsPerPage,
		rowsPerPageOptions,
		className,
	} = props

	const handleChangeRowsPerPage = (
		event
	) => {
		props.onChangeRowsPerPage(parseInt(event.target.value))
	}

	const handleChangePage = (
		_,
		page
	) => {
		props.onChangePage(page)
	}
	return (
		<TablePagination
			count={totalCount}
			component='div'
			page={currentPage}
			rowsPerPage={recordsPerPage}
			rowsPerPageOptions={rowsPerPageOptions}
			onChangeRowsPerPage={handleChangeRowsPerPage}
			onChangePage={handleChangePage}
			className={`table-pagination ${className}`}
		/>
	)
}
