import {
  SIGNINPAGE_USERNAME,
  SIGNINPAGE_PASSWORD,
  FETCH_USER_REQUEST,
} from "../constants/signinpageActionTypes";

export const updateSignPageUsername = (value = "") => {
  return {
    type: SIGNINPAGE_USERNAME,
    payload: value,
  };
};

export const updateSignPagePassword = (value = "") => {
  return {
    type: SIGNINPAGE_PASSWORD,
    payload: value,
  };
};
export const fetchUser = (payload) => {
  return {
    type: FETCH_USER_REQUEST,
    payload: payload,
  };
};

export const fetchUserwithMFA = (payload) => {
  return {
    type: "FETCH_USER_REQUEST_WITH_MFA",
    payload: payload,
  };
};
