import moment from "moment";
import { dateFormatUAR } from "../../../redux/constants/constants";

// import _ from "lodash";

const dataBaseUsersFun = (el, dimensions, projectedData, i) => {
  if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_APPROVED"
  ) {
    projectedData[i][1] = Number(projectedData[i][1]) + 1;
  } else if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_TO_BE_VERIFIED"
  ) {
    projectedData[i][2] = Number(projectedData[i][2]) + 1;
  } else if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_EXPIRED"
  ) {
    projectedData[i][3] = Number(projectedData[i][3]) + 1;
  } else if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_REJECTED"
  ) {
    projectedData[i][4] = Number(projectedData[i][4]) + 1;
  } else if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_REQUESTED"
  ) {
    projectedData[i][5] = Number(projectedData[i][5]) + 1;
  } else if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_GRANTED"
  ) {
    projectedData[i][6] = Number(projectedData[i][6]) + 1;
  } else if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_REVOKED"
  ) {
    projectedData[i][7] = Number(projectedData[i][7]) + 1;
  }else if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_MARKED_FOR_DELETION"
  ) {
    projectedData[i][8] = Number(projectedData[i][8]) + 1;
  } else if (
    el[dimensions[1]] &&
    el[dimensions[1]].toUpperCase() === "ACCESS_REMOVED"
  ) {
    projectedData[i][9] = Number(projectedData[i][9]) + 1;
  } 
};

export const accessDetailsBarChartrenderFunction = (
  resultSet,
  namesTobeUsed
) => {
  let data = resultSet.loadResponse.results[0].data;

  let dimensions = resultSet.loadResponse.pivotQuery.dimensions;
  //   let updateddimensions = _.uniq(data.map((d) => d[dimensions[0]]));

  let projectedData = [];
  let i = 1;
  if (data.length !== 0) {
    projectedData.push([
      "Date",
      "Access approved",
      "Access to be verified",
      "Access expired",
      "Access rejected",
      "Access requested",
      "Access granted",
      "Access revoked",
      "Access marked for deletion",
      "Access removed",
    ]);
    data.forEach((el, index, arr) => {
      let result = moment(el["DataSourceUsers.expirationTimestamp"]).format(
        dateFormatUAR
      );
      if (index === 0) {
        projectedData.push([result, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        dataBaseUsersFun(el, dimensions, projectedData, i);
      } else if (index !== 0) {
        let date1 = moment(el["DataSourceUsers.expirationTimestamp"]).format(
          dateFormatUAR
        );
        let date2 = moment(
          arr[index - 1]["DataSourceUsers.expirationTimestamp"]
        ).format(dateFormatUAR);
        let boolean = date1 === date2;
        if (boolean) {
          dataBaseUsersFun(el, dimensions, projectedData, i);
        } else {
          i = i + 1;
          projectedData.push([result, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          dataBaseUsersFun(el, dimensions, projectedData, i);
        }
      }
    });
console.log(projectedData)
    return projectedData;
  }
};
