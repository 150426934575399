import React, { useEffect, useRef } from "react";

export const CustomUseEffectHook = (callBackFun, dependencyArr) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      callBackFun();
    } else didMount.current = true;
  }, dependencyArr);
};
