import React, { useState, useRef, useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../Input";
import { GET_PROVISIONDB_TYPES_URL } from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";

const useStyles = makeStyles({
  input: {
    width: "100%",
    marginTop: "20px",
    boxSizing: "border-box",
    height: "32px",
    border: "1px solid #DFE1E9",
    borderRadius: "4px",
    backgroundColor: " #FFFFFF",
  },
  label: {
    height: "20px",
    width: "8rem",
    color: "#292929",
    fontSize: "0.9rem",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
  },
});

export const DB_MANUAL = "EXISTING_RDS";
export const DB_CONFIG_ACTION = "DB_CONFIG_ACTION";

/**
 *
 * @param {String} provisionDbType type of DB config
 * @param {Object} databaseConfiguration DB config
 * @returns action
 */
const updateDBConfig = (provisionDbType, databaseConfiguration) => {
  return { provisionDbType, databaseConfiguration };
};

/**
 * This functional component will give DB config options
 * @param {object} currentConfig: create tenant config
 * @param {Dispatch} dispatcher: to dispatch new values
 * @returns: JSX of DB config
 */
export default function CreateTenantDBConfig({
  disableDBConfig,
  currentConfig,
  dispatcher,
  setErrorObj,
  errorObj,
}) {
  const classes = useStyles();


  const ManualConfigParam = [
    { name: "host", value: "host", type: "text" },
    { name: "port", value: "port", type: "text", datatypeConverter: parseInt },
    { name: "username", value: "username", type: "text" },
  ];

  if (!disableDBConfig) {
    //in edit we should not have password
    ManualConfigParam.push({ name: "password", value: "password", type: "password"});
  }

  const [DBOptionsList, setDBOptionsList] = useState([]);

  useEffect(() => {
    getProvisionDbTypes();
  }, []);

  const getProvisionDbTypes = async () => {
    const data = await getService({
      method: "GET",
      url: `${GET_PROVISIONDB_TYPES_URL}`,
      headers: getAxiosHeaders(true),
    });
    const options = Object.keys(data.data).map((key) => {
      return {
        value: key,
        label: data.data[key],
      };
    });
    return setDBOptionsList(options);
  };

  /**
   * This function will be called on change of DB config radio button select
   */
  const onDBConfigChange = (ev) => {
    dispatcher(updateDBConfig(ev.target.value, {}));
  };
  /**
   * This function will change the manual DB config
   * @param {*} ev
   */
  const onManualConfigChange = (name, val, datatypeConverter) => {
    const updatedObj = {};
    if (datatypeConverter) {
      const convertedVal = datatypeConverter(val);
      if (isNaN(convertedVal)) {
        updatedObj[name] = '';
      } else {
        updatedObj[name] = convertedVal;
      }
    } else {
      updatedObj[name] = val
    }
    const err = {
      ...errorObj,
    };
    err[name + "_error"] = "";
    setErrorObj(err);
    dispatcher(updateDBConfig(DB_MANUAL, updatedObj));
  };
  return (
    <>
      <FormControl class="signUpDBConfigContainer" disabled={disableDBConfig}>
        <h4>Select Database Option:</h4>
        <RadioGroup
          name="radio-buttons-group"
          onChange={onDBConfigChange}
          value={currentConfig.provisionDbType}
        >
          {DBOptionsList &&
            DBOptionsList.map((DBOption) => {
              return (
                <FormControlLabel
                  label={DBOption.label}
                  value={DBOption.value}
                  control={<Radio color="primary" />}
                  disabled={DBOption.value === "SHARED_RDS"} //Disabling this option for now as we gaps in the implementation
                />
              );
            })}
        </RadioGroup>
      </FormControl>
      {currentConfig.provisionDbType === DB_MANUAL ? (
        <>
          {ManualConfigParam.map((manualParam) => {
            return (
              <FormControl disabled={disableDBConfig}>
                <Input
                  type={manualParam.type}
                  required={true}
                  labelName={manualParam.name}
                  labelClassName={classes.label}
                  value={currentConfig.databaseConfiguration[manualParam.value]}
                  containerName="signUpDBConfigContainer"
                  classname={classes.input}
                  eventHandler={(val) =>
                    onManualConfigChange(
                      manualParam.name,
                      val,
                      manualParam.datatypeConverter
                    )
                  }
                ></Input>
                {errorObj[manualParam.name + "_error"] ? (
                  <div class="errorMsg">
                    {errorObj[manualParam.name + "_error"]}
                  </div>
                ) : (
                  ""
                )}
              </FormControl>
            );
          })}
        </>
      ) : null}
    </>
  );
}