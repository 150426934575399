export const RiskPilotDashboardInstance = [
  {
    id: 0,
    name: "Total Risks",
    type: "Risk Pilot",
    vizState: {
      chartType: "totalRiskWidget",
      query: [
        {
          measures: ["RiskThreats.threatCount"],
          dimensions: [
            "RiskThreats.riskId",
            "RiskThreats.riskType",
            "RiskThreats.severity",
            "RiskThreats.riskLevel",
          ],
          order: {
            "RiskThreats.threatCount": "desc",
          },
        },
        {
          measures: ["RiskThreats.totalUniqueRiskTypes"],
          timeDimensions: [],
          order: {},
        },
      ],
    },
    size: 4,
    row: "medium-row",
  },
  {
    id: 1,
    name: "Risk Categories",
    type: "Risk Categories",
    vizState: {
      chartType: "riskCategoryWidget",
      query: {
        measures: [
          "RiskMitigationStatus.toDoCount",
          "RiskMitigationStatus.openCount",
          "RiskMitigationStatus.closedCount",
          "RiskMitigationStatus.inProgressCount",
          "RiskMitigationStatus.mitigationCount",
        ],
        dimensions: [
          "RiskMitigationStatus.riskType",
          "RiskMitigationStatus.severity",
        ],
        order: {
          "RiskMitigationStatus.toDoCount": "desc",
        },
      },
    },
    size: 5,
    row: "medium-row",
  },
  {
    id: 2,
    name: "Threat By Risk Level",
    type: "RiskLevel",
    vizState: {
      chartType: "threatRiskLevelWidget",
      query: {
        measures: ["ThreatsByRiskLevel.threatCount"],
        dimensions: ["ThreatsByRiskLevel.riskLevel"],
        order: {
          "ThreatsByRiskLevel.threatCount": "desc",
        },
      },
    },
    size: 3,
    row: "medium-row",
  },
  //Feature is disabled for now
  // {
  //   id: 3,
  //   name: "Risk Aging",
  //   type: "RiskAging",
  //   vizState: {
  //     chartType: "riskAgingWidget",
  //     query: {
  //       measures: [
  //         "RiskAging.toDoCount",
  //         "RiskAging.openCount",
  //         "RiskAging.inProgressCount",
  //       ],
  //       dimensions: [
  //         "RiskAging.riskId",
  //         "RiskAging.riskType",
  //         "RiskAging.createdTime",
  //         "RiskAging.oldestRisk",
  //       ],
  //       order: {
  //         "RiskAging.toDoCount": "desc",
  //       },
  //     },
  //   },
  //   size: 3,
  //   row: "medium-row",
  // },
  {
    id: 3,
    name: "Mitigation Priority",
    type: "Risk Type",
    vizState: {
      chartType: "mitigationPriorityWidget",
      query: {
        measures: ["Mitigation.mitigationCount"],
        dimensions: ["Mitigation.priority"],
        timeDimensions: [],
        order: {
          "Mitigation.mitigationCount": "desc",
        },
      },
    },
    row: "mdrThirdRowAuthentication",
    size: 3,
  },
  {
    id: 4,
    name: "Mitigation Status",
    type: "Risk Status",
    vizState: {
      chartType: "mitigationStatusWidget",
      query: {
        measures: ["Mitigation.mitigationCount"],
        dimensions: ["Mitigation.status"],
        timeDimensions: [],
        order: {
          "Mitigation.mitigationCount": "desc",
        },
      },
    },
    row: "mdrThirdRowAuthentication",
    size: 3,
  },
  {
    id: 5,
    name: "Mitigation Owner",
    type: "Risk Owner",
    vizState: {
      chartType: "mitigationOwnerWidget",
      query: {
        measures: ["Mitigation.mitigationCount"],
        dimensions: ["Mitigation.riskOwner"],
        timeDimensions: [],
        order: {
          "Mitigation.mitigationCount": "desc",
        },
      },
    },
    row: "mdrThirdRowAuthentication",
    size: 6,
  },
];
