import {
  SET_TENANT_PROFILE_LOADER,
  SET_TENANT_PROFILE_SNACKBAR,
} from "../constants/constants";

const initialState = {
  loader: false,
  snackBar: false,
  snackBarMessage: "",
};

export const TenantProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TENANT_PROFILE_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case SET_TENANT_PROFILE_SNACKBAR: {
      return {
        ...state,
        snackBar: action.payload.status,
        snackBarMessage: action.payload.message,
      };
    }
    default:
      return state;
  }
};
