import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./IncidentTableStyles.scss";
import { useSelector } from "react-redux";

import { getCubejsApi } from "../../../common/Dashboard/cubejs-util";
import { getFilterTimeStamp } from "../../../common/utilities"

import {
  UPDATE_INCIDENTPRIORITY_FILTER_DATA_ACTION,
  UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA_ACTION,
  UPDATE_INCIDENT_COUNTRY_FILTER_DATA_ACTION,
  UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA_ACTION,
  UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA_ACTION
} from "../../../../redux/actions/MDRActions/IncidentDashboardAction";

import { GET_AUTHENTICATION_SOURCE_FILTER_ACTION } from "../../../../redux/actions/MDRActions/AuthenticationDashboardAction";

import {
  UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA_ACTION,
  UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA_ACTION,
} from "../../../../redux/actions/MDRActions/MDREndPointsAction";

import {
  UPDATE_PATCHMONITORING_CLASSFICATION_FILTERDATA_ACTION,
  UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA_ACTION,
  UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA_ACTION,
  UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA_ACTION,
} from "../../../../redux/actions/MDRActions/PatchMonitoringAction";
import {
  incidentTableFilters,
  incidentTableFilter_Query_toGet_FilterValues,
  AuthenticationTableFilter_Query_toGet_FilterValues,
  endPoints_Query_toGet_FilterValues,
  patchMonitoring_Query_toGet_FilterValues,
} from "../../../../redux/constants/constants";

const TableFiltersLoad = ({ currentUser }) => {
  const cubejsApi = getCubejsApi();
  const dispatch = useDispatch();
  const dateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );
  
  useEffect(() => {
    const {startDt, endDt} = getFilterTimeStamp(dateTimeRange);
    const patchMonitoring_Query_cache = patchMonitoring_Query_toGet_FilterValues(startDt, endDt);
    const endPoints_Query_cache = endPoints_Query_toGet_FilterValues(startDt, endDt);
    const AuthenticationTableFilter_Query_cache = AuthenticationTableFilter_Query_toGet_FilterValues(startDt, endDt);
    const incidentTableFilter_Query_cache = incidentTableFilter_Query_toGet_FilterValues(startDt, endDt);
    async function fetchMDRFilterContent(query, action, filterType) {
      let resultSet = await cubejsApi.load(query);
      const response = resultSet.rawData();
      dispatch(
        action({
          filterType: filterType,
          data: response,
          addIsSelected: true,
          dimensions: query.dimensions[0],
        })
      );
    }

    if (currentUser === "Incidents") {
      fetchMDRFilterContent(
        incidentTableFilter_Query_cache.priorityFilter,
        UPDATE_INCIDENTPRIORITY_FILTER_DATA_ACTION,
        incidentTableFilters.Priority
      );
      fetchMDRFilterContent(
        incidentTableFilter_Query_cache.assigneeFilter,
        UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA_ACTION,
        incidentTableFilters.Assignee
      );
      fetchMDRFilterContent(
        incidentTableFilter_Query_cache.effectiveStatusFilter,
        UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA_ACTION,
        incidentTableFilters.effectiveStatusFilter
      );
      fetchMDRFilterContent(
        incidentTableFilter_Query_cache.stateDetailsFilter,
        UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA_ACTION,
        incidentTableFilters.StateDetails
      );
      fetchMDRFilterContent(
        incidentTableFilter_Query_cache.countryFilter,
        UPDATE_INCIDENT_COUNTRY_FILTER_DATA_ACTION,
        incidentTableFilters.City
      );
    } else if (currentUser === "Authentication") {
      fetchMDRFilterContent(
        AuthenticationTableFilter_Query_cache.source,
        GET_AUTHENTICATION_SOURCE_FILTER_ACTION
      );
    } else if (currentUser === "Endpoints") {
      fetchMDRFilterContent(
        endPoints_Query_cache.environmentFilter,
        UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA_ACTION
      );

      fetchMDRFilterContent(
        endPoints_Query_cache.osDescriptionFilter,
        UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA_ACTION
      );
    } else if (currentUser === "Patch Monitoring") {
      fetchMDRFilterContent(
        patchMonitoring_Query_cache.ClassificationFilter,
        UPDATE_PATCHMONITORING_CLASSFICATION_FILTERDATA_ACTION
      );
      fetchMDRFilterContent(
        patchMonitoring_Query_cache.SeverityFilter,
        UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA_ACTION
      );

      fetchMDRFilterContent(
        patchMonitoring_Query_cache.UpdateStateFilter,
        UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA_ACTION
      );
      fetchMDRFilterContent(
        patchMonitoring_Query_cache.SubscriptionNameFilter,
        UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA_ACTION
      );
    }
  }, [currentUser, dateTimeRange]);

  return <></>;
};

export default React.memo(TableFiltersLoad);
