import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SortIcon from "../../../assets/vizr_images/ic_sort-1.svg";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import FilterComponent from "./filterComponent";
import SortComponent from "../../shared/sortComponent";
import ClearFilter from "../../shared/clearFilter";
import Popper from "@material-ui/core/Popper";
import LogViewFilter from "./filterViewPanel";
import ImageTooltip from "../../common/HelpIconWithTooltip";
import {
  NewPolicyButtonForToolBar,
  CreateUserButton,
} from "../../common/buttons";
import { fade, makeStyles } from "@material-ui/core/styles";
import FlaggedLogViewFilter from "./flaggedFilterViewPanel.js";
import FlaggedFilterComponent from "./flaggedFilterComponent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { isEmpty } from "lodash";
import CanAllow from "../../shared/CanAllow";
import { UPDATE_ENTERPRISE_ACTORS } from "../../../redux/constants/constants";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "300px",
    height: "75px",
    boxShadow: "0 5px 8px 0px rgba(20, 18, 121, 0.5)",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      // width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    top: 0,
    right: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "default",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const EnhancedDataSourceHeader = (props) => {
  const classes = useToolbarStyles();
  const {
    totalElements,
    loc,
    title,
    rows,
    helpDescription,
    sortBy,
    setBatchEdit,
    selectedRows,
    location,
    hideAction,
    history,
    show,
    roleName
  } = props;

  const flaggedFilterApplied = useSelector((state) => state.flaggedDataSourceReducer.filterApplied);
  const normalFilterApplied = useSelector((state) => state.normalDataSourceReducer.filterApplied);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const dispatch = useDispatch();

  const handleClick = (event, newPlacement) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  //To disable the filter with respective to their location
  const handleFilter = () => {
    if ((loc === "Flagged_database_Users" && flaggedFilterApplied) ||
      (loc === "database_Users" && normalFilterApplied) ||
      !isEmpty(rows)) {
      return false;
    } else {
      return true;
    }
  }

  const renderHeaderTitle = () => {
    return (
      <Grid style={{ display: "flex" }} item xs={6}>
        {!hideAction && selectedRows.length > 0 ? (
          <Typography
            className="title"
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {selectedRows.length} selected
          </Typography>
        ) : (
          ""
        )}
        <Typography
          className="title"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
        {!hideAction && (
          <p style={{ margin: "11px 5px 0px 0px", minWidth: "45px" }}>
            Total :
          </p>
        )}
        {!hideAction && <div className="count">{totalElements}</div>}
        {!hideAction && <ImageTooltip description={helpDescription} />}
      </Grid>
    );
  };

  const renderButtons = () => {
    return (
      <Grid item xs={6} style={{ float: "right", textAlign: "right" }}>
        {/* <span className={classes.search}>
          <InputBase
            placeholder="Search"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => {
              dispatch({
                type: "UPDATE_SEARCH_VALUE",
                payload: e.target.value,
              });
              if(e.target.value === ""){
                dispatch({
                  type: "GET_NORMAL_DATA_SOURCE_INFO_DATA"
                });
              }else{
                dispatch({
                  type: "GET_NORMAL_DATA_SOURCE_INFO_DATA_ON_SEARCH"
                });
              }
            }}
          />
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
        </span> */}
        {(location === "flaggedDataSource" && show ) && (
          <CanAllow needs={{permission: [UPDATE_ENTERPRISE_ACTORS]}}>
            <CreateUserButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                history.push(`/tenant/useraccessRights/createDataSourceUser`);
              }}
              className="min-margin table-buttons"
            >
              <AddCircleOutlineIcon style={{ padding: "3px" }} />
              Create Data Source User
            </CreateUserButton>
          </CanAllow>
        )}
        {!hideAction && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              color: "#081981",
              backgroundColor: "transparent",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
            }}
            disabled={handleFilter()}
            onClick={(event) => handleClick(event, "bottom")}
            startIcon={<img src={SortIcon} alt="sort-filter" />}
            className="min-margin table-buttons"
          >
            Sort & Filter
          </Button>
        )}
        {(!hideAction && location !== "flaggedDataSource" && (roleName === "Administrator" || roleName === "DataOwner")) && (
          <CanAllow needs={{permission: [UPDATE_ENTERPRISE_ACTORS]}}>
            <NewPolicyButtonForToolBar
              variant="contained"
              color="primary"
              size="small"
              className="min-margin"
              onClick={(event) => {
                if (selectedRows.length > 0) {
                  setBatchEdit(event.currentTarget);
                } else {
                  alert("Please select multiple rows");
                }
              }}
              disabled={handleFilter()}
            >
              Batch Edit
            </NewPolicyButtonForToolBar>
          </CanAllow>
        )}
        {renderPopover()}
      </Grid>
    );
  };

  const clearFilterButtonClick = () => {
    if (location === "flaggedDataSource") {
      dispatch({ type: "DATA_FLAGGED_SOURCE_CLEAR_FILTER_DATA" });
      setOpen(false);
    } else {
      dispatch({ type: "DATA_SOURCE_CLEAR_FILTER_DATA" });
      setOpen(false);
    }
  };

  const renderPopover = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{
          boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
          marginTop: "10rem",
          marginRight: "1rem",
          zIndex: "10",
        }}
      >
        {({ TransitionProps }) => (
          <>
            <div className="clear-filter-container">
              <ClearFilter
                className="clear-filter"
                clearFilterButtonClick={clearFilterButtonClick}
                toggle={(event, type) => handleClick(event, type)}
                loc={loc}
              />
            </div>
            <div className="popper-root" style={{ height: "742px" }}>
              <Fade {...TransitionProps} timeout={350}>
                <SortComponent sortBy={sortBy} loc={loc} />
              </Fade>
              <Fade {...TransitionProps} timeout={350}>
                <div style={{ width: "420px", height: "705px" }}>
                  {location !== "flaggedDataSource" && <FilterComponent />}
                  {location === "flaggedDataSource" && (
                    <FlaggedFilterComponent />
                  )}
                </div>
              </Fade>
              <div
                style={{
                  minheight: "350px",

                  width: "400px",
                  display: "flex",
                  flexDirection: "column",
                  // marginTop: "2rem",
                  paddingLeft: "1.5rem",
                  overflow: "hidden auto",
                }}
              >
                {location !== "flaggedDataSource" && <LogViewFilter />}
                {location === "flaggedDataSource" && <FlaggedLogViewFilter />}
              </div>
              {/* </Fade> */}
            </div>
          </>
        )}
      </Popper>
    );
  };

  const renderGrid = () => {
    return (
      <Grid container spacing={3}>
        {renderHeaderTitle()}
        {renderButtons()}
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          {renderGrid()}
        </Grid>
      </Toolbar>
    </Paper>
  );
};

EnhancedDataSourceHeader.propTypes = {
  totalElements: PropTypes.number.isRequired,
};
