import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SortIcon from "../../../../assets/vizr_images/ic_sort-1.svg";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import FilterComponent from "./filterComponent";
import SortComponent from "../../../shared/sortComponent";
import ClearFilter from "../../../shared/clearFilter";
import Popper from "@material-ui/core/Popper";
import LogViewFilter from "./FilterViewPanel";
import ImageTooltip from "../../../common/HelpIconWithTooltip";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import { clearURLParam } from "../../../common/utilities";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
}));

export const EnhancedDirectDataSourceHeader = (props) => {
  const classes = useToolbarStyles();
  const {
    totalElements,
    loc,
    rows,
    title,
    helpDescription,
  } = props;

  const filterApplied = useSelector((state) => state.normalDirectDataSourceReducer.filterApplied)
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = (event, newPlacement) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  //To disable filter when there is no data and filter is not applied
  const handleFilter = () => {
    if (filterApplied || !isEmpty(rows)) {
      return false
    } else {
      return true
    }
  }

  const renderHeaderTitle = () => {
    return (
      <Grid style={{ display: "flex" }} item xs={6}>
        <Typography
          className="title"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
        <p style={{ margin: "11px 5px 0px 0px", minWidth: "45px" }}>Total :</p>
        <div className="count">{totalElements}</div>
        <ImageTooltip description={helpDescription} />
      </Grid>
    );
  };
  
  const renderButtons = () => {
    return (
      <Grid item xs={6} style={{ float: "right", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            color: "#081981",
            backgroundColor: "transparent",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
          }}
          disabled={handleFilter()}
          onClick={(event) => handleClick(event, "bottom")}
          startIcon={<img src={SortIcon} alt="sort-filter" />}
          className="min-margin table-buttons"
        >
          Sort & Filter
        </Button>
        {renderPopover()}
      </Grid>
    );
  };

  const clearFilterButtonClick = () => {
    dispatch({ type: "DIRECT_DATA_SOURCE_CLEAR_FILTER_DATA" });
    setOpen(false);
    clearURLParam(history, true, dispatch);
  };

  const renderPopover = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{
          boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
          marginTop: "10rem",
          marginRight: "1rem",
          zIndex: "10",
        }}
      >
        {({ TransitionProps }) => (
          <>
            <div className="clear-filter-container">
              <ClearFilter
                className="clear-filter"
                clearFilterButtonClick={clearFilterButtonClick}
                toggle={(event, type) => handleClick(event, type)}
                loc={loc}
              />
            </div>
            <div className="popper-root" style={{ height: "742px" }}>
              <Fade {...TransitionProps} timeout={350}>
                <SortComponent loc={loc} />
              </Fade>
              <Fade {...TransitionProps} timeout={350}>
                <div style={{ width: "420px", height: "705px" }}>
                  <FilterComponent />
                </div>
              </Fade>
              <div
                style={{
                  minheight: "350px",

                  width: "400px",
                  display: "flex",
                  flexDirection: "column",
                  // marginTop: "2rem",
                  paddingLeft: "1.5rem",
                  overflow: "hidden auto",
                }}
              >
                <LogViewFilter />
              </div>
              {/* </Fade> */}
            </div>
          </>
        )}
      </Popper>
    );
  };

  const renderGrid = () => {
    return (
      <Grid container spacing={3}>
        {renderHeaderTitle()}
        {renderButtons()}
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          {renderGrid()}
        </Grid>
      </Toolbar>
    </Paper>
  );
};

EnhancedDirectDataSourceHeader.propTypes = {
  totalElements: PropTypes.number.isRequired,
};
