import { GET_AGGREGATION_TYPES_URL} from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function getTriggerAggregationTypes() {
    const options = {
        method: "GET",
        url: `${GET_AGGREGATION_TYPES_URL}`,
        headers: getAxiosHeaders(true)
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};
