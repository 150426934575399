import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import "../../assets/scss/admin-styles/custom/loginpagestyle.scss";
import "../../assets/scss/signuppage-styles/signuppage.scss";
import "../../assets/scss/BackOfficeStyles/Common.scss";
import { isArguments } from "lodash";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(6),
    },
  },
}))(InputBase);

const Input = ({
  required,
  type,
  labelName,
  placeholder,
  classname,
  value,
  eventHandler,
  containerName,
  errors,
  labelClassName,
  helpertext,
  domainName,
  disabledCondition,
  onKeyPress
}) => {
  let arg = {};
  return (
    <div className={containerName ? containerName : ""}>
      <h3
        className={
          classname === "signinpage"
            ? "margin"
            : labelClassName
            ? labelClassName
            : null
        }
      >
        {labelName} {required ? <span style ={{color:"red"}}>*</span> : ""}
      </h3>
      <div style={{ display: "flex" }}>
        <BootstrapInput
          error
          placeholder={placeholder}
          type={type}
          disabled={disabledCondition}
          className={classname !== "signinpage" ? classname : "email-input"}
          value={value}
          onChange={
            eventHandler ? (event) => eventHandler(event.target.value) : null
          }
          onKeyUp={onKeyPress ? (event) => onKeyPress(event) : () =>{}}
          {...arg}
        />
        {domainName ? (
          <p
            style={{
              marginTop: "20px",
              marginLeft: "20px",
              width: "100%",
              wordWrap: "break-word",
              whiteSpace: "initial",
              overflow: "hidden",
            }}
          >
            {domainName}
          </p>
        ) : (
          ""
        )}
      </div>

      {errors ? (
        <p style={{ color: "#ff2251", marginTop: "-0.4rem" }}>{helpertext}</p>
      ) : null}
    </div>
  );
};

export default Input;
