import {
  PLATFORM_ADMIN_TENANT_ONBOARD_REQUEST,
  PLATFORM_ADMIN_TENANT_ONBOARD_SUCCESS,
  PLATFORM_ADMIN_TENANT_ONBOARD_FAILED,
} from "../constants/constants";

export const PlatformAdminTenantOnboard = (value) => {
  console.log(value);
  return {
    type: PLATFORM_ADMIN_TENANT_ONBOARD_REQUEST,
    payload: value,
  };
};
export const PlatformAdminTenantOnboardSuccess = (value) => {
  return {
    type: PLATFORM_ADMIN_TENANT_ONBOARD_SUCCESS,
    payload: value,
  };
};
export const PlatformAdminTenantOnboardFail = (value) => {
  return {
    type: PLATFORM_ADMIN_TENANT_ONBOARD_FAILED,
    payload: value,
  };
};
