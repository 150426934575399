import {
  GET_ALERTS,
  GET_ALERTS_URL,
  token,
  DATASOURCE_URL,
  APPLICATIONS_URL,
  DATASOURCE_URL2,
  UPDATE_LOGSOURCE
} from "../constants/constants";
//import axios from 'axios';
import { getService, getAxiosHeaders } from "../../library/RestAPI";
//dispatch add method when use of Saga
export async function fetchAllAlerts(page,pageSize) {
  const options = {
    method: "GET",
    url: `${GET_ALERTS_URL}?size=${pageSize}&page=${page}&status=UNRESOLVED`,
    headers: getAxiosHeaders(true),
    data: {},
  };
  // getService(options).then(res => {
  //     dispatch({
  //         type: GET_ALERTS,
  //         data: res.data
  //     })
  // })
  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fetchDataSource(id,currentPage,recordsPerPage) {
  const options = {
    method: "GET",
    url: `${DATASOURCE_URL}/?applicationId=${id}&size=${recordsPerPage}&page=${currentPage}`,
    headers: getAxiosHeaders(true),
    data: {},
  };
  const response = await getService(options);
  if (response && response.status === 200) {
      return response.data;
    }else{
      return response.data
    }
}

export async function fetchAllDataSources(currentPage,recordsPerPage) {
  const options = {
    method: "GET",
    url: `${DATASOURCE_URL}?size=${recordsPerPage}&page=${currentPage}`,
    headers: getAxiosHeaders(true),
    data: {},
  };
  const response = await getService(options);
  if (response && response.status === 200) {
      return response.data;
    }else{
      return "Error Fetching Data."
    }
}

export async function fetchUpdatedDataSources(id, status) {
  const options = {
    method: "PUT",
    url: `${DATASOURCE_URL}/${id}/status/${status}`,
    headers: getAxiosHeaders(true),
    data: {},
  };
  const response = await getService(options);
  if (response && response.status === 200) {
      return response.data;
    }else{
      return "An error occurred while updating, please try again."
    }
}

export async function fetchUpdatedLogSources(id, status) {
  const options = {
    method: "PUT",
    url: `${UPDATE_LOGSOURCE}/${id}/status/${status}`,
    headers: getAxiosHeaders(true),
    data: {},
  };
  const response = await getService(options);
  if (response && response.status === 200) {
      return response.data;
    }else{
      return "An error occurred while updating, please try again."
    }
}

export async function fetchApplicationData() {
  const options = {
    method: "GET",
    url: DATASOURCE_URL2,
    headers: getAxiosHeaders(true,),
  };
  // getService(options).then(res => {
  //     dispatch({
  //         type: GET_ALERTS,
  //         data: res.data
  //     })
  // })
  const response = await getService(options);
  if (response) {
    return response.data;
  }
}
