import { call, put, takeEvery } from "redux-saga/effects";
import { PLATFORM_ADMIN_TENANT_UPDATE_REQUEST } from "../constants/constants";
import { PLATFORM_ADMIN_TENANT_UPDATE_URL } from '../constants/constants';
import { PlatformAdminTenantUpdateFail, PlatformAdminTenantUpdateSuccess} from '../actions/platformAdminTenantUpdateAction';
import {getAxiosHeaders, getService} from "../../library/RestAPI";

function* PlatformAdminTenantUpdate(action) {
    console.log(action)
    let options;
    yield console.log("Hello from TenantSignUpSaga");
    try {
      let myObj = JSON.stringify(action.payload.tenantDetails);
      const formData = new FormData();
      const logo = action.payload.image ? action.payload.image : "";
      formData.append("payload", myObj);
      formData.append("logo", logo);
      options = {
        method: "PUT",
        url: `${PLATFORM_ADMIN_TENANT_UPDATE_URL}/${action.payload.id}`,
        headers: getAxiosHeaders(true,"multipart/form-data"),
        data: formData,
      };
      try{
        const res = yield getService(options);
          if(res && res.status === 200) {
            yield put(PlatformAdminTenantUpdateSuccess());
            yield put({type: "TRACK_TRAVERSAL" , payload: 'Tenant Management'})
            action.payload.history.push('/platform/tenantManagement')
          } else {
            yield put(PlatformAdminTenantUpdateFail());
          }
      }
      catch(e){
          yield put(PlatformAdminTenantUpdateFail());
      }
    } catch (e) {
      yield put(PlatformAdminTenantUpdateFail());
    }
  }
  
  function* PlatformAdminTenantUpdateSaga() {
    yield takeEvery(PLATFORM_ADMIN_TENANT_UPDATE_REQUEST, PlatformAdminTenantUpdate);
  }
  
  export default PlatformAdminTenantUpdateSaga;