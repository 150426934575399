import React from "react";
import { Chart } from "react-google-charts";
import ChartWrapper from "../Chart";
import {
  SERVICE_USERS_WITH_HIGH_ALERTS,
  SERVICE_USERS,
} from "../../../../redux/constants/constants";
import { dateFormatter } from "../utils";
import { isEmpty } from "lodash";

export let serviceUsersWithAlertsBarChart = (
  cubejsApi,
  GraphName,
  LabelName
) => {
  // The Optimized query to get both count of Service Users and Service Users with High Alerts
  let totalUsersQuery = {
    measures: [],

    timeDimensions: [
      {
        dimension: "ServiceUsers.userDate",
        granularity: "day",
      },
    ],
    segments: [],
    dimensions: ["ServiceUsers.userCount", "ServiceUsers.userHighAlertCount"],
    filters: [],
  };
//This function is to render the Bar Graph for Service Users v/s Service Users with High Alerts
  let renderMultiBarChart = (results) => {
    let chartData = [];
    let userCount = 0;
    let serviceUsersData = results.loadResponse.results[0].data
    if (!isEmpty(serviceUsersData)) {
      chartData.push(["Date", SERVICE_USERS_WITH_HIGH_ALERTS, LabelName || SERVICE_USERS]);
      serviceUsersData.forEach((row) => {
        userCount = userCount + parseInt(row["ServiceUsers.userCount"]); // to add the count of service users from multple dates
        chartData.push([
          dateFormatter(row["ServiceUsers.userDate.day"]),
          parseInt(row["ServiceUsers.userHighAlertCount"]),
          userCount,
        ]);
      });
      return (
        <Chart
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          data={chartData}
          options={{
            colors: ["#FF2291", "#91DFFF"],
          }}
        />
      );
    } else {
      return (
        <div className="noDataAvailable">No Data Available</div>
      );
    }
  };
  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={GraphName}
      query={totalUsersQuery}
      render={renderMultiBarChart}
    />
  );
};
