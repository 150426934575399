import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import "./RiskGovernance.scss";
import DeleteRRIcon from "../../../assets/icons/DeleteRR.svg";
import arrowDropUpCircle from "../../../assets/icons/arrowDropUpCircle.svg";
import arrowDropDownCircle from "../../../assets/icons/arrowDropDownCircle.svg";
import { renderHeading } from "./riskPilotHelperComp";
import { useDispatch, useSelector } from "react-redux";
import { handleAddThreat, handleDeleteThreat } from "./riskPilotUtilities";
import RiskPilotThreatComp from "./RiskPilotThreatComp";
import { isEmpty } from "lodash";

const useStyles = makeStyles(() => ({
  customThreatButton: {
    height: "0px",
    minHeight: "26px",
    minWidth: "10px",
  }
}));

const RiskRegisterThreats = ({
  renderMitigation
}) => {
  // State for managing accordion expansions
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedThreatId = useSelector((state) => state.riskPilotReducer.selectedRowId.threatId);
  const selectedVulnerabilityId = useSelector((state) => state.riskPilotReducer.selectedRowId.vulnerabilityId);
  const selectedMitigationId = useSelector((state) => state.riskPilotReducer.selectedRowId.mitigationId);
  const [threatExpanded, setThreatExpanded] = useState({});
  const [vulnerabilityExpanded, setVulnerabilityExpanded] = useState({});
  const [mitigationExpanded, setMitigationExpanded] = useState({});

  const threatData = useSelector((state) => state.threatReducer.threats);

  // Handlers for accordion expansions
  const handleThreatAccordionChange = (id) => {
    setThreatExpanded({ ...threatExpanded, [id]: !threatExpanded[id] });
  };

  const handleVulnerabilityAccordionChange = (id) => {
    setVulnerabilityExpanded({
      ...vulnerabilityExpanded,
      [id]: !vulnerabilityExpanded[id],
    });
  };

  const handleMitigationAccordionChange = (id) => {
    setMitigationExpanded({
      ...mitigationExpanded,
      [id]: !mitigationExpanded[id],
    });
  };

  useEffect(() => {
    const handleAccordionChange = () => {
      if (!isEmpty(selectedThreatId)) {
        handleThreatAccordionChange(selectedThreatId);
      }
      if (!isEmpty(selectedVulnerabilityId)) {
        handleVulnerabilityAccordionChange(selectedVulnerabilityId);
      }
      if (!isEmpty(selectedMitigationId)) {
        handleMitigationAccordionChange(selectedMitigationId);
      }
    };

    handleAccordionChange();
  }, [selectedThreatId, selectedVulnerabilityId, selectedMitigationId]);
  

  const renderThreatHeading = (index, value) => {
    return (
      <div className="riskThreatHeading">
        {threatExpanded[index] ? (
          <img src={arrowDropUpCircle} alt="arrow up" />
        ) : (
          <img src={arrowDropDownCircle} alt="arrow down" />
        )}
        <img
          className="multiThreatIconCustomSizes threatIconCustomSize"
          src="/riskPilotIcons/ThreatIcon.svg"
        />
        {renderHeading(`Threat:  ${value}`, "threat")}
      </div>
    );
  };

  const renderThreatDeleteButton = (threatId) => {
    return (
      <div>
        <Button
          color="secondary"
          onClick={() =>
            handleDeleteThreat(threatId, dispatch)
          }
        >
          <img src={DeleteRRIcon} alt="delete icon" />
        </Button>
      </div>
    );
  };

  return (
    <div className="riskContainer threats">
      {/* Container for adding new threat */}
      {
        <div className="riskThreatContainer">
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            <div className="riskAddThreatContainer">
              <img
                className="riskSummaryImageSize"
                src="/riskPilotIcons/ThreatIcon.svg"
              />
              Threat
            </div>
          </Typography>
          <Button
            className={classes.customThreatButton}
            variant="contained"
            color="primary"
            onClick={() => handleAddThreat(dispatch, threatData ? threatData : {})}
          >
            +
          </Button>
        </div>
      }

      {/* Accordion for threat data */}
      <div className="riskRegisterAccordion">
        {
          <RiskPilotThreatComp
            handleThreatAccordionChange={handleThreatAccordionChange}
            renderThreatHeading={renderThreatHeading}
            renderThreatDeleteButton={renderThreatDeleteButton}
            vulnerabilityExpanded={vulnerabilityExpanded}
            handleVulnerabilityAccordionChange={
              handleVulnerabilityAccordionChange
            }
            renderMitigation={renderMitigation}
            mitigationExpanded={mitigationExpanded}
            handleMitigationAccordionChange={handleMitigationAccordionChange}
            renderHeading={renderHeading}
            threatExpanded={threatExpanded}
          />
        }
      </div>
    </div>
  );
};

export default RiskRegisterThreats;
