import React from "react";
import LoginbackGround from "../../../components/views/LoginContainer/LoginbackGround";
import ForgotpasswordDes from "../../../components/views/ForgotPassword/ForgotpasswordDes";
// import CommonContainer from "../../../components/views/ForgotPassword/CommonContainer";

const ForgotPassword = ({ setPassword }) => {
  console.log(setPassword);
  return (
    <LoginbackGround
      log={<ForgotpasswordDes setPassword={setPassword}></ForgotpasswordDes>}
    ></LoginbackGround>
  );
};

export default ForgotPassword;
