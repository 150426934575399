import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { DOWNLOAD_AGENT } from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import page_loader from "../../../assets/vizr_images/page_loader.gif";
import Snackbar from "../../shared/Snackbar";
const AgentDownload = () => {
  const [downloadstatus, setDownloadstatus] = useState(false);
  const [downloadErrorMessage, setDownloadErrorMessage] = useState("");

  const { search } = useLocation();
  useEffect(() => {
    const obj = queryString.parse(search);
    const { fileName } = obj;

    const downloadAgent = async (fileName) => {
      const res = await getService({
        method: "GET",
        url: DOWNLOAD_AGENT(fileName ? fileName : "testupload-2.jar"),
        headers: getAxiosHeaders(true),
      });
      console.log(res);
      if (res.status === 200) {
        setDownloadstatus(true);
        let data = res.data;
        let downloadfileName = fileName ? fileName : "testupload-2.jar";
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let blob = new Blob([data], { type: "application/java-archive" }),
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = downloadfileName;
        a.click();
        window.URL.revokeObjectURL(url);
        // let jarFile = new Blob([res.data], {
        //   type: "application/java-archive",
        // });
        // let jarFileUrl = URL.createObjectURL(jarFile);
        // window.open(jarFileUrl);
      } else {
        setDownloadstatus(false);
        setDownloadErrorMessage("Agent download failed please try again");
        console.log("download failed");
      }
    };

    downloadAgent(fileName);
  });
  return (
    <div
      style={{
        marginLeft: "15%",
        marginTop: "5rem",
        height: "125vh",
        textAlign: "center",
        //fontFamily: "Nunito",
        fontSize: "16px",
        letterSpacing: "0",
      }}
    >
      {/*  <Snackbar
        snackBarMessage="Agent downloaded successfully"
        success
      ></Snackbar>*/}

      {!downloadstatus ? (
        <div style={{ marginBottom: "2rem" }}>
          Please wait while we download the agent
        </div>
      ) : (
        ""
      )}

      {!downloadstatus ? (
        <img src={page_loader} alt="loader"></img>
      ) : (
        <div>
          {downloadstatus && !downloadErrorMessage
            ? "Download Completed Successfully"
            : downloadErrorMessage}
        </div>
      )}
    </div>
  );
};

export default AgentDownload;
