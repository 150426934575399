import {
  SET_DATA_OWNER_FIELDS_DATA,
  SET_HEADER_DATA,
  CLEAR_DATA_OWNER_FIELDS,
  SET_DATA_OWNER_PAGE_SIZE,
  SET_DATA_OWNER_CURRENT_PAGE,
  SET_TOTAL_DATA_SOURCE_COUNT
} from '../constants/constants';


const initialState = {
  dataOwnerHeaderData: [],
  DataOwnerFieldsData: [],
  currentPage: 0,
  pageSize: 20,
  totalDataSourcesCount:0,
}


export const DataOwnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTAL_DATA_SOURCE_COUNT: {
      return {
        ...state,
        totalDataSourcesCount: action.payload
      }
    }
    case SET_DATA_OWNER_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      };
    }
    case SET_DATA_OWNER_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload
      };
    }
    case SET_DATA_OWNER_FIELDS_DATA: {
      return {
        ...state,
        DataOwnerFieldsData: action.payload
      }
    }
    case SET_HEADER_DATA: {
      return {
        ...state,
        dataOwnerHeaderData: action.payload
      }
    }
    case CLEAR_DATA_OWNER_FIELDS: {
      return {
        DataOwnerFieldsData:[],
        dataOwnerHeaderData:[],
        currentPage: 0,
        pageSize: 20,
        totalDataSourcesCount:0,
      }
    }
    default:
      return state;
  }
}