import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../assets/vizr_images/white_ic_remove.svg";
import EditIcon from "../../assets/vizr_images/editTenant.svg";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import { Button, Input } from "@material-ui/core";
import {
  LOGO_FORMAT_ERROR_MESSAGE,
  LOGO_SIZE_ERROR_MESSAGE,
  SET_TENANT_PROFILE_SNACKBAR,
  TENANT_LOGO_SIZE,
  TENANT_NAME_ERROR_MESSAGE,
  UPDATE_TENANT_PROFILE,
} from "../../redux/constants/constants";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

function TenantProfile(props) {
  const dispatch = useDispatch();
  const snackBar = useSelector((state) => state.TenantProfileReducer.snackBar);
  const snackBarMessage = useSelector(
    (state) => state.TenantProfileReducer.snackBarMessage
  );
  const loader = useSelector((state) => state.TenantProfileReducer.loader);
  const { handleEditTenant } = props;
  const inputFile = useRef(null);
  const tenantNameDetails = JSON.parse(localStorage.getItem("tenantName"));
  const tenantId = JSON.parse(localStorage.getItem("featuresInfo")).tenantId;
  const tenantLogo = localStorage.getItem("tenantLogo");
  const [tenantName, setTenantName] = useState();
  const [file, setFile] = useState();
  const [fileUpload, setFileUpload] = useState();
  const [openError, setopenError] = useState(false);
  const [errorMessage, setErrorMesage] = useState("");

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenError(false);
    dispatch({
      type: SET_TENANT_PROFILE_SNACKBAR,
      payload: { status: false, message: "" },
    });
  };

  useEffect(() => {
    setTenantName(tenantNameDetails);
    if (tenantLogo && !isEmpty(tenantLogo)) {
      setFile(`data:image/png;base64,${tenantLogo}`);
    }
  }, []);
  useEffect(() => {
    setopenError(snackBar);
    setErrorMesage(snackBarMessage);
  }, [snackBar]);

  const handleUploadImage = (event) => {
    event.preventDefault();
    inputFile.current.click();
    handleSnackbarClose();
  };

  const imageHandler = (event) => {
    const requiredFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (event.target.files[0]) {
      if (event.target.files[0].size / 1000 > TENANT_LOGO_SIZE) {
        setErrorMesage(LOGO_SIZE_ERROR_MESSAGE);
        setopenError(true);
      } else if (!requiredFileTypes.includes(event.target.files[0].type)) {
        setErrorMesage(LOGO_FORMAT_ERROR_MESSAGE);
        setopenError(true);
      } else {
        setFile(URL.createObjectURL(event.target.files[0]));
        setFileUpload(event.target.files[0]);
      }
    }
  };
  const tenantNameValidation = (str) => {
    const pattern = /^[a-zA-Z0-9]+$/;
    return pattern.test(str);
  };

  const submitHandler = () => {
    if (!tenantNameValidation(tenantName)) {
      setopenError(true);
      setErrorMesage(TENANT_NAME_ERROR_MESSAGE);
    } else {
      const myObj = {
        tenantName: tenantName,
      };
      const formData = new FormData();
      const logo = fileUpload ? fileUpload : "";
      formData.append("payload", JSON.stringify(myObj));
      formData.append("logo", logo);
      dispatch({
        type: UPDATE_TENANT_PROFILE,
        payload: {
          tenantId: tenantId,
          formData: formData,
          handleEditTenant: handleEditTenant,
        },
      });
    }
  };

  const renderTenantLogo = () => {
    return (
      <div className="tenantLogo">
        {file ? (
          <div className="tenantLogoContainer">
            <img
              src={file}
              alt="Preview"
              className="tenantProfileLogo"
              onClick={handleUploadImage}
            />
          </div>
        ) : (
          <div className="userName" onClick={handleUploadImage}>
            {tenantNameDetails &&
              tenantNameDetails.split(" ").map((el) => {
                return el[0];
              })}
          </div>
        )}
        <input
          type="file"
          id="image-upload"
          accept="image/*"
          onChange={imageHandler}
          ref={inputFile}
          style={{ display: "none" }}
        />
        <div className="editPicture" onClick={handleUploadImage}>
          {isEmpty(tenantLogo) ? "Add Profile" : "Edit Profile"}
          <img className="editTenant" src={EditIcon} alt="editIcon" />
        </div>
      </div>
    );
  };
  
  const handleTenantName = (event) => {
    setTenantName(event.target.value);
    handleSnackbarClose();
  }

  const renderTenantNameComp = () => {
    return (
      <div>
        <p>Name</p>
        <div className="tenantEditInput">
          <Input
            value={tenantName}
            onChange={(event) => handleTenantName(event)}
            className="tenant-inputField"
          />
          <img className="editTenant" src={EditIcon} alt="editIcon" />
        </div>
        <Button
          className="tenantButton"
          onClick={() => {
            handleEditTenant();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isEmpty(tenantName)}
          className="tenantButton"
          onClick={() => submitHandler()}
        >
          Save
        </Button>
      </div>
    );
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className="error-snackbar"
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <div className="tenantProfile">
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source tenantProfileLoader"
        ></img>
      )}
      <div className="profileHeader">
        <div className="profileName">Tenant</div>
        <img
          className="closeIcon"
          src={CloseIcon}
          alt="closeIcon"
          onClick={() => {
            handleEditTenant();
          }}
        />
      </div>
      <hr />
      <div className="tenantEditFields">
        {renderTenantLogo()}
        {renderTenantNameComp()}
      </div>
      {renderSnackBar()}
    </div>
  );
}

export default TenantProfile;
