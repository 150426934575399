import { call, put, takeEvery } from "redux-saga/effects";
import { DELETE_POLICY_REQUEST } from "../constants/constants";
import { DELETE_POLICY_DETAIL_URL } from '../constants/constants';
import {getService, getAxiosHeaders} from "../../library/RestAPI";

function* deleteAlertPolicy(action) {
    let options
    var urlData = '';
    try {
        action.payload.ids.map((each) => {
            urlData = urlData + `${each},`
        })
        urlData = urlData.slice(0, -1)
        options = {
            method: "DELETE",
            url: `${DELETE_POLICY_DETAIL_URL}?alertPolicyIds=${urlData}`,
            headers: getAxiosHeaders(true),
        };
      try {
        const res = yield getService(options);
        if(res) {
            console.log(res)
          yield put({ type: "POLICY_DELETE_SUCCEEDED"});
        } else {
          yield put({
            type: "POLICY_DELETE_FAILED",
            payload: "Delete unsuccessful",
          });
        }
      } catch (error) {
        console.log(error);
        // myStorage.setItem("access_token", "error");
        yield put({
          type: "POLICY_DELETE_FAILED",
          payload: "Error Fetching Data from API",
        });
      }
    } catch (e) {
      yield put({ type: "POLICY_DELETE_FAILED" });
    }
  }
  
  
  function* deletePolicySaga() {
    yield takeEvery(DELETE_POLICY_REQUEST, deleteAlertPolicy);
  }
  
  export default deletePolicySaga;