import React from "react";
import {
  CREATE_DATA_SOURCE,
  DATASOURCE_URL2,
  GET_LOG_SERVICES,
  GET_LOG_SOURCE_DETAILS,
  GET_LOG_SOURCE_TYPE_DETAILS,
  GET_LOG_SOURCE_URL,
  SET_DATA_SOURCE_DETAILS,
  SET_SOURCE_ERROR_MESSAGE,
  SET_DATA_SOURCE_SAVED,
  SET_LOG_SOURCE_DATA,
  SET_LOG_SOURCE_DETAILS,
  SET_LOG_SOURCE_VERIFIED,
  SET_SOURCES_LOADER,
  UPDATE_DATASOURCE_SUCCESS,
  VERIFY_LOG_SOURCES,
  create_data_source,
  create_log_source,
  SAVE_LOG_SOURCE_DETAILS,
  SET_LOGSOURCE_SAVED,
  CLEAR_SOURCE_ERROR_MESSAGE,
  GET_AVAILABLE_REGIONS,
  getAvailableRegionsData,
  SET_DS_ERROR_MESSAGE,
} from "../constants/constants";
import { call, put, select, takeEvery, all } from "redux-saga/effects";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

function* createDataSource(action) {
  yield put({
    type: SET_SOURCES_LOADER,
    payload: true,
  });
  yield put({
    type: SET_DATA_SOURCE_DETAILS,
    payload: action.payload.data,
  });
  const options = {
    method: "POST",
    url: create_data_source(),
    headers: getAxiosHeaders(true),
    data: action.payload.data,
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({ type: UPDATE_DATASOURCE_SUCCESS, payload: res.data });
      yield put({ type: SET_DATA_SOURCE_SAVED, payload: true });
      action.payload.allDataSources.getAllDataSources();
      action.payload.toggleDrawer();
      yield put({
        type: SET_SOURCES_LOADER,
        payload: false,
      });
    } else {
      let errorMessage = "";
      if (res && res.response.data) {
         if (res.response.data.fieldErrors) {
          res.response.data.fieldErrors.map((error) => {
            errorMessage = errorMessage + error.errorMessage + "\n";
          });
         } else {
          errorMessage = res.response.data.errorMessage;
         }
        yield put({
          type: SET_DS_ERROR_MESSAGE,
          payload: errorMessage,
        });
        yield put({
          type: SET_SOURCES_LOADER,
          payload: false,
        });
      }
    }
  } catch (error) {}
}

const getDataService = (url) => {
  return getService({
    method: "GET",
    url: `${url}`,
    headers: getAxiosHeaders(true),
  });
};

function* getLogSourceTypeDetails() {
  yield put({
    type: SET_SOURCES_LOADER,
    payload: true,
  });
  try {
    const [logSourceDataRes, applicationDataRes, allRegionsRes] = yield all([
      call(getDataService, GET_LOG_SOURCE_URL),
      call(getDataService, DATASOURCE_URL2),
      call(getDataService, getAvailableRegionsData),
    ]);

    let combinedPayload = {};

    if (logSourceDataRes && logSourceDataRes.status === 200) {
      const logSourceData = logSourceDataRes.data;
      combinedPayload = {
        ...combinedPayload,
        engine: logSourceData.content ? logSourceData.content[0].code : "",
        types: logSourceData.content,
      };
    }

    if (applicationDataRes && applicationDataRes.status === 200) {
      const applicationData = applicationDataRes.data.content.map(
        (app) => app.name
      );
      combinedPayload = {
        ...combinedPayload,
        applications: applicationData,
        applicationName: applicationData ? applicationData[0] : "",
      };
    }

    if (allRegionsRes && allRegionsRes.status === 200) {
      const allRegionsData = allRegionsRes.data;
      combinedPayload = {
        ...combinedPayload,
        allRegions: allRegionsData,
      }
    }

    yield put({
      type: SET_LOG_SOURCE_DATA,
      payload: combinedPayload,
    });

    yield put({
      type: SET_SOURCES_LOADER,
      payload: false,
    });
  } catch (error) {
    // Handle error
    yield put({
      type: SET_SOURCES_LOADER,
      payload: false,
    });
  }
}

function* getLogSourceDetails() {
  const options = {
    method: "GET",
    url: `${GET_LOG_SERVICES}`,
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const logSources = res.data.content.map((item) => ({
        id: item.id,
        name: item.name,
        sourceType: item.sourceType,
      }));
      yield put({ type: SET_LOG_SOURCE_DETAILS, payload: logSources });
    } else {
    }
  } catch (error) {
    console.log("ERROR");
  }
}

function* verifyLogSource(action) {
  yield put({
    type: SET_SOURCES_LOADER,
    payload: true,
  });
  const options = {
    method: "POST",
    url: `${action.payload.url}`,
    headers: getAxiosHeaders(true),
    data: action.payload.request,
  };
  try {
    const res = yield getService(options);
    if (res && (res.status === 200 || res.status === 204)) {
      yield put({ type: SET_LOG_SOURCE_VERIFIED, payload: true });
      yield put({
        type: SET_SOURCES_LOADER,
        payload: false,
      });
    } else {
      if (res.response.status === 400) {
        yield put({type:CLEAR_SOURCE_ERROR_MESSAGE});
        let errorMessage = res.response.data?.fieldErrors?.[0]?.errorMessage || res.response.data?.errorMessage;
        if (errorMessage) {
          yield put({
            type: SET_SOURCE_ERROR_MESSAGE,
            payload: errorMessage,
          });
        }
      }
      yield put({
        type: SET_SOURCES_LOADER,
        payload: false,
      });
    }    
  } catch (error) {
    //add snackbar message
    const errorMessage = "Log source verification failed";
    yield put({
      type: SET_SOURCE_ERROR_MESSAGE,
      payload: errorMessage,
    });
    yield put({
      type: SET_SOURCES_LOADER,
      payload: false,
    });
  }
}

function* saveLogSource(action) {
  yield put({
    type: SET_SOURCES_LOADER,
    payload: true,
  });
  const options = {
    method: "POST",
    url: create_log_source(),
    headers: getAxiosHeaders(true),
    data: action.payload,
  };

  try {
    const response = yield getService(options);
    if (response && response.status === 200) {
      yield put({
        type: SET_LOGSOURCE_SAVED,
        payload: true,
      });
      yield put({
        type: SET_SOURCES_LOADER,
        payload: false,
      });
    } else {
      let errorMessage = "";
      if (response && response.response.data.fieldErrors) {
        response.response.data.fieldErrors.map((error) => {
          errorMessage = errorMessage + error.errorMessage + "\n";
        });
        yield put({
          type: SET_SOURCE_ERROR_MESSAGE,
          payload: errorMessage,
        });
        yield put({
          type: SET_SOURCES_LOADER,
          payload: false,
        });
      }
    }
  } catch (error) {
    yield put({
      type: SET_SOURCES_LOADER,
      payload: false,
    });
  }
};


function* createSourceSaga() {
  yield takeEvery(CREATE_DATA_SOURCE, createDataSource);
  yield takeEvery(GET_LOG_SOURCE_DETAILS, getLogSourceDetails);
  yield takeEvery(GET_LOG_SOURCE_TYPE_DETAILS, getLogSourceTypeDetails);
  yield takeEvery(VERIFY_LOG_SOURCES, verifyLogSource);
  yield takeEvery(SAVE_LOG_SOURCE_DETAILS, saveLogSource);
}
export default createSourceSaga;
