import { put, takeEvery, select } from "redux-saga/effects";
import {
  ACCESS_DETAILS,
  sortBy_Filter_AccessDetails_Percentage,
} from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import {
  createCustomObject_AccessDetails,
  createCustomObject_AccessDetails_afterGettingSensitivePercentage,
} from "../../components/views/userAccessRights/UserAccessRightsCommonFunctions";

function* normalDirectDataSourceApi() {
  let options;

  let data = yield select();
  const {
    currentPage,
    recordsPerPage,
    finalDataSourceName,
    finalDataSourceType,
    finalDatabaseAccessRoles,
    finalSortByValue,
    finalOrderByValue,
    finalDataSourceUsers,
    usersWithAlertsQueryParam,
  } = data.normalDirectDataSourceReducer;

  let paramData = {};
  if (finalSortByValue && finalSortByValue !== "") {
    paramData.sortBy = finalSortByValue;
  }
  if (finalOrderByValue && finalOrderByValue !== "") {
    paramData.direction = finalOrderByValue;
  }
  if (finalDataSourceName && finalDataSourceName.length > 0) {
    paramData.dataSourceNames = finalDataSourceName.join(",");
  }
  if (finalDataSourceType && finalDataSourceType.length > 0) {
    paramData.dataSourceTypes = finalDataSourceType.join(",");
  }
  if (finalDatabaseAccessRoles && finalDatabaseAccessRoles.length > 0) {
    paramData.databaseAccessRoles = finalDatabaseAccessRoles.join(",");
  }
  if((usersWithAlertsQueryParam && usersWithAlertsQueryParam !== "") || (finalDataSourceUsers && finalDataSourceUsers.length > 0)){
    paramData.status = "UNRESOLVED";
  }
  options = {
    method: "GET",
    url: `${ACCESS_DETAILS}?size=${recordsPerPage}&page=${currentPage}`,
    headers: getAxiosHeaders(true),
    params: paramData,
    data: {},
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      let customArr = [];
      let totalCount = 0;
  
      if (res.data.content && res.data.content.length > 0) {
        customArr = createCustomObject_AccessDetails(res.data.content);
        totalCount = res.data.totalElements;
      }
  
      yield put({
        type: "SET_NORMAL_DIRECT_DATA_SOURCE_INFO",
        payload: {
          allNormalDirectDataSourceUsers: customArr,
          totalCount: totalCount,
        },
      });

      //Need to Add if we use Sensitivity percentage second API to get sensitivity Info
      //todo:To make second API call seperately and show percentage of sensitive data accessed
      let payload = customArr.map((obj) => {
        return {
          tenantId: obj.tenantId,
          actorId: obj.actorId,
          dataSourceId: obj.dataSourceId,
          firstAccessedOn: obj.firstAccessedOn,
          lastAccessedOn: obj.lastAccessedOn,
          userName: obj.username,
          // first:obj.
        };
      });
      const sensitiveDataAccessed_Response = yield getService({
        method: "POST",
        url: sortBy_Filter_AccessDetails_Percentage(),
        data: { actorSensitivePercentages: payload },
        headers: getAxiosHeaders(true),
      });
      console.log(
        sensitiveDataAccessed_Response,
        "sensitiveDataAccessed_Response"
      );
      if (sensitiveDataAccessed_Response.status === 200) {
        const outArr =
          createCustomObject_AccessDetails_afterGettingSensitivePercentage(
            sensitiveDataAccessed_Response.data
              ? sensitiveDataAccessed_Response.data
              : [],
            customArr
          );
        yield put({
          type: "SET_NORMAL_DIRECT_DATA_SOURCE_INFO",
          payload: {
            allNormalDirectDataSourceUsers: outArr,
            totalCount: totalCount,
          },
        });
      }
    } else {
      if (res && res.response.status === 500) {
        yield put({
          type: "SET_DIRECT_ERROR_MESSAGE",
          payload: res.response.data.errorMessage,
        });
      }
      yield put({
        type: "SET_NORMAL_DIRECT_DATA_SOURCE_LOADER_INFO",
        payload: false,
      });
    }
  } catch (error) {
    //   yield put({ type: "CREATE_ALERT_POLICY_FAILED" });
  }
}

function* normalDirectDataSourceSaga() {
  yield takeEvery(
    "GET_NORMAL_DIRECT_DATA_SOURCE_INFO_DATA",
    normalDirectDataSourceApi
  );
}

export default normalDirectDataSourceSaga;
