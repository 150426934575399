import { isEqual, cloneDeep } from "lodash";
import {createCustomGRidObject} from "../../components/views/userAccessRights/UserAccessRightsCommonFunctions";

  const initialState = {
    totalCount: 0,
    currentPage: 0,
    recordsPerPage: 100,
    allNormalDataSourceUsers: [],
    fromTimeValue: "",
    toTimeValue: "",
    dataSources:[],
    dataSourceType:[],
    databaseRoles:[],
    databaseStatusInfo:[],
    apiDataSourceName: [],
    apiDataSourceType: [],
    apiDatabaseAccessRoles: [],
    apiDatabaseStatusInfo:[],
    filterInfo: [],
    filterApplied:false,
    loader: false,
    sortByValue: "",
    orderByValue: "",
    finalDataSourceName: [],
    finalDataSourceType: [],
    finalDatabaseAccessRoles: [],
    finalDatabaseStatusInfo:[],
    finalSortByValue: "",
    finalOrderByValue: "",
    finalFromTimeValue: "",
    finalToTimeValue: "",
    copyFilterInfo:[],
    searchValue:"",
    location:"",
    rolesData:[],
    accountDataOnRole:[],
    applications:[]
  };
  
  export const normalDataSourceReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_NORMAL_DATA_SOURCE_INFO": {
        return {
          ...state,
          allNormalDataSourceUsers: createCustomGRidObject(action.payload.allNormalDataSourceUsers),
          totalCount: action.payload.totalCount,
          loader: false,
        };
      }
      case "SET_NORMAL_DATA_SOURCE_CURRENT_PAGE": {
        return {
          ...state,
          currentPage: action.payload,
          loader: true,
        };
      }
      case "SET_NORMAL_DATA_SOURCE_RECORDS_PAGE": {
        return {
          ...state,
          recordsPerPage: action.payload,
          loader: true,
        };
      }
      case "SET_NORMAL_DATA_SOURCE_LOADER_INFO": {
        return {
          ...state,
          loader: action.payload,
        };
      }
      case "SET_API_DATA_SOURCE_NAME": {
        return {
          ...state,
          dataSources: action.payload,
        };
      }
      case "SET_API_DATA_SOURCE_TYPE": {
        return {
          ...state,
          dataSourceType: action.payload,
        };
      }
      case "SET_API_DATA_BASE_ROLES": {
        return {
          ...state,
          databaseRoles: action.payload,
        };
      }
      case "SET_API_DATA_BASE_STATUS_INFO": {
        return {
          ...state,
          databaseStatusInfo: action.payload,
        };
      }
      case "SET_APPLICATIONS":{
        return {
          ...state,
          applications:action.payload
        }

      } 
      case "SET_FILTER_DATA_SOURCE_NAME": {
        const copyOfData = [...state.apiDataSourceName];
        if (!state.apiDataSourceName.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDataSourceName: temp,
          };
        }
        return {
          ...state,
          apiDataSourceName: copyOfData,
        };
      }
      case "SET_FILTER_DATA_SOURCE_TYPE": {
        const copyOfData = [...state.apiDataSourceType];
        if (!state.apiDataSourceType.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDataSourceType: temp,
          };
        }
        return {
          ...state,
          apiDataSourceType: copyOfData,
        };
      }
      case "SET_FILTER_DATABASE_ROLES": {
        const copyOfData = [...state.apiDatabaseAccessRoles];
        if (!state.apiDatabaseAccessRoles.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDatabaseAccessRoles: temp,
          };
        }
        return {
          ...state,
          apiDatabaseAccessRoles: copyOfData,
        };
      }
      case "DATA_SOURCE_SORT_BY_VALUE": {
        return {
          ...state,
          sortByValue: action.payload,
        };
      }
      case "DATA_SOURCE_ORDER_BY_VALUE": {
        return {
          ...state,
          orderByValue: action.payload,
        };
      }
      case "SET_FILTER_DATABASE_STATUS_INFO": {
        const copyOfData = [...state.apiDatabaseStatusInfo];
        if (!state.apiDatabaseStatusInfo.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDatabaseStatusInfo: temp,
          };
        }
        return {
          ...state,
          apiDatabaseStatusInfo: copyOfData,
        };
      }
      case "MAINTAIN_DATA_SOURCE_SINGLE_DATA": {
        let copyOfData = [...state.filterInfo];
        let present = false;
        for (let i = 0; i < copyOfData.length; i++) {
          if (copyOfData[i].name === action.payload.name) {
            present = true;
            break;
          }
        }
        if (!present) {
          return {
            ...state,
            filterInfo: [...copyOfData, { ...action.payload }],
          };
        } else {
          const temp = copyOfData.filter(
            (each) => each.name !== action.payload.name
          );
          return {
            ...state,
            filterInfo: temp,
          };
        }
      }
      case "SET_DATA_SOURCE_APPLY_DATA": {
        return {
          ...state,
          currentPage: 0,
          loader: true,
          filterApplied: true,
          finalDataSourceName: cloneDeep(state.apiDataSourceName),
          finalDataSourceType: cloneDeep(state.apiDataSourceType),
          finalDatabaseAccessRoles: cloneDeep(state.apiDatabaseAccessRoles),
          finalDatabaseStatusInfo: cloneDeep(state.apiDatabaseStatusInfo),
          finalSortByValue: cloneDeep(state.sortByValue),
          finalOrderByValue: cloneDeep(state.orderByValue),
          copyFilterInfo: cloneDeep(state.filterInfo),
          finalFromTimeValue: cloneDeep(state.fromTimeValue),
          finalToTimeValue: cloneDeep(state.toTimeValue),
        };
      }
      case "DATA_SOURCE_CLEAR_FILTER_DATA": {
        return {
          ...state,
          currentPage: 0,
          loader: true,
          apiDataSourceName: [],
          apiDataSourceType: [],
          apiDatabaseAccessRoles: [],
          apiDatabaseStatusInfo:[],
          filterInfo: [],
          filterApplied:false,
          sortByValue: "",
          orderByValue: "",
          finalDataSourceName: [],
          finalDataSourceType: [],
          finalDatabaseAccessRoles: [],
          finalDatabaseStatusInfo:[],
          finalSortByValue: "",
          finalOrderByValue: "",
          copyFilterInfo:[],
          fromTimeValue: "",
          toTimeValue: "",
          finalFromTimeValue: "",
          finalToTimeValue: "", 
        };
      }
      case "DATA_SOURCE_CLEAR_ON_CLOSE": {
        let apiDataSourceName = state.apiDataSourceName;
        let filterInfo = state.filterInfo;
        let apiDataSourceType = state.apiDataSourceType;
        let apiDatabaseAccessRoles = state.apiDatabaseAccessRoles;
        let sortByValue = state.sortByValue;
        let orderByValue = state.orderByValue;
        let apiDatabaseStatusInfo = state.apiDatabaseStatusInfo;
        let fromTimeValue = state.fromTimeValue;
        let toTimeValue = state.toTimeValue;
        if (
          !isEqual(
            state.finalDataSourceName.sort(),
            state.apiDataSourceName.sort()
          )
        ) {
          apiDataSourceName = cloneDeep(state.finalDataSourceName);
        }
        if (!isEqual(state.copyFilterInfo, state.filterInfo)) {
          filterInfo = cloneDeep(state.copyFilterInfo);
        }
        if (
          !isEqual(
            state.finalDataSourceType.sort(),
            state.apiDataSourceType.sort()
          )
        ) {
          apiDataSourceType = cloneDeep(state.finalDataSourceType);
        }
        if (
          !isEqual(
            state.finalDatabaseAccessRoles.sort(),
            state.apiDatabaseAccessRoles.sort()
          )
        ) {
          apiDatabaseAccessRoles = cloneDeep(state.finalDatabaseAccessRoles);
        }
        if (
          !isEqual(
            state.finalDatabaseStatusInfo.sort(),
            state.apiDatabaseStatusInfo.sort()
          )
        ) {
          apiDatabaseStatusInfo = cloneDeep(state.finalDatabaseStatusInfo);
        }
        if (state.sortByValue !== state.finalSortByValue) {
          sortByValue = cloneDeep(state.finalSortByValue);
        }
        if (state.orderByValue !== state.finalOrderByValue) {
          orderByValue = cloneDeep(state.finalOrderByValue);
        }
        if (state.fromTimeValue !== state.finalFromTimeValue) {
          fromTimeValue = cloneDeep(state.finalFromTimeValue);
        }
        if (state.toTimeValue !== state.finalToTimeValue) {
          toTimeValue = cloneDeep(state.finalToTimeValue);
        }
        return {
          ...state,
          apiDataSourceName: apiDataSourceName,
          filterInfo: filterInfo,
          apiDataSourceType: apiDataSourceType,
          apiDatabaseAccessRoles: apiDatabaseAccessRoles,
          sortByValue: sortByValue,
          orderByValue: orderByValue,
          apiDatabaseStatusInfo:apiDatabaseStatusInfo,
          fromTimeValue: fromTimeValue,
          toTimeValue: toTimeValue,
        };
      }
      case "DATA_SOURCE_FROM_TIME_VALUE": {
        return {
          ...state,
          fromTimeValue: action.payload,
        };
      }
      case "DATA_SOURCE_TO_TIME_VALUE": {
        return {
          ...state,
          toTimeValue: action.payload,
        };
      }
      case "UPDATE_SEARCH_VALUE":{
        return {
          ...state,
          searchValue: action.payload,
          currentPage:0,
          loader:true
        };
      }
      case "UPDATE_LOCATION":{
        return {
          ...state,
          location: action.payload
        };
      }
      case "DATA_SOURCE_CLEAR_STATE_DATA":{
        return {
          ...initialState,
        };
      }
      case "DATA_SOURCE_ROLES_DATA":{
        return {
          ...state,
          rolesData:action.payload
        };
      }
      case "DATA_SOURCE_DATA_BASED_ON_ROLE":{
        return {
          ...state,
          accountDataOnRole:action.payload
        };
      }
      default:
        return state;
    }
  };