import React from "react";

const CreateRoles = () => {
  return (
    <div style={{ marginLeft: "20%" }}>
      <h1>Create Roles Under Dev....</h1>
    </div>
  );
};

export default CreateRoles;
