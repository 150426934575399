import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputField from "../../views/BackOfficeGeneralContainer/InputField";
import "../../../assets/scss/BackOfficeStyles/Common.scss";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import {
  ALL_USERS,
  ALL_ROLES,
  ALL_USERS_POST,
} from "../../../redux/constants/constants";
import { validateEmail } from "../Util/Emailvalidator";
import ManagementFooter from "../BackOfficeGeneralContainer/ManagementFooter";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ManagementContent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openSuccess, setopenSuccess] = useState(false);
  const [openError, setopenError] = useState({ open: false, message: "" });

  const handleClick = (status, res) => {
    if (status === "success") {
      setopenSuccess(true);
    } else {
      if (res.response && res.response.data && res.response.data.errorMessage) {
        setopenError({ open: true, message: res.response.data.errorMessage });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSuccess(false);
    setopenError({ open: false, message: "" });
  };
  const [addFeild, setAddFeild] = useState([1]);
  // const [  input, setInput] = useState([]);
  // const [select, setSelect] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [error, setError] = useState([]);
  const [state, setState] = useState([
    { email: "", roleIds: [""], isChecked: true },
  ]);

  useEffect(() => {
    const headers = getAxiosHeaders(true);
    const apiCall = async () => {
      const res = await getService({
        method: "GET",
        url: ALL_ROLES(headers.Tenant ? headers.Tenant : ""),
        headers: headers,
      });
      if (res.status === 200) {
        const arr = res.data ? (res.data.content ? res.data.content : []) : [];
        const newArr = arr
          ? arr.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            })
          : [];
        setRoleId(newArr);
        setState([{ email: "", roleIds: [newArr[0].id], isChecked: true }]);
      }
    };
    apiCall();
  }, []);


  const removeErrorByKey = (index, field) => {
    setError((prevErrors) => {
      const updatedErrors = [...prevErrors];
      if (updatedErrors[index]) {
        delete updatedErrors[index][field];
      }
      return updatedErrors;
    });
  };

  const addClickHandler = () => {
    if (roleId.length !== 0) {
      setAddFeild((prevState) => [...prevState, 1]);
      setState((prevState) => [
        ...prevState,
        {
          email: "",
          roleIds: [roleId[0].id],
          isChecked: true,
        },
      ]);
    }
  };

  const inputHandler = (event, index) => {
    // 1. Make a shallow copy of the items
    let items = [...state];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...state[index] };
    // 3. Replace the property you're interested in
    item.email = event.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index] = item;
    // 5. Set the state to our new copy
    setState([...items]);
     //To remove errors when input is selected
     if(error[index] && Object.keys(error[index]).length > 0) {
      removeErrorByKey(index, "email");
     }
  };
  const handleClickDelete = (event, index) => {
    let latestState = [...state];
    let errors = [ ...error ];
    latestState.splice(index, 1);
    addFeild.splice(index, 1);
    errors.splice(index, 1);
    setError(errors);
    setState(latestState);
    setAddFeild(addFeild);
  };

  const checkBoxChangeHandler = (event, index) => {
    // 1. Make a shallow copy of the items
    let items = [...state];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...state[index] };
    // 3. Replace the property you're interested in
    item.isChecked = !item.isChecked;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index] = item;
    // 5. Set the state to our new copy
    setState([...items]);
  };

  const cancelHandler = () => {
    setAddFeild([1]);
    setState([{ email: "", roleIds: [""], isChecked: true }]);
    if (
      localStorage.getItem("tenant") !== "druvstar" &&
      localStorage.getItem("tenant") !== "datavision"
    ) {
      if (window.location.pathname === "/mdr/userManagement/createUsers") {
        history.push(`/mdr/userManagement`);
      } else {
        history.push("/tenant/userManagement");
      }
    } else {
      history.push("/platform/userManagement");
    }
  };

  const submitHandler = () => {
    let count = 0;
    let err = [];
    state.forEach((el, index) => {
      let errorObj = {};
      if (isEmpty(el.email)) {
        errorObj.email = "Please enter an email";
      } else if (!validateEmail(el.email)) {
        errorObj.email = "Please enter a valid email address";
      }
      if (isEmpty(el.roleIds)) {
        errorObj.roles = "Roles should not be empty";
      }
      // Check if the errorObj is not empty before adding it to the array
      if (!isEmpty(errorObj)) {
        err[index] = errorObj;
      }
    });
    setError(err);
      if (count === 0 && isEmpty(err)) {
        const users = [];
        state.forEach((el) => {
          if (el.email) {
            let newObj = {
              email: el.email,
              roleIds: el.roleIds,
              mfaEnabled: el.isChecked,
            };
            users.push(newObj);
          }
        });
        const Apicall = async () => {
          const headers = getAxiosHeaders(true);
          const res = await getService({
            method: "POST",
            url: ALL_USERS_POST(headers.Tenant ? headers.Tenant : ""),
            data: {
              users: users,
            },
            headers: headers,
          });
          if (res && res.status === 201) {
            setAddFeild([1]);
            setState([{ email: "", roleIds: [roleId[0].id], isChecked: true }]);
            handleClick("success");
          } else {
            handleClick("failure", res);
          }
        };
        if (users.length > 0) {
          Apicall();
        }
      }
  };

  const selectOnchangeHandler = (event, value, index) => {
    const updatedState = [...state];
    updatedState[index].roleIds = value.map((option) => option.id);
    setState(updatedState);

    if (error[index] && Object.keys(error[index]).length > 0) {
      removeErrorByKey(index, "roles");
    }
  };

  return (
    <div>
      <div className="ManagementContent_Container">
        <Snackbar
          open={openError.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error">
            {openError.message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="success">
            Users Created Success!
          </Alert>
        </Snackbar>

        <div className="ManagementContent_Header">
          <div className="ManagementContent_Heading">
            Enter below details to onboard new users
          </div>
          <div className="createUserButton" onClick={addClickHandler}>
            Add
          </div>
        </div>
        <div>
          {addFeild
            ? addFeild.map((el, index) => (
                <>
                  <InputField
                    key={index}
                    number={index + 1}
                    inputonChangeHandler={inputHandler}
                    selectOnchangeHandler={selectOnchangeHandler}
                    // input={state[index].email}
                    roleId={roleId}
                    error={error[index]}
                    state={state}
                    handleClickDelete={handleClickDelete}
                    // select={state[index].roleIds[0]}
                    checkBoxChangeHandler={checkBoxChangeHandler}
                  ></InputField>
                </>
              ))
            : null}
        </div>
      </div>
      <ManagementFooter
        cancelHandler={cancelHandler}
        submitHandler={submitHandler}
        classname="createUserFooter"
      ></ManagementFooter>
    </div>
  );
};

export default ManagementContent;
