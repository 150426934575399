import { call, put, takeLatest } from "redux-saga/effects";
import { getService, getAxiosHeaders } from "../../library/RestAPI";
import {
  SET_SNACKBAR,
  SET_LOADER,
  SET_SOURCE_DELETION_ERROR_MESSAGE,
  logSourceDeletionMessage,
  TRIGGER_DELETE_LOG_SOURCE,
  DELETE_LOGSOURCE_URL,
  DELETE_DATASOURCE_URL,
  SET_CRITERIA,
  DATA_SOURCE_DELETION_CRITERIA_CHECK,
  SHOW_DIALOG_BOX,
  dataSourceErrorMessage,
  SET_CRITERIA_ERROR_MESSAGE,
  TRIGGER_DELETE_DATA_SOURCE,
} from "../constants/constants";

function* deleteLogSourceSaga(action) {
  const selectedLogSource = action.payload.selectedLogSource;
  const handleDialogClose = action.payload.handleDialogClose;
  try {
    // Make the API call
    const response = yield call(getService, {
      method: "DELETE",
      url: `${DELETE_LOGSOURCE_URL(selectedLogSource.id)}`,
      headers: getAxiosHeaders(true),
    });

    if (response && response.status === 200) {
      // Dispatch success action
      handleDialogClose();
    } else if (response && response.response.status === 400) {
      const errorMessage = response.response.data.errorMessage;
      yield put({ type: SET_SNACKBAR, payload: true });
      yield put({
        type: SET_SOURCE_DELETION_ERROR_MESSAGE,
        payload: errorMessage,
      });
    } else {
      // Dispatch failure action
      yield put({ type: SET_SNACKBAR, payload: true });
      yield put({
        type: SET_SOURCE_DELETION_ERROR_MESSAGE,
        payload: logSourceDeletionMessage,
      });
    }
  } catch (error) {
    // Dispatch failure action in case of an exception
    yield put({ type: SET_SNACKBAR, payload: true });
    yield put({
      type: SET_SOURCE_DELETION_ERROR_MESSAGE,
      payload: logSourceDeletionMessage,
    });
  }
}

function* checkCriteria(action) {
  const selectedDataSource = action.payload
  // Dispatch action to set loader state to true
  yield put({ type: SET_LOADER, payload: true });
  const options = {
    method: "GET",
    url: `${DELETE_DATASOURCE_URL(selectedDataSource.id)}`,
    headers: getAxiosHeaders(true),
  };

  try {
    const res = yield getService(options);
    if (res && res.status === 200 && res.data === true) {
      // Dispatch actions for success scenario
      yield put({ type: SET_CRITERIA, payload: true });
      yield put({ type: SHOW_DIALOG_BOX, payload: true });
      yield put({ type: SET_LOADER, payload: false });
    } else if (res && res.response && res.response.status === 400) {
      // Dispatch actions for 400 status scenario
      const data = res.response.data;
      yield put({
        type: SET_CRITERIA_ERROR_MESSAGE,
        payload: data.errorMessage,
      });
      yield put({ type: SET_CRITERIA, payload: false });
      yield put({ type: SHOW_DIALOG_BOX, payload: true });
      yield put({ type: SET_LOADER, payload: false });
    } else {
      // Dispatch actions for other failure scenarios
      yield put({ type: SET_SNACKBAR, payload: true });
      yield put({
        type: SET_SOURCE_DELETION_ERROR_MESSAGE,
        payload: dataSourceErrorMessage,
      });
      yield put({ type: SHOW_DIALOG_BOX, payload: false });
      yield put({ type: SET_LOADER, payload: false });
    }
  } catch (error) {
    // Handle errors and dispatch actions accordingly
    yield put({ type: SET_SNACKBAR, payload: true });
    yield put({
      type: SET_SOURCE_DELETION_ERROR_MESSAGE,
      payload: dataSourceErrorMessage,
    });
    yield put({ type: SHOW_DIALOG_BOX, payload: false });
    yield put({ type: SET_LOADER, payload: false });
  }
}

function* deleteDataSource(action) {
  const selectedDataSource = action.payload.selectedDataSource;
  const handleDialogClose = action.payload.handleDialogClose;
  yield put({ type: SET_LOADER, payload: true });
  try {
    const response = yield call(getService, {
      method: "DELETE",
      url: `${DELETE_DATASOURCE_URL(selectedDataSource.id)}`,
      headers: getAxiosHeaders(true),
    });
    if (response && response.status === 200) {
      yield put({ type: SHOW_DIALOG_BOX, payload: false });
      yield put({ type: SET_LOADER, payload: false });
      handleDialogClose();
    } else {
      yield put({ type: SET_SNACKBAR, payload: true });
      yield put({
        type: SET_SOURCE_DELETION_ERROR_MESSAGE,
        payload: dataSourceErrorMessage,
      });
      yield put({ type: SET_LOADER, payload: false });
    }
  } catch (error) {
    yield put({ type: SET_SNACKBAR, payload: true });
    yield put({
      type: SET_SOURCE_DELETION_ERROR_MESSAGE,
      payload: dataSourceErrorMessage,
    });
    yield put({ type: SET_LOADER, payload: false });
  }
}

// Watcher saga to listen for the trigger action
function* DeleteConnectionSaga() {
  yield takeLatest(TRIGGER_DELETE_LOG_SOURCE, deleteLogSourceSaga);
  yield takeLatest(DATA_SOURCE_DELETION_CRITERIA_CHECK, checkCriteria);
  yield takeLatest(TRIGGER_DELETE_DATA_SOURCE, deleteDataSource);
}

export default DeleteConnectionSaga;
