import React from "react";
import { useSelector } from "react-redux";
import "./IncidentTableStyles.scss";

import UseIncidentFilterHook from "./IncidentFilterHook";

import {
  UPDATE_PATCHMONITORING_CLASSFICATION_FILTERDATA_ACTION,
  UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA_ACTION,
  UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA_ACTION,
  UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA_ACTION,
} from "../../../../redux/actions/MDRActions/PatchMonitoringAction";
import { patchMonitoring_Query_toGet_FilterValues } from "../../../../redux/constants/constants";
import { getFilterTimeStamp } from "../../../common/utilities"

const PatchMonitoringTableFilters = () => {
  let ClassificationFilterData = useSelector(
    (state) => state.MDRPatchMonitoringReducer.Classification
  );

  let SeverityFilterData = useSelector(
    (state) => state.MDRPatchMonitoringReducer.Severity
  );

  let UpdateStateFilterData = useSelector(
    (state) => state.MDRPatchMonitoringReducer.UpdateState
  );
  let SubscriptionNameFilterData = useSelector(
    (state) => state.MDRPatchMonitoringReducer.SubscriptionName
  );
  const dateTimeRange = useSelector(
    (state) => {
      return state.selectedDateTimeRange.dateTimeRange;
    }
  );
  const {startDt, endDt} = getFilterTimeStamp(dateTimeRange);
  const patchMonitoring_Query_cache = patchMonitoring_Query_toGet_FilterValues(startDt, endDt);
  return (
    <>
      <div className="patchmonitoringFilter">
        <p>Classfication</p>
        <UseIncidentFilterHook
          query={patchMonitoring_Query_cache.ClassificationFilter}
          action={UPDATE_PATCHMONITORING_CLASSFICATION_FILTERDATA_ACTION}
          filterData={ClassificationFilterData}
        ></UseIncidentFilterHook>
      </div>
      <div className="patchmonitoringFilter">
        <p>Severity</p>
        <UseIncidentFilterHook
          query={patchMonitoring_Query_cache.SeverityFilter}
          action={UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA_ACTION}
          filterData={SeverityFilterData}
        ></UseIncidentFilterHook>
      </div>
      <div className="patchmonitoringFilter">
        <p>Update State</p>
        <UseIncidentFilterHook
          query={patchMonitoring_Query_cache.UpdateStateFilter}
          action={UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA_ACTION}
          filterData={UpdateStateFilterData}
        ></UseIncidentFilterHook>
      </div>
      <div className="patchmonitoringFilter">
        <p>Environment</p>
        <UseIncidentFilterHook
          query={
            patchMonitoring_Query_cache.SubscriptionNameFilter
          }
          action={UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA_ACTION}
          filterData={SubscriptionNameFilterData}
        ></UseIncidentFilterHook>
      </div>
    </>
  );
};

export default PatchMonitoringTableFilters;
