import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EnhancedGridTable from "../Table/Table";
import fetchAllResolvedAlerts from "../../../redux/actions/getResolvedAlertsAction";
import { getTime } from "../../common/utilities";
import { EnhancedTableToolbar } from "./EnhancedAlertsTableHeader";
import { tableConstants } from "./alertsUtilities";
import AlertDrawer from "../alertDrawer";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { filter } from "lodash";
import { CustomUseEffectHook } from "../../views/CustomUseEffectHook";
import {
  endOfDay,
} from "date-fns";

export default function ResolvedAlerts(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const { policyId } = props;
  const alertStatusUpdated = useSelector(
    (state) => state.alertStatusChanged.alertUpdated
  );
  const [resolvedAlerts, setResolvedAlerts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [selectedAlertId, setSelectedAlertId] = useState("");
  const [stateOpen, setStateOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [severity, setSeverityFilter] = useState([]);
  const [resolution, setResolutionFilter] = useState([]);
  const [policy, setPolicyFilter] = useState([]);
  const [sortBy, setSortBy] = useState("generatedOn");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterTouched, setFilterTouched] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [filterDataInfo, setDataFilterInfo] = useState([]);
  const [toTimeRange , setToTimeRange]= useState("");
  const [fromTimeRange , setFromTimeRange]= useState("");
  const alertStatus = useSelector(
    (state) => state.alertStatusChanged.alertStatus
  );

  CustomUseEffectHook(() => {
    setLoader(true);
    setOrder("asc");
    setOrderBy("");
    getAllAlertsResolved(recordsPerPage, currentPage);
  }, [
    severity,
    resolution,
    policy,
    sortBy,
    sortOrder,
    currentPage,
    recordsPerPage,
    toTimeRange,
    fromTimeRange
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  //filter function
  useEffect(() => {
    setLoader(true);
    if (
      props.propsData &&
      props.propsData.location &&
      props.propsData.location.state
    ) {
      if (props.propsData.location.state.severity) {
        if(props.propsData.location.state.time){
          setFromTimeRange(new Date(props.propsData.location.state.time))
          setToTimeRange(endOfDay(new Date(props.propsData.location.state.time)))
        }
        setSeverityFilter([props.propsData.location.state.severity]);
        const severity = props.propsData.location.state.severity;
        setDataFilterInfo([
          {
            data: { code: severity, value: severity },
            name: severity,
            type: "severity",
            value: severity,
          },
        ]);
      } else {
        setPolicyFilter([
          props.propsData.location.state.policyId["Alerts.alert_policy_id"],
        ]);
        const id =
          props.propsData.location.state.policyId["Alerts.alert_policy_id"];
        const policyname =
          props.propsData.location.state.policyId["Alerts.policyname"];
        setDataFilterInfo([
          {
            data: { name: policyname, id: id },
            name: policyname,
            type: "policy",
            value: id,
          },
        ]);
      }
      console.log(props.propsData);
    } else {
      getAllAlertsResolved(recordsPerPage, 0);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getAllAlertsResolved(recordsPerPage, currentPage) {
    const response = await fetchAllResolvedAlerts(
      recordsPerPage,
      currentPage,
      sortBy,
      severity,
      resolution,
      policy,
      policyId,
      sortOrder ,
      fromTimeRange,
      toTimeRange
    );
    if (response && response.content) {
      setResolvedAlerts(response.content);
      setTotalCount(response.totalElements);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (alertStatusUpdated) {
      if (
        currentPage > 0 &&
        resolvedAlerts.length === 1 &&
        alertStatus === "MARK_UNRESOLVED"
      ) {
        getAllAlertsResolved(recordsPerPage, currentPage - 1);
        setCurrentPage(currentPage - 1);
      } else {
        getAllAlertsResolved(recordsPerPage, currentPage);
      }
    }
  }, [alertStatusUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleDrawer = (open, event, alertId) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSelectedAlertId(alertId);
    setStateOpen(open);
  };

  const handleChangeRowsPerPage = (value) => {
    setRecordsPerPage(value);
    setCurrentPage(0);
    setLoader(true);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
    setLoader(true);
  };

  const clearFilterData = () => {
    setLoader(true);
    setFilterTouched(false);
    setCurrentPage(0);
    setResolutionFilter([]);
    setPolicyFilter([]);
    setSortBy("generatedOn");
    setSortOrder("DESC");
    setStateOpen(false);
    setSeverityFilter([]);
    setDataFilterInfo([]);
    setToTimeRange("");
    setFromTimeRange("");
    // if (props.propsData && props.propsData.location && props.propsData.location.state) {
    //   if(props.propsData.location.state.severity){
    //     setSeverityFilter([props.propsData.location.state.severity]);
    //     const severity = props.propsData.location.state.severity;
    //     setDataFilterInfo([
    //       {
    //         data: { code: severity, value: severity },
    //         name: severity,
    //         type: "severity",
    //         value: severity,
    //       },
    //     ]);
    //   }else{
    //     setPolicyFilter([props.propsData.location.state.policyId["Alerts.alert_policy_id"]]);
    //     const id = props.propsData.location.state.policyId["Alerts.alert_policy_id"];
    //     const policyname = props.propsData.location.state.policyId["Alerts.policyname"];
    //     setDataFilterInfo([
    //       {
    //         data: { name: policyname, id: id },
    //         name: policyname,
    //         type: "policy",
    //         value: id,
    //       },
    //     ]);
    //   }
    // } else {
    //   setSeverityFilter([]);
    //   setDataFilterInfo([]);
    // }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const tableCells = () => {
    if (policyId) {
      const data = tableConstants(toggleDrawer, getTime, dispatch, history);
      return filter(data, (_, index) => index !== 2);
    } else {
      return tableConstants(toggleDrawer, getTime, dispatch, history);
    }
  };

  const getTooltipText = () => {
    if (policyId) {
      return "Historic alerts that were resolved or marked as false positives .Click on the Time to view details and monitors. Click on action to view the data access that triggered the alert";
    } else {
      return "Historic alerts that were resolved or marked as false positives .Click on the Time to view details and monitors. Click on the Policy Name to view details of the policy.Click on action to view the data access that triggered the alert";
    }
  };

  const handleApplyFilter = (
    severity,
    resolution,
    policy,
    order,
    orderBy,
    filterDataInfo,
    fromTimeRange ,
    toTimeRange
  ) => {
    setSeverityFilter(severity);
    setResolutionFilter(resolution);
    setPolicyFilter(policy);
    setSortBy(order);
    setSortOrder(orderBy);
    setCurrentPage(0);
    setFilterTouched(true);
    setDataFilterInfo(filterDataInfo);
    setToTimeRange(toTimeRange)
    setFromTimeRange(fromTimeRange)
  };

  return (
    <React.Fragment>
      {loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )}
      <EnhancedTableToolbar
        totalElements={totalCount}
        rows={resolvedAlerts}
        severityFilter={severity}
        resolutionFilter={resolution}
        policyFilter={policy}
        sortByAlert={sortBy}
        loc={"resolved-alerts"}
        alertsTitle={"Resolved Alerts"}
        selectedRows={[]}
        sortOrderAlert={sortOrder}
        clearFilterData={clearFilterData}
        policyId={policyId}
        dataLoaded={filterTouched}
        filterDataInfo={filterDataInfo}
        helpDescription={getTooltipText()}
        applyFilter={handleApplyFilter}
        toTimeRangeAlert={toTimeRange}
        fromTimeRangeAlert={fromTimeRange}
        show={true}
      />
      <EnhancedGridTable
        cols={tableCells()}
        data={resolvedAlerts}
        totalCount={totalCount}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        order={order}
        orderBy={orderBy}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onRequestSort={handleRequestSort}
      />
      {selectedAlertId !== "" && (
        <AlertDrawer
          alertDetailsId={selectedAlertId}
          toggleDrawer={toggleDrawer}
          stateOpen={stateOpen}
        />
      )}
    </React.Fragment>
  );
}
