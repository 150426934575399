import {
  UPDATE_INCIDENTPRIORITY_FILTER_DATA,
  UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA,
  UPDATE_INCIDENT_NAME_FILTER_DATA,
  APPLY_INCIDENTTABLE_FILTER,
  UPDATE_INCIDENT_COUNTRY_FILTER_DATA,
  UPDATE_INCIDENTID_FILTER_DATA,
  CACHE_INCIDENT_TABLE_DATA,
  APPLY_CACHED_INCIDENT_TABLE_DATA,
  UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA,
  INCIDENT_STATE_DETAILS_FILTER_CLEAR_DATA,
  INCIDENT_STATE_FILTER_CLEAR_DATA,
  SET_INCIDENT_STATE_VALUE,
  INCIDENT_TABLE_FILTER_CLEARALL,
  UPDATE_INCIDENT_TABLE_AS_PER_DATERANGE,
  UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA,
} from "../constants/constants";

import { tableQuery as IncidentTableQuery } from "../../components/common/Dashboard/dashboard-data";
import {
  MDRFilterDataFormatter,
  clearMDRFilters,
  AddSortingForMDRQueries,
} from "../../components/common/Dashboard/utils";
import {
  MDRTableSearchHelper,
  MDRApplyFilters,
  MDRMultipleFilters_Merge_Helper,
} from "../../components/common/Dashboard/MDRHelpers";
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  Priority: [],
  Assignee: [],
  EffectiveStatus: [],
  StateDetails: [],
  IncidentId: [],
  Name: [],
  City: [],
  incidentTableData: [],
  incidentTableRowCount: 0,
  tableQuery: IncidentTableQuery,
  currentStatus: "",
  clearAll: false,
  MDRUpdatedSearchValue: "",
  incidentSortByValue: "",
  incidentOrderByValue: "",
  stateValue: "state",
};

let cachedState = cloneDeep(initialState);

export const IncidenetTableFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INCIDENTPRIORITY_FILTER_DATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRFilterDataFormatter(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          Priority: [...updatedData],
        };
      }

      return {
        ...state,
        Priority: [...action.payload.data],
      };
    }
    case UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRFilterDataFormatter(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          Assignee: [...updatedData],
        };
      }

      return {
        ...state,
        Assignee: [...action.payload.data],
      };
    }
    case UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRFilterDataFormatter(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          EffectiveStatus: [...updatedData],
        };
      }

      return {
        ...state,
        EffectiveStatus: [...action.payload.data],
      };
    }
    // To store the state details
    case UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRFilterDataFormatter(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          StateDetails: [...updatedData],
        };
      }

      return {
        ...state,
        StateDetails: [...action.payload.data],
      };
    }
    case UPDATE_INCIDENT_NAME_FILTER_DATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRFilterDataFormatter(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          Name: [...updatedData],
        };
      }

      return {
        ...state,
        Name: [...action.payload.data],
      };
    }
    case UPDATE_INCIDENT_COUNTRY_FILTER_DATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRFilterDataFormatter(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          City: [...updatedData],
        };
      }

      return {
        ...state,
        City: [...action.payload.data],
      };
    }

    case UPDATE_INCIDENTID_FILTER_DATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRFilterDataFormatter(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          IncidentId: [...updatedData],
        };
      }

      return {
        ...state,
        IncidentId: [...action.payload.data],
      };
    }
    case APPLY_INCIDENTTABLE_FILTER: {
      const filters = MDRMultipleFilters_Merge_Helper(action.payload);
      const newTableInstance = MDRApplyFilters(state.tableQuery, filters);
      let updatedQuery;
      if (state.incidentSortByValue) {
        updatedQuery = AddSortingForMDRQueries(
          newTableInstance,
          state.incidentSortByValue,
          state.incidentOrderByValue,
          "IncidentsTable"
        );
      } else {
        updatedQuery = newTableInstance;
      }

      console.log(newTableInstance, "newTableInstance");
      return {
        ...state,
        tableQuery: [...updatedQuery],
        currentStatus: "apply",
      };
    }
    case "INCIDENT_TABLE_FILTER_CLEARALL": {
      const incidentFilterState = clearMDRFilters(
        state.Priority,
        state.EffectiveStatus,
        state.StateDetails,
        state.IncidentId,
        state.City,
        state.Assignee
      );
      return {
        ...state,
        Priority: incidentFilterState[0],
        EffectiveStatus: incidentFilterState[1],
        StateDetails: incidentFilterState[2],
        IncidentId: incidentFilterState[3],
        City: incidentFilterState[4],
        Assignee: incidentFilterState[5],
        clearAll: true,
        tableQuery: [...IncidentTableQuery],
        stateValue: "state",
        incidentSortByValue: "",
        incidentOrderByValue: "",
      };
    }
    // Clear the state details checked value if selected value is state filter
    case INCIDENT_STATE_DETAILS_FILTER_CLEAR_DATA: {
      const incidentFilter = clearMDRFilters(
        state.StateDetails,
      );
      return {
        ...state,
        StateDetails: incidentFilter[0],
      };
    }
    // Clear the state checked value if selected value is state details filter
    case INCIDENT_STATE_FILTER_CLEAR_DATA: {
      const incidentFilter = clearMDRFilters(
        state.EffectiveStatus,
      );
      return {
        ...state,
        EffectiveStatus: incidentFilter[0],
      };
    }
    case SET_INCIDENT_STATE_VALUE: {
      return {
        ...state,
        stateValue: action.value,
      };
    }
    case "UPDATE_INCIDENT_TABLE_QUERY_ASPER_PAGESELECTED": {
      console.log(action);
      let newInstance = [...state.tableQuery];
      let newObj = { ...newInstance[0] };
      let newAquery = [...newObj.query];
      let query1 = { ...newAquery[0] };

      query1.offset = action.payload * action.rowsPerPage;
      query1.limit = action.rowsPerPage;
      newAquery[0] = query1;
      newObj.query = newAquery;
      newInstance[0] = newObj;

      return {
        ...state,
        tableQuery: newInstance,
        currentStatus: "pageChange",
      };
    }
    case "INCIDENT_TABLE_DATA": {
      return {
        ...state,
        incidentTableData: [...action.payload.data],
        incidentTableRowCount: action.payload.totalTableDataCount,
        clearAll: false,
      };
    }
    case "UPDATE_INCIDENT_TABLE_AS_PER_DATERANGE": {
      return {
        ...state,
        currentStatus: action.payload.dateRangeChanged
          ? "dateRangeChanged"
          : "dateRangeNotChanged",
      };
    }
    case "UPDATE_INCIDENT_TABLE_AS_PER_SEARCHVALUE": {
      let newTableInstance = MDRTableSearchHelper(state.tableQuery, action);
      return {
        ...state,
        tableQuery: [...newTableInstance],
        currentStatus: "searchValue",
        incidentTableData: [],
        incidentTableRowCount: 0,
        MDRUpdatedSearchValue: action.payload,
      };
    }
    case "UPDATE_INCIDENT_TABLE_AS_SEARCHVALUE_CLEARED": {
      let newTableInstance = MDRTableSearchHelper(
        state.tableQuery,
        action,
        true
      );
      return {
        ...state,
        tableQuery: [...newTableInstance],
        currentStatus: "searchValue",
        incidentTableData: [],
        incidentTableRowCount: 0,
        MDRUpdatedSearchValue: action.payload,
      };
    }

    case "MDR_RESET_TABLEROWS_DATA_AS_SEARCH_FIELD_CHANGED": {
      return {
        ...state,
        incidentTableData: [],
        incidentTableRowCount: 0,
        MDRUpdatedSearchValue: action.payload,
      };
    }
    case "RESET_INCIDENT_TABLE_DATA": {
      return {
        ...state,
        incidentTableData: [],
        incidentTableRowCount: 0,
      };
    }
    case "MDR_INCIDENT_TABLE_SORT_BY_VALUE": {
      return {
        ...state,
        incidentSortByValue: action.payload.sortByValue,
        incidentOrderByValue: action.payload.orderByValue,
      };
    }
    case "MDR_INCIDENT_TABLE_ORDER_BY_VALUE": {
      return {
        ...state,
        incidentOrderByValue: action.payload,
      };
    }
    // Cache the incident table state data
    case CACHE_INCIDENT_TABLE_DATA: {
      cachedState = cloneDeep(state);
      return state;
    }
    // Clear the incident table cached data while close the popup
    case APPLY_CACHED_INCIDENT_TABLE_DATA: {
      return cachedState;
    }
    case "CLEAR_CURRENT_STATUS": {
      return {
        ...state,
        currentStatus: ""
      };
    }
    default:
      return state;
  }
};