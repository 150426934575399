import queryString from "query-string";

export const getTenant = (search) => {
  const { tenant } = queryString.parse(search);
  let tenantName = "";
  const subDomains = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/.exec(window.location.host)[1].split('.');
  if (tenant !== "" && tenant !== undefined) {
    tenantName = tenant;
  } else {
    if (subDomains.length === 2 || subDomains[0] === "dev2" || subDomains[0] === "dev3" || subDomains[0] === "dev4") {
      tenantName = "druvstar";
    } else {
      tenantName = subDomains[0];
    }
  }
  return tenantName;
};
