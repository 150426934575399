import { UPDATE_ALERT_COUNT } from "../constants/constants";

const initialState = {
  alertCount: 0,
  totalCount:0
};

export const alertCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ALERT_COUNT:
      return {
        ...state,
        alertCount: action.data.alertCount,
        totalCount:action.data.totalCount
      };
    default:
      return state;
  }
}