import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataOwnerContentTable from "./DataOwnerContentTable";
import {
  GET_DATA_OWNER_HEADER_DATA_REQUEST,
  DATA_OWNER_FILE_LIST,
  CLEAR_DATA_OWNER_FIELDS,
  viewDataSource,
  generateReport,
  fetchReport
} from "../../redux/constants/constants";
import AlertBadge from "./Badge/AlertBadge";
import SensitivityBadge from "./Badge/SensitivityBadge";
import Toggler from "./Toggler";
import { DataMapRoute } from "../common/DatamapImage";
import { isEmpty } from "lodash";

export default function DataOwner() {
  const history = useHistory();

  // State for toggler buttons
  // Add permission param for each toggler as we are rendering based on permission
  const [togglers, setTogglers] = useState([
    { name: "Data Owner View", state: "active" , permission: viewDataSource},
    { name: "System Summary", state: "inactive", permission: viewDataSource},
    { name: "Generate Report", state: "inactive", permission: generateReport },
    { name: "Report Repository", state: "inactive", permission: fetchReport }
  ]);

  const dispatch = useDispatch();

  // Redux selectors
  const headerData = useSelector(
    (state) => state.DataOwnerReducer.dataOwnerHeaderData
  );
  const loader = useSelector(
    (state) => state.InspectContentReducer.loader
  );


  useEffect(() => {
    dispatch({
      type: GET_DATA_OWNER_HEADER_DATA_REQUEST
    })
    return () => {
      // Clean up function to clear data owner fields when component unmounts
      const currentRoute = window.location.pathname;
      if (currentRoute !== DATA_OWNER_FILE_LIST) {
        dispatch({
          type: CLEAR_DATA_OWNER_FIELDS
        });
      }
    }
  },[]);


  // Function to render user alerts
  const renderUserAlerts = (totalUsers) => {
    const userAlerts = [
      {
        name: "High",
        color: "highUser",
        count: totalUsers.HIGH,
        icon: "/dataOwnerViewIcons/userIcons/highUser.svg"
      },
      {
        name: "Medium",
        color: "mediumUser",
        count: totalUsers.MEDIUM,
        icon: "/dataOwnerViewIcons/userIcons/mediumUser.svg"
      },
      {
        name: "Low",
        color: "lowUser",
        count: totalUsers.LOW,
        icon: "/dataOwnerViewIcons/userIcons/lowUser.svg"
      }
    ];

    return (
      <div className="userAlerts">
        <div className="dataOwnerTitles">Users with alerts: </div>
        <div className="userAlertsCount">
          {userAlerts.map((row) => {
            return (
              <div className="dataOwnerUserIcons">
                <div title={row.name}><img src={row.icon} alt="alertUserImage"/></div>
                <div className={row.color} title={row.count}>{row.count}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  };

   // Function to render badges
  const badges = () => {
    if (!isEmpty(headerData)) {
      return (
        <>
          <div className="dataOwnerSensitivity">
            <div className="inspect-alerts sensitivity">
              <SensitivityBadge parentprops={headerData.sensitiveData} loc="DataOwner" title={"Sensitivity in %"} />
            </div>
          </div>
          <div className="inspect-alerts summary">
            <AlertBadge title={"Alerts:"} parentprops={headerData.alertsData} InspectContent={true} />
          </div>
          {renderUserAlerts(headerData.userAlerts)}
        </>
      );
    } else {
      return null;
    }
  };

  // Function to render the header
  const renderHeader = () => {
    return (
      <div className="inspect-title-header dataOwner">
        <div className="dataOwnerSummaryHeader">Summary</div>
        <div className="inspect-title-right dataOwner">
          {badges()}
        </div>
      </div>
    );
  };

  // Function to render all fields component
  const allFieldsComponent = () => {
    return (
      <>
        <DataOwnerContentTable
          loader={loader} />
      </>
    );
  };

  // Function to handle toggler button clicks
  const toggleRoutes = {
    "Data Owner View": "/tenant/dataOwner",
    "System Summary": "/tenant/systemSummary",
    "Generate Report": "/tenant/generateReport",
    "Report Repository": "/tenant/reportRepository",
  };
  const handleToggle = (name) => {
    history.push(toggleRoutes[name])
  };

  return (
    <div className="main">
      <div className="description">
        <div style={{ display: "grid" }}>
          <h2 style={{ margin: "0", fontSize: "32px" }}>Data Owner</h2>
          <p style={{ margin: "0" }}>
            Owners data view
          </p>
        </div>
        <DataMapRoute />
      </div>
      <div className="togglerandSearch_Conatiner">
        <Toggler
          togglers={togglers}
          loc="summary"
          setTogglers={setTogglers}
          history={history}
          handleToggle={handleToggle}
          permissionBased
        ></Toggler>
      </div>
      <div className="fs-file-list-container">
        {renderHeader()}
        {allFieldsComponent()}
      </div>
    </div>
  );
}

