import { GET_DATABASE_ENGINES_URL} from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function getDatabaseEngines() {
    const options = {
        method: "GET",
        url: `${GET_DATABASE_ENGINES_URL}`,
        headers: getAxiosHeaders(true),
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};
