import LevelComponent from "../../shared/levelComponent";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { disableNotificationIconList } from "../../../redux/constants/constants";

export const renderPolicyType = (value) => {
  if (value === "Direct Database Access") {
    return "Direct Data Access";
  } else {
    return value;
  }
};
export const tableConstants = (
  dispatch,
  alertPolicyDataFlowGroupOpen,
  history
) => {
  return [
    {
      title: "Status",
      render: rowData => {
        return <LevelComponent level={rowData.status.value} />;
      },
      orderId: "status.code"
    },
    {
      title: "Policy Name",
      orderId: "name",
      render: rowData => {
        return (
          <span
            className={
              rowData && rowData.status && rowData.status.code === "DELETED"
                ? "deleted"
                : "mousePointer"
            }
            onClick={() => {
              dispatch({
                type: "TRACK_TRAVERSAL",
                payload: `Alert Policies - ${rowData.description}`
              });
              history.push(`/tenant/alertPolicies/${rowData.id}`);
            }}
          >
            {rowData.name}
          </span>
        );
      }
    },
    {
      title: "Alerts",
      orderId: "alertsCount",
      render: rowData => {
        return <span>{rowData.alertsCount}</span>;
      }
    },
    {
      title: "Policy Type",
      render: rowData => {
        if (rowData && rowData.type && rowData.type.value){
          return <span>{renderPolicyType(rowData.type.value)}</span>;
        } else {
          return <span>-</span>
        }
      }
    },
    {
      title: "Policy Subtype",
      render: rowData => {
        if (rowData && rowData.policySubtype && rowData.policySubtype.value){
          return <span>{rowData.policySubtype.value}</span>;
        } else {
          return <span>-</span>
        }
      }
    },

    {
      title: "Severity",
      orderId: "severity.code",
      render: rowData => {
        return (
          <div
            style={{
              display: "flex"
            }}
          >
            <LevelComponent level={rowData.severity.code} status={""} />
          </div>
        );
      }
    },
    {
      title: "Notify via Email",
      render: (rowData) => {
        return (
          <div>
            {rowData.notificationEnabled ? (
              <div title="on">
                <NotificationsActiveIcon
                  className={
                    disableNotificationIconList.includes(rowData.status.code)
                      ? "emailNotification inactive"
                      : "emailNotification active"
                  }
                />
              </div>
            ) : (
              <div title="off">
                <NotificationsOffIcon className="emailNotification inactive" />
              </div>
            )}
          </div>
        );
      },
    },
  ];
};

export const thresholdValue = (severity) => {
  if (severity === "HIGH") {
    return 90;
  } else if (severity === "MEDIUM") {
    return 60;
  } else if (severity === "LOW") {
    return 30;
  } else {
    return 0;
  }
};