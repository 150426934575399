import React from "react";
import { Chart } from "react-google-charts";
import { VIEW_ENTERPRISE_ACTORS } from "../../../../redux/constants/constants";
import ChartWrapper from "../Chart";

import { dateFormatter } from "../utils";
import { pathWithPermission } from "../../utilities";
import { isEmpty } from "lodash";

const returnRedirectTo = (name) => {
  let data = {
    "Sensitive Data Users": {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    }
  }
  return pathWithPermission(data[name])
};

export let sensitiveDataUsersLineChart = (cubejsApi, name , history) => {
  let totalUsersQuery = {
    measures: ["TotalUsers.count"],
    timeDimensions: [
      {
        dimension: "TotalUsers.date",
        granularity: "day",
      },
    ],
  };
  let sensitiveDataUsersQuery = {
    measures: ["ActorDataSourceBySensitiveData.userCount"],
    timeDimensions: [
      {
        dimension: "ActorDataSourceBySensitiveData.date",
        granularity: "day",
      },
    ],
  };

  let renderMultiLineChart = (results) => {
    if (results.loadResponse.results.length === 1) return null;
    let totalUsers = results.loadResponse.results[0].data;
    let sensitiveDataUsers = results.loadResponse.results[1].data;
    let userDates = totalUsers.map((tu) => tu["TotalUsers.date.day"]);
    let allDates = userDates.concat(
      sensitiveDataUsers.map((ua) => ua["ActorDatasource.date.day"])
    );
    allDates.sort((d1, d2) => new Date(d1) - new Date(d2));
    let uniqueDates = [...new Set(allDates.map((d) => d))];

    let chartData = [];
    if (!isEmpty(totalUsers) && !isEmpty(sensitiveDataUsers)) {
      chartData.push(["Date", "Total Users", "Sensitive Data Users"]);
      uniqueDates.forEach((date, index) => {
        let tu = totalUsers.find((t) => t["TotalUsers.date.day"] === date);
        let currentTotalUsers = parseInt(tu ? tu["TotalUsers.count"] : 0);
        let tuCount =
          index === 0
            ? currentTotalUsers
            : currentTotalUsers + chartData[index][1];
        let au = sensitiveDataUsers.find(
          (u) => u["ActorDataSourceBySensitiveData.date.day"] === date
        );
        let auCount = parseInt(
          au ? au["ActorDataSourceBySensitiveData.userCount"] : 0
        );
  
        chartData.push([dateFormatter(date), tuCount, auCount]);
      });
  
      return (
        <Chart
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={chartData}
          options={{
            colors: ["#91DFFF", "#FF8421"],
          }}
          chartEvents={[
            {
              eventName: "ready",
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
               if(name === "Sensitive Data Users"){
                  var handler = function (e) {
                    var selectedItem = chart.getSelection()[0];
                    if (selectedItem) {
                      if (history) {
                        history.push({
                          ...returnRedirectTo(name)
                        });
                      }
                    }
                  };
                  google.visualization.events.addListener(
                    chartWrapper.getChart(),
                    "select",
                    handler
                  );
                }
              },
            },
          ]}
        />
      );
    } else {
      return (
        <div className="noDataAvailable">No Data Available</div>
      );
    }
  };

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={[totalUsersQuery, sensitiveDataUsersQuery]}
      render={renderMultiLineChart}
      redirectTo={returnRedirectTo(name)}
    />
  );
};
