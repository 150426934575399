import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fetchDMAppList } from "../../redux/actions/dataMapInspectAction";

export default function FilterAppByName(props) {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const nodeData = useSelector((state) => state.dataMapReducer.nodesList);

  const handleOptionSelect = (option, value) => {
    props.filterAppByNameHandle(value);
  };

  useEffect(() => {
    const getAllDropdownValues = async () => {
      let queryString = {};
      setLoading(true);
      if (nodeData) {
        queryString = {
          dimensions: ["Application.name", "Application.id"],
        };
        const queryTypeString = "multi";
        const buildResp = [];
        const response = await fetchDMAppList(queryString, queryTypeString);
        const appFilterRes = response && response.results[0].data;
        console.log("appFilterRes", appFilterRes);
        appFilterRes &&
          appFilterRes.length &&
          appFilterRes.forEach((item) => {
            const listD = {
              name: item["Application.name"],
              id: item["Application.id"],
            };
            buildResp.push(listD);
          });
        setLoading(false);
        setOptions([...buildResp]);
      } else {
        setLoading(false);
        setOptions([]);
      }
    };
    getAllDropdownValues();
  }, [nodeData]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      options={options}
      getOptionLabel={(option) => option.name}
      loading={loading}
      onChange={(option, value) => {
        handleOptionSelect(option, value);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select Applications" variant="outlined" />
      )}
    />
  );
}
