import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EnhancedTable from "../../shared/alertsTable";
import { accessDetailsHeadCells } from "../../common/headCells";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import {
  ACCESS_DETAILS_FILTERVALUES,
  ApiCall_DataSourceNames,
  ApiCall_DatabaseRoles,
  ApiCall_DataSourceTypes,
  // ApiCall_lastAccessedTime,
  // ApiCall_DataSourceNames_Filter,
  // ApiCall_DataAccessRole_Filter,
  // ApiCall_DataAccessAccountStatus_Filter,
  // ApiCall_DataSourceNames_Filter_AccessDetails,
  // ApiCall_DataAccessRole_Filter_AccesDetails,
  // ApiCall_DataSourceType_Filter_AccesDetails,
  sortBy_Filter_AccessDetails,
  sortBy_Filter_AccessDetails_Percentage,
} from "../../../redux/constants/constants";
import { ACCESSDETAILS_FILTERBYARR_UPDATE_ACTION } from "../../../redux/actions/AccessDetailsAction";
// import ChartWrapper from "../../common/Dashboard/Chart";
import { getCubejsApi } from "../../common/Dashboard/cubejs-util";
import { usersWithAlertsBarChart } from "../../common/Dashboard/custom-charts/usersWithAlerts";

export const createCustomObject_AccessDetails_afterGettingSensitivePercentage =
  (SenstivepercentageArr, tableData) => {
    let newTableData = [...tableData];

    SenstivepercentageArr &&
      SenstivepercentageArr.length &&
      newTableData.forEach((obj, index) => {
        SenstivepercentageArr.forEach((SenstivepercentageArrobj) => {
          if (
            SenstivepercentageArrobj.dataSoureId === obj.dataSoureId &&
            SenstivepercentageArrobj.actorId === obj.actorId
          ) {
            obj.sensitiveDataAccessPercentage =
              SenstivepercentageArrobj.percentage;
          }
        });
      });

    console.log(newTableData);
    return newTableData;
  };

export const createCustomObject_AccessDetails = (data) => {
  let customArr = [];
  if (data.length > 0) {
    data.forEach((el) => {
      let obj = {
        username: el.username ? el.username : "",
        roles: el.roles ? el.roles : "",
        dataSourceName: el.dataSourceName ? el.dataSourceName : "",
        sensitiveDataAccessPercentage: el.sensitiveDataAccessPercentage
          ? el.sensitiveDataAccessPercentage
          : "0",
        firstAccessedOn: el.firstAccessedOn ? el.firstAccessedOn : 0,
        lastAccessedOn: el.lastAccessedOn ? el.lastAccessedOn : 0,
        sensitiveDataAccessed: el.sensitiveDataAccessed ? "True" : "False",
        tenantId: el.tenantId ? el.tenantId : "",
        actorId: el.actorId ? el.actorId : "",
        dataSourceId: el.dataSourceId ? el.dataSourceId : "",
        highestAlert: el.highestAlert ? el.highestAlert : "",
        totalAnomalies: el.totalAnomalies ? el.totalAnomalies : "",
      };
      customArr.push(obj);
    });
  }

  return customArr;
};

export const loopFunction = (filterBySelectedValues, filterByValue) => {
  let arr = [];
  filterBySelectedValues.forEach((el) => {
    if (el.isSelected && el) {
      if (filterByValue === ACCESS_DETAILS_FILTERVALUES.DATASOURCE_NAME) {
        arr.push(el.id ? el.id : "");
      } else if (
        filterByValue === ACCESS_DETAILS_FILTERVALUES.DATASOURCE_TYPE
      ) {
        arr.push(el.code);
      } else if (
        filterByValue === ACCESS_DETAILS_FILTERVALUES.DATABASE_ACCESSROLE
      ) {
        arr.push(el.id ? el.id : "");
      }
    }
  });
  return arr;
};
const AccessDetails = () => {
  const [tableData, setTableData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const [getAllAccessDetailsCall, setAccessDetailsCall] = useState(false);
  const handleGetAllAccessDetails = (bool) => {
    if (!bool) {
      setAccessDetailsCall(false);
    } else {
      setAccessDetailsCall(true);
    }
  };
  const filterBySelectedValues = useSelector(
    (state) => state.accessDetails.filterBySelectedValues
  );

  const dispatch = useDispatch();
  const filterByValue = useSelector((state) => state.accessDetails.filterValue);
  const sortByValue = useSelector((state) => state.accessDetails.sortByValue);
  const accessDetailsFilterClear = useSelector(
    (state) => state.accessDetails.accessDetailsFilterClear
  );

  const sortClicked = useSelector((state) => state.accessDetails.sortClicked);
  const [currentSortDirection, setCurrentSortDirection] = useState("ASC");

  const AccessDetailsAPIcall = async () => {
    setTableData([]);
    setDataLoaded(false);
    const arr = loopFunction(filterBySelectedValues, filterByValue);
    const str = arr.join("");
    const res = await getService({
      method: "GET",
      url: sortBy_Filter_AccessDetails(
        filterByValue,
        sortByValue,
        str,
        currentSortDirection,
        localStorage.getItem("rowsperPage")
          ? localStorage.getItem("rowsperPage")
          : 20,
        0
      ),
      headers: getAxiosHeaders(true),
    });

    if (res.status === 200) {
      setDataLoaded(true);
      let customArr = createCustomObject_AccessDetails(
        res.data ? res.data.content : []
      );
      setTableData([...customArr]);
      setTotalElements(res.data.totalElements);

      //todo:To make second API call seperately and show percentage of sensitive data accessed
      let payload = customArr.map((obj) => {
        return {
          tenantId: obj.tenantId,
          actorId: obj.actorId,
          dataSourceId: obj.dataSourceId,
        };
      });
      const sensitiveDataAccessed_Response = await getService({
        method: "POST",
        url: sortBy_Filter_AccessDetails_Percentage(),
        data: { actorSensitivePercentages: payload },
        headers: getAxiosHeaders(true),
      });
      console.log(
        sensitiveDataAccessed_Response,
        "sensitiveDataAccessed_Response"
      );
      if (sensitiveDataAccessed_Response.status === 200) {
        const outArr =
          createCustomObject_AccessDetails_afterGettingSensitivePercentage(
            sensitiveDataAccessed_Response.data
              ? sensitiveDataAccessed_Response.data
              : [],
            customArr
          );
        setTableData(outArr);
      }

      // setResetPage(true);
    } else {
    }
  };

  const FilterAPICall = async (url) => {
    const res = await getService({
      method: "GET",
      url: url,
      headers: getAxiosHeaders(true),
    });

    if (res.status === 200) {
      if (filterByValue === ACCESS_DETAILS_FILTERVALUES.DATASOURCE_NAME) {
        dispatch(ACCESSDETAILS_FILTERBYARR_UPDATE_ACTION(res.data));
      } else {
        dispatch(ACCESSDETAILS_FILTERBYARR_UPDATE_ACTION(res.data.content));
      }
    }
  };

  useEffect(() => {
    if (filterByValue === ACCESS_DETAILS_FILTERVALUES.DATASOURCE_NAME) {
      FilterAPICall(ApiCall_DataSourceNames);
    } else if (
      filterByValue === ACCESS_DETAILS_FILTERVALUES.DATABASE_ACCESSROLE
    ) {
      FilterAPICall(ApiCall_DatabaseRoles);
    } else if (filterByValue === ACCESS_DETAILS_FILTERVALUES.DATASOURCE_TYPE) {
      FilterAPICall(ApiCall_DataSourceTypes);
    } else if (
      filterByValue === ACCESS_DETAILS_FILTERVALUES.LASTTIME_ACCESSED
    ) {
      dispatch(ACCESSDETAILS_FILTERBYARR_UPDATE_ACTION([]));
    }
  }, [filterByValue]);

  const filterAPIcall = async (
    url,
    str,
    sortDirection,
    filterType,
    sortType
  ) => {
    setTableData([]);
    setDataLoaded(false);
    const res = await getService({
      method: "GET",
      url: url(
        filterType,
        sortType,
        str,
        sortDirection,
        localStorage.getItem("rowsperPage")
          ? localStorage.getItem("rowsperPage")
          : 20,
        0 //No Issues we can use actors page
      ),
      headers: getAxiosHeaders(true),
    });

    if (res.status === 200) {
      setDataLoaded(true);
      let customArr = createCustomObject_AccessDetails(
        res.data ? res.data.content : []
      );

      setTableData([...customArr]);
      setTotalElements(res.data.totalElements);
      setResetPage(true);

      //todo:To make second API call seperately and show percentage of sensitive data accessed
      let payload = customArr.map((obj) => {
        return {
          tenantId: obj.tenantId,
          actorId: obj.actorId,
          dataSourceId: obj.dataSourceId,
        };
      });
      const sensitiveDataAccessed_Response = await getService({
        method: "POST",
        url: sortBy_Filter_AccessDetails_Percentage(),
        data: { actorSensitivePercentages: payload },
        headers: getAxiosHeaders(true),
      });
      console.log(
        sensitiveDataAccessed_Response,
        "sensitiveDataAccessed_Response"
      );
      if (sensitiveDataAccessed_Response.status === 200) {
        const outArr =
          createCustomObject_AccessDetails_afterGettingSensitivePercentage(
            sensitiveDataAccessed_Response.data
              ? sensitiveDataAccessed_Response.data
              : [],
            customArr
          );
        setTableData(outArr);
      }
    }
  };
  const APICallFunction = (filterByValue, arr, curSortValue) => {
    let str = arr.join(",");
    filterAPIcall(
      sortBy_Filter_AccessDetails,
      str,
      currentSortDirection,
      filterByValue,
      curSortValue ? curSortValue : sortByValue
    );
  };
  useEffect(() => {
    const arr = loopFunction(filterBySelectedValues, filterByValue);
    if (arr.length > 0) {
      if (filterBySelectedValues.length > 0) {
        APICallFunction(filterByValue, arr);
      } else {
        setTableData([]);
      }
    }
  }, [filterBySelectedValues]);

  useEffect(() => {
    if (sortByValue) {
      const arr = loopFunction(filterBySelectedValues, filterByValue);
      APICallFunction(filterByValue, arr, sortByValue);
    }
  }, [sortByValue]);

  useEffect(() => {
    if (accessDetailsFilterClear.value) {
      let clearAll = true;
      AccessDetailsAPIcall(clearAll);
    } else {
      AccessDetailsAPIcall();
    }

    // return () => {
    //   dispatch({ type: "ACCESSDETAILS_CLEARALL" });
    // };
  }, [accessDetailsFilterClear]);

  let cubejsApi = getCubejsApi();
  return (
    <>
      <div style={{ marginLeft: "13rem", maxWidth: "84%" }}>
        {usersWithAlertsBarChart(
          cubejsApi,
          "Users with Anomalies over Time",
          "Users Accessing Data"
        )}
        {/* <UsersWithAlertsBarChart
          cubejsApi={cubejsApi}
          name="Users with Anomalies over Time"
          graphname1="Users Accessing Data"
        ></UsersWithAlertsBarChart> */}
      </div>
      <div className="filter-table-container">
        <div className="table-container">
          <EnhancedTable
            tableData={tableData}
            headCells={accessDetailsHeadCells}
            check={true}
            loc="Access_details"
            totalElements={totalElements}
            handleGetAllAccessDetails={handleGetAllAccessDetails}
            getAllAccessDetailsCall={getAllAccessDetailsCall}
            dataLoaded={dataLoaded}
            setDataLoadedAccessDetails={setDataLoaded}
            resetPage={resetPage}
            setResetPage={setResetPage}
            currentSortDirection={currentSortDirection}
            setCurrentSortDirection={setCurrentSortDirection}
            description={
              "Data source users along with their direct data access information. click on the entry to view access details and anomalies"
            }
          />
        </div>
      </div>
    </>
  );
};

export default AccessDetails;
