import { SET_TOTAL_ASSETS_COUNT, SET_ONEDRIVE_ASSETS_COUNT, SET_GOOGLEDRIVE_ASSETS_COUNT, SET_DROPBOX_ASSETS_COUNT, CLEAR_ALL_ASSETS_TOTAL_COUNT } from "../constants/constants";

const initialState = {
  totalAssetsCount: 0,
  oneDriveAssetsCount: 0,
  dropBoxAssetsCount: 0,
  googleDriveAssetsCount: 0
}

export const TotalAssetsCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTAL_ASSETS_COUNT: {
      return {
        ...state,
        totalAssetsCount: action.payload
      }
    }
    case SET_ONEDRIVE_ASSETS_COUNT: {
      return {
        ...state,
        oneDriveAssetsCount: action.payload
      }
    }
    case SET_DROPBOX_ASSETS_COUNT: {
      return {
        ...state,
        dropBoxAssetsCount: action.payload
      }
    }
    case SET_GOOGLEDRIVE_ASSETS_COUNT: {
      return {
        ...state,
        googleDriveAssetsCount: action.payload
      }
    }
    case CLEAR_ALL_ASSETS_TOTAL_COUNT: {
      return {
        ...state,
        totalAssetsCount: 0,
        oneDriveAssetsCount: 0,
        dropBoxAssetsCount: 0,
      }
    }
    default:
      return state;
  }
}