import { UPDATE_POLICY_STATUS_CHANGE_SUCCEEDED,CLOSE_SNACKBAR,UPDATE_POLICY_STATUS_CHANGE_FAILED } from '../constants/constants';

const initialState = {
    policyStatusUpdated : false,
    successMessage: '',
    unsuccessful : ''
  };

  export const updateAlertPolicyStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_POLICY_STATUS_CHANGE_SUCCEEDED: {
            return {
              ...state,
              successMessage: 'Policy(s) Status Updated',
              policyStatusUpdated: true
            };
          }
          case UPDATE_POLICY_STATUS_CHANGE_FAILED: {
            console.log("UPDATE_POLICY_STATUS_CHANGE_FAILED");
            return {
              ...state,
              policyStatusUpdated: false,
              unsuccessful: action.payload,
            };
          }
          case CLOSE_SNACKBAR: {
            console.log("CLOSE_SNACKBAR");
            return {
              ...state,
              successMessage: '',
              policyStatusUpdated: false
            };
          }
          default:
            return state;
    }
  };
