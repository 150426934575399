import React from "react";
import ForgotPasswordLeft from "../ForgotPassword/ForgotPasswordLeft";
// import ForgotPasswordRight from "../ForgotPassword/ForgotPasswordRight";
import ResetPasswordRight from "../ResetPassword/ResetPasswordRight";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import "../../../assets/scss/admin-styles/custom/loginpagestyle.scss";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  forgotPassword_container: {
    margin: "auto",
    border: "1px solid grey",
    height: "40rem",
    width: "80%",
    minWidth: "25%",
    borderRadius: "50px",
    fontFamily: "Nunito-Regular",
    backgroundColor: "white",
  },
  setPassword_container: {
    margin: "auto",
    border: "1px solid grey",
    height: "40rem",
    width: "80%",
    minWidth: "25%",
    borderRadius: "50px",
    fontFamily: "Nunito-Regular",
    backgroundColor: "white",
  },
});
const ResetPasswordContainer = ({
  email,
  code,
  tenant,
  featureName,
  setPassword,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={
        !setPassword
          ? classes.forgotPassword_container
          : classes.setPassword_container
      }
    >
      <ForgotPasswordLeft></ForgotPasswordLeft>
      <ResetPasswordRight
        email={email}
        code={code}
        tenant={tenant}
        featureName={featureName}
      ></ResetPasswordRight>
    </Grid>
  );
};

export default ResetPasswordContainer;
