import React, { useEffect, useState } from "react";

import TableDataUpdateComponent from "../common/Dashboard/MDRTables/TableDataUpdateComponent";

import DashboardMDRWidgets from "./DashboardMDRWidgets";
import { GET_MDR_GLOBAL_FILTERS } from "../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";

export const MDRContext = React.createContext();

function MDRDashboard() {
  useEffect(() => {
    console.log("useEffect Parent MDR");
    //TEST COMMENT
  });
  const [globalFiltersIntialList, setGlobalFilters] = React.useState([]);

  const [dataLoaded, setDataLoaded] = useState(false);

  const getGlobalFilters = async () => {
    const response = await getService({
      method: "GET",
      url: `${GET_MDR_GLOBAL_FILTERS}`,
      headers: getAxiosHeaders(true),
    });

    console.log(response, "response");
    if (response.status === 200 && response.data.content) {
      setGlobalFilters(
        response.data && response.data.content ? response.data.content : []
      );
      sessionStorage.setItem(
        "globalFiltersIntialList",
        JSON.stringify(
          response.data && response.data.content ? response.data.content : []
        )
      );
    }
  };
  useEffect(() => {
    getGlobalFilters();
  }, []);
  return (
    <div style={{ backgroundColor: "#F7F8FC" }}>
      <MDRContext.Provider
        value={{
          dataLoaded: dataLoaded,
          setDataLoaded: setDataLoaded,
        }}
      >
        <DashboardMDRWidgets
          globalFiltersIntialList={globalFiltersIntialList}
          setGlobalFilters={setGlobalFilters}
        ></DashboardMDRWidgets>
        <TableDataUpdateComponent
          globalFiltersIntialList={globalFiltersIntialList}
        ></TableDataUpdateComponent>
      </MDRContext.Provider>
    </div>
  );
}
export default React.memo(MDRDashboard);
