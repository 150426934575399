import { call, put, takeEvery } from "redux-saga/effects";
import { FETCH_USER_REQUEST } from "../constants/signinpageActionTypes";
import axios from "axios";
import { getService, getAxiosHeaders } from "../../../src/library/RestAPI";
import {
  OAUTH_URL,
  USERS_ME,
  DATASOURCE_URL2,
  viewApplication,
  GET_SINGLE_USER,
  GET_USER_PREFERENCES_URL,
  GET_TENANT_BY_FEATURES,
  GET_TENANT_LOGO,
} from "../constants/constants";
import { removeDuplicates } from "../../components/common/utilities";
import { isNull } from "lodash";
// import { ClickAwayListener } from "@material-ui/core";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions

function* fetchUserwithMFA(action) {
  try {
    const myStorage = window.localStorage;
    const data = new URLSearchParams();
    data.append("scope", "write");
    data.append("grant_type", "mfa");
    data.append("mfa_token", action.payload.mfa_token);
    data.append("verification_code", action.payload.mfa_code);
    try {
      const res = yield axios({
        method: "POST",
        url: OAUTH_URL,
        data: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Tenant: action.payload.tenant,
        },
      });
      console.log(res);
      if (res.status === 200) {
        let tempArr = [];
        myStorage.setItem("access_token", res.data.access_token);
        myStorage.setItem("refresh_token", res.data.refresh_token);
        myStorage.setItem("tenant", action.payload.tenant);

        const apiCall2 = yield call(() =>
          getService({
            method: "GET",
            url: USERS_ME(action.payload.tenant),
            headers: getAxiosHeaders(true),
          })
        );
        let singleUser;
        if (apiCall2.data) {
          localStorage.setItem(
            "displayName",
            apiCall2.data.displayName ? apiCall2.data.displayName : ""
          );
          localStorage.setItem(
            "email",
            apiCall2.data.email ? apiCall2.data.email : ""
          );
          localStorage.setItem(
            "tenantId",
            apiCall2.data.tenantId ? apiCall2.data.tenantId : ""
          );
          const tenantData = yield call(() =>
            getService({
              method: "GET",
              url: GET_TENANT_BY_FEATURES(apiCall2.data.tenantId),
              headers: getAxiosHeaders(true),
            })
          );
          if (tenantData.status === 200) {
            myStorage.setItem("featuresInfo", JSON.stringify(tenantData.data));
          }
          singleUser = yield call(() =>
            getService({
              method: "GET",
              url: GET_SINGLE_USER(action.payload.tenant, apiCall2.data.id),
              headers: getAxiosHeaders(true),
            })
          );
          if (singleUser.status === 200) {
            //console.log(singleUser.data.profilePicture);
            localStorage.setItem(
              "profilePicture",
              singleUser.data.profilePicture
                ? singleUser.data.profilePicture
                : ""
            );
          }
        }
        //Store multiple roles as we are receiving multiple roles
        const roles = (apiCall2.data && apiCall2.data.roles && apiCall2.data.roles.length > 0)
          ? apiCall2.data.roles.map((role) => role.name)
          : [];
        const permissions = apiCall2.data.roles.reduce((acc, role) => acc.concat(role.permissions), []);
        const allPermissions = removeDuplicates(permissions);
        //console.log(permissions);
        if (allPermissions.length > 0) {
          localStorage.setItem("PERMISSIONS", JSON.stringify(allPermissions));
          yield put({ type: "PERMISSIONS", payload: allPermissions });
        }

        yield localStorage.setItem("user_role", roles);

        if (apiCall2.status === 200) {
          yield put({
            type: "USER_FETCH_SUCCEEDED",
            payload: apiCall2.data,
            profilePicture: singleUser.profilePicture,
          });
        }

        if (tempArr.indexOf(viewApplication) > -1) {
          const getApplicationData = yield call(() =>
            getService({
              method: "GET",
              url: DATASOURCE_URL2,
              headers: getAxiosHeaders(true),
            })
          );

          const getUserPrefereneces = yield call(() =>
            getService({
              method: "GET",
              url: `${GET_USER_PREFERENCES_URL}`,
              headers: getAxiosHeaders(true),
            })
          );
          const preferences = yield getUserPrefereneces;
          if (
            JSON.parse(preferences.data.preferences).nodePositions.length > 0
          ) {
            const data = JSON.parse(preferences.data.preferences).nodePositions
              .length
              ? JSON.parse(preferences.data.preferences).nodePositions
              : [];
            localStorage.setItem("nodePosition", JSON.stringify(data));
            yield put({
              type: "NODE_POSITIONS",
              data: JSON.parse(preferences.data.preferences).nodePositions
                .length
                ? JSON.parse(preferences.data.preferences).nodePositions
                : [],
            });
          }
          const applications = yield getApplicationData.data.content;
          if (applications.length > 0) {
            yield put({ type: "APPLICATIONS", payload: applications });
          }
        }
      }
    } catch (error) {
      let errorResponse = error.response ? error.response : "";
      let errorMessage = errorResponse.data
        ? errorResponse.data.error_description
          ? errorResponse.data.error_description
          : "Server error.Please try again"
        : "Server error.Please try again";
      yield put({
        type: "USER_FETCH_FAILED",
        payload: errorMessage,
      });
    }
  } catch (error) {}
}

function* fetchUser(action) {
  console.log("testingsaga");
  try {
    const myStorage = window.localStorage;
    console.log(action.payload.signPageUsername, "testing");
    const data = new URLSearchParams();
    data.append("scope", "write");
    data.append("grant_type", "password");
    data.append("username", action.payload.signPageUsername);
    data.append("password", action.payload.signPagePassword);
    try {
      const res = yield axios({
        method: "POST",
        url: OAUTH_URL,
        data: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Tenant: action.payload.tenant,
        },
      });

      if (res.status === 200) {
        let tempArr = [];
        // console.log(`Bearer Token:${res.data.access_token}`);
        myStorage.setItem("access_token", res.data.access_token);
        myStorage.setItem("refresh_token", res.data.refresh_token);
        myStorage.setItem("tenant", action.payload.tenant);

        const apiCall2 = yield call(() =>
          getService({
            method: "GET",
            url: USERS_ME(action.payload.tenant),
            headers: getAxiosHeaders(true),
          })
        );
        let singleUser;
        if (apiCall2.data) {
          localStorage.setItem(
            "displayName",
            apiCall2.data.displayName ? apiCall2.data.displayName : ""
          );
          localStorage.setItem(
            "email",
            apiCall2.data.email ? apiCall2.data.email : ""
          );
          localStorage.setItem(
            "tenantId",
            apiCall2.data.tenantId ? apiCall2.data.tenantId : ""
          );
          const tenantData = yield call(() =>
            getService({
              method: "GET",
              url: GET_TENANT_BY_FEATURES(apiCall2.data.tenantId),
              headers: getAxiosHeaders(true),
            })
          );
          if (tenantData.status === 200) {
            myStorage.setItem("featuresInfo", JSON.stringify(tenantData.data));
          }
          singleUser = yield call(() =>
            getService({
              method: "GET",
              url: GET_SINGLE_USER(action.payload.tenant, apiCall2.data.id),
              headers: getAxiosHeaders(true),
            })
          );
          if (singleUser.status === 200) {
            //console.log(singleUser.data.profilePicture);
            localStorage.setItem(
              "profilePicture",
              singleUser.data.profilePicture
                ? singleUser.data.profilePicture
                : ""
            );
          }
        }
        //Store multiple roles as we are receiving multiple roles
        const roles = (apiCall2.data && apiCall2.data.roles && apiCall2.data.roles.length > 0)
          ? apiCall2.data.roles.map((role) => role.name)
          : [];
        const permissions = apiCall2.data.roles.reduce((acc, role) => acc.concat(role.permissions), []);
        const allPermissions = removeDuplicates(permissions);
        if (allPermissions.length > 0) {
          localStorage.setItem("PERMISSIONS", JSON.stringify(allPermissions));
          yield put({ type: "PERMISSIONS", payload: allPermissions });
        }

        yield localStorage.setItem("user_role", roles);

        if (apiCall2.status === 200) {
          //console.log(apiCall2.data);
          yield put({
            type: "USER_FETCH_SUCCEEDED",
            payload: apiCall2.data,
            profilePicture: singleUser.profilePicture,
          });
        }

        if (tempArr.indexOf(viewApplication) > -1) {
          const getApplicationData = yield call(() =>
            getService({
              method: "GET",
              url: DATASOURCE_URL2,
              headers: getAxiosHeaders(true),
            })
          );

          const getUserPrefereneces = yield call(() =>
            getService({
              method: "GET",
              url: `${GET_USER_PREFERENCES_URL}`,
              headers: getAxiosHeaders(true),
            })
          );
          const preferences = yield getUserPrefereneces;
          if (
            JSON.parse(preferences.data.preferences).nodePositions.length > 0
          ) {
            const data = JSON.parse(preferences.data.preferences).nodePositions
              .length
              ? JSON.parse(preferences.data.preferences).nodePositions
              : [];
            localStorage.setItem("nodePosition", JSON.stringify(data));
            yield put({
              type: "NODE_POSITIONS",
              data: JSON.parse(preferences.data.preferences).nodePositions
                .length
                ? JSON.parse(preferences.data.preferences).nodePositions
                : [],
            });
          }
          const applications = yield getApplicationData.data.content;
          if (applications.length > 0) {
            yield put({ type: "APPLICATIONS", payload: applications });
          }
        }
      }
    } catch (error) {
      let errorResponse = error.response ? error.response : "";
      let errorMessage = errorResponse.data
        ? errorResponse.data.error_description
          ? errorResponse.data.error_description
          : "Server error.Please try again"
        : "Server error.Please try again";
      let errorMsg = errorResponse.data
        ? errorResponse.data.error
          ? errorResponse.data.error
          : ""
        : "";
      let mfa_token = errorResponse.data
        ? errorResponse.data.mfa_token
          ? errorResponse.data.mfa_token
          : ""
        : "";
      if (errorMsg === "mfa_required") {
        sessionStorage.setItem("mfa_token", mfa_token);
        yield put({
          type: "USER_FETCH_FAILED_DUE_TO_MFA_ENABLED",
          payload: { mfa_token: mfa_token },
        });
      } else {
        yield put({
          type: "USER_FETCH_FAILED",
          payload: errorMessage,
        });
      }
    }
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED" });
  }
}
/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* signInSaga() {
  yield takeEvery(FETCH_USER_REQUEST, fetchUser);
  yield takeEvery("FETCH_USER_REQUEST_WITH_MFA", fetchUserwithMFA);
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/

export default signInSaga;
