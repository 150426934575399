import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { each, isEmpty } from "lodash";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { renderSensitivityValue } from "../common/utilities";

export default function InspectSelectedFilterView(props) {
  const { filterInfo, handleOnChipDelete } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "220px",
      overflowY: "auto",
      borderLeft: "2px solid grey",
      padding: "10px",
      height: "185px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.14",
      letterSpacing: "normal",
      color: "black",
    },
    FilterHead: {
      fontSize: "12px",
      lineHeight: 1.22,
      color: "black",
    },
  }));

  const classes = useStyles();
  const handleDelete = (data) => {
    handleOnChipDelete(data.values, data.member)
  };

  const renderChipWithClose = (data) => {
      return (
        <Box p={1}>
          <Chip
            label={renderSensitivityValue(data.values)}
            size="medium"
            style={{ borderRadius: "3px", marginBottom: "2px" }}
            onDelete={() => handleDelete(data)}
            deleteIcon={<CloseIcon />}
          />
        </Box>
      );
  };

  const renderChipWithOutClose = (data) => {
      return (
        <Box p={1}>
          <Chip
            label={renderSensitivityValue(data.values)}
            size="medium"
            style={{ borderRadius: "3px", marginBottom: "2px" }}
          />
        </Box>
      );
  };

  const renderData = (selectedValues) => {
    const result = [];
    selectedValues.map((data) => {
      let member = data.member
      data.values.forEach((each) => {
        result.push({ member: member, values: each });
      })
    });
    return result.map((data) => {
      if (!isEmpty(data.values)) {
        return renderChipWithClose(data);
      } else {
        return renderChipWithOutClose(data);
      }
    });
  };

  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormGroup aria-label="position" row>
        <div
          style={{
            wordBreak: "break-all",
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
            paddingLeft: "1.5rem",
            overflow: "auto",
          }}
        >
          {renderData(filterInfo)}
        </div>
      </FormGroup>
    </FormControl>
  );
}
