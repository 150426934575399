import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { getService, getAxiosHeaders } from "../../../../library/RestAPI";

import {
  GET_IP_URL,
  TENANT_MANAGEMENT_URL,
  MDR_SETTINGS_UPDATE_LOCATION,
  createSubnet,
} from "../../../../redux/constants/constants";
import { DeleteButtonForMDR, AddMDRConfig } from "../../../common/buttons";
import { sanitizeDOMString } from "../../../common/utilities";
import Page_Loader from "../../../../assets/vizr_images/page_loader.gif";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { cloneDeep, isEqual, uniqBy } from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import MDRInputCommon from "../../../layouts/MDRContainer/MDRInputCommon";
import { useParams } from "react-router-dom";
import { IpConfigFields, IpConfigFieldsState } from "../utilities";
import ManagementFooter from "../../../views/BackOfficeGeneralContainer/ManagementFooter";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const AddIp = (props) => {
  const queryParam = useParams();
  const dispatch = useDispatch();
  let history = useHistory();
  let name;
  let platformSubdomainNameFrom;

  if (props.location.state) {
    name = props.location.state.name;
    platformSubdomainNameFrom = props.location.state.platformSubdomainNameFrom;
  }

  const [openSuccess, setopenSuccess] = useState(false);
  const [openError, setopenError] = useState({ open: false, message: "" });
  const [errorStateArr, seterrorStateArr] = useState([]);
  const [inputFieldsNames, setInputFieldNames] = useState([]);
  const [addFeild, setAddFeild] = useState([1]);
  const [state, setState] = useState([]);

  const checkAllFieldsFilledFunction = () => {
    let errorsArr = cloneDeep([...errorStateArr]);
    let ApiCall = true;
    state.forEach((stateObj, index) => {
      for (const property in errorsArr[index]) {
        if (!stateObj[property]) {
          errorsArr[index][property] = "Required Field";
          ApiCall = false;
        } else if (property === "ip") {
          if (
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              stateObj[property]
            )
          ) {
            errorsArr[index][property] = "";
          } else {
            errorsArr[index][property] = "Invalid IP";
            ApiCall = false;
          }
        } else if (property === "subnet" && stateObj[property]) {
          if (
            typeof stateObj[property] !== "number" &&
            isNaN(Number(stateObj[property]))
          ) {
            ApiCall = false;
            errorsArr[index][property] = "Subnet  must be a number";
          } else {
            errorsArr[index][property] = "";
          }
        } else {
          errorsArr[index][property] = "";
        }
      }
      seterrorStateArr(cloneDeep([...errorsArr]));
    });
    return ApiCall;
  };

  const submitHandler = () => {
    const PostFunction = async () => {
      const data = getAxiosHeaders(true);
      data.Tenant = name;
      data["platform-subdomain-name"] = platformSubdomainNameFrom;

      const res = await getService({
        method: "POST",
        url: `${GET_IP_URL}`,
        headers: data,
        data: state,
      });
      if (res.status === 200) {
        setopenSuccess(true);
        setState([{ ...IpConfigFieldsState }]);
      } else {
        setopenError({
          open: true,
          message:
            res.response && res.response.data && res.response.data.errorMessage,
        });
      }
    };
    let checkAllFieldsFilled = checkAllFieldsFilledFunction();
    if (checkAllFieldsFilled && state.length > 0) {
      let duplicates = [];
      for (let i = 0; i < state.length - 1; i++) {
        for (let j = 1; j < state.length; j++) {
          if (isEqual(state[i], state[j])) {
            duplicates.push(state[i]);
          }
          break;
        }
      }
      if (duplicates.length === 0) {
        PostFunction();
      } else {
        let result = "";
        duplicates = uniqBy(duplicates, "ip");

        duplicates.forEach((data) => {
          result =
            result +
            `${data.ip} ip and ${data.subnet} subnet are duplicated  <br/>`;
        });

        setopenError({
          open: true,
          message: result,
        });
      }
    } else if (!state.length) {
      setopenError({
        open: true,
        message: "Please add at least one row",
      });
    }
  };

  useEffect(() => {
    setInputFieldNames(IpConfigFields);
    setState([IpConfigFieldsState]);
    seterrorStateArr([IpConfigFieldsState]);
    dispatch({
      type: MDR_SETTINGS_UPDATE_LOCATION,
      payload: createSubnet,
    });
  }, []);

  const inputHandler = (event, index1, inputFieldsNames, inputValue) => {
    let errorsArr = [...errorStateArr];
    let errItem = { ...errorStateArr[index1] };
    // 1. Make a shallow copy of the items
    let items = [...state];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...state[index1] };
    // 3. Replace the property you're intested in
    if (inputFieldsNames === "IP") {
      item.ip = event.target.value;
      errItem.ip = "";
    } else if (inputFieldsNames === "Subnet") {
      item.subnet = event.target.value;
      errItem.subnet = "";
    }

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index1] = item;
    errorsArr[index1] = errItem;
    // 5. Set the state to our new copy
    setState([...items]);
    seterrorStateArr(errorsArr);
  };

  const addClickHandler = () => {
    setAddFeild((prevState) => [...prevState, 1]);
    setState((prevState) => [...prevState, IpConfigFieldsState]);
    seterrorStateArr((prevState) => [...prevState, IpConfigFieldsState]);
  };

  const deleteClickhandler = (index) => {
    let latestClonnedState = cloneDeep(state);
    latestClonnedState.splice(index, 1);
    let clonnedErrorStateArr = cloneDeep(errorStateArr);
    clonnedErrorStateArr.splice(index, 1);
    addFeild.splice(index, 1);
    setState([...latestClonnedState]);
    setAddFeild(addFeild);
    seterrorStateArr([...clonnedErrorStateArr]);
  };

  const renderHeader = () => {
    return (
      <div
        style={{ fontSize: "20px", paddingTop: "20px", marginLeft: "230px" }}
      >
        {renderBreadCrumb()}
        <b>Add Forward IP / Subnets</b>
      </div>
    );
  };

  const cancelHandler = () => {
    history.push({
      pathname: TENANT_MANAGEMENT_URL(queryParam),
      state: {
        name: name,
        features: ["exists"],
        routFrom: "ip",
        platformSubdomainName: platformSubdomainNameFrom,
      },
    });
  };

  const renderForm = () => {
    return (
      <>
        <AddMDRConfig
          className="createUserButton"
          onClick={addClickHandler}
          style={{
            float: "right",
            margin: "30px 0px",
            backgroundColor: "rgb(41, 71, 253)",
            marginRight: "10px",
          }}
        >
          Add
        </AddMDRConfig>
        {state.length
          ? state.map((el, index1) => (
              <Grid
                container
                direction="row"
                className="MDRInputField_flex"
                key={index1}
              >
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="MDRinputFieldNumber_container">
                    <div className="MDRinputFieldNumber">{index1 + 1}</div>
                  </div>
                </Grid>
                <MDRInputCommon
                  inputFieldsNames={inputFieldsNames}
                  state={state}
                  inputHandler={inputHandler}
                  index1={index1}
                  errorStateArr={errorStateArr}
                  loc={createSubnet}
                ></MDRInputCommon>

                <div
                  style={{
                    marginLeft: "2px",
                    marginTop: "2.2rem",
                  }}
                >
                  <DeleteButtonForMDR
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      deleteClickhandler(index1);
                    }}
                  >
                    <DeleteIcon />
                  </DeleteButtonForMDR>
                </div>
              </Grid>
            ))
          : null}
      </>
    );
  };

  const renderFormHeading = () => {
    return <div className="ManagementContent_Container">{renderForm()}</div>;
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSuccess(false);
    setopenError({ open: false, message: "" });
  };

  const renderFooter = () => {
    return (
      <ManagementFooter
        cancelHandler={cancelHandler}
        submitHandler={() => submitHandler()}
        checkedValue={state.length > 0 ? true : false}
        classname="createUserFooter"
      ></ManagementFooter>
    );
  };
  const renderBreadCrumb = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" onClick={() => cancelHandler()}>
          Threat Insights
        </Link>
        <Typography color="textPrimary">Add Forward IP / Subnets</Typography>
      </Breadcrumbs>
    );
  };
  const renderSnackbar = () => {
    return (
      <>
        <Snackbar
          open={openError.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeDOMString(openError.message),
              }}
            ></div>
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="success">
            Added configuration details successful
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <>
      {false && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )}
      {renderSnackbar()}
      {renderHeader()}
      {renderFormHeading()}
      {renderFooter()}
    </>
  );
};
