import React, { useState ,useEffect } from "react";
import { useDispatch } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../../../common/Navbar/customCalender.scss";
import { NewPolicyButtonForPolicyCreateEdit } from "../../../common/buttons";
  import {
    Calendar,
    DefinedRange,
    createStaticRanges,
  } from "react-date-range";

export default function DateTimeRage({
  closeCalender,
  onTimeChange,
  expiryDate
}) {
  const [date, setDate] = useState(null);
  const dispatch = useDispatch();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  
  useEffect(() => {
    setDate(expiryDate);
  }, [expiryDate]);

  console.log("date" ,date)

  const dateRangePickerStaticList2 = () => {
    const customDateObjects = [
      {
        label: "10 days",
        range: () => ({
          startDate: 10,
          endDate: null,
        }),
      },
      {
        label: "20 days",
        range: () => ({
          startDate: 20,
          endDate: null,
        }),
      },
      {
        label: "30 days",
        range: () => ({
          startDate: 30,
          endDate: null,
        }),
      },
      {
        label: "6 Months",
        range: () => ({
          startDate: 180,
          endDate: null,
        }),
      },
      {
        label: "1 Year",
        range: () => ({
          startDate: 365,
          endDate: null,
        }),
      },
      {
        label: "2 Years",
        range: () => ({
          startDate: 730,
          endDate: null,
        }),
      },
    ];
    return customDateObjects;
  };

  
  const staticRanges2 = [...createStaticRanges(dateRangePickerStaticList2())];

  const handleSelect2 = (date, calenderStaticOptions) => {
    if (calenderStaticOptions) {
      // let dates = new Date(date.startDate ? date.startDate : date);
      setDate(date);
      closeCalender();
      onTimeChange(calenderStaticOptions, date.startDate);
      // dispatch({ type: "RESET_BATCHEDIT_CLICK" });
    } else {
      let dates = new Date(date.startDate ? date.startDate : date);
      setDate(date);
      onTimeChange(calenderStaticOptions, dates.getTime());
      closeCalender();
    }
  };

  return (
    <>
        <div
          className="dateRange-time-picker"
          style={{ height: "520px", zIndex: "1" }}
        >
          <div className="dateRanger-header">
            <span className="quick-range">Expire after</span>

            <span className="select-range" style={{ marginLeft: "10rem" }}>
              Select Expiry Date
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <DefinedRange
              onChange={(item) => {
                setState([item.selection]);
                handleSelect2(item.selection, "calenderStaticOptions");
              }}
              ranges={state}
              staticRanges={staticRanges2}
              inputRanges={[]}
            />
            <div className="calenderContainer">
              <Calendar
                onChange={handleSelect2}
                minDate={new Date()}
                date={date}
                className="customDate"
              />
            </div>
          </div>
          <NewPolicyButtonForPolicyCreateEdit
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              dispatch({ type: "RESET_BATCHEDIT_CLICK" });
              dispatch({ type: "CLOSECALENDER", payload: "" });
              closeCalender();
            }}
            className="min-margin"
            style={{ float: "right", marginTop: "1rem", marginRight: "23px" }}
          >
            CLOSE
          </NewPolicyButtonForPolicyCreateEdit>
        </div>
    </>
  );
}
