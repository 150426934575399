import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import "../../../assets/scss/admin-styles/custom/loginpagestyle.scss";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../views/Input";
import { RESETPASSWORD_MAIL, SERVER_ERROR_MESSAGE } from "../../../redux/constants/constants";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import { getTenant } from "../../../components/shared/getTenant";
import forGotpassword from "../../../assets/vizr_images/forGotpassword.png";
import { cssVariables } from "../../../cssVariables";
import SnackBar2 from "../../shared/SnackBar2";
import { Emailvalidator } from "../../views/Util/Emailvalidator";

const useStyles = makeStyles({
  forgotPassword_container_right: {},
  forgotpassword_Input: {
    marginTop: "30px",
  },
  forgotpassword_anchor: {
    color: (theme) => theme.colors.$lightRoyalBlue,
    textDecoration: "none",
    height: "22px",
    width: "175px",
    fontSize: "16px",
    letterSpacing: 0,
    lineHeight: "22px",
    marginBottom: "2rem",
    fontFamily: "Nunito-Regular",
    "&:hover": {
      color: (theme) => theme.colors.$lightRoyalBlue,
    },
  },
  forgotpassword_text: {
    height: "24px",
    width: "320px",
    color: (theme) => theme.colors.$black,
    fontFamily: "Nunito-Regular !important",
    fontSize: "24px",
    letterSpacing: "0",
    lineHeight: "24px",
    marginBottom: "3.5rem",
  },
  forgotPassword_paragraph: {
    height: "44px",
    width: "320px",
    maxWidth: "100%",
    fontFamily: "Nunito-Regular !important",
    opacity: "0.8",
    color: (theme) => theme.colors.$lightGrey,
    fontSize: "20px",
    letterSpacing: "0",
    lineHeight: "22px",
  },
  root: {
    fontFamily: "Nunito-Regular !important",
    backgroundColor: (theme) => theme.colors.$lightRoyalBlue,
    marginTop: "2rem",
    marginBottom: "1.2rem",
    width: "100%",
  },
  forgotPasswordRight_Flexcontainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    paddingRight: "2rem",
  },
});

const ForgotPasswordRight = ({ setPassword }) => {
  const theme = cssVariables;
  const classes = useStyles(theme);
  const { search } = useLocation();
  let tenantName = getTenant(search);
  console.log(tenantName);
  const [resetPasswordEmail, setresetPasswordEmail] = useState("");
  const [state, setState] = useState({
    success: "",
    successMessage: "",
    failure: "",
    failureMessage: "",
  });

  const ResetPasswordClickHandler = async () => {
    setState({
      success: "",
      successMessage: "",
      failure: "",
      failureMessage: "",
    });
    if (resetPasswordEmail.match(Emailvalidator)) {
      const headers = getAxiosHeaders(false);
      headers.Tenant = tenantName;

      const res = await getService({
        method: "POST",
        url: RESETPASSWORD_MAIL(tenantName),
        headers: headers,
        data: {
          email: resetPasswordEmail,
        },
      });

      if (res.status === 200) {
        setState({
          ...state,
          success: true,
          successMessage: "Reset password link sent to mail successfully",
        });
      } else {
        let failureMessage = SERVER_ERROR_MESSAGE;

        if (res.response?.status === 400) {
          failureMessage = res.response?.data?.errorMessage || failureMessage;
        }

        setState({
          ...state,
          failure: true,
          failureMessage,
        });
      }
    }
  };
  return (
    <Grid item xs={7} sm={7} md={7} lg={7}>
      <SnackBar2
        success={state.success}
        failure={state.failure}
        successMessage={state.successMessage}
        errorMessage={state.failureMessage}
      ></SnackBar2>
      {!setPassword ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "2rem",
            paddingTop: "2rem",
          }}
        >
          <a href="/" className={classes.forgotpassword_anchor}>
            <span style={{ width: "30px", marginRight: "0.5rem" }}>
              &#8592;
            </span>
            Back to Sign In
          </a>
        </div>
      ) : null}
      <div
        style={{
          fontFamily: "Nunito-Regular !important",
          zIndex: "10",
          height: "625px",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          marginTop: "5rem",
          marginLeft: "5rem",
        }}
      >
        <div className={classes.forgotPasswordRight_Flexcontainer}>
          <p className={classes.forgotpassword_text}>
            {!setPassword ? "Forgot Password" : "Set Password"}
          </p>
          <Input
            type="email"
            labelName="Email"
            placeholder="Email"
            containerName="forgotpasswordContainer"
            classname="forgotpasswordInput"
            // classname="signinpage"
            value={resetPasswordEmail}
            eventHandler={(value) => {
              setresetPasswordEmail(value);
            }}
          ></Input>

          <Button
            classes={{
              root: classes.root,
            }}
            variant="contained"
            color="primary"
            size="large"
            onClick={ResetPasswordClickHandler}
          >
            Reset Password
          </Button>

          <p className={classes.forgotPassword_paragraph}>
            You will receive reset password link on your registered email
          </p>
        </div>
      </div>
    </Grid>
  );
};

export default ForgotPasswordRight;
