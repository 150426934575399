
import {
  CHANGE_FILTERBYVALUE_DATABASELOGS,
  UPDATED_DATABASE_LOGS_SELECTED_FILTER_VALUES,
  DATABASE_SORT_CHANGE,
  DATABASE_CHANGE_ORDER_BY_VALUE,
  DATABASE_FROM_TIME_VALUE,
  DATABASE_TO_TIME_VALUE,
  DATABASE_LOGS_CLEAR_ALL
  } from "../constants/constants";
export const CHANGE_FILTERBYVALUE_DATABASELOGS_ACTION = (payload) => {
    return {
      type: CHANGE_FILTERBYVALUE_DATABASELOGS,
      payload: payload,
    };
  };
  export const DATABASE_FILTER_LOGS = (payload) => {
  return {
    type: UPDATED_DATABASE_LOGS_SELECTED_FILTER_VALUES,
    payload: payload,
  };

}
export const DATABASE_CHANGE_SORT = (payload) => {
return {
  type: DATABASE_SORT_CHANGE,
  payload: payload,
}

}
export const Database_changeOrderByValue = (payload) => {
  return {
    type: "DATABASE_CHANGE_ORDER_BY_VALUE",
    payload: payload,
  };
};
export const DATABASE_LOGS_FROM_TIME = (payload) => {
return {
  type: "DATABASE_FROM_TIME_VALUE" ,
  payload: payload,
}
}
export const DATABASE_LOGS_TO_TIME = (payload) => {
return {
  type: "DATABASE_TO_TIME_VALUE" ,
  payload: payload,
}



}
export const DATABASE_LOGS_CLEAR_ALL_ACTION = () =>{
  return {
    type: "DATABASE_LOGS_CLEAR_ALL" ,
    
  }
}

export const DATABASE_FINAL_FILTER_ACTION = () =>{

}