import { call, put, takeEvery } from "redux-saga/effects";
import { GET_USER_INFO_REQUEST } from "../constants/constants";
import axios from "axios";
import { getService, getAxiosHeaders } from "../../../src/library/RestAPI";
import {
  USERS_ME,
  DATASOURCE_URL2,
  viewApplication,
} from "../constants/constants";
import { removeDuplicates } from "../../components/common/utilities";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchUserInformation(action) {
  try {
    const myStorage = window.localStorage;
    console.log("hello");
    try {
      const res = yield axios({
        method: "GET",
        url: USERS_ME(myStorage.getItem("tenant")),
        headers: getAxiosHeaders(true),
      });

      if (res.status === 200) {
        let tempArr = [];
        yield put({ type: "USER_DETAILS", payload: res.data });

        //get role
           const roles = (res.data && res.data.roles && res.data.roles.length > 0)
          ? res.data.roles.map((role) => role.name)
          : [];

        //get permissions
        const permissions = res.data.roles.reduce((acc, role) => acc.concat(role.permissions), []);
        const allPermissions = removeDuplicates(permissions);
        if (allPermissions.length > 0) {
          yield localStorage.setItem("PERMISSIONS", JSON.stringify(allPermissions));
          yield put({ type: "PERMISSIONS", payload: allPermissions });
        }
        //set role in local
        yield localStorage.setItem("user_role", roles);

        //set application data
        if (tempArr.indexOf(viewApplication) > -1) {
          const getApplicationData = yield call(() =>
            getService({
              method: "GET",
              url: DATASOURCE_URL2,
              headers: getAxiosHeaders(true),
            })
          );
          const applications = yield getApplicationData.data.content;
          if (applications.length > 0) {
            yield put({ type: "APPLICATIONS", payload: applications });
          }
        }
      } else {
        yield put({
          type: "USER_FETCH_FAILED",
          payload: "Please enter valid email or Password",
        });
      }
    } catch (error) {
      console.log(error);
      const errMessage = yield JSON.stringify(error.message);
      if (errMessage === "Request failed with status code 401") {
        console.log("Please enter valid email or Password");
        yield put({
          type: "USER_FETCH_FAILED",
          payload: "Please enter valid email or Password",
        });
      } else {
        yield put({
          type: "USER_FETCH_FAILED",
          payload: "Error Fetching Data from API",
        });
      }
      // myStorage.setItem("bearerToken", "error");
    }
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED" });
  }
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* fetchUserInformationSaga() {
  yield takeEvery(GET_USER_INFO_REQUEST, fetchUserInformation);
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/

export default fetchUserInformationSaga;
