import React, { useState, useEffect } from "react";
import { getCubejsApi } from "../../common/Dashboard/cubejs-util";
import { chartTypes } from "../../common/Dashboard/chartTypeToComponent";
import { useSelector, useDispatch } from "react-redux";
import EnhancedGridTable from "../../shared/Table/Table";
import { EnhancedTableToolbar } from "./EnhanaceDataLogsHeader";
import { tableConstants } from "./utilities";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import {
  ApiCall_DataSourceNames,
  ApiCall_DatabaseRoles,
  ApiCall_DataSourceTypes,
  GET_ALERT_POLICY_NAMES_URL,
  database_Sensitivity_Fields,
} from "../../../redux/constants/constants";
import moment from "moment";
import { DataMapRoute } from "../../common/DatamapImage";
import Toggler from "../../shared/Toggler";
import "./databaseLogs.scss";
import "./directDataAccessLogs";
import DirectDataAccessLogs from "./directDataAccessLogs";
import ServiceDataAccessLogs from "./ServiceDataAccessLogs";

const helpDescription =
  "Logs of users accessing data sources. Navigate to here from an alert to see the data accesses the resulted in the violation. Use time range filters to see the normal data access patterns";

export default function DatabaseLogs(props) {
  const dispatch = useDispatch();
  const [queryData, setQueryData] = useState(null);
  const [togglers, setTogglers] = useState([
    { name: "Direct Data Access Logs", state: "active" },
    // { name: "Service Data Access Logs", state: "inactive" },
  ]);

  let alertId = null;
  let configuration;
  let configuration_from_alerts;
  let sensitivityLevel = null;
  let cubejsApi = getCubejsApi();
  const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
  const features =
    featuresData &&
    featuresData.features &&
    featuresData.features.filter((data) => data.name === "Data Vision");
  useEffect(() => {
    if (
      features &&
      features.length &&
      features[0].licenseType.name === "Platinum"
      // features[0].licenseType.name === "Silver"

    ) {
      if (props.location.state) {
        if (props.location.state.tag === "serviceLogs") {
          // setSelectedTag("serviceLogs")
          setTogglers([
            { name: "Direct Data Access Logs", state: "inactive" },
            { name: "Service Data Access Logs", state: "active" },
          ]);
        } else if (props.location.state.tag === "directDbLogs") {
          // setSelectedTag("directDbLogs")
          setTogglers([
            { name: "Direct Data Access Logs", state: "active" },
            { name: "Service Data Access Logs", state: "inactive" },
          ]);
        }
      } else {
        setTogglers([
          { name: "Direct Data Access Logs", state: "active" },
          { name: "Service Data Access Logs", state: "inactive" },
        ]);
      }
    } else {
      setTogglers([{ name: "Direct Data Access Logs", state: "active" }]);
    }
  }, []);
  if (configuration_from_alerts !== undefined) {
    sensitivityLevel = configuration_from_alerts.sensitivity_info;
  }

  const handleToggle = () => {
    dispatch({
      type: "DATABASE_ACCESS_LOGS_CLEAR_FILTER_DATA",
    });
    dispatch({
      type: "SERVICE_ACCESS_LOGS_CLEAR_FILTER_DATA",
    });
  };

  const renderHeaderInfo = () => {
    return (
      <div className="description">
        <div style={{ display: "grid" }}>
          <h2 style={{ margin: "0", fontSize: "32px" }}>Data Access Logs</h2>
          <p style={{ margin: "0" }}>Logs of users accessing data sources </p>
        </div>
        <DataMapRoute />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="main">
        {renderHeaderInfo()}
        <div className="togglerandSearch_Conatiner">
          <Toggler
            togglers={togglers}
            setTogglers={setTogglers}
            handleToggle={handleToggle}
          ></Toggler>
        </div>
        <div className="useraccessRights_content">
          {togglers[0].state === "active" && (
            <DirectDataAccessLogs
              propsData={props.location.state}
            ></DirectDataAccessLogs>
          )}
          {togglers[1]
            ? togglers[1].state === "active" && (
                <ServiceDataAccessLogs
                  propsData={props.location.state}
                ></ServiceDataAccessLogs>
              )
            : ""}
        </div>
      </div>
    </React.Fragment>
  );
}
