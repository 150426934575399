import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CompleteButton } from "../common/buttons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  fetchDataSource,
  fetchAllDataSources,
  fetchUpdatedDataSources,
} from "../../redux/actions/getAlertsAction";
import { fetchApplicationData } from "../../redux/actions/getAlertsAction";
import { getDatabaseEngines } from "../../redux/actions/getDatabaseEngines";
import { TextField } from "@material-ui/core";
import {
  viewApplication,
  GET_DATASOURCE,
  UPDATE_DATASOURCE_SUCCESS,
  ONEDRIVE,
  reviewDataSource,
  viewDataSource,
  DROPBOX,
  INSTANCE_ERROR_MESSAGE,
  DATA_SOURCE_DELETION_CRITERIA_CHECK,
  SET_SNACKBAR,
  TRIGGER_DELETE_DATA_SOURCE,
  DELETE_CONFIRMATION_MESSAGE,
  SHOW_DIALOG_BOX,
  DATA_MATCHED,
} from "../../redux/constants/constants";
import { ReviewDataSourcesHeadCells } from "../common/headCells";
import EnhancedGridTable from "../shared/Table/Table";
import EnhancedTableToolbar from "../shared/enhancedToolBar";
import { isEmpty, isNull } from "lodash";
import AlertDrawer from "../shared/alertDrawer";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import Toggler from "../shared/Toggler";
import { DataMapRoute } from "../common/DatamapImage";
import { ServiceNodes } from "../views/serviceNodes/serviceNodes";
import { LogSource } from "../views/logSource/LogSource";
import { errorSnackbar, isCloudFileSystem } from "../common/utilities";
import CanAllow from "../shared/CanAllow";
import DeleteConnection from "./DeleteConnection";
import ConfirmationDialog from "../shared/ConfirmationDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  dialogPaper: {
    width: 500,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    color: "#081981",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    color: "#081981",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    inputRoot: {
      color: "#081981",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    year: {
      paddingLeft: `calc(1em + ${theme.spacing(0.5)}px)`,
      width: "342px !important",
    },
  },
  buttonProgress: {
    color: "#2947fd",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  inputRoot: {
    color: "#081981",
  },
  inputInputClass: {
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
    borderRadius: "6px",
    marginRight: "10px",
    padding: "8px 20px",
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function ReviewDataSources(props) {
  const currentRoute = props.location.search
  const [applications, setApplications] = useState([]);
  const [value, setValue] = useState("");
  const [aliasNameEdit, setAliasNameEdit] = useState(false);
  const [URLData, setURLData] = useState(""); //will be used in the later phase
  const [rows, setDataSource] = useState([]);
  const [dataSourceId, setDataSourceId] = useState("");
  const [applicationId, setApplicationId] = useState("");
  const [selectedApplication, setSelectedApplication] = useState("");
  const [databaseEngines, setDatabaseEngines] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [stateOpen, setStateOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [togglers, setTogglers] = useState([
    { name: "Data Sources", state: "active" },
    { name: "Service Nodes", state: "inactive" },
    { name: "Log Sources", state: "inactive" },
  ]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState({});
  const [confirmMessage, setConfirmMessage] = useState("");
  const [criteria, setCriteria] = useState(false);
  const [criteriaFailErrorMessage, setCriteriaFailErrorMessage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const applicationsList = useSelector(
    (state) => state.applicationData.applications
  );
  const selectedApplicationFromState = useSelector(
    (state) => state.applicationData.selectedApplication
  );
  
  const groupSaveSuccessful = useSelector((state) => state.CreateSourceReducer.groupSaveSuccessful);

  const permissions = useSelector((state) => state.applicationData.permissions);
  const dropBoxConnectionStatus = useSelector((state) => state.dropBoxSuccessReducer.dropBoxConnected);

  const deletionLoader = useSelector(
    (state) => state.DeleteConnectionReducer.loader
  );

  const snackBar = useSelector(
    (state) => state.DeleteConnectionReducer.snackBar
  );

  const deletionCriteria = useSelector(
    (state) => state.DeleteConnectionReducer.criteria
  );

  const deletionCriteriaMessage = useSelector(
    (state) => state.DeleteConnectionReducer.criteriaErrorMessage
  );

  const deletionDialogBox = useSelector(
    (state) => state.DeleteConnectionReducer.dialogBox
  );

  const deleteConnectionErrorMessage = useSelector(
    (state) => state.DeleteConnectionReducer.deleteConnectionErrorMessage
  );

  const dataSourceErrorMessage = useSelector(
    (state) => state.datasourceData.errorMessage
  );

  const loaderDS = useSelector((state) => state.CreateSourceReducer.loader);

  useEffect(() => {
    if (!isEmpty(selectedDataSource.id)){
      checkCriteria();
    }
    return () => {
      dispatch({type: SHOW_DIALOG_BOX, payload: false})
    }
  },[selectedDataSource.id])

  useEffect(() => {
    setLoader(loaderDS);
  },[loaderDS])

  useEffect(()=> {
    if (!isEmpty(dataSourceErrorMessage)) {
      setErrorMessage(dataSourceErrorMessage);
      setOpen(true)
    }
  },[dataSourceErrorMessage])

  useEffect(() => {
    if (
      applicationsList.length === 0 &&
      permissions.indexOf(viewApplication) > -1
    ) {
      const getApplications = async () => {
        const res = await fetchApplicationData();
        if (res && res.content) {
          setApplications(res.content);
        } else {
          console.log("Error  fetching data");
        }
      };
      getApplications();
    } else {
      setApplications(applicationsList);
    }
  }, [applicationsList, viewApplication]);

  async function getAllDataSources() {
    setOpenLoader(true);
    const response = await fetchAllDataSources(currentPage, recordsPerPage);
    if (typeof response.content === "object") {
      setDataSource(response.content);
      if(currentRoute !== "" && !dropBoxConnectionStatus){
        setIsEditMode(true);
        getApiData();
      }
      setTotalCount(response.totalElements);
      setOpenLoader(false);
    } else if (typeof response.content === "string") {
      alert(response);
      setOpenLoader(false);
    }
  }
  async function getUpdatedDataSources(row,status) {
    setOpenLoader(true);
    const response = await fetchUpdatedDataSources(row.id, status);
    if (typeof(response) === 'object' && !isEmpty(rows)) {
      rows.forEach((item, index) => {
        if (item.id === response.id){
          rows[index] = response
        }
      })
      setDataSource([...rows])
      setOpenLoader(false);
    } else if (typeof(response) === 'string') {
      setOpen(true);
      setErrorMessage(response);
      setOpenLoader(false);
    }
  }
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({type: SET_SNACKBAR, payload: false});
    setOpen(false);
  };
  const handleActiveStatus = (event, row) => {
    if (row.status.value === 'Active'){
      getUpdatedDataSources(row,'INACTIVE')
    } else {
      getUpdatedDataSources(row,'ACTIVE')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getApiData() {
    const urlParam = new URLSearchParams(currentRoute);
    const state = urlParam.get("state").split('_');
    const code = urlParam.get("code");
    let dropBoxData = {
      code: code,
    };
    let dataSourceID = state[0];
    let consentType = null;
  
    if (state.length > 1 && state[1] && code) {
      consentType = state[1];
    }
    if (!isNull(consentType)) {
      dispatch({ type: GET_DATASOURCE, payload: {
        dataSourceID,
        consentType
      }});
    } else {
      dispatch({ type: GET_DATASOURCE, payload: {
        dataSourceID,
        consentType,
        dropBoxData
      }});
    }
  
    if (!isEmpty(code) && isNull(consentType)) {
      setStateOpen(false);
    } else {
      setStateOpen(true);
    }
  }

  useEffect(() => {
    if (dropBoxConnectionStatus || groupSaveSuccessful) {
      getAllDataSources();
    }
  }, [dropBoxConnectionStatus, groupSaveSuccessful]);
  

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };


  useEffect(() => {
    if (togglers[0].state === "active") {
      handleApiCall();
      return () => {
        dispatch({ type: "SELECTED_APPLICATION", payload: "" });
        dispatch({ type: "SET_DATA_LOADED", payload: false });
        dispatch({type: SET_SNACKBAR, payload: false});
        setOpen(false);
      };
    }
  }, [selectedApplicationFromState, currentPage, recordsPerPage, togglers]);

  const handleApiCall = () => {
    async function getDataSources(name) {
      let id;
      if (applications && applications.length > 0) {
        applications.forEach((obj) => {
          if (obj.name === name) {
            id = obj.id;
            handleSelectedApplication(obj.name);
          }
        });
      }

        const response = await fetchDataSource(id, currentPage, recordsPerPage);
        if (response) {
          setDataSource(response.content);
          setTotalCount(response.totalElements);
        } else {
          alert("Error Fetching data");
      }
    }
    if (selectedApplicationFromState !== "") {
      getDataSources(selectedApplicationFromState);
      setSelectedApplication(selectedApplicationFromState);
    } else {
      getAllDataSources();
    }
  };

  const handleSelectedApplication = (name) => {
    dispatch({ type: "SELECTED_APPLICATION", payload: name });
    setSelectedApplication(name);
  };

  const handleOneDriveForm = (dataSourceData) => {
    if (currentRoute !== "") {
      setIsEditMode(true);
      setStateOpen(true);
    } else {
      setStateOpen(true);
    }
  };

  const headerProps = {
    id: 12,
    name: "Review Data Sources",
    sources: "3",
    targets: "3",
    description:
      "Check if there are any data sources lacking the connection information and provide them. Check if the data in the data sources is classified correctly in terms of sensitivity and confidentiality.",
  };
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  function handleAddClick(event, dataSourceData) {
      history.push(`?state=` + dataSourceData.id);
      dispatch({
        type: UPDATE_DATASOURCE_SUCCESS,
        payload: dataSourceData,
      });
    event.preventDefault();
    handleOneDriveForm(dataSourceData);
    getEngines();
    setDataSourceId(dataSourceData.id);
    //setServiceId(dataSourceData.service.id);
    setApplicationId(dataSourceData.application.id);
  }

  useEffect(() => {
    if (currentRoute !=="") {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
      handleApiCall();
    }
  }, [currentRoute]);

  async function getEngines() {
    const response = await getDatabaseEngines();
    if (response && response.content.length) {
      console.log(response);
      setDatabaseEngines(
        response.content.filter((name) =>
          ["PostgreSQL", "MSSQL", "MySQL", "Other", "OneDrive", "Dropbox"].includes(name.value)
        )
      );
    }
  }

  const handleURLData = (event) => {
    setURLData(event.target.value);
  };


  const handleAliasNameEdit = (value) => {
    setAliasNameEdit(value)
    handleApiCall()
  }



  const renderSidePopup = () => {
    return (
      stateOpen && (
        <AlertDrawer
          toggleDrawer={toggleDrawer}
          stateOpen={stateOpen}
          getAllDataSources={getAllDataSources}
          isEditMode={isEditMode}
          isAliasEdit={(value) => handleAliasNameEdit(value)}
          loc="create-data-source"
        />
      )
    );
  };

  const toggleDrawer = (open, event, row) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if ( open === false){
      history.push("/tenant/reviewDataSources");
    }
    setStateOpen(open);
  };

  const renderTabs = () => {
    return (
      <div className="togglerandSearch_Conatiner">
        <Toggler togglers={togglers} setTogglers={setTogglers}></Toggler>
      </div>
    );
  };

  const renderServiceNodeHeader = () => {
    return (
      <div className="description">
        <div style={{ display: "grid" }}>
          <h2 style={{ margin: "0", fontSize: "32px" }}>Service Nodes</h2>
          <p style={{ margin: "0" }}></p>
        </div>
        <DataMapRoute />
      </div>
    );
  };

  const renderLogSourceHeader = () => {
    return (
      <div className="description">
        <div style={{ display: "grid" }}>
          <h2 style={{ margin: "0", fontSize: "32px" }}>Log Source</h2>
          <p style={{ margin: "0" }}></p>
        </div>
        <DataMapRoute />
      </div>
    );
  };
   // Function to handle changes in port and desired port inputs
   const handlePortInput = (value, updatePortNumber) => {
    // 'updatePortNumber' is a callback to set the value of a portname or desiredPort
    // Convert the input value to a numeric value
    const numericValue = parseInt(value);
    // Check if the input value is a valid positive number
    if (!isNaN(numericValue) && numericValue > 0) {
      // Convert any negative numeric value to a positive numeric value and update
      updatePortNumber(Math.abs(numericValue));
    } else {
      // Reset the port value using the provided callback
      updatePortNumber("");
    }
  }

  useEffect(() => {
    setOpenLoader(deletionLoader);
    if(!deletionLoader){
      setCriteria(deletionCriteria);
      setDialogOpen(deletionDialogBox);
      setCriteriaFailErrorMessage(deletionCriteriaMessage);
      setErrorMessage(deleteConnectionErrorMessage);
      setOpen(snackBar);
      if(!deletionDialogBox){
        setSelectedDataSource({})
      }
    }
  },[deletionLoader])

  const checkCriteria = () => {
    dispatch({type: DATA_SOURCE_DELETION_CRITERIA_CHECK, payload: selectedDataSource});
  }
  const handleDataSourceDelete = (name, id) => {
    setSelectedDataSource({id: id, name: name});
  }
  const handleDialogClose = () => {
    setDialogOpen(false);
    setConfirmMessage('');
    setSelectedDataSource({});
    handleApiCall();
  };
  const checkValidity = (e) => {
    if (!isEmpty(e.target.value) && !isNull(e.target.value)) {
      if (e.target.value === selectedDataSource.name) {
        setConfirmMessage(DATA_MATCHED);
      } else {
        setConfirmMessage("");
      }
    }
  };
  const deleteSubmit =  () => {
    dispatch({ 
      type: TRIGGER_DELETE_DATA_SOURCE,
      payload: {
        selectedDataSource: selectedDataSource,
        handleDialogClose: handleDialogClose
      }});
  }
  const classes = useStyles();
  return (
    <>
      {
        <div className="review-data-source">
          {renderSidePopup()}
          {openLoader && (
            <div className="global-loader-holder">
              <img src={Page_Loader} alt="_Loader" className="loader" />
            </div>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="datamap-container">
                {togglers[0].state === "active" && (
                  <div
                    className="datamap-breadcrumb"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      style={{ marginTop: "15px" }}
                    >
                      <Link
                        color="inherit"
                        href="/admin/systemSummary"
                        onClick={handleClick}
                      >
                        {selectedApplication || `All applications`}
                      </Link>
                      <Typography color="textPrimary">
                        Review Data Sources
                      </Typography>
                    </Breadcrumbs>
                    <div className={classes.search}>
                      <Grid
                        style={{
                          display: "flex",
                          width: "11rem",
                        }}
                        onChange={() => {
                          console.log("change");
                        }}
                      >
                        <Autocomplete
                          style={{
                            width: "11rem",
                          }}
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          onInputChange={async (event) => {
                            if (event.target.innerHTML) {
                              let id;
                              if (applications && applications.length > 0) {
                                applications.forEach((obj) => {
                                  if (obj.name === event.target.innerHTML) {
                                    id = obj.id;
                                    setCurrentPage(0);
                                    handleSelectedApplication(obj.name);
                                  }
                                });
                              }
                            } else {
                              setCurrentPage(0);
                              handleSelectedApplication("");
                            }
                          }}
                          options={
                            applications
                              ? applications.map((option) => {
                                  return option.name;
                                })
                              : "Error Fetching Options"
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              variant="outlined"
                              label="Select Application"
                              style={{ marginTop: "0px", marginBottom: "0px" }}
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              value={value}
                            />
                          )}
                        />
                      </Grid>
                    </div>
                  </div>
                )}
                <div className="main">
                  {togglers[0].state === "active" && (
                    <div className="description" style={{ marginTop: "10px" }}>
                      <div className="all-data-sources-title">
                        <h2 style={{ margin: "0" }}>
                          {selectedApplicationFromState || `All Data Sources`}
                        </h2>
                        <p style={{ margin: "0" }}>{headerProps.description}</p>
                      </div>
                      <DataMapRoute />
                    </div>
                  )}
                  {togglers[1].state === "active" && renderServiceNodeHeader()}
                  {togglers[2].state === "active" && renderLogSourceHeader()}
                  {renderTabs()}
                  {togglers[1].state === "active" && <ServiceNodes />}
                  {togglers[2].state === "active" && <LogSource />}
                  {togglers[0].state === "active" && (
                    <div style={{ margin: "24px 24px 12px 211px" }}>
                      <EnhancedTableToolbar
                        loc="review-data-sources"
                        rows={rows}
                        totalElements={totalCount}
                        toggleDrawer={toggleDrawer}
                        description={
                          "Check if there are any data sources lacking the connection information and provide them. Check if the data in the data sources is classified correctly in terms of sensitivity and confidentiality."
                        }
                      />
                      {togglers[0].state === "active" && rows.length > 0 && (
                        <EnhancedGridTable
                          cols={ReviewDataSourcesHeadCells(
                            handleURLData,
                            handleAddClick,
                            classes,
                            handleActiveStatus,
                            handleDataSourceDelete
                          )}
                          data={rows}
                          totalCount={totalCount}
                          currentPage={currentPage}
                          recordsPerPage={recordsPerPage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          onChangePage={handleChangePage}
                        />
                      )}
                        <CanAllow needs={{permission: [viewDataSource]}}>
                          <CompleteButton
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              dispatch({
                                type: 'SELECTED_APPLICATION',
                                payload: '',
                              });
                              dispatch({
                                type: 'TRACK_TRAVERSAL',
                                payload: 'System Summary',
                              });
                              history.push('/tenant/systemSummary');
                            }}
                            className="min-margin"
                          >
                            Complete
                          </CompleteButton>
                        </CanAllow>
                      <ConfirmationDialog
                        dialogOpen={dialogOpen}
                        dialogTitle={criteria ? DELETE_CONFIRMATION_MESSAGE : " "}
                        handleDialogCancel={handleDialogClose}
                        handleDialogConfirm={deleteSubmit}
                        disable={isEmpty(confirmMessage)}>
                        {criteria ?
                          <DeleteConnection
                            loader={deletionLoader}
                            selectedSource={selectedDataSource}
                            checkValidity={checkValidity}
                            confirmMessage={confirmMessage}
                            sourceName={"data"}
                          /> :
                          <div className='criteriaFailMessage'>{criteriaFailErrorMessage}</div>
                        }
                      </ConfirmationDialog>
                      {errorSnackbar(open, handleSnackBarClose, errorMessage, "error")}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={3}>
          <Paper className={classes.paper}>
            <DataMapSideInfo />
          </Paper>
        </Grid> */}
          </Grid>
        </div>
      }
    </>
  );
}
