import React from "react";
import { Doughnut } from "react-chartjs-2";
import ChartWrapper from "../../../common/Dashboard/Chart";
import { viewDataMap } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../../common/utilities";
import {
  Chart,
  ArcElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { renderNoData } from "../../../common/Dashboard/utils";
import "../RiskGovernance.scss";
import MitigationRadialChart from "./MitigationRadialChart";

// Register necessary Chart.js components and plugins globally
Chart.register(ArcElement, DoughnutController, Title, Tooltip, Legend);

const MitigationStatusWidget = (cubejsApi, name, query) => {

  const renderStatusValue = (status) => {
    const statusData = status.loadResponse.results[0].data;

    if (!statusData || statusData.length === 0) {
      return renderNoData("30%");
    }

    // Initial status counts
    const statusCounts = {
      TODO: 0,
      OPEN: 0,
      IN_PROGRESS: 0,
      CLOSED: 0,
    };
    
    // Iterate over the statusData and update the statusCounts
    statusData.forEach((item) => {
      const status = item["Mitigation.status"];
      const count = parseInt(item["Mitigation.mitigationCount"], 10);
      if (statusCounts.hasOwnProperty(status)) {
        statusCounts[status] = count;
      }
    });
    
    // Create an array that includes all statuses with their counts
    const CompleteStatusData = Object.keys(statusCounts).map((status) => (
      {
        "Mitigation.status": status,
        "Mitigation.mitigationCount": statusCounts[status]
      }
    ));

    // Extract the counts in the desired order
    const counts = [
      statusCounts["TODO"],
      statusCounts["OPEN"],
      statusCounts["IN_PROGRESS"],
      statusCounts["CLOSED"],
    ];

    return (
      <div>
        <hr className="threatLevelChartLine mPriority" />
        <div className="radialChartContainer">
          <div className="chartContent">
            <MitigationRadialChart data={CompleteStatusData} />
            <div className="mitigationParentLegendContainer mPriority" style={{marginTop: "0%"}}>
              {["To Do", "Open", "In Progress", "Closed"].map(
                (status, index) => (
                  <div
                    className="MitigationLegendChild mPriority"
                    key={index}
                    style={{ gap: "3px", marginLeft: "5%" }}
                  >
                    <img
                      src={`/riskPilotIcons/${
                        ["redDot", "greenDot", "orangeDot", "blueDot"][index]
                      }.svg`}
                      alt={status}
                    />
                    <div>
                      {counts[index]} {status}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="threatPieChart" style={{ marginTop: "5%", width: "95%" }}>
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={name}
        query={query}
        render={renderStatusValue}
      />
    </div>
  );
};

export default MitigationStatusWidget;
