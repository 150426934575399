import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import RefreshIcon from "../../../assets/vizr_images/ic_refresh.svg";
import "../../../assets/scss/admin-styles/custom/_variables.scss";
import { ColorButtonForAdminNavbar } from "../../common/buttons";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AppDateTimeRange from "../../common/Navbar/AppDateTimeRange";
import InputBase from "@material-ui/core/InputBase";
import { getUserPreferences } from "../../../redux/actions/getUserPreferences";
import { updateUserPreferences } from "../../../redux/actions/updateUserPreferences";
import { AppBar, Toolbar, Button, TextField } from "@material-ui/core";

import { timezones } from "../../common/timezones";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fetchApplicationData } from "../../../redux/actions/getAlertsAction";
import {
  viewApplication,
  GET_USER_PREFERENCES_URL,
  GET_TENANT_PROFILE,
} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { editTenant, renderTenantLogo } from "../../common/utilities";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  appBg: {
    backgroundColor: "#081981",
  },
  appBgMDR: {
    // backgroundColor: "#5D0A90",
    backgroundColor: "#081981",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  welcomeTitle: {
    marginLeft: "200px",
    display: "flex",
    alignItems: "center",
    width: "39%",
  },
  tenantName:{
    fontSize: "18px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    color: "#081981",
    "&:hover": {
      backgroundColor: "white",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#081981",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  year: {
    paddingLeft: `calc(1em + ${theme.spacing(0.5)}px)`,
    width: "342px !important",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function AdminNavbar({ project }) {
  const classes = useStyles();
  const allTimeZones = {
    options: timezones,
    getOptionLabel: (option) => option.displayName,
  };

  const flatProps = {
    options: timezones.map((option) => option.displayName),
  };

  const handleTimezoneSelect = (val) => {
    setSelectedTimeZone(val);
  };
  const history = useHistory();
  const [selectedTimeZone, setSelectedTimeZone] = React.useState(null);
  const permissions = useSelector((state) => state.applicationData.permissions);
  const [applications, setApplications] = useState([]);
  const [tenant, setTenant] = useState("");
  const tenantName = JSON.parse(localStorage.getItem('tenantName'));
  const applicationsList = useSelector(
    (state) => state.applicationData.applications
  );
  const selectedDateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );
  const timeZoneSelected = useSelector(
    (state) => state.timeZone.selectedTimeZone
  );
  //console.log(timeZoneSelected);
  const [selectedApplication, setSelectedApplication] = useState("");
  const [value, setValue] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({type: GET_TENANT_PROFILE})
  },[])
  
  useEffect(() => {
    async function getUserPreferences() {
      const response = await getService({
        method: "GET",
        url: `${GET_USER_PREFERENCES_URL}`,
        headers: getAxiosHeaders(true),
      });
      if (response && response.data) {
        const parseData = JSON.parse(response.data.preferences);
        if (
          parseData &&
          parseData.nodePositions &&
          parseData.nodePositions.length > 0
        ) {
          dispatch({
            type: "NODE_POSITIONS",
            data: parseData.nodePositions.length ? parseData.nodePositions : [],
          });
        }
      }
    }
    getUserPreferences();
  }, []);

  useEffect(() => {
    async function getPreferences() {
      if (tenant !== "druvstar" && tenant !== "datavision") {
        const response = await getUserPreferences(tenant);
        if (response) {
          let temp = response;
          let dTRFilter =
            Object.keys(response.dateTimeFilters).length === 0
              ? []
              : response.dateTimeFilters;
          dTRFilter.push(selectedDateTimeRange);
          temp.dateTimeFilters = [...new Set(dTRFilter)];
          const response2 = await updateUserPreferences({
            preferences: JSON.stringify(temp),
          });
          if (response2 && response.preferences) {
            dispatch({ type: "CLEAR_TRACK_TRAVERSAL" });
          }
        }
      }
    }
    if (selectedDateTimeRange) {
      //console.log(selectedDateTimeRange);
      getPreferences();
    }
  }, []);

  useEffect(() => {
    var current = new Date();
    let hour = current.getHours();
    let minutes = current.getMinutes();
    let seconds = current.getSeconds();
    setLastUpdated(`${hour}:${minutes}:${seconds}`);
    setTenant(window.localStorage.getItem("tenant"));
  }, []);

  useEffect(() => {
    if (
      applicationsList.length === 0 &&
      permissions.indexOf(viewApplication) > -1
    ) {
      const getApplications = async () => {
        const res = await fetchApplicationData();
        //console.log(res);
        if (res && res.content) {
          setApplications(res.content);
        } else {
          console.log("Error  fetching data");
        }
      };
      getApplications();
    } else {
      setApplications(applicationsList);
    }
    setSystemTimezone();
  }, [applicationsList, viewApplication]);

  const logout = () => {
    if (window.location.host.indexOf("localhost") !== -1) {
      window.location.replace(
        `http://localhost:3000/?tenant=${localStorage.getItem("tenant")}`
      );
    } else {
      window.location.replace(`http://${window.location.host}/`);
    }
    localStorage.clear();
    sessionStorage.clear();
  };
  //setTimezone()
  const setSystemTimezone = () => {
    const sysTimezone = /\((.*)\)/.exec(new Date().toString())[1];
    const findSysTimezone = timezones.find(
      (item) => item.APIValue === sysTimezone
    );
    if (findSysTimezone) {
      setSelectedTimeZone(findSysTimezone);
    }
  };

  const location = useSelector(
    (state) => state.userAccessRightsReducer.location
  );
  // const [searchValue, setSearchValue] = useState("");
  const changeHandler = (e) => {
    dispatch({
      type: "UPDATE_SERCHVALUE",
      payload: e.target.value,
    });
  };
  const editTenantProfile = (event, open) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(open);
  };
  const handleEditTenant = () =>{
    setOpen(false)
  }

  return (
    <div className={classes.grow}>
      <AppBar
        className={project ? classes.appBgMDR : classes.appBg}
        style={{ zIndex: 3 }}
        position="fixed"
      >
        <div className="mdrNavToolBarContainer">
          <Toolbar className="mdrNavToolBar">
            <div className={classes.welcomeTitle}>
              {renderTenantLogo()}
              {tenantName && (
                <div className={classes.tenantName} title={tenantName}>
                  {tenantName}
                </div>
              )}
              {editTenant(editTenantProfile, handleEditTenant, open, anchorEl)}
            </div>
            <div className={classes.grow} />
            <div className={classes.search}>
              {location === "database_Users" ? (
                <InputBase
                  placeholder={
                    location === "database_Users"
                      ? "Search Data Source Users"
                      : "Select Application"
                  }
                  classes={{
                    root: classes.inputRoot,
                    input: `${classes.inputInput} ${classes.year}`,
                  }}
                  inputProps={{ "aria-label": "application" }}
                  onChange={changeHandler}
                />
              ) : (
                ""
              )}

              {/* <Grid
              style={{
                display: "flex",
                width: "11rem",
              }}
              onChange={() => {
                console.log("change");
              }}
            >
              <Autocomplete
                style={{
                  width: "11rem",
                }}
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                onInputChange={async (event) => {
                  // console.log("Chnages made");
                  if (event.target.innerHTML) {
                    let id;
                    if (applications && applications.length > 0) {
                      applications.forEach((obj) => {
                        if (obj.name === event.target.innerHTML) {
                          id = obj.id;
                          handleSelectedApplication(obj.name)
                        }
                      });
                    }

                    // const response = await fetchDataSource(id);
                    // if (response) {
                    //   //setDataSource(response)
                    // } else {
                    //   alert("Error Fetcting data");
                    // }
                  }
                }}
                options={
                  applications
                    ? applications.map((option) => {
                      //console.log(option.name);
                      return option.name;
                    })
                    : "Error Fetching Options"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    label="Search Applications"
                    style={{marginTop: '0px', marginBottom: '0px'}}
                    InputProps={{ ...params.InputProps, type: "search" }}
                    value={value}
                  />
                )}
              />
            </Grid> */}
            </div>
            <div className={classes.sectionDesktop}>
              {(window.location.pathname.includes("dataMap") ||
                window.location.pathname.includes("dashboard")) && (
                <AppDateTimeRange />
              )}
              {/* <Button
              aria-label="back"
              size="medium"
              style={{
                color: "#081981",
                backgroundColor: "white",
                minWidth: "50px",
              }}
            >
              <NotificationsNoneOutlinedIcon />
            </Button> */}
              {/* <div className="app-timezone-box">
              <Autocomplete
                {...allTimeZones}
                id="auto-complete"
                autoComplete
                includeInputInList
                getOptionSelected={(option, value) => {
                  dispatch({ type: "SELECTED_TIME_ZONE", payload: value })
                  setSelectedTimeZone(value)
                }}
                value={selectedTimeZone}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    margin="normal"
                    variant="standard"
                    value={(selectedTimeZone && selectedTimeZone.displayName) ? selectedTimeZone.displayName : ""}
                    placeholder="Select Any Timezone"
                  />
                )}
              />
            </div>

            <div style={{ padding: '0px 5px 0px 5px', fontFamily: '$font-family-base', fontSize: '12px', lineHeight: '18px', letterSpacing: '0' }}>
              <span style={{ opacity: '0.75', lineHeight: '18px' }}>Last Updated</span>
              <br />
              <span
                style={{
                  fontSize: "14px !important",
                  fontWeight: "600",
                  lineHeight: "19px",
                }}
              >
                {lastUpdated}
              </span>
            </div> */}
              <div
                style={{
                  padding: "0px 5px 0px 5px",
                  fontFamily: "$font-family-base",
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0",
                }}
              >
                <span style={{ opacity: "0.75", lineHeight: "18px" }}>
                  Last Updated
                </span>
                <br />
                <span
                  style={{
                    fontSize: "14px !important",
                    fontWeight: "600",
                    lineHeight: "19px",
                  }}
                >
                  {lastUpdated}
                </span>
              </div>
              <ColorButtonForAdminNavbar
                variant="contained"
                color="primary"
                size="small"
                className={classes.margin}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <img alt="refresh" src={RefreshIcon} />
              </ColorButtonForAdminNavbar>
              &nbsp;&nbsp;
              <ColorButtonForAdminNavbar
                variant="contained"
                color="primary"
                size="small"
                className={classes.margin}
                onClick={() => logout()}
              >
                <PowerSettingsNewIcon />
              </ColorButtonForAdminNavbar>
            </div>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
}
