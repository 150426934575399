import { ALL_ACCESS_TOTAL_COUNT, ALL_ACCESS_DATA_TOTAL_COUNT, GET_ALL_ACCESS_TOTAL_COUNT, SET_TOTAL_COUNT_LOADER } from "../constants/constants";
import { getService, getAxiosHeaders } from '../../library/RestAPI';
import { put, takeEvery } from "redux-saga/effects";



function* getAllAccessTotalCount(action) {
    yield put({ type: SET_TOTAL_COUNT_LOADER, payload: true });
    try {
        const res = yield getService({
            method: "GET",
            url: `${ALL_ACCESS_TOTAL_COUNT(
                action.payload.actorId,
                action.payload.dataSourceId,
                action.payload.lastAccessedTimeStamp,
            )}`,
            headers: getAxiosHeaders(true),
        })
        if (res) {
            yield put({ type: ALL_ACCESS_DATA_TOTAL_COUNT, payload: res.data });
            yield put({ type: SET_TOTAL_COUNT_LOADER, payload: false });
        } else {
            yield put({ type: SET_TOTAL_COUNT_LOADER, payload: false });
        }
    } catch (e) {
        yield put({ type: SET_TOTAL_COUNT_LOADER, payload: false });
        console.log(e);
    }
};


function* allAccessSaga() {
    yield takeEvery(GET_ALL_ACCESS_TOTAL_COUNT, getAllAccessTotalCount)
}

export default allAccessSaga;