import {
  SETPASSWORD_SUBMIT,
  SETPASSWORD_SUCCESS,
  SETPASSWORD_FAIL,
} from "../constants/setPasswordActionTypes";

export const SETPASSWORD__SUBMIT = (value = "") => {
  return {
    type: SETPASSWORD_SUBMIT,
    payload: value,
  };
};

export const SETPASSWORD__SUCCESS = (value = "") => {
  return {
    type: SETPASSWORD_SUCCESS,
    // payload: value,
  };
};

export const SETPASSWORD__FAIL = (value = "") => {
  return {
    type: SETPASSWORD_FAIL,
    payload: value,
  };
};
