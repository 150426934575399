import { AutheticationTableQuery } from "../../components/common/Dashboard/dashboard-data";
import {
  GET_AUTHENTICATION_SOURCE_FILTER,
  APPLY_AUTHENTICATION_FILTER,
} from "../constants/constants";
import {
  MDRFilterDataFormatter,
  clearMDRFilters,
} from "../../components/common/Dashboard/utils";
import {
  MDRTableSearchHelper,
  MDRApplyFilters,
  MDRMultipleFilters_Merge_Helper,
} from "../../components/common/Dashboard/MDRHelpers";
import { cloneDeep } from "lodash";

let initialState = {
  authenticationTableQuery: cloneDeep(AutheticationTableQuery),
  autheticationTableData: [],
  autheticationLoc: "",
  authenticationTableTotalRows: 0,
  mdrauthenticationTableDropDownvalue: "",
  mdrAuthenticationSourceFilter: [],
  currentStatus: "",
  clearAll: false,
  authenticationDrawer:false,
  authenticationSideDrawerData:{},
};
export const MDRAuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_MDRAUTHENTICATION_TABLE_QUERY": {
      return {
        ...state,
        authenticationTableQuery: action.payload,
        autheticationTableData: [],
      };
    }
    case "CHANGE_AUTHENTICATION_TABLE_DATA": {
      return {
        ...state,
        // clearAll: action.payload.dateRangeChanged ? true : false,
        clearAll: false,
        autheticationTableData: action.payload.data,
        autheticationLoc: action.payload.loc,
        authenticationTableTotalRows: action.payload.totalTableDataCount,
      };
    }
    case "UPDATE_AUTHENTICATION_TABLE_AS_PER_DATERANGE": {
      return {
        ...state,
        currentStatus: action.payload.dateRangeChanged
          ? "dateRangeChanged"
          : "dateRangeNotChanged",
      };
    }
    case "CHANGE_AUTHENTICATION_DROPDOWN_VALUE": {
      return {
        ...state,
        autheticationTableData: [],
        authenticationTableTotalRows: 0,
        mdrauthenticationTableDropDownvalue: action.payload,
      };
    }
    case "CHANGE_MDRAUTHENTICATION_TABLE_QUERY_AS_PER_PAGESELECTED": {
      let newInstance = [...state.authenticationTableQuery];
      let newObj = { ...newInstance[0] };
      let newAquery = [...newObj.query];
      let query1 = { ...newAquery[0] };
      query1.offset = action.payload * action.rowsPerPage;
      query1.limit = action.rowsPerPage;

      newAquery[0] = query1;
      newObj.query = newAquery;
      newInstance[0] = newObj;

      return {
        ...state,
        authenticationTableQuery: newInstance,
        currentStatus: "pageChange",
      };
    }
    case GET_AUTHENTICATION_SOURCE_FILTER: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRFilterDataFormatter(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          mdrAuthenticationSourceFilter: [...updatedData],
        };
      }

      return {
        ...state,
        mdrAuthenticationSourceFilter: [...action.payload.data],
      };
    }
    case APPLY_AUTHENTICATION_FILTER: {
      const filters = MDRMultipleFilters_Merge_Helper(action.payload);
      const newTableInstance = MDRApplyFilters(
        state.authenticationTableQuery,
        filters
      );
      return {
        ...state,
        authenticationTableQuery: [...newTableInstance],
        currentStatus: "apply",
      };
    }

    case "MDR_AUTHENTICATION_FILTERS_CLEARALL": {
      const data = cloneDeep(AutheticationTableQuery);
      const authenticationFilterState = clearMDRFilters(
        state.mdrAuthenticationSourceFilter
      );
      return {
        ...state,
        mdrAuthenticationSourceFilter: authenticationFilterState[0],
        clearAll: true,
        authenticationTableQuery: [...data],
      };
    }
    case "UPDATE_AUTHENTICATION_TABLE_AS_PER_SEARCHVALUE": {
      let newTableInstance = MDRTableSearchHelper(
        state.authenticationTableQuery,
        action
      );
      return {
        ...state,
        authenticationTableQuery: [...newTableInstance],
        autheticationTableData: [],
        authenticationTableTotalRows: 0,
        currentStatus: "searchValue",
      };
    }
    case "MDR_RESET_TABLEROWS_DATA_AS_SEARCH_FIELD_CHANGED": {
      return {
        ...state,
        autheticationTableData: [],
        authenticationTableTotalRows: 0,
      };
    }
    case "UPDATE_AUTHENTICATION_TABLE_AS_SEARCHVALUE_CLEARED": {
      let newTableInstance = MDRTableSearchHelper(
        state.authenticationTableQuery,
        action,
        true
      );
      return {
        ...state,
        authenticationTableQuery: [...newTableInstance],
        currentStatus: "searchValue",
      };
    }
    case "CLOSE_AUTHENTICATION_DRAWER": {
      return {
        ...state,
        authenticationDrawer: false,
        authenticationSideDrawerData: {},
      };
    }
    case "AUTHENTICATION_DRAWER_DATA": {
      return {
        ...state,
        authenticationDrawer: true,
        authenticationSideDrawerData: action.payload,
      };
    }
    case "CLEAR_CURRENT_STATUS": {
      return {
        ...state,
        currentStatus: ""
      };
    }
    default:
      return state;
  }
};