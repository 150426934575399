import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InactiveIcon from "../../assets/vizr_images/Group 3.png";
import setUpIcon from "../../assets/vizr_images/ic_set_up.svg";
import Grid from "@material-ui/core/Grid";
import { ColorButtonForAdminNavbar } from "../common/buttons";
// import DBIcon from "../../assets/vizr_images/ic_database.svg";
// import CloudIcon from "../../assets/vizr_images/ic_server_saas.svg";
// import RecordIcon from "../../assets/vizr_images/ic_data_records.svg";
// import WebIcon from "../../assets/vizr_images/ic_web.svg";
//import AlertIcon from '../../assets/vizr_images/ic_alert.svg';
import InitialSetupIcon from "../../assets/vizr_images/Group-6.svg";
import DashboardIcon from "../../assets/vizr_images/Group-5.svg";
import SystemSummaryIcon from "../../assets/vizr_images/Group-4.svg";
import AlertIcon from "../../assets/vizr_images/Group-3.svg";
import DataMapIcon from "../../assets/vizr_images/Group-1.svg";
import DataFlowIcon from "../../assets/vizr_images/ic_data_flow.svg";
import FlowGroupIcon from "../../assets/vizr_images/ic_flow_group.svg";
import AlertPoliciesIcon from "../../assets/vizr_images/Group-2.svg";
import IntegrationsHealthIcon from "../../assets/vizr_images/Group.svg";
import userManagementIcon from "../../assets/vizr_images/userManagement.svg";
import platformSettingsIcon from "../../assets/vizr_images/platformSettings.svg";
import { getUserPreferences } from "../../redux/actions/getUserPreferences";
import {
  fetchReport,
  generateReport,
  viewAlert,
  viewDataMap,
  viewDataSource,
} from "../../redux/constants/constants.js";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { reverse } from "lodash";
import UnresolvedAlerts from "../shared/totalAlerts/UnresolvedAlerts";
import Toggler from "../shared/Toggler";
import { DataMapRoute } from "../common/DatamapImage";
import CanAllow from "../shared/CanAllow";

export default function SystemSummary() {
  const permissions = useSelector((state) => state.applicationData.permissions);

  const reviewArr = [
    {
      option: "systemSetUp",
      value: "Review System Set Up",
      description:
        "Check if all applications/services are instrumented and connected to DruvStar Data Vision through agents. Check if the logs from all services (especially data sources) are being sent to DruvStar Data Vision through agents.",
      button: "Review Set Up",
      permission: viewDataSource,
    },
    {
      option: "alerts",
      value: "Review Alerts",
      description:
        "Check alerts, investigate and resolve them. If there are any false positive alerts, refine the corresponding policies to avoid the false positives in future.",
      button: "Review Alerts",
      permission: viewAlert,
    },
    {
      option: "dataMap",
      value: "Review Data Map",
      description: "",
      button: "Review Data Map",
      permission: viewDataMap,
    },
  ];

  const [selectedReview, setSelectedReview] = useState(reviewArr[0]);
  const tenant = window.localStorage.getItem("tenant");
  const [historyList, setHistoryList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const alertStatusUpdated = useSelector(
    (state) => state.alertStatusChanged.alertUpdated
  );
  const alertStatusUpdatedSuccessMessage = useSelector(
    (state) => state.alertStatusChanged.successMessage
  );
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const alertStatusUpdatedErrorMessage = useSelector(
    (state) => state.alertStatusChanged.unsuccessful
  );

  useEffect(() => {
    if (alertStatusUpdatedSuccessMessage !== "") {
      setSnackBarMessage(alertStatusUpdatedSuccessMessage);
    }
  }, [alertStatusUpdatedSuccessMessage]);

  useEffect(() => {
    if (alertStatusUpdatedErrorMessage !== "") {
      setSnackBarMessage(alertStatusUpdatedErrorMessage);
      setOpen(true);
    }
  }, [alertStatusUpdatedErrorMessage]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  useEffect(() => {
    async function getPreferences() {
      const response = await getUserPreferences();
      if (response) {
        let temp = [];
        response.historyList.map((each) => {
          let obj;
          let today = new Date(Date.now());
          let seven_past = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
          if (
            new Date(each.date) >= seven_past &&
            new Date(each.date) <= today
          ) {
            obj = {
              date: each.date,
              routes: reverse(each.pagesVisited),
            };
          }
          temp.push(obj);
        });
        setHistoryList(temp);
      }
    }
    getPreferences();
    window.scrollTo(0,0);
  }, []);

  const selectReview = (review) => {
    if (review) {
      let temp = reviewArr.filter((each) => each.option === review);
      setSelectedReview(temp[0]);
    }
  };
  const reviewSetUp = () => {
    if (selectedReview.option === "systemSetUp") {
      dispatch({ type: "TRACK_TRAVERSAL", payload: `Review Data Sources` });
      history.push(`/tenant/reviewDataSources`);
    } else if (selectedReview.option === "dataResources") {
      dispatch({ type: "TRACK_TRAVERSAL", payload: `Data Sources` });
      history.push(`/tenant/dataSources`);
    } else if (selectedReview.option === "alerts") {
      dispatch({ type: "TRACK_TRAVERSAL", payload: `Alerts` });
      history.push(`/tenant/alerts`);
    } else if (selectedReview.option === "dataMap") {
      dispatch({ type: "TRACK_TRAVERSAL", payload: `Data Map` });
      history.push(`/tenant/dataMap`);
    }
  };
  const getFlow = (routes) => {
    //console.log(routes)
    let str = "";
    routes.map((each, index) => {
      if (index !== routes.length - 1) {
        str = str.concat(`${each}-> `);
      } else {
        str = str.concat(each);
      }
    });
    return str;
  };

  useEffect(() => {
    if (alertStatusUpdated) {
      setOpen(true);
    }
  }, [alertStatusUpdated]);

    // Add permission param for each toggler as we are rendering based on permission
  const [togglers, setTogglers] = useState([
    { name: "Data Owner View", status: "inactive", permission: viewDataSource},
    { name: "System Summary", state: "active", permission: viewDataSource },
    { name: "Generate Report", state: "inactive", permission: generateReport},
    { name: "Report Repository", state: "inactive", permission: fetchReport},
    
  ]);

  const handleToggle = (name) => {
    if (name === "Data Owner View") {
      history.push("/tenant/dataOwner");
    } else if (name === "System Summary") {
      history.push("/tenant/systemSummary");
    } else if (name === "Generate Report") {
      history.push("/tenant/generateReport")
    } else if (name === "Report Repository") {
      history.push("/tenant/reportRepository")
    }
  };
  return (
    <React.Fragment>
      <div className="main">
        <div className="description">
          <div style={{ display: "grid" }}>
            <h2 style={{ margin: "0", fontSize: "32px" }}>System Summary</h2>
            <p style={{ margin: "0" }}>
              Recommended steps to be taken. Basic history of the application
              showing alerts and navigations.{" "}
            </p>
          </div>
          <DataMapRoute />
        </div>
        <div className="togglerandSearch_Conatiner">
          <Toggler
            togglers={togglers}
            loc="summary"
            setTogglers={setTogglers}
            history={history}
            handleToggle={handleToggle}
            permissionBased
          ></Toggler>
        </div>

        <div className="getting-started-container">
          <div className="getting-started-title">
            <h3 style={{ paddingTop: "15px", color: "white" }}>
              Getting Started Tutorial
            </h3>
          </div>
          <div style={{ width: "100%", marginLeft: "35px", marginTop: "7px" }}>
            <Grid container spacing={3}>
              {reviewArr.map((each) => {
                return (
                  <Grid item xs={4} className="grid-3">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => selectReview(each.option)}
                    >
                      <img src={setUpIcon} alt={each.option} />
                      &nbsp;&nbsp;
                      <small
                        className={
                          each.value === selectedReview.value
                            ? "small-text selected"
                            : "small-text"
                        }
                      >
                        {each.value}
                      </small>
                    </span>
                  </Grid>
                );
              })}
            </Grid>
            <Grid style={{ width: "100%" }} container spacing={3}>
              {/* <Grid item xs={3} className="grid-3">
                                    <div className="review-list">
                                        {reviewArr.map((each) => {
                                            return (
                                                <span style={{ cursor: 'pointer' }} onClick={() => selectReview(each.option)}>
                                                    <img src={setUpIcon} alt={each.option} />&nbsp;&nbsp;
                                                        <small style={{ paddingTop: '3px' }}>{each.value}</small>
                                                </span>
                                            )
                                        })}
                                    </div>
                                </Grid> */}
              <Grid item xs={9}>
                <div>
                  <h2 style={{ color: "white" }}>{selectedReview.value}</h2>
                  <p>{selectedReview.description}</p>
                </div>
              </Grid>
              <Grid item xs={3} className="grid-3">
                {selectedReview && (
                  <div style={{ margin: "65px 20px 0px 15px" }}>
                    <CanAllow needs={{permission: [selectedReview.permission]}}>
                      <ColorButtonForAdminNavbar
                       variant="contained"
                        color="primary"
                        size="small"
                        style={{ width: "85%", height: "35px" }}
                        onClick={() => {
                          reviewSetUp(selectedReview.option);
                        }}
                        className="min-margin"
                      >
                        {selectedReview.button}
                      </ColorButtonForAdminNavbar>
                    </CanAllow>
                    {/* <a style={{color:'white',textDecoration:'none'}} href='#'>Learn more about this.</a> */}
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="main-content">
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <UnresolvedAlerts />
            </Grid>
            <Grid item xs={5}>
              <div className="history-list">
                <span style={{ display: "flex", paddingLeft: "10px" }}>
                  {/* <img src={RefreshIcon} alt="refresh" /> */}
                  <h2 style={{ marginTop: "10px", paddingLeft: "5px" }}>
                    History list
                  </h2>
                </span>
                {historyList && historyList.length > 0
                  ? historyList.map((each) => {
                      if (!each) return;
                      return (
                        <React.Fragment>
                          <div className="history-date">
                            <p>{each.date}</p>
                          </div>
                          <div className="history">
                            {each.routes.map((e) => {
                              return (
                                <span style={{ display: "flex" }}>
                                  {e.indexOf("Alert") > -1 &&
                                    e.indexOf("Alert Policies") === -1 && (
                                      <img src={AlertIcon} alt="Alert" />
                                    )}
                                  {e.indexOf("Policies") > -1 && (
                                    <img
                                      src={AlertPoliciesIcon}
                                      alt="Alert policies"
                                    />
                                  )}
                                  {e.indexOf("System Summary") > -1 && (
                                    <img
                                      src={SystemSummaryIcon}
                                      alt="system-summary"
                                    />
                                  )}
                                  {e.indexOf("Review Data Sources") > -1 && (
                                    <img
                                      src={SystemSummaryIcon}
                                      alt="system-summary"
                                    />
                                  )}
                                  {e.indexOf("User Management") > -1 &&
                                    e.indexOf("Create User") === -1 &&
                                    e.indexOf("Edit User") === -1 &&
                                    e.indexOf("User Access Rights") === -1 && (
                                      <img
                                        src={userManagementIcon}
                                        alt="user-management"
                                      />
                                    )}
                                  {e.indexOf("Dashboard") > -1 && (
                                    <img src={DashboardIcon} alt="dashboard" />
                                  )}
                                  {e.indexOf("Data Map") > -1 && (
                                    <img src={DataMapIcon} alt="data-map" />
                                  )}
                                  {e.indexOf("Data Flow") > -1 &&
                                    e.indexOf("Data Flow Group") === -1 && (
                                      <img src={DataFlowIcon} alt="data-flow" />
                                    )}
                                  {e.indexOf("Data Flow Group") > -1 && (
                                    <img
                                      src={FlowGroupIcon}
                                      alt="data-flow-group"
                                    />
                                  )}
                                  {e.indexOf("Integrations") > -1 && (
                                    <img
                                      src={IntegrationsHealthIcon}
                                      alt="integrations"
                                    />
                                  )}
                                  {e.indexOf("Initial") > -1 && (
                                    <img
                                      src={InitialSetupIcon}
                                      alt="initial-setup"
                                    />
                                  )}
                                  {e.indexOf("Create User") > -1 && (
                                    <img
                                      src={userManagementIcon}
                                      alt="create-user"
                                    />
                                  )}
                                  {e.indexOf("Edit User") > -1 && (
                                    <img
                                      src={userManagementIcon}
                                      alt="edit-user"
                                    />
                                  )}
                                  {e.indexOf("Platform Setting") > -1 && (
                                    <img
                                      src={platformSettingsIcon}
                                      alt="platform-settings"
                                    />
                                  )}
                                  {e.indexOf("Access Rights") > -1 && (
                                    <img
                                      src={userManagementIcon}
                                      alt="platform-settings"
                                    />
                                  )}
                                  <p>{e}</p>
                                </span>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })
                  : ""}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Snackbar
        //key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        onExited={handleExited}
        //message={messageInfo ? messageInfo.message : undefined}
      >
        <Alert
          onClose={handleClose}
          severity={alertStatusUpdatedErrorMessage ? "error" : "success"}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
