import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Grid from "@material-ui/core/Grid";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import FilterComponent from "./filterComponent";
import SortComponent from "./sortComponent";
import PolicyFilter from "./policyFilter";
import ClearFilter from "./clearFilter";
import Button from "@material-ui/core/Button";
import HelpIcon from "../../assets/vizr_images/ic_help.svg";
import unChecked from "../../assets/vizr_images/Input_cell.svg";
import checked from "../../assets/vizr_images/Active_Input.svg";
import SortIcon from "../../assets/vizr_images/ic_sort-1.svg";
import DeleteIcon from "../../assets/vizr_images/ic_rubbish_bin.png";

import PropTypes from "prop-types";
import { updateAlertPolicyStatus } from "../../redux/actions/updateStatusAction";
import { deleteAlertPolicy } from "../../redux/actions/deletePoliciesAction";
import { updateAlertStatus } from "../../redux/actions/updateAlertStatusAction";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getAlertPolicySeverities } from "../../redux/actions/getAlertPolicySeveritiesAction";
import { getAlertResolutionTypes } from "../../redux/actions/getAlertResolutionTypesAction";
import { getAllAlertPolicyNames } from "../../redux/actions/getAllAlertPolicyNamesAction";
import { deleteTenant } from "../../redux/actions/deleteTenantAction";
import { ColorButtonForToolBar } from "../common/buttons";
import { DeleteButtonForToolBar } from "../common/buttons";
import { NewPolicyButtonForToolBar } from "../common/buttons";
import { NewPolicyButtonForPolicyCreateEdit } from "../common/buttons";
import { ColoredActionButton } from "../common/buttons";
import Popover from "@material-ui/core/Popover";
import {
  batchEditClick,
  filterBy_SelectedValues,
} from "../../redux/actions/userAccessrightsAction";
import { DatabaseDateRangePicker } from "../common/Navbar/DatabaseAccessDateRange";
import {
  updateAlertPolicy,
  createAlertPolicy,
  addTenant,
  updateAlert,
  addTenantUser,
  ACCESS_DETAILS_FILTERVALUES,
  GET_ALL_ACCESS_TOTAL_COUNT,
  CLEAR_ALL_ACCESS_TOTAL_COUNT,
  FailedLoginsAllUsers,
  FailedLoginsIPbyDistinctUsers,
  FailedLoginsIPbyDistinctIP,
  MDRIncidentSearchDropdownValues,
  MDRAuthenticationAllUsers,
  MDRAuthenticationUniqueUsers,
  MDRAuthenticationUniqueIps,
  MDREndpointsSearch,
  MDRPatchMonitoringSearch,
  dataSource,
  MDRIpConfigLoc,
  reviewDataSource,
  deleteTenantUser,
  deleteTenantRole,
  deleteAlertPolicyPermission,
  addPlatformUser,
  addDataFlowGroup,
  MDR_CONFIG,
  DELETE_TENANT,
  updateTenant,
  deletePlatformUser,
} from "../../redux/constants/constants";

import RolesContext from "../views/UserMangement/UserManagement";
import { ACCESSDETAILS_FILTER_SELECTEDVALUES } from "../../redux/actions/AccessDetailsAction";
import AppDateTimeRange from "../common/Navbar/AppDateTimeRange";
import IncidentTableFilter from "../common/Dashboard/MDRTables/IncidentTableFilter";
import AuthenticationTableFilter from "../common/Dashboard/MDRTables/AuthenticationTableFilter";
import EndPointsTableFilter from "../common/Dashboard/MDRTables/EndPointsTableFilter";
import PatchMonitoringTableFilters from "../common/Dashboard/MDRTables/PatchMonitoringTableFilters";

import {
  AutheticationTableQuery,
  AutheticationTableQuery_UniqueUsers,
} from "../../components/common/Dashboard/dashboard-data";
import { fade, makeStyles } from "@material-ui/core/styles";

import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import ImageTooltip from "../common/HelpIconWithTooltip";
import FormControl from "@material-ui/core/FormControl";
import { CreateUserButton } from "../common/buttons";
import CanAllow from "./CanAllow";
import { userPermission } from "../common/utilities";
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "300px",
    height: "75px",
    padding: "5px",
    boxShadow: "0 5px 8px 0px rgba(20, 18, 121, 0.5)",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      // width: "auto",
    },
  },
  searchIcon: {
    cursor: "pointer",
  },
  inputRoot: {
    color: "default",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    isClick,
    rows,
    loc,
    num,
    handleCheckbox,
    handleSortingFilter,
    clearFilterButtonClick,
    sortBy,
    history,
    selectedRows,
    severityFilter,
    resolutionFilter,
    policyFilter,
    handleDataFlowGroupOpen,
    newAlertPolicyHide,
    deletePolicyBtnHide,
    rolesClickHandler,
    setCallApi,
    selectedTenantForDelete,
    totalElements,
    project,
    type,
    toggleDrawer,
  } = props;

  const classes = useStyles();

  const roles = useContext(RolesContext);
  const FilterArr = useSelector(
    (state) => state.userAccessRightsReducer.FilterArr
  );
  const AccessDetails_FilterArr = useSelector(
    (state) => state.accessDetails.FilterArr
  );

  const AccessDetailsSideDrawerData = useSelector(
    (state) => state.normalDirectDataSourceReducer.AccessDetailsSideDrawerData
  );

  const filterBySelectedValuesAccessDetails = useSelector(
    (state) => state.accessDetails.filterBySelectedValues
  );

  const AccessDetails_FilterValue = useSelector(
    (state) => state.accessDetails.filterValue
  );
  const filterValue = useSelector(
    (state) => state.userAccessRightsReducer.filterValue
  );

  const permissions = useSelector((state) => state.applicationData.permissions);
  const accessDetailsDrawer = useSelector(
    (state) => state.userAccessRightsReducer.accessDetailsDrawer
  );
  const tenant = window.localStorage.getItem("tenant");

  const autheticationLoc = useSelector(
    (state) => state.MDRAuthenticationReducer.autheticationLoc
  );
  const loader = useSelector(
    (state) => state.allAccessData.loader
  )
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertPolicySeverities, setAlertPolicySeverities] = useState([]);
  const [alertResolutionTypes, setAlertResolutionTypes] = useState([]);
  const [policyArr, setPolicyArr] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  // const [value, setValue] = useState("");

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setDialogOpen(true);
    dispatch({
      type: "RESET_DELETESTATE",
    });
  };

  const MDRDropdownValue = useSelector(
    (state) => state.IncidenetReducer.MDRDropdownValue
  );

  const [mdrAutenticationDropDownValue, setMdrAutenticationDropDownValue] =
    useState(FailedLoginsAllUsers);

  // taking the state of both main dropdown i.e., "MDRDropdownValue" and the dropdown which is in authentication dashboard i.e., "mdrAutenticationDropDownValue" and closing it to avoid unnecessary popover issue when user navigates to other screen
  useEffect(() => {
    setOpen(false);
  }, [MDRDropdownValue, mdrAutenticationDropDownValue]);

  const handleClose = () => {
    setDeleteMessage("");
    setDialogOpen(false);
  };
  const handleFlowOpen = (bool) => {
    handleDataFlowGroupOpen(bool);
  };

  const filterBy = useSelector(
    (state) => state.userAccessRightsReducer.filterValue
  );

  useEffect(() => {
    //disabling tool bar buttons if no data for table present
    if (rows.length > 0) {
      setDisableButton(false);
    } else if (
      loc === "database_Users" ||
      loc === "Access_details" ||
      project === "MDR"
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [rows]);

  useEffect(() => {
    async function getResolutionTypes() {
      const response = await getAlertResolutionTypes();
      if (response) {
        setAlertResolutionTypes(response.content);
      }
    }
    if (open) {
      getResolutionTypes();
    }
  }, [open]);
  useEffect(() => {
    async function getPolicyNames() {
      const response = await getAllAlertPolicyNames();
      if (response) {
        setPolicyArr(response.content);
      }
    }
    if (open) {
      getPolicyNames();
    }
  }, [open]);
  useEffect(() => {
    async function getSeverities() {
      const response = await getAlertPolicySeverities();
      if (response) {
        setAlertPolicySeverities(response.content);
      }
    }
    if (open) {
      getSeverities();
    }
  }, [open]);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const deleteSubmit = () => {
    handleClose();
    if (loc === "tenant-management-Tenants") {
      dispatch(deleteTenant({ ids: selectedRows }));
      dispatch({ type: "SELECTED_ITEMS", selectedArray: [] });
      setCallApi(true);
    } else if (loc === "users") {
      dispatch({
        type: "USERS_DELETE",
        selectedArray: [...selectedRows],
      });
    } else if (loc === "roles") {
      dispatch({
        type: "ROLES_DELETE",
        selectedArray: [...selectedRows],
      });
    } else {
      dispatch(deleteAlertPolicy({ ids: selectedRows }));
    }
  };
  const checkValidity = (e) => {
    if (e.target.value !== "" && e.target.value !== null) {
      if (e.target.value === selectedTenantForDelete) {
        setDeleteMessage("Tenant name matches.");
      } else {
        setDeleteMessage("");
      }
    }
  };
  // const searchAppFilter = (eve) => {
  //   console.log(eve);
  //   if (
  //     eve.target.value &&
  //     eve.target.value.length &&
  //     eve.target.value.length > 2
  //   ) {
  //     searchAppFilterReq(eve);
  //   }
  // };

  // async function searchAppFilterReq(params) {
  //   const response = await fetchDMSearchFilterById(params);
  //   if (response && response.content) {
  //     setAppFiltersList(response.content);
  //   } else {
  //     setAppFiltersList([]);
  //   }
  // }

  const [selecedValues, setSelectedValues] = useState([]);
  useEffect(() => {
    if (FilterArr.length > 0 && filterValue) {
      if (filterValue === "DataSourceName") {
        const arr = FilterArr.map((el) => {
          // return { name: el, isSelected: true };
          el.isSelected = false;
          return el;
        });
        setSelectedValues([...arr]);
      } else {
        const arr = FilterArr.map((el) => {
          el.isSelected = false;
          return el;
        });
        setSelectedValues([...arr]);
      }
    }
  }, [FilterArr, filterValue]);

  const [filterSelectedValues, setFilterSeledtedValues] = useState([]);

  useEffect(() => {
    if (filterBySelectedValuesAccessDetails.length > 0) {
      setFilterSeledtedValues([...filterBySelectedValuesAccessDetails]);
    } else if (AccessDetails_FilterArr.length > 0) {
      const arr = AccessDetails_FilterArr.map((el) => {
        el.isSelected = false;
        return el;
      });
      setFilterSeledtedValues([...arr]);
    } else {
      setFilterSeledtedValues([]);
    }
  }, [AccessDetails_FilterArr, filterBySelectedValuesAccessDetails]);

  const dataBaseUsersSelectClickHandler = (index, value) => {
    const arr = selecedValues;
    arr[index].isSelected = !arr[index].isSelected;
    setSelectedValues([...arr]);
  };
  const AccessDetailsSelectClickHandler = (index, value) => {
    const arr = filterSelectedValues;
    if (arr[index]) {
      arr[index].isSelected = !arr[index].isSelected;
      setFilterSeledtedValues([...arr]);
    }
  };
  const selectedItems = useSelector(
    (state) => state.selectedItemsData.selectedItems
  );

  const getName = (el) => {
    if (loc === "database_Users") {
      if (filterValue === dataSource) {
        return el.aliasName;
      } else {
        return el.name ? el.name : el.value ? el.value : "";
      }
    } else {
      if (
        AccessDetails_FilterValue ===
        ACCESS_DETAILS_FILTERVALUES.DATASOURCE_NAME
      ) {
        return el.aliasName;
      } else if (filterValue === "lastAccessedTime") {
        return "calender";
      } else {
        return el.name ? el.name : el.value ? el.value : "";
      }
    }
  };

  const changeAuthenticationDropDownUser = (e) => {
    console.log("enhancedToolBar");
    setMdrAutenticationDropDownValue(e.target.value);
    dispatch({
      type: "CHANGE_AUTHENTICATION_DROPDOWN_VALUE",
      payload: e.target.value,
    });
  };
  let [selectDropdownList, setselectDropdownList] = useState([]);
  // let [mdrSearchFilterQUery, setmdrSearchFilterQUery] = useState("");
  console.log(loc);
  useEffect(() => {
    setSearch("");
    switch (loc) {
      case "MDRDashboard_IncidentTable": {
        setcurrentDropdownValue(MDRIncidentSearchDropdownValues[1].value);
        setSearch("");
        setselectDropdownList([...MDRIncidentSearchDropdownValues]);
        break;
      }
      case "MDRDashboard_AuthenticationTable_AllUsers": {
        setcurrentDropdownValue(MDRAuthenticationAllUsers[1].value);
        setSearch("");
        setMdrAutenticationDropDownValue(FailedLoginsAllUsers);
        setselectDropdownList([...MDRAuthenticationAllUsers]);
        break;
      }
      case "MDRDashboard_AuthenticationTable_UniqueUsers": {
        setcurrentDropdownValue("selectsearchField");
        setSearch("");
        setselectDropdownList([...MDRAuthenticationUniqueUsers]);
        break;
      }
      case "MDRDashboard_AuthenticationTable_UniqueIPs": {
        setcurrentDropdownValue("selectsearchField");
        setSearch("");
        setselectDropdownList([...MDRAuthenticationUniqueIps]);
        break;
      }
      case "MDRDashboard_EndPointsTable": {
        setcurrentDropdownValue(MDREndpointsSearch[0].value);
        setSearch("");
        setselectDropdownList([...MDREndpointsSearch]);
        break;
      }

      case "MDRDashboard_PatchMonitoring": {
        setcurrentDropdownValue(MDRPatchMonitoringSearch[0].value);
        setSearch("");
        setselectDropdownList([...MDRPatchMonitoringSearch]);
        break;
      }

      default:
    }
  }, [loc]);

  let [currentDropdownValue, setcurrentDropdownValue] = useState("selectsearchField");

  //To get the total count of all access from api 
  const handleDataAccessLogs = () => {
    dispatch({ type: CLEAR_ALL_ACCESS_TOTAL_COUNT });
    let actorId = AccessDetailsSideDrawerData.actorId ? AccessDetailsSideDrawerData.actorId : "";
    let dataSourceId = AccessDetailsSideDrawerData.dataSourceId;
    let lastAccessedTimeStamp = AccessDetailsSideDrawerData.lastAccessedOn
      ? AccessDetailsSideDrawerData.lastAccessedOn
      : "";
    if (loc === "All_Access" || loc === "All_Access_serviceDataAccesses") {
      isClick(true);
      dispatch({
        type: GET_ALL_ACCESS_TOTAL_COUNT, payload: {
          actorId,
          dataSourceId,
          lastAccessedTimeStamp,
        }
      });
    }
  };

  //To prevent the anchor tag from reload and redirecting the current page
  const handlePageReload = (event) => {
    event.preventDefault();
  };

  //To render the totalCount of all access
  const renderAllAccessTotal = (num) => {
    return (
      <>
        {
          (loc === "All_Access") && num === 5000 && !loader ?
            <a className="total-count" href={(event) => handlePageReload(event)} onClick={() => { handleDataAccessLogs() }}>
              {num}+
            </a>
            :
            loader ?
              <div className="loading logs-count"></div>
              : num

        }
      </>
    )
  };
  
  const changeMDRSearchFieldDropDown = (e) => {
    setcurrentDropdownValue(e.target.value);
  };

  const [search, setSearch] = useState("");

  const MDRTablesSearchHandler = (searchValue) => {
    if (!searchValue) {
      dispatch({
        type: "MDR_RESET_TABLEROWS_DATA_AS_SEARCH_FIELD_CHANGED",
        payload: "",
      });
      switch (loc) {
        case "MDRDashboard_IncidentTable": {
          dispatch({
            type: "UPDATE_INCIDENT_TABLE_AS_SEARCHVALUE_CLEARED",
            payload: "",
          });

          break;
        }
        case "MDRDashboard_AuthenticationTable_AllUsers": {
          dispatch({
            type: "UPDATE_AUTHENTICATION_TABLE_AS_SEARCHVALUE_CLEARED",
          });

          break;
        }
        case "MDRDashboard_AuthenticationTable_UniqueUsers": {
          dispatch({
            type: "UPDATE_AUTHENTICATION_TABLE_AS_SEARCHVALUE_CLEARED",
          });

          break;
        }
        case "MDRDashboard_AuthenticationTable_UniqueIPs": {
          dispatch({
            type: "UPDATE_AUTHENTICATION_TABLE_AS_SEARCHVALUE_CLEARED",
          });

          break;
        }
        case "MDRDashboard_EndPointsTable": {
          dispatch({
            type: "UPDATE_ENDPOINTS_TABLE_AS_SEARCHVALUE_CLEARED",
          });

          break;
        }

        case "MDRDashboard_PatchMonitoring": {
          dispatch({
            type: "UPDATE_PATCHMONITORING_TABLE_AS_SEARCHVALUE_CLEARED",
          });

          break;
        }

        default:
      }
    }
    let filter = {
      member: currentDropdownValue,
      operator: "contains",
      values: [searchValue],
    };

    if (searchValue) {
      dispatch({
        type: "MDR_RESET_TABLEROWS_DATA_AS_SEARCH_FIELD_CHANGED",
        payload: searchValue,
      });
      switch (loc) {
        case "MDRDashboard_IncidentTable": {
          dispatch({
            type: "UPDATE_INCIDENT_TABLE_AS_PER_SEARCHVALUE",
            payload: filter,
          });

          break;
        }
        case "MDRDashboard_AuthenticationTable_AllUsers": {
          dispatch({
            type: "UPDATE_AUTHENTICATION_TABLE_AS_PER_SEARCHVALUE",
            payload: filter,
          });

          break;
        }
        case "MDRDashboard_AuthenticationTable_UniqueUsers": {
          dispatch({
            type: "UPDATE_AUTHENTICATION_TABLE_AS_PER_SEARCHVALUE",
            payload: filter,
          });

          break;
        }
        case "MDRDashboard_AuthenticationTable_UniqueIPs": {
          dispatch({
            type: "UPDATE_AUTHENTICATION_TABLE_AS_PER_SEARCHVALUE",
            payload: filter,
          });

          break;
        }
        case "MDRDashboard_EndPointsTable": {
          dispatch({
            type: "UPDATE_ENDPOINTS_TABLE_AS_PER_SEARCHVALUE",
            payload: filter,
          });

          break;
        }

        case "MDRDashboard_PatchMonitoring": {
          dispatch({
            type: "UPDATE_PATCHMONITORING_TABLE_AS_PER_SEARCHVALUE",
            payload: filter,
          });

          break;
        }

        default:
      }
    }
  };
  const getTableName = (loc) => {
    let tableName = "";
    switch (loc) {
      case "policy":
        tableName = `Policies`;
        break;
      case "alerts":
      case "summary":
        tableName = `Alerts`;
        break;
      case "drawer":
        tableName = "Related Alerts";
        break;
      case "create-policy":
        tableName = "Data Flows in this Group";
        break;
      case "review-data-sources":
      case "data-sources":
        tableName = "Data Sources";
        break;
      case "show-policy-flow-groups":
        tableName = "Data Flow Groups";
        break;
      case "tenant-management-Tenants":
        tableName = "All Existing Tenants";
        break;
      case "tenant-management-Deleted":
        tableName = "Deleted Tenants";
        break;
      case "users":
        tableName = "All Users";
        break;
      case "roles":
        tableName = "Roles";
        break;
      case "sql-statements":
        tableName = "Database SQL Statements";
        break;
      case "Access_details":
      case "database_Users":
        tableName = "Data Source Users";
        break;
      case "MDRDashboard_IncidentTable":
        tableName = "Incidents";
        break;
      case "MDRDashboard_EndPointsTable":
        tableName = "Agents Instances";
        break;
      case "MDRDashboard_PatchMonitoring":
        tableName = "Patch Events";
        break;

      case "span-sql-statements":
        tableName = "Span SQL Statements";
        break;

      case "OneDrive":
        tableName = "";
        break;

      default:
        break;
    }
    return tableName;
  };
  const deleteIconNeeds = {
     'policy': deleteAlertPolicyPermission,
     'tenant-management-Tenants': DELETE_TENANT,
     'users': userPermission(deleteTenantUser,deletePlatformUser),
     'roles': deleteTenantRole,
     MDRIpConfigLoc: MDR_CONFIG 
  }
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        {loc === "tenant-management-Tenants" ? (
          <DialogContent>
            <label>Please enter the selected tenant name here:</label>
            <input type="text" onChange={(e) => checkValidity(e)} />
            {deleteMessage !== "" && (
              <>
                <p style={{ color: "green" }}>{deleteMessage}</p>
                <p>Press Confirm to delete the tenant from the platform.</p>
              </>
            )}
          </DialogContent>
        ) : loc === MDRIpConfigLoc ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting these will mean they will be unavailable for you and your
              team.
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting these cnfigurations details mean they will be unavailable
              for you and your team.
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <ColoredActionButton
            style={{
              width: "124px",
              height: "32px",
              boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
            }}
            onClick={handleClose}
          >
            Cancel
          </ColoredActionButton>
          <NewPolicyButtonForPolicyCreateEdit
            style={{
              marginBottom: "0px",
              cursor: "pointer",
              width: "124px",
              height: "32px",
            }}
            onClick={() => deleteSubmit()}
            disabled={
              loc === "tenant-management-Tenants"
                ? deleteMessage !== ""
                  ? false
                  : true
                : disableButton
            }
            //autoFocus
          >
            Confirm
          </NewPolicyButtonForPolicyCreateEdit>
        </DialogActions>
      </Dialog>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          <Grid style={{ display: "flex" }} item xs={5}>
            {numSelected > 0 ? (
              <Typography
                className="title"
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : (
              ""
            )}

            <Typography
              className="title"
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {getTableName(loc)}
            </Typography>
            {loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
            loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
            loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ? (
              <div>
                <Select
                  id=""
                  style={{
                    minWidth: "200px",
                    maxWidth: "400px",
                    marginRight: "1rem",
                    marginTop: "0.4rem",
                  }}
                  value={mdrAutenticationDropDownValue}
                  onChange={changeAuthenticationDropDownUser}
                  className=""
                >
                  <MenuItem value={FailedLoginsAllUsers}>
                    Failed Logins: All Users
                  </MenuItem>
                  <MenuItem value={FailedLoginsIPbyDistinctUsers}>
                    Failed Logins: IP By Distinct Users
                  </MenuItem>
                  <MenuItem value={FailedLoginsIPbyDistinctIP}>
                    Failed Logins: Users By Distinct IP
                  </MenuItem>
                </Select>
              </div>
            ) : (
              ""
            )}

            {loc !== "drawer" &&
              loc !== "create-policy" &&
              loc !== "addDataFlowGroup" &&
              loc !== "show-policy-flow-groups" && (
                <>
                  <p style={{ margin: "11px 5px 0px 0px", minWidth: "45px" }}>
                    Total:
                  </p>
                  <div className={loader ? "" : "count"}>
                    {loc === "policy" ||
                    loc === "alerts" ||
                    loc === "summary" ||
                    loc === "tenant-management-Tenants" ||
                    loc === "review-data-sources" ||
                    loc === "users" ||
                    loc === "roles" ||
                    loc === "sql-statements" ||
                    loc === "database_Users" ||
                    loc === "Access_details" ||
                    loc !== "accessAnalomy" ||
                    loc !== "All_Access" ||
                    loc !== "All_Access_File_PopUp" ||
                    loc !== "sensitiveAccess" ||
                    loc === "span-sql-statements" ||
                    loc === "OneDrive"
                    ? totalElements
                      ? totalElements
                      : renderAllAccessTotal(num)
                    : ""
                  }
                  </div>
                </>
              )}
            {loc !== "drawer" &&
              loc !== "addDataFlowGroup" &&
              loc !== "accessAnalomy" &&
              loc !== "All_Access" &&
              loc !== "All_Access_File_PopUp" &&
              loc !== "sensitiveAccess" &&
              (props.description ? (
                <ImageTooltip description={props.description} />
              ) : (
                <img className="help-icon" src={HelpIcon} alt="help" />
              ))}
          </Grid>

          <Grid item xs={7} style={{ float: "right", textAlign: "right" }}>
            {loc !== "drawer" &&
            loc !== "create-policy" &&
            loc !== "addDataFlowGroup" ? (
              <>    
                {!deletePolicyBtnHide && 
                (loc === "policy" ||
                  loc === "tenant-management-Tenants" ||
                  loc === "users" ||
                  loc === "roles" ||
                  loc === MDRIpConfigLoc) ? (
                  <CanAllow needs={{ permission: [deleteIconNeeds[loc]] }}>
                    <DeleteButtonForToolBar
                      variant="contained"
                      id="delete"
                      size="small"
                      color="primary"
                      disabled={disableButton}
                      // && permissions.indexOf(deleteAlertPolicyPermission) > -1
                      onClick={() => handleClickOpen()}
                      className="min-margin table-buttons"
                    >
                    <img
                      style={{ width: '24px', height: '22px' }}
                      src={DeleteIcon}
                      alt="delete"
                      />
                    </DeleteButtonForToolBar>
                  </CanAllow>
                  ) : (
                  ""
                )}
                {loc === "review-data-sources" && (
                  <CanAllow needs={{permission: [reviewDataSource]}}>
                    <CreateUserButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(event) => {
                          toggleDrawer(true, event);
                        }}
                      className="min-margin table-buttons"
                    >
                      <AddCircleOutlineIcon style={{ padding: "3px" }} />
                      Add Data Source
                    </CreateUserButton>
                  </CanAllow>
                )}                  

                {loc === "MDRDashboard_IncidentTable" ||
                loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
                loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
                loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ||
                loc === "MDRDashboard_EndPointsTable" ||
                loc === "MDRDashboard_PatchMonitoring" ? (
                  <span style={{ marginRight: "1rem" }}>
                    <Select
                      style={{ minWidth: "200px", maxWidth: "400px" }}
                      value={currentDropdownValue}
                      onChange={(event) => changeMDRSearchFieldDropDown(event)}
                      className="user-type-dropdown"
                    >
                      {selectDropdownList.map((obj) => {
                        return (
                          <MenuItem value={obj.value}>{obj.field}</MenuItem>
                        );
                      })}
                    </Select>
                    <span className={classes.search}>
                      <InputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={search}
                        disabled={currentDropdownValue === "selectsearchField"}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          setSearch(e.target.value);
                          if (e.target.value === "") {
                            MDRTablesSearchHandler("");
                          }
                          // MDRTablesSearchHandler(e.target.value);
                        }}
                        endAdornment={
                          <SearchIcon
                            className={classes.searchIcon}
                            onClick={() => {
                              MDRTablesSearchHandler(search);
                            }}
                          />
                        }
                      />
                      {/* <InputBase
                        placeholder="Search"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        value={search}
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          setSearch(e.target.value);
                          if (e.target.value === "") {
                            MDRTablesSearchHandler("");
                          }
                          // MDRTablesSearchHandler(e.target.value);
                        }}
                      />
                      <div className={classes.searchIcon}>
                        <SearchIcon
                          onClick={() => {
                            MDRTablesSearchHandler(search);
                          }}
                        />
                      </div> */}
                    </span>
                  </span>
                ) : (
                  ""
                )}

                {loc !== "show-policy-flow-groups" &&
                  loc !== "tenant-management-Tenants" &&
                  loc !== "users" &&
                  loc !== "roles" &&
                  loc !== "sql-statements" &&
                  loc !== "span-sql-statements" &&
                  loc !== "OneDrive" &&
                  loc !== "accessAnalomy" &&
                  loc !== "All_Access" &&
                  loc !== "All_Access_File_PopUp" &&
                  loc !== "sensitiveAccess" &&
                  loc !== "accessAnalomy_serviceDataAccesses" &&
                  loc !== "All_Access_serviceDataAccesses" &&
                  loc !== "sensitiveAccess_serviceDataAccesses" &&
                  loc !== "datasourceDetails_serviceDataAccesses" &&
                  loc !== "review-data-sources" &&
                  loc !== "drawer" &&
                  loc !== "MDRDashboard_AuthenticationTable_UniqueUsers" &&
                  loc !== "MDRDashboard_AuthenticationTable_UniqueIPs" && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleClick("bottom")}
                      disabled={disableButton}
                      style={{
                        color: "#081981",
                        backgroundColor: "transparent",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                      startIcon={<img src={SortIcon} alt="sort-filter" />}
                      className="min-margin table-buttons"
                    >
                      {loc === "policy" ? "Sort" : "Sort & Filter"}
                    </Button>
                  )}
                {loc === "policy" ? (
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <>
                        <div className="clear-filter-container">
                          <ClearFilter
                            className="clear-filter"
                            loc={loc}
                            clearFilterButtonClick={clearFilterButtonClick}
                            toggle={handleClick}
                          />
                        </div>
                        <div className="popper-root">
                          <Fade {...TransitionProps} timeout={350}>
                            <SortComponent
                              sortBy={sortBy}
                              handleSortingFilter={handleSortingFilter}
                              loc={loc}
                            />
                          </Fade>
                        </div>
                      </>
                    )}
                  </Popper>
                ) : loc === "database_Users" ? (
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    style={{
                      boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
                      marginTop: "10rem",
                      marginRight: "1rem",
                      zIndex: "10",
                    }}
                  >
                    {({ TransitionProps }) => (
                      <>
                        <div className="clear-filter-container">
                          <ClearFilter
                            className="clear-filter"
                            // clearFilterButtonClick={clearFilterButtonClick}
                            toggle={handleClick}
                            loc={loc}
                            selecedValues={selecedValues}
                            setSelectedValues={setSelectedValues}
                          />
                        </div>
                        <div
                          className="popper-root"
                          style={{ height: "550px" }}
                        >
                          <Fade {...TransitionProps} timeout={350}>
                            <SortComponent
                              sortBy={sortBy}
                              handleSortingFilter={handleSortingFilter}
                              loc={loc}
                            />
                          </Fade>
                          <Fade {...TransitionProps} timeout={350}>
                            <SortComponent
                              sortBy={sortBy}
                              handleSortingFilter={handleSortingFilter}
                              loc={loc}
                              type="filter"
                            />
                          </Fade>

                          <FormControl
                            component="fieldset"
                            style={{ borderLeft: "2px solid grey" }}
                          >
                            <div
                              style={{
                                minheight: "350px",
                                minWidth: "300px",
                                width: "390px",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "7px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                paddingLeft: "1.5rem",
                              }}
                            >
                              {FilterArr.length > 0 &&
                                filterBy !== "expiryDate" &&
                                FilterArr.map((el, index) =>
                                  el ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "1rem",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      <img
                                        src={
                                          selecedValues.length > 0 &&
                                          selecedValues[index]
                                            ? selecedValues[index].isSelected
                                              ? checked
                                              : unChecked
                                            : checked
                                        }
                                        alt="select"
                                        style={{
                                          marginRight: "0.8rem",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          dataBaseUsersSelectClickHandler(
                                            index,
                                            el
                                          );
                                          dispatch(
                                            filterBy_SelectedValues(
                                              selecedValues
                                            )
                                          );
                                        }}
                                      ></img>
                                      <div>{getName(el)}</div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                              {filterBy === "expiryDate" && (
                                <DatabaseDateRangePicker />
                              )}
                            </div>
                          </FormControl>
                        </div>
                      </>
                    )}
                  </Popper>
                ) : loc === "MDRDashboard_IncidentTable" ? (
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    style={{
                      boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
                      zIndex: "10",
                    }}
                  >
                    {({ TransitionProps }) => (
                      <>
                        <div className="clear-filter-container">
                          <ClearFilter
                            className="clear-filter"
                            toggle={handleClick("bottom")}
                            loc={loc}
                          />
                        </div>

                        <div className="popper-root mdrIncidents">
                          <IncidentTableFilter></IncidentTableFilter>
                        </div>
                      </>
                    )}
                  </Popper>
                ) : loc === "MDRDashboard_AuthenticationTable_AllUsers" ? (
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    style={{
                      boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
                      zIndex: "10",
                    }}
                  >
                    {({ TransitionProps }) => (
                      <>
                        <div className="clear-filter-container">
                          <ClearFilter
                            className="clear-filter"
                            toggle={handleClick("bottom")}
                            loc={loc}
                          />
                        </div>

                        <div className="popper-root mdrAuthetication">
                          <AuthenticationTableFilter></AuthenticationTableFilter>
                        </div>
                      </>
                    )}
                  </Popper>
                ) : loc === "MDRDashboard_EndPointsTable" ? (
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    style={{
                      boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
                      zIndex: "10",
                    }}
                  >
                    {({ TransitionProps }) => (
                      <>
                        <div className="clear-filter-container">
                          <ClearFilter
                            className="clear-filter"
                            toggle={handleClick("bottom")}
                            loc={loc}
                          />
                        </div>

                        <div className="popper-root mdrendpoints">
                          <EndPointsTableFilter></EndPointsTableFilter>
                        </div>
                      </>
                    )}
                  </Popper>
                ) : loc === "MDRDashboard_PatchMonitoring" ? (
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    style={{
                      boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
                      zIndex: "10",
                    }}
                  >
                    {({ TransitionProps }) => (
                      <>
                        <div className="clear-filter-container">
                          <ClearFilter
                            className="clear-filter"
                            toggle={handleClick("bottom")}
                            loc={loc}
                          />
                        </div>

                        <div className="popper-root mdrendpoints">
                          <PatchMonitoringTableFilters></PatchMonitoringTableFilters>
                        </div>
                      </>
                    )}
                  </Popper>
                ) : loc === "Access_details" ? (
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    style={{
                      boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
                      marginTop: "10rem",
                      marginRight: "1rem",
                      zIndex: "10",
                    }}
                  >
                    {({ TransitionProps }) => (
                      <>
                        <div className="clear-filter-container">
                          <ClearFilter
                            className="clear-filter"
                            // clearFilterButtonClick={clearFilterButtonClick}
                            toggle={handleClick}
                            loc={loc}
                            selecedValues={filterSelectedValues}
                            // setSelectedValues={setSelectedValues}
                            setFilterSeledtedValues={setFilterSeledtedValues}
                          />
                        </div>
                        <div className="popper-root">
                          <Fade {...TransitionProps} timeout={350}>
                            <SortComponent
                              sortBy={sortBy}
                              handleSortingFilter={handleSortingFilter}
                              loc={loc}
                            />
                          </Fade>
                          <Fade {...TransitionProps} timeout={350}>
                            <SortComponent
                              sortBy={sortBy}
                              handleSortingFilter={handleSortingFilter}
                              loc={loc}
                              type="filter"
                            />
                          </Fade>{" "}
                          <FormControl
                            component="fieldset"
                            style={{
                              borderLeft: "2px solid grey",
                              width: "380px",
                              height: "396px",
                              padding: "10px",
                              "overflow-y": "auto",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "2rem",
                                paddingLeft: "1.5rem",
                                wordBreak: "break-all",
                              }}
                            >
                              {AccessDetails_FilterArr.length > 0 &&
                                AccessDetails_FilterValue !==
                                  ACCESS_DETAILS_FILTERVALUES.LASTTIME_ACCESSED &&
                                AccessDetails_FilterArr.map((el, index) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <img
                                      src={
                                        filterSelectedValues.length > 0 &&
                                        filterSelectedValues[index]
                                          ? filterSelectedValues[index]
                                              .isSelected
                                            ? checked
                                            : unChecked
                                          : checked
                                      }
                                      alt="select"
                                      style={{
                                        marginRight: "0.8rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        AccessDetailsSelectClickHandler(
                                          index,
                                          el
                                        );
                                        dispatch(
                                          ACCESSDETAILS_FILTER_SELECTEDVALUES(
                                            filterSelectedValues
                                          )
                                        );
                                      }}
                                    ></img>
                                    <div>{getName(el)}</div>
                                  </div>
                                ))}
                              {AccessDetails_FilterValue ===
                                ACCESS_DETAILS_FILTERVALUES.LASTTIME_ACCESSED && (
                                <>
                                  <AppDateTimeRange loc="AccessDetailsPopup"></AppDateTimeRange>
                                </>
                              )}
                            </div>
                          </FormControl>
                        </div>
                      </>
                    )}
                  </Popper>
                ) : loc === "alerts" || loc === "summary" ? (
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClick}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <>
                        <div className="clear-filter-container">
                          <ClearFilter
                            className="clear-filter"
                            clearFilterButtonClick={clearFilterButtonClick}
                            toggle={handleClick}
                          />
                          s
                        </div>
                        <div className="popper-root">
                          <Fade timeout={350}>
                            <SortComponent
                              sortBy={sortBy}
                              handleSortingFilter={handleSortingFilter}
                              loc={loc}
                            />
                          </Fade>
                          <Fade timeout={350}>
                            <FilterComponent
                              severityFilter={severityFilter}
                              resolutionFilter={resolutionFilter}
                              severitiesArray={alertPolicySeverities}
                              resolutionTypes={alertResolutionTypes}
                              handleCheckbox={handleCheckbox}
                            />
                          </Fade>
                          <Fade timeout={350}>
                            <PolicyFilter
                              policyFilter={policyFilter}
                              policyArr={policyArr}
                              handleCheckbox={handleCheckbox}
                            />
                          </Fade>
                        </div>
                      </>
                    </div>
                  </Popover>
                ) : (
                  // <Popper
                  //   open={open}
                  //   anchorEl={anchorEl}
                  //   placement={placement}
                  //   transition
                  //   style={{ boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)" }}
                  // >
                  //   {({ TransitionProps }) => (
                  //     <>
                  //       <div className="clear-filter-container">
                  //         <ClearFilter
                  //           className="clear-filter"
                  //           clearFilterButtonClick={clearFilterButtonClick}
                  //           toggle={handleClick}
                  //         />
                  //       </div>
                  //       <div className="popper-root">
                  //         <Fade {...TransitionProps} timeout={350}>
                  //           <SortComponent
                  //             sortBy={sortBy}
                  //             handleSortingFilter={handleSortingFilter}
                  //             loc={loc}
                  //           />
                  //         </Fade>
                  //         <Fade {...TransitionProps} timeout={350}>
                  //           <FilterComponent
                  //             severityFilter={severityFilter}
                  //             resolutionFilter={resolutionFilter}
                  //             severitiesArray={alertPolicySeverities}
                  //             resolutionTypes={alertResolutionTypes}
                  //             handleCheckbox={handleCheckbox}
                  //           />
                  //         </Fade>
                  //         <Fade {...TransitionProps} timeout={350}>
                  //           <PolicyFilter
                  //             policyFilter={policyFilter}
                  //             policyArr={policyArr}
                  //             handleCheckbox={handleCheckbox}
                  //           />
                  //         </Fade>
                  //       </div>
                  //     </>
                  //   )}
                  // </Popper>
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {(loc === "alerts" || loc === "summary") && (
              <>
                <ColorButtonForToolBar
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    disableButton || permissions.indexOf(updateAlert) === -1
                  }
                  onClick={() => {
                    dispatch(
                      updateAlertStatus({
                        status: "MARK_RESOLVED",
                        ids: selectedRows,
                      })
                    );
                  }}
                  className="min-margin table-buttons"
                >
                  Resolve
                </ColorButtonForToolBar>
                <ColorButtonForToolBar
                  variant="contained"
                  color="primary"
                  disabled={
                    disableButton || permissions.indexOf(updateAlert) === -1
                  }
                  size="small"
                  onClick={() => {
                    dispatch(
                      updateAlertStatus({
                        status: "MARK_FALSE_POSITIVE",
                        ids: selectedRows,
                      })
                    );
                  }}
                  className="min-margin table-buttons"
                >
                  False Positive
                </ColorButtonForToolBar>
              </>
            )}
            {loc === "tenant-management-Tenants" && (
              <NewPolicyButtonForToolBar
                variant="contained"
                color="primary"
                size="small"
                disabled={permissions.indexOf(addTenant) === -1}
                onClick={() => {
                  dispatch({
                    type: "TRACK_TRAVERSAL",
                    payload: "Tenant Management - createTenant",
                  });
                  history.push(`/platform/tenantManagement/createTenant`);
                }}
                className="min-margin"
              >
                Create Tenant
              </NewPolicyButtonForToolBar>
            )}
            {loc === "users" && (
            <CanAllow needs={{permission: [userPermission(addTenantUser, addPlatformUser)]}}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  color: "#ffffff",
                  textTransform: "none",
                  fontSize: 16,
                  padding: "6px 12px",
                  lineHeight: 1.5,
                  backgroundColor: project ? "#8014BD" : "#2947fd",
                  borderRadius: "6px",
                }}
                // disabled={permissions.indexOf(addTenantUser) === -1}
                onClick={() => {
                  if (
                    window.localStorage.getItem("tenant") !== "druvstar" &&
                    window.localStorage.getItem("tenant") !== "datavision"
                  ) {
                    if (window.location.pathname === "/mdr/userManagement") {
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "User Management - Create Users",
                      });
                      history.push(`/mdr/userManagement/createUsers`);
                    } else {
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "User Management - Create Users",
                      });
                      history.push(`/tenant/userManagement/createUsers`);
                    }
                  } else {
                    dispatch({
                      type: "TRACK_TRAVERSAL",
                      payload: "User Management - Create Users",
                    });
                    history.push(`/platform/userManagement/createUsers`);
                  }
                }}
                className="min-margin table-buttons"
              >
                Create User
              </Button>
            </CanAllow>)}
            {loc === "roles" && (
              <NewPolicyButtonForToolBar
                variant="contained"
                color="primary"
                size="small"
                disabled={permissions.indexOf(addTenantUser) === -1}
                onClick={(event) => {
                  if (
                    window.localStorage.getItem("tenant") !== "druvstar" &&
                    window.localStorage.getItem("tenant") !== "datavision"
                  ) {
                    rolesClickHandler(event);
                  } else {
                    rolesClickHandler(event);
                  }
                }}
                className="min-margin"
              >
                Create Roles
              </NewPolicyButtonForToolBar>
            )}
            {loc === "tenant-management-Deleted" && (
              <CanAllow needs={{permission: [updateTenant]}}>
                <ColorButtonForToolBar
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled
                  className="min-margin"
                >
                  Restore
                </ColorButtonForToolBar>
              </CanAllow>
            )}
            {loc === "policy" && (
              <>
                <ColorButtonForToolBar
                  variant="contained"
                  disabled={
                    disableButton ||
                    permissions.indexOf(updateAlertPolicy) === -1
                  }
                  onClick={() =>
                    dispatch(
                      updateAlertPolicyStatus({
                        status: "ACTIVE",
                        ids: selectedRows,
                      })
                    )
                  }
                  color="primary"
                  size="small"
                  className="min-margin table-buttons"
                >
                  Enable
                </ColorButtonForToolBar>
                <ColorButtonForToolBar
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    disableButton ||
                    permissions.indexOf(updateAlertPolicy) === -1
                  }
                  onClick={() =>
                    dispatch(
                      updateAlertPolicyStatus({
                        status: "INACTIVE",
                        ids: selectedRows,
                      })
                    )
                  }
                  className="min-margin table-buttons"
                >
                  Disable
                </ColorButtonForToolBar>
              </>
            )}
            {!newAlertPolicyHide &&
              loc === "policy" &&
              permissions.indexOf(createAlertPolicy) > -1 && (
                <NewPolicyButtonForToolBar
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    dispatch({
                      type: "TRACK_TRAVERSAL",
                      payload: "User Management - Create Users",
                    });
                    history.push(`/tenant/alertPolicies/createPolicy`);
                  }}
                  startIcon={<AddCircleOutlineIcon />}
                  className="min-margin table-buttons"
                >
                  New Alert Policy
                </NewPolicyButtonForToolBar>
              )}
            {/* {loc === "drawer" && (
              <ColorButtonForToolBar
                variant="contained"
                color="primary"
                size="small"
                className="min-margin"
                disabled={disableButton}
              >
                Show All
              </ColorButtonForToolBar>
            )} */}
            {(loc === "create-policy" || loc === "show-policy-flow-groups") && (
              <CanAllow needs={{permission: [addDataFlowGroup]}}>
                <NewPolicyButtonForToolBar
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleFlowOpen(true)}
                  startIcon={<AddCircleOutlineIcon />}
                  className="min-margin"
                >
                  Add Flow Group to Policy
                </NewPolicyButtonForToolBar>
              </CanAllow>
            )}
            {loc === "addDataFlowGroup" && (
              <>
                {/* <ColorButtonForToolBar
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={disableButton}
                  //onClick={handleClick('bottom')}
                  startIcon={<CreateIcon />}
                  className="min-margin"
                >
                  Create New Flow Group
                </ColorButtonForToolBar> */}
                <NewPolicyButtonForToolBar
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={disableButton}
                  onClick={() => handleFlowOpen(false)} //save selected flow groups
                  className="min-margin"
                >
                  Done
                </NewPolicyButtonForToolBar>
              </>
            )}
            {loc === "database_Users" && (
              <>
                <NewPolicyButtonForToolBar
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={disableButton}
                  className="min-margin"
                  onClick={(event) => {
                    if (selectedItems.length > 0) {
                      dispatch(batchEditClick(event.currentTarget));
                    } else {
                      alert("Please select multiple rows");
                    }
                  }}
                >
                  Batch Edit
                </NewPolicyButtonForToolBar>
              </>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </>
  );
}
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default React.memo(EnhancedTableToolbar);
