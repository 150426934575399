import React, { useEffect, useState, Fragment } from "react";
//import Button from '@material-ui/core/button';
import { useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ColorButtonForTrigger } from "../common/buttons";
import { SmallButtonForTrigger } from "../common/buttons";
import { getTriggerComparisonTypes } from "../../redux/actions/getComparisonTypesAction";
import { getTriggerAggregationTypes } from "../../redux/actions/getAggregationTypesAction";
import { getTriggerTimeWindowTypes } from "../../redux/actions/getTimeWindowTypesAction";
import {
  viewTriggerCategory,
  viewTimeWindowType,
  viewAggregationType,
  viewComparisonType,
} from "../../redux/constants/constants";

export default function TriggerComponent(props) {
  const [comparisonTypes, setComparisonTypes] = useState([]);
  const [aggregationTypes, setAggregationTypes] = useState([]);
  const [timeWIndowTypes, setTimeWIndowTypes] = useState([]);
  const unsuccessfulCreateEdit = useSelector(
    (state) => state.policyCreated.unsuccessful
  );
  const permissions = useSelector((state) => state.applicationData.permissions);
  const {
    queryParam,
    handleAggregationTypeCode,
    handleCategoryCode,
    handleComparisonTypeCode,
    handleTimeWindowTypeCode,
    handleTriggerValue,
    aggregationTypeCode,
    categoryCode,
    comparisonTypeCode,
    timeWindowTypeCode,
    triggerValue,
  } = props;
  useEffect(() => {
    async function triggerComparisonData() {
      const response = await getTriggerComparisonTypes();
      if (response) {
        setComparisonTypes(response);
      }
    }
    if (queryParam.id) {
      triggerComparisonData();
    }
  }, [queryParam]);
  useEffect(() => {
    async function triggerTimeWindowData() {
      const response = await getTriggerTimeWindowTypes();
      if (response) {
        setTimeWIndowTypes(response);
      }
    }
    if (queryParam.id) {
      triggerTimeWindowData();
    }
  }, [queryParam]);
  useEffect(() => {
    async function triggerAggregationData() {
      const response = await getTriggerAggregationTypes();
      if (response) {
        setAggregationTypes(response);
      }
    }
    if (queryParam.id) {
      triggerAggregationData();
    }
  }, [queryParam]);
  return (
    <div className={unsuccessfulCreateEdit ? "trigger-container error" : "trigger-container"}>
      <p style={{ fontSize: "14px" }}>Alert Trigger Rule</p>

      {permissions.indexOf(viewTriggerCategory) > -1 && (
        <Fragment>
          <p style={{ fontSize: "14px" }}>I want to monitor</p>
          <ToggleButtonGroup
            exclusive
            style={{ marginLeft: "3px" }}
            value={categoryCode}
            onChange={(event) => handleCategoryCode(event)}
          >
            <ColorButtonForTrigger
              variant="contained"
              color="primary"
              size="small"
              value="TRACES"
              key="category_traces"
            >
              Data Access
            </ColorButtonForTrigger>
          </ToggleButtonGroup>

          <Divider style={{ marginTop: "20px", backgroundColor: "#979797" }} />
        </Fragment>
      )}

      <div>
        {permissions.indexOf(viewComparisonType) > -1 && (
          <Fragment>
            <p style={{ fontSize: "14px", marginTop: "10px" }}>When they:</p>
            <ToggleButtonGroup
              exclusive
              value={comparisonTypeCode}
              onChange={(event) => handleComparisonTypeCode(event)}
            >
              <ColorButtonForTrigger
                variant="contained"
                color="primary"
                size="small"
                value="INCREASES"
                className="min-margin"
                key="comparison_increase"
              >
                Increase
              </ColorButtonForTrigger>
              <ColorButtonForTrigger
                variant="contained"
                color="primary"
                size="small"
                value="DECREASES"
                className="min-margin"
                key="comparison_decrease"
              >
                Decrease
              </ColorButtonForTrigger>
            </ToggleButtonGroup>
          </Fragment>
        )}

        {permissions.indexOf(viewComparisonType) > -1 && (
          <Fragment>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <input
                value={triggerValue}
                type="number"
                min="0"
                key="trigger_value"
                onChange={(event) => handleTriggerValue(event)}
                style={{
                  width: "100%",
                  color: "#292929",
                  height: "30px",
                  marginLeft: "7px",
                  border: "none",
                  boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
                className="min-margin"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <ToggleButtonGroup
                style={{ cursor: "pointer", lineHeight: "0.75 !important" }}
                exclusive
                value={aggregationTypeCode}
                onChange={(event) => handleAggregationTypeCode(event)}
              >
                <SmallButtonForTrigger
                  aria-label="back"
                  value="PERCENTAGE"
                  size="medium"
                  key="agg_type_percentage"
                  style={{ width: "50px" }}
                >
                  %
                </SmallButtonForTrigger>
                &nbsp;&nbsp;&nbsp;
                <SmallButtonForTrigger
                  aria-label="back"
                  value="COUNT"
                  size="medium"
                  key="agg_type_min"
                  style={{ width: "50px" }}
                >
                  #
                </SmallButtonForTrigger>
              </ToggleButtonGroup>
            </div>
            <Divider
              style={{ marginTop: "20px", backgroundColor: "#979797" }}
            />
          </Fragment>
        )}
      </div>
      <div style={{ marginTop: "10px" }}>
        {permissions.indexOf(viewTimeWindowType) > -1 && (
          <Fragment>
            <p>Over the Average:</p>
            <ToggleButtonGroup
              exclusive
              value={timeWindowTypeCode}
              onChange={(event) => handleTimeWindowTypeCode(event)}
            >
              <ColorButtonForTrigger
                variant="contained"
                color="primary"
                size="small"
                value="DAILY"
                key="window_day"
                className="min-margin"
              >
                Day
              </ColorButtonForTrigger>
              &nbsp;&nbsp;&nbsp;
              <ColorButtonForTrigger
                variant="contained"
                color="primary"
                size="small"
                value="WEEKLY"
                key="window_week"
                className="min-margin"
              >
                Week
              </ColorButtonForTrigger>
            </ToggleButtonGroup>
            <div style={{ marginTop: "10px" }}>
              <ToggleButtonGroup
                exclusive
                value={timeWindowTypeCode}
                onChange={handleTimeWindowTypeCode}
              >
                <ColorButtonForTrigger
                  variant="contained"
                  color="primary"
                  size="small"
                  value="HOURLY"
                  key="window_hr"
                  className="min-margin"
                >
                  Hour
                </ColorButtonForTrigger>
                &nbsp;&nbsp;&nbsp;
                <ColorButtonForTrigger
                  variant="contained"
                  color="primary"
                  size="small"
                  value="MINUTE"
                  key="window_min"
                  className="min-margin"
                >
                  Minute
                </ColorButtonForTrigger>
              </ToggleButtonGroup>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}
