import { UPDATE_POLICY_STATUS_URL,UPDATE_POLICY_STATUS_CHANGE_REQUEST } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';
import { call, put, takeEvery } from "redux-saga/effects";

function* updateAlertPolicyStatus(action) {
  console.log("policy status saga")
  let options;
  try {
    options = {
      method: "PUT",
      url: `${UPDATE_POLICY_STATUS_URL}/status/${action.payload.status}`,
      headers: getAxiosHeaders(true),
      data: action.payload.ids
    };
    try {
      const res = yield getService(options);
      if (res) {
        console.log("changed")
        yield put({ type: "UPDATE_POLICY_STATUS_CHANGE_SUCCEEDED" });
      } else {
        yield put({
          type: "UPDATE_POLICY_STATUS_CHANGE_FAILED",
          payload: "Update status unsuccessful",
        });
      }
    } catch (e) {
      console.log(e);
      // myStorage.setItem("access_token", "error");
      yield put({
        type: "UPDATE_POLICY_STATUS_CHANGE_FAILED",
        payload: "Error Fetching Data from API",
      });
    }
  } catch (e) {
    yield put({ type: "UPDATE_POLICY_FAILED" });
  }
  // if (response.status === 200) {
  //   yield put({ type: "UPDATE_POLICY_SUCCEED", data: true });
  // }
  // // if(response) {
  // //     return response.data
  // // }
};
function* updateAlertPolicyStatusSaga() {
  yield takeEvery(UPDATE_POLICY_STATUS_CHANGE_REQUEST, updateAlertPolicyStatus);
}

export default updateAlertPolicyStatusSaga;
