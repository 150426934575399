import { CREATE_ALERT_POLICY_REQUEST } from "../constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";

// export async function createAlertPolicy(data) {
//     const options = {
//         method: "POST",
//         url: `${CREATE_ALERT_POLICY_URL}`,
//         headers: getAxiosHeaders(true),
//         data: data
//     };

//     const response = await getService(options);
//     if(response) {
//         return response.data
//     }
// };

export const createAlertPolicy = (payload) => {
  console.log("action");
  return {
    type: CREATE_ALERT_POLICY_REQUEST,
    payload: payload,
  };
};
