import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DateRangePicker } from 'react-date-range';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  Popover,
  makeStyles,
  Chip
} from '@material-ui/core';
import { startOfDay, endOfDay } from "date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { isEmpty } from "lodash";
import {
  GET_ALL_USER_EMAILS,
  SET_REPORT_DATA,
  CLEAR_SNACK_BAR_MESSAGE,
  viewDataSource,
  generateReport,
  fetchReport
} from "../../../redux/constants/constants";
import { createStaticRange, dateRangePickerStaticList, errorSnackbar } from "../../common/utilities";
import Input from "../../shared/Input";
import Toggler from "../../shared/Toggler";
import {DataMapRoute} from "../../common/DatamapImage";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import  { formattedDate } from "./utilities";
import { UTCTooltip } from "./UTCTooltip";


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100,
  },
  popover: {
    padding: theme.spacing(2),
  },
  autocompleteRoot: {
    width: 370,
    '& ::-webkit-scrollbar': {
      width: '8px', 
    },
    '& ::-webkit-scrollbar-track': {
      background: '#f1f1f1', 
      borderRadius: '6px', 
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#D0D0D0', 
      borderRadius: '6px', 
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#C0C0C0', 
    },}
}));

export default function GenerateReport() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const checkBoxValues =[
    {
      displayName: "Data Owner View",
      ID: "dataOwnerView",
      value: true,
    },
    {
      displayName: "Data Sources",
      ID: "dataSource",
      value: true,
    },
    {
      displayName: "Users With Alerts",
      ID: "usersWithAlerts",
      value: true,
    },
    {
      displayName: "Data Source Users",
      ID: "dataSourceUsers",
      value: true,
    },
    {
      displayName: "Data Access Alerts",
      ID: "dataAccessAlerts",
      value: true,
    },
  ];
  const [reportName, setReportName] = useState("");
  const [email, setEmail] = useState([]);
  const currentDate = new Date();
  const [selectedFromDate, setSelectedFromDate] = useState(currentDate);
  const [selectedToDate, setSelectedToDate] = useState(endOfDay(currentDate));
  const [fromAnchorEl, setFromAnchorEl] = useState(null);
  const [toAnchorEl, setToAnchorEl] = useState(null);
  const [rows, setRows] = useState(100);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [sourceSelection, setSourceSelection] = useState(checkBoxValues);
  const [disabled, setDisabled] = useState(false);
  const [maxRowsError, setMaxRowsError] = useState("");
  // State for toggler buttons
  // Add permission param for each toggler as we are rendering based on permission
  const [togglers, setTogglers] = useState([
    { name: "Data Owner View", state: "inactive", permission: viewDataSource },
    { name: "System Summary", state: "inactive", permission: viewDataSource },
    { name: "Generate Report", state: "active", permission: generateReport },
    { name: "Report Repository", state: "inactive", permission: fetchReport },
  ]);

  const userEmails = useSelector(
    (state) => state.GenerateReportReducer.userEmails
  );
  const loader = useSelector((state) => state.GenerateReportReducer.loader);

  const snackBarMessage = useSelector(
    (state) => state.GenerateReportReducer.snackBarMessage
  );

  useEffect(() => {
    dispatch({ type: GET_ALL_USER_EMAILS });
    return () => {
      setOpenSnackBar(false);
      dispatch({ type: CLEAR_SNACK_BAR_MESSAGE });
    };
  }, []);

  // useEffect to manage the disabled state of the "Generate Report" button
  useEffect(() => {
    // Check if any of the required fields are empty or all section checkboxes are unchecked
    const isAnySectionSelected = sourceSelection.some((item) => item.value);
    if (
      isEmpty(reportName.trim()) ||
      isEmpty(rows.toString()) ||
      !isAnySectionSelected
    ) {
      // If any condition is met, disable the "Generate Report" button
      setDisabled(true);
    } else {
      // If all required fields are filled and at least one section checkbox is checked, enable the button
      setDisabled(false);
    }
  }, [sourceSelection, reportName, rows]);
  

  useEffect(() => {
    if (snackBarMessage && snackBarMessage.status && snackBarMessage.message) {
      setOpenSnackBar(true);
    }
  }, [snackBarMessage]);

  // Implement an onChange handler for reportName
  const handleReportNameChange = (value) => {
    setReportName(value);
  };

  // Implement an onChange handler for email
  const handleEmailChange = (event, value) => {
    setEmail(value);
  };

  const staticRanges = [...createStaticRange(dateRangePickerStaticList())];

  const handleValidation = () => {
    const errors = {};
    if (rows === 0) {
      errors.rows = "Please select No Of rows from 1 - 1000";
    }
    setMaxRowsError(errors.rows || "");
    return errors;
  };

  const clearFormFields = () => {
    setReportName("");
    setEmail([]);
    setRows(100);
    setSourceSelection(checkBoxValues);
    setSelectedFromDate(currentDate);
    setSelectedToDate(endOfDay(currentDate));
  };

  const handleSave = () => {
    const errors = handleValidation();

    // If there are no validation errors, proceed with saving the report
    if (!Object.keys(errors).length) {
      const newReport = {
        reportName,
        startDate: startOfDay(selectedFromDate).getTime().toString(),
        endDate: endOfDay(selectedToDate).getTime().toString(),
        numberOfRows: rows,
        sections: sourceSelection.filter((item) => item.value).map((item) => ({ name: item.displayName })),
        emailRecipients: email.map((name) => ({ name })),
        timeOffSet: `${new Date().getTimezoneOffset()}`,
      };
      dispatch({ type: SET_REPORT_DATA, payload: newReport });
    }
  };
  
  useEffect(() => {
    clearFormFields();
  }, [snackBarMessage]);

  const openFromDatePopover = Boolean(fromAnchorEl);
  const openToDatePopover = Boolean(toAnchorEl);

  const handleCheckChange = (event) => {
    const updatedSelection = sourceSelection.map((item) =>
      item.ID === event.target.name
        ? { ...item, value: event.target.checked }
        : item
    );

    setSourceSelection(updatedSelection);
  };
  

  const renderReportSectionCheckbox = () => {
    
    return (
      <FormControl component="fieldset">
        <FormGroup aria-label="position" className="report_check_box">
          {sourceSelection.map((item) => {
            return (
              <FormControlLabel
                value={item.displayName}
                control={
                  <Checkbox
                    color="primary"
                    checked={item.value}
                    name={item.ID}
                    onChange={handleCheckChange}
                  />
                }
                label={item.displayName}
                labelPlacement="end"
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  };
  const renderPlaceholder = () => {
    if (email && !email.length) {
      return "Enter email id or select from DDV users";
    } else {
      return null;
    }
  };
  const renderUserEmails = () => {
    if (!isEmpty(userEmails)) {
      return (
        <div className="emailContainer">
          <Autocomplete
            options={userEmails || []}
            multiple={true}
            value={email}
            onChange={(event, value) => handleEmailChange(event, value)}
            renderTags={(value, getTagProps) => {
              return (
                <div className="chipContainer">
                  {value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      label={option}
                    />
                  ))}
                </div>
              );
            }}
            renderInput={(params) => (
              <TextField
                placeholder={renderPlaceholder()}
                {...params}
                classes={{ root: classes.autocompleteRoot }}
                size="small"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: classes.inputRoot,
                  },
                }}
              />
            )}
          />
        </div>
      );
    }
  };

  const renderReportFields = () => {
    return (
      <div className="second_container">
        <div className="generate_report_heading">
          <span>Include Sections</span>
          <span className="shareReportErrorMessage">*</span>
        </div>
        <div>{renderReportSectionCheckbox()}</div>
        <div className="generate_report_heading notify">Notify Via Email</div>
        <div className="UserEditing_input" key="role">
          {renderUserEmails()}
        </div>
        <div className="notification_info email_info">
          <ul className="notificationContainer">
            <li className="notification_message">
              A notification via email will be sent once the report is ready
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderRowValue = (value) => {
    // Convert the input value to an integer
    const numericValue = parseInt(value);

    // Clear any existing error message
    setMaxRowsError("");

    // Check if the converted numeric value is not NaN (i.e., it's a valid number)
    if (!isNaN(numericValue)) {
      // Set the "rows" state to a value within the range of 0 to 1000
      setRows(Math.min(Math.max(numericValue, 0), 1000));
    } else {
      // If the entered value is a invalid number, clear the "rows" state
      setRows("");
    }
  };
  

  const renderReportRows = () => {
    return (
      <>
        <div className="generate_report_content rows_content">
          <div className="generate_report_heading">
            <span>Maximum Number of Rows</span>
            <span className="shareReportErrorMessage">*</span>
          </div>
          <div>
            <TextField
              required
              value={rows}
              onChange={(event) => renderRowValue(event.target.value)}
              id="outlined-required"
              variant="outlined"
              fullWidth={true}
              placeholder="Number Of Rows"
              type={"number"}
              autoComplete="off"
              InputProps={{ inputProps: { min: 0 } }}
            />
            {maxRowsError ? (
              <div className="reportErrorMessage">{maxRowsError}</div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  //Handle From date click
  const handleFromDateFieldClick = (event) => {
    setFromAnchorEl(event.currentTarget);
  };

  //Handle To date click
  const handleToDateFieldClick = (event) => {
    setToAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setFromAnchorEl(null);
    setToAnchorEl(null);
  };

  const renderDateInputWithPopover = (
    label,
    value,
    handleClick,
    popoverId,
    openPopover,
    anchorEl,
    handleClose
  ) => {
    return (
      <div className="generate_report_dates">
        <div className="generate_report_date_header">{label}</div>
        <div className="generate_report_date_selector">
          <TextField
            value={formattedDate(new Date(value))}
            className={classes.textField}
            InputProps={{
              readOnly: true,
            }}
            onClick={handleClick}
          />
          <ArrowDropDownIcon
            onClick={handleClick}
            className={`arrow_drop_down ${openPopover ? "icon_clicked" : ""}`}
          />
          <Popover
            id={popoverId}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className={classes.popover}>
              <DateRangePicker
                ranges={[
                  {
                    startDate: new Date(selectedFromDate),
                    endDate: new Date(selectedToDate),
                    key: "selection",
                  },
                ]}
                onChange={(ranges) => {
                  setSelectedFromDate(ranges.selection.startDate);
                  setSelectedToDate(endOfDay(ranges.selection.endDate));
                }}
                inputRanges={[]}
                staticRanges={staticRanges}
                maxDate={startOfDay(new Date())} //disabling future dates for generating reports (For Now)
              />
            </div>
          </Popover>
        </div>
      </div>
    );
  };

  const renderDate = () => {
    // Determine the Popover IDs based on the open popover state
    const fromPopoverId = openFromDatePopover ? "from-date-popover" : undefined;
    const toPopoverId = openToDatePopover ? "to-date-popover" : undefined;
    return (
      <div className="generate_report_date_container">
        <div className="generate_report_heading header-icon">
          Report Date Range {UTCTooltip()}
        </div>
        <div className="generate_report_date_range">
          {renderDateInputWithPopover(
            "From",
            selectedFromDate,
            handleFromDateFieldClick,
            fromPopoverId,
            openFromDatePopover,
            fromAnchorEl,
            handlePopoverClose
          )}
          {renderDateInputWithPopover(
            "To",
            selectedToDate,
            handleToDateFieldClick,
            toPopoverId,
            openToDatePopover,
            toAnchorEl,
            handlePopoverClose
          )}
        </div>
      </div>
    );
  };

  const renderReportName = () => {
    return (
      <>
        <div className="generate_report_content">
          <div className="generate_report_heading">
            <span>Report Name</span>
            <span className="shareReportErrorMessage">*</span>
          </div>
          <div className="generate_report_input">
            <Input
              type="text"
              value={reportName}
              classname="number_input"
              placeholder="Report Name"
              eventHandler={(value) => handleReportNameChange(value)}
            ></Input>
          </div>
        </div>
      </>
    );
  };

  // Object mapping toggle button names to their corresponding route paths.
  const toggleRoutes = {
    "Data Owner View": "/tenant/dataOwner",
    "System Summary": "/tenant/systemSummary",
    "Generate Report": "/tenant/generateReport",
    "Report Repository": "/tenant/reportRepository",
  };

  // Function to handle toggler button clicks
  const handleToggle = (name) => {
    history.push(toggleRoutes[name]);
  };

  const handlePopUpClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    dispatch({ type: CLEAR_SNACK_BAR_MESSAGE });
  };

  const generateReportButton = () => {
    return (
      <div className="generate_report_footer_container">
        <div className="generate_report_footer">
          <span className="notification_info">
            <span className="noteHeader">Note :</span> You can view the
            generated report in the{" "}
            <a
              className="report_link"
              onClick={() => history.push("/tenant/reportRepository")}
            >
              <u>Report Repository Tab</u>
            </a>
            .
          </span>
        </div>
        <div className="Generate-button-container">
          <Button
            type="submit"
            disabled={disabled}
            variant="contained"
            onClick={() => handleSave()}
            className="generate_report_button"
          >
            Generate Report
          </Button>
        </div>
      </div>
    );
  };

  // Function to render all report components
  const generateReportsComponent = () => {
    return (
      <div className="report_field_main_container">
        <div>
          <div className="report_date_range">{renderReportName()}</div>
          <div className="report_date_range">{renderDate()}</div>
          <div>{renderReportRows()}</div>
        </div>
        <div>
          <div>{renderReportFields()}</div>
        </div>
      </div>
    );
  };

  // Function to render the header
  const renderHeader = () => {
    return (
      <div className="inspect-title-header">
        <div className="dataOwnerSummaryHeader">Generate Report</div>
      </div>
    );
  };

  const renderGenerateReportPage = () => {
    return (
      <div className="main">
        <div className="description">
          <div className="generateReportHeadingContainer">
            <h2>Activity Report</h2>
            <p>Reports of important events</p>
          </div>
          <DataMapRoute />
        </div>
        <div className="togglerandSearch_Conatiner">
          <Toggler
            togglers={togglers}
            setTogglers={setTogglers}
            loc="summary"
            history={history}
            handleToggle={handleToggle}
            permissionBased
          ></Toggler>
        </div>
        <div className="fs-file-list-container">
          {renderHeader()}
          {generateReportsComponent()}
          {generateReportButton()}
        </div>
      </div>
    );
  };

  const renderMessage = () => {
    return (
      <>
        {snackBarMessage &&
          snackBarMessage.status &&
          snackBarMessage.message && (
            <>
              {errorSnackbar(
                openSnackBar,
                handlePopUpClose,
                snackBarMessage.message,
                snackBarMessage.status
              )}
            </>
          )}
      </>
    );
  };

  return (
    <>
      {loader && (
        <div className="loader-overlay">
          <img src={Page_Loader} alt="loading" className="loader-data-source" />
        </div>
      )}
      {renderMessage()}
      {renderGenerateReportPage()}
    </>
  );
}

