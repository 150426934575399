import React from "react";
import { Chart } from "react-google-charts";
import _ from "lodash";

import ChartWrapper from "../Chart";
import { numberFormatter, dateFormatter, renderNoData } from "../utils";
import { viewAlert } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../utilities";

let colors = {
  HIGH: "#FF2251",
  LOW: "#91DFFF",
  MEDIUM: "#FFC344",
};
let options = { colors: ["#FF2251", "#FFC344", "#91DFFF"] };
let tableData = [];
let severity =["HIGH" , "MEDIUM" , "LOW"]
const returnRedirectTo = (name) => {
  let data = {
    "Alerts over Time":{
      pathname: "/tenant/alerts",
      permission: [viewAlert],
    },
    "Health/agent alerts over time":{
      pathname: "/tenant/alerts",
      permission: [viewAlert],
    }
  }
  return pathWithPermission(data[name])
};
let renderMultiBarChart = (query, name, history) => {
  return (results) => {
    let data = results.loadResponse.results[0].data;
    tableData = results.loadResponse.results[0].data; 
    if (!data || data.length === 0) {
      return renderNoData("10%");
    }
    let projectedData;
    let dimensions = _.uniq(data.map((d) => d[query.dimensions[0]]));
    let timeFinder = query.timeDimensions[0].granularity
      ? `${query.timeDimensions[0].dimension}.${query.timeDimensions[0].granularity}`
      : query.timeDimensions[0].dimension;
    let dates = _.uniq(data.map((d) => d[timeFinder]));
    projectedData = [["Time", "HIGH", "MEDIUM", "LOW"]];
    dates.forEach((d) => {
      let formattedDate = dateFormatter(d);
      let barChartData = { date: formattedDate };
      let mapped = data
        .filter((entry) => entry[timeFinder] === d)
        .map((e) => {
          return (barChartData[e[query.dimensions[0]]] = e[query.measures[0]]);
        });
      let itemdata = [
        barChartData.date,
        parseInt(barChartData["HIGH"]) || 0,
        parseInt(barChartData["MEDIUM"]) || 0,
        parseInt(barChartData["LOW"]) || 0,
      ];

      projectedData.push(itemdata);
    });
    return (
      <div onClick={(event) => event.stopPropagation()}>
      <Chart
        // width={'100%'}
        // height={'300px'}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={projectedData}
        options={options}
        // For tests
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
              const chart = chartWrapper.getChart();
              if (history) {
                var handler = function (e) {
                  var selectedItem = chart.getSelection()[0];
                  if (selectedItem.row) {
                    if (history) {
                      history.push({
                        ...returnRedirectTo(name),
                        state: {
                          time:projectedData[selectedItem.row + 1][0],
                          severity: severity[selectedItem.column - 1],
                        },
                      });
                    }
                  }else{
                    if (history) {
                      history.push({
                        ...returnRedirectTo(name),
                        state: {
                          severity: severity[selectedItem.column - 1],
                        },
                      });
                    }
                  }
                };
                google.visualization.events.addListener(
                  chartWrapper.getChart(),
                  "select",
                  handler
                );
              }
            },
          },
        ]}
        rootProps={{ "data-testid": "2" }}
      />
      </div>
    );
  };
};

export let healthAgentAlertsOverTime = (cubejsApi, name) => {
  let query = {
    measures: ["AgentHealth.count"],
    timeDimensions: [{ dimension: "AgentHealth.date", granularity: "day" }],
    dimensions: ["AgentHealth.severityCode"],
  };

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={query}
      render={renderMultiBarChart(query, name)}
      redirectTo={returnRedirectTo(name)}
    />
  );
};

export let alertsOverTimeBarChart = (cubejsApi, name, filters, history) => {
  
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let query = {
    measures: ["Alerts.count"],
    timeDimensions: [{ dimension: "Alerts.date", granularity: "day" }],
    dimensions: ["Alerts.severity"],
    timezone: tz,
  };

  if (filters) {
    query.filters = filters;
  }
  // let aot = [
  //     {
  //         "Alerts.severity": "HIGH",
  //         "Alerts.date.day": "2021-05-11T00:00:00.000",
  //         "Alerts.date": "2021-05-11T00:00:00.000",
  //         "Alerts.count": "519"
  //     },
  //     {
  //         "Alerts.severity": "LOW",
  //         "Alerts.date.day": "2021-05-11T00:00:00.000",
  //         "Alerts.date": "2021-05-11T00:00:00.000",
  //         "Alerts.count": "44"
  //     },
  //     {
  //         "Alerts.severity": "HIGH",
  //         "Alerts.date.day": "2021-05-12T00:00:00.000",
  //         "Alerts.date": "2021-05-12T00:00:00.000",
  //         "Alerts.count": "19"
  //     },
  //     {
  //         "Alerts.severity": "MEDIUM",
  //         "Alerts.date.day": "2021-05-12T00:00:00.000",
  //         "Alerts.date": "2021-05-12T00:00:00.000",
  //         "Alerts.count": "25"
  //     },
  //     {
  //         "Alerts.severity": "LOW",
  //         "Alerts.date.day": "2021-05-12T00:00:00.000",
  //         "Alerts.date": "2021-05-12T00:00:00.000",
  //         "Alerts.count": "35"
  //     }
  // ];

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={query}
      render={renderMultiBarChart(query, name, history)}
      redirectTo={history ? returnRedirectTo(name) : ""}

    />
  );
};
