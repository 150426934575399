import { GET_ALERT_POLICY_SEVERITIES, GET_ALERT_POLICY_SEVERITIES_URL} from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function getAlertPolicySeverities(id) {
    const options = {
        method: "GET",
        url: `${GET_ALERT_POLICY_SEVERITIES_URL}`,
        headers: getAxiosHeaders(true)
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};
