import {
  ADD_MITIGATION,
  CLEAR_MITIGATION,
  DELETE_MITIGATION,
  RISK_PILOT_LEVELS,
  RISK_PILOT_MITIGATION_STATUS,
  UPDATE_FROM_API,
  UPDATE_MITIGATION,
} from "../riskPilotConstant";
import { renderMitigationOwner } from "../riskPilotUtilities";

const initialState = {
  mitigations: {},
};

export const mitigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MITIGATION:
      const newMitigationId = action.payload.id;
      return {
        ...state,
        mitigations: {
          ...state.mitigations,
          [newMitigationId]: action.payload,
        },
      };
    case UPDATE_MITIGATION:
      const {
        mitigationId,
        updates,
      } = action.payload;
      return {
        ...state,
        mitigations: {
          ...state.mitigations,
          [mitigationId]: {
            ...state.mitigations[mitigationId],
            ...updates,
          },
        },
      };

    case DELETE_MITIGATION:
      const {
        mitigationId: deleteMitigationId,
      } = action.payload;
      const { [deleteMitigationId]: removedMitigation, ...remainingMitigations } =
        state.mitigations;
      return {
        ...state,
        mitigations: remainingMitigations,
      };

    case UPDATE_FROM_API:
      const { apiData } = action.payload;

      return {
        ...state,
        mitigations: apiData.mitigationData,
      };

    case CLEAR_MITIGATION:
      return {
        ...state,
        mitigations: {},
      };
    default:
      return state;
  }
};
