import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCubejsApi } from "../common/Dashboard/cubejs-util";
import { chartTypes } from "../common/Dashboard/chartTypeToComponent";
import { getTime, isWorkHourPolicy, isInactivityPolicy } from "../common/utilities";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Popover from "@material-ui/core/Popover";
//import InactiveIcon from '../../assets/vizr_images/Group 3.png';
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import AlertCharts from "../shared/alertsCharts";
import LevelComponent from "../shared/levelComponent";
import TriggerComponent from "../shared/triggerComponent";
import AutoTriggerComponent from "../shared/autoTriggerComponent";
import MonitorsComponent from "../shared/monitors";
import EnhancedTable from "../shared/alertsTable";
import TextField from "@material-ui/core/TextField";
import ProfileIcon from "../../assets/Images/inspect/ic_profile.svg";
import MuteIcon from "../../assets/Images/inspect/ic_sound_on.svg";
import UnmuteIcon from "../../assets/vizr_images/Combined Shape.svg";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { searchUser } from "../../redux/actions/searchUserAction";
import { createAlertPolicy } from "../../redux/actions/createAlertPolicyAction";
import { getPolicyDetails } from "../../redux/actions/getPolicyDetailsAction";
import { updateAlertPolicy } from "../../redux/actions/updateAlertPolicyAction";
import Checkbox from "@material-ui/core/Checkbox";
import { getAlertPolicyAlerts } from "../../redux/actions/getPolicyAlertsAction";
import { getAlertPolicyComments } from "../../redux/actions/getPolicyCommentsAction";
import { getPresentUserDetails } from "../../redux/actions/getPresentUserInformationAction";
import { getAlertPolicyDataFlowGroups } from "../../redux/actions/getPolicyDataFlowGroupsAction";
import { getAllDataFlowGroups } from "../../redux/actions/getAllDataFlowGroupsAction";
import { getAlertPolicySubTypes, getAlertPolicyTypes } from "../../redux/actions/getAlertPolicyTypesAction";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Avatar } from "@material-ui/core";
import Human1 from "../../assets/vizr_images/Oval Copy.png";
import CreateIcon from "@material-ui/icons/Create";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HelpIcon from "../../assets/vizr_images/ic_help.svg";
import { ColoredActionButton } from "../common/buttons";
import { EditButtonForPolicyCreateEdit } from "../common/buttons";
import { NewPolicyButtonForPolicyCreateEdit } from "../common/buttons";
import { CreatePolicyButtonForPolicyCreateEdit } from "../common/buttons";
import { CreateFlowGroupButtonForPolicyCreateEdit } from "../common/buttons";
import { IconButtonForPolicyCreateEdit } from "../common/buttons";
import { DeleteButtonForToolBar } from "../common/buttons";
import { saveAlertPolicyComment } from "../../redux/actions/saveCommentForPolicyAction";
import DoneIcon from "@material-ui/icons/Done";
import { alertHeadCells } from "../common/headCells";
import { dataFLowGroupHeadCellForCreateEdit } from "../common/headCells";
import { dataFLowGroupHeadCell } from "../common/headCells";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import {
  viewAlertPolicyComment,
  postAlertPolicyComment,
  viewAlertPolicyStatus,
  viewAlertPolicySeverity,
  savePolicyErrorMessage,
  ROLES,
  UPDATE_ALERT_POLICY,
  addDataFlowGroup,
  NON_WORK_HOUR_POLICY,
} from "../../redux/constants/constants";
import { AlertPolicyById } from "./AlertPolicyByIdAlerts";
import Toggler from "../shared/Toggler";
import DbusersComponent from "../shared/dbusers.js";
import DbrolesComponent from "../shared/dbroles.js";
import "./alertPolicyCreateEdit.scss";
import { isEmpty } from "lodash";
import CanAllow from "../shared/CanAllow";
import { renderPolicyType, thresholdValue } from "./alertPolicies/alertPolicyUtilities.js";
import AlertEmail from "./AlertEmailNotifications/alertEmail.js";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: "#2947fd",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
export default function AlertPolicyCreateEdit(props) {
  const permissions = useSelector((state) => state.applicationData.permissions);
  const unsuccessfulCreateEdit = useSelector(
    (state) => state.policyCreated.unsuccessful
  );
  const [created, setCreated] = useState(false);
  const tenant = window.localStorage.getItem("tenant");
  const userRole = window.localStorage.getItem("user_role");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackPack, setSnackPack] = React.useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [policyId, setPolicyId] = useState("");
  const [policy, setPolicy] = useState({});
  const [policyName, setPolicyName] = useState("");
  const [policyDesc, setPolicyDesc] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [categoryCode, setCategoryCode] = useState("");
  const [comparisonTypeCode, setComparisonTypeCode] = useState("");
  const [triggerValue, setTriggerValue] = useState(50);
  const [aggregationTypeCode, setAggregationTypeCode] = useState("");
  const [timeWindowTypeCode, setTimeWindowTypeCode] = useState("");
  const [severity, setSeverity] = useState("LOW");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]); //monitors
  const [selectedDbUsers, setSelectedDbUsers] = useState([]);
  const [selectedDbRoles, setSelectedDbRoles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [createPolicyData, setCreatePolicyData] = useState({});
  const [updatePolicyData, setUpdatePolicyData] = useState({});
  const [autoResponse, setAutoResponse] = useState(false);
  const [toleranceLevelCode, setToleranceLevelCode] = useState("");
  const [editable, setEditable] = useState(false);
  const [policyStatus, setPolicyStatus] = useState("inactive");
  const [triggerId, setTriggerId] = useState("");
  const [triggerConditionId, setTriggerConditionId] = useState("");
  const [policyAlerts, setPolicyAlerts] = useState([]);
  const [alertPolicyComments, setAlertPolicyComments] = useState([]);
  const [userApiCall, setUserApiCall] = useState(false);
  const [dataFlowGroupDialogOpen, setDataFlowGroupDialogOpen] = useState(false);
  const [selectedDataFlowGroups, setSelectedDataFlowGroups] = useState([]);
  const [allDataFlowGroups, setAllDataFlowGroups] = useState([]);
  const [automatedMonitoring, setAutomatedMonitoring] = useState(true);
  //const [isDirectDBAccess, setIsDirectDBAccess] = useState(false);
  const [alertPolicyTypes, setAlertPolicyTypes] = useState([]);
  const [policyTypesDisplayValues, setPolicyTypesDisplayValues] = useState([]);
  const [policyType, setPolicyType] = useState({});
  const [policySubTypesMaster, setPolicySubTypesMaster] = useState({});
  const [policySubTypes, setPolicySubTypes] = useState({});
  const [policySubTypeValue, setPolicySubTypeValue] = useState({});
  const [policyTypeDisplay, setPolicyTypeDisplay] = useState("");
  const [autoConfidenceInterval, setAutoConfidenceInterval] = useState("");
  const [automatedMonitoringThresholdId, setAutomatedMonitoringThresholdId] =
    useState("");
  const [regenerate, setRegenerate] = useState(false);
  const [autoTracesValue, setAutoTracesValue] = useState("");
  const [newComment, setNewComment] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [tempArr, setTempArr] = useState([]);
  const [systemPolicy, setSystemPolicy] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParam = useParams();
  let cubejsApi = getCubejsApi();
  const [filter, setFilter] = useState(null);
  const classes = useStyles();
  const [confirmRegenerateAlerts, setConfirmRegenerateAlerts] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loader, setLoader] = useState(false);
  const [togglers, setTogglers] = useState([
    { name: "Monitors", state: "active" },
    { name: "Users", state: "inactive" },
    { name: "Roles", state: "inactive" },
  ]);
  const [emailNotification, setEmailNotification] = useState(false);

  useEffect(() => {
    if (queryParam.id) {
      setFilter([
        {
          member: "Alerts.alert_policy_id",
          operator: "equals",
          values: [`${queryParam.id}`],
        },
      ]);
    }
  }, [queryParam]);
  const handleSelectDataFlowGroup = (event, flowGroup, action) => {
    const selectedIndex = selectedDataFlowGroups.findIndex(
      (each) => each.id === flowGroup.id
    );
    let newSelected = [];
    if (action === "add") {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedDataFlowGroups, flowGroup);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedDataFlowGroups.slice(1));
      } else if (selectedIndex === selectedDataFlowGroups.length - 1) {
        newSelected = newSelected.concat(selectedDataFlowGroups.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedDataFlowGroups.slice(0, selectedIndex),
          selectedDataFlowGroups.slice(selectedIndex + 1)
        );
      }
      setSelectedDataFlowGroups(newSelected);
    } else if (action === "remove") {
      if (selectedIndex > -1) {
        newSelected = selectedDataFlowGroups.filter(
          (each) => each.id !== flowGroup.id
        );
      }
      setSelectedDataFlowGroups(newSelected);
    }
  };

  async function policyTypes() {
    const response = await getAlertPolicyTypes();
    if (response) {
      let temp = [];
      // Map each item in the response array to a new object
      const formattedAlertPolicyTypes = response.map(each => ({
        ...each,
        // Modify the 'value' property if it equals "Direct Database Access"
        value: renderPolicyType(each.value)
      }));
      // Extract values from modified response formattedAlertPolicyTypes
      formattedAlertPolicyTypes.forEach((each) => {
        temp.push(each.value);
      });
      setPolicyTypesDisplayValues(temp);
      setAlertPolicyTypes(formattedAlertPolicyTypes);
      if (queryParam.id === "createPolicy" || queryParam.id === undefined){
        // in edit phase we do not want to initiate with default first item instead show what that alert type belong to
        // in create phase we want to initialize it to first dropdown value
        setPolicyType(formattedAlertPolicyTypes[0]);
        setPolicyTypeDisplay(formattedAlertPolicyTypes[0] ? formattedAlertPolicyTypes[0].value : "");
      }
    }
  }

  /**
   * setPolicySubtypeFromType function filters list of policy subtype based on selected policy
   * At initial load by default first policy is selected and hence that category of subtype should be listed
   */
  function setPolicySubtypeFromType() {
    if (policySubTypesMaster && policySubTypesMaster.length > 0) {
      const subTypeList = policySubTypesMaster.filter((subtype) => {
        return subtype.typeCode.code === policyType.code;
      });
      setPolicySubTypes(subTypeList);
      if (queryParam.id === "createPolicy" || queryParam.id === undefined) {
        // only when create new alert we will set default to first option
        setPolicySubTypeValue(subTypeList[0]);
      }
    }
  }

  /**
   * getPolicySubTypes Get all subtypes from API call and feed into master list
   */
  async function getPolicySubTypes() {
    const response = await getAlertPolicySubTypes();
    if (response) {
      setPolicySubTypesMaster(response);
    }
  }

  /**
   * getPolicyTypesSubtypes function is a async function to get policy sub type and policy type
   * We get the subtype first and than policy because as soon as policy type is updated subtype will change using use effect
   */
  async function getPolicyTypesSubtypes() {
    await getPolicySubTypes();
    await policyTypes();
  }

  /**
   * use effect to update subtype list as soon as policy changes
   */
  useEffect(() => {
    setPolicySubtypeFromType();
    if(isInactivityPolicy(policyType)){
      setAutomatedMonitoring(true);
    }
  }, [policyType]);

  useEffect(() => {
    if (queryParam.id === "createPolicy" || queryParam.id === undefined) {
      // call this function to initialize policy type and subtype when its create new or any other than update
      // if its a edit of a policy we will wait for this call and than initialize the dropdown
      getPolicyTypesSubtypes();
    }
    initValues();
  }, []);

  useEffect(() => {
    if (unsuccessfulCreateEdit !== "") {
      setSnackBarMessage(unsuccessfulCreateEdit);
      setOpenSnackbar(true);
      setCreated(false);
    }
  }, [unsuccessfulCreateEdit]);
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenSnackbar(true);
    } else if (snackPack.length && messageInfo && openSnackbar) {
      // Close an active snack when a new one is added
      setOpenSnackbar(false);
    }
  }, [snackPack, messageInfo, openSnackbar]);

  const initValues = () => {
    setCategoryCode("TRACES");
    setComparisonTypeCode("INCREASES");
    setTriggerValue("50");
    setAggregationTypeCode("PERCENTAGE");
    setTimeWindowTypeCode("DAILY");
  }

  const handleRegenerateAlerts = (e) => {
    if (e.target.checked) {
      setRegenerate(true);
    } else {
      setRegenerate(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "CLEAR_ERROR" });
    dispatch({ type: "CLOSE_SNACKBAR" });
    setOpenSnackbar(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleExited = () => {
    setMessageInfo(undefined);
  };
  const handleSelectUsers = (selectedUsers) => {
    setSelectedUsers([...selectedUsers]);
  };
  const handleDbSelectUsers = (selectedUsers) => {
    setSelectedDbUsers([...selectedUsers]);
  };
  const handleDbRolesSelectUsers = (selectedUsers) => {
    setSelectedDbRoles([...selectedUsers]);
  };
  const handleEditClick = () => {
    setEditable(true);
  };
  const handlePolicyName = (e) => {
    setPolicyName(e.target.value);
  };
  const handlePolicyDesc = (e) => {
    setPolicyDesc(e.target.value);
  };
  const handleResponseDesc = (e) => {
    setResponseMessage(e.target.value);
  };
  const handleCategoryCode = (e) => {
    setCategoryCode(e.target.value);
    setOpenConfirmation(true);
  };
  const handleComparisonTypeCode = (e) => {
    setComparisonTypeCode(e.target.value);
    setOpenConfirmation(true);
  };
  const handleTriggerValue = (e) => {
    setTriggerValue(e.target.value);
    setOpenConfirmation(true);
  };
  const handleAggregationTypeCode = (e) => {
    setAggregationTypeCode(e.target.value);
    setOpenConfirmation(true);
  };
  const handleTimeWindowTypeCode = (e) => {
    setTimeWindowTypeCode(e.target.value);
    setOpenConfirmation(true);
  };
  const handleNewComment = (e) => {
    setNewComment(e.target.value);
    setOpenConfirmation(true);
  };
  const handleSeverity = (sv) => {
    setSeverity(sv);
    setOpenConfirmation(true);
    if (isInactivityPolicy(policyType)) {
      const value = thresholdValue(sv);
      handleAutoConfidenceInterval(value);
    }
  };
  const handleAutoConfidenceInterval = (value) => {
    setAutoConfidenceInterval(value);
    setOpenConfirmation(true);
  };
  const handleAutoTracesValue = (e) => {
    setAutoTracesValue(e);
    setOpenConfirmation(true);
  };
  const handleAutoSlider = (e) => {
    if (e.target.checked) {
      setAutomatedMonitoring(true);
    } else {
      setAutomatedMonitoring(false);
      initValues();
    }
    setTimeWindowTypeCode(e.target.value);
    setOpenConfirmation(true);
  };
  function handleDataFlowGroupOpen(bool) {
    setDataFlowGroupDialogOpen(bool);
  }
  const handleClose = () => {
    setDataFlowGroupDialogOpen(false);
  };
  const handleCloseConfirmation = () => {
    setCreated(false);
    setConfirmRegenerateAlerts(false);
  };

  const handleMonitorClick = (newPlacement) => (event) => {
    setUserApiCall(true);
    setAnchorEl(event.currentTarget);
    setOpen(event.currentTarget);
    // setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const isDisabled = () => {
    if (isEmpty(policyName) || isEmpty(policyDesc) || isEmpty(responseMessage) || created) {
      return true
    } else {
      return false
    }
  };

  const handleAutoResponseClick = (event) => {
    if (event.target.checked) {
      setAutoResponse(true);
    } else {
      setAutoResponse(false);
    }
  };

  const handleSaveComment = () => {
    async function saveComment(id) {
      const response = await saveAlertPolicyComment(id, newComment);
      if (response.status === 201) {
        apiOnCommentsSave();
        setNewComment("");
      } else if (
        response &&
        response.response &&
        response.response.data &&
        response.response.data.errorMessage
      ) {
        dispatch({
          type: "CREATE_ALERT_POLICY_FAILED",
          payload: response.response.data.errorMessage,
        });
      }
    }
    if (newComment !== "" && queryParam.id) {
      saveComment(queryParam.id);
    }
  };

  function handleMute(id) {
    let temp = [];
    selectedUsers.forEach((each) => {
      if (each.monitorThumbnail.id === id) {
        each.muted = !each.muted;
        temp.push(each);
      } else {
        temp.push(each);
      }
    });
    setSelectedUsers(temp);
  }

  const selectDataFlowGroupHandle = (data) => {
    data["type"] = "dataFlowGroups";
    dispatch({ type: "TRACK_TRAVERSAL", payload: "Data Flows" });
    history.push({
      pathname: "/tenant/dataFlows",
      state: data,
    });
  };

  const selectPolicyType = (event) => {
    setPolicyType(
      alertPolicyTypes.filter((each) => each.value === event.target.value)[0]
    );
    setPolicyTypeDisplay(event.target.value);
  };

  /**
   * Function to update the state value from select option on change
   * @param event on change event
   */
  const selectPolicySubtype = (event) => {
    setPolicySubTypeValue(
      policySubTypesMaster.find((each) => each.subTypeValue === event.target.value && policyType.code === each.typeCode.code)
    );
  };

  /**
   * Get the code of policy sub type selected to be passed to API
   * @returns policy subtype code
   */
  const getPolicySubTypeCode = () => {
    return policySubTypeValue ? policySubTypeValue.subTypeCode : "";
  }

  const findUser = (currentUserName) => {
    async function searchAction(name) {
      const response = await searchUser(name);
      if (response) {
        let newSelected = [];
        let temp = {};
        temp.muted = false;
        temp.monitorThumbnail = response.content[0];
        newSelected = newSelected.concat(selectedUsers, temp);
        setSelectedUsers(newSelected);
        setCurrentUser(temp.monitorThumbnail.id);
      }
    }
    if (currentUserName) {
      searchAction(currentUserName);
    }
  };
  useEffect(() => {
    async function getCurrentUserDetails() {
      const res = await getPresentUserDetails();
      if (res) {
        findUser(`${res.firstName}`);
      }
    }
    if (window.location.pathname.indexOf("createPolicy") > -1) {
      dispatch({ type: "CLEAR_ERROR" });
      getCurrentUserDetails();
    }
  }, [queryParam]);
  useEffect(() => {
    async function getAllDataFlowGroupsAction() {
      const response = await getAllDataFlowGroups();
      if (response) {
        setAllDataFlowGroups(response.content);
      }
    }
    if (dataFlowGroupDialogOpen) {
      getAllDataFlowGroupsAction();
    }
  }, [dataFlowGroupDialogOpen]);

  useEffect(() => {
    async function getPolicy(id) {
      // get policy type and subtype details before getting policy details
      await getPolicyTypesSubtypes();
      const response = await getPolicyDetails(id);

      if (response) {
        let temp = response;
        setPolicy(temp);
        setPolicyName(
          queryParam.clonePolicy ? `${temp.name}_clone` : temp.name
        );
        setPolicyDesc(temp.description);
        setResponseMessage(temp.responseAction);
        setSeverity(temp.severity.code);
        setAutoResponse(temp.autoResponse);
        setPolicyId(temp.id);
        setSelectedUsers(temp.monitors);
        setSystemPolicy(temp.systemPolicy);
        setPolicyStatus(temp.status.value);
        //setIsDirectDBAccess(temp.directDBAccess);
        setPolicyType(temp.type);
        setPolicyTypeDisplay((temp.type && temp.type.value) || "");
        setEmailNotification(temp.notificationEnabled)
        // when existing policy we will set the value of sub type from API response
        setPolicySubTypeValue({subTypeCode: temp.policySubtype.code, subTypeValue: temp.policySubtype.value});
        setToleranceLevelCode((temp.toleranceLevel && temp.toleranceLevel.code) || "");
        const roles = [];
        const usernames = [];
        temp.userNamesAndRoles &&
          temp.userNamesAndRoles.forEach((role) => {
            if (role.type === "ROLE") roles.push(role.name);
          });
        temp.userNamesAndRoles &&
          temp.userNamesAndRoles.forEach((role) => {
            if (role.type === "USERNAME") usernames.push(role.name);
          });
        setSelectedDbRoles(roles);
        setSelectedDbUsers(usernames);
        if (
          temp.automatedMonitoringThreshold &&
          temp.automatedMonitoringThreshold.id !== ""
        ) {
          setAutomatedMonitoring(temp.automatedMonitoring);
          setAutoConfidenceInterval(
            temp.automatedMonitoringThreshold &&
              temp.automatedMonitoringThreshold.confidenceLevel
          );
          setAutomatedMonitoringThresholdId(
            temp.automatedMonitoringThreshold.id
          );
          setAutoTracesValue(
            temp.automatedMonitoringThreshold &&
              temp.automatedMonitoringThreshold.minNumberOfTraces
          );
          if (temp.trigger !== null && temp.trigger !== undefined) {
            setAggregationTypeCode(
              temp.trigger.triggerCondition.aggregationType.code
            );
            setCategoryCode(temp.trigger.triggerCondition.category.code);
            setComparisonTypeCode(
              temp.trigger.triggerCondition.comparisonType.code
            );
            setTimeWindowTypeCode(
              temp.trigger.triggerCondition.timeWindowType.code
            );
            setTriggerValue(temp.trigger.triggerCondition.value);
            setPolicyStatus(temp.status.value);
            setTriggerId(temp.trigger.id);
            setTriggerConditionId(temp.trigger.triggerCondition.id);
          }
        } else {
          if (temp.trigger) {
            setAggregationTypeCode(
              temp.trigger.triggerCondition.aggregationType.code
            );
            setCategoryCode(temp.trigger.triggerCondition.category.code);
            setComparisonTypeCode(
              temp.trigger.triggerCondition.comparisonType.code
            );
            setTimeWindowTypeCode(
              temp.trigger.triggerCondition.timeWindowType.code
            );
            setTriggerValue(temp.trigger.triggerCondition.value);
            setPolicyStatus((temp.status && temp.status.value) || "");
            setTriggerId(temp.trigger.id);
            setTriggerConditionId(temp.trigger.triggerCondition.id);
          } else {
          }
        }
      }
    }
    if (queryParam.id !== "createPolicy" && queryParam.id !== undefined) {
      //find Alert Policy API call with the help of id
      getPolicy(queryParam.id);
    }
  }, [queryParam]);

  let location = useLocation();
  useEffect(() => {
    if (location.pathname.indexOf("clonePolicy") > -1) {
      setEditable(true);
    }
  }, [queryParam]);

  // useEffect(() => {
  //   async function getPolicyAlertsApi(id) {
  //     const response = await getAlertPolicyAlerts(id, page);
  //     if (response) {
  //       let temp = policyAlerts;
  //       setPolicyAlerts([...new Set(temp.concat(response.content))]);
  //       setTotalPolicyAlerts(response.totalElements);
  //     }
  //   }
  //   if (
  //     (queryParam.id !== "createPolicy" && queryParam.id !== undefined) ||
  //     getPolicyAlerts
  //   ) {
  //     //find Alert Policy API call with the help of id
  //     getPolicyAlertsApi(queryParam.id);
  //   }
  //   return () => {
  //     handleGetPolicyAlerts(false);
  //   };
  // }, [queryParam, getPolicyAlerts]);

  useEffect(() => {
    async function getCurrentUserDetails() {
      const res = await getPresentUserDetails();
      if (res) {
        findUser(`${res.firstName}`);
      }
    }
    if (window.location.pathname.indexOf("createPolicy") > -1) {
      dispatch({ type: "CLEAR_ERROR" });
      getCurrentUserDetails();
    }
  }, [queryParam]);
  useEffect(() => {
    async function getAllDataFlowGroupsAction() {
      const response = await getAllDataFlowGroups();
      if (response) {
        setAllDataFlowGroups(response.content);
      }
    }
    if (dataFlowGroupDialogOpen) {
      getAllDataFlowGroupsAction();
    }
  }, [dataFlowGroupDialogOpen]);

  useEffect(() => {
    async function getAlertPolicyDataFlowGroupsAction(id) {
      const response = await getAlertPolicyDataFlowGroups(id);
      if (response) {
        setSelectedDataFlowGroups(response.content);
      }
    }
    if (queryParam.id !== "createPolicy" && queryParam.id !== undefined) {
      //find Alert Policy API call with the help of id
      getAlertPolicyDataFlowGroupsAction(queryParam.id);
    }
  }, [queryParam]);

  useEffect(() => {
    async function getPolicyComments(id) {
      const response = await getAlertPolicyComments(id);
      if (response) {
        setAlertPolicyComments(response.content);
      }
    }
    if (queryParam.id !== "createPolicy" && queryParam.id !== undefined) {
      //find Alert Policy API call with the help of id
      getPolicyComments(queryParam.id);
    }
  }, [queryParam]);

  useEffect(() => {
    if (
      ((policyName !== "" ||
        policyDesc !== "" ||
        policyType !== "" ||
        responseMessage !== "" ||
        categoryCode !== "" ||
        selectedDataFlowGroups.length > 0 ||
        selectedUsers.length > 0 ||
        comparisonTypeCode !== "" ||
        aggregationTypeCode !== "" ||
        timeWindowTypeCode !== "" ||
        triggerValue !== 0) &&
        queryParam.id === undefined &&
        window.location.pathname.indexOf("createPolicy") > -1) ||
      window.location.pathname.indexOf("clonePolicy") > -1
    ) {
      let flowGroupIds = [];
      let monitorIds = [];
      let trigger = {};
      if (selectedDataFlowGroups.length > 0) {
        selectedDataFlowGroups.forEach((each) => {
          flowGroupIds.push(each.id);
        });
      }
      if (selectedUsers.length > 0) {
        selectedUsers.forEach((each) => {
          monitorIds.push({
            monitor: each.monitorThumbnail.id,
            muted: each.muted,
          });
        });
      }
      if (automatedMonitoring) {
        trigger = null;
      } else {
        if (
          categoryCode &&
          comparisonTypeCode &&
          triggerValue &&
          aggregationTypeCode &&
          timeWindowTypeCode
        ) {
          trigger = {
            triggerCondition: {
              categoryCode: categoryCode,
              comparisonTypeCode: comparisonTypeCode,
              value: Number(triggerValue),
              aggregationTypeCode: aggregationTypeCode,
              timeWindowTypeCode: timeWindowTypeCode,
            },
          };
        } else {
          trigger = null;
        }
      }
      let temp = {
        name: policyName,
        description: policyDesc,
        typeCode: `${policyType && policyType.code}`,
        policySubtype: getPolicySubTypeCode(),
        responseAction: responseMessage,
        autoResponse: autoResponse,
        severityCode: severity.toUpperCase(),
        automatedMonitoring: automatedMonitoring,
        trigger: trigger,
        monitors: monitorIds,
        dataFlowGroups: flowGroupIds,
        notificationEnabled: emailNotification,
      };
      if (isWorkHourPolicy(policySubTypeValue)) {
        temp = {
          ...temp,
          toleranceLevelCode: toleranceLevelCode.toUpperCase(),
        };
      } else if (automatedMonitoring) {
        temp["automatedMonitoringThreshold"] = {
          confidenceLevel: autoConfidenceInterval,
          minNumberOfTraces: autoTracesValue,
        };
      }
      setCreatePolicyData(temp);
    } else if (
      (policyName !== "" ||
        policyDesc !== "" ||
        responseMessage !== "" ||
        categoryCode !== "" ||
        selectedDataFlowGroups.length > 0 ||
        selectedUsers.length > 0 ||
        comparisonTypeCode !== "" ||
        aggregationTypeCode !== "" ||
        timeWindowTypeCode !== "" ||
        triggerValue !== 0) &&
      queryParam.id !== "createPolicy" &&
      queryParam.id !== undefined
    ) {
      let flowGroupIds = [];
      let monitorIds = [];
      let trigger = {};
      if (selectedDataFlowGroups.length > 0) {
        selectedDataFlowGroups.forEach((each) => {
          flowGroupIds.push(each.id);
        });
      }
      // if(alertPolicyDataFlowGroups.length > 0){
      //     alertPolicyDataFlowGroups.forEach((each) => {
      //         flowGroupIds.push(each.id)
      //     })
      // }
      if (selectedUsers.length > 0) {
        selectedUsers.forEach((each) => {
          monitorIds.push({
            monitor: each.monitorThumbnail.id,
            muted: each.muted,
          });
        });
      }
      if (automatedMonitoring) {
        if (triggerId && triggerConditionId) {
          trigger = {
            triggerCondition: {
              categoryCode: categoryCode,
              comparisonTypeCode: comparisonTypeCode,
              value: Number(triggerValue),
              aggregationTypeCode: aggregationTypeCode,
              timeWindowTypeCode: timeWindowTypeCode,
            },
          };
        } else {
          trigger = null;
        }
      } else {
        if (
          categoryCode &&
          comparisonTypeCode &&
          triggerValue &&
          aggregationTypeCode &&
          timeWindowTypeCode
        ) {
          trigger = {
            triggerCondition: {
              categoryCode: categoryCode,
              comparisonTypeCode: comparisonTypeCode,
              value: Number(triggerValue),
              aggregationTypeCode: aggregationTypeCode,
              timeWindowTypeCode: timeWindowTypeCode,
            },
          };
        } else {
          trigger = null;
        }
      }
      let temp = {
        name: policyName,
        description: policyDesc,
        typeCode: `${policyType ? policyType.code : ""}`,
        policySubtype: getPolicySubTypeCode(),
        responseAction: responseMessage,
        autoResponse: autoResponse,
        severityCode: severity.toUpperCase(),
        trigger: trigger,
        automatedMonitoring: automatedMonitoring,
        monitors: monitorIds,
        dataFlowGroups: flowGroupIds,
        notificationEnabled: emailNotification,
      };
      if (isWorkHourPolicy(policySubTypeValue)) {
        temp = {
          ...temp,
          toleranceLevelCode: toleranceLevelCode.toUpperCase(),
        };
      } else if (automatedMonitoring) {
        temp["automatedMonitoringThreshold"] = {
          confidenceLevel: autoConfidenceInterval,
          minNumberOfTraces: autoTracesValue,
        };
        if (automatedMonitoringThresholdId) {
          temp["automatedMonitoringThreshold"]["id"] =
            automatedMonitoringThresholdId;
        }
        if (triggerId && triggerConditionId) {
          temp.trigger["id"] = triggerId;
          temp.trigger["triggerCondition"]["id"] = triggerConditionId;
        }
      } else {
        if (automatedMonitoringThresholdId) {
          temp["automatedMonitoringThreshold"] = {
            id: automatedMonitoringThresholdId,
            confidenceLevel: autoConfidenceInterval,
            minNumberOfTraces: autoTracesValue,
          };
        }
        if (triggerId && triggerConditionId) {
          temp.trigger["id"] = triggerId;
          temp.trigger["triggerCondition"]["id"] = triggerConditionId;
        }
      }

      setUpdatePolicyData(temp);
    }
  }, [
    policyName,
    policyDesc,
    policyType,
    policySubTypeValue,
    responseMessage,
    categoryCode,
    comparisonTypeCode,
    triggerValue,
    aggregationTypeCode,
    timeWindowTypeCode,
    severity,
    queryParam.id,
    autoResponse,
    triggerId,
    triggerConditionId,
    selectedDataFlowGroups,
    selectedUsers,
    automatedMonitoring,
    autoConfidenceInterval,
    autoTracesValue,
    toleranceLevelCode,
    emailNotification,
  ]);

  async function saveAlertPolicy() {
    setCreated(true);
    if (createPolicyData.automatedMonitoring || createPolicyData.trigger !== null) {
      if (
        openConfirmation &&
        queryParam.id !== "createPolicy" &&
        queryParam.clonePolicy !== "clonePolicy" &&
        queryParam.id !== undefined
      ) {
        setConfirmRegenerateAlerts(true);
      } else {
        if (
          queryParam.id !== "createPolicy" &&
          queryParam.clonePolicy !== "clonePolicy" &&
          queryParam.id !== undefined
        ) {
          if (regenerate) {
            updatePolicyData["regenerateAlerts"] = true;
          }
          const data = [];
          selectedDbRoles.forEach((role) =>
            data.push({ name: role, type: "ROLE" })
          );
          selectedDbUsers.forEach((role) =>
            data.push({ name: role, type: "USERNAME" })
          );
          if (data.length !== 0) {
            updatePolicyData.userNamesAndRoles = data;
          }
          dispatch(
            updateAlertPolicy({
              data: updatePolicyData,
              id: policyId,
              history: history,
            })
          );
        } else {
          const data = [];
          selectedDbRoles.forEach((role) =>
            data.push({ name: role, type: "ROLE" })
          );
          selectedDbUsers.forEach((role) =>
            data.push({ name: role, type: "USERNAME" })
          );
          if (data.length !== 0) {
            createPolicyData.userNamesAndRoles = data;
          }

          dispatch(
            createAlertPolicy({ data: createPolicyData, history: history })
          );
        }
      }
    } else {
      setSnackBarMessage(savePolicyErrorMessage);
      setOpenSnackbar(true);
      setCreated(false);
    }
  }

  async function confirmSavePolicy() {
    if (queryParam.id !== "createPolicy" && queryParam.id !== undefined) {
      setLoader(true);
      if (regenerate) {
        updatePolicyData["regenerateAlerts"] = true;
      }
      dispatch(
        updateAlertPolicy({
          data: updatePolicyData,
          id: policyId,
          history: history,
        })
      );
    }
  }
  //Removing Monitor Pop up when clicked outside of popup
  // useEffect(() => {
  //   window.addEventListener("click", (e) => {
  //     if (e.target.className !== "policyMonitor") {
  //
  //       setAnchorEl(null);
  //       setOpen(false);
  //     }
  //   });
  // }, []);

  const apiOnCommentsSave = () => {
    async function getPolicyComments(id) {
      const response = await getAlertPolicyComments(id);
      if (response) {
        setAlertPolicyComments(response.content);
      }
    }
    getPolicyComments(queryParam.id);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
    setOpen(null);
  };


  const renderPolicyTypes = () => {
    return <div className="trigger-container textHeading">
      <label>Policy Type:</label>
      <br />
      <FormControl
        variant="outlined"
        className={classes.formControl}
      >
        <Select
          native
          value={policyTypeDisplay}
          className="InputSelectFeild"
          style={{ width: "100%" }}
          onChange={(event) => selectPolicyType(event)}
          disabled={
            !window.location.pathname.includes("createPolicy")
          }
        >
          {policyTypesDisplayValues.length > 0 &&
            policyTypesDisplayValues.map((el) => (
              <option value={el}>{el}</option>
            ))}
        </Select>
      </FormControl>
      <br />
      <label>Policy Subtype:</label>
      <br />
      <FormControl
        variant="outlined"
        className={classes.formControl}
      >
        <Select
          native
          value={policySubTypeValue.subTypeValue}
          className="InputSelectFeild"
          style={{ width: "100%" }}
          onChange={(event) => selectPolicySubtype(event)}
          disabled={
            !window.location.pathname.includes("createPolicy")
          }
        >
          {policySubTypes.length > 0 &&
            policySubTypes.map((el) => (
              <option value={el.subTypeValue}>{el.subTypeValue}</option>
            ))}
        </Select>
      </FormControl>
    </div>;
  };

  const handleToleranceLevelCode = (value) => {
    setToleranceLevelCode(value);
  }

  const id = open ? "simple-popover" : undefined;
  return (
    <React.Fragment>
      <div className="main-edit-create-container create-edit-policy">
        <Dialog
          open={dataFlowGroupDialogOpen}
          //onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          onBackdropClick={handleClose}
        >
          <DialogTitle id="alert-dialog-title">{"Add Flow Groups"}</DialogTitle>
          <DialogContent>
            <EnhancedTable
              tableData={allDataFlowGroups}
              selectedDataFlowGroups={selectedDataFlowGroups}
              handleDataFlowGroupOpen={handleDataFlowGroupOpen}
              handleSelectDataFlowGroup={handleSelectDataFlowGroup}
              headCells={dataFLowGroupHeadCellForCreateEdit}
              loc={"addDataFlowGroup"}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={confirmRegenerateAlerts}
          onClose={handleCloseConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          onBackdropClick={handleCloseConfirmation}
        >
          {loader ? (
            <div className="global-loader-holder">
              <img src={Page_Loader} alt="_Loader" className="loader" />
            </div>
          ) : (
            ""
          )}
          <DialogTitle id="alert-dialog-title">{"Confirmation?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you don't want to regenerate alerts?
            </DialogContentText>
            <div style={{ margin: "10px 11px 18px -7px" }}>
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                checkedIcon={<span className="checked-icon" />}
                onClick={(event) => handleRegenerateAlerts(event)}
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={regenerate}
              />
              <label>I want to regenerate alerts</label>
            </div>
          </DialogContent>
          <DialogActions>
            <ColoredActionButton
              style={{
                width: "124px",
                height: "32px",
                boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
              }}
              onClick={handleCloseConfirmation}
            >
              Cancel
            </ColoredActionButton>
            <NewPolicyButtonForPolicyCreateEdit
              style={{
                marginBottom: "0px",
                cursor: "pointer",
                width: "124px",
                height: "32px",
              }}
              onClick={() => confirmSavePolicy()}

              //autoFocus
            >
              Confirm
            </NewPolicyButtonForPolicyCreateEdit>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3}>
          <Grid item xs={9} className="grid-9">
            {!editable &&
            queryParam.id !== "createPolicy" &&
            queryParam.id !== undefined ? (
              <>
                <div className="policy-description">
                  <div style={{ marginBottom: "10px" }}>
                    <Link
                      style={{ color: "#081981" }}
                      to={`/tenant/alertPolicies`}
                    >
                      Policies
                    </Link>
                    &nbsp;{`>`}&nbsp;{policyName}
                  </div>
                  <div className="policy-content">
                    <h2 style={{ margin: "0" }}>{policyName}</h2>
                    <CanAllow needs={{ permission: [UPDATE_ALERT_POLICY] }}>
                      <EditButtonForPolicyCreateEdit
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEditClick()}
                        className="min-margin edit-policy-button"
                        disabled={
                          systemPolicy && userRole.indexOf("Administrator") === -1
                            ? true
                            : false
                        }
                      >
                      <EditOutlinedIcon />
                        Edit Policy
                      </EditButtonForPolicyCreateEdit>
                      <EditButtonForPolicyCreateEdit
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          history.push(`./${queryParam.id}/clonePolicy`);
                          setEditable(true);
                        }}
                        className="min-margin edit-policy-button"
                      >
                        <FileCopyIcon />
                        Clone Policy
                      </EditButtonForPolicyCreateEdit>
                    </CanAllow>                      
                  </div>
                  <p>{policyDesc}</p>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginBottom: "10px" }}>
                  <Link
                    style={{ color: "#081981" }}
                    to={`/tenant/alertPolicies`}
                  >{`< Back`}</Link>
                </div>
                <div style={{ backgroundColor: "white", display: "flex" }}>
                  <form className="name-form-width">
                    <input
                      type="textarea"
                      id="name"
                      className="data-input"
                      value={policyName}
                      onChange={(event) => handlePolicyName(event)}
                      placeholder="Name of Alert Policy"
                      multiline={true}
                      variant="outlined"
                      disabled={
                        !editable &&
                        queryParam.id !== "createPolicy" &&
                        queryParam.id !== undefined
                      }
                    />
                    <input
                      id="description"
                      type="textarea"
                      className="data-input"
                      value={policyDesc}
                      onChange={(event) => handlePolicyDesc(event)}
                      placeholder="Enter description of the policy..."
                      multiline={true}
                      disabled={
                        !editable &&
                        queryParam.id !== "createPolicy" &&
                        queryParam.id !== undefined
                      }
                      variant="outlined"
                    />
                  </form>
                  {!editable &&
                  queryParam.id !== "createPolicy" &&
                  queryParam.id !== undefined ? (
                    <ColoredActionButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleEditClick()}
                      className="min-margin"
                    >
                      <EditOutlinedIcon />
                      Edit Policy
                    </ColoredActionButton>
                  ) : (
                    <div className="save-button">
                      {queryParam.id !== "createPolicy" &&
                        queryParam.id !== undefined &&
                        !queryParam.clonePolicy && (
                          <div
                            className="auto-response"
                            style={{
                              margin: "10px 11px 18px 10px",
                              boxShadow: "0 5px 13px -3px rgb(1 18 121 / 50%)",
                            }}
                          >
                            <Checkbox
                              className="checkbox-root"
                              disableRipple
                              color="default"
                              onClick={(event) => handleRegenerateAlerts(event)}
                              checkedIcon={<span className="checked-icon" />}
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                              checked={regenerate}
                            />
                            <label>Regenerate Alerts</label>
                          </div>
                        )}

                      <NewPolicyButtonForPolicyCreateEdit
                        variant="contained"
                        color="primary"
                        size="medium"
                        disabled={isDisabled()}
                        onClick={() => saveAlertPolicy()}
                        className="min-margin"
                        style={{ marginLeft: "13px" }}
                      >
                        Save Policy
                        {created && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </NewPolicyButtonForPolicyCreateEdit>
                      {/* <ColoredActionButton
                        variant="contained"
                        color="primary"
                        size="medium"
                        className="min-margin"
                      >
                        Save Draft
                      </ColoredActionButton> */}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="response-action">
              <p style={{ fontSize: "18px" }}>Response Action:</p>
              {!editable &&
              queryParam.id !== "createPolicy" &&
              queryParam.id !== undefined ? (
                <p style={{ fontSize: "18px" }}>{responseMessage}</p>
              ) : (
                <div
                  className="auto-response-container"
                  style={{ display: "flex" }}
                >
                  <input
                    className="autoResponseInputField"
                    id="action"
                    type="textarea"
                    value={responseMessage}
                    onChange={(event) => handleResponseDesc(event)}
                    placeholder="Enter custom response to help others understand what action to take in the event of this error."
                    multiline={true}
                    variant="outlined"
                  ></input>
                  <div className="auto-response">
                    <Checkbox
                      className="checkbox-root"
                      disableRipple
                      color="default"
                      onClick={(event) => handleAutoResponseClick(event)}
                      checkedIcon={<span className="checked-icon" />}
                      icon={<span className="checkbox-icon" />}
                      inputProps={{
                        "aria-label": "decorative checkbox",
                      }}
                      checked={autoResponse}
                    />
                    <label>Auto Response</label>
                  </div>
                </div>
              )}
            </div>
            <div className="create-edit-chart-container">
              {queryParam.id !== "createPolicy" &&
              queryParam.id !== undefined &&
              !queryParam.clonePolicy &&
              filter
                ? chartTypes.alertsOverTimeBarChart(
                    cubejsApi,
                    "Alerts Over Time",
                    filter
                  )
                : ""}
            </div>
            <div className="table-container" style={{ marginTop: "20px" }}>
              {policyType && policyType.code !== "DIRECT_DATABASE_ACCESS" ? (
                selectedDataFlowGroups.length > 0 && !queryParam.clonePolicy ? (
                  <EnhancedTable
                    tableData={selectedDataFlowGroups}
                    handleDataFlowGroupOpen={handleDataFlowGroupOpen}
                    headCells={dataFLowGroupHeadCell}
                    selectDataFlowGroupHandle={(item) =>
                      selectDataFlowGroupHandle(item)
                    }
                    selectedDataFlowGroups={selectedDataFlowGroups}
                    loc={
                      queryParam.id !== "create-policy"
                        ? "show-policy-flow-groups"
                        : "create-policy"
                    }
                  />
                ) : (
                  <CanAllow needs={{permission: [addDataFlowGroup]}}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          Monitored Flow Groups&nbsp;&nbsp;
                          <img src={HelpIcon} alt="help" />
                        </span>
                        <span>
                          &nbsp;&nbsp;
                          <CreatePolicyButtonForPolicyCreateEdit
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleDataFlowGroupOpen(true)}
                            startIcon={<AddCircleOutlineIcon />}
                            className="min-margin"
                          >
                            Add Flow Group to Policy
                          </CreatePolicyButtonForPolicyCreateEdit>
                        </span>
                      </div>
                      <div className="add-create-flow-group">
                        <small onClick={() => handleDataFlowGroupOpen(true)}>
                          Add or Create new flow group to begin.
                        </small>
                      </div>
                    </div>
                  </CanAllow>
                )
              ) : (
                ""
              )}
              {queryParam.id !== "createPolicy" &&
                queryParam.id !== undefined &&
                !queryParam.clonePolicy && (
                  <AlertPolicyById policyId={queryParam.id} />
                )}
            </div>
          </Grid>
          <Grid item xs={3} className="grid-3">
            <p style={{ marginTop: "20px", marginBottom: "0", fontWeight: "600", }}>
              Current Status:
            </p>
            {permissions.indexOf(viewAlertPolicyStatus) > -1 && (
              <LevelComponent
                level={""}
                status={policyStatus}
                Id={policyStatus}
              />
            )}
            <br />

            {!editable &&
            queryParam.id !== "createPolicy" &&
            queryParam.id !== undefined ? (
              <div style={{ marginBottom: "10px" }}>
                <p style={{ marginBottom: "0", fontWeight: "600" }}>Severity:</p>
                {permissions.indexOf(viewAlertPolicySeverity) > -1 && (
                  <LevelComponent level={""} status={severity} Id={severity} />
                )}
              </div>
            ) : (
              <div className="trigger-container textHeading">
                <p>Select Severity:</p>
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "10px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => handleSeverity("HIGH")}
                    style={{
                      cursor: "pointer",
                      border: severity === "HIGH" ? "3px solid #081981" : "",
                    }}
                  >
                    <LevelComponent level={""} status={"high"} Id={"high"} />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleSeverity("MEDIUM")}
                    style={{
                      cursor: "pointer",
                      border: severity === "MEDIUM" ? "3px solid #081981" : "",
                    }}
                  >
                    <LevelComponent
                      level={""}
                      status={"medium"}
                      Id={"medium"}
                    />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleSeverity("LOW")}
                    style={{
                      cursor: "pointer",
                      border: severity === "LOW" ? "3px solid #081981" : "",
                    }}
                  >
                    <LevelComponent level={""} status={"low"} Id={"low"} />
                  </Button>
                </div>
              </div>
            )}
            {!editable &&
            queryParam.id !== "createPolicy" &&
            queryParam.id !== undefined &&
            queryParam.id !== "" ? (
              <>
                {policyType && (
                  <div className="trigger-container">
                    <label className="triggerContainerText">Policy Type:</label>
                    <br />
                    <span>{renderPolicyType(policyType.value)}</span>
                    <br />
                    <br />
                    <label className="triggerContainerText">Policy Subtype:</label>
                    <br />
                    <span>{policySubTypeValue ? policySubTypeValue.subTypeValue : "-"}</span>
                    <br />
                    <br />
                    <label className="triggerContainerText">Policy Information:</label>
                    <br />
                    <span>
                      {policy.automatedMonitoring
                        ? `This policy is monitored by AI.`
                        : `This is a custom made policy monitored by trigger rules.`}
                    </span>
                  </div>
                )}
                <br />
              </>
            ) : (
              <>
                {renderPolicyTypes()}
                <br />
              </>
            )}
            <AlertEmail emailNotification={emailNotification} setEmailNotification={setEmailNotification} editable={editable}/>

            {!editable &&
            queryParam.id !== "createPolicy" &&
            queryParam.id !== undefined ? (
              <div className="">
                {policy.automatedMonitoring ? (
                  <AutoTriggerComponent
                    autoConfidenceInterval={autoConfidenceInterval}
                    handleAutoConfidenceInterval={handleAutoConfidenceInterval}
                    autoTracesValue={autoTracesValue}
                    handleAutoTracesValue={handleAutoTracesValue}
                    editStatus={false}
                    toleranceLevelCode={toleranceLevelCode}
                    policySubTypeValue={policySubTypeValue}
                    policyType={policyType}
                  />
                ) : (
                  <div className="trigger-details">
                    <p>Alert Trigger Rule:</p>
                    <div>
                      {policy &&
                        policy.trigger &&
                        policy.trigger.triggerCondition && (
                          <>
                            <div className="group-4">
                              {policy.trigger.triggerCondition.category.value}
                            </div>
                            <br />
                          </>
                        )}

                      {policy &&
                        policy.trigger &&
                        policy.trigger.triggerCondition && (
                          <>
                            <div className="group-4">
                              {
                                policy.trigger.triggerCondition.comparisonType
                                  .value
                              }
                              &nbsp;
                              {aggregationTypeCode === "PERCENTAGE"
                                ? `${triggerValue}%`
                                : triggerValue}
                            </div>
                            <br />
                          </>
                        )}
                      {policy &&
                        policy.trigger &&
                        policy.trigger.triggerCondition && (
                          <div className="group-4">
                            Over{" "}
                            {
                              policy.trigger.triggerCondition.timeWindowType
                                .value
                            }{" "}
                            average.
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                 {!editable &&
                queryParam.id !== "createPolicy" &&
                queryParam.id !== undefined ? (
                  <div>
                    <p>Severity:</p>
                    <LevelComponent
                      level={""}
                      status={severity}
                      Id={severity}
                    />
                  </div>
                ) : (
                  <div className="trigger-container">
                    <div>
                      <p
                        style={{
                          paddingLeft: "10px",
                          fontSize: "600 !important",
                        }}
                      >
                        Automate Monitoring:
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Checkbox
                          className="checkbox-root"
                          disableRipple
                          color="default"
                          onClick={(event) => handleAutoSlider(event)}
                          checkedIcon={<span className="checked-icon" />}
                          icon={<span className="checkbox-icon" />}
                          inputProps={{
                            "aria-label": "decorative checkbox",
                          }}
                          checked={automatedMonitoring}
                          disabled
                        />
                        <label style={{ marginTop: "10px" }}>
                          I want AI to monitor this policy.
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {!automatedMonitoring && (
                  <TriggerComponent
                    queryParam={queryParam}
                    automatedMonitoring={automatedMonitoring}
                    aggregationTypeCode={aggregationTypeCode}
                    categoryCode={categoryCode}
                    comparisonTypeCode={comparisonTypeCode}
                    timeWindowTypeCode={timeWindowTypeCode}
                    triggerValue={triggerValue}
                    handleAggregationTypeCode={handleAggregationTypeCode}
                    handleCategoryCode={handleCategoryCode}
                    handleComparisonTypeCode={handleComparisonTypeCode}
                    handleTimeWindowTypeCode={handleTimeWindowTypeCode}
                    handleTriggerValue={handleTriggerValue}
                  />
                )}
                {automatedMonitoring && (
                  <AutoTriggerComponent
                    autoConfidenceInterval={autoConfidenceInterval}
                    handleAutoConfidenceInterval={handleAutoConfidenceInterval}
                    autoTracesValue={autoTracesValue}
                    handleAutoTracesValue={handleAutoTracesValue}
                    editStatus={true}
                    policySubTypeValue={policySubTypeValue}
                    toleranceLevelCode={toleranceLevelCode}
                    handleToleranceLevelCode={handleToleranceLevelCode}
                    policyType={policyType}
                    severity={severity}
                  />
                )}
              </>
            )}

            <br />
            <>
            <div className="policy-toggle">
                <Toggler
                  togglers={togglers}
                  setTogglers={setTogglers}
                ></Toggler>
              </div>
              {togglers[0].state === "active" && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* <p style={{ fontSize: "14px" }}>Monitors:</p> */}
                  <div>
                    <IconButtonForPolicyCreateEdit
                      variant="contained"
                      id="delete"
                      size="small"
                      color="primary"
                      className="min-margin"
                    >
                      <img src={MuteIcon} alt="mute" />
                    </IconButtonForPolicyCreateEdit>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleMonitorClick("left")}
                      style={{
                        color: "#081981",
                        backgroundColor: "transparent",
                        minWidth: "32px",
                        marginLeft: "5px",
                      }}
                      className="min-margin"
                    >
                      <img
                        className="policyMonitor"
                        src={ProfileIcon}
                        alt="monitor"
                      />
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      // placement={placement}
                      onClose={handleClosePopOver}
                      transition
                      className="policyMonitor"
                      style={{ boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)" }}
                    >
                      <div className="popper-root monitors">
                        <Fade timeout={350}>
                          <MonitorsComponent
                            userApiCall={userApiCall}
                            setUserApiCall={setUserApiCall}
                            selectedUsers={selectedUsers}
                            handleSelectUsers={handleSelectUsers}
                          />
                        </Fade>
                      </div>
                    </Popover>
                  </div>
                </div>
              )}
              {togglers[0].state === "active" &&
                selectedUsers &&
                selectedUsers.map((each) => {
                  return (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          paddingBottom: "8px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "inherit" }}>
                          <Avatar
                            className="avatar"
                            alt={
                              each.monitorThumbnail
                                ? each.monitorThumbnail.name
                                : ""
                            }
                            src={`data:image/png;base64, ${each.monitorThumbnail.picture}`}
                          />
                          <label style={{ padding: "10px", color: "#081981" }}>
                            {each.monitorThumbnail.id === currentUser
                              ? `${each.monitorThumbnail.name} (Me)`
                              : `${each.monitorThumbnail.name}`}
                          </label>
                        </div>
                        {!each.muted && (
                          <IconButtonForPolicyCreateEdit
                            variant="contained"
                            id="delete"
                            size="small"
                            color="primary"
                            disabled={!editable}
                            style={{
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMute(each.monitorThumbnail.id)}
                            className="min-margin"
                          >
                            <img src={UnmuteIcon} alt="unmute" />
                          </IconButtonForPolicyCreateEdit>
                        )}
                        {each.muted && (
                          <IconButtonForPolicyCreateEdit
                            variant="contained"
                            id="delete"
                            size="small"
                            color="primary"
                            disabled={!editable}
                            style={{
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMute(each.monitorThumbnail.id)}
                            className="min-margin"
                          >
                            <img src={MuteIcon} alt="mute" />
                          </IconButtonForPolicyCreateEdit>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
              {togglers[1].state === "active" && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* <p style={{ fontSize: "14px" }}>Monitors:</p> */}
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleMonitorClick("left")}
                      style={{
                        color: "#081981",
                        backgroundColor: "transparent",
                        minWidth: "32px",
                        marginLeft: "5px",
                      }}
                      className="min-margin"
                    >
                      <img
                        className="policyMonitor"
                        src={ProfileIcon}
                        alt="monitor"
                      />
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      // placement={placement}
                      onClose={handleClosePopOver}
                      transition
                      className="policyMonitor"
                      style={{ boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)" }}
                    >
                      <div className="popper-root monitors">
                        <Fade timeout={350}>
                          <DbusersComponent
                            userApiCall={userApiCall}
                            setUserApiCall={setUserApiCall}
                            selectedUsers={selectedDbUsers}
                            handleSelectUsers={handleDbSelectUsers}
                          />
                        </Fade>
                      </div>
                    </Popover>
                  </div>
                </div>
              )}
              {togglers[1].state === "active" &&
                selectedDbUsers &&
                selectedDbUsers.map((each) => {
                  return (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          paddingBottom: "8px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "inherit" }}>
                          <Avatar
                            className="avatar"
                            alt={each ? each : ""}
                            // src={`data:image/png;base64, ${each.monitorThumbnail.picture}`}
                          />
                          <label style={{ padding: "10px", color: "#081981" }}>
                            {each}
                          </label>
                        </div>
                        {/* {!each.muted && (
                          <IconButtonForPolicyCreateEdit
                            variant="contained"
                            id="delete"
                            size="small"
                            color="primary"
                            disabled={!editable}
                            style={{
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMute(each.monitorThumbnail.id)}
                            className="min-margin"
                          >
                            <img src={UnmuteIcon} alt="unmute" />
                          </IconButtonForPolicyCreateEdit>
                        )}
                        {each.muted && (
                          <IconButtonForPolicyCreateEdit
                            variant="contained"
                            id="delete"
                            size="small"
                            color="primary"
                            disabled={!editable}
                            style={{
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMute(each.monitorThumbnail.id)}
                            className="min-margin"
                          >
                            <img src={MuteIcon} alt="mute" />
                          </IconButtonForPolicyCreateEdit>
                        )} */}
                      </div>
                    </React.Fragment>
                  );
                })}

              {togglers[2].state === "active" && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* <p style={{ fontSize: "14px" }}>Monitors:</p> */}
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleMonitorClick("left")}
                      style={{
                        color: "#081981",
                        backgroundColor: "transparent",
                        minWidth: "32px",
                        marginLeft: "5px",
                      }}
                      className="min-margin"
                    >
                      <img
                        className="policyMonitor"
                        src={ProfileIcon}
                        alt="monitor"
                      />
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      // placement={placement}
                      onClose={handleClosePopOver}
                      transition
                      className="policyMonitor"
                      style={{ boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)" }}
                    >
                      <div className="popper-root monitors">
                        <Fade timeout={350}>
                          <DbrolesComponent
                            userApiCall={userApiCall}
                            setUserApiCall={setUserApiCall}
                            selectedUsers={selectedDbRoles}
                            handleSelectUsers={handleDbRolesSelectUsers}
                          />
                        </Fade>
                      </div>
                    </Popover>
                  </div>
                </div>
              )}
              {togglers[2].state === "active" &&
                selectedDbRoles &&
                selectedDbRoles.map((each) => {
                  return (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          paddingBottom: "8px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "inherit" }}>
                          <Avatar
                            className="avatar"
                            alt={each ? each : ""}
                            // src={`data:image/png;base64, ${each.monitorThumbnail.picture}`}
                          />
                          <label style={{ padding: "10px", color: "#081981" }}>
                            {each}
                          </label>
                        </div>
                        {/* {!each.muted && (
                          <IconButtonForPolicyCreateEdit
                            variant="contained"
                            id="delete"
                            size="small"
                            color="primary"
                            disabled={!editable}
                            style={{
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMute(each.monitorThumbnail.id)}
                            className="min-margin"
                          >
                            <img src={UnmuteIcon} alt="unmute" />
                          </IconButtonForPolicyCreateEdit>
                        )}
                        {each.muted && (
                          <IconButtonForPolicyCreateEdit
                            variant="contained"
                            id="delete"
                            size="small"
                            color="primary"
                            disabled={!editable}
                            style={{
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMute(each.monitorThumbnail.id)}
                            className="min-margin"
                          >
                            <img src={MuteIcon} alt="mute" />
                          </IconButtonForPolicyCreateEdit>
                        )} */}
                      </div>
                    </React.Fragment>
                  );
                })}
                </>
            <br />
            {!editable &&
              queryParam.id !== "createPolicy" &&
              queryParam.id !== undefined &&
              permissions.indexOf(viewAlertPolicyComment) > -1 && (
                <>
                  <p style={{ fontSize: "14px" }}>Comments:</p>
                  <CanAllow needs={{permission: [postAlertPolicyComment]}}>
                  <div>
                    <TextField
                      id="outlined-multiline-static"
                      multiline={true}
                      rows={4}
                      value={newComment}
                      onChange={(event) => handleNewComment(event)}
                      placeholder="Add Comments..."
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                    <DeleteButtonForToolBar
                      style={{ float: "right" }}
                      variant="contained"
                      id="delete"
                      size="small"
                      color="primary"
                      onClick={() => handleSaveComment()}
                      className="min-margin"
                    >
                      <DoneIcon />
                    </DeleteButtonForToolBar>
                  </div>
                  </CanAllow>
                  <div className="comments-list-arr">
                    {alertPolicyComments.map((field, index) => (
                      <div className="comments-list">
                        <div className="user-info">
                          <span className="user-icon">
                            <Avatar
                              className="avatar"
                              id={index}
                              alt={field.name}
                              elevation={2}
                              src={field.commentedBy.picture}
                            />
                          </span>
                          <span className="name-time">
                            <div>{field.commentedBy.name}</div>
                            <div className="date-time">
                              {getTime(field.commentedOn)}
                            </div>
                          </span>
                        </div>
                        <div className="comments-text">{field.comments}</div>
                      </div>
                    ))}
                  </div>
                </>
              )}
          </Grid>
        </Grid>
        <Snackbar
          //key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          onExited={handleExited}
          //message={messageInfo ? messageInfo.message : undefined}
        >
          <Alert onClose={handleCloseSnackbar} severity={"error"}>
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}
