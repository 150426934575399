import React, { useState } from "react";
import {isEmpty} from "lodash"
import DOMPurify from 'dompurify';
//var Enum = require('enum');
import {
  NON_CLASSIFIED,
  NOT_PROCESSED,
  ONEDRIVE,
  POSTGRESQL,
  MSSQL,
  SQLSERVER,
  MYSQL,
  DROPBOX,
  updateTenant,
  generateReport,
  fetchReport,
  viewDataSource,
  NON_WORK_HOUR_POLICY,
  AMAZON_RDS_SQLSERVER,
  AMAZON_RDS_POSTGRESQL,
  GOOGLEDRIVE,
  DATA_SOURCES_CODES,
  DATA_SOURCE_TYPE_CODES,
  AWS_FILE_SYSTEM,
  CLOUD_SQL_SYSTEM,
} from "../../redux/constants/constants";
import { Chart } from "react-google-charts";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import cloudSm from "../../assets/icons/cloud-sm-ff.svg";
import dbLinksSm from "../../assets/icons/dblinks-sm-ff.svg";
import userMapIcon from "../../assets/icons/user_map_icon.svg";
import EditIcon from '../../assets/vizr_images/editTenant.svg'
import store from '../../redux/store/store';
import TenantProfile from "./TenantProfile";
import { Modal } from "@material-ui/core";
import CanAllow from "../shared/CanAllow";
import { 
  isSameMinute, 
  addDays,
  subDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  subMinutes,
  subHours,
  format,
  set, 
  subMonths,
  subYears} from "date-fns";
import moment from "moment";
import { AppBar, Box, Tabs, Typography } from 'material-ui-core';
import PropTypes from 'prop-types';
import Tab from "@material-ui/core/Tab";
import AlarmIcon from "../../assets/vizr_images/access_alarm.svg";
import RedAlarmIcon from "../../assets/vizr_images/access_alarm_red.svg";

export function getOS() {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function getItem(key) {
  return localStorage.getItem(key);
}

export function getFullName(firstName, lastName) {
  return `${firstName} ${lastName}`;
}

export function setItem(key, value) {
  localStorage.setItem(key, value);
  return true;
}

export function removeItem(key) {
  localStorage.removeItem(key);
  return true;
}

export function clearStorage() {
  localStorage.clear();
  return true;
}

export const getTime = (dateValue) => {
  var options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  let temp = new Date(dateValue);
  let tem = temp.toLocaleDateString("en-US", options);
  tem = tem.replace(",", "");
  tem = tem.replaceAll("/", "-");
  return `${tem}`;
};

export const getTimeForToolTip = (dateValue) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let temp = new Date(dateValue);
  let tem = temp.toLocaleDateString("en-US", options);
  tem = tem.replace(",", "");
  tem = tem.replaceAll("/", "-");
  return `${tem}`;
};

export const getSummaryTime = (dateValue) => {
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let temp = new Date(dateValue);
  let tem = temp.toLocaleDateString("en-US", options);
  tem = tem.replace(",", "");
  tem = tem.replaceAll("/", "-");
  return `${tem}`;
};

export function commonHeaders(token, codeValue) {
  return {
    "Content-type": "application/json",
    Authorization: token,
    code: codeValue,
  };
}

export function firstLetterCap(value) {
  return `${value[0].toUpperCase()}${value.slice(1)}`;
}

const filterQueryGen = (dimensionName, dimensionType, operator, values) => {
  return {
    dimension: `${dimensionName}.${dimensionType}`,
    operator: operator,
    values: values,
  };
};

export const getAppDateTimeRange = () => {
  const appDateTimeRange = localStorage.getItem("appDateTimeRange");
  if (appDateTimeRange) {
    const returnDates = {
      startDate: JSON.parse(appDateTimeRange).startDate,
      endDate: JSON.parse(appDateTimeRange).endDate,
    };
    return returnDates;
  } else {
    return null;
  }
};

export const cubeQueryGen = (
  dimensionName,
  dimensionType,
  filtersList,
  selectedDateTimeRange
) => {
  const queryString = {
    dimensions: [],
    timeDimensions: [],
    order: {},
    filters: [],
  };

  if (dimensionType.length) {
    dimensionType.forEach((type) => {
      queryString.dimensions.push(`${dimensionName}.${type}`);
    });
  }
  const appDateTimeRange = selectedDateTimeRange;
  if (appDateTimeRange) {
    let gte = String(Date.parse(JSON.parse(appDateTimeRange).startDate));
    let lte = String(Date.parse(JSON.parse(appDateTimeRange).endDate));
    const lteFilter = filterQueryGen(dimensionName, "creationTime", "lte", [
      lte,
    ]);
    const gteFilter = filterQueryGen(dimensionName, "creationTime", "gte", [
      gte,
    ]);
    queryString.filters.push(lteFilter);
    queryString.filters.push(gteFilter);
  }
  //equals
  if (filtersList && filtersList.length) {
    filtersList.forEach((list) => {
      const filGen = filterQueryGen(
        list.dimensionName,
        list.dimensionType,
        list.operator,
        list.values
      );
      queryString.filters.push(filGen);
    });
  }

  return queryString;
};

export const cubeQueryGenCount = (
  dimensionName,
  dimensionType,
  filtersList
) => {
  const queryString = {
    dimensions: [],
    timeDimensions: [],
    order: {},
    filters: [],
    measures: [],
  };

  if (dimensionType.length) {
    dimensionType.forEach((type) => {
      queryString["measures"].push(`${dimensionName}.${type}`);
    });
  }
  
  //equals
  if (filtersList && filtersList.length) {
    filtersList.forEach((list) => {
      const filGen = filterQueryGen(
        list.dimensionName,
        list.dimensionType,
        list.operator,
        list.values
      );
      queryString.filters.push(filGen);
    });
  }

  return queryString;
};

export function getPositionOfNode(arr, id) {
  let found = arr.filter((each) => each.node === id);
  if (found) {
    return found;
  } else {
    return null;
  }
}

export function checkArray(arr, id) {
  let bool = false;
  arr.forEach((each) => {
    if (each.node === id) {
      bool = true;
    }
  });
  return bool;
}

export async function getSpanDbStatements(
  selectedNode,
  fetchDMContentTabChildNodes,
  cubejsApi,
  redirectToLogin,
  setSpanDBStatements
) {
  let responseObj = {
    apiSuccess: false,
    data: [],
  };
  let nodes = [];
  const queryType = "multi";
  const nodeQuery = {
    dimensions: ["Nodes.nodeId"],
    timeDimensions: [],
    order: {},
    filters: [],
  };
  if (selectedNode) {
    nodeQuery.filters.push({
      member: "Nodes.parentId",
      operator: "equals",
      values: [selectedNode],
    });
  }
  const nodesResponse = await fetchDMContentTabChildNodes(nodeQuery, queryType);
  if (nodesResponse) {
    //setAllFieldsData(nodesResponse.results[0].data)
    nodes.push(selectedNode);
    nodesResponse.length &&
      nodesResponse.map((each) => {
        nodes.push(each[`Nodes.nodeId`]);
      });
    const queryString = {
      dimensions: ["SpanStatements.sqlStatement"],
      filters: [
        {
          member: "SpanStatements.nodeId",
          operator: "equals",
          values: nodes,
        },
      ],
    };
    let response;
    try {
      const resultSet = await cubejsApi.load(queryString);
      response = resultSet.rawData();
    } catch (error) {
      redirectToLogin(error);
      // const response = await fetchDMContentTabDatabaseManagementFields(queryString, queryType);
    }
    if (response && response.length) {
      responseObj.apiSuccess = true;
      responseObj.data = response;
    } else {
      responseObj.apiSuccess = true;
    }
    setSpanDBStatements(responseObj);
  } else {
    responseObj.apiSuccess = true;
    setSpanDBStatements(responseObj);
  }
}

export function checkIfEmpty(data, toRender) {
  return (data && data.length > 0) ?
            toRender :
            <span className="textAlignCenter">-</span>;
}

export function checkIfEmptyString(inputString) {
  return (isEmpty(inputString))? "-" : inputString; 
}

export function sanitizeDOMString(dirty) {
  //sanitize the data to allow only html string where dangerousHTML is used
  return DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true } });
}

export function getFilterTimeStamp(dateTimeRange) {
  let startDt = 0;
  let endDt = 100000000; // dateFormatter(endDate);
  const d = new Date();
  const diff = d.getTimezoneOffset();
  if (dateTimeRange) {
    const startDate = JSON.parse(dateTimeRange).startDate;
    const endDate = JSON.parse(dateTimeRange).endDate;
    startDt = new Date(startDate).getTime() - diff * 60 * 1000;
    endDt = new Date(endDate).getTime() - diff * 60 * 1000;
  }
  return {startDt, endDt};
}
export function isOneDriveDataSource(datasourceType) {
  return datasourceType === ONEDRIVE;
}
export const statusActive = () => {
  return (
    <div title="Account Status: Active" className="side-icons" >
      <CheckCircleIcon
        className="icon-status connected"
        fontSize="small" />
    </div>
  );
}

export const statusInActive = () => {
  return (
    <div title="Account Status: In Active" className="side-icons">
      <CheckCircleIcon
        className="icon-status not-connected" 
        fontSize="small" />
    </div>
  );
}

export const getConnectedStatus = () => {
  return (
    <div title="Connected">
        <CheckCircleIcon
          className="icon-status connected" />
      </div>
  )
};

export const getInProgressStatus = () => {
  return (
    <div title="In Progress">
        <CheckCircleIcon
          className="icon-status in-progress" />
      </div>
  )
};

export const getNotConnectedStatus = () => {
  return (
    <div title="Not Connected">
      <CheckCircleIcon
        className="icon-status not-connected"  />
    </div>
  )
};

export const getErrorStatus = () => {
  return (
    <div title="Error">
      <CancelIcon
       className="icon-status error" />
    </div>
  )
}
const nullCheck = (inputVal, key) => {
  if (inputVal && inputVal.length) {
    return inputVal[0][key]
  }
  return 0;
}

export const frameTooltipData = (nodeTooltipData) => {
  if (nodeTooltipData) {
    const alertToolTipData = { 
      alert_count_high: nullCheck(nodeTooltipData.response[0].NodeDetails.alert_details.filter((a)=>a.severity_code === 'HIGH'), 'alert_count'),
      alert_count_medium: nullCheck(nodeTooltipData.response[0].NodeDetails.alert_details.filter((a)=>a.severity_code === 'MEDIUM'), 'alert_count'),
      alert_count_low: nullCheck(nodeTooltipData.response[0].NodeDetails.alert_details.filter((a)=>a.severity_code === 'LOW'), 'alert_count'),
    };
    const sensitiveData = {
      sensitivity_count_high: nullCheck(nodeTooltipData.response[0].NodeDetails.si_details.filter((a)=>a.category === 'HIGH'), 'si_count'),
      sensitivity_count_low: nullCheck(nodeTooltipData.response[0].NodeDetails.si_details.filter((a)=>a.category === 'LOW'), 'si_count'),
      sensitivity_count_medium: nullCheck(nodeTooltipData.response[0].NodeDetails.si_details.filter((a)=>a.category === 'MEDIUM'), 'si_count'),
      sensitivity_count_not_processed: nullCheck(nodeTooltipData.response[0].NodeDetails.si_details.filter((a)=>a.category === 'NOT_PROCESSED'), 'si_count'),
    }
    sensitiveData['total_sensitivity_count'] = sensitiveData.sensitivity_count_high + sensitiveData.sensitivity_count_low + sensitiveData.sensitivity_count_medium + sensitiveData.sensitivity_count_not_processed;
    return { alertToolTipData, sensitiveData }
  }
  return { alertToolTipData:null, sensitiveData:null }
}

export const totalCount = (arr) => {
  let sum = arr.reduce((acc, obj) => acc + obj.count, 0);
  return sum;
}
export const percentageCalculation = (values, total_count) => {
  // The logic is based on LRM (Largest Remainder Method)
  // Take the nearest integer values
  // Get the decimal part and arrange them in descending order
  const floorValues = values
    .map((item) => {
      let percentageVal = (item.count / total_count) * 100;
      return {
        ...item,
        unRoundPercentage: percentageVal.toFixed(2),
        count: Math.floor(percentageVal),
        decimal: percentageVal % 1,
      };
    })
    .sort((a, b) => b.decimal - a.decimal);

  // Get the total count
  let sum = totalCount(floorValues)
  
  // Check the remaining value
  const remaining = 100 - sum;

  // Add the remaining value to the number with the largest decimal number
  // Do it until the remaining value is distributed
  for (let i = 0; i < remaining; i++) {
    if (i < floorValues.length) {
      floorValues[i].count++;
    }
  }

  return floorValues;
};

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#FFFFFF",
      "  & + .MuiSwitch-track": {
        backgroundColor: "#6EC531",
        opacity:"1"
      }
    }
  },
  "& .MuiSwitch-track": {
    backgroundColor: "red",
    opacity:"1",
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}"></path> </svg>')`,
      top:"44%",
      backgroundSize:"20px",
      right: 14
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2
  }
}));

export const isDataSourceActive = (row) => {
  if (row === 'Active') {
    return true;
  } else {
    return false;
  }
};
export const errorSnackbar = (open, handleClose, errorMessage, status) => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={status}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};
export const activeRoutes = (location, path, icon, activeIcon) => {
  if(location && location.pathname.indexOf(path) > -1 && activeIcon){
    return activeIcon;
  } else {
    return icon;
  }
}
export const renderSensitivityValue = (value) => {
  if (value === NOT_PROCESSED) {
    return NON_CLASSIFIED;
  } else {
    return value;
  }
};

export const icons = {
  ONEDRIVE: '/dataSourceIcons/oneDrive_color.svg',
  POSTGRESQL:  '/dataSourceIcons/postgre.svg',
  MSSQL: '/dataSourceIcons/mssql.svg',
  SQLSERVER: '/dataSourceIcons/mssql.svg',
  MYSQL: '/dataSourceIcons/mysql.svg',
  DROPBOX: '/dataSourceIcons/dropBoxLogo.svg',
  GOOGLEDRIVE: '/dataSourceIcons/GoogleDriveIcon.svg',
  "APPLICATION": '/dataSourceIcons/ApiColor.svg',
  "DATASOURCE": '/dataSourceIcons/DatabaseColor.svg',
  "WEB_SERVER": cloudSm,
  "FILEBEATS_DATABASE": dbLinksSm,
  "USER": userMapIcon,
  "OTHER": '/dataSourceIcons/DatabaseColor.svg',
};
export const renderDataMapIcons = (subType, location) => {

  const type = subType.type.code;

  if (icons[subType.code]) {
    // If the icon exists in the icons object, return it directly
    return icons[subType.code];
  }

  //To compensate the below logos if we don't have the particular logos
  if (location === "DataMapMain" && type) {
    if (icons[type]) {
      return icons[type];
    };
  }

  // If no match is found, return null
  return null;
};

export const sourceIcons = (sourceName) => {
  if (!isEmpty(sourceName)) {
    if (DATA_SOURCES_CODES.includes(sourceName.code)) {
      return icons[sourceName.code];
    } else if (
      sourceName.type && 
      DATA_SOURCE_TYPE_CODES.includes(sourceName.type.code)
    ) {
      return icons[sourceName.type.code];
    }
  }
  return null;
};

// Function to render the Chart component
const renderChart = (data, options) => (
  <div onClick={(e) => e.stopPropagation()}>
    <Chart
      width={"70px"}
      height={"100%"}
      chartType="PieChart"
      loader={<div>Loading Chart</div>}
      data={data}
      options={options}
    />
  </div>
);

export const renderSmallDonut = (pieData) => {
  // Options for the donut chart
  let options = {
    pieHole: 0.5,
    legend: "none",
    chartArea: { left: "0%", width: "100%" },
    colors: [],
    pieSliceText: "none",
    backgroundColor: 'transparent',
  };

  // Check if pieData is empty
  if (pieData.length === 0) {
    // Render full chart with grey color
    let emptyData = [["X", "Y"]];
    // Add single data item to emptyData array
    emptyData.push(["No Data", 1]);
    // Add grey color to options.colors array
    options.colors.push('#8A8D93');

    return renderChart(emptyData, options);
  }

  // Prepare data for the chart
  let projectedData = [["X", "Y"]];
  pieData.forEach((e) => {
    let pDataItem = {
      text: e.text,
      count: e.count,
      color: e.color
    };
    projectedData.push([pDataItem.text, pDataItem.count]);
    options.colors.push(pDataItem.color);
  });

  return renderChart(projectedData, options);
};

export const userPermission = (tenant, platform) => {
  if (
    window.localStorage.getItem('tenant') !== 'druvstar' &&
    window.localStorage.getItem('tenant') !== 'datavision'
  ) {
    return tenant;
  } else {
    return platform;
  }
};
export const pathWithPermission = (path) => {
  const permissions = store.getState().applicationData.permissions;
  if (path) {
    const permissionsCheckArray = path.permission;
    const hasPermission =
      permissionsCheckArray &&
      permissionsCheckArray.every((permission) =>
        permissions.includes(permission)
      );
    if (hasPermission) {
      return path;
    }
  } else {
    return null;
  }
};

// Returns true if the sourceType is a cloud file system, otherwise returns false.
export const isCloudFileSystem = (sourceType) => {
  return DATA_SOURCES_CODES.includes(sourceType)
}

export const isAWSFileSystem = (sourceType) => {
  return AWS_FILE_SYSTEM.includes(sourceType);
}
export const isCloudSQLSystem = (sourceType) => {
  return CLOUD_SQL_SYSTEM.includes(sourceType);
};
export const renderDataSource = (dataSourceType, dataSourceName) => {
  const icon = icons[dataSourceType];
  return (
    <div className="dataSource-name-icon">
      {dataSourceName && <div>{dataSourceName}</div>}
      {icon && (
        <div className="dataSource-icon">
          <img
            src={icon}
            alt="Data Source icon"
            title={dataSourceType}
            className={'dataSource-icon-img'}
          />
        </div>
      )}
    </div>
  );
};

export const renderTenantLogo = () => {
  const tenantLogo = localStorage.getItem('tenantLogo');
  const tenantName = JSON.parse(localStorage.getItem('tenantName'));
  const file = tenantLogo && !isEmpty(tenantLogo) && `data:image/png;base64,${tenantLogo}`;
  return (
    <div className="tenantProfileLogoBar-container">
      {file ? (
        <div className="tenantProfileLogoBar-container">
          <img src={file} alt="logo" className="tenantProfileLogoBar" />
        </div>
      ) : (
        <>
          {tenantName && (
            <div className="userName">
              {tenantName.split(" ").map((el) => {
                return el[0];
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const editTenant = (
  editTenantProfile,
  handleEditTenant,
  open,
  anchorEl
) => {
  const tenantName = JSON.parse(localStorage.getItem("tenantName"));
  return (
    <>
      {tenantName && (
        <CanAllow needs={{ permission: [updateTenant] }}>
          <img
            className="editTenant"
            src={EditIcon}
            alt="editIcon"
            onClick={(event) => editTenantProfile(event, true)}
          />
          <Modal
            open={open}
            anchorEl={anchorEl}
            className="tenantProfilePopper"
          >
            <TenantProfile handleEditTenant={handleEditTenant} />
          </Modal>
        </CanAllow>
      )}
    </>
  );
};
//Date range picker static ranges

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
  past0Minutes: subMinutes(new Date(), 0),
  past15Minutes: subMinutes(new Date(), 15),
  past5Minutes: subMinutes(new Date(), 5),
  past30Minutes: subMinutes(new Date(), 30),
  past0Hour: subHours(new Date(), 0),
  past1Hour: subHours(new Date(), 1),
  past3Hours: subHours(new Date(), 3),
  past1Day: subDays(new Date(), 1),
  past3Days: subDays(new Date(), 3),
  past3Months: subMonths(new Date(), 3),
  past6Months: subMonths(new Date(), 6),
  past1Year: subYears(new Date(), 1),
  currentDate: new Date(),
  after10Days: addDays(new Date(), 10),
  after20Days: addDays(new Date(), 20),
  after30Days: addDays(new Date(), 30),
  after6Months: addDays(new Date(), 90),
  after1Year: addDays(new Date(), {
    years: 1,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  }),
  after2Years: addDays(new Date(), {
    years: 2,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  }),
};

/**
 * dateRangePickerStaticList function generates an array of static date range presets
 * based on the specified location ('global' or other).
 *
 * @param {string} loc - The location identifier ('global' or other).
 * @returns {Array} An array of date range presets with label and range functions.
 */

export const dateRangePickerStaticList = (loc) => {
  // Check if the location is 'global'
  if (loc === "global") {
    // Define custom date range presets for global location
    const customDateObjects = [
      {
        label: "Past 5 Minutes",
        range: () => ({
          startDate: defineds.past5Minutes,
          endDate: defineds.past0Minutes,
        }),
      },
      {
        label: "Past 15 Minutes",
        range: () => ({
          startDate: defineds.past15Minutes,
          endDate: defineds.past0Minutes,
        }),
      },
      {
        label: "Past 30 Minutes",
        range: () => ({
          startDate: defineds.past30Minutes,
          endDate: defineds.past0Minutes,
        }),
      },
      {
        label: "Past 1 Hour",
        range: () => ({
          startDate: defineds.past1Hour,
          endDate: defineds.past0Hour,
        }),
      },
      {
        label: "Past 3 Hours",
        range: () => ({
          startDate: defineds.past3Hours,
          endDate: defineds.past0Hour,
        }),
      },
      {
        label: "Past 1 Day",
        range: () => ({
          startDate: defineds.past1Day,
          endDate: defineds.currentDate,
        }),
      },
      {
        label: "Past 3 Days",
        range: () => ({
          startDate: defineds.past3Days,
          endDate: defineds.currentDate,
        }),
      },
      {
        label: "Past 7 Days",
        range: () => ({
          startDate: defineds.startOfLastSevenDay,
          endDate: defineds.currentDate,
        }),
      },
      {
        label: "Past 1 Month",
        range: () => ({
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
        }),
      },
    ];
    return customDateObjects; 
  } else {
    // Define custom date range presets for other locations
    const customDateObjects = [
      {
        label: "Past 1 Day",
        range: () => ({
          startDate: defineds.past1Day,
          endDate: defineds.currentDate,
        }),
      },
      {
        label: "Past 7 Days",
        range: () => ({
          startDate: defineds.startOfLastSevenDay,
          endDate: defineds.currentDate,
        }),
      },
      {
        label: "Past 1 Month",
        range: () => ({
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Past 3 Months",
        range: () => ({
          startDate: defineds.past3Months,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Past 6 Months",
        range: () => ({
          startDate: defineds.past6Months,
          endDate: defineds.endOfToday,
        }),
      },{
        label: "Past 1 Year",
        range: () => ({
          startDate: defineds.past1Year,
          endDate: defineds.endOfToday,
        }),
      },
    ];
    return customDateObjects;
  }
};

/**
 * staticRangeHandler is an object with a predefined range and a method to check
 * if a given range is selected based on minute-level precision.
 */
const staticRangeHandler = {
  range: {}, // Predefined range object
  isSelected(range) {
    // Check if the provided range matches the predefined range at minute-level precision
    const definedRange = this.range();
    return (
      isSameMinute(range.startDate, definedRange.startDate) &&
      isSameMinute(range.endDate, definedRange.endDate)
    );
  },
};

/**
 * createStaticRange function creates an array of objects by combining the
 * staticRangeHandler with the provided range objects.
 *
 * @param {Array} ranges - An array of range objects to be combined with staticRangeHandler.
 * @returns {Array} An array of objects with predefined and custom range configurations.
 */
export const createStaticRange = (ranges) => {
  // Map through the provided ranges and create objects by combining them with staticRangeHandler
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
};


export const removeDuplicates = (permissions) => {
  const permissionKey = [];
  permissions.forEach((permission) => {
    if (!permissionKey.includes(permission.code)) {
      permissionKey.push(permission.code)
    }
  });
  return permissionKey;
};

export const expiryDaysCalculation = (time) => {
  // Create a moment object for the current date and time
  const currentDate = moment(Date.now());

  // Create a moment object for the 'expiration Time' provided as input
  const expiryDate = moment(time);

  // Calculate the remaining days between the current date and the 'expiryDate'
  const remainingDays = moment.duration(expiryDate.diff(currentDate)).asDays();

  return Math.ceil(remainingDays);
}
//Switchable tabs

export const RenderSwitchableTabs = ({tabs, tabValue, handleTabChange}) => {
  return (
    <>
      <div className="switchableTabs">
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab) => {
              return (
                <Tab label={tab}/>
              )
            })}
          </Tabs>
        </AppBar>
      </div>
    </>
  )
}

const updatePathNameBasedOnPermission = (permissions, path) => {
  if (
    (permissions.includes(fetchReport) ||
      permissions.includes(generateReport)) &&
    !permissions.includes(viewDataSource)
  ) {
    return "/tenant/generateReport";
  } else {
    return path;
  }
};

export const updateObjectPathByName = (tempArray, locationPath, permissions) => {
  const systemSummaryPaths = [
    "/tenant/dataOwner",
    "/tenant/reportRepository",
    "/tenant/generateReport",
  ];

  // Find the object in the array with the specified name
  const systemSummaryObj = tempArray.find(
    (each) => each.name === "System Summary"
  );

  // If the specified object is found, modify its path
  if (systemSummaryObj) {
    // Use the systemSummaryPaths array to map conditions
    const matchedPath = systemSummaryPaths.find(
      (path) => locationPath === path
    );
    // Modify the path based on the matched condition or use a default path
    systemSummaryObj.path = matchedPath || updatePathNameBasedOnPermission(permissions, "/tenant/systemSummary");
  }
  // Return the modified array
  return tempArray;
};

export const handleLowPercentage = (count, percentage, value) => {
  if (count > 0 && percentage === 0) {
    return value;
  } else {
    return `${percentage}%`
  }
};

export const lessThanOnePercent = (percentage, value) => {
  if (percentage > 0 && percentage < 1) {
    return value;
  } else {
    return Math.floor(percentage);
  }
}

/**
 * reportGraphScript function generates a JavaScript script string to render
 * pie charts using Google Charts API based on the provided transformedArray.
 *
 * @param {Array} transformedArray - The transformed data array for rendering pie charts.
 * @returns {string} A JavaScript script string to load and render pie charts.
 */
export const reportGraphScript = (transformedArray) => {
  // Load the Google Charts library and define initial variables
  return `google.charts.load('current', {'packages':['corechart']}); var rowData = ${JSON.stringify(
    transformedArray
  )}; google.charts.setOnLoadCallback(function() { rowData.forEach(function(row) { row.data.forEach(function (item) {}); var data = new google.visualization.DataTable(); data.addColumn('string', 'Label'); data.addColumn('number', 'Value'); data.addRows(row.data); var options = Object.assign({}, { width: 70, height: 200, pieHole: 0.5, legend: 'none', pieSliceText: 'none', backgroundColor: 'transparent', chartArea: { left: "0%", width: "100%" }, }, row.options); var chartContainer = document.createElement('div'); chartContainer.style.height = '100%'; chartContainer.style.width = '70px'; var rowContainer = document.getElementById('rowPieChart' + row.id); if (rowContainer) { rowContainer.appendChild(chartContainer); var chartInstance = new google.visualization.PieChart(chartContainer); chartInstance.draw(data, options); } }); });`;
};

//Error message component 
export const errorMessageComp = (errorMessage, loc) => {
  if (!isEmpty(errorMessage)) {
    const errorListData =  errorMessage.split(",");
    const errorList = (
      <>
        <ul className = {`passwordErrorMessage ${loc}`}>
          {errorListData.map((e, index) => (
            <li key={index}>{e}</li>
          ))}
        </ul>
      </>
    );
    return errorList;
  }
};

/**
 * clearURLParam function clears the query parameters from the current URL, updates the browser history,
 * and dispatches an action to handle the state changes related to query parameter clearance.
 *
 * @param {Object} history - The browser history object used for navigation.
 * @param {boolean} status - A boolean value indicating direct data access status.
 */
export const clearURLParam = (history, status, dispatch) => {
  // Check if the history object is not empty
  if (!isEmpty(history)) {
    // Create a new URL object based on the current window location
    const url = new URL(window.location.href);

    // Clear the query parameters from the URL
    url.search = '';

    // Replace the browser history with the modified URL pathname
    history.replace(url.pathname);

    // Dispatch an action to handle state changes related to query parameter clearance
    dispatch({ type: "CLEAR_QUERY_PARAM", payload: { directDataAccess: status } });
  }
};

export const isInactivityPolicy = (policyType) => {
  return policyType && policyType.code === "INACTIVITY";
};

export const applicationNameValidation = (applicationName) => {
  if (isEmpty(applicationName) || isEmpty(applicationName.trim())) {
    return "Application name should not be empty";
  }
  if ( applicationName.includes('_')) {
    return "Application name should not contain underscores.";
  }
};

export const getExpiryDetails = (days) => {
  if (days < 10) {
    return { colorClassName: "expiryDetailsColorRed", icon: RedAlarmIcon };
  } else {
    return { colorClassName: "expiryDetailsColorOrange", icon: AlarmIcon };
  }
};

export const categoryIcons = {
  'ORGANIZATIONAL': "/dataOwnerViewIcons/categoryIcons/organizationalIcon.svg",
  'PERSONAL': "/dataOwnerViewIcons/categoryIcons/personalIcon.svg",
  'API': "/dataOwnerViewIcons/categoryIcons/API_SensitivityIcon.svg",
  'FINANCIAL': "/dataOwnerViewIcons/categoryIcons/financialIcon.svg",
  'MARKETING': "/dataOwnerViewIcons/categoryIcons/marketingIcon.svg",
  'MEDICAL': "/dataOwnerViewIcons/categoryIcons/medicalIcon.svg",
  'OTHER': "/dataOwnerViewIcons/categoryIcons/Other_SensitivityIcon.svg"
};

export const isWorkHourPolicy = (policySubTypeValue) => {
  return policySubTypeValue && policySubTypeValue.subTypeCode === NON_WORK_HOUR_POLICY;
}
export const isMSSQLDatabaseOnCloud = (engine, iconMap) => {
  return engine === MSSQL && (iconMap.AzureServer.selected || iconMap.AWSServer.selected);
}
