import {
  GET_AUTHENTICATION_SOURCE_FILTER,
  APPLY_AUTHENTICATION_FILTER,
} from "../../constants/constants";

export const GET_AUTHENTICATION_SOURCE_FILTER_ACTION = (payload) => {
  return {
    type: GET_AUTHENTICATION_SOURCE_FILTER,
    payload: payload,
  };
};

export const APPLY_AUTHENTICATION_FILTER_ACTION = (payload) => {
  return {
    type: APPLY_AUTHENTICATION_FILTER,
    payload: payload,
  };
};
