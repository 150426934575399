import {
    CHANGE_FILTERBYVALUE_DATABASELOGS,
    UPDATED_DATABASE_LOGS_SELECTED_FILTER_VALUES,
    SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE,
    SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE,
    SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE,
    SERVICE_DATA_LOGS_SORT_CHANGE
  } from "../constants/constants";
  import { isEqual, cloneDeep } from "lodash";
  const currentTime = new Date().getTime();
  const initialState = {
    sortingValue: "",
    orderByValue: "",
    fromTimeValue: "",
    toTimeValue: "",
    dataSourceName: [],
    dataSourceType: [],
    databaseAccessRoles: [],
    sensitivityInfo: [],
    policyNames: [],
    apiDataSourceName: [],
    apiDataSourceType: [],
    apiDatabaseAccessRoles: [],
    apiSensitivityInfo: [],
    apiPolicyNames: [],
    totalCount: null,
    totalCountLogs: null,
    approxCount: null,
    currentPage: 0,
    recordsPerPage: 20,
    currentPage_logDetails:0,
    recordsPerPage_logDetails:20,
    allDatabaseLogs: [],
    alertId: "",
    filterInfo: [],
    loader: false,
    finalDataSourceName: [],
    finalDataSourceType: [],
    finalDatabaseAccessRoles: [],
    finalSensitivityInfo: [],
    finalPolicyNames: [],
    finalapplications:[],
    finalSortingValue: "",
    finalOrderByValue: "",
    finalFromTimeValue: "",
    finalToTimeValue: "",
    finalServiceUsers:[],
    finalAccessStatements:[],
    copyFilterInfo: [],
    serviceAccessLogsDrawer: false,
    ServiceAccessLogsSideDrawerData: {},
    ServiceAccessLogsSideDrawerTableData: {},
    apiDataapplications:[],
    apiDataAccessStatement:[],
    apiDataServiceUsers:[],
    freshLoadTimestamp: currentTime,
  };
  
  export const serviceAccessLogsReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_FILTERBYVALUE_DATABASELOGS: {
        return {
          ...state,
          filterValue: action.payload,
        };
      }
      case UPDATED_DATABASE_LOGS_SELECTED_FILTER_VALUES: {
        return {
          ...state,
          databaseLogsSelectedFilter: [...action.payload],
        };
      }
      case SERVICE_DATA_LOGS_SORT_CHANGE: {
        return {
          ...state,
          sortingValue: action.payload,
        };
      }
      case SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE: {
        return {
          ...state,
          orderByValue: action.payload,
        };
      }
      case SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE: {
        return {
          ...state,
          fromTimeValue: action.payload,
        };
      }
      case SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE: {
        return {
          ...state,
          toTimeValue: action.payload,
        };
      }
      case "DATA_SOURCE_NAME": {
        return {
          ...state,
          dataSourceName: action.payload,
        };
      }
      case "DATA_SOURCE_TYPE": {
        return {
          ...state,
          dataSourceType: action.payload,
        };
      }
      case "DATABASE_ACCESS_ROLES": {
        return {
          ...state,
          databaseAccessRoles: action.payload,
        };
      }
      case "SENSITIVITY_INFO": {
        return {
          ...state,
          sensitivityInfo: action.payload,
        };
      }
      case "POLICY_NAMES": {
        return {
          ...state,
          policyNames: action.payload,
        };
      }
      case "APPLICATIONS":{
        return {
          ...state,
          applications: action.payload,
        }
      }
      case "SET_POLICY_NAMES": {
        const copyOfData = [...state.apiPolicyNames];
        if (!state.apiPolicyNames.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiPolicyNames: temp,
          };
        }
        return {
          ...state,
          apiPolicyNames: copyOfData,
        };
      }
      case "SET_DATA_SOURCE_NAME_SERVICE_ACCESS_LOGS": {
        const copyOfData = [...state.apiDataSourceName];
        if (!state.apiDataSourceName.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDataSourceName: temp,
          };
        }
        return {
          ...state,
          apiDataSourceName: copyOfData,
        };
      }
      case "SET_DATA_SOURCE_TYPE_SERVICE_ACCESS_LOGS": {
        const copyOfData = [...state.apiDataSourceType];
        if (!state.apiDataSourceType.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDataSourceType: temp,
          };
        }
        return {
          ...state,
          apiDataSourceType: copyOfData,
        };
      }
      case "SET_DATABASE_ACCESS_ROLES_SERVICE_ACCESS_LOGS": {
        const copyOfData = [...state.apiDatabaseAccessRoles];
        if (!state.apiDatabaseAccessRoles.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDatabaseAccessRoles: temp,
          };
        }
        return {
          ...state,
          apiDatabaseAccessRoles: copyOfData,
        };
      }
      case "SET_SENSITIVITY_INFO_SERVICE_ACCESS_LOGS": {
        let copyOfData = [...state.apiSensitivityInfo];
        if (action.payload.type && action.payload.type === "object") {
          copyOfData = [...action.payload.value];
          return {
            ...state,
            apiSensitivityInfo: copyOfData,
          };
        }
        if (!state.apiSensitivityInfo.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiSensitivityInfo: temp,
          };
        }
        return {
          ...state,
          apiSensitivityInfo: copyOfData,
        };
      }
      case "SET_SERVICE_ACCESS_LOGS_INFO": {
        return {
          ...state,
          allDatabaseLogs: action.payload.allDatabaseLogs,
          approxCount: action.payload.totalCount,
          loader: false,
        };
      }
      case "FULL_RESET_SERVICE_LOGS": {
        // when ever we are making a new call we should reset
        return {
          ...state,
          allDatabaseLogs: [],
          totalCount: null,
          approxCount: null,
          freshLoadTimestamp: new Date().getTime(),
          currentPage: 0,
        }
      }
      case "SET_SERVICE_ACCESS_LOGS_COUNT": {
        return {
          ...state,      
          totalCount: action.payload.totalCount,
          loader: false,
        };
      }
      case "SERVICE_ACCESS_LOGS_SET_CURRENT_PAGE": {
        return {
          ...state,
          currentPage: action.payload,
          loader: true,
        };
      }
      case "SERVICE_ACCESS_LOGS_SET_RECORDS_PAGE": {
        return {
          ...state,
          recordsPerPage: action.payload,
          loader: true,
        };
      }
      case "SERVICE_ACCESS_LOGS_SET_CURRENT_PAGE_LOGS_DETAILS": {
        return {
          ...state,
          currentPage_logDetails: action.payload,
          // loader: true,
        };
      }
      case "SERVICE_ACCESS_LOGS_SET_RECORDS_PAGE_LOGS_DETAILS": {
        return {
          ...state,
          recordsPerPage_logDetails: action.payload,
          // loader: true,
        };
      }
      case "SERVICE_ACCESS_LOGS_CLEAR_PAGINATION": {
        return{
        ...state,
        currentPage_logDetails: 0,
        };
      }
      case "SERVICE_ACCESS_LOGS_SET_ALERT_ID": {
        return {
          ...state,
          alertId: action.payload,
        };
      }
      case "DATABASE_LOGS_CLEAR_ALL_SERVICE_DATA_ACCESSES": {
        return {
          ...initialState,
        };
      }
      case "SET_APPLY_DATA_SERVICE_ACCESS_LOGS": {
        return {
          ...state,
          currentPage: 0,
          loader: true,
          finalDataSourceName: cloneDeep(state.apiDataSourceName),
          finalDataSourceType: cloneDeep(state.apiDataSourceType),
          finalDatabaseAccessRoles: cloneDeep(state.apiDatabaseAccessRoles),
          finalSensitivityInfo: cloneDeep(state.apiSensitivityInfo),
          finalPolicyNames: cloneDeep(state.apiPolicyNames),
          finalSortingValue: cloneDeep(state.sortingValue),
          finalOrderByValue: cloneDeep(state.orderByValue),
          finalFromTimeValue: cloneDeep(state.fromTimeValue),
          finalToTimeValue: cloneDeep(state.toTimeValue),
          finalapplications:cloneDeep(state.apiDataapplications),
          finalAccessStatements:cloneDeep(state.apiDataAccessStatement),
          finalServiceUsers:cloneDeep(state.apiDataServiceUsers),
          copyFilterInfo: cloneDeep(state.filterInfo),
        };
      }
     
      case "MAINTAIN_SINGLE_DATA_SERVICE_ACCESS_LOGS": {
        let copyOfData = [...state.filterInfo];
        let present = false;
        for (let i = 0; i < copyOfData.length; i++) {
          if (copyOfData[i].name === action.payload.name) {
            present = true;
            break;
          }
        }
        if (!present) {
          return {
            ...state,
            filterInfo: [...copyOfData, { ...action.payload }],
          };
        } else {
          const temp = copyOfData.filter(
            (each) => each.name !== action.payload.name
          );
          return {
            ...state,
            filterInfo: temp,
          };
        }
      }
      case "SERVICE_ACCESS_LOGS_CLEAR_ON_CLOSE": {
        let apiDataSourceName = state.apiDataSourceName;
        let filterInfo = state.filterInfo;
        let apiDataSourceType = state.apiDataSourceType;
        let apiDatabaseAccessRoles = state.apiDatabaseAccessRoles;
        let apiSensitivityInfo = state.apiSensitivityInfo;
        let apiPolicyNames = state.apiPolicyNames;
        let fromTimeValue = state.fromTimeValue;
        let toTimeValue = state.toTimeValue;
        let sortingValue = state.sortingValue;
        let orderByValue = state.orderByValue;
        let apiDataapplications= state.apiDataapplications;
        let apiDataAccessStatement = state.apiDataAccessStatement;
        let apiDataServiceUsers = state.apiDataServiceUsers
        if (
          !isEqual(
            state.finalDataSourceName.sort(),
            state.apiDataSourceName.sort()
          )
        ) {
          apiDataSourceName = cloneDeep(state.finalDataSourceName);
        }
        if (!isEqual(state.copyFilterInfo, state.filterInfo)) {
          filterInfo = cloneDeep(state.copyFilterInfo);
        }
        if (
          !isEqual(
            state.finalDataSourceType.sort(),
            state.apiDataSourceType.sort()
          )
        ) {
          apiDataSourceType = cloneDeep(state.finalDataSourceType);
        }
        if (
          !isEqual(
            state.finalDatabaseAccessRoles.sort(),
            state.apiDatabaseAccessRoles.sort()
          )
        ) {
          apiDatabaseAccessRoles = cloneDeep(state.finalDatabaseAccessRoles);
        }
        if (
          !isEqual(
            state.apiSensitivityInfo.sort(),
            state.finalSensitivityInfo.sort()
          )
        ) {
          apiSensitivityInfo = cloneDeep(state.finalSensitivityInfo);
        }
        if (
          !isEqual(state.finalPolicyNames.sort(), state.apiPolicyNames.sort())
        ) {
          apiPolicyNames = cloneDeep(state.finalPolicyNames);
        }
        if (state.fromTimeValue !== state.finalFromTimeValue) {
          fromTimeValue = cloneDeep(state.finalFromTimeValue);
        }
        if (state.toTimeValue !== state.finalToTimeValue) {
          toTimeValue = cloneDeep(state.finalToTimeValue);
        }
        if (state.sortingValue !== state.finalSortingValue) {
          sortingValue = cloneDeep(state.finalSortingValue);
        }
        if (state.orderByValue !== state.finalOrderByValue) {
          orderByValue = cloneDeep(state.finalOrderByValue);
        }
        if (
          !isEqual(
            state.finalapplications.sort(),
            state.apiDataapplications.sort()
          )
        ) {
          apiDataapplications = cloneDeep(state.finalapplications);
        }
        if (
          !isEqual(
            state.finalAccessStatements.sort(),
            state.apiDataAccessStatement.sort()
          )
        ) {
          apiDataAccessStatement = cloneDeep(state.finalAccessStatements);
        }
        if (
          !isEqual(
            state.finalServiceUsers.sort(),
            state.apiDataServiceUsers.sort()
          )
        ) {
          apiDataServiceUsers = cloneDeep(state.finalServiceUsers);
        }
        return {
          ...state,
          apiDataSourceName: apiDataSourceName,
          filterInfo: filterInfo,
          apiDataSourceType: apiDataSourceType,
          apiDatabaseAccessRoles: apiDatabaseAccessRoles,
          apiSensitivityInfo: apiSensitivityInfo,
          apiPolicyNames: apiPolicyNames,
          fromTimeValue: fromTimeValue,
          toTimeValue: toTimeValue,
          sortingValue: sortingValue,
          orderByValue: orderByValue,
          apiDataapplications:apiDataapplications,
          apiDataAccessStatement:apiDataAccessStatement,
          apiDataServiceUsers:apiDataServiceUsers
        };
      }
      case "SERVICE_ACCESS_LOGS_CLEAR_FILTER_DATA": {
        return {
          ...state,
          apiDataSourceName: [],
          filterInfo: [],
          apiDataSourceType: [],
          apiDatabaseAccessRoles: [],
          apiSensitivityInfo: [],
          apiPolicyNames: [],
          apiDataapplications: [],
          apiDataAccessStatement: [],
          apiDataServiceUsers: [],
          currentPage: 0,
          alertId: "",
          loader: true,
          fromTimeValue: "",
          toTimeValue: "",
          sortingValue: "",
          orderByValue: "",
          finalDataSourceName: [],
          finalDataSourceType: [],
          finalDatabaseAccessRoles: [],
          finalSensitivityInfo: [],
          finalPolicyNames: [],
          finalapplications: [],
          finalSortingValue: "",
          finalOrderByValue: "",
          finalFromTimeValue: "",
          finalToTimeValue: "",
          copyFilterInfo: [],
          finalapplications: [],
          finalAccessStatements: [],
          finalServiceUsers: []
        };
      }
  
      case "SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE_ON_MOUNT": {
        
        return {
          ...state,
          finalFromTimeValue: action.payload,
        };
      }
  
      case "SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE_ON_MOUNT": {
        return {
          ...state,
          finalToTimeValue: action.payload,
        };
      }

      case "SET_LOADER_INFO": {
        return {
          ...state,
          loader: action.payload,
        };
      }
  
      case "SET_DATABASE_SENSITIVITY_LEVEL": {
        return {
          ...state,
          finalSensitivityInfo: action.payload,
          copyFilterInfo: cloneDeep(state.filterInfo),
        };
      }
      case "SERVICE_DATA_ACCESSES_LOGS_OPEN_ACCESS_DRAWER": {
        return {
          ...state,
          serviceAccessLogsDrawer: true,
          ServiceAccessLogsSideDrawerData: action.payload,
         
        };
      }
      case "SERVICE_DATA_ACCESSES_LOGS_OPEN_ACCESS_DRAWER_GET_TABLE_DATA": {
        return {
          ...state,
          ServiceAccessLogsSideDrawerTableData: action.payload.serviceAccessDrawerLogs,
          totalCountLogs: action.payload.totalCountLogs,
        };
      }
      case "SERVICE_DATA_ACCESSES_LOGS_OPEN_ACCESS_DRAWER_LOADER": {
        return {
          ...state,
          serviceAccessLogsDrawerLoader: action.payload,
        };
      }
      case "CLOSE_ACCESS_DRAWER_SERVICE_LOGS_DATA_ACCESS": {
        return {
          ...state,
          serviceAccessLogsDrawer: false,
          ServiceAccessLogsSideDrawerData: {},
        };
      }
      case "SET_APPLICATION_DATA_SERVICE_ACCESS_LOGS":{
        const copyOfData = [...state.apiDataapplications];
        if (!state.apiDataapplications.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDataapplications: temp,
          };
        }
        return {
          ...state,
          apiDataapplications: copyOfData,
        };
      }
      case "SET_ACCESS_STATEMENT_SERVICE_ACCESS_LOGS":{
        const copyOfData = [...state.apiDataAccessStatement];
        if (!state.apiDataAccessStatement.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDataAccessStatement: temp,
          };
        }
        return {
          ...state,
          apiDataAccessStatement: copyOfData,
        };
      }
      case "SET_SERVICE_USER_SERVICE_ACCESS_LOGS" :{
        const copyOfData = [...state.apiDataServiceUsers];
        if (!state.apiDataServiceUsers.includes(action.payload)) {
          copyOfData.push(action.payload);
        } else {
          const temp = copyOfData.filter((each) => each !== action.payload);
          return {
            ...state,
            apiDataServiceUsers: temp,
          };
        }
        return {
          ...state,
          apiDataServiceUsers: copyOfData,
        };
      }
      default:
        return state;
    }
  };
  