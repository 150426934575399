import { ADD_VULNERABILITY, UPDATE_VULNERABILITY, DELETE_VULNERABILITY } from "../riskPilotConstant";

export const addVulnerability = (vulnerability) => ({
  type: ADD_VULNERABILITY,
  payload: vulnerability,
});

export const updateVulnerability = (vulnId, threatId, updates) => ({
  type: UPDATE_VULNERABILITY,
  payload: { vulnId, threatId, updates },
});

export const deleteVulnerability = (vulnId) => ({
  type: DELETE_VULNERABILITY,
  payload: vulnId,
});
