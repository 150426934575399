import { GET_ALL_DATAFLOW_GROUPS, GET_ALL_DATAFLOW_GROUPS_URL} from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function getAllDataFlowGroups() {
    const options = {
        method: "GET",
        url: `${GET_ALL_DATAFLOW_GROUPS_URL}`,
        headers: getAxiosHeaders(true)
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};
