import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../signUpPages/Navbar";
import Sidebar from "../../common/Sidebar/Sidebar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";


const SignUpBackGround = ({
  headerName,
  headerText,
  headerClassName,
  headerClassName2,
  breadcrumbValue,
  header,
  type,
  classname,
}) => {
  const { backgroundColor, setBackgroundColor } = useState("blue");
  const [expand, setExpand] = useState(true);

  const handleShrink = () => {
    setExpand(!expand);
  };
  return (
    <React.Fragment>
      {!breadcrumbValue && headerName !== "Tenant Management" && (
        <>
          <Navbar></Navbar>
          <Sidebar
            routes={[]}
            bgColor={backgroundColor}
            expand={expand}
            handleShrink={handleShrink}
            isSignuppage="true"
          />
        </>
      )}

      {breadcrumbValue && breadcrumbValue.length > 0 ? (
        <div
          className={
            type === "marginRequired"
              ? "signup_header signup_header_margin_breadCrumbs"
              : "signup_header"
          }
        >
          <div className="signup_header-wrapper">
            <>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbValue.map((each, index) => {
                  return (
                    <>
                      <Link color="inherit" key={index} to={each.link}>
                        {index !== breadcrumbValue.length - 1 && each.value}
                      </Link>
                      {index === breadcrumbValue.length - 1 && (
                        <Typography color="textPrimary">
                          {each.value}
                        </Typography>
                      )}
                    </>
                  );
                })}
              </Breadcrumbs>
              <div className="onboarding-text">{header}</div>
            </>
          </div>
        </div>
      ) : (
        <div
          className={
            type === "marginRequired"
              ? "signup_header signup_header_margin"
              : "signup_header"
          }
        >
          <div
            className="signup_header-wrapper"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="onboarding-text">{headerName}</div>
            <p className={headerClassName2}>{headerText}</p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SignUpBackGround;
