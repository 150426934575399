import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InactiveIcon from "../../assets/vizr_images/Group 3.png";
import MuiAlert from "@material-ui/lab/Alert";
import { Typography, Grid, Breadcrumbs, Link } from "@material-ui/core";
import DataMapFlowGroup from "../shared/DataMapFlowGroup";
import {
  fetchDMNodesList,
  fetchDMNodesByCubeNodes,
  fecthAllDataSourcesCount,
} from "../../redux/actions/dataMapInspectAction";
// import { getCubejsApi } from "../common/Dashboard/cubejs-util";

import { cubeQueryGen } from "../common/utilities";
import Toggler from "../shared/Toggler";
import { DataMapRoute } from "../common/DatamapImage";

export default function DataFlowGroups() {
  // const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const alertStatusUpdated = useSelector(
    (state) => state.alertStatusChanged.alertUpdated
  );
  const alertStatusUpdatedSuccessMessage = useSelector(
    (state) => state.alertStatusChanged.successMessage
  );
  const selectedDateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  // i18n.changeLanguage('de');
  const [getAllAlertsCall, setGetAllAlertsCall] = useState(true);
  const [nodeCount, setNodeCount] = useState(0);
  const [dataSourceCount, setDataSourceCount] = useState(0);
  const [selectedAppFromFilter, setSelectedAppFromFilter] = useState("");
  const [searchBy, setSearchBy] = useState("");

  const [togglers, setTogglers] = useState([
    { name: "Data Flows", state: "inactive" },
    { name: "Data Flow Groups", state: "active" },
  ]);

  const headerName = "";
  const headerProps = {
    id: 12,
    name: headerName ? headerName : "All Applications",
    sources: "0",
    targets: "0",
    description: "Data Flow Groups in the system.",
  };
  useEffect(() => {
    if (alertStatusUpdatedSuccessMessage !== "") {
      setSnackBarMessage(alertStatusUpdatedSuccessMessage);
    }
  }, [alertStatusUpdatedSuccessMessage]);

  const DataSourceCount = async (queryString, queryTypeString) => {
    const response = await fecthAllDataSourcesCount(
      queryString,
      queryTypeString
    );
    if (
      response &&
      response.data &&
      response.data.results &&
      response.data.results.length &&
      response.data.results[0] &&
      response.data.results[0].data[0]
    ) {
      const DataSourceCount =
        response.data.results[0].data[0]["DataSources.count"];
      setDataSourceCount(DataSourceCount);
    } else {
      setDataSourceCount(0);
    }
  };
  const NodesCount = async (queryString, queryTypeString) => {
    const response = await fecthAllDataSourcesCount(
      queryString,
      queryTypeString
    );

    if (
      response &&
      response.data &&
      response.data.results &&
      response.data.results.length &&
      response.data.results[0] &&
      response.data.results[0].data[0]
    ) {
      const NodeCount = response.data.results[0].data[0]["Node.countOfNodes"];
      setNodeCount(NodeCount);
    } else {
      setNodeCount(0);
    }
  };

  async function getAllDMNodesByCubeNodes(reqBody) {
    const response = await fetchDMNodesByCubeNodes(reqBody);
    if (response) {
      if (response.nodes.length > 0) {
        let dSCount = 0;
        setNodeCount(response.nodes.length);
        response.nodes.forEach((each) => {
          if (each.type.code === "DATASOURCE") {
            dSCount++;
          }
        });
        setDataSourceCount(dSCount);
        return response.nodes;
      }
    } else {
      setNodeCount(0);
      setDataSourceCount(0);
    }
  }

  const handleSelectedApp = (data, searchBy) => {
    setSelectedAppFromFilter(data);
    setSearchBy(searchBy);
  };

  useEffect(() => {
    if (selectedAppFromFilter !== "") {
      let filtersList = [];
      let filtersList2 = [];
      let queryString;
      let queryString2;
      if (searchBy === "Data Source") {
        filtersList = [
          {
            member: "DataSources.dataSourceId",
            operator: "equals",
            values: [selectedAppFromFilter.id],
          },
        ];
        filtersList2 = [
          {
            member: "Node.dataSourceId",
            operator: "equals",
            values: [selectedAppFromFilter.id],
          },
        ];

        queryString = {
          dimensions: [],
          timeDimensions: [],
          measures: ["DataSources.count"],
          order: {},
          filters: filtersList,
        };
        queryString2 = {
          dimensions: [],
          timeDimensions: [],
          measures: ["Node.countOfNodes"],
          order: {},
          segments: ["Node.parentNodes"],
          filters: filtersList2,
        };
      } else if (searchBy === "Application") {
        filtersList = [
          {
            member: "DataSources.applicationId",
            operator: "equals",
            values: [selectedAppFromFilter.id],
          },
        ];
        filtersList2 = [
          {
            member: "Node.applicationId",
            operator: "equals",
            values: [selectedAppFromFilter.id],
          },
        ];

        queryString = {
          dimensions: [],
          timeDimensions: [],
          measures: ["DataSources.count"],
          order: {},
          filters: filtersList,
        };
        queryString2 = {
          dimensions: [],
          timeDimensions: [],
          measures: ["Node.countOfNodes"],
          order: {},
          segments: ["Node.parentNodes"],
          filters: filtersList2,
        };
      }

      const queryTypeString = "multi";
      DataSourceCount(queryString, queryTypeString);
      NodesCount(queryString2, queryTypeString);
    } else {
      const queryString = {
        dimensions: [],
        measures: ["DataSources.count"],
        timeDimensions: [],
        order: {},
        filters: [],
      };
      const queryString2 = {
        dimensions: [],
        timeDimensions: [],
        order: {},
        measures: ["Node.countOfNodes"],
        filters: [],
        segments: ["Node.parentNodes"],
      };
      const queryTypeString = "multi";
      // getNodes(queryString, queryTypeString);
      DataSourceCount(queryString, queryTypeString);
      NodesCount(queryString2, queryTypeString);
    }
  }, [selectedAppFromFilter]);

  useEffect(() => {
    if (alertStatusUpdated) {
      setGetAllAlertsCall(true);
      setOpen(true);
    }
  }, [alertStatusUpdated]);

  const handleClick = (message) => () => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const flowGroupNameClick = (data) => {
    data["type"] = "dataFlowGroups";
    data["tabIndex"] = true;

    if (history && history.location && history.location.pathname) {
      if (history.location.pathname.includes("dataMap")) {
        dispatch({ type: "TRACK_TRAVERSAL", payload: "Data Flows" });
        history.push({
          pathname: "/tenant/dataFlows",
          state: data,
        });
      } else {
        // dispatch({ type: "TRACK_TRAVERSAL", payload: "Data Map" });
        // history.push({
        //   pathname: "/tenant/dataMap",
        //   state: data,
        // });
      }
    }
  };

  const handleToggle = (name) => {
    if (name === "Data Flows") {
      history.push("/tenant/dataFlows");
    } else if (name === "Data Flow Groups") {
      history.push("/tenant/dataFlowGroups");
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="datamap-container">
            <div className="datamap-breadcrumb">
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ marginTop: "15px" }}
              >
                <Link
                  color="inherit"
                  href="javascript:void(0)"
                  onClick={handleClick}
                >
                  {headerProps.name}
                </Link>
                <Typography color="textPrimary">
                  Review Data Flow Groups
                </Typography>
              </Breadcrumbs>
            </div>
            <div className="main">
              <div className="description" style={{ marginTop: "0px" }}>
                <div style={{ display: "grid" }}>
                  <h2 style={{ margin: "0" }}>
                    {selectedAppFromFilter["name"] || "All Applications"} |{" "}
                    {dataSourceCount} &nbsp; Data Source(s) | {nodeCount}&nbsp;
                    Node(s)
                  </h2>
                  <p style={{ margin: "0" }}>{headerProps.description}</p>
                </div>

                <DataMapRoute />
              </div>
              <div
                // style={{ marginLeft: "20%" }}
                className="togglerandSearch_Conatiner"
              >
                <Toggler
                  togglers={togglers}
                  setTogglers={setTogglers}
                  history={history}
                  handleToggle={handleToggle}
                ></Toggler>
              </div>
              <div style={{ margin: "24px 24px 12px 211px" }}>
                <DataMapFlowGroup
                  flowGroupNameClick={flowGroupNameClick}
                  selectedApp={null}
                  handleSelectedApp={handleSelectedApp}
                  notdatamapTrayPanel={true}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
