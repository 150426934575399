//import axios from 'axios';
import {
  GET_DATAMAP_CONTENTS,
  GET_DATAMAP_INFO,
  GET_DATAMAP_FLOWGROUPS,
  GET_DATAMAP_TRANSACTIONS,
  GET_DATAMAP_FILTERS,
  GET_DATAMAP_FILTERS_BYID,
  GET_DATAMAP_NODES,
} from "../constants/dataMapTableViewConst";
import { CUBEJS_URL, KIBANA_URL } from "../constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";

import { getAppDateTimeRange } from "../../components/common/utilities";
import { getCubejsApi , redirectToLogin} from "../../../src/components/common/Dashboard/cubejs-util";

export async function fetchAllDataMapContents() {
  const options = {
    method: "GET",
    url: `${GET_DATAMAP_CONTENTS}?size=1000`,
    headers: getAxiosHeaders(true),
    data: {},
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fetchDMInfo() {
  const options = {
    method: "GET",
    url: `${GET_DATAMAP_INFO}?size=1000`,
    headers: getAxiosHeaders(true),
    data: {},
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fetchDataFlowGroupById(id) {
  const options = {
    method: "GET",
    url: `${GET_DATAMAP_FLOWGROUPS}/${id}`,
    headers: getAxiosHeaders(true),
    data: {},
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function saveDMDataFlowGroup(data) {
  const options = {
    method: "POST",
    url: `${GET_DATAMAP_FLOWGROUPS}`,
    headers: getAxiosHeaders(true),
    data: data,
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fetchDMTransactions() {
  const options = {
    method: "GET",
    url: `${GET_DATAMAP_TRANSACTIONS}?size=1000`,
    headers: getAxiosHeaders(true),
    data: {},
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fetchDMSearchFilter(filterName) {
  const options = {
    method: "GET",
    url: `${GET_DATAMAP_FILTERS}`,
    headers: getAxiosHeaders(true),
    data: {},
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fetchDMSearchFilterById(appId) {
  const options = {
    method: "GET",
    url: `${GET_DATAMAP_FILTERS_BYID}96086a9c-dd08-4fd8-b2f2-72cc6536911e`,
    headers: getAxiosHeaders(true),
    data: {},
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fetchDMTransactionsList(startTime, endTime, appName) {
  const appDateTimeRanges = getAppDateTimeRange();
  if (appDateTimeRanges) {
    const options = {
      method: "GET",
      url: `${KIBANA_URL}/services/${appName}/transaction_groups?start=${appDateTimeRanges.startDate}&end=${appDateTimeRanges.endDate}&transactionType=request&uiFilters={"kuery":"","environment":"ENVIRONMENT_ALL"}`,
      headers: getAxiosHeaders(),
      data: {},
    };

    const response = await getService(options);
    if (response) {
      return response.data;
    }
  }
}

export async function fetchDMTracesIdList(
  startTime,
  endTime,
  transactionName,
  appName
) {
  const options = {
    method: "GET",
    url: `${KIBANA_URL}/services/${appName}/transaction_groups/distribution?start=${endTime}&end=${startTime}&transactionType=request&transactionName=${encodeURIComponent(
      transactionName
    )}&uiFilters={"environment":"ENVIRONMENT_ALL"}`,
    headers: getAxiosHeaders(),
    data: {},
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fetchDMTracesSpanList(startTime, endTime, traceId) {
  const options = {
    method: "GET",
    url: `${KIBANA_URL}/traces/${traceId}?start=${endTime}&end=${startTime}`,
    headers: getAxiosHeaders(),
    data: {},
  };

  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

export async function fecthAllDataSourcesCount(queryString, queryTypeString) {
  if (!queryString.filters) {
    queryString["filters"] = [];
  }
  const url = `${CUBEJS_URL}/load?query=${encodeURIComponent(
    JSON.stringify(queryString)
  )}&queryType=${queryTypeString}`;
  const options = {
    method: "GET",
    url: url,
    headers: getAxiosHeaders(true),
  };

  const response = await getService(options);
  if (response) {
    return response;
  }
}

export async function fetchDMNodesList(queryString, queryTypeString) {
  const cubejsApi = getCubejsApi();
  const gteTime = String(
    Date.parse(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
  );
  const lteTime = String(Date.now());
  const gteTimeFilter = {
    dimension: "NodesOfApplication.creationTime",
    operator: "gte",
    values: [gteTime],
  };
  const lteTimeFilter = {
    dimension: "NodesOfApplication.creationTime",
    operator: "lte",
    values: [lteTime],
  };
  if (!queryString.filters) {
    queryString["filters"] = [];
  }

  try {
    const resultSet = await cubejsApi.load(queryString);
    const response = resultSet.rawData();
    if (response) {
      return response;
    }
  } catch (error) {
    redirectToLogin(error)
  }
}

export async function fetchDMAppList(queryString, queryTypeString) {
  if (!queryString.filters) {
    queryString["filters"] = [];
  }

  const options = {
    method: "GET",
    url: `${CUBEJS_URL}/load?query=${encodeURIComponent(
      JSON.stringify(queryString)
    )}&queryType=${queryTypeString}`,
    headers: getAxiosHeaders(true),
  };

  const response = await getService(options);
  if (response && response.data) {
    return response.data;
  }
}

export async function fetchDMDataFlowGroups(
  queryString,
  queryTypeString,
  dimensionName
) {
  const gteTime = String(
    Date.parse(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
  );
  const lteTime = String(Date.now());
  const gteTimeFilter = {
    dimension: `${dimensionName}.creationTime`,
    operator: "gte",
    values: [gteTime],
  };
  const lteTimeFilter = {
    dimension: `${dimensionName}.creationTime`,
    operator: "lte",
    values: [lteTime],
  };

  if (!queryString.filters) {
    queryString["filters"] = [];
  }
  // queryString.filters.push(gteTimeFilter);
  // queryString.filters.push(lteTimeFilter);
  const options = {
    method: "GET",
    url: `${CUBEJS_URL}/load?query=${encodeURIComponent(
      JSON.stringify(queryString)
    )}&queryType=${queryTypeString}`,
    headers: getAxiosHeaders(true),
  };

  const response = await getService(options);
  if (response && response.data) {
    return response.data;
  }
}

export async function fetchDMDataFlows(queryString, queryTypeString) {
  const gteTime = String(
    Date.parse(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
  );
  const lteTime = String(Date.now());
  const gteTimeFilter = {
    dimension: "DataFlowsByApplication.creationTime",
    operator: "gte",
    values: [gteTime],
  };
  const lteTimeFilter = {
    dimension: "DataFlowsByApplication.creationTime",
    operator: "lte",
    values: [lteTime],
  };
  if (!queryString.filters) {
    queryString["filters"] = [];
  }
  // queryString.filters.push(gteTimeFilter);
  // queryString.filters.push(lteTimeFilter);

  const options = {
    method: "POST",
    url: `${CUBEJS_URL}/load?queryType=${queryTypeString}`,
    headers: getAxiosHeaders(true),
    data: { query: queryString },
  };

  const response = await getService(options);
  if (response && response.data) {
    return response.data;
  }
}

export async function fetchDMDataFlowsCount(queryString, queryTypeString) {
  if (!queryString.filters) {
    queryString["filters"] = [];
  }
  const options = {
    method: "POST",
    url: `${CUBEJS_URL}/load?queryType=${queryTypeString}`,
    headers: getAxiosHeaders(true),
    data: { query: queryString },
  };

  const response = await getService(options);
  if (response && response.data) {
    return response.data;
  }
}

export async function fetchDMAlertAndPolicies(
  queryString,
  queryTypeString,
  dimensionName
) {
  const gteTime = String(
    Date.parse(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
  );
  const lteTime = String(Date.now());
  const gteTimeFilter = {
    dimension: `${dimensionName}.creationTime`,
    operator: "gte",
    values: [gteTime],
  };
  const lteTimeFilter = {
    dimension: `${dimensionName}.creationTime`,
    operator: "lte",
    values: [lteTime],
  };
  if (!queryString.filters) {
    queryString["filters"] = [];
  }
  // queryString.filters.push(gteTimeFilter);
  // queryString.filters.push(lteTimeFilter);

  const options = {
    method: "GET",
    url: `${CUBEJS_URL}/load?query=${encodeURIComponent(
      JSON.stringify(queryString)
    )}&queryType=${queryTypeString}`,
    headers: getAxiosHeaders(true),
  };

  const response = await getService(options);
  if (response && response.data) {
    return response.data;
  }
}

export async function fetchDMListByQueryStr(queryString, queryTypeString) {
  const url = `${CUBEJS_URL}/load?queryType=${queryTypeString}`;
  const options = {
    method: "POST",
    url: url,
    data: { query: queryString },
    headers: getAxiosHeaders(true),
  };

  const response = await getService(options);
  if (response && response.data) {
    return response.data;
  }
}

export async function fetchDMNodesByCubeNodes(reqBody) {
  const options = {
    method: "POST",
    url: `${GET_DATAMAP_NODES}`,
    headers: getAxiosHeaders(true),
    data: reqBody,
    timeout: 1200000,
  };
  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

// "operator": "lte", // endtime
// "operator": "gte", // starttime
