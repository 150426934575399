import React from 'react';
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import DoneIcon from "../../assets/vizr_images/done_all.svg";
import { isEmpty } from 'lodash';

function DeleteConnection(props) {
  const {
    loader,
    selectedSource,
    checkValidity,
    confirmMessage,
    sourceName,
  } = props;
  return (<>
    {loader && (
      <div className="global-loader-holder">
        <img src={Page_Loader} alt="_Loader" className="loader" />
      </div>
    )}
    <div>
      <div className="selectedSourceName">{selectedSource.name}</div>
      <div>
        <label>Please enter the selected {sourceName} source here:</label>
        <div className='confirmSourceName'><input
          type="text"
          onChange={(e) => checkValidity(e)}
          className="inputSourceName"
        />
          {!isEmpty(confirmMessage) &&
            <div className="dialogConfirmMessage">
              <img src={DoneIcon} alt={'done'} />
              <span>{confirmMessage}</span>
            </div>}
        </div>
      </div>
      {!isEmpty(confirmMessage) && (
        <div>
          Press Confirm to delete the {sourceName} source from application.
        </div>
      )}
    </div></>
  );
}

export default DeleteConnection;
