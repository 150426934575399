import {
  CHANGE_FILTERBYVALUE_DATABASELOGS,
  UPDATED_DATABASE_LOGS_SELECTED_FILTER_VALUES,
  DATABASE_SORT_CHANGE,
  DATABASE_CHANGE_ORDER_BY_VALUE,
  DATABASE_FROM_TIME_VALUE,
  DATABASE_TO_TIME_VALUE,
  DATABASE_LOGS_CLEAR_ALL,
  SET_USERS,
  SET_QUERY_STATEMENT,
  LOADING_DATA,
} from "../constants/constants";
import { isEqual, cloneDeep } from "lodash";

const currentTime = new Date().getTime();
const initialState = {
  sortingValue: "",
  orderByValue: "",
  fromTimeValue: "",
  toTimeValue: "",
  dataSourceName: [],
  dataSourceType: [],
  databaseAccessRoles: [],
  applications:[],
  sensitivityInfo: [],
  policyNames: [],
  apiDataSourceName: [],
  apiDataSourceType: [],
  users: [],
  queryStatement: [],
  apiDatabaseAccessRoles: [],
  apiSensitivityInfo: [],
  apiPolicyNames: [],
  totalCount: null,
  approxCount: null,
  currentPage: 0,
  recordsPerPage: 20,
  allDatabaseLogs: [],
  alertId: "",
  filterInfo: [],
  loader: false,
  finalDataSourceName: [],
  finalUsers: [],
  finalQueryStatement: [],
  finalDataSourceType: [],
  finalDatabaseAccessRoles: [],
  finalSensitivityInfo: [],
  finalPolicyNames: [],
  finalSortingValue: "",
  finalOrderByValue: "",
  finalFromTimeValue: "",
  finalToTimeValue: "",
  copyFilterInfo: [],
  showNoOfExec: false,
  freshLoadTimestamp: currentTime,
  logsUpdateTimeStamp: currentTime
};

export const databaseLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_FILTERBYVALUE_DATABASELOGS: {
      return {
        ...state,
        filterValue: action.payload,
      };
    }
    case UPDATED_DATABASE_LOGS_SELECTED_FILTER_VALUES: {
      return {
        ...state,
        databaseLogsSelectedFilter: [...action.payload],
      };
    }
    case DATABASE_SORT_CHANGE: {
      return {
        ...state,
        sortingValue: action.payload,
      };
    }
    case DATABASE_CHANGE_ORDER_BY_VALUE: {
      return {
        ...state,
        orderByValue: action.payload,
      };
    }
    case DATABASE_FROM_TIME_VALUE: {
      return {
        ...state,
        fromTimeValue: action.payload,
      };
    }
    case DATABASE_TO_TIME_VALUE: {
      return {
        ...state,
        toTimeValue: action.payload,
      };
    }
    case "DATA_SOURCE_NAME": {
      return {
        ...state,
        dataSourceName: action.payload,
      };
    }
    case "DATA_SOURCE_TYPE": {
      return {
        ...state,
        dataSourceType: action.payload,
      };
    }
    case "DATABASE_ACCESS_ROLES": {
      return {
        ...state,
        databaseAccessRoles: action.payload,
      };
    }
    case "SENSITIVITY_INFO": {
      return {
        ...state,
        sensitivityInfo: action.payload,
      };
    }
    case "POLICY_NAMES": {
      return {
        ...state,
        policyNames: action.payload,
      };
    }
    case "APPLICATIONS":{
      return {
        ...state,
        applications: action.payload,
      }
    }
    case "SET_POLICY_NAMES": {
      const copyOfData = [...state.apiPolicyNames];
      if (!state.apiPolicyNames.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiPolicyNames: temp,
        };
      }
      return {
        ...state,
        apiPolicyNames: copyOfData,
      };
    }
    case "SET_DATA_SOURCE_NAME": {
      const copyOfData = [...state.apiDataSourceName];
      if (!state.apiDataSourceName.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDataSourceName: temp,
        };
      }
      return {
        ...state,
        apiDataSourceName: copyOfData,
      };
    }
    case SET_QUERY_STATEMENT: {
       // update query statements in store for access log
      const copyOfData = [...state.queryStatement];
      if (!state.queryStatement.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          queryStatement: temp,
        };
      }
      return {
        ...state,
        queryStatement: copyOfData,
      };
    }
    case "SET_DATA_SOURCE_TYPE": {
      const copyOfData = [...state.apiDataSourceType];
      if (!state.apiDataSourceType.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDataSourceType: temp,
        };
      }
      return {
        ...state,
        apiDataSourceType: copyOfData,
      };
    }
    case SET_USERS: {
      // update user in store for access log
      const copyOfData = [...state.users];
      if (!state.users.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          users: temp,
        };
      }
      return {
        ...state,
        users: copyOfData,
      };
    }
    case "SET_DATABASE_ACCESS_ROLES": {
      const copyOfData = [...state.apiDatabaseAccessRoles];
      if (!state.apiDatabaseAccessRoles.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDatabaseAccessRoles: temp,
        };
      }
      return {
        ...state,
        apiDatabaseAccessRoles: copyOfData,
      };
    }
    case "SET_SENSITIVITY_INFO": {
      let copyOfData = [...state.apiSensitivityInfo];
      if (action.payload.type && action.payload.type === "object") {
        copyOfData = [...action.payload.value];
        return {
          ...state,
          apiSensitivityInfo: copyOfData,
        };
      }
      if (!state.apiSensitivityInfo.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiSensitivityInfo: temp,
        };
      }
      return {
        ...state,
        apiSensitivityInfo: copyOfData,
      };
    }
    case "SET_DATABASE_INFO": {
      // set db logs into reducer
      return {
        ...state,
        allDatabaseLogs: action.payload.allDatabaseLogs,
        showNoOfExec: false,
        approxCount: action.payload.totalCount,
        loader: false,
        logsUpdateTimeStamp: new Date().getTime()
      };
    }
    case "FULL_RESET_DB_LOGS": {
      // when ever we are making a new call we should reset
      return {
        ...state,
        allDatabaseLogs: [],
        totalCount: null,
        approxCount: null,
        freshLoadTimestamp: new Date().getTime(),
        currentPage: 0,
      }
    }
    case "SET_SHOW_NO_EXEC_TRUE": {
      const allDatabaseLogs = state.allDatabaseLogs.map((log) => {
        return {
          ...log,
          noOfExecutions: LOADING_DATA
        };
      });
      // set number of execution true
      return {
        ...state,
        showNoOfExec: true,
        allDatabaseLogs
      };
    }
    case "SET_DATABASE_STMT_NO_EXEC": {
      // No of Exec needs to be merged to all logs
      const logs = state.allDatabaseLogs.map((log) => {
        const execObj = action.payload.noOfExecutionsList.find(
          e => (
              e.sqlStatementId === log.sqlStatementId &&
              e.enterpriseActor.id === log.enterpriseActor.id &&
              e.dataSource.id === log.dataSource.id
            )
        );
        let noOfExecutions = 0;
        if (execObj && execObj.noOfExecutions !== null && execObj.noOfExecutions !== undefined) {
          noOfExecutions = execObj.noOfExecutions;
        }
        return {
          ...log,
          noOfExecutions
        };
      });
      return {
        ...state,
        allDatabaseLogs: logs,
      }
    }
    case "SET_DATABASE_COUNT": {
      return {
        
        ...state,      
        totalCount: action.payload.totalCount,
        loader: false,
      };
    }
    case "SET_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
        loader: true,
      };
    }
    case "SET_RECORDS_PAGE": {
      return {
        ...state,
        recordsPerPage: action.payload,
        loader: true,
      };
    }
    case "SET_ALERT_ID": {
      return {
        ...state,
        alertId: action.payload,
      };
    }
    case DATABASE_LOGS_CLEAR_ALL: {
      return {
        ...initialState,
      };
    }
    case "SET_APPLY_DATA": {
      return {
        ...state,
        loader: true,
        currentPage: state.currentPage,
        recordsPerPage: state.recordsPerPage,
        finalDataSourceName: cloneDeep(state.apiDataSourceName),
        finalDataSourceType: cloneDeep(state.apiDataSourceType),
        finalDatabaseAccessRoles: cloneDeep(state.apiDatabaseAccessRoles),
        finalSensitivityInfo: cloneDeep(state.apiSensitivityInfo),
        finalPolicyNames: cloneDeep(state.apiPolicyNames),
        finalSortingValue: cloneDeep(state.sortingValue),
        finalOrderByValue: cloneDeep(state.orderByValue),
        finalFromTimeValue: cloneDeep(state.fromTimeValue),
        finalToTimeValue: cloneDeep(state.toTimeValue),
        finalUsers: cloneDeep(state.users),
        finalQueryStatement: cloneDeep(state.queryStatement),
        copyFilterInfo: cloneDeep(state.filterInfo),
      };
    }
    case "MAINTAIN_SINGLE_DATA": {
      let copyOfData = [...state.filterInfo];
      let present = false;
      for (let i = 0; i < copyOfData.length; i++) {
        if (copyOfData[i].name === action.payload.name) {
          present = true;
          break;
        }
      }
      if (!present) {
        return {
          ...state,
          filterInfo: [...copyOfData, { ...action.payload }],
        };
      } else {
        const temp = copyOfData.filter(
          (each) => each.name !== action.payload.name
        );
        return {
          ...state,
          filterInfo: temp,
        };
      }
    }
    case "CLEAR_ON_CLOSE": {
      let apiDataSourceName = state.apiDataSourceName;
      let users = state.users;
      let queryStatement = state.queryStatement;
      let filterInfo = state.filterInfo;
      let apiDataSourceType = state.apiDataSourceType;
      let apiDatabaseAccessRoles = state.apiDatabaseAccessRoles;
      let apiSensitivityInfo = state.apiSensitivityInfo;
      let apiPolicyNames = state.apiPolicyNames;
      let fromTimeValue = state.fromTimeValue;
      let toTimeValue = state.toTimeValue;
      let sortingValue = state.sortingValue;
      let orderByValue = state.orderByValue;
      if (
        !isEqual(
          state.finalDataSourceName.sort(),
          state.apiDataSourceName.sort()
        )
      ) {
        apiDataSourceName = cloneDeep(state.finalDataSourceName);
      }
      if (
        !isEqual(
          state.finalUsers.sort(),
          state.users.sort()
        )
      ) {
        users = cloneDeep(state.finalUsers);
      }
      if (!isEqual(state.copyFilterInfo, state.filterInfo)) {
        filterInfo = cloneDeep(state.copyFilterInfo);
      }
      if (
        !isEqual(
          state.finalDataSourceType.sort(),
          state.apiDataSourceType.sort()
        )
      ) {
        apiDataSourceType = cloneDeep(state.finalDataSourceType);
      }
      if (
        !isEqual(
          state.finalDatabaseAccessRoles.sort(),
          state.apiDatabaseAccessRoles.sort()
        )
      ) {
        apiDatabaseAccessRoles = cloneDeep(state.finalDatabaseAccessRoles);
      }
      if (
        !isEqual(
          state.apiSensitivityInfo.sort(),
          state.finalSensitivityInfo.sort()
        )
      ) {
        apiSensitivityInfo = cloneDeep(state.finalSensitivityInfo);
      }
      if (
        !isEqual(
          state.queryStatement.sort(),
          state.finalQueryStatement.sort()
        )
      ) {
        queryStatement = cloneDeep(state.finalQueryStatement);
      }
      if (
        !isEqual(state.finalPolicyNames.sort(), state.apiPolicyNames.sort())
      ) {
        apiPolicyNames = cloneDeep(state.finalPolicyNames);
      }
      if (state.fromTimeValue !== state.finalFromTimeValue) {
        fromTimeValue = cloneDeep(state.finalFromTimeValue);
      }
      if (state.toTimeValue !== state.finalToTimeValue) {
        toTimeValue = cloneDeep(state.finalToTimeValue);
      }
      if (state.sortingValue !== state.finalSortingValue) {
        sortingValue = cloneDeep(state.finalSortingValue);
      }
      if (state.orderByValue !== state.finalOrderByValue) {
        orderByValue = cloneDeep(state.finalOrderByValue);
      }
      return {
        ...state,
        apiDataSourceName: apiDataSourceName,
        filterInfo: filterInfo,
        apiDataSourceType: apiDataSourceType,
        apiDatabaseAccessRoles: apiDatabaseAccessRoles,
        apiSensitivityInfo: apiSensitivityInfo,
        apiPolicyNames: apiPolicyNames,
        fromTimeValue: fromTimeValue,
        toTimeValue: toTimeValue,
        sortingValue: sortingValue,
        orderByValue: orderByValue,
        users: users,
        queryStatement: queryStatement,
      };
    }
    case "DATABASE_ACCESS_LOGS_CLEAR_FILTER_DATA": {
      return {
        ...state,
        apiDataSourceName: [],
        filterInfo: [],
        apiDataSourceType: [],
        apiDatabaseAccessRoles: [],
        apiSensitivityInfo: [],
        queryStatement: [],
        apiPolicyNames: [],
        users: [],
        currentPage: 0,
        alertId: "",
        loader: true,
        fromTimeValue: "",
        toTimeValue: "",
        sortingValue: "",
        orderByValue: "",
        finalDataSourceName: [],
        finalDataSourceType: [],
        finalDatabaseAccessRoles: [],
        finalSensitivityInfo: [],
        finalPolicyNames: [],
        finalSortingValue: "",
        finalOrderByValue: "",
        finalFromTimeValue: "",
        finalToTimeValue: "",
        finalUsers: [],
        finalQueryStatement: [],
        copyFilterInfo: [],
      };
    }

    case "DATABASE_FROM_TIME_VALUE_ON_MOUNT": {
      return {
        ...state,
        finalFromTimeValue: action.payload,
      };
    }

    case "DATABASE_TO_TIME_VALUE_ON_MOUNT": {
      return {
        ...state,
        finalToTimeValue: action.payload,
      };
    }

    case "SET_LOADER_INFO": {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case "SET_DATABASE_SENSITIVITY_LEVEL": {
      return {
        ...state,
        finalSensitivityInfo: action.payload,
        copyFilterInfo: cloneDeep(state.filterInfo),
      };
    }

    default:
      return state;
  }
};
