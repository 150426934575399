import React from "react";

import "../../../assets/scss/signuppage-styles/signuppage.scss";
import "../../../assets/scss/admin-styles/custom/loginpagestyle.scss";
import Grid from "@material-ui/core/Grid";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import "../../../assets/scss/admin-styles/custom/loginpagestyle.scss";
import ForgotPasswordRight from "./ForgotPasswordRight";
import ForgotPasswordLeft from "./ForgotPasswordLeft";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  forgotPassword_container: {
    margin: "auto",
    border: "1px solid grey",
    height: "40rem",
    width: "80%",
    minWidth: "25%",
    borderRadius: "50px",
    fontFamily: "Nunito !important",
    backgroundColor: "white",
  },
  forgotPassword_container_flex: {
    margin: "auto",
    border: "1px solid grey",
    height: "40rem",
    width: "80%",
    minWidth: "25%",
    borderRadius: "38px",
    fontFamily: "Nunito !important",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-around",
  },
  setPassword_container: {
    margin: "auto",
    border: "1px solid grey",
    height: "40rem",
    width: "75%",
    minWidth: "25%",
    borderRadius: "38px",
    backgroundColor: "#fff",
  },
});

const ForgotpasswordDes = ({ setPassword }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      xl={12}
      md={12}
      xs={12}
      sm={12}
      className={
        !setPassword
          ? classes.forgotPassword_container
          : classes.setPassword_container
      }
    >
      <ForgotPasswordLeft></ForgotPasswordLeft>
      <ForgotPasswordRight setPassword={setPassword}></ForgotPasswordRight>
    </Grid>
  );
};

export default ForgotpasswordDes;
