import { MDRPatchMonitoringQuery } from "../../components/common/Dashboard/dashboard-data";
import {
  UPDATE_PATCHMONITORING_CLASSIFICATION_FILTERDATA,
  UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA,
  UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA,
  UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA,
} from "../constants/constants";
import {
  clearMDRFilters,
  MDRupdatedFilterData,
} from "../../components/common/Dashboard/utils";
import {
  MDRTableSearchHelper,
  MDRApplyFilters,
  MDRMultipleFilters_Merge_Helper,
} from "../../components/common/Dashboard/MDRHelpers";

let initialState = {
  patchMonitoringTableData: [],
  patchMonitoringTableQuery: [...MDRPatchMonitoringQuery],
  totalRowsPatchMonitoring: 0,
  Classification: [],
  Severity: [],
  UpdateState: [],
  SubscriptionName: [],
  currentStatus: "",
  clearAll: false,
  patchMonitoringDrawer: false,
  patchMonitoringSideDrawerData: {},
};
export const MDRPatchMonitoringReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_PATCHMONITORING_TABLE_DATA": {
      return {
        ...state,
        patchMonitoringTableData: action.payload.data,
        totalRowsPatchMonitoring: action.payload.totalTableDataCount,
        clearAll: false,
      };
    }
    case "UPDATE_PATCHMONITORING_TABLE_AS_PER_DATERANGE": {
      return {
        ...state,
        currentStatus: action.payload.dateRangeChanged
          ? "dateRangeChanged"
          : "dateRangeNotChanged",
      };
    }
    case UPDATE_PATCHMONITORING_CLASSIFICATION_FILTERDATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRupdatedFilterData(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          Classification: [...updatedData],
        };
      }

      return {
        ...state,
        Classification: [...action.payload.data],
      };
    }
    case UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRupdatedFilterData(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          Severity: [...updatedData],
        };
      }

      return {
        ...state,
        Severity: [...action.payload.data],
      };
    }
    case UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRupdatedFilterData(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          UpdateState: [...updatedData],
        };
      }

      return {
        ...state,
        UpdateState: [...action.payload.data],
      };
    }
    case UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRupdatedFilterData(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          SubscriptionName: [...updatedData],
        };
      }

      return {
        ...state,
        SubscriptionName: [...action.payload.data],
      };
    }
    case "CHANGE_MDRPATCHMONITORING_TABLE_QUERY_AS_PER_PAGESELECTED": {
      let newInstance = [...state.patchMonitoringTableQuery];
      let newObj = { ...newInstance[0] };
      let newAquery = [...newObj.query];
      let query1 = { ...newAquery[0] };
      query1.offset = action.payload * action.rowsPerPage;
      query1.limit = action.rowsPerPage;
      newAquery[0] = query1;
      newObj.query = newAquery;
      newInstance[0] = newObj;

      return {
        ...state,
        patchMonitoringTableQuery: newInstance,
        currentStatus: "pageChange",
      };
    }
    case "APPLY_PATCHMONITORING_FILTER": {
      const filters = MDRMultipleFilters_Merge_Helper(action.payload);
      const newTableInstance = MDRApplyFilters(
        state.patchMonitoringTableQuery,
        filters,
        "APPLY_PATCHMONITORING_FILTER"
      );
      return {
        ...state,
        patchMonitoringTableQuery: [...newTableInstance],
        currentStatus: "apply",
      };
    }
    case "PATCHMONITORING_FILTER_CLEARALL": {
      const data = [...MDRPatchMonitoringQuery];
      const patchMonitoringClearAll = clearMDRFilters(
        state.Classification,
        state.Severity,
        state.SubscriptionName,
        state.UpdateState
      );
      return {
        ...state,
        Classification: patchMonitoringClearAll[0],
        Severity: patchMonitoringClearAll[1],
        SubscriptionName: patchMonitoringClearAll[2],
        UpdateState: patchMonitoringClearAll[3],
        patchMonitoringTableQuery: [...data],
        clearAll: true,
      };
    }
    case "UPDATE_PATCHMONITORING_TABLE_AS_PER_SEARCHVALUE": {
      let newTableInstance = MDRTableSearchHelper(
        state.patchMonitoringTableQuery,
        action
      );
      return {
        ...state,
        patchMonitoringTableQuery: [...newTableInstance],
        currentStatus: "searchValue",
      };
    }
    case "MDR_RESET_TABLEROWS_DATA_AS_SEARCH_FIELD_CHANGED": {
      return {
        ...state,
        patchMonitoringTableData: [],
        totalRowsPatchMonitoring: 0,
      };
    }
    case "UPDATE_PATCHMONITORING_TABLE_AS_SEARCHVALUE_CLEARED": {
      let newTableInstance = MDRTableSearchHelper(
        state.patchMonitoringTableQuery,
        action,
        true
      );
      return {
        ...state,
        patchMonitoringTableQuery: [...newTableInstance],
        currentStatus: "searchValue",
      };
    }
    case "CLOSE_PATCH_MONITORING_DRAWER": {
      return {
        ...state,
        patchMonitoringDrawer: false,
        patchMonitoringSideDrawerData: {},
      };
    }
    case "PATCH_MONITORING_DRAWER_DATA": {
      return {
        ...state,
        patchMonitoringDrawer: true,
        patchMonitoringSideDrawerData: action.payload,
      };
    }
    case "CLEAR_CURRENT_STATUS": {
      return {
        ...state,
        currentStatus: ""
      };
    }
    default:
      return state;
  }
};