import React from "react";
import TextField from "@material-ui/core/TextField";

const DynamicFormFields = ({ data, setFunctions, fieldValues, errors, removeErrorByKey, disabledValues, isEditMode }) => {

  const determineFieldIsRequired = (fieldName, data, isEditMode) => {
    let fieldIsRequired = data[fieldName].isRequired; // By default, use isRequired from JSON data
  
    // If fieldName is 'username' or 'password' and isEditMode is false, set fieldIsRequired to false
    if (!isEditMode && (fieldName === "username" || fieldName === "password")) {
      fieldIsRequired = false;
    }
  
    return fieldIsRequired;
  };
  return (
    <div>
      {Object.keys(data).map((fieldName) => {
        // Check if the fieldName is present in fieldValues
        if (fieldValues.hasOwnProperty(fieldName)) {
          const isFieldDisabled = disabledValues && disabledValues[fieldName];
          const fieldIsRequired = determineFieldIsRequired(fieldName, data, isEditMode);
          return (
            <div style={{ padding: "10px 0px" }} key={fieldName}>
              <div>
                {data[fieldName].label}
                {fieldIsRequired && <span style={{ color: "red" }}>*</span>}
              </div>
              <TextField
                required={data[fieldName].isRequired}
                value={fieldValues[fieldName]}
                onChange={(event) => {
                  setFunctions[fieldName](event.target.value);
                  removeErrorByKey(fieldName);
                }}
                id="outlined-required"
                variant="outlined"
                fullWidth
                placeholder={data[fieldName].placeholder}
                type={data[fieldName].type} // Pass the type from JSON data to the type prop of TextField
                disabled={isFieldDisabled} // Enable or disable based on disabledValues prop
              />
              {errors[fieldName] && <div style={{ color: "red" }}>{errors[fieldName]}</div>}
            </div>
          );
        }
        return null; // Don't render the field if its corresponding key is not present in fieldValues
      })}
    </div>
  );
};

export default DynamicFormFields;
