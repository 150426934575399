import {
  USERACCESSRIGHTS_FILTERBY,
  USERACCESS_BATCHEDIT,
  OPEN_ACCESSDRAWER,
  CLOSE_ACCESSDRAWER,
  CHANGE_EXPIRYDATE,
  SET_ACCESSREQUESTID,
  FILTERBY_UPDATED,
  DATABASEUSERS_CLEARALL,
  FILTERBY_SELECTEDVALUES,
  CHANGE_SORTBYVALUE,
  SINGLEUSER_EXPIRYDATEEDITCLICK,
} from "../constants/constants";

const initialState = {
  filterValue: "",
  sortByValue: "",
  orderByValue: "",
  batchEdit: false,
  batchEditCurVal: "",
  accessDetailsDrawer: "",
  expiryDate: "",
  expiryDateUpdated: "",
  accessRequestID: "",
  FilterArr: [],
  filterBySelectedValues: [],
  dataBaseUsersFilterClear: { value: false },
  sigleUserExpiryDateChange: false,
  calenderToggle: false,
  singleUserCurrentValue: "",
  AccessDetailsSideDrawerData: {},
  calenderSideMenu: "",
  sortClicked: { isClicked: false, sortDirection: "ASC", currentSortValue: "" },
  location: "",
  dbUsersSearch: "",
  expiryFromDate: "",
  expiryToDate: "",
};

export const userAccessRIghtsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERACCESSRIGHTS_FILTERBY: {
      return {
        ...state,
        filterValue: action.payload,
        FilterArr: [],
      };
    }
    case USERACCESS_BATCHEDIT: {
      return {
        ...state,
        batchEdit: !state.batchEdit,
        batchEditCurVal: action.payload,
      };
    }
    case SINGLEUSER_EXPIRYDATEEDITCLICK: {
      return {
        ...state,
        sigleUserExpiryDateChange: true,
        singleUserCurrentValue: action.payload,
        calenderToggle: true,
      };
    }
    case OPEN_ACCESSDRAWER: {
      return {
        ...state,
        accessDetailsDrawer: true,
        AccessDetailsSideDrawerData: action.payload,
      };
    }
    case CLOSE_ACCESSDRAWER: {
      return {
        ...state,
        accessDetailsDrawer: false,
        AccessDetailsSideDrawerData: {},

      };
    }
    case CHANGE_EXPIRYDATE: {
      return {
        ...state,
        expiryDate: action.payload,
        expiryDateUpdated: true,
      };
    }
    case SET_ACCESSREQUESTID: {
      return {
        ...state,
        accessRequestID: action.payload,
      };
    }
    case FILTERBY_UPDATED: {
      return {
        ...state,
        FilterArr: action.payload,
      };
    }
    case DATABASEUSERS_CLEARALL: {
      return {
        ...state,
        filterValue: "",
        sortByValue: "",
        FilterArr: [],
        filterBySelectedValues: [],
        orderByValue: "",
        dataBaseUsersFilterClear: { value: true },
      };
    }
    case "SET_CLEAR_ALL_TO_FALSE": {
      return {
        dataBaseUsersFilterClear: { value: false },
      };
    }
    case FILTERBY_SELECTEDVALUES: {
      return {
        ...state,
        filterBySelectedValues: action.payload,
      };
    }
    case "UPDATE_EXPIRY_DATES": {
      return {
        ...state,
        expiryFromDate: action.payload.expiryFromDate,
        expiryToDate: action.payload.expiryToDate,
      };
    }
    case CHANGE_SORTBYVALUE: {
      return {
        ...state,
        sortByValue: action.payload,
      };
    }
    case "CHANGE_ORDER_BY_VALUE": {
      return {
        ...state,
        orderByValue: action.payload,
      };
    }
    case "CLOSECALENDER": {
      return {
        ...state,
        calenderToggle: false,
        singleUserCurrentValue: "",
        batchEditCurVal: "",
        calenderSideMenu: action.payload,
      };
    }
    case "SELECTEDDATE_CLEANUP": {
      return {
        ...state,
        expiryDate: "",
        expiryDateUpdated: "",
      };
    }
    case "RESET_BATCHEDIT_CLICK": {
      return {
        ...state,
        batchEdit: false,
      };
    }
    case "DATABASE_USERS_HEADERCLICKHANDLER": {
      console.log(action.payload);
      action.payload = action.payload.split(" ").join("");

      switch (action.payload) {
        case "DatabaseUsername": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "username",
            },
          };
        }
        case "DataSourceName": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "dataSourceName",
            },
          };
        }
        case "DatabaseAccessRoles": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "databaseRoleName",
            },
          };
        }

        case "AccountStatus": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "accountStatus",
            },
          };
        }
        case "ExpiryDate": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "expiryDate",
            },
          };
        }
        default:
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: state.sortClicked.currentSortValue,
            },
          };
      }
    }
    case "CLEAR_DATABASEUSERS_HEADERSORT": {
      return {
        ...state,
        sortClicked: {
          isClicked: false,
          sortDirection: "ASC",
          currentSortValue: "",
        },
      };
    }
    case "UPDATELOCATION": {
      return {
        ...state,
        location: action.payload,
      };
    }

    case "UPDATE_SERCHVALUE": {
      return {
        ...state,
        dbUsersSearch: action.payload,
      };
    }

    default:
      return state;
  }
};
