import axios from "axios";
import {} from "../../../redux/constants/constants";

export const ApiCall = async (obj, type) => {
  try {
    if (type === "SIGNUPPAGE_SELFSUBMIT" || type === "NO_AUTH_REQUIRED") {
      const res = await axios(obj);
      console.log(res);
      return res;
    } else {
      const myStorage = window.localStorage;
      let token = myStorage.getItem("access_token");
      if (token) {
        const res = await axios(obj);
        console.log(res);
        return res;
      }
    }
  } catch (error) {
    console.log(JSON.stringify(error));
    return JSON.stringify(error);
  }
};
