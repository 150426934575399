import { put, select, takeEvery } from 'redux-saga/effects';
import { getCubejsApi, redirectToLogin } from '../../components/common/Dashboard/cubejs-util';
import { isEmpty, isNull } from "lodash";
import {
  GET_DATA_OWNER_DATA_FIELDS_REQUEST,
  GET_DATA_OWNER_HEADER_DATA_REQUEST,
  SET_DATA_OWNER_FIELDS_DATA,
  SET_HEADER_DATA,
  SET_LOADER,
  SET_TOTAL_DATA_SOURCE_COUNT
} from '../constants/constants';

//If the value is less than 0
const checkValue = (value) => {
  if (isNull(value) || value < 0) {
    return 0;
  } else {
    return parseInt(value);
  }
};

// Function to prepare sensitive data object
function getSensitiveDataObj(sensitiveFiles) {
  const obj = {
    sensitiveData: {}
  };

  // Check if sensitiveFiles array is not empty
  if (!isEmpty(sensitiveFiles)) {
    const sensitiveData = obj.sensitiveData;

    // Update sensitive data properties with values from sensitiveFiles array
    sensitiveData.sensitivity_count_high = checkValue(sensitiveFiles[0]["DataOwner.sumHighSensitivity"]);
    sensitiveData.sensitivity_count_medium = checkValue(sensitiveFiles[0]["DataOwner.sumMediumSensitivity"]);
    sensitiveData.sensitivity_count_low = checkValue(sensitiveFiles[0]["DataOwner.sumLowSensitivity"]);
    sensitiveData.sensitivity_count_not_processed = checkValue(sensitiveFiles[0]["DataOwner.sumNotProcessedSensitivity"]);

    // Calculate the total sensitivity count
    sensitiveData.total_sensitivity_count =
      sensitiveData.sensitivity_count_high +
      sensitiveData.sensitivity_count_medium +
      sensitiveData.sensitivity_count_low +
      sensitiveData.sensitivity_count_not_processed;
  }

  return obj;
}

// Function to prepare alerts data object
function getAlertsDataObj(allAlertsCount) {
  const obj = {
    alertsData: {
      alert_count_high: 0,
      alert_count_medium: 0,
      alert_count_low: 0,
    },
  };

  // Check if allAlertsCount array is not empty
  if (!isEmpty(allAlertsCount)) {
    const alertData = obj.alertsData;

    // Update alerts data properties with values from allAlertsCount array
    alertData.alert_count_high = checkValue(allAlertsCount[0]["DataOwner.sumHighAlert"]);
    alertData.alert_count_medium = checkValue(allAlertsCount[0]["DataOwner.sumMediumAlert"]);
    alertData.alert_count_low = checkValue(allAlertsCount[0]["DataOwner.sumLowAlert"]);
  }

  return obj;
}

// Function to prepare user alerts data object
function getUserAlertsObj(userAlertsCount) {
  const obj = {
    userAlerts: {
      HIGH: 0,
      MEDIUM: 0,
      LOW: 0,
    }
  };
  // Check if userAlertsCount array is not empty
  if (!isEmpty(userAlertsCount)) {
    const userAlert = obj.userAlerts;
    // Update userAlert data properties with values from userAlertsCount array
    userAlert.HIGH = checkValue(userAlertsCount[0]["DataOwner.sumUserHighAlert"]);
    userAlert.MEDIUM = checkValue(userAlertsCount[0]["DataOwner.sumUserMediumAlert"]);
    userAlert.LOW = checkValue(userAlertsCount[0]["DataOwner.sumUserLowAlert"]);
   
  }
  return obj;
}

// Function to prepare the header data by combining sensitive data, alerts data, and user alerts data
function getHeaderData(allSensitiveData, allAlertsData, usersWithAlertsData) {
  const sensitiveFiles = allSensitiveData ? allSensitiveData.rawData() : [];
  const allAlertsCount = allAlertsData ? allAlertsData.rawData() : [];
  const userAlertsCount = usersWithAlertsData ? usersWithAlertsData.rawData() : [];

  // Getting individual data objects for sensitive data, alerts data, and user alerts data
  const sensitiveDataObj = getSensitiveDataObj(sensitiveFiles);
  const alertsDataObj = getAlertsDataObj(allAlertsCount);
  const userAlertsObj = getUserAlertsObj(userAlertsCount);

  // Combining the individual data objects into a single header data object
  const headerData = {
    sensitiveData: sensitiveDataObj.sensitiveData,
    alertsData: alertsDataObj.alertsData,
    userAlerts: userAlertsObj.userAlerts
  };

  return headerData;
}

// Saga to fetch header data
function* getDataOwnerHeaderData() {
  yield put({ type: SET_LOADER, payload: true });

  const allAlerts = {
    "measures": [
      "DataOwner.sumHighAlert",
      "DataOwner.sumMediumAlert",
      "DataOwner.sumLowAlert",
    ],
  };

  const allSensitiveFiles = {
    "measures": [
      "DataOwner.sumHighSensitivity",
      "DataOwner.sumMediumSensitivity",
      "DataOwner.sumLowSensitivity",
      "DataOwner.sumNotProcessedSensitivity"
    ]
  };

  const userWithAlerts = {
    "measures": [
      "DataOwner.sumUserHighAlert",
      "DataOwner.sumUserMediumAlert",
      "DataOwner.sumUserLowAlert",
    ],
  };

  const cubejsApi = getCubejsApi();

  try {
    const [allSensitiveData, allAlertsData, usersWithAlertsData] = yield Promise.all([
      cubejsApi.load(allSensitiveFiles),
      cubejsApi.load(allAlerts),
      cubejsApi.load(userWithAlerts)
    ]);

    const headerData = getHeaderData(allSensitiveData, allAlertsData, usersWithAlertsData);

    yield put({
      type: SET_HEADER_DATA,
      payload: headerData
    });
  } catch (error) {
    redirectToLogin(error);
  }
}


// Process the response data to create formatted rows
function getResponseData(response) {
  return response.map((field) => {
    const highSensitivity = checkValue(field && field["DataOwner.high_sensitivity"]);
    const mediumSensitivity = checkValue(field && field["DataOwner.medium_sensitivity"]);
    const lowSensitivity = checkValue(field && field["DataOwner.low_sensitivity"]);
    const notProcessedSensitivity = checkValue(field && field["DataOwner.not_processed_sensitivity"]);
    const user_high_alert =  checkValue(field && field["DataOwner.user_high_alert"]);
    const user_medium_alert = checkValue(field && field["DataOwner.user_medium_alert"]);
    const user_low_alert = checkValue(field && field["DataOwner.user_low_alert"]);
    const unique_user_total_alert = checkValue(field && field["DataOwner.user_total_alert"]);
    const alert_count_high = checkValue(field && field["DataOwner.high_alert"]);
    const alert_count_medium = checkValue(field && field["DataOwner.medium_alert"]);
    const alert_count_low = checkValue(field && field["DataOwner.low_alert"]);

    return {
      nodeId: field && field["DataOwner.node_id"],
      dataSource: {
        id: field && field["DataOwner.data_source_id"],
        name: field && field["DataOwner.alias_name"],
        type: field && field["DataOwner.ds_type"],
      },
      nodeType: field && field["DataOwner.node_type"],
      added: field && field["DataOwner.added_on"],
      sensitiveData: {
        sensitivity_count_high: highSensitivity,
        sensitivity_count_low: lowSensitivity,
        sensitivity_count_medium: mediumSensitivity,
        sensitivity_count_not_processed: notProcessedSensitivity,
        total_sensitivity_count: highSensitivity + mediumSensitivity + lowSensitivity + notProcessedSensitivity
      },
      alertSegregation: {
        alert_count_high: alert_count_high,
        alert_count_medium: alert_count_medium,
        alert_count_low: alert_count_low,
      },
      usersWithAlerts: {
        user_high_alert: user_high_alert,
        user_medium_alert: user_medium_alert,
        user_low_alert: user_low_alert,
        user_total_count: user_high_alert + user_medium_alert + user_low_alert,
        unique_user_total_count: unique_user_total_alert
      },
      categories: {
        categoryNames: field && field["DataOwner.top_categories"],
        totalCategory: checkValue(field && field["DataOwner.categories"])
      },
      activities: checkValue(field && field["DataOwner.number_of_activity"]),
      asOn: field && field["DataOwner.as_on"],
    };
  });
}


// Saga to fetch data owner data
function* getDataOwnerData() {
  yield put({ type: SET_LOADER, payload: true });

  let { currentPage, pageSize } = yield select((state) => state.DataOwnerReducer);
  let limit = pageSize;
  let offset = currentPage * pageSize;

  const cubejsApiInstance = getCubejsApi();

  const totalSourceCount = {
    measures: [
      "DataOwner.totalSourceCount"
    ]
  };

  const dataOwnerData = {
    dimensions: [
      "DataOwner.node_id",
      "DataOwner.data_source_id",
      "DataOwner.alias_name",
      "DataOwner.node_type",
      "DataOwner.ds_type",
      "DataOwner.high_sensitivity",
      "DataOwner.low_sensitivity",
      "DataOwner.medium_sensitivity",
      "DataOwner.high_alert",
      "DataOwner.medium_alert",
      "DataOwner.low_alert",
      "DataOwner.not_processed_sensitivity",
      "DataOwner.user_high_alert",
      "DataOwner.user_medium_alert",
      "DataOwner.user_low_alert",
      "DataOwner.user_total_alert",
      "DataOwner.categories",
      "DataOwner.top_categories",
      "DataOwner.user_high_alert",
      "DataOwner.as_on",
      "DataOwner.added_on",
      "DataOwner.number_of_activity"
    ],
    limit,
    offset,
    order: {
      "DataOwner.node_id": "asc"
    }
  };

  let response;
  let dataSourcesCount;

  try {
    const [totalDataSourcesData, totalData] = yield Promise.all([
      cubejsApiInstance.load(totalSourceCount),
      cubejsApiInstance.load(dataOwnerData)
    ]);
    dataSourcesCount = totalDataSourcesData && totalDataSourcesData.rawData() || [];
    response = totalData && totalData.rawData() || [];

    const totalDataSourceCount = dataSourcesCount[0] && dataSourcesCount[0]["DataOwner.totalSourceCount"] || 0;
    const formattedRows = getResponseData(response);

    yield put({ type: SET_TOTAL_DATA_SOURCE_COUNT, payload: totalDataSourceCount });
    yield put({ type: SET_DATA_OWNER_FIELDS_DATA, payload: formattedRows });
    yield put({ type: SET_LOADER, payload: false });
  } catch (error) {
    redirectToLogin(error);
    return;
  }
}

// Saga watcher
function* DataOwnerSaga() {
  yield takeEvery(GET_DATA_OWNER_HEADER_DATA_REQUEST, getDataOwnerHeaderData);
  yield takeEvery(GET_DATA_OWNER_DATA_FIELDS_REQUEST, getDataOwnerData);
}

export default DataOwnerSaga;