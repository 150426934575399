import { SET_LOADER, SET_SNACKBAR, SET_SOURCE_DELETION_ERROR_MESSAGE, SET_CRITERIA, SHOW_DIALOG_BOX, SET_CRITERIA_ERROR_MESSAGE } from "../constants/constants";

const initialState = {
  loader: false,
  snackBar: false,
  deleteConnectionErrorMessage: "",
  criteria: false,
  dialogBox: false,
  criteriaErrorMessage: "",
};

export const DeleteConnectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case SET_SNACKBAR: {
      return {
        ...state,
        snackBar: action.payload,
      };
    }
    case SET_SOURCE_DELETION_ERROR_MESSAGE: {
      return {
        ...state,
        deleteConnectionErrorMessage: action.payload,
      };
    }
    case SET_CRITERIA: {
      return {
        ...state,
        criteria: action.payload,
      };
    }
    case SHOW_DIALOG_BOX: {
      return {
        ...state,
        dialogBox: action.payload,
      };
    }
    case SET_CRITERIA_ERROR_MESSAGE: {
      return {
        ...state,
        criteriaErrorMessage: action.payload
      }
    }
    default: 
      return state;
  }
};
