import {
  CHANGE_FILTERBYVALUE_ACCESSDETAILS,
  CHANGE_ACCESSDETAILS_FILTERBYARR,
  ACCESSDETAILS_FILTERBYARR_SELECTEDVALUES,
  ACCESSDETAILS_SORTBYVALUE_UPDATE,
} from "../constants/constants";

export const changeFilterByvalue = (payload) => {
  return {
    type: "",
    payload: payload,
  };
};

export const CHANGE_FILTERBYVALUE_ACCESSDETAILS_ACTION = (payload) => {
    console.log(payload);
  return {
    type: CHANGE_FILTERBYVALUE_ACCESSDETAILS,
    payload: payload,
  };
};

export const ACCESSDETAILS_FILTERBYARR_UPDATE_ACTION = (payload) => {
  //   console.log(payload);
  return {
    type: CHANGE_ACCESSDETAILS_FILTERBYARR,
    payload: payload,
  };
};

export const ACCESSDETAILS_FILTER_SELECTEDVALUES = (payload) => {
  return {
    type: ACCESSDETAILS_FILTERBYARR_SELECTEDVALUES,
    payload: payload,
  };
};

export const change_SortByValue_AccessDetails = (payload) => {
  return {
    type: ACCESSDETAILS_SORTBYVALUE_UPDATE,
    payload: payload,
  };
};
