import { call, put, takeEvery } from "redux-saga/effects";
import { UPDATE_POLICY_REQUEST } from "../constants/constants";
import { UPDATE_ALERT_POLICY_URL } from '../constants/constants';
import {getAxiosHeaders, getService} from "../../library/RestAPI";

function* updateAlertPolicy(action) {
    let options;
    const tenant = window.localStorage.getItem("tenant")
    try {
        options = {
            method: "PUT",
            url: `${UPDATE_ALERT_POLICY_URL}/${action.payload.id}`,
            headers: getAxiosHeaders(true),
            data: action.payload.data
        };
      try {
        const res = yield getService(options);
        if(res && res.status === 200) {
          yield put({ type: "UPDATE_POLICY_SUCCEEDED"});
          yield put({type: "TRACK_TRAVERSAL" , payload: 'Alert Policies'})
          action.payload.history.push(`/tenant/alertPolicies`)
        } else {
          yield put({
            type: "UPDATE_POLICY_FAILED",
            payload: "Update unsuccessful",
          });
        }
      } catch (error) {
        console.log(error);
        // myStorage.setItem("access_token", "error");
        yield put({
          type: "UPDATE_POLICY_FAILED",
          payload: "Error Fetching Data from API",
        });
      }
    } catch (e) {
      yield put({ type: "UPDATE_POLICY_FAILED" });
    }
  }
  
  function* updateAlertPolicySaga() {
    yield takeEvery(UPDATE_POLICY_REQUEST, updateAlertPolicy);
  }
  
  export default updateAlertPolicySaga;