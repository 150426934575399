import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Input, Grid } from "@material-ui/core";
import {
  DateRange
} from "react-date-range";
import "./customCalender.scss";
import {
  addDays,
  startOfDay,
  add,
  startOfWeek,
  endOfWeek,
  addMonths,
  endOfMonth,
  startOfMonth,
} from "date-fns";
import moment from "moment";
import MaskedInput from "react-text-mask";
import { dateFormat } from '../../../redux/constants/constants'

const defineds = {
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),
  endOfNext3Month: endOfMonth(addMonths(new Date(), 3)),
  endOfNext6Month: endOfMonth(addMonths(new Date(), 6)),
  after1week: add(new Date(), { weeks: 1 }),
  after1Month: add(new Date(), { months: 1 }),
  after3Months: add(new Date(), { months: 3 }),
  after6Months: add(new Date(), { months: 6 }),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
};


const dateInput = (props) => {
  const { inputRef, ...other } = props;
  const checkMonth = (value) => {
    if (value[0] === "1") {
      return /[0-2]/;
    } else {
      return /[0-9]/;
    }
  }
  const checkDate = (value) => {
    if (value[3] === "3") {
      return /[0-1]/;
    } else {
      return /[0-9]/;
    }
  }
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-1]/, checkMonth(other.value), "/", /[0-3]/, checkDate(other.value), "/", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholder={dateFormat}
    />
  );
}

export const DatabaseDateRangePicker = (props) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [fromDateValue, setFromDateValue] = useState(new Date());
  const [toDateValue, setToDateValue] = useState(new Date());

  useEffect(() => {
    if (props.fromTimeValue && props.toTimeValue) {
      setState([{
        startDate: new Date(props.fromTimeValue),
        endDate: new Date(props.toTimeValue),
        key: "selection"
      }])
      setFromDateValue(props.fromTimeValue)
      setToDateValue(props.toTimeValue)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setFromDateValue(selection.startDate)
    setToDateValue(selection.endDate)
    setState([selection]);
    props.handleTimeRange(selection.startDate,selection.endDate)
  };

  const returnDateRanges = () => {
    return (
      <>
        <div className='week' onClick={() => handleOnChange({
          selection: {
            endDate: defineds.endOfLastMonth,
            key: "selection",
            startDate: defineds.startOfLastMonth
          }
        })}>Past Month</div>
        <div className='week' onClick={() => handleOnChange({
          selection: {
            endDate: defineds.endOfLastWeek,
            key: "selection",
            startDate: defineds.startOfLastWeek
          }
        })}>Past Week</div>
        <div className='week' onClick={() => handleOnChange({
          selection: {
            endDate: defineds.endOfWeek,
            key: "selection",
            startDate: defineds.startOfWeek
          }
        })}>This Week</div>
        <div className='month' onClick={() => handleOnChange({
          selection: {
            endDate: defineds.endOfMonth,
            key: "selection",
            startDate: defineds.startOfMonth
          }
        })}>This Month</div>

        <div className='month' onClick={() => handleOnChange({
          selection: {
            endDate: defineds.endOfNextMonth,
            key: "selection",
            startDate: defineds.startOfNextMonth
          }
        })}>Next Month</div>
        <div className='month' onClick={() => handleOnChange({
          selection: {
            endDate: defineds.endOfNext3Month,
            key: "selection",
            startDate: defineds.startOfNextMonth
          }
        })}>Next 3 Months</div>
      </>
    )
  }

  const getFromAndToInputFields = () => {
    return (
      <Grid container >
        <Grid item xs={12}>
          <div className="time-range-container">
            <Grid container>
              <Grid item xs={6}>
                <div className="time-range-picker">
                  <Grid container >
                    <Grid item xs={4}>
                      <span className="range-text">From</span>
                    </Grid>
                    <Grid item xs={8}>
                      <div className="timepicker-div">
                        <Input
                          value={moment(
                            fromDateValue
                          ).format(dateFormat)}
                          inputComponent={dateInput}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="time-range-picker">
                  <Grid container >
                    <Grid item xs={4}>
                      <span className="range-text">To</span>
                    </Grid>
                    <Grid item xs={8}>
                      <div className="timepicker-div">
                        <Input
                          value={moment(
                            toDateValue
                          ).format(dateFormat)}
                          inputComponent={dateInput}
                        >
                        </Input>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className="database-access-picker">
      <div className="header-text">Select a Date Range</div>
      <DateRange
        moveRangeOnFirstSelection={false}
        onChange={handleOnChange}
        ranges={state}
        showDateDisplay={false}
      />
      {getFromAndToInputFields()}
      <div className='quick-range-heading'>Quick Ranges</div>
      {returnDateRanges()}
    </div>
  );
};
