import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./IncidentTableStyles.scss";
import UseIncidentFilterHook from "./IncidentFilterHook";
import MdrSortHook from "./MdrSortHook";
import {
  UPDATE_INCIDENTPRIORITY_FILTER_DATA_ACTION,
  UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA_ACTION,
  UPDATE_INCIDENT_COUNTRY_FILTER_DATA_ACTION,
  UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA_ACTION,
  UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA_ACTION
} from "../../../../redux/actions/MDRActions/IncidentDashboardAction";
import { getFilterTimeStamp } from "../../../common/utilities"

import {
  incidentTableFilters,
  incidentTableFilter_Query_toGet_FilterValues,
  STATE,
  SET_INCIDENT_STATE_VALUE,
  INCIDENT_STATE_FILTER_CLEAR_DATA,
  INCIDENT_STATE_DETAILS_FILTER_CLEAR_DATA,
} from "../../../../redux/constants/constants";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const IncidentTableFilter = () => {
  let filterData1 = useSelector(
    (state) => state.IncidenetTableFilterReducer.Priority
  );
  let assignee = useSelector(
    (state) => state.IncidenetTableFilterReducer.Assignee
  );
  let filterData2 = useSelector(
    (state) => state.IncidenetTableFilterReducer.EffectiveStatus
  );
  let stateDetailsFilterData = useSelector(
    (state) => state.IncidenetTableFilterReducer.StateDetails
  );
  let cityFilterData = useSelector(
    (state) => state.IncidenetTableFilterReducer.City
  );
  const dateTimeRange = useSelector(
    (state) => {
      return state.selectedDateTimeRange.dateTimeRange;
    }
  );
  let incidentStateValue = useSelector(
    (state) => state.IncidenetTableFilterReducer.stateValue
  );

  const dispatch = useDispatch();
  const incidentFilterValues = [
    { name: 'State', value: 'state' },
    { name: 'State Details', value: 'stateDetails' }
  ]

  let [currentDropdownValue, setCurrentDropdownValue] = useState(incidentStateValue);
  const changeIncidentSearchFieldDropDown = (e) => {
    const value = e.target.value;
    // Clear the state details checked values while selected filter value is state
    if (value === STATE) {
      dispatch({ type: INCIDENT_STATE_DETAILS_FILTER_CLEAR_DATA });
    } else {
      // Clear the state checked values while selected filter value is state details
      dispatch({ type: INCIDENT_STATE_FILTER_CLEAR_DATA });
    }
    dispatch({
      type: SET_INCIDENT_STATE_VALUE,
      value: value
    })
    setCurrentDropdownValue(value);
  };

  const {startDt, endDt} = getFilterTimeStamp(dateTimeRange);
  const incidentTableFilter_Query_cache = incidentTableFilter_Query_toGet_FilterValues(startDt, endDt);

  return (
    <>
      <div className="IncidentFilter">
        <p>Sort</p>
        <MdrSortHook />
      </div>
      <div className="IncidentFilter">
        <p>Priority</p>
        <div className="PriorityFilter">
        <UseIncidentFilterHook
          query={incidentTableFilter_Query_cache.priorityFilter}
          action={UPDATE_INCIDENTPRIORITY_FILTER_DATA_ACTION}
          filterType={incidentTableFilters.Priority}
          filterData={filterData1}
          className="PriorityFilter_Containter"
        ></UseIncidentFilterHook>
        </div>
        <p>Assignee</p>
        <div className="PriorityFilter">
        <UseIncidentFilterHook
          query={incidentTableFilter_Query_cache.assigneeFilter}
          action={UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA_ACTION}
          filterType={incidentTableFilters.Assignee}
          filterData={assignee}
          className="PriorityFilter_Containter"
        ></UseIncidentFilterHook>
        </div>
      </div>
      <div className="IncidentStateFilter">
        <Select
          value={currentDropdownValue}
          onChange={(event) => changeIncidentSearchFieldDropDown(event)}
          className="user-type-dropdown"
        >
          {incidentFilterValues.map((obj) => {
            return (
              <MenuItem value={obj.value}>{obj.name}</MenuItem>
            );
          })}
        </Select>
        {currentDropdownValue === STATE ?
          <UseIncidentFilterHook
            query={incidentTableFilter_Query_cache.effectiveStatusFilter}
            action={UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA_ACTION}
            filterType={incidentTableFilters.effectiveStatusFilter}
            filterData={filterData2}
            className="effectiveStatus_filter"
          ></UseIncidentFilterHook> :
          <UseIncidentFilterHook
            query={incidentTableFilter_Query_cache.stateDetailsFilter}
            action={UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA_ACTION}
            filterType={incidentTableFilters.StateDetails}
            filterData={stateDetailsFilterData}
            className="effectiveStatus_filter"
          ></UseIncidentFilterHook>
        }
      </div>
      <div className="IncidentFilter">
        <p>City</p>
        <UseIncidentFilterHook
          query={incidentTableFilter_Query_cache.countryFilter}
          action={UPDATE_INCIDENT_COUNTRY_FILTER_DATA_ACTION}
          filterType={incidentTableFilters.City}
          filterData={cityFilterData}
        ></UseIncidentFilterHook>
      </div>
    </>
  );
};

export default IncidentTableFilter;
