import { SAVE_ALERT_POLICY_COMMENT, SAVE_ALERT_POLICY_COMMENT_URL } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function saveAlertPolicyComment(id,comment) {
    let commentData = {
        comments:`${comment}`
    }
    const options = {
        method: "POST",
        url: `${SAVE_ALERT_POLICY_COMMENT_URL}/${id}/comments`,
        headers: getAxiosHeaders(true),
        data: commentData
    };

    const response = await getService(options);
    return response;
};
