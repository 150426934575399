import React, { useEffect, useState } from 'react'
import { SHARE_REPORT } from '../../../redux/constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import { Button, Dialog, DialogActions, DialogContent, TextField, makeStyles } from '@material-ui/core';
import DisabledShareIcon from '../../../assets/vizr_images/disabledShareIcon.svg';
import ShareIcon from '../../../assets/vizr_images/shareIcon.svg';
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import CloseIcon from '../../../assets/vizr_images/ic_remove.svg';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '360px !important',
    marginTop: "15px !important",
    "& input": {
      padding: "5px !important",
      '&::placeholder': {
        fontSize: "14px",
        fontWeight: "600"
      },
    }
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "15px",
  },
  sendReportButton: {
    color: "#ffffff !important",
    backgroundColor: "#081981 !important",
    cursor: "pointer",
    width: "104px",
    height: "32px",
    padding: "10px 8px !important",
    marginTop: "18px !important",
    textTransform: "none !important",
    fontSize: "14px",
    fontWeight: "700 !important",
    lineHeight: "16px",
    boxShadow: "1px 2px 2px 0px rgba(0, 0, 0, 0.30)",
    '&:hover': {
      backgroundColor: "#061368",
    },
    '&.Mui-disabled': {
      color: "rgba(0, 0, 0, 0.26) !important",
      backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    }
  },
  blurredBackground: {
    backdropFilter: 'blur(1px)',
  },
}));
function ShareReport(props) {
  const {rowSelected, handleClearCheck, loc, id} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [email, setEmail] = useState([]);
  const userEmails = useSelector(
    (state) => state.GenerateReportReducer.userEmails
  );
  const shareReportMessage = useSelector(
    (state) => state.GenerateReportReducer.shareReportMessage
  );
  const shareReportloader = useSelector(
    (state) => state.GenerateReportReducer.shareReportloader
  );
  const handleClose = () => {
    setEmail([]);
    if( loc === "reportRepository"){
      handleClearCheck();
    }
    setShareDialogOpen(false);
  };

  useEffect(() => {
    if(shareReportMessage && !isEmpty(shareReportMessage.message)){
      handleClose();
    }
  },[shareReportMessage])

  const handleEmailChange = (event, value) => {
    setEmail(value);
  };
  const reportId = () => {
    if(loc === "reportRepository" && rowSelected){
      return rowSelected;
    } else {
      return id;
    }
  }
  const sendReport = () => {
    if (email.length) {
      dispatch({ type: SHARE_REPORT, payload: { reportId: reportId(), emails: email } });
    }
  }
  const renderPlaceholder = () => {
    if (email && !email.length) {
      return "Enter email id or select from DDV users"
    } else {
      return null;
    }
  }
  const renderUserEmails = () => {
    if (!isEmpty(userEmails)) {
      return (
        <>
          <Autocomplete
            options={userEmails || []}
            multiple={true}
            value={email}
            onChange={(event, value) => handleEmailChange(event, value)}
            renderInput={(params) => (
              <TextField placeholder={renderPlaceholder()} {...params} size="small" variant="outlined" className={classes.textField} />
            )}
          />
        </>
      )
    }
  }
  const shareReportDialog = () => {
    return (
      <>
        <Dialog
          open={shareDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={`${classes.blurredBackground} shareReportDialog`}
        >
          <DialogContent>
            {shareReportloader && (
              <img
                src={Page_Loader}
                alt="loading"
                className="loader-data-source"
              ></img>
            )}
            <div className='closeShareReport'>
              <div>
                <span>Share Report via Email</span>
                <span className='shareReportErrorMessage'>*</span>
              </div>
              <Button
                className="close-icon"
                onClick={handleClose}
                aria-label="close"
              >
                <img src={CloseIcon} alt={'close'} />
              </Button></div>

            {renderUserEmails()}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              type="submit"
              className={classes.sendReportButton}
              disabled={!email.length}
              onClick={() => sendReport()}
              variant="contained"
            >
              Send Report
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  const shareReport = () => {
      setShareDialogOpen(true);
  }
  const renderShareReportButton = () => {
    if (loc === "reportRepository" && rowSelected && !rowSelected.length) {
      return (
        <div
          className='shareButton'
          title='Please select a report'>
          <Button
            variant='contained'
            disabled={true}
          >
            <img src={DisabledShareIcon} alt={'Disabled'} />
          </Button>
        </div>
      )
    } else {
      return (
        <div className='shareButton'>
          <Button
            onClick={(event) => {
              shareReport()
            }}
            aria-label="close"
            title='Share report'
            variant='contained'
          >
            <img src={ShareIcon} alt={'Share Report'} />
          </Button>
        </div>
      )
    }
  }
  return (
    <div>
      {renderShareReportButton()}
      {shareReportDialog()}
    </div>
  )
}

export default ShareReport