import {
  ONEDRIVE,
  POSTGRESQL,
} from "../../../redux/constants/constants";

export const renderDataSourceIcons = (
  isEditMode,
  iconMap,
  handleSelectionChange,
  engine,
  dataSourceData
) => {
  const renderIconClassNames = (value) => {
    if (engine === POSTGRESQL && value === "On-Premise") {
      return "postgresOnIconImg";
    } else if (engine === POSTGRESQL && value === "Azure") {
      return "postgresAzureIconImg";
    } else {
      return null;
    }
  };

  const renderIconDisabled = (value) => {
    const { name } = value;
    if (isEditMode) {
      if (
        (name === "One Drive" && dataSourceData.oneDrive) ||
        (name === "Sharepoint" && dataSourceData.sharePoint)
      ) {
        return true;
      }
    }
    return false;
  };
  
  return (
    <div className="serverIconContainer">
      {/* Iterate through each icon in the iconMap */}
      {Object.entries(iconMap).map(([altText, iconData], index) => (
        <div className={!renderIconDisabled(iconData) ? "iconsContainer" : "iconsContainer disabled"} key={index}>
          {/* Icon container */}
          <div
            className={`sourcesIcons ${
              iconData.selected ? "iconSelected" : "iconNotSelected"
            }`}
            onClick={() =>
              renderIconDisabled(iconData.name)
                ? null
                : handleSelectionChange(altText, true)
            }
          >
            <div>
              <img
                src={iconData.icon}
                alt={altText}
                className={renderIconClassNames(iconData.name)}
              />
            </div>
          </div>
          {/* Icon title */}
          <label className="IconTitle">
            {/* Radio button for selecting the icon */}
            <input
              type={iconData.type}
              value={altText}
              checked={iconData.selected}
              onChange={() => handleSelectionChange(altText, true)}
              disabled={renderIconDisabled(iconData, isEditMode)}
              style={{ transform: "scale(1.5)" }}
            />
            {/* Icon name */}
            {iconData.name}
          </label>
        </div>
      ))}
    </div>
  );
};
