import React from "react";
import Typography from "@material-ui/core/Typography";
import { getCubejsApi, redirectToLogin } from "./cubejs-util";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import numeral from "numeral";
import * as am4core from "@amcharts/amcharts4/core";
import { cloneDeep } from "lodash";
import { addingDateRangetoTableQueries } from "./MDRHelpers";
import {
  incidentDashboardPieChart,
  AuthenticationFailedSourcePieChart,
  AuthenticationFailedIpPieChart,
  DASHBOARD_TYPES,
} from "../../../redux/constants/constants";
import { EndPointsdashboardInstance } from "./dashboard-data";
const restrictDateRange = ["Non-Compliant Servers: Total", "Non-Compliant Servers: Unpatched", "Non-Compliant Patches: Needed", "Non-Compliant Servers: By Environment", "Non-Compliant Patches: Needed by Severity", "Non-Compliant Top 10 Unpatched Servers", "Non-Compliant Top 10 KBIDs by Most Servers"];
export const numberFormatter = (item) => numeral(item).format("0,0");
export const dateFormatter = (item, format) => {
  if (format) {
    return moment(item).format(format);
  }
  return moment(item).format("MM-DD-YYYY");
};


export const dateLessThanMonth = () => {
  // patches needed should show data in chart less than 30 days
  const days = 30;
  //time is passed as string to filters
  return new Date(new Date().setDate(new Date().getDate() - days)).getTime().toString();
}

function numFormatter(item) {
  let num = numeral(item)._value;
  if (num > 10000 && num <= 1000000) {
    return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + "B"; // convert to M for number from > 1 million
  } else if (num < 10000) {
    return num; // if value < 10000, nothing to do
  }
}

export const renderSingleValue = (resultSet, key, isMdr) =>
  key ? (
    <h3 height={200} className={isMdr ? "mdrRenderSingleValue" : ""}>
      {numFormatter(resultSet.chartPivot()[0][key])}
    </h3>
  ) : (
    <h3 height={200} className={isMdr ? "mdrRenderSingleValue" : ""}>
      {numFormatter(resultSet)}
    </h3>
  );

export const renderNoData = (marginTop) => (
  <Typography variant="body2" style={{ marginLeft: "20px", marginTop }}>
    No data available
  </Typography>
);

export const colorFinder = (prority) => {
  let p = prority ? prority.toLowerCase() : "";
  return p;
};

export const MDRFilterDataFormatter = (filterType, data, dimensions) => {
  data =
    data &&
    data.map((el) => {
      let obj = {};
      obj.data = el[dimensions];
      obj.isSelected = false;
      return obj;
    });
  return data;
};

export const MDRupdatedFilterData = (filterType, data, dimensions) => {
  data =
    data &&
    data.map((el) => {
      let obj = {};
      obj.data = el[dimensions];
      obj.isSelected = false;
      return obj;
    });
  return data;
};

export const loopingFunction = (filterdData) => {
  let arr = [];
  filterdData.length > 0 &&
    filterdData.forEach((el) => {
      if (el.isSelected) {
        arr.push(el.data);
      }
    });
  return arr;
};

const ClearFilterLoopingFunction = (data) => {
  let data2 =
    data && data.length > 0
      ? data.map((el) => {
          el.isSelected = false;
          return el;
        })
      : [];
  return data2;
};

export function clearMDRFilters() {
  let output = [];
  for (let i = 0; i < arguments.length; i++) {
    output.push(ClearFilterLoopingFunction(arguments[i]));
  }
  return output;
}

export function clearAllMDRFilters() {
  let output = [];
  for (let i = 0; i < arguments.length; i++) {
    output.push(ClearFilterLoopingFunction(arguments[i]));
  }
  return output;
}

export const dateFormatterMDR = (date1, date2) => {
  let date = date1
    ? moment(date1).format("YYYY-MM-DD")
    : moment(date2).format("YYYY-MM-DD");

  return date;
};

export const incidentDashboard_ToggleCharts = (
  index,
  name,
  startDate,
  endDate
) => {
  if (index === 0) {
    if (name === "Today") {
      return {
        chartType: "MDRBarGraph",
        query: {
          dimensions: ["Incidents.priority"],
          timeDimensions: [
            {
              dimension: "Incidents.creation_date",
              granularity: "day",
              dateRange: [
                `${moment(endDate)
                  .subtract(7, "d")
                  .format("YYYY-MM-DDTHH:mm:ss.SS")}`,
                `${moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              ],
            },
          ],
          measures: ["Incidents.count"],
        },
      };
    } else if (name === "Weekly") {
      return {
        chartType: "MDRBarGraph",
        query: {
          dimensions: ["Incidents.priority"],

          timeDimensions: [
            {
              dimension: "Incidents.creation_date",
              dateRange: [
                `${moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
                `${moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              ],
              granularity: "week",
            },
          ],
          order: [["Incidents.creation_date", "asc"]],
          measures: ["Incidents.count"],
        },
      };
    } else if (name === "Monthly") {
      return {
        chartType: "MDRBarGraph",
        query: {
          dimensions: ["Incidents.priority"],

          timeDimensions: [
            {
              dimension: "Incidents.creation_date",
              dateRange: [
                `${moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
                `${moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              ],
              granularity: "month",
            },
          ],
          order: [["Incidents.creation_date", "asc"]],
          measures: ["Incidents.count"],
        },
      };
    }
  } else if (index === 1) {
    if (name === "Today") {
      return {
        chartType: "MDRAreaGraph",
        query: {
          measures: ["Incidents.count"],
          dimensions: ["Incidents.status"],
          timeDimensions: [
            {
              dimension: "Incidents.creation_date",
              granularity: "day",
              dateRange: [
                `${moment(endDate)
                  .subtract(7, "d")
                  .format("YYYY-MM-DDTHH:mm:ss.SS")}`,
                `${moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              ],
            },
          ],
        },
      };
    } else if (name === "Weekly") {
      return {
        chartType: "MDRAreaGraph",
        query: {
          measures: ["Incidents.count"],
          dimensions: ["Incidents.status"],
          timeDimensions: [
            {
              dimension: "Incidents.creation_date",
              dateRange: [
                `${moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
                `${moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              ],
              granularity: "week",
            },
          ],
        },
      };
    } else if (name === "Monthly") {
      return {
        chartType: "MDRAreaGraph",
        query: {
          measures: ["Incidents.count"],
          dimensions: ["Incidents.status"],
          timeDimensions: [
            {
              dimension: "Incidents.creation_date",
              dateRange: [
                `${moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
                `${moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              ],
              granularity: "month",
            },
          ],
        },
      };
    }
  }
};

export const endPointsDashboard_ToggleCharts = (
  index,
  name,
  startDate,
  endDate
) => {
  if (index === 0) {
    if (name === "Today") {
      return {
        chartType: "MDRBarGraph",
        type: "MDREndpoints",
        query: {
          measures: ["MDREndPoints.count"],
          timeDimensions: [
            {
              dimension: "MDREndPoints.creation_time",
              granularity: "day",
              dateRange: "Last 7 days",
            },
          ],
          filters: [
            {
              member: `MDREndPoints.incidentTime`,
              operator: "gte",
              values: [`${startDate}`],
            },
            {
              member: `MDREndPoints.incidentTime`,
              operator: "lte",
              values: [`${endDate}`],
            },
          ],
        },
      };
    } else if (name === "Weekly") {
      return {
        chartType: "MDRBarGraph",
        type: "MDREndpoints",
        query: {
          measures: ["MDREndPoints.count"],

          timeDimensions: [
            {
              dimension: "MDREndPoints.creation_time",
              granularity: "week",
            },
          ],
          filters: [
            {
              member: `MDREndPoints.incidentTime`,
              operator: "gte",
              values: [`${startDate}`],
            },
            {
              member: `MDREndPoints.incidentTime`,
              operator: "lte",
              values: [`${endDate}`],
            },
          ],
        },
      };
    } else if (name === "Monthly") {
      return {
        chartType: "MDRBarGraph",
        type: "MDREndpoints",
        query: {
          measures: ["MDREndPoints.count"],

          timeDimensions: [
            {
              dimension: "MDREndPoints.creation_time",
              granularity: "month",
            },
          ],
          filters: [
            {
              member: `MDREndPoints.incidentTime`,
              operator: "gte",
              values: [`${startDate}`],
            },
            {
              member: `MDREndPoints.incidentTime`,
              operator: "lte",
              values: [`${endDate}`],
            },
          ],
        },
      };
    }
  } else if (index === 1) {
    return {
      chartType: "MDRSortedBarCharts",
      type: "MDREndpoints",
      query: {
        dimensions: ["MDREndPoints.hostName", "MDREndPoints.score"],
        order: [["MDREndPoints.score", "desc"]],
        measures: [],
        limit: 10,
        filters: [
          {
            member: `MDREndPoints.incidentTime`,
            operator: "gte",
            values: [`${startDate}`],
          },
          {
            member: `MDREndPoints.incidentTime`,
            operator: "lte",
            values: [`${endDate}`],
          },
        ],
      },
    };
    // if (name === "Today") {
    //   return {
    //     chartType: "MDRSortedBarCharts",
    //     type: "MDREndpoints",
    //     query: {
    //       dimensions: ["MDREndPoints.hostName", "MDREndPoints.score"],
    //       timeDimensions: [
    //         {
    //           dimension: "MDREndPoints.creation_time",
    //           granularity: "day",
    //           dateRange: "Last 7 days",
    //         },
    //       ],
    //       order: [["MDREndPoints.score", "desc"]],
    //       measures: [],
    //       limit: 10,
    //       filters: [
    //         {
    //           member: `MDREndPoints.creation_time`,
    //           operator: "inDateRange",
    //           values: [startDate, endDate],
    //         },
    //       ],
    //     },
    //   };
    // } else if (name === "Weekly") {
    //   return {
    //     chartType: "MDRSortedBarCharts",
    //     type: "MDREndpoints",
    //     query: {
    //       dimensions: ["MDREndPoints.hostName", "MDREndPoints.score"],
    //       timeDimensions: [
    //         {
    //           dimension: "MDREndPoints.creation_time",
    //           granularity: "week",
    //         },
    //       ],
    //       order: [["MDREndPoints.score", "desc"]],
    //       measures: [],
    //       limit: 10,
    //       filters: [
    //         {
    //           member: `MDREndPoints.creation_time`,
    //           operator: "inDateRange",
    //           values: [startDate, endDate],
    //         },
    //       ],
    //     },
    //   };
    // } else if (name === "Monthly") {
    //   return {
    //     chartType: "MDRSortedBarCharts",
    //     type: "MDREndpoints",
    //     query: {
    //       dimensions: ["MDREndPoints.hostName", "MDREndPoints.score"],
    //       timeDimensions: [
    //         {
    //           dimension: "MDREndPoints.creation_time",
    //           granularity: "month",
    //         },
    //       ],
    //       order: [["MDREndPoints.score", "desc"]],
    //       measures: [],
    //       limit: 10,
    //       filters: [
    //         {
    //           member: `MDREndPoints.creation_time`,
    //           operator: "inDateRange",
    //           values: [startDate, endDate],
    //         },
    //       ],
    //     },
    // };
    // }
  }
};
export const endPointNewDashboardInstanceforToggling = (payload) => {
  let newDashBoardInstance = [...EndPointsdashboardInstance];

  //! use destructuring to create new instance for that object
  let newObj = { ...newDashBoardInstance[7] };

  let newObj1 = { ...newObj.vizState };
  newObj1 = {
    chartType: payload.chartType,
    query: payload.query,
  };
  newObj.vizState = newObj1;
  newDashBoardInstance[7] = newObj;
  return newDashBoardInstance;
};
let colors = [
  "#081981",
  "#2CE68F",
  "#FF5478",
  "#FF2251",
  "#087B81",
  "#FFDF60",
  "#498BD7",
  "#FF87A1",
  "#48A04B",
  "#91DFFF",
  "#66C569",
  "#FF1111",
  "#3B5C81",
  "#FF8421",
  "#5279CD",
];

const colorFunction = (priority) => {
  if (priority === "Critical") {
    return "#FF2251";
  } else if (priority === "High") {
    return "#FF5478";
  } else if (priority === "Medium") {
    return "#FFC344";
  } else if (priority === "Low") {
    return "#91DFFF";
  }
};

const generateColor = () => {
  return "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
};

const findRandomColoFunction = (randomNumberArr, index) => {
  // let randomNumber = Math.floor(Math.random() * 15);
  // if (randomNumberArr.length > 0) {
  //   if (randomNumberArr.length <= 15) {
  //     while (randomNumberArr.indexOf(randomNumber) !== -1) {
  //       randomNumber = Math.floor(Math.random() * 15);
  //     }
  //     randomNumberArr.push(randomNumber);
  //     return colors[randomNumber];
  //   } else if (randomNumberArr.length > 15) {
  //     let newrandomNumberArr = [
  //       randomNumberArr[0],
  //       randomNumberArr[randomNumberArr.length - 1],
  //     ];
  //     randomNumberArr = [...newrandomNumberArr];
  //     while (randomNumberArr.indexOf(randomNumber) !== -1) {
  //       randomNumber = Math.floor(Math.random() * 15);
  //     }
  //     randomNumberArr.push(randomNumber);
  //     return colors[randomNumber];
  //   }
  // } else {
  //   randomNumberArr.push(randomNumber);
  //   return colors[randomNumber];
  // }
  if (index < colors.length) {
    return colors[index];
  }
  return generateColor();
};

export const FormatDataforPieChart = (data, type, randomColor, labelArr) => {
  let keys = [];
  let name = "";
  if (type === "incidentDonutChart") {
    keys = incidentDashboardPieChart;
    name = "Total Incidents";
  } else if (type === "AuthenticationFailedSourcePieChart") {
    keys = AuthenticationFailedSourcePieChart;
  } else if (type === "AuthenticationFailedIpPieChart") {
    keys = AuthenticationFailedIpPieChart;
  } else {
    keys = [...labelArr];
  }

  let Allcount = 0;
  let randomNumberArr = [];
  data = data.map((el, index) => {
    if (el[keys[0]]) {
      Allcount += Number(el[keys[1]]);
      return {
        field: el[keys[0]],
        count: el[keys[1]],
        color: am4core.color(
          randomColor
            ? findRandomColoFunction(randomNumberArr, index)
            : colorFunction(el[keys[0]])
        ),
      };
    }
    return null;
  });
  return { data: data, count: Allcount, name: name ? name : "" };
};

export const updateQueryForNonCompliantData = (instance, tableName) => {
  if (restrictDateRange.indexOf(instance.name) !== -1){
    const skipMonthFilter = {
      member: `${tableName}.incidentTime`,
      operator: "lte",
      values: [dateLessThanMonth()],
    };
    if (instance.vizState.queries) {
      instance.vizState.queries.forEach((q) => {
        q.filters.push(skipMonthFilter);
      });
    } else if (instance.vizState.query) {
      instance.vizState.query.filters.push(skipMonthFilter);
    }
  }
};

export const updateQueryAsperDateRange = (data, action, dateTimeRange) => {
  let updatedData = data.map((instance) => {
    let tableName = '';
    if (instance.vizState.queries) {
      instance.vizState.queries = instance.vizState.queries.map((query) => {
        let tableNameArr = query.measures
          ? query.measures[0]
          : query.dimensions
          ? query.dimensions[0]
          : "";
        tableName = tableNameArr && tableNameArr.split(".")[0];
        let newAquery = { ...query };
        if (!newAquery.filters || newAquery.filters.length === 0) {
          newAquery.filters = [
            {
              member: `${tableName}.incidentTime`,
              operator: "gte",
              values: [`${action.payload.startDate}`],
            },
            {
              member: `${tableName}.incidentTime`,
              operator: "lte",
              values: [`${action.payload.endDate}`],
            },
          ];
        } else {
          let filters = [...newAquery.filters];
          let filterArr = [];
          let filterGte = false;
          let filterLte = false;
          filterArr = filters.map((filterObj) => {
            let newfilterobj = { ...filterObj };
            if (newfilterobj.operator === "gte") {
              filterGte = true;
              newfilterobj.values = [`${action.payload.startDate}`];
            } else if (newfilterobj.operator === "lte") {
              filterLte = true;
              // in some cases start end date is NaN hence the dashboard doesn't used to load properly 
              if(!isNaN(action.payload.endDate)) {
                  newfilterobj.values = [`${action.payload.endDate}`];
                }
            }
            return newfilterobj;
          });
          if (!filterGte && !isNaN(action.payload.startDate)) {
            filterArr.push({
              member: `${tableName}.incidentTime`,
              operator: "gte",
              values: [`${action.payload.startDate}`],
            });
          }
          if (!filterLte && !isNaN(action.payload.startDate)) {
            filterArr.push({
              member: `${tableName}.incidentTime`,
              operator: "lte",
              values: [`${action.payload.endDate}`],
            });
          }
          newAquery.filters = filterArr;
        }

        return newAquery;
      });
    } else if (instance.vizState.query) {
      let nameArr = instance.vizState.query
        ? instance.vizState.query.measures
        : "";
      let tableNameArr = nameArr ? nameArr[0] : "";
      tableName = tableNameArr && tableNameArr.split(".")[0];
      let newAquery = cloneDeep(instance.vizState.query);
      //! Important to Add this If conditon to calculate graph bar graph data for last 7 days in Incidents

      let startDate = dateTimeRange && JSON.parse(dateTimeRange).startDate;
      let endDate = dateTimeRange && JSON.parse(dateTimeRange).endDate;
      if (startDate && endDate) {
        var startDt = new Date(startDate).getTime(); // dateFormatter(startDate);
        var endDt = new Date(endDate).getTime(); // dateFormatter(endDate);
        if (instance.type === "MDRIncidents") {
          if (newAquery.timeDimensions[0].granularity === "day") {
            newAquery.timeDimensions[0].dateRange = [
              `${moment(endDt)
                .subtract(7, "d")
                .format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              `${moment(endDt).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
            ];
          } else {
            newAquery.timeDimensions[0].dateRange = [
              `${moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              `${moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SS")}`,
            ];
          }
        }
      }

      if (
        (!newAquery.filters || newAquery.filters.length === 0) &&
        !DASHBOARD_TYPES.includes(instance.type)
      ) {
        newAquery.filters = [
          {
            member: `${tableName}.incidentTime`,
            operator: "gte",
            values: [`${action.payload.startDate}`],
          },
          {
            member: `${tableName}.incidentTime`,
            operator: "lte",
            values: [`${action.payload.endDate}`],
          },
        ];
      } else if (
        !DASHBOARD_TYPES.includes(instance.type) 
      ) {
        let filters = [...newAquery.filters];
        let filterArr = [];
        let filterGte = false;
        let filterLte = false;
        filterArr = filters.map((newfilterObj) => {
          let filterObj = { ...newfilterObj };
          if (filterObj.operator === "gte") {
            filterGte = true;
            filterObj.values = [`${action.payload.startDate}`];
          } else if (filterObj.operator === "lte") {
            filterLte = true;
            filterObj.values = [`${action.payload.endDate}`];
          }

          return filterObj;
        });
        if (!filterGte) {
          filterArr.push({
            member: `${tableName}.incidentTime`,
            operator: "gte",
            values: [`${action.payload.startDate}`],
          });
        }
        if (!filterLte) {
          filterArr.push({
            member: `${tableName}.incidentTime`,
            operator: "lte",
            values: [`${action.payload.endDate}`],
          });
        }
        newAquery.filters = [...filterArr];
      }
      instance.vizState.query = newAquery;
    } else {
      instance.vizState.startDate = action.payload.startDate;
      instance.vizState.endDate = action.payload.endDate;
    }
    updateQueryForNonCompliantData(instance, tableName);
    return instance;
  });
  return updatedData;
};

export const AddSortingForMDRQueries = (
  Tablequery,
  sortbyValue,
  orderBy,
  cubeName
) => {
  let updatedQuery = cloneDeep(Tablequery);
  updatedQuery[0].query[0].order = [
    [`${cubeName}.${sortbyValue}`, orderBy === "ASC" ? "asc" : "desc"],
  ];
  // query.order = [[`${cubeName}.${sortbyValue}`, orderBy]];

  return updatedQuery;
};

export const MDRapiCall = async (
  setDataLoaded,
  dateTimeRange,
  tableQuerys,
  dispatch,
  dateRangeChanged
) => {
  setDataLoaded(false);

  let cubejsApi = getCubejsApi();

  let loc = tableQuerys[0].loc;
  const d = new Date();
  let diff = d.getTimezoneOffset();
  let startDt = dateTimeRange && JSON.parse(dateTimeRange).startDate;
  let endDt = dateTimeRange && JSON.parse(dateTimeRange).endDate;
  // let startDate = new Date(startDt).getTime(); // dateFormatter(startDt, "MM-DD-YYYY HH:mm:ss");
  // let endDate = new Date(endDt).getTime(); // dateFormatter(endDt, "MM-DD-YYYY HH:mm:ss");
  let startDate = new Date(startDt).getTime() - diff * 60 * 1000; // dateFormatter(startDate, "MM-DD-YYYY HH:mm:ss");
  let endDate = new Date(endDt).getTime() - diff * 60 * 1000; // dateFormatter(endDate, "MM-DD-YYYY HH:mm:ss");
  let filters = tableQuerys[0].query[0].filters;
  let tableNameArr = tableQuerys[0].query[0].dimensions
    ? tableQuerys[0].query[0].dimensions[0]
    : "";
  let tableName = tableNameArr && tableNameArr.split(".")[0];
  let UpdatedFilters = [];

  let filterGte = false;
  let filterLte = false;
  if (filters && filters.length) {
    console.log(filters, "filtersfiltersfilters");
    filters.map((obj) => {
      if (obj.operator === "gte") {
        filterGte = true;
        obj.values = [`${startDate}`];
      } else if (obj.operator === "lte") {
        filterLte = true;
        obj.values = [`${endDate}`];
      }
      UpdatedFilters.push(obj);
    });
    !filterGte &&
      UpdatedFilters.push({
        member: `${tableName}.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      });

    !filterLte &&
      UpdatedFilters.push({
        member: `${tableName}.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      });
  } else {
    UpdatedFilters.push({
      member: `${tableName}.incidentTime`,
      operator: "gte",
      values: [`${startDate}`],
    });
    UpdatedFilters.push({
      member: `${tableName}.incidentTime`,
      operator: "lte",
      values: [`${endDate}`],
    });
  }
  console.log(UpdatedFilters, "UpdatedFiltersUpdatedFilters");
  tableQuerys[0].query[0].filters = [...UpdatedFilters];
  tableQuerys[0].query[1].filters = [...UpdatedFilters];
  // let loc = tableQuerys[0].loc;
  // let UpdatedFilters = addingDateRangetoTableQueries(
  //   tableQuerys,
  //   dateTimeRange
  // );

  // tableQuerys[0].query[0].filters = [...tableQuerys[0].query[0].filters];
  // tableQuerys[0].query[1].filters = [...tableQuerys[0].query[0].filters];

  //! To change the rowsperpage and pagenumber when daterange is changed and table is already moved to another page before date change
  if (dateRangeChanged) {
    tableQuerys[0].query[0].limit = localStorage.getItem("rowsperPage")
      ? localStorage.getItem("rowsperPage")
      : 20;
    tableQuerys[0].query[0].offset = 0;
  }

  let response;
  let response2;
  try {
    const resultSet = await cubejsApi.load(tableQuerys[0].query[1]);
    response = resultSet.rawData();
  } catch (error) {
    redirectToLogin(error);
  }
  try {
    const resultSet2 = await cubejsApi.load(tableQuerys[0].query[0]);
    response2 = resultSet2.rawData();
  } catch (error) {
    redirectToLogin(error);
  }
  if (tableQuerys[0].type === "incidentTable") {
    setDataLoaded(true);
    dispatch({
      type: "INCIDENT_TABLE_DATA",
      payload: {
        data: response2,
        totalTableDataCount: response[0][`${tableQuerys[0].queryCall}.count`],
      },
    });
    //Trigger the below logic only if user changes date range at Incident
    if (dateRangeChanged) {
      dispatch({
        type: "UPDATE_INCIDENT_TABLE_AS_PER_DATERANGE",
        payload: {
          dateRangeChanged: dateRangeChanged
        }
      });
    }
  } else if (tableQuerys[0].type === "authenticationTable") {
    setDataLoaded(true);
    dispatch({
      type: "CHANGE_AUTHENTICATION_TABLE_DATA",
      payload: {
        data: response2,
        loc: loc,
        totalTableDataCount:
          response[0][`${tableQuerys[0].queryCall}.totalCount`] ||
          response[0][`${tableQuerys[0].queryCall}.count`],
      },
    });
     //Trigger the below logic only if user changes date range at Authentication
     if (dateRangeChanged) {
      dispatch({
        type: "UPDATE_AUTHENTICATION_TABLE_AS_PER_DATERANGE",
        payload: {
          dateRangeChanged: dateRangeChanged
        }
      });
    }
  } else if (tableQuerys[0].type === "MDREndpoints") {
    setDataLoaded(true);
    dispatch({
      type: "CHANGE_ENDPOINTS_TABLE_DATA",
      payload: {
        data: response2,
        loc: loc,
        totalTableDataCount: response[0][`${tableQuerys[0].queryCall}.count`],
      },
    });
    //Trigger the below logic only if user changes date range at EndPoints
    if (dateRangeChanged) {
      dispatch({
        type: "UPDATE_ENDPOINTS_TABLE_AS_PER_DATERANGE",
        payload: {
          dateRangeChanged: dateRangeChanged
        }
      });
    }
  } else if (tableQuerys[0].type === "MDRPatchMonitoring") {
    setDataLoaded(true);
    dispatch({
      type: "CHANGE_PATCHMONITORING_TABLE_DATA",
      payload: {
        data: response2,
        loc: loc,
        totalTableDataCount: response[0][`${tableQuerys[0].queryCall}.count`],
      },
    });
    //Trigger the below logic only if user changes date range at Patch Monitoring
    if (dateRangeChanged) {
      dispatch({
        type: "UPDATE_PATCHMONITORING_TABLE_AS_PER_DATERANGE",
        payload: {
          dateRangeChanged: dateRangeChanged
        }
      });
    }
  }
};
