import {  USERS_ME } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function getPresentUserDetails() {
    const options = {
        method: "GET",
        url: USERS_ME(localStorage.getItem('tenant')),
        headers: getAxiosHeaders(true),
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};
