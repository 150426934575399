import {
    SET_HEATMAP_DETAILS,
    CLEAR_HEATMAP,
    SET_VIEW_BY_VALUE,
    SET_HEATMAP_LOADER,
  } from "../constants/constants";
  
  const initialState = {
    data: [],
    loader: false,
    viewBy: '1 day' //week, month
  };
  
  export const heatmapReducer = (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_HEATMAP: {
        return initialState;
      }
      case SET_HEATMAP_DETAILS: {
        return {
          ...state,
          data: {...action.payload}
        }
      }
      case SET_VIEW_BY_VALUE: {
        return {
          ...state,
          viewBy: action.payload
        }
      }
      case SET_HEATMAP_LOADER: {
        return {
          ...state,
          loader: action.payload
        }
      }
      default:
        return state;
    }
  };