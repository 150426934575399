import { put, takeEvery, select } from "redux-saga/effects";
import {
  GET_DATA_SOURCE_URL,
} from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

function* normalDataSourceApi() {
  let options;

  let data = yield select();
  const {
    currentPage,
    recordsPerPage,
    finalDataSourceName,
    finalDataSourceType,
    finalDatabaseAccessRoles,
    finalDatabaseStatusInfo,
    finalSortByValue,
    finalOrderByValue,
    finalFromTimeValue,
    finalToTimeValue,
    searchValue
  } = data.normalDataSourceReducer;

  let paramData = {};

  if (finalSortByValue && finalSortByValue !== "") {
    paramData.sortBy = finalSortByValue;
  }
  if (finalOrderByValue && finalOrderByValue !== "") {
    paramData.direction = finalOrderByValue;
  }
  if (finalDataSourceName && finalDataSourceName.length > 0) {
    paramData.dataSourceNames = finalDataSourceName.join(",");
  }
  if (finalDataSourceType && finalDataSourceType.length > 0) {
    paramData.dataSourceTypes = finalDataSourceType.join(",");
  }
  if (finalDatabaseAccessRoles && finalDatabaseAccessRoles.length > 0) {
    paramData.databaseAccessRoles = finalDatabaseAccessRoles.join(",");
  }
  if (finalDatabaseStatusInfo && finalDatabaseStatusInfo.length > 0) {
    let status = [
      ...finalDatabaseStatusInfo,
    ];
    paramData.status = status.join(",");
  } else {
    let status = [
      "ACCESS_GRANTED",
      "ACCESS_REJECTED",
      "ACCESS_REMOVED"
    ];
    paramData.status = status.join(",");
  }
  if (finalFromTimeValue) {
    paramData.expiryFrom = finalFromTimeValue.getTime();
  }
  if (finalToTimeValue) {
    paramData.expiryTo = finalToTimeValue.getTime();
  }
  if (searchValue !== "") {
    paramData.queryString = searchValue;
  }


  options = {
    method: "GET",
    url: `${GET_DATA_SOURCE_URL}&size=${recordsPerPage}&page=${currentPage}`,
    headers: getAxiosHeaders(true),
    params: paramData,
    data: {},
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({
        type: "SET_NORMAL_DATA_SOURCE_INFO",
        payload: {
          allNormalDataSourceUsers: res.data.content,
          totalCount: res.data.totalElements,
        },
      });
    } else {
      yield put({ type: "SET_NORMAL_DATA_SOURCE_LOADER_INFO", payload: false });
    }
  } catch (error) {
    //   yield put({ type: "CREATE_ALERT_POLICY_FAILED" });
  }
}

function* flaggedDataSourceApi() {
  let options;

  let data = yield select();
  const {
    currentPage,
    recordsPerPage,
    finalDataSourceName,
    finalDataSourceType,
    finalDatabaseAccessRoles,
    finalDatabaseStatusInfo,
    finalSortByValue,
    finalOrderByValue,
    finalFromTimeValue,
    finalToTimeValue,
    searchValue,
    finalExpirySoon
  } = data.flaggedDataSourceReducer;

  let paramData = {};

  if (finalSortByValue && finalSortByValue !== "") {
    paramData.sortBy = finalSortByValue;
  }
  if (finalOrderByValue && finalOrderByValue !== "") {
    paramData.direction = finalOrderByValue;
  }
  if (finalDataSourceName && finalDataSourceName.length > 0) {
    paramData.dataSourceNames = finalDataSourceName.join(",");
  }
  if (finalDataSourceType && finalDataSourceType.length > 0) {
    paramData.dataSourceTypes = finalDataSourceType.join(",");
  }
  if (finalDatabaseAccessRoles && finalDatabaseAccessRoles.length > 0) {
    paramData.databaseAccessRoles = finalDatabaseAccessRoles.join(",");
  }
  if (finalDatabaseStatusInfo && finalDatabaseStatusInfo.length > 0) {
    let status = [
      ...finalDatabaseStatusInfo,
    ];
    paramData.status = status.join(",");
  } else {
    let status = [
      "ACCESS_TO_BE_VERIFIED",
      "ACCESS_EXPIRED",
      "ACCESS_MARKED_FOR_DELETION",
      "ACCESS_REQUESTED",
      "ACCESS_VERIFIED",
      "ACCESS_REVOKED",
      "ACCESS_APPROVED"
    ];
    paramData.status = status.join(",");
  }
  if (finalFromTimeValue) {
    paramData.expiryFrom = finalFromTimeValue.getTime();
  }
  if (finalToTimeValue) {
    paramData.expiryTo = finalToTimeValue.getTime();
  }
  if (finalExpirySoon && finalExpirySoon.length > 0) {
    let status = [
      "ACCESS_TO_BE_VERIFIED",
      "ACCESS_MARKED_FOR_DELETION",
      "ACCESS_REQUESTED",
      "ACCESS_REVOKED",
      "ACCESS_APPROVED",
      ...finalExpirySoon
    ];
    paramData.status = status.join(",");
  }
  if (searchValue !== "") {
    paramData.queryString = searchValue;
  }

  options = {
    method: "GET",
    url: `${GET_DATA_SOURCE_URL}&size=${recordsPerPage}&page=${currentPage}`,
    headers: getAxiosHeaders(true),
    params: paramData,
    data: {},
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({
        type: "SET_FLAGGED_DATA_SOURCE_INFO",
        payload: {
          allNormalDataSourceUsers: res.data.content,
          totalCount: res.data.totalElements,
        },
      });
    } else {
      yield put({
        type: "SET_FLAGGED_DATA_SOURCE_LOADER_INFO",
        payload: false,
      });
    }
  } catch (error) {
    //   yield put({ type: "CREATE_ALERT_POLICY_FAILED" });
  }
}

function* normalDataSourceSaga() {
  yield takeEvery("GET_NORMAL_DATA_SOURCE_INFO_DATA", normalDataSourceApi);
  yield takeEvery("GET_FLAGGED_DATA_SOURCE_INFO_DATA", flaggedDataSourceApi);
}

export default normalDataSourceSaga;