import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "420px",
    overflowY: "scroll",
    borderLeft: "2px solid grey",
    padding: "10px",
  },
  level: {
    borderTop: "solid 2px #979797",
    marginTop: "15px",
    width: "84%",
  },
  resolve: {
    borderBottom: "solid 2px #979797",
    borderTop: "solid 2px #979797",
    marginTop: "15px",
    width: "90%",
  },
  ul: {
    listStyleType: "none",
  },
  li: {
    paddingBottom: "8px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.14",
    letterSpacing: "normal",
    color: "black",
  },
  FilterHead: {
    fontSize: "12px",
    lineHeight: 1.22,
    color: "black",
  },
}));
const useStyles_checkbox = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

export default function FilterComponent() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  const classes = useStyles();
  const classes_check = useStyles_checkbox();

  const dataSourceName = useSelector(
    (state) => state.normalDataSourceReducer.dataSources
  );
  const dataSourceType = useSelector(
    (state) => state.normalDataSourceReducer.dataSourceType
  );
  const databaseRoles = useSelector(
    (state) => state.normalDataSourceReducer.databaseRoles
  );
  const aplications = useSelector(
    (state) => state.normalDataSourceReducer.applications
  );
  const dataSourceNameFilter = useSelector(
    (state) => state.serviceDataAccessReducer.apiDataSourceName
  );
  const dataSourceTypeFilter = useSelector(
    (state) => state.serviceDataAccessReducer.apiDataSourceType
  );
  const databaseRolesFilter = useSelector(
    (state) => state.serviceDataAccessReducer.apiDatabaseAccessRoles
  );
  const applicationFilter = useSelector(
    (state) => state.serviceDataAccessReducer.apiDataapplications
  );

  const DatabaseLogsClickHandler = (event, name) => {
    switch (name) {
      case "dataSourceName": {
        dispatch({
          type: "SET_FILTER_DATA_SOURCE_NAME_SERVICE_DATA_ACCESS",
          payload: event.target.value,
        });
        dispatch({
          type: "MAINTAIN_DIRECT_DATA_SOURCE_SINGLE_DATA_SERVICE_DATA_ACCESSES",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_FILTER_DATA_SOURCE_NAME_SERVICE_DATA_ACCESS",
          },
        });
        break;
      }
      case "dataSourceType": {
        dispatch({
          type: "SET_FILTER_DATA_SOURCE_TYPE_SERVICE_DATA_ACCESS",
          payload: event.target.value,
        });
        dispatch({
          type: "MAINTAIN_DIRECT_DATA_SOURCE_SINGLE_DATA_SERVICE_DATA_ACCESSES",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_FILTER_DATA_SOURCE_TYPE_SERVICE_DATA_ACCESS",
          },
        });
        break;
      }
      case "databaseRoles": {
        dispatch({
          type: "SET_FILTER_DATABASE_ROLES_SERVICE_DATA_ACCESS",
          payload: event.target.value,
        });
        dispatch({
          type: "MAINTAIN_DIRECT_DATA_SOURCE_SINGLE_DATA_SERVICE_DATA_ACCESSES",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_FILTER_DATABASE_ROLES_SERVICE_DATA_ACCESS",
          },
        });
        break;
      }
      case "applications": {
        dispatch({
          type: "SET_FILTER_APPLICATION_SERVICE_DATA_ACCESS",
          payload: event.target.value,
        });
        dispatch({
          type: "MAINTAIN_DIRECT_DATA_SOURCE_SINGLE_DATA_SERVICE_DATA_ACCESSES",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_FILTER_APPLICATION_SERVICE_DATA_ACCESS",
          },
        });
        break;
      }
      default:
        return "";
    }
  };

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="filter">
      <FormLabel style={{ fontSize: "12px", paddingLeft: "10px" }}>
        {"Filter By"}
      </FormLabel>

      <div
        style={{
          width: "420px",
          minheight: "350px",
          overflow: "auto",
          wordBreak: "break-all",
        }}
      >
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "applications"}
          onChange={handleChange("applications")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Application Name
            </Typography>
            <Typography className={classes.heading}>
              {applicationFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                minWidth: "370px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1rem",
                overflow: "auto",
                height: "240px",
              }}
            >
              <FormGroup aria-label="position" column>
                <>
                  {aplications &&
                    aplications.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.name}
                          id={index}
                          name={each.name}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={applicationFilter.includes(each.name)}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(event, "applications")
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={<p className={classes.label}>{each.name}</p>}
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "databaseRoles"}
          onChange={handleChange("databaseRoles")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Service Name
            </Typography>
            <Typography className={classes.heading}>
              {databaseRolesFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                minWidth: "370px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1rem",
                overflow: "auto",
                height: "240px",
              }}
            >
              <FormGroup aria-label="position" column>
                <>
                  {databaseRoles &&
                    databaseRoles.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.id}
                          id={index}
                          name={each.name}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={databaseRolesFilter.includes(each.id)}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(event, "databaseRoles")
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={<p className={classes.label}>{each.name}</p>}
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion> */}
        {/*         
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "dataSource"}
          onChange={handleChange("dataSource")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Data Source Name
            </Typography>
            <Typography className={classes.heading}>
              {dataSourceNameFilter?
              dataSourceNameFilter.length || ""
              :null}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                minWidth: "370px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1rem",
                overflow: "auto",
                height: "240px",
              }}
            >
              <FormGroup aria-label="position" column>
                <>
                  {dataSourceName &&
                    dataSourceName.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.id}
                          name={each.aliasName}
                          id={index}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={dataSourceNameFilter.includes(each.id)}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(
                                  event,
                                  "dataSourceName"
                                )
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={
                            <p className={classes.label}>{each.aliasName}</p>
                          }
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "dataSourceType"}
          onChange={handleChange("dataSourceType")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Data Source Type
            </Typography>
            <Typography className={classes.heading}>
              {dataSourceTypeFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1.5rem",
                overflow: "auto",
                height: "100px",
              }}
            >
              <FormGroup aria-label="position" column>
                <>
                  {dataSourceType &&
                    dataSourceType.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.code}
                          name={each.code}
                          id={index}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={dataSourceTypeFilter.includes(each.code)}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(
                                  event,
                                  "dataSourceType"
                                )
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={<p className={classes.label}>{each.code}</p>}
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "databaseRoles"}
          onChange={handleChange("databaseRoles")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Data Access Role
            </Typography>
            <Typography className={classes.heading}>
              {databaseRolesFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                minWidth: "370px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1rem",
                overflow: "auto",
                height: "240px",
              }}
            >
              <FormGroup aria-label="position" column>
                <>
                  {databaseRoles &&
                    databaseRoles.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.id}
                          id={index}
                          name={each.name}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={databaseRolesFilter.includes(each.id)}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(event, "databaseRoles")
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={<p className={classes.label}>{each.name}</p>}
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion> */}
      </div>
    </div>
  );
}
