import React, { useReducer, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Input from "../../views/Input";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import SignUpPageFooter from "./SignUpPageFooter";
import { useTranslation } from "react-i18next";
import unchecked from "../../../assets/vizr_images/Input_cell.svg";
import checked from "../../../assets/vizr_images/Active_Input.svg";
import help from "../../../assets/vizr_images/ic_help.svg";
import SnackBar2 from "../../../components/shared/SnackBar2";
import SelectDropDown from "../../shared/SelectDropDown";

// import {
//   updateSignUpPageFirstname,
//   updateSignUpPageLastname,
//   updateSignUpPagePhone,
//   updateSignUpPageAdminName,
//   updateSignUpPageAdminEmail,
//   updateSignUpPageTenentName,
//   updateSignUpPageDomain,
// } from "../../../redux/actions/signUppageAction";

//defining the initial state
const initialState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  adminName: "",
  adminEmail: "",
  tenantName: "",
  domain: "",
  monitorVision: "Silver(500)",
  controlShield: "Silver(500)",
};

//defining the Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "firstName":
      return {
        ...state,
        firstName: action.payload,
      };
    case "lastName":
      return {
        ...state,
        lastName: action.payload,
      };
    case "phoneNumber":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "adminName":
      return {
        ...state,
        adminName: action.payload,
      };
    case "adminEmail":
      return {
        ...state,
        adminEmail: action.payload,
      };
    case "tenantName":
      return {
        ...state,
        tenantName: action.payload,
      };
    case "domain":
      return {
        ...state,
        domain: action.payload,
      };

    default:
      return state;
  }
};

const SignUpPage = () => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const [signUpObj, dispatch] = useReducer(reducer, initialState);

  const [signUpactive, makeSignUpAcitive] = useState(false);
  const [privacyPolicy, setprivacyPolicy] = useState(false);
  const [license, setLicense] = useState(false);
  const [monitor, setMonitor] = useState(true);
  const [control, setControl] = useState(true);
  const [domainMismatchError, setDomainMismatchError] = useState("");
  const [errorObj, setErrorObj] = useState({
    emailError: "",
    domainError: "",
    tenantNameError: "",
  });
  const [licenses, setLicenses] = useState([]);
  const isSignedUpSuccess = useSelector(
    (state) => state.selfSignUppage.signUpsuccessful
  );

  const isSignedUpFailed = useSelector(
    (state) => state.selfSignUppage.signUpFailed
  );
  const errorMessage = useSelector(
    (state) => state.selfSignUppage.signUpFailedErrorMessage
  );

  const MonitorCheckBoxHandler = () => {
    setMonitor((prevState) => !prevState);
  };

  const ControlCheckBoxHandler = () => {
    setControl((prevState) => !prevState);
  };
  // const firstName = useSelector((state) => state.selfSignUppage.firstName);
  // const lastName = useSelector((state) => state.selfSignUppage.lastName);
  // const phoneNumber = useSelector((state) => state.selfSignUppage.phoneNumber);
  // const adminName = useSelector((state) => state.selfSignUppage.adminName);
  // const adminEmail = useSelector((state) => state.selfSignUppage.adminEmail);
  // const tenantName = useSelector((state) => state.selfSignUppage.tenantName);
  // const domain = useSelector((state) => state.selfSignUppage.domain);

  // className={privacyPolicy || license ? "signupPageBlur" : "signUpPage"}

  return (
    <div
      className="signUpPage"
      style={{
        overflowX: "hidden !important",
        fontFamily: "Nunito-Regular !important",
      }}
    >
      <SnackBar2
        success={isSignedUpSuccess}
        failure={isSignedUpFailed}
        successMessage="User Sign up Successfull"
        errorMessage={errorMessage}
      ></SnackBar2>
      <div>
        <SignUpBackGround
          headerName="Sign Up"
          headerText="Please complete the form below to create your account"
          headerClassName="SignUppage_header_text"
          headerClassName2="SignUppage_header_title"
          classname="extraWidth"
        ></SignUpBackGround>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className="signUpGrid_container"
        >
          <Grid item lg={4} md={6} sm={6} xs={8} className="signUpGrid_left">
            <h1 className="signupinput_header SignUpHeaderCommon">
              {t("Account Details")}
            </h1>
            <Input
              required
              type="text"
              labelName={t("Email")}
              labelClassName="signUp_labelName"
              placeholder="Enter your email address"
              classname="signup_inputField"
              containerName="signUpconatainer"
              value={signUpObj.adminEmail}
              eventHandler={(value) => {
                setErrorObj({
                  ...errorObj,
                  emailError: "",
                });
                setDomainMismatchError("");
                dispatch({ type: "adminEmail", payload: value });
              }}
            ></Input>
            {errorObj.emailError ? (
              <div style={{ color: "red", marginTop: "10px" }}>
                {errorObj.emailError}
              </div>
            ) : (
              ""
            )}
            {domainMismatchError ? (
              <div style={{ color: "red", marginTop: "10px" }}>
                {domainMismatchError}
              </div>
            ) : (
              ""
            )}
            <Input
              type="text"
              labelName={t("First Name")}
              placeholder=""
              classname="signup_inputField"
              labelClassName="signUp_labelName"
              containerName="signUpconatainer"
              value={signUpObj.firstName}
              eventHandler={(value) =>
                dispatch({ type: "firstName", payload: value })
              }
            ></Input>
            <Input
              type="text"
              labelName={t("Last Name")}
              placeholder=""
              classname="signup_inputField"
              labelClassName="signUp_labelName"
              containerName="signUpconatainer"
              value={signUpObj.lastName}
              eventHandler={(value) =>
                dispatch({ type: "lastName", payload: value })
              }
            ></Input>

            <Input
              type="text"
              labelName={t("Phone #")}
              labelClassName="signUp_labelName"
              placeholder="XXX-XXX-XXXX"
              classname="signup_inputField"
              containerName="signUpconatainer"
              value={signUpObj.phoneNumber}
              eventHandler={(value) =>
                dispatch({ type: "phoneNumber", payload: value })
              }
            ></Input>
            {/* <Input
              type="text"
              labelName="Admin Name"
              labelClassName="signUp_labelName"
              placeholder=""
              classname="signup_inputField"
              containerName="signUpconatainer"
              value={signUpObj.adminName}
              eventHandler={(value) =>
                dispatch({ type: "adminName", payload: value })
              }
            ></Input> */}

            <Input
              required
              type="text"
              labelName={t("Tenant Name")}
              labelClassName="signUp_labelName"
              placeholder=""
              classname="signup_inputField"
              containerName="signUpconatainer"
              value={signUpObj.tenantName}
              eventHandler={(value) => {
                setErrorObj({
                  ...errorObj,
                  tenantNameError: "",
                });
                dispatch({ type: "tenantName", payload: value });
              }}
            ></Input>
            {errorObj.tenantNameError ? (
              <div style={{ color: "red", marginTop: "10px" }}>
                {errorObj.tenantNameError}
              </div>
            ) : (
              ""
            )}
            <Input
              required
              type="text"
              labelName={t("Domain")}
              labelClassName="signUp_labelName"
              placeholder=""
              classname="signup_inputField"
              containerName="signUpconatainer"
              value={signUpObj.domain}
              eventHandler={(value) => {
                setErrorObj({
                  ...errorObj,
                  domainError: "",
                });
                setDomainMismatchError("");
                dispatch({ type: "domain", payload: value });
              }}
            ></Input>
            {errorObj.domainError ? (
              <div style={{ color: "red", marginTop: "10px" }}>
                {errorObj.domainError}
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} md={3} sm={3} xs={3} style={{ marginLeft: "8rem" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1 className="signup_select_header SignUpHeaderCommon">
                {t("Select Features & Licenses")}
              </h1>
              <img
                src={help}
                alt="help"
                style={{ marginLeft: "-10px", cursor: "pointer" }}
              ></img>
            </div>

            <div className="signUp_selectandCheckBoxHeader">
              {monitor ? (
                <img
                  src={unchecked}
                  alt="input"
                  style={{ marginRight: "1.5rem" }}
                  onClick={MonitorCheckBoxHandler}
                ></img>
              ) : (
                <img
                  src={checked}
                  alt="input"
                  style={{ marginRight: "1.5rem" }}
                  onClick={MonitorCheckBoxHandler}
                ></img>
              )}

              <div className="signup_select_container">
                <div style={{ fontSize: "12.8px" }}>{t("Monitor/Vision")}</div>
                <SelectDropDown
                  disabled={monitor ? true : ""}
                  state={licenses}
                ></SelectDropDown>
              </div>
            </div>

            <div className="signUp_selectandCheckBoxHeader">
              {control ? (
                <img
                  src={unchecked}
                  alt="input"
                  style={{ marginRight: "1.5rem" }}
                  onClick={ControlCheckBoxHandler}
                ></img>
              ) : (
                <img
                  src={checked}
                  alt="input"
                  style={{ marginRight: "1.5rem" }}
                  onClick={ControlCheckBoxHandler}
                ></img>
              )}
              <div className="signup_select_container">
                <div style={{ fontSize: "12.8px" }}>{t("Control/Shield")}</div>
                <SelectDropDown
                  disabled={control ? true : ""}
                  state={licenses}
                ></SelectDropDown>
              </div>
            </div>
          </Grid>
          <SignUpPageFooter
            id="selfSignup"
            signUpObj={signUpObj}
            signUpactive={signUpactive}
            makeSignUpAcitive={makeSignUpAcitive}
            privacyPolicy={privacyPolicy}
            setprivacyPolicy={setprivacyPolicy}
            license={license}
            setLicense={setLicense}
            setDomainMismatchError={setDomainMismatchError}
            errorObj={errorObj}
            setErrorObj={setErrorObj}
          ></SignUpPageFooter>
        </Grid>
      </div>
      <div
        className={
          privacyPolicy || license
            ? "signupPageBlur-visible"
            : "signupPageBlur-hidden"
        }
      ></div>
    </div>
  );
};

export default SignUpPage;
