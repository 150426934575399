export const privacy = `<div class="elementor-element elementor-element-f9bf066 elementor-widget elementor-widget-heading" data-id="f9bf066" data-element_type="widget" data-widget_type="heading.default">
    <div class="elementor-widget-container">
    <h2 class="elementor-heading-title elementor-size-default">Terms and Privacy Policy</h2>
    </div>
    </div>
    <div class="elementor-element elementor-element-9429294 elementor-widget elementor-widget-text-editor" data-id="9429294" data-element_type="widget" data-widget_type="text-editor.default">
    <div class="elementor-widget-container">
    <div class="elementor-text-editor elementor-clearfix">
    <p>In this policy, we explain how DruvStar and its subsidiaries handle any personal data that we obtain through our applications, services, websites or when you contact us by mail or phone. We will follow the law when you share personal data with us.</p>
    <p>The DruvStar URLs are: //*.druvstar.com</p>
    <p>When you use our applications or services, register an account, continue to browse the websites, such as reading the content or clicking on a link, or contact us by mail or phone, you consent to us using your personal data in the way that we describe here. This also includes placing of cookies as explained below.</p>
    <h4><strong>INFORMATION YOU PROVIDE TO US</strong></h4>
    <p>We collect the following information when you use our applications, services, websites or contact us:</p>
    <ul>
    <li>When you contact us by mail, phone or through the contact form or chat on our websites: we collect your name, email address, location, general analytics data and any contact and other information you provide to us.</li>
    <li>When you contact us about a job opening: we collect any relevant information you provide to us.</li>
    <li>When you browse the website or contact us with commercial inquiries: we collect general analytics data and any contact and other information you provide to us.</li>
    </ul>
    <p>We use this information:</p>
    <ul>
    <li>To provide updates on our service, and for payment processing for the DruvStar service fees.</li>
    <li>To reply to your information request when you contacted our customer support.</li>
    <li>To keep you in our recruitment database if you contacted us about job openings.</li>
    <li>For our own marketing communication correspondence, including to follow up on leads. We don&rsquo;t sign you up for promotional marketing communications unless you have opted in.</li>
    </ul>
    <p>We store:</p>
    <ul>
    <li>Customer support, &nbsp;Recruitment, and Marketing information for up to five years.</li>
    </ul>
    <p>We process this data based on your consent. In the case of customer support, we need to be able to provide you that service. It is not a contractual or statutory requirement to provide this information.</p>
    <h4><strong>INFORMATION WE OBTAIN ABOUT YOU</strong></h4>
    <p>Sometimes we obtain your personal data from other parties for general marketing purposes, such as profile enrichment companies that provide us with an overview of publicly available information on people that have submitted their email address, such as first name, last name, company website and company name. We will use this information in the same way as the information that you provided to us.</p>
    <p><strong>SHARING YOUR INFORMATION</strong></p>
    <p>We will not sell or provide your personal data to other third parties allowing them to use your personal data for their own purposes.</p>
    <p>We will only release personal data to the authorities if we are compelled to do so by law.</p>
    <p>You should also know that your personal data may be transferred from your home country to other DruvStar entities around the world. If you are an EU resident, this could imply that we also transfer data to countries or organizations that do not offer a so-called adequate level of protection for your personal data. When we transfer this data, we will do so only when we have put in place appropriate safeguards to protect your personal data.</p>
    <h4><strong>COOKIES</strong></h4>
    <p>We do set and use cookies (small text files placed on your device) on our own domains and subdomains to store settings that assist with identifying your account for sign-in. We also use third party analytics packages for our public pages that may set cookies on your computer. These are limited to our domains and do not involve cross-service tracking. You may disable cookies in your browser and continue to use our services without impact.</p>
    <h4><strong>YOUR RIGHTS</strong></h4>
    <p>You have the right to request from us access to your personal data that we process. You also have the right to:</p>
    <ul>
    <li>Rectify incorrect personal data or erase it in certain circumstances.</li>
    <li>Restrict or object to the processing of your personal data.</li>
    <li>Receive your data so that you can use it elsewhere (data portability).</li>
    </ul>
    <p>You also have the right to withdraw your consent at any time. Please be aware that a withdrawal of your consent does not affect the lawfulness of the processing of your data before the date on which you withdrew your consent.</p>
    <p>Finally, you have the right to lodge a complaint with supervisory authority. If you do not know who your supervisory authority is, please contact us and we will tell you.</p>
    <p>You can contact us with any questions you may have about the processing of your personal data or the use of cookies by us, or to exercise the above rights.</p>
    <p>info@druvstar.com</p>
    </div>
    </div>
    </div>`
