import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import LevelComponent from "./levelComponent";
import { Button } from "@material-ui/core";
import moment from "moment";
import CloseIcon from "../../assets/vizr_images/ic_remove.svg";
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined";
import PolicyOutlinedIcon from "@material-ui/icons/PolicyOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EnhancedTable from "../shared/alertsTable";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import InactiveIcon from "../../assets/vizr_images/Group 3.png";
import DB_Users from "../../assets/vizr_images/DB_Users.svg";
import { updateAlertStatus } from "../../redux/actions/updateAlertStatusAction";
import { getAlertDetails } from "../../redux/actions/getAlertDetailsAction";
import { saveAlertComment } from "../../redux/actions/saveCommentForAlertAction";
import { getCommentsByID } from "../../redux/actions/getCommentsByID";
import { FalsePositiveButtonForDrawer } from "../common/buttons";
import { MarkResolveButtonForDrawer } from "../common/buttons";
import ShowMoreData from "../common/ShowMoreData/ShowMoreData";
import { getTime, checkIfEmptyString } from "../common/utilities";
import DoneIcon from "@material-ui/icons/Done";
import { DeleteButtonForToolBar } from "../common/buttons";
import Tooltip from "@material-ui/core/Tooltip";
import { CreateLogSource } from "../views/logSource/CreateLogSource";
import { EnhancedTableToolbar } from "../views/DatabaseLogs/EnhanaceDataLogsHeader";
import {
  updateAlert,
  postAlertComment,
  viewAlertComment,
  ALL_ACCESS,
  ACCESS_ANOMALIES,
  DATASOURCE_DETAILS,
  SENSITIVE_ACCESS,
  UPDATE_DATASOURCE_SUCCESS,
  CLOSE_REPORT_HISTORY,
} from "../../redux/constants/constants";

import "../views/userAccessRights/accessDetails.scss";
import "./tableStyles.scss";

import { accessAnalomy, allAccess, sensitiveAccess, dataSourceDetails } from "../common/headCells";
import { getService, getAxiosHeaders } from "../../library/RestAPI";
import page_loader from "../../assets/vizr_images/page_loader.gif";
import RelatedTable from "./totalAlerts/RelatedTables";
import { isEmpty, without } from "lodash";
import DataSourceStatus from "../views/userAccessRights/DataSourceStatus";
import { returnData } from "../views/userAccessRights/AccessRightsutilities";
import { CreateDataSource } from "../views/CreateDataSource";
import { number } from "prop-types";
import { tableConstants_ServiceAccessLogsDrawer } from "../views/DatabaseLogs/utilities";
import EnhancedGridTable from "../shared/Table/Table";
import { DataMapRoute } from "../common/DatamapImage";
import RenderDataMapFilePopUp from "./FilePopUp";
import ReportHistory from "../views/GenerateReport/ReportHistory";
import ManageDataSource from "../views/manageDataSource/ManageDataSource";
import ManageLogSource from "../views/manageLogSource/ManageLogSource";

const useStyles = makeStyles({
  list: {
    width: 500,
    margin: "20px 20px",
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "white",
  },
  paperReport:{
    background: "white",
    overflowY:"hidden",
  },
  "& .MuiFormControl": {
    width: "100%",
  },
});
export const sensitiveAccessColoringFunction = (data) => {
  // let updatedTableData = [];
  // data.map((item) => {
  //   return (
  //     item.sensitivityInfo &&
  //     item.sensitivityInfo.forEach((data, i) => {
  //       let category = data.category;
  //       let columnName = data.columnName;
  //       let tempTable = data.tableName;
  //       if (category !== "LOW" && columnName && tempTable) {
  //         updatedTableData.push({
  //           columnName: columnName,
  //           tableName: tempTable,
  //           sensitivity: category,
  //           timeStamp: item.timestamp,
  //         });
  //       }
  //     })
  //   );
  // });

  //Todo: Code used for Seperate API
  let updatedTableData = [];
  data.length &&
    data.forEach((data, i) => {
      let category = data.sensitivityInfo.category;
      let columnName = data.sensitivityInfo.fieldName;
      let tableName = data.sensitivityInfo.containerName;
      if (columnName || tableName) {
        updatedTableData.push({
          columnName: columnName,
          tableName: tableName,
          sensitivity: category,
          timeStamp: data.timestamp,
        });
      }
    });
  return updatedTableData;
};

export const colorFunction = (sensitivityDetails, dbStatement, timestamp) => {
  let obj = { dbStatement: dbStatement, timestamp: timestamp };
  sensitivityDetails.length &&
    sensitivityDetails.forEach((data, i) => {
      if (data && data.category && data.fieldName) {
        let category = data.category;
        let columnName = data.fieldName;
        dbStatement = dbStatement.replaceAll(
          columnName,
          `<span class=${category}>${columnName}</span>`
        );
        if (i === sensitivityDetails.length - 1) {
          obj = { dbStatement: dbStatement, timestamp: timestamp };
        }
      }
    });

  return obj;
};

export default function AlertDrawer(props) {
  const {
    anchor,
    toggleDrawer,
    stateOpen,
    alertDetailsId,
    loc,
    sourceName,
    accessDetailsDrawer,
    serviceAccessLogsDrawer,
    popUpData,
    getAllDataSources,
    getServiceData,
    isEditMode,
    isAliasEdit
  } = props;
  
  const permissions = useSelector((state) => state.applicationData.permissions);
  const d = new Date();
  let diff = d.getTimezoneOffset();
  const AccessDetailsSideDrawerData = useSelector(
    (state) => state.normalDirectDataSourceReducer.AccessDetailsSideDrawerData
  );
  const ServiceAccessDetailsSideDrawerData = useSelector(
    (state) => state.serviceDataAccessReducer.ServiceAccessDetailsSideDrawerData
  );
  const ServiceAccessLogsSideDrawerData = useSelector(
    (state) => state.serviceAccessLogsReducer.ServiceAccessLogsSideDrawerData
  );

  const incidentDetailsDrawer = useSelector(
    (state) => state.IncidenetReducer.incidentDrawer
  );
  const incidentData = useSelector(
    (state) => state.IncidenetReducer.incidentSideDrawerData
  );
  const patchMonitoringDrawer = useSelector(
    (state) => state.MDRPatchMonitoringReducer.patchMonitoringDrawer
  );
  const patchMonitoringSideDrawerData = useSelector(
    (state) => state.MDRPatchMonitoringReducer.patchMonitoringSideDrawerData
  );
  const endPointDrawer = useSelector(
    (state) => state.MDREndPointsReducer.endPointDrawer
  );
  const endPointSideDrawerData = useSelector(
    (state) => state.MDREndPointsReducer.endPointSideDrawerData
  );
  const authenticationDrawer = useSelector(
    (state) => state.MDRAuthenticationReducer.authenticationDrawer
  );
  const authenticationSideDrawerData = useSelector(
    (state) => state.MDRAuthenticationReducer.authenticationSideDrawerData
  );
  const currentPage_logDetails = useSelector(
    (state) => state.serviceAccessLogsReducer.currentPage_logDetails
  );
  const recordsPerPage_logDetails = useSelector(
    (state) => state.serviceAccessLogsReducer.recordsPerPage_logDetails
  );
  const serviceAccessLogsDrawerLoader = useSelector(
    (state) => state.serviceAccessLogsReducer.serviceAccessLogsDrawerLoader
  );
  const ServiceAccessLogsSideDrawerTableData = useSelector(
    (state) =>
      state.serviceAccessLogsReducer.ServiceAccessLogsSideDrawerTableData
  );
  const totalCountLogs = useSelector(
    (state) => state.serviceAccessLogsReducer.totalCountLogs
  );
  const dataMapFilePopUp = useSelector(
    (state) => state.dataMapReducer.dataMapFilePopUp
  );
  const viewReportHistory = useSelector(
    (state) => state.GenerateReportReducer.viewReportHistory
  );
  const tenant = window.localStorage.getItem("tenant");
  const history = useHistory();
  const classes = useStyles();
  const [newComment, setNewComment] = useState("");
  const [alertDetails, setAlertDetails] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableData_AllAccess, setTableData_AllAccess] = useState([]);
  const [tableData_SensitiveAccess, setTableData_SensitiveAccess] = useState(
    []
  );
  //access anomalies
  const [tableData_serviceDataAccesses, 
    setTableData_serviceDataAccesses] =
    useState([]);
  const [
    tableData_AllAccess_serviceDataAccesses,
    setTableData_AllAccess_serviceDataAccesses,
  ] = useState([]);
  const [
    tableData_SensitiveAccess_serviceDataAccesses,
    setTableData_SensitiveAccess_serviceDataAccess,
  ] = useState([]);
  const [
    tableData_DatasourceDetails_serviceDataAccesses,
    setTableData_DatasourceDetails_serviceDataAccess,
  ] = useState([]);

  const [triggerInfo, setTriggerInfo] = useState("");
  const [reason, setReason] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [commentsArr, setCommented] = useState([]);
  const alertIdArray = [];
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchAlertDetails(id) {
      const response = await getAlertDetails(id);
      if (response) {
        setAlertDetails(response);
        alertIdArray.push(response.id);
      }
    }
    if (
      alertDetailsId !== {} &&
      alertDetailsId !== null &&
      alertDetailsId !== undefined
    ) {
      fetchAlertDetails(alertDetailsId);
    }
    return () => {
      setAlertDetails({});
      setTriggerInfo("");
    };
  }, [alertDetailsId]);

  useEffect(() => {
    if (
      alertDetailsId !== {} &&
      alertDetailsId !== null &&
      alertDetailsId !== undefined
    ) {
      fetchComments(alertDetailsId);
    }
  }, [alertDetailsId]);

  const fetchComments = (id) => {
    async function fetchCommentsById(id) {
      const commentsData = await getCommentsByID(id);
      if (commentsData) {
        setCommented(commentsData.content);
      }
    }
    fetchCommentsById(id);
  };

  const handleNewComment = (e) => {
    setNewComment(e.target.value);
  };

  const handleSaveComment = () => {
    async function saveComment(id) {
      const res = await saveAlertComment(id, newComment);
      if (res && res.status === 201) {
        fetchComments(alertDetailsId);
        setNewComment("");
      } else if (
        res.response &&
        res.response.data &&
        res.response.data.errorMessage
      ) {
        dispatch({
          type: "UPDATE_ALERT_STATUS_FAILED",
          payload: res.response.data.errorMessage,
        });
      }
    }
    if (
      newComment !== "" &&
      alertDetailsId !== {} &&
      alertDetailsId !== null &&
      alertDetailsId !== undefined
    ) {
      saveComment(alertDetailsId);
    }
  };

  const handleClose = (event) => {
    setCommented([]);
    setNewComment("");
    toggleDrawer(false, event);
  };

  useEffect(() => {
    if (
      alertDetails &&
      alertDetails.alertPolicy &&
      alertDetails.alertPolicy.trigger
    ) {
      let desc = `${
        alertDetails.alertPolicy.trigger.triggerCondition.comparisonType.value
      } by ${alertDetails.alertPolicy.trigger.triggerCondition.value}${
        alertDetails.alertPolicy.trigger.triggerCondition.aggregationType
          .value === "Percentage"
          ? "%"
          : ""
      } on a ${
        alertDetails.alertPolicy.trigger.triggerCondition.timeWindowType.value
      } basis.`;
      setTriggerInfo(desc);
    } else if (
      alertDetails &&
      alertDetails.alertPolicy &&
      alertDetails.alertPolicy.directDBAccess
    ) {
      setTriggerInfo(`Unauthorized Database Access`);
    } else {
      setTriggerInfo(`Auto Monitored`);
    }
    if (alertDetails && alertDetails.reason) {
      setReason(alertDetails.reason);
    } else {
      setReason(null);
    }
  }, [alertDetails]);

  const [username, setUsername] = useState("");
  const [aliasName, setAliasName] = useState("");
  useEffect(() => {
    setUsername(
      alertDetails.enterpriseActor ? alertDetails.enterpriseActor.username : ""
    );
    setAliasName(
      alertDetails.dataSource ? alertDetails.dataSource.aliasName : ""
    );
  }, [alertDetails]);

  const [accessbannerContent, setAccessbannerContent] = useState({
    accessAnalomy: true,
    allAccess: false,
    sensitiveAccess: false,
    
  });
  const [serviceDataAccessbannerContent, setserviceDataAccessbannerContent] =
    useState({
      accessAnalomy: true,
      allAccess: false,
      sensitiveAccess: false,
      dataSourceDetails: false,
    });
  const accessDetailsBannerClickHandler = (name) => {
    setTableData([]);
    setTableData_AllAccess([]);
    setDataLoaded(false);
    if (name === "accessAnalomy") {
      setAccessbannerContent({
        accessAnalomy: true,
        allAccess: false,
        sensitiveAccess: false,
      });
    } else if (name === "allAccess") {
      setAccessbannerContent({
        accessAnalomy: false,
        allAccess: true,
        sensitiveAccess: false,
      });
    } else if (name === "sensitiveAccess") {
      setAccessbannerContent({
        accessAnalomy: false,
        allAccess: false,
        sensitiveAccess: true,
      });
    }
  };
  const serviceDataDetailsBannerClickHandler = (name) => {
    setTableData_serviceDataAccesses([]);
    setTableData_AllAccess_serviceDataAccesses([]);
    setTableData_DatasourceDetails_serviceDataAccess([]);
    setDataLoaded(false);
    if (name === "accessAnalomy") {
      setserviceDataAccessbannerContent({
        accessAnalomy: true,
        allAccess: false,
        sensitiveAccess: false,
        dataSourceDetails: false,
      });
    } else if (name === "allAccess") {
      setserviceDataAccessbannerContent({
        accessAnalomy: false,
        allAccess: true,
        sensitiveAccess: false,
        dataSourceDetails: false,
      });
    } else if (name === "sensitiveAccess") {
      setserviceDataAccessbannerContent({
        accessAnalomy: false,
        allAccess: false,
        sensitiveAccess: true,
        dataSourceDetails: false,
      });
    }
      else if (name === "dataSourceDetails") {
        setserviceDataAccessbannerContent({
          accessAnalomy: false,
          allAccess: false,
          sensitiveAccess: false,
          dataSourceDetails: true,
        });
    }
  };

  const [totalElements, setTotalElements] = useState(0);
  const [getAllSensitiveInfo, setAllSensitiveInfo] = useState(false);
  const [
    getAllSensitiveInfo_serviceDataAccess,
    setAllSensitiveInfo_serviceDataAccess,
  ] = useState(false);
  const handleGetSenstiveInfo = (bool) => {
    if (!bool) {
      setAllSensitiveInfo(false);
    } else {
      setAllSensitiveInfo(true);
    }
  };

  const handleGetSenstiveInfo_serviceDataAccesses = (bool) => {
    if (!bool) {
      setAllSensitiveInfo_serviceDataAccess(false);
    } else {
      setAllSensitiveInfo_serviceDataAccess(true);
    }
  };
  const [getAllAccess, setAllAccess] = useState(false);
  const [getAllAccess_ServiceDataAccess, setAllAccess_ServiceDataAccess] =
    useState(false);
  const handleGetAllAccess = (bool) => {
    if (!bool) {
      setAllAccess(false);
    } else {
      setAllAccess(true);
    }
  };
  const handleGetAllAccess_ServiceDataAccess = (bool) => {
    if (!bool) {
      setAllAccess_ServiceDataAccess(false);
    } else {
      setAllAccess_ServiceDataAccess(true);
    }
  };
  const [getAllAccessAnomalies, setAllAccessAnomalies] = useState(false);
  const [
    getAllAccessAnomalies_serviceDataAccesses,
    setAllAccessAnomalies_serviceDataAccesses,
  ] = useState(false);
  const handleGetAllAccessAnomalies = (bool) => {
    if (!bool) {
      setAllAccessAnomalies(false);
    } else {
      setAllAccessAnomalies(true);
    }
  };

  const handleGetAllAccessAnomalies_serviceDataAccesses = (bool) => {
    if (!bool) {
      setAllAccessAnomalies_serviceDataAccesses(false);
    } else {
      setAllAccessAnomalies_serviceDataAccesses(true);
    }
  };
  const [
    getAllDataSourceDetails_serviceDataAccesses,
    setAllDataSourceDetails_serviceDataAccesses,
  ] = useState(false);
  
  const handleGetDataSourceDetails_serviceDataAccesses = (bool) => {
    if (!bool) {
      setAllDataSourceDetails_serviceDataAccesses(false);
    } else {
      setAllDataSourceDetails_serviceDataAccesses(true);
    }
  };

  const HTMlElementwith_Sensitivity = (
    dbStatement,
    sensitivityData,
    senstiveAccess,
    timestamp
  ) => {
    let htmlArr = [];
    let htmlObjArr = [];
    if (senstiveAccess) {
      dbStatement.forEach((db) => {
        sensitivityData.forEach((sensitive) => {
          if (
            db === `[${sensitive.columnName}]` ||
            db === sensitive.columnName
          ) {
            if (sensitive.category !== "LOW") {
              htmlObjArr.push({
                columnName: db,
                sensitivity: sensitive.category,
                timeStamp: timestamp,
              });
            }
          }
        });
      });
      return htmlObjArr;
    } else {
      dbStatement.forEach((db) => {
        let boolean = false;
        sensitivityData.every((sensitive) => {
          if (db.includes("/n") || db.includes("FROM")) {
            let dbStatementArr = db.split(`FROM`);
            if (
              dbStatementArr[0].includes(`[${sensitive.columnName}]`) ||
              dbStatementArr[0].includes(sensitive.columnName)
            ) {
              boolean = true;
              htmlArr.push(
                `<span class=${sensitive.category}>${dbStatementArr[0]}</span>`
              );
              htmlArr.push(`<span class="NoSensitivity">FROM</span>`);
            }
          } else if (
            db === `[${sensitive.columnName}]` ||
            db === sensitive.columnName
          ) {
            boolean = true;
            htmlArr.push(`<span class=${sensitive.category}>${db}</span>`);
          }
        });
        if (!boolean) {
          htmlArr.push(`<span class="NoSensitivity">${db}</span>`);
        }
      });
      return htmlArr;
    }
  };

  const APIcall = async (url) => {
    setTableData_SensitiveAccess([]);
    setDataLoaded(false);
    const res = await getService({
      method: "GET",
      url: url,
      headers: getAxiosHeaders(true),
      timeout: 1800000,
    });
    if (res.status === 200) {
      if (accessbannerContent.allAccess) {
        const data = res.data.content;
        const arr = data.map((item) => {
          return colorFunction(
            item.statementSensitivityInfoDetails,
            item.dbStatement,
            item.timestamp
          );
        });
        if (accessbannerContent.allAccess) {
          setDataLoaded(true);
          setTotalElements(
            res.data.totalElements ? res.data.totalElements : ""
          );
          setTableData_AllAccess(arr);
        }

        // setTableData(arr);
      } else if (accessbannerContent.sensitiveAccess) {
        const data = res.data.content;

        const updatedTableData = sensitiveAccessColoringFunction(data);

        if (accessbannerContent.sensitiveAccess) {
          setDataLoaded(true);
          setTotalElements(
            res.data.totalElements
              ? res.data.totalElements
              : updatedTableData.length
          );
          setTableData_SensitiveAccess(updatedTableData);
        }
      } else {
        setDataLoaded(true);
        setTotalElements(res.data.totalElements ? res.data.totalElements : "");
        setTableData(res.data.content.length > 0 ? res.data.content : []);
      }
    } else {
      setDataLoaded(false);
    }
  };

  //service data access-->all access,sensitive access(colour funtioning)
  const APIcall_ServiceDataAccesses = async (url) => {
    setTableData_SensitiveAccess([]);
  
    setDataLoaded(false);
    const res = await getService({
      method: "GET",
      url: url,
      headers: getAxiosHeaders(true),
    });
    if (res.status === 200) {
      if (serviceDataAccessbannerContent.allAccess) {
        const data = res.data.content;
        const arr = data.map((item) => {
          return colorFunction(
            item.statementSensitivityInfoDetails,
            item.dbStatement,
            item.timestamp
          );
        });
        if (serviceDataAccessbannerContent.allAccess) {
          setDataLoaded(true);
          setTotalElements(
            res.data.totalElements ? res.data.totalElements : ""
          );
          setTableData_AllAccess_serviceDataAccesses(arr);
        }

        // setTableData(arr);
      } else if (serviceDataAccessbannerContent.sensitiveAccess) {
        const data = res.data.content;
        
        const updatedTableData = sensitiveAccessColoringFunction(data);

        if (serviceDataAccessbannerContent.sensitiveAccess) {
          setDataLoaded(true);
          setTotalElements(
            res.data.totalElements
              ? res.data.totalElements
              : updatedTableData.length
          );
          setTableData_SensitiveAccess_serviceDataAccess(updatedTableData);
        }
      }
      //set table data/ sending data to the data source details table 
      else if (serviceDataAccessbannerContent.dataSourceDetails) {
        const data=res.data.content;
        setDataLoaded(true);
        setTotalElements(
          res.data.totalElements ? res.data.totalElements : ""
        );
        setTableData_DatasourceDetails_serviceDataAccess(data);
      }
      else{
        setDataLoaded(true);
        setTotalElements(res.data.totalElements ? res.data.totalElements : "");
        setTableData_serviceDataAccesses(
          res.data.content.length > 0 ? res.data.content : []
        );
      }
    } else {
      setDataLoaded(true);
    }
  };
  const handleChangeRowsPerPage = (value) => {
    dispatch({
      type: "SERVICE_ACCESS_LOGS_SET_RECORDS_PAGE_LOGS_DETAILS",
      payload: value,
    });
    dispatch({
      type: "SERVICE_ACCESS_LOGS_SET_CURRENT_PAGE_LOGS_DETAILS",
      payload: 0,
    });
    dispatch({ type: "GET_SERVICE_ACCESS_LOGS_INFO_DATA_DETAILS" });
  };

  const handleChangePage = (page) => {
    dispatch({
      type: "SERVICE_ACCESS_LOGS_SET_CURRENT_PAGE_LOGS_DETAILS",
      payload: page,
    });
    dispatch({ type: "GET_SERVICE_ACCESS_LOGS_INFO_DATA_DETAILS" });
  };
  const renderLoader = (serviceAccessLogsDrawerLoader) => {
    return (
      <img
        src={serviceAccessLogsDrawerLoader ? page_loader : ""}
        alt="loading"
        style={{
          position: "absolute",
          marginLeft: "40%",
          top: "60%",
        }}
      ></img>
    );
  };
  const renderHeader = (serviceAccessLogsDrawerTableData) => {
    return (
      <EnhancedTableToolbar
        totalElements={totalCountLogs}
        rows={serviceAccessLogsDrawerTableData}
        loc={"service-logs-drawer"}
        alertsTitle={"Service Data Access"}
      />
    );
  };
  const renderDetailsTable = (serviceAccessLogsDrawerTableData) => {
    return (
      <EnhancedGridTable
        cols={tableConstants_ServiceAccessLogsDrawer()}
        data={serviceAccessLogsDrawerTableData}
        totalCount={totalCountLogs}
        currentPage={currentPage_logDetails}
        recordsPerPage={recordsPerPage_logDetails}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        loc={"service-logs-drawer"}
        PaginationVisibility={false}
      />
    );
  };
  const renderTable = () => {
    return (
      <>
        {renderHeader(ServiceAccessLogsSideDrawerTableData)}
        {serviceAccessLogsDrawerLoader
          ? renderLoader(serviceAccessLogsDrawerLoader)
          : renderDetailsTable(ServiceAccessLogsSideDrawerTableData)}
      </>
    );
  };
  useEffect(() => {
    setTableData([]);
    setTableData_AllAccess([]);
    setTableData_SensitiveAccess([]);
  }, [accessDetailsDrawer]);

  useEffect(() => {
    if (accessbannerContent.allAccess) {
      //To show the loader when the number of rows is changed  
      setDataLoaded(true);
      APIcall(
        ALL_ACCESS(
          AccessDetailsSideDrawerData.actorId
            ? AccessDetailsSideDrawerData.actorId
            : "",
          AccessDetailsSideDrawerData.dataSourceId,
          "",
          "",
          AccessDetailsSideDrawerData.lastAccessedOn
            ? AccessDetailsSideDrawerData.lastAccessedOn
            : ""
        )
      );
    } else if (accessbannerContent.accessAnalomy && accessDetailsDrawer) {
      APIcall(
        ACCESS_ANOMALIES(
          AccessDetailsSideDrawerData.actorId,
          AccessDetailsSideDrawerData.dataSourceId
        )
      );
    } else if (accessbannerContent.sensitiveAccess) {
      APIcall(
        SENSITIVE_ACCESS(
          AccessDetailsSideDrawerData.actorId,
          AccessDetailsSideDrawerData.dataSourceId,
          "",
          "",
          AccessDetailsSideDrawerData.lastAccessedOn
            ? AccessDetailsSideDrawerData.lastAccessedOn
            : ""
        )
      );
    }
  }, [accessbannerContent, AccessDetailsSideDrawerData]);

  //This useEffect is meant for serviceAccessDetails table which includes
  // all access, access anamoly, sensitive access, data source details tables
  useEffect(() => {
    if (serviceDataAccessbannerContent.allAccess) {
      setDataLoaded(false);
      APIcall_ServiceDataAccesses(
        ALL_ACCESS(
          ServiceAccessDetailsSideDrawerData.id
            ? ServiceAccessDetailsSideDrawerData.id
            : "",
          ServiceAccessDetailsSideDrawerData.dataSource
            ? ServiceAccessDetailsSideDrawerData.dataSource.id
            : null,
          "",
          "",
          ServiceAccessDetailsSideDrawerData.lastAccessTime
            ? ServiceAccessDetailsSideDrawerData.lastAccessTime
            : "",
          "SERVICE_LEVEL"
        )
      );
    } else if (
      serviceDataAccessbannerContent.accessAnalomy &&
      accessDetailsDrawer
    ) {
      APIcall_ServiceDataAccesses(
        ACCESS_ANOMALIES(
          ServiceAccessDetailsSideDrawerData.id,
          ServiceAccessDetailsSideDrawerData.dataSource
            ? ServiceAccessDetailsSideDrawerData.dataSource.id
            : null
        )
      );
    } else if (serviceDataAccessbannerContent.sensitiveAccess) {
      APIcall_ServiceDataAccesses(
        SENSITIVE_ACCESS(
          ServiceAccessDetailsSideDrawerData.id,
          ServiceAccessDetailsSideDrawerData.dataSource
            ? ServiceAccessDetailsSideDrawerData.dataSource.id
            : null,
          "",
          "",
          ServiceAccessDetailsSideDrawerData.lastAccessTime
            ? ServiceAccessDetailsSideDrawerData.lastAccessTime
            : "",
          "SERVICE_LEVEL"
        )
      );
    }
    //here we are calling api and actorid since we are calling it in api at constants as actorId
    else if (
      serviceDataAccessbannerContent.dataSourceDetails) {
      APIcall_ServiceDataAccesses(
        DATASOURCE_DETAILS(
          ServiceAccessDetailsSideDrawerData.id
          ? ServiceAccessDetailsSideDrawerData.id
          : ""
        )
      );
    } 
  }, [serviceDataAccessbannerContent, ServiceAccessDetailsSideDrawerData]);
  const renderIncidentDashboard = () => {
    return (
      <div
        style={{
          width: "480px",
          padding: "30px 30px",
          boxSizing: "border-box",
        }}
      >
        <div className="mdr-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "15px",
            }}
          >
            <div className="access_Details">Incident Details</div>
            <Button
              onClick={(event) => dispatch({ type: "CLOSE_INCIDENT_DRAWER" })}
              aria-label="close"
              style={{ marginTop: "1rem" }}
            >
              <img src={CloseIcon} alt={"close"} />
            </Button>
          </div>
          <div
            style={{
              backgroundColor: "#F6F8FF",
              padding: "1rem",
              marginTop: "1rem",
            }}
          >
            <div className="AccessDetails_content_DB">
              <b> Incident Creation Time</b>:{" "}
              {incidentData["IncidentsTable.incidentTime"]
                ? moment(
                    parseInt(incidentData["IncidentsTable.incidentTime"]) +
                      diff * 60 * 1000
                  ).format("YYYY-MM-DD HH:mm:SS")
                : "-"}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Incident ID</b>: {incidentData["IncidentsTable.incidentId"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Priority</b>:
              <span
                style={{ padding: "3px" }}
                className={
                  incidentData["IncidentsTable.priority"] &&
                  `mdrIncidentTableSeverityColumn ${incidentData[
                    "IncidentsTable.priority"
                  ].toLowerCase()}`
                }
              >
                {incidentData["IncidentsTable.priority"]}
              </span>
            </div>
            <div className="AccessDetails_content_DB">
              <b> State</b>: {incidentData["IncidentsTable.status"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>State Details</b>: {incidentData["IncidentsTable.stateDetails"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Description</b>: {incidentData["IncidentsTable.name"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Resolved</b>:
              {typeof incidentData["IncidentsTable.resolved"] === "boolean" &&
              incidentData["IncidentsTable.resolved"]
                ? "true"
                : typeof incidentData["IncidentsTable.resolved"] ===
                    "boolean" && !incidentData["IncidentsTable.resolved"]
                ? "false"
                : ""}
            </div>
            <div className="AccessDetails_content_DB">
              <b>City</b>: {incidentData["IncidentsTable.city"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Assignee</b>: {incidentData["IncidentsTable.assignee"]}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPatchMonitoringDashboard = () => {
    return (
      <div
        style={{
          width: "480px",
          padding: "30px 30px",
          boxSizing: "border-box",
        }}
      >
        <div className="mdr-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "15px",
            }}
          >
            <div className="access_Details">Patch Event Details</div>
            <Button
              onClick={(event) =>
                dispatch({ type: "CLOSE_PATCH_MONITORING_DRAWER" })
              }
              aria-label="close"
              style={{ marginTop: "1rem" }}
            >
              <img src={CloseIcon} alt={"close"} />
            </Button>
          </div>
          <div
            style={{
              backgroundColor: "#F6F8FF",
              padding: "1rem",
              marginTop: "1rem",
            }}
          >
            <div className="AccessDetails_content_DB">
              <b>Published</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.creation_time"]
                ? moment(
                    patchMonitoringSideDrawerData[
                      "MDRPatchManagement.creation_time"
                    ]
                  ).format("YYYY-MM-DD HH:mm:SS")
                : "-"}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Computer</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.server"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Environment</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.subscriptionName"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b> Classification</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.classification"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b> Severity</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.severity"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Update State</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.updateState"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> KBID</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.kbid"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Title</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.title"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Approved</b>:{" "}
              {typeof patchMonitoringSideDrawerData[
                "MDRPatchManagement.approved"
              ] === "boolean" &&
              patchMonitoringSideDrawerData["MDRPatchManagement.approved"]
                ? "true"
                : typeof patchMonitoringSideDrawerData[
                    "MDRPatchManagement.approved"
                  ] === "boolean" &&
                  !patchMonitoringSideDrawerData["MDRPatchManagement.approved"]
                ? "false"
                : ""}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Optional</b>:{" "}
              {typeof patchMonitoringSideDrawerData[
                "MDRPatchManagement.optional"
              ] === "boolean" &&
              patchMonitoringSideDrawerData["MDRPatchManagement.optional"]
                ? "true"
                : typeof patchMonitoringSideDrawerData[
                    "MDRPatchManagement.optional"
                  ] === "boolean" &&
                  !patchMonitoringSideDrawerData["MDRPatchManagement.optional"]
                ? "false"
                : ""}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Resource</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.resource"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Subscription Id</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.subscriptionId"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Source Computer Id</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.sourceComputerId"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Update Id</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.updateId"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Source System</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.sourceSystem"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Product</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.product"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Os Type</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.osType"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Product Version</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.productVersion"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Product Arch</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.productArch"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Cve Numbers</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.cveNumbers"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Bulletin Url</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.bulletInUrl"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Bulletin Id</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.bulletinId"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Package Repository</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.packageRepo"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Package Severity</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.packageSeverity"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Os Name</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.osName"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Os Version</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.osVersion"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Os Full Name</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.osFullName"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Resource Group</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.resource_group"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Resource Provider</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.resourceProvider"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Resource Id</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.resourceId"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Resource Type</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.resourceType"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Computer Environment</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.computerEnvironment"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Vm Uuid</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.vmuuid"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>mg</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.mg"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Management Group Name</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.mngGroupName"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Revison Number</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.revisonNumber"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Reboot Behaviour</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.rebootBehaviour"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Msrc Bulletin Id</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.msrcBulletinId"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Approval Source</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.approvalSource"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Type</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.type"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Time Generated</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.timeGenerated"
                ]
              }
            </div>
            <div className="AccessDetails_content_DB">
              <b>Install Time Prediction Seconds</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.istpsTime"]
                ? moment(
                    patchMonitoringSideDrawerData[
                      "MDRPatchManagement.istpsTime"
                    ]
                  ).format("YYYY-MM-DD HH:mm:SS")
                : "-"}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Install Time Deviation Range Seconds</b>:{" "}
              {patchMonitoringSideDrawerData["MDRPatchManagement.istdrsTime"]
                ? moment(
                    patchMonitoringSideDrawerData[
                      "MDRPatchManagement.istdrsTime"
                    ]
                  ).format("YYYY-MM-DD HH:mm:SS")
                : "-"}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Install Time Available</b>:{" "}
              {
                patchMonitoringSideDrawerData[
                  "MDRPatchManagement.installTimeAvailable"
                ]
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderEndPointDashboard = () => {
    return (
      <div
        style={{
          width: "480px",
          padding: "30px 30px",
          boxSizing: "border-box",
        }}
      >
        <div className="mdr-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "15px",
            }}
          >
            <div className="access_Details">Agent Details</div>
            <Button
              onClick={(event) => dispatch({ type: "CLOSE_END_POINT_DRAWER" })}
              aria-label="close"
              style={{ marginTop: "1rem" }}
            >
              <img src={CloseIcon} alt={"close"} />
            </Button>
          </div>
          <div
            style={{
              backgroundColor: "#F6F8FF",
              padding: "1rem",
              marginTop: "1rem",
            }}
          >
            <div className="AccessDetails_content_DB">
              <b> Last Seen Time</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.creation_time"]
                ? moment(
                    endPointSideDrawerData["MDREndPoints.creation_time"]
                  ).format("YYYY-MM-DD HH:mm:SS")
                : "-"}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Host Name</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.hostName"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>IP Address</b>: {endPointSideDrawerData["MDREndPoints.ipv4"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>Score</b>: {endPointSideDrawerData["MDREndPoints.score"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Operating System</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.osDescription"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Environment</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.environment"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Username</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.userName"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Machine Security Configuration</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.machineSecurityConfig"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Administrator</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.adminstrator"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b> Group Policy Servername</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.groupPolicyServerName"]}
            </div>
            <div className="AccessDetails_content_DB">
              <b>OS Description</b>:{" "}
              {endPointSideDrawerData["MDREndPoints.osDescriptionFull"]}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAuthenticationDashboard = () => {
    return (
      <div
        style={{
          width: "480px",
          padding: "30px 30px",
          boxSizing: "border-box",
        }}
      >
        <div className="mdr-container">
          {loc === "MDRDashboard_AuthenticationTable_AllUsers" ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "15px",
                }}
              >
                <div className="access_Details">
                  Failed Logins: User Details
                </div>
                <Button
                  onClick={(event) =>
                    dispatch({ type: "CLOSE_AUTHENTICATION_DRAWER" })
                  }
                  aria-label="close"
                  style={{ marginTop: "1rem" }}
                >
                  <img src={CloseIcon} alt={"close"} />
                </Button>
              </div>
              <div
                style={{
                  backgroundColor: "#F6F8FF",
                  padding: "1rem",
                  marginTop: "1rem",
                }}
              >
                <div className="AccessDetails_content_DB">
                  <b>Time</b>:{" "}
                  {authenticationSideDrawerData[
                    "MdrAuthentication.creation_time"
                  ]
                    ? moment(
                        authenticationSideDrawerData[
                          "MdrAuthentication.creation_time"
                        ]
                      ).format("YYYY-MM-DD HH:mm:SS")
                    : "-"}
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Ingestion Time</b>:{" "}
                  {authenticationSideDrawerData[
                    "MdrAuthentication.ingestionTime"
                  ]
                    ? moment(
                        authenticationSideDrawerData[
                          "MdrAuthentication.ingestionTime"
                        ]
                      ).format("YYYY-MM-DD HH:mm:SS")
                    : "-"}
                </div>
                <div className="AccessDetails_content_DB">
                  <b>IP Address</b>:{" "}
                  {checkIfEmptyString(authenticationSideDrawerData["MdrAuthentication.ipAddress"])}
                </div>
                <div className="AccessDetails_content_DB">
                  <b> Source</b>:{" "}
                  {
                    checkIfEmptyString(authenticationSideDrawerData[
                      "MdrAuthentication.authErrorSource"
                    ])
                  }
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Username</b>:{" "}
                  {checkIfEmptyString(authenticationSideDrawerData["MdrAuthentication.userName"])}
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Username Details</b>:{" "}
                  {
                    checkIfEmptyString(authenticationSideDrawerData[
                      "MdrAuthentication.userNameFull"
                    ])
                  }
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Reason For Failures</b>:{" "}
                  {checkIfEmptyString(authenticationSideDrawerData["MdrAuthentication.result"])}
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Disposition</b>:{" "}
                  {
                    checkIfEmptyString(authenticationSideDrawerData[
                      "MdrAuthentication.disposition"
                    ])
                  }
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Action</b>:{" "}
                  {checkIfEmptyString(authenticationSideDrawerData["MdrAuthentication.action"])}
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Category</b>:{" "}
                  {checkIfEmptyString(authenticationSideDrawerData["MdrAuthentication.category"])}
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Server</b>:{" "}
                  {checkIfEmptyString(authenticationSideDrawerData["MdrAuthentication.server"])}
                </div>
              </div>
            </>
          ) : loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ? (
            <>
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                  }}
                >
                  <div className="access_Details">
                    Failed Logins: User By Distinct IP Details
                  </div>
                  <Button
                    onClick={(event) =>
                      dispatch({ type: "CLOSE_AUTHENTICATION_DRAWER" })
                    }
                    aria-label="close"
                    style={{ marginTop: "1rem" }}
                  >
                    <img src={CloseIcon} alt={"close"} />
                  </Button>
                </div>
                <div
                  style={{
                    backgroundColor: "#F6F8FF",
                    padding: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <div className="AccessDetails_content_DB">
                    <b>Username</b>:{" "}
                    {
                      authenticationSideDrawerData[
                        "MDRAuthenticationDistinctIP.userName"
                      ]
                    }
                  </div>
                  <div className="AccessDetails_content_DB">
                    <b>Distinct IPs</b>:{" "}
                    {
                      authenticationSideDrawerData[
                        "MDRAuthenticationDistinctIP.ipAdressCount"
                      ]
                    }
                  </div>
                  <div className="AccessDetails_content_DB">
                    <b>Login Failures</b>:{" "}
                    {
                      authenticationSideDrawerData[
                        "MDRAuthenticationDistinctIP.loginFailureCount"
                      ]
                    }
                  </div>
                </div>
              </>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "15px",
                }}
              >
                <div className="access_Details">
                  Failed Logins: IP By Distinct User Details
                </div>
                <Button
                  onClick={(event) =>
                    dispatch({ type: "CLOSE_AUTHENTICATION_DRAWER" })
                  }
                  aria-label="close"
                  style={{ marginTop: "1rem" }}
                >
                  <img src={CloseIcon} alt={"close"} />
                </Button>
              </div>
              <div
                style={{
                  backgroundColor: "#F6F8FF",
                  padding: "1rem",
                  marginTop: "1rem",
                }}
              >
                <div className="AccessDetails_content_DB">
                  <b> IP Address</b>:{" "}
                  {
                    authenticationSideDrawerData[
                      "MDRAuthenticationDistinctUsers.ipAddress"
                    ]
                  }
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Username</b>:{" "}
                  {
                    authenticationSideDrawerData[
                      "MDRAuthenticationDistinctUsers.userName"
                    ]
                  }
                </div>
                <div className="AccessDetails_content_DB">
                  <b>Login Failures</b>:{" "}
                  {
                    authenticationSideDrawerData[
                      "MDRAuthenticationDistinctUsers.loginFailureCount"
                    ]
                  }
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderAccessRights = () => (
    <div
      style={{
        width: "650px",
        padding: "20px 20px",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      {!dataLoaded && (
        <img
          src={!dataLoaded ? page_loader : ""}
          alt="loading"
          style={{
            position: "absolute",
            marginLeft: "40%",
            top: "60%",
          }}
        ></img>
      )}
      <div className="accessDetails_container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="access_Details">Access Details</div>
          <Button
            onClick={(event) => {
              setAccessbannerContent({
                accessAnalomy: true,
                allAccess: false,
                sensitiveAccess: false,
              });
              dispatch({ type: "CLOSE_ACCESS_DRAWER" });
            }}
            aria-label="close"
            style={{ marginTop: "1rem" }}
          >
            <img src={CloseIcon} alt={"close"} />
          </Button>
        </div>
        <div className="accessDetails_part2">
          <>
            <div className="AccessDetails_Name">
              {AccessDetailsSideDrawerData.username
                ? AccessDetailsSideDrawerData.username
                : "-"}
            </div>
            <div className="AccessDetails_content">
              User Email:{" "}
              {AccessDetailsSideDrawerData.emailId
                ? AccessDetailsSideDrawerData.emailId
                : "-"}
            </div>
            <div className="AccessDetails_Roles">
              User Roles:{" "}
              {isEmpty(AccessDetailsSideDrawerData.roles)
                ? "-"
                : without(AccessDetailsSideDrawerData.roles, "").join(", ")}
            </div>
            <div className="AccessDetails_content">
              Data Source Name:{" "}
              {AccessDetailsSideDrawerData.dataSourceName
                ? AccessDetailsSideDrawerData.dataSourceName
                : "-"}
            </div>
            <div className="AccessDetails_content">
              Has Sensitive Data:{" "}
              {AccessDetailsSideDrawerData.sensitiveDataAccessed
                ? AccessDetailsSideDrawerData.sensitiveDataAccessed
                : "-"}
            </div>
            <div className="AccessDetails_content">
              Sensitive Data Accessed:{" "}
              {AccessDetailsSideDrawerData.sensitiveDataAccessPercentage
                ? AccessDetailsSideDrawerData.sensitiveDataAccessPercentage.toFixed(2)
                : "0"}
              %
            </div>
            <div className="AccessDetails_content">
              Number of Anomalies:{" "}
              {AccessDetailsSideDrawerData.totalAnomalies
                ? AccessDetailsSideDrawerData.totalAnomalies
                : "-"}
            </div>
            <div className="AccessDetails_content">
              Highest Alert Severity:{" "}
              {AccessDetailsSideDrawerData.highestAlert
                ? AccessDetailsSideDrawerData.highestAlert
                : "-"}
            </div>
            <div className="AccessDetails_content">
              First Access:{" "}
              {AccessDetailsSideDrawerData.firstAccessedOn
                ? moment(
                    new Date(AccessDetailsSideDrawerData.firstAccessedOn)
                  ).format("MM-DD-YYYY HH:mm")
                : "-"}
            </div>
            <div className="AccessDetails_content">
              Last Access:{" "}
              {AccessDetailsSideDrawerData.lastAccessedOn
                ? moment(
                    new Date(AccessDetailsSideDrawerData.lastAccessedOn)
                  ).format("MM-DD-YYYY HH:mm")
                : "-"}
            </div>
          </>
        </div>
        <div className="accessDetails_banner">
          <div
            className={
              accessbannerContent.accessAnalomy
                ? "accessDetails_bannercontent active"
                : "accessDetails_bannercontent inactive"
            }
            onClick={() => accessDetailsBannerClickHandler("accessAnalomy")}
          >
            Access Anomalies
          </div>
          <div
            className={
              accessbannerContent.allAccess
                ? "accessDetails_bannercontent active"
                : "accessDetails_bannercontent inactive"
            }
            onClick={() => accessDetailsBannerClickHandler("allAccess")}
          >
            All Access
          </div>
          <div
            className={
              accessbannerContent.sensitiveAccess
                ? "accessDetails_bannercontent active"
                : "accessDetails_bannercontent inactive"
            }
            onClick={() => accessDetailsBannerClickHandler("sensitiveAccess")}
          >
            Sensitive Access
          </div>
        </div>
        <>
          {accessbannerContent.accessAnalomy && (
            <EnhancedTable
              tableData={tableData}
              headCells={accessAnalomy}
              check={true}
              loc="accessAnalomy"
              totalElements={totalElements}
              handleGetAllAccessAnomalies={handleGetAllAccessAnomalies}
              getAllAccessAnomalies={getAllAccessAnomalies}
            />
          )}
          {accessbannerContent.allAccess && (
            <EnhancedTable
              tableData={tableData_AllAccess}
              headCells={allAccess}
              check={true}
              loc="All_Access"
              totalElements={totalElements}
              getAllAccess={getAllAccess}
              handleGetAllAccess={handleGetAllAccess}
            />
          )}
          {accessbannerContent.sensitiveAccess && (
            <EnhancedTable
              tableData={tableData_SensitiveAccess}
              headCells={sensitiveAccess}
              check={true}
              loc="sensitiveAccess"
              totalElements={totalElements}
              getAllSensitiveInfo={getAllSensitiveInfo}
              handleGetSenstiveInfo={handleGetSenstiveInfo}
              setDataLoadedSenstiveAccess={setDataLoaded}
            />
          )}
        </>
      </div>
    </div>
  );

  //show more feature in side render table 
  const renderShowMore = (data) => {
    if (data && data.length > 0) {
      const dataString = data.join(", ");
      return (
        <ShowMoreData 
          data={data}
          size={15}
          fullRender={()=>
            <span>
              {dataString}
            </span>
          }
          partialRender={()=>
            <span>
              {data[0]}
            </span>
          }
        />
      );
    }
    return "-"
  }
  const renderServiceDataAccesses = () => (
    <div
      style={{
        width: "650px",
        padding: "20px 20px",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      {!dataLoaded && (
        <img
          src={!dataLoaded ? page_loader : ""}
          alt="loading"
          style={{
            position: "absolute",
            marginLeft: "40%",
            top: "60%",
          }}
        ></img>
      )}
      <div className="accessDetails_container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="access_Details">Service Data Accesses Details</div>
          <Button
            onClick={(event) => {
              setserviceDataAccessbannerContent({
                accessAnalomy: true,
                allAccess: false,
                sensitiveAccess: false,
                dataSourceDetails: false,
              });
              dispatch({ type: "CLOSE_ACCESS_DRAWER_SERVICE_DATA_ACCESS" });
            }}
            aria-label="close"
            style={{ marginTop: "1rem" }}
          >
            <img src={CloseIcon} alt={"close"} />
          </Button>
        </div>
        <div className="accessDetails_part2">
          <>
            {/*username as heading for side render table */}
            <div className="AccessDetails_Name">
              {ServiceAccessDetailsSideDrawerData.username ? ServiceAccessDetailsSideDrawerData.username : "-"}
            </div>
            <div className="AccessDetails_content">
              Application Name :{" "}
              {ServiceAccessDetailsSideDrawerData.applicationName
                ? ServiceAccessDetailsSideDrawerData.applicationName
                : "-"}
            </div>
            <div className="serviceDataAccessDetails_Roles">
              Service Name :{" "}
              {renderShowMore(ServiceAccessDetailsSideDrawerData.serviceName)}
            </div>
            <div className="serviceDataAccessDetails_Roles">
              Data Source Name :{" "}
              {renderShowMore(
                ServiceAccessDetailsSideDrawerData.dataSourceNames
              )}
            </div>
            <div className="AccessDetails_content">
              User Roles :{" "}
              {renderShowMore(
                ServiceAccessDetailsSideDrawerData.dataSourceRoles
              )}
            </div>
            <div className="AccessDetails_content">
              Data Source Username :{" "}
              {renderShowMore(ServiceAccessDetailsSideDrawerData.usernames)}
            </div>
            <div className="AccessDetails_content">
              Has Sensitive Data :{" "}
              {ServiceAccessDetailsSideDrawerData.hasSensitiveData ? (
                <span> True</span>
              ) : (
                <span> False</span>
              )}
            </div>
            <div className="AccessDetails_content">
              Sensitive Data Accessed :{" "}
              {ServiceAccessDetailsSideDrawerData.sensitiveCountPercentage
                ? ServiceAccessDetailsSideDrawerData.sensitiveCountPercentage.toFixed(2)
                : "0"}%
            </div>
            <div className="AccessDetails_content">
              Number of Anomalies :{" "}
              {ServiceAccessDetailsSideDrawerData.numOfAnomalies
                ? ServiceAccessDetailsSideDrawerData.numOfAnomalies
                : "-"}
            </div>
            <div className="AccessDetails_content">
              Highest Alert Severity :{" "}
              {ServiceAccessDetailsSideDrawerData.highSeveritySensitivityCategory
                ? ServiceAccessDetailsSideDrawerData.highSeveritySensitivityCategory
                : "-"}
            </div>
            <div className="AccessDetails_content">
              First Access :{" "}
              {ServiceAccessDetailsSideDrawerData.firstAccessTime
                ? moment(
                    new Date(ServiceAccessDetailsSideDrawerData.firstAccessTime)
                  ).format("MM-DD-YYYY HH:mm")
                : "-"}
            </div>
            <div className="AccessDetails_content">
              Last Access :{" "}
              {ServiceAccessDetailsSideDrawerData.lastAccessTime
                ? moment(
                    new Date(ServiceAccessDetailsSideDrawerData.lastAccessTime)
                  ).format("MM-DD-YYYY HH:mm")
                : "-"}
            </div>
          </>
        </div>
        <div className="accessDetails_banner">
          <div
            className={
              serviceDataAccessbannerContent.accessAnalomy
                ? "accessDetails_bannercontent active"
                : "accessDetails_bannercontent inactive"
            }
            onClick={() =>
              serviceDataDetailsBannerClickHandler("accessAnalomy")
            }
          >
            Access Anomalies
          </div>
          <div
            className={
              serviceDataAccessbannerContent.allAccess
                ? "accessDetails_bannercontent active"
                : "accessDetails_bannercontent inactive"
            }
            onClick={() => serviceDataDetailsBannerClickHandler("allAccess")}
          >
            All Access
          </div>
          <div
            className={
              serviceDataAccessbannerContent.sensitiveAccess
                ? "accessDetails_bannercontent active"
                : "accessDetails_bannercontent inactive"
            }
            onClick={() =>
              serviceDataDetailsBannerClickHandler("sensitiveAccess")
            }
          >
            Sensitive Access
          </div>
          {/* onclick function for dataSourceDetails table */}
          <div
            className={
              serviceDataAccessbannerContent.dataSourceDetails
                ? "accessDetails_bannercontent active"
                : "accessDetails_bannercontent inactive"
            }
            onClick={() =>
              serviceDataDetailsBannerClickHandler("dataSourceDetails")
            }
          >
            Data Source Details
          </div>
        </div>
        <>
          {serviceDataAccessbannerContent.accessAnalomy && (
            <EnhancedTable
              tableData={tableData_serviceDataAccesses}
              headCells={accessAnalomy}
              check={true}
              loc="accessAnalomy_serviceDataAccesses"
              totalElements={totalElements}
              handleGetAllAccessAnomalies_serviceDataAccesses={
                handleGetAllAccessAnomalies_serviceDataAccesses
              }
              getAllAccessAnomalies_serviceDataAccesses={
                getAllAccessAnomalies_serviceDataAccesses
              }
            />
          )}
          {serviceDataAccessbannerContent.allAccess && (
            <EnhancedTable
              tableData={tableData_AllAccess_serviceDataAccesses}
              headCells={allAccess}
              check={true}
              loc="All_Access_serviceDataAccesses"
              totalElements={totalElements}
              getAllAccess_ServiceDataAccess={getAllAccess_ServiceDataAccess}
              handleGetAllAccess_ServiceDataAccess={
                handleGetAllAccess_ServiceDataAccess
              }
            />
          )}
          {serviceDataAccessbannerContent.sensitiveAccess && (
            <EnhancedTable
              tableData={tableData_SensitiveAccess_serviceDataAccesses}
              headCells={sensitiveAccess}
              check={true}
              loc="sensitiveAccess_serviceDataAccesses"
              totalElements={totalElements}
              getAllSensitiveInfo_serviceDataAccess={
                getAllSensitiveInfo_serviceDataAccess
              }
              handleGetSenstiveInfo_serviceDataAccesses={
                handleGetSenstiveInfo_serviceDataAccesses
              }
              setDataLoadedSenstiveAccess={setDataLoaded}
            />
          )}
          {/* data of datasource details which includes tabledata,headcells,pagination */}
          {serviceDataAccessbannerContent.dataSourceDetails && (
            <EnhancedTable
              tableData={tableData_DatasourceDetails_serviceDataAccesses}
              headCells={dataSourceDetails}
              check={true}
              loc="datasourceDetails_serviceDataAccesses"
              totalElements={totalElements}
              getAllDataSourceDetails_serviceDataAccesses={
                getAllDataSourceDetails_serviceDataAccesses
              }
              handleGetDataSourceDetails_serviceDataAccesses={
                handleGetDataSourceDetails_serviceDataAccesses
              }
            />
          )}
        </>
      </div>
    </div>
  );

  const renderDataSourceStatus = () => (
    <>
      <div
        style={{
          width: "800px",
          padding: "20px 20px",
          boxSizing: "border-box",
        }}
      >
        <div className="accessDetails_container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="access_Details">Data Source User Details</div>
            <Button
              onClick={(event) => handleClose(event)}
              aria-label="close"
              style={{ marginTop: "1rem" }}
            >
              <img src={CloseIcon} alt={"close"} />
            </Button>
          </div>
          <div className="accessDetails_part2">
            <>
              <div className="AccessDetails_content_DB">
                Data Source User:{" "}{popUpData.username ? popUpData.username : "-"}
              </div>
              <div className="AccessDetails_content_DB">
                User Email:{" "}{popUpData.emailId ? popUpData.emailId : "-"}
              </div>
              <div className="AccessDetails_content_DB">
                Account Status:{" "}{popUpData.deleted 
                ? "Inactive" : "Active"}
              </div>
              <div className="AccessDetails_content_DB">
                User Roles:{" "}
                {isEmpty(popUpData.accessRoleNames) ? "-" :
                  popUpData.accessRoleNames &&
                  popUpData.accessRoleNames.map((el, index, arr) => (
                    <span
                      style={{
                        wordBreak: "break-all",
                        display: "inline-block",
                      }}
                    >{`${el.name}${
                      index !== arr.length - 1 && el ? "," : ""
                    }`}</span>
                  ))}
              </div>
              <div className="AccessDetails_content_DB">
                Data Source Name:{" "}{popUpData.aliasName ? popUpData.aliasName : "-"}
              </div>
              <div className="AccessDetails_content_DB">
                Access State:{" "}
                {popUpData.accountStatus
                  ? returnData(popUpData.accountStatus).value
                  : "-"}
              </div>
              <div className="AccessDetails_content_DB">
                Expiry Date:{" "}
                {popUpData.expiryDate
                  ? moment(new Date(popUpData.expiryDate)).format("MM-DD-YYYY")
                  : "-"}
              </div>
            </>
          </div>
        </div>
        <div
          style={{
            maxWidth: "760px",
            width: "750px",
          }}
        >
          <DataSourceStatus popUpData={popUpData} />
        </div>
      </div>
    </>
  );

  const renderAlert = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div style={{ display: "flex", width: "100%" }}>
        <h1
          style={{
            fontFamily: "Nunito-Regular",
            fontSize: "24px",
            fontWeight: "700 !important",
          }}
        >
          ALERT
        </h1>
        <div
          style={{ display: "flex", paddingTop: "20px", paddingLeft: "20px" }}
        >
          <LevelComponent
            level={alertDetails ? alertDetails.severity : ""}
            Id={"xyzID"}
          />
        </div>
        <Button
          onClick={(event) => handleClose(event)}
          style={{ left: "250px", top: "-9px" }}
          aria-label="close"
        >
          <img src={CloseIcon} alt={"close"} />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "grid" }}>
          <div
            style={{ display: "flex", alignItems: "center", paddingBottom: 10 }}
          >
            <QueryBuilderOutlinedIcon />
            &nbsp;{alertDetails ? getTime(alertDetails.generatedOn) : ""}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              paddingBottom: 10,
            }}
          >
            <PolicyOutlinedIcon />
            &nbsp;
            {alertDetails && alertDetails.alertPolicy
              ? alertDetails.alertPolicy.name
              : ""}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              paddingBottom: 10,
            }}
          >
            <NotificationsNoneOutlinedIcon />
            &nbsp;{triggerInfo}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              paddingBottom: 10,
            }}
          >
            <AccountCircleIcon />
            &nbsp;{username}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              // paddingBottom: 10,
            }}
          >
            <img
              src={DB_Users}
              alt={"db"}
              style={{ height: "15px !important" }}
            ></img>
            &nbsp;
            <div
              style={{
                width: "250px",
                wordWrap: "break-word",
              }}
            >
              {aliasName}
            </div>
          </div>
        </div>

        <DataMapRoute />
      </div>
      <br />
      <Divider />
      {reason && (
        <>
          <p>Reason : {reason}</p>
          <Divider />
        </>
      )}

      <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
        <p>
          {alertDetails && alertDetails.alertPolicy
            ? alertDetails.alertPolicy.description
            : ""}
        </p>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {alertDetails && alertDetails.status === "Unresolved" && (
            <>
              <MarkResolveButtonForDrawer
                variant="contained"
                color="primary"
                disabled={permissions.indexOf(updateAlert) === -1}
                onClick={() => {
                  dispatch(
                    updateAlertStatus({
                      status: "MARK_RESOLVED",
                      ids: [`${alertDetails.id}`],
                    })
                  );
                }}
                size="large"
              >
                Mark as Resolved
              </MarkResolveButtonForDrawer>
              &nbsp;
            </>
          )}
          {alertDetails &&
            (alertDetails.status === "Resolved" ||
              alertDetails.status === "False Positive") && (
              <>
                <MarkResolveButtonForDrawer
                  variant="contained"
                  color="primary"
                  disabled={permissions.indexOf(updateAlert) === -1}
                  onClick={() => {
                    dispatch(
                      updateAlertStatus({
                        status: "MARK_UNRESOLVED",
                        ids: [`${alertDetails.id}`],
                      })
                    );
                  }}
                  size="large"
                >
                  Mark as Unresolved
                </MarkResolveButtonForDrawer>
                &nbsp;
              </>
            )}
          {alertDetails &&
            (alertDetails.status === "Unresolved" ||
              alertDetails.status === "Resolved") && (
              <FalsePositiveButtonForDrawer
                hover
                variant="contained"
                color="primary"
                disabled={permissions.indexOf(updateAlert) === -1}
                onClick={() => {
                  dispatch(
                    updateAlertStatus({
                      status: "MARK_FALSE_POSITIVE",
                      ids: [`${alertDetails.id}`],
                    })
                  );
                }}
                size="large"
              >
                False Positive
              </FalsePositiveButtonForDrawer>
            )}
        </div>
      </div>
      <Divider />
      <br />
      <div
        style={{
          maxWidth: "500px",
          width: "500px",
        }}
      >
        <RelatedTable alertDetailsId={alertDetailsId} />
      </div>
      <br />
      <Divider />
      <p>Monitors</p>
      <div style={{ display: "flex" }}>
        {alertDetails.alertPolicy &&
          alertDetails.alertPolicy.monitors &&
          alertDetails.alertPolicy.monitors.map((each, index) => {
            return (
              !each.muted && (
                <Tooltip title={each.monitorThumbnail.name}>
                  <Avatar
                    src={each.picture}
                    alt={each.monitorThumbnail.name}
                    id={index}
                  />
                </Tooltip>
              )
            );
          })}
      </div>
      <br />
      <Divider />
      <p>Comments</p>
      <div>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={4}
          placeholder="Add Comments..."
          variant="outlined"
          value={newComment}
          onChange={(e) => handleNewComment(e)}
          style={{ width: "100%" }}
        />
        <DeleteButtonForToolBar
          style={{ float: "right" }}
          variant="contained"
          id="delete"
          size="small"
          disabled={permissions.indexOf(postAlertComment) === -1}
          color="primary"
          onClick={() => handleSaveComment()}
          className="min-margin"
        >
          <DoneIcon />
        </DeleteButtonForToolBar>
      </div>
      {permissions.indexOf(viewAlertComment) > -1 && (
        <div className="comments-list-arr">
          {commentsArr.map((field) => (
            <div className="comments-list">
              <div className="user-info">
                <span className="user-icon">
                  <Avatar
                    className="avatar"
                    alt={field.commentedBy.name}
                    elevation={2}
                    src={field.commentedBy.picture}
                  />
                </span>
                <span className="name-time">
                  <div>{field.commentedBy.name}</div>
                  <div className="date-time">{getTime(field.commentedOn)}</div>
                </span>
              </div>
              <div className="comments-text">{field.comments}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderServiceAccessLogs = () => (
    <div
      style={{
        width: "800px",
        padding: "20px 20px",
        boxSizing: "border-box",
        // overflowX: "hidden",9
      }}
    >
      <div className="accessDetails_container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="access_Details">Service Data Accesses Logs</div>
          <Button
            onClick={(event) => {
              dispatch({
                type: "CLOSE_ACCESS_DRAWER_SERVICE_LOGS_DATA_ACCESS",
              });
              dispatch({
                type: "SERVICE_ACCESS_LOGS_CLEAR_PAGINATION",
              });
            }}
            aria-label="close"
            style={{ marginTop: "1rem" }}
          >
            <img src={CloseIcon} alt={"close"} />
          </Button>
        </div>

        <div className="accessDetails_part2">
          <>
            <div className="AccessDetails_content">
              Time :{" "}
              {ServiceAccessLogsSideDrawerData.startTime
                ? moment(
                    new Date(ServiceAccessLogsSideDrawerData.startTime)
                  ).format("MM-DD-YYYY HH:mm")
                : "-"}
            </div>
            <div className="AccessDetails_content">
              Service User :{" "}
              {ServiceAccessLogsSideDrawerData.enterpriseActor
                ? ServiceAccessLogsSideDrawerData.enterpriseActor.username
                : " "}
            </div>
            <div className="AccessDetails_content">
              Application Name :
              {ServiceAccessLogsSideDrawerData.application
                ? ServiceAccessLogsSideDrawerData.application.applicationName
                : ""}
            </div>
            <div className="AccessDetails_content">
              Service Name:
              {ServiceAccessLogsSideDrawerData.service
                ? ServiceAccessLogsSideDrawerData.service.serviceName
                : ""}
            </div>
            <div className="AccessDetails_content">
              Sensitivity Level :{" "}
              {ServiceAccessLogsSideDrawerData.sensitivityLevel
                ? ServiceAccessLogsSideDrawerData.sensitivityLevel
                : ""}
            </div>
            <br />
            <div>{renderTable()}</div>
          </>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {loc === "Access_details" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={accessDetailsDrawer}
            onClose={(event) => dispatch({ type: "CLOSE_ACCESS_DRAWER" })}
          >
            {renderAccessRights()}
          </Drawer>
        </React.Fragment>
      ) : loc === "data-source-status" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={stateOpen}
            onClose={(event) => handleClose(event)}
          >
            {renderDataSourceStatus()}
          </Drawer>
        </React.Fragment>
      ) : loc === "Service_Data_Accesses" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={accessDetailsDrawer}
            onClose={(event) =>
              dispatch({ type: "CLOSE_ACCESS_DRAWER_SERVICE_DATA_ACCESS" })
            }
          >
            {renderServiceDataAccesses()}
          </Drawer>
        </React.Fragment>
      ) : loc === "service-database-logs" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={serviceAccessLogsDrawer}
            onClose={
              (event) =>
                dispatch({
                  type: "CLOSE_ACCESS_DRAWER_SERVICE_LOGS_DATA_ACCESS",
                })
              // handleClose(event)
            }
          >
            {renderServiceAccessLogs()}
          </Drawer>
        </React.Fragment>
      ) : loc === "MDRDashboard_IncidentTable" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={incidentDetailsDrawer}
            onClose={(event) => dispatch({ type: "CLOSE_INCIDENT_DRAWER" })}
          >
            {renderIncidentDashboard()}
          </Drawer>
        </React.Fragment>
      ) : loc === "MDRDashboard_PatchMonitoring" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={patchMonitoringDrawer}
            onClose={(event) =>
              dispatch({ type: "CLOSE_PATCH_MONITORING_DRAWER" })
            }
          >
            {renderPatchMonitoringDashboard()}
          </Drawer>
        </React.Fragment>
      ) : loc === "MDRDashboard_EndPointsTable" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={endPointDrawer}
            onClose={(event) => dispatch({ type: "CLOSE_END_POINT_DRAWER" })}
          >
            {renderEndPointDashboard()}
          </Drawer>
        </React.Fragment>
      ) : loc === "create-data-source" ? (
        <ManageDataSource
          toggleDrawer={toggleDrawer}
          getAllDataSources={getAllDataSources}
          isEditMode={isEditMode}
          isAliasEdit={isAliasEdit}
          stateOpen={stateOpen}
          classes={classes}
          dispatch={dispatch}
        />
      ) : loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
        loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={authenticationDrawer}
            onClose={(event) =>
              dispatch({ type: "CLOSE_AUTHENTICATION_DRAWER" })
            }
          >
            {renderAuthenticationDashboard()}
          </Drawer>
        </React.Fragment>
      ) : loc === "log-source" ? (
        <ManageLogSource
          toggleDrawer={toggleDrawer}
          getServiceData={getServiceData}
          stateOpen={stateOpen}
          classes={classes}
        />
      ) : loc === "Data-Map-File-Pop-up" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={dataMapFilePopUp}
            onClose={(event) => dispatch({ type: "CLOSE_FILE_POP_UP" })}
          >
            <RenderDataMapFilePopUp sourceName={sourceName} />
          </Drawer>
        </React.Fragment>
      ) : loc === "View-Report-History" ? (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paperReport,
            }}
            anchor={"right"}
            open={viewReportHistory}
            onClose={(event) => dispatch({ type: CLOSE_REPORT_HISTORY })}
          >
            <ReportHistory />
          </Drawer>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Drawer
            classes={{
              paper: classes.paper,
            }}
            anchor={"right"}
            open={stateOpen}
            onClose={(event) => handleClose(event)}
          >
            {renderAlert()}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
