import {
  USERACCESSRIGHTS_FILTERBY,
  USERACCESS_BATCHEDIT,
  OPEN_ACCESSDRAWER,
  CLOSE_ACCESSDRAWER,
  CHANGE_EXPIRYDATE,
  SET_ACCESSREQUESTID,
  FILTERBY_UPDATED,
  DATABASEUSERS_CLEARALL,
  FILTERBY_SELECTEDVALUES,
  CHANGE_SORTBYVALUE,
  SINGLEUSER_EXPIRYDATEEDITCLICK,
} from "../constants/constants";

export const changeFilterByvalue = (payload) => {
  return {
    type: USERACCESSRIGHTS_FILTERBY,
    payload: payload,
  };
};

export const batchEditClick = (payload) => {
  return {
    type: USERACCESS_BATCHEDIT,
    payload: payload,
  };
};
export const singleUserExpiryDate_EditClick = (payload) => {
  return {
    type: SINGLEUSER_EXPIRYDATEEDITCLICK,
    payload: payload,
  };
};

export const openAccess_Drawer = (payload) => {
  return {
    type: OPEN_ACCESSDRAWER,
    payload: payload,
  };
};

export const closeAccess_Drawer = (payload) => {
  return {
    type: CLOSE_ACCESSDRAWER,
    payload: payload,
  };
};

export const Change_ExpiryDate = (payload) => {
  return {
    type: CHANGE_EXPIRYDATE,
    payload: payload,
  };
};

export const setdatabaseAccessRequestId = (payload) => {
  return {
    type: SET_ACCESSREQUESTID,
    payload: payload,
  };
};

export const filterByAction = (payload) => {
  return {
    type: FILTERBY_UPDATED,
    payload: payload,
  };
};

export const DatabaseUsersPopUp_ClearAll = (payload) => {
  return {
    type: DATABASEUSERS_CLEARALL,
    payload: payload,
  };
};

export const filterBy_SelectedValues = (payload) => {
  return {
    type: FILTERBY_SELECTEDVALUES,
    payload: payload,
  };
};

export const change_SortByValue = (payload) => {
  return {
    type: CHANGE_SORTBYVALUE,
    payload: payload,
  };
};

export const changeOrderByValue = (payload) => {
  return {
    type: "CHANGE_ORDER_BY_VALUE",
    payload: payload,
  };
};
