import { DELETE_TENANT_SUCCESS, DELETE_TENANT_FAIL, CLOSE_SNACKBAR } from '../constants/constants'

const initialState = {
    deleted : false,
    successMessage: '',
    unsuccessful : ''
  };

  export const deleteTenantReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_TENANT_SUCCESS: {
            console.log("DELETE_TENANT_SUCCESS");
            return {
              ...state,
              successMessage: 'Tenant Deleted!!',
              deleted: true,
            };
          }
          case DELETE_TENANT_FAIL: {
            console.log("DELETE_TENANT_FAIL");
            return {
              ...state,
              deleted: false,
              unsuccessful: action.payload,
            };
          }
          case CLOSE_SNACKBAR: {
            console.log("CLOSE_SNACKBAR");
            return {
              ...state,
              successMessage: '',
              deleted: false,
            };
          }
          default:
            return state;
    }
  };