import React from "react";
import { Chart } from "react-google-charts";
import { QueryRenderer } from "@cubejs-client/react";
import ChartWrapper from "../Chart";
import {
  renderNoData,
  renderSingleValue,
} from "../utils";
import { viewAlert } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../utilities";

export function dataAssetsDonut(cubejsApi, title, query) {
  const returnRedirectTo = (name) => {
    let data = {
      "Total Alerts": {
        pathname: "/tenant/alerts",
        permission: [viewAlert]
      },
      "Alerts By Users": {
        pathname: "/tenant/alerts",
        permission: [viewAlert]
      }
    }
    return pathWithPermission(data[name])
  };
  let options = {
    // Material design options
    pieHole: 0.5,
    legend: "none",
  };
  options.chartArea = { left: "0%", width: "150%" };
  options.pieSliceText = "none";
  let separatorStyle = {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "1px solid #ccc",
    height: "1px",
  };
  let chartQuery = query;
  let isTotalCount = false;
  if (query && query.length > 1) {
    isTotalCount = true;
    chartQuery = query[1];
  }

  const renderLegend = (legendData) => {
    return (
      <>
        <div className="user-roles-legendData data-assets-widget">
          {legendData.map((data) => {
            let box = {
              width: "45px",
              height: "max-content",
              display:"flex",
              justifyContent:"center",
              backgroundColor: data.color,
              color: "#FFFFFF",
              padding: "5px 15px",
              borderRadius: "2px",
              fontSize: "small"
            };
            return (
              <div className="user-roles-legend data-assets-widgets">
                <div style={box}>{data.text ? data.text : "-"}</div>
                <div>
                  <span className="data-assets-widget-count">{data.count}</span>
                </div>
              </div>
            );
          })}
        </div>
      </>
    )
  }

  const renderTotalCount = (query, cubejsApi) => {
    return (
      <div className="widget-header2">
        <span className="span-total-count">
          <QueryRenderer
            query={query}
            cubejsApi={cubejsApi}
            render={({ resultSet }) => {
              if (!resultSet) {
                return <div className="loader" />;
              }
              return renderSingleValue(resultSet, query.measures[0]);
            }}
          />
        </span>
      </div>
    );
  };
  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={title}
      query={chartQuery}
      render={(resultSet) => {
        let data = resultSet.loadResponse.results[0].data;
        let sensitiveColors = {
          'LOW': '#081981',
          'MEDIUM': '#FFC344',
          'HIGH': '#FF2251',
        };
        if (!data || data.length === 0) {
          return renderNoData("30%");
        }
        let projectedData = [["X", "Y"]];
        let legends = [];
        options.colors = [];
        data.forEach((d) => {
          let keys = Object.keys(d);
          let colorCode = keys[0]; // Access the first key dynamically
          let severityCode = d[colorCode];
          let pDataItem = {
            text: d[chartQuery.dimensions[0]] || "-",
            count: parseInt(d[chartQuery.measures[0]]),
          };
          pDataItem.color = sensitiveColors[severityCode];
          projectedData.push([pDataItem.text, pDataItem.count]);
          legends.push(pDataItem);
          options.colors.push(pDataItem.color);
        });

        return (
          <React.Fragment>
            {isTotalCount && renderTotalCount(query[0], cubejsApi)}
            <hr style={separatorStyle} />
            <div style={{display: "flex", alignItems: "center"}}>
            <div onClick={(e) => e.stopPropagation()}>
              <Chart
                width={"200px"}
                height={"100%"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={projectedData}
                options={options}
              />
            </div>
            {renderLegend(legends)}
            </div>
          </React.Fragment>
        );
      }}
      redirectTo={returnRedirectTo(title)}
    />
  );
}
