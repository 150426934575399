import Paper from '@material-ui/core/Paper';
import './Badge.scss'

export default function AlertBadge({ title, parentprops, InspectContent }) {
  if (!parentprops) {
    return null;
  }
  const {
    alert_count_high = 0,
    alert_count_medium = 0,
    alert_count_low = 0,
  } = parentprops;

  const alertBadgeData = [
    {
      title: 'High',
      color: 'high',
      count: alert_count_high,
    },
    {
      title: 'Medium',
      color: 'medium',
      count: alert_count_medium,
    },
    {
      title: 'Low',
      color: 'low',
      count: alert_count_low,
    },
  ];
  return (
    <Paper component="ul" className={InspectContent ? "chip-container alerts inspect-content" : "chip-container alerts"}>
      <div className="dataOwnerTitles">{title}</div>
      <div className={InspectContent ? 'alerts-container inspect-content-header' : 'alerts-container'}>
        {
          alertBadgeData.map((row) => {
            return (
              <div className={`squareBadge ${row.color}`} title={row.title}>
                <span className='squareBadge-text'>{row.count}</span>
              </div>
            );
          })
        }
      </div>
    </Paper>
  );
}