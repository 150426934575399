import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { isEmpty, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_CLOUD_DRIVE_GROUPS,
  SET_CLOUD_SOURCE_FIELDS_DATA,
} from "../../../redux/constants/constants";
import { Button, Checkbox} from "@material-ui/core";
import { GoogleDriveConnection } from "../GoogleDriveConnection";
import { renderGroupNames } from "./uitilitiesComp";
import { makeStyles } from "material-ui-core";
import InfoIcon from "../../../assets/icons/InfoIcon.svg";
import { filterGroups } from "./dataSourceUtilities";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  noOptions: {
    color: 'red', // Customize the color
    // Add any other styles you want to customize
  },
  autocompleteRoot: {
    "& ::-webkit-scrollbar": {
      width: "8px",
    },
    "& ::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "6px",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#D0D0D0",
      borderRadius: "6px",
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#C0C0C0",
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
  },
  fixedTag: {
    backgroundColor: "#e0e0e0",
  },
}));

function GoogleDriveDataSourceForm({
  isEditMode,
  error,
  removeErrorByKey,
  handleNumberInput,
  dataSourceData,
  handleConnect,
  toggleDrawer,
  saveDataSourceFieldData,
  staticInstructionsOpen,
}) {
  const dispatch = useDispatch();
  const cloudSourceData = useSelector(
    (state) => state.CreateSourceReducer.cloudSourceData
  );
  const classes = useStyles();
  const [googleConfigCheck, setGoogleConfigCheck] = useState(false);
  const {
    domainName,
    group,
    allGroups,
    limitUsers,
    cloudSourceUsers,
    noOfUsersError,
  } = cloudSourceData;

  useEffect(() => {
    if (isEditMode && dataSourceData) {
      dispatch({
        type: SET_CLOUD_SOURCE_FIELDS_DATA,
        payload: {
          ...cloudSourceData,
          group: dataSourceData.groups,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (dataSourceData && !isEmpty(dataSourceData.secret1)) {
      dispatch({
        type: GET_ALL_CLOUD_DRIVE_GROUPS,
        payload: dataSourceData.id,
      });
    }
  }, [dataSourceData]);

  const handleUserToggleChange = () => {
    let oneDriveUserCount;
    if (!limitUsers && cloudSourceUsers !==0) {
      oneDriveUserCount = cloudSourceUsers;
    } else if (!limitUsers && cloudSourceUsers === 0) {
      oneDriveUserCount = 50;
    } else {
      oneDriveUserCount= "";
    }
    dispatch({
      type: SET_CLOUD_SOURCE_FIELDS_DATA,
      payload: {
        ...cloudSourceData,
        limitUsers: !limitUsers,
        noOfUsersError: "",
        cloudSourceUsers: oneDriveUserCount,
      },
    });
  };

  const handleGoogleConfig = () => {
    //To save the field data even when you navigate to the Google Configuration screen
    saveDataSourceFieldData ();
    staticInstructionsOpen(true);
  };

  //To render the selective user toggle
  const renderSelectiveUsersToggle = () => {
    const isDefaultSelected = group.some(
      (selectedGroup) => selectedGroup.name === "Default"
    );
    return (
      <>
        <div className="selectiveUsers">
          <span className="selectiveUserText">
            Monitor Only a Subset of Users
          </span>
          <FormControlLabel
            control={
              <Switch
                checked={limitUsers}
                onChange={() => handleUserToggleChange()}
                color="primary"
                disabled={group.length === 0 || !isDefaultSelected}
              />
            }
          />
        </div>
      </>
    );
  };

  //To render the selective user field
  const renderSelectiveUserField = () => {
    if (isEditMode && (isNull(cloudSourceUsers))) {
      return null;
    } else {
      return (
        <>
          <div className="selectiveUserContainer">
            <div
              className={
                !limitUsers && !isEditMode ? "selectiveUserDisabled" : ""
              }
            >
              Number of Users
            </div>
            <TextField
              required
              disabled={!limitUsers}
              value={cloudSourceUsers}
              className={!limitUsers ? "selectiveUserFiled" : ""}
              onChange={(event) =>
                handleNumberInput(event.target.value, "users")
              }
              id="outlined-required"
              variant="outlined"
              fullWidth={true}
              placeholder="No of Users"
              type={"number"}
              autoComplete="off"
              InputProps={{ inputProps: { min: 0 } }}
            />
            {!isEmpty(noOfUsersError) ? (
              <div style={{ color: "red" }}>{noOfUsersError}</div>
            ) : null}
          </div>
        </>
      );
    }
  };

  const handleGroupNameChange = (value) => {
    const updatedGroups = [
      ...dataSourceData.groups,
      ...value.filter((option) => dataSourceData.groups.indexOf(option) === -1),
    ];
    dispatch({
      type: SET_CLOUD_SOURCE_FIELDS_DATA,
      payload: {
        ...cloudSourceData,
        group: updatedGroups,
      },
    });
  };

  const renderSelectiveUsers = () => {
    if (dataSourceData && !isEmpty(dataSourceData.secret1)) {
      const fixedOptions =
        dataSourceData.groups && dataSourceData.groups.length > 0
          ? dataSourceData.groups
          : [];
      const updatedGroups = filterGroups(group, allGroups, classes);
      return (
        <>
          <div style={{ padding: "10px 0px" }}>
            <div>Group Name</div>
            {renderGroupNames(
              fixedOptions,
              group,
              updatedGroups,
              handleGroupNameChange,
              classes
            )}
          </div>
          <div className="noOfUsersContainer">
            {renderSelectiveUsersToggle()}
            {renderSelectiveUserField()}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const handleConfirmConfiguration = (event) => {
    setGoogleConfigCheck(event.target.checked);
  };

  const renderGoogleConsent = () => {
    return (
      <div
          className={
            googleConfigCheck
              ? "logConfigurationDoneContainer logSourceConfigDone"
              : "logConfigurationDoneContainer"
          }
        >
          <FormControlLabel
            className="confirmContainer"
            control={
              <Checkbox
                color="primary"
                checked={googleConfigCheck}
                onChange={handleConfirmConfiguration}
              />
            }
            label="Please accept if Google Workspace configuration is done"
            labelPlacement="end"
            style={{ fontSize: "14px" }}
          />
          <div className="WinlogbeatContainer">
            <img src={InfoIcon} alt="info icon" />
            <div
              className="winlogbeatText"
              onClick={() => handleGoogleConfig()}
            >
              Link To Google Workspace configuration
            </div>
          </div>
        </div>
    )
  }

  return (
    <div>
      <div style={{ padding: "10px 0px" }}>
        <div>Domain Name{<span style={{ color: "red" }}>*</span>}</div>
        <TextField
          required
          value={domainName}
          onChange={(event) => {
            dispatch({
              type: SET_CLOUD_SOURCE_FIELDS_DATA,
              payload: { ...cloudSourceData, domainName: event.target.value },
            });
            removeErrorByKey("domainName");
          }}
          id="outlined-required"
          variant="outlined"
          fullWidth={true}
          placeholder="Domain Name"
          disabled={isEditMode}
        />
        {error && error.domainName ? (
          <div style={{ color: "red" }}>{error.domainName}</div>
        ) : null}
      </div>
      <div>{isEmpty(dataSourceData.secret1) ? renderGoogleConsent() : null}</div>
      {
        <GoogleDriveConnection
          onHandleConnect={handleConnect}
          dataSourceData={dataSourceData}
          toggleDrawer={toggleDrawer}
          isEditMode={isEditMode}
          googleConfigCheck = {googleConfigCheck}
        />
      }
      {renderSelectiveUsers()}
    </div>
  );
}

export default GoogleDriveDataSourceForm;
