import React, { useState } from "react";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import UserEditing from "./UserEditing";
import ManagementFooter from "../BackOfficeGeneralContainer/ManagementFooter";
const EditUsers = () => {
  const [submitted, setsubmitted] = useState(false);
  const submitHandler = () => {
    setsubmitted((state) => !state);
  };
  // const getEdited = (data) => {
  //   return data;
  // };

  return (
    <div style={{ overflow: "hidden", height: "100%" }}>
      <SignUpBackGround
        headerName="User Editing"
        header="User Editing"
        breadcrumbValue={[
          {
            value: "User Management",
            link: window.location.pathname.includes(
              "/mdr/userManagement/editUser"
            )
              ? `/mdr/userManagement`
              : `/tenant/userManagement`,
          },
          { value: "Edit", link: "" },
        ]}
        // type="marginRequired"
      ></SignUpBackGround>
      {UserEditing({ submitted: submitted })}
    </div>
  );
};

export default EditUsers;
