import React, { useState } from "react";
import "./RiskGovernance.scss";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_MITIGATION,
  CLEAR_THREATS,
  CLEAR_VULNERABILITIES,
} from "./riskPilotConstant";
import RiskPilotPopUp from "./RiskPilotPopUp";
import RiskDetails from "./RiskDetails";

const RiskPilot = () => {
  const [showRiskPilotForm, setShowRiskPilotForm] = useState(false);
  const [showNewRiskButton, setShowNewRiskButton] = useState(false);
  const [riskPilotPopUp, setRiskPilotPopUp] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const threats = useSelector((state) => state.threatReducer.threats);

  const handleClearRegister = () => {
    if (threats && Object.keys(threats).length > 0) {
      setRiskPilotPopUp(true);
    } else {
      dispatch({ type: CLEAR_THREATS });
      dispatch({ type: CLEAR_VULNERABILITIES });
      dispatch({ type: CLEAR_MITIGATION });
    }
  };
  return (
    <>
      <div className="createRRHeader RiskGovernance">
        <div className="riskGovernanceHeaderContainer">
          <div>
            <h2 className="riskHeaderTitle">Risk Pilot</h2>
          </div>
          {showNewRiskButton && threats && Object.keys(threats).length > 0 && (showRiskPilotForm || isEditMode) ? ( // Conditionally render the button if showNewRiskButton is true
            <div className="customRiskButtonContainer">
              <Button
                className="createRRButton"
                onClick={() => handleClearRegister()} // Show the riskPilotPopUp when the button is clicked
              >
                Create New
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <RiskDetails
          setShowNewRiskButton={setShowNewRiskButton}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          showRiskPilotForm={showRiskPilotForm}
          setShowRiskPilotForm={setShowRiskPilotForm}
        />{" "}
        {/* Passing setShowNewRiskButton to CreateRegister */}
      </div>
      <RiskPilotPopUp
        riskPilotPopUp={riskPilotPopUp}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        setRiskPilotPopUp={setRiskPilotPopUp}
        ShowRiskPilotForm={setShowRiskPilotForm}
      />{" "}
    </>
  );
};

export default RiskPilot;
