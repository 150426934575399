import React from "react";
import { Doughnut } from "react-chartjs-2";
import ChartWrapper from "../../../common/Dashboard/Chart";
import {
  percentageCalculation,
} from "../../../common/utilities";
import {
  Chart,
  ArcElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { renderNoData } from "../../../common/Dashboard/utils";
import "../RiskGovernance.scss";

// Register necessary Chart.js components and plugins globally
Chart.register(ArcElement, DoughnutController, Title, Tooltip, Legend);

const options = {
  plugins: {
    legend: { display: false },
    tooltip: { enabled: true },
    datalabels: { display: false }, // Hide labels on the chart slices
  },
  elements: {
    arc: { borderWidth: 0 },
  },
  maintainAspectRatio: false,
  responsive: true,
};

const MitigationPriorityWidget = (cubejsApi, name, query) => {
  const priorityLookUp = {
    HIGH: {
      name: "High",
      alt: "High Priority",
      image: "/riskPilotIcons/redDot.svg",
    },
    MEDIUM: {
      name: "Medium",
      alt: "Medium Priority",
      image: "/riskPilotIcons/orangeDot.svg",
    },
    LOW: {
      name: "Low",
      alt: "Low Priority",
      image: "/riskPilotIcons/blueDot.svg",
    },
  };

  const renderChart = (resultSet) => {
    const rawData = resultSet.loadResponse.results[0].data;

    if (!rawData || rawData.length === 0) {
      return renderNoData("30%");
    }

    // Transform the raw data into labels and data points for the chart
    const labels = ["High", "Medium", "Low"];
    const dataPoints = [0, 0, 0]; // Initial values for High, Medium, Low
    rawData.forEach((item) => {
      const priority = item["Mitigation.priority"];
      const count = parseInt(item["Mitigation.mitigationCount"], 10);
      if (priority === "HIGH") {
        dataPoints[0] += count;
      } else if (priority === "MEDIUM") {
        dataPoints[1] += count;
      } else if (priority === "LOW") {
        dataPoints[2] += count;
      }
    });
    const priorityCounts = rawData.map((e) => ({
      priority: e["Mitigation.priority"],
      count: parseInt(e["Mitigation.mitigationCount"], 10),
    }));
    // Calculate the total and percentages
    const total = priorityCounts
      .map((e) => e.count)
      .reduce((acc, value) => acc + value, 0);
    const calculatedPercentage = percentageCalculation(priorityCounts, total);

    const chartData = {
      labels: labels,
      datasets: [
        {
          data: dataPoints,
          backgroundColor: ["#FF2251", "#F68D2B", "#344BFD"],
        },
      ],
    };

    const renderHoverPercentage = () => {
      return calculatedPercentage.map((item) => {
        return (
          item.count > 0 && (
            <div className={`centerLabel ${item.priority}`}>{item.count}%</div>
          )
        );
      });
    };

    const renderLegend = () => {
      return calculatedPercentage.map((item) => {
        return (
          item.count > 0 && (
            <div
              className="MitigationLegendChild mPriority"
              style={{ gap: "3px", marginLeft: "5%" }}
            >
              <img
                src={priorityLookUp[item.priority].image}
                alt={priorityLookUp[item.priority].alt}
              />
              <div>
                {item.count}% {priorityLookUp[item.priority].name}
              </div>
            </div>
          )
        );
      });
    };

    return (
      <div>
        <hr className="threatLevelChartLine mPriority" />
        <div className="mPriorityContainer">
          <div className="chartContent">
            <div className="chartWrapper">
              <Doughnut data={chartData} options={options} />
              {renderHoverPercentage()}
              <div className="centerLabel priority">Priority</div>
            </div>
            <div
              className="mitigationParentLegendContainer mPriority"
              style={{ marginTop: "10%" }}
            >
              {renderLegend()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="threatPieChart" style={{ marginTop: "5%", width: "95%" }}>
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={name}
        query={query}
        render={renderChart}
      />
    </div>
  );
};

export default MitigationPriorityWidget;
