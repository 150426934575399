import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getCubejsApi, redirectToLogin } from '../common/Dashboard/cubejs-util';
import Page_Loader from "../../assets/vizr_images/page_loader.gif";

export default function SensitivityInformation() {
  const cubejsApi = getCubejsApi();
  const [initialData, setInitialData] = useState(null);
  const [nativeData, setNativeData] = useState(null);
  const [deepData, setDeepData] = useState(null);
  const [loader, setLoader] = useState(false);
  const sensitivityId = useSelector(
    (state) => state.dataMapReducer.dataMapFilePopUpData[`Contents.sensitivityId`]
  );

  useEffect(() => {
    async function getSensitiveFieldData(sensitivityId) {
      setLoader(true);
      const queryString = {
        dimensions: [
          'SensitivityModel.sensitivityId',
          'SensitivityModel.sourceCode',
          'SensitivityModel.dataSensitivityType',
          'SensitivityModel.informationType',
          'SensitivityModel.label',
          'SensitivityModel.category',
        ],
        filters: [
          {
            member: 'SensitivityModel.sensitivityId',
            operator: 'equals',
            values: [sensitivityId],
          },
        ],
      };
      let response;
      try {
        const resultSet = await cubejsApi.load(queryString);
        response = resultSet.rawData();
      } catch (error) {
        redirectToLogin(error);
      }
      if (response) {
        setLoader(false);
        const deepDataLocal = [];
        const initialDataLocal = [];
        const nativeDataLocal = [];
        response.forEach((each) => {
          if (each[`SensitivityModel.sourceCode`] === 'HEURISTICS') {
            initialDataLocal.push(each);
          } else if (each[`SensitivityModel.sourceCode`] === 'GCP') {
            deepDataLocal.push(each);
          } else if (each[`SensitivityModel.sourceCode`] === 'SQL SERVER') {
            nativeDataLocal.push(each);
          }
        });
        if (deepDataLocal.length > 0) {
          setDeepData(deepDataLocal);
        }
        if (initialDataLocal.length > 0) {
          setInitialData(initialDataLocal);
        }
        if (nativeDataLocal.length > 0) {
          setNativeData(nativeDataLocal);
        }
      }
    }
    if(!isEmpty(sensitivityId)) {
      getSensitiveFieldData(sensitivityId);
    }
  }, []);
  const getInitialData = () => {
    if (!isEmpty(initialData)) {
      return (
        <div className="sensitivity-classification">
          Initial Classification:
          <TableContainer className="classification-table-container">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="classification-table-cell type-header">
                    Type
                  </TableCell>
                  <TableCell className="classification-table-cell type-header">
                    Information Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialData.map((initialDataObj) => (
                  <TableRow>
                    {initialDataObj[`SensitivityModel.dataSensitivityType`] && (
                      <TableCell className="classification-table-cell">
                        {`${
                          initialDataObj[`SensitivityModel.dataSensitivityType`]
                        }`}
                      </TableCell>
                    )}
                    {initialDataObj[`SensitivityModel.informationType`] && (
                      <TableCell className="classification-table-cell">
                        {`${
                          initialDataObj[`SensitivityModel.informationType`]
                        }`}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
    return null;
  };
  const getDeepData = () => {
    if (!isEmpty(deepData)) {
      return (
        <div className="sensitivity-classification">
          Deep Classification:
          <TableContainer className="classification-table-container">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="classification-table-cell type-header">
                    Type
                  </TableCell>
                  <TableCell className="classification-table-cell type-header">
                    Information Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deepData.map((deepDataObj) => (
                  <TableRow>
                    {deepDataObj[`SensitivityModel.dataSensitivityType`] && (
                      <TableCell className="classification-table-cell">
                        {`${
                          deepDataObj[`SensitivityModel.dataSensitivityType`]
                        }`}
                      </TableCell>
                    )}
                    {deepDataObj[`SensitivityModel.informationType`] && (
                      <TableCell className="classification-table-cell">
                        {`${deepDataObj[`SensitivityModel.informationType`]}`}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
    return null;
  };

  const getNativeDate = () => {
    if (!isEmpty(nativeData)) {
      return (
        <div className="sensitivity-classification">
          Native Classification:
          <TableContainer className="classification-table-container">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="classification-table-cell type-header">
                    Type
                  </TableCell>
                  <TableCell className="classification-table-cell type-header">
                    Label
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nativeData.map((nativeDataObj) => (
                  <TableRow>
                    {nativeDataObj[`SensitivityModel.dataSensitivityType`] && (
                      <TableCell className="classification-table-cell">
                        {`${
                          nativeDataObj[`SensitivityModel.dataSensitivityType`]
                        }`}
                      </TableCell>
                    )}
                    {nativeDataObj[`SensitivityModel.label`] && (
                      <TableCell className="classification-table-cell">
                        {`${nativeDataObj[`SensitivityModel.label`]}`}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
    return null;
  };

  const renderSensitiveData = () => {
    if (isEmpty(initialData) && isEmpty(nativeData) && isEmpty(deepData)) {
      return (<>No Data Available</>)
    } else {
      return (
        <div>
          {getInitialData()}
          {getDeepData()}
          {getNativeDate()}
        </div>)
    }
  }
  return (
    <>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source oneDrive-Table"
        ></img>
      )}
      {renderSensitiveData()}
    </>
  );
}