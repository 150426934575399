import { SAVE_ALERT_COMMENT_URL } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function getCommentsByID(id) {
    const options = {
        method: "GET",
        url: `${SAVE_ALERT_COMMENT_URL}/${id}/comments?size=200`,
        headers: getAxiosHeaders(true),
        data: {}
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};

