import { getPositionOfNode } from "../common/utilities";
import { ONEDRIVE, POSTGRESQL,MSSQL,  MYSQL } from "../../redux/constants/constants";
import { renderDataMapIcons } from "../common/utilities";

export const guiInterFace = (subType) => {
  const type = subType.type.code;
  return {
    color: {
      background: nodeBgColor(subType),
      border: nodeBorderColor(type),
      highlight: {
        background: "#f9faff",
        border: "#b8b7d0",
      },
      hover: {
        background: "#f9faff",
        border: "#b8b7d0",
      },
    },
    shape: "circularImage",
    image: nodeImageType(subType),
    size: nodeSizeType(type),
    type: type,
    borderWidth: nodeBorderWidth(subType),
    borderWidthSelected: nodeBorderWidth(subType, "selected"),
  };
};

const selectedDataFlowObj = {
  nodes: [{ id: "aa22ce40-8afc-11eb-9048-0273868cb945" }],
  name: "Unusual Path",
  id: 12,
};

export const nodeImageType = (subType) => {
  const location = "DataMapMain";
  return renderDataMapIcons(subType,location);
};

export const nodeSizeType = (type) => {
  // 104       - 13
  // 90        - 11
  // 68        - 8
  // 48        - 6
  // 24        - 3
  if (type === "APPLICATION") {
    return 45;
  } else if (type === "DATASOURCE") {
    return 70;
  } else if (type === "WEB_SERVER") {
    return 24;
  } else if (type === "FILEBEATS_DATABASE") {
    return 12;
  } else if (type === "USER") {
    return 15;
  }
};

export const nodeBgColor = (subType) => {
  const type = subType.type.code;
  if (subType === ONEDRIVE || subType === POSTGRESQL) {
    return '#ffffff'
  }
  if (type === "APPLICATION") {
    return "#fffbfc";
  } else if (type === "DATASOURCE") {
    return "#f8f9ff";
  } else if (type === "WEB_SERVER") {
    return "#ffffff";
  } else if (type === "FILEBEATS_DATABASE") {
    return "#f8f9ff";
  } else if (type === "USER") {
    return "#f8f9ff";
  }
};

export const nodeBorderWidth = (subType) => {
  const type = subType.type.code;
  if (subType.code === ONEDRIVE ||
    subType.code === POSTGRESQL ||
    subType.code === MSSQL ||
    subType.code === MYSQL) {
    return 4;
  }
  if (type === "APPLICATION") {
    return 4;
  } else if (type === "DATASOURCE") {
    return 4;
  } else if (type === "WEB_SERVER") {
    return 6;
  } else if (type === "FILEBEATS_DATABASE") {
    return 3;
  } else if (type === "USER") {
    return 3;
  }
};

export const nodeBorderColor = (type) => {
  // border
  // gray  :'#b8b7d0'
  // green : '#2ce68f'
  // red: '#ff2251'
  // blue : '#2947fd'

  // label
  // bg- '#e8e8e8'

  // arrow - color
  // black - '#292929'
  // red: '#ff2251'

  if (type === "APPLICATION") {
    return "#b8b7d0";
  } else if (type === "DATASOURCE") {
    return "#b8b7d0";
  } else if (type === "WEB_SERVER") {
    return "#b8b7d0";
  } else if (type === "FILEBEATS_DATABASE") {
    return "#b8b7d0";
  } else if (type === "USER") {
    return "#b8b7d0";
  }
};

// Data map nodes generation
export const nodesGeneration = (list) => {
  const graphNodes = [];
  list.forEach((item, ind) => {
    const nodeItem = item;
    const nodeObj = {
      id: nodeItem["id"],
      label: nodeItem["aliasName"] ? validateName(nodeItem["aliasName"]) : validateName(nodeItem["name"]),
      applicationId: nodeItem["applicationId"],
      serviceId: nodeItem["serviceId"],
      creationTime: nodeItem["creationTimeStamp"],
      ...guiInterFace(nodeItem["subType"]),
      shapeProperties: { useImageSize: false },
      font: {
        size: 14,
        align: "center",
        vadjust: 10,
      },
      shadow: {
        color: "#b8b7d0",
        enabled: true,
        size: 48,
        x: 0,
        y: 0,
      },
      metrics: nodeItem["metrics"],
      subType: nodeItem["subType"],
      uiConfiguration: nodeItem.uiConfiguration[nodeItem.id],
    };
    graphNodes.push(nodeObj);
  });
  return graphNodes;
};

// Data map edges generation
export const edgesGeneration = (hierarchy) => {
  const graphEdges = [];
  //sources : incoming;
  //targets: outgoing
  const dataMapHierarchy = Object.keys(hierarchy);
  if (dataMapHierarchy && dataMapHierarchy.length) {
    dataMapHierarchy.forEach(function (key) {
      if (hierarchy[key].targets && hierarchy[key].targets.length) {
        hierarchy[key].targets.forEach(function (targetId) {
          const edgeObj = {
            from: key,
            to: targetId.id,
            color: {
              color: "#b8b7d0",
            },
            arrows: {
              to: { enabled: true, scaleFactor: 0.5, type: "arrow" },
            },
            width: 2,
          };
          if (selectedDataFlowObj && selectedDataFlowObj.nodes.length) {
            const nodeItem = selectedDataFlowObj.nodes.find(
              (item) => item.id === key
            );
            if (nodeItem) {
              edgeObj.dashes = true; //[2,2,5,5];
              edgeObj.color = {
                color: "#ff2251",
              };
            }
          }
          graphEdges.push(edgeObj);
        });
      }
    });
    return graphEdges;
  }
};

const getLogScaleNodeSize = (nodeCount) => {
  return Math.log(nodeCount) + 25; //25 is default size of node
}

export const getNodeProperties = (dataObj) => {
  const GREEN = "#2ce68f";
  const RED = "#ff2251";
  dataObj.data.nodes.map((each, index) => {
    let uiConfig = each.uiConfiguration;

    if (uiConfig) {
      if (uiConfig.alert_status === "GREEN") {
        each["color"]["border"] = GREEN;
        each["size"] = getLogScaleNodeSize(uiConfig.node_count);
        each["color"]["highlight"]["border"] = GREEN;
        each["color"]["hover"]["border"] = GREEN;
        each["shadow"]["color"] = GREEN;
      } else if (uiConfig.alert_status === "RED") {
        each["size"] = getLogScaleNodeSize(uiConfig.node_count);
        each["color"]["border"] = RED;
        each["color"]["highlight"]["border"] = RED;
        each["color"]["hover"]["border"] = RED;
        each["shadow"]["color"] = RED;
      }
    }
    return "";
  });
};
// To generate the node positions
export const getNodePositions = (dataObj, nodePositions) => {
  dataObj.data.nodes.map((each, index) => {
    let temp = getPositionOfNode(nodePositions, each.id);
    if (temp.length > 0) {
      each["x"] = temp[0]["x"];
      each["y"] = temp[0]["y"];
    }
    return "";
  });
};

//To get the only data map nodes
export const filterDataMapNodes = (graphNodesList) => {
  let filterNodes = graphNodesList.data && graphNodesList.data.nodes.filter((obj) => {
    return obj.type !== 'USER'
  })
  return filterNodes;
}

const validateName = (name) => {
  if (name) {
    // If data source name contains any space removing the spaces and replacing with _ only
    return name.replace(/\s/ig, "_");
  }
}

// To generate the nodes for data map users
export const nodesGenerationForUsers = (usersData) => {
  const subType = {
    type: {
      code: "USER"
    }
  }
  const graphNodes = [];
  if (usersData.data.length) {
    usersData.data.forEach((item) => {
      item.topUsers.forEach(function (user) {
        const nodeObj = {
          id: user.actorId,
          label: validateName(user.userName),
          percentage: user.percentage,
          totalAnomalies: user.totalAnomalies,
          ...guiInterFace(subType),
          shapeProperties: { useImageSize: false },
          font: {
            size: 14,
            align: "center",
            vadjust: 10,
          },
          shadow: {
            color: "#b8b7d0",
            enabled: true,
            size: 48,
            x: 0,
            y: 0,
          },
        };
        graphNodes.push(nodeObj);
      })
    });
    return graphNodes;
  }
};

// To generate the Edges for data map users
export const edgesGenerationForUsers = (usersData) => {
  const graphEdges = [];
  //sources : incoming;
  //targets: outgoing
  if (usersData.data.length) {
    usersData.data.forEach(function (nodeId) {
      if (nodeId.topUsers && nodeId.topUsers.length) {
        nodeId.topUsers.forEach(function (targetId) {
          const edgeObj = {
            from: targetId.actorId,
            to: nodeId.nodeId,
            color: {
              color: "#b8b7d0",
            },
            arrows: {
              to: { enabled: true, scaleFactor: 0.5, type: "arrow" },
            },
            width: 2,
          };
          graphEdges.push(edgeObj);
        });
      }
    });
    return graphEdges;
  }
};

// To get the all data source nodes for getting the top sensitive users 
export const getNodeIds = (graphNodesList) => {
  let nodes = graphNodesList.data && graphNodesList.data.nodes.filter((obj) => {
    return obj.type !== 'USER' && obj.type === 'DATASOURCE';
  })
  return nodes;
}