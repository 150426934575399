import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";

import rootReducer from "../reducers/rootReducer";
import rootSaga from "../redux-saga/RootSaga";

const initialState = {};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [thunk, sagaMiddleware, logger];

const store = createStore(
  rootReducer,
  initialState,
  compose(composeWithDevTools(applyMiddleware(...middleware)))
);

// then run the saga
sagaMiddleware.run(rootSaga);
// sagaMiddleware.run(selfSignUppage);
export default store;
