import { GET_USER_PREFERENCES_URL } from "../constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";

export async function updateUserPreferences(userData) {
  const options = {
    method: "PUT",
    url: `${GET_USER_PREFERENCES_URL}`,
    headers: getAxiosHeaders(true),
    data: JSON.stringify(userData),
  };

  const response = await getService(options);
  if (response.status === 201) {
    return JSON.parse(response.data.preferences);
  }
}
