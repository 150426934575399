import { takeLatest, call, put,select } from 'redux-saga/effects';
import { 
  GET_ALL_USERS_URL,  
  SET_USER_EMAILS, 
  GET_ALL_USER_EMAILS, 
  SET_REPORT_LOADER, 
  REPORT_DATA, 
  GET_REPORT_HISTORY, 
  REPORT_HISTORY_DATA, 
  SAVE_REPORT_DATA, 
  SET_REPORT_DATA,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  FETCH_REPORT_DATA,
  GET_REPORT_DATA_URL,
  SET_SNACKBAR_MESSAGE,
  SET_TOTAL_REPORTS_COUNT,
  SEARCH_REPORT_DATA,
  SEARCH_REPORT_DATA_URL,
  VIEW_REPORT_URL,
  FETCH_VIEW_REPORT,
  HTML_PAGE,
  VIEW_REPORT_ERROR_MESSAGE,
  GET_REPORT_HISTORY_URL,
  SET_REPORT_HISTORY_LOADER,
  SET_SHARE_REPORT_MESSAGE,
  SHARE_REPORT_ERROR_MESSAGE,
  SHARE_REPORT_SUCCESS_MESSAGE,
  SHARE_REPORT,
  SET_SHARE_REPORT_LOADER,
  POST_MULTIPLE_REPORT_SHARE,
  SEARCH_REPORT_ERROR_MESSAGE,
  VEW_REPORT_FAILED_MESSAGE
} from "../constants/constants";
import { getAxiosHeaders, getService } from '../../library/RestAPI';


function* fetchAllUserEmails() {
  yield put({ type: SET_REPORT_LOADER, payload: true })
  const userEmails = [];
  const options = {
    method: "GET",
    url: `${GET_ALL_USERS_URL}`,
    headers: getAxiosHeaders(true)
  };
  try {
    const res = yield getService(options);
    if (res) {
      userEmails.push(...res.data.content.map((e) => e.email));
      yield put({ type: SET_USER_EMAILS, payload: userEmails })
      yield put({ type: SET_REPORT_LOADER, payload: false })
    }
  } catch (error) {
    console.error('Error generating report:', error);
    yield put({ type: SET_REPORT_LOADER, payload: false })
  }
}

function* fetchReportData(action) {
  const size = action.payload.pageSize;
  const page = action.payload.page;
  yield put({type: SET_REPORT_LOADER, payload: true})
  const options = {
    method: "GET",
    url: GET_REPORT_DATA_URL(size, page),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({type: REPORT_DATA, payload: res.data.content})
      yield put({type: SET_TOTAL_REPORTS_COUNT, payload: res.data.totalElements})
      yield put({type: SET_REPORT_LOADER, payload: false})
    } else {
      yield put({type: SET_REPORT_LOADER, payload: false})
    }
  } catch (error) {
    console.log(error);
    yield put({type: SET_REPORT_LOADER, payload: false})
  }
}

function* fetchViewReport(action){
  yield put({type: SET_REPORT_LOADER, payload: true})
  const id = action.payload;
  const email = localStorage.getItem("email");
  const options = {
    method: "GET",
    url: VIEW_REPORT_URL(id, email),
    headers: getAxiosHeaders(true)
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res.data;
      yield put({type: HTML_PAGE, payload: data});
      yield put({type: SET_REPORT_LOADER, payload: false})
    } else if (res && res.response.status === 400){
      const errorMessage = res.response.data.errorMessage;
      yield put ({type: VIEW_REPORT_ERROR_MESSAGE, payload: errorMessage});
      yield put({type: SET_REPORT_LOADER, payload: false});
    } else {
      yield put ({type: VIEW_REPORT_ERROR_MESSAGE, payload: VEW_REPORT_FAILED_MESSAGE});
      yield put({type: SET_REPORT_LOADER, payload: false})
    }
  } catch (error) {
    yield put ({type: VIEW_REPORT_ERROR_MESSAGE, payload: VEW_REPORT_FAILED_MESSAGE});
    yield put({type: SET_REPORT_LOADER, payload: false})
  }
}

function* fetchReportHistory(action) {
  yield put({type: SET_REPORT_HISTORY_LOADER, payload: true})
  const options = {
    method: "GET",
    url: `${GET_REPORT_HISTORY_URL(action.payload)}`,
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({ type: REPORT_HISTORY_DATA, payload: res.data })
      yield put({type: SET_REPORT_HISTORY_LOADER, payload: false})
    } else {
      yield put({type: SET_REPORT_HISTORY_LOADER, payload: false})
    }
  } catch (error) {
    console.log(error);
    yield put({ type: SET_REPORT_HISTORY_LOADER, payload: false })
  }
}

function* getReportData(action){
  yield put({type: SET_REPORT_LOADER, payload: true})
  const options = {
    method: "POST",
    url:`${SAVE_REPORT_DATA}`,
    headers: getAxiosHeaders(true),
    data: action.payload
  };


  try {
      const res = yield getService(options);
      if (res && res.status === 200) {
        yield put({type: SET_SNACKBAR_MESSAGE, payload: {
          status: "success", message: SUCCESS_MESSAGE
        }});
      } else if (res && res.response.status === 400) {
        const errorData = res.response.data;
        yield put({type: SET_SNACKBAR_MESSAGE, payload: {
          status: "error", message: errorData.errorCode
        }});
      } else {
        yield put({type: SET_SNACKBAR_MESSAGE, payload: {
          status: "error", message: ERROR_MESSAGE
        }});
      }
      yield put({type: SET_REPORT_LOADER, payload: false})
  } catch (error) {
    console.log(error);
    yield put({
      type: SET_SNACKBAR_MESSAGE, payload: {
        status: "error", message: ERROR_MESSAGE
      }
    });
    yield put({ type: SET_REPORT_LOADER, payload: false })
  }
}

function* searchReport(action) {
  const size = action.payload.pageSize;
  const page = action.payload.page;
  const searchItem = action.payload.searchReport;
  yield put({type: SET_REPORT_LOADER, payload: true})
  const options = {
    method: "GET",
    url: SEARCH_REPORT_DATA_URL(size, page, searchItem),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({type: REPORT_DATA, payload: res.data.content})
      yield put({type: SET_TOTAL_REPORTS_COUNT, payload: res.data.totalElements})
      yield put({type: SET_REPORT_LOADER, payload: false})
    } else {
      yield put({
        type: SET_SHARE_REPORT_MESSAGE, payload: {
          status: "error", message: SEARCH_REPORT_ERROR_MESSAGE
        }
      });
      yield put({type: SET_REPORT_LOADER, payload: false})
    }
  } catch (error) {
    yield put({
      type: SET_SHARE_REPORT_MESSAGE, payload: {
        status: "error", message: SEARCH_REPORT_ERROR_MESSAGE
      }
    });
    yield put({type: SET_REPORT_LOADER, payload: false})
  }
}
function* shareReport(action) {
  yield put({type: SET_SHARE_REPORT_LOADER, payload: true})
  const options = {
    method: "POST",
    url: `${POST_MULTIPLE_REPORT_SHARE}`,
    headers: getAxiosHeaders(true),
    data: action.payload
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({type: SET_SHARE_REPORT_MESSAGE, payload: {
        status: "success", message: SHARE_REPORT_SUCCESS_MESSAGE
      }});
    } else {
      yield put({type: SET_SHARE_REPORT_MESSAGE, payload: {
        status: "error", message: SHARE_REPORT_ERROR_MESSAGE
      }});
    }
    yield put({type: SET_SHARE_REPORT_LOADER, payload: false})
} catch (error) {
  yield put({
    type: SET_SHARE_REPORT_MESSAGE, payload: {
      status: "error", message: SHARE_REPORT_ERROR_MESSAGE
    }
  });
  yield put({ type: SET_SHARE_REPORT_LOADER, payload: false })
}
}

// Saga watcher function
function* generateReportSaga() {
  yield takeLatest(SET_REPORT_DATA, getReportData);
  yield takeLatest(GET_ALL_USER_EMAILS, fetchAllUserEmails);
  yield takeLatest(FETCH_REPORT_DATA, fetchReportData);
  yield takeLatest(SEARCH_REPORT_DATA, searchReport);
  yield takeLatest(GET_REPORT_HISTORY, fetchReportHistory);
  yield takeLatest(FETCH_VIEW_REPORT, fetchViewReport);
  yield takeLatest(SHARE_REPORT, shareReport);
}

export default generateReportSaga;