import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Pagination } from "./TablePagination";
import { isFunction, get } from "lodash";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import "./table.scss";
import { LOADING_DATA, LOAD_EXEC_DESCRIPTION, MASKED_DATA } from "../../../redux/constants/constants"
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import CanAllow from "../CanAllow";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontFamily: "Nunito-Regular",
    fontSize: 14,
    padding: 15,
    maxWidth:"550px"
  },
  arrow: {
    fontSize: 16,
    width: 17,
    "&::before": {
      border: "1px solid #fff",
      backgroundColor: "white",
      boxSizing: "border-box",
      boxShadow: " 0 0 3px #0004",
    },
  },
}))(Tooltip);

function descendingComparator(a, b, orderBy) {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1;
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const dispatch = useDispatch();
  const { headCells, onRequestSort, order, orderBy , loc } = props;

  const createSortHandler = (event, orderId) => {
    if (onRequestSort) {
      onRequestSort(event, orderId);
    }
  };

  const renderHeaderName = (headCell) => {
    if (isFunction(headCell.title)) {
      return headCell.title();
    } else {
      return headCell.title;
    }
  };

  const renderTableHeaderCell = (headCell) => {
    if (headCell && headCell.orderId) {
      // with sort option
      return (
        <TableSortLabel
          active={orderBy === headCell.orderId}
          direction={order}
          onClick={(event) =>
            createSortHandler(event, headCell.orderId, headCell.label, true)
          }
        >
          {renderHeaderName(headCell)}
          {orderBy === headCell.orderId ? (
            <span className="visually-hidden">
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      );
    } else if (headCell && headCell.loadValueOnClick) {
      // with click to load option mainly for no of exec in db logs
      return (
        <>
          {renderHeaderName(headCell)}
          <div className="show-exec-container">
            <LightTooltip title={LOAD_EXEC_DESCRIPTION} arrow>
              <VisibilityIcon onClick={() => dispatch({type: "LOAD_VALUE_TBL_COLUMN", payload: {loc}})}/>
            </LightTooltip>
          </div>
        </>
      );
    } else {
      // only render names
      return <>{renderHeaderName(headCell)}</>
    }
  };

  const renderTableCell = (col) => {
    const arg = {};
    if (col.style) {
      arg.style = col.style;
    }
    const cellClassName = (loc === "mdr-ipConfig" || loc === "mdr-AccountId") ? "mdr-other-cell" : "other-cell";
    const headerClassName = col.headerClassName ? col.headerClassName : "";
    const combinedClassName = `${cellClassName} ${headerClassName}`;
    return (
      <TableCell
        align="left"
        padding={col.padding || "normal"}
        className={combinedClassName}
        // className = "mdr-other-cell"
        {...arg}
      >
        {renderTableHeaderCell(col)}
      </TableCell>
    );
  };

  return (
    <TableHead style={{ position: "sticky", top: "0", zIndex: "1" }}>
      <TableRow>
        {headCells.map((headCell, index) => {
          if (headCell.requiredPermission) {
            return (
              <CanAllow needs={{ permission: [headCell.requiredPermission] }}>
                {renderTableCell(headCell)}
              </CanAllow>
            );
          }
          else {
            return renderTableCell(headCell)
          }
        })
        }
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}));

export default function EnhancedTable(props) {
  const {
    cols,
    data,
    totalCount,
    currentPage,
    recordsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    order,
    orderBy,
    loc,
    tableClickHandler,
    PaginationVisibility
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const renderTableCell = (col, item) => {
    const arg = {};
    if (col.rowBodyCellStyle) {
      arg.style = col.rowBodyCellStyle;
    }
    //for alerts table where we have datasource loading show loader
    if (
      (col.title === "Data Source Name" && item.dataSource && item.dataSource === LOADING_DATA)
    ) {
      // if Data Source Name or Number Of Executions we have async loading
      return (
        <TableCell align="left" padding={col.padding || "normal"} {...arg}>
          <div class="loading"></div>
        </TableCell>
      );
    }
    if (
      (col.title === "Number Of Executions" && (item.noOfExecutions !== null || item.noOfExecutions !== undefined))
    ) {
      if (item.noOfExecutions === LOADING_DATA) {
        // if Data Source Name or Number Of Executions we have async loading
        return (
          <TableCell align="left" padding={col.padding || "normal"} {...arg}>
            <div class="loading"></div>
          </TableCell>
        );
      } else if (item.noOfExecutions === MASKED_DATA) {
        return (
          <TableCell align="left" padding={col.padding || "normal"} {...arg}>
          ...
          </TableCell>
        );
      }
    }
    return (
      <TableCell align="left" padding={col.padding || "normal"} {...arg}>
        {col.render(item)}
      </TableCell>
    );
  };

  const renderCols = (item) => {
    return cols.map((col) => {
      if (col.requiredPermission) {
        return (
          <CanAllow needs={{permission: [col.requiredPermission]}}>
            {renderTableCell(col, item)}
          </CanAllow>
        );
      }
      else {
        return renderTableCell(col, item);
      }
    })
  };
  const renderTableRowWithClick = (deleteClassname, isItemSelected, index, item, rowClassName) => {
    return (
      <TableRow
        className={`enhanced-table-row ${deleteClassname} ${rowClassName}`}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={index}
        selected={isItemSelected}
        onClick={(event) => handleClick(item, event)}
      >
        {renderCols(item)}
      </TableRow>
    );
  }
  const renderRows = (deleteClassname, isItemSelected, index, item, rowClassName) => {
    const tableRowWithoutClick = (
      <TableRow
        className={`enhanced-table-row ${deleteClassname} ${rowClassName}`}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={index}
        selected={isItemSelected}
      >
        {renderCols(item)}
      </TableRow>
    );
    if (props.permission) {
      return (
        <CanAllow
          needs={{ permission: [props.permission] }}
          component={tableRowWithoutClick}
        >
          {renderTableRowWithClick(deleteClassname, isItemSelected, index, item, rowClassName)}
        </CanAllow>
      );
    } else {
      return renderTableRowWithClick(deleteClassname, isItemSelected, index, item, rowClassName);
    }
  }

  const handleClick = (data,event) => {
    if (props.onTableRowClick && isFunction(props.onTableRowClick)) {
      props.onTableRowClick(data,event);
    }
    if(loc === "service-database-logs")
    {
      tableClickHandler(data)
    }
  };

  const tableHeight = () => {
    if (loc === "report") {
      return " enhanced-grid report"
    } else {
      return " enhanced-grid"
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper + tableHeight()}>
        {data.length !== 0 && (
          <TableContainer>
            <Table
              className={classes.table + " table"}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                headCells={cols}
                onRequestSort={onRequestSort}
                order={order}
                orderBy={orderBy}
                loc = {loc}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).map(
                  (item, index) => {
                    const isItemSelected = item.checked || false;
                    const deleteClassname =
                      item && item.status && item.status.code === "DELETED"
                        ? "list-deleted"
                        : "";
                    const rowClassName = loc === "report" ? "rowClassName" : "";
                    return (
                      renderRows(deleteClassname, isItemSelected, index, item,rowClassName)
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {data.length === 0 && (
          <div align="center" style={{ padding: "10px" }}>
            <h2>No data to show</h2>
          </div>
        )}
        {(data.length !== 0 && PaginationVisibility !== "hidden")&& (
          <Pagination
            totalCount={totalCount}
            currentPage={currentPage}
            rowsPerPageOptions={[5, 20, 50, 100, 200]}
            recordsPerPage={recordsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            className="pagination-tool-bar"
          />
        )}
      </Paper>
    </div>
  );
}
