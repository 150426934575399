import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, NavLink, Link, useLocation } from "react-router-dom";
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import { useTranslation } from "react-i18next";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { NewPolicyButtonForPolicyCreateEdit } from "../../common/buttons";
import UserAccountIcon from "../../../assets/vizr_images/ic_avatar.png";

import HelpIcon from "../../../assets/vizr_images/Group-7.svg";
import LeftArrowIcon from "../../../assets/vizr_images/01. Inactive.svg";
import RightArrowIcon from "../../../assets/Images/inspect/ic_home.svg";
import DruvStarLogo from "../../../assets/vizr_images/02. Monotone Logo.svg";
import NewDruvstarLogo from "../../../assets/vizr_images/DDVWhiteLogo.svg";
import SmallDruvstarLogo from "../../../assets/vizr_images/logo.svg";
import DropDownChevron from "../../../assets/vizr_images/ic_chevron_down.svg";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import MDRLogo from "../../../assets/vizr_images/ThreatInsightLogoWhite.svg";
import MDRLogoSmall from "../../../assets/vizr_images/MDRLogoSmall.svg";
import backicon from "../../../assets/icons/backicon.svg";
import DTILogosmall from "../../../assets/icons/DTILogosmall.svg";
import { MDR, DDV, platformNames, fetchReport, generateReport, viewDataSource, RISK } from "../../../redux/constants/constants";
import { activeRoutes, updateObjectPathByName } from "../utilities";
import CanAllow from "../../shared/CanAllow";
export default function Sidebar(props) {
  let history = useHistory();
  const { routes, bgColor, expand, handleShrink, isSignuppage } = props;
  const permissions = useSelector((state) => state.applicationData.permissions);

  const profilePicture = localStorage.getItem("profilePicture");
  const displayName = localStorage.getItem("displayName");

  const [sidebarLinks, setSidebarLinks] = useState([]);
  const [showInnerLinks, setShowInnerLinks] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  var tenant = window.localStorage.getItem("tenant");
  const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
  let userRole = localStorage.getItem("user_role");
  const features =
    featuresData &&
    featuresData.features &&
    featuresData.features.map((data) => data.name);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let location = useLocation();
  const alertCount = useSelector((state) => state.alertCount.alertCount);

  const handleClickOpen = () => {
    setOpenHelpDialog(true);
  };
  const handleClose = () => {
    setOpenHelpDialog(false);
  };
  console.log(features);

  useEffect(() => {
    let temp = [];
    if (routes.length > 0 && permissions.length > 0) {
      routes.forEach((each, index) => {
        if (
          (permissions.includes(fetchReport) ||
            permissions.includes(generateReport)) &&
          !permissions.includes(viewDataSource) &&
          each.name === "System Summary"
        ) {
          each.condition = generateReport;
        }
        if (permissions.indexOf(each.condition) > -1) {
          temp.push(each);
        } else if (
          each.condition === "" &&
          tenant !== "druvstar" &&
          tenant !== "datavision"
        ) {
          temp.push(each);
        }
      });
      const customObj = updateObjectPathByName(temp, location.pathname, permissions);
      setSidebarLinks(customObj);
    }
  }, [routes, permissions, location]);

  const toggleShowInnerLinks = () => {
    setShowInnerLinks(!showInnerLinks);
  };

  useEffect(() => {
    if (
      location.pathname === "/tenant/dataFlows" ||
      location.pathname === "/tenant/dataFlowGroups" ||
      location.pathname === "/tenant/databaseLogs"
    ) {
      setShowInnerLinks(true);
    }
  }, [location]);

  const userAccountClickHandler = () => {
    if (!platformNames.includes(tenant)) {
      // window.location.href = "/tenant/profileEditing";
      dispatch({ type: "TRACK_TRAVERSAL", payload: "Profile Editing" });
      history.push("/tenant/profileEditing");
    } else {
      dispatch({ type: "TRACK_TRAVERSAL", payload: "Profile Editing" });
      history.push("/platform/profileEditing");
    }
  };

  const renderImageWithUrl = (prop, activeClassName) => {
    if (activeClassName) {
      return (
        <img
          src={activeRoutes(location, prop.path, prop.icon, prop.activeIcon)}
          alt={prop.name}
          className={
            location.pathname.indexOf(prop.path) > -1
              ? 'active side-bar-img'
              : iconStyles(prop.name)
          }
        />
      );
    } else {
      return (
        <img
          className={iconStyles(prop.name)}
          src={activeRoutes(location, prop.path, prop.icon, prop.activeIcon)}
          alt={prop.name}
        />
      );
    }
  };

  const renderIcon = (prop, activeClassName) => {
    if (prop.name === "Alerts") {
      if (alertCount > 0 && alertCount <= 39) {
        return (
          <Badge className="alert-badge"
            badgeContent={alertCount}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {renderImageWithUrl(prop, activeClassName)}
          </Badge>
        );
      } else if (alertCount > 39 && alertCount < 99) {
        return (
          <Badge
            badgeContent={alertCount}
            className="error alert-badge"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {renderImageWithUrl(prop, activeClassName)}
          </Badge>
        );
      } else if (alertCount > 98 && alertCount <= 9999) {
        return (
          <Badge className="alert-badge"
            badgeContent={alertCount}
            color="secondary"
            max={9999}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {renderImageWithUrl(prop, activeClassName)}
          </Badge>
        );
      } else if (alertCount > 9999) {
        return (
          <Badge className="alert-badge"
            badgeContent={alertCount}
            color="secondary"
            max={9999}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {renderImageWithUrl(prop, activeClassName)}
          </Badge>
        );
      } else {
        return renderImageWithUrl(prop, activeClassName);
      }
    } else {
      return renderImageWithUrl(prop, activeClassName);
    }
  };
  const handleTrackTraversal = (prop) => {
    return dispatch({
      type: "TRACK_TRAVERSAL",
      payload: `${prop.name}`,
    });
  };

  const routeDirection = () => {
    if (!platformNames.includes(tenant)) {
      return "/tenant/dashboard";
    } else {
      return "/platform/tenantManagement";
    }
  };
  const iconStyles = (name) => {
    if (name === "Dashboard") {
      return "dashboard-style side-bar-img"
    } else {
      return "side-bar-img"
    }
  }
  const mdrAccess = () => {
    if (
      (userRole.includes("DataOwner") ||
      userRole.includes("DataCustodian") ||
      userRole.includes("DataManager")) &&
      (!userRole.includes("Administrator") &&
      !userRole.includes("DevOps") &&
      !userRole.includes("Compliance") &&
      !userRole.includes("ITManager"))
    ) {
      return false;
    } else {
      return true;
    }
  };
  
  return (
    <React.Fragment>
      {expand ? (
        <div
          className="sidebar"
          style={{ height: isSignuppage ? "1075px" : "1065px" }}
          data={bgColor}
        >
          <div className="sidebar-wrapper">
            <Nav className="navbar" style={{ listStyleType: "none" }}>
              <li className="druvstar-logo">
                <Link
                  onClick={() =>
                    dispatch({ type: "TRACK_TRAVERSAL", payload: "Dashboard" })
                  }
                  to={routeDirection()}
                >
                  <img src={NewDruvstarLogo} alt={NewDruvstarLogo} />
                </Link>
              </li>
              {sidebarLinks.map((prop, key) => {
                if (prop.redirect) return null;
                if (prop.innerLinks && prop.innerLinks.length > 0) {
                  return (
                    <>
                      <li key={key} style={{ height: "60px" }}>
                        <div
                          style={{ display: "flex" }}
                          className="nav-link"
                          activeClassName="active"
                        >
                          <img
                            style={{ marginLeft: "5px" }}
                            src={prop.icon}
                            alt={prop.name}
                          />
                          <p
                            style={{
                              marginLeft: "10px",
                              color: "#ffffff",
                            }}
                          >
                            {t(prop.name)}
                          </p>
                          {showInnerLinks ? (
                            <IconButton onClick={() => toggleShowInnerLinks()}>
                              <KeyboardArrowUpOutlinedIcon
                                style={{ fill: "#ffffff" }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => toggleShowInnerLinks()}>
                              <KeyboardArrowDownOutlinedIcon
                                style={{ fill: "#ffffff" }}
                              />
                            </IconButton>
                          )}
                        </div>
                      </li>
                      {showInnerLinks &&
                        prop.innerLinks.map((each) => {
                          return (
                            <CanAllow needs={{permission: [each.condition]}}>
                              <li key={key} style={{ height: "60px" }}>
                                <Link
                                  onClick={() =>
                                    dispatch({
                                      type: "TRACK_TRAVERSAL",
                                      payload: `${each.name}`,
                                    })
                                  }
                                  className="nav-link"
                                  to={each.path}
                                  href={each.path}
                                  activeClassName="active"
                                >
                                  <img
                                    className="side-bar-img"
                                    src={activeRoutes(
                                      location,
                                      each.path,
                                      each.icon,
                                      each.activeIcon
                                    )}
                                    alt={each.name}
                                  />
                                  <p
                                    className={
                                      location &&
                                      location.pathname.indexOf(each.path) > -1
                                        ? 'side-bar-para active'
                                        : 'side-bar-para'
                                    }
                                  >
                                    {t(each.name)}
                                  </p>
                                </Link>
                              </li>
                            </CanAllow>
                          );
                        })}
                    </>
                  );
                }
                return (
                  <li key={key} style={{ height: "60px" }}>
                    <Link
                      onClick={() => handleTrackTraversal(prop)}
                      className="nav-link"
                      to={prop.path}
                      href={prop.path}
                      activeClassName="active"
                    >
                      {renderIcon(prop, false)}
                      <p
                        className={
                          location && location.pathname.indexOf(prop.path) > -1
                            ? "side-bar-para active"
                            : "side-bar-para"
                        }
                      >
                        {t(prop.name)}
                      </p>
                    </Link>
                  </li>
                );
              })}
              {features && (features.includes(MDR) || features.includes(RISK)) && mdrAccess() && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    paddingLeft: "10px",
                  }}
                >
                  <img
                    src={backicon}
                    alt="MDRLogo"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push("/MDRdashboards");
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "Threat Insights Dashboard",
                      });
                    }}
                  ></img>
                  <span
                    style={{
                      height: "64px",
                      marginLeft: "10px",
                      borderLeft: "1px solid white",
                    }}
                  ></span>
                  <img
                    src={DTILogosmall}
                    alt="MDRLogo"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push("/MDRdashboards");
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "Threat Insights Dashboard",
                      });
                    }}
                  ></img>
                </div>
              )}
              {!isSignuppage ? (
                <li className="user-account">
                  <ReactstrapNavLink onClick={userAccountClickHandler}>
                    {profilePicture ? (
                      <img className="profile-pic"
                        src={`data:image/png;base64,` + profilePicture}
                        alt={UserAccountIcon}
                      />
                    ) : (
                      <div className="userName">
                        {displayName &&
                          displayName.split(" ").map((el) => {
                            return el[0];
                          })}
                      </div>
                    )}

                    <p className="side-bar-para user-title">User Account</p>
                  </ReactstrapNavLink>
                </li>
              ) : null}

              <li className={!isSignuppage ? "active-pro" : null}>
                <ReactstrapNavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClickOpen()}
                >
                  <img className="sidebar-small-icon" src={HelpIcon} alt={HelpIcon} />
                  <p className="side-bar-para user-title">Help & Support</p>
                </ReactstrapNavLink>
              </li>
              <li className="forward" onClick={handleShrink}>
                <IconButton>
                  <img src={LeftArrowIcon} alt={LeftArrowIcon} />
                </IconButton>
              </li>
            </Nav>
          </div>
          <>
            <Dialog
              open={openHelpDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Druvstar Support"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please contact{" "}
                  <i
                    style={{
                      color: "#081981",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    ddv.support@druvstar.com
                  </i>
                  , for further queries and support.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <NewPolicyButtonForPolicyCreateEdit
                  style={{
                    marginBottom: "0px",
                    cursor: "pointer",
                    width: "124px",
                    height: "32px",
                  }}
                  onClick={() => handleClose()}
                  //autoFocus
                >
                  Close
                </NewPolicyButtonForPolicyCreateEdit>
              </DialogActions>
            </Dialog>
          </>
        </div>
      ) : (
        <div className="shrunk-sidebar" data={bgColor}>
          <div className="sidebar-wrapper">
            <Nav className="navbar shrunk" style={{ listStyleType: "none" }}>
              <li className="shrunk-druvstar-logo">
                <ReactstrapNavLink href="#" className="shrunk-druvstar-main-logo">
                  <img src={SmallDruvstarLogo} alt={SmallDruvstarLogo} />
                </ReactstrapNavLink>
              </li>
              {sidebarLinks.map((prop, key) => {
                if (prop.redirect) return null;
                if (prop.innerLinks && prop.innerLinks.length > 0) {
                  return (
                    <>
                      <li key={key} >
                        <div
                          style={{ display: "flex" }}
                          className="nav-link"
                          activeClassName="active"
                        >
                          <img
                            className="side-bar-img"
                            src={prop.icon}
                            alt={prop.name}
                          />
                          {showInnerLinks ? (
                            <IconButton className="dropdown-icon" onClick={() => toggleShowInnerLinks()}>
                              <KeyboardArrowUpOutlinedIcon
                                style={{ fill: "#ffffff" }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton className="dropdown-icon" onClick={() => toggleShowInnerLinks()}>
                              <KeyboardArrowDownOutlinedIcon
                                style={{ fill: "#ffffff" }}
                              />
                            </IconButton>
                          )}
                        </div>
                      </li>
                      {showInnerLinks &&
                        prop.innerLinks.map((each) => {
                          return (
                            <CanAllow needs={{permission: [each.condition]}}>
                              <li key={key}>
                                <Link
                                  onClick={() =>
                                    dispatch({
                                      type: "TRACK_TRAVERSAL",
                                      payload: `${each.name}`,
                                    })
                                  }
                                  className="nav-link"
                                  to={each.path}
                                  href={each.path}
                                  activeClassName="active"
                                >
                                  <img className={iconStyles(each.name)} src={activeRoutes(location, each.path, each.icon, each.activeIcon)} alt={each.name} />
                                </Link>
                              </li>
                            </CanAllow>
                          );
                        })}
                    </>
                  );
                }
                return (
                  <li key={key}>
                    <Link
                      onClick={() => handleTrackTraversal(prop)}
                      className="nav-link"
                      to={prop.path}
                      href={prop.path}
                      activeClassName="active"
                    >
                      {renderIcon(prop, true)}
                    </Link>
                  </li>
                );
              })}
              <li className="shrunk-druvstar-logo">
                {features && features.includes(MDR) && mdrAccess() && (
                  <ReactstrapNavLink href="#">
                    <img className="shrunk-druvstar-image"
                      src={MDRLogoSmall}
                      alt={MDRLogoSmall}
                      onClick={() => {
                        history.push("/MDRdashboards");
                        dispatch({
                          type: "TRACK_TRAVERSAL",
                          payload: "Threat Insights Dashboard",
                        });
                      }}
                    />
                  </ReactstrapNavLink>
                )}
              </li>
              {!isSignuppage ? (
                <li
                  className="shrink-user-account"
                  onClick={() => {
                    console.log(location);
                  }}
                >
                  <ReactstrapNavLink onClick={userAccountClickHandler}>
                    {profilePicture ? (
                      <img className="profile-pic"
                        src={`data:image/png;base64,` + profilePicture}
                        alt={UserAccountIcon}
                      />
                    ) : (
                      <div className="userName">
                        {displayName.split(" ").map((el) => {
                          return el[0];
                        })}
                      </div>
                    )}
                  </ReactstrapNavLink>
                </li>
              ) : null}

              <li className={!isSignuppage ? "active-pro" : null}>
                <ReactstrapNavLink href="">
                  <img className="sidebar-small-icon" src={HelpIcon} alt={HelpIcon} />
                </ReactstrapNavLink>
              </li>
              <li className="forward" onClick={handleShrink}>
                <IconButton>
                  <img src={RightArrowIcon} alt={"rightarrowicon"} />
                </IconButton>
              </li>
            </Nav>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
