import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import { ColorButtonForToolBar } from "../../common/buttons";
import Button from "@material-ui/core/Button";

import Permissons from "./Permissons";

import "../../../assets/scss/BackOfficeStyles/Common.scss";
import "../../../assets/scss/PlatformSetting.scss";

const PlatformSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const [agentHealth, setAgentHealth] = useState(true);
  const [permissions, setPermissions] = useState(false);
  const activeToggle = (value) => {
    if (value === "agentHealth") {
      setAgentHealth(true);
      setPermissions(false);
    } else if (value === "permissions") {
      setAgentHealth(false);
      setPermissions(true);
    }
  };

  const createClikHanlder = () => {
    if (window.localStorage.getItem("tenant") === "druvstar") {
      dispatch({type: "TRACK_TRAVERSAL" , payload: 'Platform Settings - Create Agent'}) 
      history.push(`/platform/platformSettings/createAgent`);
    }
  };
  return (
    <div className="PlatformSettings">
      <div>
        <SignUpBackGround
          headerName="Platform Settings"
          breadcrumbValue
        ></SignUpBackGround>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="header_toggler">
            <div
              className={agentHealth ? "header_toggler_active" : "header_toggler_inactive"}
              onClick={() => activeToggle("agentHealth")}
            >
              Agent Health Check
            </div>
            <div
              className={permissions ? "header_toggler_active" : "header_toggler_inactive"}
              onClick={() => activeToggle("permissions")}
            >
              Permissons
            </div>
          </div>
          {agentHealth && (
            <Button
              style={{
                width: "125px",
                height: "50px",
                marginRight: "5%",
                marginTop: "1%",
              }}
              variant="contained"
              color="primary"
              onClick={createClikHanlder}
            >
              Create
            </Button>
          )}
        </div>

        {agentHealth && (
          <div style={{ marginLeft: "250px" }}>Under Dev.....</div>
        )}
        {permissions && (
          <div style={{ marginLeft: "200px" }}>
            <Permissons></Permissons>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlatformSettings;
