import { UPDATE_POLICY_STATUS_CHANGE_REQUEST } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

// export async function updateAlertPolicyStatus(status,ids,history) {
//     let options
//     options = {
//         method: "PUT",
//         url: `${UPDATE_POLICY_STATUS_URL}/status/${status}`,
//         headers: getAxiosHeaders(true),
//         data: ids
//     };
//     const response = await getService(options);
//     if(response) {
//         console.log(response)
//         if(response.status === 204){
//             window.location.reload(false);
//         }
//     }
// };

export const updateAlertPolicyStatus = (payload) => {
    return {
      type: UPDATE_POLICY_STATUS_CHANGE_REQUEST,
      payload: payload,
    };
  };

