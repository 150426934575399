import { ADD_THREAT, DELETE_THREAT, UPDATE_THREAT } from "../riskPilotConstant";

export const addThreat = (threat) => ({
  type: ADD_THREAT,
  payload: threat,
});

export const updateThreat = (id, updates) => ({
  type: UPDATE_THREAT,
  payload: { id, updates },
});

export const deleteThreat = (id) => ({
  type: DELETE_THREAT,
  payload: id,
});
