import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import numeral from "numeral";
import { QueryRenderer } from "@cubejs-client/react";
import { Chart } from "react-google-charts";
import "./IncidentDashBoard.scss";
import { dateFormatter } from "../../Dashboard/utils";
import IncidentGreenArrow from "../../../../assets/vizr_images/IncidentGreenArrow.svg";
import IncidentRedArrow from "../../../../assets/vizr_images/IncidentRedArrow.svg";

import ChartWrapper from "../Chart";

const IncidentByState = ({
  cubejsApi,
  title,
  renderSingleValue,
  renderNoData,
  query,
  project,
}) => {
  const dateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );

  const d = new Date();
  const diff = d.getTimezoneOffset();
  const endDate = dateTimeRange && JSON.parse(dateTimeRange).endDate;

  const endDt =
    endDate && new Date(moment(endDate)).getTime() - diff * 60 * 1000; // dateFormatter(endDate, "MM-DD-YYYY HH:mm:ss");

  //Need this for future reference
  // let stDt = startDate && new Date(startDate).getTime(); // dateFormatter(startDate);
  // let endDt =
  // endDate && new Date(moment(endDate).subtract(1, "days")).getTime(); // dateFormatter(endDate);

  const getPercentageforIncidentBystate = (
    selectedDateRangeCount,
    totalCountIncludingDateRange
  ) => {
    const present = totalCountIncludingDateRange;
    const past = totalCountIncludingDateRange - selectedDateRangeCount;
    if (past) {
      const percentage = ((present - past) / past) * 100;
      return Math.round(percentage);
    }

    return present * 100;
  };
  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={title}
      query={query}
      render={(resultSet) => {
        const data = resultSet.loadResponse.results[0].data;
        return (
          <Grid container direction="column">
            {data.length > 0 ? (
              data.map((el, index) => {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    justify="space-between"
                    align="center"
                    className="incidentByState-Wrapper"
                  >
                    <Grid className="incidentState">
                      {el["Incidents.status"]}
                    </Grid>
                    <Grid className="incidentNumber">
                      {el["Incidents.count"]}
                    </Grid>
                    <Grid className={`incidentPercentage`}>
                      <QueryRenderer
                        query={{
                          dimensions: ["Incidents.status"],
                          measures: ["Incidents.count"],
                          timeDimensions: [],
                          order: {
                            "Incidents.status": "asc",
                          },
                          filters: [
                            {
                              member: "Incidents.incidentTime",
                              operator: "lte",
                              values: [`${endDt}`],
                            },
                            {
                              member: "Incidents.status",
                              operator: "equals",
                              values: [el["Incidents.status"]],
                            },
                          ],
                        }}
                        cubejsApi={cubejsApi}
                        render={({ resultSet }) => {
                          if (!resultSet) {
                            return (
                              <div className="loader">Loading Charts...</div>
                            );
                          }

                          const data = resultSet.loadResponse.results[0].data;

                          const percentage = getPercentageforIncidentBystate(
                            el["Incidents.count"],
                            data.length > 0
                              ? data[data.length - 1]["Incidents.count"]
                              : 0
                          );
                          return percentage > 0 ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <img
                                src={IncidentRedArrow}
                                alt="percentage"
                              ></img>
                              <span
                                style={{ color: "black", marginLeft: "0.5rem" }}
                              >
                                {percentage}%
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <img
                                src={IncidentGreenArrow}
                                alt="percentage"
                              ></img>
                              <span
                                style={{ color: "black", marginLeft: "0.5rem" }}
                              >
                                {percentage}%
                              </span>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <div>No Data Available</div>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default IncidentByState;
