import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  APICALL_PERMISSIONS,
  ALL_ROLES,
  UPDATE_PERMISSIONS,
} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";

import EnhancedTable from "../../shared/alertsTable";
import { headCellsForPermissons } from "../../common/headCells";

import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popperContainer: {
    height: "200%",
    width: "255px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 6px 8px 0 rgba(0,0,0,0.2)",
  },
  list: {
    listStyle: "none",
    paddingTop: "25px",
    marginRight: "25px",
  },
  listFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "12px",
  },
  listFlexImage: {
    cursor: "pointer",
    marginLeft: "10px",
  },
  button: {
    width: "15px !important",
    minWidth: "15px !important",
    height: "20px",
    borderRadius: "50px",
    backgroundColor: "#FFF",
  },
}));

const Persmissons = () => {
  const permissionsState = useSelector(
    (state) => state.applicationData.permissions
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, row) => {
    // console.log(row);
    setCurrentEditedRow(row);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const classes = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const [roleUpdated, SetroleUpdated] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [roles, setRolesData] = useState([]);
  const [currentEditedRow, setCurrentEditedRow] = useState(null);
  const [onTickHover, setonTickHover] = useState([]);
  // console.log(permissions);
  // const [paginationRows, setpaginationRows] = useState(20);
  useEffect(() => {
    const getPermissons = async () => {
      const res = await getService({
        method: "GET",
        url: APICALL_PERMISSIONS(
          localStorage.getItem("tenant")
            ? localStorage.getItem("tenant")
            : ""
        ),
        headers: getAxiosHeaders(true),
      });
      console.log(res);
    };

    getPermissons();
  }, []);
  useEffect(() => {
    // console.log(currentEditedRow, roleUpdated);
    const rolesAPIcall = async () => {
      const res = await getService({
        method: "GET",
        url: ALL_ROLES(
          localStorage.getItem("tenant")
            ? localStorage.getItem("tenant")
            : ""
        ),
        headers: getAxiosHeaders(true),
      });
      // console.log(res);

      if (res.status === 200 && res.data.content) {
        // console.log("permissons");
        setRolesData([...res.data.content]);
        let tickArr = [];
        let finalArr = new Map();
        for (let i = 0; i <= res.data.content.length - 1; i++) {
          const permissionArrr = res.data.content[i].permissions;
          tickArr.push(false);
          // console.log(res.data.content[0].permissions);
          for (let j = 0; j <= permissionArrr.length - 1; j++) {
            if (permissionsState.includes(permissionArrr[j].code)) {
              if (finalArr.has(permissionArrr[j].code)) {
                const obj = finalArr.get(permissionArrr[j].code);
                // console.log(obj.role);
                obj.role = obj.role + "," + res.data.content[i].name;
              } else {
                finalArr.set(permissionArrr[j].code, {
                  name: permissionArrr[j].code,
                  id: permissionArrr[j].id,
                  role: res.data.content[i].name,
                });
              }
            }
          }

          // console.log(finalArr);
          // console.log(finalArr.size);

          let mapToArray = Array.from(finalArr.values());
          // console.log(mapToArray);
          console.log(mapToArray);
          setPermissions([...mapToArray]);
          setonTickHover([...tickArr]);
        }
      }
    };
    rolesAPIcall();
  }, [permissionsState, currentEditedRow, roleUpdated]);
  console.log(permissions);
  const addPermissonsClickHandler = (event, name) => {
    // console.log(name);
    let id;
    let perObj = [];
    console.log(roles);
    roles &&
      roles.forEach((el) => {
        if (el.name === name) {
          id = el.id;
          el.permissions.length &&
            el.permissions.forEach((obj) => {
              perObj.push(obj.id);
            });
          perObj.push(currentEditedRow.id);
        }
      });

    console.log(perObj);

    if (perObj) {
      const updatePermissions = async () => {
        const res = await getService({
          method: "PUT",
          url: UPDATE_PERMISSIONS(
            localStorage.getItem("tenant")
              ? localStorage.getItem("tenant")
              : "",
            id
          ),
          headers: getAxiosHeaders(true),
          data: perObj,
        });
        // console.log(res);
        if (res.status === 200) {
          const obj = currentEditedRow;
          obj.role = `${currentEditedRow.role},${name}`;
          // setCurrentEditedRow({
          //   ...currentEditedRow,
          //   role: `${currentEditedRow.role},${name}`,
          // });
          console.log(obj);
          setCurrentEditedRow({ ...obj });
          SetroleUpdated((prevstate) => !prevstate);
        }
      };
      updatePermissions();
    }
  };
  const removePermissionsClickHandler = (event, name) => {
    console.log(name);
    let id;
    let perObj = [];
    console.log(roles);
    roles &&
      roles.forEach((el) => {
        if (el.name === name) {
          // console.log(el);
          id = el.id;
          el.permissions.length &&
            el.permissions.forEach((obj) => {
              if (obj.id !== currentEditedRow.id) {
                perObj.push(obj.id);
              }
            });
          console.log(currentEditedRow);
        }
      });
    console.log(perObj);
    if (perObj) {
      // console.log(perObj);
      const updatePermissions = async () => {
        const res = await getService({
          method: "PUT",
          url: UPDATE_PERMISSIONS(
            localStorage.getItem("tenant")
              ? localStorage.getItem("tenant")
              : "",
            id
          ),
          headers: getAxiosHeaders(true),
          data: perObj,
        });
        if (res.status === 200) {
          console.log(res);
          let obj = currentEditedRow;
          console.log(name, obj.role);
          const roleArr = obj.role.split(",");
          console.log(roleArr);
          const index = roleArr.indexOf(name);
          roleArr.splice(index, 1);
          const str = roleArr.join(",");
          obj.role = str;
          console.log(obj);
          setCurrentEditedRow({ ...obj });
          SetroleUpdated((prevstate) => !prevstate);
        }
      };
      updatePermissions();
    }
  };
  const onMouseHandler = (index, position) => {
    if (position === "over") {
      let arr = onTickHover;
      arr[index] = true;
      setonTickHover([...arr]);
    } else {
      let arr = onTickHover;
      arr[index] = false;
      setonTickHover([...arr]);
    }
  };

  return (
    <div>
      <EnhancedTable
        tableData={permissions}
        headCells={headCellsForPermissons}
        check={true}
        // setpaginationRows={setpaginationRows}
        loc="platformSettingsPermissons"
        permissonsClickHandler={handleClick}
      />
      <div>
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <div className={classes.popperContainer}>
            <ul className={classes.list}>
              {roles.length &&
                roles.map((el, index) => (
                  <div className={classes.listFlex} key={el.id}>
                    <li>{el.name}</li>
                    {currentEditedRow &&
                    currentEditedRow.role.includes(el.name) ? (
                      onTickHover[index] ? (
                        <Button
                          variant="contained"
                          color="default"
                          size="small"
                          className={classes.button}
                          startIcon={
                            <IndeterminateCheckBoxIcon
                              className={classes.listFlexImage}
                              // onMouseOver={() => onMouseHandler(index, "over")}
                              style={{
                                height: "17.92px",
                                width: "17.92px",
                                color: "red",
                              }}
                              onMouseOut={() => onMouseHandler(index)}
                              onClick={(event) =>
                                removePermissionsClickHandler(event, el.name)
                              }
                            ></IndeterminateCheckBoxIcon>
                          }
                        ></Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="default"
                          size="small"
                          className={classes.button}
                          startIcon={
                            <CheckCircleOutlineIcon
                              className={classes.listFlexImage}
                              style={{
                                height: "17.92px",
                                width: "17.92px",
                                color: "#2CE68F",
                              }}
                              onMouseOver={() => onMouseHandler(index, "over")}
                              // onMouseOut={() => onMouseHandler(index)}
                            ></CheckCircleOutlineIcon>
                          }
                        ></Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        className={classes.button}
                        startIcon={
                          <ControlPointIcon
                            className={classes.listFlexImage}
                            onClick={(event) => {
                              addPermissonsClickHandler(event, el.name);
                            }}
                            style={{
                              height: "17.92px",
                              width: "17.92px",
                              color: "#081981",
                            }}
                          ></ControlPointIcon>
                        }
                      ></Button>
                    )}
                  </div>
                ))}
            </ul>
          </div>
        </Popper>
      </div>
    </div>
  );
};

export default Persmissons;
