import React, { useState, useEffect } from "react";
import "../../../../assets/scss/TenantMangement.scss";
import { ViewMdrConcentratorDetailsConstants } from "../utilities";
import EnhancedGridTable from "../../../shared/Table/Table";
import { EnhancedTableToolbar } from "../Header";
import Page_Loader from "../../../../assets/vizr_images/page_loader.gif";
import { useHistory } from "react-router-dom";
import { getService, getAxiosHeaders } from "../../../../library/RestAPI";
import {
  GET_CONCENTRATOR_URL,
  CONCENTRATOR_URL,
  Concentrator,
  Ip,
  Endpoints,
} from "../../../../redux/constants/constants";
import Toggler from "../../../shared/Toggler";
import { ViewTenantIpDetails } from "../ViewIp/ViewIp";
import { ViewEndpoint } from "../viewEndpoints/ViewEndpoints";

export const ViewTenantConcentratorDetails = (props) => {
  const { id, name, platformSubdomainNameFrom, routFrom } = props;
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [loader, setLoader] = useState(false);
  const [concentratorDetails, setConcentrator] = useState([]);
  const history = useHistory();
  const [togglers, setTogglers] = useState([
    { name: "Concentrator Config", state: "active" },
    { name: "Forward IP/Subnets", state: "inactive" },
    { name: "Endpoints Concentrator Config", state: "inactive" },
  ]);

  useEffect(() => {
    setLoader(true);
    getConcentratorData();
  }, [currentPage, recordsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (routFrom === Ip) {
      setTogglers([
        { name: "Concentrator Config", state: "inactive" },
        { name: "Forward IP/Subnets", state: "active" },
        { name: "Endpoints Concentrator Config", state: "inactive" },
      ]);
    } else if (routFrom === Endpoints) {
      setTogglers([
        { name: "Concentrator Config", state: "inactive" },
        { name: "Forward IP/Subnets", state: "inactive" },
        { name: "Endpoints Concentrator Config", state: "active" },
      ]);
    }
  }, [routFrom]);

  async function getConcentratorData() {
    const data = getAxiosHeaders(true);
    data.Tenant = name;
    data["platform-subdomain-name"] = platformSubdomainNameFrom;

    const res = await getService({
      method: "GET",
      url: `${GET_CONCENTRATOR_URL}?page=${currentPage}&size=${recordsPerPage}`,
      headers: data,
    });
    if (res && res.status === 200) {
      setConcentrator(res.data.content);
      setTotalCount(res.data.totalElements);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  async function deleteConcentratorApi(id) {
    const data = getAxiosHeaders(true);
    data.Tenant = name;
    data["platform-subdomain-name"] = platformSubdomainNameFrom;

    const res = await getService({
      method: "DELETE",
      url: `${CONCENTRATOR_URL}/${id}`,
      headers: data,
    });
    if (res && res.status === 200) {
      if (currentPage > 0 && concentratorDetails.length === 1) {
        setCurrentPage(currentPage - 1);
      } else {
        getConcentratorData();
      }
    } else {
      setLoader(false);
    }
  }

  const deleteConcentratorConfig = (id) => {
    setLoader(true);
    deleteConcentratorApi(id);
  };

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleToggle = () => {};

  return (
    <>
      <div className="togglerandSearch_Conatiner">
        <Toggler
          togglers={togglers}
          setTogglers={setTogglers}
          handleToggle={handleToggle}
        ></Toggler>
      </div>
      {togglers[0].state === "active" && (
        <div className="main">
          {loader && (
            <div className="global-loader-holder">
              <img src={Page_Loader} alt="_Loader" className="loader" />
            </div>
          )}
          <div className="filter-table-container">
            <div className="table-container">
              <EnhancedTableToolbar
                totalElements={totalCount}
                loc={Concentrator}
                alertsTitle={"Concentrator Config"}
                helpDescription={"Concentrator Config"}
                id={id}
                name={name}
                platformSubdomainNameFrom={platformSubdomainNameFrom}
              />
              <EnhancedGridTable
                cols={ViewMdrConcentratorDetailsConstants(
                  history,
                  id,
                  deleteConcentratorConfig
                )}
                data={concentratorDetails}
                totalCount={totalCount}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
              />
            </div>
          </div>
        </div>
      )}
      {togglers[1].state === "active" && (
        <ViewTenantIpDetails
          id={id}
          name={name}
          platformSubdomainNameFrom={platformSubdomainNameFrom}
        />
      )}
      {togglers[2].state === "active" && (
        <ViewEndpoint
          id={id}
          name={name}
          platformSubdomainNameFrom={platformSubdomainNameFrom}
        />
      )}
    </>
  );
};
