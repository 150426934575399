import React, { useState, useEffect } from "react";
import { getCubejsApi } from "../../common/Dashboard/cubejs-util";
import { chartTypes } from "../../common/Dashboard/chartTypeToComponent";
import { useSelector, useDispatch } from "react-redux";
import EnhancedGridTable from "../../shared/Table/Table";
import { EnhancedTableToolbar } from "./EnhanaceDataLogsHeader";
import { tableConstants } from "./utilities";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import {
  ApiCall_DataSourceNames,
  ApiCall_DatabaseRoles,
  ApiCall_DataSourceTypes,
  GET_ALERT_POLICY_NAMES_URL,
  database_Sensitivity_Fields,
  APPLICATIONS_URL,
} from "../../../redux/constants/constants";
import moment from "moment";
import Toggler from "../../shared/Toggler";
import "./databaseLogs.scss"

const helpDescription =
  "Logs of users accessing data sources. Navigate to here from an alert to see the data accesses the resulted in the violation. Use time range filters to see the normal data access patterns";

export default function DirectDataAccessLogs(props) {
    const {
       propsData
      } = props;
  const dispatch = useDispatch();
  const [queryData, setQueryData] = useState(null);
  const [togglers, setTogglers] = useState([
    { name: "Direct Data Access Logs", state: "active" },
    { name: "Service Data Access Logs", state: "inactive" },
  ]);

  let alertId = null;
  let configuration;
  let configuration_from_alerts;
  let sensitivityLevel = null;
  let cubejsApi = getCubejsApi();

  if (propsData) {
    alertId = propsData.alertId || null;
    configuration = propsData.configuration || {};
    configuration_from_alerts = configuration ? JSON.parse(configuration) : {};
  }
  if (configuration_from_alerts !== undefined) {
    sensitivityLevel = configuration_from_alerts.sensitivity_info;
  }

  const fromTimeRange = useSelector(
    (state) => state.databaseLogsReducer.finalFromTimeValue
  );
  const toTimeRange = useSelector(
    (state) => state.databaseLogsReducer.finalToTimeValue
  );

  const allDatabaseLogs = useSelector(
    (state) => state.databaseLogsReducer.allDatabaseLogs
  );
  const currentPage = useSelector(
    (state) => state.databaseLogsReducer.currentPage
  );
  const recordsPerPage = useSelector(
    (state) => state.databaseLogsReducer.recordsPerPage
  );
  const totalCount = useSelector(
    (state) => state.databaseLogsReducer.totalCount
  );
  const approxCount = useSelector(
    (state) => state.databaseLogsReducer.approxCount
  );
  const loader = useSelector((state) => state.databaseLogsReducer.loader);


  useEffect(() => {
    queryConstructor();
  }, [fromTimeRange, toTimeRange]);
  const handleToggle = () => {
    dispatch({
      type: "DATA_SOURCE_CLEAR_FILTER_DATA",
    });
    dispatch({
      type: "DIRECT_DATA_SOURCE_CLEAR_FILTER_DATA",
    });
  };
  useEffect(() => {
    FilterAPICall();
    settingDataFromAlerts();
    dispatch({type: "SET_LOADER_INFO" ,payload:true})
    dispatch({ type: "FULL_RESET_DB_LOGS" });
    dispatch({ type: "GET_DATABASE_LOGS_INFO_DATA" });
    return () => {
      dispatch({ type: "DATABASE_LOGS_CLEAR_ALL" });
    };
  }, []);

  const getDataService = (url) => {
    return getService({
      method: "GET",
      url: `${url}`,
      headers: getAxiosHeaders(true),
    });
  };

  const FilterAPICall = async () => {
    await Promise.all([
      getDataService(ApiCall_DataSourceNames),
      getDataService(ApiCall_DatabaseRoles),
      getDataService(ApiCall_DataSourceTypes),
      getDataService(database_Sensitivity_Fields),
      getDataService(GET_ALERT_POLICY_NAMES_URL),
      getDataService(APPLICATIONS_URL)
    ]).then((data) =>
      data.map((apiData, index) => settingApiData(apiData.data, index))
    );
  };

  const settingApiData = (apiData, index) => {
    switch (index) {
      case 0:
        return dispatch({ type: "DATA_SOURCE_NAME", payload: apiData || "" });
      case 1:
        return dispatch({
          type: "DATABASE_ACCESS_ROLES",
          payload: apiData.content,
        });
      case 2:
        return dispatch({ type: "DATA_SOURCE_TYPE", payload: apiData.content });
      case 3:
        return dispatch({ type: "SENSITIVITY_INFO", payload: apiData.content });
      case 4:
        return dispatch({ type: "POLICY_NAMES", payload: apiData.content });
      case 5:
        return dispatch({ type:"APPLICATIONS", payload: apiData.content })
      default:
        return "";
    }
  };

  const settingDataFromAlerts = () => {
    if (propsData) {
      alertId = propsData.alertId || "";
      const configuration = propsData.configuration || {};
      configuration_from_alerts = configuration
        ? JSON.parse(configuration)
        : {};
      dispatch({ type: "SET_ALERT_ID", payload: alertId });
      
      if (configuration_from_alerts.start_time) {
        dispatch({
          type: "DATABASE_FROM_TIME_VALUE_ON_MOUNT",
          payload: configuration_from_alerts.start_time,
        });
        dispatch({
          type: "DATABASE_FROM_TIME_VALUE",
          payload: configuration_from_alerts.start_time,
        });
      }
      if (configuration_from_alerts.end_time) {
        dispatch({
          type: "DATABASE_TO_TIME_VALUE_ON_MOUNT",
          payload: configuration_from_alerts.end_time,
        });
        dispatch({
          type: "DATABASE_TO_TIME_VALUE",
          payload: configuration_from_alerts.end_time,
        });
      }

      if (configuration_from_alerts.sensitivity_info) {
        dispatch({
          type: "SET_SENSITIVITY_INFO",
          payload: {
            type: "object",
            value: configuration_from_alerts.sensitivity_info.split(","),
          },
        });
        configuration_from_alerts.sensitivity_info.split(",").map((data)=>{
          dispatch({
            type: "MAINTAIN_SINGLE_DATA",
            payload: {name:data,value:data,dispatchName:"SET_SENSITIVITY_INFO"}
          });
        }) 
        dispatch({
          type: "SET_DATABASE_SENSITIVITY_LEVEL",
          payload: configuration_from_alerts.sensitivity_info.split(","),
        });
      }
    } else {
      const fromTime = moment().subtract(1, "hours").valueOf();
      const toTime = moment().valueOf();
      dispatch({
        type: "DATABASE_FROM_TIME_VALUE_ON_MOUNT",
        payload: fromTime,
      });
      dispatch({
        type: "DATABASE_TO_TIME_VALUE_ON_MOUNT",
        payload: toTime,
      });
      dispatch({
        type: "DATABASE_FROM_TIME_VALUE",
        payload: fromTime,
      });
      dispatch({
        type: "DATABASE_TO_TIME_VALUE",
        payload: toTime,
      });
    }
  };

  const queryConstructor = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let cubeName = "DatabaseLogs";
    let paramsData = {};
    let queryDataWithFilters = {
      measures: [`${cubeName}.count`],
      timeDimensions: [
        { dimension: `${cubeName}.timeStamp`, granularity: "hour" },
      ],
      filters: [],
      timezone: tz,
    };
    if (alertId) {
      queryDataWithFilters.filters.push({
        member: `${cubeName}.alertId`,
        operator: "equals",
        values: [alertId],
      });
    }

    if (sensitivityLevel) {
      queryDataWithFilters.filters.push({
        member: `${cubeName}.sensitivityLevel`,
        operator: "equals",
        values: [...sensitivityLevel.split(",")],
      });
    }

    let setGranularity = (from, to) => {
      // Setting granularity to minute if the time range is less than 5 hours
      if (to - from <= 5 * 60 * 60 * 1000) {
        queryDataWithFilters.timeDimensions[0].granularity = "minute";
      }
      // Setting granularity to hour if the time range is less than a week
      else if (to - from <= 7 * 24 * 60 * 60 * 1000) {
        queryDataWithFilters.timeDimensions[0].granularity = "hour";
      }
      // Setting granularity to day if the time range is less than a month
      else if (to - from <= 30 * 24 * 60 * 60 * 1000) {
        queryDataWithFilters.timeDimensions[0].granularity = "day";
      }
      // Setting granularity to hour if the time range is less than a week
      else if (to - from <= 6 * 30 * 24 * 60 * 60 * 1000) {
        queryDataWithFilters.timeDimensions[0].granularity = "week";
      } else {
        queryDataWithFilters.timeDimensions[0].granularity = "month";
      }
    };

    if (fromTimeRange && toTimeRange) {
      paramsData.fromTimeStamp = fromTimeRange;
      paramsData.toTimeStamp = toTimeRange;
      queryDataWithFilters.filters.push({
        member: `${cubeName}.creationTimeStamp`,
        operator: "gte",
        values: [`${fromTimeRange}`],
      });
      queryDataWithFilters.filters.push({
        member: `${cubeName}.creationTimeStamp`,
        operator: "lte",
        values: [`${toTimeRange}`],
      });
      setGranularity(fromTimeRange, toTimeRange);
    } else if (configuration_from_alerts != undefined) {
      paramsData.fromTimeStamp = configuration_from_alerts.start_time;
      paramsData.toTimeStamp = configuration_from_alerts.end_time;

      queryDataWithFilters.filters.push({
        member: `${cubeName}.creationTimeStamp`,
        operator: "gte",
        values: [`${paramsData.fromTimeStamp}`],
      });
      queryDataWithFilters.filters.push({
        member: `${cubeName}.creationTimeStamp`,
        operator: "lte",
        values: [`${paramsData.toTimeStamp}`],
      });
      setGranularity(paramsData.fromTimeStamp, paramsData.toTimeStamp);
    } else {
      paramsData.fromTimeStamp = moment().subtract(1, "hours").valueOf();
      paramsData.toTimeStamp = moment().valueOf();

      queryDataWithFilters.filters.push({
        member: `${cubeName}.creationTimeStamp`,
        operator: "gte",
        values: [`${paramsData.fromTimeStamp}`],
      });
      queryDataWithFilters.filters.push({
        member: `${cubeName}.creationTimeStamp`,
        operator: "lte",
        values: [`${paramsData.toTimeStamp}`],
      });
      setGranularity(paramsData.fromTimeStamp, paramsData.toTimeStamp);
    }

    // Temporarily commenting the filtering logic, will uncomment once we find a way to make the cube.js work dynamically
    // let filterName = "";
    // switch (filterByDatabaseLogs) {
    //   case 'databaseAccessRole':
    //     filterName = 'enterpriseRoleId';
    //     break;
    //   case 'dataSource':
    //     filterName = 'dataSourceId';
    //     break;
    //   case "alertID": break;
    //   default:
    //     filterName = filterByDatabaseLogs;
    //     break;
    // }
    // if (filterName && (arr && arr.length > 0)) {
    //   queryDataWithFilters.filters.push({
    //     member: `${cubeName}.${filterName}`,
    //     operator: 'equals',
    //     values: arr
    //   });
    // }

    if (alertId) {
      let modifiedQuery = JSON.stringify(queryDataWithFilters).replaceAll(
        cubeName,
        "DatabaseLogsWithAlert"
      );
      queryDataWithFilters = JSON.parse(modifiedQuery);
    }
    setQueryData(queryDataWithFilters);
  };

  const clearFilterData = () => {
    settingDataFromAlerts();
    dispatch({ type: "FULL_RESET_DB_LOGS" });
    dispatch({ type: "GET_DATABASE_LOGS_INFO_DATA" });
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch({ type: "SET_RECORDS_PAGE", payload: value });
    dispatch({ type: "SET_CURRENT_PAGE", payload: 0 });
    dispatch({ type: "GET_DATABASE_LOGS_INFO_DATA" });
  };

  const handleChangePage = (page) => {
    dispatch({ type: "SET_CURRENT_PAGE", payload: page });
    dispatch({ type: "GET_DATABASE_LOGS_INFO_DATA" });
  };
  
  /**
   * Get count either approx 5000+ or actual count
   * @returns current best count
   */
  const getCount = () => {
    let count = 0; // default count
    if (totalCount !== null && totalCount !== undefined) {
      // if total count from actual count API call assign it on priority
      count = totalCount;
    } else if (approxCount !== null && approxCount !== undefined) {
      // if total count is not available yet but API for table data has approx count
      count = approxCount;
    }
    return count;
  };

  const renderTable = () => {
    
    return (
      <EnhancedGridTable
        cols={tableConstants()}
        data={allDatabaseLogs}
        loc={"database-logs"}
        totalCount={getCount()}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
      />
    );
  };

  const renderTableToolbar = () => {
    return (
      <EnhancedTableToolbar
        totalElements={getCount()}
        rows={allDatabaseLogs}
        loc={"database-logs"}
        alertsTitle={"Data Source Accesses"}
        timeStamps={configuration_from_alerts}
        helpDescription={helpDescription}
        clearFilterData={clearFilterData}
      />
    );
  };

  const renderLoader = () => {
    return (
      loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )
    );
  };

  const renderChart = () => {
    return (
      <div className="chart-container alerts">
        {chartTypes.line(
          cubejsApi,
          "Data Access Logs Over Time",
          queryData,
          null,
          { x: "Time", y: "Number of queries" },
          "MM-dd-YYYY h:mm:ss"
        )}
      </div>
    );
  };


  return (
    <React.Fragment>
      <div className="main">
        {/* {renderHeaderInfo()} */}
        {renderChart()}
        <div className="alerts-table-container">
          {renderLoader()}
          {renderTableToolbar()}
          {renderTable()}
        </div>
      </div>
    </React.Fragment>
  );
}
