export const cssVariables = {
  colors: {
    $palegrey: "#dfe1e9",
    $white: "#ffffff",
    "$pale-grey-0": "rgba(241, 242, 246, 0)",
    $black: "#292929",
    $lightRoyalBlue: "#2947fd",
    $royal: "#081981",
    $tangerine: "#ff8421",
    "$reddish-pink": "#ff2251",
    $lightGrey: "#706F7E",
    $lightGray: "rgba(223,225,233,0.2)",
  },
  fontFamily: "Nu",
};
