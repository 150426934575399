import { FILTER_ALERTS_URL } from '../constants/constants';
//import axios from 'axios';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function getAlertPolicyAlerts(id, page, pageSize) {
    const pageCount = page || 0
    const pageSizeCount = pageSize || 20
    let paramData = {};
    if (id !== '') {
        paramData.policy = id
    }
    const options = {
        method: "GET",
        url: `${FILTER_ALERTS_URL}?size=${pageSizeCount}&page=${pageCount}&status=UNRESOLVED`,
        params: paramData,
        headers: getAxiosHeaders(true)
    };

    const response = await getService(options);
    if (response) {
        return response.data
    }

    // axios.get(`${GET_ALERT_POLICIES_URL}?size=20`).then(res => {

    //     if (res && res.data && res.status === 200) {
    //         console.log(res.data)
    //         return res.data.content
    //     }
    // })
};

