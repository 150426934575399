import {
  UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA,
  UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA,
  APPLY_ENDPOINTS_FILTER,
} from "../constants/constants";
import { MDREndpointsQuery } from "../../components/common/Dashboard/dashboard-data";
import {
  clearMDRFilters,
  MDRupdatedFilterData,
} from "../../components/common/Dashboard/utils";
import {
  MDRTableSearchHelper,
  MDRApplyFilters,
  MDRMultipleFilters_Merge_Helper,
} from "../../components/common/Dashboard/MDRHelpers";

const initialState = {
  endPointsTableQuery: [...MDREndpointsQuery],
  endPointsTableData: [],
  Environment: [],
  OsDescription: [],
  totalRowsEndpoints: 0,
  currentStatus: "",
  clearAll: false,
};

export const MDREndPointsFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_ENDPOINTS_TABLE_DATA": {
      return {
        ...state,
        endPointsTableData: action.payload.data,
        totalRowsEndpoints: action.payload.totalTableDataCount,
        clearAll: false,
      };
    }
    case "UPDATE_ENDPOINTS_TABLE_AS_PER_DATERANGE": {
      return {
        ...state,
        currentStatus: action.payload.dateRangeChanged
          ? "dateRangeChanged"
          : "dateRangeNotChanged",
      };
    }
    case "CHANGE_MDRENDPOINTS_TABLE_QUERY_AS_PER_PAGESELECTED": {
      let newInstance = [...state.endPointsTableQuery];
      let newObj = { ...newInstance[0] };
      let newAquery = [...newObj.query];
      let query1 = { ...newAquery[0] };
      query1.offset = action.payload * action.rowsPerPage;
      query1.limit = action.rowsPerPage;
      newAquery[0] = query1;
      newObj.query = newAquery;
      newInstance[0] = newObj;

      return {
        ...state,
        endPointsTableQuery: [...newInstance],
        currentStatus: "pageChange",
      };
    }

    case UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRupdatedFilterData(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          Environment: [...updatedData],
        };
      }

      return {
        ...state,
        Environment: [...action.payload.data],
      };
    }
    case UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA: {
      if (action.payload.addIsSelected) {
        let updatedData = MDRupdatedFilterData(
          action.payload.filterType,
          action.payload.data,
          action.payload.dimensions
        );
        return {
          ...state,
          OsDescription: [...updatedData],
        };
      }

      return {
        ...state,
        OsDescription: [...action.payload.data],
      };
    }
    case APPLY_ENDPOINTS_FILTER: {
      const filters = MDRMultipleFilters_Merge_Helper(action.payload);
      const newTableInstance = MDRApplyFilters(
        state.endPointsTableQuery,
        filters
      );
      return {
        ...state,
        endPointsTableQuery: [...newTableInstance],
        currentStatus: "apply",
      };
    }

    case "ENDPOINTS_TABLE_FILTER_CLEARALL": {
      const data = [...MDREndpointsQuery];

      const endPointsFiltersClearAll = clearMDRFilters(
        state.Environment,
        state.OsDescription
      );
      return {
        ...state,
        Environment: endPointsFiltersClearAll[0],
        OsDescription: endPointsFiltersClearAll[1],
        endPointsTableQuery: [...data],
        clearAll: true,
      };
    }
    case "UPDATE_ENDPOINTS_TABLE_AS_PER_SEARCHVALUE": {
      let newTableInstance = MDRTableSearchHelper(
        state.endPointsTableQuery,
        action
      );
      return {
        ...state,
        endPointsTableQuery: [...newTableInstance],
        currentStatus: "searchValue",
      };
    }
    case "MDR_RESET_TABLEROWS_DATA_AS_SEARCH_FIELD_CHANGED": {
      return {
        ...state,
        endPointsTableData: [],
        totalRowsEndpoints: 0,
      };
    }
    case "UPDATE_ENDPOINTS_TABLE_AS_SEARCHVALUE_CLEARED": {
      let newTableInstance = MDRTableSearchHelper(
        state.endPointsTableQuery,
        action,
        true
      );
      return {
        ...state,
        endPointsTableQuery: [...newTableInstance],
        currentStatus: "searchValue",
      };
    }
    case "CLEAR_CURRENT_STATUS": {
      return {
        ...state,
        currentStatus: ""
      };
    }
    default:
      return state;
  }
};