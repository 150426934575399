import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "../views/DatabaseLogs/customCalender.scss";
import LevelComponent from "../shared/levelComponent";
import checkedIcon from "../../assets/icons/checkedIcon.svg"
import { renderSensitivityValue } from "../common/utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "390px",
    height: "402px",
    overflowY: "scroll",
    borderLeft: "2px solid grey",
    padding: "10px",
  },
  level: {
    borderTop: "solid 2px #979797",
    marginTop: "15px",
    width: "84%",
  },
  resolve: {
    borderBottom: "solid 2px #979797",
    borderTop: "solid 2px #979797",
    marginTop: "15px",
    width: "90%",
  },
  ul: {
    listStyleType: "none",
  },
  li: {
    paddingBottom: "8px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.14",
    letterSpacing: "normal",
    color: "black",
  },
  FilterHead: {
    fontSize: "12px",
    lineHeight: 1.22,
    color: "black",
  },
}));
const useStyles_filter = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  formControlContainerSort: {
    width: "290px",
    background: "#DFE1E9",
    margin: "5px",
    borderRadius: "3px",
    paddingTop: "12px",
    paddingLeft: "10px",
  },
  formControlContainerFilter: {
    width: "250px",
    margin: "5px",
    borderRadius: "3px",
    paddingTop: "12px",
    paddingLeft: "10px",
    borderLeft: "2px solid grey",
    borderRight: "2px solid grey",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#2947FD",
    border: "1px solid white",
    "input:hover ~ &": {
      backgroundColor: "#2947FD",
    },
  },
  databaseUsers: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  margin: {
    marginTop: "1rem",
  },
});
const useStyles_checkbox = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

export default function InspectFilterContent(props) {
  const dispatch = useDispatch();
  const {
    handleCheckbox,
    severitiesArray,
    severityFilter,
    categoryOptions,
    categories,
  } = props;
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const classes_check = useStyles_checkbox();



  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="filter">
      <FormLabel style={{ fontSize: "12px", paddingLeft: "10px" }}>
        {"Filter By"}
      </FormLabel>

      <div
        style={{
          width: "280px",
          height: "184px",
          overflow: "auto",
          wordBreak: "break-all",
        }}
      >
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "sensitivityFilter"}
          onChange={handleChange("sensitivityFilter")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Sensitivity Level
            </Typography>
            <Typography className={classes.heading}>
              {severityFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup aria-label="position" row>
              <>
                {severitiesArray &&
                  severitiesArray.map((each, index) => {
                    return (
                      <FormControlLabel
                        value={each.code}
                        name={each.code}
                        id={index}
                        control={
                          <Checkbox
                            className={classes_check.root}
                            checked={severityFilter.includes(each.code)}
                            onChange={(event) => (handleCheckbox(event, "severity"))}
                            color="default"
                            checkedIcon={
                              <img src={checkedIcon} alt="checked"/>
                            }
                            icon={<span className="checkbox-icon" />}
                            inputProps={{ "aria-label": "decorative checkbox" }}
                          />
                        }
                        label={
                          <LevelComponent level={renderSensitivityValue(each.code)}></LevelComponent>
                        }
                        labelPlacement="end"
                      />
                    );
                  })}
              </>
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "categories"}
          onChange={handleChange("categories")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Category
            </Typography>
            <Typography className={classes.heading}>
              {categories.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "min-content",
                minWidth: "min-content",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1rem",
                overflow: "auto",
              }}
            >
              <FormGroup aria-label="position" column>
                <>
                  {categoryOptions &&
                    categoryOptions.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.code}
                          name={each.code}
                          id={index}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={categories.includes(each.code)}
                              onChange={(event) => (handleCheckbox(event, "categories"))}
                              color="default"
                              checkedIcon={
                                <img src={checkedIcon} alt="checked"/>
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={
                            <p className={classes.label}>{each.code}</p>
                          }
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}