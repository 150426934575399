import TextField from "@material-ui/core/TextField";
import { isEmpty, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { SET_CLOUD_SOURCE_FIELDS_DATA } from "../../../redux/constants/constants";

function DropboxDataSourceForm({ removeErrorByKey, isEditMode, error }) {
  const cloudSourceData = useSelector(
    (state) => state.CreateSourceReducer.cloudSourceData
  );
  const { domainName, groupName } = cloudSourceData;
  const dispatch = useDispatch();
  return (
    <div>
      <div style={{ padding: "10px 0px" }}>
        <div>Account Name{<span style={{ color: "red" }}>*</span>}</div>
        <TextField
          required
          value={domainName}
          onChange={(event) => {
            dispatch({
              type: SET_CLOUD_SOURCE_FIELDS_DATA,
              payload: { ...cloudSourceData, domainName: event.target.value },
            });
            removeErrorByKey("accountName");
          }}
          id="outlined-required"
          variant="outlined"
          fullWidth={true}
          placeholder="Account Name"
          disabled={isEditMode}
        />
        {error && error.accountName ? (
          <div style={{ color: "red" }}>{error.accountName}</div>
        ) : null}
      </div>
      <div style={{ padding: "10px 0px" }}>
        <div>Group Name</div>
        <TextField
          value={groupName}
          onChange={(event) => {
            dispatch({
              type: SET_CLOUD_SOURCE_FIELDS_DATA,
              payload: { ...cloudSourceData, groupName: event.target.value },
            });
          }}
          id="outlined-required"
          variant="outlined"
          fullWidth={true}
          placeholder="Group Name"
          disabled={isEditMode}
        />
      </div>
    </div>
  );
}

export default DropboxDataSourceForm;
