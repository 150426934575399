import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SortIcon from "../../../assets/vizr_images/ic_sort-1.svg";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import FilterComponent from "./filterComponent";
import ServiceLevelFilterComponent from "./serviceLogsFilterComponent"
import SortComponent from "../../shared/sortComponent";
import ClearFilter from "../../shared/clearFilter";
import Popper from "@material-ui/core/Popper";
import LogViewFilter from "./LogsFilter";
import LogsViewFilter_ServiceAccessLogs from "./ServiceLogs_LogFilter"
import ImageTooltip from "../../common/HelpIconWithTooltip";
import "../../shared/Table/table.scss";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    totalElements,
    loc,
    alertsTitle,
    helpDescription,
    sortBy,
    timeStamps
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [placement, setPlacement] = useState();
  const dispatch = useDispatch();

  const handleClick = (event, newPlacement) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  useEffect(() => {
    setLoader(false);
  }, [totalElements]);

  const handleDataAccessLogs = () => {
    setLoader(true)
    if (loc === "database-logs") {
      dispatch({ type: "GET_DATABASE_LOGS_COUNT" });
    } else {
      dispatch({ type: "GET_SERVICE_LOGS_COUNT" });
    }
  };

  //To prevent the anchor tag from reload and redirecting the current page
  const handlePageReload = (event) => {
    event.preventDefault()
  }

  const renderHeaderTitle = () => {
    return (
      <Grid style={{ display: "flex" }} item xs={6}>
        <Typography
          className="title"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {alertsTitle}
        </Typography>
        <p style={{ margin: "11px 5px 0px 0px", minWidth: "45px" }}>Total:</p>
        {(loc === "database-logs" || loc === "service-database-logs") && totalElements === 5000 ?
          <>
            {!loader ?
              <div className="count">
                <a className="total-count" href={(event) => handlePageReload(event)} onClick={() => { handleDataAccessLogs() }}>
                  {totalElements}+
                </a>
              </div>
              : <div className="loading logs-count"></div>}
          </>
          :
          <>
            <div className="count">{totalElements}</div>
          </>
        }
        {!loader && <ImageTooltip description={helpDescription} />}
      </Grid>
    );
  };

  const renderButtons = () => {
    return (
      <Grid item xs={6} style={{ float: "right", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            color: "#081981",
            backgroundColor: "transparent",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
          }}
          onClick={(event) => handleClick(event, "bottom")}
          startIcon={<img src={SortIcon} alt="sort-filter" />}
          className="min-margin table-buttons"
        >
          Sort & Filter
        </Button>

        {renderPopover()}
      </Grid>
    );
  };

  const clearFilterButtonClick = () => {
    if(loc === "service-database-logs")
    {
      dispatch({ type: "SERVICE_ACCESS_LOGS_CLEAR_FILTER_DATA" });
      props.clearFilterData();
      setOpen(false)
    }
    else
    {
      dispatch({ type: "DATABASE_ACCESS_LOGS_CLEAR_FILTER_DATA" });
      props.clearFilterData();
      setOpen(false)

    }
    

  };
  const renderPopover = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{
          boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
          marginTop: "23rem",
          marginRight: "1rem",
          zIndex: "10",
      
        }}
      >
        {({ TransitionProps }) => (
          <>
            <div className="clear-filter-container">
              <ClearFilter
                className="clear-filter"
                clearFilterButtonClick={clearFilterButtonClick}
                toggle={(event, type) => handleClick(event, type)}
                loc={loc}
              />
            </div>
            <div className="popper-root" style={{ height: "742px" }}>
              <Fade {...TransitionProps} timeout={350}>
                <SortComponent
                  sortBy={sortBy}
                  loc={loc}
                />
              </Fade>
              <Fade {...TransitionProps} timeout={350}>
                <div style={{ width: "370px", height: "705px" }}>
                   {
                     loc === "service-database-logs" ? 
                     <ServiceLevelFilterComponent  timeStamps={timeStamps}></ServiceLevelFilterComponent> 
                     :<FilterComponent
                     timeStamps={timeStamps}
                     loc = {loc}
                   />
                   }
                
                </div>
              </Fade>
              <div
                style={{
                  minheight: "350px",

                  width: "400px",
                  display: "flex",
                  flexDirection: "column",
                  // marginTop: "2rem",
                  paddingLeft: "1.5rem",
                  overflow: "hidden auto",
                }}
              >

{
                     loc === "service-database-logs" ? 
                     <LogsViewFilter_ServiceAccessLogs></LogsViewFilter_ServiceAccessLogs> 
                     :  <LogViewFilter
                     />
                   }
              
              </div>
              {/* </Fade> */}
            </div>
          </>
        )}
      </Popper>
    );
  };

  const renderGrid = () => {
    return (
      <Grid container spacing={3}>
        {renderHeaderTitle()}
        {loc  !== "service-logs-drawer" ? renderButtons() : null}
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          {renderGrid()}
        </Grid>
      </Toolbar>
    </Paper>
  );
};

EnhancedTableToolbar.propTypes = {
  totalElements: PropTypes.number.isRequired,
};
