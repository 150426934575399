import { SAVE_CONNECTION_URL } from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function saveAliasName(name,appId,DSId) {
    let aliasNameData = {
        aliasName:`${name}`
    }
    const options = {
        method: "PUT",
        url: `${SAVE_CONNECTION_URL}/${appId}/dataSources/${DSId}/aliasName`,
        headers: getAxiosHeaders(true),
        data: aliasNameData
    };

    const response = await getService(options);
    if(response) {
        return response;
    }
};
