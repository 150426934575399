import { SELECTED_DATE_TIME_RANGE } from "../constants/constants";

const initialState = {
  dateTimeRange: '',
};

export const selectedDateTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_DATE_TIME_RANGE:
      return {
        ...state,
        dateTimeRange: action.payload,
      };
    default:
      return state;
  }
}
