import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Asc from "../../../../assets/icons/Asc.svg";
import Dsc from "../../../../assets/icons/Dsc.svg";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  formControlContainerSort: {
    width: "290px",
    background: "#DFE1E9",
    margin: "5px",
    borderRadius: "3px",
    paddingTop: "12px",
    paddingLeft: "10px",
  },
  formControlContainerFilter: {
    width: "250px",
    margin: "5px",
    borderRadius: "3px",
    paddingTop: "12px",
    paddingLeft: "10px",
    borderLeft: "2px  grey",
    borderRight: "2px  grey",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#2947FD",
    border: "1px solid white",
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    // '&:before': {
    //   display: 'block',
    //   width: 15,
    //   height: 16,
    //   content: '""',
    // },
    "input:hover ~ &": {
      backgroundColor: "#2947FD",
    },
  },
  databaseUsers: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  margin: {
    marginTop: "1rem",
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const { sortBy, loc } = props;
  const classes = useStyles();
  return (
    <Radio
      className={
        loc === "database_Users" ? classes.root : classes.databaseUsers
      }
      disableRipple
      color="default"
      checked={sortBy === props.value}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const MdrSortHook = ({
  query,
  action,
  filterType,
  filterData,
  className,
  loc,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const selectClickHandler = (index) => {
    let data = [...filterData];
    data[index].isSelected = data[index].isSelected ? false : true;
    dispatch(
      action({
        filterType: filterType,
        data: data,
        addIsSelected: false,
        dimensions: query.dimensions[0],
      })
    );
  };

  const incidentSortByValue = useSelector(
    (state) => state.IncidenetTableFilterReducer.incidentSortByValue
  );
  const incidentOrderByValue = useSelector(
    (state) => state.IncidenetTableFilterReducer.incidentOrderByValue
  );

  return (
    <>
      <RadioGroup
        onChange={(event) => {
          dispatch({
            type: "MDR_INCIDENT_TABLE_SORT_BY_VALUE",
            payload: { sortByValue: event.target.value, orderByValue: "ASC" },
          });
          // dispatch({
          //   type: "MDR_INCIDENT_TABLE_ORDER_BY_VALUE",
          //   payload: "ASC",
          // });
        }}
        // defaultValue="Created"
        aria-label="sortBy"
        name="customized-radios"
      >
        <FormControlLabel
          className={classes.margin}
          value="incidentTime"
          control={<StyledRadio sortBy={incidentSortByValue} loc={loc} />}
          label="Created"
        />
        <FormControlLabel
          className={classes.margin}
          value="status"
          control={<StyledRadio sortBy={incidentSortByValue} loc={loc} />}
          label="State"
        />
        <FormControlLabel
          className={classes.margin}
          value="stateDetails"
          control={<StyledRadio sortBy={incidentSortByValue} loc={loc} />}
          label="State Details"
        />
        <FormControlLabel
          className={classes.margin}
          value="incidentId"
          control={<StyledRadio sortBy={incidentSortByValue} loc={loc} />}
          label="Incident Id"
        />
           <FormControlLabel
          className={classes.margin}
          value="assignee"
          control={<StyledRadio sortBy={incidentSortByValue} loc={loc} />}
          label="Assignee"
        />
      </RadioGroup>
      <br />
      <div className="database-horizontal-line"></div>
      <RadioGroup
        onChange={(event) =>
          dispatch({
            type: "MDR_INCIDENT_TABLE_ORDER_BY_VALUE",
            payload: event.target.value,
          })
        }
        // defaultValue="username"
        aria-label="sortBy"
        name="customized-radios"
      >
        <div className="">
          <FormControlLabel
            className={classes.margin}
            value="ASC"
            control={<StyledRadio sortBy={incidentOrderByValue} loc={loc} />}
            label="Ascending"
          />
          <img
            alt="ASC"
            src={Asc}
            className="asc"
            style={{
              marginTop: "18px",
              marginLeft: "2px",
              cursor: "pointer",
              float: "right",
              marginRight: "17px",
            }}
            onClick={() => {
              dispatch({
                type: "MDR_INCIDENT_TABLE_ORDER_BY_VALUE",
                payload: "ASC",
              });
            }}
          ></img>
        </div>
        <div>
          <FormControlLabel
            className={classes.margin}
            value="DESC"
            control={<StyledRadio sortBy={incidentOrderByValue} loc={loc} />}
            label="Descending"
          />
          <img
            alt="DSC"
            src={Dsc}
            className="dsc"
            style={{
              marginTop: "18px",
              cursor: "pointer",
              float: "right",
              marginRight: "17px",
            }}
            onClick={() => {
              dispatch({
                type: "MDR_INCIDENT_TABLE_ORDER_BY_VALUE",
                payload: "DESC",
              });
            }}
          ></img>
        </div>
      </RadioGroup>
    </>
  );
};

export default MdrSortHook;
