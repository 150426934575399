import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import numeral from "numeral";
import { QueryRenderer } from "@cubejs-client/react";
import { Chart } from "react-google-charts";

import { renderNoData, renderSingleValue, dateFormatter } from "../utils";
import ChartWrapper from "../Chart";
import { VIEW_ENTERPRISE_ACTORS } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../utilities";

const returnRedirectTo = (name) => {
  let data = {
    "Total Users": {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },
  }
  return pathWithPermission(data[name]);
};

export function totalUsersCombo(cubejsApi, name, queries, history) {

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={queries[1]}
      render={(resultSet) => {
        let data = resultSet.loadResponse.results[0].data;
        let projectedData = [["Day", "Count"]];
        let granularity = `${queries[1].timeDimensions[0].dimension}.${queries[1].timeDimensions[0].granularity}`;
        data.forEach((d, index) => {
          if (d[granularity]) {
            let formattedDate = dateFormatter(d[granularity]);
            let totalCount =
              index === 0
                ? parseInt(d[queries[1].measures[0]])
                : projectedData[index][1] + parseInt(d[queries[1].measures[0]]);
            projectedData.push([formattedDate, totalCount]);
          }
        });

        return (
          <Grid container spacing={0}>
            {data && data.length > 0 ? (
              <React.Fragment>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    <QueryRenderer
                      query={queries[0]}
                      cubejsApi={cubejsApi}
                      render={({ resultSet }) => {
                        if (!resultSet) {
                          return <div className="loader" />;
                        }
                        return renderSingleValue(
                          resultSet,
                          queries[0].measures[0]
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Chart
                    width={"100%"}
                    height={"100px"}
                    chartType="AreaChart"
                    loader={<div>Loading Chart</div>}
                    data={projectedData}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {
                         if(name === "Total Users" ){
                            var handler = function (e) {
                                  history.push({
                                    ...returnRedirectTo(name)
                                  });
                            };
                            google.visualization.events.addListener(
                              chartWrapper.getChart(),
                              "select",
                              handler
                            );
                          }
                        },
                      },
                    ]}
                    options={{
                      vAxis: {
                        minValue: 0,
                        // gridlines: { color: 'transparent' },
                        baselineColor: "#fff",
                        gridlineColor: "#fff",
                        textPosition: "none",
                      },
                      haxis: {
                        title: "",
                        baselineColor: "#fff",
                        gridlineColor: "#fff",
                        textPosition: "none",
                      },
                      // For the legend to fit, we make the chart area smaller
                      chartArea: { width: "90%", height: "100%" },
                      // lineWidth: 25,
                    }}
                    // For tests
                    rootProps={{ "data-testid": "1" }}
                  />
                </Grid>
              </React.Fragment>
            ) : (
              renderNoData()
            )}
          </Grid>
        );
      }}
      redirectTo={returnRedirectTo(name)}
    />
  );
}
