import { UPDATE_POLICY_SUCCEEDED,CLOSE_SNACKBAR,UPDATE_POLICY_FAILED } from '../constants/constants';

const initialState = {
    updated : false,
    successMessage: '',
    unsuccessful : ''
  };

  export const updateAlertPolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_POLICY_SUCCEEDED: {
            console.log("UPDATE_POLICY_SUCCEEDED");
            return {
              ...state,
              successMessage: 'Policy Updated',
              updated: true,
            };
          }
          case UPDATE_POLICY_FAILED: {
            console.log("UPDATE_POLICY_FAILED");
            return {
              ...state,
              unsuccessful: "Update Failed",
              updated: false,
            };
          }
          case CLOSE_SNACKBAR: {
            console.log("CLOSE_SNACKBAR");
            return {
              ...state,
              successMessage: '',
              updated: false,
            };
          }
          default:
            return state;
    }
  };
