import { DELETE_POLICY_DETAIL_URL,DELETE_POLICY_REQUEST } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

// export async function deleteAlertPolicy(ids) {
//     let options
//     var urlData = '';

//     ids.map((each) => {
//         urlData = urlData + `${each},`
//     })
//     urlData = urlData.slice(0, -1)
//     options = {
//         method: "DELETE",
//         url: `${DELETE_POLICY_DETAIL_URL}?alertPolicyIds=${urlData}`,
//         headers: getAxiosHeaders(true)
//     };
//     const response = await getService(options);
//     if(response) {
//         window.location.reload(false);
//         return response.data
//     }
// };

export const deleteAlertPolicy = (payload) => {
    return {
      type: DELETE_POLICY_REQUEST,
      payload: payload,
    };
  };
