import React, {useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import LevelComponent from './levelComponent';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export default function FilterComponent(props) {
    const { handleCheckbox, severitiesArray, resolutionTypes, severityFilter, resolutionFilter, loc , selectedDatabaseFilter,alertIdCheck} = props;
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '290px',
            borderLeft: '2px solid grey',
            padding: '10px'
        },
        level: {
            display: 'inline-grid',
            marginTop: '15px',
            width: '84%'
        },
        resolve: {
            marginTop: '15px',
            width: '90%'
        },
        ul: {
            listStyleType: 'none'
        },
        li: {
            paddingBottom: '8px'
        },
        label:{
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.14',
            letterSpacing: 'normal',
            color: 'black'
        },
        FilterHead:{
            fontSize: '12px',
            lineHeight: 1.22,
            color: 'black',
            textAlign:'left'
        }
    }));
    const useStyles_checkbox = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: 3,
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
    });
    const useStyles_filter = makeStyles({
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        formControlContainerSort: {
          width: "290px",
          background: "#DFE1E9",
          margin: "5px",
          borderRadius: "3px",
          paddingTop: "12px",
          paddingLeft: "10px",
        },
        formControlContainerFilter: {
          width: "250px",
          margin: "5px",
          borderRadius: "3px",
          paddingTop: "12px",
          paddingLeft: "10px",
          borderLeft: "2px solid grey",
          borderRight: "2px solid grey",
        },
        icon: {
          borderRadius: "50%",
          width: 16,
          height: 16,
          boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
          backgroundColor: "#f5f8fa",
          "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
          },
          "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
          },
        },
        checkedIcon: {
          backgroundColor: "#2947FD",
          border: "1px solid white",
          // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
          // '&:before': {
          //   display: 'block',
          //   width: 15,
          //   height: 16,
          //   content: '""',
          // },
          "input:hover ~ &": {
            backgroundColor: "#2947FD",
          },
        },
        databaseUsers: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        margin: {
          marginTop: "1rem",
        },
      });
    const classes = useStyles();
    const classes_check = useStyles_checkbox();
    function StyledRadio(props) {
        const { sortBy, loc } = props;
        const classes = useStyles_filter()
        return (
          <Radio
            className={
              loc === "database_Users" ? classes.root : classes.databaseUsers
            }
            disableRipple
            color="default"
            checked={sortBy === props.value}
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
          />
        );
      }
    return (
        <FormControl component="fieldset" className={classes.root}>
            {
                loc === "database-logs"?
                <>
                <FormLabel className={classes.FilterHead}>Filter By </FormLabel>
             
             {severitiesArray && severitiesArray.map((each,index) => {
                 return(   
                    <RadioGroup
                    defaultValue="severity"
                    aria-label="sortBy"
                    name="customized-radios"
                    onChange={(event) => selectedDatabaseFilter(event)}
                      // onChange={(event) => dispatch()}

                  >
                    
                    <FormControlLabel
                      value={each.code}
                      control={<StyledRadio loc={loc} /> }
                      label={each.code}
                       sortBy={each.code}
                    />
                   
                  </RadioGroup>)

             })}
        
            </>:
             <>  <FormLabel className={classes.FilterHead}>Filter By Severity Level</FormLabel>
                <FormGroup aria-label="position" row>
                    <div className={classes.level}>
                        {severitiesArray && severitiesArray.map((each,index) => {
                            return(
                                <FormControlLabel
                                    id={index}
                                    value={each.code}
                                    control={
                                        <Checkbox className={classes_check.root}
                                            checked={severityFilter.includes(each.code)}
                                            onChange={(event) => (handleCheckbox(event, "severity"))}
                                            color="default"
                                            checkedIcon={
                                                severityFilter.includes(each.code) ? (
                                                  <span className="checked-icon" />
                                                ) : (
                                                  <span className="checkbox-icon" />
                                                )
                                              }
                                            icon={<span className="checkbox-icon" />}
                                            inputProps={{ 'aria-label': 'decorative checkbox' }}
                                            id={'high'}
                                        />}
                                    label={<LevelComponent level={each.code} />}
                                    labelPlacement="end"
                                />
                            )
                        })}
                    </div>
                    </FormGroup>
                    <br />
                   {loc === 'resolved-alerts' && <FormLabel className={classes.FilterHead}>Filter By Resolution Status</FormLabel>}
                    { loc === 'resolved-alerts' && <FormGroup>
                        <div className={classes.resolve}>
                        {resolutionTypes && resolutionTypes.map((each,index) => {
                            return(
                                <FormControlLabel
                                    value={each.code}
                                    control={
                                        <Checkbox className={classes_check.root}
                                            checked={resolutionFilter.includes(each.code)}
                                            onChange={(event) => (handleCheckbox(event, "resolutionType"))}
                                            color="default"
                                            checkedIcon={
                                                resolutionFilter.includes(each.code) ? (
                                                  <span className="checked-icon" />
                                                ) : (
                                                  <span className="checkbox-icon" />
                                                )
                                              }
                                            icon={<span className="checkbox-icon" />}
                                            inputProps={{ 'aria-label': 'decorative checkbox' }}
                                            id={index}
                                        />}
                                    label={<p className={classes.label}>{each.value} Resolved   </p>}
                                    labelPlacement="end"
                                />
                            )
                        })}
                        </div>
                    </FormGroup> }</>
            }
          
        </FormControl>
    );
}
