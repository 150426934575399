import { GET_POLICY_DETAIL, GET_POLICY_DETAIL_URL } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function getPolicyDetails(id) {
    const options = {
        method: "GET",
        url: `${GET_POLICY_DETAIL_URL}/${id}`,
        headers: getAxiosHeaders(true),
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};
