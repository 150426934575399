import React, { useState, useRef, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  GET_SINGLE_USER,
  ALL_ROLES,
  RESETPASSWORD_MAIL,
  SET_GENERATED_PASSWORD,
  SERVER_ERROR_MESSAGE
} from "../../../redux/constants/constants";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import "../../../assets/scss/TenantMangement.scss";
import "./userEditing.scss";
// import Box from "@material-ui/core/Box";
// import { flexbox } from "@material-ui/system";
import ManagementFooter from "../BackOfficeGeneralContainer/ManagementFooter";
import ImageEditing from "../../shared/ImageEditing";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from '@material-ui/core/Button';
import resetPasswordIcon from "../../../assets/vizr_images/Reset_Password-delete.svg";
import { SendLink } from "../../common/buttons";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { isEmpty } from "lodash";
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { errorMessageComp } from "../../common/utilities";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  imageContainer: {
    marginTop: "4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const initialState = {
  userName: "",
  firstName: "",
  lastName: "",
  roleIds: [],
  domain: "",
  features: "",
  licenses: "",
  phoneNumber: "",
  profilePicture: "",
  mfaEnabled: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "success":
      return {
        userName: action.payload.email ? action.payload.email : "",
        firstName: action.payload.firstName ? action.payload.firstName : "",
        lastName: action.payload.lastName ? action.payload.lastName : "",
        roleIds: action.payload.roles.map((role) => role.id),
        mfaEnabled: action.payload.mfaEnabled
          ? action.payload.mfaEnabled
          : false,
        domain: "",
        features: "",
        licenses: "",
        phoneNumber: action.payload.phone,
        profilePicture: action.payload.profilePicture,
      };
    case "change_Roles":
      return {
        ...state,
        roleIds: action.payload
      };
    case "change_Phonenumber":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "change_firstName":
      return {
        ...state,
        firstName: action.payload,
      };
    case "change_lastName":
      return {
        ...state,
        lastName: action.payload,
      };
    case "change_mfaEnabled": {
      return {
        ...state,
        mfaEnabled: !state.mfaEnabled,
      };
    }
    default:
      throw new Error();
  }
}

const UserEditing = ({ submitted }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [imgUrl, setImgurl] = useState("");
  const [openSuccess, setopenSuccess] = useState(false);
  const [openError, setopenError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState({ error: "" });
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [roleError, setRoleErrors] = useState("");
  const classes = useStyles();
  const handleClick = (status, message) => {
    if (status === "success") {
      setopenSuccess(true);
      setSuccessMessage(message);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSuccess(false);
    setopenError(false);
  };
  const location = useLocation();
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [file, setFile] = useState("");
  const [file2, setFile2] = useState("");
  const [resetPasswordMail, setresetPasswordMail] = useState("");
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [passwordToggler, setpasswordToggler] = useState(false);
  const [newPasswordToggler, setNewPasswordToggler] = useState(false);
  const [resetPassword, setresetPassword] = useState("");
  const inputFile = useRef(null);
  const firstName = useRef(null);
  const lastName = useRef(null);
  const phoneNumber = useRef(null);
  const [currentFocus, setcurrentFocus] = useState({ ref: "" });

  const [name, setName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const clickHandler = (event) => {
    // imageHandler(event);
    if (event.target.files[0]) {
      if (!event.target.files[0] === name) {
        imageHandler(event);
      }
    }
  };
  const imageHandler = (event) => {
    if (event.target.files[0]) {
      setName(event.target.files[0].name);
      if (event.target.files[0].size / 1000 <= 40) {
        setFile(URL.createObjectURL(event.target.files[0]));
        setFile2(event.target.files[0]);
      } else {
        setopenError(true);
        let obj = { error: "Please select image with size less than 40Kb" };
        setErrorMessage({
          ...obj,
        });
      }
    }
    event.target.value = "";
  };
  useEffect(() => {
    const path = location.pathname.split("/");
    const id = path[path.length - 1];
    const apiCall = async () => {
      const headers = getAxiosHeaders(true);
      const res = await getService({
        method: "GET",
        url: GET_SINGLE_USER(
          localStorage.getItem("tenant") ? localStorage.getItem("tenant") : "",
          id
        ),
        headers: headers,
      });
      if (res.status === 200) {
        setresetPasswordMail(res.data.email);
        setImgurl(res.data.qrCode.imageUri);
        dispatch({ type: "success", payload: res.data });
      }
    };
    apiCall();
  }, [location.pathname]);

  useEffect(() => {
    const apiCall = async () => {
      const headers = getAxiosHeaders(true);
      const res = await getService({
        method: "GET",
        url: ALL_ROLES(
          localStorage.getItem("tenant") ? localStorage.getItem("tenant") : "",
          100
        ),
        headers: headers,
      });
      if (res.status === 200) {
        setRoles([...res.data.content]);
      }
    };
    apiCall();
  }, []);

  //grabbing roleId when they select multiple
  const selectOnchangeHandler = (event, value) => {
    const selectedRoles = value.map((role) => role.id);
    dispatch({ type: "change_Roles", payload: selectedRoles });
    
    //To remove errors when input is selected
    setRoleErrors("");

  };

  const submitHandler = () => {
    setSuccessMessage("");
    const path = location.pathname.split("/");
    const id = path[path.length - 1];
    const formData = new FormData();
    if (isEmpty(state.roleIds)) {
      setRoleErrors("Roles should not be empty");
    } else {
      setRoleErrors("");
      const obj = {
        firstName: state.firstName,
        lastName: state.lastName,
        phone: state.phoneNumber,
        mfaEnabled: state.mfaEnabled,
        roleIds: state.roleIds,
      };
      let myObj = JSON.stringify(obj);
      formData.append("payload", myObj);
      formData.append("profilePicture", file2);
      if (state.mfaEnabled) {
        resetPassword_APIcall("mfaEnabled");
      }
      const apiCall = async () => {
        const res = await getService({
          method: "PUT",
          url: GET_SINGLE_USER(
            localStorage.getItem("tenant") ? localStorage.getItem("tenant") : "",
            id
          ),
          headers: getAxiosHeaders(true, "multipart/form-data"),
          data: formData,
        });
        let successMessage = state.mfaEnabled
          ? "User updated successfully and password reset mail is sent as MFA is enabled"
          : "User updated successfully";
        console.log(successMessage, "successMessage");
        if (res.status === 200) {
          handleClick("success", successMessage);
        } else {
          handleClick("failed");
        }
      };
      apiCall();
    }
  };

  const generator = require('secure-random-password');

  const generatePassword = () => {
    const Password = generator.randomString({ length: 12, })
    setGeneratedPassword(Password);
  }

  const generatedPasswordAPICall = async () => {
    const res = await getService({
      method: "PUT",
      url: SET_GENERATED_PASSWORD,
      headers: getAxiosHeaders(true),
      data: {
        email: state.userName,
        password: generatedPassword
      }
    });
    setSuccessMessage("");
    if (res.status === 200) {
      let successMessage = "Password reset successful";
      handleClick("success", successMessage);
    } else {
      handleClick("failed");
      let obj = { error: res.response.data.fieldErrors[0].errorMessage };
      const errMessage = errorMessageComp(obj.error, "userEditingScreen")
      setPasswordErrorMessage(errMessage);
    }
  }

  const resetPassword_APIcall = async (mfaEnabled) => {
    const res = await getService({
      method: "POST",
      url: RESETPASSWORD_MAIL(
        localStorage.getItem("tenant") ? localStorage.getItem("tenant") : ""
      ),
      headers: getAxiosHeaders(true),
      data: {
        email: resetPasswordMail,
      },
    });
    if (!mfaEnabled) {
      setSuccessMessage("");
      if (res.status === 200) {
        let successMessage = "Password reset mail sent successfully";
        handleClick("success", successMessage);
      } else {
        handleClick("failed");
        let obj = { error: res.response?.data?.errorMessage || SERVER_ERROR_MESSAGE};
        setErrorMessage({
          ...obj,
        });
        setopenError(true)
      }
    }
  };

  const resetPasswordHandler = () => {
    setNewPasswordToggler(false);
    setGeneratedPassword("");
    setShowPassword(false);
    setpasswordToggler((prevState) => !prevState);
  };

  const newPasswordGeneratorHandler = () => {
    setresetPasswordMail(state.userName)
    setpasswordToggler(false);
    setNewPasswordToggler((prevState) => !prevState);
  }

  const emailPasswordReset = () => {
    return (
      <>
        <Grid
          item
          xs={12}
          xl={12}
          className={passwordToggler ? "" : "hide"}
        >
          <div>Email</div>
          <input
            type="text"
            value={resetPasswordMail}
            onChange={(event) => {
              setresetPasswordMail(event.target.value);
            }}
            className="randomStyle"
          ></input>
          <br />
          <br />
          <SendLink
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => resetPasswordSubmit()}
            className="min-margin"
          >
            Send Link
          </SendLink>
        </Grid>
      </>
    )
  }

  const newPasswordReset = () => {
    return (
      <Grid item xs={12} xl={12} className={newPasswordToggler ? "" : "hide"}>
        <div>New Password</div>
        <FormControl variant="outlined">
          <OutlinedInput
            id="outlined-adornment-password"
            value={generatedPassword}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={(event) => {
              setGeneratedPassword(event.target.value.replace(/\s/g, ""));
              setPasswordErrorMessage("");
            }
            }
          />
        </FormControl>
        {!isEmpty(passwordErrorMessage) ? (
          <h4>
            {passwordErrorMessage}
          </h4>
        ) : null}
        <br />
        <br />
        <Button
          variant="contained"
          size="medium"
          onClick={() => generatePassword()}
          className="min-margin"
          color="primary"
        >
          Generate
        </Button>
        <Button
          variant="contained"
          size="medium"
          disabled={isEmpty(generatedPassword) ? true : false}
          className="min-margin generate-save"
          color="primary"
          onClick={() => generatedPasswordAPICall()}
        >
          Save
        </Button>
      </Grid>
    );
  }

  const handlePasswordOptionChange = () => {
    if (passwordToggler) {
      return emailPasswordReset()
    } else {
      return newPasswordReset()
    }
  }

  const cancelHandler = () => {
    if (
      localStorage.getItem("tenant") !== "druvstar" &&
      localStorage.getItem("tenant") !== "datavision"
    ) {
      if (window.location.pathname.includes("/mdr/userManagement/editUser")) {
        history.push(`/mdr/userManagement`);
      } else {
        history.push("/tenant/userManagement");
      }
    } else {
      history.push("/platform/userManagement");
    }
  };

  const resetPasswordSubmit = () => {
    resetPassword_APIcall();
  };
  //displaying multiple roles
  const getSelectedRoles = (roleIds, roles) => {
    return roleIds.map((id) => {
      const role = roles.find((role) => role.id === id);
      return role ? role : null;
    });
  };
  return (
    <>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage.error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <div className="userEditing_MainContainer">
        <Grid container className="userEditing_Container" direction="row">
          <Grid
            container
            item
            direction="row"
            xs={12}
            sm={12}
            md={5}
            xl={5}
            alignItems="center"
          >
            <Grid item xs={6} xl={6}>
              <div className="userEditing_ImageContainer">
                <ImageEditing
                  imageHandler={imageHandler}
                  inputFile={inputFile}
                  file={file}
                  state={state}
                  clickHandler={clickHandler}
                ></ImageEditing>
              </div>
            </Grid>
            <Grid item xs={12} xl={12}>
              <div className="editUser_resetPasswordFlex">
                <h2 style={{ marginRight: "4rem" }}>Reset Password</h2>
                <img
                  src={resetPasswordIcon}
                  alt="ResetPassword"
                  onClick={resetPasswordHandler}
                ></img>
                <Button
                  variant="contained"
                  size="medium"
                  className="generate-save"
                  onClick={newPasswordGeneratorHandler}
                  color="primary"
                >
                  Set Password
                </Button>
              </div>
            </Grid>
            {handlePasswordOptionChange()}
          </Grid>
          <></>
          <Grid container item xs={8} sm={8} md={6} xl={6} direction="row">
            <Grid
              item
              xs={12}
              xl={12}
              className="UserEditing_input"
              key="userName"
            >
              <div>Username</div>
              <input
                value={state.userName}
                type="text"
                className="InputTextFeild disabled"
                disabled
              ></input>
            </Grid>
            <Grid
              item
              xs={12}
              xl={12}
              className="UserEditing_input"
              key="firstName"
            >
              <div>Firstname</div>
              <input
                value={state.firstName}
                type="email"
                className="InputTextFeild"
                ref={firstName}
                onChange={(event) => {
                  dispatch({
                    type: "change_firstName",
                    payload: event.target.value,
                  });
                }}
              ></input>
            </Grid>
            <Grid
              item
              xs={12}
              xl={12}
              className="UserEditing_input"
              key="lastName"
            >
              <div>LastName</div>
              <input
                value={state.lastName}
                type="text"
                ref={lastName}
                className="InputTextFeild"
                onChange={(event) => {
                  setcurrentFocus({ ref: lastName });
                  dispatch({
                    type: "change_lastName",
                    payload: event.target.value,
                  });
                }}
              ></input>
            </Grid>
            <Grid item xs={12} xl={12} className="UserEditing_input" key="role">
              <div>Roles</div>
              <Autocomplete
                options={roles || []}
                getOptionLabel={(option) => option ? option.name : ""}
                value={getSelectedRoles(state.roleIds, roles)}
                multiple={true}
                onChange={(event, value) => selectOnchangeHandler(event, value)}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
              />
              {roleError ? (
                <div style={{ color: "red" }}>{roleError}</div>
              ) : null}

            </Grid>
            <Grid item xs={2} className="UserEditing_input">
              <div>Enable MFA</div>
              <Checkbox
                checked={state.mfaEnabled}
                color="primary"
                onChange={(event) => {
                  dispatch({
                    type: "change_mfaEnabled",
                    payload: event,
                  });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid item xs={12} xl={12} className="UserEditing_input">
              {/* <div>Domain</div>
              <input
                value={state.domain}
                type="text"
                className="InputTextFeild disabled"
                disabled
              ></input> */}
            </Grid>
            <Grid item xs={12} xl={12} className="UserEditing_input">
              {/* <div>Features</div>
              <input
                value=""
                type="text"
                className="InputTextFeild disabled"
                disabled
              ></input> */}
            </Grid>
            <Grid item xs={12} xl={12} className="UserEditing_input" disabled>
              {/* <div>Licenses</div>
              <Select
                value="No Value"
                className="InputSelectFeild disabled"
                disabled
              >
                <MenuItem value="">Hello</MenuItem>
              </Select> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ManagementFooter
        classname="editUser_footer"
        submitHandler={submitHandler}
        cancelHandler={cancelHandler}
      ></ManagementFooter>
    </>
  );
};

export default UserEditing;