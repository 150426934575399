import { loopingFunction } from "./utils";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

export const MDRTableSearchHelper = (tableQuery, action, removeSearch) => {
  let newTableInstance = [...tableQuery];
  let filtersArr = [];
  let filters = newTableInstance[0].query[0].filters;
  console.log(filters, "testing");
  //If we have filters already then we are writing logic to add new search filter
  if (filters && filters.length > 0 && !removeSearch) {
    let wehaveSearchFilter = false;
    filters.forEach((filterobj) => {
      let newfilterobj = { ...filterobj };

      console.log("newfilterobj", newfilterobj);
      if (
        newfilterobj.member === action.payload.member &&
        newfilterobj.operator === "contains" &&
        action.payload.values[0]
      ) {
        // newfilterobj.values = action.payload.values !== "";
        filtersArr.push(action.payload);
        wehaveSearchFilter = true;
      } else if (
        newfilterobj.member !== action.payload.member &&
        newfilterobj.operator === "contains" &&
        action.payload.values[0]
      ) {
        newfilterobj.member = action.payload.member;
        newfilterobj.values = action.payload.values;
        filtersArr.push(newfilterobj);
        wehaveSearchFilter = true;
      } else if (action.payload.values[0]) {
        filtersArr.push(newfilterobj);
      }
    });
    if (!wehaveSearchFilter && action.payload.values[0]) {
      filtersArr.push(action.payload);
    }
  } else if (removeSearch && filters && filters.length) {
    let newFilters = [...filters];
    // let serchFilterObj = {};
    newFilters.forEach((filterObj) => {
      if (filterObj.operator !== "contains") {
        filtersArr.push({ ...filterObj });
        // serchFilterObj = { ...filterObj };
      }
    });
  }
  //If we dont have filters already then we are writing logic to push  new search filter
  else if (action.payload.values[0]) {
    filtersArr.push(action.payload);
  }

  //This maping to direct table query which is helping to avoid bug when clear filter and have search filed value typed in
  let updatednewTableQueryInstance = newTableInstance[0].query.map(
    (queryObj, index) => {
      let newQueryObj = { ...queryObj };
      newQueryObj.filters = [...filtersArr];
      if (index === 0) {
        newQueryObj.offset = 0;
      }
      return newQueryObj;
    }
  );
  console.log("hello2", updatednewTableQueryInstance);
  newTableInstance[0].query = [...updatednewTableQueryInstance];
  return newTableInstance;
};

// export const MDRApplyFilters = (tableQuery, filters, type) => {
//   let newTableInstance = [...tableQuery];
//   let newObjInstance = { ...newTableInstance[0] };
//   let newQueryArr = [...newObjInstance.query];
//   let updatedQueryWithFilters = newQueryArr.map((queryObj) => {
//     let newQueryObj = { ...queryObj };
//     console.log(newQueryObj, filters, "testestyetsttt");
//     //This logic is when the  table data is  already filtered using search field
//     let newFilters = [...newQueryObj.filters];
//     if (newQueryObj && newQueryObj.filters && newQueryObj.filters.length > 0) {
//       let serchFilterObj = {};
//       newFilters.forEach((filterObj) => {
//         if (filterObj.operator === "contains") {
//           serchFilterObj = { ...filterObj };
//         }
//       });
//       if (serchFilterObj && serchFilterObj.operator)
//         newQueryObj.filters.push(serchFilterObj);
//     } else {
//       newQueryObj.filters = [...filters];
//     }
//     let globalFilters = JSON.parse(sessionStorage.getItem("globalFilters"));
//     //! Apply global filters for patch Monitoring
//     if (
//       globalFilters &&
//       globalFilters.length &&
//       type === "APPLY_PATCHMONITORING_FILTER"
//     ) {
//       newQueryObj.filters = [...newQueryObj.filters, ...globalFilters];
//     }
//     console.log(newQueryObj, "newQueryObjnewQueryObj");
//     return newQueryObj;
//   });
//   console.log(updatedQueryWithFilters, "updatedQueryWithFilters");
//   updatedQueryWithFilters[0].offset = 0;
//   newObjInstance.query = updatedQueryWithFilters;
//   console.log("newObjInstance", newObjInstance, updatedQueryWithFilters[0]);
//   newTableInstance[0] = newObjInstance;
//   return newTableInstance;
// };
export const MDRApplyFilters = (tableQuery, filters, type) => {
  let newTableInstance = [...tableQuery];
  let newObjInstance = { ...newTableInstance[0] };
  let newQueryArr = [...newObjInstance.query];
  let updatedQueryWithFilters = newQueryArr.map((queryObj) => {
    let newQueryObj = { ...queryObj };

    //This logic is when the  table data is  already filtered using search field
    if (newQueryObj && newQueryObj.filters && newQueryObj.filters.length > 0) {
      let newFilters = [...newQueryObj.filters];
      let serchFilterObj = {};
      newFilters.forEach((filterObj) => {
        if (filterObj.operator === "contains") {
          serchFilterObj = { ...filterObj };
        }
      });
      console.log("hello", filters);
      newQueryObj.filters = [...filters];
      if (serchFilterObj && serchFilterObj.operator)
        newQueryObj.filters.push(serchFilterObj);
    } else {
      newQueryObj.filters = filters;
    }

    let globalFilters = JSON.parse(sessionStorage.getItem("globalFiltersObj"));
    //! Apply global filters for patch Monitoring
    if (
      globalFilters &&
      globalFilters.length &&
      type === "APPLY_PATCHMONITORING_FILTER"
    ) {
      newQueryObj.filters = [...newQueryObj.filters, ...globalFilters];
    }

    return newQueryObj;
  });

  updatedQueryWithFilters[0].offset = 0;
  newObjInstance.query = updatedQueryWithFilters;
  // console.log("newObjInstance", newObjInstance, updatedQueryWithFilters[0]);
  newTableInstance[0] = newObjInstance;
  let newIns = cloneDeep(newTableInstance);
  return newIns;
};

export const MDRMultipleFilters_Merge_Helper = (AllFiltersArr) => {
  let filters = [];
  AllFiltersArr.forEach((FiltersObj) => {
    let filterQuery = {};
    const filterValuesArr = loopingFunction(FiltersObj.values);
    if (filterValuesArr.length) {
      filterQuery = FiltersObj.query;
      filterQuery.values = filterValuesArr;
      filters.push(filterQuery);
    }
  });
  console.log(filters, "testestest");
  return filters;
};

// loc === "MDRDashboard_IncidentTable" ||
// loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
// loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
// loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ||
// loc === "MDRDashboard_EndPointsTable" ||
// loc === "MDRDashboard_PatchMonitoring"
export const MDRTableCurrentStatusFinder = (
  loc,
  currentStatus,
  currentStatusMDRAuthenticationTable,
  currentStatusEndpointsTable,
  currentStatusPatchmonitoringTable
) => {
  if (loc === "MDRDashboard_IncidentTable") {
    return currentStatus;
  } else if (
    loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
    loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
    loc === "MDRDashboard_AuthenticationTable_UniqueIPs"
  ) {
    return currentStatusMDRAuthenticationTable;
  } else if (loc === "MDRDashboard_EndPointsTable") {
    return currentStatusEndpointsTable;
  } else if (loc === "MDRDashboard_PatchMonitoring") {
    return currentStatusPatchmonitoringTable;
  }
  return null;
};

export let masterArr = {
  "Subscription Name": "subscriptionName",
  Resource: "resource",
};

export const addGlobalFiltersForDashboard = (
  data,
  singleFilterValuesObj,
  masterArr
) => {
  let updatedData = data.map((instance) => {
    if (instance.vizState.queries) {
      instance.vizState.queries = instance.vizState.queries.map((query) => {
        let tableNameArr = query.measures
          ? query.measures[0]
          : query.dimensions
          ? query.dimensions[0]
          : "";
        let tableName = tableNameArr && tableNameArr.split(".")[0];
        let newAquery = { ...query };
        if (!newAquery.filters || newAquery.filters.length === 0) {
          let filterArr = [];
          singleFilterValuesObj.forEach((obj) => {
            if (obj["type"] === "include") {
              let bool = false;

              filterArr.forEach((el, index) => {
                if (el["member"] === `${tableName}.${obj["name"]}`) {
                  filterArr[index]["values"].push(`${obj["value"]}`);
                  bool = true;
                }
              });
              if (!bool) {
                filterArr.push({
                  member: `${tableName}.${obj["name"]}`,
                  operator: "equals",
                  values: [`${obj["value"]}`],
                });
              }
            }
            if (obj["type"] === "exclude") {
              let bool = false;

              filterArr.forEach((el, index) => {
                if (el["member"] === `${tableName}.${obj["name"]}`) {
                  filterArr[index]["values"].push(`${obj["value"]}`);
                  bool = true;
                }
              });
              if (!bool) {
                filterArr.push({
                  member: `${tableName}.${obj["name"]}`,
                  operator: "notEquals",
                  values: [`${obj["value"]}`],
                });
              }
            }
          });
          newAquery.filters = filterArr;
        } else {
          let filters = [...newAquery.filters];
          let filterArr = [];
          let newFilterArr = filters.filter((obj) => {
            if (
              obj["member"] === "MDRPatchManagement.updateState" ||
              obj["member"] === "MDRPatchManagement.incidentTime"
            ) {
              return true;
            }
            return false;
          });
          singleFilterValuesObj.forEach((obj) => {
            if (obj["type"] === "include") {
              let bool = false;

              filterArr.forEach((el, index) => {
                if (el["member"] === `${tableName}.${obj["name"]}`) {
                  if (!el["values"].includes(`${obj["value"]}`)) {
                    filterArr[index]["values"].push(`${obj["value"]}`);
                  }
                  // filterArr[index]["values"].push(`${obj["value"]}`);
                  bool = true;
                }
              });
              if (!bool) {
                filterArr.push({
                  member: `${tableName}.${obj["name"]}`,
                  operator: "equals",
                  values: [`${obj["value"]}`],
                });
              }
            } else if (obj["type"] === "exclude") {
              let bool = false;
              filterArr.forEach((el, index) => {
                console.log(el["values"], "testing");
                if (el["member"] === `${tableName}.${obj["name"]}`) {
                  if (!el["values"].includes(`${obj["value"]}`)) {
                    filterArr[index]["values"].push(`${obj["value"]}`);
                  }
                  bool = true;
                }
              });
              if (!bool) {
                filterArr.push({
                  member: `${tableName}.${obj["name"]}`,
                  operator: "notEquals",
                  values: [`${obj["value"]}`],
                });
              }
            }
          });
          console.log("newFilterArr", newFilterArr);
          newAquery.filters = [...filterArr, ...newFilterArr];
        }

        return newAquery;
      });
    } else if (instance.vizState.query) {
      let nameArr = instance.vizState.query
        ? instance.vizState.query.measures
        : "";
      let tableNameArr = nameArr ? nameArr[0] : "";
      let tableName = tableNameArr && tableNameArr.split(".")[0];
      let newAquery = { ...instance.vizState.query };

      if (!newAquery.filters || newAquery.filters.length === 0) {
        let filterArr = [];
        singleFilterValuesObj.forEach((obj) => {
          if (obj["type"] === "include") {
            let bool = false;

            filterArr.forEach((el, index) => {
              if (el["member"] === `${tableName}.${obj["name"]}`) {
                filterArr[index]["values"].push(`${obj["value"]}`);
                bool = true;
              }
            });
            if (!bool) {
              filterArr.push({
                member: `${tableName}.${obj["name"]}`,
                operator: "equals",
                values: [`${obj["value"]}`],
              });
            }
          }
          if (obj["type"] === "exclude") {
            let bool = false;

            filterArr.forEach((el, index) => {
              if (el["member"] === `${tableName}.${obj["name"]}`) {
                filterArr[index]["values"].push(`${obj["value"]}`);
                bool = true;
              }
            });
            if (!bool) {
              filterArr.push({
                member: `${tableName}.${obj["name"]}`,
                operator: "notEquals",
                values: [`${obj["value"]}`],
              });
            }
          }
        });
        newAquery.filters = [...filterArr];
      } else {
        let filters = [...newAquery.filters];
        let filterArr = [];
        let newFilterArr = filters.filter((obj) => {
          if (
            obj["member"] === "MDRPatchManagement.updateState" ||
            obj["member"] === "MDRPatchManagement.incidentTime"
          ) {
            return true;
          }
          return false;
        });
        singleFilterValuesObj.forEach((obj) => {
          if (obj["type"] === "include") {
            let bool = false;

            filterArr.forEach((el, index) => {
              console.log(el["values"], "testing");
              if (el["member"] === `${tableName}.${obj["name"]}`) {
                if (!el["values"].includes(`${obj["value"]}`)) {
                  filterArr[index]["values"].push(`${obj["value"]}`);
                }
                // filterArr[index]["values"].push(`${obj["value"]}`);
                bool = true;
              }
            });
            if (!bool) {
              filterArr.push({
                member: `${tableName}.${obj["name"]}`,
                operator: "equals",
                values: [`${obj["value"]}`],
              });
            }
          }
          if (obj["type"] === "exclude") {
            let bool = false;

            filterArr.forEach((el, index) => {
              console.log(el["values"], "testing");
              if (el["member"] === `${tableName}.${obj["name"]}`) {
                if (!el["values"].includes(`${obj["value"]}`)) {
                  filterArr[index]["values"].push(`${obj["value"]}`);
                }

                bool = true;
              }
            });
            if (!bool) {
              filterArr.push({
                member: `${tableName}.${obj["name"]}`,
                operator: "notEquals",
                values: [`${obj["value"]}`],
              });
            }
          }
        });

        newAquery.filters = [...filterArr, ...newFilterArr];

        console.log(instance);
      }

      instance.vizState.query = newAquery;
    }
    return instance;
  });
  return updatedData;
};

export const addingDateRangetoTableQueries = (tableQuerys, dateTimeRange) => {
  const d = new Date();
  let diff = d.getTimezoneOffset();
  let startDt = dateTimeRange && JSON.parse(dateTimeRange).startDate;
  let endDt = dateTimeRange && JSON.parse(dateTimeRange).endDate;
  // let startDate = new Date(startDt).getTime(); // dateFormatter(startDt, "MM-DD-YYYY HH:mm:ss");
  // let endDate = new Date(endDt).getTime(); // dateFormatter(endDt, "MM-DD-YYYY HH:mm:ss");
  let startDate = new Date(startDt).getTime() - diff * 60 * 1000; // dateFormatter(startDate, "MM-DD-YYYY HH:mm:ss");
  let endDate = new Date(endDt).getTime() - diff * 60 * 1000; // dateFormatter(endDate, "MM-DD-YYYY HH:mm:ss");
  // let filters = tableQuerys[0].query[0].filters;
  let tableNameArr = tableQuerys[0].query[0].dimensions
    ? tableQuerys[0].query[0].dimensions[0]
    : "";
  let tableName = tableNameArr && tableNameArr.split(".")[0];
  let UpdatedFilters = [];

  UpdatedFilters.push({
    member: `${tableName}.incidentTime`,
    operator: "gte",
    values: [`${startDate}`],
  });
  UpdatedFilters.push({
    member: `${tableName}.incidentTime`,
    operator: "lte",
    values: [`${endDate}`],
  });

  return UpdatedFilters;
};
