import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

export const AddButtonForAlertTable = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "6px",
  },
}))(Button);

export const AddedButtonForAlertTable = withStyles((theme) => ({
  root: {
    color: "#2947fd",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: "#ffffff",
    borderRadius: "6px",
  },
}))(Button);

export const ColorButtonForToolBar = withStyles((theme) => ({
  root: {
    color: "#081981",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
  },
}))(Button);

export const DeleteButtonForToolBar = withStyles((theme) => ({
  root: {
    color: "#081981",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    minWidth: "41px !important",
  },
}))(Button);

export const NewPolicyButtonForToolBar = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "6px",
  },
}))(Button);

export const AddMDRConfig = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: "#8014BD",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#7600a9",
    },
  },
}))(Button);

export const ColorButtonForAdminNavbar = withStyles((theme) => ({
  root: {
    color: "#081981",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontFamily: "$font-family-base",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    marginLeft: "5px",
    lineHeight: "16px",
    textAlign: "center",
  },
  disabled: {
    backgroundColor: "#ffffff !important",
  },
}))(Button);

export const ColorButtonForClearFilter = withStyles((theme) => ({
  root: {
    color: "#081981",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    marginLeft: "5px",
  },
}))(Button);

export const CloseButton = withStyles((theme) => ({
  root: {
    color: "#081981",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    marginLeft: "5px",
    minWidth: "40px",
  },
}))(Button);

export const ColoredActionButton = withStyles((theme) => ({
  root: {
    width: "162px",
    height: "47px",
    color: "#081981",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
  },
}))(Button);

export const EditButtonForPolicyCreateEdit = withStyles((theme) => ({
  root: {
    height: "32px",
    color: "#081981",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
  },
}))(Button);

export const DeleteButtonForMDR = withStyles((theme) => ({
  root: {
    height: "32px",
    maxWidth: "32px",
    color: "#081981",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    // fontSize: "16px",
    // fontWeight: "bold",
    // textTransform: "none",
  },
}))(Button);

export const NewPolicyButtonForPolicyCreateEdit = withStyles((theme) => ({
  root: {
    width: "162px",
    height: "47px",
    color: "#ffffff",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "6px",
    marginBottom: "12px",
    "&:hover": {
      backgroundColor: "#081981",
    },
  },
}))(Button);

export const CreatePolicyButtonForPolicyCreateEdit = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "6px",
  },
}))(Button);

export const CreateFlowGroupButtonForPolicyCreateEdit = withStyles((theme) => ({
  root: {
    color: "#081981",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
  },
}))(Button);

export const IconButtonForPolicyCreateEdit = withStyles((theme) => ({
  root: {
    color: "#081981",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#dfe1e9",
    },
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    minWidth: "32px !important",
  },
}))(Button);

export const ColorButtonForTrigger = withStyles((theme) => ({
  root: {
    width: "135px",
    color: "#292929",
    padding: "0px",
    backgroundColor: "#DFE1E9",
    "&:hover": {
      color: "white",
      backgroundColor: "#081981",
    },
    "&:focus": {
      color: "white",
      backgroundColor: "#081981",
    },
    fontSize: "16px",
    textTransform: "none",
  },
  label: {
    margin: "7px",
    padding: 0,
    pointerEvents: "none",
  },
}))(ToggleButton);

export const SmallButtonForTrigger = withStyles((theme) => ({
  root: {
    color: "#081981",
    backgroundColor: "white",
    minWidth: "auto",
    "&:hover": {
      color: "white",
      backgroundColor: "#081981",
    },
    "&:focus": {
      color: "white",
      backgroundColor: "#081981",
    },
  },
  label: {
    pointerEvents: "none",
  },
}))(ToggleButton);

export const MarkResolveButtonForDrawer = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 48px",
    lineHeight: 1.5,
    backgroundColor: "#2947FD",
    borderRadius: "4px",
  },
}))(Button);

export const FalsePositiveButtonForDrawer = withStyles((theme) => ({
  root: {
    color: "#081981",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 59px",
    lineHeight: 1.5,
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#081981",
      color: "#ffffff",
    },
  },
}))(Button);

export const CompleteButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 62px",
    lineHeight: 1.5,
    backgroundColor: "#2947FD",
    borderRadius: "4px",
  },
}))(Button);

export const CancelButton = withStyles((theme) => ({
  root: {
    color: "#1a2983",
    height: "32px",
    width: "98px",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 62px",
    lineHeight: 1.5,
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
  },
  label: {
    fontWeight: "600 !important",
  },
}))(Button);

export const ConnectButton = withStyles((theme) => ({
  root: {
    height: "44px",
    width: "146px",
    color: "#ffffff",
    textTransform: "none",
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "4px",
    boxShadow: "0 5px 8px -3px rgba(41,71,253,0.5)",
  },
}))(Button);

export const SaveButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    height: "32px",
    width: "98px",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 62px",
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "4px",
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
  },
}))(Button);

export const AddDBButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    fontSize: "16px",
    lineHeight: 1.5,
    paddingLeft: "17px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    backgroundColor: "#2947fd",
  },
}))(Button);
export const ViewLogDetailsButton = withStyles((theme) => ({
  root: {
    color: "#081981",
    textTransform: "none",
    fontSize: 16,
    width: "175px",
    lineHeight: 1.5,
    backgroundColor: "#ffffff",
    fontFamily: "Nunito-Bold",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#2D48FD",
      color: "#ffffff",
    },
  },
}))(Button);

export const AutoTriggerSlider = withStyles({
  root: {
    color: "#2CE68F",
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#2947FD",
    border: "2px solid #fff",
    marginTop: -7,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "#2947FD",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "#DFE1E9",
  },
})(Slider);
export const SendLink = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    height: "32px",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 62px",
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "4px",
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
  },
}))(Button);

export const ApplyButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    height: "36px",
    width: "98px",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 62px",
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "4px",
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
    "&:hover": {
      color: "#2947fd",
    },
  },
}))(Button);

export const CreateUserButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    height: "32px",
    width: "278px",
    textTransform: "none",
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#2947fd",
    borderRadius: "4px",
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
  },
}))(Button);
