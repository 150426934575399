import React from "react";
import SignUpBackGround from "../../views/BackOfficeGeneralContainer/SignupPageBackGround";
import MDRSettingsContent from "./MDRSettingsContent";
const MDRConfig = () => {
  const tenant = window.localStorage.getItem("tenant");
  return (
    <div>
      <SignUpBackGround
        headerName="Adding configuration"
        header="Adding Configuration"
        breadcrumbValue={[
          {
            value:
              window.location.pathname ===
              "/mdr/Settings/accountIdConfiguration"
                ? "Account ID-based Geolocation"
                : "Private IP-based Geolocation",
            link:
              tenant !== "druvstar" &&
              tenant !== "datavision" &&
              //   window.location.pathname === "/mdr/Settings/Configuration"
              `/mdr/Settings`,
          },
          { value: "Add", link: "" },
        ]}
        // type="marginRequired"
      ></SignUpBackGround>

      <MDRSettingsContent></MDRSettingsContent>
      {/* <ManagementContent></ManagementContent> */}
    </div>
  );
};

export default MDRConfig;
