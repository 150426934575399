import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import { deleteTenantRole, deleteTenantUser, DELETE_TENANT, updateAlert, updateAlertPolicy, updateTenant, updateTenantRole, updateTenantUser, UPDATE_ENTERPRISE_ACTORS, deletePlatformUser } from "../../redux/constants/constants";
import CanAllow from "./CanAllow";
import { userPermission } from "../common/utilities";

export default function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    loc,
    project,
    // totalElements,
  } = props;
  const createSortHandler = (property, label, arrowClick) => (event) => {
    onRequestSort(event, property);
    if (arrowClick) {
      // if (loc === "database_Users") {
      //   dispatch({
      //     type: "DATABASE_USERS_HEADERCLICKHANDLER",
      //     payload: label,
      //   });
      // } else if (loc === "Access_details") {
      //   dispatch({
      //     type: "ACCESS_DETAILS_HEADERCLICKHANDLER",
      //     payload: label,
      //   });
      // }
    }
  };
  // const headerClickHandler = (event, headCell) => {
  //   console.log(event.target.textContent);
  //   // if (loc === "database_Users") {
  //   //   dispatch({
  //   //     type: "DATABASE_USERS_HEADERCLICKHANDLER",
  //   //     payload: event.target.textContent,
  //   //   });
  //   // } else if (loc === "Access_details") {
  //   //   console.log(event.target.textContent);
  //   //   dispatch({
  //   //     type: "ACCESS_DETAILS_HEADERCLICKHANDLER",
  //   //     payload: event.target.textContent,
  //   //   });
  //   // }

  //   createSortHandler(headCell);
  // };

  const classNameGenerator = (className) => {
    if (project === "MDR") {
      return `${className}-mdr`;
    }
    return className;
  };
  const needs = { 
    'alerts': updateAlert,
    'policy': updateAlertPolicy,
    'summary': updateAlert,
    'tenant-management-Tenants': DELETE_TENANT,
    'tenant-management-Deleted': updateTenant,
    'users': userPermission(deleteTenantUser,deletePlatformUser),
    'roles': deleteTenantRole,
    'database_Users': UPDATE_ENTERPRISE_ACTORS 
  }
  const renderHeadings = (headCell, index) => {
    return (
      <>
        <TableCell
          key={headCell.id ? headCell.id : index}
          align={
            headCell.id === "level" ||
              headCell.id === "status" ||
              headCell.id === "DataFlowName" ||
              headCell.id === "action" ||
              headCell.id === "applicationName" ||
              headCell.id === "username"
              ? "center"
              : "left"
          }
          padding={headCell.disablePadding ? "none" : "default"}
          onClick={(event) => {
            if (headCell.orderId !== "") {
              // headerClickHandler(event, headCell.orderId);
              createSortHandler(headCell.orderId);
            }
          }}
          sortDirection={orderBy === headCell.id ? order : false}
          className={
            index === headCells.length - 1
              ? classNameGenerator("last-cell")
              : index === 0 &&
                loc !== "alerts" &&
                loc !== "policy" &&
                loc !== "summary" &&
                loc !== "tenant-management-Tenants" &&
                loc !== "tenant-management-Deleted" &&
                loc !== "users" &&
                loc !== "roles" &&
                loc !== "database_Users" &&
                loc !== "Access_details"
                ? classNameGenerator("first-cell")
                : classNameGenerator("other-cell")
          }
        >
          <TableSortLabel
            active={
              headCell.orderId !== "" &&
              orderBy === headCell.orderId &&
              loc !== "database_Users" &&
              loc !== "Access_details"
            }
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={
              headCell.orderId !== ""
                ? createSortHandler(headCell.orderId, headCell.label, true)
                : ""
            }
          >
            {headCell.label !== "" ? headCell.label : ""}
            {headCell.orderId !== "" &&
              orderBy === headCell.orderId &&
              loc !== "database_Users" &&
              loc !== "Access_details" ? (
              <span className="visually-hidden">
                {order === "desc"
                  ? "sorted descending"
                  : "sorted ascending"}
              </span>
            ) : null}
            {headCell.img && <img src={headCell.img} />}
          </TableSortLabel>
        </TableCell>
      </>
    )
  }
  return (
    <TableHead style={{ position: "sticky", top: "0", zIndex: "1" }}>
      <TableRow>
      {loc === "alerts" ||
        loc === "policy" ||
        loc === "summary" ||
        loc === "tenant-management-Tenants" ||
        loc === "tenant-management-Deleted" ||
        loc === "users" ||
        loc === "roles" ||
        loc === "database_Users" ? (
          <CanAllow needs={{permission: [needs[loc]] }} >
            <TableCell
              className={classNameGenerator("first-cell")}
              padding="checkbox"
            >
              <Checkbox
                className="checkbox-root"
                color="default"
                checkedIcon={
                  rowCount > 0 && numSelected === rowCount ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  ) 
                }
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "decorative checkbox" }}
              />
            </TableCell>
          </CanAllow> 
          ) : (
          ""
        )}
        {headCells.map((headCell, index) => {
          // Check if the object has a requiredPermission property
          if (headCell.requiredPermission) {
            // Check if the user has the required permission
            return(
              <CanAllow needs = {{permission: [headCell.requiredPermission]}}>
                {renderHeadings(headCell,index)}
              </CanAllow>
            )
          } else {
           return renderHeadings(headCell,index)
          }
        }
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
