import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ImageTooltip from "../../common/HelpIconWithTooltip";
import { useSelector } from "react-redux";
import { reviewDataSource } from "../../../redux/constants/constants";
import CanAllow from "../../shared/CanAllow";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { totalElements, alertsTitle, helpDescription, toggleDrawer } = props;
  const permissions = useSelector((state) => state.applicationData.permissions);

  const renderHeaderTitle = () => {
    return (
      <Grid style={{ display: "flex" }} item xs={6}>
        <Typography
          className="title"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {alertsTitle}
        </Typography>
        <div className="count">{totalElements}</div>
        <ImageTooltip description={helpDescription} />
      </Grid>
    );
  };

  const renderButtons = () => {
    return (
      <Grid item xs={6} style={{ float: "right", textAlign: "right" }}>
        <CanAllow needs={{permission: [reviewDataSource]}}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(event) => toggleDrawer(true, event)}
            style={{
              color: "rgb(255, 255, 255)",
              textTransform: "none",
              fontSize: "16px",
              padding: "6px 12px",
              lineHeight: 1.5,
              backgroundColor: "rgb(41, 71, 253)",
              borderRadius: "6px",
            }}
            className="min-margin table-buttons"
          >
            Add Log Source
          </Button>
        </CanAllow>
      </Grid>
    );
  };

  const renderGrid = () => {
    return (
      <Grid container spacing={3}>
        {renderHeaderTitle()}
        {renderButtons()}
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          {renderGrid()}
        </Grid>
      </Toolbar>
    </Paper>
  );
};
