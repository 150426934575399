import {
  UPDATE_ALERT_STATUS_SUCCEEDED,
  CLOSE_SNACKBAR,
  UPDATE_ALERT_STATUS_FAILED,
  PLATFORM_ADMIN_TENANT_ONBOARD_SUCCESS,
  PLATFORM_ADMIN_TENANT_ONBOARD_FAILED,
  PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE,
} from "../constants/constants";

const initialState = {
  tenantCreated: false,
  successMessage: "",
  tenantCreation: {
    errorMessage: "",
    successMessage: "",
  },
  unsuccessful: "",
};

export const platformAdminTenantOnBoardReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CLOSE_SNACKBAR: {
      return {
        ...state,
        successMessage: "",
        alertUpdated: false,
      };
    }
    case PLATFORM_ADMIN_TENANT_ONBOARD_SUCCESS: {
      return {
        ...state,
        tenantCreation: { successMessage: "Tenant creation successfull" },
      };
    }
    case PLATFORM_ADMIN_TENANT_ONBOARD_FAILED: {
      return {
        ...state,
        tenantCreation: {
          errorMessage: action.payload,
          successMessage: "",
        },
      };
    }
    case PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE: {
      return {
        ...state,
        tenantCreation: {
          errorMessage: "",
          successMessage: "",
        },
      };
    }
    default:
      return state;
  }
};