import React from "react";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "../../../assets/vizr_images/ic_remove.svg";
import Button from "@material-ui/core/Button";
import { CreateLogSource } from "../../views/logSource/CreateLogSource";

function ManageLogSource({ toggleDrawer, getServiceData, stateOpen, classes}) {

  const handleClose = (event) => {
    toggleDrawer(false, event);
  };

  const renderLogSource = () => {
    return (
      <>
        <div
          style={{
            width: "600px",
            padding: "30px 30px",
            boxSizing: "border-box",
          }}
        >
          <div className="data-source-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="access_Details">Add Log Source</div>
              <Button
                onClick={(event) => handleClose(event)}
                aria-label="close"
                style={{ marginTop: "1rem" }}
              >
                <img src={CloseIcon} alt={"close"} />
              </Button>
            </div>
            <CreateLogSource
              toggleDrawer={toggleDrawer}
              getServiceData={getServiceData}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <React.Fragment>
        <Drawer
          classes={{
            paper: classes.paper,
          }}
          anchor={"right"}
          open={stateOpen}
          onClose={(event) => handleClose(event)}
        >
          {renderLogSource()}
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default ManageLogSource;
