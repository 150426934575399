import { ADD_MITIGATION, UPDATE_MITIGATION, DELETE_MITIGATION } from "../riskPilotConstant";

export const addMitigation = (mitigation) => ({
  type: ADD_MITIGATION,
  payload: mitigation,
});

export const updateMitigation = (vulnId, threatId, mitigationId, updates) => ({
  type: UPDATE_MITIGATION,
  payload: { vulnId, threatId, mitigationId, updates },
});

export const deleteMitigation = ( deleteMitigationId ) => ({
  type: DELETE_MITIGATION,
  payload: deleteMitigationId,
});
