// src/reducers/threatReducer.js
import {
  ADD_THREAT,
  DELETE_THREAT,
  UPDATE_THREAT,
  ADD_VULNERABILITY,
  DELETE_VULNERABILITY,
  CLEAR_THREATS,
  UPDATE_FROM_API,
} from "../riskPilotConstant";

const initialState = {
  threats: null,
  orderedThreats: []
};

export const threatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_THREAT:
      const newThreatId = action.payload.id;

      return {
        ...state,
        threats: {
          ...state.threats,
          [newThreatId]: action.payload,
        },
        orderedThreats: [newThreatId, ...(state.orderedThreats || [])],
      };

    case UPDATE_THREAT:
      return {
        ...state,
        threats: {
          ...state.threats,
          [action.payload.id]: {
            ...state.threats[action.payload.id],
            ...action.payload.updates,
          },
        },
      };

    case DELETE_THREAT:
      const { [action.payload]: removed, ...remainingThreats } = state.threats;

      // Remove the ID from orderedThreats
      const updatedOrderedThreats = state.orderedThreats.filter(
        (id) => id !== action.payload
      );

      return {
        ...state,
        threats: remainingThreats,
        orderedThreats: updatedOrderedThreats,
      };

    case ADD_VULNERABILITY:
      const { id, threatId } = action.payload;

      return {
        ...state,
        threats: {
          ...state.threats,
          [threatId]: {
            ...state.threats[threatId],
            vulnerabilities: [
              id,
              ...state.threats[threatId].vulnerabilities,
            ],
          },
        },
      };

    case DELETE_VULNERABILITY:
      const { vulnerabilityId, threatId: delThreatId } = action.payload;
      return {
        ...state,
        threats: {
          ...state.threats,
          [delThreatId]: {
            ...state.threats[delThreatId],
            vulnerabilities: state.threats[delThreatId].vulnerabilities.filter(
              (id) => id !== vulnerabilityId
            ),
          },
        },
      };

    case UPDATE_FROM_API:
      const { apiData } = action.payload;
      return {
        ...state,
        threats: apiData.threatData,
        orderedThreats: apiData.threatIds
      }

    case CLEAR_THREATS:
      return {
        ...state,
        threats: null,
        orderedThreats: [],
      };

    default:
      return state;
  }
};
