import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { dataFlowStaData } from "../../assets/staticData/dataFlow";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import LevelComponent from "./levelComponent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  fetchDMAlertAndPolicies,
  fetchDMListByQueryStr,
  fetchDMDataFlowsCount,
} from "../../redux/actions/dataMapInspectAction";
import LoaderComponent from "./LoaderComponent";
import { cubeQueryGen, cubeQueryGenCount, getTime } from "../common/utilities";
import {
  Button,
  Icon,
  TextField,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
} from "@material-ui/core";
import { fetchDMContentTabChildNodes } from "../../redux/actions/DataMapContentTabAction";
import { getCubejsApi, redirectToLogin } from "../common/Dashboard/cubejs-util";
import { useSelector } from "react-redux";
import ImageTooltip from "../common/HelpIconWithTooltip";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function createData(
  id,
  status,
  policyName,
  dataFlowGroupCount,
  alerts,
  alertRule,
  severity,
  time
) {
  return {
    id,
    status,
    policyName,
    dataFlowGroupCount,
    alerts,
    alertRule,
    severity,
    time,
  };
}

const headCells = [
  { id: "statusCode", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "alertPolicyName",
    numeric: false,
    disablePadding: false,
    label: "Policy Name",
  },
  {
    id: "dataFlowGroupCount",
    numeric: false,
    disablePadding: false,
    label: "Data Flow Groups",
  },
  { id: "alerts", numeric: false, disablePadding: false, label: "Alerts" },
  {
    id: "alertRule",
    numeric: false,
    disablePadding: false,
    label: "Alert Rule",
  },
  {
    id: "severityCode",
    numeric: false,
    disablePadding: false,
    label: "Severity",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: false,
    label: "Creation Date",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="enhanced-table-head">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ color: "#ffffff" }}
          >
            {headCell && headCell.orderId ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : headCell.label !== "" ? (
              headCell.label
            ) : (
              ""
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
    display: "flex",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, createNewFlowGroupClick, alertsTableCount } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span
          flexGrow={1}
          style={{ fontSize: "18px", fontWeight: "600", marginTop: "12px" }}
        >
          Policies
        </span>
        &nbsp;&nbsp;
        <p style={{ minWidth: "45px" }}>Total :</p>
        <div className="dataMap-tray-count">{alertsTableCount}</div>
        &nbsp;
        <span style={{ marginTop: "6px" }}>
          <ImageTooltip
            description={"Policies to monitor data access violations."}
          />
        </span>
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function DataMapAlertPolicies(props) {
  const {
    selectedAlertIds,
    transactionsTabHandle,
    selectedAppByType,
    selectedApp,
  } = props;
  let initFGList = {
    apiSucc: null,
    data: [],
    apiProgress: true,
  };
  const cubejsApi = getCubejsApi();
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [pagesLoaded, setPagesLoaded] = useState([`${0}`]);
  const [alertsTableData, setAlertsTableData] = useState(initFGList);
  const [alertsTableCount, setAlertsTableCount] = useState(0);
  const [nodeList, setNodeList] = useState([]);
  const selectedNode = useSelector(
    (state) => state.selectedNode.selectedNodeId
  );

  useEffect(() => {
    getNodeList();
    return () => {
      setNodeList([]);
    };
  }, [selectedNode]);

  useEffect(() => {
    if (nodeList && nodeList.length > 0) {
      getAllDMAlertPolicies(rowsPerPage, page);
      getDMFlowGroupCount();
    }
    return () => { };
  }, [nodeList]);

  async function getNodeList() {
    let nodes = [];
    const queryType = "multi";
    const nodeQuery = {
      dimensions: ["Nodes.nodeId"],
      timeDimensions: [],
      order: {},
      filters: [],
    };
    if (selectedNode) {
      nodeQuery.filters.push({
        member: "Nodes.parentId",
        operator: "equals",
        values: [selectedNode],
      });
    }
    const nodesResponse = await fetchDMContentTabChildNodes(
      nodeQuery,
      queryType
    );
    if (nodesResponse) {
      nodesResponse.length &&
        nodesResponse.forEach((each) => {
          nodes.push(each[`Nodes.nodeId`]);
        });
      if (selectedNode) {
        nodes.push(selectedNode);
      }
      setNodeList(nodes);
    }
  }

  async function getAllDMAlertPolicies(rowsPerPage, page) {
    initFGList = {
      apiSucc: null,
      data: [],
      apiProgress: true,
    };
    setAlertsTableData(initFGList);

    // let nodes = [];
    // const queryType = "multi"
    // const nodeQuery = {
    //   "dimensions": [
    //     "Nodes.nodeId"
    //   ],
    //   "timeDimensions": [],
    //   "order": {},
    //   "filters": []
    // };
    // if(selectedNode){
    //   nodeQuery.filters.push({
    //     "member": "Nodes.parentId",
    //     "operator": "equals",
    //     "values": [
    //       selectedNode
    //     ]
    //   })
    // }
    //const nodesResponse = await fetchDMContentTabChildNodes(nodeQuery, queryType);

    if (nodeList && nodeList.length > 0) {
      // nodesResponse.results[0].data.map((each) => {
      //   nodes.push(each[`Nodes.nodeId`])
      // })
      // nodes.push(selectedNode)
      const dimensionType = [
        "alertPolicyName",
        "alertPolicyId",
        //"policyDescription",
        "dataFlowGroupCount",
        "severityCode",
        "statusCode",
        "triggerConditionValue",
        "aggregationTypeValue",
        "triggerCategoryValue",
        "comparisonTypeValue",
        "timeWindowTypeValue",
        "dataFlowGroupCount",
        "alertCount",
        "automatedMonitoring",
        "creationTime",
      ];
      let filtersList = [];
      if (selectedApp && selectedApp.id) {
        filtersList = [
          {
            dimensionName: "AlertPoliciesForApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [selectedApp.id],
          },
        ];
      }

      if (
        selectedAppByType &&
        Object.keys(selectedAppByType).length > 0 &&
        selectedAppByType.type !== "alerts" &&
        selectedAppByType.type !== "alertPolicies"
      ) {
        if (selectedAppByType.type === "dataFlowGroups") {
          filtersList.push({
            dimensionName: "AlertPoliciesForApplication",
            dimensionType: "dataFlowGroupId",
            operator: "equals",
            values: [selectedAppByType.id],
          });
        } else {
          filtersList.push({
            dimensionName: "AlertPoliciesForApplication",
            dimensionType: "dataFlowId",
            operator: "equals",
            values: [selectedAppByType.id],
          });
        }
      } else {
        filtersList.push({
          dimensionName: "AlertPoliciesForApplication",
          dimensionType: "nodeId",
          operator: "equals",
          values: nodeList,
        });
      }

      const queryString = cubeQueryGen(
        "AlertPoliciesForApplication",
        dimensionType,
        filtersList
      );
      queryString["limit"] = rowsPerPage;
      queryString["offset"] = page * rowsPerPage;
      const queryTypeString = "multi";

      // if (nodes.length > 0) {
      //   queryString.filters.push({ "dimension": "AlertPoliciesForApplication.nodeId", "operator": "equals", "values": nodes })
      // }
      // const response = await fetchDMListByQueryStr(queryString, queryTypeString)
      let response;
      try {
        const resultSet = await cubejsApi.load(queryString);
        response = resultSet.rawData();
      } catch (error) {
        redirectToLogin(error)
      }

      if (response && response.length) {
        handleAlertsTableData(response);
      } else {
        initFGList = {
          apiSucc: true,
          data: [],
          apiProgress: null,
        };
        setAlertsTableData(initFGList);
      }
    }
  }
  async function getDMFlowGroupCount() {
    setAlertsTableCount(0);

    // let nodes = [];
    // const queryType = "multi"
    // const nodeQuery = {
    //   "dimensions": [
    //     "Nodes.nodeId"
    //   ],
    //   "timeDimensions": [],
    //   "order": {},
    //   "filters": []
    // };
    // if(selectedNode){
    //   nodeQuery.filters.push({
    //     "member": "Nodes.parentId",
    //     "operator": "equals",
    //     "values": [
    //       selectedNode
    //     ]
    //   })
    // }
    // const nodesResponse = await fetchDMContentTabChildNodes(nodeQuery, queryType);
    if (nodeList && nodeList.length > 0) {
      // nodesResponse.results[0].data.map((each) => {
      //   nodes.push(each[`Nodes.nodeId`])
      // })
      // nodes.push(selectedNode)
      const dimensionType = ["alertPoliciesCount"];
      let filtersList = [];
      if (selectedApp && selectedApp.id) {
        filtersList = [
          {
            dimensionName: "AlertPoliciesForApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [selectedApp.id],
          },
        ];
      }
      if (
        selectedAppByType &&
        Object.keys(selectedAppByType).length > 0 &&
        selectedAppByType.type !== "alerts" &&
        selectedAppByType.type !== "alertPolicies"
      ) {
        if (selectedAppByType.type === "dataFlowGroups") {
          filtersList.push({
            dimensionName: "AlertPoliciesForApplication",
            dimensionType: "dataFlowGroupId",
            operator: "equals",
            values: [selectedAppByType.id],
          });
        } else {
          filtersList.push({
            dimensionName: "AlertPoliciesForApplication",
            dimensionType: "dataFlowId",
            operator: "equals",
            values: [selectedAppByType.id],
          });
        }
      } else {
        if (nodeList) {
          filtersList.push({
            dimensionName: "AlertPoliciesForApplication",
            dimensionType: "nodeId",
            operator: "equals",
            values: nodeList,
          });
        }
      }
      const queryString = cubeQueryGenCount(
        "AlertPoliciesForApplication",
        dimensionType,
        filtersList
      );
      // const respCount = await fetchDMDataFlowsCount(queryString, "multi");
      let respCount;
      try {
        const resultSet = await cubejsApi.load(queryString);
        respCount = resultSet.rawData();
      } catch (error) {
        redirectToLogin(error)
      }
      if (
        respCount && respCount.length > 0 &&
        respCount[0]["AlertPoliciesForApplication.alertPoliciesCount"]
      ) {
        setAlertsTableCount(
          respCount[0]["AlertPoliciesForApplication.alertPoliciesCount"]
        );
      } else {
        setAlertsTableCount(0);
      }
    }
  }

  const handleChangePage = (event, newPage) => {
    if (pagesLoaded.length > 0) {
      setPage(newPage);
      getAllDMAlertPolicies(rowsPerPage, newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getAllDMAlertPolicies(parseInt(event.target.value, 10), 0);
  };

  const selectTransactionHandle = (dataFlowItem) => {
    transactionsTabHandle(dataFlowItem);
  };

  const handleAlertsTableData = (data) => {
    let filteredData = data;
    if (selectedAlertIds && selectedAlertIds.length) {
      filteredData = filteredData.filter((filterItem) => {
        const findAlertId = selectedAlertIds.find(
          (alertId) => alertId === filterItem.alertPolicyId
        );
        if (findAlertId) {
          return filterItem;
        }
      });
    }
    const newD = [];
    filteredData.forEach((item) => {
      let triggerCondition = "-";
      if (!item["AlertPoliciesForApplication.automatedMonitoring"]) {
        if (item["AlertPoliciesForApplication.comparisonTypeValue"]) {
          triggerCondition = `${item["AlertPoliciesForApplication.comparisonTypeValue"]} 
                            by 
                            ${item["AlertPoliciesForApplication.triggerConditionValue"]}
                            ${item["AlertPoliciesForApplication.aggregationTypeValue"]} 
                            on a 
                            ${item["AlertPoliciesForApplication.timeWindowTypeValue"]} 
                            basis`;
        } else {
          triggerCondition = "Sensitive Data Access";
        }
      } else {
        triggerCondition = "Automated Monitoring";
      }
      newD.push(
        createData(
          item["AlertPoliciesForApplication.alertPolicyId"],
          item["AlertPoliciesForApplication.statusCode"],
          item["AlertPoliciesForApplication.alertPolicyName"],
          item["AlertPoliciesForApplication.dataFlowGroupCount"],
          item["AlertPoliciesForApplication.alertCount"],
          triggerCondition,
          item["AlertPoliciesForApplication.severityCode"],
          getTime(Number(item["AlertPoliciesForApplication.creationTime"]))
        )
      );
    });
    initFGList = {
      apiSucc: true,
      data: newD,
      apiProgress: null,
    };
    setAlertsTableData(initFGList);
  };
  return (
    <div className="transcation-container">
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          alertsTableCount={alertsTableCount}
        />
        <TableContainer className="inspect-height">
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={alertsTableData.data.length}
            />
            {alertsTableData.apiProgress ? (
              <TableBody>
                <LoaderComponent />
              </TableBody>
            ) : (
              <React.Fragment>
                {!alertsTableData.apiProgress && alertsTableData.data.length ? (
                  <TableBody>
                    {stableSort(
                      alertsTableData.data,
                      getComparator(order, orderBy)
                    ).map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          <TableCell component="th" id={labelId} scope="row">
                            <LevelComponent
                              level={row.status}
                              status={""}
                              Id={labelId}
                            />
                          </TableCell>
                          <TableCell align="left">{row.policyName}</TableCell>
                          <TableCell align="left">
                            {row.dataFlowGroupCount}
                          </TableCell>
                          <TableCell align="left">{row.alerts}</TableCell>
                          <TableCell align="left">{row.alertRule}</TableCell>
                          <TableCell align="left">
                            <LevelComponent
                              level={""}
                              status={row.severity}
                              Id={row.severity}
                            />
                          </TableCell>
                          <TableCell align="left">{row.time}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        No Data Available
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>
        {Number(alertsTableCount) > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 20, 50, 100, 200]}
            component="div"
            count={alertsTableCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
