import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import HelpIcon from "../../assets/vizr_images/ic_help.svg";

import LoaderComponent from "./LoaderComponent";
import { cubeQueryGen, cubeQueryGenCount } from "../common/utilities";
import TransactionIcon from "../../assets/vizr_images/ic_transaction.svg";
import {
  fetchDMContentTabChildNodes,
  fetchCubeDataSources,
  fecthAllDataSources,
} from "../../redux/actions/DataMapContentTabAction";
import InputBase from "@material-ui/core/InputBase";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fetchApplicationData } from "../../redux/actions/getAlertsAction";
import {
  fetchDMDataFlows,
  fetchDMDataFlowsCount,
  fetchDMNodesByCubeNodes,
} from "../../redux/actions/dataMapInspectAction";

import {
  Button,
  TextField,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@material-ui/core";

import { saveDMDataFlowGroup } from "../../redux/actions/dataMapInspectAction";
import { getCubejsApi } from "../common/Dashboard/cubejs-util";
import { useSelector } from "react-redux";
import ImageTooltip from "../common/HelpIconWithTooltip";

function createData(name, noOfTransactions, id) {
  return { name, noOfTransactions, id };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "noOfTransactions",
    numeric: true,
    disablePadding: false,
    label: "",
    icon: TransactionIcon,
  },
  //{ id: 'dataSentivity', numeric: false, disablePadding: false, label: 'Data Sentivity' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="enhanced-table-head">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              color: "#ffffff",
              width: headCell.id === "noOfTransactions" ? "200px" : "default",
            }}
          >
            {headCell && headCell.orderId ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label && <span>{headCell.label}</span>}
                {headCell.icon && <img src={headCell.icon} />}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <div>
                {headCell.label !== "" ? headCell.label : ""}
                {headCell.icon && <img src={headCell.icon} />}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    display: "flex",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    createNewFlowGroupClick,
    getToolbarFilter,
    handleSelectedApp,
    flowGrouplistCount,
  } = props;
  const searchCriteria = [
    { code: "Clear Filter", value: "" },
    { code: "Application", value: "DataFlowGroupsByApplication.applicationId" },
    { code: "Data Source", value: "DataFlowGroupsByApplication.nodeId" },
    //{ code: "Data Flow Name", value: "Da      taFlowsByApplication.dataFlowName"},
  ];
  //const optionsFor = ["Application","Data Source","Data Flow Name"]
  const [optionsFor, setOptionsFor] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  const [searchOptionSelected, setSearchOptionSelected] = useState("");
  const [applications, setApplications] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [selectedOpt, setSelectedOpt] = useState("");
  useEffect(() => {
    console.log(searchBy, "searchBy");
    if (searchBy) {
      if (searchBy === "Application") {
        //api call for applications
        const getApplications = async () => {
          const res = await fetchApplicationData();
          let temp = [];
          if (res && res.content) {
            res.content.forEach((each) => {
              temp.push({ name: each.name, id: each.id });
            });
            setApplications(temp);
            setOptionsFor(temp);
          } else {
            alert("Error  fetching data");
          }
        };
        getApplications();
      } else if (searchBy === "Data Flow Name") {
        //api call for data flow name to get the id i.e nodeId
      } else if (searchBy === "Data Source") {
        //api call for data sources -> cubeJs
        const getAllDataSourcesUpdated = async () => {
          const dimensionType = ["dataSourceId", "databaseName"];
          const queryType = "multi";
          const queryString = cubeQueryGen("DataSources", dimensionType);
          const response = await fecthAllDataSources(queryString, queryType);
          if (
            response &&
            response.data &&
            response.data.results &&
            response.data.results.length &&
            response.data.results[0].data &&
            response.data.results[0].data.length
          ) {
            console.log("getAllDataSourcesUpdated", response);
            const data = response.data.results[0].data;
            let options = [];
            options = data
              .filter((each) => {
                return each[`DataSources.databaseName`] !== "";
                // options.push({ name: each[`DataSources.databaseName`] });
              })
              .map((each) => {
                return {
                  name: each[`DataSources.databaseName`],
                  id: each["DataSources.dataSourceId"],
                };
              });
            console.log(options);
            setOptionsFor(options);
          }
        };
        getAllDataSourcesUpdated();
      } else {
        getToolbarFilter("", "clear");
        if (handleSelectedApp) {
          handleSelectedApp("");
        }
      }
    }
  }, [searchBy]);

  const handleSelectedOption = (name) => {
    if (searchBy === "Application") {
      applications.forEach((each) => {
        if (each.name === name) {
          //setSearchOptionSelected(each.id)
          getToolbarFilter(each.id, "Application");
        }
      });
    } else if (searchBy === "Data Source") {
      dataSources.forEach((each) => {
        if (each.name === name) {
          //setSearchOptionSelected(each.id)
          getToolbarFilter(each.id, "Data Source");
        }
      });
    } else {
      //data flow name search
    }
  };

  useEffect(() => {
    if (searchBy === "Clear Filter") {
      getToolbarFilter("", "clear");
      if (handleSelectedApp) {
        handleSelectedApp("");
      }
    }
  }, [searchBy]);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span
          flexGrow={1}
          style={{ fontSize: "18px", fontWeight: "600", marginTop: "12px" }}
        >
          Flow Group(s)
        </span>
        &nbsp;&nbsp;
        <p style={{ minWidth: "45px" }}>Total :</p>
        <div className="dataMap-tray-count">{flowGrouplistCount}</div>&nbsp;
        <span style={{ marginTop: "6px" }}>
          <ImageTooltip description={"Data Flow Groups in the system."} />
        </span>
        {/* <span className="" style={{ float: 'right' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button, 'float-right'}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => createNewFlowGroupClick(true)}
          >
            Create New Flow Group
              </Button>
        </span> */}
      </Typography>
      <div style={{ display: "flex" }}>
        <h3 className="margin" style={{ marginTop: "8px", width: "65px" }}>
          Search By:
        </h3>
        <Select
          value={searchBy}
          onChange={(e) => {
            setSearchOptionSelected("");
            setSearchBy(e.target.value);
            setOptionsFor([]);
          }}
          className="InputSelectFeild"
          style={{ width: "200px" }}
        >
          {searchCriteria && searchCriteria.length > 0
            ? searchCriteria.map((el, index) => (
                <MenuItem value={el.code} id={el.value}>
                  {el.code}
                </MenuItem>
              ))
            : null}
        </Select>
      </div>
      {searchBy === "Data Flow Name" && (
        <div className={classes.search}>
          <InputBase
            classes={{
              root: classes.inputRoot,
              input: `${classes.inputInput} ${classes.year}`,
            }}
            inputProps={{ "aria-label": "application" }}
          />
          <Grid
            style={{
              display: "flex",
              width: "11rem",
              height: "5rem",
            }}
            onChange={() => {
              console.log("change");
            }}
          >
            <Autocomplete
              style={{
                width: "11rem",
              }}
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              onInputChange={async (event) => {
                handleSelectedOption(event.target.innerHTML);
              }}
              options={optionsFor}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  label={`Search ${searchBy}`}
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                  InputProps={{ ...params.InputProps, type: "search" }}
                  value={searchOptionSelected}
                />
              )}
            />
          </Grid>
        </div>
      )}
      {(searchBy === "Data Source" || searchBy === "Application") && (
        <Select
          value={selectedOpt}
          onChange={(e) => {
            setSelectedOpt(e.target.value);
            getToolbarFilter(e.target.value["id"], searchBy);
            if (handleSelectedApp) {
              handleSelectedApp(e.target.value, searchBy);
            }
          }}
          className="InputSelectFeild"
          style={{ width: "200px" }}
        >
          {optionsFor && optionsFor.length > 0
            ? optionsFor.map((el, index) => (
                <MenuItem value={el} key={index}>
                  {el.name}
                </MenuItem>
              ))
            : null}
        </Select>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function DataMapFlowGroup(props) {
  const classes = useStyles();
  let initFGList = {
    apiSucc: null,
    data: [],
    apiProgress: true,
  };
  const cubejsApi = getCubejsApi();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [flowGrouplistData, setFlowGrouplistData] = useState(initFGList);
  const [flowGrouplistCount, setFlowGrouplistCount] = useState(0);
  const [createNewFlowGroupView, setCreateNewFlowGroupView] = useState(false);
  const [pagesLoaded, setPagesLoaded] = useState([`${0}`]);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchFilterType, setSearchFilterType] = useState("");
  const [nodeList, setNodeList] = useState([]);
  const {
    transactionsCountClick,
    flowGroupNameClick,
    selectedAppByType,
    selectedApp,
    handleSelectedApp,
    notdatamapTrayPanel,
  } = props;
  const selectedNode = useSelector(
    (state) => state.selectedNode.selectedNodeId
  );

  useEffect(() => {
    getNodeList();
  }, [selectedNode]);

  useEffect(() => {
    if (nodeList.length) {
      getAllDMFlowGroup(rowsPerPage, page);
      getDMFlowGroupCount();
    }

    return () => {};
  }, [nodeList]);

  async function getNodeList() {
    let nodes = [];
    const queryType = "multi";
    const nodeQuery = {
      dimensions: ["Nodes.nodeId"],
      timeDimensions: [],
      order: {},
      filters: [],
    };
    if (selectedNode) {
      nodeQuery.filters.push({
        member: "Nodes.parentId",
        operator: "equals",
        values: [selectedNode],
      });
    }
    const nodesResponse = await fetchDMContentTabChildNodes(
      nodeQuery,
      queryType
    );
    if (nodesResponse) {
      nodesResponse.length &&
        nodesResponse.forEach((each) => {
          nodes.push(each[`Nodes.nodeId`]);
        });
      if (selectedNode) {
        nodes.push(selectedNode);
      }
      setNodeList(nodes);
    }
  }

  async function getAllDMFlowGroup(rowsPerPage, page, filter, filterType) {
    initFGList = {
      apiSucc: null,
      data: [],
      apiProgress: true,
    };
    setFlowGrouplistData(initFGList);
    if (nodeList && nodeList.length > 0) {
      const dimensionType = [
        "dataFlowGroupId",
        "dataFlowName",
        "flowCount",
        "dataFlowGroupSensitivity",
      ];
      let filtersList = [];
      let nodes = [...nodeList];
      if (selectedApp && selectedApp.id) {
        filtersList = [
          {
            dimensionName: "DataFlowGroupsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [selectedApp.id],
          },
        ];
      }
      if (filter && filterType) {
        if (filterType === "Application") {
          filtersList.push({
            dimensionName: "DataFlowGroupsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [filter],
          });
        } else if (filterType === "Data Source") {
          filtersList.push({
            dimensionName: "DataFlowGroupsByApplication",
            dimensionType: "dataSourceId",
            operator: "equals",
            values: [filter],
          });
          // nodes.push(filter);
        }
      }
      if (
        searchFilter &&
        searchFilterType &&
        (filterType === "" || !filterType)
      ) {
        if (searchFilterType === "Application") {
          filtersList.push({
            dimensionName: "DataFlowGroupsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [searchFilter],
          });
        } else if (searchFilterType === "Data Source") {
          filtersList.push({
            dimensionName: "DataFlowGroupsByApplication",
            dimensionType: "dataSourceId",
            operator: "equals",
            values: [searchFilter],
          });
        }
      }
      const queryString = cubeQueryGen(
        "DataFlowGroupsByApplication",
        dimensionType,
        filtersList
      );
      queryString["limit"] = rowsPerPage;
      queryString["offset"] = page * rowsPerPage;
      const queryTypeString = "multi";

      //This will only execute if this component is called or used in Datamap inspect content for particular node

      if (nodes && nodes.length > 0 && !notdatamapTrayPanel) {
        queryString.filters.push({
          dimension: "DataFlowGroupsByApplication.nodeId",
          operator: "equals",
          values: nodes,
        });
      }

      const resultSet = await cubejsApi.load(queryString);
      const response = resultSet.rawData();
      if (response.length) {
        handleflowGrouplistData(response);
      } else {
        initFGList = {
          apiSucc: true,
          data: [],
          apiProgress: null,
        };
        setFlowGrouplistData(initFGList);
      }
    } else {
      initFGList = {
        apiSucc: true,
        data: [],
        apiProgress: null,
      };
      setFlowGrouplistData(initFGList);
    }
  }

  async function getDMFlowGroupCount(filter, filterType) {
    setFlowGrouplistCount(0);

    const dimensionType = ["dataFlowGroupCount"];
    let filtersList = [];
    let nodes = [...nodeList];
    if (selectedApp && selectedApp.id) {
      filtersList = [
        {
          dimensionName: "DataFlowGroupsByApplication",
          dimensionType: "applicationId",
          operator: "equals",
          values: [selectedApp.id],
        },
      ];
    }
    if (filter && filterType) {
      if (filterType === "Application") {
        filtersList.push({
          dimensionName: "DataFlowGroupsByApplication",
          dimensionType: "applicationId",
          operator: "equals",
          values: [filter],
        });
      } else if (filterType === "Data Source") {
        filtersList.push({
          dimensionName: "DataFlowGroupsByApplication",
          dimensionType: "dataSourceId",
          operator: "equals",
          values: [filter],
        });
      }
    }
    if (
      searchFilter &&
      searchFilterType &&
      (filterType === "" || !filterType)
    ) {
      if (searchFilterType === "Application") {
        filtersList.push({
          dimensionName: "DataFlowGroupsByApplication",
          dimensionType: "applicationId",
          operator: "equals",
          values: [searchFilter],
        });
      } else if (searchFilterType === "Data Source") {
        // nodes.push(searchFilter);
        filtersList.push({
          dimensionName: "DataFlowGroupsByApplication",
          dimensionType: "dataSourceId",
          operator: "equals",
          values: [searchFilter],
        });
      }
    }
    if (nodes && nodes.length > 0 && !notdatamapTrayPanel) {
      filtersList.push({
        dimensionName: "DataFlowGroupsByApplication",
        dimensionType: "nodeId",
        operator: "equals",
        values: nodes,
      });
    }
    const queryString = cubeQueryGenCount(
      "DataFlowGroupsByApplication",
      dimensionType,
      filtersList
    );

    const resultSet = await cubejsApi.load(queryString);
    const respCount = resultSet.rawData();
    console.log(
      filter,
      filterType,
      nodes,
      searchFilterType,
      filterType,
      respCount,
      "testing"
    );
    if (
      respCount.length > 0 &&
      respCount[0]["DataFlowGroupsByApplication.dataFlowGroupCount"]
    ) {
      setFlowGrouplistCount(
        respCount[0]["DataFlowGroupsByApplication.dataFlowGroupCount"]
      );
    } else {
      setFlowGrouplistCount(0);
    }
  }

  const createNewFlowGroupClick = (val) => {
    setCreateNewFlowGroupView(val);
  };

  const selectTransactionHandle = (dataFlowGroupItem, name) => {
    if (name === "dataFlowGroup") {
      flowGroupNameClick(dataFlowGroupItem);
    } else {
      transactionsCountClick(dataFlowGroupItem);
    }
  };
  const handleflowGrouplistData = (data) => {
    const newD = [];
    data.forEach((item) => {
      newD.push(
        createData(
          item["DataFlowGroupsByApplication.dataFlowName"],
          item["DataFlowGroupsByApplication.flowCount"],
          //item['DataFlowGroupsByApplication.dataFlowGroupSensitivity'],
          item["DataFlowGroupsByApplication.dataFlowGroupId"]
        )
      );
    });
    initFGList = {
      apiSucc: true,
      data: newD,
      apiProgress: null,
    };
    setFlowGrouplistData(initFGList);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = flowGrouplistData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    if (pagesLoaded.length > 0) {
      setPage(newPage);
      getAllDMFlowGroup(rowsPerPage, newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getAllDMFlowGroup(parseInt(event.target.value, 10), 0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      sensitivity: "",
      definition: "",
    }
  );
  const handleSubmit = (evt) => {
    evt.preventDefault();
    submitDMDataFlowGroup(formInput);
  };

  async function submitDMDataFlowGroup(data) {
    const response = await saveDMDataFlowGroup(data);
    if (response) {
      getAllDMFlowGroup(rowsPerPage, page);
      setCreateNewFlowGroupView(false);
    }
  }

  const getToolbarFilter = (filter, filterType) => {
    getAllDMFlowGroup(rowsPerPage, page, filter, filterType);
    getDMFlowGroupCount(filter, filterType);
    setSearchFilter(filter);
    setSearchFilterType(filterType);
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  return (
    <div className="transcation-container">
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleSelectedApp={handleSelectedApp}
          createNewFlowGroupClick={createNewFlowGroupClick}
          getToolbarFilter={getToolbarFilter}
          flowGrouplistCount={flowGrouplistCount}
        />
        {!createNewFlowGroupView && (
          <>
            <TableContainer className="inspect-height">
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={flowGrouplistData.data.length}
                />
                {flowGrouplistData.apiProgress ? (
                  <TableBody>
                    <LoaderComponent classname="lessZindex" />
                  </TableBody>
                ) : (
                  <React.Fragment>
                    {!flowGrouplistData.apiProgress &&
                    flowGrouplistData.data.length ? (
                      <TableBody>
                        {stableSort(
                          flowGrouplistData.data,
                          getComparator(order, orderBy)
                        ).map((row, index) => {
                          const isItemSelected = isSelected(row.name);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.name}
                              selected={isItemSelected}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                              >
                                <a
                                  className="text-decoration-none"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    selectTransactionHandle(
                                      row,
                                      "dataFlowGroup"
                                    )
                                  }
                                >
                                  {row.name}
                                </a>
                              </TableCell>
                              <TableCell align="right">
                                <a
                                  className="text-decoration-none"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    selectTransactionHandle(row, "traces")
                                  }
                                >
                                  {" "}
                                  {row.noOfTransactions}
                                </a>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={3}>
                            No Data Available
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </React.Fragment>
                )}
              </Table>
            </TableContainer>
            {Number(flowGrouplistCount) > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 20, 50, 100, 200]}
                component="div"
                count={flowGrouplistCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </>
        )}
      </Paper>
      {createNewFlowGroupView && (
        <div className="new-flowgroup-form">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Flow Group Name"
                  id="margin-normal"
                  name="name"
                  defaultValue={formInput.name}
                  className={classes.textField}
                  helperText="Enter Flow Group Name"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Definition"
                  id="margin-normal"
                  name="definition"
                  defaultValue={formInput.definition}
                  className={classes.textField}
                  helperText="Enter Definition"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Sensitivity
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleInput}
                    defaultValue={formInput.sensitivity}
                    name="sensitivity"
                  >
                    <MenuItem value={"HIGH"}>High</MenuItem>
                    <MenuItem value={"MEDIUM"}>Medium</MenuItem>
                    <MenuItem value={"LOW"}>Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" justify="flex-end">
              <Grid item xs={12} sm={3}>
                <Button
                  type="submit"
                  variant="contained"
                  className={(classes.button, "float-right")}
                  style={{ marginRight: "15px" }}
                  onClick={() => createNewFlowGroupClick(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={(classes.button, "float-right")}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
}
