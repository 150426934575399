import cubejs from "@cubejs-client/core";
import { CUBEJS_URL, CUBEJS_TOKEN } from "../../../redux/constants/constants";

export const getCubejsApi = () => {
  return cubejs(CUBEJS_TOKEN, {
    apiUrl: CUBEJS_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
};

export const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: CUBEJS_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
});

export const redirectToLogin = (error) => {
  if (error.message === "TokenExpiredError: jwt expired") {
    if (window.location.host.indexOf("localhost") !== -1) {
      window.location.replace(
        `http://localhost:3000/?tenant=${localStorage.getItem("tenant")}`
      );
    } else {
      window.location.replace(`http://${window.location.host}/`);
    }
    localStorage.clear();
    sessionStorage.clear();
  }
};
