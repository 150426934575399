import React from "react";
import ChartWrapper from "../../../common/Dashboard/Chart";
import { viewDataMap } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../../common/utilities";
import {
  renderNoData,
  renderSingleValue,
} from "../../../common/Dashboard/utils";
import { Chart } from "react-google-charts";

export function threatRiskLevelWidget(cubejsApi, name, query) {
  // Color data lookup for different risk levels
  const colorData = {
    CRITICAL: "#CC1C3E",
    HIGH: "#FF2251",
    MEDIUM: "#F68D2B",
    LOW: "#344BFD",
  };

  // Function to render legends and chart
  const renderLegends = (resultSet) => {
    // Initial chart options
    let chartOptions = {
      slices: {},
      pieSliceText: "label", // Display text on slices
      chartArea: { width: "90%", height: "75%" },
      legend: { position: "bottom" }, // Position the legend below the chart
      is3D: true, // Enable 3D effect
      animation: {
        startup: true, // Enable animation on startup
        duration: 1000, // Animation duration in milliseconds
        easing: "out", // Animation easing function
      },
    };

    // Event handlers for chart interaction
    const chartEvents = [
      {
        eventName: "onmouseover",
        callback: ({ chartWrapper, row }) => {
          chartOptions.slices[row].offset = 0.1; // Adjust the offset value to pop out the slice
          chartWrapper.draw();
        },
      },
      {
        eventName: "onmouseout",
        callback: ({ chartWrapper, row }) => {
          chartOptions.slices[row].offset = 0;
          chartWrapper.draw();
        },
      },
    ];

    const chartData = resultSet.loadResponse.results[0].data;

    // Handle no data scenarios
    if (!chartData || chartData.length === 0) {
      return renderNoData("43%");
    }

    // Construct pie chart data from resultSet and sort it
    const pieChartData = [["Risk Level", "Count"]];
    const orderedRiskLevels = ["CRITICAL", "HIGH", "MEDIUM", "LOW"];
    const sortedChartData = chartData.sort((a, b) => {
      return (
        orderedRiskLevels.indexOf(a["ThreatsByRiskLevel.riskLevel"]) -
        orderedRiskLevels.indexOf(b["ThreatsByRiskLevel.riskLevel"])
      );
    });

    sortedChartData.forEach((item, index) => {
      const riskLevel = item["ThreatsByRiskLevel.riskLevel"];
      const threatCount = parseInt(item["ThreatsByRiskLevel.threatCount"], 10);
      pieChartData.push([riskLevel, threatCount]);
      chartOptions.slices[index] = { color: colorData[riskLevel] };
    });

    return (
      <React.Fragment>
        <div>
          <hr className="threatLevelChartLine"></hr>
          <Chart
            chartType="PieChart"
            data={pieChartData}
            options={chartOptions}
            chartEvents={chartEvents}
            width={"95%"}
            height={"250px"}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="threatPieChart threatByRiskLevel">
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={name}
        query={query}
        render={renderLegends}
      />
    </div>
  );
}
