import React, { useState, useEffect } from "react";
import Toggler from "../../../components/shared/Toggler";
import './HeatMap.scss';
import { HEATMAP_URL, CLEAR_HEATMAP, SET_VIEW_BY_VALUE } from "../../../redux/constants/constants"
import { useDispatch, useSelector } from "react-redux";
import Popover from '@material-ui/core/Popover';
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";

const dayShorthandMap = {
  MONDAY: 'M',
  TUESDAY: 'T',
  WEDNESDAY: 'W',
  THURSDAY: 'Th',
  FRIDAY: 'F',
  SATURDAY: 'S',
  SUNDAY: 'Su'
};

export default function HeatMap(props) {
  //-------VAR HANDLING----------
  //popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState(null);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedData({ ...data });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.heatmapReducer.data
  );
  const loader = useSelector(
    (state) => state.heatmapReducer.loader
  );
  const [togglers, setTogglers] = useState([
    { name: "1 Day", state: "inactive" },
    { name: "1 Week", state: "active" },
    { name: "1 Month", state: "inactive" },
  ]);
  const setViewByHeatmap = (toggleValue) => {
    setTogglers(toggleValue);
    const active = togglers.filter((t) => t.state === "active")[0].name;
    dispatch({
      type: CLEAR_HEATMAP,
    })
    dispatch({
      type: SET_VIEW_BY_VALUE,
      payload: active
    });
  }

  //----------USE EFFECT----------
  useEffect(() => {
    dispatch({
      type: SET_VIEW_BY_VALUE,
      payload: "1 Week"
    });
    return () => {
      // Unmounting heat map
      const currentRoute = window.location.pathname;
      if (currentRoute !== HEATMAP_URL) {
        dispatch({
          type: CLEAR_HEATMAP,
        });
      }
    };
  }, []);

  //---------RENDERING----------
  const renderHeader = (data) => {
    return (
      <div className="group-container header-container">
        {
          (data[0].distribution.length < 10) ?
            data[0].distribution.map((d) => {
              return (
                <div className="bar-container no-border">
                  <span className="day-header">{dayShorthandMap[d.day]}</span>
                  <span className="day-header">{d.date}</span>
                </div>
              );
            }) :
            data[0].distribution.map((d) => {
              return (
                <div className="bar-container no-border">
                  <span className="day-header">{String(new Date(d.date).getDate()).padStart(2, '0')}</span>
                </div>
              );
            })
        }
      </div>
    );
  }
  const renderBorderClassName = (percentage) => {
    if (percentage === 0) {
      return 'no-border';
    } else {
      return '';
    }
  };
  const renderPopover = (daysData) => {
    return daysData ? (
      <>
        <div className="bar-container popover">
          <div className="fileCountContainer">
            <div>High</div> 
            <div>{daysData.highCount}</div>
          </div>
          <div className="indicator-container">
            <div className="bar-background">
              <div
                className={`bar high ${renderBorderClassName(daysData.highPercentage)}`}
                style={{ width: `${daysData.highPercentage}%` }}
                title={`${daysData.highPercentage}%`}
              ></div>
            </div>
            <div>{daysData.highPercentage}%</div>
          </div>
          <div className="fileCountContainer">
            <div>Medium</div> 
            <div>{daysData.mediumCount}</div>
          </div>
          <div className="indicator-container">
            <div className="bar-background">
              <div
                className={`bar medium ${renderBorderClassName(daysData.mediumPercentage)}`}
                style={{ width: `${daysData.mediumPercentage}%` }}
                title={`${daysData.mediumPercentage}%`}
              ></div>
            </div>
            <div>{daysData.mediumPercentage}%</div>
          </div>
          <div className="fileCountContainer">
            <div>Low</div> 
            <div>{daysData.lowCount}</div>
          </div>
          <div className="indicator-container">
            <div className="bar-background">
              <div
                className={`bar low ${renderBorderClassName(daysData.lowPercentage)}`}
                style={{ width: `${daysData.lowPercentage}%` }}
                title={`${daysData.lowPercentage}%`}
              ></div>
            </div>
            <div>{daysData.lowPercentage}%</div>
          </div>
          <div className="fileCountContainer">
            <div>Non Classified</div> 
            <div>{daysData.notClassifiedCount}</div>
          </div>
          <div className="indicator-container">
            <div className="bar-background">
              <div
                className={`bar nclassified ${renderBorderClassName(daysData.notClassifiedPercentage)}`}
                style={{ width: `${daysData.notClassifiedPercentage}%` }}
                title={`${daysData.notClassifiedPercentage}%`}
              ></div>
            </div>
            <div>{daysData.notClassifiedPercentage}%</div>
          </div>
        </div>
      </>
    ) : null;
  };
  const renderBarClassName = () => {
    const noOfDays = data.groupData[0].distribution.length;
    if (noOfDays > 10) {
      return 'barWrap-month';
    } else if (noOfDays < 7) {
      return 'barWrap-day';
    } else {
      return '';
    }
  };
  const renderDayBars = (daysData) => {
    return daysData ? (
      <>
        <div
          className="bar-container"
          onClick={(e) => handleClick(e, daysData)}
        >
          <div className={`barWrap high ${renderBarClassName()}`}>
            <div
              className={`bar high ${renderBorderClassName(daysData.highPercentage)}`}
              style={{ width: `${daysData.highPercentage}%` }}
              title={`${daysData.highPercentage}%`}
            ></div>
          </div>
          <div className={`barWrap medium ${renderBarClassName()}`}>
            <div
              className={`bar medium ${renderBorderClassName(daysData.mediumPercentage)}`}
              style={{ width: `${daysData.mediumPercentage}%` }}
              title={`${daysData.mediumPercentage}%`}
            ></div>
          </div>
          <div className={`barWrap low ${renderBarClassName()}`}>
            <div
              className={`bar low ${renderBorderClassName(daysData.lowPercentage)}`}
              style={{ width: `${daysData.lowPercentage}%` }}
              title={`${daysData.lowPercentage}%`}
            ></div>
          </div>
          <div className={`barWrap nclassified ${renderBarClassName()}`}>
            <div
              className={`bar nclassified ${renderBorderClassName(daysData.notClassifiedPercentage)}`}
              style={{ width: `${daysData.notClassifiedPercentage}%` }}
              title={`${daysData.notClassifiedPercentage}%`}
            ></div>
          </div>
        </div>
      </>
    ) : null;
  };

  const renderSummary = () => {
    return (
      <div className="summary-container">
        <div className="count-distro-container">
          <div className="count-item high-text">
            <div className="legend-item high">High</div>
            <div className="count-text">{data.totalHighSensitiveFiles}</div>
          </div>
          <div className="count-item medium-text">
            <div className="legend-item medium">Medium</div>
            <div className="count-text">{data.totalMediumSensitiveFiles}</div>
          </div>
          <div className="count-item low-text">
            <div className="legend-item low">Low</div>
            <div className="count-text">{data.totalLowSensitiveFiles}</div>
          </div>
          <div className="count-item nclassified-text">
            <div className="legend-item nclassified"> Non Classified</div>
            <div className="count-text">
              {data.totalNotClassifiedFiles}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderGroup = (groupData) => {
    return (
      <div className="group-main-container">
        <div className="header-container">
          <span>
            {groupData.group}
          </span>
        </div>
        <div className="group-container full-width">
          {groupData.distribution.map((day, i) => {
            return renderDayBars(day)
          })}
        </div>
      </div>
    );
  }
  const renderMonthHeader = (data) => {
    if (data[0].distribution.length < 10) {
      return null;
    } else {
      const lastDate = data[0].distribution.length - 1;
      return (
        <div className="month-header">
          <span className="month-header-M">1M</span>
          <span>
            {data[0].distribution[0].date} to{' '}
            {data[0].distribution[lastDate].date}
          </span>
        </div>
      );
    }
  };
  const renderMapContent = () => {
    return (
      <>
        <div className="main-heatmap-content">
          <div className="heatmap-container">
            {renderMonthHeader(data.groupData)}
            {renderHeader(data.groupData)}
            <div className="heatmap-groupdata">
              {data.groupData.map((d) => renderGroup(d))}
            </div>
          </div>
        </div>
        <Popover
          id={id}
          open={open}
          classes={{ paper: 'popover-custom' }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {renderPopover(selectedData)}
        </Popover>
      </>
    );
  };

  const renderToggler = () => {
    return (
      <div className="action-container">
        <span className="sensitivity-heatMap-header">Sensitivity Heat Map</span>
        <div className="toggle-container">
          <Toggler
            togglers={togglers}
            setTogglers={setViewByHeatmap}
            loc="inspectContent"
          ></Toggler>
        </div>
        {renderSummary()}
      </div>
    );
  }

  return (
    <>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source oneDrive-Table"
        ></img>
      )}
      {renderToggler()}
      {data && data.groupData && data.groupData.length ? (
        renderMapContent()
      ) : (
        <span className="no-data">No data available</span>
      )}
    </>
  );
}