import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    backgroundColor: "#3E4954",
    color: "#fff",
    width: "100px",
  },
}));

export default function MapPopover({ anchorEl, setAnchorEl, priorityState }) {
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // onMouseOut={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "#FF2251",
                  marginBottom: "10px",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {priorityState.high ? priorityState.high : 0}
              </div>
              <div>High</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "#FF8421",
                  marginBottom: "10px",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {priorityState.medium ? priorityState.medium : 0}
              </div>
              <div>Medium</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "#A8D5FF",
                  marginBottom: "10px",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {priorityState.low ? priorityState.low : 0}
              </div>
              <div>Low</div>
            </div>
          </div>
        </Typography>
      </Popover>
    </div>
  );
}
