import {
  CHANGE_ALERT_POLICY_SEVERITY,
  CHANGE_ALERT_POLICY_SEVERITY_URL,
} from "../constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";

export async function changeAlertPolicySeverity(id, severity) {
  const options = {
    method: "PUT",
    url: `${CHANGE_ALERT_POLICY_SEVERITY_URL}/${id}/severity/${severity}`,
    headers: getAxiosHeaders(true),
  };

  const response = await getService(options);
  if (response) {
    return response;
  }
}
