import React, { useState, useEffect } from "react";
import "../../../../assets/scss/TenantMangement.scss";
import { ViewMdrIpDetailsConstants } from "../utilities";
import EnhancedGridTable from "../../../shared/Table/Table";
import { EnhancedTableToolbar } from "../Header";
import Page_Loader from "../../../../assets/vizr_images/page_loader.gif";
import { useHistory } from "react-router-dom";
import { getService, getAxiosHeaders } from "../../../../library/RestAPI";
import { GET_IP_URL, Ip } from "../../../../redux/constants/constants";

export const ViewTenantIpDetails = (props) => {
  const { id, name, platformSubdomainNameFrom } = props;
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [loader, setLoader] = useState(false);
  const [IpDetails, setIpDetails] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setLoader(true);
    getIpData();
  }, [currentPage, recordsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getIpData() {
    const data = getAxiosHeaders(true);
    data.Tenant = name;
    data["platform-subdomain-name"] = platformSubdomainNameFrom;

    const res = await getService({
      method: "GET",
      url: `${GET_IP_URL}?page=${currentPage}&size=${recordsPerPage}`,
      headers: data,
    });
    if (res && res.status === 200) {
      setIpDetails(res.data.content);
      setTotalCount(res.data.totalElements);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  async function deleteIpsApi(id) {
    const data = getAxiosHeaders(true);
    data.Tenant = name;
    data["platform-subdomain-name"] = platformSubdomainNameFrom;

    const res = await getService({
      method: "DELETE",
      url: `${GET_IP_URL}/${id}`,
      headers: data,
    });
    if (res && res.status === 200) {
      if (currentPage > 0 && IpDetails.length === 1) {
        setCurrentPage(currentPage - 1);
      } else {
        getIpData();
      }
    } else {
      if (res.response && res.response.status === 401) {
        setLoader(false);
      }
    }
  }

  const deleteIpApi = (id) => {
    setLoader(true);
    deleteIpsApi(id);
  };

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="main">
      {loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )}
      <div className="filter-table-container">
        <div className="table-container">
          <EnhancedTableToolbar
            totalElements={totalCount}
            loc={Ip}
            alertsTitle={"Forward IP/Subnets"}
            helpDescription={"Forward IP/Subnets"}
            id={id}
            name={name}
            platformSubdomainNameFrom={platformSubdomainNameFrom}
          />
          <EnhancedGridTable
            cols={ViewMdrIpDetailsConstants(history, id, deleteIpApi)}
            data={IpDetails}
            totalCount={totalCount}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
          />
        </div>
      </div>
    </div>
  );
};
