import { 
  SELECTED_NODE,
  SET_DATAMAP_TOOLTIP_LOADER,
  SET_DATAMAP_TOOLTIP_DETAILS,
  SET_DATAMAP_MULTI_TOOLTIP_LOADER,
  SET_DATAMAP_MULTI_TOOLTIP_DATA,
  CLOSE_TOOLTIP,
  CLEAR_DATAMAP_MULTI_TOOLTIP,
} from "../constants/constants";

const initialState = {
  selectedNodeId: '',
  selectedNodeSubType: '',
  activeTooltipList: {}
};

export const selectedNodeReducer = (state = initialState, action) => {
  let newTooltipObj = {};
  switch (action.type) {
    case SELECTED_NODE:
      return {
        ...state,
        selectedNodeId: action.payload.node,
        selectedNodeSubType: action.payload.subtype
      };
    case SET_DATAMAP_TOOLTIP_LOADER:
      return {
        ...state,
        nodeTooltipLoader: action.payload
      }
    case SET_DATAMAP_TOOLTIP_DETAILS:
      return {
        ...state,
        nodeTooltipData: action.payload,
      }
    case SET_DATAMAP_MULTI_TOOLTIP_LOADER:
      newTooltipObj = {...state.activeTooltipList};
      newTooltipObj[action.payload.selectedHoverNode.id] = {
        loaderStatus: action.payload.loaderStatus,
        posiCoord: action.payload.posiCoord
      };
      return {
        ...state,
        activeTooltipList: newTooltipObj
      };
    case SET_DATAMAP_MULTI_TOOLTIP_DATA:
      newTooltipObj = {...state.activeTooltipList};
      newTooltipObj[action.payload.selectedHoverNode.id] = {
        loaderStatus: action.payload.loaderStatus,
        data: action.payload.data,
        selectedHoverNode: action.payload.selectedHoverNode,
        posiCoord: action.payload.posiCoord
      };
      return {
        ...state,
        activeTooltipList: newTooltipObj
      }
    case CLOSE_TOOLTIP:
      newTooltipObj = {...state.activeTooltipList};
      delete newTooltipObj[action.payload.node.id]
      return {
        ...state,
        activeTooltipList: newTooltipObj
      }
    case CLEAR_DATAMAP_MULTI_TOOLTIP:
      return {
        ...state,
        activeTooltipList: {}
      }
    default:
      return state;
  }
}
