import React from 'react';

export function DropBox(cubejsApi, data) {
  return (
    <React.Fragment>
      <div className='dropBox'>
        <span>{data}</span>
      </div>
    </React.Fragment>
  );
};