import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import ChartWrapper from "../../../common/Dashboard/Chart";
import { viewDataMap } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../../common/utilities";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  renderNoData,
  renderSingleValue,
} from "../../../common/Dashboard/utils";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  RiskSeverityLookUp,
  riskTypeLookup,
  severityThreatsLookUp,
} from "../riskPilotConstant";

Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const severityLevels = {
  CRITICAL: 4,
  HIGH: 3,
  MEDIUM: 2,
  LOW: 1,
};

const TotalRiskWidget = (cubejsApi, name, query) => {
  const severityColors = {
    CRITICAL: "#cc1c3e",
    HIGH: "#FF2251",
    MEDIUM: "#F68D2B",
    LOW: "#344BFD",
  };

  const renderLegends = (resultSet) => {
    const data = resultSet.loadResponse.results[0].data;

    // Handle no data scenarios
    if (!data || data.length === 0) {
      return renderNoData("30%");
    }

    const processedData = data.reduce((acc, item) => {
      const riskType = item["RiskThreats.riskType"];
      const riskSeverity = item["RiskThreats.severity"];
      const riskLevel = item["RiskThreats.riskLevel"];
      const threatCount = parseInt(item["RiskThreats.threatCount"], 10);

      if (!acc[riskType]) {
        acc[riskType] = {
          counts: {},
          highestSeverity: riskSeverity,
        };
      }

      if (!acc[riskType].counts[riskLevel]) {
        acc[riskType].counts[riskLevel] = 0;
      }

      acc[riskType].counts[riskLevel] += threatCount;

      // Determine the highest severity
      if (
        severityLevels[riskSeverity] >
        severityLevels[acc[riskType].highestSeverity]
      ) {
        acc[riskType].highestSeverity = riskSeverity;
      }

      return acc;
    }, {});

    const labels = Object.keys(processedData).sort((a, b) => {
      return (
        severityLevels[processedData[b].highestSeverity] -
        severityLevels[processedData[a].highestSeverity]
      );
    });


    const datasets = Object.keys(severityColors).map((level) => {
      return {
        label: severityThreatsLookUp[level],
        data: labels.map((label) => processedData[label].counts[level] || 0),
        backgroundColor: severityColors[level],
        borderRadius: 5,
        barThickness: 30,
        barPercentage: 0.3,
        categoryPercentage: 0.9,
      };
    });

    const chartData = {
      labels: labels.map(
        (label) =>
          `${riskTypeLookup[label] || label} (${
            RiskSeverityLookUp[processedData[label].highestSeverity] ||
            processedData[label].highestSeverity
          })`
      ),
      datasets: datasets,
    };

    const options = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          anchor: "center",
          align: "center",
          offset: 0,
          color: "#fff",
          font: {
            weight: "bold",
          },
          formatter: (value) => (value === 0 ? null : value),
        },
      },
      scales: {
        x: {
          stacked: true,
          display: true,
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 20,
            minRotation: 20,
            callback: function (value, index, values) {
              const label = riskTypeLookup[labels[index]];
              if (label.length > 12) {
                return label.substring(0, 12) + "...";
              } else {
                return label;
              }
            },
            color: (context) => {
              const index = context.index;
              const riskType = labels[index];
              const riskSeverity = processedData[riskType].highestSeverity;
              return severityColors[riskSeverity] || "#000";
            },
          },
        },
        y: {
          stacked: true,
          display: false,
          grid: {
            display: false,
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            let label = tooltipItem.label;
            const index = tooltipItem.dataIndex;
            const riskType = labels[index];
            return riskType.length > 15
              ? riskType.substring(0, 15) + "..."
              : riskType;
          },
        },
      },
    };
    return (
      <React.Fragment>
        <hr className="threatLevelChartLine"></hr>
        <div className="barChartParentRiskContainer">
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "15px",
              marginTop: "5%",
            }}
          >
            <Bar
              data={chartData}
              options={options}
              plugins={[ChartDataLabels]}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="threatPieChart totalRisk">
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={name}
        query={query[0]}
        countQuery={query[1]}
        render={renderLegends}
      />
    </div>
  );
};

export default TotalRiskWidget;
