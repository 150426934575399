import React, { useState } from "react";
import "./RiskGovernance.scss";
import EnhancedTable from "../../shared/Table/Table";
import EditRRIcon from "../../../assets/icons/CreateRR.svg";
import { isEmpty, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { GET_RISK_PILOT_ROW_BY_ID, riskTypeIcons } from "./riskPilotConstant";
import { RiskPilotHeadCells } from "./riskPilotUtilities";

function RiskPilotTable({
  tableData,
  currentPage,
  recordsPerPage,
  setCurrentPage,
  setRecordsPerPage,
  isEditMode,
}) {
  const dispatch = useDispatch();

  const totalRiskRegisterCount = useSelector(
    (state) => state.riskPilotReducer.totalRowCount
  );
  const allRiskTypes = useSelector(
    (state) => state.riskPilotReducer.riskTypes
  );

  const renderNull = () => {
    return <>-</>;
  };

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const renderRiskRegisterID = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      return <div title={params}>{params}</div>;
    }
  };

  const renderRiskRegisterName = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      const riskTypeValue = allRiskTypes.filter((risk) => risk.code === params);
      return (
        <div className="riskTypeIconsContainer">
          <div><img src={riskTypeIcons[riskTypeValue[0]?.code]} alt={riskTypeValue[0]?.value}/></div>
          <div title={riskTypeValue[0]?.value}>{riskTypeValue[0]?.value}</div>
        </div>
      );
    }
  };

  const renderRiskSeverity = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      return (
        <div
          className={`riskSeverityContainer ${params}`}
          style={{ marginTop: "0px" }}
        >
          <div className={`riskSeverityValue ${params}`}>{params}</div>
        </div>
      );
    }
  };

  const renderThreats = (params) => {
    if (params === 0) {
      return renderNull();
    } else {
      return <div title={params}>{params}</div>;
    }
  };

  const renderVulnerabilities = (params) => {
    if (params === 0) {
      return renderNull();
    } else {
      return <div title={params}>{params}</div>;
    }
  };

  const renderMitigations = (
    mitigationCount,
    toDoCount,
    openCount,
    inProgressCount,
    closedCount
  ) => {
    if (
      [
        mitigationCount,
        toDoCount,
        openCount,
        inProgressCount,
        closedCount,
      ].every((val) => val === 0)
    ) {
      return renderNull();
    } else {
      const dataWithIcons = [
        {
          count: toDoCount,
          icon: "/riskPilotIcons/ToDoWhite.svg",
          code: "TODO",
        },
        {
          count: openCount,
          icon: "/riskPilotIcons/openWhite.svg",
          code: "OPEN",
        },
        {
          count: inProgressCount,
          icon: "/riskPilotIcons/InProgressWhite.svg",
          code: "IN_PROGRESS",
        },
        {
          count: closedCount,
          icon: "/riskPilotIcons/closedWhite.svg",
          code: "CLOSED",
        },
      ];
      return (
        <div className="mitigationCountContainer">
          <div className="mitigationTotal">{`Total: ${mitigationCount}`}</div>
          <div className="mitigationCountChildContainer">
            {dataWithIcons.map((data) => (
              <div
                className={`mitigationStatusCount ${data.code}`}
                title={data.code}
              >
                <div className="statusCount">
                  {<img src={data.icon} alt={data.code} />}
                  {data.count}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const handleUpdateRow = (params) => {
    isEditMode(true);
    dispatch({ type: GET_RISK_PILOT_ROW_BY_ID, payload: params });
  };

  const renderRegisterAction = (params) => {
    return (
      <div>
        <div>
          <img
            src={EditRRIcon}
            alt="RREditIcon"
            onClick={() => handleUpdateRow(params)}
          />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <EnhancedTable
        cols={RiskPilotHeadCells(
          renderRiskRegisterID,
          renderRiskRegisterName,
          renderRiskSeverity,
          renderThreats,
          renderVulnerabilities,
          renderMitigations,
          renderRegisterAction
        )}
        data={tableData}
        totalCount={totalRiskRegisterCount}
        loc={"mdr-AccountId"}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
      />
    );
  };
  return <>{renderTable()}</>;
}

export default RiskPilotTable;
