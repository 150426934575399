import { put, takeEvery } from "redux-saga/effects";
import { UPDATE_ALERT_STATUS_REQUEST } from "../constants/constants";
import { UPDATE_ALERTS_STATUS_URL } from '../constants/constants';
import {getService,getAxiosHeaders} from "../../library/RestAPI";

function* updateAlertStatus(action) {
    let options;
    let errorInfo;
    const handleServerError = (errorData) =>{
      errorInfo = errorData
    }
    try {
        options = {
            method: "PUT",
            url: `${UPDATE_ALERTS_STATUS_URL}/${action.payload.status}`,
            headers: getAxiosHeaders(true),
            data: action.payload.ids
        };
      try {
       if(action.payload.ids.length !== 0){
        const res = yield getService(options,handleServerError);
        if(res && res.status === 204) {
          yield put({ type: "UPDATE_ALERT_STATUS_SUCCEEDED",payload:action.payload.status});
        } else {
          let errorMessage = ''
          // handling server errors ifor create alert
          if (errorInfo && errorInfo.errorMessage) {
            errorMessage = errorInfo.errorMessage
          }else{
            errorMessage = "Update unsuccessful"
          }
          yield put({
            type: "UPDATE_ALERT_STATUS_FAILED",
            payload: errorMessage,
          });
        }
      }
      } catch (error) {
        console.log(error);
        // myStorage.setItem("access_token", "error");
        yield put({
          type: "UPDATE_ALERT_STATUS_FAILED",
          payload: "Error Fetching Data from API",
        });
      }
    } catch (e) {
      yield put({ type: "UPDATE_ALERT_STATUS_FAILED" });
    }
  }
  
  function* changeAlertStatusSaga() {
    yield takeEvery(UPDATE_ALERT_STATUS_REQUEST, updateAlertStatus);
  }
  
  export default changeAlertStatusSaga;