import React, { useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { searchDbUser } from "../../redux/actions/searchUserAction";
import Avatar from "@material-ui/core/Avatar";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import Button from "@material-ui/core/Button";
import { eachHourOfInterval } from "date-fns/esm";
import { isEmpty } from "lodash";

export default function DbusersComponent(props) {
  const { handleSelectUsers, selectedUsers } =
    props;
  const [Dbusers, setDbusers] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "290px",
      borderLeft: "2px solid grey",
      padding: "10px",
    },
    level: {
      display: "inline-grid",
      marginTop: "15px",
      width: "84%",
    },
    resolve: {
      marginTop: "15px",
      width: "90%",
    },
    ul: {
      listStyleType: "none",
    },
    li: {
      paddingBottom: "8px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.14",
      letterSpacing: "normal",
      color: "black",
    },
    FilterHead: {
      fontSize: "12px",
      lineHeight: 1.22,
      color: "black",
      textAlign: "left",
    },
    paper: {
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    popperContainer: {
      height: "200%",
      width: "255px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 6px 8px 0 rgba(0,0,0,0.2)",
    },
    list: {
      listStyle: "none",
      paddingTop: "25px",
      marginRight: "25px",
    },
    listFlex: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: "12px",
    },
    listFlexImage: {
      cursor: "pointer",
      marginLeft: "10px",
    },
    button: {
      width: "15px !important",
      minWidth: "15px !important",
      height: "20px",
      borderRadius: "50px",
      backgroundColor: "#FFF",
    },
  }));
  const classes = useStyles();

  const handleClick = (event, user) => {
    const selectedIndex = selectedUsers.findIndex(
      (each) => each === user
    );
    let newSelected = [];
    let temp = user;
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, temp);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }
    // handle selected users for access log
    handleSelectUsers(newSelected, user);
  };
  const removeMonitor = (event, user) => {
    const selectedIndex = selectedUsers.findIndex(
      (each) => each === user
    );
    let newSelected = [];

    if (selectedIndex > -1) {
      newSelected = selectedUsers.filter(
        (each) => each !== user
      );
    }
    // handle selected users for access log
    handleSelectUsers(newSelected, user);
  };
  const findUser = (event) => {
    async function searchAction(event) {
      if (event) {
        const response = await searchDbUser(event);
        if (response && !isEmpty(response.content)) {
          setDataAvailable(true);
          setDbusers(response.content);
        } else {
          setDataAvailable(false);
          setErrorInfo("No Results");
        }
      }
    }
    if (event) {
      searchAction(event.target.value);
    }
  };

  return (
    <FormControl component="fieldset" className={classes.root}>
      <div style={{ display: "flex", paddingBottom: "10px" }}>
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          style={{ borderBottom: "2px solid gray", width: "100%" }}
          placeholder="DB Users"
          onChange={(event) => findUser(event)}
          inputProps={{ "aria-label": "search" }}
        />
      </div>{
        !dataAvailable ? <span className="errorInfo">{errorInfo}</span> :
          <FormGroup aria-label="position" row>
            <div className="monitors">
              {selectedUsers.map((each, index) => {
                return (
                  <div style={{ display: "flex" }} key={`${index}-added`}>
                    <div style={{ display: "inherit", width: "220px" }}>
                      <Avatar
                        className="avatar"
                        alt={eachHourOfInterval}
                      />
                      <span style={{ display: "grid", paddingLeft: "10px" }}>
                        <label>{each}</label>
                      </span>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        className={classes.button}
                        startIcon={
                          <IndeterminateCheckBoxIcon
                            className={classes.listFlexImage}
                            style={{
                              height: "17.92px",
                              width: "17.92px",
                              color: "red",
                            }}
                            onClick={(event) => removeMonitor(event, each)}
                          ></IndeterminateCheckBoxIcon>
                        }
                      ></Button>
                    </div>
                  </div>
                );
              })}
              {Dbusers.map((each, index) => {
                if (
                  selectedUsers.findIndex(
                    (item) => item === each
                  ) === -1
                ) {
                  return (
                    <div style={{ display: "flex" }} key={`${index}-add`}>
                      <div style={{ display: "inherit", width: "220px" }}>
                        <Avatar
                          className="avatar"
                          alt={each}
                        />
                        <span style={{ display: "grid", paddingLeft: "10px" }}>
                          <label>{each}</label>
                        </span>
                      </div>
                      <div>
                        <IconButton onClick={(event) => handleClick(event, each)}>
                          <AddCircleOutlineOutlinedIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </FormGroup>
      }
    </FormControl>
  );
}
