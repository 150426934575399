import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const MDRSortedBarCharts = ({ data, query, type }) => {
  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);
    console.log(type);
    const getXValueAxis = () => {
      if (type === "patchesByTop10Servers") {
        return "Patches";
      } else if (type === "serversByTop10KBID") {
        return "Servers";
      } else if (type === "MDREndpoints") {
        return "Score";
      } else if (type === "reasonsForLoginFailed") {
        return "Failed Logins";
      } else if (type === "usersLoginFailed") {
        return "Failed Logins";
      } else {
        return "";
      }
    };
    const getYValueAxis = () => {
      if (type === "patchesByTop10Servers") {
        return "Servers";
      } else if (type === "serversByTop10KBID") {
        return "KBIDs";
      } else if (type === "MDREndpoints") {
        return "Devices";
      } else if (type === "reasonsForLoginFailed") {
        return "Reasons";
      } else if (type === "usersLoginFailed") {
        return "Username";
      } else {
        return "";
      }
    };
    let chart = am4core.create(`${type}`, am4charts.XYChart);
    chart.padding(40, 40, 40, 40);

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "network";
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.title.text = getYValueAxis();

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = getXValueAxis();

    let series = chart.series.push(new am4charts.ColumnSeries());
    // let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    // valueLabel.label.text = "Hello";
    // valueLabel.label.fontSize = 20;
    series.dataFields.categoryY = "network";
    series.dataFields.valueX = "MAU";
    series.tooltipText = "{valueX.value}";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusBottomRight = 5;
    series.columns.template.column.cornerRadiusTopRight = 5;

    var columnTemplate = series.columns.template;
    // columnTemplate.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    // columnTemplate.fillOpacity = 0.8;
    // columnTemplate.strokeOpacity = 0;
    columnTemplate.fill = am4core.color("#264479");

    // let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    // labelBullet.label.horizontalCenter = "left";
    // labelBullet.label.fill = "white";
    // labelBullet.label.dx = 10;
    // labelBullet.label.text = "{name}";
    // labelBullet.locationX = 1;

    var valueLabel = series.columns.template.createChild(am4core.Label);
    valueLabel.text = "{name}";
    valueLabel.fontSize = 12;
    valueLabel.fontWeight = 20;
    valueLabel.valign = "middle";
    valueLabel.fill = "#AFACAC";
    valueLabel.dx = 10;
    // valueLabel.strokeWidth = 400;

    // var valueLabel = series.bullets.push(new am4charts.LabelBullet());
    // valueLabel.label.text = "{name}";
    // valueLabel.label.fill = "lightgray";
    // valueLabel.label.fontSize = 12;
    // // valueLabel.label.width = 10;
    // valueLabel.label.truncate = false;
    // // valueLabel.label.hideOversized = false;
    // // valueLabel.label.horizontalCenter = "right";
    // // valueLabel.label.dx = -100;
    // // valueLabel.locationX = 0;
    // chart.maskBullets = false;

    let updatedData = [];

    updatedData =
      data.length > 0 &&
      data.map((el, index) => {
        let count;
        if (type === "MDREndpoints") {
          count = el[`${query["dimensions"][1]}`];
        } else {
          count = el[`${query["measures"][0]}`];
        }
        return {
          network: index + 1,
          MAU: count,
          name: el[`${query["dimensions"][0]}`],
          // color: "red",
        };
      });

    chart.data = updatedData;

    return () => {
      chart.dispose();
    };
  }, []);
  return (
    <>
      <div id={type} style={{ width: "100%", height: "400px" }} />
    </>
  );
};

export default MDRSortedBarCharts;
