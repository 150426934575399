import React from "react";
import RiskPlotIcon from "../../../assets/icons/RiskRegisterIcon.svg";
import { Link } from "react-router-dom";
import { activeRoutes } from "../../common/utilities";

// Define a functional component named ManageRiskPlot which accepts activeRoutes and location as props
function ManageRiskPilot({ location }) {
  return (
    // Link component to navigate to the RiskPlot page when clicked
    <Link
      className="nav-link"
      to={"/mdr/RiskPilot"}
      activeClassName="active"
    >
      {/* Display the RiskPlotIcon image; activeRoutes function determines which icon to use based on the current location */}
      <img
        src={activeRoutes(
          location,
          "/mdr/RiskPilot",
          RiskPlotIcon,
          RiskPlotIcon
        )}
        alt={"RiskRegisterIcon"} 
      />
      {/* Display the text 'Risk Plot'; the text styling changes based on whether the current path matches "/mdr/RiskPilot" */}
      <p
        className={
          location &&
          location.pathname.indexOf("/mdr/RiskPilot") > -1
            ? "active side-bar-para mdr-para"
            : "side-bar-para mdr-para"
        }
      >
        Risk Pilot
      </p>
    </Link>
  );
}

export default ManageRiskPilot;

