import React from "react";
import Grid from "@material-ui/core/Grid";

import img from "../../../assets/signinpage/Bitmap-1.png";
// import druvlogo from "../../../assets/signinpage/druvstarLogoFinal.svg";
import druvlogo from "../../../assets/vizr_images/whiteLogo.svg";
// import Background from "../../../assets/signinpage/background.png";
import BackgroundImage from "../../../assets/vizr_images/backgroundForgotPassword.svg";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import "../../../assets/scss/admin-styles/custom/loginpagestyle.scss";
import "./forgotpassword.scss";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  forgotPassword_container_leftText: {
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "19px",
    marginTop: "3rem",
  },
  forgotPasswordcontainer_leftFlex: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // paddingTop: "12rem",
    marginLeft: "3rem",
    textAlign: "center",
    maxWidth: "15rem",
  },
});

const ForgotPasswordLeft = () => {
  const classes = useStyles();
  return (
    <Grid item xs={5} md={5} lg={5} className="forgotPasswordcontainer_left">
      <div className={classes.forgotPasswordcontainer_leftFlex}>
        <img src={druvlogo} alt="DVlogo" style={{ marginTop: "10rem" }}></img>
        <div>
          <p
            style={{
              maxWidth: "15rem",
              color: "white",
              height: "100px",
              marginTop: "5rem",
              fontFamily: "Nunito-Regular",
            }}
          >
            DruvStar protects your business and infrastructure with proactive,
            data-driven and modern security
          </p>
        </div>
      </div>
    </Grid>
  );
};

export default ForgotPasswordLeft;
