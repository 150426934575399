import {  GET_ALERT_POLICIES_URL } from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function sortAlertsPolicies(sortBy,page,rowsPerPage) {
    //console.log(severity)
    let options;
    let paramData = {};
    
    if(sortBy !== ''){
        paramData.sortBy = sortBy;
        paramData.direction = 'ASC'
    }
    options = {
        method: "GET",
        url: `${GET_ALERT_POLICIES_URL}?size=${rowsPerPage}&page=${page}`,
        params: paramData,
        headers: getAxiosHeaders(true),
    };
    const response = await getService(options);
    if(response) {
        //window.location.reload(false);
        return response.data
    }
};
