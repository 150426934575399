import {  put, takeEvery } from "redux-saga/effects";
import { CREATE_ALERT_POLICY_REQUEST } from "../constants/constants";
import { CREATE_ALERT_POLICY_URL } from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

function* createAlertPolicy(action) {
  const tenant = window.localStorage.getItem("tenant");
  let options;
  let errorInfo;
  const handleServerError = (errorData) =>{
    errorInfo = errorData
  }
  try {
    options = {
      method: "POST",
      url: `${CREATE_ALERT_POLICY_URL}`,
      headers: getAxiosHeaders(true),
      data: action.payload.data,
    };
    try {
      const res = yield getService(options,handleServerError);
      if (res && res.status === 201) {
        yield put({ type: "CREATE_ALERT_POLICY_SUCCEEDED" });
        yield put({ type: "TRACK_TRAVERSAL", payload: "Alert Policies" });
        action.payload.history.push(`/tenant/alertPolicies`);
      } else {
              // myStorage.setItem("access_token", "error");
      let errorMessage = ''
      // handling server errors ifor create alert
      if (errorInfo && errorInfo.errorMessage) {
        errorMessage = errorInfo.errorMessage
      } else if (errorInfo && errorInfo.fieldErrors) {
        errorInfo.fieldErrors.map((error) => {
          errorMessage = errorMessage + error.errorMessage
        }
        )
      } else {
        errorMessage = "Error Fetching Data from API"
      }
      yield put({
        type: "CREATE_ALERT_POLICY_FAILED",
        payload: errorMessage,
      });
      }
    } catch (error) {
      yield put({ type: "CREATE_ALERT_POLICY_FAILED" });
    }
  } catch (e) {
    yield put({ type: "CREATE_ALERT_POLICY_FAILED" });
  }
}

function* createAlertPolicySaga() {
  yield takeEvery(CREATE_ALERT_POLICY_REQUEST, createAlertPolicy);
}

export default createAlertPolicySaga;
