import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AlertPolicies from "../../views/alertPolicies/AlertPolicies";
import Alerts from "../../views/Alerts";
import Dashboard from "../../views/Dashboard";
//import DashboardEdit from "./components/views/DashboardEdit";
import DataMap from "../../views/DataMap";
import DataFlows from "../../views/DataFlows";
import DataFlowGroups from "../../views/DataFlowGroups";
import IntegrationsHealth from "../../views/IntegrationsHealth";
import AgentDownload from "../../views/DevopsUser/AgentDownload";
import SystemSummary from "../../views/SystemSummary";
import Sidebar from "../../common/Sidebar/Sidebar";
import AdminNavbar from "../../common/Navbar/AdminNavbar";
import { Route, Switch } from "react-router-dom";
import routes from "../../../routes.js";
import AlertPolicyCreateEdit from "../../views/alertPolicyCreateEdit";
import CreateAgent from "../../views/platformSettings/CreateAgent";
import ReviewDataSources from "../../views/reviewDataSources";
import InitialSetUpDevops from "../../views/initialSetUpDevops";
import UserManagement from "../../views/UserMangement/UserManagement";
import TenantManagement from "../../views/TenantManagement/TenantManagement";
// import PlatformManagement from "../../views/PlatformManagement/PlatformManagement";
import PlatformSettings from "../../views/platformSettings/platformSettings";
import CreateUser from "../../views/CreateUser/CreateUser";
import UserAccessRights from "../../views/userAccessRights/UserAccessRights";
import EditUsers from "../../views/EditUser/EditUsers";
import TenenantSignUp from "../../views/TenantSignUp/TenenantSignUp";
import CreateRoles from "../../views/CreateRoles/CreateRoles";
import ProfileEditing from "../../views/ProfileEditing/ProfileEditing";
import DatabaseLogs from "../../views/DatabaseLogs/dataBaseLogs";
import FileList from "../../shared/fileList"
import {
  viewAlertPolicy,
  updateAlert,
  viewAlert,
  viewDataSource,
  addAgent,
  createAlertPolicy,
  updateAlertPolicy,
  viewTenantUser,
  viewAgentDefinition,
  addTenantUser,
  updateTenantUser,
  addTenant,
  addPlatformUser,
  updateTenant,
  viewDataMap,
  viewDataFlow,
  viewDataFlowGroup,
  viewTenants,
  viewPlatformUser,
  updatePlatformUser,
  reviewDataSource,
  addTenantRole,
  createAgent,
  viewSetting,
  viewAgent,
  addAgentType,
  VIEW_AGENT_PLATFORM_TYPE,
  VIEW_PLATFORM_ROLE,
  viewAgentType,
  VIEW_ENTERPRISE_ACTORS,
  viewDatabaseLogs,
  attachEdition,
  EXTERNAL_VIEW_REPORT_URL,
  generateReport,
  fetchReport,
  viewReportDetails,
  platformNames
} from "../../../redux/constants/constants";
import { KeyboardReturnOutlined } from "@material-ui/icons";
import {
  UPDATE_ALERT_COUNT,
  GET_ALERTS_URL,
  Create_Endpoint_Settings,
  Create_Ip_Settings,
  Create_Concentrator_Settings,
} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { getUserPreferences } from "../../../redux/actions/getUserPreferences";
import { useLocation } from "react-router-dom";
import { cloneDeep, isEmpty } from "lodash";
import { CreateDataSourceUser } from "../../views/userAccessRights/createDataSourceUser/CreateDataSourceUser";
import { AddConcentrator } from "../../views/MdrConcentrator/CreateConcentrator/CreateConcentrator";
import { AddIp } from "../../views/MdrConcentrator/addIp/AddIp";
import { AddEndpoints } from "../../views/MdrConcentrator/addEndpoints/AddEndpoints";
import DataOwner from "../../shared/DataOwner";
import GenerateReport from "../../views/GenerateReport/GenerateReport";
import ReportRepository from "../../views/GenerateReport/ReportRepository";
import ReportPage from "../../views/GenerateReport/ReportPage";
import NoPermissionView from "../../views/NoPermission/NoPermissionView";
//get druvstar logo import logo from"

export default function MainContainer() {
  const { backgroundColor, setBackgroundColor } = useState("blue");
  const [sidebarLinks, setSidebarLinks] = useState([]);
  const [expand, setExpand] = useState(true);
  const permissions = useSelector((state) => state.applicationData.permissions);
  const dispatch = useDispatch();
  const tenant = window.localStorage.getItem("tenant");
  const location = useLocation();
  const activityReportView = useSelector(
    (state) => state.GenerateReportReducer.activityReportView
  );

  useEffect(() => {
    const routesData = cloneDeep(routes);
    if (routesData.length > 0) {
      let temp = [];
      routesData.map((each) => {
        let obj = each;
        let str;
        if (
          obj.condition === addPlatformUser ||
          obj.condition === addTenant ||
          obj.condition === viewSetting ||
          obj.condition === attachEdition
        ) {
          str = `/platform${obj.path}`;
        } else {
          if (each.innerLinks) {
            each.innerLinks.map((item, index) => {
              let str1 = `/tenant${item.path}`;
              obj.innerLinks[index].path = str1;
              return null;
            });
          } else {
            str = `/tenant${obj.path}`;
          }
        }
        obj.path = str;
        temp.push(obj);
        return null;
      });

      setSidebarLinks(temp);
    }
    return () => {
      setSidebarLinks([]);
    };
  }, [routes]);

  const handleShrink = () => {
    setExpand((prevState) => !prevState);
  };

  const getRoutes = () => {
    return permissions.map((each, key) => {
      if (each === viewAlertPolicy) {
        return [
          <Route
            exact
            path={`/tenant/alertPolicies`}
            component={AlertPolicies}
            key={`${key}_1`}
          />,
          <Route
            exact
            path={`/tenant/alertPolicies/:id`}
            component={AlertPolicyCreateEdit}
            key={`${key}_2`}
          />,
        ];
      } else if (each === viewAlert) {
        return (
          <Route exact path={`/tenant/alerts`} component={Alerts} key={key} />
        );
      } else if (each === viewDataSource) {
        return [
          <Route
            exact
            path={`/tenant/systemSummary`}
            component={SystemSummary}
            key={`${key}_1`}
          />,
          <Route
            exact
            path={`/tenant/reviewDataSources`}
            component={ReviewDataSources}
            key={`${key}_2`}
          />,
        ];
      } else if (each === addAgent) {
        return (
          <Route
            exact
            path={`/tenant/initialSetup`}
            component={InitialSetUpDevops}
            key={key}
          />
        );
      } else if (each === viewDataMap) {
        return [
          <Route
            exact
            path={`/tenant/dataMap`}
            component={DataMap}
            key={`${key}_1`}
          />,
          <Route
            exact
            path={`/tenant/fileList`}
            component={FileList}
            key={`${key}_2`}
          />,
        ];
      } else if (each === viewDataFlow) {
        return (
          <Route
            exact
            path={`/tenant/dataFlows`}
            component={DataFlows}
            key={key}
          />
        );
      } else if (each === viewDataFlowGroup) {
        return (
          <Route
            exact
            path={`/tenant/dataFlowGroups`}
            component={DataFlowGroups}
            key={key}
          />
        );
      } else if (each === createAlertPolicy) {
        return (
          <Route
            exact
            path={`/tenant/alertPolicies/createPolicy`}
            component={AlertPolicyCreateEdit}
            key={key}
          />
        );
      } else if (each === viewTenantUser) {
        return (
          <Route
            exact
            path={`/tenant/userManagement`}
            component={UserManagement}
            key={key}
          />
        );
      } else if (each === viewAgentDefinition) {
        return (
          <Route
            exact
            path={`/tenant/platformManagement`}
            component={CreateUser}
            key={key}
          />
        ); //CHANGE THE COMPONENT
      } else if (each === addTenantUser) {
        return (
          <Route
            exact
            path={`/tenant/userManagement/createUsers`}
            component={CreateUser}
            key="create"
          />
        );
      } else if (each === updateTenantUser) {
        return (
          <Route
            exact
            path={`/tenant/userManagement/editUser/:id`}
            component={EditUsers}
          />
        );
      } else if (each === viewTenants) {
        if (tenant === "druvstar" || tenant === "datavision") {
          return (
            <Route
              exact
              path={`/platform/tenantManagement`}
              component={TenantManagement}
            />
          );
        }
      } else if (each === viewPlatformUser) {
        return (
          <Route
            exact
            path={`/platform/userManagement`}
            component={UserManagement}
          />
        );
      } else if (each === addAgentType || each === VIEW_AGENT_PLATFORM_TYPE) {
        return (
          <Route
            exact
            path={`/platform/platformSettings`}
            component={PlatformSettings}
            key={key}
          />
        ); //CHANGE THE COMPONENT
      } else if (each === addPlatformUser) {
        return (
          <Route
            exact
            path={`/platform/userManagement/createUsers`}
            component={CreateUser}
          />
        );
      } else if (each === updatePlatformUser) {
        return (
          <Route
            exact
            path={`/platform/userManagement/editUser/:id`}
            component={EditUsers}
          />
        );
      } else if (each === addTenant) {
        if (tenant === "druvstar" || tenant === "datavision") {
          return (
            <Route
              exact
              path={`/platform/tenantManagement/createTenant`}
              component={TenenantSignUp}
            />
          );
        }
      } else if (each === updateTenant) {
        if (tenant === "druvstar" || tenant === "datavision") {
          return (
            <Route
              exact
              path={`/platform/tenantManagement/:id`}
              component={TenenantSignUp}
            />
          );
        }
      } else if (each === addTenantRole) {
        return (
          <Route
            exact
            path={`/tenant/userManagement/createRoles`}
            component={CreateRoles}
          />
        );
      } else if (each === viewAgentType) {
        return (
          <Route
            exact
            path={`/platform/platformSettings/createAgent`}
            component={CreateAgent}
          />
        );
      } else if (each === VIEW_ENTERPRISE_ACTORS) {
        return (
          <Route
            exact
            path={`/tenant/useraccessRights`}
            component={UserAccessRights}
          />
        );
      } else if (each === viewDatabaseLogs) {
        return (
          <Route
            exact
            path={`/tenant/databaseLogs`}
            component={DatabaseLogs}
            key={key}
          />
        );
      } else if (each === "PLATFORM_MDR_CONFIG") {
        return [
          <Route
            exact
            path={Create_Concentrator_Settings}
            component={AddConcentrator}
            key={`${key}_1`}
          />,
          <Route
            exact
            path={Create_Ip_Settings}
            component={AddIp}
            key={`${key}_2`}
          />,
        ];
      } else if (each === generateReport) {
        return (
          <Route
            exact
            path={`/tenant/generateReport`}
            component={GenerateReport}
          />
        );
      } else if (each === fetchReport) {
        return (
          <Route
            exact
            path={`/tenant/reportRepository`}
            component={ReportRepository}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    async function getAlerts() {
      let response;
      let responseData;
      await Promise.all([
        getService({
          method: "GET",
          url: `${GET_ALERTS_URL}?size=5&page=0`,
          headers: getAxiosHeaders(true),
        }),
        getUserPreferences(),
      ]).then((data) => ((response = data[0]), (responseData = data[1])));

      if (response && response.data && responseData) {
        dispatch({
          type: UPDATE_ALERT_COUNT,
          data: {
            alertCount:
              response.data.totalElements -
              (responseData && responseData.alertsVisted
                ? responseData.alertsVisted
                : 0),
            totalCount: response.data.totalElements,
          },
        });
      }
    }
    if (!platformNames.includes(tenant)) {
      getAlerts();
    }
  }, [location]);

  // Function to check if a dynamic path matches a given pathname
  const isDynamicMatch = (path, pathname) => {
    // Split the route path and current pathname into segments
    const pathSegments = path.split("/");
    const pathnameSegments = pathname.split("/");

    // Check if the number of segments in the route path matches the number of segments in the pathname
    if (pathSegments.length !== pathnameSegments.length) {
      return false;
    }

    // Iterate over each segment in the route path and check if it matches the corresponding segment in the pathname
    return pathSegments.every(
      (segment, index) =>
        segment === pathnameSegments[index] ||
        (segment === ":id" && !isEmpty(pathnameSegments[index].trim()))
    );
  };

  /**
   * renderComponent function determines the appropriate route to render based on the current location pathname.
   * It first retrieves routes using getRoutes(), filters out falsy values (e.g., null), and flattens the resulting array.
   * Subsequently, it searches for a matching route within the flattened array.
   *
   * If a matching route is found, it is returned; otherwise, NoPermissionView is returned to handle cases with no matches.
   *
   * @returns {JSX.Element} The React component to render based on the matched route or NoPermissionView.
   */
  const renderComponent = () => {
    // Get routes, filter out falsy values (e.g., null), then flatten the array
    const routes = getRoutes().flat().filter(Boolean);

    // Find the first route that matches the current location
    const matchedRoute = routes.find((route) => {
      // Extract the path from the route's props
      const path = route.props.path;
      // Check for an exact match or a dynamic match
      return (
        path === location.pathname || isDynamicMatch(path, location.pathname)
      );
    });

    // Return the matched route or NoPermissionView if none is found
    return matchedRoute || NoPermissionView();
  };

  // Use seperate route for report view as it should not include sidebar
  const renderReportView = () => {
    // Need VIEW_REPORT permission to view the report
    const viewReport = permissions.includes(viewReportDetails);
    if (viewReport) {
      return (
        <div>
          <Switch>
            <Route
              exact
              path={EXTERNAL_VIEW_REPORT_URL}
              component={ReportPage}
            />
          </Switch>
        </div>
      );
    } else {
      // need to add no view permission page here
      return null;
    }
  };

  const renderNormalView = () => {
    return (
      <>
        <Sidebar
          routes={sidebarLinks}
          bgColor={backgroundColor}
          expand={expand}
          handleShrink={handleShrink}
        />
        <div
          className={expand ? "main-panel" : "main-panel shrunk"}
          data={backgroundColor}
        >
          <AdminNavbar />
          <Switch>
            <Route
              exact
              path={`/tenant/useraccessRights/createDataSourceUser`}
              component={CreateDataSourceUser}
            />
            <Route exact path={`/tenant/dashboard`} component={Dashboard} />
            <Route exact path={`/tenant/dataOwner`} component={DataOwner} />
            <Route
              exact
              path={`/tenant/integrations`}
              component={IntegrationsHealth}
            />
            );
            <Route
              exact
              path={Create_Endpoint_Settings}
              component={AddEndpoints}
            />
            );
            {/* <Route exact path={`/`} component={ErrorPage} /> */}
            <Route
              path={`/platform/profileEditing`}
              component={ProfileEditing}
            />
            <Route path={`/tenant/profileEditing`} component={ProfileEditing} />
            <Route
              path="/tenant/initialSetup/agentDownload"
              component={AgentDownload}
            ></Route>
            <Route
              exact
              path={`/tenant/alertPolicies/:id/:clonePolicy`}
              component={AlertPolicyCreateEdit}
              // key={key}
            />
            {/* <Route exact path={`/tenant/dataSources`} component={DataSources} key="review" /> */}
            {renderComponent()}
          </Switch>
        </div>
      </>
    );
  };

  const renderView = () => {
    if (location.pathname === EXTERNAL_VIEW_REPORT_URL) {
      return renderReportView();
    } else {
      return renderNormalView();
    }
  };

  return (
    <>
      <div className="wrapper">{renderView()}</div>
    </>
  );
}
