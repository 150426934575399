import { GET_ALERTS_URL, GET_DATASOURCE_URL } from "../constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";
import { SERVICE_LEVEL_ACCESS, LOADING_DATA } from "../constants/constants";
import { find } from "lodash";
//dispatch add method when use of Saga
export async function fetchAllUnResolvedAlerts(
  pageSize,
  currentPage,
  sortBy,
  severity,
  resolution,
  policy,
  policyId,
  sortOrder,
  startTime,
  endTime
) {
  let sev = "";
  let res = "";
  let name = "";
  let paramData = {};
  if (severity && severity.length > 0) {
    severity.forEach((each) => {
      sev = sev + `${each},`;
    });
    sev = sev.slice(0, -1);
    paramData.severity = sev;
  }
  if (resolution && resolution.length > 0) {
    resolution.forEach((each) => {
      res = res + `${each},`;
    });
    res = res.slice(0, -1);
    paramData.resolutionType = res;
  }
  if (policy && policy.length > 0) {
    policy.forEach((each) => {
      name = name + `${each},`;
    });
    name = name.slice(0, -1);
    paramData.policy = name;
  }
  if (sortBy && sortBy !== "") {
    paramData.sortBy = sortBy;
  }
  if (sortOrder && sortOrder !== "") {
    paramData.direction = sortOrder;
  }
  if (policyId) {
    paramData.policy = policyId;
  }
  if (startTime && endTime) {
    paramData.from = startTime.getTime();
    paramData.to = endTime.getTime();
  }
  const options = {
    method: "GET",
    url: `${GET_ALERTS_URL}?size=${pageSize}&page=${currentPage}&status=UNRESOLVED`,
    params: paramData,
    headers: getAxiosHeaders(true),
    data: {},
  };
  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

/**
 * processAlerts get list of service alerts request with data source as loading
 * also gets direct data alerts for instant loading in table
 * @param alertList
 * @returns
 */
export function processAlerts(alertList) {
  const serviceAlertsRequest = [];
  const alertsWithLoader = [];
  for (let index = 0; index < alertList.length; index++) {
    const alert = alertList[index];
    if (
      alert.alertPolicy &&
      alert.alertPolicy.type.code === SERVICE_LEVEL_ACCESS
    ) {
      const config = JSON.parse(alert.configuration);
      if (config) {
        serviceAlertsRequest.push({
          id: alert.id,
          startTime: config.start_time,
          endTime: config.end_time,
          dataSources: [],
        });
        alert.dataSource = LOADING_DATA;
      }
      alertsWithLoader.push(alert);
    } else {
      alertsWithLoader.push(alert);
    }
  }
  return { serviceAlertsRequest, alertsWithLoader };
}

/**
 * Get data source for service alerts
 * @param serviceAlertReq
 * @returns
 */
export async function getDataSourceNamesForAlerts(serviceAlertReq) {
  const options = {
    method: "POST",
    url: `${GET_DATASOURCE_URL}`,
    headers: getAxiosHeaders(true),
    data: serviceAlertReq,
  };
  const response = await getService(options);
  if (response) {
    return response.data;
  }
}

/**
 * merge service alert and direct data alert with concat of data source
 * @param alertsWithLoader
 * @param serviceAlerts
 * @returns
 */
export function mergeDataSourceIntoAlerts(alertsWithLoader, serviceAlerts) {
  const alerts = [...alertsWithLoader];
  serviceAlerts.forEach((serviceAlert) => {
    const alert = find(alerts, { id: serviceAlert.id });
    alert.dataSource = {
      aliasName: serviceAlert.dataSources.map((d) => d.aliasName).join(" , "),
    };
  });
  return alerts;
}
