import React, { Component, Fragment } from "react";
import Graph from "vis-react";
import ToolTip from "./Tooltip";
import {
  staticGraphOptions,
} from "../../redux/constants/staticGraphList";
import { getUserPreferences } from "../../redux/actions/getUserPreferences";
import { updateUserPreferences } from "../../redux/actions/updateUserPreferences";

import { isEmpty, findIndex } from "lodash";
import { ENABLE_NODE_TOOLTIP } from '../../redux/constants/constants';
import { connect } from 'react-redux';
const options = staticGraphOptions;

class GraphViewVis extends Component {
  validateIsPositionChange(node1, node2) {
    return (node1.x !== node2.x || node1.y !== node2.y);
  }
  componentWillMount() {
    this.mounted = true;
  }
  componentDidUpdate(prevProps) {
    const addNodes = [];
    const removeNodes = [];
    const updateNodes = [];
    const addEdge = [];
    const removeEdge = [];

    this.props.graphNodesList.forEach((node, i) => {
      const earlierIdx = findIndex(prevProps.graphNodesList, (o) => { return o.id === node.id })
      if (earlierIdx === -1) {
        addNodes.push(node);
      } else {
        // found the node check if position is changed
        if (this.validateIsPositionChange(node, prevProps.graphNodesList[earlierIdx])) {
          //position is changed
          updateNodes.push(node);
        }
      }
    });

    prevProps.graphNodesList.forEach((node, i) => {
      const earlierIdx = findIndex(this.props.graphNodesList, (o) => { return o.id === node.id })
      if (earlierIdx === -1) {
        removeNodes.push(node);
      }
    });

    this.props.graphEdgesList.forEach((edge, i) => {
      const earlierIdx = findIndex(prevProps.graphEdgesList, (o) => { return o.id === edge.id })
      if (earlierIdx === -1) {
        addEdge.push(edge);
      }
    });

    prevProps.graphEdgesList.forEach((edge, i) => {
      const earlierIdx = findIndex(this.props.graphNodesList, (o) => { return o.id === edge.id })
      if (earlierIdx === -1) {
        removeEdge.push(edge);
      }
    });

    if (!isEmpty(addNodes)) {
      this.graphRef.nodes.add(addNodes);
    }
    if (!isEmpty(removeNodes)) {
      this.graphRef.nodes.remove(removeNodes);
    }
    if (!isEmpty(addEdge)) {
      this.graphRef.edges.add(addEdge);
    }
    if (!isEmpty(removeEdge)) {
      this.graphRef.nodes.remove(removeEdge);
    }
    if (!isEmpty(updateNodes)) {
      this.graphRef.nodes.update(updateNodes);
    }
  }

  constructor(props) {
    super(props);
    this.graphRef = React.createRef();
    this.graphPosition = {};
    this.state = {
      initialRender: false,
      toolTipPop: null,
      selectedHoverNode: null,
      posiCoord: {
        x: 50,
        y: 50,
      },
      graph: {
        nodes: this.props.graphNodesList,
        edges: this.props.graphEdgesList ? this.props.graphEdgesList : [],
      },
      style: { width: "100%", height: "800px" },
      network: null,
      toolTipPopEnable: false,
    };

    this.events = {
      select: (event) => this.nodeSelectFun(event, this),
      afterDrawing: () => {
        this.graphPosition = this.graphRef.Network.getViewPosition();
      },
      zoom: () => {
        //used to limit the zoom level
        if (this.graphRef.Network.getScale() <= 0.4) {
          this.graphRef.Network.moveTo({
            scale: 0.4,
            position: this.graphPosition,
          });
        }
      },
      dragEnd: function (event) {
        let nodeInfo = this.graphRef.Network.getPositions();
        // gettings all node positions data and updating in prefernce object
        async function getPreferences(that) {
          const response = await getUserPreferences();
          let positions = [];
          for (const [key, value] of Object.entries(nodeInfo)) {
            positions.push({ node: key, x: value.x, y: value.y });
          }
          async function savePreferences(preferences) {
            const response = await updateUserPreferences({
              preferences: JSON.stringify(preferences),
            });
            if (response) {
              that.props.updateStoreForPositions(response.nodePositions, this);
            }
          }
          if (response) {
            let preferences = {
              historyList: response.historyList ? response.historyList : [],
              dateTimeFilters: response.dateFilters ? response.dateFilters : {},
              timezone: response.timezone ? response.timezone : "",
              nodePositions: positions && positions.length > 0 ? positions : [],
              alertsVisted: response.alertsVisted ? response.alertsVisted : 0,
            };
            savePreferences(preferences);
          }
        }
        // Not updating nodes of search of datamap
        if (
          !(
            this.props.selectedApp &&
            this.props.selectedApp.app &&
            this.props.selectedApp.app.id
          )
        )
          getPreferences(this);
      },
    };

    this.events.dragEnd = this.events.dragEnd.bind(this);
  }

  nodeSelectFun(event, that) {
    let { pointer } = event;

    let findPos = that.props.graphNodesList.find(
      (node) => node.id === event.nodes[0]
    );
    if (findPos) {
      const action = {
        type: findPos.type,
        posiCoord: { x: pointer.DOM.x, y: pointer.DOM.y },
        selectedHoverNode: findPos,
        node: event.nodes[0],
        subType: (findPos && findPos.subType)? findPos.subType.code : null,
        alertCount: (findPos && findPos.uiConfiguration)? findPos.uiConfiguration.alert_count : null,
      };
      this.props.enableNodeTooltip(action);

      if (findPos.type === "USER") {
        that.setState({
          posiCoord: { x: pointer.DOM.x, y: (pointer.DOM.y + 30) },
          selectedHoverNode: findPos,
          toolTipPop: true,
          toolTipPopEnable: true,
          node: event.nodes[0],
        });
      }
      else {
        that.setState({
          posiCoord: { x: pointer.DOM.x, y: pointer.DOM.y },
          selectedHoverNode: findPos,
          toolTipPop: true,
          toolTipPopEnable: true,
          node: event.nodes[0],
          subType: findPos.subType.code,
          alertCount: findPos.uiConfiguration.alert_count,
        });
      }
    }
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener("resize", this.measure);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.measure);
    let obj = {
      initialRender: false,
      toolTipPop: null,
      selectedHoverNode: null,
      posiCoord: {
        x: 50,
        y: 50,
      },
      graph: {
        nodes: [],
        edges: [],
      },
      style: { width: "100%", height: "800px" },
      network: null,
      toolTipPopEnable: false,
    };
    let newObj = { ...obj };
    this.setState = {
      ...newObj,
    };
  }

  handlePopoverOpen(event) {
    this.setState({ toolTipPop: event });
  }

  handlePopoverClose = () => {
    this.setState({ toolTipPopEnable: false, toolTipPop: null });
  };

  getNetwork = (data) => {
    this.setState({ network: data });
  };

  render() {
    return (
      <Fragment>
        <Graph
          graph={this.state.graph}
          style={this.state.style}
          options={options}
          getNetwork={this.getNetwork}
          events={this.events}
          ref={(ref) => (this.graphRef = ref)}
          vis={(vis) => {
            return (this.vis = vis);
          }}
        />
        {this.props.multiTooltipData && Object.keys(this.props.multiTooltipData).map((nodeId) => {
          const current_node = this.props.multiTooltipData[nodeId];
          const node = current_node.selectedHoverNode || {};
          return (
            <ToolTip
            position={current_node.posiCoord}
            handlePopoverOpen={(event) => this.handlePopoverOpen(event)}
            handlePopoverClose={() => this.handlePopoverClose()}
            selectedHoverNode={node}
            nodeInfo={node.id}
            subTypeInfo={node.subType}
            alertCount={node.alertCount}
            handleSelectedNode={this.props.handleSelectedNode}
            loader={current_node.loaderStatus}
            data={current_node.data}
            bottomTrayEnableEve={() => {
              this.props.bottomTrayEnableEve(true)
            }
            }
          />
          )
        })}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    multiTooltipData: state.selectedNode.activeTooltipList,
  };
};

 

const mapDispatchToProps = (dispatch) => {
  return {
    enableNodeTooltip: (action) => dispatch({type: ENABLE_NODE_TOOLTIP, action})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GraphViewVis);