import { call, put, takeEvery } from "redux-saga/effects";
import { DELETE_TENANT_REQUEST } from "../constants/constants";
import { DELETE_TENANT_URL } from '../constants/constants';
import {getService, getAxiosHeaders} from "../../library/RestAPI";

function* deleteTenant(action) {
    let options
    var urlData = '';
    try {
        action.payload.ids.map((each) => {
            urlData = urlData + `${each},`
        })
        urlData = urlData.slice(0, -1)
        options = {
            method: "DELETE",
            url: `${DELETE_TENANT_URL}/${urlData}`,
            headers: getAxiosHeaders(true),
        };
      try {
        const res = yield getService(options);
        if(res) {
            console.log(res)
          yield put({ type: "DELETE_TENANT_SUCCESS"});
        } else {
          yield put({
            type: "DELETE_TENANT_FAIL",
            payload: "Delete unsuccessful",
          });
        }
      } catch (error) {
        console.log(error);
        // myStorage.setItem("access_token", "error");
        yield put({
          type: "DELETE_TENANT_FAIL",
          payload: "Error Fetching Data from API",
        });
      }
    } catch (e) {
      yield put({ type: "DELETE_TENANT_FAIL" });
    }
  }
  
  
  function* deleteTenantSaga() {
    yield takeEvery(DELETE_TENANT_REQUEST, deleteTenant);
  }
  
  export default deleteTenantSaga;