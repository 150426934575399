import React, { useState } from "react";

import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
const PlatformList = ({ platforms, setcurPlatformValue }) => {
  const [isClicked, setIsClicked] = useState({
    clicked: false,
    id: "",
    mouseOver: false,
  });
  return (
    <Paper className="PlatformList">
      <MenuList>
        <MenuItem
          key="0"
          onClick={(event) => {
            setIsClicked({ clicked: true, id: 0 });
            setcurPlatformValue("Technology", 0);
          }}
        >
          Technology
        </MenuItem>
        {platforms.length > 0 &&
          platforms.map((el, index) => (
            <MenuItem
              className={
                isClicked.clicked &&
                isClicked.id === index + 1 &&
                !isClicked.mouseOver
                  ? "onSelected"
                  : "onHover"
              }
              key={index + 1}
              value={el.code}
              onMouseOver={() => {
                if (isClicked.clicked && isClicked.id === index + 1) {
                  setIsClicked({
                    clicked: true,
                    id: index + 1,
                    onMouseOver: true,
                  });
                }
              }}
              // onMouseOut={() => {
              //   if (isClicked.clicked && isClicked.id === index + 1) {
              //     setIsClicked({
              //       clicked: true,
              //       id: index + 1,
              //       onMouseOver: false,
              //     });
              //   }
              // }}
              onClick={(event) => {
                setIsClicked({
                  clicked: true,
                  id: index + 1,
                  onMouseOver: false,
                });
                setcurPlatformValue(el.code, index + 1);
              }}
            >
              {el.value}
            </MenuItem>
          ))}
      </MenuList>
    </Paper>
  );
};

export default PlatformList;
