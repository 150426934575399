import {
  TOGGLE_INCIDENT_CHART,
  INCIDENT_CHANGEGROUPING,
  UPDATE_INCIDENTPRIORITY_FILTER_DATA,
  UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA,
  UPDATE_INCIDENT_NAME_FILTER_DATA,
  APPLY_INCIDENTTABLE_FILTER,
  UPDATE_INCIDENT_COUNTRY_FILTER_DATA,
  UPDATE_INCIDENTID_FILTER_DATA,
  UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA,
  UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA,
} from "../../constants/constants";

export const BARCHART_TOGGLERS = (payload) => {
  return {
    type: TOGGLE_INCIDENT_CHART,
    payload: payload,
  };
};

export const INCIDENT_GROUPINGTOGGLERS = (payload) => {
  return {
    type: INCIDENT_CHANGEGROUPING,
    payload: payload,
  };
};

export const UPDATE_INCIDENTPRIORITY_FILTER_DATA_ACTION = (payload) => {
  return {
    type: UPDATE_INCIDENTPRIORITY_FILTER_DATA,
    payload: payload,
  };
};

export const UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA_ACTION = (payload) => {
  return {
    type: UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA,
    payload: payload,
  };
};

export const UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA_ACTION = (payload) => {
  return {
    type: UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA,
    payload: payload,
  };
};

export const UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA_ACTION = (payload) => {
  return {
    type: UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA,
    payload: payload,
  };
};

export const UPDATE_INCIDENT_NAME_FILTER_DATA_ACTION = (payload) => {
  return {
    type: UPDATE_INCIDENT_NAME_FILTER_DATA,
    payload: payload,
  };
};

export const UPDATE_INCIDENTID_FILTER_DATA_ACTION = (payload) => {
  return {
    type: UPDATE_INCIDENTID_FILTER_DATA,
    payload: payload,
  };
};

export const UPDATE_INCIDENT_COUNTRY_FILTER_DATA_ACTION = (payload) => {
  return {
    type: UPDATE_INCIDENT_COUNTRY_FILTER_DATA,
    payload: payload,
  };
};
export const APPLY_INCIDENTTABLE_FILTER_ACTION = (payload) => {
  return {
    type: APPLY_INCIDENTTABLE_FILTER,
    payload: payload,
  };
};
