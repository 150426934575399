import React, { useState } from "react";
import "./RiskGovernance.scss";
import EnhancedTable from "../../shared/Table/Table";
import EditRRIcon from "../../../assets/icons/CreateRR.svg";
import { isEmpty, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RiskMitigationHeadCells } from "./RiskPilotHeadCells";
import { GET_RISK_PILOT_ROW_BY_ID, riskTypeIcons, SELECTED_ROW_ID } from "./riskPilotConstant";

const RiskMitigationTable = ({
  tableData,
  currentPage,
  recordsPerPage,
  setCurrentPage,
  setRecordsPerPage,
  isEditMode,
}) => {
  const dispatch = useDispatch();

  const totalRiskRegisterCount = useSelector(
    (state) => state.riskPilotReducer.totalRowCount
  );
  const allRiskTypes = useSelector(
    (state) => state.riskPilotReducer.riskTypes
  );

  const riskOwners = useSelector(
    (state) => state.riskPilotReducer.riskOwners
  );

  const selectedRowId = useSelector(
    (state) => state.riskPilotReducer.selectedRowId
  );

  const renderNull = () => {
    return <>-</>;
  };

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const renderRiskRegisterID = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      return <div title={params}>{params}</div>;
    }
  };

  const renderThreat = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      return <div title={params} className="textContainer">{params}</div>;
    }
  };

  const renderRiskRegisterName = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      const riskTypeValue = allRiskTypes.filter((risk) => risk.code === params);
      return (
        <div className="riskTypeIconsContainer">
          <div><img src={riskTypeIcons[riskTypeValue[0]?.code]} alt={riskTypeValue[0]?.value}/></div>
          <div title={riskTypeValue[0]?.value}>{riskTypeValue[0]?.value}</div>
        </div>
      );
    }
  };

  const renderRiskSeverity = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      return (
        <div
          className={`riskSeverityContainer ${params}`}
          style={{ marginTop: "0px" }}
        >
          <div className={`riskSeverityValue ${params}`}>{params}</div>
        </div>
      );
    }
  };

  const handleUpdateRow = (riskId, mitigationId, vulnerabilityId, threatId) => {
    isEditMode(true);
    dispatch({ type: GET_RISK_PILOT_ROW_BY_ID, payload: riskId });
    dispatch({ type: SELECTED_ROW_ID, payload: { threatId, vulnerabilityId, mitigationId}})
  };

  const renderRegisterAction = (riskId, mitigationId, vulnerabilityId, threatId) => {
    return (
      <div>
        <div>
          <img
            src={EditRRIcon}
            alt="RREditIcon"
            onClick={() => handleUpdateRow(riskId, mitigationId, vulnerabilityId, threatId)}
          />
        </div>
      </div>
    );
  };

  const renderMitigationPriority = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      return (
        <div>
          <div
            className={`inspect-file-severity ${params.toUpperCase()}`}
            style={{ marginTop: "0px", padding: "0px", borderRadius: "5px" }}
          >
            <div className={`riskSeverityValue ${params}`}>{params}</div>
          </div>
        </div>
      );
    }
  };

  const renderVulnerability = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      return <div title={params} className="textContainer">{params}</div>;
    }
  };

  const renderMitigation = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      return <div title={params} className="textContainer">{params}</div>;
    }
  };

  const renderMitigationStatus = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      const dataWithIcons = [
        {
          value: "To Do",
          icon: "/riskPilotIcons/ToDoWhite.svg",
          code: "TODO",
        },
        {
          value: "Open",
          icon: "/riskPilotIcons/openWhite.svg",
          code: "OPEN",
        },
        {
          value: "In Progress",
          icon: "/riskPilotIcons/InProgressWhite.svg",
          code: "IN_PROGRESS",
        },
        {
          value: "Closed",
          icon: "/riskPilotIcons/closedWhite.svg",
          code: "CLOSED",
        },
      ];
      const data = dataWithIcons.find((status) => status.code === params);
      return (
        <div className="mitigationCountChildContainer" style={{gap: "0px", justifyContent: "center"}}>
          <div
            className={`mitigationStatusCount ${data.code}`}
            title={data.code}
          >
            <div className="statusCount">
              {<img src={data.icon} alt={data.value} />}
              {data.value}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderMitigationOwner = (params) => {
    if (isNull(params) || isEmpty(params)) {
      return renderNull();
    } else {
      const ownerValue = riskOwners.filter((risk) => risk.code === params)
      return <div title={ownerValue[0]?.value}>{ownerValue[0]?.value}</div>;
    }
  }

  const renderTable = () => {
    return (
      <EnhancedTable
        cols={RiskMitigationHeadCells(
          renderRiskRegisterID,
          renderRiskRegisterName,
          renderRiskSeverity,
          renderThreat,
          renderVulnerability,
          renderMitigation,
          renderMitigationPriority,
          renderMitigationStatus,
          renderMitigationOwner,
          renderRegisterAction
        )}
        data={tableData}
        totalCount={totalRiskRegisterCount}
        loc={"mdr-AccountId"}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
      />
    );
  };
  return <>{renderTable()}</>;
};

export default RiskMitigationTable;
