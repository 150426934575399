import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

export default function DashboardMDRGlobalFilterViewPanel({
  singleFilterValuesObj,
  hadleGlobalFiltersDelete,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "380px",
      overflowY: "auto",
      borderLeft: "2px solid grey",
      padding: "10px",
      height: "705px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.14",
      letterSpacing: "normal",
      color: "black",
    },
    FilterHead: {
      fontSize: "12px",
      lineHeight: 1.22,
      color: "black",
    },
  }));

  //   const dispatch = useDispatch();
  const classes = useStyles();

  const handleDelete = (data) => {
    hadleGlobalFiltersDelete(data);
  };

  const renderChipWithClose = (data) => {
    return (
      <Box p={1}>
        <Chip
          label={data.value}
          size="medium"
          style={{ borderRadius: "3px", marginBottom: "2px" }}
          onDelete={() => handleDelete(data)}
          deleteIcon={<CloseIcon />}
        />
      </Box>
    );
  };
  console.log(singleFilterValuesObj, "singleFilterValuesObj");

  const renderChipWithOutClose = (data) => {
    return (
      <Box p={1}>
        <Chip
          label={data.value}
          size="medium"
          style={{ borderRadius: "3px", marginBottom: "2px" }}
        />
      </Box>
    );
  };

  const renderData = (selectedValues, type) => {
    return selectedValues.map((data) => {
      if (data.value !== "" && data.type === type) {
        return renderChipWithClose(data);
      }
    });
  };

  return (
    <FormControl component="fieldset" className={classes.root}>
      {/* <p className={classes.FilterHead}>Filter By</p> */}
      <FormGroup aria-label="position" row>
        <div>Include Filters</div>
        <div
          style={{
            wordBreak: "break-all",
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
            paddingLeft: "1.5rem",
            overflow: "auto",
          }}
        >
          {renderData(singleFilterValuesObj, "include")}
        </div>
      </FormGroup>
      <FormGroup style={{ marginTop: "5rem" }}>
        <div>Exclude Filters</div>
        <div
          style={{
            wordBreak: "break-all",
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
            paddingLeft: "1.5rem",
            overflow: "auto",
          }}
        >
          {renderData(singleFilterValuesObj, "exclude")}
        </div>
      </FormGroup>
    </FormControl>
  );
}
