import React from "react";
import { useSelector } from "react-redux";
import "./IncidentTableStyles.scss";

import UseIncidentFilterHook from "./IncidentFilterHook";
import {
  UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA_ACTION,
  UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA_ACTION,
} from "../../../../redux/actions/MDRActions/MDREndPointsAction";
import { endPoints_Query_toGet_FilterValues } from "../../../../redux/constants/constants";
import { getFilterTimeStamp } from "../../../common/utilities"

const EndPointsTableFilter = () => {
  let environmentFilterData = useSelector(
    (state) => state.MDREndPointsFilterReducer.Environment
  );
  let OsDescriptionFilterData = useSelector(
    (state) => state.MDREndPointsFilterReducer.OsDescription
  );
  const dateTimeRange = useSelector(
    (state) => {
      return state.selectedDateTimeRange.dateTimeRange;
    }
  );
  const {startDt, endDt} = getFilterTimeStamp(dateTimeRange);
  const endPoints_Query_cache = endPoints_Query_toGet_FilterValues(startDt, endDt);

  return (
    <>
      <div className="endpointsFilter">
        <p>Environment</p>
        <UseIncidentFilterHook
          query={endPoints_Query_cache.environmentFilter}
          action={UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA_ACTION}
          filterData={environmentFilterData}
        ></UseIncidentFilterHook>
      </div>
      <div className="endpointsFilter">
        <p>Operating System</p>
        <UseIncidentFilterHook
          query={endPoints_Query_cache.osDescriptionFilter}
          action={UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA_ACTION}
          filterData={OsDescriptionFilterData}
        ></UseIncidentFilterHook>
      </div>
    </>
  );
};

export default EndPointsTableFilter;
