import {
  TOGGLE_INCIDENT_CHART,
  INCIDENT_CHANGEGROUPING,
} from "../constants/constants";
import {
  IncidentdashboardInstance,
  tableQuery,
} from "../../components/common/Dashboard/dashboard-data";

import {
  dateFormatter,
  updateQueryAsperDateRange,
} from "../../components/common/Dashboard/utils";

const initialState = {
  //   barGraphTogglerValue: "MDRBarGraph",
  incidentdashboardInstance: IncidentdashboardInstance,
  tableQuery: tableQuery,
  tableQueryForFilter: [],
  incidentCurrentGraphActiveValue: 0,
  incidentGroupingValue: "Today",
  filterApply: "",
  renderforDateChange: false,
  incidentTableRowCount: 0,
  MDRDropdownValue: "",
  incidentDrawer: false,
  incidentSideDrawerData: {},
};

export const IncidenetReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_INCIDENT_CHART: {
      console.log(action.payload.query);
      const data = [...IncidentdashboardInstance];
      let newObj = { ...data[5] };
      let newObj1 = { ...newObj.vizState };
      newObj1 = {
        chartType: action.payload.chartType,
        query: action.payload.query,
      };
      newObj.vizState = newObj1;
      data[5] = newObj;
      return {
        ...state,
        incidentdashboardInstance: [...data],
      };
    }
    case INCIDENT_CHANGEGROUPING: {
      console.log(action.payload.query);
      const data = [...IncidentdashboardInstance];
      data[5].vizState.chartType = action.payload.chartType;
      data[5].vizState.query = action.payload.query;
      return {
        ...state,
        incidentdashboardInstance: [...data],
      };
    }
    case "INCIDENT_GROUPINGTOGGLE_VALUE": {
      return {
        ...state,
        incidentGroupingValue: action.payload,
      };
    }
    case "CURRENT_ACTIVE_GRAPH": {
      return {
        ...state,
        incidentCurrentGraphActiveValue: action.payload,
      };
    }

    case "UPDATE_QUERIES_ASPER_DATARANGE_SELECTED": {
      let data = [...state.incidentdashboardInstance];
      let updatedQuery = updateQueryAsperDateRange(
        data,
        action,
        action.payload.dateTimeRange
      );

      return {
        ...state,
        renderforDateChange: true,
        incidentdashboardInstance: updatedQuery,
      };
    }
    case "CHANGE_MDR_DASHBOARD": {
      return {
        ...state,
        incidentdashboardInstance: [...action.payload],
      };
    }
    case "UPDATE_MDR_DROPDOWN_VALUE": {
      return {
        ...state,
        MDRDropdownValue: action.payload,
      };
    }

    case "UPDATE_RENDERFORTIMECHANGE_TO_FALSE": {
      return {
        ...state,
        renderforDateChange: false,
      };
    }
    case "CLOSE_INCIDENT_DRAWER": {
      return {
        ...state,
        incidentDrawer: false,
        incidentSideDrawerData: {},
      };
    }
    case "INCIDENT_DRAWER_DATA": {
      return {
        ...state,
        incidentDrawer: true,
        incidentSideDrawerData: action.payload,
      };
    }
    default:
      return state;
  }
};
