import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// import AdminContainer from "./components/layouts/AdminContainer/AdminContainer";
import "./assets/scss/admin-styles.scss";

import App from "./App";
import "./index.css";
import "./i18n";
import { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./redux/store/store";

const cssVariables = {
  colors: {
    $palegrey: "#dfe1e9",
    $white: "#ffffff",
    "$pale-grey-0": "rgba(241, 242, 246, 0)",
    $black: "#292929",
    $lightRoyalBlue: "#2947fd",
    $royal: "#081981",
    $tangerine: "#ff8421",
  },
};

export const ThemeContext = React.createContext();

if (window.self === window.top) {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={<p>Loading</p>}>
        <Provider store={store}>
          <BrowserRouter>
            <ThemeContext.Provider value={cssVariables}>
              <App></App>
            </ThemeContext.Provider>
          </BrowserRouter>
        </Provider>
      </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
