import { call, put, takeEvery } from "redux-saga/effects";
import { SIGNUPPAGE_SELFSUBMIT } from "../constants/signuppageActionTypes";
// import axios from "axios";
import { ApiCall } from "../../../src/components/views/Util/ApiCall";
import {
  SignUpPage_Successful,
  SignUpPage_Failed,
} from "../../../src/redux/actions/signUppageAction";
import {
  // SIGN_UP_URL,
  TENANT_SIGN_UP_URL,
} from "../../redux/constants/constants";

function* APICALL2(action) {
  const apiCall2 = yield call(() =>
    ApiCall(
      {
        method: "POST",
        url: TENANT_SIGN_UP_URL,
        // url: "http://421ef6ea2667.ngrok.io/platform/signup",
        data: action.payload,
        headers: {
          "Content-Type": "application/json",
        },
      },
      "SIGNUPPAGE_SELFSUBMIT"
    )
  );
  // console.log(apiCall2);
  let out;
  console.log(apiCall2);
  if (apiCall2.status !== 201) {
    out = JSON.parse(apiCall2);
    console.log(out.message);
  }
  // console.log(JSON.parse(apiCall2));

  if (apiCall2 && apiCall2.status === 201) {
    console.log(apiCall2.status);
    yield put(SignUpPage_Successful());
  } else if (out.message === "Request failed with status code 500") {
    yield put(SignUpPage_Failed("User Already Exists"));
  } else if (out.message === "Request failed with status code 400") {
    // console.log("hello");
    yield put(SignUpPage_Failed("Please fill the Fields"));
  } else if (out.message === "Network Error") {
    yield put(SignUpPage_Failed("Network error Please try again later"));
  } else {
    yield put(SignUpPage_Failed(`${out.message}`));
  }
}

function* signUpUser(action) {
  console.log(action);
  yield APICALL2(action);
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
export function* selfSignUppageSaga() {
  yield takeEvery(SIGNUPPAGE_SELFSUBMIT, signUpUser);
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/

export default selfSignUppageSaga;
