import { put, takeEvery, call } from "redux-saga/effects";
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import {
  GET_USERSBY_DATASOURCE,
  SET_DATAMAP_USER_DETAILS,
  SET_DATAMAP_LOADER,
  usersWithSensitiveAccess,
  usersWithAnomalies
} from "../../redux/constants/constants";

async function getAllDataSourceIds(nodeIds, type, size) {
  if (nodeIds && nodeIds.length) {
    let obj = nodeIds.map((el) => {
      return { nodeId: el.id };
    });
    let nodeIdObj = {
      actorSensitivePercentages: obj,
    };
    let response = '';
    if (type === usersWithSensitiveAccess) {
      response = await getService({
        method: "POST",
        url: `${GET_USERSBY_DATASOURCE}?page=0&size=${size}`,
        headers: getAxiosHeaders(true),
        data: nodeIdObj,
      });
    } else if (type === usersWithAnomalies) {
      response = await getService({
        method: "POST",
        url: `${GET_USERSBY_DATASOURCE}?page=0&sortBy=totalAnomalies&size=${size}`,
        headers: getAxiosHeaders(true),
        data: nodeIdObj,
      });
    } else {
      response = await getService({
        method: "POST",
        url: `${GET_USERSBY_DATASOURCE}`,
        headers: getAxiosHeaders(true),
        data: nodeIdObj,
      });
    }
    return response;
  }
}

function* getUserNodesList(action) {
  const response = yield call(getAllDataSourceIds, action.payload.nodeId, action.payload.type, action.payload.size);
  try {
    if (response.data.length) {
      yield put({
        type: SET_DATAMAP_USER_DETAILS,
        payload: response,
        size: action.payload.size,
        criteriaType: action.payload.type
      });
    } else {
      yield put({
        type: SET_DATAMAP_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    yield put({
      type: SET_DATAMAP_LOADER,
      payload: true,
    });
  }
}

function* dataMapUserSaga() {
  yield takeEvery("GET_USER_NODES_LIST", getUserNodesList);
}

export default dataMapUserSaga;
