import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MapViewIcon from "../../assets/vizr_images/MapViewIcon.svg";
import { viewDataMap } from '../../redux/constants/constants';
import CanAllow from '../shared/CanAllow';
export const DataMapRoute = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <CanAllow needs={{permission: [viewDataMap]}}>
        <span
          className="map-view-img"
          onClick={() => {
            dispatch({ type: 'TRACK_TRAVERSAL', payload: 'Data Map' });
            history.push('/tenant/dataMap');
          }}
        >
          <div class="nav-link" activeclassname="active">
            <span className="icon-map">
              <img src={MapViewIcon} alt="MapViewIcon" />
            </span>
            <span className="text">Map View</span>
          </div>
        </span>
      </CanAllow>
    </>
  );
}
