import { SEARCH_USER, SEARCH_USER_URL , SEARCH_DB_USER_URL , SEARCH_DB_ROLE_URL } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function searchUser(searchName) {
    const options = {
        method: "GET",
        url: `${SEARCH_USER_URL}`,
        headers: getAxiosHeaders(true),
          params:{
              queryString: searchName
          }
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};

export async function searchDbUser(searchName) {
    const options = {
        method: "GET",
        url: `${SEARCH_DB_USER_URL}`,
        headers: getAxiosHeaders(true),
          params:{
              queryString: searchName === "" ? ' ' : searchName
          }
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};

export async function searchDbRoleUser(searchName) {
    const options = {
        method: "GET",
        url: `${SEARCH_DB_ROLE_URL}`,
        headers: getAxiosHeaders(true),
          params:{
              queryString: searchName === "" ? ' ' : searchName
          }
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};