import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../DashBoardStyles.scss";
import {
  INCIDENT_GROUPINGTOGGLERS,
  BARCHART_TOGGLERS,
} from "../../../../redux/actions/MDRActions/IncidentDashboardAction";
import { UPDATE_MDRENDPOINTS_GROUPINGVALUE_ACTION } from "../../../../redux/actions/MDRActions/MDREndPointsAction";
import {
  incidentDashboard_ToggleCharts,
  dateFormatter,
  endPointsDashboard_ToggleCharts,
  endPointNewDashboardInstanceforToggling,
} from "../../Dashboard/utils";
import { EndPointsdashboardInstance } from "../dashboard-data";

const SquareToggler = ({ type }) => {
  const dispatch = useDispatch();
  const index = useSelector(
    (state) => state.IncidenetReducer.incidentCurrentGraphActiveValue
  );

  const incidentGroupingValue = useSelector(
    (state) => state.IncidenetReducer.incidentGroupingValue
  );
  const dateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );

  const endPointGroupingValue = useSelector(
    (state) => state.MDREndPointsReducer.endPointGroupingValue
  );
  const endPointsCurrentGraphActiveValue = useSelector(
    (state) => state.MDREndPointsReducer.endPointsCurrentGraphActiveValue
  );
  const [groupingValue, setGroupingValue] = useState("");

  //todo:To Highlight and change the grouping value as per selected value for Incident Dashboard
  useEffect(() => {
    setGroupingValue(incidentGroupingValue);
  }, [incidentGroupingValue]);

  //todo:To Highlight and change the grouping value as per selected value for Endpoint Dashboard
  useEffect(() => {
    setGroupingValue(endPointGroupingValue);
  }, [endPointGroupingValue]);

  //todo:Used to update the grouping value to default value after component is unmounted for both incident and endpoint dashboard
  useEffect(() => {
    return () => {
      setGroupingValue("Today");
      dispatch({
        type: "INCIDENT_GROUPINGTOGGLE_VALUE",
        payload: "Today",
      });
      dispatch(UPDATE_MDRENDPOINTS_GROUPINGVALUE_ACTION("Today"));
    };
  }, []);

  // const [activeName, setActiveName] = useState("Today");
  const clickHandler = (e) => {
    // setActiveName(e.target.textContent);
    let name;
    if (e.target.textContent === "Last 7 Days") {
      name = "Today";
    } else {
      name = e.target.textContent;
    }
    let startDate = dateTimeRange && JSON.parse(dateTimeRange).startDate;
    let endDate = dateTimeRange && JSON.parse(dateTimeRange).endDate;
    if (startDate && endDate) {
      var startDt = new Date(startDate).getTime(); // dateFormatter(startDate);
      var endDt = new Date(endDate).getTime(); // dateFormatter(endDate);
    }
    if (type === "MDRIncidents") {
      console.log(name);
      dispatch({
        type: "INCIDENT_GROUPINGTOGGLE_VALUE",
        payload: name,
      });
      const payload = incidentDashboard_ToggleCharts(
        index,
        name,
        startDt,
        endDt
      );
      dispatch(INCIDENT_GROUPINGTOGGLERS(payload));
    } else if (type === "MDREndpoints") {
      dispatch(UPDATE_MDRENDPOINTS_GROUPINGVALUE_ACTION(name));
      const payload = endPointsDashboard_ToggleCharts(
        endPointsCurrentGraphActiveValue,
        name,
        startDt,
        endDt
      );
      //! use destructuring to create new instance for that object(destructuring used in below function)
      const newDashBoardInstance =
        endPointNewDashboardInstanceforToggling(payload);

      //! we are dipatching CHANGE_MDR_DASHBOARD action because we are using dashboard instance in IncidentReducer for all Dashboards in MDR
      dispatch({
        type: "CHANGE_MDR_DASHBOARD",
        payload: newDashBoardInstance,
      });
    }
  };

  return (
    <>
      {type === "MDREndpoints" && endPointsCurrentGraphActiveValue === 1 ? (
        ""
      ) : (
        <div
          className="MDR_squareTogglers"
          onClick={(e) => {
            clickHandler(e);
          }}
        >
          <div className={groupingValue === "Today" ? "active" : "inactive"}>
            Last 7 Days
          </div>
          <div className={groupingValue === "Weekly" ? "active" : "inactive"}>
            Weekly
          </div>
          <div className={groupingValue === "Monthly" ? "active" : "inactive"}>
            Monthly
          </div>
        </div>
      )}
    </>
  );
};

export default SquareToggler;
