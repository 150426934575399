import React from 'react';
import { Link } from 'react-router-dom';
import ChartWrapper from '../Chart';
import { QueryRenderer } from '@cubejs-client/react';
import { renderNoData, renderSingleValue } from '../utils';
import { viewDataMap } from '../../../../redux/constants/constants';
import { pathWithPermission } from '../../utilities';

export function userRoles(cubejsApi, name, query) {
  const chartQuery = query[1];
  const countQuery = query[0];
  const returnRedirectTo = (name) => {
    let data = {
      "Total Users By Roles":{
        pathname: "/tenant/dataMap",
        permission: [viewDataMap],
      },
    }
    return pathWithPermission(data[name])
  };
  const renderLegends = (resultSet) => {
    const data = resultSet.loadResponse.results[0].data;
    if (!data || data.length === 0) {
      return renderNoData('30%');
    }
    let legends = [];
    data.map((item) => {
      const pDataItem = {
        text: item["UserRoles.roleName"],
        count: item["UserRoles.totalCount"]
      }
      legends.push(pDataItem);
    })
    return (
      <React.Fragment>
        <div className='widget-header2'>
          <span className='span-total-count'>
            <QueryRenderer
              query={countQuery}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) {
                  return <div className='loader' />;
                }
                return renderSingleValue(resultSet, countQuery.measures[0]);
              }}
            />
          </span>
        </div>
        <div>
          <h4>Top 5 users with organisational roles</h4>
          {legends.map((row) => {
            return (
              <div className='user-role-names-container'>
                  <div className='user-role-data' >
                    <div className='user-role-name'>
                      {row.text ? row.text : '-'}
                    </div>
                    <div>
                      {row.count ? row.count : 0}
                    </div>
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={chartQuery}
      render={renderLegends}
      redirectTo={returnRedirectTo(name)}
    />
  );
}
