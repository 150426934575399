import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../assets/scss/admin-styles/custom/_variables.scss";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import img from "../../assets/signinpage/Bitmap-1.png";
import druvlogo from "../../assets/signinpage/druvstarLogoFinal.svg";
import background1 from "../../assets/signinpage/bg1.png";

import "../../assets/scss/admin-styles/custom/loginpagestyle.scss";
import Button from "@material-ui/core/Button";

import Input from "../views/Input";
import { cssVariables } from "../../cssVariables";
import { getTenant } from "../../components/shared/getTenant";

import {
  fetchUser,
  fetchUserwithMFA,
} from "../../redux/actions/signinPageAction";
import { Emailvalidator } from "../views/Util/Emailvalidator";
import page_loader from "../../assets/vizr_images/page_loader.gif";

const useStyles = makeStyles({
  login_pageConatainerForm_header: {
    height: "1.5rem",
    width: "18.75rem",
    color: (theme) => theme.colors.$black,
    fontFamily: "Nunito-Regular",
    fontSize: "1.5rem",
    letterSpacing: "0",
    lineHeight: "1.5rem",
    marginTop: "5%",
  },
  login_pageConatainer_RightContainer: {
    width: "100% !important",
    backgroundColor: (theme) => theme.colors.$lightGray,
    minHeight: "42rem",
    fontFamily: "Nunito-Regular",
  },

  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  sideLogo: {
    marginTop: "5rem",
    height: "383px",
    width: "362px",
  },
  login_pageRightConatainer_text: {
    width: "70%",
    marginTop: "5%",
    color: (theme) => theme.colors.$royal,
    fontSize: "1.5rem",
  },
  login_pageRightConatainer_text2: {
    fontSize: "1.5rem",
    color: (theme) => theme.colors.$tangerine,
    display: "flex",
  },
  login_pageRightConatainer_line: {
    borderLeft: "2px solid black",
    height: "3rem",
    width: "10rem",
    position: "absolute",
    right: "12%",
    bottom: "6.5%",
  },
  rightContainerImage2: {
    height: "75px",
    width: "151px",
    marginRight: "1rem",
  },
  root: {
    fontFamily: "Nunito-Regular !important",
  },
});

const LoginPage = () => {
  const history = useHistory();
  // const theme = useContext(ThemeContext);
  const theme = cssVariables;
  const { search } = useLocation();
  // const { tenant } = queryString.parse(search);
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const errorMessage = useSelector(
    (state) => state.signInpage.loginErrorMessage
  );
  const loginEnableMFA = useSelector(
    (state) => state.signInpage.loginEnableMFA
  );
  const mfa_token = useSelector((state) =>
    state.signInpage.mfa_token
      ? state.signInpage.mfa_token
      : sessionStorage.getItem("mfa_token")
  );

  // const isLoggedIn = useSelector((state) => state.signInpage.isloggedIn);
  const [signPageUsername, setsignPageUsername] = useState("");
  const [signPagePassword, setsignPagePassword] = useState("");
  const [signInPageMFA, setsignInPageMFA] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [signInClicked, setSignInClicked] = useState(false);
  const { t } = useTranslation();

  let tenantName = getTenant(search);
  const signInsubmitHandler = () => {
    setSignInClicked(true);
    dispatch({ type: "USER_ERROR_MESSAGE_REFRESH" });
    if (!signInPageMFA && !mfa_token) {
      if (signPageUsername.match(Emailvalidator)) {
        setValidEmail(true);
        dispatch({ type: "USER_FETCHSTATE_REFRESH" });
        setSignInClicked(true);
        dispatch(
          fetchUser({ signPageUsername, signPagePassword, tenant: tenantName })
        );
      } else {
        setValidEmail(false);
        console.log("Not a valid email");
      }
    } else if (mfa_token) {
      setSignInClicked(true);
      dispatch(
        fetchUserwithMFA({
          mfa_token,
          mfa_code: signInPageMFA,
          tenant: tenantName,
        })
      );
    }
  };
  const handleOnKeyPress = (event) => {
    console.log("keyCode", event.keyCode);
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      signInsubmitHandler();
    }
  };
  useEffect(() => {
    if (loginEnableMFA) {
      setSignInClicked(false);
      history.push("/mfaAuthentication");
    }
  }, [loginEnableMFA]);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className="login_pageConatainer"
    >
      <Grid item lg={6} md={6} xs={12} style={{ fontFamily: "Nunito-Regular" }}>
        <Grid item lg={12}>
          <img
            src={druvlogo}
            alt="Druvstarlogo"
            className="login_pageConatainerForm-logo"
          ></img>
          <div
            style={{
              margin: "6% 15%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {signInClicked && !errorMessage && validEmail ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={page_loader}
                  style={{ width: "75px", height: "75px" }}
                  alt="loader"
                ></img>
              </div>
            ) : (
              ""
            )}
            {errorMessage ? (
              <h4
                style={{
                  color: theme.colors["$reddish-pink"],
                  marginTop: "0.4rem",
                }}
              >
                {errorMessage}
              </h4>
            ) : null}
            {!mfa_token ? (
              <>
                {" "}
                <h1 className={classes.login_pageConatainerForm_header}>
                  {t("Sign In")}
                </h1>
                <Input
                  type="email"
                  labelName={t("User Name")}
                  placeholder="Enter your email"
                  classname="signinpage"
                  value={signPageUsername}
                  eventHandler={(value) => {
                    setsignPageUsername(value);
                  }}
                  onKeyPress={(event) => handleOnKeyPress(event)}
                  errors={!validEmail ? "error" : ""}
                  helpertext={!validEmail ? "Please enter valid email" : ""}
                ></Input>
                <Input
                  type="password"
                  labelName={t("Password")}
                  placeholder="Password"
                  classname="signinpage"
                  value={signPagePassword}
                  onKeyPress={(event) => handleOnKeyPress(event)}
                  eventHandler={(value) => setsignPagePassword(value)}
                ></Input>
              </>
            ) : (
              ""
            )}

            {(loginEnableMFA || mfa_token) && (
              <Input
                type="text"
                labelName={t("MFA code")}
                placeholder="Enter your MFA code"
                classname="signinpage"
                value={signInPageMFA}
                eventHandler={(value) => {
                  setsignInPageMFA(value);
                }}
                // errors={!validEmail ? "error" : ""}
                // helpertext={!validEmail ? "Please enter valid email" : ""}
              ></Input>
            )}

            {!mfa_token && (
              <div className="login_pageConatainerForm_forgotpassword_link">
                <a
                  className="login_pageConatainerForm_forgotpassword"
                  href="/forgotpassword"
                >
                  {t("Forgot/Reset Password")}
                </a>
              </div>
            )}
          </div>
        </Grid>

        <Grid
          item
          lg={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Nunito-Regular",
          }}
        >
          <Button
            classes={{
              root: classes.root,
            }}
            variant="contained"
            color="primary"
            size="large"
            className="signin-Button"
            onClick={() => signInsubmitHandler()}
          >
            {t("Sign In")}
          </Button>
          {/* <div className="signuppage_NotaMember">
            <span style={{ marginRight: "6px" }}> {t("Not a member")}?</span>

            <a href="/signup" className="login_pageConatainerForm_signup">
              {t("Sign Up")}
            </a>
          </div> */}
        </Grid>
      </Grid>

      <Grid
        item
        lg={6}
        md={6}
        xs={12}
        className={classes.login_pageConatainer_RightContainer}
      >
        <div className={classes.imageContainer}>
          <img src={img} alt="logo" className={classes.sideLogo} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start !important",
            marginLeft: "5rem",
          }}
        >
          <div className={classes.login_pageRightConatainer_text}>
            {t("Protect the Pulse of Your")}
          </div>
          <div className={classes.login_pageRightConatainer_text2}>
            <span style={{ marginRight: "0.5rem" }}>
              {" "}
              {t("Infrastructure")}
            </span>

            <div
              style={{
                borderLeft: `2px solid ${theme.colors["$black"]}`,
                height: "2.5rem",
              }}
            ></div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={background1}
              alt="bg1"
              className={classes.rightContainerImage2}
            ></img>
          </div>
        </div>

        {/*         <div className={classes.login_pageRightConatainer_line}></div>*/}
      </Grid>
    </Grid>
  );
};

export default LoginPage;
