import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "../../../src/assets/scss/TenantMangement.scss";

const SelectDropDown = ({ disabled, state }) => {
  const [currentValue, setCurrentValue] = useState("Select Licenses");

  const selectClickHandler = (value) => {
    console.log(value);
    setCurrentValue(value);
  };

  return (
    <div style={{ width: "30rem", marginBottom: "2rem", marginTop: "0.5rem" }}>
      <Select
        value={currentValue}
        className="InputSelectFeild"
        disabled={disabled}
      >
        <MenuItem
          value="Select Licenses"
          onClick={() => selectClickHandler("Select Licenses")}
        >
          Select Licenses
        </MenuItem>
        {state && state.length > 0
          ? state.map((el, index) => (
              <MenuItem value={el} onClick={() => selectClickHandler(el)}>
                {el}
              </MenuItem>
            ))
          : null}
      </Select>
    </div>
  );
};

export default SelectDropDown;
