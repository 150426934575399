import {
  nodesGeneration,
  edgesGeneration,
  nodesGenerationForUsers,
  edgesGenerationForUsers
} from "../../src/components/shared/dataMapUtilities";

export const dataMapRender= (dataMapNodes)=>{
    const nodesObj = {
        apiSucc: true,
        data: {
          nodes: [],
          edges: [],
        },
      };
      let allNodes = [];
      let allEdges = [];
      if (dataMapNodes.nodes && dataMapNodes.nodes.length) {
        allNodes = dataMapNodes.nodes;
        allEdges = dataMapNodes.hierarchy;
      } else {
        allNodes = [];
        allEdges = [];
      }
      nodesObj.data.nodes = allNodes.length ? nodesGeneration(allNodes) : [];
      nodesObj.data.edges =
        Object.keys(allEdges) && Object.keys(allEdges).length
          ? edgesGeneration(allEdges)
          : [];
    return nodesObj
}

export const dataMapUsersRender= (dataMapUserNodes)=>{
  const nodesObj = {
      apiSucc: true,
      data: {
        nodes: [],
        edges: [],
      },
    };
    nodesObj.data.nodes =  nodesGenerationForUsers(dataMapUserNodes);
    nodesObj.data.edges =  edgesGenerationForUsers(dataMapUserNodes);
  return nodesObj
}