import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import arrow_back_ios from "../../../assets/icons/arrow_back_ios.svg";
import RiskRegisterThreats from "./RiskRegisterThreats";
import { isEmpty } from "lodash";
import "./RiskGovernance.scss";
import {
  CLEAR_MITIGATION,
  CLEAR_THREATS,
  CLEAR_VULNERABILITIES,
  CLEAR_RP_DATA,
  CREATE_RISK_PILOT,
  GET_ALL_RISK_OWNERS,
  GET_ALL_RISK_TYPES,
  UPDATE_RISK_PILOT_ROW_BY_ID,
  SET_IS_RISK_CREATED,
  GET_RISK_SUMMARY_DETAILS,
} from "./riskPilotConstant";
import { updateFromApi } from "./actions/updateAPIData";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import RiskForm from "./RiskForm";
import { createObjectWithOrder } from "./riskPilotUtilities";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RiskPilotFormManager({
  showRiskPilotForm,
  setShowNewRiskButton,
  isEditMode,
  setIsEditMode,
}) {
  const dispatch = useDispatch();
  const allRiskTypes = useSelector((state) => state.riskPilotReducer.riskTypes);
  const riskOwners = useSelector((state) => state.riskPilotReducer.riskOwners);
  const isRiskCreated = useSelector(
    (state) => state.riskPilotReducer.isRiskCreated
  );
  const riskPilotRowDetails = useSelector(
    (state) => state.riskPilotReducer.riskPilotRowDetails
  );

  const threats = useSelector((state) => state.threatReducer.threats);
  const vulnerabilities = useSelector(
    (state) => state.vulnerabilityReducer.vulnerabilities
  );
  const mitigations = useSelector(
    (state) => state.mitigationReducer.mitigations
  );
  const loader = useSelector(
    (state) => state.riskPilotReducer.loader
  );
  const snackbarMessage =  useSelector(
    (state) => state.riskPilotReducer.snackbarMessage
  );

  const riskId = riskPilotRowDetails ? riskPilotRowDetails.riskId : "";
  const [risk, setRisk] = useState("");
  const [riskSeverity, setRiskSeverity] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: SET_IS_RISK_CREATED, payload: "" });
  };

  useEffect(() => {
    setShowNewRiskButton(true);
    dispatch({ type: GET_ALL_RISK_TYPES });
    dispatch({ type: GET_ALL_RISK_OWNERS });
    return () => {
      dispatch({ type: CLEAR_RP_DATA });
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(isRiskCreated) && isRiskCreated === 'success') {
      showRiskPilotForm(false);
      if (isEditMode) setIsEditMode(false);
    }
  }, [isRiskCreated]);

  useEffect(() => {
    if (allRiskTypes?.length > 0 && !isEditMode) {
      setRisk(allRiskTypes[0].code);
    } else if (
      isEditMode &&
      riskPilotRowDetails
    ) {
      setRisk(riskPilotRowDetails.riskType);
      dispatch({ type: GET_RISK_SUMMARY_DETAILS, payload: riskPilotRowDetails.riskId });
      const allData = createObjectWithOrder(riskPilotRowDetails.threat, riskOwners)
      dispatch(updateFromApi(allData, riskOwners));
    }
  }, [allRiskTypes, isEditMode, riskPilotRowDetails]);

  const determinePrioritySeverity = (threats) => {
    let pSeverity = {
      code: "LOW",
      value: "Low Risk severity",
    };
  
    for (const threat of Object.values(threats)) {
      const severity = threat.riskLevel;
  
      if (severity === "CRITICAL") {
        pSeverity = {
          code: "CRITICAL",
          value: "Critical Risk severity",
        };
        break; // Found the highest priority, no need to check further
      } else if (severity === "HIGH") {
        pSeverity = {
          code: "HIGH",
          value: "High Risk severity",
        };
      } else if (severity === "MEDIUM" && pSeverity.code === "LOW") {
        pSeverity = {
          code: "MEDIUM",
          value: "Medium Risk severity",
        };
      }
    }
  
    return pSeverity;
  };
  

  useEffect(() => {
    if (threats) {
      setRiskSeverity(determinePrioritySeverity(threats));
    }
  }, [threats]);

  const getFormattedData = (isEditMode) => {
    // Format the data
    const formattedData = Object.entries(threats).map(([threatId, threat]) => {
      // Get vulnerabilities for the current threat
      const threatVulnerabilities = Object.values(vulnerabilities).filter(
        (vuln) => vuln.threatId === threatId
      );

      // Format vulnerabilities and their mitigations
      const formattedVulnerabilities = threatVulnerabilities.map(
        (vulnerability) => {
          const vulnerabilityMitigations = Object.values(mitigations).filter(
            (mitigation) =>
              mitigation.vulnerabilityId === vulnerability.id &&
              mitigation.threatId === threatId
          );

          const formattedMitigations = vulnerabilityMitigations.map(
            (mitigation) => ({
              id: isEditMode ? mitigation.id : null,
              mitigation: mitigation.mitigationText,
              mitigationPriority: mitigation.mitigationPriority,
              mitigationStatus: mitigation.mitigationStatus,
              riskOwner: mitigation.mitigationOwner,
              orderExecuted: mitigation.orderExecuted
            })
          );

          return {
            id: isEditMode ? vulnerability.id : null,
            vulnerability: vulnerability.vulnerability,
            mitigation: formattedMitigations,
            orderExecuted: vulnerability.orderExecuted
          };
        }
      );

      return {
        id: isEditMode ? threat.id : null,
        threat: threat.threat,
        likelihood: threat.likelihood,
        riskLevel: threat.riskLevel,
        impact: threat.impact,
        vulnerability: formattedVulnerabilities,
        orderExecuted: threat.orderExecuted
      };
    });

    return formattedData;
  };

  const handleRiskRegister = () => {
    const request = {
      riskType: risk,
      riskCost: 554,
      severity: riskSeverity.code || "",
      threat: getFormattedData(isEditMode),
    };

    if (isEditMode) {
      dispatch({
        type: UPDATE_RISK_PILOT_ROW_BY_ID,
        payload: { id: riskId, data: request },
      });
    } else {
      dispatch({ type: CREATE_RISK_PILOT, payload: request });
    }
  };

  const handleCloseScreen = () => {
    showRiskPilotForm(false);
    setIsEditMode(false);
    dispatch({ type: CLEAR_THREATS });
    dispatch({ type: CLEAR_VULNERABILITIES });
    dispatch({ type: CLEAR_MITIGATION });
  };

  const createRRHeader = () => (
    <div className="createAndBackContainer">
      <div className="createAndBackImage">
        <img
          src={arrow_back_ios}
          alt="back"
          title="Go Back"
          onClick={handleCloseScreen}
          style={{ width: "25px", cursor: "pointer" }}
        />
      </div>
      <div>
        <p className="header_ContainerRR">
          {isEditMode ? "View & Edit" : "Create"}
        </p>
      </div>
    </div>
  );

  // Disable the save button if there are no threats or if the save process has been initiated with threats present.
  const manageRiskSaveButton = () => {
    // Check if threats is null or an empty object
    const isThreatsInvalid = !threats || Object.keys(threats).length === 0;
  
    // Check if loader is active and threats is not null
    const shouldDisableSave = loader && !isThreatsInvalid;
  
    // Save button should be disabled if threats is invalid or save should be disabled
    return isThreatsInvalid || shouldDisableSave;
  };
  


  const riskPilotForm = () => (
    <div className="RRContainer">
      {createRRHeader()}
      {/* {renderFormDetails()} */}
      <RiskForm
        isEditMode={isEditMode}
        riskId={riskId}
        risk={risk}
        setRisk={setRisk}
        riskSeverity={riskSeverity}
        threats={threats}/>
      {
        <div className="createRRButton">
          <Button
            className="createRRButton"
            onClick={handleRiskRegister}
            disabled={manageRiskSaveButton()}
          >
            {isEditMode ? "Save" : "Create"}
          </Button>
        </div>
      }
    </div>
  );

  return (
    <div
      className={"filter-table-container riskPilotForm"}
    >
      {riskPilotForm()}
      <div className="riskSnackBarContainer">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isRiskCreated === 'error'}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={'error'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default RiskPilotFormManager;
