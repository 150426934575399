import { SELECTED_TIME_ZONE } from "../constants/constants";

const initialState = {
  selectedTimeZone: '',
};

export const selectedTimeZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_TIME_ZONE:
      return {
        ...state,
        selectedTimeZone: action.payload,
      };
    default:
      return state;
  }
}
