import {  GET_TENANT_DETAIL_URL } from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';

export async function getTenantDetails(id) {
    const options = {
        method: "GET",
        url: `${GET_TENANT_DETAIL_URL}/${id}`,
        headers: getAxiosHeaders(true),
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};
