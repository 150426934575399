import { put, takeEvery, select } from "redux-saga/effects";
import {
 SERVICE_DATA_ACCESS,
 SERVICE_DATAUSERS_SENSITIVITY_PERCENTAGE
} from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import {
    createCustomObject_ServiceDataAcceses, createCustomObject_ServiceDataAcceses_SensitivityPercentage,
    
} from "../../components/views/userAccessRights/UserAccessRightsCommonFunctions";

function* ServiceDataAccessApi() {
  let options;

  let data = yield select();
  const {
    currentPage,
    recordsPerPage,
    finalDataSourceName,
    finalDataSourceType,
    finalDatabaseAccessRoles,
    finalSortByValue,
    finalOrderByValue,
    finalapplication
  } = data.serviceDataAccessReducer;

  let paramData = {};
  if (finalSortByValue && finalSortByValue !== "") {
    paramData.sortBy = finalSortByValue;
  }
  if (finalOrderByValue && finalOrderByValue !== "") {
    paramData.direction = finalOrderByValue;
  }
  if (finalDataSourceName && finalDataSourceName.length > 0) {
    paramData.dataSourceNames = finalDataSourceName.join(",");
  }
  if (finalDataSourceType && finalDataSourceType.length > 0) {
    paramData.dataSourceTypes = finalDataSourceType.join(",");
  }
  if (finalDatabaseAccessRoles && finalDatabaseAccessRoles.length > 0) {
    paramData.databaseAccessRoles = finalDatabaseAccessRoles.join(",");
  }
  if(finalapplication && finalapplication.length> 0)
  {
    paramData.application = finalapplication.join(",")
  }
  options = {
    method: "GET",
    url: `${SERVICE_DATA_ACCESS}?size=${recordsPerPage}&page=${currentPage}`,
    headers: getAxiosHeaders(true),
    params: paramData,
    data: {},
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      let enterPriseActorArray = createCustomObject_ServiceDataAcceses(res.data.content);
      let options1 = {
        method: "POST",
        url: SERVICE_DATAUSERS_SENSITIVITY_PERCENTAGE,
        headers: getAxiosHeaders(true),
        // params: paramData1,
        data: enterPriseActorArray,
      };
      try{
          const res1  = yield getService(options1);
          let finalResponse = createCustomObject_ServiceDataAcceses_SensitivityPercentage(res1.data,res.data.content);
           yield put({
            type: "SET_SERVICE_DATA_ACCESSES_INFO",
            payload: {
              allServiceDataAccesses: finalResponse,
              totalCount: res.data.totalElements,
            },
          });
          yield put({
            type: "SET_SERVICE_DATA_ACCESSES_LOADER_INFO",
            payload: false,
          });
      }
      catch (error) {
        yield put({ type: "SET_LOADER_INFO" ,payload:false});
      }
      yield put({
        type: "SET_SERVICE_DATA_ACCESSES_LOADER_INFO",
        payload: false,
      });
  
    
    } else {
      if (res && res.response.status === 500) {
        yield put({
          type: "SET_DIRECT_ERROR_MESSAGE",
          payload: res.response.data.errorMessage,
        });
      }
      yield put({
        type: "SET_SERVICE_DATA_ACCESSES_LOADER_INFO",
        payload: false,
      });
    }
  } catch (error) {
    yield put({ type: "SET_LOADER_INFO" ,payload:false});
  }
}

function* ServiceDataAccessSaga() {
  yield takeEvery(
    "GET_SERVICE_DATA_ACCESSES_INFO_DATA",
    ServiceDataAccessApi
  );
}

export default ServiceDataAccessSaga;
