import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SortIcon from "../../../assets/vizr_images/ic_sort-1.svg";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import FilterComponent from "./alertsFilter";
import SortComponent from "../../shared/sortComponent";
import PolicyFilter from "../../shared/policyFilter";
import ClearFilter from "../../shared/clearFilter";
import Popper from "@material-ui/core/Popper";
import { getAlertPolicySeverities } from "../../../redux/actions/getAlertPolicySeveritiesAction";
import { getAlertResolutionTypes } from "../../../redux/actions/getAlertResolutionTypesAction";
import { getAllAlertPolicyNames } from "../../../redux/actions/getAllAlertPolicyNamesAction";
import { updateAlertStatus } from "../../../redux/actions/updateAlertStatusAction";
import { ColorButtonForToolBar } from "../../common/buttons";
import { updateAlert } from "../../../redux/constants/constants";
import ImageTooltip from "../../common/HelpIconWithTooltip";
import SelectedFiltersView from "./SelectedFiltersView"
import { cloneDeep } from "lodash";
import CanAllow from "../CanAllow";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    totalElements,
    rows,
    policyId,
    loc,
    alertsTitle,
    severityFilter,
    resolutionFilter,
    policyFilter,
    selectedRows,
    dataLoaded,
    hideAction,
    helpDescription,
    sortByAlert,
    sortOrderAlert,
    applyFilter,
    filterDataInfo,
    toTimeRangeAlert,
    fromTimeRangeAlert,
    show
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [alertPolicySeverities, setAlertPolicySeverities] = useState([]);
  const [alertResolutionTypes, setAlertResolutionTypes] = useState([]);
  const [policyArr, setPolicyArr] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [severity, setSeverityFilter] = useState([]);
  const [resolution, setResolutionFilter] = useState([]);
  const [policy, setPolicyFilter] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [filterInfo,setFilterInfo] = useState([]);
  const [toTimeRange , setToTimeRange]= useState("");
  const [fromTimeRange , setFromTimeRange]= useState("");
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.applicationData.permissions);

  useEffect(() => {
    async function getResolutionTypes() {
      const response = await getAlertResolutionTypes();
      if (response) {
        setAlertResolutionTypes(response.content);
      }
    }
    getResolutionTypes();
  }, []);

  useEffect(() => {
    async function getPolicyNames() {
      const response = await getAllAlertPolicyNames();
      if (response) {
        setPolicyArr(response);
      }
    }
    getPolicyNames();
  }, []);

  useEffect(() => {
    async function getSeverities() {
      const response = await getAlertPolicySeverities();
      if (response) {
        setAlertPolicySeverities(response.content);
      }
    }
    getSeverities();
  }, []);

  useEffect(() => {
    setSeverityFilter(severityFilter);
    setResolutionFilter(resolutionFilter);
    setPolicyFilter(policyFilter);
    setSortBy(sortByAlert);
    setSortOrder(sortOrderAlert);
    setFilterInfo(cloneDeep(filterDataInfo))
  }, []);

  useEffect(() => {
    console.log("fdf")
    setToTimeRange(toTimeRangeAlert)
    setFromTimeRange(fromTimeRangeAlert)
  }, [fromTimeRangeAlert , toTimeRangeAlert]);

  useEffect(() => {
    if (rows && rows.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [rows]);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    setValues();
  };

  const setValues = () => {
    setSeverityFilter(severityFilter);
    setResolutionFilter(resolutionFilter);
    setPolicyFilter(policyFilter);
    setSortBy(sortByAlert);
    setSortOrder(sortOrderAlert);
    setFilterInfo(cloneDeep(filterDataInfo))
  }

  //this method is used in the sort and filter component
  const handleCheckbox = (event, type,data) => {
    if (event.target.checked) {
      if (type === "severity") {
        let finalInfoBackup = filterInfo
        finalInfoBackup.push({type:type, name:data.code ,data:data ,value:event.target.value})
        setFilterInfo(finalInfoBackup)
        if (!severity.includes(event.target.value)) {
          setSeverityFilter([...severity, event.target.value]);
        }
      } else if (type === "resolutionType") {
        if (!resolution.includes(event.target.value)) {
          let finalInfoBackup = filterInfo
          finalInfoBackup.push({type:type, name:data.value,data:data,value:event.target.value})
          setFilterInfo(finalInfoBackup)
          setResolutionFilter([...resolution, event.target.value]);
        }
      } else if (type === "policy") {
        let finalInfoBackup = filterInfo
        finalInfoBackup.push({type:type, name:data.name,data:data,value:event.target.value})
        setFilterInfo(finalInfoBackup)
        if (!policy.includes(event.target.value)) {
          setPolicyFilter([...policy, event.target.value]);
        }
      }
    } else {
      if (type === "severity") {
        let finalInfoBackup = filterInfo.filter((each) => each.name !== data.code);
        setFilterInfo(finalInfoBackup)
        let temp = severity.filter((each) => each !== event.target.value);
        setSeverityFilter(temp);
      } else if (type === "resolutionType") {
        let finalInfoBackup = filterInfo.filter((each) => each.name !== data.value);
        setFilterInfo(finalInfoBackup)
        let temp = resolution.filter((each) => each !== event.target.value);
        setResolutionFilter(temp);
      } else if (type === "policy") {
        let finalInfoBackup = filterInfo.filter((each) => each.name !== data.name);
        setFilterInfo(finalInfoBackup)
        let temp = policy.filter((each) => each !== event.target.value);
        setPolicyFilter(temp);
      }
    }
  };

  const handleOnChipDelete = (value, type,data) =>{
    if (type === "severity") {
      let finalInfoBackup = filterInfo.filter((each) => each.name !== data.code);
      setFilterInfo(finalInfoBackup)
      let temp = severity.filter((each) => each !== value);
      setSeverityFilter(temp);
    } else if (type === "resolutionType") {
      let finalInfoBackup = filterInfo.filter((each) => each.name !== data.value);
      setFilterInfo(finalInfoBackup)
      let temp = resolution.filter((each) => each !== value);
      setResolutionFilter(temp);
    } else if (type === "policy") {
      let finalInfoBackup = filterInfo.filter((each) => each.name !== data.name);
      setFilterInfo(finalInfoBackup)
      let temp = policy.filter((each) => each !== value);
      setPolicyFilter(temp);
    }
  }

  const clearFilterData = () => {
    setSeverityFilter([]);
    setResolutionFilter([]);
    setPolicyFilter([]);
    setSortBy("");
    setSortOrder("");
    setOpen(false)
    setFilterInfo([])
    setToTimeRange("")
    setFromTimeRange("")
    props.clearFilterData();
  };

  const handleSorting = (event) => {
    setSortOrder("ASC");
    setSortBy(event.target.value);
  };

  const handleSortOrder = (value) => {
    setSortOrder(value);
  };

  const handleApplyFilter = () =>{
    applyFilter(severity,resolution,policy,sortBy,sortOrder,filterInfo ,fromTimeRange ,toTimeRange)
    setOpen(false)
  }
  const handleCloseButton = () =>{
    setToTimeRange(toTimeRangeAlert)
    setFromTimeRange(fromTimeRangeAlert)
    setOpen(false)
  }

  const handleOnDateChange = (startDate , endDate) =>{
    setToTimeRange(endDate)
    setFromTimeRange(startDate)
  }
  
  const renderHeaderTitle = () => {
    return (
      <Grid style={{ display: "flex" }} item xs={5}>
        {selectedRows.length > 0 && loc === "alerts" ? (
          <Typography
            className="title"
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {selectedRows.length} selected
          </Typography>
        ) : (
          ""
        )}
        <Typography
          className="title"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {alertsTitle}
        </Typography>
        {!hideAction && (
          <p style={{ margin: "11px 5px 0px 0px", minWidth: "45px" }}>
            Total :
          </p>
        )}
        {!hideAction && <div className="count">{totalElements}</div>}
        {!hideAction && <ImageTooltip description={helpDescription} />}
      </Grid>
    );
  };

  const renderButtons = () => {
    return (
      <Grid item xs={7} style={{ float: "right", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            color: "#081981",
            backgroundColor: "transparent",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
          }}
          disabled={dataLoaded ? false : disableButton}
          onClick={handleClick("bottom")}
          startIcon={<img src={SortIcon} alt="sort-filter" />}
          className="min-margin table-buttons"
        >
          Sort & Filter
        </Button>
        {loc === "alerts" && (
          <CanAllow needs={{permission: [updateAlert]}}>
            <ColorButtonForToolBar
              variant="contained"
              color="primary"
              size="small"
              disabled={disableButton}
              onClick={() => {
                if (selectedRows.length > 0) {
                  dispatch(
                    updateAlertStatus({
                      status: "MARK_RESOLVED",
                      ids: selectedRows,
                    })
                  );
               }
              }}
              className="min-margin table-buttons"
            >
              Resolve
            </ColorButtonForToolBar>
            <ColorButtonForToolBar
              variant="contained"
              color="primary"
              disabled={disableButton}
              size="small"
              onClick={() => {
                if (selectedRows.length > 0) {
                  dispatch(
                    updateAlertStatus({
                      status: "MARK_FALSE_POSITIVE",
                      ids: selectedRows,
                    })
                  );
                }
              }}
              className="min-margin table-buttons"
            >
              False Positive
            </ColorButtonForToolBar>
          </CanAllow>
        )}
        {renderPopover()}
      </Grid>
    );
  };

  const renderPopover = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{
          boxShadow: "0 6px 8px 0 rgb(0 0 0 / 27%)",
          marginTop: "10rem",
          marginRight: "1rem",
          zIndex: "10"
        }}
      >
        {({ TransitionProps }) => (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <>
                <div className="clear-filter-container">
                  <ClearFilter
                    className="clear-filter"
                    clearFilterButtonClick={clearFilterData}
                    toggle={handleClick}
                    handleApplyFilter={handleApplyFilter}
                    handleCloseButton = {handleCloseButton}
                    loc={loc}
                  />
                </div>
                <div className="popper-root" style={{height:"650px"}}>
                  <Fade timeout={350} {...TransitionProps}>
                    <SortComponent
                      sortBy={sortBy}
                      handleSortingFilter={handleSorting}
                      sortOrder={sortOrder}
                      handleSortOrder={handleSortOrder}
                      loc={loc}
                    />
                  </Fade>
                  <Fade timeout={350} {...TransitionProps}>
                    <FilterComponent
                      severityFilter={severity}
                      resolutionFilter={resolution}
                      policy = {policy}
                      severitiesArray={alertPolicySeverities}
                      resolutionTypes={alertResolutionTypes}
                      handleCheckbox={handleCheckbox}
                      policyArr={policyArr}
                      policyId = {policyId}
                      handleOnDateChange={handleOnDateChange}
                      toTimeRange={toTimeRange}
                      fromTimeRange={fromTimeRange}
                      loc={loc}
                      show={show}
                    />
                  </Fade>
                 
                  <div
                style={{
                  minheight: "350px",
                  width: "400px",
                  display: "flex",
                  flexDirection: "column",
                  // marginTop: "2rem",
                  paddingLeft: "1.5rem",
                  overflow: "hidden auto",
                }}
              >
                <SelectedFiltersView filterInfo={filterInfo}  handleOnChipDelete={handleOnChipDelete}/>
            
              </div>
                  
                </div>
              </>
            </div>
          </>
        )}
      </Popper>
    );
  };

  const renderGrid = () => {
    return (
      <Grid container spacing={3}>
        {renderHeaderTitle()}
        {!hideAction && renderButtons()}
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          {renderGrid()}
        </Grid>
      </Toolbar>
    </Paper>
  );
};

EnhancedTableToolbar.propTypes = {
  totalElements: PropTypes.number.isRequired,
};
