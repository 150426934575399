import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ColoredActionButton } from "../../common/buttons";
import verifiedButton from "../../../assets/icons/verifiedButton.svg";
import { isEmpty } from "lodash";
import {
  LOCATION_ERROR_MESSAGE,
  REGIONS,
  REGION_ERROR_MESSAGE,
  SET_LOG_SOURCE_VERIFIED,
  STORAGE_CONTAINER_ERROR_MESSAGE,
} from "../../../redux/constants/constants";
import { useDispatch } from "react-redux";


const useStyles = makeStyles(() => ({
  customSaveButton: {
    marginLeft: "10px",
    textTransform: "none",
    height: "31px",
    marginTop: "0.5px",
  },
  customVerifySaveButton: {
    marginLeft: "10px",
    textTransform: "none",
    height: "31px",
    marginTop: "2px",
  },
  customCancelButton: {
    width: "124px",
    height: "32px",
    boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
  },
  customVerificationButton: {
    marginLeft: "13px",
    textTransform: "none",
    height: "32px",
    marginTop: "5.5px",
  },
  customVerifiedButton: {
    marginTop: "10px",
    marginLeft: "10px",
  }
}));

const DynamicFormDataCreator = ({
  formData,
  engine,
  handleVerification,
  getTypeData,
  handleSave,
  applicationErrorCheck,
  regionErrorCheck,
  handleToggleDrawerClose,
  handleTypeChange,
  handleOptions,
  handleAutocompleteValue,
  handleAutocompleteInputText,
  isLogSourceVerified,
  handleAutoCompleteErrorChecks,
  handleAutocompleteInput,
  handleAutocompleteSolo,
  handleAutocompleteTags,
  handleAutoCompleteError,
  setRegionError,
  setApplicationError,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  const [formDataState, setFormDataState] = useState(null);
  const classes = useStyles();

  const dispatch = useDispatch();

  // Effect hook to reset form data and clear errors when engine changes
  useEffect(() => {
    clearErrors();
    setApplicationError(null);
    setRegionError(null);
    reset(formData);
  }, [engine]);

  // Function to check form errors
  const checkErrors = (data) => {
    let hasErrors = false;

    formData.forEach((field) => {
      if (
        field.name !== "sourceType" &&
        field.name !== REGIONS &&
        field.name !== "applicationName" &&
        field.required
      ) {
        const value = data[field.name]?.trim();
        if (isEmpty(value)) {
          setError(field.name, {
            type: "required",
            message: field.errorMessage,
          });
          hasErrors = true;
        } else if (field.customFn && field.customFn(value)) {
          setError(field.name, {
            type: "required",
            message: field.customErrorMessage,
          });
          hasErrors = true;
        }
      }
    });

    // Check for application name error
    const applicationField = formData.find(
      (field) => field.name === "applicationName"
    )
    const regionField = formData.find(
      (field) => field.name === REGIONS
    )
    const applicationErrors = applicationErrorCheck(
      applicationField
    );
    if (applicationErrors) {
      hasErrors = true;
    }

    // Check for region error
    const regionErrors = regionErrorCheck(regionField);
    if (regionErrors) {
      hasErrors = true;
    }

    return hasErrors;
  };

  const resetVerificationStatus = () => {
    dispatch({ type: SET_LOG_SOURCE_VERIFIED, payload: false });
  };

  // Form submit handler
  const onSubmit = (data) => {
    clearErrors();
    setApplicationError(null);
    setRegionError(null);
    let hasErrors = checkErrors(data);
    if (!hasErrors) {
      setFormDataState(data);
      handleVerification(data);
    }
  };

  // Function to handle port input
  const handlePortInput = (value, name) => {
    const numericValue = parseInt(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      setValue(name, Math.abs(numericValue));
    } else {
      setValue(name, "");
    }
  };

  // Function to handle input change
  const handleInputChange = (event, name) => {
    const value = event.target.value;
    if (name === "port") {
      handlePortInput(value, name);
    }
    clearErrors(name);
    resetVerificationStatus();
  };

  // Function to render verification button
  const renderVerification = () => {
    if (isLogSourceVerified) {
      return (
        <div className="logSourceVerificationContainer">
          <img
            src={verifiedButton}
            alt="verified"
            className={classes.customVerifiedButton}
          />
        </div>
      );
    }

    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.customVerificationButton}
      >
        Verification
      </Button>
    );
  };

  const renderTypes = (field) => {
    const typeData = getTypeData(field);
    return typeData.map((data) => (
      <MenuItem key={data.code} value={data.code}>
        {data.value}
      </MenuItem>
    ));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formData.map((field, index) => (
        <div key={index} style={{ padding: "10px 0px" }}>
          <div>
            {field.label}
            {field.required && <span style={{ color: "red" }}>*</span>}
          </div>
          {field.type === "select" ? (
            <FormControl variant="outlined" fullWidth>
              <Select
                value={engine}
                onChange={(event) => {
                  handleTypeChange(event.target.value, field);
                  resetVerificationStatus();
                }}
              >
                {renderTypes(field)}
              </Select>
            </FormControl>
          ) : field.type === "autocomplete" ? (
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                freeSolo={handleAutocompleteSolo(field)}
                options={handleOptions(field)}
                value={handleAutocompleteValue(field)}
                onChange={(event, newValue) => {
                  handleAutocompleteInput(newValue, field); // Call the handleEngineChange function
                  handleAutoCompleteErrorChecks(field);
                  resetVerificationStatus();
                }}
                renderTags={(value, getTagProps) =>
                  handleAutocompleteTags(value, getTagProps, field)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    onChange={(event) => {
                      handleAutocompleteInputText(event.target.value, field);
                      handleAutoCompleteErrorChecks(field);
                      resetVerificationStatus();
                    }}
                    fullWidth
                    error={handleAutoCompleteError(field)}
                    helperText={handleAutoCompleteError(field)}
                    placeholder={field.placeholder}
                  />
                )}
              />
            </FormControl>
          ) : (
            <TextField
              {...register(field.name)}
              id={field.name}
              variant="outlined"
              fullWidth
              type={field.type}
              placeholder={field.placeholder}
              autoComplete="off"
              error={errors[field.name] ? true : false}
              helperText={errors[field.name] && errors[field.name].message}
              onChange={(event) => handleInputChange(event, field.name)}
              InputProps={{ style: { color: "black" } }}
            />
          )}
        </div>
      ))}
      <div style={{ padding: "30px 0px" }} className="logSourceButtonContainer">
        <ColoredActionButton
          className={classes.customCancelButton}
          onClick={(event) => handleToggleDrawerClose(event)}
        >
          Cancel
        </ColoredActionButton>
        {renderVerification()}
        <Button
          variant="contained"
          disabled={!isLogSourceVerified}
          color="primary"
          className={ isLogSourceVerified ? classes.customVerifySaveButton : classes.customSaveButton}
          onClick={(event) => handleSave(event, formDataState)}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default DynamicFormDataCreator;
