import Checkbox from "@material-ui/core/Checkbox";
import { isBoolean, filter } from "lodash";

export const tableHeadCells_allIps = (
  selectedList,
  setSelectedList,
  allIPs,
  setAllIps
) => {
  const handleCheckItem = (item) => {
    console.log(item, "testingselect");
    const id = item.id;
    const list = [...allIPs];
    list.forEach((item) => {
      if (item.id === id) {
        item.checked = isBoolean(item.checked) ? !item.checked : true;
      }
    });
    let arr = filter(list, (item) => item.checked).map((i) => i.id);
    setSelectedList([...arr]);
    setAllIps(list);
  };
  const onSelectAllClick = (event) => {
    const list = [...allIPs];
    list.forEach((item) => {
      if (event.target.checked) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    let arr = filter(list, (item) => item.checked).map((i) => i.id);
    setSelectedList([...arr]);
    setAllIps(list);
  };
  return [
    {
      style: { width: "5%" },
      rowBodyCellStyle: { width: "5%", wordBreak: "break-all" },
      title: () => {
        return (
          <Checkbox
            onChange={(event) => {
              onSelectAllClick(event);
            }}
            inputProps={{ "aria-label": "decorative checkbox" }}
            checkedIcon={
              selectedList && selectedList.length === allIPs.length ? (
                <span className="checked-icon" />
              ) : (
                <span className="checkbox-icon" />
              )
            }
            indeterminate={
              selectedList.length > 0 && selectedList.length < allIPs.length
                ? true
                : false
            }
          />
        );
      },
      render: (rowData) => {
        return (
          <Checkbox
            className="checkbox-root"
            disableRipple
            color="default"
            onChange={() => {
              handleCheckItem(rowData);
            }}
            checkedIcon={
              rowData.checked ? (
                <span className="checked-icon" />
              ) : (
                <span className="checkbox-icon" />
              )
            }
            icon={<span className="checkbox-icon" />}
            inputProps={{
              "aria-label": "decorative checkbox",
            }}
            checked={true}
          />
        );
      },
      padding: "checkbox",
    },
    {
      title: "IP",
      style: { width: "14%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.ip4Address ? rowData.ip4Address : ""}</span>;
      },
    },
    {
      title: "Subnet Mask",
      style: { width: "12%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.subnetMask ? rowData.subnetMask : ""}</span>;
      },
    },
    {
      title: "Country",

      style: { width: "10%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.country ? rowData.country : ""}</span>;
      },
    },
    {
      title: "Region",

      style: { width: "10%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.region ? rowData.region : ""}</span>;
      },
    },
    {
      title: "City",
      style: { width: "8%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.city ? rowData.city : ""}</span>;
      },
    },
  ];
};

export const tableHeadCells_accountId = (
  selectedList,
  setSelectedList,
  allAccounts,
  setAllAccounts
) => {
  const handleCheckItem = (item) => {
    console.log(item, "testingselect");
    const id = item.id;
    const list = [...allAccounts];
    list.forEach((item) => {
      if (item.id === id) {
        item.checked = isBoolean(item.checked) ? !item.checked : true;
      }
    });
    let arr = filter(list, (item) => item.checked).map((i) => i.id);
    setSelectedList([...arr]);
    setAllAccounts(list);
  };
  const onSelectAllClick = (event) => {
    const list = [...allAccounts];
    list.forEach((item) => {
      if (event.target.checked) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    let arr = filter(list, (item) => item.checked).map((i) => i.id);
    setSelectedList([...arr]);
    setAllAccounts(list);
  };
  return [
    {
      style: { width: "5%" },
      rowBodyCellStyle: { width: "5%", wordBreak: "break-all" },
      title: () => {
        return (
          <Checkbox
            onChange={(event) => {
              onSelectAllClick(event);
            }}
            inputProps={{ "aria-label": "decorative checkbox" }}
            checkedIcon={
              selectedList && selectedList.length === allAccounts.length ? (
                <span className="checked-icon" />
              ) : (
                <span className="checkbox-icon" />
              )
            }
            indeterminate={
              selectedList.length > 0 &&
              selectedList.length < allAccounts.length
            }
          />
        );
      },
      render: (rowData) => {
        return (
          <Checkbox
            className="checkbox-root"
            disableRipple
            color="default"
            onChange={() => {
              handleCheckItem(rowData);
            }}
            checkedIcon={
              rowData.checked ? (
                <span className="checked-icon" />
              ) : (
                <span className="checkbox-icon" />
              )
            }
            icon={<span className="checkbox-icon" />}
            inputProps={{
              "aria-label": "decorative checkbox",
            }}
            checked={true}
          />
        );
      },
      padding: "checkbox",
    },

    {
      title: "Account ID",
      style: { width: "14%" },
      rowBodyCellStyle: { width: "14%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.accountId ? rowData.accountId : ""}</span>;
      },
    },
    {
      title: "Country",
      style: { width: "12%" },
      rowBodyCellStyle: { width: "12%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.country ? rowData.country : ""}</span>;
      },
    },
    {
      title: "Region",

      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.region ? rowData.region : ""}</span>;
      },
    },
    {
      title: "City",

      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.city ? rowData.city : ""}</span>;
      },
    },
    {
      title: "Environment",
      style: { width: "8%" },
      rowBodyCellStyle: { width: "8%", wordBreak: "break-all" },
      render: (rowData) => {
        return <span>{rowData.environment ? rowData.environment : ""}</span>;
      },
    },
  ];
};
