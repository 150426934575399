import React, { useState, useEffect } from "react";
import { ViewServiceNodeConstants } from "./utilities";
import EnhancedGridTable from "../../shared/Table/Table";
import { EnhancedTableToolbar } from "../MdrConcentrator/Header";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import {
  GET_SERVICE_NODES,
  PUT_SERVICE_NODE,
} from "../../../redux/constants/constants";
import { makeStyles } from "@material-ui/core/styles";
import { isBoolean } from "lodash";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "#081981",
  },
  inputInputClass: {
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
    borderRadius: "6px",
    marginRight: "10px",
    padding: "8px 20px",
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ServiceNodes = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [loader, setLoader] = useState(false);
  const [serviceNodes, setServiceNodes] = useState([]);
  const [openSuccess, setopenSuccess] = useState(false);
  const [openError, setopenError] = useState({ open: false, message: "" });
  const classes = useStyles();

  useEffect(() => {
    setLoader(true);
    getServiceData();
  }, [currentPage, recordsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getServiceData() {
    const data = getAxiosHeaders(true);

    const res = await getService({
      method: "GET",
      url: `${GET_SERVICE_NODES}?pageNumber=${currentPage}&pageSize=${recordsPerPage}`,
      headers: data,
    });
    if (res && res.status === 200) {
      const data = [];
      res.data.content.forEach((item, id) => {
        data.push({
          ...item,
          id,
          dummyAliasName: item.aliasName || "",
          errorAliasName: "",
        });
      });
      setServiceNodes(data);
      setTotalCount(res.data.totalElements);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  async function saveNodeInfo(id) {
    if (id.errorAliasName === "" && id.dummyAliasName !== "") {
      const data = getAxiosHeaders(true);
      const res = await getService({
        method: "PUT",
        url: `${PUT_SERVICE_NODE}`,
        headers: data,
        data: {
          aliasName: id.dummyAliasName,
          nodeId: id.nodeId,
          serviceId: id.serviceId,
        },
      });
      if (res && res.status === 200) {
        getServiceData();
        setLoader(true);
        setopenSuccess(true);
        setLoader(true);
        getServiceData();
      } else {
        if (
          res &&
          res.response &&
          res.response.data &&
          res.response.data.errorMessage
        ) {
          setopenError({
            open: true,
            message:
              res.response.data.errorMessage ||
              "Service node edited unsuccessfully",
          });
        } else {
          setopenError({
            open: true,
            message: "Service node editing is unsuccessfull",
          });
        }
        const list = [...serviceNodes];
        list[id.id].dummyAliasName = id.aliasName || "";
        list[id.id].edit = !id.edit;
        setServiceNodes(list);
        setLoader(false);
      }
    } else {
      setopenError({
        open: true,
        message: "Alias name cannot be empty",
      });
    }
  }

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleEditItem = (item) => {
    const id = item.id;
    const list = [...serviceNodes];
    list.forEach((item) => {
      if (item.id === id) {
        item.edit = isBoolean(item.edit) ? !item.edit : true;
      }
    });
    setServiceNodes(list);
  };

  const handleEvent = (event, item) => {
    const id = item.id;
    const name = event.target.name;
    const list = [...serviceNodes];
    list.forEach((item) => {
      if (item.id === id) {
        item[name] = event.target.value;
        if (name === "dummyAliasName" && event.target.value === "") {
          item.errorAliasName = "Alias name cannot be empty";
        } else if (name === "dummyAliasName") {
          item.errorAliasName = "";
        }
      }
    });
    setServiceNodes(list);
  };

  const handleSave = (rowData) => {
    saveNodeInfo(rowData);
  };

  const handleCancel = (id) => {
    const list = [...serviceNodes];
    list[id.id].dummyAliasName = id.aliasName || "";
    list[id.id].errorAliasName = "";
    list[id.id].edit = !id.edit;
    setServiceNodes(list);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSuccess(false);
    setopenError({ open: false, message: "" });
  };

  const renderSnackbar = () => {
    return (
      <>
        <Snackbar
          open={openError.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error">
            <div>{openError.message}</div>
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="success">
            Service node edited successfully
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <div className="main">
      {loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )}
      {renderSnackbar()}
      <div className="filter-table-container">
        <div className="table-container">
          <EnhancedTableToolbar
            totalElements={totalCount}
            loc={"service"}
            alertsTitle={"Service Nodes"}
            helpDescription={"Service Nodes"}
            hide={true}
          />
          <EnhancedGridTable
            cols={ViewServiceNodeConstants(
              handleEditItem,
              handleEvent,
              handleSave,
              handleCancel,
              classes
            )}
            data={serviceNodes}
            totalCount={totalCount}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
          />
        </div>
      </div>
    </div>
  );
};
