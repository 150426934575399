import React from "react";
import background1 from "../../../assets/signinpage/bg1.png";
import background2 from "../../../assets/signinpage/bg2.svg";
import background3 from "../../../assets/signinpage/bg3.svg";
import "../../../assets/scss/admin-styles/custom/loginpagestyle.scss";
// import LoginPage from "../../../components/views/LoginPage";
const LoginbackGround = (props) => {
  return (
    <div className="login_page">
      <img src={background1} alt="bg" className="bg1" />
      <img src={background2} alt="bg" className="bg2" />
      <img src={background3} alt="bg" className="bg3" />
      <img src={background1} alt="bg" className="bg4" />
      {/* <LoginPage mfaPage={mfaPage}></LoginPage> */}
      {props.log}
    </div>
  );
};

export default LoginbackGround;
