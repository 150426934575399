import Toggler from "./Toggler";
import EnhancedTable from "./alertsTable";
import { headCellsForSqlStatements } from "../common/headCells";

const RenderTabsInfo = ({
  views,
  setViews,
  databaseManagementFields,
  spanDBStatements,
}) => {
  return (
    <>
      <div className="togglerandSearch_Conatiner">
        <Toggler togglers={views} setTogglers={setViews}></Toggler>
      </div>
      {views.length && views[0].state === "active" && (
        <>
          <EnhancedTable
            description={"Direct Data Access Statements"}
            tableData={
              databaseManagementFields.data ? databaseManagementFields.data : []
            }
            headCells={headCellsForSqlStatements}
            loc={"sql-statements"}
          />{" "}
        </>
      )}
      {views.length && views[1].state === "active" && (
        <>
          <EnhancedTable
            description={"Service Data Access Statements"}
            tableData={spanDBStatements.data ? spanDBStatements.data : []}
            headCells={headCellsForSqlStatements}
            loc={"span-sql-statements"}
          />{" "}
        </>
      )}
    </>
  );
};

export default RenderTabsInfo;
