import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { OPEN_REPORT_HISTORY, FETCH_REPORT_DATA, SEARCH_REPORT_DATA, GET_ALL_USER_EMAILS, CLEAR_SNACK_BAR_MESSAGE, CLEAR_SHARE_REPORT_MESSAGE, viewReportURL } from '../../../redux/constants/constants';
import { isEmpty, isNull } from 'lodash';
import AlertDrawer from '../../shared/alertDrawer';
import "./Report.scss";
import viewReport from "../../../assets/vizr_images/viewReport.svg";
import viewReportDisabled from "../../../assets/vizr_images/viewReportDisabled.svg";
import historyIcon from "../../../assets/vizr_images/history.svg";
import historyDisabledIcon from "../../../assets/vizr_images/histroyDisabled.svg";
import { Popover } from '@mui/material';
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { Button, Input, makeStyles } from '@material-ui/core';
import CloseIcon from '../../../assets/vizr_images/ic_remove.svg';
import SearchIcon from '../../../assets/vizr_images/ic_search.svg';
import { errorSnackbar, expiryDaysCalculation, getExpiryDetails } from '../../common/utilities';
import EnhancedTable from '../../shared/Table/Table';
import { ReportRepositoryHeadCells } from "../../common/headCells";
import { formatDateAndTime } from './utilities';
import ShareReport from './ShareReport';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const useStyles = makeStyles((theme) => ({
  reportSearchInput: {
    width: "350px",
    "& input": {
      padding: "5px 0px 5px 16px",
      '&::placeholder': {
        color: '#081981 !important',
        opacity: 1,
        fontSize: "16px",
      },
    }
  }
}));
function ReportRepositoryTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowData, setRowData] = useState();
  const [searchReportName, setSearchReportName] = useState("");
  const [rowSelected, setRowSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [searchClicked, setSearchClicked] = useState(false); 
  const [loaderOnTable, setLoaderOnTable] = useState(false); 
  const reportData = useSelector(
    (state) => state.GenerateReportReducer.reportData
  );
  const viewReportHistory = useSelector(
    (state) => state.GenerateReportReducer.viewReportHistory
  );

  const totalReportsCount = useSelector(
    (state) => state.GenerateReportReducer.totalReportsCount
  );

  const loader = useSelector(
    (state) => state.GenerateReportReducer.loader
  );
  const shareReportMessage = useSelector(
    (state) => state.GenerateReportReducer.shareReportMessage
  );
  const [openSnackBar, setOpenSnackBar] = useState(false);
  useEffect(() => {
    if (reportData && reportData.length > 0) {
      dispatch({ type: GET_ALL_USER_EMAILS });
    }
    return () => {
      setOpenSnackBar(false);
      dispatch({type: CLEAR_SHARE_REPORT_MESSAGE})
    }
  }, [reportData])
  useEffect(() => {
    if (!searchClicked) {
      if (isEmpty(searchReportName)) {
        dispatch({
          type: FETCH_REPORT_DATA,
          payload: { pageSize: recordsPerPage, page: currentPage }
        });
      } else {
        dispatch({
          type: SEARCH_REPORT_DATA, payload: {
            pageSize: recordsPerPage,
            page: currentPage,
            searchReport: searchReportName,
          }
        })
      }
    }
  }, [currentPage, recordsPerPage]);

  const reportStatusMessages = {
    "Success": "Success",
    "In-Progress": "In Progress",
    "Not-Connected": "Not Connected",
    "Failure": "Failure",
  };

  const renderNull = () => {
    return (<>-</>);
  };

  useEffect(() => {
    if (shareReportMessage && shareReportMessage.status && shareReportMessage.message) {
      setOpenSnackBar(true);
    }
  }, [shareReportMessage]);
  
  const handlePopUpClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    dispatch({type: CLEAR_SNACK_BAR_MESSAGE})
  };
  
  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
    setSearchClicked(false);
    setLoaderOnTable(true);
    setRowSelected([]);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
    setSearchClicked(false);
    setLoaderOnTable(true)
    setRowSelected([]);
  };

  const renderReportName = (params) => {
    if (isNull(params)) {
      return renderNull();
    } else {
      return (
        <div className="report_titles name" title={params}>{params}</div>
      )
    }
  };

  const renderGeneratedOn = (params) => {
    if (isNull(params)) {
      return renderNull();
    } else {
      const momentDate = formatDateAndTime(params, "MM-DD-YYYY HH:mm");
      const reportTime = momentDate.split(" ");
      return (
        <div>
          <div className='report_titles'>{reportTime[0]}</div>
          <div className='report_titles'>{reportTime[1]}</div>
        </div>
      )
    }
  };
  const reportDate = (date) => {
    if (isNull(date)) {
      return renderNull();
    } else {
      const momentDate = formatDateAndTime(date, "MM-DD-YYYY");
      return momentDate;
    }
  }
  const renderTimeRange = (startDate, endDate) => {
    return (
      <div className='report_timeRange'>
        <div className='report_titles'>{`${reportDate(startDate)} to ${reportDate(endDate)}`}</div>
      </div>
    )
  };

  const renderGeneratedBy = (params) => {
    if (isNull(params)) {
      return renderNull();
    } else {
      return (
        <div className='report_titles generateBy'>{params}</div>
      )
    }
  };

  const renderContent = (params) => {
    if (isNull(params)) {
      return renderNull();
    } else {
      return (
        <div className='report_titles content'>{params}</div>
      )
    }
  };

// Function to render a report generation status icon based on the provided parameters
const renderReportGenerationStatus = (params) => {
  // Check if the parameters are empty
  if (isEmpty(params)) {
    // If empty, render a null component
    return renderNull();
  } else if (params === "Success") {
    // If parameters indicate success, render a checkmark icon
    return (
      <div title={reportStatusMessages[params]} className="side-icons">
        <CheckCircleIcon className={`report-status ${params}`} fontSize="small" />
      </div>
    );
  } else if (params === "In-Progress" || params === "Not-Started") {
    // If parameters indicate in-progress or not started, render a clock/watch icon
    return (
      <div title={reportStatusMessages[params]} className="side-icons">
        <WatchLaterIcon className={`report-status`} fontSize="small" />
      </div>
    );
  } else if (params === "Failure") {
    // If parameters indicate failure, render a cancel/error icon
    return (
      <div title={reportStatusMessages[params]} className="side-icons">
        <CancelIcon className={`report-status ${params}`} fontSize="small" />
      </div>
    );
  } else {
    // If parameters do not match any expected values, render null
    return null;
  }
};


  const expiryDateCalculation = (time) => {
    const remainingDays = expiryDaysCalculation(time);
    const expiryDetails = getExpiryDetails(remainingDays);
    return (
      <div>
        <div className="report_table">
          <img src={expiryDetails.icon} alt="expiry" />
          <div className={expiryDetails.colorClassName}>
            Expires in {remainingDays} days
            <div>at {formatDateAndTime(time, "MM-DD-YYYY")}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderNoOfPages = (params) => {
    if (isNull(params.numberOfRows)) {
      return renderNull();
    } else {
      return (
        <div className='report_pages'>
          <div className='report_titles noOfPages'>{params.numberOfRows}</div>
          {expiryDateCalculation(params.expirationTime)}
        </div>
      )
    }
  };

  const handleShareHistory = (rowData) => {
    if (rowData.status === "Success") {
      handleCloseReportPopup();
      dispatch({ type: OPEN_REPORT_HISTORY, payload: rowData });
    } else {
    return null;
    }
  };
  const handleViewReport = (rowData) => {
    if (rowData.status === "Success") {
      const queryParams = new URLSearchParams();
      queryParams.set("id", rowData.reportId);
      const url = viewReportURL(queryParams);
      // Open the URL in a new tab
      window.open(url, "_blank");
    } else {
      return null;
    }
  };
  const handleOpenReportPopup = (data, event) => {
    setRowData(data);
    setSelectedRow(data.reportId);
    setAnchorEl(event.currentTarget);
  }
  const renderViewHistory = () => {
    return (
      <AlertDrawer
        viewReportHistory={viewReportHistory}
        loc={"View-Report-History"}
      ></AlertDrawer>
    );
  };
  const handleCloseReportPopup = () => {
    setAnchorEl(null);
  };

  const handleViewReportPopUpTitle = (params) => {
    // Define a mapping between params values and titles
    const reportGenerationProgressMessage = {
      "In-Progress": "Report Generation In Progress",
      "Not-Connected": "Report Generation Not Started",
      "Failure": "Report Generation Failed",
    };
  
    // Return the corresponding title from the map, or a default value if not found
    return reportGenerationProgressMessage[params] || "";
  };
  
  const PopupContent = (rowId) => {
    const rowData = reportData.find((row) => row.reportId === rowId);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const successStatus = !isEmpty(rowData) && rowData.status === "Success";
  
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseReportPopup}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="reportRepositoryPopover"
      >
        <div className="viewHistoryPopup">
          <div className="popup-content">
            <div className={`popup-images viewIcon ${successStatus ? "Success" : ""}`}>
              <img
                src={successStatus ? viewReport : viewReportDisabled}
                alt="View Report"
                title={successStatus ? "View Report" : handleViewReportPopUpTitle(rowData?.status)}
                onClick={() => handleViewReport(rowData)}
              />
            </div>
            <div className={`popup-images ${successStatus ? "Success" : ""}`}>
              <img
                src={successStatus ? historyIcon : historyDisabledIcon}
                alt="View History"
                title={successStatus ? "View History" : handleViewReportPopUpTitle(rowData?.status)}
                onClick={() => handleShareHistory(rowData)}
              />
            </div>
          </div>
        </div>
      </Popover>
    );
  };
  
  const handleChange = (e) => {
    setSearchReportName(e.target.value);
  }
  const handleSearch = () => {
    if (!isEmpty(searchReportName)) {
      setCurrentPage(0);
      setRecordsPerPage(20);
      setSearchClicked(true);
      setLoaderOnTable(true);
      dispatch({
        type: SEARCH_REPORT_DATA, payload: {
          pageSize: 20,
          page: 0,
          searchReport: searchReportName,
        }
      })
      setRowSelected([]);
    }
  }
  const handleClearSearch = () => {
    setSearchReportName("");
    setCurrentPage(0);
    setRecordsPerPage(20);
    setSearchClicked(true);
    setLoaderOnTable(true);
    dispatch({
      type: FETCH_REPORT_DATA,
      payload: { pageSize: 20, page: 0 }
    });
    setRowSelected([]);
  }
  const handleClearCheck = () => {
    // Create a new array with a shallow copy of reportData
    const list = [...reportData];
    // Iterate over the list to find the checked items
    list.forEach((item) => {
      if (item.checked) {
        // Set the 'checked' property of all items to false
        item.checked = false;
      }
    });
    // Update the rowSelected state with the reportIds of checked items
    setRowSelected(list.filter((item) => item.checked).map((i) => i.reportId));
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };
  const renderSearchBar = () => {
    return (
      <>
        <div className='repositorySearch'>
          <div className='reportSearchBar'>
            <Input
              value={searchReportName}
              type='text'
              placeholder='Search here...'
              onKeyPress={(event) => handleKeyPress(event)}
              disableUnderline
              className={classes.reportSearchInput}
              onChange={(e) => handleChange(e)}
            />
            <Button
              className="searchCancelIcon"
              onClick={(event) => {
                handleClearSearch()
              }}
              aria-label="close"
            >
              <img src={CloseIcon} alt={'close'} />
            </Button>
            <hr />
            <Button
              className="searchIcon"
              onClick={() => {
                handleSearch()
              }}
              aria-label="close"
            >
              <img src={SearchIcon} alt={'close'} />
            </Button>
          </div>
          <div className='newReportLink'>
          <div className="Generate-button-container">
          <Button
            variant="contained"
            onClick={() => history.push("/tenant/generateReport")}
            className="generate_report_button generate_report_repository"
          >Generate Report</Button></div>
            <ShareReport rowSelected={rowSelected} handleClearCheck={handleClearCheck} loc={"reportRepository"} />
          </div></div>
      </>
    )
  }
  const handleSelectionModelChange = (event) => {
    // Create a new array with a shallow copy of reportData
    const list = [...reportData];
    
    // Track the number of selected rows
    let selectedCount = 0;

    // Update the 'checked' property for each item in the list based on the status
    list.forEach((item) => {
      if (item.status === "Success" && selectedCount < 5) {
        // Set the 'checked' property of the item based on the event target's checked status
        item.checked = event.target.checked;
        // Increment the selected count if the item is checked
        if (item.checked) {
          selectedCount++;
        }
      } else {
        item.checked = false;
      }
    });
  
    // Update the rowSelected state with the reportIds of checked items
    setRowSelected(list.filter((item) => item.checked).map((i) => i.reportId));
  };
  
  const handleCheckItem = (item) => {
    // Extract the reportId from the item
    const id = item.reportId;
    // Create a new array with a shallow copy of reportData
    const list = [...reportData];
    // Iterate over the list to find the item with the matching reportId
    list.forEach((item) => {
      if (item.reportId === id) {
        // Toggle the 'checked' property of the found item
        item.checked = !item.checked;
      }
    });
    // Update the rowSelected state with the reportIds of checked items
    setRowSelected(list.filter((item) => item.checked).map((i) => i.reportId));
  };
  const renderTable = () => {
    return (
      <EnhancedTable
          cols={ReportRepositoryHeadCells(
            renderReportName, 
            renderGeneratedOn, 
            renderTimeRange, 
            renderGeneratedBy, 
            renderContent, 
            renderReportGenerationStatus,
            renderNoOfPages, 
            handleSelectionModelChange, 
            handleCheckItem, 
            reportData, 
            rowSelected)}
          data={reportData}
          totalCount={totalReportsCount}
          loc={"report"}
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
          onTableRowClick={(event, data) => handleOpenReportPopup(event, data)}
        />
    )
  }

  const renderReportRepositoryTable = () => {
    return (
      <>
        {renderSearchBar()}
        <div className="custom-data-grid-main report">
          {renderTable()}
          {selectedRow !== null && PopupContent(selectedRow)}
          {renderViewHistory()}
        </div>
      </>
    );
  }
  const renderMessage = () => {
    return (
      <>
        {shareReportMessage && shareReportMessage.status && shareReportMessage.message && (
          <>
          {errorSnackbar(openSnackBar, handlePopUpClose, shareReportMessage.message, shareReportMessage.status)}
          </>
        )}
      </>
    )
  }

  const renderContentTable = () => {
    if (loader && !loaderOnTable) {
      return null;
    } else {
      return renderReportRepositoryTable();
    }
  }
  return (
    <>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source reports"
        ></img>
      )}
      {renderContentTable()}
      {renderMessage()}
    </>
  )
}

export default ReportRepositoryTable