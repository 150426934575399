import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { dataFlowStaData } from "../../assets/staticData/dataFlow";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import LevelComponent from "./levelComponent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  fetchDMDataFlows,
  fetchDMDataFlowsCount,
  fetchDMNodesByCubeNodes,
} from "../../redux/actions/dataMapInspectAction";
import TransactionIcon from "../../assets/vizr_images/ic_transaction.svg";
import {
  fetchDMContentTabChildNodes,
  fetchCubeDataSources,
} from "../../redux/actions/DataMapContentTabAction";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fetchApplicationData } from "../../redux/actions/getAlertsAction";
import { fetchAllDataSources } from "../../redux/actions/getAlertsAction";

import {
  Button,
  Icon,
  TextField,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
} from "@material-ui/core";

import LoaderComponent from "./LoaderComponent";
import { cubeQueryGen, cubeQueryGenCount } from "../common/utilities";
import { getCubejsApi , redirectToLogin} from "../common/Dashboard/cubejs-util";
import { useSelector } from "react-redux";
import ImageTooltip from "../common/HelpIconWithTooltip";

function createData(
  name,
  //url,
  //description,
  //subSer,
  sensitivity,
  transactions,
  //count,
  transactionsTimings,
  id
) {
  return {
    name,
    //url,
    //description,
    //subSer,
    sensitivity,
    transactions,
    //count,
    transactionsTimings,
    id,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "dataFlowName",
    numeric: false,
    disablePadding: true,
    label: "Data Flow Name",
  },
  //{ id: 'url', numeric: false, disablePadding: false, label: 'URL' },
  //{ id: 'subServicesTraceComponent', numeric: true, disablePadding: false, label: 'Sub services / Trace Component' },
  {
    id: "dataSensitivity",
    numeric: false,
    disablePadding: false,
    label: "Data Sensitivity",
  },
  {
    id: "transactions",
    numeric: true,
    disablePadding: false,
    label: "",
    icon: TransactionIcon,
  },
  //{ id: 'count', numeric: false, disablePadding: false, label: 'Count', icon: "" },
  {
    id: "duration",
    numeric: true,
    disablePadding: false,
    label: "Duration",
    icon: "",
    tag: "milliseconds",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="enhanced-table-head">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={"default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ color: "#ffffff" }}
          >
            {headCell && headCell.orderId ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label && headCell.tag ? (
                  <span>{`${headCell.label} (${headCell.tag})`}</span>
                ) : (
                  <span>{headCell.label}</span>
                )}
                {headCell.icon && <img src={headCell.icon} />}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <>
                {headCell.label && headCell.tag ? (
                  <span>{`${headCell.label} (${headCell.tag})`}</span>
                ) : (
                  <span>{headCell.label}</span>
                )}
                {headCell.icon && <img src={headCell.icon} />}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    color: "#081981",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    inputRoot: {
      color: "#081981",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    year: {
      paddingLeft: `calc(1em + ${theme.spacing(0.5)}px)`,
      width: "342px !important",
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    createNewFlowGroupClick,
    getToolbarFilter,
    handleSelectedApp,
    dataFlowsListCount,
  } = props;
  const searchCriteria = [
    { code: "Clear Filter", value: "" },
    { code: "Application", value: "DataFlowsByApplication.applicationId" },
    { code: "Data Source", value: "DataFlowsByApplication.nodeId" },
    //{ code: "Data Flow Name", value: "DataFlowsByApplication.dataFlowName"},
  ];
  //const optionsFor = ["Application","Data Source","Data Flow Name"]
  const [optionsFor, setOptionsFor] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  const [searchOptionSelected, setSearchOptionSelected] = useState("");
  const [applications, setApplications] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [selectedOpt, setSelectedOpt] = useState("");

  useEffect(() => {
    if (searchBy) {
      if (searchBy === "Application") {
        //api call for applications
        const getApplications = async () => {
          const res = await fetchApplicationData();
          let temp = [];
          if (res && res.content) {
            res.content.forEach((each) => {
              temp.push({ name: each.name, id: each.id });
            });
            setApplications(temp);
            setOptionsFor(temp);
          } else {
            alert("Error  fetching data");
          }
        };
        getApplications();
      } else if (searchBy === "Data Flow Name") {
        //api call for data flow name to get the id i.e nodeId
      } else if (searchBy === "Data Source") {
        //api call for data sources -> cubeJs
        async function getAllDataSources() {
          const dimensionType = ["nodeId", "nodeName", "nodeType"];
          const queryType = "multi";
          const queryString = cubeQueryGen("NodesOfApplication", dimensionType);
          const response = await fetchCubeDataSources(queryString, queryType);
          let temp = [];
          if (response.data.length > 0) {
            response.data.forEach((each) => {
              temp.push(each[`NodesOfApplication.nodeId`]);
            });
            let reqBody = { nodeIds: temp };
            let resp = await fetchDMNodesByCubeNodes(reqBody);
            let nodesList = [];
            if (resp.nodes.length > 0) {
              resp.nodes.forEach((each) => {
                if (each.type.code === "DATASOURCE") {
                  nodesList.push({ name: each.name, id: each.id });
                }
              });
              setOptionsFor(nodesList);
            } else {
              setOptionsFor([]);
            }
          }
        }
        getAllDataSources();
      } else {
        getToolbarFilter("", "clear");
        if (handleSelectedApp) {
          handleSelectedApp("");
        }
      }
    }
  }, [searchBy]);

  useEffect(() => {
    if (searchBy === "Clear Filter") {
      getToolbarFilter("", "clear");
      if (handleSelectedApp) {
        handleSelectedApp("");
      }
    }
  }, [searchBy]);

  const handleSelectedOption = (name) => {
    if (searchBy === "Application") {
      applications.forEach((each) => {
        if (each.name === name) {
          //setSearchOptionSelected(each.id)
          getToolbarFilter(each.id, "Application");
        }
      });
    } else if (searchBy === "Data Source") {
      dataSources.forEach((each) => {
        if (each.name === name) {
          //setSearchOptionSelected(each.id)
          getToolbarFilter(each.id, "Data Source");
        }
      });
    } else {
      //data flow name search
    }
  };

  useEffect(() => {
    if (searchBy === "Clear") {
      getToolbarFilter("", "clear");
    }
  }, [searchBy]);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span
          flexGrow={1}
          style={{ fontSize: "18px", fontWeight: "600", marginTop: "12px" }}
        >
          Data Flows
        </span>
        &nbsp;&nbsp;
        <p style={{ minWidth: "45px" }}>Total :</p>
        <div className="dataMap-tray-count">{dataFlowsListCount}</div>&nbsp;
        <span style={{ marginTop: "6px" }}>
          <ImageTooltip description={"Data flows in the system."} />
        </span>
      </Typography>
      <div style={{ display: "flex" }}>
        <h3 className="margin" style={{ marginTop: "8px", width: "65px" }}>
          Search By:
        </h3>
        <Select
          value={searchBy}
          onChange={(e) => {
            setSearchOptionSelected("");
            setSearchBy(e.target.value);
            setOptionsFor([]);
          }}
          className="InputSelectFeild"
          style={{ width: "200px" }}
        >
          {searchCriteria && searchCriteria.length > 0
            ? searchCriteria.map((el, index) => (
                <MenuItem value={el.code} id={el.value} name={el.code}>
                  {el.code}
                </MenuItem>
              ))
            : null}
        </Select>
      </div>
      {searchBy === "Data Flow Name" && (
        <div className={classes.search}>
          <InputBase
            classes={{
              root: classes.inputRoot,
              input: `${classes.inputInput} ${classes.year}`,
            }}
            inputProps={{ "aria-label": "application" }}
          />
          <Grid
            style={{
              display: "flex",
              width: "11rem",
              height: "5rem",
            }}
            onChange={() => {
              console.log("change");
            }}
          >
            <Autocomplete
              style={{
                width: "11rem",
              }}
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              onInputChange={async (event) => {
                handleSelectedOption(event.target.innerHTML);
              }}
              options={
                optionsFor
                // applications
                //   ? applications.map((option) => {
                //     //console.log(option.name);
                //     return option.name;
                //   })
                //   : "Error Fetching Options"
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  label={`Search ${searchBy}`}
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                  InputProps={{ ...params.InputProps, type: "search" }}
                  value={searchOptionSelected}
                />
              )}
            />
          </Grid>
        </div>
      )}
      {(searchBy === "Data Source" || searchBy === "Application") && (
        <Select
          value={selectedOpt}
          onChange={(e) => {
            setSelectedOpt(e.target.value);
            getToolbarFilter(e.target.value["id"], searchBy);
            if (handleSelectedApp) {
              handleSelectedApp(e.target.value, searchBy);
            }
            //setSearchOptionSelected(e.target.value)
          }}
          className="InputSelectFeild"
          style={{ width: "200px" }}
        >
          {optionsFor && optionsFor.length > 0
            ? optionsFor.map((el, index) => (
                <MenuItem value={el} key={index}>
                  {el.name}
                </MenuItem>
              ))
            : null}
        </Select>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function DataMapDataFlow(props) {
  const {
    transactionsTabHandle,
    selectedAppByType,
    dataFlowNameClick,
    selectedApp,
    handleSelectedApp,
  } = props;
  const classes = useStyles();
  let initDFList = {
    apiSucc: null,
    data: [],
    apiProgress: null,
  };
  const cubejsApi = getCubejsApi();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [pagesLoaded, setPagesLoaded] = useState([`${0}`]);
  const [dataFlowsListData, setDataFlowsListData] = useState(initDFList);
  const [dataFlowsListCount, setDataFlowsListCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchFilterType, setSearchFilterType] = useState("");
  const [nodeList, setNodeList] = useState([]);
  const selectedNode = useSelector(
    (state) => state.selectedNode.selectedNodeId
  );

  useEffect(() => {
    getNodeList();
  }, [selectedNode]);
  useEffect(() => {
    if (nodeList && nodeList.length > 0) {
      getAllDMFlows(rowsPerPage, page);
      getDMFlowsCount();
    }

    return () => {};
  }, [nodeList]);

  async function getNodeList() {
    let nodes = [];
    const queryType = "multi";
    const nodeQuery = {
      dimensions: ["Nodes.nodeId"],
      timeDimensions: [],
      order: {},
      filters: [],
    };
    if (selectedNode) {
      nodeQuery.filters.push({
        member: "Nodes.parentId",
        operator: "equals",
        values: [selectedNode],
      });
    }
    const nodesResponse = await fetchDMContentTabChildNodes(
      nodeQuery,
      queryType
    );
    if (nodesResponse) {
      nodesResponse.length &&
        nodesResponse.forEach((each) => {
          nodes.push(each[`Nodes.nodeId`]);
        });
      if (selectedNode) {
        nodes.push(selectedNode);
      }
      setNodeList(nodes);
    }
  }

  async function getDMFlowsCount(filter, filterType) {
    setDataFlowsListCount(0);

    // let nodes = [];
    // const queryType = "multi"
    // const nodeQuery = {
    //   "dimensions": [
    //     "Nodes.nodeId"
    //   ],
    //   "timeDimensions": [],
    //   "order": {},
    //   "filters": []
    // };
    // if (selectedNode) {
    //   nodeQuery.filters.push({
    //     "member": "Nodes.parentId",
    //     "operator": "equals",
    //     "values": [
    //       selectedNode
    //     ]
    //   })
    // }
    // const nodesResponse = await fetchDMContentTabChildNodes(nodeQuery, queryType);
    if (nodeList && nodeList.length > 0) {
      // nodesResponse.results[0].data.map((each) => {
      //   nodes.push(each[`Nodes.nodeId`])
      // })
      // if (selectedNode) {
      //   nodes.push(selectedNode)
      // }

      const dimensionType = ["dataFlowCount"];
      let filtersList = [];
      let nodes = [...nodeList];
      if (selectedApp && selectedApp.id) {
        filtersList = [
          {
            dimensionName: "DataFlowsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [selectedApp.id],
          },
        ];
      }
      if (filter && filterType) {
        if (filterType === "Application") {
          filtersList.push({
            dimensionName: "DataFlowsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [filter],
          });
        } else if (filterType === "Data Source") {
          nodes.push(filter);
        }
      }
      if (
        searchFilter &&
        searchFilterType &&
        (filterType === "" || !filterType)
      ) {
        if (searchFilterType === "Application") {
          filtersList.push({
            dimensionName: "DataFlowsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [searchFilter],
          });
        } else if (searchFilterType === "Data Source") {
          nodes.push(searchFilter);
        }
      }
      if (nodes && nodes.length > 0) {
        filtersList.push({
          dimensionName: "DataFlowsByApplication",
          dimensionType: "nodeId",
          operator: "equals",
          values: nodes,
        });
      }
      const queryString = cubeQueryGenCount(
        "DataFlowsByApplication",
        dimensionType,
        filtersList
      );
      // const respCount = await fetchDMDataFlowsCount(queryString, "multi");
      let respCount ;
      try {
        const resultSet = await cubejsApi.load(queryString);
        respCount = resultSet.rawData();
      } catch (error) {
        redirectToLogin(error)
      }

      if (
        respCount.length > 0 &&
        respCount[0]["DataFlowsByApplication.dataFlowCount"]
      ) {
        setDataFlowsListCount(
          respCount[0]["DataFlowsByApplication.dataFlowCount"]
        );
      } else {
        setDataFlowsListCount(0);
      }
    }
  }

  async function getAllDMFlows(rowsPerPage, page, filter, filterType) {
    initDFList = {
      apiSucc: null,
      data: [],
      apiProgress: true,
    };
    setDataFlowsListData(initDFList);

    // let nodes = [];
    // const queryType = "multi"
    // const nodeQuery = {
    //   "dimensions": [
    //     "Nodes.nodeId"
    //   ],
    //   "timeDimensions": [],
    //   "order": {},
    //   "filters": []
    // };
    // if (selectedNode) {
    //   nodeQuery.filters.push({
    //     "member": "Nodes.parentId",
    //     "operator": "equals",
    //     "values": [
    //       selectedNode
    //     ]
    //   })
    // }
    // const nodesResponse = await fetchDMContentTabChildNodes(nodeQuery, queryType);
    if (nodeList && nodeList.length > 0) {
      // nodesResponse.results[0].data.map((each) => {
      //   nodes.push(each[`Nodes.nodeId`])
      // })
      // if (selectedNode) {
      //   nodes.push(selectedNode)
      // }
      const dimensionType = [
        "dataFlowName",
        "url",
        "dataSensitivity",
        "numberOfTransactions",
        "dataFlowId",
        "duration",
      ];
      let filtersList = [];
      let nodes = [...nodeList];
      if (selectedApp && selectedApp.id) {
        filtersList = [
          {
            dimensionName: "DataFlowsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [selectedApp.id],
          },
        ];
      }
      if (filter && filterType) {
        if (filterType === "Application") {
          filtersList.push({
            dimensionName: "DataFlowsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [filter],
          });
        } else if (filterType === "Data Source") {
          nodes.push(filter);
        }
      }
      if (
        searchFilter &&
        searchFilterType &&
        (filterType === "" || !filterType)
      ) {
        if (searchFilterType === "Application") {
          filtersList.push({
            dimensionName: "DataFlowsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [searchFilter],
          });
        } else if (searchFilterType === "Data Source") {
          nodes.push(searchFilter);
        }
      }
      if (nodes && nodes.length > 0) {
        filtersList.push({
          dimensionName: "DataFlowsByApplication",
          dimensionType: "nodeId",
          operator: "equals",
          values: nodes,
        });
      }
      const queryString = cubeQueryGen(
        "DataFlowsByApplication",
        dimensionType,
        filtersList
      );
      queryString["limit"] = rowsPerPage;
      queryString["offset"] = page * rowsPerPage;
      // const response = await fetchDMDataFlows(queryString, "multi");
      let response;
      try {
        const resultSet = await cubejsApi.load(queryString);
        response = resultSet.rawData();
      } catch (error) {
        redirectToLogin(error)
      }
      if (response.length) {
        handleDataFlowsListData(response);
      } else {
        initDFList = {
          apiSucc: true,
          data: [],
          apiProgress: null,
        };
        setDataFlowsListData(initDFList);
      }
    }
  }

  const selectTransactionHandle = (dataFlowItem, name) => {
    if (name === "dataFlow") {
      dataFlowNameClick(dataFlowItem);
    } else {
      transactionsTabHandle(dataFlowItem);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (pagesLoaded.length > 0) {
      // let startInd = rowsPerPage * newPage
      // let endInd = (rowsPerPage * newPage) + rowsPerPage;
      // const pagiList = dataFlowsListDataApi.slice(startInd, endInd)
      // setDataFlowsListData(pagiList);
      setPage(newPage);
      getAllDMFlows(rowsPerPage, newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // let startInd = 0
    // let endInd = parseInt(event.target.value, 10)
    // const pagiList = dataFlowsListDataApi.slice(startInd, endInd)
    // setDataFlowsListData(pagiList);
    setPage(0);
    getAllDMFlows(parseInt(event.target.value, 10), 0);
  };

  const getToolbarFilter = (filter, filterType) => {
    getAllDMFlows(rowsPerPage, page, filter, filterType);
    getDMFlowsCount(filter, filterType);
    setSearchFilter(filter);
    setSearchFilterType(filterType);
  };

  const handleDataFlowsListData = (data) => {
    const newD = [];
    let sensitivity = "";
    data.forEach((item) => {
      if (item["DataFlowsByApplication.dataSensitivity"] === 0) {
        sensitivity = "LOW";
      } else if (item["DataFlowsByApplication.dataSensitivity"] === 1) {
        sensitivity = "MEDIUM";
      } else if (item["DataFlowsByApplication.dataSensitivity"] === 2) {
        sensitivity = "HIGH";
      } else {
        sensitivity = "-";
      }
      // item.subSer = `-`;
      // item.transactionsTimings = '-';
      // item.count = '-';
      newD.push(
        createData(
          item["DataFlowsByApplication.dataFlowName"],
          //item['DataFlowsByApplication.url'],
          //'-',
          //item.subSer,
          sensitivity,
          item["DataFlowsByApplication.numberOfTransactions"],
          //item.count,
          item["DataFlowsByApplication.duration"],
          item["DataFlowsByApplication.dataFlowId"]
        )
      );
    });
    initDFList = {
      apiSucc: true,
      data: newD,
      apiProgress: null,
    };
    setDataFlowsListData(initDFList);
  };

  return (
    <div className="transcation-container">
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleSelectedApp={handleSelectedApp}
          getToolbarFilter={getToolbarFilter}
          dataFlowsListCount={dataFlowsListCount}
        />
        <TableContainer className="inspect-height">
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={dataFlowsListData.data.length}
            />
            {dataFlowsListData.apiProgress ? (
              <TableBody>
                <LoaderComponent />
              </TableBody>
            ) : (
              <React.Fragment>
                {!dataFlowsListData.apiProgress &&
                dataFlowsListData.data.length ? (
                  <TableBody>
                    {stableSort(
                      dataFlowsListData.data,
                      getComparator(order, orderBy)
                    ).map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow hover tabIndex={-1} key={row.name}>
                          <TableCell component="th" id={labelId} scope="row">
                            <a
                              className="text-decoration-none"
                              href="javascript:void(0)"
                              onClick={() =>
                                selectTransactionHandle(row, "dataFlow")
                              }
                            >
                              {row.name}
                            </a>
                          </TableCell>
                          {/* <TableCell align="left">{row.url}</TableCell> */}
                          <TableCell align="left">
                            <LevelComponent
                              level={row.sensitivity}
                              Id={row.dataSensitivity}
                            />
                          </TableCell>

                          <TableCell align="center">
                            <a
                              className="text-decoration-none"
                              href="javascript:void(0)"
                              onClick={() =>
                                selectTransactionHandle(row, "traces")
                              }
                            >
                              {row.transactions}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            {Math.round(
                              (row.transactionsTimings + Number.EPSILON) * 100
                            ) / 100}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        No Data Available
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>
        {Number(dataFlowsListCount) > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 20, 50, 100, 200]}
            component="div"
            count={dataFlowsListCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
