import moment from "moment";

//Dashboard Instance for cloud sources
export const cloudFileSystemDashBoardInstance = (source) => {
  const DashBoardInstance = [
    {
      id: 0,
      name: "Total Alerts",
      vizState: {
        chartType: "dataAssetsDonut",
        query: [
          {
            "measures": [
              "Alerts.count"
            ],
            "filters": [
              {
                "member": "Alerts.datasourceType",
                "operator": "equals",
                "values": [
                  source
                ]
              }
            ],
          },
          {
            "dimensions": [
              "Alerts.severity"
            ],
            "filters": [
              {
                "member": "Alerts.datasourceType",
                "operator": "equals",
                "values": [
                  source
                ]
              }
            ],
            "measures": [
              "Alerts.count"
            ]
          },
        ],
      },
      size: 4,
      row: "medium-row",
    },
    
    {
      id: 1,
      name: "Sensitivity File Count",
      vizState: {
        chartType: "heatMapLegend",
        query:
        {
          "dimensions": [
            "Assets.category"
          ],
          "timeDimensions": [],
          "order": {
            "Assets.count": "desc"
          },
          "filters": [
            {
              member: "Assets.datasourceType",
              operator: "equals",
              values: [source],
            },
          ],
          "measures": [
            "Assets.count"
          ]
        }
      },
      size: 4,
      row: "medium-row",
    },
    {
      id: 2,
      name: "Alerts By Users",
      vizState: {
        chartType: "dataAssetsDonut",
        query: [
          {
            measures: [
              "ActorAlerts.userCount"
            ],
            filters: [
              {
                member: "ActorAlerts.datasourceType",
                operator: "equals",
                values: [
                source
               ]
              }
            ]
          },
          {
            "measures": [
              "ActorAlerts.userCount"
            ],
            "order": {
              "ActorAlerts.userCount": "desc"
            },
            "dimensions": [
              "ActorAlerts.severityCode"
            ],
            "filters": [
              {
                "member": "ActorAlerts.datasourceType",
                "operator": "equals",
                "values": [
                  source
                ]
              }
            ]
          }
        ],
        location: 'oneDriveUserWithAlerts'
      },
      size: 4,
      row: "medium-row",
    },
    {
      id: 3,
      name: "Total Users By Roles",
      vizState: {
        chartType: "userRoles",
        queries: [
          {
            "measures": [
              "UserRoles.totalCount"
            ],
            "filters": [
              {
                "member": "UserRoles.dataSourceType",
                "operator": "equals",
                "values": [
                  source
                ]
              },
              {
                "member": "UserRoles.userIsInActive",
                "operator": "equals",
                "values": [
                  "false"
                ]
              }
            ]
          },
          {
            measures: ["UserRoles.totalCount"],
            dimensions: [
              "UserRoles.roleName",
              "UserRoles.dataSourceType"
            ],
            timeDimensions: [],
            order: {
              "UserRoles.totalCount": "desc"
            },
            limit: 5,
            offset: 0,
            filters: [
              {
                member: "UserRoles.dataSourceType",
                operator: "equals",
                values: [
                  source
                ]
              },
              {
                "member": "UserRoles.userIsInActive",
                "operator": "equals",
                "values": [
                  "false"
                ]
              }
            ]
          }
        ],
      },
      size: 4,
      row: "third-row",
    },
    {
      id: 4,
      name: "Total Groups",
      vizState: {
        chartType: "totalGroups",
        query:[
          {
            "measures": [
              "Groups.group_count"
            ],
            "filters": [
              {
                member: "Groups.dataSourceType",
                operator: "equals",
                values: [source],
              },
              {
                "member": "Groups.userIsInActive",
                "operator": "equals",
                "values": [
                  "false"
                ]
              }
            ],
          },
          {
            "dimensions": [
              "Groups.groupName"
            ],
            "order": {
              "Groups.user_count": "desc"
            },
            "filters": [
              {
                member: "Groups.dataSourceType",
                operator: "equals",
                values: [source],
              },
              {
                "member": "Groups.userIsInActive",
                "operator": "equals",
                "values": [
                  "false"
                ]
              }
            ],
            "measures": [
              "Groups.user_count"
            ],
            "limit": '5',
          }
        ]
      },
      size: 4,
      row: "third-row",
    },
    {
      id: 5,
      name: "Sensitive Data Groups",
      vizState: {
        chartType: "SensitiveGroups",
        query: [
          {
            measures: [
              "SensitiveGroups.totalSensitiveGroupCount",
              "SensitiveGroups.sensitiveFileCount"
            ],
            filters: [
              {
                member: "SensitiveGroups.sensitiveCategory",
                operator: "equals",
                values: [
                  "HIGH",
                  "MEDIUM"
                ]
              },
              {
                "member": "SensitiveGroups.userIsInActive",
                "operator": "equals",
                "values": [
                  "false"
                ]
              },
              {
                "member": "SensitiveGroups.dataSourceType",
                "operator": "equals",
                "values": [
                  source
                ]
              },
            ]
          },
          {
            measures: [
              "SensitiveGroups.sensitiveFileCount"
            ],
            dimensions: [
              "SensitiveGroups.enterpriseGroupName"
            ],
            order: {
              "SensitiveGroups.sensitiveFileCount": "desc"
            },
            filters: [
              {
                member: "SensitiveGroups.sensitiveCategory",
                operator: "equals",
                values: [
                  "HIGH",
                  "MEDIUM"
                ]
              },
              {
                "member": "SensitiveGroups.userIsInActive",
                "operator": "equals",
                "values": [
                  "false"
                ]
              },
              {
                "member": "SensitiveGroups.dataSourceType",
                "operator": "equals",
                "values": [
                  source
                ]
              },
            ],
            limit: 5,
            offset: 0
          },
        ],
        location: "dataMap",
      },
      size: 4,
      row: "sensitiveGroupWidget",
    },
  ]
  return DashBoardInstance
}

const devopsDashboardInstance = [
  {
    id: 0,
    name: "Agents",
    vizState: {
      queries: [
        { measures: ["Agents.numberOfAgents"] },
        {
          measures: ["Agents.numberOfAgents"],
          timeDimensions: [
            {
              dimension: "Agents.date",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "number - Agents",
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 1,
    name: "Nodes",
    vizState: {
      queries: [
        { measures: ["Node.countOfNodes"], segments: ["Node.parentNodes"] },
        {
          measures: ["Node.countOfNodes"],
          segments: ["Node.parentNodes"],
          timeDimensions: [
            {
              dimension: "Node.creationTime",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "number - Nodes",
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 2,
    name: "Data Sources",
    vizState: {
      queries: [
        {
          measures: ["DataSources.count"],
        },
        {
          measures: ["DataSources.count"],
          timeDimensions: [
            {
              dimension: "DataSources.date",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "number - data sources",
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 3,
    name: "Open Health Alerts",
    vizState: {
      chartType: "number - open health",
      queries: [
        {
          measures: ["AgentHealth.count"],
        },
        {
          measures: ["AgentHealth.count"],
          timeDimensions: [
            {
              dimension: "AgentHealth.date",
              granularity: "day",
            },
          ],
        },
      ],
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 4,
    name: "Health/agent alerts over time",
    vizState: {
      // query: {
      //   measures: ["AgentHealth.count"],
      //   timeDimensions: [{ dimension: "AgentHealth.date", granularity: "day" }],
      //   dimensions: ["AgentHealth.severityCode"],
      // },
      // colors: {
      //   HIGH: "#FF2251",
      //   LOW: "#91DFFF",
      //   MEDIUM: "#FFC344",
      // },
      chartType: "healthAgentAlertsOverTime",
    },
    size: 6,
    row: "second-row",
  },
  {
    id: 5,
    name: "Traces over time",
    vizState: {
      chartType: "area",
      query: {
        measures: ["Traces.count"],
        timeDimensions: [{ dimension: "Traces.date", granularity: "day" }],
      },
    },
    size: 6,
    row: "second-row",
  },

  {
    id: 6,
    name: "Alerts by service name",
    vizState: {
      chartType: "donut",
      query: {
        dimensions: ["AlertsByService.name"],
        measures: ["AlertsByService.count"],
      },
    },
    size: 3,
    row: "thrid-row",
  },
  {
    id: 7,
    name: "Agent health by application name",
    vizState: {
      chartType: "donut",
      query: {
        dimensions: ["AgentHealth.name"],
        measures: ["AgentHealth.count"],
      },
    },
    size: 3,
    row: "thrid-row",
  },
  {
    id: 8,
    name: "Traces by data flows",
    vizState: {
      chartType: "donut",
      query: {
        // DataFlowGroups
        measures: ["Traces.count"],
        dimensions: ["Traces.dataFlowName"],
        limit: 10,
      },
    },
    size: 3,
    row: "thrid-row",
  },
  {
    id: 9,
    name: "Traces by flow groups",
    vizState: {
      chartType: "donut",
      query: {
        // DataFlowGroups
        measures: ["Traces.count"],
        dimensions: ["Traces.dataFlowGroupName"],
        limit: 10,
      },
    },
    size: 3,
    row: "thrid-row",
  },
];

const dashboardInstance = [
  {
    id: 0,
    name: "Active Alerts",
    vizState: {
      queries: [
        {
          measures: ["Alerts.count"],
          segments: ["Alerts.unresolvedAlerts"],
        },
        {
          measures: ["Alerts.count"],
          segments: ["Alerts.unresolvedAlerts"],
          timeDimensions: [
            {
              dimension: "Alerts.date",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "number - Alerts",
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 1,
    name: "Traces",
    vizState: {
      chartType: "number - Transactions",
      queries: [
        {
          measures: ["Traces.count"],
        },
        {
          measures: ["Traces.count"],
          timeDimensions: [{ dimension: "Traces.date", granularity: "day" }],
        },
      ],
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 2,
    name: "Sensitive Data Access Traces",
    vizState: {
      chartType: "number - Transactions accessing sensitive data",
      queries: [
        {
          measures: ["SensitiveTransactions.count"],
          filters: [
            {
              member: "SensitiveTransactions.sensitivityCategory",
              operator: "equals",
              values: ["HIGH"],
            },
          ],
        },
        {
          measures: ["SensitiveTransactions.count"],
          timeDimensions: [
            {
              dimension: "SensitiveTransactions.date",
              granularity: "day",
            },
          ],
          filters: [
            {
              member: "SensitiveTransactions.sensitivityCategory",
              operator: "equals",
              values: ["HIGH"],
            },
          ],
        },
      ],
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 3,
    name: "Sensitive Data Sources",
    vizState: {
      queries: [
        {
          measures: ["DataSources.count"],
          filters: [
            {
              member: "DataSources.sensitivityCategory",
              operator: "equals",
              values: ["HIGH", "MEDIUM"],
            },
          ],
        },
        {
          measures: ["DataSources.count"],
          filters: [
            {
              member: "DataSources.sensitivityCategory",
              operator: "equals",
              values: ["HIGH", "MEDIUM"],
            },
          ],
          timeDimensions: [
            {
              dimension: "DataSources.date",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "number - Data Sources with sensitice data",
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 4,
    name: "Alerts over Time",
    vizState: {
      chartType: "alertsOverTimeBarChart",
      // query: {
      //   measures: ["Alerts.count"],
      //   timeDimensions: [{ dimension: "Alerts.date", granularity: "day" }],
      //   dimensions: ["Alerts.severity"],
      // },
      // colors: {
      //   HIGH: "#FF2251",
      //   LOW: "#91DFFF",
      //   MEDIUM: "#FFC344",
      // },
      // chartType: "bar",
    },
    size: 6,
    row: "second-row",
  },
  {
    id: 5,
    name: "Traces over Time",
    vizState: {
      chartType: "area",
      query: {
        measures: ["Traces.count"],
        timeDimensions: [{ dimension: "Traces.date", granularity: "day" }],
      },
    },
    size: 6,
    row: "second-row",
  },
  {
    id: 6,
    name: "Alerts by Severity",
    vizState: {
      chartType: "complianceAlertsByLevelChart",
      // query: {
      //   dimensions: ["Alerts.severity"],
      //   measures: ["Alerts.count"],
      // },
      // chartType: "donut",
      // colors: {
      //   HIGH: "#FF2251",
      //   LOW: "#91DFFF",
      //   MEDIUM: "#FFC344",
      // },
    },
    size: 3,
    row: "thrid-row",
  },
  {
    id: 7,
    name: "Alerts by Policy",
    vizState: {
      query: {
        dimensions: ["Alerts.policyname", "Alerts.alert_policy_id"],
        measures: ["Alerts.count"],
        segments: ["Alerts.unresolvedAlerts"],
      },
      chartType: "donut",
    },
    size: 3,
    row: "thrid-row",
  },
  {
    id: 8,
    name: "Traces by data flows",
    vizState: {
      chartType: "donut",
      query: {
        // DataFlowGroups
        measures: ["Traces.count"],
        dimensions: ["Traces.dataFlowName"],
        limit: 10,
      },
    },
    size: 3,
    row: "thrid-row",
  },
  {
    id: 9,
    name: "Traces by flow groups",
    vizState: {
      chartType: "donut",
      query: {
        // DataFlowGroups
        measures: ["Traces.count"],
        dimensions: ["Traces.dataFlowGroupName"],
        limit: 10,
      },
    },
    size: 3,
    row: "thrid-row",
  },
];

let dashboardInstanceForUser = [
  {
    id: 0,
    name: "Users with Alerts",
    vizState: {
      chartType: "number - Users with Alerts",
      queries: [
        {
          "measures": [
            "ActorAlerts.userCount"
          ]
        },
        {
          measures: ["ActorAlerts.userCount"],
          timeDimensions: [
            {
              dimension: "ActorAlerts.date",
              granularity: "day",
            },
          ],
        },
      ],
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 1,
    name: "Total Users",
    vizState: {
      chartType: "totalUsersCombo",
      queries: [
        {
          measures: ["TotalUsers.count"],
        },
        {
          measures: ["TotalUsers.count"],
          order: [["TotalUsers.date", "asc"]],
          timeDimensions: [
            {
              dimension: "TotalUsers.date",
              granularity: "day",
            },
          ],
        },
      ],
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 2,
    name: "Sensitive Data Users",
    vizState: {
      chartType: "number - Sensitive Data Users",
      queries: [
        {
          measures: ["ActorDataSourceBySensitiveData.userCount"],
        },
        {
          measures: ["ActorDataSourceBySensitiveData.userCount"],
          order: [["ActorDataSourceBySensitiveData.date", "asc"]],
          timeDimensions: [
            {
              dimension: "ActorDataSourceBySensitiveData.date",
              granularity: "day",
            },
          ],
        },
      ],
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 3,
    name: "Sensitive Data Sources",
    vizState: {
      chartType: "number - Sensitive Data Sources",
      queries: [
        {
          measures: ["DataSources.count"],
          filters: [
            {
              member: "DataSources.sensitivityCategory",
              operator: "equals",
              values: ["HIGH", "MEDIUM"],
            },
          ],
        },
        {
          measures: ["DataSources.count"],
          filters: [
            {
              member: "DataSources.sensitivityCategory",
              operator: "equals",
              values: ["HIGH", "MEDIUM"],
            },
          ],
          timeDimensions: [
            {
              dimension: "DataSources.date",
              granularity: "day",
            },
          ],
        },
      ],
    },
    size: 3,
    row: "first-row",
  },
  {
    id: 4,
    name: "Users with Alerts over Time",
    vizState: {
      chartType: "usersWithAlerts",
    },
    size: 6,
    row: "second-row",
  },
  {
    id: 5,
    name: "Sensitive Data Users",
    vizState: {
      chartType: "sensitiveDataUsers",
    },
    size: 6,
    row: "second-row",
  },
  {
    id: 6,
    name: "Users with Alerts by Policy",
    vizState: {
      chartType: "donut",
      query: {
        measures: ["ActorAlerts.count"],
        dimensions: ["ActorAlerts.alertPolicyName"],
      },
    },
    size: 3,
    row: "third-row",
  },
  {
    id: 7,
    name: "Users with Alerts by Severity",
    vizState: {
      chartType: "usersWithAlertsByLevelChart",
    },
    size: 3,
    row: "third-row",
  },
  {
    id: 8,
    name: "Users by Data Source",
    vizState: {
      chartType: "donut",
      query: {
        measures: ["ActorDatasource.userCount"],
        dimensions: [
          "ActorDatasource.aliasName",
          "ActorDatasource.dataSourceId",
        ],
      },
    },
    size: 3,
    row: "third-row",
  },
  {
    id: 9,
    name: "Sensitive Data Users by Data Source",
    vizState: {
      chartType: "donut",
      query: {
        measures: ["ActorDataSourceBySensitiveData.userCount"],
        dimensions: [
          "ActorDataSourceBySensitiveData.aliasName",
          "ActorDataSourceBySensitiveData.dataSourceId",
        ],
        filters: [
          {
            member: "ActorDataSourceBySensitiveData.totalSensitive",
            operator: "gt",
            values: ["0"],
          },
        ],
      },
    },
    size: 3,
    row: "third-row",
  }
];

const calculateStartdateAndEndDate = () => {
  let dateTimeRange = localStorage.getItem("appDateTimeRange");
  console.log(dateTimeRange, "dateTimeRange");
  let startDate = dateTimeRange && JSON.parse(dateTimeRange).startDate;
  let endDate = dateTimeRange && JSON.parse(dateTimeRange).endDate;
  const d = new Date();
  let diff = d.getTimezoneOffset();
  console.log(diff, "diff");
  let startDt = new Date(startDate).getTime() - diff * 60 * 1000; // dateFormatter(startDate, "MM-DD-YYYY HH:mm:ss");
  let endDt = new Date(endDate).getTime() - diff * 60 * 1000; // dateFormatter(endDate, "MM-DD-YYYY HH:mm:ss");
  // let startDt = new Date(startDate).getTime();
  // let endDt = new Date(endDate).getTime();
  localStorage.setItem("startDt", startDt);
  localStorage.setItem("endDt", endDt);
  console.log(startDate, endDate);
  return { startDate: startDt, endDate: endDt };
};

const IncidentdashboardInstance = [
  {
    id: 0,
    name: "Events: Total",
    vizState: {
      queries: [
        {
          // measures: ["Incidents.totalEventCount"],
          measures: ["IncidentsEventCount.totalEventCount"],
        },
        {
          // dimensions: ["Incidents.eventCount"],
          // measures: ["Incidents.totalEventCount"],
          measures: ["IncidentsEventCount.totalEventCount"],
          timeDimensions: [
            {
              dimension: "IncidentsEventCount.creation_time",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "MDRNumber - Events",
    },
    row: "mdrIncidentFirstRow",
    size: 4,
  },
  {
    id: 1,
    name: "Alerts: Total",
    vizState: {
      chartType: "MDRNumber - Alerts",
      queries: [
        {
          measures: ["Incidents.totalAlertCount"],
        },
        {
          // dimensions: ["Incidents.alertCount"],
          measures: ["Incidents.totalAlertCount"],
          timeDimensions: [
            {
              dimension: "Incidents.creation_time",
              granularity: "day",
            },
          ],
          filters: [],
        },
      ],
    },
    row: "mdrIncidentFirstRow",
    size: 4,
  },
  {
    id: 2,
    name: "Incidents: Total",
    vizState: {
      chartType: "MDRNumber - Incidents Total",
      queries: [
        {
          measures: ["Incidents.count"],
          timeDimensions: [],
          dimensions: [],
          filters: [],
        },
        {
          measures: ["Incidents.count"],
          timeDimensions: [
            {
              dimension: "Incidents.creation_time",
              granularity: "day",
            },
          ],
          dimensions: [],
          filters: [],
        },
      ],
    },
    row: "mdrIncidentFirstRow",
    size: 4,
  },

  {
    id: 3,
    name: "Incidents: By Priority",
    type: "incidentDonutChart",
    vizState: {
      chartType: "MDRdonut",
      query: {
        measures: ["Incidents.count"],
        timeDimensions: [
          {
            dimension: "Incidents.creation_time",
          },
        ],
        filters: [],
        dimensions: ["Incidents.priority"],
      },
    },
    row: "mdrIncidentSecondRow",
    size: 8,
  },
  {
    id: 4,
    name: "Incidents: By State",
    vizState: {
      chartType: "MDRCustomChart",
      query: {
        dimensions: ["Incidents.status"],
        timeDimensions: [],
        order: [
          ["Incidents.count", "desc"],
          ["Incidents.incidentTime", "asc"],
        ],
        measures: ["Incidents.count"],
      },
    },
    row: "mdrIncidentSecondRow",
    size: 4,
  },
  {
    id: 5,
    name: "",
    type: "MDRIncidents",
    vizState: {
      chartType: "MDRBarGraph",
      query: {
        dimensions: ["Incidents.priority"],
        timeDimensions: [
          {
            dimension: "Incidents.creation_date",
            granularity: "day",
            dateRange: [
              `${moment(calculateStartdateAndEndDate().endDate)
                .subtract(7, "d")
                .format("YYYY-MM-DDTHH:mm:ss.SS")}`,
              `${moment(calculateStartdateAndEndDate().endDate).format(
                "YYYY-MM-DDTHH:mm:ss.SS"
              )}`,
            ],
          },
        ],
        measures: ["Incidents.count"],
        filters: [],
      },
    },
    row: "mdrIncidentThirdRow",
    size: 12,
  },
  {
    id: 6,
    name: "Incidents: By Locations",
    vizState: {
      chartType: "MDRMapGraph",
      query: {
        measures: ["IncidentMaps.count"],
        dimensions: [
          "IncidentMaps.lat",
          "IncidentMaps.long",
          "IncidentMaps.ipaddress",
          "IncidentMaps.incidentId",
          "IncidentMaps.priority",
        ],
        filters: [
          {
            member: "IncidentMaps.ipType",
            operator: "equals",
            values: ["SOURCE"],
          },
        ],
      },
    },
    row: "mdrIncidentFourthRow",
    size: 12,
  },
];

const AuthenticationdashboardInstance = [
  {
    id: 0,
    name: "Failed Logins: Total",
    vizState: {
      queries: [
        {
          measures: ["MdrAuthentication.count"],
        },
        {
          measures: ["MdrAuthentication.count"],
          timeDimensions: [
            {
              dimension: "MdrAuthentication.creation_time",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "MDRNumber - AuthErrors",
    },
    row: "mdrAutheticationFirstRow",
    size: 4,
  },
  {
    id: 1,
    name: "Failed Logins: Distinct Users",
    vizState: {
      queries: [
        {
          measures: ["MDRAuthenticationDistinctUsers.count"],
        },
        {
          measures: ["MDRAuthenticationDistinctUsers.count"],
          timeDimensions: [
            {
              dimension: "MDRAuthenticationDistinctUsers.creation_time",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "MDRNumber - AuthErrors",
    },
    row: "mdrAutheticationFirstRow",
    size: 4,
  },
  {
    id: 2,
    name: "Failed Logins: Distinct IPs",
    vizState: {
      queries: [
        {
          measures: ["MDRAuthenticationDistinctIP.ipAdressCount"],
        },
        {
          measures: ["MDRAuthenticationDistinctIP.ipAdressCount"],
          timeDimensions: [
            {
              dimension: "MDRAuthenticationDistinctIP.creation_time",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "MDRNumber - AuthErrors",
    },
    row: "mdrAutheticationFirstRow",
    size: 4,
  },
  {
    id: 3,
    name: "Failed Logins: By Source",
    type: "AuthenticationFailedSourcePieChart",
    vizState: {
      chartType: "MDRdonut",
      query: {
        measures: ["MdrAuthentication.count"],
        timeDimensions: [],
        dimensions: ["MdrAuthentication.authErrorSource"],
        filters: [],
      },
    },
    row: "mdrSecondRowAuthentication",
    size: 6,
  },
  {
    id: 4,
    name: "Failed Logins: Top 10 IP Addresses",
    type: "AuthenticationFailedIpPieChart",
    vizState: {
      chartType: "MDRdonut",
      query: {
        measures: ["MdrAuthentication.count"],
        dimensions: ["MdrAuthentication.ipAddress"],
        filters: [],
        limit: 10,
      },
    },
    row: "mdrSecondRowAuthentication",
    size: 6,
  },
  {
    id: 5,
    name: "Failed Logins: Top 10 Distinct Users",
    type: "usersLoginFailed",
    vizState: {
      chartType: "MDRSortedBarCharts",
      query: {
        measures: ["MdrAuthentication.count"],
        timeDimensions: [],
        order: {
          "MdrAuthentication.count": "desc",
        },
        dimensions: ["MdrAuthentication.userName"],
        limit: 10,
        filters: [],
      },
    },
    row: "mdrThirdRowAuthentication",
    size: 6,
  },
  {
    id: 6,
    name: "Failed Logins: Top 10 Reasons",
    type: "reasonsForLoginFailed",
    vizState: {
      chartType: "MDRSortedBarCharts",
      query: {
        measures: ["MdrAuthentication.count"],
        timeDimensions: [],
        order: {
          "MdrAuthentication.count": "desc",
        },
        dimensions: ["MdrAuthentication.result"],
        limit: 10,
        filters: [],
      },
    },

    row: "mdrThirdRowAuthentication",
    size: 6,
  },
];

const EndPointsdashboardInstance = [
  {
    id: 0,
    name: "Agents: Total",
    vizState: {
      queries: [
        {
          measures: ["MDRTotalEndPoints.count"],
        },
        {
          measures: ["MDRTotalEndPoints.count"],
          timeDimensions: [
            {
              dimension: "MDRTotalEndPoints.creation_time",
              granularity: "day",
            },
          ],
        },
      ],
      chartType: "MDRNumber - endPointsTotal",
    },
    row: "mdrEndPointsFirstRow",
    size: 3,
  },
  {
    id: 1,
    name: "Disabled: Firewall",
    type: "",
    vizState: {
      chartType: "MDRNumber - DisabledFirewall",
      queries: [
        {
          measures: ["MDREndPoints.count"],
          filters: [
            {
              member: "MDREndPoints.machineSecurityConfig",
              operator: "equals",
              values: ["Firewall"],
              // values: ["firewallDisabled"],
            },
          ],
        },
        {
          measures: ["MDREndPoints.count"],
          timeDimensions: [
            {
              dimension: "MDREndPoints.creation_time",
              granularity: "day",
            },
          ],
          filters: [
            {
              member: "MDREndPoints.machineSecurityConfig",
              operator: "equals",
              values: ["Firewall"],

              // values: ["firewallDisabled"],
            },
          ],
          dimensions: [],
        },
      ],
    },
    row: "mdrEndPointsFirstRow",
    size: 3,
  },
  {
    id: 2,
    name: "Disabled: Antivirus",
    type: "",
    vizState: {
      chartType: "MDRNumber - DisabledAntivirus",
      queries: [
        {
          measures: ["MDREndPoints.count"],
          filters: [
            {
              member: "MDREndPoints.machineSecurityConfig",
              operator: "equals",
              values: ["Antivirus"],
              // values: ["antiVirusDisabled"],
            },
          ],
        },
        {
          measures: ["MDREndPoints.count"],
          timeDimensions: [
            {
              dimension: "MDREndPoints.creation_time",
              granularity: "day",
            },
          ],
          filters: [
            {
              member: "MDREndPoints.machineSecurityConfig",
              operator: "equals",
              values: ["Antivirus"],
              // values: ["antiVirusDisabled"],
            },
          ],
          dimensions: [],
        },
      ],
    },
    row: "mdrEndPointsFirstRow",
    size: 3,
  },
  {
    id: 3,
    name: "Admin Users: Workstations",
    type: "",
    vizState: {
      chartType: "MDRNumber - AdminUsers",
      queries: [
        {
          measures: ["MDREndPoints.count"],
          filters: [
            {
              member: "MDREndPoints.adminstrator",
              operator: "equals",
              values: ["TRUE"],
            },
          ],
        },
        {
          measures: ["MDREndPoints.count"],
          timeDimensions: [
            {
              dimension: "MDREndPoints.creation_time",
              granularity: "day",
            },
          ],

          filters: [
            {
              member: "MDREndPoints.adminstrator",
              operator: "equals",
              values: ["TRUE"],
            },
          ],
          dimensions: ["MDREndPoints.adminstrator"],
        },
      ],
    },
    row: "mdrEndPointsFirstRow",
    size: 3,
  },
  {
    id: 4,
    name: "Agents: By Environment",
    type: "AgentsbyEnvironment",
    vizState: {
      query: {
        measures: ["MDREndPoints.count"],
        timeDimensions: [],
        filters: [],
        dimensions: ["MDREndPoints.environment"],
      },
      chartType: "MDRdonut",
    },
    row: "mdrEndPointsSecondRow",
    size: 4,
  },
  {
    id: 5,
    name: "Agents: By Security Tools State",
    type: "AgentsSecurityToolState",
    vizState: {
      query: {
        measures: ["MDREndPoints.count"],
        timeDimensions: [],
        filters: [],
        dimensions: ["MDREndPoints.machineSecurityConfig"],
      },
      chartType: "MDRdonut",
    },
    row: "mdrEndPointsSecondRow",
    size: 4,
  },
  {
    id: 6,
    name: "Agents: By OS Version",
    type: "AgentByOSVersion",
    vizState: {
      query: {
        measures: ["MDREndPoints.count"],
        dimensions: ["MDREndPoints.osDescription"],
        filters: [],
        timeDimensions: [],
      },

      chartType: "MDRdonut",
    },
    row: "mdrEndPointsSecondRow",
    size: 4,
  },
  {
    id: 7,
    name: "",
    type: "MDREndpoints",
    vizState: {
      query: {
        measures: ["MDREndPoints.count"],
        timeDimensions: [
          {
            dimension: "MDREndPoints.creation_time",
            granularity: "day",
            dateRange: "Last 7 days",
          },
        ],
        // dimensions: ["MDREndPoints.creation_time"],
      },
      chartType: "MDRBarGraph",
    },
    row: "mdrEndPointsThirdRow",
    size: 12,
  },
];

const tableQuery = [
  {
    type: "incidentTable",
    queryCall: "IncidentsTable",
    query: [
      {
        dimensions: [
          "IncidentsTable.priority",
          "IncidentsTable.resolved",
          "IncidentsTable.status",
          "IncidentsTable.incidentTime",
          "IncidentsTable.incidentId",
          "IncidentsTable.name",
          "IncidentsTable.priorityNum",
          "IncidentsTable.city",
          "IncidentsTable.stateDetails",
          "IncidentsTable.assignee",
        ],
        order: [["IncidentsTable.incidentTime", "desc"]],
        timeDimensions: [],
        limit: 20,
      },
      {
        measures: ["IncidentsTable.count"],
      },
    ],
  },
];

const PatchMonitoringdashboardInstance = () => [
  {
    id: 0,
    name: "Non-Compliant Servers: Total",
    vizState: {
      chartType: "MDRNumber - serversTotal",
      queries: [
        {
          measures: ["MDRPatchManagement.serverCount"],
          filters: []
        },
        {
          measures: ["MDRPatchManagement.serverCount"],
          timeDimensions: [
            {
              dimension: "MDRPatchManagement.creation_time",
              granularity: "day",
            },
          ],
          filters: []
        },
      ],
    },
    row: "mdrPatchMonitoringFirstRow",
    size: 4,
  },
  {
    id: 1,
    name: "Non-Compliant Servers: Unpatched",
    type: "",
    vizState: {
      chartType: "MDRNumber - serversUnpatched",
      queries: [
        {
          measures: ["MDRPatchManagement.serverCount"],
          filters: [
            {
              member: "MDRPatchManagement.updateState",
              operator: "equals",
              values: ["Needed"],
            }
          ],
        },
        {
          measures: ["MDRPatchManagement.serverCount"],
          timeDimensions: [
            {
              dimension: "MDRPatchManagement.creation_time",
              granularity: "day",
            },
          ],

          // dimensions: ["MDRPatchManagement.updateState"],
          filters: [
            {
              member: "MDRPatchManagement.updateState",
              operator: "equals",
              values: ["Needed"],
            },
          ],
        },
      ],
    },
    row: "mdrPatchMonitoringFirstRow",
    size: 4,
  },
  {
    id: 2,
    name: "Non-Compliant Patches: Needed",
    type: "",
    vizState: {
      chartType: "MDRNumber - DisabledAntivirus",
      queries: [
        {
          measures: ["MDRPatchManagement.count"],
          filters: [
            {
              member: "MDRPatchManagement.updateState",
              operator: "equals",
              values: ["Needed"],
            }
          ],
        },
        {
          measures: ["MDRPatchManagement.count"],
          timeDimensions: [
            {
              dimension: "MDRPatchManagement.creation_time",
              granularity: "day",
            },
          ],

          // dimensions: ["MDRPatchManagement.updateState"],
          filters: [
            {
              member: "MDRPatchManagement.updateState",
              operator: "equals",
              values: ["Needed"],
            },
          ],
        },
      ],
    },
    row: "mdrPatchMonitoringFirstRow",
    size: 4,
  },
  {
    id: 3,
    name: "Non-Compliant Servers: By Environment",
    type: "patchMonitoringBySubsCriptionName",
    vizState: {
      // chartType: "MDRNumber - serversTotal",
      chartType: "MDRdonut",
      query: {
        measures: ["MDRPatchManagement.serverCount"],
        timeDimensions: [],
        dimensions: ["MDRPatchManagement.subscriptionName"],
        filters: []
      },
    },
    row: "mdrPatchMonitoringSecondRow",
    size: 6,
  },
  {
    id: 4,
    name: "Non-Compliant Patches: Needed by Severity",
    type: "patchesNeedBySeverity",
    vizState: {
      chartType: "MDRdonut",
      query: {
        measures: ["MDRPatchManagement.count"],
        timeDimensions: [],
        dimensions: ["MDRPatchManagement.severity"],
        filters: [
          {
            member: "MDRPatchManagement.updateState",
            operator: "equals",
            values: ["Needed"],
          },
        ],
      },
    },
    row: "mdrPatchMonitoringSecondRow",
    size: 6,
  },
  {
    id: 5,
    name: "Non-Compliant Top 10 Unpatched Servers",
    type: "patchesByTop10Servers",
    vizState: {
      chartType: "MDRSortedBarCharts",
      query: {
        measures: ["MDRPatchManagement.count"],
        timeDimensions: [],
        order: [["MDRPatchManagement.count", "desc"]],
        dimensions: ["MDRPatchManagement.server"],
        filters: [
          {
            member: "MDRPatchManagement.updateState",
            operator: "equals",
            values: ["Needed"],
          }
        ],
        limit: 10,
      },
    },
    row: "mdrPatchMonitoringThirdRow",
    size: 6,
  },
  {
    id: 6,
    name: "Non-Compliant Top 10 KBIDs by Most Servers",
    type: "serversByTop10KBID",
    vizState: {
      chartType: "MDRSortedBarCharts",
      query: {
        measures: ["MDRPatchManagement.serverCount"],
        timeDimensions: [],
        order: [["MDRPatchManagement.serverCount", "desc"]],
        dimensions: ["MDRPatchManagement.kbid"],
        limit: 10,
        filters: []
      },
    },
    row: "mdrPatchMonitoringThirdRow",
    size: 6,
  },
  {
    id: 7,
    name: "Patches: Installed vs Needed",
    type: "MDRpatchesInstalledvsNeeded",
    vizState: {
      chartType: "MDRBarGraph",
      query: {
        measures: ["MDRPatchManagement.count"],
        timeDimensions: [
          {
            dimension: "MDRPatchManagement.creation_time",
            granularity: "day",
          },
        ],
        dimensions: ["MDRPatchManagement.updateState"],
      },
    },
    row: "mdrPatchMonitoringFourthRow",
    size: 12,
  },
];

const PatchMonitoringdashboardInstanceClone = () => [
  {
    id: 0,
    name: "Non-Compliant Servers: Total",
    vizState: {
      chartType: "MDRNumber - serversTotal",
      queries: [
        {
          measures: ["MDRPatchManagement.serverCount"],
          filters: [],
        },
        {
          measures: ["MDRPatchManagement.serverCount"],
          timeDimensions: [
            {
              dimension: "MDRPatchManagement.creation_time",
              granularity: "day",
            },
          ],
          filters: [],
        },
      ],
    },
    row: "mdrPatchMonitoringFirstRow",
    size: 4,
  },
  {
    id: 1,
    name: "Non-Compliant Servers: Unpatched",
    type: "",
    vizState: {
      chartType: "MDRNumber - serversUnpatched",
      queries: [
        {
          measures: ["MDRPatchManagement.serverCount"],
          filters: [
            {
              member: "MDRPatchManagement.updateState",
              operator: "equals",
              values: ["Needed"],
            }
          ],
        },
        {
          measures: ["MDRPatchManagement.serverCount"],
          timeDimensions: [
            {
              dimension: "MDRPatchManagement.creation_time",
              granularity: "day",
            },
          ],

          // dimensions: ["MDRPatchManagement.updateState"],
          filters: [
            {
              member: "MDRPatchManagement.updateState",
              operator: "equals",
              values: ["Needed"],
            }
          ],
        },
      ],
    },
    row: "mdrPatchMonitoringFirstRow",
    size: 4,
  },
  {
    id: 2,
    name: "Non-Compliant Patches: Needed",
    type: "",
    vizState: {
      chartType: "MDRNumber - DisabledAntivirus",
      queries: [
        {
          measures: ["MDRPatchManagement.count"],
          filters: [
            {
              member: "MDRPatchManagement.updateState",
              operator: "equals",
              values: ["Needed"],
            }
          ],
        },
        {
          measures: ["MDRPatchManagement.count"],
          timeDimensions: [
            {
              dimension: "MDRPatchManagement.creation_time",
              granularity: "day",
            },
          ],

          // dimensions: ["MDRPatchManagement.updateState"],
          filters: [
            {
              member: "MDRPatchManagement.updateState",
              operator: "equals",
              values: ["Needed"],
            },
          ],
        },
      ],
    },
    row: "mdrPatchMonitoringFirstRow",
    size: 4,
  },
  {
    id: 3,
    name: "Non-Compliant Servers: By Environment",
    type: "patchMonitoringBySubsCriptionName",
    vizState: {
      // chartType: "MDRNumber - serversTotal",
      chartType: "MDRdonut",
      query: {
        measures: ["MDRPatchManagement.serverCount"],
        timeDimensions: [],
        dimensions: ["MDRPatchManagement.subscriptionName"],
        filters: []
      },
    },
    row: "mdrPatchMonitoringSecondRow",
    size: 6,
  },
  {
    id: 4,
    name: "Non-Compliant Patches: Needed by Severity",
    type: "patchesNeedBySeverity",
    vizState: {
      chartType: "MDRdonut",
      query: {
        measures: ["MDRPatchManagement.count"],
        timeDimensions: [],
        dimensions: ["MDRPatchManagement.severity"],
        filters: [
          {
            member: "MDRPatchManagement.updateState",
            operator: "equals",
            values: ["Needed"],
          },
        ],
      },
    },
    row: "mdrPatchMonitoringSecondRow",
    size: 6,
  },
  {
    id: 5,
    name: "Non-Compliant Top 10 Unpatched Servers",
    type: "patchesByTop10Servers",
    vizState: {
      chartType: "MDRSortedBarCharts",
      query: {
        measures: ["MDRPatchManagement.count"],
        timeDimensions: [],
        order: [["MDRPatchManagement.count", "desc"]],
        dimensions: ["MDRPatchManagement.server"],
        filters: [
          {
            member: "MDRPatchManagement.updateState",
            operator: "equals",
            values: ["Needed"],
          },
        ],
        limit: 10,
      },
    },
    row: "mdrPatchMonitoringThirdRow",
    size: 6,
  },
  {
    id: 6,
    name: "Non-Compliant Top 10 KBIDs by Most Servers",
    type: "serversByTop10KBID",
    vizState: {
      chartType: "MDRSortedBarCharts",
      query: {
        measures: ["MDRPatchManagement.serverCount"],
        timeDimensions: [],
        order: [["MDRPatchManagement.serverCount", "desc"]],
        dimensions: ["MDRPatchManagement.kbid"],
        limit: 10,
        filters: []
      },
    },
    row: "mdrPatchMonitoringThirdRow",
    size: 6,
  },
  {
    id: 7,
    name: "Patches: Installed vs Needed",
    type: "MDRpatchesInstalledvsNeeded",
    vizState: {
      chartType: "MDRBarGraph",
      query: {
        measures: ["MDRPatchManagement.count"],
        timeDimensions: [
          {
            dimension: "MDRPatchManagement.creation_time",
            granularity: "day",
          },
        ],
        dimensions: ["MDRPatchManagement.updateState"],
      },
    },
    row: "mdrPatchMonitoringFourthRow",
    size: 12,
  },
];

const AutheticationTableQuery = [
  {
    id: 0,
    name: "",
    type: "authenticationTable",
    loc: "MDRDashboard_AuthenticationTable_AllUsers",
    queryCall: "MdrAuthentication",

    query: [
      {
        measures: ["MdrAuthentication.count"],
        timeDimensions: [],
        order: [["MdrAuthentication.incidentTime", "desc"]],
        dimensions: [
          "MdrAuthentication.id",
          "MdrAuthentication.authErrorSource",
          "MdrAuthentication.groupId",
          "MdrAuthentication.ipAddress",
          "MdrAuthentication.userName",
          "MdrAuthentication.creation_time",
          "MdrAuthentication.ingestionTime",
          "MdrAuthentication.incidentTime",
          "MdrAuthentication.result",
          "MdrAuthentication.server",
          "MdrAuthentication.userNameFull",
          "MdrAuthentication.disposition",
          "MdrAuthentication.category",
          "MdrAuthentication.action",
        ],
        limit: 20,
        filters: [],
      },
      {
        measures: ["MdrAuthentication.count"],
      },
    ],
    size: 12,
  },
];

const AutheticationTableQuery_UniqueUsers = [
  {
    id: 0,
    name: "",
    type: "authenticationTable",
    loc: "MDRDashboard_AuthenticationTable_UniqueUsers",
    queryCall: "MDRAuthenticationDistinctUsers",
    query: [
      {
        measures: ["MDRAuthenticationDistinctUsers.loginFailureCount"],
        timeDimensions: [],
        dimensions: [
          "MDRAuthenticationDistinctUsers.ipAddress",
          "MDRAuthenticationDistinctUsers.userName",
        ],
        order: [["MDRAuthenticationDistinctUsers.incidentTime", "desc"]],
        limit: 20,
      },
      {
        measures: ["MDRAuthenticationDistinctUsers.totalCount"],
      },
    ],
  },
];

const AutheticationTableQuery_UniqueIPs = [
  {
    id: 0,
    name: "",
    type: "authenticationTable",
    loc: "MDRDashboard_AuthenticationTable_UniqueIPs",
    queryCall: "MDRAuthenticationDistinctIP",

    query: [
      {
        measures: [
          "MDRAuthenticationDistinctIP.ipAdressCount",
          "MDRAuthenticationDistinctIP.loginFailureCount",
        ],
        timeDimensions: [],
        dimensions: ["MDRAuthenticationDistinctIP.userName"],
        order: [["MDRAuthenticationDistinctIP.incidentTime", "desc"]],
        limit: 20,
      },
      {
        measures: ["MDRAuthenticationDistinctIP.totalCount"],
      },
    ],

    row: "",
    size: 12,
  },
];

const MDREndpointsQuery = [
  {
    id: 0,
    name: "",
    type: "MDREndpoints",
    queryCall: "MDREndPoints",
    query: [
      {
        dimensions: [
          "MDREndPoints.id",
          "MDREndPoints.creation_time",
          "MDREndPoints.incidentTime",
          "MDREndPoints.hostName",
          "MDREndPoints.ipv4",
          "MDREndPoints.score",
          "MDREndPoints.osDescription",
          "MDREndPoints.environment",
          "MDREndPoints.userName",
          "MDREndPoints.machineSecurityConfig",
          "MDREndPoints.adminstrator",
          "MDREndPoints.groupPolicyServerName",
          "MDREndPoints.lastScanTime",
          "MDREndPoints.machineSecurityConfigFull",
          "MDREndPoints.osDescriptionFull",
        ],
        timeDimensions: [],
        order: [["MDREndPoints.incidentTime", "desc"]],
        limit: 20,
        offset: 0,
      },
      {
        measures: ["MDREndPoints.count"],
      },
    ],

    row: "",
    size: 12,
  },
];
const MDRPatchMonitoringQuery = [
  {
    id: 0,
    name: "",
    type: "MDRPatchMonitoring",
    queryCall: "MDRPatchManagement",
    query: [
      {
        dimensions: [
          "MDRPatchManagement.id",
          "MDRPatchManagement.creation_time",
          "MDRPatchManagement.server",
          "MDRPatchManagement.subscriptionName",
          "MDRPatchManagement.classification",
          "MDRPatchManagement.severity",
          "MDRPatchManagement.updateState",
          "MDRPatchManagement.kbid",
          "MDRPatchManagement.title",
          "MDRPatchManagement.approved",
          "MDRPatchManagement.optional",
          "MDRPatchManagement.resource",
          "MDRPatchManagement.subscriptionId",
          "MDRPatchManagement.incidentTime",
          "MDRPatchManagement.sourceComputerId",
          "MDRPatchManagement.updateId",
          "MDRPatchManagement.sourceSystem",
          "MDRPatchManagement.product",
          "MDRPatchManagement.osType",
          "MDRPatchManagement.productVersion",
          "MDRPatchManagement.productArch",
          "MDRPatchManagement.cveNumbers",
          "MDRPatchManagement.bulletInUrl",
          "MDRPatchManagement.bulletinId",
          "MDRPatchManagement.packageRepo",
          "MDRPatchManagement.packageSeverity",
          "MDRPatchManagement.osName",
          "MDRPatchManagement.osVersion",
          "MDRPatchManagement.osFullName",
          "MDRPatchManagement.resource_group",
          "MDRPatchManagement.resourceProvider",
          "MDRPatchManagement.resourceId",
          "MDRPatchManagement.resourceType",
          "MDRPatchManagement.computerEnvironment",
          "MDRPatchManagement.vmuuid",
          "MDRPatchManagement.mg",
          "MDRPatchManagement.mngGroupName",
          "MDRPatchManagement.revisonNumber",
          "MDRPatchManagement.rebootBehaviour",
          "MDRPatchManagement.msrcBulletinId",
          "MDRPatchManagement.approvalSource",
          "MDRPatchManagement.type",
          "MDRPatchManagement.timeGenerated",
          "MDRPatchManagement.istpsTime",
          "MDRPatchManagement.istdrsTime",
          "MDRPatchManagement.installTimeAvailable",
        ],
        timeDimensions: [],
        order: [["MDRPatchManagement.incidentTime", "desc"]],
        limit: 20,
      },
      {
        measures: ["MDRPatchManagement.count"],
      },
    ],
    row: "",
    size: 12,
  },
];


export {
  dashboardInstance,
  devopsDashboardInstance,
  dashboardInstanceForUser,
  IncidentdashboardInstance,
  AuthenticationdashboardInstance,
  EndPointsdashboardInstance,
  PatchMonitoringdashboardInstance,
  PatchMonitoringdashboardInstanceClone,
  tableQuery,
  AutheticationTableQuery,
  AutheticationTableQuery_UniqueUsers,
  AutheticationTableQuery_UniqueIPs,
  MDREndpointsQuery,
  MDRPatchMonitoringQuery,
};
