import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewLogSourceConstants } from "./utilities";
import EnhancedGridTable from "../../shared/Table/Table";
import { EnhancedTableToolbar } from "./Header";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { GET_LOG_SERVICES, TRIGGER_DELETE_LOG_SOURCE, SET_SNACKBAR, DELETE_CONFIRMATION_MESSAGE, DATA_MATCHED } from "../../../redux/constants/constants";
import AlertDrawer from "../../shared/alertDrawer";
import { fetchUpdatedLogSources } from "../../../redux/actions/getAlertsAction";
import { isEmpty, isNull } from "lodash";
import { errorSnackbar } from "../../common/utilities";
import ConfirmationDialog from "../../shared/ConfirmationDialog";
import DeleteConnection from "../DeleteConnection";

export const LogSource = () => {
  const dispatch = useDispatch();
  const snackBar = useSelector (
    (state) => state.DeleteConnectionReducer.snackBar
  );
  const deleteConnectionErrorMessage = useSelector (
    (state) => state.DeleteConnectionReducer.deleteConnectionErrorMessage
  );
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [loader, setLoader] = useState(false);
  const [serviceNodes, setServiceNodes] = useState([]);
  const [stateOpen, setStateOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedLogSource, setSelectedLogSource] = useState({});
  const [confirmMessage, setConfirmMessage] = useState("");

  useEffect(() => {
    setLoader(true);
    getServiceData();
     return () => {
      dispatch({type: SET_SNACKBAR, payload: false});
      setOpen(false); 
    }
  }, [currentPage, recordsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  //Trigger snackbar and show error message for deletion
  useEffect(() => {
    setErrorMessage(deleteConnectionErrorMessage);
    setOpen(snackBar);
    setLoader(false);
  },[snackBar, deleteConnectionErrorMessage]);

  async function getServiceData() {
    const data = getAxiosHeaders(true);

    const res = await getService({
      method: "GET",
      url: `${GET_LOG_SERVICES}?page=${currentPage}&size=${recordsPerPage}`,
      headers: data,
    });
    if (res && res.status === 200) {
      setServiceNodes(res.data.content);
      setTotalCount(res.data.totalElements);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }
  async function getUpdatedLogSources(row,status) {
    setLoader(true);
    const response = await fetchUpdatedLogSources(row.id, status);
    if (typeof(response) === 'object' && !isEmpty(serviceNodes)) {
      serviceNodes.forEach((item, index) => {
        if (item.id === response.id){
          serviceNodes[index] = response
        }
      })
      setServiceNodes([...serviceNodes])
      setLoader(false);
    } else if (typeof(response) === 'string') {
      setOpen(true);
      setErrorMessage(response);
      setLoader(false);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({type: SET_SNACKBAR, payload: false});
    setOpen(false);
  };
  const handleLogSourceStatus = (event, row) => {
    if (row.status.value === 'Active'){
      getUpdatedLogSources(row,'INACTIVE')
    } else {
      getUpdatedLogSources(row,'ACTIVE')
    }
  }
  const handleLogSourceDelete = (name, id) => {
    setDialogOpen(true);
    setSelectedLogSource({id: id, name:name});
  }
  const handleDialogClose = () => {
    setDialogOpen(false);
    setConfirmMessage('');
    setSelectedLogSource({});
    setLoader(true);
    getServiceData();
  };

  const deleteLogSource = () => {
    setLoader(true);
    dispatch({
      type: TRIGGER_DELETE_LOG_SOURCE,
      payload: { selectedLogSource: selectedLogSource, handleDialogClose: handleDialogClose }
    })
  }

  const checkValidity = (e) => {
    if (!isEmpty(e.target.value) && !isNull(e.target.value)) {
      if (e.target.value === selectedLogSource.name) {
        setConfirmMessage(DATA_MATCHED);
      } else {
        setConfirmMessage("");
      }
    }
  };
  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const toggleDrawer = (open, event, row) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateOpen(open);
  };

  const renderSidePopup = () => {
    return (
      stateOpen && (
        <AlertDrawer
          toggleDrawer={toggleDrawer}
          stateOpen={stateOpen}
          getServiceData={getServiceData}
          loc="log-source"
        />
      )
    );
  };

  return (
    <div className="main">
      {loader && (
        <div className="global-loader-holder">
          <img src={Page_Loader} alt="_Loader" className="loader" />
        </div>
      )}
      {renderSidePopup()}
      <div className="filter-table-container">
        <div className="table-container">
          <EnhancedTableToolbar
            totalElements={totalCount}
            loc={"service"}
            alertsTitle={"Log Source"}
            helpDescription={"Log Source"}
            toggleDrawer={toggleDrawer}
          />
          <EnhancedGridTable
            cols={ViewLogSourceConstants(
              handleLogSourceStatus,
              handleLogSourceDelete
            )}
            data={serviceNodes}
            totalCount={totalCount}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
          />
          <ConfirmationDialog
            dialogOpen={dialogOpen}
            dialogTitle={DELETE_CONFIRMATION_MESSAGE}
            handleDialogCancel={handleDialogClose}
            handleDialogConfirm={deleteLogSource}
            disable={isEmpty(confirmMessage)}>
            <DeleteConnection
              loader={loader}
              selectedSource={selectedLogSource}
              checkValidity={checkValidity}
              confirmMessage={confirmMessage}
              sourceName={"log"}
            />
          </ConfirmationDialog>
        </div>
      </div>
      {errorSnackbar(open, handleClose, errorMessage, "error")}
    </div>
  );
};
