import { SAVE_ALERT_COMMENT_URL } from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

export async function saveAlertComment(id, comment) {
  let commentData = {
    comments: `${comment}`
  };
  const options = {
    method: "POST",
    url: `${SAVE_ALERT_COMMENT_URL}/${id}/comments`,
    headers: getAxiosHeaders(true),
    data: commentData
  };

  const res = await getService(options);
  return res;
}
