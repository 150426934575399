import { ALL_ACCESS_DATA_TOTAL_COUNT, CLEAR_ALL_ACCESS_TOTAL_COUNT, SET_TOTAL_COUNT_LOADER } from '../constants/constants';

const initialState = {
    totalCount: 0,
    loader: false,
}

export const allAccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_ACCESS_DATA_TOTAL_COUNT: {
            return {
                ...state,
                totalCount: action.payload
            };
        }
        case CLEAR_ALL_ACCESS_TOTAL_COUNT: {
            return {
                ...state,
                totalCount: 0
            }
        }
        case SET_TOTAL_COUNT_LOADER: {
            return {
                ...state,
                loader: action.payload
            }
        }
        default:
            return state;
    }
}