import React from "react";
import { Chart } from "react-google-charts";
import _, { isNull } from "lodash";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import ChartWrapper from "../Chart";
import { numberFormatter, dateFormatter, renderNoData } from "../utils";
import { viewAlert, VIEW_ENTERPRISE_ACTORS } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../utilities";

export let usersWithAlertsByLevelChart = (cubejsApi, name, history ) => {
  return alertsByLevelDonutChart(
    cubejsApi,
    name,
    {
      measures: ["ActorAlerts.count"],
      dimensions: ["ActorAlerts.severityCode"],
    },
    history,
  );
};

export let complianceAlertsByLevelChart = (cubejsApi, name, history) => {
  return alertsByLevelDonutChart(
    cubejsApi,
    name,
    {
      dimensions: ["Alerts.severity"],
      measures: ["Alerts.count"],
      segments: ["Alerts.unresolvedAlerts"],
    },
    history
  );
};

const returnRedirectTo = (name) => {
  let data = {
    "Users with Alerts by Severity": {
      pathname: "/tenant/useraccessRights",
      state: {
        redirectedFrom: "dashboard",
      },
      permission: [VIEW_ENTERPRISE_ACTORS]
    },
    "Alerts by Severity":{
      pathname: "/tenant/alerts",
      permission: [viewAlert]
    },
  }
  return pathWithPermission(data[name]);
};

let alertsByLevelDonutChart = (cubejsApi, name, query, history) => {
  let colors = {
    HIGH: "#FF2251",
    MEDIUM: "#FFC344",
    LOW: "#91DFFF",
  };

  let tableStyle = { border: 0 };
  let options = {
    // Material design options
    pieHole: 0.5,
    legend: "none",
  };
  options.chartArea = { left: "0%", width: "100%" };
  options.pieSliceText = "none";
  options.legend = { position: "labeled" };
  if (colors) {
    let colorsForDonut = Object.getOwnPropertyNames(colors).map(
      (color) => colors[color]
    );
    options.colors = colorsForDonut;
  }

  let separatorStyle = {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "1px solid #ccc",
    height: "1px",
  };

  let tableBorder = {
    boxShadow: "none",
  };

  let renderChart = (resultSet) => {
    let data = resultSet.loadResponse.results[0].data;
    if (!data || data.length === 0) {
      return renderNoData("30%");
    }
    let projectedData = [["Severity", "Number of alerts"]];
    let legends = [];
    let countsOfSeverity = {
      HIGH: 0,
      MEDIUM: 0,
      LOW: 0,
    };
    ["HIGH", "MEDIUM", "LOW"].forEach((svt, index) => {
      let d = data.find((d) => d[query.dimensions[0]] === svt);
      countsOfSeverity[svt] = parseInt(d ? d[query.measures[0]] : 0);
      projectedData.push([svt, countsOfSeverity[svt]]);
    });

    Object.getOwnPropertyNames(countsOfSeverity).forEach((c) => {
      legends.push({
        name: c,
        text: c,
        count: countsOfSeverity[c],
        color: colors[c],
      });
    });
    console.log(name)
    return (
      <React.Fragment>
        <div onClick={(e) =>e.stopPropagation()}>
        <Chart
          width={"100%"}
          height={"200px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={projectedData}
          options={options}
          chartEvents={[
            {
              eventName: "ready",
              callback: ({ chartWrapper, google, props }) => {
                const chart = chartWrapper.getChart();
                var handler = function (e) {
                  console.log(e, props.googleChartWrapper, projectedData);
                  var selectedItem = chart.getSelection()[0];

                  if (selectedItem) {
                    if(name === "Users with Alerts by Severity"){
                      history.push({
                        ...returnRedirectTo(name)
                      });
                    }
                    else if (history) {
                      history.push({
                        ...returnRedirectTo(name),
                        state: {
                          severity: projectedData[selectedItem.row + 1][0],
                        },
                      });
                    }
                    // var topping = projectedData.getValue(selectedItem.row, 0);
                    // alert("The user selected " + projectedData[selectedItem.row]);
                  }
                };
                google.visualization.events.addListener(
                  chartWrapper.getChart(),
                  "select",
                  handler
                );
              },
            },
          ]}
        />
        </div>
        <hr style={separatorStyle} />
        <TableContainer className="donut-legends" style={tableBorder}>
          <Table style={tableStyle}>
            <TableBody>
              {legends.map((row) => {
                if (row.count === 0) return "";
                let box = {
                  width: "10px",
                  height: "10px",
                  backgroundColor: row.color,
                };
                let content = {
                  maxWidth: "120px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                };
                return (
                  <TableRow key={row.name}>
                    <TableCell>
                      <div style={box}></div>
                    </TableCell>
                    <TableCell title={row.text} className="content">
                      {row.text ? row.text : "-"}
                    </TableCell>
                    <TableCell align="right">{row.count}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  };

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={query}
      render={renderChart}
      redirectTo={returnRedirectTo(name)}
    />
  );
};
