import { GET_ALERT_POLICY_TYPES_URL, GET_ALERT_POLICY_SUB_TYPES_URL } from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

export async function getAlertPolicyTypes() {
  const options = {
    method: "GET",
    url: `${GET_ALERT_POLICY_TYPES_URL}`,
    headers: getAxiosHeaders(true),
  };

  const response = await getService(options);
  if (
    response &&
    response.data &&
    response.data.content &&
    response.data.content.length
  ) {
    return response.data.content;
  } else {
    return [];
  }
}

/**
 * function to get sub types list 
 * @returns list of subtypes
 */
export async function getAlertPolicySubTypes() {
  const options = {
    method: "GET",
    url: `${GET_ALERT_POLICY_SUB_TYPES_URL}`,
    headers: getAxiosHeaders(true),
  };

  const response = await getService(options);
  if (
    response &&
    response.data &&
    response.data.content &&
    response.data.content.length
  ) {
    return response.data.content;
  } else {
    return [];
  }
}