import { TENANT_TOKEN } from '../constants/constants';

const initialState = {
    userDetails: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TENANT_TOKEN:
            return {
                ...state,
                userDetails: action.data
            }
        default:
            return state;
    }
};
