const storageNameErrorCheck = (value) => {
  return value.length < 3 || value.length > 63
}

export const azureFormData = [
  {
    name: "Name",
    label: "Name",
    type: "text",
    placeholder: "Name",
    required: true,
    errorMessage: "Name cannot be blank or empty",
  },
  {
    name: "sourceType",
    label: "Source Type",
    type: "select",
    placeholder: "Select Source Type",
    required: true,
    errorMessage: "Source Type is required",
  },
  {
    name: "eventHubConnection",
    label: "Event Hub Connection",
    type: "password",
    placeholder: "Event Hub Connection",
    required: true,
    errorMessage: "Event Hub Connection cannot be blank or empty",
  },
  {
    name: "storageAccountConnection",
    label: "Storage Account Connection",
    type: "text",
    placeholder: "Storage Account Connection",
    required: true,
    errorMessage: "Storage Account Connection cannot be blank or empty",
  },
  {
    name: "storageContainerName",
    label: "Storage Container Name",
    type: "text",
    placeholder: "Storage Container Name",
    required: true,
    errorMessage: "Storage Container Name cannot be blank or empty",
    customErrorMessage: "Storage Container Name must be between 3 and 63 characters long",
    customFn: storageNameErrorCheck,
  },
];