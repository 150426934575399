import React, { useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import ResetBackground from "../../../components/views/ResetPassword/ResetPassword";

const SetPassword = ({ featureName }) => {
  const { search } = useLocation();

  useEffect(() => {});

  let code, email;
  let codes, tenant;
  if (featureName === "setPassword") {
    const obj = queryString.parse(search);
    code = obj.code;
    email = obj.email;
    const codeArr = code ? code.split(" ") : null;
    codes = codeArr ? codeArr.join("+") : null;
    const arr = window.location.href.split(".");
    tenant = arr[0].includes("localhost") ? "" : arr[0];
    console.log(tenant);
  } else if (featureName === "resetPassword") {
    const obj = queryString.parse(search);
    code = obj.token;
    email = obj.email;
    const codeArr = code ? code.split(" ") : [];
    codes =
      codeArr.length > 1
        ? codeArr.join("+")
        : codeArr.length === 1
        ? codeArr[0]
        : "";
    const arr = window.location.href.split(".");
    tenant = arr[0].includes("localhost") ? "" : arr[0];
    console.log(tenant);
  }

  return (
    <>
      <ResetBackground
        email={email}
        code={codes}
        tenant={tenant}
        featureName={featureName}
      ></ResetBackground>
      ;
    </>
  );
};

export default SetPassword;
