import React, { useState, useEffect, useRef } from "react";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import {
  GET_AGENT_TYPES,
  GET_ALLPLATFORMS,
  AGENTS,
  PLATFORM_TYPES,
} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../../src/library/RestAPI";

import "../../../assets/scss/CreateAgent.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import PlatformList from "../../shared/PlatformList";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import Input from "../../../components/views/Input";
import Button from "@material-ui/core/Button";
import ManagementFooter from "../BackOfficeGeneralContainer/ManagementFooter";

const CreateAgent = () => {
  const inputFile = useRef(null);
  const [tenant, setTenant] = useState(localStorage.getItem("tenant"));
  const [agents, setAgents] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [curAgentValue, setCurAgentValue] = useState("");
  const [curPlatformValue, setcurPlatformValue] = useState("");
  const [agentName, setAgentName] = useState("");
  const [richText, setrichText] = useState("");
  const [uploadedArr, setUploadedArr] = useState([]);

  console.log(uploadedArr);
  useEffect(() => {
    const getAgents = async () => {
      const res = await getService({
        method: "GET",
        url: GET_AGENT_TYPES(localStorage.getItem("tenant")),
        headers: getAxiosHeaders(true),
      });
      console.log(res);
      if (res.status === 200) {
        if (res.data && res.data.content) {
          setAgents(res.data.content);
          setCurAgentValue(res.data.content[0].code);
        }
      }
    };
    const getPlatforms = async () => {
      const res = await getService({
        method: "GET",
        url: PLATFORM_TYPES(localStorage.getItem("tenant")),
        headers: getAxiosHeaders(true),
      });
      console.log(res);
      if (res.status === 200) {
        if (res.data && res.data.content) {
          setPlatforms(res.data.content);
        }
      }
    };
    getAgents();
    getPlatforms();
  }, [tenant]);
  // useEffect(() => {
  //   const apiCall = async () => {
  //     const res = await getService({
  //       method: "GET",
  //       url: GET_ALLPLATFORMS(tenant, curAgentValue),
  //       headers: getAxiosHeaders(true),
  //     });
  //     if (res.status === 200) {
  //       if (res.data.content[0]) {
  //         console.log(res.data);
  //         setPlatforms(res.data.content[0].platformInstructions);
  //         // setcurAgentId(res.data.content[0].id);
  //       } else {
  //         setPlatforms(res.data.content);
  //       }
  //     }
  //   };
  //   if (curAgentValue) {
  //     apiCall();
  //   }
  // }, [curAgentValue]);
  const agentClickHandler = (event) => {
    setCurAgentValue(event.target.value);
  };
  // console.log(agents, richText.value.toString("html"));

  const uploadChangeHandler = (event) => {
    console.log(event.target.files[0]);
    setUploadedArr([event.target.files[0]]);
  };
  console.log(uploadedArr, curPlatformValue, richText);
  const submitHandler = (event) => {
    const formData = new FormData();
    const obj = {
      name: agentName,
      type: curAgentValue,
      platformInstructions: [
        {
          // platformType: curPlatformValue,
          platformType: curPlatformValue,
          overview: "Overview about an net agent Updated......!",
          // instructions: richText.value.toString("html"),
          instructions: richText,
        },
      ],
    };

    console.log(uploadedArr);
    let myObj = JSON.stringify(obj);
    console.log(myObj);
    formData.append("payload", myObj);
    formData.append("artifacts", ...uploadedArr);
    const createAgent = async () => {
      const res = await getService({
        method: "POST",
        url: AGENTS(localStorage.getItem("tenant")),
        headers: getAxiosHeaders(true, "multipart/form-data"),
        data: formData,
      });
      console.log(res);
    };
    createAgent();
  };
  const uploadButtonClick = (event) => {
    event.preventDefault();
    inputFile.current.click();
  };
  // console.log(richText);
  // alert(curPlatformValue);
  return (
    <div className="createAgent_Containers">
      <SignUpBackGround
        headerName="New Agent"
        header="New Agent"
        breadcrumbValue={[
          {
            value: "Platform Settings",
            link: `/platform/platformSettings`,
          },
          { value: "Create", link: "" },
        ]}
      ></SignUpBackGround>
      <div className="createAgent_Content">
        <div className="createAgent_Content_heading">Agent Details</div>
        <div className="createAgent_Content_Container">
          <div style={{ width: "25%" }}>
            {agents.map((el, index) =>
              index === 0 ? (
                <>
                  <input
                    defaultChecked="true"
                    type="radio"
                    id={el.code}
                    name="agent"
                    value={el.code}
                    onClick={agentClickHandler}
                    key={index}
                  />
                  <label htmlFor={el.code} style={{ marginRight: "6%" }}>
                    {el.value}
                  </label>
                </>
              ) : (
                <>
                  <input
                    type="radio"
                    id={el.code}
                    name="agent"
                    value={el.code}
                    onClick={agentClickHandler}
                    key={index}
                  />
                  <label htmlFor={el.code}>{el.value}</label>
                </>
              )
            )}
            <PlatformList
              platforms={platforms}
              setcurPlatformValue={setcurPlatformValue}
            ></PlatformList>
          </div>
          <div className="rightFlexContainer">
            <div>
              <div>Agent Name</div>
              <input
                type="email"
                placeholder="agent name"
                className="createAgent_input"
                value={agentName}
                onChange={(event) => setAgentName(event.target.value)}
              ></input>
            </div>
            <div className="inputFileContainer">
              <input
                type="file"
                multiple
                className="inputFile"
                onChange={uploadChangeHandler}
                ref={inputFile}
              />
              <div className="inputFileText">
                {uploadedArr.length
                  ? uploadedArr.map((el) => <div>{el.name}</div>)
                  : "Please click Browse to select a file to upload"}
              </div>
            </div>

            <Button
              variant="contained"
              className="createAgentButton"
              onClick={uploadButtonClick}
            >
              Browse
            </Button>

            <div className="createAgent_RichtextEditor">
              <CKEditor
                className="createAgent_RichtextEditor"
                editor={ClassicEditor}
                data=""
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                  console.log(data);
                  setrichText(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ManagementFooter
        submitHandler={submitHandler}
        classname="CreateAgentFooter"
      ></ManagementFooter>
    </div>
  );
};

export default CreateAgent;
