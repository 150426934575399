import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SnackBar2 from "../../shared/SnackBar2";
import EnhancedGridTable from "../../shared/Table/Table";
import { DataSourceUserConstants } from "./AccessRightsutilities";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import AppDateTimeRange from "../../common/Navbar/AppDateTimeRange";
import { updateTimeStamps, UPDATE_ENTERPRISE_ACTORS } from "../../../redux/constants/constants";
import Checkbox from "@material-ui/core/Checkbox";
import { EnhancedDataSourceHeader } from "./EnhancedDataSourceHeader";
import { isBoolean, filter } from "lodash";
import page_loader from "../../../assets/vizr_images/page_loader.gif";
import { UPDATE_ROLE_API } from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import AlertDrawer from "../../shared/alertDrawer";

const DataSourceUsers = ({ dbusersDate ,roleName }) => {
  const [snackBarState, setSnackBarState] = useState({
    success: false,
    failure: false,
    successMessage: "",
    failureMessage: "",
  });
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openCalender, setOpenCalender] = useState(false);
  const [openBatchEditCalender, setOpenBatchEditCalender] = useState(false);
  const [accessRequestID, setAccessRequestID] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [dataSourceUsers, setAllNormalDataSourceUsers] = useState([]);
  const [stateOpen, setStateOpen] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const dispatch = useDispatch();

  const allNormalDataSourceUsers = useSelector(
    (state) => state.normalDataSourceReducer.allNormalDataSourceUsers
  );
  const currentPage = useSelector(
    (state) => state.normalDataSourceReducer.currentPage
  );
  const recordsPerPage = useSelector(
    (state) => state.normalDataSourceReducer.recordsPerPage
  );
  const totalCount = useSelector(
    (state) => state.normalDataSourceReducer.totalCount
  );
  const roles = useSelector((state) => state.normalDataSourceReducer.rolesData);
  const loader = useSelector((state) => state.normalDataSourceReducer.loader);
  const accountDataOnRole = useSelector(
    (state) => state.normalDataSourceReducer.accountDataOnRole
  );
  useEffect(() => {
    dispatch({ type: "SET_NORMAL_DATA_SOURCE_LOADER_INFO", payload: true });
    dispatch({ type: "GET_NORMAL_DATA_SOURCE_INFO_DATA" });
    dispatch({ type: "UPDATE_LOCATION", payload: "database_Users" });
    return () => {
      dispatch({ type: "UPDATE_LOCATION", payload: "" });
    };
  }, []);

  useEffect(() => {
    setAllNormalDataSourceUsers(allNormalDataSourceUsers);
    setSelectedList([]);
    setSnackBarState((prevState) => {
      return {
        ...prevState,
        success: false,
      };
    });
  }, [allNormalDataSourceUsers]);

  const handleChangeRowsPerPage = (value) => {
    dispatch({ type: "SET_NORMAL_DATA_SOURCE_RECORDS_PAGE", payload: value });
    dispatch({ type: "SET_NORMAL_DATA_SOURCE_CURRENT_PAGE", payload: 0 });
    dispatch({
      type: "GET_NORMAL_DATA_SOURCE_INFO_DATA",
    });
  };

  const handleChangePage = (page) => {
    dispatch({ type: "SET_NORMAL_DATA_SOURCE_CURRENT_PAGE", payload: page });
    dispatch({
      type: "GET_NORMAL_DATA_SOURCE_INFO_DATA",
    });
  };

  const renderSnackBar = () => {
    return (
      <SnackBar2
        success={
          snackBarState.success ||
          (snackBarState.successMessage && snackBarState.success)
        }
        failure={snackBarState.failure}
        successMessage={snackBarState.successMessage}
        errorMessage={snackBarState.failureMessage}
      ></SnackBar2>
    );
  };

  const renderCalender = () => {
    return (
      <Popper
        id={"simple-popper"}
        open={true}
        anchorEl={anchorEl2}
        style={{ marginTop: "21rem", zIndex: "1" }}
      >
        <Paper elevation={5}>
          <AppDateTimeRange
            loc="useraccessRights_content"
            setAnchorElHandler={setAnchorEl2}
            closeCalender={closeCalender}
            onTimeChange={onTimeChange}
          ></AppDateTimeRange>
        </Paper>
      </Popper>
    );
  };

  const setTarget = (target, id) => {
    setAnchorEl2(target);
    setOpenCalender(true);
    setAccessRequestID(id);
  };

  const closeCalender = () => {
    setOpenCalender(false);
    setOpenBatchEditCalender(false);
  };

  const handleOnChangeRole = async (value, data) => {
    dispatch({ type: "SET_NORMAL_DATA_SOURCE_LOADER_INFO", payload: true });
    const options = {
      method: "PUT",
      url: UPDATE_ROLE_API(value, data.databaseAccessRequestID),
      headers: getAxiosHeaders(true),
      data: {},
    };
    const response = await getService(options);
    if (response && response.status === 200) {
      dispatch({ type: "GET_NORMAL_DATA_SOURCE_INFO_DATA" });
      dispatch({ type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA" });
    } else {
      dispatch({ type: "SET_NORMAL_DATA_SOURCE_LOADER_INFO", payload: false });
    }
  };

  const epocTimeGenerator = (curExpiry, expiryDate) => {
    var theDate = new Date(curExpiry);
    var myNewDate = new Date(theDate);
    myNewDate.setDate(myNewDate.getDate() + expiryDate);
    return myNewDate.getTime();
  };

  const onTimeChange = (calenderSideMenu, expiryDate) => {
    let arr = [];
    if (openCalender) {
      if (calenderSideMenu) {
        let curExpiry;
        allNormalDataSourceUsers.forEach((el) => {
          if (el.databaseAccessRequestID === accessRequestID) {
            curExpiry = el.expiryDate;
          }
        });
        const updatedEpocTime = epocTimeGenerator(curExpiry, expiryDate);

        arr = [
          {
            databaseAccessRequestId: accessRequestID,
            expirationTimeInMillis: updatedEpocTime,
          },
        ];
      } else {
        arr = [
          {
            databaseAccessRequestId: accessRequestID,
            expirationTimeInMillis: expiryDate,
          },
        ];
      }
      let data = {
        updateExpirationTimeRequests: arr,
      };
      DataBaseUsersAPIUpdateCall(data);
    } else {
      if (selectedList.length > 0) {
        if (calenderSideMenu) {
          // let curExpiry;
          let arr = [];
          allNormalDataSourceUsers.forEach((el) => {
            if (selectedList.includes(el.databaseAccessRequestID)) {
              arr.push({
                databaseAccessRequestId: el.databaseAccessRequestID,
                expirationTimeInMillis: epocTimeGenerator(
                  el.expiryDate,
                  expiryDate
                ),
              });
            }
          });
          let data = {
            updateExpirationTimeRequests: arr,
          };
          DataBaseUsersAPIUpdateCall(data);
        } else {
          let arr = selectedList
            .filter((accessRequestID) => {
              if (accessRequestID && expiryDate) {
                return accessRequestID;
              }
            })
            .map((accessRequestID) => {
              return {
                databaseAccessRequestId: accessRequestID,
                expirationTimeInMillis: expiryDate,
              };
            });

          let data = {
            updateExpirationTimeRequests: arr,
          };
          DataBaseUsersAPIUpdateCall(data);
        }
      }
    }
  };

  const DataBaseUsersAPIUpdateCall = async (data) => {
    const res = await getService({
      method: "PUT",
      url: updateTimeStamps,
      headers: getAxiosHeaders(true),
      data: data,
    });
    if (res.status === 204) {
      dispatch({ type: "GET_NORMAL_DATA_SOURCE_INFO_DATA" });
      const obj = {
        success: true,
        successMessage: "Expiry date updated Successfully",
      };
      setSnackBarState({
        ...obj,
      });
    } else {
      setSnackBarState({
        failure: true,
        failureMessage: "Expiry Date updated failed. Please try later",
      });
    }
  };

  const getSelectAllStatus = () => {
    if (selectedList.length === 0) {
      return "UNCHECKED";
    }
    if (selectedList.length === allNormalDataSourceUsers.length) {
      return "CHECKED";
    }
    return "INTERMEDIATE";
  };

  const onSelectAllClick = (event) => {
    const list = [...dataSourceUsers];
    list.forEach((item) => {
      if (event.target.checked) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    setSelectedList(
      filter(list, (item) => item.checked).map((i) => i.databaseAccessRequestID)
    );
    setAllNormalDataSourceUsers(list);
  };

  const handleBatchEdit = (currentTarget) => {
    setAnchorEl2(currentTarget);
    setOpenBatchEditCalender(true);
  };

  const handleCheckItem = (event, item) => {
    const id = item.databaseAccessRequestID;
    const list = [...dataSourceUsers];
    list.forEach((item) => {
      if (item.databaseAccessRequestID === id) {
        item.checked = isBoolean(item.checked) ? !item.checked : true;
      }
    });
    setSelectedList(
      filter(list, (item) => item.checked).map((i) => i.databaseAccessRequestID)
    );
    setAllNormalDataSourceUsers(list);
  };

  const toggleDrawer = (open, event, row) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setPopUpData(row);
    setStateOpen(open);
  };

  const tableRowsClickHandler = (event, row) => {
    if (event.target && event.target.checked ===  undefined){
      toggleDrawer(true, event, row);
    }
  };

  const getColumnsData = () => {
    let rolesData = JSON.parse(JSON.stringify(roles));
    rolesData = rolesData.filter((data) => {
      if (
        data.code !== "ACCESS_WILL_EXPIRE" &&
        data.code !== "ACCESS_EXPIRED"
      ) {
        return data;
      }
    });
    // rolesData.splice(5,1);
    let data = DataSourceUserConstants(
      setTarget,
      accountDataOnRole,
      handleOnChangeRole,"",roleName
    );
      data = [
        {
          style: { width: "5%" },
          rowBodyCellStyle: { width: "5%", wordBreak: "break-all" },
          title: () => {
            return (
              <Checkbox
                value={getSelectAllStatus()}
                onChange={(event) => onSelectAllClick(event)}
                inputProps={{ "aria-label": "decorative checkbox" }}
                checkedIcon={
                  selectedList.length === allNormalDataSourceUsers.length ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                indeterminate={
                  selectedList.length > 0 &&
                  selectedList.length < allNormalDataSourceUsers.length
                }
              />
            );
          },
          render: (rowData) => {
            return (
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                onChange={(event) => {
                  handleCheckItem(event, rowData);
                }}
                checkedIcon={
                  rowData.checked ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={true}
              />
            );
          },
          padding: "checkbox",
          requiredPermission: UPDATE_ENTERPRISE_ACTORS
        },
        ...data,
      ];
    return data;
  };

  const renderTableToolbar = () => {
    return (
      <EnhancedDataSourceHeader
        totalElements={totalCount}
        rows={allNormalDataSourceUsers}
        loc={"database_Users"}
        title={"Normal Data Source Users"}
        helpDescription={
          "Other normal data source users along with their access information."
        }
        setBatchEdit={handleBatchEdit}
        selectedRows={selectedList}
        roleName={roleName}
      />
    );
  };

  const renderTable = () => {
    return (
      <EnhancedGridTable
        cols={getColumnsData()}
        data={allNormalDataSourceUsers}
        totalCount={totalCount}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onTableRowClick={(event, data) => tableRowsClickHandler(data, event)}
      />
    );
  };

  const renderSidePopup = () => {
    return (
      stateOpen && (
        <AlertDrawer
          toggleDrawer={toggleDrawer}
          stateOpen={stateOpen}
          popUpData={popUpData}
          loc="data-source-status"
        />
      )
    );
  };

  const renderLoader = () => {
    const height = allNormalDataSourceUsers.length > 0 ? "580px" : "60px";
    return (
      loader && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            zIndex: 5,
            top: "0px",
            display: "flex",
            justifyContent: "space-around",
            height: height,
            alignItems: "center",
          }}
        >
          <img src={page_loader} alt="loading"></img>
        </div>
      )
    );
  };

  return (
    <>
      {/* {renderChartData()} */}
      <div className="filter-table-container">
        <div className="table-container" style={{ position: "relative" }}>
          {renderTableToolbar()}
          {renderTable()}
          {renderLoader()}
        </div>
      </div>
      {openCalender && renderCalender()}
      {openBatchEditCalender && renderCalender()}
      {renderSnackBar()}
      {renderSidePopup()}
    </>
  );
};

export default DataSourceUsers;
