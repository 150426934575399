import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import './EditCriteriaDialog.scss';
import {
  NewPolicyButtonForToolBar,
  ColorButtonForClearFilter,
  CloseButton,
} from "../common/buttons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_USER_NODES_LIST,
  SET_GRAPH_NODES_LIST,
  usersWithAnomalies
} from "../../../src/redux/constants/constants";
import { getNodeIds, filterDataMapNodes } from "../shared/dataMapUtilities";

export default function EditCriteria(props) {
  const defaultUsers = 5;
  const {
    showCriteria,
    handleEditCriteria
  } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const graphNodesList = useSelector(
    (state) => state.dataMapReducer.graphNodesList
  );
  const noOfUsersSize = useSelector(
    (state) => state.dataMapReducer.size
  );
  const criteriaType = useSelector(
    (state) => state.dataMapReducer.type
  );
  const userSize = !noOfUsersSize ? defaultUsers : noOfUsersSize;
  const criteriaValue = !criteriaType ? usersWithAnomalies : criteriaType;
  const [radioValue, setValue] = React.useState(criteriaValue);
  const [noOfUsers, setNoOfUsers] = React.useState(userSize);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const noOfUsersHandleChange = (event) => {
    const value = parseInt(event.target.value);
    setNoOfUsers(value);
    if (value >= 1 && value <= 10) {
      setShowError(false)
    } else {
      setShowError(true);
    }
  };

  const selectedUserValue = isNaN(noOfUsers) || noOfUsers === 0 || noOfUsers < 0 || noOfUsers >= 11;

  useEffect(() => {
    setOpen(showCriteria);
  }, [showCriteria]);

  const handleClose = () => {
    setOpen(false);
    handleEditCriteria();
  };

  const handleDone = () => {
    let nodeIds = getNodeIds(graphNodesList);
    let dataMapNodes = filterDataMapNodes(graphNodesList);
    if (graphNodesList.data.nodes.length) {
      dispatch({
        type: SET_GRAPH_NODES_LIST,
        payload: {
          apiSucc: true,
          data: {
            nodes: dataMapNodes,
            edges: [graphNodesList.data.edges],
          },
        },
      });
    }
    if (nodeIds.length !== 0) {
      dispatch({
        type: GET_USER_NODES_LIST,
        payload: {
          nodeId: nodeIds,
          type: radioValue,
          size: noOfUsers,
        },
      });
    }
    setOpen(false);
    handleEditCriteria();
  }

  const handleClearAll = () => {
    setNoOfUsers(defaultUsers);
    setValue(usersWithAnomalies);
    setShowError(false);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <span>Show Criteria</span>
          <div className="dialog-header">
            <NewPolicyButtonForToolBar
              variant="contained"
              color="primary"
              size="small"
              onClick={handleDone}
              disabled={selectedUserValue}
            >
              Done
            </NewPolicyButtonForToolBar>
            <ColorButtonForClearFilter
              variant="contained"
              color="primary"
              size="small"
              onClick={handleClearAll}
            >
              Reset All
            </ColorButtonForClearFilter>
            <CloseButton
              variant="contained"
              color="primary"
              size="small"
              onClick={handleClose}
            >
              <CloseOutlinedIcon />
            </CloseButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="dialog_content">
            <span className="user-label">
              No of Users
            </span>
            <TextField
              className="user_count_txt"
              id="outlined-number"
              type="number"
              value={noOfUsers}
              onChange={noOfUsersHandleChange}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            {showError ? <div className="error_msg">Selected Users range between 1 to 10 only</div> : ''}
            <div className="view_by">View by</div>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={radioValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="usersWithAnomalies"
                control={<Radio color="primary" />}
                label={`Top ${noOfUsers} Users with Anomalies`}
                color="primary"
              />
              <span> </span>
              <FormControlLabel
                value="usersWithSensitiveAccess"
                control={<Radio color="primary" />}
                label={`Top ${noOfUsers} Users with Percentage Sensitivity Access`}
              />
            </RadioGroup>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}