import { UPDATE_ALERT_STATUS_SUCCEEDED,CLOSE_SNACKBAR,UPDATE_ALERT_STATUS_FAILED } from '../constants/constants';

const initialState = {
    alertUpdated : false,
    successMessage: '',
    unsuccessful : '',
    alertStatus:''
  };

  export const changeAlertStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ALERT_STATUS_SUCCEEDED: {
            console.log("UPDATE_ALERT_STATUS_SUCCEEDED");
            return {
              ...state,
              successMessage: 'Alert(s) status Updated',
              alertUpdated: true,
              alertStatus:action.payload
            };
          }
          case UPDATE_ALERT_STATUS_FAILED: {
            console.log("UPDATE_ALERT_STATUS_FAILED");
            return {
              ...state,
              alertUpdated: false,
              unsuccessful: action.payload,
            };
          }
          case CLOSE_SNACKBAR: {
            console.log("CLOSE_SNACKBAR");
            return {
              ...state,
              successMessage: '',
              unsuccessful : '',
              alertUpdated: false,
            };
          }
          default:
            return state;
    }
  };
