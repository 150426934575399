
import React, { useState } from 'react';
import { AllAccessFilePopUpHeadCells } from '../common/headCells';
import EnhancedTable from './alertsTable';

export default function AllAccessFilePopUp() {

  const [getAllAccessFileData, setAllAccessFileData] = useState(false);

  return (
    <>
      <EnhancedTable
       headCells={AllAccessFilePopUpHeadCells}
       loc={"All_Access_File_PopUp"}
       getAllAccessFilePopUpData={getAllAccessFileData}
       handleGetAllAccessFilePopUp={setAllAccessFileData} />
    </>
  );
}
