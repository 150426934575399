import {  FILTER_ALERTS_URL } from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function filterAlerts(severity,resolution,policy,sortBy,alertPage,pageSize,policyId) {
    //console.log(severity)
    let options;
    let sev = '';
    let res = '';
    let name = '';
    let paramData = {};
    if(severity.length > 0){
        severity.forEach((each) => {
            sev = sev + `${each},`
        })
        sev = sev.slice(0,-1);
        paramData.severity = sev;
    }
    if(resolution.length > 0){
        resolution.forEach((each) => {
            res = res + `${each},`
        })
        res = res.slice(0,-1);
        paramData.resolutionType = res;
    }
    if(policy.length > 0){
        policy.forEach((each) => {
            name = name + `${each},`
        })
        name = name.slice(0,-1);
        paramData.policy = name;
    }
    if(sortBy !== ''){
        paramData.sortBy = sortBy;
        paramData.direction = 'ASC'
    }
    if(policyId){
        paramData.policy = policyId
    }
    options = {
        method: "GET",
        url: `${FILTER_ALERTS_URL}?size=${pageSize}&page=${alertPage}&status=UNRESOLVED`,
        params: paramData,
        headers: getAxiosHeaders(true)
    };
    const response = await getService(options);
    if(response) {
        //window.location.reload(false);
        return response.data
    }
};
