import React from "react";
import "./RiskGovernance.scss";
import Input from "../../shared/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { riskTypeIcons, riskTypeLookup } from "./riskPilotConstant";

export const RenderFormFields = ({
  options,
  value,
  setValue,
  severityValue,
  loc,
  isEditMode,
  owner
}) => {
  const riskClasses = () => {
    if (loc === "risk") {
      return "riskTypeIconsContainer iconSelector";
    } else {
      return "";
    }
  };
  if (severityValue) {
    return (
      <div className="mitigationStatusParentContainer">
        <Select
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          className="InputSelectField file-pop-up impact"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {options.map((option, index) => (
            <MenuItem className="severity-selection" key={index} value={option}>
              <div className={`inspect-file-severity file-pop-up ${option}`}>
                {option}
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  } else {
    return (
      <>
        <FormControl variant="outlined" fullWidth={true}>
          <Select
            value={value}
            onChange={(event) => setValue(event.target.value)}
            className="InputSelectField file-pop-up impact"
            disabled={loc === "risk" && isEditMode}
          >
            {options.map((option, index) => (
              <MenuItem
                key={index}
                value={loc && loc === "risk" ? option.code : option}
              >
                <div className={riskClasses()}>
                  {loc === "risk" && !owner? (
                    <div className="riskTypeImageContainer">
                      <img
                        src={riskTypeIcons[option.code]}
                        alt={option.value}
                      />
                    </div>
                  ) : null}
                  {loc && loc === "risk" ? option.value : option}
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }
};

export const renderInputFields = (value, setValue, placeholder, isEditMode) => {
  return (
    <>
      <Input
        type="text"
        classname="riskRegisterNumberInput"
        value={value}
        disabled={isEditMode && placeholder === "Risk ID"}
        placeholder={placeholder}
        eventHandler={(value) => setValue(value)}
      />
    </>
  );
};

export const renderTextArea = (value, setValue, placeholder, isEditMode) => {
  return (
    <>
      <textarea
        type="text"
        className="riskRegisterTextAreaInput"
        rows={4}
        cols={40}
        defaultValue={value} // Changed value to defaultValue
        disabled={isEditMode && placeholder === "Risk ID"}
        placeholder={placeholder}
        onChange={(event) => setValue(event.target.value)} // Changed eventHandler to onChange
      />
    </>
  );
};

export const renderFormWithIcon = (options, value, setValue, loc) => {
  const renderMenuItemDetails = (icon, value) => {
    return (
      <div className="mitigationStatusContainer">
        <img src={icon} alt={icon} style={{marginBottom: "1%"}} />
        {value}
      </div>
    );
  };
  return (
    <div className="mitigationStatusParentContainer">
      <FormControl variant="outlined" fullWidth={true}>
        <Select
          value={value}
          onChange={(event) => setValue(event.target.value)}
          style={{background: "white"}}
          className="InputSelectField file-pop-up impact"
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={loc && loc === "risk" ? option.code : option}
            >
              {renderMenuItemDetails(option.icon, option.value)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const renderHeading = (value, loc) => {
  return (
    <div
      className={
        loc && loc === "threat"
          ? "riskRegisterHeading threat"
          : "riskRegisterHeading"
      }
    >
      <span>{value}</span>
    </div>
  );
};

export const renderRiskTypeWithIcon = (
  allRiskTypes,
  risk,
  setRisk,
  isEditMode
) => {
  if (isEditMode) {
    return (
      <div className="riskTypeParentContainer riskTypeIconContainer">
        <img src={riskTypeIcons[risk]} alt="Risk Icon" />
        <div>{riskTypeLookup[risk]}</div>
      </div>
    );
  } else {
    return (
      <>
        <RenderFormFields
          options={allRiskTypes}
          value={risk}
          setValue={setRisk}
          loc="risk"
          isEditMode={isEditMode}
        />
      </>
    );
  }
};

const renderNull = () => {
  return <div className="riskSummaryInfo threat">-</div>;
};

const renderThreats = (params) => {
  if (params === 0) {
    return renderNull();
  } else {
    return <div className="riskInfoSummaryDataContainer" title={params}>{params}</div>;
  }
};

const renderVulnerabilities = (params) => {
  if (params === 0) {
    return renderNull();
  } else {
    return <div className="riskInfoSummaryDataContainer" title={params}>{params}</div>;
  }
};

const renderMitigations = (
  mitigationCount,
  toDoCount,
  openCount,
  inProgressCount,
  closedCount
) => {
  const counts = [
    mitigationCount,
    toDoCount,
    openCount,
    inProgressCount,
    closedCount,
  ];

  if (counts.every((val) => parseInt(val) === 0)) {
    return renderNull();
  }

  const dataWithIcons = [
    { count: toDoCount, icon: "/riskPilotIcons/ToDoWhite.svg", code: "TODO" },
    { count: openCount, icon: "/riskPilotIcons/openWhite.svg", code: "OPEN" },
    {
      count: inProgressCount,
      icon: "/riskPilotIcons/InProgressWhite.svg",
      code: "IN_PROGRESS",
    },
    {
      count: closedCount,
      icon: "/riskPilotIcons/closedWhite.svg",
      code: "CLOSED",
    },
  ];

  return (
    <div className="summaryMitigationCountContainer">
      <div className="riskSummaryInfo totalMitigationCount">{mitigationCount}</div>
      <div className="mitigationCountChildContainer">
        {dataWithIcons.map(({ count, icon, code }) => (
          <div
            key={code}
            className={`mitigationStatusCount ${code}`}
            title={`${code}: ${count}`}
          >
            <div className="statusCount riskSummary">
              <img src={icon} alt={code} />
              <div className="mitigationCounts">{count}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const summaryClassNames = (label) => {
  if (label === "Threats") {
    return "riskSummaryInfo threat"
  } else if (label === "Vulnerabilities") {
    return "riskSummaryInfo vulnerability"
  } else {
    return "riskSummaryInfo"
  }
}

const SummaryItem = ({ icon, label, children, imgClass = "riskSummaryImageSize" }) => (
  <div className="riskSummaryThreatContainer">
    <div className="riskSummaryImageContainer">
      <img className={imgClass} src={icon} alt="icon" />
      <div className="riskSummaryTextInfo">{`${label}:`}</div>
      <div className={summaryClassNames(label)}>{children}</div>
    </div>
  </div>
);

export const riskSummaryDataComp = (riskData) => {
  const threatCount = riskData[`RiskPilotRisks.threatCount`] || 0;
  const vulnerabilityCount = riskData[`RiskPilotRisks.vulnerabilityCount`] || 0;
  const totalMitigationCount = riskData[`RiskPilotRisks.mitigationCount`] || 0;
  const toDoCount = riskData[`RiskPilotRisks.toDoCount`] || 0;
  const openCount = riskData[`RiskPilotRisks.openCount`] || 0;
  const inProgressCount = riskData[`RiskPilotRisks.inProgressCount`] || 0;
  const closedCount = riskData[`RiskPilotRisks.closedCount`] || 0;
  return (
  <div className="riskSummaryContainer">
    <SummaryItem icon="/riskPilotIcons/ThreatIcon.svg" label="Threats">
      {renderThreats(threatCount)}
    </SummaryItem>
    <SummaryItem
      icon="/riskPilotIcons/VulnerbilityIcon.svg"
      label="Vulnerabilities"
    >
      {renderVulnerabilities(vulnerabilityCount)}
    </SummaryItem>
    <SummaryItem
      icon="/riskPilotIcons/MitigationIcon.svg"
      label="Mitigations"
      imgClass="riskSummaryMitigationImageSize"
    >
      {renderMitigations(
        totalMitigationCount,
        toDoCount,
        openCount,
        inProgressCount,
        closedCount
      )}
    </SummaryItem>
  </div>
)};

