import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ImageTooltip from "../../common/HelpIconWithTooltip";

import {
  Concentrator,
  Ip,
  Endpoints,
  MDR_SETTINGS_UPDATE_LOCATION,
  createConcentrator,
  createSubnet,
  createEndpoint,
  AddConcentrator,
  AddForwardIPSubnets,
  AddEndpointsConcentratorConfig,
  CreateConcentratorSettings,
  CreateIpSettings,
  CreateEndpointSettings,
} from "../../../redux/constants/constants";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const history = useHistory();
  const {
    totalElements,
    loc,
    alertsTitle,
    helpDescription,
    id,
    name,
    platformSubdomainNameFrom,
    hide,
  } = props;

  const dispatch = useDispatch();

  const renderHeaderTitle = () => {
    return (
      <Grid style={{ display: "flex" }} item xs={6}>
        <Typography
          className="title"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {alertsTitle}
        </Typography>
        <div className="count">{totalElements}</div>
        <ImageTooltip description={helpDescription} />
      </Grid>
    );
  };

  const toggleButton = () => {
    if (loc === Concentrator) {
      dispatch({
        type: MDR_SETTINGS_UPDATE_LOCATION,
        payload: createConcentrator,
      });
      history.push({
        pathname: CreateConcentratorSettings(id),
        state: {
          name: name,
          platformSubdomainNameFrom: platformSubdomainNameFrom,
        },
      });
    } else if (loc === Ip) {
      dispatch({
        type: MDR_SETTINGS_UPDATE_LOCATION,
        payload: createSubnet,
      });
      history.push({
        pathname: CreateIpSettings(id),
        state: {
          name: name,
          platformSubdomainNameFrom: platformSubdomainNameFrom,
        },
      });
    } else if (loc === Endpoints) {
      dispatch({
        type: MDR_SETTINGS_UPDATE_LOCATION,
        payload: createEndpoint,
      });
      history.push({
        pathname: CreateEndpointSettings(id),
        state: {
          name: name,
          platformSubdomainNameFrom: platformSubdomainNameFrom,
        },
      });
    }
  };

  const toggleLocation = () => {
    if (loc === Concentrator) {
      return AddConcentrator;
    } else if (loc === Ip) {
      return AddForwardIPSubnets;
    } else if (loc === Endpoints) {
      return AddEndpointsConcentratorConfig;
    } else {
      return AddConcentrator;
    }
  };

  const renderButtons = () => {
    return (
      <Grid item xs={6} style={{ float: "right", textAlign: "right" }}>
        {hide !== true && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              toggleButton();
            }}
            style={{
              color: "rgb(255, 255, 255)",
              textTransform: "none",
              fontSize: "16px",
              padding: "6px 12px",
              lineHeight: 1.5,
              backgroundColor: "rgb(41, 71, 253)",
              borderRadius: "6px",
            }}
            className="min-margin table-buttons"
          >
            {toggleLocation()}
          </Button>
        )}
      </Grid>
    );
  };

  const renderGrid = () => {
    return (
      <Grid container spacing={3}>
        {renderHeaderTitle()}
        {renderButtons()}
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          {renderGrid()}
        </Grid>
      </Toolbar>
    </Paper>
  );
};
