import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import { getCubejsApi } from "../common/Dashboard/cubejs-util";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import DataMapContents from "./DataMapContents";
import DataMapDataFlow from "./DataMapDataFlow";
import DataMapFlowGroup from "./DataMapFlowGroup";
import DataMapAlerts from "./DataMapAlerts";
import DataMapAlertPolicies from "./DataMapAlertPolicies";
import { getTimeForToolTip, isCloudFileSystem, sourceIcons } from "../common/utilities";
import AlertBadge from "./Badge/AlertBadge";
import SensitivityBadge from "./Badge/SensitivityBadge";
import { frameTooltipData } from "../common/utilities";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DataMapInspect(props) {
  const {
    tableViewClick,
    selectedAppByType,
    selectedApp,
    selectedAlertIds,
    bottomTrayEnable,
    bottomTrayEnableEve,
    nodeName,
    dataSource,
    creationTime,
    tooltipData
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedNode = useSelector(
    (state) => state.selectedNode.selectedNodeId
  );
  const selectedNodeSubType = useSelector(
    (state) => state.selectedNode.selectedNodeSubType
  );
  const { t, i18n } = useTranslation();
  const [allTabsList, setAllTabsList] = useState([]);
  let cubejsApi = getCubejsApi();
  const [queryData, setQueryData] = useState(null);
  const [headerName, setHeaderName] = useState("");
  const [description, setDescription] = useState("");
  const [nodeTooltip, setNodeTooltip] = useState({
    alertToolTipData: null, sensitiveData: null
  });
  let selectedDFGTrueFlag = true;
  let selectedDFTrueFlag = true;
  let selectedTabInd = 0;

  const cloudFileSystem = isCloudFileSystem(selectedNodeSubType.code);

  if (selectedAppByType && selectedAppByType.type) {
    selectedDFGTrueFlag = true;
    selectedDFTrueFlag = true;
    if (selectedAppByType.type == "contents") {
      selectedTabInd = 0;
    } else if (selectedAppByType.type == "alerts") {
      selectedTabInd = 1;
    } else if (selectedAppByType.type == "alertPolicies") {
      selectedTabInd = 2;
    } else if (selectedAppByType.type == "dataFlows") {
      selectedDFTrueFlag = false;
      selectedTabInd = 3;
    } else if (selectedAppByType.type == "dataFlowGroups") {
      selectedDFGTrueFlag = false;
      selectedTabInd = 4;
    } else if (selectedAppByType.type == "traces") {
      selectedTabInd = 5;
    }
  }

  if (selectedAppByType.tabIndex) {
    if (
      selectedAppByType.type == "dataFlows" ||
      selectedAppByType.type == "dataFlowGroups"
    ) {
      selectedTabInd = selectedDFGTrueFlag && selectedDFTrueFlag ? 4 : 3;
    }
  } else {
    selectedTabInd = 0;
  }

  const [value, setValue] = useState(selectedTabInd);
  const [selectedDFGTrue, setSelectedDFGTrue] = useState(selectedDFGTrueFlag);
  const [selectedDFTrue, setSelectedDFTrue] = useState(selectedDFTrueFlag);

  useEffect(() => {
    const { alertToolTipData, sensitiveData } = frameTooltipData(tooltipData);
    const tooltip = {
      alertToolTipData, sensitiveData
    }
    setNodeTooltip(tooltip);
  }, [selectedNodeSubType]);

  useEffect(() => {
    const { alertToolTipData, sensitiveData } = frameTooltipData(tooltipData);
    const tooltip = {
      alertToolTipData, sensitiveData
    }
    setNodeTooltip(tooltip);
    return () => {
      dispatch({ type: "SELECTED_NODE", payload: { node: "", subtype: "" } });
    };
  }, []);

  useEffect(() => {
    if (bottomTrayEnable) {
      setValue(selectedTabInd);
      setSelectedDFGTrue(selectedDFGTrueFlag);
      setSelectedDFTrue(selectedDFTrueFlag);
    }
  }, [bottomTrayEnable]);

  useEffect(() => {
    if (selectedAppByType && selectedAppByType.id) {
      if (selectedAppByType.type == "dataFlowGroups") {
        if (selectedApp && Object.keys(selectedApp).length > 0) {
          setHeaderName(`${selectedApp.name} > ${selectedAppByType.display}`);
          setDescription(`Data Flow group in application ${selectedApp.name}`);
        } else {
          setHeaderName(selectedAppByType.name);
          setDescription(`Data Flow group details`);
        }
      } else if (selectedAppByType.type == "dataFlows") {
        if (selectedApp && Object.keys(selectedApp).length > 0) {
          setHeaderName(`${selectedApp.name} > ${selectedAppByType.display}`);
          setDescription(`Data Flow in application ${selectedApp.name}`);
        } else {
          setHeaderName(selectedAppByType.name);
          setDescription(`Data Flow details`);
        }
      }

      const reqQuery = {
        measures: ["AlertsByApplication.alertsCount"],
        timeDimensions: [
          {
            dimension: "AlertsByApplication.generatedOn",
            granularity: "day",
          },
        ],
        dimensions: ["AlertsByApplication.severityCode"],
      };

      if (selectedAppByType.type === "dataFlows") {
        reqQuery["filters"] = [
          {
            member: "AlertsByApplication.dataFlowId", //dataFlowId
            operator: "equals",
            values: [selectedAppByType.id],
          },
        ];
      } else if (selectedAppByType.type === "dataFlowGroups") {
        reqQuery["filters"] = [
          {
            member: "AlertsByApplication.dataFlowGroupId", //dataFlowGroupId
            operator: "equals",
            values: [selectedAppByType.id],
          },
        ];
      }

      setQueryData(reqQuery);
    } else {
      if (selectedAppByType.type === "contents") {
        if (selectedApp && Object.keys(selectedApp).length > 0) {
          setHeaderName(`${selectedApp.name} > ${selectedAppByType.display}`);
          setDescription(`Contents in application ${selectedApp.name}`);
        } else {
          setHeaderName(`All applications > ${selectedAppByType.display}`);
          setDescription(`Contents across all applications`);
        }
      } else if (selectedAppByType.type === "alerts") {
        if (selectedApp && Object.keys(selectedApp).length > 0) {
          setHeaderName(`${selectedApp.name} > ${selectedAppByType.display}`);
          setDescription(`Alerts in application ${selectedApp.name}`);
        } else {
          setHeaderName(`All applications > ${selectedAppByType.display}`);
          setDescription(`Alerts across all applications`);
        }
      } else if (selectedAppByType.type === "alertPolicies") {
        if (selectedApp && Object.keys(selectedApp).length > 0) {
          setHeaderName(`${selectedApp.name} > ${selectedAppByType.display}`);
          setDescription(`Policies in application ${selectedApp.name}`);
        } else {
          setHeaderName(`All applications > ${selectedAppByType.display}`);
          setDescription(`Policies across all applications`);
        }
      } else if (selectedAppByType.type === "dataFlows") {
        if (selectedApp && Object.keys(selectedApp).length > 0) {
          setHeaderName(`${selectedApp.name} > ${selectedAppByType.display}`);
          setDescription(`Data Flows in application ${selectedApp.name}`);
        } else {
          setHeaderName(`All applications > ${selectedAppByType.display}`);
          setDescription(`Data Flows across all applications`);
        }
      } else if (selectedAppByType.type === "dataFlowGroups") {
        if (selectedApp && Object.keys(selectedApp).length > 0) {
          setHeaderName(`${selectedApp.name} > ${selectedAppByType.display}`);
          setDescription(`Data Flow group in application ${selectedApp.name}`);
        } else {
          setHeaderName(`All applications > ${selectedAppByType.display}`);
          setDescription(`Data Flow group across all applications`);
        }
      }
    }
    return () => { };
  }, []);

  useEffect(() => {
    if (value && !selectedAppByType.id) {
      if (selectedApp && Object.keys(selectedApp).length > 0) {
        if (value === 0) {
          setHeaderName(`${selectedApp.name} > Contents`);
        } else if (value === 1) {
          setHeaderName(`${selectedApp.name} > Alerts`);
        } else if (value === 2) {
          setHeaderName(`${selectedApp.name} > Policies`);
        } else if (value === 3) {
          setHeaderName(`${selectedApp.name} > Data Flows`);
        } else if (value === 4) {
          setHeaderName(`${selectedApp.name} > Data Flow Groups`);
        } else {
          setHeaderName(`${selectedApp.name} > Traces`);
        }
      } else {
        // when ever no app is selected
        if (value === 0) {
          setHeaderName(`All applications > Contents`);
        } else if (value === 1) {
          setHeaderName(`All applications > Alerts`);
        } else if (value === 2) {
          setHeaderName(`All applications > Policies`);
        } else if (value === 3) {
          setHeaderName(`All applications > Data Flows`);
        } else if (value === 4) {
          setHeaderName(`All applications > Data Flow Groups`);
        } else {
          setHeaderName(`All applications > Traces`);
        }
      }
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const transactionsCountClick = () => {
    setValue(5);
  };
  // const headerName = (selectedApp && selectedApp.name) ? `${selectedApp.name} > ${selectedAppByType.display}` : null;

  // const headerProps = {
  //   id: 12,
  //   name: headerName ? headerName : `All Applications > ${selectedAppByType.display}`,
  //   sources: "0",
  //   targets: "0",
  //   description: headerName ? "Details of Data Flow/Flow Group." : "Details of all applications."
  // }

  const dataFlowNameClick = (data) => {
    data["type"] = "dataFlows";
    data["tabIndex"] = true;
    if (history && history.location && history.location.pathname) {
      if (history.location.pathname.includes("dataMap")) {
        // history.push({
        //     pathname: '/tenant/dataFlowGroups',
        //     state: data
        // })
        setSelectedDFTrue(false);
        setSelectedDFGTrue(true);
        setValue(0);
      } else {
        dispatch({ type: "TRACK_TRAVERSAL", payload: "Data Map" });
        history.push({
          pathname: "/tenant/dataMap",
          state: data,
        });
      }
    }
  };

  const flowGroupNameClick = (data) => {
    data["type"] = "dataFlowGroups";
    data["tabIndex"] = true;
    if (history && history.location && history.location.pathname) {
      if (history.location.pathname.includes("dataMap")) {
        history.push({
          pathname: "/tenant/dataFlows",
          state: data,
        });
        setSelectedDFGTrue(false);
        setSelectedDFTrue(true);
        setValue(0);
      } else {
        dispatch({ type: "TRACK_TRAVERSAL", payload: "Data Map" });
        history.push({
          pathname: "/tenant/dataMap",
          state: data,
        });
      }
    }
  };
  const oneDriveTabs = () => {
    return (
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        scrollButtons="auto"
      >
        <Tab label="Contents" {...a11yProps(0)} />
        <Tab label="Alerts" {...a11yProps(1)} />
        <Tab label="Policies" {...a11yProps(2)} />
      </Tabs>
    );
  };



  const badges = () => {
    if (dataSource && dataSource.type.code === "APPLICATION") {
      return (
        <div className="inspect-alerts">
          <AlertBadge title={"Alerts:"} parentprops={nodeTooltip.alertToolTipData} InspectContent={true} />
        </div>
      );
    } else {
      return (
        <>
          <div className="inspect-alerts sensitivity">
            <SensitivityBadge parentprops={nodeTooltip.sensitiveData} loc="InspectContent" title={"Sensitivity:"} />
          </div>
          <div className="inspect-alerts">
            <AlertBadge title={"Alerts:"} parentprops={nodeTooltip.alertToolTipData} InspectContent={true} />
          </div>
        </>
      );
    }
  };

  const inspectHeaderContent = () => {
      return (
        <div className="inspect-title-header">
          <div className="inspect-title" title={nodeName}>
            <img className="inspect-oneDriveImage" src={sourceIcons(dataSource)} alt="oneDrive Icon" />
            <p className="node-title oneDriveNodeTitle">{nodeName}</p>
          </div>
          <div className="inspect-title-right">
            <div className="inspect-dataSource">
              <div className="inspect-text dataOwnerTitles">Data Source: </div>
              <div className="inspect-text">{dataSource.value}</div>
            </div>
            <div className="inspect-creationTime">
              <div className="inspect-text dataOwnerTitles">Added: </div>
              <div className="inspect-text">{getTimeForToolTip(creationTime)}</div>
            </div>
            {badges()}
          </div>
        </div>
      );
  }

  const handleTabs = () => {
    if (selectedDFGTrue && selectedDFTrue) {
      return (
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab label="Contents" {...a11yProps(0)} />
          <Tab label="Alerts" {...a11yProps(1)} />
          <Tab label="Policies" {...a11yProps(2)} />
          <Tab label="Data Flows" {...a11yProps(3)} />
          <Tab label="Flow Groups" {...a11yProps(4)} />
          {/* <Tab label="Traces" {...a11yProps(5)} /> */}
        </Tabs>
      );
    } else if (selectedDFTrue) {
      return (
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab label="Contents" {...a11yProps(0)} />
          <Tab label="Alerts" {...a11yProps(1)} />
          <Tab label="Policies" {...a11yProps(2)} />
          <Tab label="Data Flows" {...a11yProps(3)} />
          {/* <Tab label="Traces" {...a11yProps(4)} /> */}
        </Tabs>
      );
    } else {
      return (
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab label="Contents" {...a11yProps(0)} />
          <Tab label="Alerts" {...a11yProps(1)} />
          <Tab label="Policies" {...a11yProps(2)} />
          <Tab label="Flow Groups" {...a11yProps(3)} />
          {/* <Tab label="Traces" {...a11yProps(4)} /> */}
        </Tabs>
      );
    }
  };

  const handleTabPanels = () => {
    if (selectedDFGTrue && selectedDFTrue) {
      return (
        <>
          <TabPanel value={value} index={3} className="contents-panel-dataFlow">
            <DataMapDataFlow
              selectedAppByType={selectedAppByType}
              dataFlowNameClick={dataFlowNameClick}
              transactionsTabHandle={() => { }}
              selectedApp={selectedApp}
              selectedNode={selectedNode}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={4}
            className="contents-panel-flowGroup"
          >
            <DataMapFlowGroup
              transactionsCountClick={transactionsCountClick}
              selectedAppByType={selectedAppByType}
              flowGroupNameClick={flowGroupNameClick}
              selectedApp={selectedApp}
              selectedNode={selectedNode}
            />
          </TabPanel>
          {/* <TabPanel
            value={value}
            index={5}
            className="contents-panel-transactions"
          >
            <DataMapTransactions selectedApp={selectedApp} />
          </TabPanel> */}
        </>
      );
    } else if (selectedDFTrue) {
      return (
        <>
          <TabPanel value={value} index={3} className="contents-panel-dataFlow">
            <DataMapDataFlow
              selectedAppByType={selectedAppByType}
              dataFlowNameClick={dataFlowNameClick}
              transactionsTabHandle={() => { }}
              selectedApp={selectedApp}
              selectedNode={selectedNode}
            />
          </TabPanel>
          {/* <TabPanel
            value={value}
            index={4}
            className="contents-panel-transactions"
          >
            <DataMapTransactions selectedApp={selectedApp} />
          </TabPanel> */}
        </>
      );
    } else {
      return (
        <>
          <TabPanel
            value={value}
            index={3}
            className="contents-panel-flowGroup"
          >
            <DataMapFlowGroup
              transactionsCountClick={transactionsCountClick}
              selectedAppByType={selectedAppByType}
              flowGroupNameClick={flowGroupNameClick}
              selectedApp={selectedApp}
              selectedNode={selectedNode}
            />
          </TabPanel>
          {/* <TabPanel
            value={value}
            index={4}
            className="contents-panel-transactions"
          >
            <DataMapTransactions selectedApp={selectedApp} />
          </TabPanel> */}
        </>
      );
    }
  };

  return (
    <div className="datamap-bottom-view">
      <div className="inspect-contents">
        <AppBar
          position="static"
          className="inspect-contents-header"
          color="default"
        >
          <div
            className="header-bar"
            onClick={() => {
              dispatch({
                type: "SELECTED_NODE",
                payload: { node: "", subtype: "" },
              });
              bottomTrayEnableEve(false);
            }}
          ></div>
          {inspectHeaderContent()}
          {cloudFileSystem ? oneDriveTabs() : handleTabs()}
        </AppBar>
        <div style={{ overflow: "hidden" }}>
          <TabPanel value={value} index={0} className="contents-panel">
            <DataMapContents
              selectedNode={selectedNode}
              selectedNodeSubType={selectedNodeSubType.code}
              selectedApp={selectedApp}
              bottomTrayEnable={bottomTrayEnable}
              sourceName={dataSource.code}
            />
          </TabPanel>
          <TabPanel value={value} index={1} className="contents-panel-alerts">
            <DataMapAlerts
              selectedAppByType={selectedAppByType}
              selectedAlertIds={selectedAlertIds}
              selectedNode={selectedNode}
              selectedApp={selectedApp}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            className="contents-panel-alertPolicies"
          >
            <DataMapAlertPolicies
              selectedAppByType={selectedAppByType}
              selectedAlertIds={selectedAlertIds}
              selectedNode={selectedNode}
              selectedApp={selectedApp}
            />
          </TabPanel>
          {cloudFileSystem ? <></> : handleTabPanels()}
        </div>
      </div>
    </div>
  );
}
