import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from "dompurify";
import {
  FETCH_VIEW_REPORT,
  CLEAR_HTML_PAGE,
  GET_ALL_USER_EMAILS,
  fetchReport,
} from "../../../redux/constants/constants";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { Button } from "@material-ui/core";
import { isEmpty } from "lodash";
import CanAllow from "../../shared/CanAllow";
import ShareReport from "./ShareReport";
import { Helmet } from "react-helmet";
import { reportGraphScript } from "../../common/utilities";

export default function ReportPage() {
  const dispatch = useDispatch();
  const htmlData = useSelector((state) => state.GenerateReportReducer.htmlData);
  const loader = useSelector((state) => state.GenerateReportReducer.loader);
  const viewReportErrorMessage = useSelector((state) => state.GenerateReportReducer.viewReportErrorMessage);

  const [decodedHTML, setDecodedHTML] = useState("");
  const [reportScript, setReportScript] = useState("");

  // Get the current URL
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const searchParams = url.searchParams;
  const id = searchParams.get("id");

  useEffect(() => {
    dispatch({ type: FETCH_VIEW_REPORT, payload: id });
    return () => {
      dispatch({ type: CLEAR_HTML_PAGE });
    };
  }, []);

  const htmlDataReports = () => {
    const reportHTML = decodeURIComponent(
      htmlData.reportHtml.replace(/\+/g, "%20")
    );

    //Glue elements like style or others to document.body and prevent unintuitive browser behavior in several edge cases (default is false)
    const sanitizedHTML = DOMPurify.sanitize(reportHTML, {
      FORCE_BODY: true,
    });    
    // Include a script for testing
    const originalArray = htmlData.rowDataList;
    const transformedArray = originalArray.map((item) => {
      const id = item.id;
      // Check if data.high, data.medium, and data.low are all zero
      const isAllValuesZero = item.data.every(([label, value]) => parseInt(value) === 0);
      // If all values are zero, create a single dataset; otherwise, parse the values
      const data = isAllValuesZero
        ? [["No Data", 1]]
        : item.data.map(([label, value]) => [label, parseInt(value, 10)]);
      // Set colors to grey if all values are zero; otherwise, use the provided colors
      const colors = isAllValuesZero ? ["#8A8D93"] : item.colors;
      const options = { colors };
      return { id, data, options };
    });
    const graphScript = reportGraphScript(transformedArray)
    setReportScript(graphScript);
    setDecodedHTML(sanitizedHTML);
    dispatch({ type: GET_ALL_USER_EMAILS });
  };

  useEffect(() => {
    if (!isEmpty(htmlData)) {
      htmlDataReports();
    }
  }, [htmlData]);


  const renderReport = () => {
    if (htmlData && isEmpty(htmlData.reportHtml)) {
      return (
        <>
          <div className="noReportFound">This Report is not shared with you</div>
        </>
      )
    } else if (!isEmpty(reportScript) && !isEmpty(decodedHTML)) {
      return (
        <>
          <div className="printShareContainer">
            <div className="printContainer">
              <div className="Generate-button-container">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => window.print()}
                  className="generate_report_button printButton"
                >
                  Print Report
                </Button>
              </div>
              <CanAllow needs={{ permission: [fetchReport] }}>
                <div>
                  <ShareReport id={[id]} />
                </div>
              </CanAllow>
            </div>
          </div>
          {/* Render the HTML content */}
          <div dangerouslySetInnerHTML={{ __html: decodedHTML }} />
        </>
      )
    } else if (!isEmpty(viewReportErrorMessage)) {
      return (
        <>
          <div className="noReportFound">{viewReportErrorMessage}</div>
        </>
      )
    }
  }
  return (
    <div>
      {loader && (
        <div className="loader-overlay report-page">
          <img
            src={Page_Loader}
            alt="loading"
            className="loader-data-source report-view"
          ></img>
        </div>
      )}
      {renderReport()}
      {/* Render the Google Charts script */}
      <Helmet>
        <script src="https://www.gstatic.com/charts/loader.js"></script>
        <script>{reportScript}</script>
      </Helmet>
    </div>
  );
}
