import React, { useState, useEffect, useReducer } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import PropTypes from "prop-types";
import { dataFlowStaData } from "../../assets/staticData/dataFlow";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import LevelComponent from "./levelComponent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  fetchDMAlertAndPolicies,
  fetchDMListByQueryStr,
  fetchDMDataFlowsCount,
} from "../../redux/actions/dataMapInspectAction";
import LoaderComponent from "./LoaderComponent";
import { cubeQueryGen, cubeQueryGenCount, getTime } from "../common/utilities";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClearFilter from "./clearFilter";
import FilterComponent from "./filterComponent";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import SortComponent from "./sortComponent";
import PolicyFilter from "./policyFilter";
import { filterAlerts } from "../../redux/actions/filterAlertsAction";
import { getAllAlertPolicyNames } from "../../redux/actions/getAllAlertPolicyNamesAction";
import { getAlertPolicySeverities } from "../../redux/actions/getAlertPolicySeveritiesAction";
import { getAlertResolutionTypes } from "../../redux/actions/getAlertResolutionTypesAction";
import {
  Button,
  Icon,
  TextField,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Popover,
} from "@material-ui/core";
import { fetchDMContentTabChildNodes } from "../../redux/actions/DataMapContentTabAction";
import { getCubejsApi, redirectToLogin } from "../common/Dashboard/cubejs-util";
import { useSelector } from "react-redux";
import ImageTooltip from "../common/HelpIconWithTooltip";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function createData(id, level, policy, description, risk, time) {
  return { id, level, policy, description, risk, time };
}

const headCells = [
  { id: "statusCode", numeric: false, disablePadding: true, label: "Status" },
  {
    id: "alertPolicyName",
    numeric: false,
    disablePadding: false,
    label: "Policy Name",
  },
  //{ id: 'riskScore', numeric: false, disablePadding: false, label: 'Risk' },
  { id: "severityCode", numeric: true, disablePadding: false, label: "Level" },
  { id: "time", numeric: false, disablePadding: false, label: "Time" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="enhanced-table-head">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ color: "#ffffff" }}
          >
            {headCell && headCell.orderId ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : headCell.label ? (
              headCell.label
            ) : (
              ""
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    display: "flex",
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    createNewFlowGroupClick,
    selectedApp,
    page,
    rowsPerPage,
    handleAlertsTableData,
    getDMFlowGroupCount,
    getAllDMAlerts,
    selectedNode,
    cubejsApi,
    alertsTableCount,
    loc,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [placement, setPlacement] = useState();
  const [severityFilter, setSeverityFilter] = useState([]);
  const [resolutionFilter, setResolutionFilter] = useState([]);
  const [policyFilter, setPolicyFilter] = useState([]);
  const [alertPolicySeverities, setAlertPolicySeverities] = useState([]);
  const [alertResolutionTypes, setAlertResolutionTypes] = useState([]);
  const [policyArr, setPolicyArr] = useState([]);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    async function getPolicyNames() {
      const response = await getAllAlertPolicyNames();
      if (response) {
        setPolicyArr(response.content);
      }
    }
    if (open) {
      getPolicyNames();
    }
  }, [open]);
  useEffect(() => {
    async function getSeverities() {
      const response = await getAlertPolicySeverities();
      if (response) {
        setAlertPolicySeverities(response.content);
      }
    }
    if (open) {
      getSeverities();
    }
  }, [open]);
  useEffect(() => {
    async function getResolutionTypes() {
      const response = await getAlertResolutionTypes();
      if (response) {
        setAlertResolutionTypes(response.content);
      }
    }
    if (open) {
      getResolutionTypes();
    }
  }, [open]);
  useEffect(() => {
    //disabling tool bar buttons if no data for table present
    if (numSelected > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [numSelected]);

  const handleSortingFilter = (event) => {
    setSortBy(event.target.value);
  };
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const clearFilterButtonClick = () => {
    setSeverityFilter([]);
    setResolutionFilter([]);
    setPolicyFilter([]);
    setSortBy("");
    getAllDMAlerts(rowsPerPage, page);
  };
  const handleCheckbox = (event, type) => {
    if (event.target.checked) {
      if (type === "severity") {
        if (!severityFilter.includes(event.target.value)) {
          setSeverityFilter([...severityFilter, event.target.value]);
        }
      } else if (type === "resolutionType") {
        if (!resolutionFilter.includes(event.target.value)) {
          setResolutionFilter([...resolutionFilter, event.target.value]);
        }
      } else if (type === "policy") {
        if (!policyFilter.includes(event.target.value)) {
          setPolicyFilter([...policyFilter, event.target.value]);
        }
      }
    } else {
      if (type === "severity") {
        let temp = severityFilter.filter((each) => each !== event.target.value);
        setSeverityFilter(temp);
      } else if (type === "resolutionType") {
        let temp = resolutionFilter.filter(
          (each) => each !== event.target.value
        );
        setResolutionFilter(temp);
      } else if (type === "policy") {
        let temp = policyFilter.filter((each) => each !== event.target.value);
        setPolicyFilter(temp);
      }
    }
  };
  useEffect(() => {
    async function getFilteredDMAlerts(rowsPerPage, page) {
      let nodes = [];
      const queryType = "multi";
      const nodeQuery = {
        dimensions: ["Nodes.nodeId"],
        timeDimensions: [],
        order: {},
        filters: [],
        segments: ["Nodes.childNodes"],
      };
      if (selectedNode) {
        nodeQuery.filters.push({
          member: "Nodes.parentId",
          operator: "equals",
          values: [selectedNode],
        });
      }
      const nodesResponse = await fetchDMContentTabChildNodes(
        nodeQuery,
        queryType
      );
      if (nodesResponse) {
        nodesResponse.length &&
          nodesResponse.forEach((each) => {
            nodes.push(each[`Nodes.nodeId`]);
          });
        nodes.push(selectedNode);
        const dimensionType = [
          "alertId",
          "alertPolicyName",
          "riskScore",
          "severityCode",
          "statusCode",
          "creationTime",
        ];
        let filtersList = [];
        if (selectedApp && selectedApp.id) {
          filtersList = [
            {
              dimensionName: "AlertsByApplication",
              dimensionType: "applicationId",
              operator: "equals",
              values: [selectedApp.id],
            },
          ];
        }
        if (nodes) {
          filtersList.push({
            dimensionName: "AlertsByApplication",
            dimensionType: "nodeId",
            operator: "equals",
            values: nodes,
          });
        }
        const queryString = cubeQueryGen(
          "AlertsByApplication",
          dimensionType,
          filtersList
        );
        queryString["limit"] = rowsPerPage;
        queryString["offset"] = page * rowsPerPage;
        const queryTypeString = "multi";

        if (severityFilter.length > 0) {
          queryString.filters.push({
            member: "AlertsByApplication.severityCode",
            operator: "contains",
            values: severityFilter,
          });
        }
        if (resolutionFilter.length > 0) {
          queryString.filters.push({
            member: "AlertsByApplication.statusCode",
            operator: "equals",
            values: resolutionFilter,
          });
        }
        if (policyFilter.length > 0) {
          queryString.filters.push({
            member: "AlertsByApplication.alertPolicyId",
            operator: "equals",
            values: policyFilter,
          });
        }

        let resultSet = await cubejsApi.load(queryString);
        console.log(resultSet.rawData());

        const response = await fetchDMListByQueryStr(
          queryString,
          queryTypeString
        );
        console.log(response);
        if (response && response.data && response.data.length) {
          handleAlertsTableData(response.data);
          getDMFlowGroupCount();
        }
        // else {
        //   initFGList = {
        //     apiSucc: true,
        //     data: [],
        //     apiProgress: null
        //   }
        //   handleAlertsTableData(initFGList);
        // }
      }
    }
    if (
      severityFilter.length > 0 ||
      resolutionFilter.length > 0 ||
      policyFilter.length > 0 ||
      sortBy !== "" ||
      (severityFilter.length > 0 &&
        resolutionFilter.length > 0 &&
        policyFilter.length > 0 &&
        sortBy !== "")
    ) {
      getFilteredDMAlerts(rowsPerPage, page);
    }
  }, [severityFilter, resolutionFilter, policyFilter, sortBy]);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span
          flexGrow={1}
          style={{ fontSize: "18px", fontWeight: "600", marginTop: "12px" }}
        >
          Alerts
        </span>
        &nbsp; &nbsp;
        <p style={{ minWidth: "45px" }}>Total :</p>
        <div className="dataMap-tray-count">{alertsTableCount}</div>
        &nbsp;
        <span style={{ marginTop: "6px" }}>
          <ImageTooltip description="Alerts generated due to violation of policies." />
        </span>
      </Typography>

      {loc !== "DATAMAP_ALERTS" && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClick("bottom")}
          style={{
            color: "#081981",
            backgroundColor: "transparent",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            width: "150px",
          }}
          startIcon={<ImportExportIcon />}
          className="min-margin"
        >
          Sort & Filter
        </Button>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <>
          <div className="clear-filter-container">
            <ClearFilter
              className="clear-filter"
              clearFilterButtonClick={clearFilterButtonClick}
              toggle={handleClick}
            />
          </div>
          <div className="popper-root">
            <Fade timeout={350}>
              <SortComponent
                sortBy={sortBy}
                handleSortingFilter={handleSortingFilter}
              />
            </Fade>
            <Fade timeout={350}>
              <FilterComponent
                severityFilter={severityFilter}
                resolutionFilter={resolutionFilter}
                severitiesArray={alertPolicySeverities}
                resolutionTypes={alertResolutionTypes}
                handleCheckbox={handleCheckbox}
              />
            </Fade>
            <Fade timeout={350}>
              <PolicyFilter
                policyFilter={policyFilter}
                policyArr={policyArr}
                handleCheckbox={handleCheckbox}
              />
            </Fade>
          </div>
        </>
      </Popover>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function DataMapAlerts(props) {
  const {
    selectedAlertIds,
    selectedApp,
    selectedAppByType,
    transactionsTabHandle,
  } = props;
  //console.log("selectedApp",selectedApp)
  //console.log("selectedAppByType",selectedAppByType)
  let initFGList = {
    apiSucc: null,
    data: [],
    apiProgress: true,
  };
  const cubejsApi = getCubejsApi();
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [pagesLoaded, setPagesLoaded] = useState([`${0}`]);
  const [alertsTableData, setAlertsTableData] = useState(initFGList);
  const [alertsTableCount, setAlertsTableCount] = useState(0);
  const [nodeList, setNodeList] = useState([]);
  const selectedNode = useSelector(
    (state) => state.selectedNode.selectedNodeId
  );

  useEffect(() => {
    getNodeList();
    return () => {
      setNodeList([]);
    };
  }, [selectedNode]);

  useEffect(() => {
    if (nodeList && nodeList.length > 0) {
      getAllDMAlerts(rowsPerPage, page);
      getDMFlowGroupCount();
    }
    return () => {};
  }, [nodeList]);

  async function getNodeList() {
    let nodes = [];
    const queryType = "multi";
    const nodeQuery = {
      dimensions: ["Nodes.nodeId"],
      timeDimensions: [],
      order: {},
      filters: [],
    };
    if (selectedNode) {
      nodeQuery.filters.push({
        member: "Nodes.parentId",
        operator: "equals",
        values: [selectedNode],
      });
    }
    const nodesResponse = await fetchDMContentTabChildNodes(
      nodeQuery,
      queryType
    );
    if (nodesResponse) {
      nodesResponse.length &&
        nodesResponse.forEach((each) => {
          nodes.push(each[`Nodes.nodeId`]);
        });
      if (selectedNode) {
        nodes.push(selectedNode);
      }
      setNodeList(nodes);
    }
  }

  async function getAllDMAlerts(rowsPerPage, page) {
    initFGList = {
      apiSucc: null,
      data: [],
      apiProgress: true,
    };
    setAlertsTableData(initFGList);

    // let nodes = [];
    // const queryType = "multi"
    // const nodeQuery = {
    //   "dimensions": [
    //     "Nodes.nodeId"
    //   ],
    //   "timeDimensions": [],
    //   "order": {},
    //   "filters": []
    // };
    // if(selectedNode){
    //   nodeQuery.filters.push({
    //     "member": "Nodes.parentId",
    //     "operator": "equals",
    //     "values": [
    //       selectedNode
    //     ]
    //   })
    // }
    //const nodesResponse = await fetchDMContentTabChildNodes(nodeQuery, queryType);
    if (nodeList && nodeList.length > 0) {
      // nodesResponse.results[0].data.map((each) => {
      //   nodes.push(each[`Nodes.nodeId`])
      // })
      // if(selectedNode){
      //   nodes.push(selectedNode)
      // }
      const dimensionType = [
        "alertId",
        "alertPolicyName",
        "riskScore",
        "severityCode",
        "statusCode",
        "creationTime",
      ];
      let filtersList = [];
      if (selectedApp && selectedApp.id) {
        filtersList = [
          {
            dimensionName: "AlertsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [selectedApp.id],
          },
        ];
      }
      if (
        selectedAppByType &&
        Object.keys(selectedAppByType).length > 0 &&
        selectedAppByType.type !== "alerts" &&
        selectedAppByType.type !== "alertPolicies"
      ) {
        if (selectedAppByType.type === "dataFlowGroups") {
          filtersList.push({
            dimensionName: "AlertsByApplication",
            dimensionType: "dataFlowGroupId",
            operator: "equals",
            values: [selectedAppByType.id],
          });
        } else {
          filtersList.push({
            dimensionName: "AlertsByApplication",
            dimensionType: "dataFlowId",
            operator: "equals",
            values: [selectedAppByType.id],
          });
        }
      } else {
        filtersList.push({
          dimensionName: "AlertsByApplication",
          dimensionType: "nodeId",
          operator: "equals",
          values: nodeList,
        });
      }
      const queryString = cubeQueryGen(
        "AlertsByApplication",
        dimensionType,
        filtersList
      );
      queryString["limit"] = rowsPerPage;
      queryString["offset"] = page * rowsPerPage;
      const queryTypeString = "multi";
      let response;
      try {
        const resultSet = await cubejsApi.load(queryString);
        response = resultSet.rawData();
      } catch (error) {
        redirectToLogin(error);
      }
      if (response && response.length) {
        handleAlertsTableData(response);
      } else {
        initFGList = {
          apiSucc: true,
          data: [],
          apiProgress: null,
        };
        setAlertsTableData(initFGList);
      }
    }
  }

  async function getDMFlowGroupCount() {
    setAlertsTableCount(0);

    // let nodes = [];
    // const queryType = "multi"
    // const nodeQuery = {
    //   "dimensions": [
    //     "Nodes.nodeId"
    //   ],
    //   "timeDimensions": [],
    //   "order": {},
    //   "filters": []
    // };
    // if(selectedNode){
    //   nodeQuery.filters.push({
    //     "member": "Nodes.parentId",
    //     "operator": "equals",
    //     "values": [
    //       selectedNode
    //     ]
    //   })
    // }
    // const nodesResponse = await fetchDMContentTabChildNodes(nodeQuery, queryType);
    if (nodeList && nodeList.length > 0) {
      // nodesResponse.results[0].data.map((each) => {
      //   nodes.push(each[`Nodes.nodeId`])
      // })
      // if(selectedNode){
      //   nodes.push(selectedNode)
      // }
      const dimensionType = ["alertsCount"];
      let filtersList = [];
      if (selectedApp && selectedApp.id) {
        filtersList = [
          {
            dimensionName: "AlertsByApplication",
            dimensionType: "applicationId",
            operator: "equals",
            values: [selectedApp.id],
          },
        ];
      }

      if (
        selectedAppByType &&
        Object.keys(selectedAppByType).length > 0 &&
        selectedAppByType.type !== "alerts" &&
        selectedAppByType.type !== "alertPolicies"
      ) {
        if (selectedAppByType.type === "dataFlowGroups") {
          filtersList.push({
            dimensionName: "AlertsByApplication",
            dimensionType: "dataFlowGroupId",
            operator: "equals",
            values: [selectedAppByType.id],
          });
        } else {
          filtersList.push({
            dimensionName: "AlertsByApplication",
            dimensionType: "dataFlowId",
            operator: "equals",
            values: [selectedAppByType.id],
          });
        }
      } else {
        if (nodeList) {
          filtersList.push({
            dimensionName: "AlertsByApplication",
            dimensionType: "nodeId",
            operator: "equals",
            values: nodeList,
          });
        }
      }
      const queryString = cubeQueryGenCount(
        "AlertsByApplication",
        dimensionType,
        filtersList
      );

      let respCount;
      try {
        const resultSet = await cubejsApi.load(queryString);
        respCount = resultSet.rawData();
      } catch (error) {
        redirectToLogin(error);
      }

      if (
        respCount &&
        respCount.length > 0 &&
        respCount[0]["AlertsByApplication.alertsCount"]
      ) {
        setAlertsTableCount(respCount[0]["AlertsByApplication.alertsCount"]);
      } else {
        setAlertsTableCount(0);
      }
    }
  }
  const selectTransactionHandle = (dataFlowItem) => {
    transactionsTabHandle(dataFlowItem);
  };

  const handleChangePage = (event, newPage) => {
    if (pagesLoaded.length > 0) {
      setPage(newPage);
      getAllDMAlerts(rowsPerPage, newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getAllDMAlerts(parseInt(event.target.value, 10), 0);
  };
  const handleTableData = (data) => {
    setAlertsTableData(data);
  };
  const handleAlertsTableData = (data) => {
    let filteredData = data;
    if (selectedAlertIds && selectedAlertIds.length) {
      filteredData = filteredData.filter((filterItem) => {
        const findAlertId = selectedAlertIds.find(
          (alertId) => alertId === filterItem.alertPolicyId
        );
        if (findAlertId) {
          return filterItem;
        }
      });
    }
    const newD = [];
    filteredData.map((item) => {
      newD.push(
        createData(
          item["AlertsByApplication.alertId"],
          item["AlertsByApplication.statusCode"],
          item["AlertsByApplication.alertPolicyName"],
          item["AlertsByApplication.riskScore"],
          item["AlertsByApplication.severityCode"],
          getTime(Number(item["AlertsByApplication.creationTime"]))
        )
      );
    });
    initFGList = {
      apiSucc: true,
      data: newD,
      apiProgress: null,
    };
    setAlertsTableData(initFGList);
  };
  return (
    <div className="transcation-container">
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedApp={selectedApp}
          alertsTableCount={alertsTableCount}
          selectedNode={selectedNode}
          page={page}
          rowsPerPage={rowsPerPage}
          handleAlertsTableData={handleAlertsTableData}
          initFGList={initFGList}
          getDMFlowGroupCount={getDMFlowGroupCount}
          getAllDMAlerts={getAllDMAlerts}
          cubejsApi={cubejsApi}
          loc="DATAMAP_ALERTS"
        />
        <TableContainer className="inspect-height">
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={alertsTableData.data.length}
            />
            {alertsTableData.apiProgress ? (
              <TableBody>
                <LoaderComponent />
              </TableBody>
            ) : (
              <React.Fragment>
                {!alertsTableData.apiProgress && alertsTableData.data.length ? (
                  <TableBody>
                    {stableSort(
                      alertsTableData.data,
                      getComparator(order, orderBy)
                    ).map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          <TableCell component="th" id={labelId} scope="row">
                            <LevelComponent
                              level={row.risk.toUpperCase()}
                              status={row.level}
                              Id={labelId}
                            />
                          </TableCell>
                          <TableCell align="left">{row.policy}</TableCell>
                          {/* <TableCell align="left">
                              <LevelComponent level={row.risk.toUpperCase()} status={""} risk={row.description === 0 ? '0' : Math.round(row.description)} Id={labelId} />
                            </TableCell>  -- add if needed in the future */}
                          <TableCell align="left">
                            <LevelComponent
                              level={row.risk.toUpperCase()}
                              status={""}
                              risk={""}
                              Id={labelId}
                            />
                          </TableCell>
                          <TableCell align="left">{row.time}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        No Data Available
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>
        {Number(alertsTableCount) > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 20, 50, 100, 200]}
            component="div"
            count={alertsTableCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
