import {
  SETPASSWORD_SUBMIT,
  SETPASSWORD_SUCCESS,
  SETPASSWORD_FAIL,
} from "../constants/setPasswordActionTypes";

const initialState = {
  setPasswordSuccess: false,
  setPasswordSubmitted: false,
  setPasswordErrorMessage: "",
};

export const setPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETPASSWORD_SUCCESS:
      return {
        ...state,
        setPasswordSuccess: true,
      };
    case SETPASSWORD_FAIL:
      return {
        ...state,
        setPasswordSuccess: false,
        setPasswordErrorMessage: action.payload,
      };

    case SETPASSWORD_SUBMIT:
      return {
        ...state,
        setPasswordSubmitted: true,
      };
    case "RESET_SETPASSWORD_ERROR_MESSAGE": {
      return {
        ...state,
        setPasswordErrorMessage: "",
      };
    }
    default:
      return state;
  }
};
