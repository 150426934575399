export async function getSpanDbStatements(
  selectedNode,
  cubejsApi,
  redirectToLogin,
  setSpanDBStatements,
  getNodesResponse
) {
  let responseObj = {
    apiSuccess: false,
    data: [],
  };
  let nodes = [];
  const queryType = "multi";
  const nodeQuery = {
    dimensions: ["Nodes.nodeId"],
    timeDimensions: [],
    order: {},
    filters: [],
  };
  if (selectedNode) {
    nodeQuery.filters.push({
      member: "Nodes.parentId",
      operator: "equals",
      values: [selectedNode],
    });
  }
  const nodesResponse = await getNodesResponse(nodeQuery, queryType);
  if (nodesResponse) {
    //setAllFieldsData(nodesResponse.results[0].data)
    nodes.push(selectedNode);
    nodesResponse.length &&
      nodesResponse.map((each) => {
        nodes.push(each[`Nodes.nodeId`]);
      });
    const queryString = {
      dimensions: ["SpanStatements.sqlStatement"],
      filters: [
        {
          member: "SpanStatements.nodeId",
          operator: "equals",
          values: nodes,
        },
      ],
    };
    let response;
    try {
      const resultSet = await cubejsApi.load(queryString);
      response = resultSet.rawData();
    } catch (error) {
      redirectToLogin(error);
    }
    if (response && response.length) {
      responseObj.apiSuccess = true;
      responseObj.data = response;
    } else {
      responseObj.apiSuccess = true;
    }
    setSpanDBStatements(responseObj);
  } else {
    responseObj.apiSuccess = true;
    setSpanDBStatements(responseObj);
  }
}
