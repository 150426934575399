import React, { useRef, useEffect, useState } from "react";
import "../../../assets/scss/admin-styles/custom/initialSetUp.scss";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { sanitizeDOMString } from "../../common/utilities";
import { DOWNLOAD_AGENT } from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import page_loader from "../../../assets/vizr_images/page_loader.gif";
import SnackBar2 from "../../../components/shared/SnackBar2";

function delegate(el, evt, sel, handler) {
  el.addEventListener(evt, function (event) {
    var t = event.target;
    while (t && t !== this) {
      if (t.matches(sel)) {
        handler.call(t, event);
      }
      t = t.parentNode;
    }
  });
}

const PlatformInstructions = ({
  currentInstructions,
  curAgentId,
  setcurrentInstructions,
  insUnmasked,
  loc
}) => {
  const [state, setState] = useState({
    isDownloading: "",
    downloadingSuccess: "",
    downloadingErrormessage: "",
    downloadSuccessMessage: "",
  });
  let htmlContainer = useRef(null);
  console.log(currentInstructions);
  const instrucrionsClickHandler = (event) => {
    console.log(event.target);
  };
  useEffect(() => {
    if (htmlContainer) {
      console.log(htmlContainer);
      delegate(
        htmlContainer.current,
        "click",
        ".agent-download",
        function (event) {
          if (event.target.dataset.filename === "xxxxxxxxxx") {
            setState({
              ...state,
              downloadingErrormessage:
                "Please unmask the instructions to download the agent",
            });
          } else {
            console.log("Downloading file " + event.target.dataset.filename);
            setState({ ...state, isDownloading: true });
            const downloadAgent = async () => {
              const res = await getService({
                method: "GET",
                url: DOWNLOAD_AGENT(event.target.dataset.filename),
                headers: getAxiosHeaders(true),
              });
              console.log(res);
              if (res.status === 200) {
                setState({
                  ...state,
                  isDownloading: false,
                  downloadingSuccess: true,
                });
                let data = res.data;
                let downloadfileName = event.target.dataset.filename;
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let blob = new Blob([data], {
                    type: "application/java-archive",
                  }),
                  url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = downloadfileName;
                a.click();
                window.URL.revokeObjectURL(url);
              } else {
                setState({
                  ...state,
                  isDownloading: false,
                  downloadingSuccess: false,
                  downloadingErrormessage: "Download Failed please try Again",
                });
                console.log("download failed");
              }
            };

            downloadAgent();
          }
        }
      );
    }
  }, []);

  const renderInstructionContentStyles = () => {
    let className = "platformInstructions_content";
  
    if (loc === "dataSource" && insUnmasked) {
      className += " dataSource platformInstructions_content_unmasked";
    } else if (loc === "dataSource") {
      className += " dataSource";
    } else if (insUnmasked) {
      className += " platformInstructions_content_unmasked";
    }
  
    return className;
  };
  
  return (
    <div
      className={
        loc === "dataSource"
          ? "Devops_platfrom_Instructions dataSource"
          : "Devops_platfrom_Instructions"
      }
      onClick={instrucrionsClickHandler}
    >
      <SnackBar2
        failure={state.downloadingErrormessage && !insUnmasked ? true : false}
        errorMessage={state.downloadingErrormessage}
        setState={setState}
        state={state}
      ></SnackBar2>
      {state.isDownloading && !state.downloadingSuccess ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={page_loader} alt="page_loader"></img>
          <h3 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Please wait while we download the Agent
          </h3>
        </div>
      ) : state.downloadingSuccess ? (
        <SnackBar2
          success={true}
          successMessage={"Agent downloaded Successfully"}
        ></SnackBar2>
      ) : (
        ""
      )}

      <div className="flex">
        {currentInstructions.length > 0 &&
          currentInstructions.map((el, index) => (
            <div className="grid">
              {loc === "dataSource" ? null : (
                <div className="flex1">
                  <div className="platformInstructions_numbering">
                    {index + 1}
                  </div>
                  <div className="platformInstructions_line"></div>
                </div>
              )}
              <div
                className={
                  loc === "dataSource"
                    ? "initial-setup-data dataSourceloc"
                    : "initial-setup-data"
                }
              >
                {loc === "dataSource" ? null : (
                  <div>
                    <h2 className="platformInstructions_stepname">Step Name</h2>
                  </div>
                )}
                {/*   <div className="platformInstructions_heading">
                     <p className="platformInstructions_heading">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Laborum nulla quisquam, impedit esse.
                  </p>
                </div>*/}

                <div className={renderInstructionContentStyles()}>
                  {loc === "dataSource" ? null : (
                    <div className="platformInstructions_content_heading">
                      <div> Code Snippet</div>
                      {/* <FileCopyIcon style={{ cursor: "pointer" }}></FileCopyIcon> */}
                    </div>
                  )}
                  <div className="platformInstructions_content_points">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeDOMString(el),
                      }}
                      style={{
                        wordWrap: "break-word",
                        width: "96%",
                        height: "100%",
                      }}
                      ref={htmlContainer}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PlatformInstructions;
