import { NODE_POSITIONS } from "../constants/constants";

const initialState = {
  nodePositions: [],
};

export const nodePositionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NODE_POSITIONS:
      localStorage.setItem("nodePosition", JSON.stringify(action.data));
      return {
        ...state,
        nodePositions: action.data,
      };
    default:
      return state;
  }
};
