import { POLICY_DELETE_SUCCEEDED,POLICY_DELETE_FAILED,CLOSE_SNACKBAR } from '../constants/constants'

const initialState = {
    deleted : false,
    successMessage: '',
    unsuccessful : ''
  };

  export const deletePolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case POLICY_DELETE_SUCCEEDED: {
            console.log("POLICY_DELETE_SUCCEEDED");
            return {
              ...state,
              successMessage: 'Policy(s) Deleted!!',
              deleted: true,
            };
          }
          case POLICY_DELETE_FAILED: {
            console.log("POLICY_DELETE_FAILED");
            return {
              ...state,
              deleted: false,
              unsuccessful: action.payload,
            };
          }
          case CLOSE_SNACKBAR: {
            console.log("CLOSE_SNACKBAR");
            return {
              ...state,
              successMessage: '',
              deleted: false,
            };
          }
          default:
            return state;
    }
  };