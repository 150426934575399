import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "material-ui-core";

const useStyles = makeStyles({
  notificationHeader: {
    paddingLeft: "10px",
    fontWeight: "600",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  checkboxLabel: {
    marginTop: "10px",
  },
});

const AlertEmail = ({emailNotification, setEmailNotification, editable}) => {  
  const classes = useStyles();
  const handleCheckboxChange = (event) => {
    setEmailNotification(event.target.checked);
  };
  const handleDisable = () => {
    return !window.location.pathname.includes("createPolicy") && !editable
  }
  return (
    <div>
      <div className="trigger-container">
        <div>
          <p
            className={classes.notificationHeader}
          >
            Email Notifications:
          </p>
          <div
            className={classes.checkboxContainer}
          >
            <Checkbox
              className="checkbox-root"
              disableRipple
              color="default"
              onChange={handleCheckboxChange}
              checkedIcon={<span className="checked-icon" />}
              icon={<span className="checkbox-icon" />}
              inputProps={{
                "aria-label": "decorative checkbox",
              }}
              checked={emailNotification}
              disabled={handleDisable()}
            />
            <label className={classes.checkboxLabel}>
              I want Email Notifications for Alerts.
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertEmail;
