import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Loader from "../../assets/vizr_images/page_loader.gif";
export default function LoaderComponent({ classname }) {
  return (
    <div
      className={
        classname ? "durvstar-loader-box lessZindex" : "durvstar-loader-box"
      }
    >
      <div className="durvstar-loader-gif">
        <img src={Loader} alt="_Loader" />
      </div>
    </div>
  );
}
