import {
  SELECTED_ITEMS,
  USERS_DELETE,
  ROLES_DELETE,
  RESET_DELETESTATE,
  DATABASE_USERSUPDATE,
} from "../constants/constants";

const initialState = {
  selectedItems: [],
  isUsersDeleted: false,
  isRolesDeleted: false,
  isDatabaseUsersBatchEdit: false,
  dataLoaded:false
};

export const selectedItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: action.selectedArray,
        isUsersDeleted: false,
        isRolesDeleted: false,
      };
    case USERS_DELETE:
      return {
        ...state,
        isUsersDeleted: true,
      };
    case ROLES_DELETE:
      return {
        ...state,
        isRolesDeleted: true,
      };
    case DATABASE_USERSUPDATE: {
      return {
        ...state,
        isDatabaseUsersBatchEdit: !state.isDatabaseUsersBatchEdit,
      };
    }
    case RESET_DELETESTATE:
      return {
        ...state,
        isUsersDeleted: false,
        isRolesDeleted: false,
        isDatabaseUsersBatchEdit: false,
      };
    case "SELECTEDITEMS_RESET": {
      return {
        ...state,
        selectedItems: [],
      };
    }
    case "SET_DATA_LOADED": {
      return {
        ...state,
        dataLoaded: action.payload,
      };
    }
    default:
      return state;
  }
};
