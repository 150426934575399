import { EDIT_SENSITIVITY_SUCCEEDED,CLOSE_SNACKBAR,EDIT_SENSITIVITY_FAILED } from '../constants/constants';

const initialState = {
    sensitivityFieldsUpdated : false,
    successMessage: '',
    unsuccessful : ''
  };

  export const editSensitivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_SENSITIVITY_SUCCEEDED: {
            return {
              ...state,
              successMessage: 'Sensitivity Fields Updated',
              sensitivityFieldsUpdated: true,
            };
          }
          case EDIT_SENSITIVITY_FAILED: {
            console.log("EDIT_SENSITIVITY_FAILED");
            return {
              ...state,
              sensitivityFieldsUpdated: false,
              unsuccessful: action.payload,
            };
          }
          case CLOSE_SNACKBAR: {
            console.log("CLOSE_SNACKBAR");
            return {
              ...state,
              successMessage: '',
              sensitivityFieldsUpdated: false,
            };
          }
          default:
            return state;
    }
  };
