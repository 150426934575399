import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { Change_ExpiryDate } from "../../../redux/actions/userAccessrightsAction";
import {
  DateRange,
  DateRangePicker,
  Calendar,
  DefinedRange,
  createStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./customCalender.scss";
import { Popover, Input, InputBase, Grid } from "@material-ui/core";
import { NewPolicyButtonForPolicyCreateEdit } from "../../common/buttons";

import {
  addDays,
  subDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  subMinutes,
  subHours,
  format,
  set,
  isSameMinute,
} from "date-fns";
import moment from "moment";
import TimepickerInput from "./TimepickerInput";
import {
  AddButtonForAlertTable,
  ColorButtonForClearFilter,
} from "../../common/buttons";

import { createStaticRange, dateRangePickerStaticList } from "../utilities";

const useStyles = makeStyles((theme) => ({
  appBg: {
    backgroundColor: "#081981",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    color: "#081981",
    "&:hover": {
      backgroundColor: "white",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#081981",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  year: {
    paddingLeft: `calc(1em + ${theme.spacing(0.5)}px)`,
    width: "342px !important",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
const PATCH_MONITORING = "Patch Monitoring";
const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
  past0Minutes: subMinutes(new Date(), 0),
  past15Minutes: subMinutes(new Date(), 15),
  past5Minutes: subMinutes(new Date(), 5),
  past30Minutes: subMinutes(new Date(), 30),
  past0Hour: subHours(new Date(), 0),
  past1Hour: subHours(new Date(), 1),
  past3Hours: subHours(new Date(), 3),
  past1Day: subDays(new Date(), 1),
  past3Days: subDays(new Date(), 3),
  currentDate: new Date(),
  after10Days: addDays(new Date(), 10),
  after20Days: addDays(new Date(), 20),
  after30Days: addDays(new Date(), 30),
  after6Months: addDays(new Date(), 90),
  after1Year: addDays(new Date(), {
    years: 1,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  }),
  after2Years: addDays(new Date(), {
    years: 2,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  }),
};

const dateRangePickerStaticList2 = () => {
  const customDateObjects = [
    {
      label: "10 days",
      range: () => ({
        startDate: 10,
        endDate: null,
      }),
    },
    {
      label: "20 days",
      range: () => ({
        startDate: 20,
        endDate: null,
      }),
    },
    {
      label: "30 days",
      range: () => ({
        startDate: 30,
        endDate: null,
      }),
    },
    {
      label: "6 Months",
      range: () => ({
        startDate: 180,
        endDate: null,
      }),
    },
    {
      label: "1 Year",
      range: () => ({
        startDate: 365,
        endDate: null,
      }),
    },
    {
      label: "2 Years",
      range: () => ({
        startDate: 730,
        endDate: null,
      }),
    },
  ];
  return customDateObjects;
};

const calculateTimimg = (d) => {
  let months = 0,
    years = 0,
    days = 0;
  while (d) {
    if (d >= 365) {
      years++;
      d -= 365;
    } else if (d >= 30) {
      months++;
      d -= 30;
    } else {
      days++;
      d--;
    }
  }

  let retuVal = "";
  if (years > 0) {
    retuVal += years + "Y ";
  }
  if (months > 0) {
    retuVal += months + "M ";
  }
  if (days > 0) {
    retuVal += days + "D ";
  }
  return retuVal;
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameMinute(range.startDate, definedRange.startDate) &&
      isSameMinute(range.endDate, definedRange.endDate)
    );
  },
};


export default function AppDateTimeRage({
  loc,
  dateAnchorEl,
  setAnchorElHandler1,
  setAnchorElHandler2,
  closeCalender,
  onTimeChange,
}) {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [toTimeValue, setToTimeValue] = useState("");
  const [fromTimeValue, setFromTimeValue] = useState("");
  const [fromDateValue, setFromDateValue] = useState(new Date());
  const [toDateValue, setToDateValue] = useState(new Date());
  const dispatch = useDispatch();
  // get which dashboard is it to have custom year range for Patch monitoring
  const MDRDropdownValue = useSelector(
    (state) => state.IncidenetReducer.MDRDropdownValue
  );
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  /**
   * This function return date filter for one year
   * @returns start and end date range
   */
  const getPastYearDateRange = () => {
    const pastYear = new Date();
    pastYear.setFullYear(pastYear.getFullYear() - 1);
    return {startDate : pastYear, endDate : defineds.endOfToday};
  }

  const [selectionRangeDates, setSelectionRangeDates] =
    useState(selectionRange);

  const [dateRangeDiff, setDateRangeDiff] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const setAppDateTimeRange = () => {
    let startDate = "";
    let endDate = "";
    // if patch monitoring dashboard we will default the date range to 1year for all other we load from local storage
    if (MDRDropdownValue === PATCH_MONITORING) {
      const patchMonitoringDefault = getPastYearDateRange();
      startDate = patchMonitoringDefault.startDate;
      endDate = patchMonitoringDefault.endDate;
    } else {
      if (localStorage.getItem("appDateTimeRange")) {
        const appDateTimeRange = JSON.parse(
          localStorage.getItem("appDateTimeRange")
        );
        startDate = new Date(appDateTimeRange.startDate);
        endDate = new Date(appDateTimeRange.endDate);
      } else {
        startDate = defineds.startOfLastSevenDay;
        endDate = defineds.endOfToday;
      }
    }
    appDatelocalStorageUpdate(startDate, endDate);
  };

  useEffect(() => {
    setAppDateTimeRange();
  }, [MDRDropdownValue]);

  const dateRangeList = [
    {
      id: 1,
      dateRange: "2020-09-17 02:00:00 to 2020-09-18 01:59:59",
    },
    {
      id: 1,
      dateRange: "2020-09-17 02:56:00 to 2020-09-18 04:59:59",
    },
  ];

  const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRange(dateRangePickerStaticList(global)),
  ];

  const staticRanges2 = [...createStaticRanges(dateRangePickerStaticList2())];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const accessDetailsDateClose = () => {
    // setAnchorElHandler1(null);
    // dispatch({ type: "AccessDetailsDatePopUp", payload: null });
    handleClose();
  };

  const handleDateRangeDiff = (endDate, startDate) => {
    const days = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24));
    const hours = parseInt(
      (Math.abs(endDate - startDate) / (1000 * 60 * 60)) % 24
    );
    const minutes = parseInt(
      (Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60)) % 60
    );
    const seconds = parseInt(
      (Math.abs(endDate.getTime() - startDate.getTime()) / 1000) % 60
    );
    let setTim = "";
    if (days > 0) {
      setTim = calculateTimimg(days + 1);
    } else {
      setTim = "";
      if (hours > 0) {
        setTim = hours + "H";
      } else if (minutes > 0) {
        setTim = minutes + "Mins";
      }
    }
    setDateRangeDiff(setTim);
  };
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setFromDateValue(selection.startDate);
    setToDateValue(selection.endDate);
    setState([selection]);
    dispatch({
      type: "UPDATE_EXPIRY_DATES",
      payload: {
        expiryFromDate: selection.startDate,
        expiryToDate: selection.endDate,
      },
    });
  };
  const selectedItems = useSelector(
    (state) => state.selectedItemsData.selectedItems
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleSelect2 = (date, calenderStaticOptions) => {
    if (calenderStaticOptions) {
      // let dates = new Date(date.startDate ? date.startDate : date);
      setDate(date);
      closeCalender();
      onTimeChange(calenderStaticOptions, date.startDate);
      // dispatch({ type: "RESET_BATCHEDIT_CLICK" });
    } else {
      let dates = new Date(date.startDate ? date.startDate : date);
      setDate(date);
      onTimeChange(calenderStaticOptions, dates.getTime());
      closeCalender();
    }
  };
  const handleSelect = (ranges, staticSelection) => {
    const findKey = Object.keys(ranges);
    if (findKey.length && findKey[0]) {
      const selectionKey = findKey[0];
      let startDate = ranges[selectionKey].startDate;
      let endDate = ranges[selectionKey].endDate;
      if (
        new Date(endDate).getHours() == 0 &&
        new Date(endDate).getMinutes() == 0 &&
        new Date(endDate).getSeconds() == 0
      ) {
        startDate = set(new Date(startDate), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        endDate = set(new Date(endDate), {
          hours: 23,
          minutes: 59,
          seconds: 59,
        });
      }
      appDatelocalStorageUpdate(startDate, endDate);
      if (staticSelection) {
        handleClose();
      }
    }
  };
  
  const appDatelocalStorageUpdate = (startDate, endDate) => {
    const appDateTimeRange = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };
    if (MDRDropdownValue !== PATCH_MONITORING) {
      localStorage.setItem("appDateTimeRange", JSON.stringify(appDateTimeRange));
    }
    dispatch({
      type: "SELECTED_DATE_TIME_RANGE",
      payload: JSON.stringify(appDateTimeRange),
    });
    setFromDateValue(startDate, endDate);
    setToDateValue(endDate);
    fromToTimeUpdate(startDate, endDate);
    handleDateRangeDiff(endDate, startDate);
    setSelectionRangeDates(appDateTimeRange);
    // setSelectionRangeDates2(appDateTimeRange);
  };
  const fromToTimeUpdate = (startDateUTC, endDateUTC) => {
    let startDate = new Date(startDateUTC);
    let endDate = new Date(endDateUTC);
    setFromTimeValue(moment(startDateUTC).format("HH:mm:ss"));
    setToTimeValue(moment(endDateUTC).format("HH:mm:ss"));
    // const fromTimeVal = `${("0" + startDate.getHours()).slice(-2)}:${(
    //   "0" + startDate.getMinutes()
    // ).slice(-2)}:${("0" + startDate.getSeconds()).slice(-2)}`;
    // const toTimeVal = `${("0" + endDate.getHours()).slice(-2)}:${(
    //   "0" + endDate.getMinutes()
    // ).slice(-2)}:${("0" + endDate.getSeconds()).slice(-2)}`;
    // setFromTimeValue(fromTimeVal);
    // setToTimeValue(toTimeVal);
  };

  const fromTimeChange = (name) => (event) => {
    if (event.target.value) {
      setFromTimeValue(event.target.value);
      const tarValSpl = event.target.value.split(":");
      if (
        tarValSpl.length === 3 &&
        !isNaN(tarValSpl[0]) &&
        !isNaN(tarValSpl[1]) &&
        !isNaN(tarValSpl[2])
      ) {
        fromDateValue.setHours(tarValSpl[0]);
        fromDateValue.setMinutes(tarValSpl[1]);
        fromDateValue.setSeconds(tarValSpl[2]);
        setFromDateValue(fromDateValue);
        appDatelocalStorageUpdate(fromDateValue, toDateValue);
      }
    }
  };

  const toTimeChange = (name) => (event) => {
    if (event.target.value) {
      setToTimeValue(event.target.value);
      const tarValSpl = event.target.value.split(":");
      if (
        tarValSpl.length === 3 &&
        !isNaN(tarValSpl[0]) &&
        !isNaN(tarValSpl[1]) &&
        !isNaN(tarValSpl[2])
      ) {
        toDateValue.setHours(tarValSpl[0]);
        toDateValue.setMinutes(tarValSpl[1]);
        toDateValue.setSeconds(tarValSpl[2]);
        setToDateValue(toDateValue);
        appDatelocalStorageUpdate(fromDateValue, toDateValue);
      }
    }
  };
  const convertDateFun = () => {
    const dateRangeVal = `${format(
      fromDateValue,
      "yyyy-MM-dd HH:mm:ss"
    ).toString()} to ${format(toDateValue, "yyyy-MM-dd HH:mm:ss").toString()}`;
    return dateRangeVal;
  };
  const AccesDetailsFilter_Anchorel = useSelector(
    (state) => state.accessDetails.anchorel
  );

  return (
    <>
      {loc === "useraccessRights_content" ? (
        <div
          className="dateRange-time-picker"
          style={{ height: "520px", zIndex: "1" }}
        >
          <div className="dateRanger-header">
            <span className="quick-range">Expire after</span>

            <span className="select-range" style={{ marginLeft: "10rem" }}>
              Select Expiry Date
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <DefinedRange
              onChange={(item) => {
                setState([item.selection]);
                handleSelect2(item.selection, "calenderStaticOptions");
              }}
              ranges={state}
              staticRanges={staticRanges2}
              inputRanges={[]}
            />
            <div className="calenderContainer">
              <Calendar
                onChange={handleSelect2}
                minDate={new Date()}
                date={new Date()}
                className="customDate"
              />
            </div>
          </div>
          <NewPolicyButtonForPolicyCreateEdit
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              dispatch({ type: "RESET_BATCHEDIT_CLICK" });
              dispatch({ type: "CLOSECALENDER", payload: "" });
              closeCalender();
            }}
            className="min-margin"
            style={{ float: "right", marginTop: "1rem", marginRight: "23px" }}
          >
            CLOSE
          </NewPolicyButtonForPolicyCreateEdit>
        </div>
      ) : loc === "AccessDetailsPopup" ? (
        <div className={classes.search}>
          <div className="dateRange-search-box">
            <span className="selected-date-value">{dateRangeDiff}</span>
            <InputBase
              placeholder="1 YR"
              classes={{
                root: classes.inputRoot,
                input: `${classes.inputInput} ${classes.year}`,
              }}
              inputProps={{ "aria-label": "search" }}
              onClick={handleClick}
              value={convertDateFun()}
            />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={AccesDetailsFilter_Anchorel}
            onClose={accessDetailsDateClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            className="dateRange-time-popover"
          >
            <div className="dateRange-time-picker">
              <div className="dateRanger-header">
                <span className="quick-range">Quick Ranges</span>

                <span className="select-range">Select a Range</span>
              </div>
              <DateRangePicker
                onChange={handleSelect}
                ranges={[selectionRangeDates]}
                staticRanges={staticRanges}
                maxDate={new Date()}
                inputRanges={[]}
                retainEndDateOnFirstSelection={true}
              />
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Grid container spacing={2}></Grid>
                </Grid>
                <Grid item xs={7}>
                  <div className="time-range-container">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="time-range-picker">
                          <Grid container spacing={1}>
                            <Grid item xs={8} style={{ marginLeft: "5rem" }}>
                              <ColorButtonForClearFilter
                                variant="contained"
                                color="primary"
                                size="medium"
                                className="min-margin"
                                onClick={accessDetailsDateClose}
                              >
                                Cancel
                              </ColorButtonForClearFilter>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="time-range-picker">
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <AddButtonForAlertTable
                                variant="contained"
                                color="primary"
                                size="medium"
                                className="min-margin"
                                onClick={accessDetailsDateClose}
                              >
                                Save
                              </AddButtonForAlertTable>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Grid container spacing={2}></Grid>
                </Grid>
                <Grid item xs={7}></Grid>
              </Grid>
            </div>
          </Popover>
        </div>
      ) : loc === "database-logs" ? (
        <>
          <div className="database-access-picker">
            <div className="header-text">Select a Date Range</div>
            <DateRange
              moveRangeOnFirstSelection={false}
              onChange={handleOnChange}
              ranges={state}
              showDateDisplay={false}
            />
          </div>
          <div className="quick-range-heading">Time Range</div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Grid container spacing={2}></Grid>
              </Grid>
              <Grid item xs={7}>
                <div className="time-range-container">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="time-range-picker">
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <span className="range-text">From</span>
                          </Grid>
                          <Grid item xs={8}>
                            <div className="timepicker-div">
                              <Input
                                value={fromTimeValue}
                                inputComponent={TimepickerInput}
                                onChange={fromTimeChange("textmask")}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="time-range-picker">
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <span className="range-text">To</span>
                          </Grid>
                          <Grid item xs={8}>
                            <div className="timepicker-div">
                              <Input
                                value={toTimeValue}
                                inputComponent={TimepickerInput}
                                onChange={toTimeChange("textmask")}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <div className={classes.search}>
          <div className="dateRange-search-box">
            <span className="selected-date-value">{dateRangeDiff}</span>
            <InputBase
              placeholder="1 YR"
              classes={{
                root: classes.inputRoot,
                input: `${classes.inputInput} ${classes.year}`,
              }}
              inputProps={{ "aria-label": "search" }}
              onClick={handleClick}
              value={convertDateFun()}
            />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            className="dateRange-time-popover"
          >
            <div className="dateRange-time-picker">
              <div className="dateRanger-header">
                <span className="quick-range">Quick Ranges</span>

                <span className="select-range">Select a Range </span>
              </div>
              <div style={{ display: "flex" }}>
                <DefinedRange
                  onChange={(ranges) => handleSelect(ranges, true)}
                  ranges={[selectionRangeDates]}
                  staticRanges={staticRanges}
                  inputRanges={[]}
                />
                <DateRange
                  onChange={(ranges) => handleSelect(ranges)}
                  ranges={[selectionRangeDates]}
                  staticRanges={staticRanges}
                  // maxDate={new Date()}
                  inputRanges={[]}
                  retainEndDateOnFirstSelection={true}
                />
              </div>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Grid container spacing={2}></Grid>
                </Grid>
                <Grid item xs={7}>
                  <div className="time-range-container">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="time-range-picker">
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <span className="range-text">From</span>
                            </Grid>
                            <Grid item xs={8}>
                              <div className="timepicker-div">
                                <Input
                                  value={fromTimeValue}
                                  inputComponent={TimepickerInput}
                                  onChange={fromTimeChange("textmask")}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="time-range-picker">
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <span className="range-text">To</span>
                            </Grid>
                            <Grid item xs={8}>
                              <div className="timepicker-div">
                                <Input
                                  value={toTimeValue}
                                  inputComponent={TimepickerInput}
                                  onChange={toTimeChange("textmask")}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Grid container spacing={2}></Grid>
                </Grid>
                <Grid item xs={7}></Grid>
              </Grid>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
}
