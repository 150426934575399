import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

export default function FlaggedFilterViewPanel() {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "380px",
      overflowY: "auto",
      borderLeft: "2px solid grey",
      padding: "10px",
      height: "705px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.14",
      letterSpacing: "normal",
      color: "black",
    },
    FilterHead: {
      fontSize: "12px",
      lineHeight: 1.22,
      color: "black",
    },
  }));

  const dispatch = useDispatch();
  const classes = useStyles();

  const filterInfo = useSelector(
    (state) => state.flaggedDataSourceReducer.filterInfo
  );

  const handleDelete = (data) => {
    dispatch({ type: data.dispatchName, payload: data.value });
    dispatch({
      type: "MAINTAIN_DATA_FLAGGED_SOURCE_SINGLE_DATA",
      payload: {
        name: data.name,
        value: data.value,
        dispatchName: data.dispatchName,
      },
    });
  };

  const renderChipWithClose = (data) => {
    return (
      <Box p={1}>
        <Chip
          label={data.name}
          size="medium"
          style={{ borderRadius: "3px", marginBottom: "2px" }}
          onDelete={() => handleDelete(data)}
          deleteIcon={<CloseIcon />}
        />
      </Box>
    );
  };

  const renderChipWithOutClose = (data) => {
    return (
      <Box p={1}>
        <Chip
          label={data.name}
          size="medium"
          style={{ borderRadius: "3px", marginBottom: "2px" }}
        />
      </Box>
    );
  };

  const renderData = (selectedValues) => {
    return selectedValues.map((data) => {
      if (data.value !== "") {
        return renderChipWithClose(data);
      } else {
        return renderChipWithOutClose(data);
      }
    });
  };

  return (
    <FormControl component="fieldset" className={classes.root}>
      {/* <p className={classes.FilterHead}>Filter By</p> */}
      <FormGroup aria-label="position" row>
        <div
          style={{
            wordBreak: "break-all",
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
            paddingLeft: "1.5rem",
            overflow: "auto",
          }}
        >
          {renderData(filterInfo)}
        </div>
      </FormGroup>
    </FormControl>
  );
}
