import React from "react";
import { dataFLowGroupHeadCell } from "../../common/headCells";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EnhancedTable from "../../shared/alertsTable";

export default function DataFlowGroups(props) {
  const {
    openDataFlowGroupDialog,
    setOpenDataFlowGroupDialog,
    selectDataFlowGroupHandle,
    handleClose,
    alertPolicyDataFlowGroups,
    selectedAlertPolicyToShowFlowGroups
  } = props;
  return (
    <Dialog
      open={openDataFlowGroupDialog}
      onClose={() => setOpenDataFlowGroupDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      fullWidth={true}
      onBackdropClick={handleClose}
    >
      <DialogTitle id="alert-dialog-title">{`Policy Name: ${selectedAlertPolicyToShowFlowGroups}`}</DialogTitle>
      <DialogContent>
        <div className="dataMap-dialog-section">
          <EnhancedTable
            tableData={alertPolicyDataFlowGroups}
            headCells={dataFLowGroupHeadCell}
            loc={"show-policy-flow-groups"}
            selectDataFlowGroupHandle={item => selectDataFlowGroupHandle(item)}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
