// import { createCustomObject_AccessDetails } from "../../components/views/userAccessRights/UserAccessRightsCommonFunctions";
import { isEqual, cloneDeep } from "lodash";

const initialState = {
  totalCount: 0,
  currentPage: 0,
  recordsPerPage: 20,
  allNormalDirectDataSourceUsers: [],
  apiDataSourceName: [],
  apiDataSourceType: [],
  apiDatabaseAccessRoles: [],
  apiDataSourceUsers: [],
  filterInfo: [],
  filterApplied: false,
  sortByValue: "",
  orderByValue: "",
  finalDataSourceName: [],
  finalDataSourceType: [],
  finalDatabaseAccessRoles: [],
  finalDataSourceUsers: [],
  finalSortByValue: "",
  finalOrderByValue: "",
  copyFilterInfo: [],
  accessDetailsDrawer: false,
  AccessDetailsSideDrawerData: {},
  errorMessage: "",
  usersWithAlertsQueryParam: "",
  directDataAccess: false,
};

export const normalDirectDataSourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NORMAL_DIRECT_DATA_SOURCE_INFO": {
      return {
        ...state,
        allNormalDirectDataSourceUsers:
          action.payload.allNormalDirectDataSourceUsers,
        totalCount: action.payload.totalCount,
        loader: false,
      };
    }
    case "SET_NORMAL_DIRECT_DATA_SOURCE_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
        loader: true,
      };
    }
    case "SET_NORMAL_DIRECT_DATA_SOURCE_RECORDS_PAGE": {
      return {
        ...state,
        recordsPerPage: action.payload,
        loader: true,
      };
    }
    case "SET_NORMAL_DIRECT_DATA_SOURCE_LOADER_INFO": {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case "SET_FILTER_DIRECT_DATA_SOURCE_NAME": {
      const copyOfData = [...state.apiDataSourceName];
      if (!state.apiDataSourceName.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDataSourceName: temp,
        };
      }
      return {
        ...state,
        apiDataSourceName: copyOfData,
      };
    }
    case "SET_FILTER_DIRECT_DATA_SOURCE_TYPE": {
      const copyOfData = [...state.apiDataSourceType];
      if (!state.apiDataSourceType.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDataSourceType: temp,
        };
      }
      return {
        ...state,
        apiDataSourceType: copyOfData,
      };
    }
    case "SET_FILTER_DIRECT_DATABASE_ROLES": {
      const copyOfData = [...state.apiDatabaseAccessRoles];
      if (!state.apiDatabaseAccessRoles.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDatabaseAccessRoles: temp,
        };
      }
      return {
        ...state,
        apiDatabaseAccessRoles: copyOfData,
      };
    }
    case "SET_FILTER_DIRECT_DATA_SOURCE_USER": {
      const copyOfData = [...state.apiDataSourceUsers];
      if (!state.apiDataSourceUsers.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDataSourceUsers: temp,
        };
      }
      return {
        ...state,
        apiDataSourceUsers: copyOfData,
      };
    }
    case "MAINTAIN_DIRECT_DATA_SOURCE_SINGLE_DATA": {
      let copyOfData = [...state.filterInfo];
      let present = false;
      for (let i = 0; i < copyOfData.length; i++) {
        if (copyOfData[i].name === action.payload.name) {
          present = true;
          break;
        }
      }
      if (!present) {
        return {
          ...state,
          filterInfo: [...copyOfData, { ...action.payload }],
        };
      } else {
        const temp = copyOfData.filter(
          (each) => each.name !== action.payload.name
        );
        return {
          ...state,
          filterInfo: temp,
        };
      }
    }
    case "DIRECT_DATA_SOURCE_SORT_BY_VALUE": {
      return {
        ...state,
        sortByValue: action.payload,
      };
    }
    case "DIRECT_DATA_SOURCE_ORDER_BY_VALUE": {
      return {
        ...state,
        orderByValue: action.payload,
      };
    }
    case "SET_DIRECT_DATA_SOURCE_APPLY_DATA": {
      return {
        ...state,
        currentPage: 0,
        loader: true,
        filterApplied: true,
        finalDataSourceName: cloneDeep(state.apiDataSourceName),
        finalDataSourceType: cloneDeep(state.apiDataSourceType),
        finalDatabaseAccessRoles: cloneDeep(state.apiDatabaseAccessRoles),
        finalDataSourceUsers: cloneDeep(state.apiDataSourceUsers),
        finalSortByValue: cloneDeep(state.sortByValue),
        finalOrderByValue: cloneDeep(state.orderByValue),
        copyFilterInfo: cloneDeep(state.filterInfo),
      };
    }
    case "DIRECT_DATA_SOURCE_CLEAR_FILTER_DATA": {
      return {
        ...state,
        currentPage: 0,
        loader: true,
        filterApplied: false,
        apiDataSourceName: [],
        apiDataSourceUsers: [],
        apiDataSourceType: [],
        apiDatabaseAccessRoles: [],
        filterInfo: [],
        sortByValue: "",
        orderByValue: "",
        finalDataSourceName: [],
        finalDataSourceType: [],
        finalDatabaseAccessRoles: [],
        finalDataSourceUsers: [],
        finalSortByValue: "",
        finalOrderByValue: "",
        copyFilterInfo: [],
      };
    }
    case "DIRECT_DATA_SOURCE_CLEAR_ON_CLOSE": {
      let apiDataSourceName = state.apiDataSourceName;
      let filterInfo = state.filterInfo;
      let apiDataSourceType = state.apiDataSourceType;
      let apiDatabaseAccessRoles = state.apiDatabaseAccessRoles;
      let sortByValue = state.sortByValue;
      let orderByValue = state.orderByValue;
      let apiDataSourceUsers = state.apiDataSourceUsers;
      if (
        !isEqual(
          state.finalDataSourceName.sort(),
          state.apiDataSourceName.sort()
        )
      ) {
        apiDataSourceName = cloneDeep(state.finalDataSourceName);
      }
      if (!isEqual(state.copyFilterInfo, state.filterInfo)) {
        filterInfo = cloneDeep(state.copyFilterInfo);
      }
      if (
        !isEqual(
          state.finalDataSourceType.sort(),
          state.apiDataSourceType.sort()
        )
      ) {
        apiDataSourceType = cloneDeep(state.finalDataSourceType);
      }
      if (
        !isEqual(
          state.finalDataSourceUsers.sort(),
          state.apiDataSourceUsers.sort()
        )
      ) {
        apiDataSourceUsers = cloneDeep(state.finalDataSourceUsers);
      }
      if (
        !isEqual(
          state.finalDatabaseAccessRoles.sort(),
          state.apiDatabaseAccessRoles.sort()
        )
      ) {
        apiDatabaseAccessRoles = cloneDeep(state.finalDatabaseAccessRoles);
      }
      if (state.sortByValue !== state.finalSortByValue) {
        sortByValue = cloneDeep(state.finalSortByValue);
      }
      if (state.orderByValue !== state.finalOrderByValue) {
        orderByValue = cloneDeep(state.finalOrderByValue);
      }
      return {
        ...state,
        apiDataSourceName: apiDataSourceName,
        filterInfo: filterInfo,
        apiDataSourceType: apiDataSourceType,
        apiDatabaseAccessRoles: apiDatabaseAccessRoles,
        sortByValue: sortByValue,
        orderByValue: orderByValue,
        apiDataSourceUsers: apiDataSourceUsers,
      };
    }
    case "DIRECT_DATA_SOURCE_CLEAR_STATE_DATA": {
      return {
        ...initialState,
      };
    }
    case "OPEN_ACCESS_DRAWER": {
      return {
        ...state,
        accessDetailsDrawer: true,
        AccessDetailsSideDrawerData: action.payload,
      };
    }
    case "CLOSE_ACCESS_DRAWER": {
      return {
        ...state,
        accessDetailsDrawer: false,
        AccessDetailsSideDrawerData: {},
      };
    }
    case "SET_DIRECT_ERROR_MESSAGE": {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case "CLOSE_SNACKBAR": {
      return {
        ...state,
        errorMessage: "",
      };
    }
    case "SET_QUERY_PARAM": {
      return {
        ...state,
        directDataAccess: action.payload.directDataAccess,
        usersWithAlertsQueryParam: action.payload.status,
      };
    }
    case "CLEAR_QUERY_PARAM": {
      return {
        ...state,
        directDataAccess:  action.payload.directDataAccess,
        usersWithAlertsQueryParam: "",
      };
    }
    default:
      return state;
  }
};
