import {
  TENANTSIGNUPPAGE_SUCCESSFUL,
  TENANTSIGNUPPAGE_FAILED,
  TENANTSIGNUPPAGE_RESET,
  TENANTSIGNUPPAGE_SUBMIT,
} from "../constants/TenantSignUpTypes.js";

export const TenantSignUpPage_Successful = () => {
  return {
    type: TENANTSIGNUPPAGE_SUCCESSFUL,
  };
};

export const TenantSignUpPage_Failed = (value) => {
  return {
    type: TENANTSIGNUPPAGE_FAILED,
    payload: value,
  };
};

export const TenantSIGNUPPAGE_Reset = () => {
  return {
    type: TENANTSIGNUPPAGE_RESET,
  };
};
export const TENANTSIGNUPPAGE_Submit = (value) => {
  return {
    type: TENANTSIGNUPPAGE_SUBMIT,
    payload: value,
  };
};
