import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getCubejsApi } from "../common/Dashboard/cubejs-util";
import { chartTypes } from "../common/Dashboard/chartTypeToComponent";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import InactiveIcon from "../../assets/vizr_images/Group 3.png";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import UnresolvedAlerts from "../shared/totalAlerts/UnresolvedAlerts";
import ResolvedFalsePositiveAlerts from "../shared/totalAlerts/ResolvedFalsePositiveAlerts";
import { DataMapRoute } from "../common/DatamapImage";

export default function Alerts(props) {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const alertStatusUpdated = useSelector(
    state => state.alertStatusChanged.alertUpdated
  );
  const alertStatusUpdatedSuccessMessage = useSelector(
    state => state.alertStatusChanged.successMessage
  );
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [preferences, setPreferences] = useState(0);
  const alertStatusUpdatedErrorMessage = useSelector(
    state => state.alertStatusChanged.unsuccessful
  );

  let cubejsApi = getCubejsApi();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let queryData = {
    measures: ["Alerts.count"],
    timeDimensions: [{ dimension: "Alerts.date", granularity: "day" }],
    dimensions: ["Alerts.severity"],
    timezone: tz,
  };
 
  useEffect(() => {
    if (alertStatusUpdatedSuccessMessage !== "") {
      setSnackBarMessage(alertStatusUpdatedSuccessMessage);
    }
  }, [alertStatusUpdatedSuccessMessage]);

  useEffect(() => {
    if (alertStatusUpdatedErrorMessage !== "") {
      setSnackBarMessage(alertStatusUpdatedErrorMessage);
      setOpen(true);
    }
  }, [alertStatusUpdatedErrorMessage]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);


  useEffect(() => {
    if (alertStatusUpdated) {
      setOpen(true);
    }
  }, [alertStatusUpdated]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

 
  return (
    <React.Fragment>
      <div className="main">
        <div className="description">
          <div style={{ display: "grid" }}>
            <h2 style={{ margin: "0", fontSize: "32px" }}>Alerts</h2>
            <p style={{ margin: "0" }}>
              Alerts generated due to violation of policies.{" "}
            </p>
          </div>
          <DataMapRoute />
        </div>
        <div className="chart-container alerts">
          {chartTypes.alertsOverTimeBarChart(cubejsApi, "Alerts Over Time")}
        </div>
        <div className="filter-table-container">
          <div className="table-container">
            <UnresolvedAlerts propsData= {props}/>
          </div>
        </div>
        <div className="alerts-table-container">
          <ResolvedFalsePositiveAlerts propsData= {props} />
        </div>
        <Snackbar
          //key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          onExited={handleExited}
          //message={messageInfo ? messageInfo.message : undefined}
        >
          <Alert
            onClose={handleClose}
            severity={alertStatusUpdatedErrorMessage ? "error" : "success"}
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}
