import React from "react";
import SignUpBackGround from "../../../src/components/views/BackOfficeGeneralContainer/SignupPageBackGround";
import "./IntegrationStyles.scss";

export default function IntegrationsHealth() {
  return (
    <div className="IntegrationsHealth_Container">
      <div
        style={{
          overflowX: "hidden",
          overflowY: "hidden" /* Hide horizontal scrollbar */,
          minHeight: "150vh",
        }}
      >
        <SignUpBackGround
          headerName="Integrations & Health"
          breadcrumbValue
          // type="marginRequired"
        ></SignUpBackGround>
      </div>
    </div>
  );
}
