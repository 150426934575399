export const awsFormData = [
  {
    name: "Name",
    label: "Name",
    type: "text",
    placeholder: "Name",
    required: true,
    errorMessage: "Name cannot be blank or empty",
  },
  {
    name: "applicationName",
    label: "Application Name",
    type: "autocomplete",
    placeholder: "Application Name",
    required: true,
    errorMessage: "Application Name cannot be blank or empty",
  },
  {
    name: "sourceType",
    label: "Source Type",
    type: "select",
    required: true,
    errorMessage: "Source Type must be selected",
  },
  {
    name: "databaseName",
    label: "S3 Bucket Name",
    type: "text",
    placeholder: "S3 Bucket Name",
    required: true,
    errorMessage: "S3 Bucket Name cannot be blank or empty",
  },
  {
    name: "accessKey",
    label: "Access Key",
    type: "password",
    placeholder: "Access Key",
    required: true,
    errorMessage: "Access Key cannot be blank or empty",
  },
  {
    name: "secretKey",
    label: "Secret Key",
    type: "password",
    placeholder: "Secret Key",
    required: true,
    errorMessage: "Secret Key cannot be blank or empty",
  },
  {
    name: "dataLocation",
    label: "Data Location",
    type: "text",
    placeholder: "Data Location",
  },
  {
    name: "regions",
    label: "Region",
    type: "autocomplete",
    required: true,
    placeholder: "Select a Region",
    errorMessage: "Region cannot be blank or empty"
  },
];
