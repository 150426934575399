import React from 'react';
import './Badge.scss';
import { handleLowPercentage } from '../../common/utilities';

/**
 * CircularProgressBar component renders a circular progress bar with a given percentage.
 *
 * @param {number} strokeWidth - The width of the progress bar stroke.
 * @param {number} percentage - The percentage of the progress to be displayed.
 * @param {string} severity - The severity level of the progress bar.
 * @param {number} width - The width of the SVG element.
 * @param {number} height - The height of the SVG element.
 * @param {number} titlePercentage - The percentage of the progress bar.
 * @returns {JSX.Element} CircularProgressBar component.
 */

export default function CircularProgressBar({ strokeWidth, percentage, severity, width, height, titlePercentage, count }) {
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
    M 50,50 m 0,-${radius}
    a ${radius},${radius} 0 1 1 0,${2 * radius}
    a ${radius},${radius} 0 1 1 0,-${2 * radius}
  `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    strokeLinecap: 'round',
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
  };


  return (
    <div title={`${titlePercentage}%`}>
      <svg className='CircularProgressbar' viewBox="-2 -4 110 110" width={width} height={height}>

        <path
          className="CircularProgressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fill="transparent"
        />

        {/* Added extra path to add border around the circular progress bar */}
        <path
          className={`CircularProgressbar-path-border ${severity}`}
          d={pathDescription}
          strokeWidth={strokeWidth + 7} // Adjust the border width as needed
          fill="transparent"
          stroke="red"
          style={progressStyle}
        />

        <path
          className={`CircularProgressbar-path ${severity}`}
          d={pathDescription}
          strokeWidth={strokeWidth}
          fill="transparent"
          style={progressStyle}
        />

        <text
          className="CircularProgressbar-text"
          x={50}
          y={50}
          style={{
            fill: 'black',
            fontSize: '22px',
            fontWeight: '700',
            dominantBaseline: 'central',
            textAnchor: 'middle',
          }}
        >
          {handleLowPercentage(count, percentage, '<1%')}
        </text>
      </svg>
    </div>
  );
}
