import { GET_ALERTS_URL } from "../constants/constants";
import { getService, getAxiosHeaders } from "../../library/RestAPI";
//dispatch add method when use of Saga
export default async function fetchAllResolvedAlerts(
  pageSize,
  currentPage,
  sortBy,
  severity,
  resolution,
  policy,
  policyId,
  sortOrder,
  startTime,
  endTime
) {
  let sev = "";
  let res = "";
  let name = "";
  let paramData = {};
  if (severity && severity.length > 0) {
    severity.forEach(each => {
      sev = sev + `${each},`;
    });
    sev = sev.slice(0, -1);
    paramData.severity = sev;
  }
  if (resolution && resolution.length > 0) {
    resolution.forEach(each => {
      res = res + `${each},`;
    });
    res = res.slice(0, -1);
    paramData.resolutionType = res;
  }
  if (policy && policy.length > 0) {
    policy.forEach(each => {
      name = name + `${each},`;
    });
    name = name.slice(0, -1);
    paramData.policy = name;
  }
  if (sortBy && sortBy !== "") {
    paramData.sortBy = sortBy;
  }
  if(sortOrder && sortOrder !== ""){
    paramData.direction = sortOrder;
  }
  if (policyId) {
    paramData.policy = policyId;
  }  
  if(startTime && endTime){
    paramData.from = startTime.getTime();
    paramData.to = endTime.getTime();
  }
  const options = {
    method: "GET",
    url: `${GET_ALERTS_URL}?size=${pageSize}&page=${currentPage}&status=RESOLVED,FALSE_POSITIVE`,
    params: paramData,
    headers: getAxiosHeaders(true),
    data: {}
  };
  const response = await getService(options);
  if (response) {
    return response.data;
  }
}
