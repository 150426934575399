import config from "../../project.config";
import { domain_vizr } from "./constants";

// export const protocol = !config.secure ? "http://" : "https://";
// //const domain_vizr = !config.secure ? '10.19.1.192:8082' : 'VizrUrl';
// const domain_vizr = !config.secure ? "10.19.1.192:8082" : "VizrUrl";

export const token = `Bearer ${localStorage.getItem("access_token")}`;

// export const access_token = localStorage.getItem("access_token");
// export const token = `Bearer ${access_token}`

export const ALERTS = "ALERTS";
export const GET_ALERTS = "GET_ALERTS";
export const GET_ALERT_POLICIES = "GET_ALERT_POLICIES";
export const GET_POLICY_DETAIL = "GET_POLICY_DETAIL";
export const DELETE_POLICY_DETAIL = "DELETE_POLICY_DETAIL";
export const UPDATE_POLICY_STATUS = "UPDATE_POLICY_STATUS";
export const UPDATE_ALERT_STATUS = "UPDATE_ALERT_STATUS";
export const DATA_FLOW_GROUPS = "DATA_FLOW_GROUPS";
export const DATA_FLOWS = "DATA_FLOWS";
export const UPDATE_ALERT_POLICY = "UPDATE_ALERT_POLICY";
export const CREATE_ALERT_POLICY = "CREATE_ALERT_POLICY";
export const ALERT_DETAILS = "ALERT_DETAILS";
export const SERVICE_MAP_FOR_ALERT = "SERVICE_MAP";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const SEARCH_USER = "SEARCH_USER";
export const GET_ALERT_DETAILS = "GET_ALERT_DETAILS";
export const GET_ALERT_POLICY_ALERTS = "GET_ALERT_POLICY_ALERTS";
export const GET_POLICY_COMMENTS = "GET_POLICY_COMMENTS";
export const TENANT_TOKEN = "TENANT_TOKEN";
export const GET_POLICY_DATAFLOW = "GET_POLICY_DATAFLOW";
export const GET_RELATED_ALERTS = "GET_RELATED_ALERTS";
export const GET_ALERT_RESOLUTION_TYPES = "GET_ALERT_RESOLUTION_TYPES";
export const GET_ALERT_POLICY_SEVERITIES = "GET_ALERT_POLICY_SEVERITIES";
export const GET_ALERT_POLICY_NAMES = "GET_ALERT_POLICY_NAMES";

//Token
export const OAUTH_URL = domain_vizr + "/oauth/token"; //token call

//Alerts
export const GET_ALERTS_URL = domain_vizr + "/tenant/alerts"; //get alerts url

//DataMap
export const GET_DATAMAP_INFO = "GET_DATAMAP_INFO";
export const GET_DATAMAP_FLOWGROUPS = domain_vizr + "/tenant/dataFlowGroups";
export const GET_DATAMAP_DATAFLOWS = domain_vizr + "/tenant/dataFlows";
export const GET_DATAMAP_TRANSACTIONS = domain_vizr + "/tenant/transactions";
export const GET_DATAMAP_CONTENTS = domain_vizr + "/tenant/dataMapContents";
export const GET_DATAMAP_FILTERS = domain_vizr + "/tenant/dataSources";
export const GET_DATAMAP_FILTERS_BYID =
  domain_vizr + "/tenant/dataSources?applicationId=";

export const GET_DATAMAP_NODES = domain_vizr + "/tenant/dataMap";
export const GET_ALERT_BY_NODE_ID = (nodeId) =>
  `${domain_vizr}/tenant/dataMap/nodes/${nodeId}/metrics`;
