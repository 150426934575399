import { call, put, takeEvery } from "redux-saga/effects";
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import {
  GET_TENANT_LOGO,
  GET_TENANT_PROFILE,
  SET_TENANT_PROFILE_LOADER,
  SET_TENANT_PROFILE_SNACKBAR,
  TENANT_UPDATE_UNSUCCESSFUL,
  UPDATE_TENANT_PROFILE,
} from "../constants/constants";
import { redirectToLogin } from "../../components/common/Dashboard/cubejs-util";
import { isNull } from "lodash";

function* GetTenantProfile() {
  const tenantId = localStorage.getItem("tenantId");
  try {
      const tenantLogo = yield call(() =>
        getService({
          method: "GET",
          url: GET_TENANT_LOGO(tenantId),
          headers: getAxiosHeaders(true),
        })      
      );
      if (tenantLogo.status === 200 && tenantLogo.data) {
        localStorage.setItem("tenantLogo", tenantLogo.data.logo);
        localStorage.setItem("tenantName", JSON.stringify(tenantLogo.data.name));
      } else {
        const featuresInfo = JSON.parse(localStorage.getItem("featuresInfo"))
        localStorage.setItem("tenantLogo", "");
        localStorage.setItem("tenantName", JSON.stringify(featuresInfo.tenantName));
      }
    } catch (error) {
      redirectToLogin(error);
    }
}
function* UpdateTenantProfile(action) {
  const handleEditTenant = action.payload.handleEditTenant;
  yield put({ type: SET_TENANT_PROFILE_LOADER, payload: true });
  try {
    const res = yield getService({
      method: "PUT",
      url: `${GET_TENANT_LOGO(action.payload.tenantId)}`,
      data: action.payload.formData,
      headers: getAxiosHeaders(true),
    });
    if (res && res.status === 200 && res.data) {
      if(!isNull(res.data.logo)){
        yield localStorage.setItem("tenantLogo", res.data.logo);
      }
      yield localStorage.setItem("tenantName", JSON.stringify(res.data.name));
      yield put({ type: SET_TENANT_PROFILE_LOADER, payload: false });
      handleEditTenant();
    } else {
      const error = res.response && res.response.data;
      yield put({ type: SET_TENANT_PROFILE_LOADER, payload: false });
      yield put({
        type: SET_TENANT_PROFILE_SNACKBAR,
        payload: { status: true, message: error.errorMessage },
      });
    }
  } catch (e) {
    yield put({ type: SET_TENANT_PROFILE_LOADER, payload: false });
    yield put({
      type: SET_TENANT_PROFILE_SNACKBAR,
      payload: { status: true, message: TENANT_UPDATE_UNSUCCESSFUL },
    });
  }
}

function* TenantProfileSaga() {
  yield takeEvery(UPDATE_TENANT_PROFILE, UpdateTenantProfile);
  yield takeEvery(GET_TENANT_PROFILE, GetTenantProfile);
}
export default TenantProfileSaga;
