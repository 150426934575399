import { put, takeLatest, select } from "redux-saga/effects";
import { GET_SERVICE_ACCESS_LOGS ,GET_SERVICE_ACCESS_LOGS_COUNT_URL, GET_SERVICE_ACCESS_LOGS_DETAILS} from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

function* getParamData() {
  let data = yield select();
  const {
    finalDataSourceName,
    finalDataSourceType,
    finalDatabaseAccessRoles,
    finalPolicyNames,
    finalSensitivityInfo,
    finalSortingValue,
    finalOrderByValue,
    alertId,
    finalFromTimeValue,
    finalToTimeValue,
    finalapplications,
    finalAccessStatements,
    finalServiceUsers
  } = data.serviceAccessLogsReducer;
  
  let paramData = {};

  if (finalDataSourceType && finalDataSourceType.length > 0) {
    paramData.dataSourceType = finalDataSourceType.join(",");
  }
  if (finalDatabaseAccessRoles && finalDatabaseAccessRoles.length > 0) {
    paramData.databaseAccessRoles = finalDatabaseAccessRoles.join(",");
  }
  if (finalPolicyNames && finalPolicyNames.length > 0) {
    paramData.alertPolicyId = finalPolicyNames.join(",");
  }
  if (finalSensitivityInfo && finalSensitivityInfo.length > 0) {
    paramData.sensitivityLevel = finalSensitivityInfo.join(",");
  }
  if (finalDataSourceName && finalDataSourceName.length > 0) {
    paramData.dataSource = finalDataSourceName.join(",");
  }
  if (finalSortingValue && finalSortingValue !== "") {
    paramData.sortBy = finalSortingValue;
  }
  if(finalOrderByValue && finalOrderByValue !== ""){
    paramData.direction = finalOrderByValue;
  }
  if(alertId && alertId !== ""){
    paramData.alertId = alertId;
  }
  if(finalFromTimeValue && finalFromTimeValue !== ""){
    paramData.fromTimeStamp = finalFromTimeValue
  }
  if(finalToTimeValue && finalToTimeValue !== ""){
    paramData.toTimeStamp = finalToTimeValue
  }
  if(finalapplications && finalapplications.length>0)
  {
    paramData.applicationId = finalapplications.join(",");
  }
  if(finalAccessStatements && finalAccessStatements.length>0)
  {
    if(finalAccessStatements[0] === "Statement Present")
    {
      paramData.accessStatement = "Yes"
    }
  }
  if(finalServiceUsers && finalServiceUsers.length>0)
  {
    if(finalServiceUsers[0] === "ServiceUser Present")
    {
      paramData.serviceUser = "Yes"
    }
  }
  return paramData;
}

function* serviceLogsApi() {
  let paramData = yield getParamData();
  let data = yield select();
  const {
    currentPage,
    recordsPerPage,
  } = data.serviceAccessLogsReducer;
  let options;
  options = {
    method: "GET",
    url: `${GET_SERVICE_ACCESS_LOGS}?size=${recordsPerPage}&page=${currentPage}`,
    headers: getAxiosHeaders(true),
    params: paramData,
    data: {},
    timeout: 1200000,
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({ type: "SET_SERVICE_ACCESS_LOGS_INFO", payload: { allDatabaseLogs: res.data.content, totalCount: res.data.totalElements }});
    } else {
      yield put({ type: "SET_LOADER_INFO", payload: false });
    }
  } catch (error) {
    yield put({ type: "SET_LOADER_INFO", payload: false });
  }
}

function* serviceLogsCountApi() {
  const timeToCall = new Date().getTime();
  let paramData = yield getParamData();
  let options;
  options = {
    method: "GET",
    url: `${GET_SERVICE_ACCESS_LOGS_COUNT_URL}`,
    headers: getAxiosHeaders(true),
    params: paramData,
    data: {},
    timeout: 1200000,
  };
  try {
    const res = yield getService(options);
    let data = yield select();
    const {
      freshLoadTimestamp
    } = data.serviceAccessLogsReducer;
    if (freshLoadTimestamp < timeToCall) {
      // update count to store only if logs are not changed or updated
      yield put({ type: "SET_SERVICE_ACCESS_LOGS_COUNT", payload: { totalCount: res.data }});
    }
  } catch (error) {
    yield put({ type: "SET_LOADER_INFO", payload: false });
  }
}

function* serviceAccessLogsDetailsApi() {
  let options;
  let data = yield select();
  const {
    currentPage_logDetails,
    recordsPerPage_logDetails,
    finalFromTimeValue,
    finalToTimeValue,
    ServiceAccessLogsSideDrawerData,
  } = data.serviceAccessLogsReducer;
  let traceId= ServiceAccessLogsSideDrawerData.traceId;
  let enterpriseActorId =ServiceAccessLogsSideDrawerData.enterpriseActor.id;
  let paramData = {};
  if (finalFromTimeValue && finalFromTimeValue !== "") {
    paramData.fromTimeStamp = finalFromTimeValue;
  }
  if (finalToTimeValue && finalToTimeValue !== "") {
    paramData.toTimeStamp = finalToTimeValue;
  }
  options = {
    method: "GET",
    url: `${GET_SERVICE_ACCESS_LOGS_DETAILS}?size=${recordsPerPage_logDetails}&page=${currentPage_logDetails}&traceId=${traceId}&enterpriseActorId=${enterpriseActorId}`,
    headers: getAxiosHeaders(true),
    params: paramData,
    data: {}
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({
        type: "SERVICE_DATA_ACCESSES_LOGS_OPEN_ACCESS_DRAWER_GET_TABLE_DATA",
        payload: {serviceAccessDrawerLogs : res.data.content, totalCountLogs : res.data.totalElements},
      });
      yield put({
        type: "SERVICE_DATA_ACCESSES_LOGS_OPEN_ACCESS_DRAWER_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    yield put({
      type: "SERVICE_DATA_ACCESSES_LOGS_OPEN_ACCESS_DRAWER_LOADER",
      payload: false,
    });
  }
}

function* serviceLevelAccessLogs() {
  yield takeLatest("GET_SERVICE_ACCESS_LOGS_INFO_DATA", serviceLogsApi);
  yield takeLatest("GET_SERVICE_LOGS_COUNT", serviceLogsCountApi);
  yield takeLatest("GET_SERVICE_ACCESS_LOGS_INFO_DATA_DETAILS", serviceAccessLogsDetailsApi);
}

export default serviceLevelAccessLogs;
