import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  MDRAccountIdArr,
  MDRIpConfigArr,
  MDRIpConfigLoc,
  MDRAccountIdConfigLoc,
  createConcentrator,
  createSubnet,
  createEndpoint,
} from "../../../redux/constants/constants";
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  ConcentratorConfigFieldsArray,
  IpConfigFieldsArray,
  EndpointConcentratorFieldsArray,
  ConcentratorKeys,
} from "../../views/MdrConcentrator/utilities";

const MDRInputCommon = ({
  inputFieldsNames,
  state,
  index1,
  inputHandler,
  errorStateArr,
  countryList,
  regionsList,
  citiesList,
  setcurrentCountrySearchStr,
  setcurrentRegionSearchStr,
  addLatitudeAndLongitude,
  loc,
}) => {
  const theme = createMuiTheme({
    components: {
      // Name of the component ⚛️
      MuiInputBase: {
        padding: 0,
      },
      MuiFormControl: {
        padding: 0,
      },
      MuiOutlinedInput: {
        padding: 0,
      },
      // MuiButtonBase: {
      //   defaultProps: {
      //     // The default props to change
      //     disableRipple: true, // No more ripple, on the whole application 💣!
      //   },
      // },
    },
  });

  const settingsUpdatedLocation = useSelector(
    (state) => state.mdrSettingsReducer.updatedLocation
  );
  const [values, setValues] = useState([]);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState([
    false,
    false,
    false,
  ]);
  useEffect(() => {
    if (settingsUpdatedLocation === MDRIpConfigLoc) {
      setValues(MDRIpConfigArr);
    } else if (settingsUpdatedLocation === MDRAccountIdConfigLoc) {
      setValues(MDRAccountIdArr);
    } else if (settingsUpdatedLocation === createConcentrator) {
      setValues(ConcentratorConfigFieldsArray);
    } else if (settingsUpdatedLocation === createSubnet) {
      setValues(IpConfigFieldsArray);
    } else if (settingsUpdatedLocation === createEndpoint) {
      setValues(EndpointConcentratorFieldsArray);
    }
    setAutoCompleteOpen([false, false, false]);
  }, [settingsUpdatedLocation]);

  console.log(inputFieldsNames, values, state, settingsUpdatedLocation);

  const inputFieldDisableFinder = (inputFieldsNames) => {
    let disabled = false;
    if (inputFieldsNames === "Country") {
      disabled = false;
    } else if (inputFieldsNames === "Region" && !state[index1]["country"]) {
      disabled = true;
    } else if (
      inputFieldsNames === "City" &&
      (!state[index1]["country"] || !state[index1]["region"])
    ) {
      disabled = true;
    }
    return disabled;
  };
  const optionsFinder = (inputFieldsNames) => {
    if (inputFieldsNames === "Country") {
      return countryList;
    } else if (inputFieldsNames === "Region") {
      return regionsList;
    } else if (inputFieldsNames === "City") {
      return citiesList;
    }
  };

  const openHandler = (index2) => {
    if (settingsUpdatedLocation === MDRIpConfigLoc) {
      return autoCompleteOpen[index2 - 2];
    } else if (settingsUpdatedLocation === MDRAccountIdConfigLoc) {
      return autoCompleteOpen[index2 - 1];
    }
  };
  const updateHandler = (index2, autoCompleteArr, value) => {
    if (settingsUpdatedLocation === MDRIpConfigLoc) {
      autoCompleteArr[index2 - 2] = !!value;
    } else if (settingsUpdatedLocation === MDRAccountIdConfigLoc) {
      autoCompleteArr[index2 - 1] = !!value;
    }
    return autoCompleteArr;
  };

  const returnType = (value) => {
    let type = "text";
    if (value === "Password") {
      type = "password";
    }
    if (value === "Port") {
      type = "number";
    }
    return type;
  };
  return (
    <ThemeProvider theme={theme}>
      {inputFieldsNames.length &&
        state.length &&
        inputFieldsNames.map((inputFieldsNames, index2) => {
          let disabled = inputFieldDisableFinder(inputFieldsNames);
          let options = optionsFinder(inputFieldsNames);
          let open = openHandler(index2);

          return (
            <Grid item sm xs={12}>
              <>
                <h3
                  className="onboarding_text"
                  style={{ marginBottom: "0.8rem" }}
                >
                  {inputFieldsNames}
                  {inputFieldsNames !== "Subnet Mask" &&
                    inputFieldsNames !== ConcentratorKeys.AUTH_ERROR_COND &&
                    inputFieldsNames !== ConcentratorKeys.EVENT_COUNT_COND && (
                      <span
                        style={{
                          color: "red",
                          marginLeft: "0.2rem",
                        }}
                      >
                        *
                      </span>
                    )}
                </h3>

                {inputFieldsNames === "Country" ||
                inputFieldsNames === "Region" ||
                inputFieldsNames === "City" ? (
                  <div className="MDRContainerInputFields">
                    <Autocomplete
                      className="MDRAutocomplete"
                      options={options}
                      disabled={disabled}
                      open={open}
                      value={
                        values.length > 0 ? state[index1][values[index2]] : ""
                      }
                      // This will close the autocomplete on empty text
                      // Will collapse on select

                      onChange={(event, newValue) => {
                        if (inputFieldsNames !== "City") {
                          inputHandler(
                            event,
                            index1,
                            inputFieldsNames,
                            newValue
                          );
                        }
                      }}
                      onInputChange={(event, value, reason) => {
                        switch (reason) {
                          case "input":
                            let autoCompleteArr = [...autoCompleteOpen];
                            let updatedAutoCompleteArr = updateHandler(
                              index2,
                              autoCompleteArr,
                              value
                            );
                            setAutoCompleteOpen(updatedAutoCompleteArr);
                            break;
                          case "reset":
                          case "clear":
                            if (inputFieldsNames === "Country") {
                              setcurrentCountrySearchStr(value);
                            } else if (inputFieldsNames === "Region") {
                              setcurrentRegionSearchStr(value);
                            } else if (inputFieldsNames === "City") {
                              //! To update city state
                              addLatitudeAndLongitude(index1, value);
                            }
                            setAutoCompleteOpen([false, false, false]);
                            break;
                          default:
                            console.log(reason);
                        }
                      }}
                      // Removed the required symbol as it is already there on the top of the input text-fields
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Select ${inputFieldsNames}`}
                          variant="outlined"
                          size="small"
                          className="MDRFormcontrol"
                        />
                      )}
                    />
                  </div>
                ) : (
                  <input
                    value={
                      values && values.length > 0 && state && state.length > 0
                        ? state[index1][values[index2]]
                        : ""
                    }
                    type={returnType(inputFieldsNames)}
                    className="MDRInputTextFeildAddConfig"
                    disabled={inputFieldsNames === "Name"}
                    onChange={(event) => {
                      inputHandler(event, index1, inputFieldsNames);
                    }}
                    autoComplete="new-password"
                  ></input>
                )}

                {errorStateArr[index1][values[index2]] && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marignTop: "1rem !important",
                      fontFamily: "Nunito-Regular",
                    }}
                  >
                    {errorStateArr[index1][values[index2]]}
                  </div>
                )}
              </>
            </Grid>
          );
        })}
    </ThemeProvider>
  );
};

export default MDRInputCommon;
