import React, { useState } from "react";
import "./ShowMoreData.scss";

const ShowMoreData = ({
  data,
  size,
  fullRender,
  partialRender,
}) => {
  const [loadEntireText, setLoadEntireText] = useState(false);
  const onShowMore = (e) => {
    setLoadEntireText(true);
    e.stopPropagation();
  }
  const onShowLess = (e) => {
    setLoadEntireText(false);
    e.stopPropagation();
  }
  const dataString = Array.isArray(data) ? data.join(",") : data;
  const stringFitsView = dataString.length <= size;
  return (
    <>
      {
        (loadEntireText || stringFitsView) ?
          <span><span>{fullRender(data)}</span> {!stringFitsView ? <span class="linkText" onClick={onShowLess}>(Show Less)</span> : null}</span> :
          <span>{partialRender(data)}...<span class="linkText" onClick={onShowMore}>Show More</span></span>
      }
    </>
  );
};

export default ShowMoreData;
