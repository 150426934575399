import { GET_ALERT_POLICY_NAMES, GET_ALERT_POLICY_NAMES_URL} from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function getAllAlertPolicyNames(id) {
    const options = {
        method: "GET",
        url: `${GET_ALERT_POLICY_NAMES_URL}`,
        headers: getAxiosHeaders(true)
    };

    const response = await getService(options);
    if(response) {
        return response.data
    }
};
