import {
  // SIGNUPPAGE_USERNAME,
  SIGNUPPAGE_FIRSTNAME,
  SIGNUPPAGE_LASTNAME,
  SIGNUPPAGE_PHONE,
  SIGNUPPAGE_ADMINNAME,
  SIGNUPPAGE_ADMINEMAIL,
  SIGNUPPAGE_TENENTNAME,
  SIGNUPPAGE_DOMAIN,
  SIGNUPPAGE_MONITOR,
  SIGNUPPAGE_CONTROL,
  // SIGNUPPAGE_SELFSUBMIT,
  SIGNUPPAGE_RESET,
  SIGNUPPAGE_SUCCESSFUL,
  SIGNUPPAGE_FAILED,
} from "../constants/signuppageActionTypes";

const initialState = {
  // userName: "",
  // firstName: "",
  // lastName: "",
  // phoneNumber: "",
  // adminName: "",
  // adminEmail: "",
  // tenentName: "",
  // domain: "",
  // monitor: "Silver500",
  // control: "Silver 500",
  signUpsuccessful: false,
  signUpFailed: false,
  signUpFailedErrorMessage: "",
};

export const signUpPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUPPAGE_FIRSTNAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case SIGNUPPAGE_LASTNAME:
      return {
        ...state,
        lastName: action.payload,
      };
    case SIGNUPPAGE_PHONE:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case SIGNUPPAGE_ADMINNAME:
      return {
        ...state,
        adminName: action.payload,
      };

    case SIGNUPPAGE_ADMINEMAIL:
      return {
        ...state,
        adminEmail: action.payload,
      };
    case SIGNUPPAGE_TENENTNAME:
      return {
        ...state,
        tenentEmail: action.payload,
      };
    case SIGNUPPAGE_DOMAIN:
      return {
        ...state,
        domain: action.payload,
      };
    case SIGNUPPAGE_MONITOR:
      return {
        ...state,
        monitor: action.payload,
      };
    case SIGNUPPAGE_CONTROL:
      return {
        ...state,
        control: action.payload,
      };

    case SIGNUPPAGE_SUCCESSFUL:
      return {
        ...state,
        signUpsuccessful: true,
      };

    case SIGNUPPAGE_FAILED:
      return {
        ...state,
        signUpFailed: true,
        signUpFailedErrorMessage: action.payload,
      };
    case SIGNUPPAGE_RESET:
      return {
        ...state,
        signUpsuccessful: false,
      };

    default:
      return state;
  }
};
