import { GET_ALERT_POLICIES } from "../constants/constants";

const initialState = {
  getAlertPolicies: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALERT_POLICIES:
      return {
        ...state,
        getAlertPolicies: action.data,
      };
    default:
      return state;
  }
}
