import React from "react";
import { useSelector } from "react-redux";
import "./IncidentTableStyles.scss";
import UseIncidentFilterHook from "./IncidentFilterHook";
import { GET_AUTHENTICATION_SOURCE_FILTER_ACTION } from "../../../../redux/actions/MDRActions/AuthenticationDashboardAction";
import { AuthenticationTableFilter_Query_toGet_FilterValues } from "../../../../redux/constants/constants";
import { getFilterTimeStamp } from "../../../common/utilities"

const AuthenticationTableFilter = () => {
  const dateTimeRange = useSelector(
    (state) => {
      return state.selectedDateTimeRange.dateTimeRange;
    }
  );
  const {startDt, endDt} = getFilterTimeStamp(dateTimeRange);
  const AuthenticationTableFilter_Query_cache = AuthenticationTableFilter_Query_toGet_FilterValues(startDt, endDt);
  
  let mdrAuthenticationSourceFilter = useSelector(
    (state) => state.MDRAuthenticationReducer.mdrAuthenticationSourceFilter
  );
  return (
    <>
      <div className="AuthenticationFilter">
        <p>Source</p>
        <UseIncidentFilterHook
          query={AuthenticationTableFilter_Query_cache.source}
          action={GET_AUTHENTICATION_SOURCE_FILTER_ACTION}
          filterData={mdrAuthenticationSourceFilter}
        ></UseIncidentFilterHook>
      </div>
      {/* <div className="AuthenticationFilter">
        <p>Source</p>
        <UseIncidentFilterHook
          query={AuthenticationTableFilter_Query_cache.source}
          action={GET_AUTHENTICATION_SOURCE_FILTER_ACTION}
          filterData={mdrAuthenticationSourceFilter}
        ></UseIncidentFilterHook>
    </div> */}
    </>
  );
};

export default AuthenticationTableFilter;
