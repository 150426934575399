import React from "react";
import "../../assets/scss/TenantMangement.scss";

const ImageEditing = ({
  imageHandler,
  inputFile,
  file,
  state,
  clickHandler,
  disabled,
}) => {
  return (
    <div>
      <div>
        <div></div>
      </div>
      <input
        disabled={disabled ? disabled : ""}
        type="file"
        id="img"
        name="img"
        accept="image/*"
        onChange={imageHandler}
        onClick={clickHandler}
        ref={inputFile}
        className="img"
      />
      {file ? (
        <img className="uploaded_image" src={file} alt="Preview" />
      ) : state.profilePicture ? (
        <img
          className="uploaded_image"
          src={`data:image/png;base64,` + state.profilePicture}
          alt="Preview"
        />
      ) : (
        <div className="name_text">
          {state.firstName[0] && state.lastName[0]
            ? `${state.firstName[0]} ${state.lastName[0]}`
            : ""}
        </div>
      )}
    </div>
  );
};

export default ImageEditing;
