import {
  SIGNINPAGE_USERNAME,
  SIGNINPAGE_PASSWORD,
  USER_FETCH_SUCCEEDED,
  USER_FETCH_FAILED,
  USER_FETCHSTATE_REFRESH,
  USER_DETAILS,
} from "../constants/signinpageActionTypes";

const initialState = {
  username: "",
  password: "",
  user: "",
  isloggedIn: false,
  loginEnableMFA: false,
  mfa_token: "",
  loginErrorMessage: "",
  token: "",
  tenant: "",
  userDetails: "",
  profilePicture: "",
};

export const signInPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNINPAGE_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case SIGNINPAGE_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case USER_FETCH_SUCCEEDED: {
      console.log("USER_FETCH_SUCCEEDED");
      return {
        ...state,
        isloggedIn: true,
        token: "",
        userDetails: action.payload,
        profilePicture: action.profilePicture,
      };
    }
    case USER_FETCH_FAILED: {
      return {
        ...state,
        loginErrorMessage: action.payload,
      };
    }
    case "USER_FETCH_FAILED_DUE_TO_MFA_ENABLED": {
      return {
        ...state,
        loginEnableMFA: true,
        mfa_token: action.payload.mfa_token,
      };
    }
    case USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case USER_FETCHSTATE_REFRESH: {
      return {
        username: "",
        password: "",
        user: "",
        isloggedIn: false,
        loginErrorMessage: "",
        token: "",
        tenant: "",
      };
    }
    case "USER_ERROR_MESSAGE_REFRESH": {
      return {
        loginErrorMessage: "",
      };
    }

    default:
      return state;
  }
};
