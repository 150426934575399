import { put, takeEvery } from "redux-saga/effects";
import { PLATFORM_ADMIN_TENANT_ONBOARD_REQUEST } from "../constants/constants";
import { PLATFORM_ADMIN_TENANT_ONBOARD_URL } from "../constants/constants";
import {
  PlatformAdminTenantOnboardFail,
  PlatformAdminTenantOnboardSuccess,
} from "../actions/platformAdminTenantOnBoardAction";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

function* PlatformAdminTenantOnboard(action) {
  let options;
  yield console.log("Hello from TenantSignUpSaga");
  try {
    const formData = new FormData();
    const payload = JSON.stringify(action.payload.tenantDetails);
    const logo = action.payload.image ? action.payload.image : "";
    formData.append("payload", payload);
    formData.append("logo", logo);
    options = {
      method: "POST",
      url: PLATFORM_ADMIN_TENANT_ONBOARD_URL,
      headers: getAxiosHeaders(true, "multipart/form-data"),
      data: formData,
    };
    try {
      const res = yield getService(options);
      if (res && res.status === 201) {
        yield put(PlatformAdminTenantOnboardSuccess());
        yield put({ type: "TRACK_TRAVERSAL", payload: "Tenant Management" });
        yield action.payload.history.push("/platform/tenantManagement");
      } else {
        let errorMessage = res.response.data.errorMessage;
        yield put(PlatformAdminTenantOnboardFail(errorMessage));
      }
    } catch (e) {
      yield put(PlatformAdminTenantOnboardFail(e.message ? e.message : ""));
    }
  } catch (e) {
    yield put(PlatformAdminTenantOnboardFail(e.message ? e.message : ""));
  }
}

function* PlatformAdminTenantOnboardSaga() {
  yield takeEvery(
    PLATFORM_ADMIN_TENANT_ONBOARD_REQUEST,
    PlatformAdminTenantOnboard
  );
}

export default PlatformAdminTenantOnboardSaga;