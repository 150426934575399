// import { createCustomObject_AccessDetails } from "../../components/views/userAccessRights/UserAccessRightsCommonFunctions";
import { isEqual, cloneDeep } from "lodash";

const initialState = {
  currentPage: 0,
  recordsPerPage: 20,
  allServiceDataAccesses: [],
  totalCount:0,
  loader:false,
  dataSourceName: [],
  dataSourceType: [],
  databaseAccessRoles: [],
  applications:[],
  sensitivityInfo: [],
  policyNames: [],
  apiDataSourceName: [],
  apiDataSourceType: [],
  apiDatabaseAccessRoles: [],
  apiDataapplications:[],
  filterInfo: [],
  filterApplied: false,
  sortByValue: "",
  orderByValue: "",
  finalDataSourceName: [],
  finalDataSourceType: [],
  finalDatabaseAccessRoles: [],
  finalapplication:[],
  finalSortByValue: "",
  finalOrderByValue: "",
  copyFilterInfo: [],
  accessDetailsDrawer: false,
  serviceAccessDetailsDrawer:false,
  ServiceAccessDetailsSideDrawerData: {},
  errorMessage: "",
};

export const serviceDataAccessReducer = (state = initialState, action) => {
  switch (action.type) {
     
    case "SET_SERVICE_DATA_ACCESSES_INFO" : {
        return {
            ...state,
            allServiceDataAccesses:action.payload.allServiceDataAccesses,
            totalCount:action.payload.totalCount
        }
    }
    case "SET_SERVICE_DATA_ACCESSES_LOADER_INFO": {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case "SET_SERVICE_DATA_ACCESS_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case "SET_SERVICE_DATA_ACCESS_RECORDS_PAGE": {
      return {
        ...state,
        recordsPerPage: action.payload,
      };
    }
    case "SET_DIRECT_ERROR_MESSAGE" :{
        return {
            ...state,
            errorMessage:action.payload
        }
    }
    case "SERVICE_DATA_ACCESSES_OPEN_ACCESS_DRAWER": {
      return {
        ...state,
        serviceAccessDetailsDrawer: true,
        ServiceAccessDetailsSideDrawerData: action.payload,
      };
    }
    case "CLOSE_ACCESS_DRAWER_SERVICE_DATA_ACCESS": {
      return {
        ...state,
        serviceAccessDetailsDrawer: false,
        ServiceAccessDetailsSideDrawerData: {},
      };
    }  case "DATA_SOURCE_NAME": {
      return {
        ...state,
        dataSourceName: action.payload,
      };
    }
    case "DATA_SOURCE_TYPE": {
      return {
        ...state,
        dataSourceType: action.payload,
      };
    }
    case "DATABASE_ACCESS_ROLES": {
      return {
        ...state,
        databaseAccessRoles: action.payload,
      };
    }
    case "SENSITIVITY_INFO": {
      return {
        ...state,
        sensitivityInfo: action.payload,
      };
    }
    case "POLICY_NAMES": {
      return {
        ...state,
        policyNames: action.payload,
      };
    }
    case "APPLICATIONS":{
      return {
        ...state,
        applications: action.payload,
      }
    }
    case "SERVICE_DATA_ACCESS_DATA_SOURCE_CLEAR_FILTER_DATA": {
      return {
        ...state,
        currentPage: 0,
        loader: true,
        apiDataSourceName: [],
        apiDataSourceType: [],
        apiDatabaseAccessRoles: [],
        apiDatabaseStatusInfo:[],
        apiDataapplications:[],
        filterInfo: [],
        filterApplied: false,
        sortByValue: "",
        orderByValue: "",
        finalDataSourceName: [],
        finalDataSourceType: [],
        finalDatabaseAccessRoles: [],
        finalDatabaseStatusInfo:[],
        finalapplication:[],
        finalSortByValue: "",
        finalOrderByValue: "",
        copyFilterInfo:[],
        fromTimeValue: "",
        toTimeValue: "",
        finalFromTimeValue: "",
        finalToTimeValue: "", 
      };
    }
    case "SET_SERVICE_DATA_ACCESS_APPLY_DATA": {
      return {
        ...state,
        currentPage: 0,
        loader: true,
        filterApplied: true,
        finalDataSourceName: cloneDeep(state.apiDataSourceName),
        finalDataSourceType: cloneDeep(state.apiDataSourceType),
        finalDatabaseAccessRoles: cloneDeep(state.apiDatabaseAccessRoles),
        finalapplication: cloneDeep(state.apiDataapplications),
        finalSortByValue: cloneDeep(state.sortByValue),
        finalOrderByValue: cloneDeep(state.orderByValue),
        copyFilterInfo: cloneDeep(state.filterInfo),
      };
    }
    case "MAINTAIN_DIRECT_DATA_SOURCE_SINGLE_DATA_SERVICE_DATA_ACCESSES": {
      let copyOfData = [...state.filterInfo];
      let present = false;
      for (let i = 0; i < copyOfData.length; i++) {
        if (copyOfData[i].name === action.payload.name) {
          present = true;
          break;
        }
      }
      if (!present) {
        return {
          ...state,
          filterInfo: [...copyOfData, { ...action.payload }],
        };
      } else {
        const temp = copyOfData.filter(
          (each) => each.name !== action.payload.name
        );
        return {
          ...state,
          filterInfo: temp,
        };
      }
    }
    case "SET_FILTER_DATA_SOURCE_NAME_SERVICE_DATA_ACCESS": {
      const copyOfData = [...state.apiDataSourceName];
      if (!state.apiDataSourceName.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDataSourceName: temp,
        };
      }
      return {
        ...state,
        apiDataSourceName: copyOfData,
      };
    }
    case "SET_FILTER_DATA_SOURCE_TYPE_SERVICE_DATA_ACCESS": {
      const copyOfData = [...state.apiDataSourceType];
      if (!state.apiDataSourceType.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDataSourceType: temp,
        };
      }
      return {
        ...state,
        apiDataSourceType: copyOfData,
      };
    }
    case "SET_FILTER_DATABASE_ROLES_SERVICE_DATA_ACCESS": {
      const copyOfData = [...state.apiDatabaseAccessRoles];
      if (!state.apiDatabaseAccessRoles.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDatabaseAccessRoles: temp,
        };
      }
      return {
        ...state,
        apiDatabaseAccessRoles: copyOfData,
      };
    }
    case "SET_FILTER_APPLICATION_SERVICE_DATA_ACCESS": {
      const copyOfData = [...state.apiDataapplications];
      if (!state.apiDataapplications.includes(action.payload)) {
        copyOfData.push(action.payload);
      } else {
        const temp = copyOfData.filter((each) => each !== action.payload);
        return {
          ...state,
          apiDataapplications: temp,
        };
      }
      return {
        ...state,
        apiDataapplications: copyOfData,
      };
    }
    case "DIRECT_DATA_SOURCE_CLEAR_ON_CLOSE_SERVICE_ACCESS_DATA": {
      let apiDataSourceName = state.apiDataSourceName;
      let filterInfo = state.filterInfo;
      let apiDataSourceType = state.apiDataSourceType;
      let apiDatabaseAccessRoles = state.apiDatabaseAccessRoles;
      let sortByValue = state.sortByValue;
      let orderByValue = state.orderByValue;
      let apiDataapplications = state.apiDataapplications
      if (
        !isEqual(
          state.finalDataSourceName.sort(),
          state.apiDataSourceName.sort()
        )
      ) {
        apiDataSourceName = cloneDeep(state.finalDataSourceName);
      }
      if (!isEqual(state.copyFilterInfo, state.filterInfo)) {
        filterInfo = cloneDeep(state.copyFilterInfo);
      }
      if (!isEqual(state.apiDataapplications.sort(), state.finalapplication.sort())) {      
        apiDataapplications = cloneDeep(state.finalapplication);
      }
      if (
        !isEqual(
          state.finalDataSourceType.sort(),
          state.apiDataSourceType.sort()
        )
      ) {
        apiDataSourceType = cloneDeep(state.finalDataSourceType);
      }
      if (
        !isEqual(
          state.finalDatabaseAccessRoles.sort(),
          state.apiDatabaseAccessRoles.sort()
        )
      ) {
        apiDatabaseAccessRoles = cloneDeep(state.finalDatabaseAccessRoles);
      }
      if (state.sortByValue !== state.finalSortByValue) {
        sortByValue = cloneDeep(state.finalSortByValue);
      }
      if (state.orderByValue !== state.finalOrderByValue) {
        orderByValue = cloneDeep(state.finalOrderByValue);
      }
      return {
        ...state,
        apiDataSourceName: apiDataSourceName,
        filterInfo: filterInfo,
        apiDataSourceType: apiDataSourceType,
        apiDatabaseAccessRoles: apiDatabaseAccessRoles,
        apiDataapplications:apiDataapplications,
        sortByValue: sortByValue,
        orderByValue: orderByValue,
      };
    }
    case "SERVICE_DATA_ACCESS_CLEAR_FILTER_DATA": {
      return {
        ...state,
        currentPage: 0,
        loader: true,
        apiDataSourceName: [],
        apiDataSourceType: [],
        apiDatabaseAccessRoles: [],
        apiDataapplications:[],
        filterInfo: [],
        sortByValue: "",
        orderByValue: "",
        finalDataSourceName: [],
        finalDataSourceType: [],
        finalDatabaseAccessRoles: [],
        finalapplication:[],
        finalSortByValue: "",
        finalOrderByValue: "",
        copyFilterInfo: [],
      };
    }
    case "DIRECT_DATA_SOURCE_ORDER_BY_VALUE_SERVICE_DATA_ACCESS": {
      return {
        ...state,
        orderByValue: action.payload,
      };
    }
  
    case "DIRECT_DATA_SOURCE_SORT_BY_VALUE_SERVICE_DATA_ACCESS": {
      return {
        ...state,
        sortByValue: action.payload,
      };
    }
    default:
      return state;
  }
};
