import React, { useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import queryString from 'query-string';
// import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SignUpSuccesful from "../signUpPages/SignUpSuccesful";
import { ApiCall } from "../../../components/views/Util/ApiCall";
import { TENANT_SIGN_UP_URL } from "../../../redux/constants/constants";

const initialState = {
  emailVerificationStatus: false,
  emailVerificationCompleted: false,
  messageToDisplay: "Please wait while we process your Sign Up.....",
};

//defining the Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "VERIFYEMAIL_SUCCESS":
      return {
        ...state,
        emailVerificationStatus: true,
        emailVerificationCompleted: true,
        messageToDisplay:
          "Thanks for verifying your email.We have now initiated your account provisioning process. Once it is completed, you will receive an email containing the instructions on accessing the Data Vision Platform.",
      };
    case "VERIFYEMAIL_FAIL":
      return {
        ...state,
        emailVerificationStatus: false,
        emailVerificationCompleted: true,
        messageToDisplay: action.value,
      };
    default:
      return state;
  }
};

const VerifyEmail = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  // const location = useLocation();
  useEffect(() => {
    const arr = history.location.search
      ? history.location.search.split("&")
      : null;
    const tID = arr ? arr[0].substring(5, arr[0].length) : null;
    const code = arr ? arr[1].substring(5, arr[1].length) : null;
    console.log(tID, code);
    const APICALL = async () => {
      const data = {
        code: code,
      };
      const res = await ApiCall(
        {
          method: "POST",
          url: `${TENANT_SIGN_UP_URL}/${tID}/verifyEmail`,
          // url: "http://421ef6ea2667.ngrok.io/platform/signup",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        },
        "NO_AUTH_REQUIRED"
      );
      // console.log(res);
      if (res && res.status === 200) {
        dispatch({ type: "VERIFYEMAIL_SUCCESS" });
      } else if (res && res.message === "Network Error") {
        dispatch({
          type: "VERIFYEMAIL_FAIL",
          value: "Your Email Verification has failed please Try after sometime",
        });
      } else if (res && res.status !== 200) {
        dispatch({
          type: "VERIFYEMAIL_FAIL",
          value: "Your Email Verification has failed please Try after sometime",
        });
      }
    };

    if (tID && code) {
      APICALL();
    }
  }, [history.location.search]);
  return (
    <div>
      <SignUpSuccesful
        state={state}
        message={state.messageToDisplay}
      ></SignUpSuccesful>
    </div>
  );
};

export default VerifyEmail;
