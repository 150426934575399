import React from "react";
import { renderHeading, renderRiskTypeWithIcon, riskSummaryDataComp } from "./riskPilotHelperComp";
import { useSelector } from "react-redux";
import RiskRegisterThreats from "./RiskRegisterThreats";

function RiskForm({
  isEditMode,
  riskId,
  risk,
  setRisk,
  threats,
  riskSeverity
}) {
  const riskSummaryData = useSelector(
    (state) => state.riskPilotReducer.riskSummaryData
  );
  const allRiskTypes = useSelector((state) => state.riskPilotReducer.riskTypes);
  const renderRiskAndThreat = () => (
    <div className="riskAndThreatContainer riskHeader">
      {isEditMode && (
        <div className="riskRegisterIdContainer">
          <div className="riskRegisterHeading riskId">Risk ID</div>
          <div className="riskPilotID" title={`Risk ID: ${riskId}`}>
            {riskId}
          </div>
        </div>
      )}
      <div className="riskContainer">
        {renderHeading("Risk")}
        <div className={isEditMode ? "riskTypeEdit" : "riskSelect"}>
          {renderRiskTypeWithIcon(allRiskTypes, risk, setRisk, isEditMode)}
        </div>
      </div>
      {threats && Object.keys(threats).length > 0 ? (
        <div className={`riskSeverityContainer ${riskSeverity.code}`}>
          <div className={`riskSeverityValue ${riskSeverity.code}`}>
            {riskSeverity.value}
          </div>
        </div>
      ) : null}
      {isEditMode ? <>{riskSummaryDataComp(riskSummaryData)}</> : null}
    </div>
  );

  return (
    <>
      {renderRiskAndThreat()}
      <RiskRegisterThreats
      />
    </>
  );
}

export default RiskForm;
