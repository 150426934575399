import {
  UPDATE_MDRENDPOINTS_GROUPINGVALUE,
  UPDATE_MDRENDPOINTS_CURRENTINDEX,
  UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA,
  UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA,
  APPLY_ENDPOINTS_FILTER,
} from "../../constants/constants";

export const UPDATE_MDRENDPOINTS_GROUPINGVALUE_ACTION = (payload) => {
  return {
    type: UPDATE_MDRENDPOINTS_GROUPINGVALUE,
    payload: payload,
  };
};

export const UPDATE_MDRENDPOINTS_CURRENTINDEX_ACTION = (payload) => {
  return {
    type: UPDATE_MDRENDPOINTS_CURRENTINDEX,
    payload: payload,
  };
};

export const UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA_ACTION = (payload) => {
  return {
    type: UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA,
    payload: payload,
  };
};

export const UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA_ACTION = (payload) => {
  return {
    type: UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA,
    payload: payload,
  };
};

export const APPLY_ENDPOINTS_FILTER_ACTION = (payload) => {
  return {
    type: APPLY_ENDPOINTS_FILTER,
    payload: payload,
  };
};
