import {} from "../constants/constants";
import {
  ACCESS_DETAILS_FILTERVALUES,
  CHANGE_FILTERBYVALUE_ACCESSDETAILS,
  CHANGE_ACCESSDETAILS_FILTERBYARR,
  ACCESSDETAILS_FILTERBYARR_SELECTEDVALUES,
  ACCESSDETAILS_SORTBYVALUE_UPDATE,
} from "../../../src/redux/constants/constants";

const initialState = {
  // filterValue: ACCESS_DETAILS_FILTERVALUES.DATASOURCE_NAME,:
  filterValue: "",
  sortByValue: "",
  FilterArr: [],
  filterBySelectedValues: [],
  accessDetailsFilterClear: { value: false },
  sortClicked: { isClicked: false, sortDirection: "ASC", currentSortValue: "" },
  anchorel: null,
};

export const AccessDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_FILTERBYVALUE_ACCESSDETAILS: {
      return {
        ...state,
        filterValue: action.payload,
      };
    }
    case CHANGE_ACCESSDETAILS_FILTERBYARR: {
      return {
        ...state,
        FilterArr: action.payload,
      };
    }
    case ACCESSDETAILS_FILTERBYARR_SELECTEDVALUES: {
      return {
        ...state,
        filterBySelectedValues: action.payload,
      };
    }
    case "ACCESSDETAILS_CLEARALL": {
      return {
        ...state,
        sortByValue: "",
        filterValue: "",
        FilterArr: [],
        filterBySelectedValues: [],
        // filterValue: ACCESS_DETAILS_FILTERVALUES.DATASOURCE_NAME,
        accessDetailsFilterClear: { value: true },
      };
    }
    case ACCESSDETAILS_SORTBYVALUE_UPDATE: {
      return {
        ...state,
        sortByValue: action.payload,
      };
    }
    case "ACCESS_DETAILS_HEADERCLICKHANDLER": {
      action.payload = action.payload.split(" ").join("");
      console.log(action.payload);
      switch (action.payload) {
        case "Username": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "username",
            },
          };
        }
        case "DatabaseRole": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "databaseRoleName",
            },
          };
        }
        case "DataSourceName": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "dataSourceName",
            },
          };
        }

        case "HasSensitiveData?": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "sensitiveData",
            },
          };
        }
        case "SensitiveDataAccessed": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "sensitiveDataAccessed",
            },
          };
        }
        case "firstAccessedOn": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "firstAccessedOn",
            },
          };
        }
        case "lastAccessedOn": {
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: "lastAccessedOn",
            },
          };
        }
        default:
          return {
            ...state,
            sortClicked: {
              isClicked: true,
              sortDirection: "ASC",
              currentSortValue: state.sortClicked.currentSortValue,
            },
          };
      }
    }
    case "AccessDetailsDatePopUp": {
      return {
        ...state,
        anchorel: action.payload,
      };
    }
    default:
      return state;
  }
};
