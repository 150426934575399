import { put, takeEvery, select } from 'redux-saga/effects'
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import {
  GET_HEATMAP_DATA, SET_HEATMAP_LOADER, SET_HEATMAP_DETAILS, SET_VIEW_BY_VALUE
} from "../../redux/constants/constants";

const requestRangeMap = {
  "1 Day": "1D",
  "1 Month": "1M",
  "1 Week": "1W"
}
const getHeatmapRange = (state) => state.heatmapReducer.viewBy
const getSelectedNodeId = (state) => state.InspectContentReducer.selectedNodeDetails.node

function* getHeatmapData(action) {
  yield put({
    type: SET_HEATMAP_LOADER,
    payload: true,
  });
  yield* fetchHeatmap(action.payload);
}

function* fetchHeatmap(postData) {
  const duration = postData ? postData : yield select(getHeatmapRange)
  const selectedNodeId = yield select(getSelectedNodeId)
  try {
    const reqRange = requestRangeMap[duration];
    const options = {
      method: "GET",
      url: `${GET_HEATMAP_DATA}/${selectedNodeId}/duration/${reqRange}`,
      headers: getAxiosHeaders(true),
    };
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({
        type: SET_HEATMAP_DETAILS,
        payload: res.data,
      });
      yield put({
        type: SET_HEATMAP_LOADER,
        payload: false,
      });
    } else {
      yield put({
        type: SET_HEATMAP_LOADER,
        payload: false,
      });
    }
  } catch (e) {
      yield put({
      type: SET_HEATMAP_LOADER,
      payload: false,
    });
  }
}

export function* heatmapSaga() {
  yield takeEvery(SET_VIEW_BY_VALUE, getHeatmapData);
}

export default heatmapSaga;