import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../../../src/assets/scss/admin-styles/custom/initialSetUp.scss";
import heartRate from "../../../src/assets/vizr_images/DashedHeartRate.svg";
import {
  GET_AGENT_TYPES,
  GET_ALLPLATFORMS,
  GET_ALL_LICENSES,
} from "../../../src/redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../src/library/RestAPI";
import PlatformInstructions from "../../components/views/DevopsUser/PlatformInstructions";

import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import Button from "@material-ui/core/Button";

import PopUp from "../views/DevopsUser/PopUp";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#706F7E",
    height: "53px",
    width: "310px",
    boxShadow: "inset 0 -1px 0 0 rgba(0,0,0,0.2)",
    "&:hover": {
      backgroundColor: "#706F7E",
      height: "53px",
      width: "310px",
      boxShadow: "inset 0 -1px 0 0 rgba(0,0,0,0.2)",
    },
  },
  rootUnselected: {
    height: "53px",
    width: "310px",
    // boxShadow: "inset 0 -1px 0 0 rgba(0,0,0,0.2)",
    // "&:hover": {
    //   height: "53px",
    //   width: "310px",
    //   boxShadow: "inset 0 -1px 0 0 rgba(0,0,0,0.2)",
    // },
  },

  triangleright: {
    width: "0",
    height: "0",
    borderTop: "26px solid transparent",
    borderLeft: "50px solid #706F7E",
    borderBottom: "26px solid transparent",
    marginRight: "-30rem",
  },
});

export default function InitialSetUpDevops() {
  let history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tenant, setTenant] = useState(localStorage.getItem("tenant"));
  const [agents, setAgents] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [curAgentValue, setCurAgentValue] = useState("");
  const [curAgentId, setcurAgentId] = useState("");
  const [currentInstructions, setcurrentInstructions] = useState([]);
  const [currentPlatform, setCurrentPlatform] = useState("");
  const [insUnmasked, setinsUnmasked] = useState(false);
  const [currentPlatformSelection, setCurrentcurrentPlatformSelection] =
    useState("");

  console.log(currentPlatformSelection);
  useEffect(() => {
    const apiCall = async () => {
      const res = await getService({
        method: "GET",
        url: GET_AGENT_TYPES(tenant),
        headers: getAxiosHeaders(true),
      });
      if (res.status === 200) {
        if (res.data && res.data.content) {
          setAgents(res.data.content);
          setCurAgentValue(res.data.content[0].code);
        }
      }
    };
    apiCall();
  }, [tenant]);

  useEffect(() => {
    const apiCall = async () => {
      const res = await getService({
        method: "GET",
        url: GET_ALLPLATFORMS(tenant, curAgentValue),
        headers: getAxiosHeaders(true),
      });
      if (res.status === 200) {
        console.log(res.data.content);
        if (res.data.content[0]) {
          setPlatforms(res.data.content[0].platformInstructions);
          setcurAgentId(res.data.content[0].id);
        }
        // if (res.data.content[1]) {
        //   setPlatforms(res.data.content[1].platformInstructions);
        //   setcurAgentId(res.data.content[1].id);
        // }
        else {
          setPlatforms(res.data.content);
        }
      }
    };
    apiCall();
  }, [curAgentValue]);

  const agentClickHandler = (event) => {
    setCurAgentValue(event.target.value);
    setcurrentInstructions([]);
    setCurrentcurrentPlatformSelection("");
    setCurrentPlatform("");
    setPlatforms([]);
  };

  const platformSelector = (event, id) => {
    const platform = event.target.textContent;
    // setcurrentInstructions();
    setCurrentPlatform(platform);
    setCurrentcurrentPlatformSelection(id);
    let currentIns;
    platforms.forEach((el) => {
      // console.log(el, platform);
      if (el.platformType === platform) {
        console.log(el.platformType);
        currentIns = el.instructions ? el.instructions : "";
      } else {
        setcurrentInstructions([]);
      }
    });
    console.log(currentIns);
    // const insArr = currentIns
    //   ? currentIns.split("<h3>Attach an agent in a standalone mode</h3>")
    //   : [];

    // console.log(currentIns.split("<a>"));
    // const arr = currentIns.split("<a></a>");
    // console.log(arr[0]);
    const insArr = currentIns ? currentIns.split() : [];
    console.log(insArr);
    console.log("testing", insArr);
    setcurrentInstructions([...insArr]);
  };

  const dashBoardClickHanlder = () => {
    dispatch({ type: "TRACK_TRAVERSAL", payload: "Dashboard" });
    history.push(`/tenant/dashboard`);
  };

  // useEffect(() => {
  //   let apicall = async () => {
  //     const res = await getService({
  //       method: "GET",
  //       url: GET_ALL_LICENSES(),
  //       headers: getAxiosHeaders(false),
  //     });
  //     console.log(res);
  //   };
  //   apicall();
  // });
  return (
    <div className="Devops_initialSetUpContainer">
      <div className="InitialSetup_header">
        <div style={{ width: "90%" }}>
          <h1>Agent installation</h1>
          <p style={{ width: "92%" }}>
            DruvStar Data Vision analyzes the logs and traces from your
            application to discover various services, data sources and abnormal
            data accesses. For that your application needs to be configured
            and/or instrumented to send the necessary logs and traces. This
            configuration depends on the programming language used for
            developing your application and which type of agent Elastic APM or
            Jaeger you want to use. Please select the programming language on
            the left side and the agent type tab.
          </p>
        </div>
      </div>
      <div className="Devops_padding">
        {/* <div
          className={
            insUnmasked
              ? "InitialSetup_contentAgent_Success"
              : "InitialSetup_contentAgent"
          }
        >
          {insUnmasked ? (
            <>
              <div className="marginLeft"> Agent Detected</div>
              <Button
                variant="contained"
                color="primary"
                className="marginRight"
                onClick={dashBoardClickHanlder}
              >
                Go to dashboard
              </Button>
            </>
          ) : (
            <>
              <div className="marginLeft">No Agent Detected</div>
              <img
                src={heartRate}
                className="marginRight"
                alt="heartRate"
              ></img>
            </>
          )}
        </div> */}
        <div className="initialSetUp_AgentSelection">
          {agents.map((el, index) =>
            index === 0 ? (
              <>
                <input
                  defaultChecked="true"
                  type="radio"
                  id={el.code}
                  name="agent"
                  value={el.code}
                  onClick={agentClickHandler}
                  key={index}
                  style={{
                    height: "14px",
                    width: "14px",
                    backgroundColor: "#2947FD",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor={el.code} style={{ marginRight: "18px" }}>
                  {el.value}
                </label>
              </>
            ) : (
              <>
                <input
                  type="radio"
                  id={el.code}
                  name="agent"
                  value={el.code}
                  onClick={agentClickHandler}
                  key={index}
                  style={{
                    height: "14px",
                    width: "14px",
                    backgroundColor: "#2947FD",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor={el.code} style={{ marginRight: "18px" }}>
                  {el.value}
                </label>
              </>
            )
          )}
        </div>

        <div className="initialSetUp_platformSelection">
          <div className="initialSetUp_platformSelection_leftContainer">
            <MenuList>
              <MenuItem
                className={
                  currentPlatform
                    ? "platformWrapper"
                    : "platformWrapperSelected"
                }
              >
                Overview
              </MenuItem>

              {platforms.length > 0 &&
                platforms.map((el, index) => (
                  <div style={{ display: "flex" }}>
                    <MenuItem
                      classes={{
                        root: classes.rootUnselected,
                      }}
                      onClick={(event) => platformSelector(event, index)}
                      value={el.platformType}
                      className={
                        currentPlatformSelection === index
                          ? "platformWrapperSelected"
                          : "platformWrapper"
                      }
                    >
                      {el.platformType}
                    </MenuItem>
                    <div
                      className={
                        currentPlatformSelection === index
                          ? classes.triangleright
                          : ""
                      }
                    ></div>
                  </div>
                ))}
            </MenuList>
          </div>
          {!currentInstructions.length > 0 ? (
            <div className="initialSetUp_platformSelection_rightContainer">
              <div>
                <h2 className="initialSetUp_platformSelection_rightContainer_heading">
                  Select Your platform to see APM Agent Installation
                </h2>
              </div>
            </div>
          ) : (
            <PlatformInstructions
              currentInstructions={currentInstructions}
              curAgentId={curAgentId}
              setcurrentInstructions={setcurrentInstructions}
              insUnmasked={insUnmasked}
            ></PlatformInstructions>
          )}
        </div>
        {currentInstructions.length > 0 ? (
          <div className="devopsInitialSetUp_Popup_Container">
            <PopUp
              currentInstructions={currentInstructions}
              curAgentId={curAgentId}
              setcurrentInstructions={setcurrentInstructions}
              tenant={tenant}
              currentPlatform={currentPlatform}
              platforms={platforms}
              setinsUnmasked={setinsUnmasked}
            ></PopUp>
          </div>
        ) : null}
      </div>
    </div>
  );
}
