import { 
    PLATFORM_ADMIN_TENANT_UPDATE_REQUEST, 
    PLATFORM_ADMIN_TENANT_UPDATE_SUCCESS, 
    PLATFORM_ADMIN_TENANT_UPDATE_FAILED} 
    from '../constants/constants';

export const PlatformAdminTenantUpdate = (value) => {
    console.log(value)
    return {
      type: PLATFORM_ADMIN_TENANT_UPDATE_REQUEST,
      payload: value,
    };
  };
  export const PlatformAdminTenantUpdateSuccess = (value) => {
    return {
      type: PLATFORM_ADMIN_TENANT_UPDATE_SUCCESS,
      payload: value,
    };
  };
  export const PlatformAdminTenantUpdateFail = (value) => {
    return {
      type: PLATFORM_ADMIN_TENANT_UPDATE_FAILED,
      payload: value,
    };
  };