import {
  MDR_SETTINGS_UPDATE_LOCATION,
  MDRIpConfigLoc,
} from "../constants/constants";

const initialState = {
  updatedLocation: sessionStorage.getItem(MDR_SETTINGS_UPDATE_LOCATION)
    ? sessionStorage.getItem(MDR_SETTINGS_UPDATE_LOCATION)
    : MDRIpConfigLoc,
  currentPage: 0,
};

export const mdrSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MDR_SETTINGS_UPDATE_LOCATION: {
      console.log("called", action);
      return {
        ...state,
        updatedLocation: action.payload,
      };
    }
    case "SET_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
};
