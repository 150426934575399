import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Checkbox} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AMAZON_RDS_POSTGRESQL, AMAZON_RDS_SQLSERVER, AZURE_EVENT_HUB, GET_LOG_SOURCE_DETAILS, POSTGRESQL, SQLSERVER } from "../../../redux/constants/constants";
import noLogSourceFound from "../../../assets/icons/noLogSourceFound.svg";
import InfoIcon from "../../../assets/icons/InfoIcon.svg";


function LogSourceSelection({
  staticInstructionsOpen,
  logSourceOpen,
  setSelectedLogSourceId,
  selectedLogMode,
  setSelectedLogMode,
  engine,
  iconMap,
  isConfigurationDone,
  setIsConfigurationDone,
  saveDataSourceFieldData ,
}) {
  const dispatch = useDispatch();
  const logSourceDetails = useSelector((state) => state.CreateSourceReducer.logSourceDetails);
  const [allLogSources, setAllLogSources] = useState([]);
  const [logSourceSelected, setLogSourceSelected] = useState("");
  const [winlogBeatCheck, setWinlogBeatCheck] = useState(false);

  const filterAndSetLogSources = (filterCode) => {
    const filteredLogSources = logSourceDetails.filter(
      (data) => data.sourceType.code === filterCode
    );
    if (filteredLogSources.length > 0) {
      setAllLogSources(filteredLogSources);
    } else {
      setAllLogSources([]);
    }
  };

  useEffect(() => {
    dispatch({ type: GET_LOG_SOURCE_DETAILS });
  }, []);

  useEffect(() => {
    const logSourceDetailsExist =
      logSourceDetails && logSourceDetails.length > 0;

    if (selectedLogMode ==="Pull" && iconMap.MSSQLServer.selected  && logSourceDetailsExist) {
      filterAndSetLogSources(SQLSERVER);
    } else if (iconMap.AzureServer.selected  && logSourceDetailsExist) {
      filterAndSetLogSources(AZURE_EVENT_HUB);
    } else if (iconMap.AWSServer.selected  && logSourceDetailsExist) {
      if (engine === POSTGRESQL) {
        filterAndSetLogSources(AMAZON_RDS_POSTGRESQL)
      } else {
        filterAndSetLogSources(AMAZON_RDS_SQLSERVER);
      }
    }
  }, [selectedLogMode, iconMap]);

  useEffect(() => {
    // Ensure there is an initial selected value
    if (allLogSources.length > 0) {
      setLogSourceSelected(allLogSources[0].id);
      setSelectedLogSourceId(allLogSources[0].id);
      logSourceSelectionDetails({ target: { value: allLogSources[0].id } });
    }
  }, [allLogSources]);

  useEffect(() => {
    if (selectedLogMode === "Push" && winlogBeatCheck) {
      setIsConfigurationDone(true);
    } else if ( selectedLogMode === "Pull" && allLogSources.length > 0) {
      setIsConfigurationDone(true);
    } else {
      setIsConfigurationDone(false);
    }
  },[winlogBeatCheck, allLogSources, selectedLogMode])

  useEffect(() => {
    if (iconMap.AzureServer.selected  || iconMap.AWSServer.selected ) {
      setSelectedLogMode("Pull");
    } else {
      setSelectedLogMode("Push");
    }
  }, [iconMap]);


  const handleWinlogbeatConfig = () => {
    //To save the field data even when you navigate to the winlogbeat screen
    saveDataSourceFieldData ();
    staticInstructionsOpen(true);
  };

  const handleLogSourceOpen = () => {
    //To save the field data even when you navigate to the log Source Addition screen
    saveDataSourceFieldData ();
    logSourceOpen(true);
  }

  const handleConfirmConfiguration = (event) => {
    setWinlogBeatCheck(event.target.checked);
  };

  const logSourceSelectionDetails = (event) => {
    const selectedDataId = event.target.value;
    setLogSourceSelected(selectedDataId);
    setSelectedLogSourceId(selectedDataId);
  };

  const renderMenuItem = () => {
    return allLogSources.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.name}
      </MenuItem>
    ));
  };

  const renderLogSourceDropDown = () => {
    return (
      <div className="logSourceDsContainer">
        <div style={{ padding: "10px 0px", width: "90%" }}>
          <div>Log Source{<span style={{ color: "red" }}>*</span>}</div>
          <FormControl variant="outlined" fullWidth={true}>
            <Select
              value={logSourceSelected}
              onChange={(event) => logSourceSelectionDetails(event)}
            >
              {/* Render menu items */}
              {renderMenuItem()}
            </Select>
          </FormControl>
        </div>
        <div className="logSourceAddButton">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px", textTransform: "none", marginTop: "25px" }}
            onClick={() => handleLogSourceOpen()}
          >
            Add
          </Button>
        </div>
      </div>
    );
  };

  const renderLogSourcePullMode = () => {
    // Check if logSourceDetails is available and not empty
    if (allLogSources && allLogSources.length > 0) {
      // Render log source selection when logSourceDetails is available
      return renderLogSourceDropDown();
    }
    // Render when logSourceDetails is not available or empty
    return (
      <>
        <div className="logSourceContainerHeader">
          Log Source {<span style={{ color: "red" }}>*</span>}
        </div>
        <div className="logSourceContainer">
          <div className="logSourceLogoContainer">
            <img src={noLogSourceFound} />
          </div>
          <div className="logSourceMessageContainer">
            No Log Source available Click below to add
          </div>
          <div className="logSourceAddButton">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px", textTransform: "none" }}
              onClick={() => handleLogSourceOpen()}
            >
              Add
            </Button>
          </div>
        </div>
      </>
    );
  };
  
  const renderLabelText = () => {
    if (engine === POSTGRESQL) {
      return "Please accept if Filebeat configuration is done";
    } else {
      return "Please accept if Winlogbeat configuration is done";
    }
  };

  const logConfigurationMode = () => {
    // Render log configuration for Push mode and Azure Server not selected 
    if (selectedLogMode === "Push" && !iconMap.AzureServer.selected ) {
      return (
        <div
          className={
            isConfigurationDone
              ? "logConfigurationDoneContainer logSourceConfigDone"
              : "logConfigurationDoneContainer"
          }
        >
          <FormControlLabel
            className="confirmContainer"
            control={
              <Checkbox
                color="primary"
                checked={isConfigurationDone}
                onChange={handleConfirmConfiguration}
              />
            }
            label={renderLabelText()}
            labelPlacement="end"
            style={{ fontSize: "14px" }}
          />
          <div className="WinlogbeatContainer">
            <img src={InfoIcon} alt="info icon" />
            <div
              className="winlogbeatText"
              onClick={() => handleWinlogbeatConfig()}
            >
              {engine === POSTGRESQL ? "Link To Filebeat" : "Link To Winlogbeat"}
            </div>
          </div>
        </div>
      );
    }
    // Render log configuration for Pull mode or Azure Server selected 
    else if (selectedLogMode === "Pull" || iconMap.AzureServer.selected ) {
      return renderLogSourcePullMode();
    }
  };

  // Function to render log source configuration methods
  const renderLoSourceMethods = () => {
    if (iconMap.MSSQLServer.selected && engine !== POSTGRESQL) {
      const options = ["Push", "Pull"];
      return (
        <>
          <div>
            Log Configuration Mode {<span style={{ color: "red" }}>*</span>}
          </div>
          <div className="logConfigurationContainer">
            {options.map((opt, index) => (
              <label key={index} className="IconTitle">
                <input
                  type="radio"
                  value={opt}
                  checked={selectedLogMode === opt}
                  onChange={() => setSelectedLogMode(opt)}
                  style={{ transform: "scale(1.5)" }}
                />
                {opt}
              </label>
            ))}
          </div>
        </>
      );
    } 
    else {
      return null;
    }
  };

  return (
    <>
      {/* Render log source methods */}
      {renderLoSourceMethods()}
      {/* Render log configuration mode */}
      {logConfigurationMode()}
    </>
  );
}

export default LogSourceSelection;
