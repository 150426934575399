import {
  // SIGNUPPAGE_USERNAME,
  SIGNUPPAGE_FIRSTNAME,
  SIGNUPPAGE_LASTNAME,
  SIGNUPPAGE_PHONE,
  SIGNUPPAGE_ADMINNAME,
  SIGNUPPAGE_ADMINEMAIL,
  SIGNUPPAGE_TENENTNAME,
  SIGNUPPAGE_DOMAIN,
  SIGNUPPAGE_MONITOR,
  SIGNUPPAGE_CONTROL,
  SIGNUPPAGE_SUCCESSFUL,
  SIGNUPPAGE_FAILED,
  SIGNUPPAGE_RESET,
  SIGNUPPAGE_SELFSUBMIT,
} from "../constants/signuppageActionTypes";

// export const updateSignUpPageUsername = (value = "") => {
//   return {
//     type: SIGNUPPAGE_USERNAME,
//     payload: value,
//   };
// };
export const updateSignUpPageFirstname = (value = "") => {
  return {
    type: SIGNUPPAGE_FIRSTNAME,
    payload: value,
  };
};

export const updateSignUpPageLastname = (value = "") => {
  return {
    type: SIGNUPPAGE_LASTNAME,
    payload: value,
  };
};
export const updateSignUpPagePhone = (value = "") => {
  return {
    type: SIGNUPPAGE_PHONE,
    payload: value,
  };
};
export const updateSignUpPageAdminName = (value = "") => {
  return {
    type: SIGNUPPAGE_ADMINNAME,
    payload: value,
  };
};

export const updateSignUpPageAdminEmail = (value = "") => {
  return {
    type: SIGNUPPAGE_ADMINEMAIL,
    payload: value,
  };
};

export const updateSignUpPageTenentName = (value = "") => {
  return {
    type: SIGNUPPAGE_TENENTNAME,
    payload: value,
  };
};

export const updateSignUpPageDomain = (value = "") => {
  return {
    type: SIGNUPPAGE_DOMAIN,
    payload: value,
  };
};
export const updateSignUpPageMonitor = () => {
  return {
    type: SIGNUPPAGE_MONITOR,
  };
};

export const updateSignUpPageControl = () => {
  return {
    type: SIGNUPPAGE_CONTROL,
  };
};
export const selfSignUpSubmit = (value) => {
  return {
    type: SIGNUPPAGE_SELFSUBMIT,
    payload: value,
  };
};

export const SignUpPage_Successful = () => {
  return {
    type: SIGNUPPAGE_SUCCESSFUL,
  };
};

export const SignUpPage_Failed = (value) => {
  return {
    type: SIGNUPPAGE_FAILED,
    payload: value,
  };
};

export const SIGNUPPAGE_Reset = () => {
  return {
    type: SIGNUPPAGE_RESET,
  };
};
