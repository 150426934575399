import React, { useEffect, useState } from "react";
import { EnhancedDataSourceHeader } from "./EnhancedDataSourceHeader";
import EnhancedGridTable from "../../shared/Table/Table";
import { DataSourceStatus } from "./AccessRightsutilities";
import { getStatusHistory } from "../../../redux/actions/getStatusHistoryAction";
import page_loader from "../../../assets/vizr_images/page_loader.gif";

export default function DataSourceStatusData(props) {
  const { popUpData } = props;
  const [historyData, setHistoryInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   if (
  //     popUpData
  //   ) {
  //     getHistoryInfo();
  //     setLoader(true);
  //   }
  //   return () => {
  //     setHistoryInfo([]);
  //   };
  // }, [popUpData.id]);

  useEffect(() => {
    getHistoryInfo();
    setLoader(true);
  }, [currentPage, recordsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const getHistoryInfo = () => {
    async function fetchInfo(id) {
      const response = await getStatusHistory(popUpData.id, popUpData.dataSourceId, recordsPerPage,currentPage);
      if (response) {
        setHistoryInfo(response.content);
        setTotalCount(response.totalElements);
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
    fetchInfo(popUpData);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {loader && (
        <img
          src={page_loader}
          alt="loading"
          style={{
            position: "absolute",
            marginLeft: "350px",
            top: "120px",
            zIndex: 5,
          }}
        ></img>
      )}
      <EnhancedDataSourceHeader
        totalElements={""}
        loc={"flaggedDataSource"}
        alertsTitle={"Related Alerts"}
        selectedRows={[]}
        rows={historyData}
        hideAction={true}
        title={"Data Source Details"}
      />
      <EnhancedGridTable
        cols={DataSourceStatus(popUpData)}
        data={historyData}
        totalCount={totalCount}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
      />
    </div>
  );
}
