import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import Toggler from "../../shared/Toggler";
import { DataMapRoute } from "../../common/DatamapImage";
import { useHistory } from "react-router-dom";
import { CLEAR_REPORT_DATA, fetchReport, generateReport, viewDataSource } from '../../../redux/constants/constants';
import ReportRepositoryTable from './ReportRepositoryTable';

function ReportRepository() {
  const history = useHistory();
  const dispatch = useDispatch();
  // Add permission param for each toggler as we are rendering based on permission
  const [togglers, setTogglers] = useState([
    { name: "Data Owner View", state: "inactive", permission: viewDataSource },
    { name: "System Summary", state: "inactive", permission: viewDataSource},
    { name: "Generate Report", state: "inactive", permission: generateReport},
    { name: "Report Repository", state: "active", permission: fetchReport }
  ]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_REPORT_DATA })
    }
  }, [])

  // Function to render the header
  const renderHeader = () => {
    return (
      <div className="inspect-title-header reportRepositoryHeader">
        <div className="dataOwnerSummaryHeader">Previously Generated Reports</div>
      </div>
    );
  };

  const toggleRoutes = {
    "Data Owner View": "/tenant/dataOwner",
    "System Summary": "/tenant/systemSummary",
    "Activity Report": "/tenant/activityReport",
    "Generate Report": "/tenant/generateReport",
    "Report Repository": "/tenant/reportRepository",
  };

  const handleToggle = (name) => {
    history.push(toggleRoutes[name]);
  };


  const renderReportRepository = () => {
    return (
      <div className="main">
        <div className="description">
          <div className="generateReportHeadingContainer">
            <h2>Activity Report</h2>
            <p>
              Reports of important events
            </p>
          </div>
          <DataMapRoute />
        </div>
        <div className="togglerandSearch_Conatiner">
          <Toggler
            togglers={togglers}
            setTogglers={setTogglers}
            loc="summary"
            history={history}
            handleToggle={handleToggle}
            permissionBased
          ></Toggler>
        </div>
        <div className="fs-file-list-container">
          {renderHeader()}
          <ReportRepositoryTable />
        </div>
      </div>
    )
  }
  return (
    <>
      {renderReportRepository()}
    </>
  )
}

export default ReportRepository