import { DeleteButtonForToolBar } from "../../common/buttons";
import DeleteIcon from "@material-ui/icons/Delete";

export const ViewMdrConcentratorDetailsConstants = (
  _,
  id,
  deleteConcentratorApi
) => {
  return [
    {
      title: "Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: "Host",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.host}</span>;
      },
    },
    {
      title: "Port",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.port}</span>;
      },
    },
    {
      title: "Username",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.userName}</span>;
      },
    },
    {
      title: "",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return (
          <span>
            {" "}
            {
              <DeleteButtonForToolBar
                variant="contained"
                id="delete"
                size="small"
                color="primary"
                onClick={() => {
                  deleteConcentratorApi(rowData.id);
                }}
                className="min-margin"
              >
                <DeleteIcon />
              </DeleteButtonForToolBar>
            }
          </span>
        );
      },
    },
  ];
};

export const ViewMdrIpDetailsConstants = (_, id, deleteIpApi) => {
  return [
    {
      title: "Ip",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.ip}</span>;
      },
    },
    {
      title: "Subnet",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.subnet}</span>;
      },
    },
    {
      title: "",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return (
          <span>
            {" "}
            {
              <DeleteButtonForToolBar
                variant="contained"
                id="delete"
                size="small"
                color="primary"
                onClick={() => {
                  deleteIpApi(rowData.id);
                }}
                className="min-margin"
              >
                <DeleteIcon />
              </DeleteButtonForToolBar>
            }
          </span>
        );
      },
    },
  ];
};

///////////////////////////////////////

export const ConcentratorKeys = {
  AUTH_ERROR_COND: "Auth Errors Where Condition",
  EVENT_COUNT_COND: "Event Counts Where Condition"
};

export const ConcentratorConfigFields = [
  "Name",
  "Host",
  "Port",
  "Username",
  "Password",
  ConcentratorKeys.AUTH_ERROR_COND,
  ConcentratorKeys.EVENT_COUNT_COND,
];

export const ConcentratorConfigFieldsState = {
  name: "concentrator",
  host: "",
  port: "",
  userName: "",
  password: "",
  authErrorsWhereCondition: "",
  eventCountsWhereCondition: "",
};

export const ConcentratorErrorFieldsState = {
  name: "",
  host: "",
  port: "",
  userName: "",
  password: "",
  authErrorsWhereCondition: "",
  eventCountsWhereCondition: "",
};
export const ConcentratorConfigFieldsArray = [
  "name",
  "host",
  "port",
  "userName",
  "password",
  "authErrorsWhereCondition",
  "eventCountsWhereCondition",
];

export const IpConfigFields = ["IP", "Subnet"];

export const IpConfigFieldsState = {
  ip: "",
  subnet: "",
};
export const IpConfigFieldsArray = ["ip", "subnet"];

///////////////////////////////////////////////////////////////////////

export const EndpointConcentratorFields = [
  "Name",
  "Host",
  "Port",
  "Username",
  "Password",
  "Environment",
];

export const EndpointConcentratorFieldsState = {
  name: "mongo-endpoint",
  host: "",
  port: "",
  userName: "",
  password: "",
  environment: "",
};

export const EndpointErrorFieldsState = {
  name: "",
  host: "",
  port: "",
  userName: "",
  password: "",
  environment: "",
};
export const EndpointConcentratorFieldsArray = [
  "name",
  "host",
  "port",
  "userName",
  "password",
  "environment",
];

export const findDuplicatesHostAndPort = (state, errorsArr) => {
  //Finding Duplicate Host,Port combination to display error in the UI
  const map = {};
  let hasDuplicateHostOrPort = false;
  state.forEach((obj, i) => {
    if (map.hasOwnProperty(obj.host + ":" + obj.port)) {
      errorsArr[i]["host"] = "Host is duplicated";
      errorsArr[i]["port"] = "Port is duplicated";
      hasDuplicateHostOrPort = true;
    } else {
      errorsArr[i]["host"] = "";
      errorsArr[i]["port"] = "";
    }
    map[obj.host + ":" + obj.port] = true;
  });
  return hasDuplicateHostOrPort;
};
