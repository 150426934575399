import { UPDATE_POLICY_REQUEST } from '../constants/constants';

// export async function updateAlertPolicy(data,id) {
//     const options = {
//         method: "PUT",
//         url: `${UPDATE_ALERT_POLICY_URL}/${id}`,
//         headers: getAxiosHeaders(true),
//         data: data
//     };

//     const response = await getService(options);
//     if(response) {
//         return response.data
//     }
// };

export const updateAlertPolicy = (payload) => {
    return {
      type: UPDATE_POLICY_REQUEST,
      payload: payload,
    };
  };
