import { MDREndpointsQuery } from "../../components/common/Dashboard/dashboard-data";
import {
  UPDATE_MDRENDPOINTS_GROUPINGVALUE,
  UPDATE_MDRENDPOINTS_CURRENTINDEX,
} from "../constants/constants";

let initialState = {
  endPointsCurrentGraphActiveValue: 0,
  endPointGroupingValue: "Today",
  totalRowsEndpoints: 0,
  endPointsCurrentTogglerValue: "Agents-LastSeen",
  endPointDrawer: false,
  endPointSideDrawerData: {},
};
export const MDREndPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MDRENDPOINTS_GROUPINGVALUE: {
      return {
        ...state,
        endPointGroupingValue: action.payload,
      };
    }
    case UPDATE_MDRENDPOINTS_CURRENTINDEX: {
      return {
        ...state,
        endPointsCurrentGraphActiveValue: action.payload,
      };
    }
    case "UPDATE_CURRENT_TOGGLER_VALUE": {
      return {
        ...state,
        endPointsCurrentTogglerValue: action.payload,
      };
    }
    case "CLOSE_END_POINT_DRAWER": {
      return {
        ...state,
        endPointDrawer: false,
        endPointSideDrawerData: {},
      };
    }
    case "END_POINT_DRAWER_DATA": {
      return {
        ...state,
        endPointDrawer: true,
        endPointSideDrawerData: action.payload,
      };
    }

    default:
      return state;
  }
};
