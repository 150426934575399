import { CustomSwitch } from "../../common/utilities";
import { isEmpty } from "lodash";
import { isDataSourceActive } from "../../common/utilities";
import { reviewDataSource } from "../../../redux/constants/constants";
import { DeleteButtonForToolBar } from "../../common/buttons";
import DeleteIcon from "../../../assets/vizr_images/ic_rubbish_bin.png";

export const ViewLogSourceConstants = (handleLogSourceStatus, handleLogSourceDelete) => {
  const renderActiveStatus = (row, handleLogSourceStatus) => {
    if (!isEmpty(row.status) && !isEmpty(row.status.value)) {
      return (
        <>
          <CustomSwitch
            checked={isDataSourceActive(row.status.value)}
            onChange={(event) => handleLogSourceStatus(event, row)}
            title={row.status.value}
          />
        </>
      );
    } else {
      return <>-</>;
    }
  };
  const renderAction = (row, handleLogSourceDelete) => {
    const disable = !isEmpty(row.status) && isDataSourceActive(row.status.value);
    if (!isEmpty(row.name)) {
      return (
        <>
          <DeleteButtonForToolBar
            variant="contained"
            id="delete"
            size="small"
            color="primary"
            disabled={disable}
            onClick={() => handleLogSourceDelete(row.name, row.id)}
            className="min-margin table-buttons"
          >
            <img className="deleteIconImg" src={DeleteIcon} alt="delete" />
          </DeleteButtonForToolBar>
        </>
      );
    }
  };
  return [
    {
      title: "Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: "Source Type",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return (
          <span>{(rowData.sourceType && rowData.sourceType.value) || ""}</span>
        );
      },
    },
    {
      title: "Data Source Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.databaseName}</span>;
      },
    },
    {
      title: "Host",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.host}</span>;
      },
    },
    {
      title: "Port",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.port}</span>;
      },
    },

    {
      title: "Data Location",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.dataLocation}</span>;
      },
    },
    {
      title: "Status",
      style: { width: "5%" },
      rowBodyCellStyle: { width: "5%" },
      render: (rowData) => {
        return <span>{renderActiveStatus(rowData, handleLogSourceStatus)}</span>;
        ;
      },
      requiredPermission: reviewDataSource,
    },
    {
      title: "Action",
      style: { width: "5%" },
      rowBodyCellStyle: { width: "5%" },
      render: (rowData) => {
        return <span>{renderAction(rowData, handleLogSourceDelete)} </span>;
        ;
      },
      requiredPermission: reviewDataSource,
    }
  ];
};
