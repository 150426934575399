import React from 'react';
import { Link } from 'react-router-dom';
import ChartWrapper from '../Chart';
import safeIcon from '../../../../assets/icons/safeIcon.png';
import needsAttentionIcon from '../../../../assets/icons/needsAttentionIcon.png';
import { QueryRenderer } from '@cubejs-client/react';
import { renderNoData, renderSingleValue } from '../utils';

export function fileSummary(cubejsApi, name, query) {
  const chartQuery = query[1];
  const countQuery = query[0];
  const renderLegends = (resultSet) => {
    const data = resultSet.loadResponse.results[0].data[0];
    if (!data || data.length === 0) {
      return renderNoData('30%');
    }
    let legends = [];
    const categories = [
      {
        title: 'Needs Attention',
        cubeParam: 'Assets.needsAttentionCount',
        icon: needsAttentionIcon,
      },
      {
        title: 'Safe',
        cubeParam: 'Assets.safeCount',
        icon: safeIcon,
      },
    ];
    categories.forEach((item) => {
      const pDataItem = {
        text: item.title,
        count: renderSingleValue(parseInt(data[item.cubeParam])),
        icon: item.icon,
      };
      legends.push(pDataItem);
    });
    return (
      <React.Fragment>
        <div className='widget-header2'>
          <span className='span-total-count'>
            <QueryRenderer
              query={countQuery}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) {
                  return <div className='loader' />;
                }
                return renderSingleValue(resultSet, countQuery.measures[0]);
              }}
            />
          </span>
        </div>
        <div className='asset-legend-container'>
          {legends.map((row) => {
            return (
              <div>
                <hr className='separatorStyle' />
                <div className='asset-legend'>
                  <div className='asset-legend-img-count'>
                    <img
                      className='asset-legend-icon'
                      src={row.icon}
                      alt='icon'
                    />
                    <span className='asset-legend-count'>
                      {row.count ? row.count : 0}
                    </span>
                  </div>
                  <div className='asset-legend-text'>
                    {row.text ? row.text : '-'}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={chartQuery}
      render={renderLegends}
      redirectTo={"/tenant/dataMap"}
    />
  );
}
