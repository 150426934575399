import React, { useState, useEffect } from "react";
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import "./oneDriveInspectContentsStyles.scss";
import { isEmpty, isNull } from "lodash";
import SensitivityBadge from "./Badge/SensitivityBadge";
import AlertBadge from "./Badge/AlertBadge";
import { categoryIcons, icons, renderSmallDonut } from "../common/utilities";
import ImageTooltip from "../common/HelpIconWithTooltip";
import { SET_DATA_OWNER_PAGE_SIZE,
  SET_DATA_OWNER_CURRENT_PAGE,GET_DATA_OWNER_DATA_FIELDS_REQUEST,SET_ALL_NODE_DETAILS, DataOwnerAsOnDesc } from "../../redux/constants/constants";

export default function DataOwnerContentTable({ loader }) {
  const dispatch = useDispatch();
  const history = useHistory();
const DataOwnerFieldsData = useSelector(
    (state) => state.DataOwnerReducer.DataOwnerFieldsData
  );
  const totalCount = useSelector(
    (state) => state.DataOwnerReducer.totalDataSourcesCount
  );
const currentPage = useSelector(
    (state) => state.DataOwnerReducer.currentPage
  );
  const pageSize = useSelector(
    (state) => state.DataOwnerReducer.pageSize
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [rowData, setRowData] = useState([]);
  
  // Define the headers object
  const headers = {
    dataSource: "Data Source",
    added: "Added",
    sensitivity: "Sensitivity",
    alerts: "Alerts",
    categories: "Categories",
    flaggedUsers: "Users with Alerts",
    activities: "Activities",
    asOn: "As Of"
  };
  
  useEffect(() => {
    setRowData(DataOwnerFieldsData);
  }, [DataOwnerFieldsData]);

useEffect(() => {
    dispatch({
      type: GET_DATA_OWNER_DATA_FIELDS_REQUEST,
    });
    setPaginationModel(prevState => ({
      ...prevState,
      page: currentPage,
      pageSize: pageSize,
    }));
  },[currentPage, pageSize]);

  useEffect(() => {
    setPaginationModel({ page: 0, pageSize: 20 });
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_DATA_OWNER_CURRENT_PAGE,
      payload: paginationModel.page
    });
    dispatch({
      type: SET_DATA_OWNER_PAGE_SIZE,
      payload: paginationModel.pageSize
    });
  }, [paginationModel]);

  const handleFullScreenView = (data) => {
    const subtype = {
      code: data.row.dataSource.type,
      type: {
        code: data.row.nodeType,
      },
      value: data.row.dataSource.type
    }
    dispatch({
      type: SET_ALL_NODE_DETAILS,
      payload: { node: data.row.nodeId, subtype: subtype.code, name: data.row.dataSource.name, creationTime: data.row.added, sourceName: subtype },
    });
    history.push(`/tenant/fileList`)
  }

  

  const handlePaginationChange = (params) => {
    const newPagination = {
      pageSize: params.pageSize,
      page: params.page,
    };
    if (paginationModel.pageSize !== newPagination.pageSize) {
      newPagination.page = 0;
    }
    setPaginationModel(newPagination);
  };

  const renderNull = () => {
    return (<>-</>);
  };

  const renderAlerts = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    } else {
      return (
        <div className="inspect-alerts dataOwner">
          <AlertBadge parentprops={params.value} InspectContent={true} />
        </div>
      );
    }
  };

  const renderDataSource = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    } else {
      const icon = icons[params.value.type];
      return (
        <div className="inspect-file-header dataOwner">
          <div className="inspect-file-fieldName dataOwner" title={params.value.name}>
            {params.value.name}
          </div>
          <div title={params.value.type}><img src={icon} alt="icon" className={`dataOwner-icons ${params.value.type}`} /></div>
        </div>
      );
    }
  };

  const renderSensitivity = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    } else {
      return (
        <div className="dataOwnerSensitivity">
          <div className="inspect-alerts dataOwner">
            <SensitivityBadge parentprops={params.value} loc="DataOwnerTable" />
          </div>
        </div>
      );
    }
  };

  const renderDonut = (values) => {
    const pieData = [
      {
        color: "#FF2251",
        text: "High",
        count: values.user_high_alert,
        icon: "/dataOwnerViewIcons/userIcons/highUser.svg"
      },
      {
        color: "#FF8421",
        text: "Medium",
        count: values.user_medium_alert,
        icon: "/dataOwnerViewIcons/userIcons/mediumUser.svg"
      },
      {
        color: "#081981",
        text: "Low",
        count: values.user_low_alert,
        icon: "/dataOwnerViewIcons/userIcons/lowUser.svg"
      }
    ];
    return (
      <div className="dataOwnerPieLegend">
        {renderSmallDonut(pieData)}
        <div className="pieDataOwner">
          {pieData.map((e) => {
            return (
              <div className="dataOwnerCount">
                <div title={e.text}><img src={e.icon}/></div>
                <div className="dataOwnerPieCount" style={{color: e.color}} title={e.count}>{e.count}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderAddedOn = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    } else {
      const dateAndTime = params.value.split(" ");
      return (
        <div className="dataOwnerDate">
          <div>{dateAndTime[0]}</div>
          <div>{dateAndTime[1]}</div>
        </div>
      );
    }
  };

  const renderIcons = (categoryNames) => {
    if (!isEmpty(categoryNames)) {
      return categoryNames.slice(0, 3).map(categoryName => {
        const icon = categoryIcons[categoryName];
        if (icon) {
          return (
            <img
              src={icon}
              alt={categoryName}
              title={categoryName}
              className="oneDriveIconImage"
            />
          );
        }
      });
    } else {
      return renderNull();
    }
  };

  const imageCount = (count) => {
    if (count > 0) {
      return (
        <div className="circle">
          <span className="circleText" title={count}>{`+${count}`}</span>
        </div>
      );
    } else {
      return "";
    }
  };

  const renderCatagories = (params) => {
    let totalCategory = params.value.totalCategory;
    // Check if totalCategory is null or less than 0
    if (isNull(totalCategory) || totalCategory < 0) {
      totalCategory = 0;
    }
    if (isEmpty(params.value.categoryNames)) {
      return renderNull();
    } else {
      let imagesRenderCount = 0;
      const categoryNames = params.value.categoryNames.split(", ");
      if (totalCategory > 3) {
        imagesRenderCount = totalCategory - 3;
      }
      return (
        <div className="oneDriveCategoryContainer">
          <div className="oneDriveCategoryIcons dataOwner">
            {renderIcons(categoryNames)}
          </div>
          {imageCount(imagesRenderCount)}
        </div>
      );
    }
  };
  // Function to render user icon based on whether there are users with alerts or not
  const userIcon = (noUsersWithAlerts) => {
    if (noUsersWithAlerts) {
      return (
        <img
          src="/dataOwnerViewIcons/userIcons/noUsersWithAlerts.svg"
          alt="noUserIcon"
          title="No Users with Alerts"
        />
      );
    } else {
      return (
        <img
          src="/dataOwnerViewIcons/userIcons/noUser.svg"
          alt="userIcon"
          title="Unique Users"
        />
      );
    }
  };

  const renderTotalAlerts = (params) => {
    const noUsersWithAlerts = params.value.user_total_count === 0;
    return (
      <div className="dataOwner-table-totalAlerts">
        <div className="dataOwnerTotalIcon">
          <div className="noUsersWithAlerts">{userIcon(noUsersWithAlerts)}</div>
          <div className="dataOwner-table-count" title={params.value.unique_user_total_count}>{params.value.unique_user_total_count}</div>
        </div>
        <div className="inspect-alerts dataOwner">
          {/* Render donut chart based on whether there are users with alerts */}
          {noUsersWithAlerts ? renderSmallDonut([]) : renderDonut(params.value)}
        </div>
      </div>
    );
  };

  const renderActivities = (params) => {
    return (
      <div style={{fontFamily: "Nunito-Regular", fontWeight: 700}}>{params.value}</div>
    )
  }

  const customNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>No Results</div>
      </GridOverlay>
    );
  }

  const renderAsOn = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    } else {
      const dateAndTime = params.value.split(" ");
      return (
        <div className="dataOwnerDate">
          <div>{dateAndTime[0]}</div>
          <div>{dateAndTime[1]}</div>
        </div>
      );
    }
  };

  const columns = [
    {
      field: "dataSource",
      flex: 0.5,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: renderDataSource
    },
    {
      field: "added",
      flex: 0.3,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      checkboxSelection: false,
      renderCell: renderAddedOn
    },
    {
      field: "sensitiveData",
      flex: 0.65,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: renderSensitivity
    },
    {
      field: "alertSegregation",
      flex: 0.4,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: renderAlerts
    },
    {
      field: "categories",
      flex: 0.45,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: renderCatagories
    },
    {
      field: "usersWithAlerts",
      flex: 0.43,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: renderTotalAlerts
    },
    {
      field: "activities",
      flex: 0.2,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: renderActivities
    },
    {
      field: "asOn",
      flex: 0.25,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: renderAsOn
    }
  ];

  const renderTable = () => {
    return (
      <div className="custom-data-grid-main">
        <div className="main-heading-container">
          {Object.entries(headers).map(([classKey, name]) => (
            <h4 key={classKey} className={`main-header-name ${classKey}`}>
              {name}
            </h4>
          ))}
          <div className="DataOwnerHelpIcon">
            <ImageTooltip
              description={DataOwnerAsOnDesc}
            />
          </div>
        </div>
        <DataGrid
          className="custom-data-grid"
          rows={rowData}
          columns={columns}
          rowCount={totalCount}
          columnHeaderHeight={0}
          getRowId={(row) => row.nodeId}
          rowHeight={90}
          components={{ NoRowsOverlay: customNoRowsOverlay }}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          showCellRightBorder={false}
          showColumnRightBorder={false}
          showCellVerticalBorder={true}
          disableColumnFilter={true}
          disableColumnSelector
          disableColumnMenu
          paginationMode="server"
          hideFooterSelectedRowCount={true}
          getRowSpacing={(params) => ({
            top: 10,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          onRowClick={handleFullScreenView}
        />
      </div>
    );
  }

  return (
    <>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source dataOwner-Table"
        ></img>
      )}
      {renderTable()}
    </>
  );
};