import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import Button from '@material-ui/core/button';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { AutoTriggerSlider } from "../common/buttons";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { NON_WORK_HOUR_POLICY, TOLERANCE_LEVEL_INFO } from "../../redux/constants/constants";
import lowToleranceLevel from "../../assets/icons/toleranceLevelIcons/lowTolerance.svg";
import highToleranceLevel from "../../assets/icons/toleranceLevelIcons/highTolerance.svg";
import mediumToleranceLevel from "../../assets/icons/toleranceLevelIcons/mediumTolerance.svg";
import { isEmpty } from "lodash";
import { thresholdValue } from "../views/alertPolicies/alertPolicyUtilities";
import { isInactivityPolicy } from "../common/utilities";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: (props) => {
      // Use toleranceLevel prop to determine tooltip text color
      if (props.toleranceLevel === 'LOW') {
        return '#162660';
      } else if (props.toleranceLevel === 'MEDIUM') {
        return '#ED8A43';
      } else if (props.toleranceLevel === 'HIGH') {
        return '#FA0639';
      }
      return '#162660';
    },
    border: (props) => {
      // Use toleranceLevel prop to determine tooltip text border
      if (props.toleranceLevel === 'LOW') {
        return '1px solid #162660';
      } else if (props.toleranceLevel === 'MEDIUM') {
        return '1px solid #ED8A43';
      } else if (props.toleranceLevel === 'HIGH') {
        return '1px solid #FA0639';
      }
      return '#162660';
    },
    fontSize: '12px',
    fontWeight: "600",
    lineHeight: '16px',
    borderRadius: '5px',
    padding: '6px 8px',
    wordBreak: 'break-all',
  },
  arrow: {
    color: '#fff',
  },
}))(Tooltip);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

export default function AutoTriggerComponent(props) {
  const {
    autoConfidenceInterval,
    handleAutoConfidenceInterval,
    autoTracesValue,
    handleAutoTracesValue,
    toleranceLevelCode,
    editStatus,
    policyType,
    policySubTypeValue,
    handleToleranceLevelCode,
    severity,
  } = props;
  const unsuccessfulCreateEdit = useSelector(
    (state) => state.policyCreated.unsuccessful
  );
  const [toleranceLevel, setToleranceLevel] = useState("LOW");

  useEffect(() => {
    if (!isEmpty(toleranceLevelCode)) {
      setToleranceLevel(toleranceLevelCode);
    }
  }, [toleranceLevelCode]);

  const handleSliderChange = (event, newValue) => {
    handleAutoConfidenceInterval(newValue);
  };

  const valueEditTraces = (e) => {
    const value = parseInt(e.target.value);
    handleAutoTracesValue(value);
  };

  // Function to handle tolerance level selection
  const handleToleranceLevel = (value) => {
    if (editStatus) {
      // Call a function to handle tolerance level code
      handleToleranceLevelCode(value);

      // Set the tolerance level state to the selected value
      setToleranceLevel(value);
    }
  };

  const renderNoOfTraces = () => {
    return (
      <>
        {editStatus ? (
          <div>
            <Divider
            className="divider"
            />
            <p className="header-font">
              {policyType && policyType.code === "DIRECT_DATABASE_ACCESS"
                ? `Min # of Unusual Accesses`
                : `Min # of Traces`}
            </p>
            <input
              value={autoTracesValue}
              type="number"
              onChange={(event) => valueEditTraces(event)}
              min={0}
              className="min-margin thresholdInput"
            />
          </div>
        ) : (
          <div>
            <p className="header-font">
              {policyType && policyType.code === "DIRECT_DATABASE_ACCESS"
                ? `Min # of Unusual Accesses:`
                : `Min # of Traces:`}
              &nbsp;
              <span
                className="min-traces-value"
              >
                {autoTracesValue}
              </span>
            </p>
          </div>
        )}
      </>
    );
  };

  // Renders the tolerance level text based on the current tolerance level state
  const renderToleranceLevel = () => {
    return (
      <div className={`toleranceLevelTextContainer ${toleranceLevel}Tolerance`}>
        {toleranceLevel}
      </div>
    );
  };

  // Renders the tolerance level images along with their tooltips
  const renderToleranceLevelImages = () => {
    // Data for tolerance level images
    const imageData = {
      LowTL: {
        name: "LOW",
        image: lowToleranceLevel,
        text: "Low Tolerance Level",
        tooltip: "68-95%",
      },
      MediumTL: {
        name: "MEDIUM",
        image: mediumToleranceLevel,
        text: "Medium Tolerance Level",
        tooltip: "95-97.7%",
      },
      HighTL: {
        name: "HIGH",
        image: highToleranceLevel,
        text: "High Tolerance Level",
        tooltip: ">97.7%",
      },
    };
    // Renders the tolerance level images with tooltips
    return (
      <div className="toleranceContainer">
        <div className="dashed-line"></div>
        <div className="toleranceImagesContainer">
          {Object.entries(imageData).map(([key, value], index) => (
            <div key={key} className="toleranceItem">
              <CustomTooltip title={value.tooltip} arrow toleranceLevel={value.name}>
                <img
                  src={value.image}
                  alt={value.text}
                  onClick={() => handleToleranceLevel(value.name)}
                />
              </CustomTooltip>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Renders the work hour policy component including tolerance level text, images, and information
  const renderToleranceLevelInformation = () => {
    return (
      <div className="toleranceLevelInfoContainer">
        <img src="/infoIconBlue.svg" alt="Tolerance level Information" />
        <p className="toleranceLevelInformationText">{TOLERANCE_LEVEL_INFO}</p>
      </div>
    );
  };

  const renderWorkHourPolicyComponent = () => {
    return (
      <div className="toleranceLevelContainer">
        <div className="toleranceText">
          <div>Tolerance Level:</div>
          <div>{renderToleranceLevel()}</div>
        </div>
        {editStatus ? (
          <>
            <div>{renderToleranceLevelImages()}</div>
            <div className="toleranceLevelInfoContainers">{renderToleranceLevelInformation()}</div>
          </>
        ) : null}
      </div>
    );
  };

  const renderSubComponent = () => {
    if (
      policySubTypeValue &&
      policySubTypeValue.subTypeCode === NON_WORK_HOUR_POLICY
    ) {
      return renderWorkHourPolicyComponent();
    } else if ( policyType && policyType.code === "INACTIVITY" ) {
      if (editStatus) {
        return (
          <>
            <div>Inactivity Threshold (Number of days)</div>
            <input
                value={autoConfidenceInterval}
                type="number"
                onChange={(e) => handleAutoConfidenceInterval(parseInt(e.target.value))}
                min={0}
                className="min-margin thresholdInput"
              />
          </>
        )
      } else {
        return (
          <>
          <p>
            Inactivity Threshold (Number of days): {autoConfidenceInterval}
          </p>
        </>
        )
      }
    } else {
      return (
        <>
          <p
            className="header-font"
          >{`Confidence Percentage :  ${autoConfidenceInterval}`}</p>
          <div>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <AutoTriggerSlider
                  value={
                    typeof autoConfidenceInterval === "number"
                      ? autoConfidenceInterval
                      : 0
                  }
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  ValueLabelComponent={ValueLabelComponent}
                  disabled={editStatus ? false : true}
                />
              </Grid>
            </Grid>
          </div>
          {renderNoOfTraces()}
        </>
      );
    }
  };

  useEffect(() => {
    // At initial render, set default value of threshold based on severity
    if (isInactivityPolicy(policyType)) {
      return handleAutoConfidenceInterval(thresholdValue(severity));
    }
  }, [policyType]);
  return (
    <div
      className={
        unsuccessfulCreateEdit ? "trigger-container error" : "trigger-container"
      }
    >
      <p className="triggerRuleText">Alert Trigger Rule</p>
      {renderSubComponent()}
    </div>
  );
}
