import React from "react";
import "../../../assets/scss/BackOfficeStyles/Common.scss";
import Button from "@material-ui/core/Button";
import { MDRPatchMonitoringConfigurationLoc } from "../../../redux/constants/constants";

const ManagementFooter = ({
  cancelHandler,
  submitHandler,
  classname,
  loc,
  checkedValue,
}) => {
  const project =
    window.location.pathname === "/mdr/userManagement/createUsers"
      ? "#8014BD"
      : "#2947FD";
  return (
    <div
      className={
        classname ? `managementFooter ${classname}` : "managementFooter"
      }
    >
      {loc === MDRPatchMonitoringConfigurationLoc ? (
        <Button
          // disabled={!checkedValue}
          variant="contained"
          className="managementDone"
          style={{ backgroundColor: project, marginLeft: "30%" }}
          onClick={submitHandler}
        >
          Done
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            className="managementCancel"
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="managementDone"
            style={{ backgroundColor: project }}
            onClick={submitHandler}
          >
            Done
          </Button>
        </>
      )}
    </div>
  );
};

export default ManagementFooter;
