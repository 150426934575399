import {
  ADD_MITIGATION,
  ADD_VULNERABILITY,
  CLEAR_VULNERABILITIES,
  DELETE_MITIGATION,
  DELETE_VULNERABILITY,
  UPDATE_FROM_API,
  UPDATE_VULNERABILITY,
} from "../riskPilotConstant";

const initialState = {
  vulnerabilities: {},
};

export const vulnerabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_VULNERABILITY:
      const newVulnerabilityId = action.payload.id;
      return {
        ...state,
        vulnerabilities: {
          ...state.vulnerabilities,
          [newVulnerabilityId]: action.payload,
        },
      };

    case UPDATE_VULNERABILITY:
      const { vulnId, updates } = action.payload;
      return {
        ...state,
        vulnerabilities: {
          ...state.vulnerabilities,
          [vulnId]: {
            ...state.vulnerabilities[vulnId],
            ...updates,
          },
        },
      };

    case DELETE_VULNERABILITY:
      const { vulnerabilityId } = action.payload;
      const { [vulnerabilityId]: removed, ...remainingVulnerabilities } =
        state.vulnerabilities;
      return {
        ...state,
        vulnerabilities: remainingVulnerabilities,
      };

    case ADD_MITIGATION:
      const newMitigation = action.payload;
      return {
        ...state,
        vulnerabilities: {
          ...state.vulnerabilities,
          [newMitigation.vulnerabilityId]: {
            ...state.vulnerabilities[newMitigation.vulnerabilityId],
            mitigations: [
              newMitigation.id,
              ...(state.vulnerabilities[newMitigation.vulnerabilityId].mitigations || []),
            ],
          },
        },
      };

    case DELETE_MITIGATION:
      const {
        mitigationId,
        vulnerabilityId: vulId,
      } = action.payload;
      return {
        ...state,
        vulnerabilities: {
          ...state.vulnerabilities,
          [vulId]: {
            ...state.vulnerabilities[vulId],
            mitigations: state.vulnerabilities[
              vulId
            ].mitigations.filter((id) => id !== mitigationId),
          },
        },
      };

    case UPDATE_FROM_API:
      const { apiData } = action.payload;

      return {
        ...state,
        vulnerabilities: apiData.vulnerabilityData,
      };

    case CLEAR_VULNERABILITIES:
      return {
        ...state,
        vulnerabilities: {},
      };

    default:
      return state;
  }
};
