import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SortIcon from "../../../assets/vizr_images/ic_sort-1.svg";
import Button from "@material-ui/core/Button";
import { ColorButtonForToolBar } from "../../common/buttons";
import { DeleteButtonForToolBar } from "../../common/buttons";
import DeleteIcon from "../../../assets/vizr_images/ic_rubbish_bin.png";
import { updateAlertPolicyStatus } from "../../../redux/actions/updateStatusAction";
import {
  updateAlertPolicy,
  createAlertPolicy,
  deleteAlertPolicyPermission,
} from "../../../redux/constants/constants";
import { NewPolicyButtonForToolBar } from "../../common/buttons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { deleteAlertPolicy } from "../../../redux/actions/deletePoliciesAction";
import { NewPolicyButtonForPolicyCreateEdit } from "../../common/buttons";
import { ColoredActionButton } from "../../common/buttons";
import Popover from "@material-ui/core/Popover";
import ClearFilter from "../../shared/clearFilter";
import SortComponent from "../../shared/sortComponent";
import ImageTooltip from "../../common/HelpIconWithTooltip"
import CanAllow from "../../shared/CanAllow";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const {
    totalElements,
    rows,
    history,
    sortBy,
    handleSortBy,
    selectedRows,
    sortOrder,
    handleSortOrder,
    helpDescription,
  } = props;
  const [disableButton, setDisableButton] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const permissions = useSelector((state) => state.applicationData.permissions);

  const dispatch = useDispatch();
  const classes = useToolbarStyles();

  useEffect(() => {
    //disabling tool bar buttons if no data for table present
    if (rows.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [rows]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const deleteSubmit = () => {
    handleClose();
    dispatch(deleteAlertPolicy({ ids: selectedRows }));
  };

  const clearFilterButtonClick = () => {
    handleSortBy("");
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleSortingFilter = (event) => {
    handleSortBy(event.target.value);
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting these will mean they will be unavailable for you and your
            team.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ColoredActionButton
            style={{
              width: "124px",
              height: "32px",
              boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
            }}
            onClick={handleClose}
          >
            Cancel
          </ColoredActionButton>
          <NewPolicyButtonForPolicyCreateEdit
            style={{
              marginBottom: "0px",
              cursor: "pointer",
              width: "124px",
              height: "32px",
            }}
            onClick={() => deleteSubmit()}
          >
            Confirm
          </NewPolicyButtonForPolicyCreateEdit>
        </DialogActions>
      </Dialog>
    );
  };

  const renderPopoverComponent = () => {
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        onClose={handleClick}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <>
          <div className="clear-filter-container">
            <ClearFilter
              className="clear-filter"
              loc={"policy"}
              clearFilterButtonClick={clearFilterButtonClick}
              toggle={handleClick}
            />
          </div>
          <div className="popper-root">
            <SortComponent
              sortBy={sortBy}
              handleSortingFilter={handleSortingFilter}
              loc={"policy"}
              sortOrder={sortOrder}
              handleSortOrder={handleSortOrder}
            />
          </div>
        </>
      </Popover>
    );
  };

  const renderHeader = () => {
    return (
      <>
        {selectedRows.length > 0 ? (
          <Typography
            className="title"
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {selectedRows.length} selected
          </Typography>
        ) : (
          ""
        )}
        <Typography
          className="title"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Policies
        </Typography>
        <p style={{ margin: "11px 5px 0px 0px", minWidth: "45px" }}>Total :</p>
        <div className="count">{totalElements}</div>
        <ImageTooltip description={helpDescription} />
      </>
    );
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const renderButtons = () => {
    return (
      <>
        <CanAllow needs={{ permission: [deleteAlertPolicyPermission] }}>
          <DeleteButtonForToolBar
            variant="contained"
            id="delete"
            size="small"
            color="primary"
            disabled={disableButton}
            onClick={() => {
              if (selectedRows.length > 0) {
                handleClickOpen();
              }
            }}
            className="min-margin table-buttons"
          >
            <img
              style={{ width: '24px', height: '22px' }}
              src={DeleteIcon}
              alt="delete"
            />
          </DeleteButtonForToolBar>
        </CanAllow>

        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            color: "#081981",
            backgroundColor: "transparent",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
          }}
          disabled={disableButton}
          onClick={handleClick("bottom")}
          startIcon={<img src={SortIcon} alt="sort-filter" />}
          className="min-margin table-buttons"
        >
          Sort
        </Button>
        <CanAllow needs={{ permission: [updateAlertPolicy] }}>
          <ColorButtonForToolBar
            variant="contained"
            disabled={disableButton}
            onClick={() => {
              if (selectedRows.length > 0) {
                dispatch(
                  updateAlertPolicyStatus({
                    status: 'ACTIVE',
                    ids: selectedRows,
                  })
                );
              }
            }}
            color="primary"
            size="small"
            className="min-margin table-buttons"
          >
            Enable
          </ColorButtonForToolBar>
          <ColorButtonForToolBar
            variant="contained"
            color="primary"
            size="small"
            disabled={disableButton}
            onClick={() => {
              if (selectedRows.length > 0) {
                dispatch(
                  updateAlertPolicyStatus({
                    status: 'INACTIVE',
                    ids: selectedRows,
                  })
                );
              }
            }}
            className="min-margin table-buttons"
          >
            Disable
          </ColorButtonForToolBar>
        </CanAllow>
        <CanAllow needs={{ permission: [createAlertPolicy] }}>
          <NewPolicyButtonForToolBar
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              dispatch({
                type: "TRACK_TRAVERSAL",
                payload: "User Management - Create Users",
              });
              history.push(`/tenant/alertPolicies/createPolicy`);
            }}
            startIcon={<AddCircleOutlineIcon />}
            className="min-margin table-buttons"
          >
            New Alert Policy
          </NewPolicyButtonForToolBar>
        </CanAllow>
      </>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className="toolbar-root">
        <Grid container spacing={3}>
          <Grid style={{ display: "flex" }} item xs={3}>
            {renderHeader()}
          </Grid>
          <Grid item xs={9} style={{ float: "right", textAlign: "right" }}>
            {renderButtons()}
          </Grid>
        </Grid>
        {renderDialog()}
        {renderPopoverComponent()}
      </Toolbar>
    </Paper>
  );
};

EnhancedTableToolbar.propTypes = {
  totalElements: PropTypes.number.isRequired,
};
