import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../DashBoardStyles.scss";
import {
  BARCHART_TOGGLERS,
  INCIDENT_GROUPINGTOGGLERS,
} from "../../../../redux/actions/MDRActions/IncidentDashboardAction";
import {
  incidentDashboard_ToggleCharts,
  dateFormatter,
  endPointsDashboard_ToggleCharts,
  endPointNewDashboardInstanceforToggling,
} from "../../Dashboard/utils";
import { UPDATE_MDRENDPOINTS_CURRENTINDEX_ACTION } from "../../../../redux/actions/MDRActions/MDREndPointsAction";

const Toggler = ({ togglerNamesArr, type }) => {
  const dispatch = useDispatch();
  const incidentCurrentGraphActiveValue = useSelector(
    (state) => state.IncidenetReducer.incidentCurrentGraphActiveValue
  );
  const incidentGroupingValue = useSelector(
    (state) => state.IncidenetReducer.incidentGroupingValue
  );

  const endPointGroupingValue = useSelector(
    (state) => state.MDREndPointsReducer.endPointGroupingValue
  );
  const endPointsCurrentGraphActiveValue = useSelector(
    (state) => state.MDREndPointsReducer.endPointsCurrentGraphActiveValue
  );
  const dateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );
  let startDate = dateTimeRange && JSON.parse(dateTimeRange).startDate;
  let endDate = dateTimeRange && JSON.parse(dateTimeRange).endDate;
  // console.log(startDate, endDate);
  // console.log(dateTimeRange.startDate, dateTimeRange.endDate, dateTimeRange);
  if (startDate && endDate) {
    var startDt = new Date(startDate).getTime(); // dateFormatter(startDate);
    var endDt = new Date(endDate).getTime(); // dateFormatter(endDate);
  }

  const [currentActive, setCurrentActive] = useState(0);

  useEffect(() => {
    if (type === "MDRIncidents") {
      setCurrentActive(incidentCurrentGraphActiveValue);
    } else if (type === "MDREndpoints") {
      setCurrentActive(endPointsCurrentGraphActiveValue);
    }
  }, [incidentCurrentGraphActiveValue, endPointsCurrentGraphActiveValue, type]);

  const clickHandler = (e, index, value) => {
    // setCurrentActive(index);
    if (type === "MDRIncidents") {
      dispatch(UPDATE_MDRENDPOINTS_CURRENTINDEX_ACTION(0));
      dispatch({ type: "CURRENT_ACTIVE_GRAPH", payload: index });
      const payload = incidentDashboard_ToggleCharts(
        index,
        incidentGroupingValue,
        startDt,
        endDt
      );
      dispatch(BARCHART_TOGGLERS(payload));
    } else if (type === "MDREndpoints") {
      dispatch({ type: "CURRENT_ACTIVE_GRAPH", payload: 0 });
      dispatch(UPDATE_MDRENDPOINTS_CURRENTINDEX_ACTION(index));
      const payload = endPointsDashboard_ToggleCharts(
        index,
        endPointGroupingValue,
        startDt,
        endDt
      );
      //! use destructuring to create new instance for that object(destructuring used in below function)
      const newDashBoardInstance =
        endPointNewDashboardInstanceforToggling(payload);
      //! we are dipatching CHANGE_MDR_DASHBOARD action because we are using dashboard instance in IncidentReducer for all Dashboards in MDR
      dispatch({
        type: "CHANGE_MDR_DASHBOARD",
        payload: newDashBoardInstance,
      });
    }
  };

  //To Show the Bar chart by default if we shift between MDR and DDV
  useEffect(() => {
    return () => {
      dispatch({ type: "CURRENT_ACTIVE_GRAPH", payload: 0 });
      setCurrentActive(0);
      console.log("returnclear");
    };
  }, []);
  console.log(currentActive, "setindex");
  return (
    <section className="MDRbarGraph">
      {togglerNamesArr.map((el, index) => {
        return (
          <div
            className={currentActive == index ? "active" : "inactive"}
            onClick={(e) => clickHandler(e, index, el)}
          >
            {el}
          </div>
        );
      })}
    </section>
  );
};

export default Toggler;
