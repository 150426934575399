import ScatterIcon from "../../../assets/vizr_images/scatter_icon_green.svg";
import ScatterIcon1 from "../../../assets/vizr_images/scatter_icon_yellow.svg";
import ScatterIcon2 from "../../../assets/vizr_images/scatter_icon_red.svg";
import ReactSpeedometer from "react-d3-speedometer";
import CombinedShapeCross from "../../../assets/icons/CombinedShapeCross.svg";
import { useDispatch } from "react-redux";
import { CLOSE_TOOLTIP } from "../../../redux/constants/constants"

export default function UserTooltip({ hoverNode }) {
  const dispatch = useDispatch();
  let percentage =
    hoverNode && hoverNode.percentage ? Math.round(hoverNode.percentage) : 0;
  let totalAnomalies =
    hoverNode && hoverNode.totalAnomalies ? hoverNode.totalAnomalies : 0;
  const anomaliesCount = [0, 20, 70]
  const closeTooltip = () => {
    dispatch({ type: CLOSE_TOOLTIP, payload: {node: hoverNode}})
  }
  const renderImage = () => {
    if (totalAnomalies > anomaliesCount[0] && totalAnomalies <= anomaliesCount[1]) {
      return <img src={ScatterIcon} id="scatter" alt="scatterIcon"></img>;
    } else if (totalAnomalies > anomaliesCount[1] && totalAnomalies <= anomaliesCount[2]) {
      return <img src={ScatterIcon1} id="scatter" alt="scatterIcon"></img>;
    } else if (totalAnomalies > anomaliesCount[2]) {
      return <img src={ScatterIcon2} id="scatter" alt="scatterIcon"></img>;
    }
  };
  return (
    <div style={{ maxHeight: "130px", overflow: "hidden", width: "235px" }}>
      <div className="user-toolTip-header">
        <div class="labelStyle">{hoverNode && hoverNode.label}</div>
        <div className="inspect-tooltip-close">
          <img src={CombinedShapeCross} alt="Close Icon" title="close" onClick={closeTooltip} />
        </div>
      </div>
      <div class="toolTip">
        <ReactSpeedometer
          segments={3}
          ringWidth={8}
          width={110}
          height={100}
          value={percentage}
          segmentColors={["#081981", "#FFA500", "#FE5478"]}
          needleColor="#000000"
          needleHeightRatio={0.5}
          maxSegmentLabels={0}
          maxValue={100}
        />
      </div>
      <h6 id="align1">{`${percentage}% Sensitivity`}</h6>
      <div class="anomalies">
        {renderImage()}
        <h6 id="align2">{`${totalAnomalies} Anomalies`}</h6>
      </div>
    </div>
  );
}
