import React from 'react';
import ChartWrapper from '../Chart';
import { Link } from 'react-router-dom';
import { totalCount, percentageCalculation, pathWithPermission } from '../../utilities';
import { QueryRenderer } from '@cubejs-client/react';
import { renderNoData, renderSingleValue } from '../utils';
import { viewDataMap } from '../../../../redux/constants/constants';

export function totalGroups(cubejsApi, name, query) {
  const countQuery = query[0];
  const chartQuery = query[1];
  const returnRedirectTo = (name) => {
    let data = {
      "Total Groups":{
        pathname: "/tenant/dataMap",
        permission: [viewDataMap],
      },
    }
    return pathWithPermission(data[name])
  };
  const renderChart = (resultSet) => {
    const color = ['devOps', 'engineering', 'it', 'sales', 'finance'];
    let groupData = [];
    const data = resultSet.loadResponse.results[0].data;
    if (!data || data.length === 0) {
      return renderNoData('30%');
    }
    data.map((item, index) => {
      const pDataItem = {
        groupName: item['Groups.groupName'],
        count: parseInt(item['Groups.user_count']),
        color: color[index],
      };
      groupData.push(pDataItem);
    });
    const total_groups_count = totalCount(groupData);
    if (total_groups_count !== 0) {
      const floorValues = percentageCalculation(groupData, total_groups_count);
      groupData.forEach((d) => {
        const floorValue = floorValues.find((val) => val.color === d.color);
        d.percentage = floorValue.count;
        d.unRoundPercentage = floorValue.unRoundPercentage;
      });
    }
    return (
      <div className="totalGroupsWidget">
        <div className="widget-header2">
          <span className="span-total-count">
            <QueryRenderer
              query={countQuery}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) {
                  return <div className="loader" />;
                }
                return renderSingleValue(resultSet, countQuery.measures[0]);
              }}
            />
          </span>
        </div>
        <div className="total-groups-container">
          <h4>Top 5 groups with users</h4>
          {groupData.map((row) => {
            return (
              <div className="group-container">
                <div className="bar-background">
                  <span>{row.groupName}</span>
                  <span className='group-percentage'>{row.percentage}%</span>
                  <div
                    className={`bar ${row.color}`}
                    style={{ width: `${row.percentage}%` }}
                    title={`${row.unRoundPercentage}%`}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={chartQuery}
      render={renderChart}
      redirectTo={returnRedirectTo(name)}
    />
  );
}
