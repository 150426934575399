import React, { useState, useEffect } from "react";
import { ColoredActionButton } from "../common/buttons";
import { Button } from "@material-ui/core";
import ErrorIcon from "../../assets/icons/ErrorIcon.svg";
import ConnectedIcon from "../../assets/icons/ConnectedStatusIcon.svg";
import NotConnected from "../../assets/icons/NotConnectedStatusIcon.svg";

import ImageTooltip from "../common/HelpIconWithTooltip";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import { isEmpty, isNull } from "lodash";
import { useLocation } from "react-router-dom";

const OPERATIONS = {
  GoogleDriveConnect: "GoogleDriveConnect",
};

export const GoogleDriveConnection = (props) => {
  const cancelErrorInfo = "Something went wrong, Please try again";
  const currentRoute = useLocation();
  const { onHandleConnect, dataSourceData, toggleDrawer, isEditMode, googleConfigCheck } = props;
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [errorOcurred, setErrorOcurred] = useState(false);
  const dispatch = useDispatch();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    const urlParam = new URLSearchParams(currentRoute.search);
    const error_description = urlParam.get("error_description");
    if (!isEmpty(error_description)) {
      setErrorOcurred(true);
    }
  }, [currentRoute]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const getButtonText = (secretKey) => {
    if (dataSourceData && dataSourceData[secretKey]) {
      return "Authorize";
    } else if (dataSourceData.id) {
      return "Authorize";
    } else {
      return "Save & Authorize";
    }
  };

  const graphButton = () => {
    return getButtonText("secret1");
  };

  const getConnectedStatus = () => {
    return (
      <div
        className={
          !isEmpty(dataSourceData.secret1)
            ? "one-drive-status-icon oneDriveStatus"
            : "one-drive-status-icon gDriveStatus"
        }
      >
        <img src={ConnectedIcon} />
      </div>
    );
  };

  const getNotConnectedStatus = () => {
    return (
      <div
        className={
          !isEmpty(dataSourceData.secret1)
            ? "one-drive-status-icon oneDriveStatus"
            : "one-drive-status-icon gDriveStatus"
        }
      >
        <img src={NotConnected} />
      </div>
    );
  };

  const getErrorStatus = (errorInfo) => {
    return (
      <div
        className={
          !isEmpty(dataSourceData.secret1)
            ? "one-drive-status-icon oneDriveStatus"
            : "one-drive-status-icon gDriveStatus"
        }
      >
        <img src={ErrorIcon} />
        <span className="icon-status error one-drive-status-text">
          {errorInfo}
        </span>
      </div>
    );
  };

  const getSnackBarStatus = (secretKey) => {
    //If we receive any error from backend
    if (
      dataSourceData &&
      isNull(dataSourceData[secretKey]) &&
      !isNull(dataSourceData.errorInfo)
    ) {
      return getErrorStatus(dataSourceData.errorInfo);
    }
    //If we receive any error from Google Drive
    if (
      dataSourceData &&
      isNull(dataSourceData[secretKey]) &&
      isNull(dataSourceData.errorInfo) &&
      errorOcurred
    ) {
      return getErrorStatus(cancelErrorInfo);
    }
    //To see if the Api's are connected or not
    if (dataSourceData && dataSourceData[secretKey]) {
      return getConnectedStatus();
    } else if (dataSourceData && isNull(dataSourceData[secretKey])) {
      return getNotConnectedStatus();
    }
  };

  return (
    <>
      <div className="one-drive-buttons">
        <div className="connection-group  gDrive-api">
          <div
            className={
              isEditMode && !isEmpty(dataSourceData.secret1)
                ? "drive-status-group"
                : "drive-status-group gDriveGroup"
            }
          >
            <div className="oneDriveConnectionIconContainer gDriveIconContainer">
              {isEditMode ? getSnackBarStatus("secret1") : null}
              <div className="gDriveButtonMainContainer">
                <div
                  className={
                    isEditMode && !isEmpty(dataSourceData.secret1)
                      ? "dataSourceButtonsContainer"
                      : "dataSourceButtonsContainer gDriveButtonContainer"
                  }
                >
                  {isEmpty(dataSourceData.secret1) ? (
                    <ColoredActionButton
                      style={{
                        width: "124px",
                        height: "32px",
                        boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
                      }}
                      onClick={(event) => toggleDrawer(false, event)}
                    >
                      Cancel
                    </ColoredActionButton>
                  ) : null}
                  <div className="gDriveButton">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={(event) =>
                        onHandleConnect(
                          event,
                          OPERATIONS.GoogleDriveConnect,
                          dispatch
                        )
                      }
                      disabled={
                        isEmpty(dataSourceData.secret1)
                          ? !googleConfigCheck
                          : false
                      }
                    >
                      {graphButton()}
                    </Button>
                  </div>
                </div>
                <div>
                  <ImageTooltip description="Access to User Information, Shared Drive information and User Activities" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-group GDrive">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          onExited={handleExited}
        >
          <Alert onClose={handleClose} severity={"error"}>
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
