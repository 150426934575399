import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useSelector } from "react-redux";
import "./RiskGovernance.scss";

const CustomCard = styled(Card)`
  background-color: ${(props) => props.color};
  border-radius: 8px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  font-weight: bold;
  height: 230px;
  box-shadow: none;
`;

const GridContainer = styled(Grid)`
  background: white;
  border-radius: 8px;
  padding-right: 0;
`;

const StatusTypography = styled(Typography)`
  color: red;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
`;

const renderStatus = (label, data) => {
  if (!data) return null;

  return (
    <GridContainer container spacing={2}>
      <Grid item xs={1}>
        <StatusTypography variant="body2">
          {`${label}: ${data}`}
        </StatusTypography>
      </Grid>
    </GridContainer>
  );
};

const RiskPilotStatusCards = ({ color, tableView, type }) => {
  const {
    riskStatusData,
    threatStatusData,
    vulnerabilityStatusData,
    mitigationStatusData
  } = useSelector((state) => state.riskPilotReducer);

  return (
    <div style={{ width: "200%" }}>
      <CustomCard color={color}>
        <CardContent
          sx={{ textAlign: "left" }}
          style={{ display: "flex", alignItems: "baseline", gap: "40px", paddingLeft: "3px" }}
        >
          <div className="statusCardContainer">
            {(tableView === "RISK_VIEW" || tableView === "THREAT_VIEW" || tableView === "VULNERABILITY_VIEW" || tableView === "MITIGATION_VIEW") &&
              renderStatus('Risk', riskStatusData[type])
            }
            {(tableView === "THREAT_VIEW" || tableView === "VULNERABILITY_VIEW" || tableView === "MITIGATION_VIEW") &&
              renderStatus('Threat', threatStatusData[type])
            }
          </div>
          <div className="statusCardContainer">
            {(tableView === "VULNERABILITY_VIEW" || tableView === "MITIGATION_VIEW") &&
              renderStatus('Vulnerability', vulnerabilityStatusData[type])
            }
            {tableView === "MITIGATION_VIEW" &&
              renderStatus('Mitigation', mitigationStatusData[type])
            }
          </div>
        </CardContent>
      </CustomCard>
    </div>
  );
};

export default RiskPilotStatusCards;
