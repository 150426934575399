import moment from "moment";
export const renderDateTime = (dateTimeString) => {
  if (dateTimeString) {
    const momentDate = moment(new Date(dateTimeString));
    return (
      <span>
        {momentDate.format("MM-DD-YYYY")}
        <br />
        {momentDate.format("HH:mm")}
      </span>
    );
  }
  return "-";
}
