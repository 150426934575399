import config from "../../project.config";
// export const protocol = !config.secure ? "http://" : "https://";
//needed
export const domain_vizr = process.env.REACT_APP_DOMAIN_VIZR;
export const CUBEJS_URL = process.env.REACT_APP_CUBEJS_URL;
export const CUBEJS_TOKEN = process.env.REACT_APP_CUBEJS_TOKEN;
// needed
//export const KIBANA_URL = process.env.REACT_APP_ELASTIC_APM_SERVER;
//api-qa1-data-vision.druvstardemo.com
// export const CUBEJS_URL =

export const KIBANA_URL = !config.secure ? `${domain_vizr}/kibana/api/apm` : "";
export const APPLICATIONS = "APPLICATIONS";
export const PERMISSIONS = "PERMISSIONS";
export const SELECTED_APPLICATION = "SELECTED_APPLICATION";
export const SELECTED_ITEMS = "SELECTED_ITEMS";
export const TRACK_TRAVERSAL = "TRACK_TRAVERSAL";
export const SELECTED_TIME_ZONE = "SELECTED_TIME_ZONE";
export const CLEAR_TRACK_TRAVERSAL = "CLEAR_TRACK_TRAVERSAL";
export const NODE_POSITIONS = "NODE_POSITIONS";
export const ALERTS = "ALERTS";
export const GET_ALERTS = "GET_ALERTS";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const GET_ALERT_POLICIES = "GET_ALERT_POLICIES";
export const GET_POLICY_DETAIL = "GET_POLICY_DETAIL";
export const GET_USER_PREFERENCES_URL =
  domain_vizr + "/tenant/users/me/preferences";
export const GET_PLATFORM_USER_PREFERENCES_URL =
  domain_vizr + "/platform/users/me/preferences";

export const GET_ALL_COUNTRIES = (countryName) => {
  if (countryName) {
    return (
      domain_vizr +
      `/platform/geoGraphy/getDistinctCountries?country=${countryName}`
    );
  } else {
    return domain_vizr + `/platform/geoGraphy/getDistinctCountries`;
  }
};

export const dataMapsplitView = [
  { name: "Direct Data Access Statements", state: "active" },
  { name: "Service Data Access Statements", state: "inactive" },
];

export const GET_ALL_REGIONS = (countryName, region) => {
  if (countryName && region) {
    return (
      domain_vizr +
      `/platform/geoGraphy/getDistinctRegions?country=${countryName}&region=${region}`
    );
  } else {
    return (
      domain_vizr +
      `/platform/geoGraphy/getDistinctRegions?country=${countryName}`
    );
  }
};

export const GET_ALL_CITIES = (countryName, region) => {
  if (countryName && region) {
    return (
      domain_vizr +
      `/platform/geoGraphy/getCitiesWithGeoLocation?country=${countryName}&region=${region}`
    );
  }
};

export const MICROSOFT_ONLINE_GRAPH = (domainId, clientId, dataSourceId) => {
  if (domainId && clientId && dataSourceId) {
    return (
      `https://login.microsoftonline.com/${domainId}/oauth2/v2.0/authorize?prompt=consent&client_id=${clientId}&scope=offline_access https://graph.microsoft.com/.default&response_type=code&sso_reload=true&state=${dataSourceId}&redirect_uri=`
    );
  }
};

export const MICROSOFT_ONLINE_MGMT = (domainId, clientId, dataSourceId) => {
  if (domainId && clientId && dataSourceId) {
    return (
      `https://login.windows.net/common/oauth2/authorize?prompt=consent&client_id=${clientId}&resource=https://manage.office.com&response_type=code&sso_reload=true&state=${dataSourceId}&redirect_uri=`
    );
  }
};

//To connect to Dropbox
export const DROPBOX_CONNECT = ( clientId, dataSourceId ) => {
  if ( clientId && dataSourceId) {
    return (
      `https://www.dropbox.com/oauth2/authorize?client_id=${clientId}&token_access_type=offline&response_type=code&state=${dataSourceId}&redirect_uri=`
    );
  }
}

export const GOOGLEDRIVE_CONNECT = ( clientId, dataSourceId) => {
  if ( clientId, dataSourceId) {
    return (
      `https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=${clientId}&scope=https://www.googleapis.com/auth/admin.directory.group.readonly%20https://www.googleapis.com/auth/admin.directory.group.member.readonly%20https://www.googleapis.com/auth/admin.reports.audit.readonly%20https://www.googleapis.com/auth/admin.directory.user.readonly%20https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly%20
      https://www.googleapis.com/auth/drive.readonly&response_type=code&state=${dataSourceId}&redirect_uri=`
      )
  }
}

export const DOMAIN_NAME_VALIDATION = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/;

export const DELETE_POLICY_DETAIL = "DELETE_POLICY_DETAIL";
export const POLICY_DELETE_FAILED = "POLICY_DELETE_FAILED";
export const POLICY_DELETE_SUCCEEDED = "POLICY_DELETE_SUCCEEDED";
export const UPDATE_POLICY_STATUS = "UPDATE_POLICY_STATUS";
export const UPDATE_POLICY_STATUS_CHANGE_REQUEST =
  "UPDATE_POLICY_STATUS_CHANGE_REQUEST";
export const UPDATE_POLICY_STATUS_CHANGE_SUCCEEDED =
  "UPDATE_POLICY_STATUS_CHANGE_SUCCEEDED";
export const UPDATE_POLICY_STATUS_CHANGE_FAILED =
  "UPDATE_POLICY_STATUS_CHANGE_FAILED";

export const UPDATE_POLICY_REQUEST = "UPDATE_POLICY_REQUEST";
export const UPDATE_POLICY_SUCCEEDED = "UPDATE_POLICY_SUCCEEDED";
export const UPDATE_POLICY_FAILED = "UPDATE_POLICY_FAILED";

export const UPDATE_ALERT_STATUS = "UPDATE_ALERT_STATUS";
export const UPDATE_ALERT_STATUS_REQUEST = "UPDATE_ALERT_STATUS_REQUEST";
export const UPDATE_ALERT_STATUS_SUCCEEDED = "UPDATE_ALERT_STATUS_SUCCEEDED";
export const UPDATE_ALERT_STATUS_FAILED = "UPDATE_ALERT_STATUS_FAILED";

export const DATA_FLOW_GROUPS = "DATA_FLOW_GROUPS";
export const DATA_FLOWS = "DATA_FLOWS";
export const UPDATE_ALERT_POLICY = "UPDATE_ALERT_POLICY";

export const CREATE_ALERT_POLICY = "CREATE_ALERT_POLICY";
export const CREATE_ALERT_POLICY_REQUEST = "CREATE_ALERT_POLICY_REQUEST";
export const CREATE_ALERT_POLICY_SUCCEEDED = "CREATE_ALERT_POLICY_SUCCEEDED";
export const CREATE_ALERT_POLICY_FAILED = "CREATE_ALERT_POLICY_FAILED";

export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const VIEW_ENTERPRISE_ACTORS = "VIEW_ENTERPRISE_ACTORS";
export const UPDATE_ENTERPRISE_ACTORS = "UPDATE_ENTERPRISE_ACTORS";

export const EDIT_SENSITIVITY_REQUEST = "EDIT_SENSITIVITY_REQUEST";
export const EDIT_SENSITIVITY_SUCCEEDED = "EDIT_SENSITIVITY_SUCCEEDED";
export const EDIT_SENSITIVITY_FAILED = "EDIT_SENSITIVITY_FAILED";
export const EDIT_SENSITIVITY_URL =
  domain_vizr + "/tenant/sensitivityInfo/category";
export const SELECTED_NODE = "SELECTED_NODE";

export const SELECTED_DATE_TIME_RANGE = "SELECTED_DATE_TIME_RANGE";

export const PLATFORM_ADMIN_TENANT_ONBOARD_REQUEST =
  "PLATFORM_ADMIN_TENANT_ONBOARD_REQUEST";
export const PLATFORM_ADMIN_TENANT_ONBOARD_FAILED =
  "PLATFORM_ADMIN_TENANT_ONBOARD_FAILED";
export const PLATFORM_ADMIN_TENANT_ONBOARD_SUCCESS =
  "PLATFORM_ADMIN_TENANT_ONBOARD_SUCCESS";

export const PLATFORM_ADMIN_TENANT_UPDATE_REQUEST =
  "PLATFORM_ADMIN_TENANT_UPDATE_REQUEST";
export const PLATFORM_ADMIN_TENANT_UPDATE_SUCCESS =
  "PLATFORM_ADMIN_TENANT_UPDATE_SUCCESS";
export const PLATFORM_ADMIN_TENANT_UPDATE_FAILED =
  "PLATFORM_ADMIN_TENANT_UPDATE_FAILED";

export const VIEW_PLATFORM_ROLE = "VIEW_PLATFORM_ROLE";

export const DELETE_TENANT_REQUEST = "DELETE_TENANT_REQUEST";
export const DELETE_TENANT_SUCCESS = "DELETE_TENANT_SUCCESS";
export const DELETE_TENANT_FAIL = "DELETE_TENANT_FAIL";

export const ALERT_DETAILS = "ALERT_DETAILS";
export const SERVICE_MAP_FOR_ALERT = "SERVICE_MAP";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const SEARCH_USER = "SEARCH_USER";
export const GET_ALERT_DETAILS = "GET_ALERT_DETAILS";
export const GET_ALERT_POLICY_ALERTS = "GET_ALERT_POLICY_ALERTS";
export const GET_POLICY_COMMENTS = "GET_POLICY_COMMENTS";
export const TENANT_TOKEN = "TENANT_TOKEN";
export const GET_POLICY_DATAFLOW = "GET_POLICY_DATAFLOW";
export const GET_RELATED_ALERTS = "GET_RELATED_ALERTS";
export const GET_ALERT_RESOLUTION_TYPES = "GET_ALERT_RESOLUTION_TYPES";
export const GET_ALERT_POLICY_SEVERITIES = "GET_ALERT_POLICY_SEVERITIES";
export const GET_ALERT_POLICY_NAMES = "GET_ALERT_POLICY_NAMES";
export const SAVE_ALERT_POLICY_COMMENT = "SAVE_ALERT_POLICY_COMMENT";
export const CHANGE_ALERT_POLICY_SEVERITY = "CHANGE_ALERT_POLICY_SEVERITY";
export const SELECTED_PROFILE = "SELECTED_PROFILE";

// alertcount status
export const UPDATE_ALERT_COUNT = "UPDATE_ALERT_COUNT";

//Token
export const OAUTH_URL = domain_vizr + "/oauth/token"; //token call

//get all tenants
export const GET_PLATFORM_TENANTS = domain_vizr + "/platform/tenants"; //get alerts url

// get provisionDb types
export const GET_PROVISIONDB_TYPES_URL =
  domain_vizr + "/platform/tenants/getListOfProvisionTypes"; //get ProvisionDb types

//Alerts
export const GET_ALERTS_URL = domain_vizr + "/tenant/alerts"; //get alerts url
export const UPDATE_ALERTS_STATUS_URL = domain_vizr + "/tenant/alerts/status"; //get alerts url
export const UPDATE_ALERT_URL = domain_vizr + ""; //update an alert url
export const GET_ALERT_DETAILS_URL = domain_vizr + "/tenant/alerts"; //get alert details an alert url
export const GET_RELATED_ALERTS_URL = domain_vizr + "/tenant/alerts";
export const FILTER_ALERTS_URL = domain_vizr + "/tenant/alerts"; //filter alerts
export const SAVE_ALERT_COMMENT_URL = domain_vizr + "/tenant/alerts"; //save comment for alert

//Severities
export const GET_ALERT_POLICY_SEVERITIES_URL =
  domain_vizr + "/tenant/lookups/alertPolicySeverities";
export const GET_ALERT_RESOLUTION_TYPES_URL =
  domain_vizr + "/tenant/lookups/alertResolutionTypes";
export const GET_ALERT_POLICY_TYPES_URL =
  domain_vizr + "/tenant/lookups/alertPolicyTypes";
  export const GET_ALERT_POLICY_SUB_TYPES_URL =
  domain_vizr + "/tenant//lookups/alertPolicyTypeSubType/all";
  
//Alert Policies
export const CREATE_ALERT_POLICY_URL = domain_vizr + "/tenant/alertPolicies"; //create an alert policy url
export const GET_ALERT_POLICIES_URL = domain_vizr + "/tenant/alertPolicies"; //get alert policies url
export const GET_POLICY_DETAIL_URL = domain_vizr + "/tenant/alertPolicies"; //get alert policies url
export const DELETE_POLICY_DETAIL_URL = domain_vizr + "/tenant/alertPolicies"; //get alert policies url
export const UPDATE_POLICY_STATUS_URL = domain_vizr + "/tenant/alertPolicies";
export const UPDATE_ALERT_POLICY_URL = domain_vizr + "/tenant/alertPolicies";
export const GET_ALERT_POLICY_ALERTS_URL =
  domain_vizr + "/tenant/alertPolicies";
export const CHANGE_ALERT_POLICY_SEVERITY_URL =
  domain_vizr + "/tenant/alertPolicies";
export const GET_ALERT_POLICY_NAMES_URL =
  domain_vizr + "/tenant/alertPolicies/summary"; //will change

//Users
export const GET_ALL_USERS_URL = domain_vizr + "/tenant/users"; //create an alert policy url
export const SEARCH_USER_URL = domain_vizr + "/tenant/users/search"; //search use api call
export const SEARCH_DB_USER_URL =
  domain_vizr + "/tenant/alertPolicies/dataSources/username/search";
export const SEARCH_DB_ROLE_URL =
  domain_vizr + "/tenant/alertPolicies/dataSources/roles/search";

//search use api call

//Data Flow Groups
export const GET_POLICY_DATAFLOW_URL = domain_vizr + "/tenant/alertPolicies"; //get data flow groups url
export const GET_ALL_DATAFLOW_GROUPS_URL =
  domain_vizr + "/tenant/dataFlowGroups"; //get all data flow groups
//Policy Comments
export const GET_POLICY_COMMENTS_URL = domain_vizr + "/tenant/alertPolicies"; //create an alert policy url

//password authenticate
export const SERVICE_MAP_FOR_ALERT_URL = domain_vizr + ""; //get service map for map view of an alert url

//Trigger Rules
export const GET_COMPARISON_TYPES_URL =
  domain_vizr + "/tenant/lookups/comparisonTypes";
export const GET_AGGREGATION_TYPES_URL =
  domain_vizr + "/tenant/lookups/aggregationTypes";
export const GET_TIME_WINDOW_TYPES_URL =
  domain_vizr + "/tenant/lookups/timeWindowTypes";
export const GET_ALERT_POLICY_STATUSES_URL =
  domain_vizr + "/tenant/lookups/alertPolicyStatuses";
export const GET_DATA_SENSITIVITY_CATEGORIES_URL =
  domain_vizr + "/tenant/lookups/dataSesnitivityCategories";
export const GET_DATABASE_ENGINES_URL = domain_vizr + "/tenant/databaseEngines";

//comments
export const SAVE_ALERT_POLICY_COMMENT_URL =
  domain_vizr + "/tenant/alertPolicies";

export const DELETE_POLICY_REQUEST = "DELETE_POLICY_REQUEST";

// user session management
export const SIGN_UP_URL = `${domain_vizr}/platform/signup`;

//Tenant management

export const TENANT_SIGN_UP_URL = `${domain_vizr}/platform/onboard/tenants`;

// export const TENANT_VERIFY_EMAIL = `${domain_vizr}/platform/onboard/tenants/<tenant id>/verifyEmail`;

export const DATASOURCE_URL = `${domain_vizr}/tenant/dataSources`;

export const APPLICATIONS_URL = `${domain_vizr}/tenant/applications?size=20`;

export const DATASOURCE_URL2 = `${domain_vizr}/tenant/applications`;

export const SAVE_CONNECTION_URL = `${domain_vizr}/tenant/applications`;
export const GET_TEST_CONNECTION_URL = `${domain_vizr}/connections/dataSources/test`;

export const GET_MDR_GLOBAL_FILTERS = `${domain_vizr}/tenant/mdrPatchConfig/getFilter`;
export const SAVE_MDR_GLOBAL_FILTERS = `${domain_vizr}/tenant/mdrPatchConfig/saveFilter`;

//Back Office ADMIN API'S Constants:

//get the Particular Login user Data:
export const USERS_ME = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/users/me`;
  } else {
    return `${domain_vizr}/tenant/users/me`;
  }
};

export const UPDATE_DATASOURCE_SUCCESS = "UPDATE_DATASOURCE_SUCCESS";
export const UPDATE_DATASOURCE = "UPDATE_DATASOURCE";
export const TRIGGER_TOKEN =  "TRIGGER_TOKEN";
export const DROPBOX_TRIGGER = "DROPBOX_TRIGGER";
export const GET_DATASOURCE = "GET_DATASOURCE";
export const GET_ALL_TYPES_OF_DATASOURCE = "GET_ALL_TYPES_OF_DATASOURCE";
export const UPDATE_DROPBOX_CONNECTION_STATUS = "UPDATE_DROPBOX_CONNECTION_STATUS";
export const RESET_DROPBOX_CONNECTION_STATUS = "RESET_DROPBOX_CONNECTION_STATUS";
// Update Datasource URL
export const UPDATE_DATASOURCE_URL = (applicationID, datasourceID) => {
  return (
    domain_vizr +
    `/tenant/applications/${applicationID}/dataSources/${datasourceID}`
  );
};

export const TRIGGER_TOKEN_URL = (applicationID, datasourceID, consentType) => {
  return (
    domain_vizr +
    `/tenant/applications/${applicationID}/dataSources/${datasourceID}/secrets?apiType=${consentType}`
  );
};

export const DROPBOX_TRIGGER_URL = (applicationID, dataSourceID, dropBoxData) => {
  return (
    domain_vizr + 
    `/tenant/applications/${applicationID}/dataSources/${dataSourceID}/secrets?code=${dropBoxData}`
  );
}

//
export const GET_ACTIVITY_REPORT_ALERTS_URL = (startTime, endTime, pageSize) => {
  return (
    domain_vizr +
    `/tenant/alerts?from=${startTime}&to=${endTime}&sortBy=generatedOn&direction=DESC&size=${pageSize}&page=0`
  );
};

export const GET_ACTIVITY_REPORT_UNRESOLVED_ALERTS_URL = (
  startTime,
  endTime,
  pageSize
) => {
  return (
    domain_vizr +
    `/tenant/alerts?from=${startTime}&to=${endTime}&status=UNRESOLVED&sortBy=generatedOn&direction=DESC&size=${pageSize}&page=0`
  );
};

export const GET_ACTIVITY_REPORT_DATA_SOURCE_URL = (startTime, endTime, pageSize) => {
  return (
    domain_vizr +
    `/tenant/dataSources?startTime=${startTime}&endTime=${endTime}&size=${pageSize}&page=0`
  );
};

export const GET_ACTIVITY_REPORT_ATTENTION_DATA_SOURCE_URL = (
  startTime,
  endTime,
  pageSize
) => {
  return (
    domain_vizr +
    `/tenant/dataSources?startTime=${startTime}&endTime=${endTime}&password=password&size=${pageSize}&page=0`
  );
};

export const GET_DATASOURCE_DATA = (dataSourceId) => {
  return (
    domain_vizr + `/tenant/dataSource/${dataSourceId}`
  );
};

export const GET_ACTIVITY_REPORT_ATTENTION_DATA_SOURCE_USER_URL = (
  startTime,
  endTime,
  pageSize,
) => {
  return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&enterpriseActorCreatedTimeFrom=${startTime}&enterpriseActorCreatedTimeTo=${endTime}&status=ACCESS_TO_BE_VERIFIED,ACCESS_EXPIRED,ACCESS_MARKED_FOR_DELETION,ACCESS_REQUESTED,ACCESS_REVOKED,ACCESS_APPROVED&size=${pageSize}&page=0`;
};

//Get all users:
export const ALL_USERS = (tenant, size, page) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/users?size=${size ? size : 20}&page=${
      page ? page : 0
    }`;
  } else {
    return `${domain_vizr}/tenant/users?size=${size ? size : 20}&page=${
      page ? page : 0
    }`;
  }
};
export const ALL_USERS_POST = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/users`;
  } else {
    return `${domain_vizr}/tenant/users`;
  }
};

export const GET_TENANT_BY_FEATURES = (tenantId) => {
  return `${domain_vizr}/tenant/${tenantId}/features`;
};
export const GET_TENANT_LOGO = (tenantId) => {
  return `${domain_vizr}/tenant/${tenantId}/logo`;
};

//get all the Tenants available:
export const GET_TENANTS = `${domain_vizr}/platform/tenants?size=200&page=0`;
export const GET_DELETEDTENANTS = `${domain_vizr}/platform/tenants?deleted=true`;

export const PLATFORM_ADMIN_TENANT_ONBOARD_URL = `${domain_vizr}/platform/tenants`;
export const GET_TENANT_DETAIL_URL = `${domain_vizr}/platform/tenants`;
export const PLATFORM_ADMIN_TENANT_UPDATE_URL = `${domain_vizr}/platform/tenants`;
export const DELETE_TENANT_URL = `${domain_vizr}/platform/tenants`;
export const GET_FEATURES = `${domain_vizr}/platform/features/summary`;
export const GET_LICENSES = `${domain_vizr}/platform/licenseTypes`;
export const VALIDATE_SHORTNAME = (name) => {
  return `${domain_vizr}/platform/tenants/${name}/findByShortName`;
};

//get all roles:
export const ALL_ROLES = (tenant, size, page) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/roles?size=${size ? size : 20}&page=${
      page ? page : 0
    }`;

    // return `${domain_vizr}/platform/roles`;
  } else {
    return `${domain_vizr}/tenant/roles?size=${size ? size : 20}&page=${
      page ? page : 0
    }`;
  }
};
export const ROLES = (tenant, id) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/roles/${id}`;
  } else {
    return `${domain_vizr}/tenant/roles/${id}`;
  }
};

export const TENANT_MANAGEMENT_URL = (queryParam) => {
  return `/platform/tenantManagement/${queryParam.id}`;
};

export const CreateConcentratorSettings = (id) => {
  return `/platform/tenantManagement/${id}/CreateConcentratorSettings`;
};

export const CreateIpSettings = (id) => {
  return `/platform/tenantManagement/${id}/CreateIpSettings`;
};

export const CreateEndpointSettings = (id) => {
  return `/platform/tenantManagement/${id}/CreateEndpointSettings`;
};
//setPassword

export const SET_PASSWORD = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/users/activation`;
  } else {
    return `${domain_vizr}/tenant/users/activation`;
  }
};

//resetPassword
export const RESET_PASSWORD = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/passwords/resetPassword`;
  } else {
    return `${domain_vizr}/tenant/passwords/resetPassword`;
  }
};

export const RESETPASSWORD_MAIL = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/passwords/resetRequests`;
  } else {
    return `${domain_vizr}/tenant/passwords/resetRequests`;
  }
};

//Get all agents

export const GET_AGENT_TYPES = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/lookups/agentTypes`;
  } else {
    return `${domain_vizr}/tenant/agentTypes`;
  }
};

//Get Platforms
export const GET_ALLPLATFORMS = (tenant, AGENT_ID) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/agentDefinitions?agentType=${AGENT_ID}`;
  } else {
    return `${domain_vizr}/tenant/agentDefinitions?agentType=${AGENT_ID}`;
  }
};

export const GET_ALL_LICENSES = () => {
  return `${domain_vizr}/platfrom/licenseTypes`;
};

//permissions
export const addAgent = "VIEW_AGENT";
export const createAgent = "ADD_AGENT";
export const VIEW_AGENT_PLATFORM_TYPE = "VIEW_AGENT_PLATFORM_TYPE";
export const addDataFlowGroup = "ADD_DATA_FLOW_GROUP";
export const addPermission = "ADD_PERMISSION";
export const addTenantRole = "ADD_TENANT_ROLE";
export const addTenantUser = "ADD_TENANT_USER";
export const attachDataFlowGroup = "ATTACH_DATA_FLOW_GROUP";
export const attachPermission = "ATTACH_PERMISSION";
export const createAlertPolicy = "CREATE_ALERT_POLICY";
export const deleteAgent = "DELETE_AGENT";
export const deleteAlert = "DELETE_ALERT";
export const deleteAlertComment = "DELETE_ALERT_COMMENT";
export const deleteAlertPolicyPermission = "DELETE_ALERT_POLICY";
export const deleteAlertPolicyComment = "DELETE_ALERT_POLICY_COMMENT";
export const deleteDataFlow = "DELETE_DATA_FLOW";
export const deleteDataFlowGroup = "DELETE_DATA_FLOW_GROUP";
export const deletePermission = "DELETE_PERMISSION";
export const deleteSettings = "DELETE_SETTINGS";
export const deleteTenantRole = "DELETE_TENANT_ROLE";
export const deleteTenantUser = "DELETE_TENANT_USER";
export const deleteTenantUserPreferences = "DELETE_TENANT_USER_PREFERENCES";
export const postAlertComment = "POST_ALERT_COMMENT";
export const postAlertPolicyComment = "POST_ALERT_POLICY_COMMENT";
export const saveSettings = "SAVE_SETTINGS";
export const saveTenantUserPreferences = "SAVE_TENANT_USER_PREFERENCES";
export const updateAgent = "UPDATE_AGENT";
export const updateAlert = "UPDATE_ALERT";
export const updateAlertComment = "UPDATE_ALERT_COMMENT";
export const updateAlertPolicy = "UPDATE_ALERT_POLICY";
export const updateAlertPolicyComment = "UPDATE_ALERT_POLICY_COMMENT";
export const updateDataFlow = "UPDATE_DATA_FLOW";
export const updateDataFlowGroup = "UPDATE_DATA_FLOW_GROUP";
export const updatePermission = "UPDATE_PERMISSION";
export const updateTenantRole = "UPDATE_TENANT_ROLE";
export const updateTenantRolePermissions = "UPDATE_TENANT_ROLE_PERMISSIONS";
export const updateTenantUser = "UPDATE_TENANT_USER";
export const updateTenantUserRoles = "UPDATE_TENANT_USER_ROLES";
export const viewAgent = "VIEW_AGENT";
export const viewAgentDefinition = "VIEW_AGENT_DEFINITION";
export const viewAgentStatus = "VIEW_AGENT_STATUS";
export const viewAgentType = "VIEW_AGENT_TYPE";
export const viewAggregationType = "VIEW_AGGREGATION_TYPE";
export const viewAlert = "VIEW_ALERT";
export const viewAlertComment = "VIEW_ALERT_COMMENT";
export const viewAlertPolicy = "VIEW_ALERT_POLICY";
export const viewAlertPolicyComment = "VIEW_ALERT_POLICY_COMMENT";
export const viewAlertPolicySeverity = "VIEW_ALERT_POLICY_SEVERITY";
export const viewAlertPolicyStatus = "VIEW_ALERT_POLICY_STATUS";
export const viewAlertResolutionType = "VIEW_ALERT_RESOLUTION_TYPE";
export const viewAlertStatus = "VIEW_ALERT_STATUS";
export const viewApplication = "VIEW_APPLICATION";
export const viewComparisonType = "VIEW_COMPARISON_TYPE";
export const viewDashboardTemplate = "VIEW_DASHBOARD_TEMPLATE";
export const viewDataMap = "VIEW_DATA_MAP";
export const viewDataFlow = "VIEW_DATA_FLOW";
export const viewDataFlowGroup = "VIEW_DATA_FLOW_GROUP";
export const viewDataSensitivityCategory = "VIEW_DATA_SENSITIVITY_CATEGORY";
export const viewDataSource = "VIEW_DATA_SOURCE";
export const reviewDataSource = "UPDATE_DATA_SOURCE";
export const viewPermission = "VIEW_PERMISSION";
export const viewSetting = "VIEW_SETTINGS";
export const viewTenantRole = "VIEW_TENANT_ROLE";
export const viewTenantStatus = "VIEW_TENANT_STATUS";
export const viewTenantUser = "VIEW_TENANT_USER";
export const viewTenantUserPreferences = "VIEW_TENANT_USER_PREFERENCES";
export const viewTimeWindowType = "VIEW_TIME_WINDOW_TYPE";
export const viewTriggerCategory = "VIEW_TRIGGER_CATEGORY";
export const addTenant = "ADD_TENANT";
export const addPlatformUser = "ADD_PLATFORM_USER";
export const viewTenants = "VIEW_TENANT";
export const updateTenant = "UPDATE_TENANT";
export const viewPlatformUser = "VIEW_PLATFORM_USER";
export const updatePlatformUser = "UPDATE_PLATFORM_USER";
export const deletePlatformUser = "DELETE_PLATFORM_USER";
export const addAgentType = "ADD_AGENT_TYPE";
export const UPDATE_SENSITIVITY_INFO_CATEGORY = 'UPDATE_SENSITIVITY_INFO_CATEGORY';
export const MDR_CONFIG = 'MDR_CONFIG';
export const DELETE_TENANT = 'DELETE_TENANT';
export const ACTIVATE_ACCOUNT_RESET_CLICK = (tenantId) => {
  return `${domain_vizr}/platform/onboard/tenants/${tenantId}/sendAccountActivationLink`;
};

// export const updateTenant = "UPDATE_TENANT";

//Get Unmasked Instructions

export const GET_UNMASKED_INSTRUCTIONS = (tenant, AGENT_ID, mfaCode) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/users/activation`;
  } else {
    return `${domain_vizr}/tenant/agentDefinitions/${AGENT_ID}/unmask?verificationCode=${mfaCode}`;
  }
};

export const DEL_USERS = (tenant, userId) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/users/${userId}`;
  } else {
    return `${domain_vizr}/tenant/users/${userId}`;
  }
};

export const GET_SINGLE_USER = (tenant, userId) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/users/${userId}`;
  } else {
    return `${domain_vizr}/tenant/users/${userId}`;
  }
};

// all the permissions

export const APICALL_PERMISSIONS = (tenant, size) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/permissions?size=100`;
  } else {
    return `${domain_vizr}/tenant/permissions?size=100`;
  }
};

//Update the permissons

export const UPDATE_PERMISSIONS = (tenant, roleId) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/roles/${roleId}/permissions`;
  } else {
    return `${domain_vizr}/tenant/roles/${roleId}/permissions`;
  }
};

//Agents
export const AGENTS = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/agentDefinitions`;
  } else {
    return `${domain_vizr}/tenant/agentDefinitions`;
  }
};

//Platform Types
export const PLATFORM_TYPES = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/lookups/agentPlatformTypes`;
  } else {
    return `${domain_vizr}/tenant/lookups/agentPlatformTypes`;
  }
};

export const DOWNLOAD_AGENT = (fileName) => {
  return `${domain_vizr}/tenant/agent/artifacts/download?fileName=${fileName}`;
};

//USERS DELETE
export const USERS_DELETE = "USERS_DELETE";
export const ROLES_DELETE = "ROLES_DELETE";
export const RESET_DELETESTATE = "RESET_DELETESTATE";

export const UPDATE_PASSWORD = (tenant) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/passwords/updatePassword`;
  } else {
    return `${domain_vizr}/tenant/passwords/updatePassword`;
  }
};
export const DATABASE_USERS = (tenant, size, page, sortByValue) => {
  if (tenant === "druvstar" || tenant === "datavision") {
    return `${domain_vizr}/platform/databases/actors?actorType=DATABASE&size${
      size ? size : 20
    }&page=${page ? page : 0}&sortBy=${sortByValue}&direction=ASC`;
  } else {
    return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&size=${
      size ? size : 20
    }&page=${page ? page : 0}&sortBy=${sortByValue}&direction=ASC`;
  }
};

export const SERVER_ERROR_MESSAGE = "Server Error, Please try later.";

export const SET_GENERATED_PASSWORD = `${domain_vizr}/tenant/passwords/updateUserPassword`;

export const USERACCESSRIGHTS_FILTERBY = "USERACCESSRIGHTS_FILTERBY";
export const USERACCESS_BATCHEDIT = "USERACCESS_BATCHEDIT";

export const OPEN_ACCESSDRAWER = "OPEN_ACCESSDRAWER";
export const CLOSE_ACCESSDRAWER = "CLOSE_ACCESSDRAWER";
export const CHANGE_EXPIRYDATE = "CHANGE_EXPIRYDATE";
export const updateTimeStamps = `${domain_vizr}/tenant/databases/access/expirationTimestamp`;
export const ApiCall_DataSourceNames = `${domain_vizr}/tenant/dataSourceNames`;
export const ApiCall_GET_ROLES = `${domain_vizr}/tenant/databases/getDatabaseAccessRequestStatus`;
export const UPDATE_ROLE_API = (accessStatus, id) => {
  return `${domain_vizr}/tenant/databases/accessRequest/${id}/updateStatus/${accessStatus}`;
};
export const ApiCall_DataSourceNames_Filter = (params) => {
  return `${domain_vizr}/tenant/databases/actors?dataSourceNames=${params}`;
};
export const ApiCall_DataSourceNames_Filter_AccessDetails = (params) => {
  return `${domain_vizr}/tenant/databases/accessDetails?dataSourceNames=${params}`;
};
export const ApiCall_DataAccessRole_Filter = (params) => {
  return `${domain_vizr}/tenant/databases/actors?databaseAccessRoles=${params}`;
};
export const ApiCall_DataAccessRole_Filter_AccesDetails = (params) => {
  return `${domain_vizr}/tenant/databases/accessDetails?databaseAccessRoles=${params}`;
};
export const ApiCall_DataSourceType_Filter_AccesDetails = (params) => {
  return `${domain_vizr}/tenant/databases/accessDetails?dataSourceTypes=${params}`;
};

export const ApiCall_DataAccessAccountStatus_Filter = (params) => {
  return `${domain_vizr}/tenant/databases/actors?status=${params}`;
};

export const ApiCall_dataSourceType = (params) => {
  return `${domain_vizr}/tenant/databases/actors?dataSourceTypes=${params}`;
};
export const ApiCall_DataSourceTypes = `${domain_vizr}/tenant/dataSourceTypes`;
export const ApiCall_DatabaseRoles = `${domain_vizr}/tenant/databaseRoles`;
export const ApiCall_AccountStatus = `${domain_vizr}/tenant/databaseRequestStatus`;
export const ApiCall_lastAccessedTime = `${domain_vizr}/tenant/databases/accessDetails?lastAccessedTime`;
export const ApiCall_AccountStatus_On_Role = `${domain_vizr}/tenant/database/accountStates`;

export const create_data_source = () => {
  return `${domain_vizr}/tenant/datasources`;
};

export const get_client_id = (type) => {
  return `${domain_vizr}/tenant/dataSources/${type}/clientId`;
};

export const ACTORS_SEARCH = (queryString) => {
  return `${domain_vizr}/tenant/databases/actors/search?queryString=${queryString}&actorType=DATABASE`;
};

export const ACCESS_DETAILS = `${domain_vizr}/tenant/databases/accessDetails`;
export const SERVICE_DATA_ACCESS = `${domain_vizr}/tenant/serviceData/serviceUserAccessDetails`;
export const SERVICE_DATAUSERS_SENSITIVITY_PERCENTAGE = `${domain_vizr}/tenant/serviceData/serviceDataUserSensitivityPercentage`;
export const Create_Data_Source_Users = `${domain_vizr}/tenant/databases/actors`;
export const Get_Data_Source_Roles = (dataSourceId) => {
  return `${domain_vizr}/tenant/dataSources/${dataSourceId}/databaseRoles`;
};

export const SENSITIVE_ACCESS = (
  actorId,
  dataSourceId,
  size,
  page,
  lastAccessedTimeStamp,
  requestFor
) => {
  if (requestFor) {
    return `${domain_vizr}/tenant/databases/actors/${actorId}/dataSources/${dataSourceId}/sensitiveDataAccess?lastAccessedTimeStamp=${lastAccessedTimeStamp}&size=${
      size ? size : 20
    }&page=${page ? page : 0}&requestFor=${requestFor}`;
  }
  return `${domain_vizr}/tenant/databases/actors/${actorId}/dataSources/${dataSourceId}/sensitiveDataAccess?lastAccessedTimeStamp=${lastAccessedTimeStamp}&size=${
    size ? size : 20
  }&page=${page ? page : 0}`;
};

export const ALL_ACCESS_DATA_TOTAL_COUNT = "ALL_ACCESS_DATA_TOTAL_COUNT";
export const GET_ALL_ACCESS_TOTAL_COUNT = "GET_ALL_ACCESS_TOTAL_COUNT";
export const CLEAR_ALL_ACCESS_TOTAL_COUNT = "CLEAR_ALL_ACCESS_TOTAL_COUNT";
export const SET_TOTAL_COUNT_LOADER = "SET_TOTAL_COUNT_LOADER";

export const ALL_ACCESS_FILEPOPUP = (
  nodeId,
  fieldId,
  size,
  page,
  lastAccessedTimeStamp,
  nodeSubType
) => {
  return `${domain_vizr}/tenant/databases/node/${nodeId}/allAccessesFile?size=${size ? size : 20}&page=${page ? page : 0}&sensitivityId=${fieldId}&lastAccessedTimeStamp=${lastAccessedTimeStamp}&dataSourceType=${nodeSubType}`;
};
export const ALL_ACCESS = (
  actorId,
  dataSourceId,
  size,
  page,
  lastAccessedTimeStamp,
  requestFor
) => {
  return `${domain_vizr}/tenant/databases/actors/${actorId}/dataSources/${dataSourceId}/allAccesses?lastAccessedTimeStamp=${lastAccessedTimeStamp}&size=${
    size ? size : 20
  }&page=${page ? page : 0}&requestFor=${requestFor}`;
};

export const ALL_ACCESS_TOTAL_COUNT = (actorId, dataSourceId, lastAccessedTimeStamp) => {
  //return the total records count of all access
  return `${domain_vizr}/tenant/databases/actors/${actorId}/dataSources/${dataSourceId}/allAccesses/totalCount?lastAccessedTimeStamp=${lastAccessedTimeStamp}`
};

export const ACCESS_ANOMALIES = (actorId, dataSourceId, size, page) => {
  return `${domain_vizr}/tenant/databases/actors/${actorId}/dataSources/${dataSourceId}/accessAnomalies?size=${
    size ? size : 20
  }&page=${page ? page : 0}`;
};

export const DATASOURCE_DETAILS = (actorId) => {
  return `${domain_vizr}/tenant/databases/actors/${actorId}/dataSourceDetails?size=20&page=0`;
}; 
  
export const sortBy_Filter_DatabaseUsers = (
  filterType,
  sortType,
  filterConditions,
  sortDirection,
  pageSize,
  pageNumber,
  expiryFrom,
  expiryTo
) => {
  if (filterConditions.length > 0 && sortType) {
    return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&${filterType}=${filterConditions}&sortBy=${sortType}&direction=${sortDirection}&size=${
      pageSize ? pageSize : 100
    }&page=${pageNumber ? pageNumber : 0}`;
  } else if (expiryFrom && expiryTo && sortType) {
    return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&expiryFrom=${expiryFrom}&expiryTo=${expiryTo}&sortBy=${sortType}&direction=${sortDirection}&size=${
      pageSize ? pageSize : 100
    }&page=${pageNumber ? pageNumber : 0}`;
  } else if (expiryFrom && expiryTo) {
    return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&expiryFrom=${expiryFrom}&expiryTo=${expiryTo}&size=${
      pageSize ? pageSize : 100
    }&page=${pageNumber ? pageNumber : 0}`;
  } else if (filterConditions.length > 0) {
    return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&${filterType}=${filterConditions}&size=${
      pageSize ? pageSize : 100
    }&page=${pageNumber ? pageNumber : 0}`;
  } else if (sortType) {
    return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&sortBy=${sortType}&direction=${sortDirection}&size=${
      pageSize ? pageSize : 100
    }&page=${pageNumber ? pageNumber : 0}`;
  } else {
    return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&size=${
      pageSize ? pageSize : 100
    }&page=${pageNumber ? pageNumber : 0}`;
  }

  // return `${domain_vizr}/tenant/databases/actors?sortBy=username&direction=${sortDirection}`;
};

export const sortBy_Filter_AccessDetails = (
  filterType,
  sortType,
  filterConditions,
  sortDirection,
  pageSize,
  pageNumber
) => {
  // return `${domain_vizr}/tenant/databases/accessDetails?${filterType}=${filterConditions}&sortBy=${sortType}&direction=${sortDirection}`;
  if (filterConditions.length > 0 && sortType) {
    return `${domain_vizr}/tenant/databases/accessDetails?${filterType}=${filterConditions}&sortBy=${sortType}&direction=${sortDirection}&size=${
      pageSize ? pageSize : 20
    }&page=${pageNumber ? pageNumber : 0}`;
  } else if (filterConditions.length > 0) {
    return `${domain_vizr}/tenant/databases/accessDetails?${filterType}=${filterConditions}&size=${
      pageSize ? pageSize : 20
    }&page=${pageNumber ? pageNumber : 0}`;
  } else if (sortType) {
    return `${domain_vizr}/tenant/databases/accessDetails?sortBy=${sortType}&direction=${sortDirection}&size=${
      pageSize ? pageSize : 20
    }&page=${pageNumber ? pageNumber : 0}`;
  } else {
    return `${domain_vizr}/tenant/databases/accessDetails?size=${
      pageSize ? pageSize : 20
    }&page=${pageNumber ? pageNumber : 0}`;
  }
};

export const sortBy_Filter_AccessDetails_Percentage = () => {
  return `${domain_vizr}/tenant/databases/sensitivityPercentage`;
};

export const SET_ACCESSREQUESTID = "SET_ACCESSREQUESTID";
export const FILTERBY_UPDATED = "FILTERBY_UPDATED";
export const DATABASEUSERS_CLEARALL = "DATABASEUSERS_CLEARALL";
export const FILTERBY_SELECTEDVALUES = "FILTERBY_SELECTEDVALUES";
export const CHANGE_SORTBYVALUE = "CHANGE_SORTBYVALUE";
export const SINGLEUSER_EXPIRYDATEEDITCLICK = "SINGLEUSER_EXPIRYDATEEDITCLICK";
export const DATABASE_USERSUPDATE = "DATABASE_USERSUPDATE";

export const ACCESS_DETAILS_FILTERVALUES = {
  DATASOURCE_NAME: "dataSourceNames",
  DATASOURCE_TYPE: "dataSourceTypes",
  DATABASE_ACCESSROLE: "databaseAccessRoles",
  LASTTIME_ACCESSED: "lastAccessedTime",
};

export const CHANGE_FILTERBYVALUE_ACCESSDETAILS =
  "CHANGE_FILTERBYVALUE_ACCESSDETAILS";
export const CHANGE_ACCESSDETAILS_FILTERBYARR =
  "CHANGE_ACCESSDETAILS_FILTERBYARR";

export const ACCESSDETAILS_FILTERBYARR_SELECTEDVALUES =
  "ACCESSDETAILS_FILTERBYARR_SELECTEDVALUES";

export const ACCESSDETAILS_SORTBYVALUE_UPDATE =
  "ACCESSDETAILS_SORTBYVALUE_UPDATE";

export const TOGGLE_INCIDENT_CHART = "TOGGLE_INCIDENT_CHART";
export const INCIDENT_CHANGEGROUPING = "INCIDENT_CHANGEGROUPING";

export const UPDATE_INCIDENTPRIORITY_FILTER_DATA =
  "UPDATE_INCIDENTPRIORITY_FILTER_DATA";
export const UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA =
  "UPDATE_INCIDENT_EFFECTIVESTATUS_FILTER_DATA";
export const UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA =
  "UPDATE_INCIDENT_STATE_DETAILS_FILTER_DATA";
export const UPDATE_INCIDENT_NAME_FILTER_DATA =
  "UPDATE_INCIDENT_NAME_FILTER_DATA";
export const APPLY_INCIDENTTABLE_FILTER = "APPLY_INCIDENTTABLE_FILTER";
export const INCIDENT_STATE_FILTER_CLEAR_DATA = "INCIDENT_STATE_FILTER_CLEAR_DATA";
export const INCIDENT_STATE_DETAILS_FILTER_CLEAR_DATA = "INCIDENT_STATE_DETAILS_FILTER_CLEAR_DATA";
export const SET_INCIDENT_STATE_APPLIED_VALUE = "SET_INCIDENT_STATE_APPLIED_VALUE";
export const RESET_INCIDENT_TABLE_DATA = "RESET_INCIDENT_TABLE_DATA";
export const SET_INCIDENT_STATE_VALUE = "SET_INCIDENT_STATE_VALUE";
export const UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA = "UPDATE_INCIDENT_ASSIGNEE_FILTER_DATA"
export const incidentTableFilters = {
  Priority: "Priority",
  Assignee: "Assignee",
  EffectiveStatus: "EffectiveStatus",
  StateDetails:"StateDetails",
  incidentId: "incidentId",
  Name: "Name",
  Country: "Country",
  City: "City",
};
export const CACHE_INCIDENT_TABLE_DATA = "CACHE_INCIDENT_TABLE_DATA";
export const STATE = "state";
export const APPLY_CACHED_INCIDENT_TABLE_DATA = "APPLY_CACHED_INCIDENT_TABLE_DATA";
export const UPDATE_INCIDENT_TABLE_AS_PER_DATERANGE = "UPDATE_INCIDENT_TABLE_AS_PER_DATERANGE";
export const UPDATE_INCIDENT_COUNTRY_FILTER_DATA =
  "UPDATE_INCIDENT_COUNTRY_FILTER_DATA";
export const CLEAR_CURRENT_STATUS = "CLEAR_CURRENT_STATUS";

export const incidentTableFilter_Query_toGet_FilterValues = (startDate, endDate) => ({
  priorityFilter: {
    dimensions: ["Incidents.priority"],
    filters: [
      {
        member: `Incidents.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `Incidents.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  assigneeFilter: {
    dimensions: ["Incidents.assignee"],
    filters: [
      {
        member: `Incidents.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `Incidents.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  effectiveStatusFilter: {
    dimensions: ["Incidents.status"],
    filters: [
      {
        member: `Incidents.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `Incidents.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  stateDetailsFilter: {
    dimensions: ["Incidents.stateDetails"],
    filters: [
      {
        member: `Incidents.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `Incidents.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  incidentIdFilter: {
    dimensions: ["Incidents.incidentId"],
    filters: [
      {
        member: `Incidents.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `Incidents.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  nameFilter: {
    dimensions: ["Incidents.name"],
    filters: [
      {
        member: `Incidents.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `Incidents.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  countryFilter: {
    dimensions: ["IncidentsTable.city"],
    filters: [
      {
        member: `IncidentsTable.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `IncidentsTable.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
});

export const AuthenticationTableFilter_Query_toGet_FilterValues = (startDate, endDate) => ({
  source: {
    dimensions: ["MdrAuthentication.authErrorSource"],
    filters: [
      {
        member: `MdrAuthentication.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `MdrAuthentication.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
});
export const GET_AUTHENTICATION_SOURCE_FILTER =
  "GET_AUTHENTICATION_SOURCE_FILTER";

export const APPLY_AUTHENTICATION_FILTER = "APPLY_AUTHENTICATION_FILTER";

export const endPoints_Query_toGet_FilterValues = (startDate, endDate) => ({
  environmentFilter: {
    dimensions: ["MDREndPoints.environment"],
    filters: [
      {
        member: `MDREndPoints.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `MDREndPoints.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  osDescriptionFilter: {
    dimensions: ["MDREndPoints.osDescription"],
    filters: [
      {
        member: `MDREndPoints.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `MDREndPoints.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
});

export const patchMonitoring_Query_toGet_FilterValues = (startDate, endDate) => ({
  ClassificationFilter: {
    dimensions: ["MDRPatchManagement.classification"],
    filters: [
      {
        member: `MDRPatchManagement.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `MDRPatchManagement.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  SeverityFilter: {
    dimensions: ["MDRPatchManagement.severity"],
    filters: [
      {
        member: `MDRPatchManagement.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `MDRPatchManagement.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  UpdateStateFilter: {
    dimensions: ["MDRPatchManagement.updateState"],
    filters: [
      {
        member: `MDRPatchManagement.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `MDRPatchManagement.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
  SubscriptionNameFilter: {
    dimensions: ["MDRPatchManagement.subscriptionName"],
    filters: [
      {
        member: `MDRPatchManagement.incidentTime`,
        operator: "gte",
        values: [`${startDate}`],
      },
      {
        member: `MDRPatchManagement.incidentTime`,
        operator: "lte",
        values: [`${endDate}`],
      },
    ],
  },
});
export const incidentDashboardPieChart = [
  "Incidents.priority",
  "Incidents.count",
];

export const AuthenticationFailedSourcePieChart = [
  "MdrAuthentication.authErrorSource",
  "MdrAuthentication.count",
];

export const AuthenticationFailedIpPieChart = [
  "MdrAuthentication.ipAddress",
  "MdrAuthentication.count",
];

export const IncidentAreaGraph = (startDate, endDate) => {
  let incidentAreaGraphQuery = {
    chartType: "MDRAreaGraph",
    query: {
      dimensions: ["Incidents.resolved"],
      timeDimensions: [
        {
          dimension: "Incidents.creation_time",
          granularity: "day",
          dateRange: "Today",
        },
      ],
      order: [["Incidents.creation_time", "asc"]],
      filters: [
        {
          member: `Incidents.incidentTime`,
          operator: "gte",
          values: [`${startDate}`],
        },
        {
          member: `Incidents.incidentTime`,
          operator: "lte",
          values: [`${endDate}`],
        },
      ],
    },
  };
  return incidentAreaGraphQuery;
};

export const incidentDashboardBarChart = (startDate, endDate) => {
  return {
    chartType: "MDRBarGraph",
    query: {
      dimensions: ["Incidents.priority"],
      timeDimensions: [
        {
          dimension: "Incidents.creation_time",
          granularity: "day",
          dateRange: "Today",
        },
      ],
      order: {
        "Incidents.priority": "asc",
      },
      measures: ["Incidents.count"],
      filters: [
        {
          member: `Incidents.incidentTime`,
          operator: "gte",
          values: [`${startDate}`],
        },
        {
          member: `Incidents.incidentTime`,
          operator: "lte",
          values: [`${endDate}`],
        },
      ],
    },
  };
};

export const FailedLoginsAllUsers = "FailedLoginsAllUsers";
export const FailedLoginsIPbyDistinctUsers = "FailedLoginsIPbyDistinctUsers";
export const FailedLoginsIPbyDistinctIP = "FailedLoginsIPbyDistinctIP";

export const UPDATE_MDRENDPOINTS_GROUPINGVALUE =
  "UPDATE_MDRENDPOINTS_GROUPINGVALUE";

export const UPDATE_MDRENDPOINTS_CURRENTINDEX =
  "UPDATE_MDRENDPOINTS_CURRENTINDEX";

export const UPDATE_INCIDENTID_FILTER_DATA = "UPDATE_INCIDENTID_FILTER_DATA";

export const UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA =
  "UPDATE_ENDPOINTS_ENVIRONMENT_FILTERDATA";
export const UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA =
  "UPDATE_ENDPOINTS_OSDESCRIPTION_FILTERDATA";
export const APPLY_ENDPOINTS_FILTER = "APPLY_ENDPOINTS_FILTER";

export const UPDATE_PATCHMONITORING_CLASSIFICATION_FILTERDATA =
  "UPDATE_PATCHMONITORING_CLASSIFICATION_FILTERDATA";
export const UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA =
  "UPDATE_PATCHMONITORING_SEVERITY_FILTERDATA";
export const UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA =
  "UPDATE_PATCHMONITORING_UPDATESTATE_FILTERDATA";
export const UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA =
  "UPDATE_PATCHMONITORING_SUBSCRIPTIONNAME_FILTERDATA";

export const MDRIncidentSearchDropdownValues = [
  {
    field: "Description",
    value: "IncidentsTable.name",
    query: {
      member: "IncidentsTable.name",
      operator: "contains",
      values: [],
    },
  },
  {
    field: "Incident ID",
    value: "IncidentsTable.incidentId",
    query: {
      member: "IncidentsTable.incidentId",
      operator: "contains",
      values: [],
    },
  },
];

export const MDRAuthenticationAllUsers = [
  {
    field: "IP Address",
    value: "MdrAuthentication.ipAddress",
  },
  {
    field: "Username",
    value: "MdrAuthentication.userName",
  },
  {
    field: "Reason For Failures",
    value: "MdrAuthentication.result",
  },
];
export const MDRAuthenticationUniqueUsers = [
  {
    field: "Username",
    value: "MDRAuthenticationDistinctUsers.userName",
  },
  {
    field: "IP Address",
    value: "MDRAuthenticationDistinctUsers.ipAddress",
  },
];
export const MDRAuthenticationUniqueIps = [
  {
    field: "Username",
    value: "MDRAuthenticationDistinctIP.userName",
  },
  // {
  //   field: "IP Address Count",
  //   value: "MDRAuthenticationDistinctIP.ipAdressCount",
  // },
];

export const MDREndpointsSearch = [
  {
    field: "Host Name",
    value: "MDREndPoints.hostName",
  },
  {
    field: "IP Address",
    value: "MDREndPoints.ipv4",
  },
  {
    field: "Username",
    value: "MDREndPoints.userName",
  },
];

export const MDRPatchMonitoringSearch = [
  {
    field: "Computer",
    value: "MDRPatchManagement.server",
  },
  {
    field: "KBID",
    value: "MDRPatchManagement.kbid",
  },
  {
    field: "Title",
    value: "MDRPatchManagement.title",
  },
];

export const HISTROY_DATA_SOURCE = (actorId, dataSourceId) => {
  return `${domain_vizr}/tenant/databases/getDARChangeHistory/${actorId}/dataSources/${dataSourceId}`;
};

export const resetFirstPasswordContent =
  "Your account has now been activated. As the Data Vision platform supports Multi-Factor authentication (MFA) and in situations where your administrator enables MFA for all users of your organization, it is recommended to install virtual authenticator applications like Google Authenticator or Microsoft Authenticator. Once installed, add an account by scanning the below QR code. Click the below link to login to Data Vision platform.";

export const resetPasswordContent =
  "Your password has been updated successfully. Scan/rescan the below QR code to enable/update Multi-Factor authentication (MFA). As the Data Vision platform supports MFA and in situations where your administrator enables MFA for all users of your organization, it is recommended to install virtual authenticator applications like Google Authenticator or Microsoft Authenticator. Once installed, add an account by scanning the below QR code. Click the below link to login to Data Vision platform.";

export const dateFormat = "MM/DD/YYYY";
export const viewDatabaseLogs="VIEW_DATABASE_LOGS";

//database logs
// export const viewDatabaseLogs = "VIEW_ALERT";
export const attachEdition = "ATTACH_EDITION";
export const GET_DATABASE_LOGS = "GET_DATABASE_LOGS";
export const GET_DATABASE_LOGS_URL = domain_vizr + "/tenant/databaseLogs";
export const GET_DATABASE_LOGS_NO_EXEC_URL = domain_vizr + "/tenant/databaseLogs/noOfExecutions";
export const GET_DATABASE_LOGS_COUNT_URL =
  domain_vizr + "/tenant/databaseLogs/totalDatabaselogsCount";
export const CHANGE_FILTERBYVALUE_DATABASELOGS =
  "CHANGE_FILTERBYVALUE_DATABASELOGS";
export const CHANGE_FILTERBYVALUE_DATABASELOGS_ACTION =
  "CHANGE_FILTERBYVALUE_DATABASELOGS_ACTION";
export const database_Sensitivity_Fields =
  domain_vizr + "/tenant/lookups/dataSensitivityCategories?page=0&size=0";
export const DATABASE_LOGS_FILTERVALUES = {
  DATASOURCE_NAME: "dataSource",
  DATASOURCE_TYPE: "dataSourceType",
  DATABASE_ACCESSROLE: "databaseAccessRole",
  ALERT_ID: "alertId",
  POLICY_NAME: "alertPolicyId",
  SENSITIVITY_LEVEL: "sensitivityLevel",
  TIME_RANGE: "timeRange",
};
export const GET_SERVICE_ACCESS_LOGS =
  domain_vizr + "/tenant/serviceAccessLogs/getAllLogs";
export const GET_SERVICE_ACCESS_LOGS_COUNT_URL =
  domain_vizr + "/tenant/serviceAccessLogs/totalCountOfServiceAccessLogs";
export const GET_SERVICE_ACCESS_LOGS_DETAILS =
  domain_vizr + "/tenant/serviceAccessLogs/getAllServiceDataLogs";
export const UPDATED_DATABASE_LOGS_SELECTED_FILTER_VALUES =
  "UPDATED_DATABASE_LOGS_SELECTED_FILTER_VALUES";
export const DATABASE_SORT_CHANGE = "DATABASE_SORT_CHANGE";
export const DATABASE_CHANGE_ORDER_BY_VALUE = "DATABASE_CHANGE_ORDER_BY_VALUE";
export const SERVICE_DATA_LOGS_SORT_CHANGE = "SERVICE_DATA_LOGS_SORT_CHANGE";
export const SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE = "SERVICE_DATA_LOGS_CHANGE_ORDER_BY_VALUE";
export const sortBy_Filter_DatabaseLogs = (
  filterType,
  sortType,
  filterConditions,
  sortDirection,
  pageSize,
  pageNumber
) => {
  if (filterConditions.length > 0 && sortType) {
    return `${domain_vizr}/tenant/databaseLogs?${filterType}=${filterConditions}&sortBy=${sortType}&direction=${sortDirection}&size=${
      pageSize ? pageSize : 20
    }&page=${pageNumber ? pageNumber : 0}`;
  } else if (filterConditions.length > 0) {
    return `${domain_vizr}/tenant/databaseLogs?${filterType}=${filterConditions}&size=${
      pageSize ? pageSize : 20
    }&page=${pageNumber ? pageNumber : 0}`;
  } else if (sortType) {
    return `${domain_vizr}/tenant/databaseLogs?sortBy=${sortType}&direction=${sortDirection}&size=${
      pageSize ? pageSize : 20
    }&page=${pageNumber ? pageNumber : 0}`;
  } else {
    return `${domain_vizr}/tenant/databaseLogs?size=${
      pageSize ? pageSize : 20
    }&page=${pageNumber ? pageNumber : 0}`;
  }
};

export const GET_ACTIVITY_REPORT_DS_USERS = (startTime, endTime, pageSize) => {
  return `${domain_vizr}/tenant/databases/actors?actorType=DATABASE&enterpriseActorCreatedTimeFrom=${startTime}&enterpriseActorCreatedTimeTo=${endTime}&size=${pageSize}&page=0`;
};

export const GET_DATA_SOURCE_URL = `${domain_vizr}/tenant/databases/actors?actorType=DATABASE`;
export const GET_DATA_SOURCE_SEARCH_URL = `${domain_vizr}/tenant/databases/actors/search?`;
export const GET_USERSBY_DATASOURCE = `${domain_vizr}/tenant/databases/findTopSensitivityPercentageAccessUsers`;
export const DATABASE_LOGS_FROM_TIME = "DATABASE_LOGS_FROM_TIME";
export const DATABASE_LOGS_TO_TIME = "DATABASE_LOGS_TO_TIME";
export const DATABASE_TO_TIME_VALUE = "DATABASE_TO_TIME_VALUE";
export const SET_USERS = "SET_USERS";
export const SET_QUERY_STATEMENT = "SET_QUERY_STATEMENT";
export const DATABASE_FROM_TIME_VALUE = "DATABASE_FROM_TIME_VALUE";
export const DATABASE_LOGS_CLEAR_ALL = "DATABASE_LOGS_CLEAR_ALL";
export const SET_DATABASE_LOGS_API_VALUES = "SET_DATABASE_LOGS_API_VALUES";
export const DATABASE_LOGS_CLEAR_ALL_ACTION = "DATABASE_LOGS_CLEAR_ALL_ACTION";
export const dateFormatUAR = "MM-DD-YYYY";
export const DATABASE_LOGS_FINAL_FILTER = "DATABASE_LOGS_FINAL_FILTER";
export const SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE =
  "SERVICE_ACCESS_LOGS_DATABASE_TO_TIME_VALUE";
export const SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE =
  "SERVICE_ACCESS_LOGS_DATABASE_FROM_TIME_VALUE";

export const dataSource = "dataSourceNames";
export const dataSourceType = "dataSourceTypes";
export const databaseAccessRole = "databaseAccessRoles";
export const status = "status";
export const expiryDate = "expiryDate";

export const Concentrator = "concentrator";
export const Ip = "ip";
export const Endpoints = "endpoints";

export const AddConcentrator = "Add Concentrator";
export const AddForwardIPSubnets = "Add Forward IP/Subnets";
export const AddEndpointsConcentratorConfig =
  "Add Endpoints Concentrator Config";

export const createConcentrator = "create-concentrator";
export const createSubnet = "create-subnet";
export const createEndpoint = "create-endpoint";

export const FILTER_ID = "applicationFilterId"
export const MDR = "Threat Insights";
export const DDV = "Data Vision";
export const RISK = "Risk Governor";
export const DATA_MAP_URL = "/tenant/dataMap";
export const ALL_FILES_LIST = "/tenant/fileList";
export const DATA_OWNER_FILE_LIST = "/tenant/dataOwner";
export const GENERATE_REPORT = "/tenant/generateReport";
export const SET_ALL_NODE_DETAILS = "SET_ALL_NODE_DETAILS";
export const SET_TOOL_TIP_DATA = "SET_TOOL_TIP_DATA";
export const CLEAR_TOOL_TIP_DATA = "CLEAR_TOOL_TIP_DATA";
export const CLEAR_ALL_NODE_ID = "CLEAR_ALL_NODE_ID";
export const MDR_SETTINGS_UPDATE_LOCATION = "MDR_SETTINGS_UPDATE_LOCATION";
export const MDR_TENENT_IP_GEO_LOCATION_URL =
  domain_vizr + "/tenant/IPGeoLocation";
export const MDR_TENENT_ACCOUNT_ID_LOCATION_URL =
  domain_vizr + "/tenant/accountGeoLocation";
export const MDR_TENENT_PATCHMONITORING_CONFIGURATION_URL =
  domain_vizr + "/tenant/mdrPatchConfig";
export const MDRAccoutIdConfigFields = [
  "Account ID",
  "Country",
  "Region",
  "City",
  "Environment",
];

export const MDRAccountIdState = {
  accountId: "",
  country: "",
  region: "",
  city: "",
  environment: "",
  latitude: "",
  longitude: "",
};

export const MDRAccountIdArr = [
  "accountId",
  "country",
  "region",
  "city",
  "environment",
  "latitude",
  "longitude",
];

export const MDRIpConfigFields = [
  "IP",
  "Subnet Mask",
  "Country",
  "Region",
  "City",
];

export const MDRPatchMonitoringConfiguratiomFileds = {
  // "grantType",
  "Application (client) ID": "clientId",
  "Directory (tenant) ID": "tenantId",
  "Client secret value": "clientSecret",
  "Analytics Workspace ID": "workspaceId",
  // "accessToken"
};
export const MDRIpConfigState = {
  ip4Address: "",
  subnetMask: "",
  country: "",
  region: "",
  city: "",
  latitude: "",
  longitude: "",
};

export const MDRIpConfigArr = [
  "ip4Address",
  "subnetMask",
  "country",
  "region",
  "city",
  "latitude",
  "longitude",
];

export const QueryAuthErrorWhereCondition = "authErrorsWhereCondition";
export const QueryEventCountsWhereCondition = "eventCountsWhereCondition";
export const Environment = "environment";
export const RequiredField = "Required Field";
export const MDRIpConfigLoc = "mdr-ipConfig";
export const MDRAccountIdConfigLoc = "mdr-AccountId";
export const MDRPatchMonitoringConfiguration =
  "mdr-patchMonitoringCaonfiguration";
export const MDRPatchMonitoringConfigurationLoc =
  "mdr-patchmonitoringConfiguration";
export const GET_CONCENTRATOR_URL = `${domain_vizr}/tenant/nwService/serviceName/concentrator`;
export const CONCENTRATOR_URL = `${domain_vizr}/tenant/nwService`;
export const GET_IP_URL = `${domain_vizr}/tenant/forwardIps`;
/////////////////////////////////p
export const GET_ENDPOINT_URL = `${domain_vizr}/tenant/nwService/serviceName/mongo-endpoint`;
//////////////////////////////////////
export const Create_Concentrator_Settings = `/platform/tenantManagement/:id/CreateConcentratorSettings`;
export const Create_Ip_Settings = `/platform/tenantManagement/:id/CreateIpSettings`;
export const Create_Endpoint_Settings = `/platform/tenantManagement/:id/CreateEndpointSettings`;

export const GET_SERVICE_NODES = `${domain_vizr}/tenant/service/serviceNodes`;
export const PUT_SERVICE_NODE = `${domain_vizr}/tenant/service/updateNodeAliasName`;
export const GET_CDC_LOGS_URL = `${domain_vizr}/tenant/cdcLog`;
export const GET_LOG_SERVICES = `${domain_vizr}/tenant/auditLogs`;
export const UPDATE_LOGSOURCE = `${domain_vizr}/tenant/auditLogs/logSource`;
export const GET_LOG_SOURCE_URL = domain_vizr + "/tenant/LogSourceType";
export const VERIFY_AZURE_LOG_SOURCE = domain_vizr + "/connections/logSources/test";
export const VERIFY_AWS_LOG_SOURCE = domain_vizr + "/tenant/auditLogs/logSource/testConnection";
export const create_log_source = () => {
  return `${domain_vizr}/tenant/auditLogs`;
};
export const GET_DATASOURCE_URL =
  domain_vizr + "/tenant/alerts/alertDataSources";

export const SERVICE_USERS_WITH_HIGH_ALERTS = "Service Users With High Alerts";
export const SERVICE_USERS = "Service Users";

export const LOADING_DATA = "{{LOADING_DATA}}";
export const MASKED_DATA = "{{MASKED_DATA}}";
export const LOAD_EXEC_DESCRIPTION = "Click here to load Number of Executions";
export const SERVICE_LEVEL_ACCESS = "SERVICE_LEVEL_ACCESS";
export const PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE =
  "PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE";
export const GET_DATAMAP_SHOW_USERS =   `${domain_vizr}/tenant/databases/findTopSensitivityPercentageAccessUsers`;
export const ALL_DATAMAP_NODES_BY_CUBE_NODES_REQUEST = "ALL_DATAMAP_NODES_BY_CUBE_NODES_REQUEST";
export const SET_DATAMAP_DETAILS= "SET_DATAMAP_DETAILS";
export const SET_DATAMAP_TOOLTIP_DETAILS = "SET_DATAMAP_TOOLTIP_DETAILS";
export const SET_DATAMAP_TOOLTIP_LOADER = "SET_DATAMAP_TOOLTIP_LOADER";
export const SET_DATAMAP_USER_DETAILS = "SET_DATAMAP_USER_DETAILS";
export const CLEAR_DATA_MAP = "CLEAR_DATA_MAP";
export const SET_DATAMAP_USER = "SET_DATAMAP_USER";
export const GET_DATAMAP_NODES = domain_vizr + "/tenant/dataMap";
export const GET_DATAMAP_NODE_TOOLTIP = domain_vizr + "/tenant/dataMap/nodeDetails";
export const SET_GRAPH_NODES_LIST = "SET_GRAPH_NODES_LIST";
export const SET_DATAMAP_LOADER= "SET_DATAMAP_LOADER";
export const GET_USER_NODES_LIST = "GET_USER_NODES_LIST";
export const SET_USERS_NODES_LIST = "SET_USERS_NODES_LIST";
export const GET_NODES_LIST = "GET_NODES_LIST";
export const usersWithAnomalies = "usersWithAnomalies";
export const usersWithSensitiveAccess = "usersWithSensitiveAccess";
export const savePolicyErrorMessage = "Select trigger section or select auto monitoring to save policy";
export const ONEDRIVE = "ONEDRIVE";
export const DROPBOX = "DROPBOX";
export const OTHER = "OTHER";
export const POSTGRESQL = "POSTGRESQL";
export const MSSQL = "MSSQL";
export const SQLSERVER = "SQLSERVER";
export const MYSQL = "MYSQL";
export const AWS = "AWS";
export const AZURE = "AZURE";
export const ON_PREMISE = "ON_PREMISE";
export const GOOGLEDRIVE = "GOOGLEDRIVE";
export const MS_CLOUD_FILE_SYSTEM = "MS_CLOUD_FILE_SYSTEM";

//Heatmap changes
export const GET_HEATMAP_DATA = `${domain_vizr}/tenant/heatMap`;
export const SET_HEATMAP_DETAILS = "SET_HEATMAP_DETAILS";
export const SET_HEATMAP_LOADER = 'SET_HEATMAP_LOADER';
export const HEATMAP_URL = "/tenant/Heatmap";
export const FETCH_SENSITIVE_DATA_FIELDS_REQUEST = 'FETCH_SENSITIVE_DATA_FIELDS_REQUEST';
export const CLEAR_HEATMAP = 'CLEAR_HEATMAP';
export const SET_VIEW_BY_VALUE = 'SET_VIEW_BY_VALUE';
export const FETCH_ALL_FIELDS_DATA_REQUEST = 'FETCH_ALL_FIELDS_DATA_REQUEST';
export const SET_SOURCE_NAME = 'SET_SOURCE_NAME';
export const HEATMAP_SELECTED_NODE = 'HEATMAP_SELECTED_NODE';

//Inspect Content Changes
export const SET_INSPECT_CURRENT_PAGE = 'SET_INSPECT_CURRENT_PAGE';
export const SET_INSPECT_PAGE_SIZE = 'SET_INSPECT_PAGE_SIZE';
export const SET_SENSITIVE_POP_UP_DATA = 'SET_SENSITIVE_POP_UP_DATA';
export const SET_SENSITIVE_DATA_FIELDS = 'SET_SENSITIVE_DATA_FIELDS';
export const SET_ALL_FIELDS_DATA = 'SET_ALL_FIELDS_DATA';
export const SET_TOTAL_FILE_COUNT = 'SET_TOTAL_FILE_COUNT';
export const SET_TOTAL_SENSITIVE_COUNT = 'SET_TOTAL_SENSITIVE_COUNT';
export const INSPECT_CONTENT_SORT_BY_VALUE = 'INSPECT_CONTENT_SORT_BY_VALUE';
export const INSPECT_CONTENT_ORDER_BY_VALUE = 'INSPECT_CONTENT_ORDER_BY_VALUE';
export const SET_SEVERITY_FILTER_DATA = 'SET_SEVERITY_FILTER_DATA';
export const SET_CATEGORIES_FILTER_DATA = 'SET_CATEGORIES_FILTER_DATA';
export const SET_INSPECT_CONTENT_FILTERS = 'SET_INSPECT_CONTENT_FILTERS';
export const SET_INSPECT_FILTER_DATA = 'SET_INSPECT_FILTER_DATA';
export const SET_LOADER = 'SET_LOADER';
export const CLEAR_SENSITIVE_DATA_FIELDS = 'CLEAR_SENSITIVE_DATA_FIELDS';
export const CLEAR_ALL_FIELDS_DATA = 'CLEAR_ALL_FIELDS_DATA';
export const CLEAR_INSPECT_FILTER_DATA = 'CLEAR_INSPECT_FILTER_DATA';
export const ENABLE_NODE_TOOLTIP = 'ENABLE_NODE_TOOLTIP';
export const SET_DATAMAP_MULTI_TOOLTIP_LOADER = 'SET_DATAMAP_MULTI_TOOLTIP_LOADER';
export const SET_DATAMAP_MULTI_TOOLTIP_DATA = 'SET_DATAMAP_MULTI_TOOLTIP_DATA';
export const CLOSE_TOOLTIP = 'CLOSE_TOOLTIP';
export const CLEAR_DATAMAP_MULTI_TOOLTIP = 'CLEAR_DATAMAP_MULTI_TOOLTIP';
export const NOT_PROCESSED = 'NOT_PROCESSED';
export const NON_CLASSIFIED = 'NON CLASSIFIED';
export const INSTANCE_ERROR_MESSAGE = "Either Instance or DesiredPort is mandatory";
export const SET_NODES_RESPONSE = 'SET_NODES_RESPONSE';

//DataOwner 
export const GET_DATA_OWNER_DATA_FIELDS_REQUEST = "GET_DATA_OWNER_DATA_FIELDS_REQUEST";
export const GET_DATA_OWNER_HEADER_DATA_REQUEST = "GET_DATA_OWNER_HEADER_DATA_REQUEST";
export const SET_DATA_OWNER_FIELDS_DATA = "SET_DATA_OWNER_FIELDS_DATA";
export const SET_HEADER_DATA = "SET_HEADER_DATA;"
export const CLEAR_DATA_OWNER_FIELDS = "CLEAR_DATA_OWNER_FIELDS";
export const SET_DATA_OWNER_PAGE_SIZE = "SET_DATA_OWNER_PAGE_SIZE";
export const SET_DATA_OWNER_CURRENT_PAGE =  "SET_DATA_OWNER_CURRENT_PAGE";
export const SET_TOTAL_DATA_SOURCE_COUNT = "SET_TOTAL_DATA_SOURCE_COUNT";

//tabSwitch variables
export const FETCH_TOTAL_ASSETS_COUNT = "FETCH_TOTAL_ASSETS_COUNT";
export const SET_TOTAL_ASSETS_COUNT = "SET_TOTAL_ASSETS_COUNT";
export const SET_ONEDRIVE_ASSETS_COUNT = "SET_ONEDRIVE_ASSETS_COUNT";
export const SET_GOOGLEDRIVE_ASSETS_COUNT = "SET_GOOGLEDRIVE_ASSETS_COUNT";
export const SET_DROPBOX_ASSETS_COUNT = "SET_DROPBOX_ASSETS_COUNT";
export const CLEAR_ALL_ASSETS_TOTAL_COUNT = "CLEAR_ALL_ASSETS_TOTAL_COUNT";

//generate report
export const SET_USER_EMAILS = "SET_USER_EMAILS";
export const GET_ALL_USER_EMAILS = "GET_ALL_USER_EMAILS";
export const FETCH_REPORT_DATA = 'FETCH_REPORT_DATA';
export const SET_REPORT_LOADER = "SET_REPORT_LOADER";
export const REPORT_DATA = "REPORT_DATA";
export const CLEAR_REPORT_DATA = "CLEAR_REPORT_DATA";
export const SUCCESS_MESSAGE = "Generating Report..";
export const ERROR_MESSAGE = "Report Generation Failed.";
export const GET_REPORT_HISTORY = "GET_REPORT_HISTORY";
export const REPORT_HISTORY_DATA = "REPORT_HISTORY_DATA";
export const OPEN_REPORT_HISTORY = "OPEN_REPORT_HISTORY"; 
export const CLOSE_REPORT_HISTORY = "CLOSE_REPORT_HISTORY";
export const CLEAR_REPORT_HISTORY_DATA = "CLEAR_REPORT_HISTORY_DATA";
export const ACTIVITY_REPORT_VIEW = "ACTIVITY_REPORT_VIEW";
export const REPORT_DETAILS = "REPORT_DETAILS";
export const ACTIVITY_REPORT_DATA = "ACTIVITY_REPORT_DATA";
export const SET_REPORT_DATA = "SET_REPORT_DATA";
export const SAVE_REPORT_DATA = `${domain_vizr}/report/generateReport`;
export const SET_SNACKBAR_MESSAGE = "SET_SNACKBAR_MESSAGE";
export const SET_TOTAL_REPORTS_COUNT = "SET_TOTAL_REPORTS_COUNT";
export const CLEAR_SNACK_BAR_MESSAGE = "CLEAR_SNACK_BAR_MESSAGE";
export const GET_REPORT_DATA_URL = (size, page) => {
  return`${domain_vizr}/report/fetchReport?size=${size}&page=${page}`;
};
export const SEARCH_REPORT_DATA = "SEARCH_REPORT_DATA";
export const SEARCH_REPORT_DATA_URL = (size, page, reportName) => {
  return`${domain_vizr}/report/search?name=${reportName}&size=${size}&page=${page}`;
}
export const VIEW_REPORT_URL = (id, email) => {
  return `${domain_vizr}/report/ViewReport?id=${id}&email=${email}`;
};
export const FETCH_VIEW_REPORT = "FETCH_VIEW_REPORT";
export const VEW_REPORT_FAILED_MESSAGE = "Failed to load this report"
export const HTML_PAGE = "HTML_PAGE";
export const VIEW_REPORT_ERROR_MESSAGE = "VIEW_REPORT_ERROR_MESSAGE";
export const CLEAR_HTML_PAGE = "CLEAR_HTML_PAGE";
export const GET_REPORT_HISTORY_URL = (id) => {
  return `${domain_vizr}/report/sharedHistory?id=${id}`
}
export const SET_REPORT_HISTORY_LOADER = "SET_REPORT_HISTORY_LOADER";
export const EXTERNAL_VIEW_REPORT_URL = "/tenant/ViewReport";
export const generateReport = "GENERATE_REPORT";
export const fetchReport = "FETCH_REPORT";
export const viewReportDetails = "VIEW_REPORT";

//share report
export const SHARE_REPORT = "SHARE_REPORT";
export const SET_SHARE_REPORT_MESSAGE = "SET_SHARE_REPORT_MESSAGE";
export const CLEAR_SHARE_REPORT_MESSAGE = "CLEAR_SHARE_REPORT_MESSAGE";
export const SHARE_REPORT_ERROR_MESSAGE = "Report send unsuccessful";
export const SHARE_REPORT_SUCCESS_MESSAGE = "Report sent successfully";
export const SET_SHARE_REPORT_LOADER = "SET_SHARE_REPORT_LOADER";
export const POST_MULTIPLE_REPORT_SHARE = `${domain_vizr}/report/multiShare`;
export const SEARCH_REPORT_ERROR_MESSAGE = "Failed to search report";
export const DELETE_DATASOURCE_URL = (dataSourceId) => {
  return `${domain_vizr}/tenant/dataSources/${dataSourceId}`;
} 
export const DELETE_LOGSOURCE_URL = (logSourceId) => {
  return `${domain_vizr}/tenant/auditLogs/${logSourceId}`;
} 
//fullscreen view variables
export const SET_TAB_VIEW = "SET_TAB_VIEW";
//Error Messages
export const CONNECTION_SUCCESSFUL = "Connection Successful"; 
export const CONNECTION_UNSUCCESSFUL = "Connection Unsuccessful";

//Platform names
export const platformNames = ["druvstar", "datavision"];

//seletive one drive users
export const selectiveOneDriveUsersErrorMessage = "No of users should not be empty or 0"

export const DataOwnerAsOnDesc = "The values shown in this view are calulated at time mentioned below, If you want accurate values click on the row";

//LogSource and DataSource Deletion
export const SET_SNACKBAR = "SET_SNACKBAR";
export const SET_SOURCE_DELETION_ERROR_MESSAGE = "SET_SOURCE_DELETION_ERROR_MESSAGE";
export const TRIGGER_DELETE_LOG_SOURCE = "TRIGGER_DELETE_LOG_SOURCE";
export const logSourceDeletionMessage = "Log source deletion failed";

//Data Source Deletion
export const SET_CRITERIA = "SET_CRITERIA";
export const DATA_SOURCE_DELETION_CRITERIA_CHECK = "DATA_SOURCE_DELETION_CRITERIA_CHECK";
export const SHOW_DIALOG_BOX = "SHOW_DIALOG_BOX";
export const SET_CRITERIA_ERROR_MESSAGE = "SET_CRITERIA_ERROR_MESSAGE";
export const dataSourceErrorMessage = "Data source deletion failed";

export const TRIGGER_DELETE_DATA_SOURCE = "TRIGGER_DELETE_DATA_SOURCE";
export const DELETE_CONFIRMATION_MESSAGE = "Are you sure you want to delete?"
export const DATA_MATCHED = "Data matched.";

//Tenant logo display
export const UPDATE_TENANT_PROFILE = "UPDATE_TENANT_PROFILE";
export const SET_TENANT_PROFILE_LOADER = "SET_TENANT_PROFILE_LOADER";
export const SET_TENANT_PROFILE_SNACKBAR = "SET_TENANT_PROFILE_SNACKBAR";
export const TENANT_UPDATE_UNSUCCESSFUL = "Tenant update unsuccessful";
export const GET_TENANT_PROFILE = "GET_TENANT_PROFILE";
// Image size should be less than 40KB
export const TENANT_LOGO_SIZE = 40;
export const LOGO_SIZE_ERROR_MESSAGE = `Please select image with size less than ${TENANT_LOGO_SIZE}KB.`;
// Image should be of type JPG, JPEG, or PNG.
export const LOGO_FORMAT_ERROR_MESSAGE = "Invalid file type. Please select a JPEG, JPG, or PNG image.";
export const TENANT_NAME_ERROR_MESSAGE = "Tenant name cannot contain special characters"
//Reset password error Messages
export const NETWORK_ERROR_MESSAGE = "Couldn't connect to the server. Please check your network connection";
export const PASSWORD_CRITERIA = "Password must be 9 or more characters in length,Password must contain 1 or more uppercase characters,Password must contain 1 or more digit characters,Password must contain 1 or more special characters";

//Report repository 
export const viewReportURL = (queryParams) => {
  return `/tenant/ViewReport?${queryParams.toString()}`
}

export const NON_WORK_HOUR_POLICY = "UNUSUAL_DATA_ACCESS_NON_WORK_HOUR_DIRECTDB";
export const TOLERANCE_LEVEL_INFO = "Percentages are based on 68–95–99.7 statistical empirical rule";
//Data Source Redux saga
export const AMAZON_RDS_SQLSERVER = "AMAZON_RDS_SQLSERVER";
export const AZURE_EVENT_HUB = "AZUREEVENTHUB";
export const CREATE_DATA_SOURCE = "CREATE_DATA_SOURCE";
export const CREATE_LOG_SOURCE = "CREATE_LOG_SOURCE";
export const REGIONS = "regions";
export const GET_LOG_SOURCE_TYPE_DETAILS = "GET_LOG_SOURCE_TYPE_DETAILS";
export const SET_DATA_SOURCE_DETAILS = "SET_DATA_SOURCE_DETAILS";
export const SET_SOURCE_ERROR_MESSAGE = "SET_SOURCE_ERROR_MESSAGE";
export const SET_LOGSOURCE_SAVED = "SET_LOGSOURCE_SAVED";
export const SAVE_LOG_SOURCE_DETAILS = "SAVE_LOG_SOURCE_DETAILS";
export const CLEAR_DATA_SOURCE_DETAILS = "CLEAR_DATA_SOURCE_DETAILS";
export const GET_LOG_SOURCE_DETAILS = "GET_LOG_SOURCE_DETAILS";
export const SET_LOG_SOURCE_DETAILS = "SET_LOG_SOURCE_DETAILS";
export const SET_LOG_SOURCE_DATA = "SET_LOG_SOURCE_DATA";
export const CLEAR_LOG_SOURCE_DATA = "CLEAR_LOG_SOURCE_DATA";
export const SET_SOURCES_LOADER = "SET_SOURCES_LOADER";
export const VERIFY_LOG_SOURCES = "VERIFY_LOG_SOURCES";
export const SET_LOG_SOURCE_VERIFIED = "SET_LOG_SOURCE_VERIFIED";
export const SET_DATA_SOURCE_SAVED = "SET_DATA_SOURCE_SAVED";
export const DATA_SOURCE_CONNECTION_MESSAGE = "DATA_SOURCE_CONNECTION_MESSAGE";
export const TEST_DATA_SOURCE_CONNECTION = "TEST_DATA_SOURCE_CONNECTION";
export const CLEAR_SOURCE_SAVED = "CLEAR_SOURCE_SAVED";
export const DATASOURCE_SAVE_ALIAS_NAME = "DATA_SOURCE_SAVE_ALIAS_NAME";
export const DATASOURCE_NAME_EDIT_SUCCESS = "DATASOURCE_NAME_EDIT_SUCCESS";
export const CLEAR_SOURCE_ERROR_MESSAGE = "CLEAR_SOURCE_ERROR_MESSAGE";
export const LOCATION_ERROR_MESSAGE = "Location cannot contain special characters except ':' and '\\'";
export const STORAGE_CONTAINER_ERROR_MESSAGE = "Storage Container Name must be between 3 and 63 characters long and cannot be blank or empty";
export const getAvailableRegionsData = `${domain_vizr}/tenant/auditLogs/getAvailableRegions`;
export const GET_AVAILABLE_REGIONS = "GET_AVAILABLE_REGIONS";
export const MAXIMUM_NUMBER_OF_REPORTS = "A maximum of five reports can be selected";

//DataSource error messages
export const USER_NAME_ERROR_MESSAGE = "Username cannot be blank or empty";
export const PASSWORD_ERROR_MESSAGE = "Password cannot be blank or empty";
export const DESIRED_HOST_ERROR_MESSAGE = "Either Host or Desired host is mandatory";
export const HOST_ERROR_MESSAGE = "Host name cannot be blank or empty";
export const PORT_ERROR_MESSAGE = "Port cannot be blank or empty";
export const DB_NAME_ERROR_MESSAGE = "Database cannot be blank or empty";
export const DOMAIN_NAME_ERROR_MESSAGE = "Domain name should not be empty";
export const ACCOUNT_NAME_ERROR_MESSAGE = "Account name should not be empty";
export const ALIAS_NAME_ERROR_MESSAGE = "Alias Name cannot be blank or empty";

//oneDrive Data
export const SET_CLOUD_SOURCE_FIELDS_DATA = "SET_CLOUD_SOURCE_FIELDS_DATA";
export const CLEAR_CLOUD_SOURCE_FIELDS_DATA = "CLEAR_CLOUD_SOURCE_FIELDS_DATA";
export const getAllGroups = (dsId) => {
  return `${domain_vizr}/tenant/datasources/${dsId}/groups`
};
export const GET_ALL_CLOUD_DRIVE_GROUPS = "GET_ALL_CLOUD_DRIVE_GROUPS";
export const CLEAR_DATASOURCE_NAME_EDIT_SUCCESS = "CLEAR_DATASOURCE_NAME_EDIT_SUCCESS";
export const SAVE_API_ERROR = "API Error, please try after sometime";
export const GROUPS_SAVE_API = "GROUPS_SAVE_API";

export const REGION_ERROR_MESSAGE = "Region Should not be blank or empty";
export const AMAZON_RDS_POSTGRESQL = "AMAZON_RDS_POSTGRESQL";
// Define constants for source codes
export const DATA_SOURCES_CODES = ['ONEDRIVE', 'DROPBOX', 'GOOGLEDRIVE'];
export const DATA_SOURCE_TYPE_CODES = ['DATASOURCE', 'APPLICATION'];

export const GROUPS_SAVE_SUCCESSFUL = "GROUPS_SAVE_SUCCESSFUL";
export const RESET_GROUPS_SAVE_SUCCESS = "RESET_GROUPS_SAVE_SUCCESS";

export const cloudFileFormTextMap = {
  ONEDRIVE: "OneDrive Data Source",
  DROPBOX: "Dropbox Data Source",
  GOOGLEDRIVE: "Google Drive Data Source",
  default: "Data Source",
};

export const setupPageDetails = {
  GOOGLEDRIVE: "CLOUD_FILE_SYSTEM",
  MSSQL: "WINLOGBEAT",
  POSTGRESQL: "FILEBEAT"
}

export const sourceDetailsHeaderTitles = {
  POSTGRESQL: "Filebeat",
  GOOGLEDRIVE: "Google Workspace Configuration",
  MSSQL: "Winlogbeat"
} 

export const SET_DS_ERROR_MESSAGE = "SET_DS_ERROR_MESSAGE";

export const AWS_FILE_SYSTEM = [AMAZON_RDS_SQLSERVER, AMAZON_RDS_POSTGRESQL]
export const CLOUD_SQL_SYSTEM = [MSSQL, POSTGRESQL]

export const DASHBOARD_TYPES = [
  "MDRIncidents",
  "RiskLevel",
  "Risk Categories",
  "Risk Owner",
  "RiskAging",
  "Risk Type",
  "Risk Status"
];

export const disableNotificationIconList = ["INACTIVE", "DELETED"]
