import React from "react";
import Popover from "@material-ui/core/Popover";
import LevelComponent from "../../shared/levelComponent";

export const PopoverComponent = props => {
  const {
    severityPopUpOpen,
    anchorEl,
    handleCloseSeverityPopup,
    changeAlertPolicySeveritySave
  } = props;

  const severities = [
    { value: "HIGH", display: "HIGH" },
    { value: "MEDIUM", display: "MEDIUM" },
    { value: "LOW", display: "LOW" }
  ];
  return (
    <Popover
      open={severityPopUpOpen}
      anchorEl={anchorEl}
      onClose={handleCloseSeverityPopup}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px"
        }}
      >
        {severities.map(each => {
          return (
            <>
              <div
                className="severity-span"
                onClick={() =>
                  changeAlertPolicySeveritySave(
                    each.value
                  )
                }
              >
                <LevelComponent level={each.display} status={""} />
              </div>
            </>
          );
        })}
      </div>
    </Popover>
  );
};
