import React, { useReducer, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import "../../../assets/scss/ProfileEditing.scss";
import "../../../assets/scss/TenantMangement.scss";
import Grid from "@material-ui/core/Grid";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  GET_SINGLE_USER,
  ALL_ROLES,
  UPDATE_PASSWORD,
} from "../../../redux/constants/constants";
import SnackBar2 from "../../shared/SnackBar2";

import Input from "../../shared/Input";
import ImageEditing from "../../shared/ImageEditing";
import ManagementFooter from "../BackOfficeGeneralContainer/ManagementFooter";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

import Switch from "@material-ui/core/Switch";
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const initialState = {
  userName: "",
  firstName: "",
  lastName: "",
  imgUrl: "",
  fullName: "",
  role: [],
  domain: "",
  features: "",
  licenses: "",
  phoneNumber: "",
  profilePicture: "",
};

function reducer(state, action) {
  console.log(action.payload);
  switch (action.type) {
    case "success":
      return {
        userName: action.payload.email ? action.payload.email : "",
        firstName: action.payload.firstName ? action.payload.firstName : "",
        lastName: action.payload.lastName ? action.payload.lastName : "",
        imgUrl: action.payload.qrCode.imageUri
          ? action.payload.qrCode.imageUri
          : "",
        fullName: `${action.payload.firstName} ${action.payload.lastName}`,
        role: action.payload.roles.map((role) => role.id),
        domain: "",
        features: "",
        licenses: "",
        phoneNumber: action.payload.phone,
        profilePicture: action.payload.profilePicture,
      };
    case "change_Roles":
      return {
        ...state,
        role: action.payload,
      };
    case "change_Phonenumber":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "change_firstName":
      return {
        ...state,
        firstName: action.payload,
      };
    case "change_lastName":
      return {
        ...state,
        lastName: action.payload,
      };
    case "change_Name":
      return {
        ...state,
        fullName: action.payload,
      };
    default:
      throw new Error();
  }
}

const ProfileEditing = () => {
  let history = useHistory();
  const [file, setFile] = useState("");
  const [file2, setFile2] = useState("");
  const [roles, setRoles] = useState([]);
  const [profileUpdateState, setProfileUpdateState] = useState({
    profileUpdateSuccess: "",
    profileUpdateFailure: "",
    successMessage: "",
    failureMessage: "",
  });
  const userID = useSelector((state) => state.signInpage.userDetails.id);

  const inputFile = useRef(null);

  useEffect(() => {
    console.log("Profile Editing");
    console.log(userID);
    const apiCall = async () => {
      const headers = getAxiosHeaders(true);
      const res = await getService({
        method: "GET",
        url: GET_SINGLE_USER(
          localStorage.getItem("tenant") ? localStorage.getItem("tenant") : "",
          userID
        ),
        headers: headers,
      });
      console.log(res);
      if (res.status === 200) {
        console.log(res);
        dispatch({ type: "success", payload: res.data });
      }
    };
    apiCall();
  }, [userID]);

  useEffect(() => {
    const apiCall = async () => {
      const headers = getAxiosHeaders(true);
      const res = await getService({
        method: "GET",
        url: ALL_ROLES(
          localStorage.getItem("tenant") ? localStorage.getItem("tenant") : ""
        ),
        headers: headers,
      });
      console.log(res);
      if (res.status === 200) {
        setRoles([...res.data.content]);
      }
    };
    apiCall();
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
  });
  const imageHandler = (event) => {
    if (event.target.files[0]) {
      console.log(event.target.files[0]);
      setFile(URL.createObjectURL(event.target.files[0]));
      setFile2(event.target.files[0]);
    }
  };

  const [toggle, setToggle] = useState({
    gridSettings: false,
    dashBoardSettings: false,
  });

  const ToggleChangeHandler = (name) => {
    if (name === "gridSettings") {
      setToggle({ ...toggle, gridSettings: !toggle.gridSettings });
    } else if (name === "dashboardSettings") {
      setToggle({ ...toggle, dashBoardSettings: !toggle.dashBoardSettings });
    }
  };

  const passwordHandler = (name, value) => {
    if (name === "oldPassword") {
      setPasswords({ ...passwords, oldPassword: value });
    } else if (name === "newPassword") {
      setPasswords({ ...passwords, newPassword: value });
    } else if (name === "repeatPassword") {
      setPasswords({ ...passwords, repeatPassword: value });
    }
  };

  const profileUpdateAPIcall = async (formData) => {
    console.log(formData);
    const res = await getService({
      method: "PUT",
      url: GET_SINGLE_USER(
        localStorage.getItem("tenant") ? localStorage.getItem("tenant") : "",
        userID
      ),
      headers: getAxiosHeaders(true, "multipart/form-data"),
      data: formData,
    });
    console.log(res);
    if (res.status === 200) {
      console.log(res);
      setProfileUpdateState({
        ...profileUpdateState,
        profileUpdateSuccess: true,
        successMessage: "profile updated successfully",
      });
    } else if (res.status === 400) {
      setProfileUpdateState({
        ...profileUpdateState,
        profileUpdateFailure: true,
      });
    } else {
      console.log(res);
      setProfileUpdateState({
        ...profileUpdateState,
        profileUpdateFailure: true,
        failureMessage: "profile update failed. Please try after some time",
      });
    }
  };
  const resetPassword_APIcall = async (formData) => {
    const res = await getService({
      method: "PUT",
      url: UPDATE_PASSWORD(
        localStorage.getItem("tenant") ? localStorage.getItem("tenant") : ""
      ),
      headers: getAxiosHeaders(true),
      data: {
        oldPassword: passwords.oldPassword,
        newPassword: passwords.newPassword,
      },
    });
    console.log(res);
    if (res.status === 200) {
      profileUpdateAPIcall(formData);
      setPasswords({
        oldPassword: "",
        newPassword: "",
        repeatPassword: "",
      });
    } else {
      setProfileUpdateState({
        ...profileUpdateState,
        profileUpdateFailure: true,
      });
    }
  };

  console.log(profileUpdateState);
  const submitHandler = () => {
    const profileUpdate = {
      ...profileUpdateState,
      profileUpdateSuccess: "",
      profileUpdateFailure: "",
      successMessage: "",
      failureMessage: "",
    };
    setProfileUpdateState({
      ...profileUpdate,
    });
    console.log("submit");

    const formData = new FormData();
    const obj = {
      firstName: state.firstName,
      lastName: state.lastName,
      phone: state.phoneNumber,
      mfaEnabled: "",
      roleIds: state.role,
    };
    console.log(obj);
    let myObj = JSON.stringify(obj);
    formData.append("payload", myObj);
    formData.append("profilePicture", file2);
    if (
      passwords.newPassword &&
      passwords.oldPassword &&
      passwords.repeatPassword
    ) {
      if (passwords.newPassword === passwords.repeatPassword) {
        resetPassword_APIcall(formData);
        // profileUpdateAPIcall(formData);
      } else {
        alert("New Password did not match with the Repeat Password");
      }
    } else if (passwords.newPassword && !passwords.oldPassword) {
      setProfileUpdateState({
        ...profileUpdateState,
        profileUpdateFailure: true,
        failureMessage: "Please fill all the passwords",
      });
    } else if (!passwords.newPassword && passwords.oldPassword) {
      setProfileUpdateState({
        ...profileUpdateState,
        profileUpdateFailure: true,
        failureMessage: "Please fill all the passwords",
      });
    } else {
      profileUpdateAPIcall(formData);
    }
  };
  // rendering role names
  const renderRoles = () => {
    if (state.role && roles) {
      const userRoles = roles.filter((role) => state.role.includes(role.id))
      return userRoles.map((option) => option.name).join(", ");
    } else {
      return "";
    }
  };

  const cancelHandler = () => {
    dispatch({ type: "TRACK_TRAVERSAL", payload: "Dashboard" });
    history.push(`/tenant/dashboard`);
  };
  return (
    <div className="ProfileEditing">
      <SnackBar2
        success={profileUpdateState.profileUpdateSuccess}
        successMessage={profileUpdateState.successMessage}
        failure={profileUpdateState.profileUpdateFailure}
        errorMessage={profileUpdateState.failureMessage}
        setProfileUpdateState={setProfileUpdateState}
        profileUpdateState
      ></SnackBar2>
      <div>
        <SignUpBackGround
          headerName="Profile and Preferences"
          header="Profile and Preferences"
          breadcrumbValue
        ></SignUpBackGround>
        <div className="ProfileEditing_content">
          <Grid container direction="row" justify="center" xs={12} xl={12}>
            <Grid
              container
              direction="column"
              item
              xs={12}
              sm={3}
              md={3}
              xl={3}
              className="userEditing_Container"
            >
              <Grid className="userEditing_ImageContainer userEditing_ImageContainer_profileEditing">
                <ImageEditing
                  imageHandler={imageHandler}
                  inputFile={inputFile}
                  file={file}
                  state={state}
                  disabled={true}
                ></ImageEditing>
              </Grid>
              <Grid>
                <img
                  style={{ width: "50%" }}
                  src={state.imgUrl}
                  alt="QR code"
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              item
              xs={12}
              sm={4}
              md={4}
              xl={4}
            >
              <div className="profileHeading">Profile</div>
              <Input
                // disabled
                type="text"
                classname="ProfileEditing_Input"
                labelName="Firstname"
                value={state.firstName}
                eventHandler={(value) => {
                  dispatch({
                    type: "change_firstName",
                    payload: value,
                  });
                }}
              ></Input>
              <Input
                // disabled
                type="text"
                classname="ProfileEditing_Input"
                labelName="Lastname"
                value={state.lastName}
                eventHandler={(value) => {
                  dispatch({
                    type: "change_lastName",
                    payload: value,
                  });
                }}
              ></Input>
              <Input
                // disabled
                type="number"
                classname="ProfileEditing_Input ProfileEditing_Input_phoneNumber"
                labelName="Phone"
                value={state.phoneNumber}
                eventHandler={(value) => {
                  dispatch({
                    type: "change_Phonenumber",
                    payload: value,
                  });
                }}
              ></Input>
              <Input
                type="email"
                classname="ProfileEditing_Input disabled"
                labelName="Username"
                value={state.userName}
                eventHandler={(value) => {}}
                disabled
              ></Input>
              <div style={{ marginBottom: "2rem" }}>
                <div>Roles</div>
                <TextField
                  disabled
                  className="ProfileEditing_Roles"
                  required
                  value={renderRoles()}
                  variant="outlined"
                  multiline
                />
              </div>

              {/* <Input
                type="text"
                classname="ProfileEditing_Input disabled"
                labelName="Domain"
                value={state.domain}
                disabled
              ></Input>
              <Input
                type="text"
                classname="ProfileEditing_Input disabled"
                labelName="Features"
                value={state.features}
                disabled
              ></Input>

              <Input
                type="text"
                classname="ProfileEditing_Input disabled"
                labelName="Licenses"
                value={state.licenses}
                disabled
              ></Input> */}
            </Grid>
            <Grid
              container
              direction="column"
              item
              xs={12}
              sm={4}
              md={4}
              xl={4}
              style={{ marginLeft: "20px" }}
            >
              <div className="profileHeading">Reset password</div>
              {/* <div className="toggleFlex">
                <div className="profileSettings">Grid Settings</div>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      disabled
                      checked={toggle.gridSettings}
                      onChange={() => ToggleChangeHandler("gridSettings")}
                      name="checkedB"
                    />
                  }
                />
              </div> */}
              {/* <div className="toggleFlex">
                <div className="profileSettings">Dashboard Settings</div>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      disabled
                      checked={toggle.dashBoardSettings}
                      onChange={() => ToggleChangeHandler("dashboardSettings")}
                    />
                  }
                />
              </div> */}

              {/* <div className="profileHeading resetPassword">Reset Password</div> */}
              <Input
                type="password"
                classname="ProfileEditing_Input"
                labelName="Old Password"
                value={passwords.oldPassword}
                eventHandler={(value) => passwordHandler("oldPassword", value)}
              ></Input>
              <Input
                type="password"
                classname="ProfileEditing_Input"
                labelName="New Password"
                value={passwords.newPassword}
                eventHandler={(value) => passwordHandler("newPassword", value)}
              ></Input>
              <Input
                type="password"
                classname="ProfileEditing_Input"
                labelName="Repeat Password"
                value={passwords.repeatPassword}
                eventHandler={(value) =>
                  passwordHandler("repeatPassword", value)
                }
              ></Input>
            </Grid>
          </Grid>
        </div>
        <ManagementFooter
          classname="profileEditing_footer"
          submitHandler={submitHandler}
          cancelHandler={cancelHandler}
        ></ManagementFooter>
      </div>
    </div>
  );
};

export default ProfileEditing;
