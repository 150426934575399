import { SET_DS_ERROR_MESSAGE, UPDATE_DATASOURCE_SUCCESS } from '../constants/constants';

const initialState = {};

export const datasourceDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATASOURCE_SUCCESS: {
      if (action.payload === null) {
        return {}
      }
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_DS_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload
      }
    }
    default:
      return state;
  }
};
