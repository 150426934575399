import { GET_ALERT_DETAILS, GET_ALERT_DETAILS_URL} from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

export async function getAlertDetails(id) {
    console.log(id)
    const options = {
        method: "GET",
        url: `${GET_ALERT_DETAILS_URL}/${id}`,
        headers: getAxiosHeaders(true),
    };
    if(id !== ''){
        const response = await getService(options);
        if(response) {
            return response.data
        }
    }
};
