import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export default function PolicyFilter(props) {
    console.log("props",props);
    const { selectedFilter,policyArr,policyFilter,handleCheckbox } = props;
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '290px',
            overflowY: 'scroll',
            borderLeft: '2px solid grey',
            padding: '10px'
        },
        level: {
            borderTop: 'solid 2px #979797',
            marginTop: '15px',
            width: '84%'
        },
        resolve: {
            borderBottom: 'solid 2px #979797',
            borderTop: 'solid 2px #979797',
            marginTop: '15px',
            width: '90%'
        },
        ul: {
            listStyleType: 'none'
        },
        li: {
            paddingBottom: '8px'
        },
        label: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.14',
            letterSpacing: 'normal',
            color: 'black'
        },
        FilterHead: {
            fontSize: '12px',
            lineHeight: 1.22,
            color: 'black'
        }
    }));
    const useStyles_checkbox = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: 3,
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
    });
    const classes = useStyles();
    const classes_check = useStyles_checkbox();
    return (
        <FormControl component="fieldset" className={classes.root}>
            <p className={classes.FilterHead}>Filter By Policy</p>
            <FormGroup aria-label="position" row>
                <div>
                    {policyArr && policyArr.map((each,index) => {
                        return (
                            <FormControlLabel
                                value={each.id}
                                id={index}
                                control={
                                    <Checkbox className={classes_check.root}
                                        checked={policyFilter.includes(each.id)}
                                        onChange={(event) => handleCheckbox(event,"policy")}
                                        color="default"
                                        checkedIcon={<span className="checkbox-icon checked-icon" />}
                                        icon={<span className="checkbox-icon" />}
                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                    />}
                                label={<p className={classes.label}>{each.name}</p>}
                                labelPlacement="end"
                            />
                        )
                    })}
                </div>
            </FormGroup>
        </FormControl>
    );
}
